<div style="display: flex; justify-content: space-between; gap: 10px; width: 100%;"
  [nbSpinner]="!evolution.prev || !evolution.prev">
  <div style="font-size: 0.9em; font-weight: bold;">Evolução</div>
  <div class="flexColumn" style="min-width: 0px; width: 100%;" *ngIf="evolution.prev">
    <div class="flexColumn" style="width: 100%;">
      <div class="flexRow flexGap5">
        <div class="fontTitleKey">Previsto</div>
        <div class="key">
          <div class="circle setup"></div>
          <div class="fontKey" [nbTooltip]="evolution?.prev?.setup | minutesToHours">{{evolution?.prev?.setup |
            minutesToHours}}</div>
        </div>
        <div class="key">
          <div class="circle run"></div>
          <div class="fontKey" [nbTooltip]="evolution?.prev?.run | minutesToHours">{{evolution?.prev?.run |
            minutesToHours}}</div>
        </div>
      </div>
      <div class="flexRow">
        <div class="rect setup" [nbTooltip]="evolution?.prev?.setup | minutesToHours"
          [style.width]="(evolution?.prev?.setup/(evolution?.prev?.setup + evolution?.prev?.run)*100)+'%'"></div>
        <div class="rect run" [nbTooltip]="evolution?.prev?.run | minutesToHours"
          [style.width]="(evolution?.prev?.run/(evolution?.prev?.setup + evolution?.prev?.run)*100)+'%'"></div>
      </div>
    </div>
    <div class="flexColumn" style="width: 100%;">
      <div class="flexRow flexGap5" style="width: 100%;">
        <div class="fontTitleKey">Real</div>
        <div class="key">
          <div class="circle setup"></div>
          <div class="fontKey" [nbTooltip]="evolution?.sumSetup | minutesToHours">{{evolution?.sumSetup |
            minutesToHours}}</div>
        </div>
        <div class="key">
          <div class="circle run"></div>
          <div class="fontKey" [nbTooltip]="evolution?.sumRun | minutesToHours">{{evolution?.sumRun | minutesToHours}}
          </div>
        </div>
        <div class="key">
          <div class="circle stop"></div>
          <div class="fontKey" [nbTooltip]="evolution?.sumStop | minutesToHours">{{evolution?.sumStop | minutesToHours}}
          </div>
        </div>
      </div>
      <div style="display: flex;">
        <div *ngFor="let i of evolution?.real"
          [style.width]="(i.duration/(evolution?.sumRun + evolution?.sumSetup + evolution?.sumStop)*100)+'%'">
          <div [ngClass]="{'rect': true,
                          'run': i.operationType == 'Production',
                          'stop': i.operationType == 'Stop',
                          'setup': i.operationType == 'Setup'}" [nbTooltip]="i.operation +'\n'+ (i.duration | minutesToHours)">
          </div>
        </div>
      </div>
    </div>
  </div>

</div>