import { AwsIpListComponent } from './core/utils/aws-ip-list/aws-ip-list/aws-ip-list.component';
import { StockDetailFormComponent } from './bsn/stk/stock-detail/stock-detail-form/stock-detail-form.component';
import { AnalysisComponent } from './core/analysis/analysis.component';
import { ApsComponent } from "./bsn/prd/aps/aps.component";
import { DictionaryComponent } from './core/dev/dictionary/dictionary/dictionary.component';
import { Tela1Component } from './core/tests/tela1/tela1.component';
import { DesignPatternComponent } from './core/design-pattern/design-pattern.component';
import { AutoformComponent } from './core/tests/autoform/autoform.component';
import { RegistrationFixComponent } from './bsn/prd/mes/registration-fix/registration-fix.component';
import { PackingComponent } from './bsn/stk/packing/packing.component';
import { PriceTableComponent } from './bsn/sls/price-table/price-table.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './core/login/login.component';
import { HomeComponent } from './core/home/home.component';
import { ConfigComponent } from './core/config/config.component';
import { DatasetComponent } from './core/dataset/dataset.component';
import { SequenceDashboardComponent } from './bsn/prd/analysis/sequence-dashboard/sequence-dashboard.component';
import { OeeComponent } from './bsn/prd/analysis/oee/oee.component';
import { CylinderSchedulerComponent } from './bsn/flx/cylinder-scheduler/cylinder-scheduler.component';
import { VolumeUnlockComponent } from "./bsn/stk/volume/volume-unlock/volume-unlock.component";
import { ResponsiveLayoutComponent } from './core/tests/responsive-layout/responsive-layout.component';
import { GroupAccessControlComponent } from './core/security/access-control/group-access-control.component';
import { T2AutoFormComponent } from "./core/form/t2-auto-form/t2-auto-form.component";
import { DepartmentalRequisitionComponent } from "./bsn/stk/departmental-requisition/departmental-requisition.component";
import { EditRequisitionComponent } from "./bsn/stk/departmental-requisition/edit-requisition/edit-requisition.component";
import { WorkScheduleComponent } from './bsn/prd/ppc/work-schedule/work-schedule.component';
import { ManageItemRequestComponent } from './bsn/prd/ppc/manage-item-request/manage-item-request.component';
import { DashboardComponent } from "./core/dashboard/dashboard.component";
import { LogComponent } from "./core/log/log.component";
import { StockOperationComponent } from "./bsn/stk/stock-operation/stock-operation.component";
import { EPComponent } from "./bsn/epp/ep/ep.component";
import { SeparationComponent } from "./bsn/prd/mes/separation/separation.component";
import { ShopFloorComponent } from "./bsn/prd/mes/shop-floor/shop-floor.component";
import { ProductionOrderReleaseComponent } from "./bsn/prd/ppc/production-order-release/production-order-release.component";
import { BudgetPolicyComponent } from "./bsn/cst/budget-policy/budget-policy.component";
import { T2OAuthAutorizedComponent } from "./core/cmp/oauth/t2-oauth-autorized/t2-oauth-autorized.component";

const routes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: 'home', component: HomeComponent },
  { path: 'config', component: ConfigComponent },

  { path: 'validateOAuth', component: T2OAuthAutorizedComponent },

  { path: 'accessGroupFunctionality', component: GroupAccessControlComponent },
  { path: 'accessGroupRoles', component: GroupAccessControlComponent },

  { path: 'analysis/:id_analysis', component: AnalysisComponent },

  { path: 'dataset/:datasetName', component: DatasetComponent },
  { path: 'dataset/:datasetName/edit/:informationId', component: T2AutoFormComponent },
  { path: 'dataset/:datasetName/edit', component: T2AutoFormComponent },
  { path: 'dataset/:datasetName/insert', component: T2AutoFormComponent },

  { path: 'prd/workSchedule', component: WorkScheduleComponent },
  { path: 'prd/itemRequest', component: ManageItemRequestComponent },
  { path: 'prd/aps', component: ApsComponent },
  { path: 'prd/oee', component: OeeComponent },
  { path: 'prd/sequenceDash', component: SequenceDashboardComponent }, // deprecated
  { path: 'prd/panelMonitor', component: SequenceDashboardComponent },
  { path: 'prd/registrationFix', component: RegistrationFixComponent },
  { path: 'prd/separation', component: SeparationComponent },
  { path: 'prd/shopfloor', component: ShopFloorComponent },
  { path: 'prd/shopFloor', component: ShopFloorComponent },
  { path: 'prd/prodOrderRelease', component: ProductionOrderReleaseComponent },

  { path: 'flx/cylinderScheduler', component: CylinderSchedulerComponent },

  { path: 'cst/budgetPolicy', component: BudgetPolicyComponent },

  { path: 'stk/volumeUnlock', component: VolumeUnlockComponent },
  { path: 'stk/stockSummary', component: StockDetailFormComponent },
  { path: 'stk/stockDetail', component: StockDetailFormComponent },
  { path: 'stk/packing', component: PackingComponent },
  { path: 'stk/departmentalRequisition', component: DepartmentalRequisitionComponent },
  { path: 'stk/departmentalRequisition/edit/:id_requisicaoDepartAgrup', component: EditRequisitionComponent },
  { path: 'stk/departmentalRequisition/insert', component: EditRequisitionComponent },
  { path: 'stk/stockOperation', component: StockOperationComponent },

  { path: 'sls/priceTable', component: PriceTableComponent },

  { path: 'ui-pattern', component: DesignPatternComponent },
  { path: 'dev/dictionary', component: DictionaryComponent },
  { path: 'config/ips', component: AwsIpListComponent },

  { path: 'dashboard/:actionId', component: DashboardComponent },

  { path: 'epp/ep', component: EPComponent },

  // Testes (não devem existir por muito tempo)
  { path: 'testeLayout', component: ResponsiveLayoutComponent },
  { path: 'tela1', component: Tela1Component },
  { path: 'autoform', component: AutoformComponent },
  { path: 'log', component: LogComponent },

  { path: '**', component: HomeComponent },
];
@NgModule({
  imports: [RouterModule.forRoot(routes, { enableTracing: false })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
