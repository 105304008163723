import { T2AnalysisComponent } from './../cmp/t2-analysis/t2-analysis.component';
import { take } from 'rxjs/Operators';
import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { Subscription } from "rxjs";
import { FormTemplateComponent } from "../cmp/form-template/form-template.component";
import { T2HttpClientService } from "../http/t2httpClient.service";
import { T2Route } from "../http/t2route";
import { T2SecurityService } from "../security/t2security.service";

@Component({
  selector: 'app-analysis',
  templateUrl: './analysis.component.html',
  styleUrls: ['./analysis.component.scss']
})
export class AnalysisComponent implements AfterViewInit {

  loaded = false;
  id_analysis: string = null;
  private t2Route: T2Route;
  private pathParams$: Subscription = null;

  @ViewChild("formTemplate", { static: true }) formTemplate: FormTemplateComponent;
  @ViewChild("analysis", { static: true }) analysis: T2AnalysisComponent;

  constructor(private sec: T2SecurityService, private httpClient: T2HttpClientService, private route: ActivatedRoute) {
    this.t2Route = this.httpClient.getT2RouteFromSnapshot(this.route.snapshot);
    this.id_analysis = this.id_analysis || this.t2Route.pathParams.get("id_analysis") || "";

    this.pathParams$ = this.route.params.subscribe(params => {
      if (this.id_analysis != params['id_analysis']) {
        this.id_analysis = params['id_analysis'] || this.id_analysis;
        this.loadAnalysisConfig();
      }
    });
  }

  ngAfterViewInit(): void {
    this.loadAnalysisConfig();
  }

  private loadAnalysisConfig() {

    this.loaded = false;

    this.sec.getAnalysisActionID(this.id_analysis).subscribe(id => {
      if (this.formTemplate.validateAccess([id], 'dashboard')) {
        this.analysis.loadAnalysis(this.id_analysis);

        this.loaded = true;
      }
    });
  }


  onAnalysisTitle(title: string) {
    this.formTemplate.formTitle = "Análise / " + title;
  }

}
