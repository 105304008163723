import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { catchError, map, take } from "rxjs/Operators";
import { T2HttpClientService } from "../http/t2httpClient.service";
import { T2MessageService } from "../t2-message.service";
import { T2LocalStorageService } from "../t2local-storage.service";
import { MediaType } from "../http/t2RequestInfo";

@Injectable({
  providedIn: "root",
})
export class T2PrinterService {
  constructor(
    private httpClient: T2HttpClientService,
    private storage: T2LocalStorageService,
    private messageService: T2MessageService
  ) { }

  public getPrinterList() {
    return this.httpClient.get("core.printer/printerList", null).pipe(
      take(1),
      map((resp) => {
        let printerList = new Array<{
          clientName: string;
          printerList: Array<string>;
        }>();
        resp.printerList?.printer.forEach((printer) => {
          let clientArr = printerList.filter(
            (obj) => obj.clientName == printer.client
          );

          if (clientArr.length) {
            clientArr[0].printerList.push(printer.name);
          } else {
            printerList.push({
              clientName: printer.client,
              printerList: [printer.name],
            });
          }
        });

        return printerList;
      })
    );
  }

  public changeLabelPrinter(clientName: string, printerName: string) {
    this.storage.setData("labelPrinter$", JSON.stringify({ clientName: clientName, printerName: printerName }));
  }

  public getSelectedLabelPrinter(): { clientName: string, printerName: string } {
    let obj;
    try {
      obj = JSON.parse(this.storage.getData("labelPrinter$"));
    } catch (error) { } // não faz nada se der erro

    return obj;
  }

  // tslint:disable-next-line: variable-name
  public printReport(id_report: string, params: Map<string, string>) {
    let paramsStr;

    if (params) {
      paramsStr = new Array<string>();
      params.forEach((value, key) => {
        paramsStr.push(key + "=" + value);
      });

      paramsStr = paramsStr.join("&");
    } else {
      paramsStr = null;
    }

    let queryParams = new Map<string, string>();
    queryParams.set("params", paramsStr);

    this.getPrinterList().subscribe((printerArray) => {
      let selectedPrinter = this.getSelectedLabelPrinter();
      let printerOnline = printerArray.some((obj) => {
        return obj.clientName == selectedPrinter.clientName && obj.printerList.some(p => p == selectedPrinter.printerName)
      });

      if (printerOnline) {
        queryParams.set("printerClient", selectedPrinter.clientName);
        queryParams.set("printerName", selectedPrinter.printerName);
      } else {
        this.messageService.showToastError(
          "A impressora configurada não está online. Verifique se a mesma está ligada"
        );
        return;
      }

      this.httpClient.get(`core.report/printReport/${id_report}`, queryParams).subscribe(() => { });
    });
  }

  public printReportByUrl(reportUrl: string, description: string) {
    this.getPrinterList().subscribe((printerArray) => {
      let selectedPrinter = this.getSelectedLabelPrinter();
      let printerOnline = printerArray.some((obj) => {
        return obj.clientName == selectedPrinter.clientName && obj.printerList.some(p => p == selectedPrinter.printerName)
      });

      let bodyParams = {};

      if (printerOnline) {
        bodyParams["printerClient"] = selectedPrinter.clientName;
        bodyParams["printerName"] = selectedPrinter.printerName;
      } else {
        this.messageService.showToastError(
          "A impressora configurada não está online. Verifique se a mesma está ligada"
        );
        return;
      }

      if (description) {
        bodyParams["description"] = description;
      }

      bodyParams["url"] = reportUrl;

      this.httpClient.post("core.report/printReportByUrl", null, bodyParams).subscribe(() => { });
    });
  }

  // tslint:disable-next-line: variable-name
  public openReport(id_report: string, params: Map<string, string>): Observable<void> {
    const reportParams = [];
    params.forEach((value: string, key: string) =>
      reportParams.push(key + "=" + value)
    );

    const newParams = new Map<string, string>();
    newParams.set("params", reportParams.join(","));

    return this.httpClient.openPdfReport(
      this.httpClient.getBlob(
        `core.report/reportStream/${id_report}`,
        newParams,
        null,
        MediaType.PDF
      ).pipe(
        catchError(async (error, obs) => {
          let respUrl: string;
          await this.httpClient.get(`core.report/getReportUrl/${id_report}`, newParams)
            .pipe(take(1))
            .toPromise().then(resp => {
              console.log(resp);
              respUrl = resp.url;
              this.messageService.showToastError(resp.url);
            }, subError => {
              throw new Error(subError);
            })

          throw respUrl;
        })
      )
    );
  }
}
