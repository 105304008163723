import { T2InputIntegerComponent } from './../ui/t2-input-integer/t2-input-integer.component';
import { take } from 'rxjs/Operators';
import { pipe } from 'rxjs';
import { DialogComponent } from './../dialog/dialog.component';
import { ActionType } from 'src/app/core/cmp/view-template/model/t2-view-template-action';
import { Condition, InputType, T2ViewTemplateFlow } from 'src/app/core/cmp/view-template/model/t2-view-template-flow';
import { T2HttpClientService } from 'src/app/core/http/t2httpClient.service';
import { T2CheckBoxComponent } from 'src/app/core/cmp/ui/t2-check-box/t2-check-box.component';
import { T2SelectComponent } from 'src/app/core/cmp/ui/t2-select/t2-select.component';
import { DialogTemplateComponent } from 'src/app/core/cmp/dialog-template/dialog-template.component';
import { T2InputTextComponent } from './../ui/t2-input-text/t2-input-text.component';
import { Component, Input, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { LayoutType, ViewTemplateElement } from "src/app/core/cmp/view-template/model/view-template-element";
import { T2ViewTemplateData } from "../view-template/model/t2-view-template-data";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { T2MessageService } from "../../t2-message.service";
import { T2SecurityService } from "../../security/t2security.service";
import { T2AggregationComponent } from "../ui/t2-aggregation/t2-aggregation.component";
import { T2ViewTemplateAction } from "../view-template/model/t2-view-template-action";
import { NbDialogService } from "@nebular/theme";

@Component({
  selector: 'app-dialog-duplicate-nf',
  templateUrl: './dialog-duplicate-nf.component.html',
  styleUrls: ['./dialog-duplicate-nf.component.scss']
})
export class DialogDuplicateNFComponent implements OnInit {
  @ViewChild('dialog', { static: true }) dialog: DialogTemplateComponent;

  @Input() id_notafiscal: string;
  @Input() id: string;

  formGroup: FormGroup;
  saving: boolean = false;

  layout: Array<ViewTemplateElement> = [
    {
      layoutType: LayoutType.listLayout,
      direction: "column",
      children: [
        {
          layoutType: LayoutType.listLayout,
          title: "Origem",
          children: [{
            layoutType: LayoutType.component,
            title: 'Nota Fiscal de Origem',
            cmpName: 'nfOrigem',
            cmpType: T2AggregationComponent,
            isBaseComponent: true,
            width: "200px",
          },]

        },
        {
          layoutType: LayoutType.listLayout,
          title: "Destino",
          direction: "column",
          gap: "15px",
          children: [
            {
              layoutType: LayoutType.listLayout,
              gap: "10px",
              direction: "row wrap",
              children: [
                {
                  layoutType: LayoutType.component,
                  title: 'Número da Nota Fiscal',
                  cmpName: 'nfDestino',
                  cmpType: T2InputIntegerComponent,
                  width: "200px",
                  isBaseComponent: true,
                },
                {
                  layoutType: LayoutType.component,
                  title: 'Tipo da Nota Fiscal',
                  cmpName: 'tipoNF',
                  cmpType: T2SelectComponent,
                  width: "200px",
                  isBaseComponent: true,
                },
              ]
            },
            {
              layoutType: LayoutType.component,
              title: 'Unidade da Nota Fiscal',
              cmpName: 'companySiteNF',
              cmpType: T2SelectComponent,
              isBaseComponent: true,
            },
            {
              layoutType: LayoutType.listLayout,
              children: [
                {
                  layoutType: LayoutType.component,
                  title: 'Duplicar anexos',
                  cmpName: 'anexos',
                  cmpType: T2CheckBoxComponent,
                  isBaseComponent: true,
                },
                {
                  layoutType: LayoutType.listLayout,
                  direction: "column",
                  children: [
                    {
                      layoutType: LayoutType.component,
                      title: 'Criar referência para a nota original',
                      cmpName: 'referenciaNF',
                      cmpType: T2CheckBoxComponent,
                      isBaseComponent: true,
                    },
                    {
                      layoutType: LayoutType.component,
                      title: 'Marcar referência como operação triangular',
                      cmpName: 'opTriangular',
                      cmpType: T2CheckBoxComponent,
                      isBaseComponent: true,
                    },
                  ]
                }
              ]
            },
          ]
        },
      ]
    }
  ];

  data: Array<T2ViewTemplateData> = [
    { cmpName: "nfOrigem", properties: { datasetId: "{D6E4AD2C-6049-4025-9F33-591DD8812C3A}", datasetName: "fis_notafiscal" } },
    { cmpName: "tipoNF", properties: { list: [{ id: "Emitida", description: "Emitida" }, { id: "Recebida", description: "Recebida" }] } },
    { cmpName: "companySiteNF", properties: { list: this.loadCompanySiteList(), } }
  ];

  actions: Array<T2ViewTemplateAction> = [
    { type: ActionType.changeValue, methodName: "changeValue" }
  ]

  flows: Array<T2ViewTemplateFlow> = [
    {
      action: this.actions[0], inputs: [{ cmpName: "referenciaNF", type: InputType.component, paramName: "value", condition: Condition.denyValue }],
      triggerCmpNameList: ["referenciaNF"],
      outputs: [{ cmpName: "opTriangular", cmpInputName: "readOnly" }]
    }
  ]

  constructor(private formBuilder: FormBuilder, private messageService: T2MessageService, public sec: T2SecurityService, public httpService: T2HttpClientService, private dialogService: NbDialogService) {

  }

  ngOnInit(): void {
    this.formGroup = this.formBuilder.group({});
    this.formGroup.addControl('nfOrigem', this.formBuilder.control(this.id_notafiscal || this.id, Validators.required));
    this.formGroup.addControl('nfDestino', this.formBuilder.control(0, Validators.required));
    this.formGroup.addControl('tipoNF', this.formBuilder.control(undefined, Validators.required));
    this.formGroup.addControl('companySiteNF', this.formBuilder.control(this.httpService.id_companySite, Validators.required));
    this.formGroup.addControl('anexos', this.formBuilder.control(undefined));
    this.formGroup.addControl('referenciaNF', this.formBuilder.control(true));
    this.formGroup.addControl('opTriangular', this.formBuilder.control(undefined));

  }
  closeDialog(action: string) {
    if (action == "Confirma") {
      if (this.formGroup.invalid) {
        this.messageService.showToastError("As informações são inválidas");
        return;
      }
      this.duplicarNF(action);
    } else {
      this.dialog.close(undefined);
    }

  }

  duplicarNF(action: string) {
    let nfInfo = {
      id_notaFiscal: this.id_notafiscal || this.id,
      novoNumero: this.formGroup.controls['nfDestino'].value,
      anexos: this.formGroup.controls['anexos'].value,
      criarReferencia: this.formGroup.controls['referenciaNF'].value,
      operacaoTriangular: this.formGroup.controls['opTriangular'].value,
      tipoNFDestino: this.formGroup.controls['tipoNF'].value,
      id_companySite: this.formGroup.controls['companySiteNF'].value
    }

    this.saving = true;
    this.httpService.post('fiscal.invoice.utils/duplicateNF', null, nfInfo).pipe(take(1)).subscribe(notaFiscalNova => {
      if(!notaFiscalNova) return;
      this.saving = false;
      this.dialog.close(action);
      this.dialogService.open(DialogComponent,
        {
          context: {
            message: "Deseja abrir a nova nota fiscal?",
            actions: [{description: "Sim"}, {description: "Não", status: "basic"}]
          }
        }).onClose.pipe(take(1)).subscribe(resp => {
          if (resp == "Sim") {
            if (this.formGroup.controls['tipoNF'].value == 'Emitida') {
              this.abrirNFEmitida(notaFiscalNova.id_notaFiscal);
            } else if (this.formGroup.controls['tipoNF'].value == 'Recebida') {
              this.abrirNFRecebida(notaFiscalNova.id_notaFiscal);
            }
          }
        })
    }, error => {
      this.saving = false;
    });
  }

  abrirNFRecebida(id_notaFiscalNova: string) {
    window.open(`dataset/fis_notafiscal_rec/edit/${id_notaFiscalNova}`, '_blank');
  }

  abrirNFEmitida(id_notaFiscalNova: string) {
    window.open(`dataset/fis_notafiscal_emi/edit/${id_notaFiscalNova}`, '_blank');
  }

  loadCompanySiteList() {
    let companySiteList = new Array<{ id: string, description: string }>();
    this.sec.companySiteObservable().subscribe(id_companySite => {
      this.sec.sites.forEach(site => {
        let s = { id: site.id_companySite, description: site.description }
        companySiteList.push(s);
      });
    });

    return companySiteList;
  }

}
