import { DatePipe } from "@angular/common";
import { AfterViewInit, Component, ViewChild, ViewContainerRef } from '@angular/core';
import { FormControl } from "@angular/forms";
import { ICellEditorAngularComp } from "ag-grid-angular";

@Component({
  selector: 'app-date-time-editor',
  templateUrl: './date-time-editor.component.html',
  styleUrls: ['./date-time-editor.component.scss']
})
export class DateTimeEditorComponent implements ICellEditorAngularComp, AfterViewInit {

  @ViewChild('input', { read: ViewContainerRef }) public input: ViewContainerRef;

  inputControl = new FormControl();
  params: any;

  constructor() { }

  ngAfterViewInit(): void {
    // focus on the select
    setTimeout(() => this.input.element.nativeElement.focus());
  }

  agInit(params: any): void {
    this.params = params;
    this.inputControl.setValue(new Date(params.value || new Date() ));
  }

  getValue() {
    return new DatePipe("en-GB").transform(this.inputControl.value, "yyyy-MM-dd'T'HH:mm:ss");
  }

  // Gets called once before editing starts, to give editor a chance to
  // cancel the editing before it even starts.
  isCancelBeforeStart() {
    return false;
  }

  // Gets called once when editing is finished (eg if enter is pressed).
  // If you return true, then the result of the edit will be ignored.
  isCancelAfterEnd() {
    return false;
  }

  public onValueChange() {
    this.params.api.stopEditing();
  }

}
