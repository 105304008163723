import { Component, Input, OnInit, ElementRef, AfterViewInit, EventEmitter, Output, ViewChild } from '@angular/core';
import { take } from "rxjs/Operators";
import { T2MessageService } from "src/app/core/t2-message.service";
import { T2BaseComponent } from "../t2-base-component";
import { T2Status } from "./model/t2-status";
import { T2DatasetService } from "src/app/core/dataset/t2dataset.service";
import { DatasetPropertyStatusItem } from "src/app/core/dataset/model/dataset-property-status-item";
import { DialogTemplateComponent } from "../../dialog-template/dialog-template.component";
import { T2SecurityService } from "src/app/core/security/t2security.service";

@Component({
  selector: 'app-t2-status',
  templateUrl: './t2-status.component.html',
  styleUrls: ['./t2-status.component.scss'],
  host: {
    '(document:click)': 'onClick($event)',
  }
})

export class T2StatusComponent extends T2BaseComponent implements OnInit, AfterViewInit {

  @ViewChild('dialog') dialog: DialogTemplateComponent;

  @Input() minWidth: boolean = true;
  @Input() datasetName: string;
  @Input() informationId: string;
  @Input() isNewRecord: boolean;
  @Input() saveData: (params: { cmpReference: any, data?: any }, reloadData: boolean) => Promise<any>;
  @Input() paramsSaveData: { cmpReference: any, data?: any };
  @Input() displayOnly: boolean = false;
  @Input() statusDescription: string;
  @Input() statusColorStyle: string;
  @Input() showOptionsForDialog: boolean = false;
  @Output() statusChanged = new EventEmitter<{status: T2Status, reloadData: boolean}>();

  private statusList = new Array<T2Status>();
  public statusFlow = new Array<T2Status>();
  public currentStatus: T2Status;
  public saving: boolean = false;
  public showingOptions = false;

  constructor(private _eref: ElementRef, private messageService: T2MessageService,
    private dsService: T2DatasetService, private secService: T2SecurityService) {
    super();
  }

  ngOnInit(): void {
    this.setDefaultValues();
  }

  ngAfterViewInit(): void {
    if (this.datasetName && this.informationId) {
      this.dsService.getStatusList(this.datasetName).pipe(take(1)).subscribe(sList => {
        sList.forEach((status: DatasetPropertyStatusItem) => {
          this.statusList.push({
            name: status.description,
            action: status.execDescription,
            tooltip: status.helpText,
            colorStyle: status.statusColorStyle,
            blockChange: status.blockChanges,
            isDefaultValue: status.isDefaultValue
          });
        });

        if (this.isNewRecord) {
          this.currentStatus = this.statusList.find(s => s.isDefaultValue);
          this.initialConfiguration();
        } else {
          this.dsService.getPropertyValue(this.datasetName, this.informationId, "status")
            .pipe(take(1))
            .subscribe(status => {
              this.currentStatus = this.statusList.find(s => s.name == status);

              if (!this.currentStatus) {
                this.statusDescription = status;
                this.displayOnly = true;
              } else {
                this.initialConfiguration();
              }
            })
        }
      });
    }
  }

  private initialConfiguration() {
    this.statusChanged.emit({status: this.currentStatus, reloadData: false});

    if (this.formGroup.controls[this.t2IdCmp] && !this.formGroup.controls[this.t2IdCmp]?.value && this.currentStatus) {
      this.formGroup.controls[this.t2IdCmp].setValue(this.currentStatus.name);
    }

    if (this.saveData && !this.paramsSaveData) {
      let message = "Ao informar o método 'saveData' é obrigatório informar também o input 'paramsSaveData' !!";
      this.messageService.showToastError(message);
      console.error(message);
    } else {
      this.updateStatusFlow();
    }
  }

  private updateStatusFlow() {
    if (this.currentStatus) {
      this.dsService.getNextStatusFlow(this.datasetName, this.currentStatus.name).pipe(take(1)).subscribe(flowList => {
        this.secService.accessLoaded().pipe(take(1)).subscribe(resp => {
          flowList = flowList?.filter(flow => !flow.id_action || this.secService.hasAction(flow.id_action));
          this.statusFlow = this.statusList.filter(status => flowList?.map(flow => flow.status).includes(status.name)).map(stat => {
            let flow = flowList?.find(f => f.status == stat.name);
            return {
              name: stat.name,
              action: stat.action,
              tooltip: stat.tooltip,
              colorStyle: stat.colorStyle,
              blockChange: stat.blockChange,
              questionMsg: flow.questionMsg,
              showQuestionMsg: flow.showQuestionMsg
            }
          })
        })
      });
    }
  }

  onClick(event) {
    if (!this._eref.nativeElement.contains(event.target))
      this.showingOptions = false;
  }

  statusClick() {
    this.showingOptions = !this.showingOptions;
  }

  selectStatus(status: T2Status) {
    if (status.showQuestionMsg) {
      this.messageService.showDialog({
        context: {
          title: "Alteração de Status",
          message: status.questionMsg,
          actions: [{ description: "Sim" }, { description: "Não", status: "basic" }]
        }
      }).onClose.pipe(take(1)).subscribe(resp => {
        if (resp == "Sim") {
          this.changeStatus(status);
        }
      })
    } else {
      this.changeStatus(status);
    }
  }

  private changeStatus(status: T2Status) {
    this.showingOptions = false;
    this.saving = true;

    if (this.saveData) {
      this.saveData(this.paramsSaveData, false).then(() => {
        this.saveStatusChange(status);
      }, error => {
        this.saving = false;
      })
    } else {
      this.saveStatusChange(status);
    }
  }

  private saveStatusChange(status: T2Status) {
    this.dsService.changeDatasetPropertyValue(this.datasetName, this.informationId, "status", status.name).pipe(take(1)).subscribe(() => {
      this.currentStatus = status;
      this.formGroup.controls[this.t2IdCmp].setValue(status.name);
      this.formGroup.markAsPristine();
      this.updateStatusFlow();
      this.statusChanged.emit({status: this.currentStatus, reloadData: true});

      if (this.showOptionsForDialog) {
        this.dialog.close(status);
      }

      this.saving = false;
    }, error => {
      this.saving = false;
      this.messageService.showDialog({
        context: {
          topMessage: "Ocorreu um erro ao alterar o status",
          message: error.errorMessage || error.message,
          actions: [{ description: "OK", status: "primary" }]
        }
      })
    });
  }
}
