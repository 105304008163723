import { Component, Inject, Input, LOCALE_ID, OnDestroy, ViewChild } from '@angular/core';
import { DeviceOperationInput } from "../../model/device-operation-input";
import { DeviceTaskPlan, ShopFloorService, VolumeProduced } from "../../shop-floor.service";
import { T2InputFloatComponent } from "src/app/core/cmp/ui/t2-input-float/t2-input-float.component";
import { FormBuilder, FormGroup } from "@angular/forms";
import { Subject } from "rxjs";
import { DeviceRegistrationInputType } from "../model/device-registration-input-type";
import { DeviceRegistrationInputTypeVolume } from "../model/device-registration-input-type-volume";
import { T2UserDeviceService } from "src/app/t2-user-device.service";
import { take, takeUntil } from "rxjs/Operators";
import { T2MessageService } from "src/app/core/t2-message.service";

@Component({
  selector: 'app-appointment-register-production-reverse',
  templateUrl: './appointment-register-production-reverse.component.html',
  styleUrls: ['./appointment-register-production-reverse.component.scss']
})
export class AppointmentRegisterProductionReverseComponent implements OnDestroy {
  @ViewChild('qtyInput') qtyInput: T2InputFloatComponent;

  @Input()
  get taskPlan(): DeviceTaskPlan {
    return this._taskPlan
  }
  set taskPlan(value: DeviceTaskPlan) {
    this._taskPlan = value;
    this.loadInfo();
  }

  @Input()
  get inputList(): Array<DeviceOperationInput> {
    return this._inputList;
  }
  set inputList(value: Array<DeviceOperationInput>) {
    this._inputList = value;
    this.loadInfo();
  }

  private _inputList: Array<DeviceOperationInput>;
  private _taskPlan: DeviceTaskPlan;
  private unsubscribe = new Subject<void>();
  private unsubscribeAutoInput = new Subject<void>();
  private reverseInput: DeviceOperationInput;
  private inputVol = new DeviceRegistrationInputTypeVolume();
  public loaded: boolean = false;
  public formGroup: FormGroup;
  public volumeList: Array<VolumeProduced>;
  public devRegInput = new DeviceRegistrationInputType();
  public itemDescription: string;

  constructor(public userDevService: T2UserDeviceService, private formBuilder: FormBuilder, private sfService: ShopFloorService,
    @Inject(LOCALE_ID) public locale: string, private messageService: T2MessageService) {
    this.formGroup = this.formBuilder.group({});
    this.formGroup.addControl("volume", this.formBuilder.control(undefined));
    this.formGroup.addControl("local", this.formBuilder.control(undefined));
    this.formGroup.addControl("quantidade", this.formBuilder.control(0));

    this.devRegInput.volumes = [];
    this.devRegInput.volumes.push(this.inputVol);

    this.formGroup.controls["quantidade"].valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe(qty => {
      this.devRegInput.value = qty;
      this.inputVol.quantity = qty;
    })
  }
  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
    this.unsubscribeAutoInput.next();
    this.unsubscribeAutoInput.complete();
  }

  private loadInfo() {
    if (this.taskPlan && this.inputList) {
      this.reverseInput = this.inputList.find(inp => inp.functionality == "ProductionReturn");
      this.unsubscribeAutoInput.next();

      if (this.reverseInput.autoInputId) {
        this.sfService.getAutoDataObservable(this.reverseInput.autoInputId).pipe(takeUntil(this.unsubscribeAutoInput)).subscribe(value => {
          this.formGroup.controls["quantidade"].setValue(Number(Number(value).toFixed(this.reverseInput.decimals)));
        })
      }

      this.sfService.getProducedVolumes(this.taskPlan.taskPlanId).pipe(take(1)).subscribe(volList => {
        this.volumeList = volList;
        this.loaded = true;

        setTimeout(() => {
          this.qtyInput.decimalQty = this.reverseInput.decimals;
        }, 100);
      }, error => {
        this.loaded = true;
      })
    }
  }

  public searchVolume() {
    let volumeCode = this.formGroup.controls["volume"].value;

    if (!volumeCode) {
      this.messageService.showToast("Informe um código de volume para realizar a busca", "Aviso", "warning");
      return;
    }

    let selectedVol = this.volumeList.find(vol => vol.code == volumeCode);

    if (!selectedVol) {
      this.messageService.showToast("Nenhum volume produzido com o código informado !!", "Aviso", "warning");
    } else {
      let cmpProd = this.taskPlan.cmpProdList.find(cmp => cmp.itemId == selectedVol.itemId);

      this.itemDescription = cmpProd.descriptionCmp;

      this.devRegInput.id_component = cmpProd.prodOrderCmpId;
      this.devRegInput.id_operationGroup_it = this.reverseInput.id_operationGroup_it;
      this.devRegInput.id_operationGroup_op_it = this.reverseInput.id_operationGroup_op_it;
      this.devRegInput.id_item = cmpProd.itemId;

      this.inputVol.id_volume = selectedVol.volumeId;
      this.inputVol.id_stockAddress_destination = this.reverseInput.id_operation_stockAddress_destination;
    }
  }

  selectVolume(volume: VolumeProduced) {
    this.formGroup.controls["volume"].setValue(volume.code);
    this.searchVolume();
  }
}
