import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { T2AutoFormComponent } from "src/app/core/form/t2-auto-form/t2-auto-form.component";

@Component({
  selector: 'app-time-reservation',
  templateUrl: './time-reservation.component.html',
  styleUrls: ['./time-reservation.component.scss']
})
export class TimeReservationComponent implements OnInit {
  @ViewChild('autoform', {static: true}) autoform: T2AutoFormComponent;

  @Input() insert: boolean;
  @Input() informationId: string;
  @Output() callBack = new EventEmitter<string>();
  public waiting: boolean = false;

  constructor() { }

  ngOnInit(): void {
    this.autoform.insert = this.insert;
    this.autoform.edit = !this.insert;
    this.autoform.informationId = this.informationId;
  }

  runCallback(informationId: any) {
    this.callBack.emit(informationId);
  }

  delete(hasDeleted: boolean) {
    if (hasDeleted) {
      this.callBack.emit(this.informationId);
    } else {
      this.waiting = false;
    }
  }

  startedOperation() {
    this.waiting = true;
  }

  errorInOperation() {
    this.waiting = false;
  }

}
