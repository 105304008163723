import { Component, OnInit, Input } from '@angular/core';
import { DatasetStatus } from "../model/datasetStatus";
import { FilterField } from './model/filterField';

@Component({
  selector: 'app-filter-status',
  templateUrl: './filter-status.component.html',
  styleUrls: ['./filterTheme.scss']
})
export class FilterStatusComponent implements OnInit {

  @Input() statusList: Array<DatasetStatus>;
  @Input() filterField: FilterField;

  constructor() { }

  ngOnInit(): void {
  }

  onStatusSelect(event) {
    // console.log(this.filterField.statusSelected);
  }

}
