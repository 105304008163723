<app-dialog-template #dialog title="Prazo Firme">
  <content class="flexColumn" style="gap: 10px;">
    <div>
      <nb-radio-group [(ngModel)]="option">
        <nb-radio value="1">Sem prazo firme</nb-radio>
        <nb-radio value="2">Com prazo firme</nb-radio>
      </nb-radio-group>
    </div>
    <div *ngIf="option == '2'" style="display: flex; gap: 10px">
      <span>Prazo Firme (dias)</span>
      <app-t2-input-integer t2IdCmp="firmDeadline" [visible]="true" [readOnly]="false"
        [placeHolder]="''" [formGroup]="_fg"></app-t2-input-integer>
    </div>
  </content>
  <footer>
    <div class="wFooter">
      <div class="button-row" style="display: flex;">
        <button nbButton status="danger" size="tiny" (click)="closeDialog('Cancela')"
          style="margin-left: auto">Cancela</button>
        <button nbButton status="primary" size="tiny" (click)="closeDialog('Confirma')">Confirma</button>
      </div>
    </div>
  </footer>
</app-dialog-template>
