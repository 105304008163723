<div class="status" [ngClass]="{'taskStatusGreen': started , t2ColorGray: !started}">

  <div style="width: 100%; text-align: center;">
    {{started ? 'Iniciada' : suspended? 'Suspensa': 'Não Iniciada'}}
  </div>

  <div *ngIf="started && progress >= 0" class="progressBar">
    {{progress | number: '1.0-0'}}%
  </div>

</div>