<div class="flexColumn" style="height: 100%; width: 100%; gap: 10px; padding: 5px;"
  [nbSpinner]="loading">
  <div style="display: flex; justify-content: space-between; align-items: center; ">
    <app-shop-floor-sequencing-filter></app-shop-floor-sequencing-filter>
    <div class="sequenciamento">Sequenciamento</div>
  </div>

  <app-sequencing-cards style="overflow-y: auto; width: 100%; height: 100%;" *ngIf="smallWindow"
    [taskSequenceList]="filteredTaskSequenceList" (swapDevice)="swapTaskPlanDevice($event)" [loading]="loading" (taskPlanSelected)="selectTaskPlan($event)" />

  <app-sequencing-grid style="width: 100%; height: 100%;" *ngIf="!smallWindow"
    [taskSequenceList]="filteredTaskSequenceList" [deviceId]="id_device" [loading]="loading" (taskPlanSelected)="selectTaskPlan($event)" />

</div>
