<div [ngClass]="{'stockStatus': true,
                'stockStatusBlue': mpSeparate,
                'stockStatusYellow': !mpSeparate && stockAvailable,
                'stockStatusRed': !mpSeparate && !stockAvailable,
                'stockStatusNoReq': !hasRequisition}">
  <span>
    {{!hasRequisition ? 'Sem requisições': mpSeparate ? 'Separado': !mpSeparate && stockAvailable ? 'Não separada':
    !mpSeparate && !stockAvailable ? 'Sem estoque' :''}}
  </span>
  <div *ngIf="percSeparation > 0">{{percSeparation | number: '1.0-2'}}%</div>
</div>