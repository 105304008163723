<app-dialog-template #dialog title="Reserva" [ngStyle]="{'height.vh': 70, 'width.vw': 80}"
  [nbSpinner]="lockScreen || loadingRequisitions" nbSpinnerStatus="primary" nbSpinnerSize="giant"
  [ngClass]="{'waiting': lockScreen}">
  <content class="flexColumn flexLength1" style="gap: 3px; height: 100%;">
    <div style="display: flex; justify-content: space-between;">
      <span class="defaultTitle">{{itemDescription}}</span>
      <div style="display: flex; justify-content: flex-end; align-items: center;">
        <button nbButton size="tiny" status="primary" [disabled]="!volumeList || !volumeList.length"
          (click)="autoReservation()">Distribuir Quantidade</button>
      </div>
    </div>
    <div *ngIf="!loadingRequisitions" style="display: flex; gap: 10px; height: calc(100% - 15px);">
      <div class="flexColumn flexGap5" style="overflow-y: auto; flex: 40%">
        <span class="defaultSmallTitle" style="padding-left: 5px;">Requisições</span>
        <div #divFoco *ngFor="let req of requisitionList"
          class="T2GrayHoverArea T2GrayAreaItemNoFill flexColumn requisition"
          [ngClass]="{'T2GraySelectedArea':  req.requisitionId == selectedReq?.requisitionId}"
          (click)="selectRequisition(req)" [id]="req.requisitionId">
          <div style="display: flex; justify-content: space-between; align-items: center;">
            <span class="defaultSmallTitle">{{req.code}}</span>
            <span class="defaultSmallTitle">{{req.detail}}</span>
          </div>
          <div style="display: flex; justify-content: space-between; align-items: center; gap: 5px">
            <div class="flexColumn">
              <span class="cmpLabel">Dt Necessário</span>
              <app-t2-date [formGroup]="formGroup" [t2IdCmp]="req.requisitionId + '_requiredDate'"
                [ngStyle]="{'background-color': 'red', 'pointer-events': 'none', 'color': 'white'}"></app-t2-date>
            </div>
            <div class="flexColumn">
              <span class="cmpLabel">Qtd Pendente</span>
              <app-t2-input-float [formGroup]="formGroup" [t2IdCmp]="req.requisitionId + '_pendingQty'"
                [ngStyle]="{'background-color': 'red', 'pointer-events': 'none', 'color': 'white'}">
              </app-t2-input-float>
            </div>
            <div class="flexColumn">
              <span class="cmpLabel">Qtd Reservada</span>
              <app-t2-input-float [formGroup]="formGroup" [t2IdCmp]="req.requisitionId + '_reservedQty'"
                [ngStyle]="{'background-color': 'green', 'pointer-events': 'none', 'color': 'white'}">
              </app-t2-input-float>
            </div>
          </div>
        </div>
      </div>
      <div class="T2GrayArea flexColumn" style="padding: 5px; overflow-y: auto; flex: 60%"
        [nbSpinner]="loadingVolumes" nbSpinnerStatus="primary" nbSpinnerSize="giant">
        <span class="defaultSmallTitle" style="padding-left: 5px;">Volumes</span>
        <div class="T2GrayArea spacing" style="width: 100%; margin-bottom: 5px; overflow: initial;" *ngFor="let vol of volumeList">
          <div class="flexRow">
            <div class="flexColumn" style="flex: 40%">
              <span class="defaultSmallTitle">{{vol.volumeCode}}</span>
              <span class="defaultText">{{vol.stockAddressCode + ' ' + vol.stockAddress}}</span>
            </div>
            <div class="flexColumn flexGap5" style="flex: 60%">
              <div style="display: flex; gap: 5px;">
                <div class="flexColumn">
                  <span class="cmpLabel">Qtd Física</span>
                  <app-t2-input-float [formGroup]="formGroupVol" [t2IdCmp]="vol.id_volume + '_quantityStock'"
                    [ngStyle]="{'background-color': 'green', 'pointer-events': 'none', 'color': 'white'}">
                  </app-t2-input-float>
                </div>
                <div class="flexColumn">
                  <span class="cmpLabel">Qtd Reservada</span>
                  <app-t2-input-float [formGroup]="formGroupVol" [t2IdCmp]="vol.id_volume + '_quantityReserved'"
                    [ngStyle]="{'background-color': 'green', 'pointer-events': 'none', 'color': 'white'}">
                  </app-t2-input-float>
                </div>
                <div class="flexColumn">
                  <span class="cmpLabel">Qtd Disponível</span>
                  <app-t2-input-float [formGroup]="formGroupVol" [t2IdCmp]="vol.id_volume + '_quantityAvailable'"
                    [ngStyle]="{'background-color': 'green', 'pointer-events': 'none', 'color': 'white'}">
                  </app-t2-input-float>
                </div>
              </div>
              <div class="flexAlignRowEndCenter">
                <div style="max-width: 120px;">
                  <span class="cmpLabel">Qtd a Reservar</span>
                  <app-t2-input-float [t2IdCmp]="vol.id_volume" [formGroup]="formGroupVol">
                  </app-t2-input-float>
                </div>
              </div>
              <div style="margin-right: 10px; color: red" class="flexAlignRowEndCenter"
                *ngIf="formGroupVol.controls[vol.id_volume].invalid">
                Valor inválido
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </content>
  <footer>
    <div class="flexAlignRowEndCenter">
      <app-t2-button status="basic" size="tiny" value="Fechar" (clicked)="closeDialog()"></app-t2-button>
    </div>
  </footer>
</app-dialog-template>
