import { FlexLayoutModule } from '@angular/flex-layout';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VolumeUnlockComponent } from './volume/volume-unlock/volume-unlock.component';
import { T2CoreModule } from "src/app/core/t2core.module";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { T2NebularModule } from "src/app/t2nebular.module";
import { PackingComponent } from './packing/packing.component';
import { PackingStageComponent } from './packing/packing-stage/packing-stage.component';
import { DialogInPackingVolumesComponent } from './packing/dialog-in-packing-volumes/dialog-in-packing-volumes.component';
import { DialogFinishedReprovesPackingComponent } from './packing/dialog-finished-reproves-packing/dialog-finished-reproves-packing.component';
import { DialogFinishedReprovesSeparationComponent } from './packing/dialog-finished-reproves-separation/dialog-finished-reproves-separation.component';
import { DepartmentalRequisitionComponent } from './departmental-requisition/departmental-requisition.component';
import { EditRequisitionComponent } from './departmental-requisition/edit-requisition/edit-requisition.component';
import { ReservationRequisitionComponent } from './reservation-requisition/reservation-requisition.component';
import { StockOperationComponent } from './stock-operation/stock-operation.component';
import { StockDetailComponent } from './stock-detail/stock-detail.component';
import { ComponentService } from "src/app/core/cmp/component.service";
import { StockDetailDialogComponent } from './stock-detail/stock-detail-dialog/stock-detail-dialog.component';
import { StockDetailFormComponent } from './stock-detail/stock-detail-form/stock-detail-form.component';
import { OperationTypeCellRendererComponent } from './stock-detail/operation-type-cell-renderer/operation-type-cell-renderer.component';



@NgModule({
  declarations: [VolumeUnlockComponent,  PackingComponent, PackingStageComponent, DialogInPackingVolumesComponent, DialogFinishedReprovesPackingComponent, DialogFinishedReprovesSeparationComponent, DepartmentalRequisitionComponent, EditRequisitionComponent, ReservationRequisitionComponent, StockOperationComponent, StockDetailComponent, StockDetailDialogComponent, StockDetailFormComponent, OperationTypeCellRendererComponent],
  imports: [
    CommonModule,

    T2NebularModule,
    T2CoreModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule
  ]
})
export class StockModule {
  constructor(private cmpService: ComponentService) {
    cmpService.registerType("StockDetailDialogComponent", StockDetailDialogComponent);
    cmpService.registerType("StockDetailComponent", StockDetailComponent);
    cmpService.registerType("EditRequisitionComponent", EditRequisitionComponent);
  }
}
