import { Injectable, OnDestroy, Type } from '@angular/core';
import { Observable } from "rxjs";
import { map, take } from "rxjs/Operators";
import { T2HttpClientService } from "src/app/core/http/t2httpClient.service";
import { EPPStructureLayerComponent } from "../../flx/epp/eppstructure-layer/eppstructure-layer.component";
import { CharacteristicComponent } from "./characteristic-component";
import { CharacteristicInfo } from "./model/characteristic-info";
import { ProcessOption } from "./model/process-option";
import { ProductSpecification } from "./model/product-specification";
import { DimensionComponent } from "./dimension/dimension.component";

@Injectable({
  providedIn: 'root'
})
export class EPService implements OnDestroy {

  private characteristicMap = new Map<string, ICharacCmp>();
  private characteristicList = new Array<CharacteristicInfo>();
  private productSpecification = new Array<ProductSpecification>();
  private characteristicComponentList = new Array<any>();

  constructor(private httpClient: T2HttpClientService) {
    this.characteristicMap.set("flx_especif_carac_estr", { cmpType: EPPStructureLayerComponent, description: "Estrutura", sortOrder: 0 });
    this.characteristicMap.set("flx_especif_carac_quimico", { cmpType: EPPStructureLayerComponent, description: "Estrutura", sortOrder: 0 });
    this.characteristicMap.set("flx_especif_carac_lamSeq", { cmpType: EPPStructureLayerComponent, description: "Estrutura", sortOrder: 0 });
    this.characteristicMap.set("epp_especif_carac_dimensao", { cmpType: DimensionComponent, description: "Dimensões", sortOrder: 1 });
    this.characteristicMap.set("flx_especif_carac_prodPasso", { cmpType: DimensionComponent, description: "Dimensões", sortOrder: 1 });
    this.characteristicMap.set("flx_especif_carac_embobinament", { cmpType: DimensionComponent, description: "Dimensões", sortOrder: 1 });
    this.characteristicMap.set("flx_especif_carac_mont_prd", { cmpType: DimensionComponent, description: "Dimensões", sortOrder: 1 });
    this.characteristicMap.set("flx_especif_carac_mont_cj", { cmpType: DimensionComponent, description: "Dimensões", sortOrder: 1 });
  }

  ngOnDestroy(): void {
  }

  getPSCharacStructure(id_specification: string, excludeWithCustomCmp?: boolean): Observable<{ characteristicList: Array<CharacteristicInfo>, processOptionList: Array<ProcessOption> }> {
    let params = new Map<string, string>();
    params.set("id_specification", id_specification);
    return this.httpClient.get("bsn.epp/psCharacStructure", params)
      .pipe(
        take(1),
        map(resp => {
          let characList = new Array<CharacteristicInfo>(), processList = new Array<ProcessOption>();
          if (resp?.struct) {
            if (!Array.isArray(resp.struct)) resp.struct = [resp.struct];

            characList = resp.struct;
            this.characteristicList = characList;

            characList.filter(c => c.id_especif_componente_prc).forEach(c => {
              let exist = processList.some(po => po.id_especif_componente_prc == c.id_especif_componente_prc);

              if (!exist) {
                processList.push({
                  id_especif_componente_prc: c.id_especif_componente_prc,
                  name: c.processName,
                  default: c.defaultProcess
                })
              }
            });

            characList.filter(c => this.characHasCustomCmp(c.datasetName)).forEach(charac => {
              charac.hasCustomCmp = true;
            })

            if (excludeWithCustomCmp) {
              characList = characList.filter(c => !this.characHasCustomCmp(c.datasetName))
            }
          }

          return {
            characteristicList: characList,
            processOptionList: processList
          };
        })
      );
  }

  

  getProductSpecification(id_specification: string): Observable<Array<ProductSpecification>> {
    let params = new Map<string, string>();
    params.set("id_specification", id_specification);

    return this.httpClient.get("bsn.epp/getEP", params)
      .pipe(
        take(1),
        map(resp => {
          let psList = new Array<ProductSpecification>();
          if (resp && resp.epList) {
            if (!Array.isArray(resp.epList)) {
              resp.epList = [resp.epList];
            }

            resp.epList.forEach(ep => {
              let ps = new ProductSpecification();
              ps.id_specification = ep.id_especificacao;
              ps.psCode = ep.codigoEp;
              ps.id_specif_component = ep.id_especif_componente;
              ps.id_structure = ep.id_estrutura;
              ps.description = ep.descricao;
              ps.descriptionItem = ep.descricaoProduto;
              ps.status = ep.status;
              ps.specificationType = ep.tipoEspecificao;
              ps.code = ep.codigo;

              switch (ps.specificationType) {
                case "P": {
                  ps.datasetName = "stk_item_produto_versao";
                  ps.datasetId = "{46CEEDC6-3B5B-4486-828F-54A4EA91FD39}"
                  break;
                }
                case "M": {
                  ps.datasetName = "epp_modelo";
                  ps.datasetId = "{FF74C2B0-D703-45D7-B465-5FAEA6F1B6E8}"
                  break;
                }
                case "CONJ": {
                  ps.datasetName = "stk_conjugado";
                  ps.datasetId = "}D20151008H130433256R000000001";
                  break;
                }
                case "C": {
                  ps.datasetName = "ctv_cotacao_item_carac_versao";
                  ps.datasetId = "{810CE805-737E-4704-976C-BCA9AF3631E4}";
                  break;
                }
              }

              psList.push(ps);
            })

            this.productSpecification = psList;
            return psList;
          }

          return []
        })
      )
  }

  deleteCharacteristic(id_especif_carac: string): Observable<any> {
    let params = new Map<string, string>();
    params.set("id_especif_carac", id_especif_carac);

    return this.httpClient.get("bsn.epp/deleteCharacteristic", params).pipe(take(1));
  }

  addCharacteristic(charac: CharacteristicInfo): Observable<CharacteristicInfo> {
    let params = new Map<string, string>();

    params.set("datasetName", charac.datasetName);
    params.set("id_especif_componente", charac.id_especif_componente);
    params.set("id_tipoEspecif_cmp_carac", charac.id_tipoEspecif_cmp_carac);

    if (charac.id_especif_componente_prc) {
      params.set("id_especif_componente_prc", charac.id_especif_componente_prc);
    }

    return this.httpClient.get("bsn.epp/addCharacteristic", params).pipe(take(1), map(resp => {
      if (resp.characteristic) {
        let respCharac: CharacteristicInfo = resp.characteristic;
        charac.id_especif_carac = respCharac.id_especif_carac;
        charac.id_especif_cmp_prd_carac = respCharac.id_especif_cmp_prd_carac;
      }

      return charac;
    }))
  }

  characHasCustomCmp(datasetName: string): boolean {
    return this.characteristicMap.has(datasetName);
  }

  getUniqueCmpList(datasetNameList: Array<string>): Array<ICharacCmp> {
    let uniqueList = new Array<ICharacCmp>();

    datasetNameList.forEach(dsName => {
      if (this.characteristicMap.has(dsName)) {
        let itemMap = this.characteristicMap.get(dsName);
        if (!uniqueList.some(item => item.description == itemMap.description)) {
          uniqueList.push(itemMap);
        }
      }
    });

    return uniqueList;
  }

  getCharacNameListByComponentType(cmpTypeDescription: string): Array<string> {
    return [...this.characteristicMap].filter(([key, value]) => {
      return value.description == cmpTypeDescription;
    }).map(([key, value]) => key);
  }

  getFullCharacList(): Array<CharacteristicInfo> {
    return this.characteristicList;
  }

  getPSDescription(): string {
    return this.productSpecification[0]?.description;
  }

  getPsByProduct(itemId: string): Observable<Array<ProductSpecificationSelection>> {
    let params = new Map<string, string>();
    params.set("id_item", itemId);

    return this.httpClient.get("bsn.epp/getPsByProduct", params).pipe(take(1), map(resp => {
      let psList = new Array<ProductSpecificationSelection>();

      if (resp.psList) {
        if (!Array.isArray(resp.psList)) {
          resp.psList = [resp.psList];
        }

        psList = resp.psList;
      }

      return psList;
    }))
  }

  insertProductSpecificationByType(itemId: string, specifTypeId: string) {
    let params = new Map<string, string>();
    params.set("id_item", itemId);
    params.set("id_psType", specifTypeId);

    return this.insertProductSpecification(params);
  }

  copyProdutctSpecification(specificationId: string, destinationItemId: string) {
    let params = new Map<string, string>();
    params.set("id_especificacao_original", specificationId);
    params.set("id_item_destino", destinationItemId);

    return this.copyPS(params);
  }

  insertProductSpecificationByModel(itemId: string, modelId: string, version?: string, description?: string) {
    let params = new Map<string, string>();
    params.set("id_item", itemId);
    params.set("id_psModel", modelId);

    if (version)
      params.set("code", version);

    if (description)
      params.set("description", description);

    return this.insertProductSpecification(params);
  }

  private insertProductSpecification(params: Map<string, string>): Observable<string> {
    return this.httpClient.get("bsn.epp/createPS", params).pipe(take(1), map(resp => {
      return resp.id_specification;
    }));
  }

  private copyPS(params: Map<string, string>) {
    return this.httpClient.get("bsn.epp/createPsCopy", params).pipe(take(1), map(resp => {
      return resp.id_specification;
    }));
  }

  addComponentRef(cmpRef) {
    this.characteristicComponentList.push(cmpRef);
  }

  getCharacComponentList() {
    return this.characteristicComponentList;
  }

  insertModel(specifTypeId: string, description: string, structureId?: string) {
    let params = new Map<string, string>();
    params.set("id_specifType", specifTypeId);
    params.set("description", description);

    if (structureId)
      params.set("id_structure", structureId);

    return this.httpClient.get("bsn.epp/createPSModel", params).pipe(take(1), map(resp => {
      return resp.id_specification;
    }));
  }

  copyModel(specificationId: string) {
    let params = new Map<string, string>();
    params.set("id_especificacao_original", specificationId);

    return this.httpClient.get("bsn.epp/createPsModelCopy", params).pipe(take(1), map(resp => {
      return resp.id_specification;
    }));
  }

}

export interface ICharacCmp {
  cmpType: Type<CharacteristicComponent>;
  description: string;
  sortOrder: number
}

export class ProductSpecificationSelection {
  productSpecificationId: string;
  code: string;
  description: string;
}
