import { getLocaleNumberSymbol, NumberSymbol } from "@angular/common";
import { Component, Inject, Input, LOCALE_ID, OnInit } from '@angular/core';
import { T2BaseComponent } from "../t2-base-component";

@Component({
  selector: 'app-t2-input-float',
  templateUrl: './t2-input-float.component.html',
  styleUrls: ['./t2-input-float.component.scss']
})
export class T2InputFloatComponent extends T2BaseComponent implements OnInit {

  @Input() 
  get decimalQty(): number {
    return this._decimalQty;
  }
  set decimalQty(value: number) {
    this._decimalQty = value ?? 4;
    let decimalSeparator = getLocaleNumberSymbol(this.locale, NumberSymbol.Decimal);
    this.pattern = new RegExp(`^-?[0-9]{0,12}([${decimalSeparator}][0-9]{0,${this.decimalQty}})?$`);
  }
  
  private pattern: RegExp;
  private _decimalQty: number = 4;

  constructor(@Inject(LOCALE_ID) public locale) {
    super();
    let decimalSeparator = getLocaleNumberSymbol(this.locale, NumberSymbol.Decimal);
    this.pattern = new RegExp(`^-?[0-9]{0,12}([${decimalSeparator}][0-9]{0,${this.decimalQty}})?$`);
  }

  ngOnInit(): void {
    this.setDefaultValues();
  }

  onKeyPress(event: any) {
    let decimalSeparator = getLocaleNumberSymbol(this.locale, NumberSymbol.Decimal);
    const previousValue = decimalSeparator == "." ? event.srcElement.value : event.srcElement.value.replace(".", ",");;

    const inputChar = event.key;

    if (!this.pattern.test(previousValue + inputChar)) {
      event.preventDefault();
    }
  }

  onEnter(event) {
    event.target.select();
  }
}
