<app-dialog-template #dialog title="Troca de cilindros entre as cores">
  <content style="width: 50vw;">
    <app-view-template #viewTemplate [FormGroup]="formGroup" [layout]="layout" />
  </content>
  <footer>
    <div class="wFooter">
      <div class="flexRow flexGap5">
        <button nbButton size="tiny" status="danger" (click)="closeWindow(false)" style="margin-left: auto">
          Cancela
        </button>
        <button nbButton size="tiny" status="primary" (click)="closeWindow(true)">
          Confirma
        </button>
      </div>
    </div>
  </footer>
</app-dialog-template>
  
