import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { T2BaseComponent } from "../t2-base-component";

@Component({
  selector: 'app-t2-inactivity',
  templateUrl: './t2-inactivity.component.html',
  styleUrls: ['./t2-inactivity.component.scss']
})
export class T2InactivityComponent implements OnInit {
  @Input() inactive: boolean;
  @Input() t2IdCmp: string;
  @Output() isInactive = new EventEmitter<{ isInactive: boolean, t2IdCmp: string }>();

  constructor() { }

  ngOnInit(): void { }

  changeValue() {
    this.inactive = !this.inactive;
    this.isInactive.emit({ isInactive: this.inactive, t2IdCmp: this.t2IdCmp });
  }
}
