import { Component, ComponentRef, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from "@angular/forms";
import { take } from "rxjs/Operators";
import { DialogTemplateComponent } from "src/app/core/cmp/dialog-template/dialog-template.component";
import { T2MenuButtonComponent } from "src/app/core/cmp/ui/t2-menu-button/t2-menu-button.component";
import { T2ViewTemplateData } from "src/app/core/cmp/view-template/model/t2-view-template-data";
import { LayoutType, ViewTemplateElement } from "src/app/core/cmp/view-template/model/view-template-element";
import { ViewTemplateComponent } from "src/app/core/cmp/view-template/view-template.component";
import { T2MessageService } from "src/app/core/t2-message.service";
import { ApsService } from "../../aps.service";

@Component({
  selector: 'app-environment-selection',
  templateUrl: './environment-selection.component.html',
  styleUrls: ['./environment-selection.component.scss'],
})
export class EnvironmentSelectionComponent implements OnInit {

  @ViewChild('dialog', { static: true }) dialog: DialogTemplateComponent;
  @ViewChild('viewTemplate') viewTemplate: ViewTemplateComponent;

  public simulationSelected: string;
  public _layout?: Array<ViewTemplateElement>;
  public _data?: Array<T2ViewTemplateData>;
  public _FormGroup?= new FormGroup({}, { updateOn: "blur" });
  public simulationList: Array<{ id: string, description: string, realEnv: boolean }>;
  saving: boolean = false;

  constructor(private messageService: T2MessageService,
    private apsService: ApsService,) { }

  ngOnInit(): void {
    this.setLayoutDataViewTemplate();
  }

  setLayoutDataViewTemplate() {
    this._layout = [
      {
        layoutType: LayoutType.listLayout,
        gap: "5px grid",
        direction: "row wrap",
        children: this.simulationList.map(s => {
          return {
            width: "nogrow",
            layoutType: LayoutType.component,
            isBaseComponent: false,
            cmpType: T2MenuButtonComponent,
            cmpName: s.id,
            outputs: {
              clicked: this.btnClick.bind(this)
            }
          }
        })
      }
    ];

    this._data = this.simulationList.map(s => {
      return {
        cmpName: s.id,
        properties: { caption: s.description, status: s.id == this.simulationSelected ? "success" : "basic" }
      }
    });
  }

  btnClick(id: string) {
    if (this.simulationSelected != id) {
      if (this.simulationSelected) {
        let btn: ComponentRef<T2MenuButtonComponent> = this.viewTemplate.getCmpReference(this.simulationSelected);
        btn.instance.status = "basic";
      }

      let btn: ComponentRef<T2MenuButtonComponent> = this.viewTemplate.getCmpReference(id);
      btn.instance.status = "success";

      this.simulationSelected = id;
    }
  }

  closeDialog(action: string) {
    if (action == "Cancela") {
      this.dialog.close(undefined);
    } else if (action == "Confirma") {
      this.dialog.close(this.simulationSelected);
    }
  }

  public addSimulation() {
    this.saving = true;
    this.apsService.addSimulation().then(resp => {
      if (resp == "success") {
        this.dialog.close(undefined);
      } else {
        this.saving = false;
      }
    }, error => {
      this.saving = false;
      this.messageService.showToastError(error);
    });
  }

  public renameSimulation() {
    this.apsService.renameSimulation(this.simulationSelected).then(resp => {
      if (resp == "success") {
        this.apsService.getSimulationList()
          .pipe(take(1))
          .subscribe(simuList => {
            this.simulationList.find(s => s.id == this.simulationSelected).description = simuList.find(s => s.id == this.simulationSelected).description;
            let btn: ComponentRef<T2MenuButtonComponent> = this.viewTemplate.getCmpReference(this.simulationSelected);
            btn.instance.caption = this.simulationList.find(s => s.id == this.simulationSelected).description;
          });
      }
    })
  }

  public deleteSimulation() {
    let btn: ComponentRef<T2MenuButtonComponent> = this.viewTemplate.getCmpReference(this.simulationSelected)
    btn.instance.loading = true;
    this.apsService.deleteSimulation(this.simulationSelected).then(resp => {
      if (resp == "success") {
        btn.instance.loading = false;
        this.simulationList.splice(this.simulationList.findIndex(s => s.id == this.simulationSelected), 1);
        this.setLayoutDataViewTemplate();
        this.simulationSelected = undefined;
      }
    }, error => {
      btn.instance.loading = false;
      this.messageService.showToastError(error);
    });
  }

  public realEnv(): boolean {
    return this.simulationList.find(s => s.id == this.simulationSelected)?.realEnv;
  }

  public canEditEnvironment(): boolean {
    return this.apsService.canEditEnvironment();
  }

  public canDeleteEnvironment(): boolean {
    return this.apsService.canDeleteEnvironment();
  }

  public canAddEnvironment(): boolean {
    return this.apsService.canAddEnvironment();
  }

}
