import { T2PrinterService } from './../../../../../core/printer/t2-printer.service';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { take } from "rxjs/Operators";
import { T2HttpClientService } from "src/app/core/http/t2httpClient.service";
import { Appointment, DeviceCache, DeviceCacheTask } from "../device-cache.service";
import { ShopFloorService } from "../shop-floor.service";
import { T2SecurityService } from "src/app/core/security/t2security.service";
import { T2AccessItem, T2AccessItemType } from "src/app/core/security/model/t2accessItem";
import { ActionService } from "src/app/core/action/action.service";
import { Observable } from "rxjs";
import { T2WidgeInputOutputParams } from "src/app/core/widget/model/t2widgetInputOutputParams";

@Component({
  selector: 'app-shop-floor-task-history',
  templateUrl: './shop-floor-task-history.component.html',
  styleUrls: ['./shop-floor-task-history.component.scss']
})

export class ShopFloorTaskHistoryComponent implements OnInit, OnDestroy {

  constructor(private httpClient: T2HttpClientService, private sfService: ShopFloorService,
    private sec: T2SecurityService, private actionService: ActionService, private printService: T2PrinterService) {

    /*
    Esse timer é necessário pois a lista de apontamentos da tarefa só é carregada depois que chego input
    do taskplan, dessa maneira se a lista de apontamentos mudar, é preciso considerar a mudança nos apontamentos
    */
    this.appontListTimer = setInterval(() => {
      if (this.taskPlan && this.appontListLength != this.taskPlan.appointment.length) {
        this.loadAppointmentList();
      }
    }, 100);
  }

  private _taskPlan: DeviceCacheTask;
  private _device: DeviceCache;
  private timer: NodeJS.Timeout;
  private appontListTimer: NodeJS.Timeout;
  private appontListLength: number;
  panel: 'document' | 'justify operation' = 'document';
  appointmentToJustify: Appointment;

  @Input()
  set taskPlan(value: DeviceCacheTask) {
    if (value && !value.appointment) { value.appointment = []; }

    this._taskPlan = value;
    if (value != undefined) {
      this.changeParams();
    }
  }
  get taskPlan(): DeviceCacheTask {
    return this._taskPlan;
  }

  @Input()
  set device(value: DeviceCache) {
    this._device = value;
    if (value != undefined) this.changeParams();
  }
  get device() {
    return this._device;
  }

  @Output() justifyOperationFinished = new EventEmitter<boolean>();
  @Output() lockScreen = new EventEmitter<boolean>();

  unjustifiedAppont = false;
  quantityAppont = false;
  filteredAppointmentList: Array<Appointment> = [];
  requisitionMaterialList: Array<RequisitionMaterial>;
  salesOrderList: Array<SalesOrder> = [];
  prodOrder: ProdOrder;
  epList: Array<EP>;

  ngOnInit(): void { }

  ngOnDestroy(): void {
    if (this.appontListTimer) {
      clearInterval(this.appontListTimer);
    }
  }

  loadAppointmentList() {

    this.appontListLength = this.taskPlan?.appointment?.length || 0;

    this.sfService.getOperationGroup(this.device.deviceId).subscribe(opg => {
      opg = opg.filter(i => i.opType == 'OPERATION');
      opg.forEach(operation => {
        this.taskPlan.appointment?.forEach((a, index) => {
          a.index = index;
          if (!a.description) {
            a.description = 'Operação não justificada'
            a.color = '#212f3d';
          } else if (a.description == operation.description) {
            a.color = this.sfService.getOperationColor(operation);
            a.filterGroup = operation.filterGroup;
            a.id_report_etq = operation.id_report_etq;
            a.sendToPrinter = operation.sendToPrinter;
          }
        });
      });
    });

    this.filterAppointmentList();
  }

  loadRequisitionList() {
    if (!this.device.deviceId || !this.taskPlan?.id_taskPlan) {
      this.requisitionMaterialList = [];
      return;
    }
    this.httpClient.get(`production.registration/device/${this.device.deviceId}/taskPlan/${this.taskPlan?.id_taskPlan}`, null)
      .pipe(take(1))
      .subscribe(resp => {
        if (!resp.taskPlans) {
          this.requisitionMaterialList = [];
          return;
        }
        if (!Array.isArray(resp.taskPlans)) {
          resp.taskPlans = [resp.taskPlans];
        }
        const taskPlans = resp.taskPlans;
        taskPlans.forEach(taskPlan => {
          if (!Array.isArray(taskPlan)) taskPlan = [taskPlan];
          this.requisitionMaterialList = [];
          taskPlan.forEach(tp => {
            if (!Array.isArray(tp.taskPlan.cmpsCons)) tp.taskPlan.cmpsCons = [tp.taskPlan.cmpsCons];
            const cmpsCons = tp.taskPlan.cmpsCons;
            cmpsCons.forEach(cc => {
              if (!Array.isArray(cc.cmpCons)) cc.cmpCons = [cc.cmpCons];
              const cmp = cc.cmpCons;
              cmp.forEach(c => {
                if (!c.codeItemRequest) return;
                let cmpCons = new RequisitionMaterial();
                cmpCons.cmpDescr = c.cmpDescr;
                cmpCons.consumedQtt = +c.consumedQtt;
                cmpCons.requestedQtt = +c.requestedQtt;
                cmpCons.separatedQtt = +c.separatedQtt;
                cmpCons.unit = c.unitSymbol;
                this.requisitionMaterialList.push(cmpCons);
              });
            });
          });
        });
      });
  }

  loadSalesOrderList() {
    if (!this.taskPlan?.id_ordemProd) {
      this.salesOrderList = [];
      return;
    }

    let params = new Map<string, string>();
    params.set("id_ordemprod", this.taskPlan.id_ordemProd);
    this.httpClient.get('bsn.sales.salesOrderProductionOrder/salesOrderRetatedProductionOrder', params)
      .pipe(take(1))
      .subscribe(resp => {
        this.salesOrderList = [];
        if (!resp.salesOrderList) return;
        if (!Array.isArray(resp.salesOrderList)) {
          resp.salesOrderList = [resp.salesOrderList];
        }
        const sOList = resp.salesOrderList;
        sOList.forEach(so => {
          let salesOrder: SalesOrder = new SalesOrder();
          salesOrder.id_pedidovenda = so.id_pedidovenda;
          salesOrder.code = so.salesOrderCode;

          const datasetName = 'pvd_pedidovenda';
          this.httpClient.get(`core.dataset.attach/${datasetName}/${salesOrder.id_pedidovenda}/count`, null)
            .pipe(take(1))
            .subscribe(r => {
              salesOrder.hasAttachment = r.attachQtt > 0
            });

          this.salesOrderList.push(salesOrder);
        });

        this.sec.getDatasetActions("pvd_pedidovenda")
          .pipe(take(1))
          .subscribe(resp => {
            const actions = resp.filter(a => a.type == T2AccessItemType.ACTION_REPORT);
            this.salesOrderList?.forEach(so => so.actions = actions);
          });
      });
  }

  loadProdOrder() {
    if (!this.taskPlan?.id_ordemProd) {
      this.prodOrder = null;
      return;
    }
    this.prodOrder = new ProdOrder();
    this.sfService.getOperationGroup(this.device.deviceId)
      .pipe(take(1))
      .subscribe(ogf => {
        ogf = ogf.filter(i => i.id_report_etq && !i.sendToPrinter);
        ogf.forEach(operation => {
          this.prodOrder?.reportOperations.push({ id_report_etq: operation.id_report_etq, opType: operation.opType, description: operation.description, printing: false });
        });
      });

    this.sec.getDatasetActions("ord_ordemProd")
      .pipe(take(1))
      .subscribe(resp => {
        const actions = resp.filter(a => a.type == T2AccessItemType.ACTION_REPORT);
        actions?.forEach(a => this.prodOrder.actions.push(a));
      });

    const datasetName = 'ord_ordemprod';
    this.httpClient.get(`core.dataset.attach/${datasetName}/${this.taskPlan.id_ordemProd}/count`, null)
      .pipe(take(1))
      .subscribe(r => {
        this.prodOrder.hasAttachment = r.attachQtt > 0
      });
  }

  loadEpInfo() {
    if (!this.taskPlan?.id_ordemProd) {
      this.epList = [];
      return;
    }

    let params = new Map<string, string>();
    params.set("id_ordemprod", this.taskPlan.id_ordemProd);

    this.httpClient.get("bsn.epp/getEPTypeByIdProdOrder", params)
      .pipe(take(1))
      .subscribe(resp => {
        this.epList = [];
        if (!resp.ep) return;
        if (!Array.isArray(resp.ep)) {
          resp.ep = [resp.ep];
        }
        const epList = resp.ep;
        epList.forEach(ep => {
          let epType = new EP();
          epType.code = ep.epCode;
          epType.id_especificacao = ep.id_especificacao;
          epType.tipoEp = ep.epType;

          let datasetName = null;
          switch (epType.tipoEp) {
            case "CONJUGADA":
              datasetName = 'stk_conjugado';
              break;
            case "VERSÃO":
              datasetName = 'stk_item_produto_versao';
              break;
          }

          this.sec.getDatasetActions(datasetName)
            .pipe(take(1))
            .subscribe(resp => {
              const actions = resp.filter(a => a.type == T2AccessItemType.ACTION_REPORT);
              actions?.forEach(a => epType.actions.push(a));
            });

          this.epList.push(epType);
        });
      });
  }

  executeAction(action: T2AccessItem, id: string): void {
    let params = new Map<string, string>();
    params.set("datasetName", action.datasetName);
    params.set("id", id);
    const resp = this.actionService.executeAction(action, params, "newWindow");
    if (resp instanceof Observable) {
      resp.subscribe(resp => {
      });
    }
  }

  openReport(opReport) {
    opReport.printing = true;
    let params = new Map<string, string>();
    switch (opReport.opType) {
      case 'PRINT':
        params.set("ID", this.taskPlan.id_ordemProd);
        break;
      case 'DETAIL':
        params.set("ID", this.taskPlan.id_taskPlan);
        break;
      case 'SEQUENCE':
        params.set("ID", this.device.deviceId);
        break;
      case 'OPERATION':
        params.set("ID", this.taskPlan.id_taskPlan);
        break;
    }

    this.printService.openReport(opReport.id_report_etq, params).subscribe(() => {
      opReport.printing = false;
    }, error => {
      opReport.printing = false;
    });

  }

  private changeParams() {
    if (this.timer) {
      clearTimeout(this.timer);
    }

    this.timer = setTimeout(() => {
      this.loadAppointmentList();
      this.loadRequisitionList();
      this.loadSalesOrderList();
      this.loadProdOrder();
      this.loadEpInfo();
    }, 100);
  }

  checkUnjustifiedAppont(value) {
    this.unjustifiedAppont = value || false;
    this.quantityAppont = false;

    this.filterAppointmentList();
  }

  checkQuantityAppont(value) {
    this.quantityAppont = value || false;
    this.unjustifiedAppont = false;

    this.filterAppointmentList();
  }

  private filterAppointmentList(): void {

    this.filteredAppointmentList.length = 0;

    if (!this.taskPlan.appointment)
      return;

    if (this.unjustifiedAppont && !this.quantityAppont) {
      this.filteredAppointmentList.push(...this.taskPlan.appointment.filter(item => !item.description));
    } else if (!this.unjustifiedAppont && this.quantityAppont) {
      this.filteredAppointmentList.push(...this.taskPlan.appointment.filter(item => item.sendToPrinter && item.id_report_etq));
    } else {
      this.filteredAppointmentList.push(...this.taskPlan.appointment);
    }
  }

  reprintLabel(appointment: Appointment) {
    this.sfService.printLabel(appointment.registrationId, appointment.taskProdQtyId);
  }

  justifyOperation(appointment: Appointment) {
    this.panel = 'justify operation';
    this.appointmentToJustify = appointment;
  }

  goBackToDocument(justifyOperationFinished: boolean) {
    if (justifyOperationFinished) {
      this.justifyOperationFinished.emit(true);
      this.panel = 'document';
    }
  }

  setLockScreen(lockScreen: boolean) {
    this.lockScreen.emit(lockScreen);
  }
}

export class RequisitionMaterial {
  cmpDescr: string;
  consumedQtt: number;
  unit: string;
  requestedQtt: number;
  separatedQtt: number;
}

export class SalesOrder {
  id_pedidovenda: string;
  code: string;
  actions?: Array<T2AccessItem>;
  hasAttachment?: boolean;
}

export class ProdOrder {
  actions?: Array<T2AccessItem> = [];
  hasAttachment?: boolean;
  reportOperations?: Array<{ id_report_etq: string, opType: string, description: string, printing: boolean }> = [];
}

export class EP {
  id_especificacao: string;
  tipoEp: string;
  code: string;
  actions?: Array<| T2AccessItem> = [];
}
