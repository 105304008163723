import { AfterContentInit, Component, HostListener, Input, OnInit } from '@angular/core';
import { EChartsOption, EChartsType } from "echarts";

@Component({
  selector: 'app-t2-progress-index',
  templateUrl: './t2-progress-index.component.html',
  styleUrls: ['./t2-progress-index.component.scss']
})
export class T2ProgressIndexComponent implements AfterContentInit {

  @Input() title: string;
  @Input() progress: number = 10;
  @Input() color: 'red' | 'yellow' | 'green' | 'blue' = 'yellow';

  smallWindow: boolean;
  bgColor = {
    red: 'rgba(255, 125, 125, 1)',
    yellow: '#FFC700',
    green: 'rgb(74, 224, 89)',
    blue: '#0789c2'
  };

  themeName = "light";
  echartsIntance: EChartsType;
  co: EChartsOption = {
    //title: {text: "75% Consumo", show: true, bottom: -5, textStyle: {fontSize: 16}},
    series: [
      {
        top: -20,
        left: -20,
        bottom: -20,
        right: -20,
        type: 'pie',
        radius: ['30%', '57%'],
        avoidLabelOverlap: false,
        label: {
          show: true, formatter: '{c}%', fontWeight: 'bold', fontSize: 14,
          position: 'center'
        },
        data: []
      }
    ]
  };

  onChartInit(ec: EChartsType) {
    const colors = {
      red: { current: 'rgba(255, 0, 0, 1)', remaining: 'rgba(255, 0, 0, 0.15)' },
      yellow: { current: '#FFC700', remaining: 'rgba(255, 199, 0, 0.50)' },
      green: { current: '#24C634', remaining: 'rgba(36, 198, 52, 0.50)' },
      blue: { current: '#0789c2', remaining: '#0789c23b' },
    };

    this.echartsIntance = ec;
    if (this.echartsIntance) {
      this.co.series[0].data = [
        { value: this.progress.toFixed(2), itemStyle: { color: colors[this.color].current } },
        { value: (100 - this.progress).toFixed(2), itemStyle: { color: colors[this.color].remaining } },
      ];

      this.co.series[0].label.show = this.progress > 0;
      this.co.series[0].label.formatter = this.progress.toFixed(2) + '%';

      this.echartsIntance.setOption(this.co);
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event): void {
    if (window.innerWidth <= 450) this.smallWindow = true;
    if (window.innerWidth > 450) this.smallWindow = false;
  }

  ngAfterContentInit(): void {
    if (window.innerWidth <= 450) this.smallWindow = true;
    if (window.innerWidth > 450) this.smallWindow = false;
  }
}
