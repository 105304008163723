import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ColorService {

  private colorClassMap = {
    "DarkGray": "t2ColorDarkGray",
    "Gray": "t2ColorGray",
    "LightGray": "t2ColorLightGray",
    "DarkOrange": "t2ColorDarkOrange",
    "Orange": "t2ColorOrange",
    "LightOrange": "t2ColorLightOrange",
    "Yellow": "t2ColorYellow",
    "DarkGreen": "t2ColorDarkGreen",
    "Green": "t2ColorGreen",
    "LightGreen": "t2ColorLightGreen",
    "DarkBlue": "t2ColorDarkBlue",
    "Blue": "t2ColorBlue",
    "LightBlue": "t2ColorLightBlue",
    "DarkRed": "t2ColorDarkRed",
    "Red": "t2ColorRed",
    "LightRed": "t2ColorLightRed"
  }

  constructor() { }

  public getClass(color: string) {
    return this.colorClassMap[color];
  }
}
