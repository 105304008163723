<div style="display: flex; flex-direction: column; height: 100%; align-items: flex-end; gap: 10px">
  <div style="display: flex; gap: 5px;">
    <div *ngIf="showMsg" style="display: flex; flex-direction: column;
    align-items: center;" class="fontConfig">
      <span>Atualizado (via API)</span>
      <span>{{refreshDate}}</span>
    </div>
    <div *ngIf="groupId && datasetId" class="editFloatButton">
      <nb-icon icon="sync-outline" [nbSpinner]="refreshing" nbSpinnerStatus="primary" (click)="refreshData()"></nb-icon>
    </div>
  </div>
  <powerbi-report *ngIf="reportConfig" style="height: 100%; display: grid; width: 100%;" #reportBI
    [embedConfig]="reportConfig" [phasedEmbedding]="phasedEmbeddingFlag" [eventHandlers]="eventHandlersMap" />
</div>