import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-aps-kpi',
  templateUrl: './aps-kpi.component.html',
  styleUrls: ['./aps-kpi.component.scss']
})
export class ApsKPIComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
