export enum RestMethod {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  DELETE = 'DELETE',
}

export enum MediaType {
  JSON = 'application/json',
  PDF = 'application/pdf',
  SVG = 'image/svg+xml',
  PNG = 'image/png',
  OCTET = 'application/octet-stream'
}

export class T2RequestInfo {
  method: RestMethod;
  path: string;
  queryParams?: Map<string, string>;
  body?: any;
  responseType?: string;
  accept?: string;
}
