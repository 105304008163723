import { FormGroup } from '@angular/forms';
declare module "@angular/forms" {
  interface FormGroup {
    readOnly: boolean;
    setReadOnly(readOnly: boolean): void;
    removeAllControls(): void;
    isAllControlsPristine(): boolean;
    markAllControlsAsDirty(): void;
  }
}

FormGroup.prototype.setReadOnly = function (this: FormGroup, readOnly: boolean): void {
  this.readOnly = readOnly;
}

FormGroup.prototype.removeAllControls = function(this: FormGroup): void {
  Object.keys(this.controls)?.forEach(key => {
    this.removeControl(key);
  })
}

FormGroup.prototype.isAllControlsPristine = function(this: FormGroup): boolean {
  let isPristine: boolean = true;
  
  for (let key of Object.keys(this.controls)) {
    if (this.controls[key].dirty) {
      isPristine = false;
      break;
    }
  }

  return isPristine;
}

FormGroup.prototype.markAllControlsAsDirty = function(this: FormGroup): void {
  Object.keys(this.controls)?.forEach(key => {
    this.controls[key].markAsDirty();
  })
}