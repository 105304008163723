<div *ngIf="stage != 'dataLoaded'">
  <nb-card class="wHeader" style="margin-bottom: 5px;">
    <nb-card-body style="overflow: hidden;">
      <div style="text-align: center;">
        <span>

          <div *ngIf="stage == 'notADashboard'">
            Para acessar essa informação desative o modo <strong>Painéis e Indicadores</strong>
          </div>

          <div *ngIf="stage == 'loadingAccess'">
            Carregando permissões de acesso...
          </div>
          <div *ngIf="stage == 'validatingAccess'">
            Validando permissões de acesso....
          </div>
          <div *ngIf="stage == 'accessDenied'">
            Você não tem permissão para visualizar essa informação
          </div>
          <div *ngIf="stage == 'loadingData'">
            Carregando informações...
          </div>
        </span>
      </div>
    </nb-card-body>
  </nb-card>
</div>

<div *ngIf="stage == 'dataLoaded'" class="w">

  <nb-card style="margin-bottom: 0px; height: 100%;">
    <nb-card-body style="height: 100%; display: flex;flex-direction: column; padding: 1rem 0.7rem 0.5rem !important;">

      <div *ngIf="showHeader === true" class="wHeader" style="margin-bottom: 5px;">
        <div style="overflow: hidden;">
          <div style="display: flex; gap: 10px; justify-content: flex-end; align-items: center;">
            <span style="font-size: 150%; font-weight: bold; height: 24px;">{{formTitle}}</span>
            <button *ngIf="helpUrl" nbButton shape="round" ghost size="medium" (click)="openHowToUse()"
              style="padding: 0px 1px; color: #3366ff;" nbTooltip="Guia de uso"><nb-icon
                icon="question-mark-circle-outline"></nb-icon></button>
            <span class="beta" *ngIf="beta"
              nbTooltip="Essa funcionalidade está sem sua fase final de testes e ajustes">Beta</span>
          </div>
          <div>
            <ng-content select="header"></ng-content>
          </div>
        </div>
      </div>

      <div class="wContent">
        <div [ngClass]="{contentFlex: useFlexLayout}" style="max-height: 100%; height: 100%">
          <div style="flex: 1 0 0; height: 100%; overflow: auto;">
            <ng-content select="content"></ng-content>
          </div>
        </div>
      </div>
      <div class="wFooter">
        <ng-content select="footer"></ng-content>
      </div>
    </nb-card-body>
  </nb-card>
</div>
