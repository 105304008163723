import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NbDialogRef, NbDialogService } from "@nebular/theme";
import { DialogTemplateComponent } from "../../dialog-template/dialog-template.component";
import { T2DatasetTreeGridComponent, TreeInfo } from "../../t2-dataset-tree-grid/t2-dataset-tree-grid.component";
import { T2BaseComponent } from "../t2-base-component";

@Component({
  selector: 'app-t2-tree-grid-input',
  templateUrl: './t2-tree-grid-input.component.html',
  styleUrls: ['./t2-tree-grid-input.component.scss']
})
export class T2TreeGridInputComponent extends T2BaseComponent implements OnInit {

  @ViewChild('treeGridDialog') treeGridDialog: TemplateRef<any>;
  @ViewChild('treeGrid') treeGrid: T2DatasetTreeGridComponent;
  
  @Input() datasetName: string;
  @Input() description: string;

  public dialogRef: NbDialogRef<DialogTemplateComponent>;
  private itemSelected: TreeInfo;

  constructor(private dialogService: NbDialogService) {
    super();
  }

  ngOnInit(): void {
    this.setDefaultValues();
  }

  openTreeGrid() {
    this.dialogRef = this.dialogService.open(this.treeGridDialog, {
      autoFocus: true, closeOnEsc: true, hasBackdrop: true
    });
  }

  onTreeGridSelectionChanged(data: TreeInfo) {
    this.itemSelected = data;
  }

  confirmSelection() {
    this.formGroup.controls[this.t2IdCmp].setValue(this.itemSelected.id);
    this.description = this.itemSelected.code + " " + this.itemSelected.description;
    this.dialogRef.close(true);
  }
}
