<app-dialog-template title="Configuração avançada de visualização" #dialog [beta]="true"
  helpUrl="https://tech2e.atlassian.net/l/cp/AQVgqnrN">
  <content>

    <div class="flexColumn" style="gap: 5px; text-wrap: nowrap;">
      <!-- Tab selecion -->
      <div class="tabSelection" [ngClass]="{ tabSelected: tabSelected == 1 }" (click)="tabSelected = 1">Identificação
      </div>
      <div class="tabSelection" [ngClass]="{ tabSelected: tabSelected == 2 }" (click)="tabSelected = 2">Seleção de
        colunas</div>
      <div class="tabSelection" [ngClass]="{ tabSelected: tabSelected == 3 }" (click)="tabSelected = 3">Definição de
        Filtros</div>
    </div>

    <div class="T2GrayArea" style="padding: 5px; width: 100%; display: flex; flex-direction: column;">
      <!-- Tab sections -->
      <div class="section" [ngStyle]="{'display': tabSelected == 1 ? 'block' : 'none'}">
        <app-view-template #viewTemplate [FormGroup]="formGroup" [layout]="layout"></app-view-template>
      </div>

      <div *ngIf="tabSelected == 2" class="section">
        <!-- Colunas -->
        <app-visualization [fromDictionary]="fromDictionary" [queryMode]="false" [id_dataset]="id_dataset"
          [description]="" [selectedFieldList]="datasetVisualization.fieldList" [showPinned]="false"
          style="height: 100%; max-height: 58.5vh; display: flex; flex-direction: row;" />
      </div>

      <div *ngIf="tabSelected == 3" class="section">
        <!-- Filtro -->
        <app-query-condition *ngIf="datasetVisualization?.queryCondition"
          [condition]="datasetVisualization?.queryCondition" [id_dataset]="id_dataset" [canDelete]="false"
          [formGroup]="formGroup"
          class="flexColumn" style="background-color: white; padding: 5px; border-radius: 5px; height: 100%; width: 100%; max-height: 58.5vh;">
        </app-query-condition>
      </div>

    </div>


  </content>

  <footer style="display: flex; gap: 5px; justify-content: space-between;">
    <div></div>
    <div style="display: flex; gap: 5px">
      <button nbButton size="tiny" (click)="closeDialog(false)">Cancelar</button>
      <button nbButton status="primary" size="tiny" (click)="closeDialog(true)" [nbSpinner]="savingQuery"
        [disabled]="savingQuery">
        {{'Salvar'}}
      </button>
    </div>
  </footer>
</app-dialog-template>
