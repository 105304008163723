<app-dialog-template #dialog title="Seleção de Horário de trabalho">
  <content style="min-width: 80vw;">
    <app-view-template *ngIf="loaded" #viewTemplate [layout]="layout" [data]="data"
      [FormGroup]="formGroup"></app-view-template>
  </content>
  <footer>
    <div class="flexAlignRowEndCenter flexGap5">
      <button nbButton status="danger" size="tiny" (click)="closeDialog('Cancela')">Cancela</button>
      <button nbButton status="primary" size="tiny" [disabled]="!formGroup.valid"
        (click)="closeDialog('Confirma')">Confirma</button>
    </div>
  </footer>
</app-dialog-template>
