import { Component, OnInit } from "@angular/core";
import { AgRendererComponent } from "ag-grid-angular";
import { IAfterGuiAttachedParams, ICellRendererParams } from "ag-grid-community";

@Component({
  selector: "app-dataset-action-access-renderer",
  templateUrl: "./dataset-action-access-renderer.component.html",
  styleUrls: ["./dataset-action-access-renderer.component.scss"]
})
export class DatasetActionAccessRendererComponent implements AgRendererComponent {

  cellValue: string = null;

  constructor() { }

  // gets called once before the renderer is used
  agInit(params: ICellRendererParams): void {
    this.cellValue = params.value;
  }

  // after this component has been created and inserted into the grid
  afterGuiAttached?(params?: IAfterGuiAttachedParams): void {
  }

  // gets called whenever the user gets the cell to refresh
  refresh(params: ICellRendererParams): boolean {

    return true;
  }

}
