import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { DatasetTemplateComponent } from "src/app/core/dataset/dataset-template.component";
import { Dataset } from "src/app/core/dataset/model/dataset";

@Component({
  selector: 'app-eppstructure-layer-chemical',
  templateUrl: './eppstructure-layer-chemical.component.html',
  styleUrls: ['./eppstructure-layer-chemical.component.scss']
})
export class EppstructureLayerChemicalComponent implements OnInit {

  @ViewChild("datasetTemplate", { static: true }) datasetTemplate: DatasetTemplateComponent;

  private _id_especif_carac: string;
  @Input()
  get id_especif_carac(): string { return this._id_especif_carac; };
  set id_especif_carac(value: string) { this._id_especif_carac = value; this.loadChemicalItems(); };

  private _id_especif_carac_estr_item: string;
  @Input()
  get id_especif_carac_estr_item(): string { return this._id_especif_carac_estr_item; };
  set id_especif_carac_estr_item(value: string) {
    this._id_especif_carac_estr_item = value;
    this.insertParams.set("flx_especif_carac_quimico_item.id_especif_carac_estr_item", value);
    this.loadChemicalItems();
  };

  private _blockChange: boolean;
  @Input()
  get blockChange(): boolean {
    return this._blockChange;
  }
  set blockChange(value: boolean) {
    this._blockChange = value;
    if (this.datasetTemplate?.dataset?.datasetName) {
      this.datasetTemplate.setDatasetInfo("flx_especif_carac_quimico_item", this.id_especif_carac, null, [{ property: "id_especif_carac_estr_item", value: this.id_especif_carac_estr_item, operator: "EQUALS" }], true);
    }
  }

  public insertParams = new Map<string, any>();

  constructor() { }

  ngOnInit(): void {
  }

  onDatasetChange(dataset: Dataset) {
    this.datasetTemplate.executeSearch();
  }

  private loadChemicalItems(): void {
    if (this.id_especif_carac && this.id_especif_carac_estr_item) {
      this.datasetTemplate.setDatasetInfo("flx_especif_carac_quimico_item", this.id_especif_carac, null, [{ property: "id_especif_carac_estr_item", value: this.id_especif_carac_estr_item, operator: "EQUALS" }]);
      if (this.datasetTemplate.dataset?.datasetName) {
        this.datasetTemplate.executeSearch();
      }
    }
  }

}
