import { Component } from '@angular/core';
import { ICellRendererAngularComp } from "ag-grid-angular";
import { ICellRendererParams } from "ag-grid-community";

@Component({
  selector: 'app-check-box-renderer',
  templateUrl: './check-box-renderer.component.html',
  styleUrls: ['./check-box-renderer.component.scss']
})
export class CheckBoxRendererComponent implements ICellRendererAngularComp {

  public params: ICellRendererParams;

  constructor() { }

  refresh(params: ICellRendererParams): boolean {
    return true;
  }

  agInit(params: ICellRendererParams): void {
    this.params = params;
  }

  checkedHandler(checked): void {
    let colId = this.params.column.getColId();
    this.params.node.setDataValue(colId, checked);
  }
}
