import { takeUntil } from 'rxjs/Operators';
import { Component, OnInit, AfterViewInit, OnDestroy, AfterContentInit } from '@angular/core';
import { Subject } from "rxjs";
import { T2SecurityService } from "../../security/t2security.service";
import { T2AccessItem } from "../../security/model/t2accessItem";
import { MenuService } from "../../menu/menu.service";

@Component({
  selector: 'app-favorites',
  templateUrl: './favorites.component.html',
  styleUrls: ['./favorites.component.scss']
})
export class FavoritesComponent implements OnInit, OnDestroy, AfterContentInit {

  public favAccessList: T2AccessItem[] = [];
  private unsubscribe$ = new Subject<void>();

  constructor(public menuService: MenuService, private secService: T2SecurityService) { }
  ngAfterContentInit(): void {
    this.secService.getFavAccessList().pipe(takeUntil(this.unsubscribe$)).subscribe(accessList => {

      accessList.sort((i1, i2) => {
        let comp = (i1.groupIndex - i2.groupIndex)
        if (comp != 0) return comp;

        return (i1.subGroupIndex - i2.subGroupIndex);
      });

      this.favAccessList = [...accessList];
    });
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
