import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DialogTemplateComponent } from "src/app/core/cmp/dialog-template/dialog-template.component";

@Component({
  selector: 'app-change-device-dialog',
  templateUrl: './change-device-dialog.component.html',
  styleUrls: ['./change-device-dialog.component.scss']
})
export class ChangeDeviceDialogComponent implements OnInit {

  @ViewChild('dialog', { static: true }) dialog: DialogTemplateComponent;
  @Input() closeOnConfirm: boolean = false;
  @Output() callBack = new EventEmitter<string>();

  public itemList: Array<{ id: string, description: string }>;
  public selectedItem: string;

  constructor() { }

  ngOnInit(): void {
  }

  closeDialog(action) {
    this.dialog.close(undefined);
  }

  runCallback() {
    this.callBack.emit(this.selectedItem);

    if (this.closeOnConfirm) {
      this.dialog.close(this.selectedItem);
    }
  }
}
