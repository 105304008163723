import { AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';

import { DataSet } from 'vis-data';
import { IdType, Timeline, TimelineAnimationOptions, TimelineOptions, TimelineOptionsGroupHeightModeType, TimelineOptionsTemplateFunction } from 'vis-timeline';
import Arrow from './timeline-arrow/arrow'

@Component({
  selector: 'app-t2-gantt',
  templateUrl: './t2-gantt.component.html',
  styleUrls: ['./t2-gantt.component.scss']
})
export class T2GanttComponent implements AfterViewInit {

  @Input() items: DataSet<any>;
  @Input() groups: any;
  @Input() arrows: any;
  @Input("template")
  get template(): TimelineOptionsTemplateFunction {
    return this._template;
  }
  set template(value: TimelineOptionsTemplateFunction) {
    if (value != undefined) {
      this._template = value;
      this.options["template"] = this._template;
    }
  }
  @Input("additionalOptions")
  get additionalOptions() {
    return this._additionalOptions;
  }
  set additionalOptions(value: { stack?: boolean, groupHeightMode?: TimelineOptionsGroupHeightModeType }) {
    this._additionalOptions = value;
    Object.keys(value).forEach(key => {
      this.options[key] = value[key];
    })
  }
  @Input("groupTemplate")
  get groupTemplate(): TimelineOptionsTemplateFunction {
    return this._groupTemplate;
  }
  set groupTemplate(value: TimelineOptionsTemplateFunction) {
    if (value != undefined) {
      this._groupTemplate = value;
      this.options["groupTemplate"] = this._groupTemplate;
    }
  }

  @Output() click = new EventEmitter();
  @Output() doubleClick = new EventEmitter();
  @Output() itemOver = new EventEmitter();
  @Output() mouseOver = new EventEmitter();

  @ViewChild('ganttRef', {static: true}) ganttRef: ElementRef;
  private gantt: Timeline;
  private _template: TimelineOptionsTemplateFunction;
  private _groupTemplate: TimelineOptionsTemplateFunction;
  private _additionalOptions: { stack?: boolean, groupHeightMode?: TimelineOptionsGroupHeightModeType}
  private options: TimelineOptions = {
    locale: 'pt_BR',
    width: "100%",
    height: "100%",
    orientation: { axis: 'both', item: 'top' },
    verticalScroll: true,
    stack: false,
    start: new Date(new Date().setHours(0, 0, 0, 0)),
    end: new Date(new Date().setHours(0, 0, 0, 0) + ((24 * 60 * 60 * 1000) * 3)),
    zoomKey: "ctrlKey"
  };
  private arrow: Arrow;

  constructor() { }

  ngAfterViewInit(): void {
    this.gantt = new Timeline(this.ganttRef.nativeElement, this.items, this.groups, this.options);
    this.gantt.on("click", this.onClick.bind(this));
    this.gantt.on("itemover", this.onItemOver.bind(this));
    this.gantt.on("mouseOver", this.onMouseOver.bind(this));
    this.gantt.on("doubleClick", this.onDoubleClick.bind(this));

    this.arrow = new Arrow(this.gantt, this.arrows || []);
  }

  private onClick(event) {
    this.click.emit(event);
  }

  private onItemOver(event) {
    this.itemOver.emit(event);
  }

  private onMouseOver(event) {
    this.mouseOver.emit(event);
  }

  private onDoubleClick(event) {
    this.doubleClick.emit(event);
  }

  public addArrow(arrowObj: { id: any, id_item_1: IdType, id_item_2: IdType, title?: string}) {
    this.arrow.addArrow(arrowObj)
  }

  public removeArrow(arrowId: any) {
    this.arrow.removeArrow(arrowId);
  }

  public removeArrowByItemId(itemId: any) {
    this.arrow.removeArrowbyItemId(itemId);
  }

  public focus(ids: IdType | IdType[], options?: TimelineAnimationOptions) {
    this.gantt.focus(ids, options);
  }
}
