import { AfterViewInit, Component, EventEmitter, Inject, Input, LOCALE_ID, OnInit, Output, ViewChild } from '@angular/core';
import { TaskRequisition, T2SeparationService } from "../t2separation.service";
import { take, takeUntil } from "rxjs/Operators";
import { T2InputTextComponent } from "src/app/core/cmp/ui/t2-input-text/t2-input-text.component";
import { FormBuilder } from "@angular/forms";
import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import { T2UserDeviceService } from "src/app/t2-user-device.service";
import { T2gridComponent } from "src/app/core/cmp/t2grid/t2grid.component";
import { MinutesToHoursPipe } from "../../../../../core/pipes/minutes-to-hours.pipe";
import { RowDragEndEvent } from "ag-grid-community";
import { FormatWidth, getLocaleDateFormat } from "@angular/common";
import { Observable, Subject } from "rxjs";
import { T2SecurityService } from "src/app/core/security/t2security.service";
import { T2AccessItem, T2AccessItemDatasetActionType } from "src/app/core/security/model/t2accessItem";
import { T2MessageService } from "src/app/core/t2-message.service";
import { ActionService } from "src/app/core/action/action.service";

@Component({
  selector: 'app-manual-separation',
  templateUrl: './manual-separation.component.html',
  styleUrls: ['./manual-separation.component.scss']
})
export class ManualSeparationComponent implements OnInit, AfterViewInit {

  @ViewChild('inputSearch', { static: true }) inputSearch: T2InputTextComponent;
  @ViewChild('gridReq') gridReq: T2gridComponent;

  @Input() hasCoordinatorAccess: boolean;
  @Output() startTransfer = new EventEmitter<TaskRequisition>();

  public pendingRequestList: Array<TaskRequisition>;
  public loading: boolean = true;
  public reqTitle = "Requisições Pendentes";
  public selectedRequisition: TaskRequisition;
  public loadingTransfer: boolean;
  public dateFormat: string;
  private minToUpdate: number = 1;
  private intervalId;
  public canChangeOrder: boolean = true;
  private unsubscribe = new Subject<void>();
  public gridContextComponent = { contextMenu: [] };
  public loadingData: boolean = false;


  constructor(private separationService: T2SeparationService,
    private formBuilder: FormBuilder,
    public userDevService: T2UserDeviceService,
    private sec: T2SecurityService,
    private messageService: T2MessageService,
    private actionService: ActionService,
    @Inject(LOCALE_ID) public locale: string,) { }

  ngAfterViewInit(): void {
    this.inputSearch.formGroup.setControl(this.inputSearch.t2IdCmp, this.formBuilder.control(undefined, { updateOn: "change" }));

    this.userDevService.getObservableSmallScreen().pipe(takeUntil(this.unsubscribe)).subscribe(result => {
      this.selectedRequisition = undefined;
      if (!result.matches) {
        this.configGrid();
        this.gridReq.setGridData(this.pendingRequestList);
      }
    });
  }

  ngOnInit(): void {
    this.loadPendingRequests();
  }

  loadPendingRequests(selectRequisition?: TaskRequisition) {
    this.loading = true;
    this.selectedRequisition = undefined;
    this.separationService.getPendingRequests(this.hasCoordinatorAccess).pipe(take(1)).subscribe(pendReqList => {
      this.pendingRequestList = pendReqList;

      this.pendingRequestList.forEach(req => {
        req["ID"] = req.requestId;
      })

      if (!this.userDevService.isSmallScreen && this.pendingRequestList.length) {
        if (selectRequisition) {
          this.selectedRequisition = this.pendingRequestList.find(pr => pr.requestId == selectRequisition.requestId);
        }

        this.setDataForGrid();

        if (!this.selectedRequisition) {
          this.selectedRequisition = this.pendingRequestList[0];
          this.gridReq.setSelected(this.gridReq.data[0].ID);
        }
      }

      this.loading = false;
    }, error => this.loading = false);
  }

  setDataForGrid() {
    let dtFormat = getLocaleDateFormat(this.locale, FormatWidth.Short);

    if (dtFormat.startsWith("M")) {
      this.dateFormat = "MM/dd/yy hh:mm a";
    } else {
      this.dateFormat = "dd/MM/yy HH:mm";
    }

    this.pendingRequestList.forEach(req => {
      req.elapsedTimeInMin = req.requestedDate ? Math.floor((new Date().getTime() - req.requestedDate.getTime()) / 60000) : undefined;
      req["ID"] = req.requestId;
    });

    this.intervalId = setInterval(() => {
      this.pendingRequestList.forEach(req => {
        req.elapsedTimeInMin = req.requestedDate ? Math.floor((new Date().getTime() - req.requestedDate.getTime()) / 60000) : undefined;
      })

    }, this.minToUpdate * 60000)

    this.gridReq.setGridData(this.pendingRequestList);
    this.gridReq.deselectAll();
  }

  configGrid() {
    this.gridContextComponent.contextMenu = [];
    this.sec.getDatasetActions("stk_requisicao").subscribe((actionList) => {
      actionList.forEach((action) => {
        if ([T2AccessItemDatasetActionType.DSACTION_OTHER,
        T2AccessItemDatasetActionType.DSACTION_SEARCH,
        T2AccessItemDatasetActionType.DSACTION_OTHERCLOSE,
        T2AccessItemDatasetActionType.DSACTION_VIEW,
        T2AccessItemDatasetActionType.DSACTION_EDIT].includes(action.datasetActionType)) {
          this.gridContextComponent.contextMenu.push({
            name: action.datasetActionDescription,
            requiresId: true,
            action: () => { this.executeDatasetAction(action) },
          });
        }
      });
    });

    this.gridReq.setGridColumnDefs([
      { headerName: "Seq", field: "sequence", width: 80, rowDrag: () => this.hasCoordinatorAccess && this.canChangeOrder },
      { headerName: "Código", field: "requestCode" },
      { headerName: "Dispositivo", field: "deviceDescription" },
      { headerName: "Tarefa", field: "taskDescription" },
      { headerName: "OP", field: "prodOrderCode" },
      { headerName: "OP Descr", field: "prodOrderDescription" },
      {
        headerName: "Tempo", field: "elapsedTimeInMin",
        valueGetter: (params) => {
          if (params.data.elapsedTimeInMin) {
            return new MinutesToHoursPipe().transform(params.data.elapsedTimeInMin);
          } else {
            return "-"
          }
        }
      },
      { headerName: "Item Req", field: "itemDescription" },
      { headerName: "Solicitado", field: "requestedQty", type: "numericColumn" },
      { headerName: "Reservado", field: "reservedQty", type: "numericColumn" },
      { headerName: "Transferido", field: "transferredQty", type: "numericColumn" },
      { headerName: "Consumido", field: "consumedQty", type: "numericColumn" },
      { headerName: "Solicitação", field: "requestedDate", type: "dateTimeColumn" },
      { headerName: "Solicitante", field: "requestedUser" },
      { headerName: "Equipe OP", field: "prodOrderSeparationTeam" },
      { headerName: "Planejado", field: "planningDate", type: "dateTimeColumn" },
      {
        headerName: "Equipe Separação",
        field: "separationTeamId",
        cellRenderer: "aggregationRenderer",
        cellEditor: "aggregationEditor",
        datasetId: "zD20201123H184442467R000000001",
        datasetName: "prd_equipeSeparacao",
        editable: this.hasCoordinatorAccess
      },
    ]);

    this.gridReq.t2GridOptions.onRowDragEnd = this.onGridRowDragEnd.bind(this);
    this.gridReq.t2GridOptions.suppressRowClickSelection = false;

    if (!this.gridReq.loadColumnGridState()) this.gridReq.autoSizeAllColumns(false);
  }

  private executeDatasetAction(action: T2AccessItem) {
    this.loadingData = true;
    const params = new Map<string, string>();
    const rows = this.gridReq.getRowsSelected();
    if (!rows.length && this.gridReq.getFocusedRow()) {
      rows.push(this.gridReq.getFocusedRow());
    }

    let promList = new Array<Promise<any>>()

    rows.forEach((row) => {
      params.set("id", row.ID);
      const obs$: Observable<any> = this.actionService.executeAction(action, params);
      if (obs$) {
        promList.push(obs$.pipe(take(1)).toPromise());
      }
    });

    Promise.all(promList).then(() => {
      this.loadingData = false;
      this.messageService.showToast("A ação foi executada", action.datasetActionDescription, "info", false);
    })

  }

  btnSearchClick() {
    this.loading = true;
    this.selectedRequisition = undefined;
    let prodOrderCode = this.inputSearch.formGroup.controls[this.inputSearch.t2IdCmp].value;

    if (prodOrderCode) {
      this.separationService.getProductionOrderPendingRequisitions(prodOrderCode).pipe(take(1)).subscribe(reqList => {
        this.pendingRequestList = reqList;
        this.reqTitle = `Requisições da OP ${prodOrderCode}`;
        this.canChangeOrder = false;

        if (!this.userDevService.isSmallScreen) {
          this.setDataForGrid();
        }

        this.loading = false;
      }, error => this.loading = false)
    } else {
      this.separationService.getPendingRequests(this.hasCoordinatorAccess).pipe(take(1)).subscribe(pendReqList => {
        this.pendingRequestList = pendReqList;
        this.reqTitle = "Requisições Pendentes";
        this.canChangeOrder = true;

        if (!this.userDevService.isSmallScreen) {
          this.setDataForGrid();
        }

        this.loading = false;
      }, error => this.loading = false);
    }
  }

  onDrop(event: CdkDragDrop<Array<TaskRequisition>>) {
    this.changeReqSequence(event.previousIndex, event.currentIndex);
  }

  private changeReqSequence(previousIndex: number, currentIndex: number) {
    this.loading = true;
    moveItemInArray(this.pendingRequestList, previousIndex, currentIndex);
    this.pendingRequestList.forEach((pr, index, arr) => {
      pr.sequence = index + 1;
    });

    this.separationService.saveRequestListOrder(this.pendingRequestList).pipe(take(1)).subscribe(() => {
      this.loading = false;
    }, error => {
      this.loading = false;
    })
  }

  onGridRowDragEnd(e: RowDragEndEvent) {
    if (e.node && e.overNode) {
      let prevIndex = this.pendingRequestList.findIndex(req => req.requestId == e.node.data.requestId);
      this.changeReqSequence(prevIndex, e.overIndex);
      this.gridReq.redrawRows();
    }
  }

  onReqClick(taskRequisition: TaskRequisition) {
    this.selectedRequisition = taskRequisition;

    if (this.userDevService.isSmallScreen) {
      this.startTransfer.emit(taskRequisition);
    }
  }

  loadingInfoTransfer(loading: boolean) {
    this.loadingTransfer = loading;
  }

  transferCompleted() {
    this.loadPendingRequests(this.selectedRequisition);
  }

  reservationExit() {
    this.loadingTransfer = true;
    this.separationService.getProductionOrderPendingRequisitions(this.selectedRequisition.prodOrderCode).pipe(take(1)).subscribe(reqList => {
      let sequence = this.selectedRequisition.sequence;
      let index = this.pendingRequestList.findIndex(r => r.requestId == this.selectedRequisition.requestId);

      this.pendingRequestList[index] = reqList.find(r => r.requestId == this.selectedRequisition.requestId);
      this.pendingRequestList[index].sequence = sequence;

      // Apenas para atualizar os volumes
      this.selectedRequisition = this.pendingRequestList[index];

      this.loadingTransfer = false;
    }, error => {
      this.loadingTransfer = false;
    })
  }

  onRowClicked(params) {
    this.onReqClick(params.data);
  }
}
