import { Component, OnInit, Input } from '@angular/core';
import { FilterField } from './model/filterField';

@Component({
  selector: 'app-filter-text',
  templateUrl: './filter-text.component.html',
  styleUrls: ['./filterTheme.scss']
})
export class FilterTextComponent implements OnInit {

  @Input() filterField: FilterField;

  constructor() { }

  ngOnInit(): void {
  }

}
