import { Subscription } from 'rxjs';
import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';

import { EChartsOption, EChartsType } from 'echarts';

import { WidgetService } from "../widget.service";
import { T2Widget } from '../model/t2widget';
import { T2ThemingService } from '../../t2theming.service';

@Component({
  selector: 'app-widget-chart',
  templateUrl: './widget-chart.component.html',
  styleUrls: ['./widget-chart.component.scss']
})
export class WidgetChartComponent implements OnInit {

  private _widget: T2Widget;
  private _params: {};

  themeName = "light";
  echartsIntance: EChartsType;
  chartOption: EChartsOption = {
    title: { text: 'Techprod Chart' },
    style: { height: '100%', width: '100%' },
    tooltip: { trigger: 'axis', axisPointer: { type: 'shadow' } },
    // dataZoom: [ { type: 'inside' }, { type: 'slider' } ],
    toolbox: {
      show: true,
      orient: 'vertical',
      left: 'right',
      top: 'top',
      feature: {
        // dataView: { show: false, readOnly: true, title: 'dados' },
        saveAsImage: { show: true, title: " " },
        restore: { show: true }
      }
    },
    // color: ['#007e54', '#015981', '#ce8807', '#e5323e'],
    legend: { data: ['A Serie', 'B Serie'] },
    //xAxis: { type: 'category', data: [], },
    //yAxis: { type: 'value', },
    angleAxis: {},
    radiusAxis: { type: 'category', data: ['Dev 1', 'Dev 2', 'Dev 3', 'Dev 4'], z: 3 },
    polar: {},
    series: [
      { type: 'bar', data: [1, 2, 3, 4], coordinateSystem: 'polar', name: 'A', stack: 'a' },
      { type: 'bar', data: [2, 4, 6, 8], coordinateSystem: 'polar', name: 'B', stack: 'a' },
      { type: 'bar', data: [1, 2, 3, 4], coordinateSystem: 'polar', name: 'C', stack: 'a' }],
    animationEasing: 'elasticOut',
    animationDelayUpdate: (idx: any) => idx * 3
  };

  @Input()
  get widget() { return this._widget; }
  set widget(value: T2Widget) {
    this._widget = value;
    this.updateContent();
  }

  @Input()
  get params() { return this._params; }
  set params(value: any) {
    const newParams = this.widgetService.mergeInputParams(this._widget, this._params, value);
    if (newParams.updated) {
      this._params = newParams.params;
      this.updateContent();
      this.echartsIntance.showLoading();
    }
  }

  @Output() widgetChange = new EventEmitter();

  constructor(
    themeService: T2ThemingService,
    private widgetService: WidgetService
  ) {
    themeService.getThemeNameObservable().subscribe(theme => this.themeName = theme);
  }

  ngOnInit(): void { }

  private updateContent() {
    if (this._widget && this._widget.id_techprodWidget) {
      this.widgetService.getWidgetContent(this._widget, this._params)
        .subscribe((content: string) => {

          if (content) {
            try {
              const newOption = JSON.parse(content);
              this.chartOption = { ...this.chartOption, ...newOption };
              if (this.echartsIntance) {
                this.echartsIntance.setOption(this.chartOption);
                this.echartsIntance.hideLoading();
              }
            } catch (error) {
              console.log(content, error);
            }
          }

        });
    }
  }

  onChartInit(ec: EChartsType) {
    this.echartsIntance = ec;
  }

}
