import { Warning } from "src/app/core/warning/model/warning";
import { ComplementaryField } from "../../../utils/model/complementary-field";

export class TaskPlan {
  apsId: number;
  id_task_plan: string;
  id_task: string;
  id_ordemProd: string | null;
  id_especificacao: string | null;
  startDate: Date;
  endDate: Date;
  started: boolean;
  fixedDate: boolean;
  minDate: Date;
  maxDate: Date;
  task: string;
  id_device: string;
  device: string;
  prodOrder: string;
  prodOrdDescription: string;
  prodOrdMinDate: Date;
  prodOrdMaxDate: Date;
  client: string;
  estimatedRunDuration: number;
  estimatedSetupDuration: number;
  percProgress: number;
  incompatibilityNextPlan: number;
  complementaryFields: Array<ComplementaryField>;
  predecessorTaskIds: Array<string>;
  successorTaskIds: Array<string>;
  prodInterval?: Array<{ startDate: Date, endDate: Date }>;
  cylinderStatus?: Map<String, number>;
  warningList?: Array<Warning>;
  urgent?: boolean;
}
