import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-t2-button',
  templateUrl: './t2-button.component.html',
  styleUrls: ['./t2-button.component.scss']
})
export class T2ButtonComponent implements OnInit {

  @Input() t2IdCmp: string;
  @Input() value: string;
  @Input() status: string;
  @Input() size: string;
  @Input() disabled: boolean;
  @Input() ngStyle: any;
  @Input() icon: string;

  @Output() clicked = new EventEmitter<any>();

  constructor() {
  }

  ngOnInit(): void {
    this.status = this.status ?? "primary";
    this.size = this.size ?? "tiny";

    if (this.disabled)
      this.disabled = this.disabled ?? false;
  }

  onClick(event: any) {
    this.clicked.emit(event);
  }

}
