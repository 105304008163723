<nb-card accent="info" style="min-height: 75vh; max-height: 75vh; padding: 0px;"
  [ngStyle]="{'min-width': '70vw', 'max-width':'70vw'}" [ngStyle.xs]="{'max-width': '90vw'}">
  <nb-card-header style="display: flex; flex-direction: row; ">
    <div style="flex: 1 1 auto">Menu</div>
    <div style="flex: 0 1 auto">
      <div class="selectedGroupName">
        {{selectedGroup?.name}}
      </div>
      <nb-form-field class="searchSmallWindow">
        <nb-icon nbSuffix icon="search" pack="eva"></nb-icon>
        <input id="searchInputSmall" type="text" #searchInputSmall nbInput fieldSize="small" [disabled]="loading"
          (keyup)="onSearchChange($event.target['value'])">
      </nb-form-field>
    </div>
  </nb-card-header>

  <nb-card-body style="display: flex; padding: 0px;">

    <div *ngIf="loading" [nbSpinner]="loading" style="width: 100%;">
    </div>
    <div *ngIf="!loading && accessGroupsOriginal && accessGroupsOriginal.length === 0" style="width: 100%;">
      <div style="margin: 30px;">
        <h2>Ops...</h2>
        <h5>
          Você ainda não possui acesso a nenhuma ação do Techprod<br>
          Solicite os seus acessos ao coordenador da área ou o responsável pelo sistema.
        </h5>
      </div>
    </div>

    <!-- Módulos - LARGE WINDOW -->
    <div *ngIf="!loading && accessGroupsOriginal && accessGroupsOriginal.length > 0" class="groupList">
      <div style=" display: flex; flex-direction: column;  gap: 10px; width: 280px; border-radius: 5px 0px 0px 5px;">

        <nb-form-field style="margin-right: 25px;">
          <nb-icon nbSuffix icon="search" pack="eva"></nb-icon>
          <input id="searchInput" type="text" #searchInput nbInput fieldSize="small" [ngStyle.xs]="{'width': '190px'}"
            [disabled]="loading" (keyup)="onSearchChange($event.target['value'])">
        </nb-form-field>

        <div style="display: flex; flex-direction: column; gap: 3px; overflow: auto;">
          <div *ngFor="let group of accessGroups" class="module" [ngClass]="{'T2GrayArea': group.selected}"
            (click)="groupClick(group)">
            <div style="width: 10px;">
              <div class="selected" [ngStyle]="{'display': group.selected ? 'flex' : 'none'}"></div>
            </div>
            {{ group.index > 0 ? "" : "#"}} {{group.name}}
          </div>
        </div>
      </div>
      <div class="T2GrayArea" style="height: 100%; width: 3px;"></div>
    </div>

    <!-- Ações dos módulos  - LARGE WINDOW -->
    <div *ngIf="!loading && accessGroupsOriginal && accessGroupsOriginal.length > 0" class="subGroupList">
      <div *ngFor="let group of accessGroups">
        <div *ngIf="group.selected && group.accessList.length" class="flexColumn" style="gap: 5px">

          <div *ngFor="let subMenu of group.accessSubMenu" class="subGroup">
            <div class="subGroupName"> {{subMenu.name}} </div>

            <div class="groupItemList">
              <div *ngFor="let item of group.accessList">
                <div *ngIf="item.subGroupIndex == subMenu.index" class="groupItem">
                  <div [class.groupItemTypeBlue]="item.informationType === 0"
                    [class.groupItemTypeGreen]="item.informationType === 1"
                    [class.groupItemTypeGray]="item.informationType === 2"
                    [class.groupItemTypeLightBlue]="item.informationType === 3"
                    [class.groupItemTypePurple]="item.informationType === 4"
                    [class.groupItemTypeOrange]="item.informationType === 5">
                    <div (click)="$event.preventDefault()">
                      <nb-icon *ngIf="item.favItem" style="display: flex; color: #ffbf00;" class="groupItemIcon"
                        icon="star" (click)="setFavItem(item);"></nb-icon>

                      <nb-icon *ngIf="!item.favItem" style="color: rgba(255, 255, 255, 0.408);" class="groupItemIcon"
                        icon="star-outline" (click)="setFavItem(item);"></nb-icon>
                    </div>
                  </div>
                  <div class="groupItemText" [class.groupItemTextBlue]="item.informationType === 0"
                    [class.groupItemTextGreen]="item.informationType === 1"
                    [class.groupItemTextGray]="item.informationType === 2"
                    [class.groupItemTextLightBlue]="item.informationType === 3"
                    [class.groupItemTextPurple]="item.informationType === 4"
                    [class.groupItemTextOrange]="item.informationType === 5" (click)="selectItem(item);">
                    <div>{{ item.subGroupIndex > 0 ? "" : "#"}} {{item.name}}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- SMALL WINDOW -->
    <div class="smallGroupList">
      <div *ngFor="let group of accessGroups">
        <div class="groupTitle" style="background-color: #8080800f; cursor: auto;"> {{ group.name }} </div>
        <div *ngFor="let item of group.accessList" class="groupItem" (click)="selectItem(item);">
          <div [class.groupItemTypeBlue]="item.informationType === 0"
            [class.groupItemTypeGreen]="item.informationType === 1"
            [class.groupItemTypeGray]="item.informationType === 2"
            [class.groupItemTypeLightBlue]="item.informationType === 3"
            [class.groupItemTypePurple]="item.informationType === 4"
            [class.groupItemTypeOrange]="item.informationType === 5">
          </div>
          <div class="groupItemText" style="height: 37px" [class.groupItemTextBlue]="item.informationType === 0"
            [class.groupItemTextGreen]="item.informationType === 1"
            [class.groupItemTextGray]="item.informationType === 2"
            [class.groupItemTextLightBlue]="item.informationType === 3"
            [class.groupItemTextPurple]="item.informationType === 4"
            [class.groupItemTextOrange]="item.informationType === 5">
            <div>{{item.name}}</div>
          </div>
        </div>
      </div>
    </div>


    <div>
    </div>

  </nb-card-body>

  <nb-card-footer style="padding: 0px !important;">
    <div style="display: flex; margin-top: auto; font-size: 9px">

      <div class="cilSmallBox lightBlueSquare"></div>
      <div>Configuração</div>

      <div class="cilSmallBox blueSquare"></div>
      <div>Cadastro</div>

      <div class="cilSmallBox greenSquare"></div>
      <div>Operação</div>

      <div class="cilSmallBox orangeSquare"></div>
      <div>Painel</div>

      <div class="cilSmallBox purpleSquare"></div>
      <div>Relatório</div>

      <div class="cilSmallBox graySquare"></div>
      <div>Utilitário</div>
    </div>
  </nb-card-footer>

</nb-card>
