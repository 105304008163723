import { getLocaleNumberSymbol, NumberSymbol } from "@angular/common";
import { AfterViewInit, Component, Inject, Input, LOCALE_ID, ViewChild, ViewContainerRef, OnInit, Output, EventEmitter } from '@angular/core';
import { FormControl, Validators } from "@angular/forms";
import { AgEditorComponent } from "ag-grid-angular";
import { ICellEditorParams } from "ag-grid-community";

@Component({
  selector: 'editor-cell',
  template: `
     <input #input
            nbInput fieldSize="small" fullWidth
            type="text"
            [formControl]="numberControl"
            style="width: 100%; height: 100%; text-align: right"
            (keypress)="keyPressNumbersDecimal($event)"
     />
  `,
})
export class NumericEditorComponent implements AgEditorComponent, AfterViewInit, OnInit {


  private decimalSeparator = ','; // virgula
  numberControl = new FormControl('', []);

  @Input('editorValue')
  get editorValue(): Number {
    const value: string = this.numberControl.value;

    if (value === "") return null
    else return Number(value.replace(this.decimalSeparator, "."));
   }
  set editorValue(v: Number) {
    this.numberControl.patchValue((v + '').replace(".", this.decimalSeparator));
   }

  private _required = false;
   @Input('required')
   get required(): boolean {
     return this._required;
   }
   set required(v: boolean) {
     this._required = v;
     this.updateValidators();
   }

  @Output() changed = new EventEmitter();

  @ViewChild('input', { read: ViewContainerRef }) public input: ViewContainerRef;

  constructor(@Inject(LOCALE_ID) public locale: string) {
    const decimalSep: string = getLocaleNumberSymbol(locale, NumberSymbol.Decimal);
    this.decimalSeparator = decimalSep;
  }

  ngAfterViewInit() {
    this.setFocus();
  }

  setFocus() {
    // focus on the input
    setTimeout(() => {
      this.input.element.nativeElement.focus();
      this.input.element.nativeElement.select();
    });
  }

  ngOnInit(){
    this.numberControl.valueChanges.subscribe(changes => {
      this.changed.emit(this.editorValue);
    });
  }

  agInit(params: ICellEditorParams): void {

    let value = params.value;

    if (params.charPress && this.validChar(params.charPress.charCodeAt(0))) {
      value = params.charPress;
    }

    this.editorValue = Number(value);
    //this.numberControl.patchValue((value + '').replace(".", this.decimalSeparator));
  }

  /* Component Editor Lifecycle methods */

  // the final value to send to the grid, on completion of editing
  getValue() {
    return this.editorValue;
    //const value = this.numberControl.value;
    //return value.replace(this.decimalSeparator, ".");
  }

  // Gets called once before editing starts, to give editor a chance to
  // cancel the editing before it even starts.
  isCancelBeforeStart() {
    return false;
  }

  // Gets called once when editing is finished (eg if enter is pressed).
  // If you return true, then the result of the edit will be ignored.
  isCancelAfterEnd() {
    // our editor will reject any value greater than 1000
    return false;
  }

  private validChar(charCode): boolean {
    if ((charCode >= 48 && charCode <= 57) || charCode === 31) {
      return true;
    } else if (charCode === this.decimalSeparator.charCodeAt(0)) {
      const currentValue: string = this.numberControl.value + '';
      if (!currentValue || currentValue.indexOf(this.decimalSeparator) === -1) {
        return true;
      }
    }

    return false;
  }


  keyPressNumbersDecimal(event) {

    // Does not work on cellphones :(

    const charCode = (event.which) ? event.which : event.keyCode;
    if (this.validChar(charCode)) {
      return true;
    }

    event.preventDefault();
    return false;
  }

  private updateValidators(): void {
    const val = [];
    if (this._required) {
      val.push(Validators.required);
    }

    this.numberControl.setValidators(val);
  }
}
