<div style="height: 100%;display: flex;flex-direction: column;">
  <div>
    <div style="display: flex;">
      <!-- <input type="date" class="espacamento" #inputStartDate nbInput fieldSize="small" id="inputStartDate"
        (change)="onChange($event)" />
      <input type="date" class="espacamento" #inputEndDate nbInput fieldSize="small" id="inputEndDate"
        (change)="onChange($event)" />
      <button class="espacamento" nbButton size="tiny" status="primary" [disabled]="!startDate || !endDate"
        (click)="loadData()">
        Pesquisar
      </button> -->

      <input nbInput placeholder="Dt inicial" [nbDatepicker]="formpicker1" [formControl]="dateStartControl"
        fieldSize="tiny" style="max-width: 150px">
      <nb-datepicker #formpicker1 [max]="dateEndControl.value" [format]="format"></nb-datepicker>
      <input nbInput placeholder="Dt final" [nbDatepicker]="formpicker2" [formControl]="dateEndControl" fieldSize="tiny"
        style="max-width: 150px; margin-left: 5px;">
      <nb-datepicker #formpicker2 [min]="dateStartControl.value" [format]="format"></nb-datepicker>

      <button style="margin-left: 5px" nbButton size="tiny" status="primary"
        [disabled]="!dateStartControl.value || !dateEndControl.value"
        nbSpinnerStatus="primary" nbSpinnerSize="tiny" [nbSpinner]="loading" (click)="loadData()">
        <nb-icon icon="refresh-outline"></nb-icon>

      </button>

      <button class="espacamento" style="margin-left: auto;" nbButton size="tiny" status="primary"
        (click)="printEngraving()">
        <nb-icon icon="printer-outline"></nb-icon>
        Reimprimir
      </button>
    </div>
  </div>

  <div style="height: 100%;" class="espacamento">
    <app-t2grid class="gridArea" #gridEngraving id="gridEngraving" rowSelection="multiple" [sortable]="true">
    </app-t2grid>
  </div>
</div>
