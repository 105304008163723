import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { DeviceOperationInput } from "../../model/device-operation-input";
import { FormBuilder, FormGroup } from "@angular/forms";
import { LayoutType, ViewTemplateElement } from "src/app/core/cmp/view-template/model/view-template-element";
import { T2InputFloatComponent } from "src/app/core/cmp/ui/t2-input-float/t2-input-float.component";
import { DeviceTaskPlan, DeviceTaskPlanCmp, ShopFloorService } from "../../shop-floor.service";
import { T2InputTextComponent } from "src/app/core/cmp/ui/t2-input-text/t2-input-text.component";
import { DeviceRegistrationInputType } from "../model/device-registration-input-type";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/Operators";

@Component({
  selector: 'app-appointment-register-quantity',
  templateUrl: './appointment-register-quantity.component.html',
  styleUrls: ['./appointment-register-quantity.component.scss']
})
export class AppointmentRegisterQuantityComponent implements OnDestroy {

  @Input()
  get taskPlan(): DeviceTaskPlan {
    return this._taskPlan;
  }
  set taskPlan(value: DeviceTaskPlan) {
    this._taskPlan = value;
    this.generateForm();
  }
  @Input()
  get inputList(): Array<DeviceOperationInput> {
    return this._inputList;
  }
  set inputList(value: Array<DeviceOperationInput>) {
    this._inputList = value;
    this.generateForm();
  }

  @Output() disableConfirm = new EventEmitter<void>();

  private _inputList: Array<DeviceOperationInput>;
  private _taskPlan: DeviceTaskPlan;
  private unsubscribe = new Subject<void>();
  public loaded: boolean = false;
  public layout: Array<ViewTemplateElement>;
  public formGroup: FormGroup;
  public devRegInputList: Array<DeviceRegistrationInputType> = [];
  private unsubscribeAutoInput = new Subject<void>();

  constructor(private formBuilder: FormBuilder, private sfService: ShopFloorService) { }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
    this.unsubscribeAutoInput.next();
    this.unsubscribeAutoInput.complete();
  }

  private generateForm() {
    if (this.taskPlan && this.inputList?.length) {
      this.loaded = false;
      this.unsubscribeAutoInput.next();

      this.formGroup = this.formBuilder.group({});

      this.layout = [
        {
          layoutType: LayoutType.listLayout,
          direction: "column",
          children: []
        }
      ];

      let cmpList = this.layout[0].children;

      this.inputList.forEach(input => {
        let tpCmpList: Array<DeviceTaskPlanCmp>;
        if (["Consumption", "ConsumptionReturn"].includes(input.functionality)) {
          tpCmpList = this.taskPlan.cmpConsList;
        } else {
          tpCmpList = this.taskPlan.cmpProdList;
        }

        tpCmpList.forEach(tpCmp => {
          let index = cmpList.push({
            layoutType: LayoutType.gridLayout,
            title: tpCmp.descriptionCmp,
            children: []
          });

          let cmp = cmpList[index - 1].children;
          let convFactor: number;
          let unitFound: boolean = false;

          if (tpCmp.unitSymbol == input.unitSymbol || !tpCmp.unitId) {
            convFactor = 1;
            unitFound = true;
          } else {
            let convUn = tpCmp.unitList?.find(un => un.unitSymbol == input.unitSymbol);

            if (convUn) {
              convFactor = 1 / convUn.factor;
              unitFound = true;
            }
          }

          let regInput = new DeviceRegistrationInputType();
          regInput.id_component = tpCmp.prodOrderCmpId;
          regInput.id_item = tpCmp.itemId;
          regInput.id_operationGroup_it = input.id_operationGroup_it;
          regInput.id_operationGroup_op_it = input.id_operationGroup_op_it;
          regInput.tare = tpCmp.tare;
          regInput.value = 0;

          let controlId = `${tpCmp.prodOrderCmpId}_${input.id_operationGroup_op_it}`;

          this.devRegInputList.push(regInput);

          if (!unitFound) {
            cmp.push({
              layoutType: LayoutType.component,
              cmpType: T2InputTextComponent,
              cmpName: controlId,
              title: `${input.inputLabel} (${input.unitSymbol})`,
              isBaseComponent: true,
              columnSpan: 6,
              inputs: {
                readOnly: true,
              }
            });

            this.formGroup.addControl(controlId, this.formBuilder.control(`Não é possível apontar pois é necessário cadastrar um fator de conversão de ${input.unitSymbol} para ${tpCmp.unitSymbol}`));
          } else {
            let additionalInfo: string = "";

            if (input.showCurrentQty) {
              additionalInfo = `R: ${Number(tpCmp.taskPlanQtyActual / convFactor).toFixed(input.decimals)}`;
            }

            if (input.showEstimatedQty) {
              if (additionalInfo) {
                additionalInfo += ` / P: ${Number(tpCmp.taskPlanQtyPrev / convFactor).toFixed(input.decimals)}`;
              } else {
                additionalInfo = `P: ${Number(tpCmp.taskPlanQtyPrev / convFactor).toFixed(input.decimals)}`
              }
            }

            cmp.push({
              layoutType: LayoutType.component,
              cmpType: T2InputFloatComponent,
              cmpName: controlId,
              title: `${input.inputLabel} (${input.unitSymbol})`,
              isBaseComponent: true,
              columnSpan: 2,
              inputs: {
                additionalInfo: additionalInfo,
                decimalQty: input.decimals
              }
            });

            this.formGroup.addControl(controlId, this.formBuilder.control(0));

            if (input.autoInputId) {
              this.sfService.getAutoDataObservable(input.autoInputId).pipe(takeUntil(this.unsubscribeAutoInput)).subscribe(value => {
                this.formGroup.controls[controlId].setValue(Number(Number(value).toFixed(input.decimals)));
              })
            }

            this.formGroup.controls[controlId].valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe(value => {
              let reg = this.devRegInputList.find(inp => `${inp.id_component}_${inp.id_operationGroup_op_it}` == controlId);
              reg.value = value;
            })
          }
        });

        if (!tpCmpList.length) {
          this.disableConfirm.emit();
          cmpList.push({
            layoutType: LayoutType.listLayout,
            title: "Não há componente definido para essa atividade, reveja o cadastro da OP"
          });
        }
      })

      this.loaded = true;
    }
  }
}
