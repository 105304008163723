import { Injectable } from '@angular/core';
import { NbToastrService, NbGlobalLogicalPosition, NbDialogService, NbDialogRef, NbIconConfig, NbToastrConfig } from "@nebular/theme";
import { DialogComponent } from "./cmp/dialog/dialog.component";

@Injectable({
  providedIn: 'root'
})
export class T2MessageService {

  constructor(private toastrService: NbToastrService, private dialogService: NbDialogService) { }

  showToastError(message: string) {
    this.toastrService.show(message, "Atenção", { limit: 4, status: 'danger', position: NbGlobalLogicalPosition.TOP_END });
  }

  showToast(message: string, title: string, status: 'basic' | 'primary' | 'success' | 'warning' | 'danger' | 'info' | 'control', showIcon?: boolean, icon?: string) {
    let config: Partial<NbToastrConfig> = {
      limit: 4,
      status,
      position: NbGlobalLogicalPosition.TOP_END
    }

    if (showIcon == false) {
      let iconConfig: NbIconConfig = { icon: "", pack: "eva" };
      config.icon = iconConfig
    } else if (icon) {
      let iconConfig: NbIconConfig = { icon: icon, pack: "eva" };
      config.icon = iconConfig
    }

    this.toastrService.show(message, title, config);
  }

  showDialog(pUserConfig): NbDialogRef<any> {
    return this.dialogService.open(DialogComponent, pUserConfig);
  }
}
