<div style="height: 100%; width: 100%; display: flex; align-items: center; justify-content: center; gap: 5px">

  <nb-icon class="selection" [ngClass]="{'deselection': selected, 'selection': !selected}"
    [icon]="selected ? 'checkmark-circle-2-outline' : 'radio-button-off-outline'"
    [nbTooltip]="selected ? 'Remover a atribuição da EP à conjugação' : 'Atribuir a EP à conbjugação'"
    (click)="setProdSpecifForConj()" />

  <nb-icon class="edit" icon="edit-outline" nbTooltip="Abrir EP" (click)="openProdSpecif()" />

</div>
