<app-dialog-template #dialog [title]="title">
  <content style="width: 55vw; max-width: 55vw; height: 45vh; max-height: 45vh;" [nbSpinner]="!loaded"
    nbSpinnerStatus="primary" nbSpinnerSize="giant">
    <div *ngIf="loaded" class="flexColumn flexGap5">
      <div *ngIf="!structureLayerEdit" class="flexRow flexWrap flexGap5">
        <app-t2-button size="tiny" [status]="layerType == 'CHEMICAL' ? 'primary' : 'basic'" value="Químico"
          (click)="changeLayerType('CHEMICAL')"></app-t2-button>
        <app-t2-button size="tiny" [status]="layerType == 'SUBSTRATE' ? 'primary' : 'basic'" value="Substrato"
          (click)="changeLayerType('SUBSTRATE')"></app-t2-button>
        <app-t2-button size="tiny" [status]="layerType == 'METALIZATION' ? 'primary' : 'basic'" value="Metalização"
          (click)="changeLayerType('METALIZATION')"></app-t2-button>
        <app-t2-button size="tiny" [status]="layerType == 'EXTRUSION' ? 'primary' : 'basic'" value="Extrusão"
          (click)="changeLayerType('EXTRUSION')"></app-t2-button>
      </div>

      <div *ngIf="layerType" gdColumns="repeat(auto-fill, minmax(75px, 1fr))" gdGap="5px">
        <div *ngIf="layerType == 'CHEMICAL'" gdColumn="3 span">
          <span class="cmpLabel">Substrato</span>
          <app-t2-select t2IdCmp="id_especif_carac_estr_i_subst" [formGroup]="formGroup"
            [list]="substrateList"></app-t2-select>
        </div>

        <div *ngIf="layerType == 'CHEMICAL'" gdColumn="2 span">
          <span class="cmpLabel">Tipo</span>
          <app-t2-select t2IdCmp="tipo" [formGroup]="formGroup" [list]="chemicalTypeList"></app-t2-select>
        </div>

        <div *ngIf="layerType == 'CHEMICAL'" gdColumn="2 span">
          <span class="cmpLabel">Lado</span>
          <app-t2-select t2IdCmp="lado" [formGroup]="formGroup" [list]="chemicalSideList"></app-t2-select>
        </div>

        <div *ngIf="layerType == 'CHEMICAL' && formGroup.controls['tipo'].value == 'Cor'" gdColumn="2 span">
          <span class="cmpLabel">Impressão</span>
          <app-t2-select t2IdCmp="impressao" [formGroup]="formGroup" [list]="chemicalPrintList"></app-t2-select>
        </div>

        <div *ngIf="layerType == 'SUBSTRATE'" gdColumn="3 span">
          <span class="cmpLabel">Tipo de Substrato</span>
          <app-t2-tree-grid-input #substrateTreeGrid t2IdCmp="id_item_3" [formGroup]="formGroup"
            [datasetName]="'stk_item_produto_3'"
            [description]="structureLayerEdit?.substrate.classif"></app-t2-tree-grid-input>
        </div>

        <div *ngIf="formGroup.controls['tipo'].value != 'Cor'" gdColumn="2 span">
          <span class="cmpLabel">Gramatura</span>
          <app-t2-input-float t2IdCmp="gramatura" [formGroup]="formGroup"></app-t2-input-float>
        </div>

        <div gdColumn="2 span">
          <span class="cmpLabel">Gramatura Mínima</span>
          <app-t2-input-float t2IdCmp="gramMinima" [formGroup]="formGroup"></app-t2-input-float>
        </div>

        <div gdColumn="2 span">
          <span class="cmpLabel">Gramatura Máxima</span>
          <app-t2-input-float t2IdCmp="gramMaxima" [formGroup]="formGroup"></app-t2-input-float>
        </div>

        <div *ngIf="layerType == 'CHEMICAL'" gdColumn="3 span">
          <span class="cmpLabel">Família de Químicos</span>
          <app-t2-tree-grid-input #chemicalTreeGrid t2IdCmp="id_quimico_3" [formGroup]="formGroup"
            [datasetName]="'prt_quimicos_3'"
            [description]="structureLayerEdit?.chemical.chemicalDescription"></app-t2-tree-grid-input>
        </div>

        <div *ngIf="layerType == 'CHEMICAL' && formGroup.controls['tipo'].value == 'Adesivo'" gdColumn="2 span">
          <span class="cmpLabel">Tempo Cura</span>
          <app-t2-input-float t2IdCmp="tempoCura" [formGroup]="formGroup"></app-t2-input-float>
        </div>
      </div>
    </div>
  </content>
  <footer style="display: flex; justify-content: flex-end; align-items: center; gap: 5px">
    <button nbButton size="tiny" (click)="dialogRef.close(false)">Cancelar</button>
    <button nbButton size="tiny" status="primary" (click)="dialogRef.close(true)">Confirmar</button>
  </footer>
</app-dialog-template>
