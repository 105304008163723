import { ActivatedRouteSnapshot } from '@angular/router';

export class T2Route {
  path: string;
  originalPath: string;
  queryParams: Map<string, string>;
  pathParams: Map<string, string>;
  routeSnapshot: ActivatedRouteSnapshot;

  constructor() {
    this.queryParams = new Map<string, string>();
    this.pathParams = new Map<string, string>();
  }
}
