import { Injectable } from '@angular/core';
import { map, take } from "rxjs/Operators";
import { T2HttpClientService } from "src/app/core/http/t2httpClient.service";
import { RequisitionVolume } from "./model/requisition-volume";
import { Observable } from "rxjs";
import { ReservationRequisition } from "./model/reservation-requisition";

@Injectable({
  providedIn: 'root'
})
export class T2ReservationService {

  constructor(private httpClient: T2HttpClientService) { }

  public loadVolumes(requisitionId: string): Observable<Array<RequisitionVolume>> {
    let params = new Map<string, string>();
    params.set("id_requisition", requisitionId);

    return this.httpClient.get("stk.reservationRequisition/requisitionsVolumes", params)
      .pipe(take(1),
        map(resp => {
          let volumeList = new Array<RequisitionVolume>();
          if (resp.requisitionsVolumes) {
            if (!Array.isArray(resp.requisitionsVolumes)) {
              resp.requisitionsVolumes = [resp.requisitionsVolumes];
            }

            volumeList = resp.requisitionsVolumes;

            volumeList.forEach(vol => {
              vol.quantityAvailable = new Number(vol.quantityAvailable || 0).valueOf();
              vol.quantityRequisition = new Number(vol.quantityRequisition || 0).valueOf();
              vol.quantityReserved = new Number(vol.quantityReserved || 0).valueOf();
              vol.quantityStock = new Number(vol.quantityStock || 0).valueOf();
            })
          }

          return volumeList
        }));
  }

  public loadRequisitions(itemId: string, requisitionType: string): Observable<Array<ReservationRequisition>> {
    let params = new Map();
    params.set("id_item", itemId);
    params.set("tipoRequisicao", requisitionType);

    return this.httpClient.get("stk.reservationRequisition/requisitions", params)
      .pipe(take(1),
        map(resp => {
          let requisitionList = new Array<ReservationRequisition>();
          if (resp.requisitionList) {
            if (!Array.isArray(resp.requisitionList)) {
              resp.requisitionList = [resp.requisitionList];
            }

            requisitionList = resp.requisitionList;

            for (let req of requisitionList) {
              req.consumedQty = new Number(req.consumedQty || 0).valueOf();
              req.requestedQty = new Number(req.requestedQty || 0).valueOf();
              req.transferredQty = new Number(req.transferredQty || 0).valueOf();
              req.requiredDate = new Date(req.requiredDate) || undefined;
            }
          }

          return requisitionList;
        }));
  }
}
