import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { T2NebularModule } from "src/app/t2nebular.module";
import { T2CoreModule } from "src/app/core/t2core.module";
import { BudgetPolicyComponent } from './budget-policy/budget-policy.component';
import { BudgetRuleComponent } from './budget-policy/budget-rule/budget-rule.component';
import { ComponentService } from "src/app/core/cmp/component.service";



@NgModule({
  declarations: [
    BudgetPolicyComponent,
    BudgetRuleComponent
  ],
  imports: [
    CommonModule,
    T2NebularModule,
    T2CoreModule,
  ]
})
export class CostModule { 
  constructor(private cmpService: ComponentService) {
    this.cmpService.registerType("BudgetPolicyComponent", BudgetPolicyComponent);
  }
}
