import { Component, Input, OnDestroy, ViewChild } from '@angular/core';
import { CilindroCor } from "../../model/cilindro-cor";
import { LayoutType, ViewTemplateElement } from "src/app/core/cmp/view-template/model/view-template-element";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { T2SelectComponent } from "src/app/core/cmp/ui/t2-select/t2-select.component";
import { NbDialogRef } from "@nebular/theme";
import { Subject } from "rxjs";
import { pairwise, startWith, takeUntil } from "rxjs/Operators";

@Component({
  selector: 'app-cylinder-swap',
  templateUrl: './cylinder-swap.component.html',
  styleUrls: ['./cylinder-swap.component.scss']
})
export class CylinderSwapComponent implements OnDestroy {
  @ViewChild('viewTemplate', { static: true }) viewTemplate;

  @Input()
  get colorList(): Array<CilindroCor> {
    return this._colorList;
  }
  set colorList(value: Array<CilindroCor>) {
    this._colorList = value;
    this.loadData();
  }

  private _colorList: Array<CilindroCor>;
  private componentList = new Array<ViewTemplateElement>();
  private unsubscribe = new Subject<void>();
  public layout: Array<ViewTemplateElement> = [
    {
      layoutType: LayoutType.listLayout,
      direction: "column",
      children: [
        {
          layoutType: LayoutType.gridLayout,
          children: this.componentList
        }
      ]
    }
  ];

  public formGroup: FormGroup;

  constructor(private formBuilder: FormBuilder, public dialogRef: NbDialogRef<CylinderSwapComponent>) {
    this.formGroup = this.formBuilder.group({});
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  private loadData() {
    this.componentList.length = 0;
    this.formGroup.removeAllControls();
    this.unsubscribe.next();

    let cylinderList = this.colorList?.map(color => {
      return {
        id: color.id_item_cylinder,
        description: color.cylinderCode
      }
    });

    this.colorList?.forEach(color => {
      this.formGroup.addControl(color.id_ordemProd_cilindro, this.formBuilder.control(color.id_item_cylinder, [Validators.required]));

      this.componentList.push({
        layoutType: LayoutType.component,
        cmpName: color.id_ordemProd_cilindro,
        title: color.color,
        cmpType: T2SelectComponent,
        columnSpan: 2,
        isBaseComponent: true,
        inputs: {
          list: cylinderList
        }
      });

      this.formGroup.controls[color.id_ordemProd_cilindro].valueChanges
        .pipe(takeUntil(this.unsubscribe),)
        .subscribe(id_item => {
          let swapReg = this.colorList.find(c => c.id_item_cylinder == id_item);
          let reg = this.colorList.find(c => c.id_ordemProd_cilindro == color.id_ordemProd_cilindro);
          let cylCode = swapReg.cylinderCode;
          
          swapReg.id_item_cylinder = reg.id_item_cylinder;
          swapReg.cylinderCode = reg.cylinderCode;

          reg.id_item_cylinder = id_item;
          reg.cylinderCode = cylCode;

          this.formGroup.controls[swapReg.id_ordemProd_cilindro].setValue(swapReg.id_item_cylinder, { emitEvent: false });
        })
    });
  }

  closeWindow(confirm: boolean) {
    if (confirm) {
      Object.keys(this.formGroup.controls).forEach(key => {
        let color = this.colorList.find(color => color.id_ordemProd_cilindro == key);
        color.id_item_cylinder = this.formGroup.controls[key].value;
      })
    }

    const result = { confirm: confirm, colorList: this.colorList };
    this.dialogRef.close(result);
  }
}
