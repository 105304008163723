import { Component, Input, OnInit, TemplateRef, ViewChild, OnDestroy, AfterContentInit } from '@angular/core';
import { NbDialogRef, NbDialogService } from "@nebular/theme";
import { Subscription } from "rxjs";
import { take } from "rxjs/Operators";
import { DialogTemplateComponent } from "src/app/core/cmp/dialog-template/dialog-template.component";
import { DatasetTemplateComponent, ExtType } from "src/app/core/dataset/dataset-template.component";
import { T2HttpClientService } from "src/app/core/http/t2httpClient.service";
import { T2BaseComponent } from "../t2-base-component";
import { T2SecurityService } from "src/app/core/security/t2security.service";
import { T2AccessItem, T2AccessItemDatasetActionType } from "src/app/core/security/model/t2accessItem";
import { ActionService } from "src/app/core/action/action.service";
import { DialogItemSelectionComponent, GroupSelection, ItemSelection } from "../../dialog-item-selection/dialog-item-selection.component";
import { T2MessageService } from "src/app/core/t2-message.service";
import { ConditionField, VisualizationField } from "src/app/core/dataset/visualization/t2-visualization.service";

@Component({
  selector: 'app-t2-aggregation',
  templateUrl: './t2-aggregation.component.html',
  styleUrls: ['./t2-aggregation.component.scss'],
})
export class T2AggregationComponent extends T2BaseComponent implements OnInit, OnDestroy, AfterContentInit {

  private _datasetId: string;
  private _datasetName: string;

  @Input()
  get description(): string { return this.aggregationInfo.description; }
  set description(value: string) { this.aggregationInfo.description = value; }
  @Input()
  get datasetName(): string { return this?._datasetName; }
  set datasetName(value: string) { this._datasetName = value; }
  @Input()
  get datasetId(): string { return this._datasetId; }
  set datasetId(value: string) { this._datasetId = value; }

  @Input()
  get aggregationCondPropId(): string { return this.aggregationInfo.condPropertId; }
  set aggregationCondPropId(value: string) { this.aggregationInfo.condPropertId = value; }
  @Input()
  get aggregationCondPropertyName(): string { return this.aggregationInfo.condPropertyName; }
  set aggregationCondPropertyName(value: string) { this.aggregationInfo.condPropertyName = value; }
  @Input()
  get aggregationCondPropValue(): any { return this.aggregationInfo.condPropertyValue };
  set aggregationCondPropValue(value: any) {
    this.aggregationInfo.condPropertyValue = value;
    this.datasetTemplateParentId = value;

    if (value) {
      if (!this.aggregationInfo.fixedFilter) this.aggregationInfo.fixedFilter = [];

      this.aggregationInfo.fixedFilter = this.aggregationInfo.fixedFilter.filter(i => i.property != this.aggregationInfo.condPropertyName );
      this.aggregationInfo.fixedFilter.push({ property: this.aggregationInfo.condPropertyName, value: value, operator: "EQUALS" });
    }
  }
  @Input() aggregationDatasetDescription: string;
  @Input('fixedFilter')
  get fixedFilter(): ConditionField[] {
    return this.aggregationInfo.fixedFilter
  }
  set fixedFilter(value: ConditionField[]) {

    if (!value) return;

    let aggCond = this.aggregationInfo?.fixedFilter?.find(cf => cf.property == this.aggregationCondPropertyName);

    this.aggregationInfo.fixedFilter = value;

    if (aggCond) {
      this.aggregationInfo.fixedFilter.push(aggCond);
    }
  }
  @Input() classicTheme = false;
  @Input() fieldSelection: boolean = false;

  public datasetTemplateParentId: string;
  @ViewChild('datasetDialog') datasetDialog: TemplateRef<any>;
  @ViewChild('propertySelectionDialog') propertySelectionDialog: TemplateRef<any>;
  @ViewChild('datasetTemplate') datasetTemplate: DatasetTemplateComponent;
  @ViewChild(DialogTemplateComponent) dialog: DialogTemplateComponent;

  public loadingData = false;
  public searchText: string;
  public informationId: string;
  public canView: boolean = false;
  public canEdit: boolean = false;
  public dialogRef: NbDialogRef<DialogTemplateComponent>;
  public selectedFieldList: Array<VisualizationField> = [];
  private subCValueChange$: Subscription = null;

  public aggregationInfo: {
    condPropertId?: string, condPropertyName?: string, condPropertyValue?: any, fixedFilter: ConditionField[],
    code?: string, description?: string, deleted: boolean, inactive: boolean,
    extTypeList: Array<ExtType>, extTypeSelected: Array<string>, extDatasetName?: string, extTypeDescription?: string,
    actionSearch?: T2AccessItem, actionInsert?: T2AccessItem, actionEdit?: T2AccessItem, otherActions: T2AccessItem[], otherReadonlyActions: T2AccessItem[]
  } = {
      deleted: false,
      inactive: false,
      extTypeList: [],
      extTypeSelected: [],
      fixedFilter: [],
      otherActions: [],
      otherReadonlyActions: []
    }

  constructor(
    private dialogService: NbDialogService, private httpClient: T2HttpClientService, private secService: T2SecurityService,
    private actionService: ActionService, private messageService: T2MessageService) {
    super();

    this.aggregationInfo.fixedFilter = [];
  }

  ngAfterContentInit(): void {
    this.setDefaultValues();
    this.loadDatasetDescription();
    this.updateValue();

    if (this.formGroup.controls[this.t2IdCmp] && !this.subCValueChange$) {
      this.subCValueChange$ = this.formGroup.controls[this.t2IdCmp].valueChanges.subscribe(data => {
        this.updateValue();
      });
    }

    if (this.datasetId == "{48E52567-CA3C-4551-B261-65FFD67F28CD}") { //afx_dataset_property
      this.fieldSelection = true;
    }

    if (this.fieldSelection) {
      if (this.formGroup.controls[this.t2IdCmp].value) {
        let vf = new VisualizationField();
        vf.id_dataset = this.datasetId;
        vf.datasetName = this.datasetName;
        vf.id_dataset_property = this.formGroup.controls[this.t2IdCmp].value;

        this.selectedFieldList.push(vf);
      } else {
        this.selectedFieldList = []
      }
    }
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    if (this.subCValueChange$) {
      this.subCValueChange$.unsubscribe();
    }
  }

  private loadDatasetDescription() {
    if (this.datasetId) {
      const params = new Map<string, string>();
      params.set("id_dataset", this.datasetId);
      this.httpClient.get("core.dataset/dataset/description", params)
        .pipe(take(1))
        .subscribe(resp => {
          this.aggregationDatasetDescription = resp.description;
        });
    }
  }

  private updateValue() {

    if (!this.formGroup.controls[this.t2IdCmp]) {
      return;
    }

    let value = this.formGroup?.controls[this.t2IdCmp].value;

    if (value && typeof value === "object") {
      value = value.value;
    }

    if (this.formGroup.controls[this.t2IdCmp] && value && this.datasetId) {

      this.httpClient.get(`core.presentation/entityDescription/${this.datasetId}/${value}`, null)
        .pipe(take(1))
        .subscribe(resp => {

          this.description = resp.entityDescription.mainDescription || resp.entityDescription.description;
          this.aggregationInfo.code = resp.entityDescription.code;
          this.aggregationInfo.deleted = resp.entityDescription.deleted;
          this.aggregationInfo.inactive = resp.entityDescription.inactive;
          this.aggregationInfo.extDatasetName = resp.entityDescription.extDatasetName;
          this.aggregationInfo.extTypeDescription = resp.entityDescription.extType;
          this.aggregationInfo.actionSearch = undefined;
          this.aggregationInfo.actionEdit = undefined;
          this.aggregationInfo.actionInsert = undefined;

          this.secService.getDatasetActions(this.aggregationInfo.extDatasetName || this.datasetName).subscribe(resp => {

            this.aggregationInfo.actionSearch = resp.find(item => item.datasetActionType == T2AccessItemDatasetActionType.DSACTION_SEARCH);
            this.aggregationInfo.actionEdit = resp.find(item => item.datasetActionType == T2AccessItemDatasetActionType.DSACTION_EDIT);
            this.aggregationInfo.actionInsert = resp.find(item => item.datasetActionType == T2AccessItemDatasetActionType.DSACTION_INSERT);
            this.aggregationInfo.otherActions = resp.filter(item => {
              return ![
                T2AccessItemDatasetActionType.DSACTION_SEARCH,
                T2AccessItemDatasetActionType.DSACTION_EDIT,
                T2AccessItemDatasetActionType.DSACTION_INSERT,
                T2AccessItemDatasetActionType.DSACTION_DELETE,
              ].includes(item.datasetActionType);
            });

            this.aggregationInfo.otherReadonlyActions = resp.filter(item => {
              return ![
                T2AccessItemDatasetActionType.DSACTION_INSERT,
                T2AccessItemDatasetActionType.DSACTION_EDIT,
                T2AccessItemDatasetActionType.DSACTION_DELETE,
                T2AccessItemDatasetActionType.DSACTION_SEARCH,
                T2AccessItemDatasetActionType.DSACTION_OTHER,
              ].includes(item.datasetActionType);
            });

            this.canView = !this.aggregationInfo.actionEdit && (!!this.aggregationInfo.actionSearch || !!this.aggregationInfo.actionInsert);
            this.canEdit = !!this.aggregationInfo.actionEdit;

          });
        });
    } else {
      this.description = undefined;
      this.aggregationInfo.code = undefined;
      this.aggregationInfo.deleted = undefined;
      this.aggregationInfo.inactive = undefined;
      this.aggregationInfo.extDatasetName = undefined;
      this.aggregationInfo.extTypeDescription = undefined;
      this.aggregationInfo.actionSearch = undefined;
      this.aggregationInfo.actionEdit = undefined;
      this.aggregationInfo.actionInsert = undefined;
      this.aggregationInfo.otherActions = [];
      this.aggregationInfo.otherReadonlyActions = [];

      this.canView = false;
      this.canEdit = false;
    }
  }

  openDataset() {
    if (!this.fieldSelection) {
      this.dialogRef = this.dialogService.open(this.datasetDialog, { autoFocus: true, closeOnEsc: true, hasBackdrop: true });
      this.setDialogRefOnClose();
    } else {
      this.dialogRef = this.dialogService.open(this.propertySelectionDialog, { autoFocus: true, closeOnEsc: true, hasBackdrop: true });
      this.setDialogRefOnClose();
    }
  }

  private setDialogRefOnClose() {
    this.dialogRef.onClose.subscribe(change => {
      if (change) {
        if (this.informationId != this.formGroup.controls[this.t2IdCmp].value) {

          this.formGroup.controls[this.t2IdCmp].setValue(this.informationId);
          this.formGroup.controls[this.t2IdCmp].markAsDirty();

          this.updateValue();
        }
      }
    })
  }

  clearValue() {
    this.formGroup.controls[this.t2IdCmp].setValue(null);
    this.formGroup.controls[this.t2IdCmp].markAsDirty();

    this.updateValue();
  }

  executeAction(action: T2AccessItem) {

    let value = this.formGroup?.controls[this.t2IdCmp].value;

    if (value && typeof value === "object") {
      value = value.value;
    }

    const params = new Map<string, string>();
    params.set("datasetName", this.aggregationInfo.extDatasetName || this.datasetName);
    params.set("id", value);
    this.actionService.executeAction(action, params, "newWindow");
  }

  showOtherActionsList() {

    const groups: Array<GroupSelection> = [];
    const dsActions = (this.readOnly ? this.aggregationInfo.otherReadonlyActions : this.aggregationInfo.otherActions);

    dsActions.forEach(action => {
      let group = groups.find(g => g.id_group == action.id_dataset_group);
      if (!group) {
        group = { id_group: action.id_dataset_group, groupName: null, items: [] };
        groups.push(group);
      }

      group.items.push({ id_item: action.id, itemName: action.datasetActionDescription, selected: false });
    });


    this.dialogService.open(
      DialogItemSelectionComponent,
      {
        context: {
          title: this.aggregationInfo.extTypeDescription || this.aggregationDatasetDescription,
          groupSelection: 'none',
          itemSelection: 'single',
          itemFromDifGroups: false,
          groups: groups,
          useConfirmButton: false,
          buttonCase: 'upper',
          buttonSize: 'large'
        },
      }
    ).onClose.subscribe(resp => {
      if (resp.itemsContext && resp.itemsContext.length) {
        const action = this.aggregationInfo.otherActions.find(action => action.id == resp.itemsContext[0].id_item);
        if (action)
          this.executeAction(action);
      }
    })

  }

  refreshData() {
    this.datasetTemplate?.loadDatasetData({ force: true, extTypeSelected: [...this.aggregationInfo.extTypeSelected] });
  }

  onExtTypeList(extType: { values: Array<ExtType>, selected: Array<string> }) {

    if (extType.selected?.length == 0)
      extType.selected = undefined;

    if (this.aggregationInfo.extTypeList) {
      this.aggregationInfo.extTypeList.length = 0;
      this.aggregationInfo.extTypeList = extType.values;
    }

    if (extType?.selected && extType?.selected.length)
      this.aggregationInfo.extTypeSelected = extType.selected
    else if (extType && extType.values)
      this.aggregationInfo.extTypeSelected = extType.values.map(value => value.datasetName);
  }

  closeDialog(params: any) {
    if (!this.fieldSelection) {
      this.dialogRef.close(params);
    } else {
      if (this.selectedFieldList?.length) {
        this.informationId = this.selectedFieldList[0].id_dataset_property;
        this.dialogRef.close(true);
      } else {
        this.dialogRef.close(false);
      }
    }
  }
}


/** TO DO
 * - Exibir botões rápidos
 *   OK Editar (apenas se o usuário possuir acesso para editar, mas as informação não está bloqueada e nem deletada)
 *   - Visualizar (apenas se o usuário possuir acesso para visualizar ou para editar e a informação está bloqueada. Se a informação estiver deletadas, não deve ser exibido)
 * - Destacar visualmente (on hover) o tipo de registro (extensao) e o status
 * - Permitir limpar o conteúdo do campo
 * - dialogo de busca demora para exibir a informação que está carregando, o usuário fica sem saber o que está acontecendo
 * - dialogo de busca é muito lenta para grandes grupos de informação (pensar em alguma maneira de manter os dados, uma vez que foi carregado)
*/
