import { Component, OnInit, ViewChild } from '@angular/core';
import { FormTemplateComponent } from "src/app/core/cmp/form-template/form-template.component";
import { T2HttpClientService } from "src/app/core/http/t2httpClient.service";
import { take } from "rxjs/Operators";
import { T2SecurityService } from "src/app/core/security/t2security.service";
import { T2MessageService } from "src/app/core/t2-message.service";

@Component({
  selector: 'app-aws-ip-list',
  templateUrl: './aws-ip-list.component.html',
  styleUrls: ['./aws-ip-list.component.scss']
})
export class AwsIpListComponent implements OnInit {

  formValid = true;
  formLoaded = false;
  @ViewChild("formTemplate", { static: true }) formTemplate: FormTemplateComponent;

  ipList: Array<IPDescription> = [];
  saving = false;
  noAWSServiceAvailable = true;
  maxIP: number;

  constructor(public httpService: T2HttpClientService, private sec: T2SecurityService, private messageService: T2MessageService) { }

  ngOnInit(): void {

    this.sec.accessLoaded()
      .pipe(
        take(1)
      )
      .subscribe(() => {
        if (this.formTemplate.validateAccess(["zD20230118H180550587R000000005"], 'full')) {
          this.formLoaded = true;
          this.carregarIps();
        }
      });

  }

  carregarIps() {
    this.httpService.get("core.aws/allowedIPList", null)
      .pipe(take(1))
      .subscribe(resp => {

        this.noAWSServiceAvailable = resp.noAWSServiceAvailable;
        if (!resp.ipList) return;

        if (!Array.isArray(resp.ipList)) {
          resp.ipList = [resp.ipList];
        }

        this.ipList = resp.ipList;
        this.maxIP = Number(resp.maxIP);
      }, error => {

      })
  }

  excluirIp(index: number) {
    this.ipList.splice(index, 1);
  }

  adicionarIp() {
    this.ipList.push(new IPDescription);
  }

  salvarIp() {
    this.saving = true;
    this.validateForm();
    if (!this.formValid) return;

    this.httpService.post("core.aws/allowedIPList", null, this.ipList).pipe(take(1)).subscribe(resp => {
      if (!resp) {
        this.saving = false;
        return;
      }

      if (!Array.isArray(resp.ipList)) {
        resp.ipList = [resp.ipList];
      }

      resp.ipList.forEach(item => {
        if (!Array.isArray(item.errorList)) {
          item.errorList = [item.errorList];
        }
      });

      this.ipList = resp.ipList;
      if (!this.ipList.filter(item => item.errorList?.length).length) {
        this.messageService.showToast("Salvo com sucesso", '', 'success', false);
      }

      this.saving = false;

    }, error => {

    });
  }

  validateForm(): void {

    this.formValid = true;

    const ipRegex = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;

    this.ipList.forEach(ip => {
      ip.ip = ip.ip.trim();
      ip.errorList = [];

      if (!ipRegex.test(ip.ip)) {
        ip.errorList.push('IP é inválido');
        this.formValid = false;
      } else if (ip.ip.startsWith('0')) {
        ip.errorList.push('IP 0.0.0.0 ou uma variante não é permitido');
        this.formValid = false;
      } else if (this.ipList.filter(item => item.ip.trim() == ip.ip).length > 1) {
        ip.errorList.push('IP está duplicado');
        this.formValid = false;
      }
    });
  }
}

class IPDescription {
  description: string;
  ip: string;
  errorList?: Array<string>;
}
