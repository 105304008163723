<div *ngIf="!readOnly" class="flexColumn" [formGroup]="formGroup">
  <input nbInput type="number" fieldSize="small" [id]="t2IdCmp" [formControlName]="t2IdCmp"
    [hidden]="!visible" [ngStyle]="ngStyle" [placeholder]="placeHolder"
    (keypress)="onKeyPress($event)" fullWidth>
  <span *ngIf="additionalInfo" class="defaultSmallText">{{additionalInfo}}</span>
</div>

<div *ngIf="readOnly && visible" class="T2CmpReadOnly" style="justify-content: flex-end;">
  {{formGroup.controls[t2IdCmp].value}}
</div>
