import { Type } from "@angular/core";
import { Condition, InputType } from "./t2-view-template-flow";

export enum LayoutType {
  listLayout = "list",
  gridLayout = "grid",
  tabLayout = "tab",
  component = "component"
}


export class ViewTemplateElement {
  layoutType = LayoutType.listLayout;
  description?: string;
  children?: ViewTemplateElement[];
  height?: string;
  background?: "box" | "card" | "none" | "banner" = "none";
  cssClass?: string;
  title?: string;

  // For list and grid
  gap?: string;
  cssClassLayout?: string;

  // Only for list
  direction?: string;
  align?: string;

  // Only for list-elements
  width?: string;
  maxWidth?: string;
  fill?: boolean = false;

  // Only for grid-elements
  columnsFixed?: string;
  columnsMin?: string;
  columnsMax?: string;
  columns?: string;
  columnSpan?: number = 1;
  gridStyle?: any;

  // Only for tabs
  tabTitle?: string;

  // Only for components
  toolTip?: string;
  cmpName?: string;
  cmpType?: Type<any>;
  inputs?: { [key: string]: any };
  outputs?: { [key: string]: (event: any) => void };
  isBaseComponent?: boolean;
  actionId?: string;
  flowsInput?: Array<{
    type: InputType,
    cmpName: string,
    cmpInputName?: string,
    paramName: string,
    paramValue?: any,
    condition?: Condition
  }>;
  cmpCssClass?: string;
  required?: boolean;
}