<div class="flexRow flexGap5 fillContent">
  <div style="background-color: white; color: black; max-height: 500px; width: 100%; height: 100%;" #nwRef id="nwRef">
  </div>
  <div style="width: 200px;">
    <div style="text-align: center;">Configurações</div>
    <div>
      <!-- General config
          - Auto stabilize (check)
          - Force stabilize (button)
          - Shape (combo)

         -->
    </div>
    <div>
      <!--
        - Nodes
          - Add node (button)
          - Node title (input)
          - Node color (combo)
        - Links (selected node)
          - Add link
          - Show links
       -->
    </div>
  </div>
</div>
