import { ViewChild } from "@angular/core";
import { Component, OnInit } from '@angular/core';
import { NbDialogRef } from "@nebular/theme";
import { T2gridComponent } from "src/app/core/cmp/t2grid/t2grid.component";
import { CilindroProgramacao } from "../../model/cilindro-programacao";

@Component({
  selector: 'app-scheduled-prod-order',
  templateUrl: './scheduled-prod-order.component.html',
  styleUrls: ['./scheduled-prod-order.component.scss']
})
export class ScheduledProdOrderComponent implements OnInit {

  public scheduledOPList: Array<CilindroProgramacao>;

  @ViewChild("gdOP", { static: true }) gdOP: T2gridComponent;

  constructor(public dialogRef: NbDialogRef<ScheduledProdOrderComponent>) {

  }

  ngOnInit(): void {
    this.load();
  }

  private load(){
    this.gdOP.t2GridOptions.masterDetail = true;
    this.gdOP.t2GridOptions.detailRowAutoHeight = true;
    this.gdOP.setGridColumnDefs([
      { headerName: "OP", field: "prodOrder", width: 100, cellRenderer: 'agGroupCellRenderer' },
      { headerName: "Programação", field: "planDate", type: ["dateColumn"] },
      { headerName: "Dispositivo", field: "deviceName" },
      { headerName: "Tarefa", field: "taskDescription" },
      { headerName: "Produtos", field: "prodOrderTitle" },
    ]);

    this.gdOP.setDetailCellRendererParams({
      detailGridOptions: {
        columnDefs: [
          { headerName: "Cilindro", field: "cylinderCode", width: 100 },
        ]
      },

      getDetailRowData: function (params) {
        params.successCallback(Array.isArray(params.data.colors) ? params.data.colors : [params.data.colors]);
      }
    });

    this.gdOP.setGridData(this.scheduledOPList, null);
  }

  closeWindow(acao) {    
    const result = { acao: acao};

    this.dialogRef.close(result);
  }

}
