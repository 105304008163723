import { AfterContentInit, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { DialogTemplateComponent } from "src/app/core/cmp/dialog-template/dialog-template.component";
import { T2AggregationComponent } from "src/app/core/cmp/ui/t2-aggregation/t2-aggregation.component";
import { T2InputTextComponent } from "src/app/core/cmp/ui/t2-input-text/t2-input-text.component";
import { EPService } from "../ep.service";
import { take, takeUntil } from "rxjs/Operators";
import { Observable, Subject } from "rxjs";
import { LayoutType, ViewTemplateElement } from "src/app/core/cmp/view-template/model/view-template-element";
import { T2ButtonComponent } from "src/app/core/cmp/ui/t2-button/t2-button.component";
import { ViewTemplateComponent } from "src/app/core/cmp/view-template/view-template.component";
import { ActionType, T2ViewTemplateAction } from "src/app/core/cmp/view-template/model/t2-view-template-action";
import { InputType, T2ViewTemplateFlow } from "src/app/core/cmp/view-template/model/t2-view-template-flow";
import { NbDialogService } from "@nebular/theme";
import { EPComponent } from "../ep.component";

@Component({
  selector: 'app-insert-epdialog',
  templateUrl: './insert-epdialog.component.html',
  styleUrls: ['./insert-epdialog.component.scss']
})
export class InsertEPDialogComponent implements OnInit, AfterContentInit, OnDestroy {
  @ViewChild('dialog', { static: true }) dialog: DialogTemplateComponent;
  @ViewChild("viewTemplate", { static: true }) viewTemplate: ViewTemplateComponent;

  @Input() parentId: string; // id do produto

  private unsubscribe = new Subject<void>();
  public loading: boolean = false;
  public formGroup: FormGroup;
  public layout: Array<ViewTemplateElement>;
  public actions: Array<T2ViewTemplateAction> = [
    {
      type: ActionType.changeValue,
      actionName: "changeValue",
      methodName: "changeValue"
    },
    {
      type: ActionType.changeValue,
      actionName: "clearValue",
      methodName: "clearValue"
    }
  ];
  public flows: Array<T2ViewTemplateFlow> = [
    {
      actionName: "changeValue",
      action: this.actions.find(ac => ac.actionName == "changeValue"),
      triggerCmpNameList: ["produtoOpcao3"],
      inputs: [
        {
          type: InputType.component,
          cmpName: "produtoOpcao3",
          paramName: "value"
        }
      ],
      outputs: [
        {
          cmpName: "versaoOpcao3",
          cmpInputName: "aggregationCondPropValue"
        }
      ]
    },
    {
      actionName: "clearValue",
      action: this.actions.find(ac => ac.actionName == "clearValue"),
      triggerCmpNameList: ["produtoOpcao3"],
      inputs: [
        {
          type: InputType.static,
          cmpName: "produtoOpcao3",
          paramName: "value",
        }
      ],
      outputs: [
        {
          cmpName: "versaoOpcao3"
        }
      ]
    }
  ]

  constructor(private epService: EPService, private formBuilder: FormBuilder, private dialogService: NbDialogService) { }

  ngAfterContentInit(): void {
    this.layout = [
      {
        layoutType: LayoutType.listLayout,
        direction: "column",
        children: [
          {
            layoutType: LayoutType.tabLayout,
            children: [
              {
                layoutType: LayoutType.gridLayout,
                tabTitle: "Criar Vazia",
                gap: "10px",
                children: [
                  {
                    layoutType: LayoutType.component,
                    cmpType: T2AggregationComponent,
                    cmpName: "tipoOpcao1",
                    title: "Tipo de especificação",
                    columnSpan: 5,
                    isBaseComponent: true,
                    inputs: {
                      datasetName: "epp_tipoEspecif",
                      datasetId: "{8BEC69E4-17AE-44B6-9299-2E1E1CAD4E20}"
                    }
                  },
                  {
                    layoutType: LayoutType.component,
                    cmpType: T2ButtonComponent,
                    cmpName: "btnCriarEp1",
                    columnSpan: 5,
                    inputs: {
                      status: "primary",
                      size: "tiny",
                      value: "Criar EP",
                      disabled: true
                    },
                    outputs: {
                      clicked: this.createPS.bind(this)
                    }
                  }
                ]
              },
              {
                layoutType: LayoutType.gridLayout,
                tabTitle: "Copiar Versão",
                gap: "10px",
                children: [
                  {
                    layoutType: LayoutType.component,
                    cmpType: T2AggregationComponent,
                    cmpName: "versaoOpcao2",
                    title: "Versão",
                    columnSpan: 5,
                    isBaseComponent: true,
                    inputs: {
                      datasetName: "stk_item_produto_versao",
                      datasetId: "{46CEEDC6-3B5B-4486-828F-54A4EA91FD39}",
                      aggregationCondPropId: "{AB2D7365-A2F4-47F3-81FC-D05911D8BAE3}",
                      aggregationCondPropValue: this.parentId
                    }
                  },
                  {
                    layoutType: LayoutType.component,
                    cmpType: T2ButtonComponent,
                    cmpName: "btnCriarEp2",
                    columnSpan: 5,
                    inputs: {
                      status: "primary",
                      size: "tiny",
                      value: "Copiar EP",
                      disabled: true
                    },
                    outputs: {
                      clicked: this.createPS.bind(this)
                    }
                  }
                ]
              },
              {
                layoutType: LayoutType.gridLayout,
                tabTitle: "Copiar outro produto",
                gap: "10px",
                children: [
                  {
                    layoutType: LayoutType.component,
                    cmpType: T2AggregationComponent,
                    cmpName: "produtoOpcao3",
                    title: "Produto",
                    columnSpan: 5,
                    isBaseComponent: true,
                    inputs: {
                      datasetName: "stk_item_produto",
                      datasetId: "{6527C214-BB28-4E85-9A84-FD6B05BC66A9}"
                    }
                  },
                  {
                    layoutType: LayoutType.component,
                    cmpType: T2AggregationComponent,
                    cmpName: "versaoOpcao3",
                    title: "Versão",
                    columnSpan: 5,
                    isBaseComponent: true,
                    inputs: {
                      datasetName: "stk_item_produto_versao",
                      datasetId: "{46CEEDC6-3B5B-4486-828F-54A4EA91FD39}",
                      aggregationCondPropId: "{AB2D7365-A2F4-47F3-81FC-D05911D8BAE3}"
                    }
                  },
                  {
                    layoutType: LayoutType.component,
                    cmpType: T2ButtonComponent,
                    cmpName: "btnCriarEp3",
                    columnSpan: 5,
                    inputs: {
                      status: "primary",
                      size: "tiny",
                      value: "Copiar EP",
                      disabled: true
                    },
                    outputs: {
                      clicked: this.createPS.bind(this)
                    }
                  }
                ]
              },
              {
                layoutType: LayoutType.gridLayout,
                tabTitle: "Copiar modelo",
                gap: "10px",
                children: [
                  {
                    layoutType: LayoutType.component,
                    cmpType: T2AggregationComponent,
                    cmpName: "modeloOpcao4",
                    title: "Modelo",
                    columnSpan: 5,
                    isBaseComponent: true,
                    inputs: {
                      datasetName: "epp_modelo",
                      datasetId: "{FF74C2B0-D703-45D7-B465-5FAEA6F1B6E8}"
                    }
                  },
                  {
                    layoutType: LayoutType.component,
                    cmpType: T2InputTextComponent,
                    cmpName: "versaoOpcao4",
                    title: "Versão",
                    columnSpan: 2,
                    isBaseComponent: true
                  },
                  {
                    layoutType: LayoutType.component,
                    cmpType: T2InputTextComponent,
                    cmpName: "descricaoOpcao4",
                    title: "Descrição",
                    columnSpan: 3,
                    isBaseComponent: true
                  },
                  {
                    layoutType: LayoutType.component,
                    cmpType: T2ButtonComponent,
                    cmpName: "btnCriarEp4",
                    columnSpan: 5,
                    inputs: {
                      status: "primary",
                      size: "tiny",
                      value: "Criar EP",
                      disabled: true
                    },
                    outputs: {
                      clicked: this.createPS.bind(this)
                    }
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  ngOnInit(): void {
    this.formGroup = this.formBuilder.group({});
    this.formGroup.addControl("tipoOpcao1", this.formBuilder.control(undefined, [Validators.required]));
    this.formGroup.addControl("versaoOpcao2", this.formBuilder.control(undefined, [Validators.required]));
    this.formGroup.addControl("produtoOpcao3", this.formBuilder.control(undefined, [Validators.required]));
    this.formGroup.addControl("versaoOpcao3", this.formBuilder.control(undefined, [Validators.required]));
    this.formGroup.addControl("modeloOpcao4", this.formBuilder.control(undefined, [Validators.required]));
    this.formGroup.addControl("versaoOpcao4", this.formBuilder.control(undefined));
    this.formGroup.addControl("descricaoOpcao4", this.formBuilder.control(undefined));

    this.formGroup.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe(changes => {
      this.viewTemplate.setCmpInputValue("btnCriarEp1", "disabled", !changes.tipoOpcao1);
      this.viewTemplate.setCmpInputValue("btnCriarEp2", "disabled", !changes.versaoOpcao2);
      this.viewTemplate.setCmpInputValue("btnCriarEp3", "disabled", !changes.produtoOpcao3 || !changes.versaoOpcao3);
      this.viewTemplate.setCmpInputValue("btnCriarEp4", "disabled", !changes.modeloOpcao4);
    });
  }

  closeDialog(newId?: string) {
    this.dialog.close({ newId: newId });
  }

  createPS(btnId: string) {
    /*
    btnCriarEp1 = Criar uma especificação vazia
    btnCriarEp2 = Copiar de uma versão existente
    btnCriarEp3 = Copiar uma versão de outro produto
    btnCriarEp4 = Criar baseado em um modelo
    */

    this.loading = true;
    let observableResp: Observable<string>;

    switch (btnId) {
      case "btnCriarEp1": {
        let prodSpecifTypeId = this.formGroup.controls["tipoOpcao1"].value;
        observableResp = this.epService.insertProductSpecificationByType(this.parentId, prodSpecifTypeId);

        break;
      }
      case "btnCriarEp2": {
        let specificationId = this.formGroup.controls["versaoOpcao2"].value;
        observableResp = this.epService.copyProdutctSpecification(specificationId, this.parentId);

        break;
      }
      case "btnCriarEp3": {
        let itemId = this.formGroup.controls["produtoOpcao3"].value;
        let specificationId = this.formGroup.controls["versaoOpcao3"].value;
        observableResp = this.epService.copyProdutctSpecification(specificationId, itemId);

        break;
      }
      case "btnCriarEp4": {
        let modelId = this.formGroup.controls["modeloOpcao4"].value;
        let versionCode = this.formGroup.controls["versaoOpcao4"].value;
        let versionDescription = this.formGroup.controls["descricaoOpcao4"].value;

        observableResp = this.epService.insertProductSpecificationByModel(this.parentId, modelId, versionCode, versionDescription);
        break;
      }
    }

    observableResp.pipe(take(1)).subscribe(newProdSpecifId => {
      this.loading = false;

      let dlg = this.dialogService.open(EPComponent, {
        context: {
          id: newProdSpecifId,
          inDialog: true
        }, hasBackdrop: true, closeOnBackdropClick: false, closeOnEsc: false
      });

      dlg.onClose.pipe(take(1)).subscribe(() => {
        this.closeDialog(newProdSpecifId);
      });
    }, error => {
      this.loading = false;
    })
  }
}
