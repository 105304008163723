<app-form-template #formTemplate formTitle="Embalagem" [loaded]="loaded">
  <content>
    <!-- Dispotivos Pequenos -->
    <div style="height: 100%; display: none; flex-direction: column;" [ngStyle.sm]="{'display': 'flex'}" [ngStyle.xs]="{'display': 'flex'}">
      <div style="display: flex; gap: 5px;">
        <button nbButton size="tiny" [status]="getButtonStatus('Pendentes')" class="flexLength1" (click)="changeStage('Pendentes')">
          Pendentes ({{ pendingPacking?.length }})
        </button>
        <button nbButton size="tiny" [status]="getButtonStatus('Embalando')" class="flexLength1" (click)="changeStage('Embalando')">
          Embalando ({{ inPacking?.length }})
        </button>
        <button nbButton size="tiny" [status]="getButtonStatus('Finalizados')" class="flexLength1"
          (click)="changeStage('Finalizados')" *ngIf="inspector">
          Finalizados ({{ finishedPacking?.length }})
        </button>
      </div>

      <div style="margin-top: 10px; height: calc(100% - 35px); overflow: auto" [nbSpinner]="(currentTab === 'Pendentes' && loadingPending) ||
        (currentTab === 'Embalando' && loadingInPacking) ||
        (currentTab === 'Finalizados' && loadingFinished)" nbSpinnerStatus="primary" nbSpinnerSize="tiny">
        <app-packing-stage *ngIf="currentTab === 'Pendentes'" [packingList]="pendingPacking" [currentTab]="currentTab"
          (packingClick)="onPackingClick($event)" [clickable]="packer"></app-packing-stage>

        <app-packing-stage *ngIf="currentTab === 'Embalando'" [packingList]="inPacking" [currentTab]="currentTab"
          (packingClick)="onPackingClick($event)" [clickable]="packer"></app-packing-stage>

        <app-packing-stage *ngIf="currentTab === 'Finalizados'" [packingList]="finishedPacking"
          [currentTab]="currentTab" (packingClick)="onPackingClick($event)" [clickable]="inspector"></app-packing-stage>
      </div>
    </div>

    <!-- Dispotivos Grandes -->
    <div [ngStyle.sm]="{'display': 'none'}" [ngStyle.xs]="{'display': 'none'}" style="height: 100%">
      <nb-tabset style="height: 100%" (changeTab)="onTabChange($event)">
        <nb-tab tabTitle="Pendentes" style="height: calc(100% - 49px)" [active]="currentTab === 'Pendentes'"
          [nbSpinner]="loadingPending" nbSpinnerStatus="primary" nbSpinnerSize="tiny"
          [badgeText]="pendingPacking?.length + ''" badgeStatus="danger">
          <app-packing-stage [currentTab]="currentTab" [packingList]="pendingPacking"
            (packingClick)="onPackingClick($event)" [clickable]="packer"></app-packing-stage>
        </nb-tab>

        <nb-tab tabTitle="Embalando" style="height: calc(100% - 49px)" [active]="currentTab === 'Embalando'"
          [nbSpinner]="loadingInPacking" nbSpinnerStatus="primary" nbSpinnerSize="tiny"
          [badgeText]="inPacking?.length + ''" badgeStatus="danger">
          <app-packing-stage [currentTab]="currentTab" [packingList]="inPacking" (packingClick)="onPackingClick($event)"
            [clickable]="packer"></app-packing-stage>
        </nb-tab>

        <nb-tab tabTitle="Finalizados" style="height: calc(100% - 49px)" [active]="currentTab === 'Finalizados'"
          [nbSpinner]="loadingFinished" nbSpinnerStatus="primary" nbSpinnerSize="tiny" *ngIf="inspector"
          [badgeText]="finishedPacking?.length + ''" badgeStatus="danger">
          <app-packing-stage [currentTab]="currentTab" [packingList]="finishedPacking"
            (packingClick)="onPackingClick($event)" [clickable]="inspector"></app-packing-stage>
        </nb-tab>
      </nb-tabset>
    </div>
  </content>
</app-form-template>
