import { Component, EventEmitter, Inject, Input, LOCALE_ID, OnInit, Output } from '@angular/core';
import { ControlContainer, FormBuilder, FormGroup } from "@angular/forms";
import { getLocaleNumberSymbol, NumberSymbol } from "@angular/common";

@Component({
  selector: 'app-filter-params',
  templateUrl: './filter-params.component.html',
  styleUrls: ['./filter-params.component.scss']
})
export class FilterParamsComponent implements OnInit {

  private pattern: RegExp;

  formGroup: FormGroup;
  _filterParams: Array<any>;

  @Input()
  get filterParams(): Array<any> { return this._filterParams; };
  set filterParams(fp: Array<any>) {
    this._filterParams = fp;
    this.formGroup = this.formBuilder.group({});

    this._filterParams?.forEach(filter => {
      this.formGroup.addControl(filter.name + "Control", this.formBuilder.control(filter.value));
    });
  };

  @Output() updateFilter = new EventEmitter();

  constructor(@Inject(LOCALE_ID) private locale, private formBuilder: FormBuilder) {
    this.formGroup = formBuilder.group({});

    let decimalSeparator = getLocaleNumberSymbol(locale, NumberSymbol.Decimal);
    this.pattern = new RegExp(`^-?[0-9]{0,12}([${decimalSeparator}][0-9]{0,4})?$`);
  }

  ngOnInit(): void {
  }

  onKeyPress(event: any) {
    let decimalSeparator = getLocaleNumberSymbol(this.locale, NumberSymbol.Decimal);
    const previousValue = decimalSeparator == "." ? event.srcElement.value : event.srcElement.value.replace(".", ",");;

    const inputChar = event.key;

    if (!this.pattern.test(previousValue + inputChar)) {
      event.preventDefault();
    }
  }

  onEnter(event) {
    event.target.select();
  }

  updateClick() {

    this.filterParams.forEach(filter => {
      if (filter.showFilter) {
        filter.value = this.formGroup.controls[filter.name + "Control"].value;
      }
    });

    this.updateFilter.emit();
  }

}
