import { AfterContentInit, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Observable, Subject } from "rxjs";
import { take, takeUntil } from "rxjs/Operators";
import { DialogTemplateComponent } from "src/app/core/cmp/dialog-template/dialog-template.component";
import { T2AggregationComponent } from "src/app/core/cmp/ui/t2-aggregation/t2-aggregation.component";
import { T2ButtonComponent } from "src/app/core/cmp/ui/t2-button/t2-button.component";
import { T2InputTextComponent } from "src/app/core/cmp/ui/t2-input-text/t2-input-text.component";
import { LayoutType, ViewTemplateElement } from "src/app/core/cmp/view-template/model/view-template-element";
import { ViewTemplateComponent } from "src/app/core/cmp/view-template/view-template.component";
import { EPService } from "../ep.service";
import { NbDialogService } from "@nebular/theme";
import { EPComponent } from "../ep.component";

@Component({
  selector: 'app-insert-ep-model-dialog',
  templateUrl: './insert-ep-model-dialog.component.html',
  styleUrls: ['./insert-ep-model-dialog.component.scss']
})
export class InsertEpModelDialogComponent implements OnInit, OnDestroy {

  @ViewChild('dialog', { static: true }) dialog: DialogTemplateComponent;
  @ViewChild("viewTemplate", { static: true }) viewTemplate: ViewTemplateComponent;

  private unsubscribe = new Subject<void>();
  public loading: boolean = false;
  public formGroup: FormGroup;
  public layout: Array<ViewTemplateElement> = [
    {
      layoutType: LayoutType.listLayout,
      direction: "column",
      children: [
        {
          layoutType: LayoutType.tabLayout,
          children: [
            {
              layoutType: LayoutType.gridLayout,
              tabTitle: "Copiar Modelo",
              gap: "10px",
              children: [
                {
                  layoutType: LayoutType.component,
                  cmpType: T2AggregationComponent,
                  cmpName: "modelo",
                  title: "Modelo",
                  columnSpan: 5,
                  isBaseComponent: true,
                  inputs: {
                    datasetName: "epp_modelo",
                    datasetId: "{FF74C2B0-D703-45D7-B465-5FAEA6F1B6E8}"
                  }
                },
                {
                  layoutType: LayoutType.component,
                  cmpType: T2ButtonComponent,
                  cmpName: "btnCriarModelo1",
                  columnSpan: 5,
                  inputs: {
                    status: "primary",
                    size: "tiny",
                    value: "Copiar modelo",
                    disabled: true
                  },
                  outputs: {
                    clicked: this.createModel.bind(this)
                  }
                }
              ]
            },
            {
              layoutType: LayoutType.gridLayout,
              tabTitle: "Criar Vazio",
              gap: "10px",
              children: [
                {
                  layoutType: LayoutType.component,
                  cmpType: T2InputTextComponent,
                  cmpName: "descricao",
                  title: "Descrição",
                  columnSpan: 3,
                  isBaseComponent: true
                },
                {
                  layoutType: LayoutType.component,
                  cmpType: T2AggregationComponent,
                  cmpName: "tipoEspecificacao",
                  title: "Tipo de especificação",
                  columnSpan: 5,
                  isBaseComponent: true,
                  inputs: {
                    datasetName: "epp_tipoEspecif",
                    datasetId: "{8BEC69E4-17AE-44B6-9299-2E1E1CAD4E20}"
                  }
                },
                {
                  layoutType: LayoutType.component,
                  cmpType: T2AggregationComponent,
                  cmpName: "estrutura",
                  title: "Estrutura básica",
                  columnSpan: 5,
                  isBaseComponent: true,
                  inputs: {
                    datasetName: "epp_estrutura",
                    datasetId: "{8B6AB969-9751-4AA2-9F4C-EBE00BF01131}"
                  }
                },
                {
                  layoutType: LayoutType.component,
                  cmpType: T2ButtonComponent,
                  cmpName: "btnCriarModelo2",
                  columnSpan: 5,
                  inputs: {
                    status: "primary",
                    size: "tiny",
                    value: "Criar modelo",
                    disabled: true
                  },
                  outputs: {
                    clicked: this.createModel.bind(this)
                  }
                }
              ]
            }
          ]
        }
      ]
    }
  ];

  constructor(private formBuilder: FormBuilder, private epService: EPService, private dialogService: NbDialogService) { }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  ngOnInit(): void {
    this.formGroup = this.formBuilder.group({});
    this.formGroup.addControl("modelo", this.formBuilder.control(undefined, [Validators.required]));
    this.formGroup.addControl("descricao", this.formBuilder.control(undefined, [Validators.required]));
    this.formGroup.addControl("tipoEspecificacao", this.formBuilder.control(undefined, [Validators.required]));
    this.formGroup.addControl("estrutura", this.formBuilder.control(undefined));

    this.formGroup.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe(changes => {
      this.viewTemplate.setCmpInputValue("btnCriarModelo1", "disabled", !changes.modelo);
      this.viewTemplate.setCmpInputValue("btnCriarModelo2", "disabled", !changes.descricao || !changes.tipoEspecificacao);
    });
  }

  closeDialog(newId?: string) {
    this.dialog.close({ newId: newId });
  }

  createModel(btnId: string) {
    /*
    btnCriarModelo1 = Copiar de um modelo existente 
    btnCriarModelo2 = Criar um modelo vazio
    */

    this.loading = true;
    let observableResp: Observable<string>;

    switch (btnId) {
      case "btnCriarModelo1": {
        let specificationId = this.formGroup.controls["modelo"].value;
        observableResp = this.epService.copyModel(specificationId);

        break;
      }
      case "btnCriarModelo2": {
        let specifTypeId = this.formGroup.controls["tipoEspecificacao"].value;
        let description = this.formGroup.controls["descricao"].value;
        let structureId = this.formGroup.controls["estrutura"].value;

        observableResp = this.epService.insertModel(specifTypeId, description, structureId);
        break;
      }
    }

    observableResp.pipe(take(1)).subscribe(newProdSpecifId => {
      this.loading = false;

      let dlg = this.dialogService.open(EPComponent, {
        context: {
          id: newProdSpecifId,
          inDialog: true
        }, hasBackdrop: true, closeOnBackdropClick: false, closeOnEsc: false
      });

      dlg.onClose.pipe(take(1)).subscribe(() => {
        this.closeDialog(newProdSpecifId);
      });
    }, error => {
      this.loading = false;
    })
  }
}
