/**
 * NAO PERMITIR DIGITAR CAACTERES QUE NAO SEJAM PARA NUMEROS
*/

import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { FormBuilder, FormControl, Validators } from "@angular/forms";
import { take } from "rxjs/Operators";
import { FormTemplateComponent } from "src/app/core/cmp/form-template/form-template.component";
import { T2gridComponent } from "src/app/core/cmp/t2grid/t2grid.component";
import { T2HttpClientService } from "src/app/core/http/t2httpClient.service";
import { T2SecurityService } from "src/app/core/security/t2security.service";
import { ActivatedRoute, Router } from "@angular/router";
import { Subscription } from "rxjs";
import { NbDialogService } from "@nebular/theme";
import { DuplicateDialogComponent } from "./duplicate-dialog/duplicate-dialog.component";
import { T2MessageService } from "src/app/core/t2-message.service";

@Component({
  selector: 'app-price-table',
  templateUrl: './price-table.component.html',
  styleUrls: ['./price-table.component.scss']
})
export class PriceTableComponent implements OnInit, OnDestroy {

  loading = false;

  public loaded = false;
  public priceTableList: Array<any> = [];
  public priceTableListItem: Array<{ ID?: string, id_item: string, itemCode: string, itemDescription: string, itemType: string, currentPrice?: number, price: number, currentPriceExpDate?: Date, priceExpDate: Date }>;
  public priceTableChanges = [];
  selectionField = new FormControl({ disabled: this.loading });
  newValueField = new FormControl(undefined, [Validators.min(0.0)]);
  newPercField = new FormControl(undefined, []);
  widgetFormGroup = this.formBuilder.group({});

  saving = false;
  changed = false;
  changedItems: number = 0;
  applyValue = false;
  duplicating = false;
  editing = false;
  private idSelectedPriceTable = null;
  private subsQueryParams$: Subscription = null;
  private subsPriceTable$: Subscription = null;
  private subsInputValue$: Subscription = null;
  private subsInputPerc$: Subscription = null;

  @ViewChild("formTemplate", { static: true }) formTemplate: FormTemplateComponent;
  @ViewChild("gridTask", { static: true }) gridTask: T2gridComponent;

  constructor(
    private sec: T2SecurityService,
    private httpClient: T2HttpClientService,
    private formBuilder: FormBuilder,
    public router: Router,
    private route: ActivatedRoute,
    private dialogService: NbDialogService,
    private messages: T2MessageService) { }

  ngOnInit(): void {
    this.sec.accessLoaded()
      .pipe(
        take(1)
      ).subscribe(() => {
        if (this.formTemplate.validateAccess(['zD20210320H132741970R000000082'], 'full')) {

          this.loaded = true;

          this.gridTask.component = this;

          this.setGridColumnsPriceTable(null);
          this.loadPriceTableList();
        }
      });

    // Monitora o Select
    this.subsPriceTable$ = this.selectionField.valueChanges
      .subscribe((value: any) => {

        this.router.navigate(
          [],
          {
            relativeTo: this.route,
            queryParams: { "priceTable": value },
            queryParamsHandling: "merge",
          });

        this.idSelectedPriceTable = value;
        this.loadPriceTableItems(value);
      });

    // Monitora a URL
    this.subsQueryParams$ = this.route.queryParams.subscribe(params => {
      if (this.idSelectedPriceTable != (params['priceTable'] || "")) {
        this.idSelectedPriceTable = params['priceTable'] || this.idSelectedPriceTable || "";
      }
    });

    // Monitora os INPUTS
    const funcApply = (value: any) => { this.canApply(); };
    this.subsInputValue$ = this.newValueField.valueChanges.subscribe(funcApply);
    this.subsInputPerc$ = this.newPercField.valueChanges.subscribe(funcApply);
  }

  ngOnDestroy(): void {
    if (this.subsQueryParams$) { this.subsQueryParams$.unsubscribe(); }
    if (this.subsPriceTable$) { this.subsPriceTable$.unsubscribe(); }
    if (this.subsInputValue$) { this.subsInputValue$.unsubscribe(); }
    if (this.subsInputPerc$) { this.subsInputPerc$.unsubscribe(); }
  }

  private setGridColumnsPriceTable(customColumns: any[]): void {

    const fixedColumns = [
      { headerName: "Codigo", field: "itemCode", enableRowGroup: true, columnGroupShow: 'always' },
      { headerName: "Descrição", field: "itemDescription", enableRowGroup: true, columnGroupShow: 'always' },
      { headerName: "Un", field: "un", enableRowGroup: true, columnGroupShow: 'always' },
      { headerName: "Tipo", field: "itemType", enableRowGroup: true, columnGroupShow: 'always' },
      { headerName: "Preço atual", field: "currentPrice", type: 'numericColumn', enableRowGroup: true, columnGroupShow: 'always' },
      {
        headerName: "Novo Preço",
        field: "price",
        type: 'numericColumn',
        editable: true,
        onCellValueChanged: this.onPriceEdit,
      },
      {
        headerName: "Variação",
        field: "priceVariation",
        valueGetter: this.priceVariationGetter
      },
      {
        headerName: "Vencimento",
        field: "priceExpDate",
        type: 'dateColumn',
        editable: true,
        onCellValueChanged: this.onExpirationDateEdit,
      }
    ];

    if (customColumns && customColumns.length) {
      customColumns.forEach(column => {
        column.enableRowGroup = true;
        column.columnGroupShow = 'always';
      });
      const allColumns = [...fixedColumns, ...customColumns];
      this.gridTask.setGridColumnDefs(allColumns);
    } else {
      this.gridTask.setGridColumnDefs(fixedColumns);
    }
  }

  private onPriceEdit(event) {
    const tela: PriceTableComponent = event.context.component;
    tela.changed = tela.getChangedRows().length > 0;
  }

  private onExpirationDateEdit(event) {
    const tela: PriceTableComponent = event.context.component;
    tela.changed = tela.getChangedRows().length > 0;
  }

  priceVariationGetter(params: any) {
    const data = params.data;
    const currentPrice: number = data?.currentPrice || 0.0;
    const newPrice: number = data?.price || 0.0;
    if (!currentPrice) {
      return newPrice > 0 ? "100%" : " - ";
    } else {
      return (((newPrice / currentPrice) - 1) * 100).toFixed(2) + "%";
    }
  }

  private loadPriceTableList(): void {
    this.httpClient.get('bsn.sales.priceTable/list', null)
      .pipe(take(1))
      .subscribe(resp => {
        if (!Array.isArray(resp.priceTableList)) {
          resp.priceTableList = [resp.priceTableList];
        }
        this.priceTableList = resp.priceTableList;

        if (this.idSelectedPriceTable && this.priceTableList.find(item => item.id_priceTable === this.idSelectedPriceTable)) {

          setTimeout(() => {
            // Gambiarra, se nao esperar esse tempo, o combo/seletion nao é atualizado com a descricao do ID informado
            this.selectionField.patchValue(this.idSelectedPriceTable);
          }, 50);
        }

        if (this.priceTableList.length == 1) this.selectionField.setValue(this.priceTableList[0].id_priceTable);
      });
  }

  loadPriceTableItems(id_priceTable: string) {
    this.loading = true;
    this.httpClient.get(`bsn.sales.priceTable/items/${id_priceTable}`, null)
      .pipe(take(1))
      .subscribe(resp => {
        this.loading = false;
        this.changed = false;
        this.editing = true;

        const customInfo = JSON.parse(resp.customInfo);

        this.priceTableListItem = resp.priceTableItemList;
        this.priceTableListItem.forEach((item) => {
          const customItem = customInfo.items?.find((ciItem: any) => ciItem.id_item === item.id_item);
          if (customItem) {
            const properties = Object.keys(customItem);

            properties.forEach(key => item[key] = customItem[key]);
          }

          item.ID = item.id_item;
          item.price = item.price * 1;
          item.currentPrice = item.price;
          item.priceExpDate = (item.priceExpDate) ? new Date(item.priceExpDate) : undefined;
          item.currentPriceExpDate = item.priceExpDate;
        });

        this.setGridColumnsPriceTable(customInfo.columns);
        this.gridTask.setGridData(this.priceTableListItem, null);
        this.gridTask.autoSizeAllColumns(false);
      }, error => {
        this.loading = false;
      });
  }

  private getChangedRows() {

    const ch = this.gridTask.getAllRowNodes(false)
      .filter((row: any) => {

        const precoDiferente = row.data.price != row.data.currentPrice;
        const dataDiferente = (row.data.priceExpDate ? new Date(row.data.priceExpDate).getTime() : undefined) !=
          (row.data.currentPriceExpDate ? row.data.currentPriceExpDate.getTime() : undefined);

        return precoDiferente || dataDiferente;
      })
      .map((row: any) => {
        return {
          id_item: row.data.id_item,
          price: Number.parseFloat(row.data.price),
          priceExpDate: (row.data.priceExpDate) ? new Date(row.data.priceExpDate) : undefined
        };
      });

    this.changedItems = ch.length;
    return ch;
  }

  savePriceTableChanges() {

    const precosAlterados = this.getChangedRows();

    if (precosAlterados.length > 4000) {
      this.messages.showToastError("Não é permitido alterar mais de 4.000 itens ao mesmo tempo, faça uma seleção menor");
    }

    this.saving = true;

    this.httpClient.post(`bsn.sales.priceTable/items/${this.idSelectedPriceTable}`, null, precosAlterados)
      .subscribe(resp => {
        this.saving = false;
        this.changed = false;

        precosAlterados.forEach(item => {
          const rowNode = this.gridTask.getRowNode(item.id_item);
          const data = rowNode.data;
          data.currentPrice = item.price;
          data.currentPriceExpDate = item.priceExpDate;
          rowNode.setData(data);

        });
      },
        error => {
          this.saving = false;
        });
  }

  undoPriceTableChanges() {

    const precosAlterados = this.getChangedRows();

    precosAlterados.forEach(item => {
      const rowNode = this.gridTask.getRowNode(item.id_item);
      const data = rowNode.data;
      data.price = data.currentPrice;
      data.priceExpDate = data.currentPriceExpDate;
      rowNode.setData(data);
    });

    this.changed = this.getChangedRows().length > 0;

  }

  getFilterRows() {

    let valor = null;
    let funcCalc = null;

    if (this.newPercField.value) {
      valor = this.newPercField.value;
      funcCalc = (data: any, value: number) => {
        if (data.currentPrice != 0) {
          data.price = data.currentPrice + ((data.currentPrice / 100) * value);
        }
      };
    } else if (this.newValueField.value) {
      valor = this.newValueField.value;
      funcCalc = (data: any, value: number) => { data.price = value; };
    }
    else {
      return;
    }

    const allRows = this.gridTask.getAllFilterRowNodes(false);
    allRows.forEach(item => {
      const rowNode = this.gridTask.getRowNode(item.id);
      const data = rowNode.data;
      funcCalc(data, valor);
      rowNode.setData(data);
    });

    this.changed = this.getChangedRows().length > 0;

    this.newPercField.setValue(null);
    this.newValueField.setValue(null);
  }

  canApply() {
    this.applyValue = this.newPercField.value > 0 || this.newPercField.value < 0 || this.newValueField.value > 0;
  }

  updatePriceTableInfo() {
    const currentPriceTable = this.priceTableList.find((item: any) => {
      return item.id_priceTable === this.idSelectedPriceTable;
    });

    const dialogRefInsD = this.dialogService.open(DuplicateDialogComponent,
      {
        context: {
          title: 'Editar tabela de preço',
          id_priceTable: this.idSelectedPriceTable,
          baseDescription: `${currentPriceTable?.description}`,
          startDate: new Date(`${currentPriceTable?.startDate}`),
          endDate: new Date(`${currentPriceTable?.endDate}`)
        }
      });

    dialogRefInsD.onClose.subscribe(resp => {

      if (resp && resp.type === "confirm") {

        if (resp.description) {

          const queryParams = new Map<string, string>();
          queryParams.set('id_priceTable', resp.id_script_3);
          queryParams.set('description', resp.description);
          queryParams.set('startDate', resp.startDate.toISOString());
          queryParams.set('endDate', resp.endDate.toISOString());

          this.httpClient.get('bsn.sales.priceTable/editPriceTable', queryParams)
            .pipe(take(1))
            .subscribe();
          var index = this.priceTableList?.findIndex(item => item.id_priceTable === this.idSelectedPriceTable)
          this.priceTableList[index].description = resp.description;
          this.priceTableList[index].startDate = resp.startDate;
          this.priceTableList[index].endDate = resp.endDate;
          setTimeout(() => {
            // Gambiarra, se nao esperar esse tempo, o combo/seletion nao é atualizado com a descricao do ID informado
            this.selectionField.patchValue(this.idSelectedPriceTable);
          }, 50);

        }
      } else {

      }
    });

  }

  duplicateTable() {

    const currentPriceTable = this.priceTableList.find((item: any) => {
      return item.id_priceTable === this.idSelectedPriceTable;
    });


    const dialogRefInsD = this.dialogService.open(DuplicateDialogComponent,
      {
        context: {
          title: 'Duplicar tabela de preço',
          id_priceTable: this.idSelectedPriceTable,
          baseDescription: `Cópia de ${currentPriceTable.description}`,
          startDate: new Date(`${currentPriceTable.startDate}`),
          endDate: new Date(`${currentPriceTable.endDate}`)
        }
      });

    dialogRefInsD.onClose.subscribe(resp => {

      if (resp && resp.type === "confirm") {

        if (resp.description) {

          this.duplicating = true;

          const queryParams = new Map<string, string>();
          queryParams.set('id_priceTable', resp.id_script_3);
          queryParams.set('description', resp.description);
          queryParams.set('startDate', resp.startDate.toISOString());
          queryParams.set('endDate', resp.endDate.toISOString());

          this.httpClient.get('bsn.sales.priceTable/copyPriceTable', queryParams)
            .pipe(take(1))
            .subscribe(resp2 => {

              this.idSelectedPriceTable = resp2.id_newPriceTable;
              this.loadPriceTableList();
              this.duplicating = false;
            }, error => {
              this.duplicating = false;
            });


        }
      } else {

      }
    });
  }

  clicouNaLinha(event) {
    // console.log(event);
  }

}
