import { T2DsAttachmentsComponent } from './../t2-ds-attachments/t2-ds-attachments.component';
import { Location } from "@angular/common";
import { AfterContentInit, Component, EventEmitter, Input, OnDestroy, OnInit, Optional, Output, ViewChild, reflectComponentType } from '@angular/core';
import { FormBuilder, FormGroup, ValidatorFn, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { Subject, Subscription } from "rxjs";
import { take, takeUntil } from "rxjs/Operators";
import { ActionService } from "../../action/action.service";
import { FormTemplateComponent } from "../../cmp/form-template/form-template.component";
import { T2ViewTemplateData } from "../../cmp/view-template/model/t2-view-template-data";
import { LayoutType, ViewTemplateElement } from "../../cmp/view-template/model/view-template-element";
import { T2DatasetService } from "../../dataset/t2dataset.service";
import { T2CoreException } from "../../exception/exception";
import { T2HttpClientService } from "../../http/t2httpClient.service";
import { T2Route } from "../../http/t2route";
import { T2SecurityService } from "../../security/t2security.service";
import { T2MessageService } from "../../t2-message.service";
import { Autoform } from "../model/autoform";
import { AutoformGroup } from "../model/autoformGroup";
import { AutoformProperty } from "../model/autoformProperty";
import { AutoformTabsheet } from "../model/autoformTabsheet";
import { ActionType, T2ViewTemplateAction } from "../../cmp/view-template/model/t2-view-template-action";
import { InputType, T2ViewTemplateFlow } from "../../cmp/view-template/model/t2-view-template-flow";
import { DatasetTemplateComponent } from "../../dataset/dataset-template.component";
import { Dataset } from "../../dataset/model/dataset";
import { ComponentService } from "../../cmp/component.service";
import { T2InputTextComponent } from "../../cmp/ui/t2-input-text/t2-input-text.component";
import { T2ViewTemplateFunctionsService } from "../../cmp/view-template/t2-view-template-functions.service";
import { DialogLogDataComponent } from "../../cmp/dialog-log-data/dialog-log-data.component";
import { NbDialogRef, NbDialogService } from "@nebular/theme";
import { T2Status } from "../../cmp/ui/t2-status/model/t2-status";
import { ViewTemplateComponent } from "../../cmp/view-template/view-template.component";
import { DialogItemSelectionComponent, GroupSelection, ItemSelection } from "../../cmp/dialog-item-selection/dialog-item-selection.component";
import { T2AccessItem, T2AccessItemDatasetActionType } from "../../security/model/t2accessItem";
import { ConditionField } from "../../dataset/visualization/t2-visualization.service";
import { T2InactivityComponent } from "../../cmp/ui/t2-inactivity/t2-inactivity.component";
import { T2FormListComponent } from "../t2-form-list/t2-form-list.component";

@Component({
  selector: 'app-t2-auto-form',
  templateUrl: './t2-auto-form.component.html',
  styleUrls: ['./t2-auto-form.component.scss']
})
export class T2AutoFormComponent implements OnInit, AfterContentInit, OnDestroy {
  @Input() t2IdCmp: string;
  @Input() datasetName: string = null;
  @Input()
  get informationId(): string { return this._informationId; }
  set informationId(value: string) {
    this._informationId = value;

    if (this.loaded) {
      this.loaded = false;
      this.loadFormInfo();
    }
  }

  @Input() inDialog: boolean = false;
  @Input() changeTitle: boolean = true;
  @Input() formTemplateUseFlexLayout: boolean = true;
  @Input() inFormList: boolean = false;
  @Input() hideButtons: boolean = false;
  @Input() hideHeader: boolean = false;
  @Input() insertParams: Map<string, any>;
  @Input() loadIdFromUrl: boolean = true;
  @Input() layoutFormList: boolean = false;
  @Input() parentId: string;
  @Output() startedSaving = new EventEmitter<void>();
  @Output() saved = new EventEmitter<string>();
  @Output() startedDelete = new EventEmitter<void>();
  @Output() deleted = new EventEmitter<boolean>();
  @Output() errorInDbOperation = new EventEmitter<void>();
  @Output() loadingCompleted = new EventEmitter<void>();

  @ViewChild("formTemplate", { static: true }) formTemplate: FormTemplateComponent;
  @ViewChild("viewTemplate") viewTemplate: ViewTemplateComponent;

  private _informationId: string = null;
  private t2Route: T2Route;
  private pathParams$: Subscription = null;
  private queryParams$: Subscription = null;
  private loading: boolean = false;
  private autoformProperties: Array<AutoformProperty> = [];
  private unsubscribe = new Subject<void>();
  private awaitChildren: boolean = false;
  private formListSaved = new Subject<void>();

  public loaded: boolean = false;
  public autoform: Autoform;
  public insert: boolean = false;
  public edit: boolean = false;
  public recFound: boolean = false;
  public fieldStatus: AutoformProperty = null;
  public t2IdCmpStatus: string;
  public statusDatasetName: string;
  public formGroup: FormGroup;
  public layout: ViewTemplateElement[];
  public data: Array<T2ViewTemplateData> = [];
  public actions: Array<T2ViewTemplateAction> = [];
  public flows: Array<T2ViewTemplateFlow> = [];
  public buttonSave: boolean = false;
  public buttonDelete: boolean = false;
  public waitingDB: boolean = false;
  public dsActionList: Array<T2AccessItem> = [];
  public dsActions: Array<T2AccessItem> = [];
  public actionExists: boolean = false;
  public hasCompanySiteCtrl: boolean;
  private companySiteCtrlDataset: string;
  private allowAttachments: boolean = false;

  constructor(
    private sec: T2SecurityService,
    private httpClient: T2HttpClientService,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private _location: Location,
    private messageService: T2MessageService,
    private datasetService: T2DatasetService,
    private actionService: ActionService,
    private cmpService: ComponentService,
    private funcService: T2ViewTemplateFunctionsService,
    private dialogService: NbDialogService,
    public router: Router,
    @Optional() protected dialogRef: NbDialogRef<T2AutoFormComponent>) {

    this.actions.push({ type: ActionType.changeValue, methodName: "changeValue", actionName: "changeValue" });
    this.actions.push({ type: ActionType.changeValue, methodName: "clearValue", actionName: "clearValue" });
  }

  ngAfterContentInit(): void {
    this.t2Route = this.httpClient.getT2RouteFromSnapshot(this.route.snapshot);

    if (!this.inDialog && !this.layoutFormList) {
      this.datasetName = this.datasetName || this.t2Route.pathParams.get("datasetName") || "";

      if (this.loadIdFromUrl)
        this.informationId = this.informationId || this.t2Route.pathParams.get("informationId") || this.t2Route.queryParams.get("id") || "";

      this.parentId = this.parentId || this.t2Route.queryParams.get("parentId") || "";

      this.pathParams$ = this.route.params.subscribe(params => {
        this.datasetName = params['datasetName'] || this.datasetName;

        if (this.loadIdFromUrl)
          this.informationId = params['informationId'] || params["id"] || this.informationId;

        this.parentId = params['parentId'] || this.parentId;
        this.loaded = false;
        this.loadFormInfo();
      });

      this.route.queryParams.pipe(takeUntil(this.unsubscribe)).subscribe(params => {
        this.parentId = params['parentId'] || this.parentId;

        if (params["id"]) {
          if (this.loadIdFromUrl)
            this.informationId = params["id"];
        }
      });
    }

    this.loadFormInfo();

    this.loadingCompleted.pipe(take(1)).subscribe(() => {
      if (this.insert) {
        if (this.allowAttachments) {
          this.viewTemplate.setCmpInputValue("anexo", "autoformInInsertMode", true);
        }

        if (this.insertParams) {
          this.insertParams.forEach((value, key) => {
            let control = this.formGroup.controls[key];

            if (control) {
              control.setValue(value);
              control.markAsDirty();
            }
          })
        }
      }
    });
  }

  ngOnInit(): void { }

  ngOnDestroy(): void {
    if (this.pathParams$) {
      this.pathParams$.unsubscribe();
    }

    if (this.queryParams$) {
      this.queryParams$.unsubscribe();
    }

    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  private getAction(actionList: Array<T2AccessItem>, actionType: T2AccessItemDatasetActionType): T2AccessItem[] {
    return actionList.filter(ac => ac.datasetActionType === actionType);
  }

  private loadFormInfo() {
    if (this.loaded || this.loading) { return; }

    this.loading = true;
    this.fieldStatus = null;
    this.formGroup = this.formBuilder.group({}, { updateOn: 'blur' });
    this.data = [];
    this.flows = [];

    if (!this.datasetName) {
      return null;
    }

    this.buttonSave = false;
    this.buttonDelete = false;

    this.sec.getDatasetActions(this.datasetName)
      .pipe(take(1)).subscribe(actionList => {

        let ids = actionList.filter(ac => ac.id != null).map(ac => ac.id);

        if (this.formTemplate.validateAccess(ids, 'full')) {
          this.dsActionList = actionList;

          if (this.dsActionList) {
            this.dsActions = this.dsActionList.filter(ac => {
              if ([T2AccessItemDatasetActionType.DSACTION_OTHER,
              T2AccessItemDatasetActionType.DSACTION_OTHERCHANGE,
              T2AccessItemDatasetActionType.DSACTION_OTHERCLOSE
              ].includes(ac.datasetActionType)) {
                return ac;
              }
            });

            if (this.dsActions.length) this.actionExists = true;
          }
          this.continueLoadFormInfo(ids).then((onSuccess) => {
            if (!this.formGroup.readOnly && !this.insert) {
              this.datasetService.getBlockChanges(this.datasetName, this.informationId)
                .pipe(take(1))
                .subscribe(resp => {
                  if (resp.blockChanges)
                    this.setAllControlsReadOnly(true);
                })
            }

            this.httpClient.get(`core.dataset/dataset/${this.datasetName}/getCompanySiteCtrl`, null).pipe(take(1)).subscribe(resp => {
              if (resp?.companySiteCtrl) {
                this.hasCompanySiteCtrl = true;
                this.companySiteCtrlDataset = resp.datasetName;
              }

              this.loaded = true;

              setTimeout(() => {
                this.loadingCompleted.emit();
              }, 100);

            }, error => {
              this.loaded = true;
              this.loadingCompleted.emit();
            });

          }, (error) => {
            this.messageService.showToastError(error);
            console.error(error);
          });
        }
      });
  }

  private continueLoadFormInfo(ids: Array<string>) {
    return new Promise<any>((resolve, reject) => {
      this.formTemplate.validateAccess(ids, 'full');

      if (ids.length == 0) {
        this.loading = false;
        resolve("success");
        return;
      }

      const params = new Map<string, string>();
      params.set("id_recordOfDataset", this.informationId);

      if (this.parentId) {
        params.set("id_parent", this.parentId);
      }

      if (this.layoutFormList) {
        params.set("formList", "true");
      }

      this.httpClient.get(`core.dynAutoform/autoform/${this.datasetName}`, params).pipe(take(1)).subscribe(async resp => {
        this.recFound = resp.recordFound;

        if (this.recFound) {
          this.insert = false;
          this.edit = true;
        } else {
          this.insert = true;
          this.edit = false;
        }

        if (this.edit) {
          this.buttonSave = (this.getAction(this.dsActionList, T2AccessItemDatasetActionType.DSACTION_EDIT).length > 0);
        } else {
          this.buttonSave = (this.getAction(this.dsActionList, T2AccessItemDatasetActionType.DSACTION_INSERT).length > 0);
        }

        this.buttonDelete = !this.insert && (this.getAction(this.dsActionList, T2AccessItemDatasetActionType.DSACTION_DELETE).length > 0);

        if (resp.autoformLayout) {
          try {
            resp.autoformLayout.layout = JSON.parse(resp.autoformLayout.layout);
            resp.autoformLayout.dataset.propertyList = resp.autoformLayout.dataset.completePropertyList;

            this.adjustLayoutData(resp.autoformLayout);
          } catch (error) {
            reject(error);
            return;
          }
        } else {
          await this.generateLayout(resp.autoform).then(() => { });
        }

        if (this.insert) {
          let autoIncMakedProperties = this.autoformProperties.filter(prop => prop.contentType == "AUTO_INC_MASKED");
          autoIncMakedProperties.forEach(prop => {
            let paramsAi = new Map<string, string>();
            paramsAi.set("id_dataset_property", prop.id_dataset_property);

            this.httpClient.get("core.dataset/dataset/autoincMaskedValue", paramsAi)
              .pipe(take(1))
              .subscribe(resp => {
                if (resp.autoincMaskedValue) {
                  this.formGroup.controls[`${prop.datasetName}.${prop.name}`].setValue(resp.autoincMaskedValue);
                  this.formGroup.controls[`${prop.datasetName}.${prop.name}`].markAsDirty();
                }
              })
          });
        }

        let actionProperties = this.autoformProperties.filter(prop => prop.aggregation && prop.id_dataset_property_master);
        actionProperties.forEach(prop => {
          let propOrigin = this.autoformProperties.filter(p => p.id_dataset_property == prop.id_dataset_property_master)[0];

          if (propOrigin) {
            this.flows.push({
              triggerCmpNameList: [propOrigin.datasetName + "." + propOrigin.name],
              action: this.actions.find(ac => ac.actionName == "changeValue"),
              inputs: [{
                type: InputType.component,
                cmpName: propOrigin.datasetName + "." + propOrigin.name,
                paramName: "value"
              }],
              outputs: [{
                cmpName: prop.datasetName + "." + prop.name,
                cmpInputName: "aggregationCondPropValue"
              }]
            });

            this.flows.push({
              triggerCmpNameList: [propOrigin.datasetName + "." + propOrigin.name],
              action: this.actions.find(ac => ac.actionName == "clearValue"),
              inputs: [{
                type: InputType.static,
                cmpName: propOrigin.datasetName + "." + propOrigin.name,
                paramName: undefined
              }],
              outputs: [{
                cmpName: prop.datasetName + "." + prop.name
              }, {
                cmpName: prop.datasetName + "." + prop.name,
                cmpInputName: "description"
              }]
            });
          }
        });

        this.loading = false;

        resolve("success");
      }, error => reject("Erro ao buscar as informações do autoform"));
    });
  }

  private adjustLayoutData(autoformLayout) {
    let layout: ViewTemplateElement[] = autoformLayout.layout;
    let dataset: Dataset = autoformLayout.dataset;
    let propData: Array<{ name: string, value: string }> = autoformLayout.properties;

    layout.forEach(l => {
      this.addCmpToFormGroup(l, dataset, propData);
    });

    this.layout = layout;
  }

  private addCmpToFormGroup(layout: ViewTemplateElement, dataset: Dataset, propData: Array<{ name: string, value: string }>) {
    if (layout.children) {
      layout.children.forEach(l => this.addCmpToFormGroup(l, dataset, propData));
    }

    if (layout.layoutType == LayoutType.component) {
      if (layout.isBaseComponent) {
        let dsProp = dataset.propertyList.find(prop => prop.datasetName + "." + prop.propertyName == layout.cmpName);

        if (!dsProp) {
          throw new Error(`Não foi encontrada nenhuma propriedade no dataset para o componente "${layout.cmpName}"`);
        }

        if (!layout.cmpType) {
          layout.cmpType = this.cmpService.getType(dsProp.contentType);
        }

        let validators = new Array<ValidatorFn>();

        if (dsProp.required) {
          validators.push(Validators.required);
        }

        const templateData = {
          cmpName: layout.cmpName,
          properties: {}
        };

        if (layout.inputs) {
          Object.keys(layout.inputs).forEach(key => {
            templateData.properties[key] = layout.inputs[key];
          });

          layout.inputs = undefined;
        }

        if (dsProp.contentType == "Integer" || dsProp.contentType == "Money" || dsProp.contentType == "Decimal") {
          let value = propData?.find(prop => prop.name == layout.cmpName).value ?? dsProp.defaultValue;
          this.formGroup.addControl(layout.cmpName, this.formBuilder.control(value ? Number(value) : undefined, validators));

          if (dsProp.defaultValue != undefined && dsProp.defaultValue != null) {
            templateData.properties["defaultValue"] = Number(dsProp.defaultValue);
          }
        } else if (dsProp.contentType == "Date" || dsProp.contentType == "DateTime" || dsProp.contentType == "Time") {
          let value: any = propData?.find(prop => prop.name == layout.cmpName).value ?? dsProp.defaultValue;

          if (value) {
            value = new Date(value);
          }

          this.formGroup.addControl(layout.cmpName, this.formBuilder.control(value, validators));

          if (dsProp.defaultValue != undefined && dsProp.defaultValue != null) {
            templateData.properties["defaultValue"] = new Date(dsProp.defaultValue);
          }
        } else {
          this.formGroup.addControl(layout.cmpName, this.formBuilder.control(propData?.find(prop => prop.name == layout.cmpName).value ?? dsProp.defaultValue, validators));

          if (dsProp.defaultValue != undefined && dsProp.defaultValue != null) {
            templateData.properties["defaultValue"] = dsProp.defaultValue;
          }
        }

        if (dsProp.relationedWith) {
          if (!Array.isArray(dsProp.relationedWith)) {
            dsProp.relationedWith = [dsProp.relationedWith];
          }

          dsProp.relationedWith.forEach(rel => {
            if (rel.relationType == "Aggregation") {
              templateData.properties["datasetName"] = rel.datasetRefName;
              templateData.properties["datasetId"] = rel.id_dataset_ref;
            }
          })
        }

        if (dsProp.contentType == "FixedCombo") {
          templateData.properties["list"] = dsProp.fixedComboList?.map(item => {
            return {
              id: item.description,
              description: item.description
            }
          })
        }

        this.data.push(templateData);
      } else {
        if (!layout.cmpType) {
          throw new Error("É obrigatório informar o \"cmpType\" do componente " + layout.cmpName);
        }

        layout.cmpType = this.cmpService.getType(layout.cmpType.toString());

        if (layout.inputs) {
          const templateData = {
            cmpName: layout.cmpName,
            properties: {}
          };

          let keys = Object.keys(layout.inputs);

          keys.forEach(key => {
            if (layout.cmpType == DatasetTemplateComponent && key == "isChildrenTabsheet" && layout.inputs[key]) {
              layout.inputs["parentId"] = this.informationId;
              layout.inputs["searchText"] = "";
              layout.inputs["tabChange"] = new Subject<any>();
              layout.inputs["tabTitle"] = layout.title;
              layout.inputs["saveAutoformParent"] = this.saveFromChild.bind(this);
              layout.inputs["FormGroup"] = this.formGroup;
            } else {
              templateData.properties[key] = layout.inputs[key];
            }
          });
        }
      }

      if (layout.actionId) {
        this.sec.getAction(layout.actionId).subscribe(ac => {
          let index: number;
          index = this.actions.push({ type: ActionType.runAction, action: ac, adjustResponse: this.funcService.returnScriptResponse }) - 1;

          this.flows.push({ triggerCmpNameList: [layout.cmpName], action: this.actions[index], inputs: layout.flowsInput });
        });
      }
    }
  }

  private loadListOfAutoformProperties() {
    this.autoform.mainTabsheet?.dataGroupList?.forEach(group => {
      group.propertyList?.forEach(prop => this.autoformProperties.push(prop));
    });

    this.autoform.tabsheetList?.forEach(ts => {
      ts.dataGroupList?.forEach(group => {
        group.propertyList?.forEach(prop => this.autoformProperties.push(prop));
      })
    });
  }

  private async generateLayout(autoform) {
    if (autoform.mainTabsheet) {
      this.validateDGArray(autoform.mainTabsheet);
    }

    if (autoform.tabsheetList) {
      autoform.tabsheetList = autoform.tabsheetList.tabsheet;
      if (!Array.isArray(autoform.tabsheetList)) {
        autoform.tabsheetList = [autoform.tabsheetList];
      }

      autoform.tabsheetList.forEach(ts => {
        this.validateDGArray(ts);
      });
    }

    if (autoform.childrenTabsheets) {
      if (!Array.isArray(autoform.childrenTabsheets.childTabsheet)) {
        autoform.childrenTabsheets = [autoform.childrenTabsheets.childTabsheet];
      } else {
        autoform.childrenTabsheets = autoform.childrenTabsheets.childTabsheet;
      }
    }

    if (autoform.actions) {
      autoform.actions = JSON.parse(autoform.actions);

      if (!Array.isArray(autoform.actions)) {
        autoform.actions = [autoform.actions];
      }
    }

    if (autoform.flows) {
      autoform.flows = JSON.parse(autoform.flows);

      if (!Array.isArray(autoform.flows)) {
        autoform.flows = [autoform.flows];
      }
    }

    const afOBJ = JSON.parse(JSON.stringify(autoform));
    afOBJ.hiddenPropertyList = afOBJ.hiddenPropertyList?.property || [];
    this.autoform = afOBJ as Autoform;
    this.fieldStatus = null;

    this.loadListOfAutoformProperties();

    await this.readFormControls(this.autoform.mainTabsheet).then(() => { });

    if (this.autoform.tabsheetList) {
      for (let ts of this.autoform.tabsheetList) {
        await this.readFormControls(ts).then(() => { });
      }
    }

    //read hidden properties
    if (this.autoform.hiddenPropertyList?.length) {
      await this.autoform.hiddenPropertyList.forEach(prop => {
        if (prop.id_autoform_property = 'null') prop.id_autoform_property = null;
        this.addProperty(prop, false);
      });
    }

    if (this.autoform.actions?.length > 0) {
      this.actions.push(...this.autoform.actions);
    }

    if (this.autoform.flows?.length > 0) {
      this.flows.push(...this.autoform.flows);

      this.flows.filter(f => !f.action).forEach(flow => {
        if (flow.actionName) {
          flow.action = this.actions.find(ac => ac.actionName.toLowerCase() == flow.actionName.toLowerCase());
        }
      })
    }

    this.formTemplate.formTitle = this.autoform.description;
    this.layout = [{ layoutType: LayoutType.listLayout, direction: "column", children: [] }];

    let parentLayout: ViewTemplateElement = this.layout[0];
    let tabLayout: ViewTemplateElement;

    this.addAutoformTabsheetToPart(parentLayout, this.autoform.mainTabsheet);

    if ((this.autoform.childrenTabsheets && this.autoform.childrenTabsheets.length > 0) || this.autoform.tabsheetList?.length > 1 || this.autoform.allowAttachments) {
      let index = parentLayout.children.push({ layoutType: LayoutType.tabLayout, children: [] });
      tabLayout = parentLayout.children[index - 1];
      if (this.autoform.tabsheetList?.length > 0) {
        tabLayout.children.push({ layoutType: LayoutType.listLayout, direction: "column", tabTitle: "Geral", children: [] });
      }

      parentLayout = tabLayout.children[0];

      this.autoform.childrenTabsheets?.forEach(ct => this.addAutoformChildrenTabsheetToPart(tabLayout, ct));
      this.autoform.tabsheetList?.filter(tab => tab.actionCommand).forEach(tab => this.addAutoformActionTabsheetToPart(tabLayout, tab));
    }

    this.autoform.tabsheetList?.forEach(t => this.addAutoformTabsheetToPart(parentLayout, t));

    if (this.autoform.allowAttachments) {
      this.allowAttachments = true;
      tabLayout.children.push({
        layoutType: LayoutType.component,
        cmpType: T2DsAttachmentsComponent,
        cmpName: "anexo",
        title: "Anexos",
        inputs: {
          datasetName: this.autoform.datasetName,
          parentId: this.informationId,
        }
      });
    }

    this.layout = this.layout[0].children;
  }

  private validateDGArray(ts: any): void {

    if (ts.dataGroupList) {
      ts.dataGroupList = ts.dataGroupList.dataGroup;
      if (!Array.isArray(ts.dataGroupList)) {
        ts.dataGroupList = [ts.dataGroupList];
      }

      ts.dataGroupList.forEach(dg => {
        if (dg.propertyList) {
          dg.propertyList = dg.propertyList.property;
        }

        if (dg.propertyList && !Array.isArray(dg.propertyList)) {
          dg.propertyList = [dg.propertyList];
        }

        dg.propertyList?.forEach((prop: AutoformProperty) => {

          if (prop.fixedComboList?.fixedCombo) {
            // Deixar no mínimo 20 de tamanho, para deixar o combo sem cortar o conteudo
            if (!Array.isArray(prop.fixedComboList.fixedCombo)) {
              prop.fixedComboList.fixedCombo = [prop.fixedComboList.fixedCombo];
            }

            prop.size = prop.fixedComboList.fixedCombo.reduce((maxLen, fixedCombo) => Math.max(maxLen, fixedCombo.description.trim().length), 20);
          }

          if (prop.aggregation) {
            prop.span = 6;
          } else if (prop.javaType === "String") {
            if (prop.contentType === "MEMO") {
              prop.span = 8;
            } else if (prop.contentType === "STATUS") {
              prop.span = 3;
            } else if (prop.size <= 15) {
              prop.span = 1;
            } else if (prop.size <= 30) {
              prop.span = 2;
            } else if (prop.size <= 60) {
              prop.span = 4;
            } else {
              prop.span = 6;
            }
          } else if (prop.sortKey) {
            prop.span = 1
          } else {
            prop.span = 2;
          }
        });

        if (dg.componentList) {
          if (!Array.isArray(dg.componentList)) {
            dg.componentList = [dg.componentList];
          }
        }
      });
    }
  }

  private async readFormControls(ts: AutoformTabsheet): Promise<void> {

    if (!ts) {
      return;
    }

    if (!ts.dataGroupList) return;

    for (let group of ts.dataGroupList) {
      if (group.propertyList) {
        for (let prop of group.propertyList) {
          await this.addProperty(prop, false);
        }
      }
    }
  }

  private async addProperty(prop: AutoformProperty, hidden: boolean): Promise<void> {
    if (prop.contentType === "STATUS") {
      let statusControl = this.formBuilder.control(prop.propValue ?? prop.defaultValue, { updateOn: "blur" });
      this.formGroup.addControl(`${prop.datasetName}.${prop.name}`, statusControl);
      this.t2IdCmpStatus = `${prop.datasetName}.${prop.name}`;
      this.statusDatasetName = prop.datasetName;
      this.fieldStatus = prop;
    } else {
      const validators = new Array<ValidatorFn>();
      if (prop.required) {
        validators.push(Validators.required);
      }

      if (prop.javaType == "Float" || prop.javaType == "Integer") {
        this.formGroup.addControl(`${prop.datasetName}.${prop.name}`, this.formBuilder.control(new Number(prop.propValue ?? prop.defaultValue), validators));
      } else if (prop.javaType == "Date") {
        let value: any = prop.propValue ?? prop.defaultValue;

        if (value) {
          value = new Date(value);
        }

        this.formGroup.addControl(`${prop.datasetName}.${prop.name}`, this.formBuilder.control(value, validators));
      } else if (prop.aggregation || prop.contentType == "FIXED_COMBO") {
        this.formGroup.addControl(`${prop.datasetName}.${prop.name}`, this.formBuilder.control(prop.propValue ?? prop.defaultValue, { validators: validators, updateOn: "change" }));
      } else {
        const formControl = this.formBuilder.control(prop.propValue ?? prop.defaultValue, validators);
        if (prop.propValue == undefined && prop.defaultValue != undefined)
          formControl.markAsDirty();

        this.formGroup.addControl(`${prop.datasetName}.${prop.name}`, formControl);
      }

      if (hidden) return;

      const templateData = {
        cmpName: `${prop.datasetName}.${prop.name}`,
        properties: {
          readOnly: prop.readOnly,
          visible: prop.visible,
        }
      };

      if (prop.aggregation) {
        templateData.properties["description"] = prop.aggregationDescription;
        templateData.properties["datasetName"] = prop.aggregationDatasetName;
        templateData.properties["datasetId"] = prop.aggregationDatasetID;

        templateData.properties["aggregationCondPropId"] = prop.id_dataset_property_cond;
        templateData.properties["aggregationCondPropertyName"] = prop.datasetPropertyNameCond;
        templateData.properties["aggregationCondPropValue"] = prop.aggregationCondPropertyValue;
        templateData.properties["aggregationDatasetDescription"] = prop.aggregationDatasetDescription;

        if (prop.propertyCondPath) {
          let params = new Map<string, string>();
          params.set("propertyPath", prop.propertyCondPath);

          let firstProp = prop.propertyCondPath.split(".")[0];
          let afProp = this.autoformProperties.find(p => p.name == firstProp);

          if (afProp) {
            params.set("idRecordOrigin", afProp.propValue);
            params.set("datasetNameOrigin", prop.datasetName);
          } else if (this.parentId) {
            params.set("idRecordOrigin", this.parentId);
            params.set("datasetNameOrigin", this.autoform.parentDatasetName);
          }

          await this.httpClient.get("core.presentation/getIdFromPropertyPath", params).pipe(take(1)).toPromise().then(resp => {
            if (resp?.id) {
              let cond = new Array<ConditionField>();
              cond.push({
                property: prop.datasetPropertyNameCond,
                operator: "EQUALS",
                value: resp.id
              });

              templateData.properties["fixedFilter"] = cond;
            }
          })
        }
      }

      if (prop.contentType == "FIXED_COMBO") {
        templateData.properties["list"] = prop.fixedComboList?.fixedCombo?.map(item => {
          return {
            id: item.description,
            description: item.description
          }
        })
      }

      this.data.push(templateData);
    }
  }

  private addAutoformTabsheetToPart(l: ViewTemplateElement, t: AutoformTabsheet): void {
    if (!t?.dataGroupList?.length) return;

    let parent: ViewTemplateElement = { layoutType: LayoutType.listLayout, direction: "column", title: t.description, background: "banner", children: [] };

    l.children.push(parent);

    if (!t.actionCommand) {
      t.dataGroupList.forEach(g => this.addAutoformGroupToPart(parent, g));
    }
  }

  private addAutoformGroupToPart(l: ViewTemplateElement, g: AutoformGroup): void {
    if ((!g?.propertyList?.length) && (!g?.componentList?.length)) return;

    // const part: ViewTemplateElement = { layoutType: LayoutType.gridLayout, children: [], title: g.description };
    let part: ViewTemplateElement;
    if (!this.layoutFormList) {
      part = { layoutType: LayoutType.gridLayout, children: [], title: g.description };
    } else {
      part = { layoutType: LayoutType.listLayout, direction: "row", children: [], title: g.description };
    }

    l.children.push(part);

    if (!g.propertyList?.length && g.componentList?.length == 1) {
      part.layoutType = LayoutType.listLayout;
    }

    let cmpCount = 0;

    g.componentList?.filter(c => !c.id_autoform_property).forEach(cmp => {
      cmpCount++;
      let cmpType = this.cmpService.getType(cmp.componentName);
      let cmpMirror = reflectComponentType(cmpType);
      let inputs = {};

      if (cmpMirror.inputs?.some(inp => inp.propName == "saveAutoformParent")) {
        inputs["saveAutoformParent"] = this.saveFromChild.bind(this);
      }

      if (cmpMirror.inputs?.some(inp => inp.propName == "autoformParent")) {
        inputs["autoformParent"] = this;
      }

      let l: ViewTemplateElement = {
        layoutType: LayoutType.component,
        title: cmp.label,
        cmpType: cmpType,
        cmpName: cmp.componentName,
        columnSpan: cmp.columnSpan,
        isBaseComponent: false,
        inputs: inputs
      }

      this.formGroup.addControl(cmp.componentName + cmpCount.toString(), this.formBuilder.control(undefined));

      part.children.push(l);
    })

    if (g.propertyList?.length) {
      for (let p of g.propertyList) {
        if (this.layoutFormList && p.name.toLowerCase() == "sortkey") {
          continue;
        }

        if (p.contentType !== "STATUS" && !p.inactivity) {
          let cmpType = this.cmpService.getType(p.contentType) ?? T2InputTextComponent;
          const c: ViewTemplateElement = {
            layoutType: LayoutType.component,
            title: p.label || p.name,
            columnSpan: p.span || 1,
            toolTip: p.helpText,
            cmpType: p.contentType == "GUID" ? p.aggregation ? cmpType : T2InputTextComponent : cmpType,
            cmpName: `${p.datasetName}.${p.name}`,
            isBaseComponent: true
          };

          if (c.cmpType == T2InputTextComponent && p.size) {
            c.inputs = {
              maxLength: new Number(p.size).valueOf()
            }
          }

          part.children.push(c);

          g.componentList?.filter(c => c.id_autoform_property == p.id_autoform_property).forEach(cmp => {
            cmpCount++;
            let l: ViewTemplateElement = {
              layoutType: LayoutType.component,
              title: cmp.label,
              cmpType: this.cmpService.getType(cmp.componentName),
              cmpName: cmp.componentName + cmpCount.toString(),
              columnSpan: cmp.columnSpan || 1,
              isBaseComponent: false
            }

            this.formGroup.addControl(cmp.componentName + cmpCount.toString(), this.formBuilder.control(undefined));
            part.children.push(l);
          });

        } else if (p.contentType !== "STATUS" && p.inactivity) {
          const c: ViewTemplateElement = {
            layoutType: LayoutType.component,
            title: p.label || p.name,
            columnSpan: p.span || 1,
            toolTip: p.helpText,
            cmpType: T2InactivityComponent,
            cmpName: `${p.datasetName}.${p.name}`,
            isBaseComponent: false,
            inputs: {
              inactive: p.propValue,
              t2IdCmp: `${p.datasetName}.${p.name}`
            },
            outputs: { isInactive: this.changeInactive.bind(this) }
          };

          part.children.push(c);
        }
      };
    }

  }

  private changeInactive({ isInactive, t2IdCmp }): void {
    this.formGroup.controls[t2IdCmp].setValue(isInactive);
    this.formGroup.controls[t2IdCmp].markAsDirty();
  }

  private addAutoformChildrenTabsheetToPart(l: ViewTemplateElement, t: AutoformTabsheet): void {
    let part: ViewTemplateElement;
    if (t.layoutType == "Grid") {
      part = {
        layoutType: LayoutType.component,
        title: t.description,
        cmpType: DatasetTemplateComponent,
        cmpName: t.datasetName,
        inputs: {
          datasetName: t.datasetName,
          parentId: this.informationId,
          searchText: "",
          isChildrenTabsheet: true,
          tabChange: new Subject<any>(),
          tabTitle: t.description,
          saveAutoformParent: this.saveFromChild.bind(this),
          FormGroup: this.formGroup
        }
      }
    } else if (t.layoutType == "Form List") {
      this.awaitChildren = true;
      part = {
        layoutType: LayoutType.component,
        title: t.description,
        cmpType: T2FormListComponent,
        cmpName: t.datasetName,
        inputs: {
          datasetName: t.datasetName,
          parentId: this.informationId,
          parentDatasetName: this.datasetName,
          parentSaved: this.saved.asObservable()
        },
        outputs: {
          hasChanges: () => {
            this.formGroup.markAsDirty();
          },
          saveCompleted: () => {
            this.formListSaved.next();
          }
        }
      }
    }


    l.children.push(part);
  }

  private addAutoformActionTabsheetToPart(l: ViewTemplateElement, t: AutoformTabsheet): void {
    let index = t.actionCommand.indexOf("?"), cmpName: string, params: string;

    if (index > -1) {
      cmpName = t.actionCommand.substring(t.actionCommand.indexOf(":") + 1, index);
      params = t.actionCommand.substring(index + 1);
    } else {
      cmpName = t.actionCommand.substring(t.actionCommand.indexOf(":") + 1);
    }

    let cmpType = this.cmpService.getType(cmpName);

    let part: ViewTemplateElement = {
      layoutType: LayoutType.component,
      title: t.description,
      cmpType: cmpType,
      cmpName: t.description,
      isBaseComponent: false,
      inputs: {}
    }

    if (params) {
      let paramsList = params.split("&");

      paramsList.forEach(param => {
        let key = param.substring(0, param.indexOf("="));
        let value = param.substring(param.indexOf("=") + 1);

        part.inputs[key] = value;
      })
    }

    l.children.push(part);
  }

  saveData(params: { cmpReference: any, data?: any }, reloadData = true): Promise<string> {
    return params.cmpReference.saveAutoform(params.cmpReference, false, reloadData);
  }

  saveFromChild(): Promise<string> {
    return this.saveAutoform(this);
  }

  saveAutoform(afCmp: T2AutoFormComponent, changeSpinner?: boolean, reloadData = true): Promise<string> {
    if (changeSpinner)
      afCmp.waitingDB = true;

    return new Promise<string>((resolve, reject) => {
      if (!afCmp.formGroup.valid) {
        afCmp.messageService.showToastError("Antes de salvar, preencha os campos obrigatórios");
        afCmp.waitingDB = false;
        reject();
        return;
      }

      if (afCmp.formGroup.pristine) {
        afCmp.waitingDB = false;

        resolve(afCmp.informationId);
        return;
      }

      afCmp.startedSaving.emit();

      if (!afCmp.insert && afCmp.formGroup.isAllControlsPristine()) {
        afCmp.waitingDB = false;
        afCmp.saved.emit(afCmp.informationId);
        afCmp.formGroup.markAsPristine();
        resolve(afCmp.informationId);
        return;
      }

      if (afCmp.parentId) {
        afCmp.httpClient.get(`core.dataset/dataset/${afCmp.datasetName}/parentProperty`, null).pipe(take(1)).subscribe(resp => {
          if (resp?.propertyName) {
            afCmp.formGroup.addControl(`${afCmp.datasetName}.${resp.propertyName}`, afCmp.formBuilder.control(afCmp.parentId));
            afCmp.formGroup.controls[`${afCmp.datasetName}.${resp.propertyName}`].markAsDirty();

            afCmp.datasetService.saveFormGroupEntity(afCmp.datasetName, afCmp.informationId, afCmp.formGroup).subscribe(resp => {
              afCmp.saved.emit(resp.datasetEntityID);
              if (afCmp.awaitChildren) {
                afCmp.formListSaved.pipe(takeUntil(afCmp.unsubscribe)).subscribe(() => {
                  afCmp.finalizeSave(afCmp, resp);
                  afCmp.waitingDB = false;

                  resolve(afCmp.informationId);
                });
              } else {
                afCmp.saved.emit(resp.datasetEntityID);
                afCmp.formGroup.markAsPristine();

                if (afCmp.insert) {
                  afCmp.edit = true;
                  afCmp.insert = false;
                  afCmp.buttonDelete = !afCmp.insert && (afCmp.getAction(afCmp.dsActionList, T2AccessItemDatasetActionType.DSACTION_DELETE).length > 0);

                  if (afCmp.allowAttachments) {
                    afCmp.viewTemplate.setCmpInputValue("anexo", "autoformInInsertMode", false);
                  }
                }
              }

              resolve(afCmp.informationId);
            }, error => {
              afCmp.waitingDB = false;
              afCmp.errorInDbOperation.emit();

              if (error.message) {
                afCmp.messageService.showToastError(error.message);
              }

              reject();
            });
          } else {
            afCmp.messageService.showToastError(`Não foi possível carregar a propriedade pai do dataset ${afCmp.datasetName}`);
            console.error(`Não foi possível carregar a propriedade pai do dataset ${afCmp.datasetName}`);
            reject();
          }
        })
      } else {
        if (afCmp.insert && afCmp.hasCompanySiteCtrl) {
          afCmp.formGroup.addControl(`${afCmp.companySiteCtrlDataset}.id_companySite`, afCmp.formBuilder.control(undefined));
          afCmp.formGroup.controls[`${afCmp.companySiteCtrlDataset}.id_companySite`].setValue(afCmp.httpClient.id_companySite);
          afCmp.formGroup.controls[`${afCmp.companySiteCtrlDataset}.id_companySite`].markAsDirty();
        }
        afCmp.datasetService.saveFormGroupEntity(afCmp.datasetName, afCmp.informationId, afCmp.formGroup).subscribe(resp => {
          afCmp.waitingDB = false;

          afCmp.saved.emit(resp.datasetEntityID);
          afCmp.formGroup.markAsPristine();

          if (afCmp.insert) {
            afCmp.edit = true;
            afCmp.insert = false;
            afCmp.buttonDelete = !afCmp.insert && (afCmp.getAction(afCmp.dsActionList, T2AccessItemDatasetActionType.DSACTION_DELETE).length > 0);

            if (afCmp.allowAttachments) {
              afCmp.viewTemplate.setCmpInputValue("anexo", "autoformInInsertMode", false);
            }
          }

          if (reloadData) {
            afCmp.informationId = resp.datasetEntityID; // ao setar o ID irá chamar o método Set que irá recarregar as informações do AutoForm
          } else {
            afCmp._informationId = resp.datasetEntityID;
          }

          resolve(afCmp.informationId);
        }, error => {
          afCmp.waitingDB = false;
          afCmp.errorInDbOperation.emit();

          if (error.message) {
            afCmp.messageService.showToastError(error.message);
          }

          reject();
        });
      }
    });
  }

  private finalizeSave(afCmp: T2AutoFormComponent, resp: any): void {
    afCmp.informationId = resp.datasetEntityID;
    afCmp.formGroup.markAsPristine();

    if (afCmp.insert) {
      afCmp.edit = true;
      afCmp.insert = false;
      afCmp.buttonDelete = !afCmp.insert && (afCmp.getAction(afCmp.dsActionList, T2AccessItemDatasetActionType.DSACTION_DELETE).length > 0);

      if (afCmp.allowAttachments) {
        afCmp.viewTemplate.setCmpInputValue("anexo", "autoformInInsertMode", false);
      }
    }
  }

  exitForm(): void {
    if (!this.inDialog && !this.inFormList) {
      window.close();
    } else if (this.inDialog) {
      this.dialogRef.close();
    }
  }

  deleteRecord(): void {
    this.messageService.showDialog({
      context: {
        topMessage: "ATENÇÃO",
        message: "Deseja excluir esse registro ?",
        actions: [{ description: "Excluir", status: "danger" }, { description: "Cancelar", status: "basic" }]
      }
    }).onClose.subscribe(resp => {
      if (resp == "Excluir") {
        if (!this.inDialog) {
          this.waitingDB = true;
        }

        this.startedDelete.emit();
        this.sec.getDatasetActions(this.datasetName).subscribe(actionList => {
          let actions = actionList.filter(ac => ac.datasetActionType == T2AccessItemDatasetActionType.DSACTION_DELETE);

          if (actions && actions.length > 0) {
            let deleteAction = actions[0],
              params = new Map<string, string>();

            params.set("datasetName", this.datasetName);
            params.set("id", this.informationId);

            this.actionService.executeAction(deleteAction, params).subscribe(resp => {
              this.waitingDB = false;
              if (!resp.error) {
                this.deleted.emit(true);

                this.exitForm();
              }
            }, (error: T2CoreException) => {
              this.waitingDB = false;
              this.errorInDbOperation.emit();
              if (error.message) {
                this.messageService.showToastError(error.message);
              }
            });
          }
        })
      } else {
        this.deleted.emit(false);
      }
    });
  }

  loadLogInformation(): void {
    this.dialogService.open(DialogLogDataComponent,
      {
        autoFocus: true, closeOnEsc: true, hasBackdrop: true,
        context: { id_information: this.informationId, datasetName: this.datasetName }
      });

  }

  getActionsList(): void {
    let groups: Array<GroupSelection> = [];
    let items: Array<ItemSelection> = [];
    let groupName = null;

    if (this.dsActionList) {
      this.dsActions = this.dsActionList.filter(ac => {
        if ([T2AccessItemDatasetActionType.DSACTION_OTHER,
        T2AccessItemDatasetActionType.DSACTION_OTHERCHANGE,
        T2AccessItemDatasetActionType.DSACTION_OTHERCLOSE
        ].includes(ac.datasetActionType)) {
          return ac;
        }
      });
    }

    this.dsActions.forEach(action => {
      groupName = action.datasetDescription || action.datasetGroup || groupName;
      items.push(
        {
          id_item: action.id,
          itemName: action.datasetActionDescription,
          selected: false
        });

      if (groups.find(g => g.id_group == action.id_dataset_group)) {
        return;
      }

      groups.push(
        {
          id_group: action.id_dataset_group,
          groupName: null,
          selected: false,
          items: items
        });
    });

    const dialogActions = this.dialogService.open(
      DialogItemSelectionComponent,
      {
        context: {
          title: groupName,
          groupSelection: 'none',
          itemSelection: 'single',
          itemFromDifGroups: false,
          groups: groups,
          useConfirmButton: false,
          buttonCase: 'upper',
          buttonSize: 'large'
        },
      }
    );

    dialogActions.onClose.subscribe(resp => {
      if (resp) {
        let i = items.find(item => item.selected);

        let id_action = null;
        if (i) {
          id_action = i.id_item;
        }

        const action: T2AccessItem = this.dsActions.find(a => a.id == id_action);
        let params = new Map<string, string>();
        params.set("id", this.informationId);

        if (action.datasetActionType == T2AccessItemDatasetActionType.DSACTION_OTHERCHANGE && this.formGroup.dirty) {
          this.saveAutoform(this, true).then(() => {
            this.executeAction(action, params);
          })
        } else {
          this.executeAction(action, params);
        }
      }
    });
  }

  private executeAction(action: T2AccessItem, params: Map<string, string>): void {
    let obs = this.actionService.executeAction(action, params);
    if (obs) {
      obs.pipe(take(1)).subscribe(resp => {
        this.messageService.showToast("Ação executada com sucesso", action.description, "success");
      }, error => {
        this.messageService.showToastError(error);
        console.error(error);
      }, () => {
        if (action.datasetActionType == T2AccessItemDatasetActionType.DSACTION_OTHERCHANGE) {
          this.loaded = false;
          this.loadFormInfo();
        }
      });
    }
  }

  onStatusChanged(params: { status: T2Status, reloadData: boolean }): void {
    if (params.reloadData) {
      this.loaded = false;
      this.loadFormInfo();
    } else {
      this.fieldStatus.propValue = params.status.name;
      this.formGroup.controls[this.t2IdCmpStatus].setValue(params.status.name);
      this.setAllControlsReadOnly(params.status.blockChange);
    }
  }

  // private getStatusStyleList(): Array<{ status: string, style: string }> {
  //   return this.statusList?.map(color => {
  //     return { status: color.description, style: color.statusColorStyle }
  //   })
  // }

  // private getCurrentStatus() {
  //   let status = this.statusList?.find(stat => stat.description == this.fieldStatus.propValue);
  //   return {
  //     name: status?.description,
  //     action: status?.execDescription,
  //     tooltip: status?.helpText,
  //     colorStyle: status?.statusColorStyle,
  //     blockChange: status?.blockChanges
  //   }
  // }

  setAllControlsReadOnly(readOnly: boolean): void {
    this.formGroup.setReadOnly(readOnly);
  }
}
