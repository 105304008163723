<div class="slider flexColumn" style="gap: 5px;" [nbSpinner]="loading">
  <div *ngFor="let task of taskSequenceList; let i = index" class="flexRow" style="width: 100%; height: fit-content;"
    [style.transform]="'translateX(' + task.translateX + 'px)'" (touchstart)="onTouchStart($event, i)"
    (touchmove)="onTouchMove($event, i)" (touchend)="onTouchEnd($event, i)">
    <div class="T2GrayArea taskCard" style="flex: 1;">
      <div style="display: flex;">
        <div class="taskCount"> {{task.position}}
        </div>
        <div class="taskInfoCard">

          <div class="flexColumn" style="gap: 6px; ">

            <div style="display: flex; justify-content: space-between; align-items: center;">
              <div class="prodOrderNumber "> {{task.prodOrder}}</div>
              <div class="taskSalesOrderDescription "> {{task.taskDescription}}</div>
              <div class="planDate "> {{task.planDate | date: "dd/MM/yy HH:mm"}}</div>
            </div>

            <div>
              <div class="opInfo">{{task.customer}} </div>
              <div class="opInfo"> {{task.prodOrderDescription}}</div>
              <div *ngIf="task.reworkProdOrder" class="opRetrabalho">Retrabalho {{task.reworkProdOrder}} </div>
            </div>

            <div style="display: flex; justify-content: space-between; align-items: center;">
              <div class="flexColumn" style="justify-content: center; line-height: 0.75em;">
                <div class="salesOrderTaskTitle ">Demandas</div>
                <div class="taskSalesOrderDescription ">{{task.salesOrder}}</div>
              </div>
              <div class="flexColumn" style="justify-content: center; line-height: 0.75em;">
                <div *ngIf="task.shippingType" class="salesOrderTaskTitle">Tipo Frete</div>
                <div class="taskSalesOrderDescription ">{{task.shippingType}}</div>
              </div>
              <div>
                <div class="flexColumn" style="justify-content: center; line-height: 0.75em;">
                  <div class="salesOrderTaskTitle ">Entrega</div>
                  <div class="taskSalesOrderDescription ">{{task.shippingDate | date: "dd/MM/yy"}}</div>
                </div>
              </div>
              <app-task-urgent [urgent]="task.urgent"></app-task-urgent>
            </div>

            <div style="display: flex; justify-content: space-between; align-items: center;">
              <div class="flexColumn" style="line-height: 0.8rem; flex-wrap: wrap;">
                <div class="salesOrderTaskTitle ">Tarefas da OP</div>
                <app-shop-floor-task-flow [taskFlowList]="task.tasksList" />
              </div>
              <app-task-separation-status [stockAvailable]="task.stockAvailable" [mpSeparate]="task.mpSeparate"
                [percSeparation]="task.percSeparation" [hasRequisition]="task.hasRequisition">
              </app-task-separation-status>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
