import { Component, Input, OnInit } from '@angular/core';
import { StructureLayer } from "../flx-product-specification.service";

@Component({
  selector: 'app-eppstructure-layer-substrate',
  templateUrl: './eppstructure-layer-substrate.component.html',
  styleUrls: ['./eppstructure-layer-substrate.component.scss']
})
export class EppstructureLayerSubstrateComponent implements OnInit {

  @Input() structLayer: StructureLayer;

  private _blockChange: boolean;
  @Input()
  get blockChange(): boolean {
    return this._blockChange;
  }
  set blockChange(value: boolean) {
    this._blockChange = value;
  }

  constructor() { }

  ngOnInit(): void {
  }

}
