<div [ngStyle.xs]="{'width':'300px'}">
  <app-dialog-template #dialog [title]="title">
    <content>
      <div class="flexAlignRowEndCenter">
        <div style="margin-right: 5px;">Volumes</div>
        <editor-cell #volume [editorValue]="qtdVolume ? qtdVolume: 0.0" (changed)="onVolumeChange($event)"
          style="width: 100px;"></editor-cell>
      </div>

      <div class="flexAlignRowEndCenter" style="margin-top: 5px;">
        <div style="margin-right: 5px;">Peso</div>
        <editor-cell [editorValue]="peso ? peso : 0.0" (changed)="onPesoChange($event)"
          style="width: 100px;"></editor-cell>
      </div>
    </content>
    <footer>
      <div class="flexAlignRowCenter flexGap5">
        <button (click)="confirm()" nbButton size="tiny" status="primary">
          Confirmar
        </button>
        <button (click)="cancel()" nbButton size="tiny" status="primary">
          Cancelar
        </button>
      </div>
    </footer>
  </app-dialog-template>
</div>
