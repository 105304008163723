import { Injectable, OnDestroy } from '@angular/core';
import { FirebaseApp, initializeApp } from "firebase/app";
import { getFirestore, writeBatch, setDoc, doc, updateDoc, Firestore, getDocFromServer, getDocs, collection, onSnapshot, DocumentSnapshot, DocumentData, query, where, QueryConstraint, QuerySnapshot, Unsubscribe, addDoc, deleteDoc, getDocsFromServer } from "firebase/firestore";
import { BehaviorSubject, Subject } from "rxjs";
import { getAuth, signInWithCustomToken } from "firebase/auth";

const apsApp = {
  apiKey: "AIzaSyBxM7YtX_ePNSIA0WVauY-Ljyk0FI9rBRY",
  authDomain: "techprod-aps.firebaseapp.com",
  projectId: "techprod-aps",
  storageBucket: "techprod-aps.appspot.com",
  messagingSenderId: "976432108662",
  appId: "1:976432108662:web:d89c070b45d4ae3264fb57",
  measurementId: "G-BMST8D52CC"
};

@Injectable()

export class T2FirestoreService implements OnDestroy {

  private firestore: Firestore;
  private firebaseApp: FirebaseApp;
  private unsubList = new Array<{ sub: Subject<any>, unsub: Unsubscribe }>();

  constructor() { }

  ngOnDestroy(): void {
    this.unsubscribeFromStoppedListeners();
  }

  public unsubscribeFromStoppedListeners() {
    let removeList = this.unsubList.filter(obj => obj.sub.observers.length == 0);

    removeList.forEach(obj => {
      obj.unsub();
      obj.sub.complete();
      this.unsubList.splice(this.unsubList.indexOf(obj), 1);
    });
  }

  public initialize(app: { aps: boolean }) {
    if (app.aps) {
      this.firebaseApp = initializeApp(apsApp, "APS");
      this.firestore = getFirestore(this.firebaseApp);
    }
  }

  public signInWithToken(token: string) {
    this.checkInitialized();

    let auth = getAuth(this.firebaseApp);
    return signInWithCustomToken(auth, token);
  }

  private checkInitialized() {
    if (!this.firestore) {
      throw Error("O serviço T2FirestoreService não foi inicializado");
    }
  }

  public addDocument(path: string, data: { [key: string]: any }, docId?: string,) {
    this.checkInitialized();

    if (docId) {
      return setDoc(doc(this.firestore, path, docId), data);
    } else {
      return addDoc(collection(this.firestore, path), data);
    }
  }

  public addDocumentList(path: string, docList: Array<{ docId: string, data: { [key: string]: any } }>) {
    this.checkInitialized();

    if (docList.length > 500) {
      throw Error("As gravações em lote são limitadas a 500 documentos por vez");
    }

    let batch = writeBatch(this.firestore);

    docList.forEach(dc => {
      batch.set(doc(this.firestore, path, dc.docId), dc.data);
    });

    return batch.commit();
  }

  public updateDocument(path: string, docId: string, data: { [key: string]: any }, overrideDoc: boolean = false) {
    this.checkInitialized();
    if (overrideDoc) {
      return setDoc(doc(this.firestore, path, docId), data);
    } else {
      return updateDoc(doc(this.firestore, path, docId), data);
    }
  }

  public updateDocumentList(path: string, docList: Array<{ docId: string, data: { [key: string]: any }, overrideDoc?: boolean }>) {
    this.checkInitialized();
    let batch = writeBatch(this.firestore);

    docList.forEach(dc => {
      if (dc.overrideDoc) {
        batch.set(doc(this.firestore, path, dc.docId), dc.data);
      } else {
        batch.update(doc(this.firestore, path, dc.docId), dc.data);
      }
    });

    return batch.commit();
  }

  public getDocument(path: string, docId: string) {
    this.checkInitialized();
    return getDocFromServer(doc(this.firestore, path, docId));
  }

  public getAllDocumentsFromCollection(path: string) {
    this.checkInitialized();
    return getDocs(collection(this.firestore, path));
  }

  public getDocumentListener(path: string, docId: string) {
    this.checkInitialized();

    let sub$ = new BehaviorSubject<DocumentSnapshot<DocumentData>>(undefined);
    let unsub = onSnapshot(doc(this.firestore, path, docId), ds => {
      sub$.next(ds);
    }, error => {
      sub$.error(error);
    });

    this.unsubList.push({ sub: sub$, unsub: unsub });

    return sub$.asObservable();
  }

  public getCollectionListener(path: string, ...queryConstraints: QueryConstraint[]) {
    let q = query(collection(this.firestore, path), ...queryConstraints);
    let sub$ = new BehaviorSubject<Array<DocumentSnapshot<DocumentData>>>(undefined);

    let unsub = onSnapshot(q, querySnap => {
      let docList = new Array<DocumentSnapshot<DocumentData>>();
      querySnap.forEach(ds => {
        docList.push(ds);
      });

      sub$.next(docList);
    }, error => {
      sub$.error(error);
    });

    this.unsubList.push({ sub: sub$, unsub: unsub });

    return sub$.asObservable();
  }

  public getDocuments(path: string, ...queryConstraints: QueryConstraint[]) {
    let q = query(collection(this.firestore, path), ...queryConstraints);

    return getDocsFromServer(q);
  }

  public deleteDocument(path: string, docId: string) {
    this.checkInitialized();
    return deleteDoc(doc(this.firestore, path, docId));
  }
}
