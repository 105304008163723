export class DatasetPropertyStatusItem {
  description: string;
  execDescription?: string;
  helpText?: string;
  sortKey: Number;
  endsInformation?: boolean;
  statusColor?: string;
  statusColorStyle?: string;
  blockChanges?: boolean;
  isDefaultValue?: boolean;
}
