<app-dialog-template #dialog [title]="title" [ngStyle]="ngStyle">
  <content class="flexColumn">
    <div *ngIf="topMessage" style="margin-bottom: 20px; font-size: 1.05rem; font-weight: bold; min-width: 400px;">
      {{topMessage}}</div>
    <app-view-template #viewTemplate [layout]="layout" [FormGroup]="FormGroup" [data]="data" [actions]="actions"
      [flows]="flows"></app-view-template>
  </content>
  <footer>
    <div class="button-row">
      <button nbButton status="basic" size="tiny" (click)="closeDialog('Cancela')">Cancela</button>
      <button nbButton status="primary" size="tiny" (click)="closeDialog('Confirma')">Confirma</button>
    </div>
  </footer>
</app-dialog-template>