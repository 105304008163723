import { DatasetPropertyFixedComboItem } from "./dataset-property-fixed-combo-item";
import { DatasetPropertyStatusItem } from "./dataset-property-status-item";
import { DatasetRelationed } from "./dataset-relationed";

export class DatasetProperty {

  id_dataset_property: string;
  id_dataset: string;
  datasetName: string;
  propertyName: string;
  propertyLabel: string;
  contentType: string;

  sortkey: number;
  required: boolean;
  requiredForPublication: boolean;
  defaultValue: string;

  code: boolean;
  descriptive: boolean;
  primaryKey: boolean;
  statusCtrl: boolean;
  deletionCtrl: boolean;
  inactivityCtrl: boolean;
  publicationCtrl: boolean;
  companySiteCtrl: boolean;

  relationedWith: Array<DatasetRelationed>;
  fixedComboList: Array<DatasetPropertyFixedComboItem>;
  statusList: Array<DatasetPropertyStatusItem>;
}
