import { DialogItemSelectionComponent, GroupSelection, ItemSelection } from 'src/app/core/cmp/dialog-item-selection/dialog-item-selection.component';
import { DialogFinishedReprovesSeparationComponent } from "./dialog-finished-reproves-separation/dialog-finished-reproves-separation.component";
import { DialogFinishedReprovesPackingComponent } from "./dialog-finished-reproves-packing/dialog-finished-reproves-packing.component";
import { DialogInPackingVolumesComponent } from "./dialog-in-packing-volumes/dialog-in-packing-volumes.component";
import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { NbDialogService } from "@nebular/theme";
import { take, takeUntil } from "rxjs/Operators";
import { FormTemplateComponent } from "src/app/core/cmp/form-template/form-template.component";
import { T2HttpClientService } from "src/app/core/http/t2httpClient.service";
import { T2SecurityService } from "src/app/core/security/t2security.service";
import { T2MessageService } from "src/app/core/t2-message.service";
import { Packing } from "./model/packing";
import { T2PrinterService } from "src/app/core/printer/t2-printer.service";
import { Subject } from "rxjs";

@Component({
  selector: "app-packing",
  templateUrl: "./packing.component.html",
  styleUrls: ["./packing.component.scss"],
})
export class PackingComponent implements OnInit, OnDestroy {
  public loaded = false;
  public loadingPending = false;
  public loadingInPacking;
  public loadingFinished;
  private currentStage = "Pendentes";
  private printerConfig;
  public packer: boolean;
  public inspector: boolean;
  private unsubscribe = new Subject<void>()

  @ViewChild("formTemplate", { static: true }) formTemplate: FormTemplateComponent;
  currentTab = "Pendentes";
  pendingPacking: Array<Packing> = [];
  inPacking: Array<Packing> = [];
  finishedPacking: Array<Packing> = [];
  reasonList: Array<any> = [];

  constructor(
    private sec: T2SecurityService,
    private httpClient: T2HttpClientService,
    private dialogService: NbDialogService,
    private printService: T2PrinterService,
    private messageService: T2MessageService
  ) { }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  ngOnInit(): void {
    this.sec
      .accessLoaded()
      .pipe(take(1))
      .subscribe(() => {
        if (
          this.formTemplate.validateAccess(["zD20210723H134635550R000000005"], 'dataCollector')
        ) {
          this.config();
          this.loaded = true;
        }
      });

    this.sec.companySiteObservable()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(resp => {
        this.config();
      })
  }

  private loadData() {
    this.pendingPacking = [];
    this.loadPendingPacking();
    this.inPacking = [];
    this.loadInPacking();
    if (this.inspector) {
      this.finishedPacking = [];
      this.loadFinished();
    }
  }

  getButtonStatus(button: string) {
    if (button === this.currentStage) {
      return "primary";
    }

    return "basic";
  }

  changeStage(newStage: string) {
    this.currentStage = newStage;
    this.currentTab = this.currentStage;
  }

  onTabChange(params) {
    this.currentTab = params.tabTitle;
  }

  private loadPendingPacking() {
    this.loadingPending = true;

    this.pendingPacking = [];

    this.httpClient
      .get(`stk.packing/pendingPacking`, null)
      .pipe(take(1))
      .subscribe(
        (resp: any) => {
          try {
            if (resp.packingList) {
              this.pendingPacking = this.validatePackingList(resp.packingList);
            }
          } finally {
            this.loadingPending = false;
          }
        },
        (error) => {
          this.loadingPending = false;
        }
      );
  }

  onPackingClick(event: any) {
    const packing: Packing = event.packing;
    const currentTab: string = event.currentTab;

    switch (currentTab) {
      case "Pendentes":
        this.pendenteClick(packing);
        break;

      case "Embalando":
        this.embalandoClick(packing);
        break;

      case "Finalizados":
        this.finalizadoClick(packing);
        break;

      default:
        break;
    }
  }

  private pendenteClick(packing: Packing) {

    if (this.packer) {
      this.messageService
        .showDialog({
          context: {
            title: `Pedido ${packing.salesOrder}`,
            message: "Iniciar o processo de embalagem?",
            actions: [{ description: "Sim" }, { description: "Não", status: "basic" }],
          },
        })
        .onClose.pipe(take(1))
        .subscribe((resp) => {
          if (resp === "Sim") {
            this.startPacking(packing.id_packingList);
          } else {
          }
        });
    }
  }

  private getEmbalandoActionsGroup() {
    let groups: Array<GroupSelection> = [];
    let items: Array<ItemSelection> = [];

    items.push(
      {
        id_item: "Informar Volumes e Pesos",
        itemName: "Informar Volumes e Pesos",
        selected: false
      });

    items.push(
      {
        id_item: "Imprimir etiquetas",
        itemName: "Imprimir etiquetas",
        selected: false
      });

    items.push(
      {
        id_item: "Retornar para pendentes",
        itemName: "Retornar para pendentes",
        selected: false
      });

    items.push(
      {
        id_item: "Finalizar embalagem",
        itemName: "Finalizar embalagem",
        selected: false
      });

    items.push(
      {
        id_item: "Retornar para separação",
        itemName: "Retornar para separação",
        selected: false
      });

    groups.push({
      id_group: "EmbalandoActionGroup",
      groupName: null,
      selected: false,
      items: items
    });

    return groups;

  }

  private getFinalizadosActionsGroup() {
    let groups: Array<GroupSelection> = [];
    let items: Array<ItemSelection> = [];

    items.push(
      {
        id_item: "Aprovar Inspeção",
        itemName: "Aprovar Inspeção",
        selected: false
      });

    items.push(
      {
        id_item: "Reprovar e retornar para separação",
        itemName: "Reprovar e retornar para separação",
        selected: false
      });

    items.push(
      {
        id_item: "Reprovar e retornar para embalagem",
        itemName: "Reprovar e retornar para embalagem",
        selected: false
      });

    groups.push({
      id_group: "EmbalandoActionGroup",
      groupName: null,
      selected: false,
      items: items
    });

    return groups;

  }

  private embalandoClick(packing: Packing) {
    if (this.packer) {
      const groups = this.getEmbalandoActionsGroup();

      const dialogInPackingActions = this.dialogService.open(
        DialogItemSelectionComponent,
        {
          context: {
            title: "Embalando",
            groupSelection: 'none',
            itemSelection: 'single',
            itemFromDifGroups: false,
            groups: groups,
            useConfirmButton: false,
            buttonSize: 'large'
          },
        }
      );

      dialogInPackingActions.onClose.subscribe(resp => {
        const selection = resp.items && resp.items.length ? resp.items[0] : null;

        const queryParams = new Map<string, string>();
        queryParams.set("ID", packing.id_packingList);

        switch (selection) {
          case "Retornar para pendentes":
            queryParams.set("id_packingList", packing.id_packingList);
            this.httpClient
              .put("stk.packing/returnPackingToPending", queryParams, null)
              .pipe(take(1))
              .subscribe((resp2) => {
                this.loadData();
              }, (error) => { });
            break;

          case "Informar Volumes e Pesos":
            queryParams.set("id_packingList", packing.id_packingList);
            this.dialogService.open(DialogInPackingVolumesComponent,
              {
                context: {
                  title: "Insira a quantidade de volumes e o peso",
                  id_packingList: packing.id_packingList,
                  qtdVolume: packing.volumes,
                  peso: packing.peso,
                },
              }).onClose.subscribe((resp5) => {
                if (resp5 && resp5.type === "confirm") {
                  queryParams.set("volumes", resp5.qtdVolume);
                  queryParams.set("peso", resp5.peso);

                  this.httpClient
                    .put("stk.packing/setVolumes", queryParams, null)
                    .pipe(take(1))
                    .subscribe((resp3) => {
                      this.loadData();
                    }, (error) => { });
                }
              });
            break;

          case "Finalizar embalagem":
            queryParams.set("id_packingList", packing.id_packingList);
            this.loadingInPacking = true;
            this.httpClient
              .put("stk.packing/finishesPacking", queryParams, null)
              .pipe(take(1))
              .subscribe((resp4) => {
                this.loadingInPacking = false;
                this.loadData();
              }, (error) => {
                this.loadingInPacking = false;
              });

            break;

          case "Imprimir etiquetas":
            this.printService.printReport(this.printerConfig, queryParams);
            break;

          case "Retornar para separação":
            this.reprovarRetornarSeparacao(packing.id_packingList);
            break;
        }
      });
    }
  }

  private finalizadoClick(packing: Packing) {
    const groups = this.getFinalizadosActionsGroup();

    const dialogFinishedActions = this.dialogService.open(
      DialogItemSelectionComponent,
      {
        context: {
          title: "Finalizados",
          groupSelection: 'none',
          itemSelection: 'single',
          itemFromDifGroups: false,
          groups: groups,
          useConfirmButton: false,
          buttonSize: 'large'
        },
      }
    );

    dialogFinishedActions.onClose.subscribe(resp => {
      const selection = resp.items && resp.items.length ? resp.items[0] : null;

      const queryParams = new Map<string, string>();
      queryParams.set("id_packingList", packing.id_packingList);

      switch (selection) {
        case "Aprovar Inspeção":
          this.loadingFinished = true;
          this.httpClient
            .put("stk.packing/finishesInspection", queryParams, null)
            .pipe(take(1))
            .subscribe((resp1) => {
              this.loadingFinished = false;
              this.loadData();
            }, (error1) => {
              this.loadingFinished = false;
            });
          break;

        case "Reprovar e retornar para embalagem":
          this.dialogService.open(
            DialogFinishedReprovesPackingComponent,
            {
              context: {
                title: "Escreva o motivo da reprovação",
                id_packingList: packing.id_packingList,
              },
            }
          ).onClose.subscribe((resp2) => {
            if (resp2 && resp2.type === "confirm") {
              queryParams.set("reproveReason", resp2.reproveReason);
              this.httpClient
                .put("stk.packing/reprovesPacking", queryParams, null)
                .pipe(take(1))
                .subscribe((resp3) => {
                  this.loadingFinished = false;
                  this.loadData();
                }, (error3) => {
                  this.loadingFinished = false;
                });
            }
          });
          break;

        case "Reprovar e retornar para separação":
          this.reprovarRetornarSeparacao(packing.id_packingList);
          break;
      }
    });
  }

  private reprovarRetornarSeparacao(id_packingList: string) {
    const dialogFinishedReprovesSeparation = this.dialogService.open(
      DialogFinishedReprovesSeparationComponent,
      {
        context: {
          title: "Escolha o motivo do retorno para a separação",
          id_packingList: id_packingList,
          reasonList: this.reasonList
        },
      }
    );

    dialogFinishedReprovesSeparation.onClose.subscribe((resp4: any) => {

      if (resp4) {
        if (resp4.type === "confirm") {
          this.loadingFinished = true;

          const params = new Map<string, string>();
          params.set("id_packingList", resp4.id_packingList);
          params.set("id_reason", resp4.reproveReason);
          this.httpClient
            .put(`stk.packing/reprovesSeparation`, params, { id_packingList: resp4.id_packingList, id_reason: resp4.reproveReason, notes: resp4.notes })
            .pipe(take(1))
            .subscribe(
              (resp5: any) => {
                this.loadingFinished = false;
                this.loadData();
              },
              (error5) => {
                this.loadingFinished = false;
              }
            );
        }
      }
    });

  }

  private startPacking(id_packingList) {
    const params = new Map<string, string>();
    params.set("id_packingList", id_packingList);
    this.httpClient
      .post(`stk.packing/startPacking`, params, null)
      .pipe(take(1))
      .subscribe(
        (resp: any) => {
          this.loadData();
          this.changeStage("Embalando");
        },
        (error) => { }
      );
  }

  private loadInPacking() {
    this.loadingInPacking = true;

    this.inPacking = [];

    const params = new Map<string, string>();
    params.set("inspector", String(this.inspector));
    this.httpClient
      .get(`stk.packing/inPacking`, params)
      .pipe(take(1))
      .subscribe(
        (resp: any) => {
          try {
            if (resp.packingList) {
              this.inPacking = this.validatePackingList(resp.packingList);
            }
          } finally {
            this.loadingInPacking = false;
          }
        },
        (error) => {
          this.loadingInPacking = false;
        }
      );
  }

  private validatePackingList(respPackingList: any): Array<Packing> {

    if (!respPackingList) {
      respPackingList = [];
    }

    if (!Array.isArray(respPackingList)) {
      respPackingList = [respPackingList];
    }

    const packingList: Array<Packing> = respPackingList;

    packingList.forEach((packing) => {
      if (packing.salesOrderList && !Array.isArray(packing.salesOrderList)) {
        packing.salesOrderList = [packing.salesOrderList];
      }

      if (packing.itemList && !Array.isArray(packing.itemList)) {
        packing.itemList = [packing.itemList];
      }

      packing.itemList.forEach(item => {
        item.requiredAmount = Number(item.requiredAmount);
        item.separatedAmount = Number(item.separatedAmount);
      });
    });

    return packingList;
  }

  private loadFinished() {
    this.loadingFinished = true;

    this.inPacking = [];

    this.httpClient
      .get(`stk.packing/finishedPacking`, null)
      .pipe(take(1))
      .subscribe(
        (resp: any) => {
          try {
            if (resp.packingList) {
              this.finishedPacking = this.validatePackingList(resp.packingList);
            }
          } finally {
            this.loadingFinished = false;
          }
        },
        (error) => {
          this.loadingFinished = false;
        }
      );
  }

  private config() {
    this.httpClient
      .get("stk.packing/config", null)
      .pipe(take(1))
      .subscribe(
        (resp) => {
          this.printerConfig = resp.id_report_label;
          this.packer = resp.packer;
          this.inspector = resp.inspector;
          this.reasonList = resp.reasonList;

          this.loadData();
        },
        (error) => { }
      );
  }
}
