import { T2AccessItem } from "./t2accessItem";
import { T2AccessSubGroup } from "./t2accessSubGroup";

export class T2AccessGroup {

  public index: number;
  public name: string;
  public accessList = new Array<T2AccessItem>();
  public accessSubMenu = new Array<T2AccessSubGroup>();

  selected = false;
}
