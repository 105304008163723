import { Component, Input, ViewChild } from '@angular/core';
import { PowerBIReportEmbedComponent } from "powerbi-client-angular";
import { IReportEmbedConfiguration, models, service, Embed } from 'powerbi-client';
import { T2HttpClientService } from "src/app/core/http/t2httpClient.service";
import { take } from "rxjs/Operators";

@Component({
  selector: 'app-t2-powerbi',
  templateUrl: './t2-powerbi.component.html',
  styleUrls: ['./t2-powerbi.component.scss']
})
export class T2PowerbiComponent {

  @Input() embedUrl: string;
  @Input() type: string = "report";
  @Input() clientId: string;
  @Input() idReport: string;
  @Input() groupId?: string;
  @Input() datasetId?: string;

  refreshing: boolean = false;
  showMsg: boolean = false;
  refreshDate: string;

  @ViewChild('reportBI', { static: false }) reportBI: PowerBIReportEmbedComponent;

  eventHandlersMap = new Map([
    ['loaded', () => {
      const report = this.reportBI.getReport();
      report.setComponentTitle('Embedded report');
      console.log('Report has loaded');
    },
    ],
    ['rendered', () => console.log('Report has rendered')],
    ['error', (event?: service.ICustomEvent<any>) => {
      if (event) {
        console.error(event.detail);
      }
    },
    ],
    ['visualClicked', () => console.log('visual clicked')],
    ['pageChanged', (event) => console.log("pageChanged ", event)],
  ]) as Map<string, (event?: service.ICustomEvent<any>, embeddedEntity?: Embed) => void | null>;

  reportConfig: IReportEmbedConfiguration = {
    type: 'report',
    embedUrl: undefined,
    //tokenType: models.TokenType.Embed,
    accessToken: undefined,
    settings: undefined,
  };

  phasedEmbeddingFlag = false;
  isEmbedded = false;

  constructor(private httpClient: T2HttpClientService) { }

  ngAfterViewInit(): void {
    let accessToken: string;
    let params = new Map<string, string>();
    params.set("clientId", this.clientId);

    this.httpClient.get("core.powerBI/getAuthToken", params)
      .pipe(take(1))
      .subscribe(resp => {
        accessToken = resp.message;
        this.reportConfig = {
          type: "report",
          id: this.idReport,
          embedUrl: this.embedUrl,
          accessToken: accessToken,
          settings: {
            filterPaneEnabled: true,
            panes: {
              filters: { visible: true },
              pageNavigation: { visible: true }
            }
          },
        };
      }, error => {
      });

    this.isEmbedded = true;
  }

  ngOnInit(): void { }

  onClick() {
    const report = this.reportBI.getReport();
    report.refresh().then(v => {
      console.log("Refreshes data sources for the report.");
    }, e => {
      console.log(e);
    });
  }

  refreshData() {
    this.refreshing = true;
    let params = new Map<string, string>();
    params.set("groupId", this.groupId);
    params.set("datasetId", this.datasetId);
    params.set("clientId", this.clientId);

    this.httpClient.post("core.powerBI/refreshData", params, null)
      .pipe(take(1))
      .subscribe(resp => {
        this.showMsg = true;
        const date = new Date();
        this.refreshDate = date.toLocaleDateString() + " às " + date.toLocaleTimeString();
      }, error => {
        this.refreshing = false;
      });
  }

}
