import { Component, OnInit, ViewChild } from '@angular/core';
import { DialogTemplateComponent } from "../../cmp/dialog-template/dialog-template.component";

@Component({
  selector: 'app-action-dialog',
  templateUrl: './action-dialog.component.html',
  styleUrls: ['./action-dialog.component.scss']
})
export class ActionDialogComponent implements OnInit {

  public actionResponseMsg: any;
  public isError: boolean = false;
  public actionDescription: string;
  public showCloseButton: boolean = false;

  @ViewChild('dialog', { static: true }) dialog: DialogTemplateComponent;
  
  constructor() { }

  ngOnInit(): void {
  }

  closeDialog() {
    this.dialog.close(null);
  }

}
