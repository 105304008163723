import { Component, Input } from '@angular/core';
import { ColorInfoDetail } from "../model/color-info-detail";
import { ProdOrderReleaseService } from "../prod-order-release.service";
import { take } from "rxjs/Operators";
import { T2LocalStorageService } from "src/app/core/t2local-storage.service";

@Component({
  selector: 'app-color-detail',
  templateUrl: './color-detail.component.html',
  styleUrls: ['./color-detail.component.scss']
})
export class ColorDetailComponent {

  @Input() 
  get prodSpecifId() {
    return this._prodSpecifId;
  }
  set prodSpecifId(value: string) {
    this._prodSpecifId = value;
    this.loadColorInfoDetail();
  }

  private _prodSpecifId: string;
  public colorList: Array<ColorInfoDetail>;
  public loading: boolean = false;

  constructor(private releaseService: ProdOrderReleaseService, private storage: T2LocalStorageService) {

  }

  loadColorInfoDetail() {
    this.loading = true;
    let obj: any = JSON.parse(this.storage.getSessionData(`psColorDetail#${this.prodSpecifId}`));

    if (!obj) {
      if (this.prodSpecifId) {
        this.releaseService.getColorInfoList(this.prodSpecifId).pipe(take(1)).subscribe(list => {
          this.colorList = list;
          obj = JSON.stringify(this.colorList);

          this.storage.setSessionData(`psColorDetail#${this.prodSpecifId}`, obj);

          this.loading = false;
        }, error => {
          this.loading = false;
        })
      }
    } else {
      this.colorList = obj;
      this.loading = false;
    }
  }
}
