import { Component, OnInit } from '@angular/core';
import { DeviceCacheService } from "../../device-cache.service";

@Component({
  selector: 'app-shop-floor-sequencing-filter',
  templateUrl: './shop-floor-sequencing-filter.component.html',
  styleUrls: ['./shop-floor-sequencing-filter.component.scss']
})
export class ShopFloorSequencingFilterComponent implements OnInit {

  statusList: Array<{ description: string, checked: boolean }>;
  constructor(private devCacheService: DeviceCacheService) { }

  ngOnInit() {
    this.statusList = [
      { description: 'Iniciada', checked: true },
      { description: 'Suspensa', checked: true },
      { description: 'Separada', checked: true },
      { description: 'Não separada', checked: true },
      { description: 'Sem estoque', checked: true }
    ];
  }

  filterTasks(value) {
    this.devCacheService.setSelectedFilterTasks(this.statusList.filter(f => f.checked));
  }

}
