<app-dialog-template #dialog [title]="'Histórico de Alterações'">
  <content style="width: 80vw; height: 45vh;">
    <app-t2-log [id_information]="id_information" [datasetName]="datasetName"></app-t2-log>
  </content>
  <footer>
    <div style="display: flex; justify-content: flex-end; align-items: center;">
      <button nbButton status="primary" size="tiny" (click)="closeDialog()">Fechar</button>
    </div>
  </footer>
</app-dialog-template>



