import { Component, OnInit, OnDestroy } from '@angular/core';
import { T2SecurityService } from '../security/t2security.service';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { take } from "rxjs/Operators";
import { T2LocalStorageService } from '../t2local-storage.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {

  private authSub$: Subscription = null;
  public loginForm: FormGroup;
  public loginError = '';

  constructor(
    private sec: T2SecurityService,
    private storage: T2LocalStorageService,
    private formBuilder: FormBuilder,
    private router: Router) {
    this.authSub$ = sec.authStatusObservable().subscribe(auth => {
      if (auth.authenticated) {
        this.router.navigate(['']);
      }

      if (auth.invalidCredentials && auth.error) {
        this.loginError = auth.errorMessage;
      }
    });

  }
  ngOnInit(): void {

    this.sec.checkAuthStatus();


    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });
  }

  ngOnDestroy(): void {
    if (this.authSub$ != null) {
      this.authSub$.unsubscribe();
    }
  }

  doLogin() {

    this.loginError = null;

    this.sec.login(this.loginForm.value.username, this.loginForm.value.password)
      .pipe(take(1))
      .subscribe(resp => {

        if (!resp.authenticated) {
          if (resp.communicationError || resp.error) {
            this.loginError = resp.errorMessage;
          }
        } else {
          this.router.navigate(['']);
        }
      }, error => {
        console.warn(error);
        this.loginError = error;
      });
  }
}
