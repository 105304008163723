<div *ngIf="!readOnly" class="flexColumn" [formGroup]="formGroup" style="width: 100%; max-height: 26px;">
  <nb-select *ngIf="!grouped" [multiple]="multiple" size="small" [id]="t2IdCmp" [hidden]="!visible" [ngStyle]="ngStyle"
    [placeholder]="placeHolder" [formControlName]="t2IdCmp" fullWidth>
    <nb-option *ngIf="!required" [value]="null"> Limpar</nb-option>
    <nb-option *ngFor="let item of list" [value]="item.id">
      {{ item.description }}
    </nb-option>
  </nb-select>

  <nb-select *ngIf="grouped" [multiple]="multiple" size="small" [id]="t2IdCmp" [hidden]="!visible" [ngStyle]="ngStyle"
    [placeholder]="placeHolder" [formControlName]="t2IdCmp" fullWidth>
    <nb-option-group *ngFor="let group of list" [title]="group.description">
      <nb-option *ngFor="let item of group.items" [value]="item.id">
        {{ item.description }}
      </nb-option>
    </nb-option-group>
  </nb-select>

  <span *ngIf="additionalInfo && visible" class="defaultSmallText">{{additionalInfo}}</span>
</div>

<div *ngIf="readOnly && visible" class="T2CmpReadOnly">
  {{ getDescription() }}
</div>