import { Component} from '@angular/core';
import { ICellRendererAngularComp } from "ag-grid-angular";
import { ICellRendererParams } from "ag-grid-community";

@Component({
  selector: 'app-conjugated-renderer',
  templateUrl: './conjugated-renderer.component.html',
  styleUrls: ['./conjugated-renderer.component.scss']
})
export class ConjugatedRendererComponent implements ICellRendererAngularComp {
 conjugationStatus: string;

  agInit(params: ICellRendererParams<any, any>): void {
    if (params?.data) {
      this.conjugationStatus = params.data.conjugationId ? "Conjugado" : "Não conjugado";
    }
  }
  refresh(params: ICellRendererParams): boolean {
    return true;
  }
}
