<div [nbSpinner]="saving" nbSpinnerStatus="primary" nbSpinnerSize="giant">
  <app-dialog-template #dialog title="Duplicar Nota Fiscal">
    <content class="flexColumn" style="width: 50vw;">
      <app-view-template [layout]="layout" [FormGroup]="formGroup" [data]="data" [flows]="flows" [actions]="actions">
      </app-view-template>
    </content>
    <footer>
      <div style="display: flex; gap: 5px; justify-content: flex-end; align-items: center;">
        <button nbButton status="danger" size="tiny" (click)="closeDialog('Cancela')">Cancela</button>
        <button nbButton status="primary" size="tiny" (click)="closeDialog('Confirma')"
          [disabled]="formGroup.invalid || saving">Confirma</button>
      </div>
    </footer>
  </app-dialog-template>
</div>
