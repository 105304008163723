
export enum T2AccessItemType {
  ACTION_CUSTOMCALL,
  ACTION_REPORT,
  ACTION_AUTOFORM,
  ACTION_ANALYSIS,
  ACTION_SEARCH,
  ACTION_VIEW
}

export enum T2AccessInformationType {
  REGISTER,
  OPERATIONAL,
  UTILITY,
  CONFIGURATION,
  REPORT,
  DASHBOARD
}

export enum T2AccessItemDatasetActionType {
  DSACTION_EDIT,
  DSACTION_INSERT,
  DSACTION_DELETE,
  DSACTION_SEARCH,
  DSACTION_COPY,
  DSACTION_OTHER,
  DSACTION_OTHERCHANGE,
  DSACTION_OTHERCLOSE,
  DSACTION_VIEW
}

export class T2AccessItem {
  public id: string;
  public name: string;
  public description: string;
  public module: string;
  public actionCommand: string;
  public datasetActionType: T2AccessItemDatasetActionType;
  public datasetActionDescription: string;
  public id_dataset: string;
  public id_dataset_parent?: string;
  public datasetName: string;
  public datasetActionQuestion: string;
  public id_dataset_group: string;
  public datasetGroup: string;
  public datasetGroupDatasetName?: string;
  public type: T2AccessItemType;
  public informationType: T2AccessInformationType;
  public mainDataset: boolean;
  public id_report: string;
  public reportFilterProps: number;
  public informationGroupName: string;
  public informationGroupSubName: string;
  public simpleDatasetEntity?: boolean;
  public eppCarac?: boolean;
  public directive?: boolean;
  public groupIndex: number;
  public subGroupIndex: number;
  datasetDescription: string;

  public favItem = false;
}
