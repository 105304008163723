import { Component, Input } from '@angular/core';
import { isArray } from "util";

@Component({
  selector: 'app-t2-evolution-index',
  templateUrl: './t2-evolution-index.component.html',
  styleUrls: ['./t2-evolution-index.component.scss']
})
export class T2EvolutionIndexComponent {

  private _evolution: { prev: { run: number, setup: number }, real, sumStop: number, sumSetup: number, sumRun: number };
  @Input()
  set evolution(value) {
    if (value) {
      this._evolution = value;

      if (this._evolution.real && !Array.isArray(this._evolution.real)) {
        this._evolution.real = [this._evolution.real];
      }
    }
  }
  get evolution() {
    return this._evolution;
  }
}
