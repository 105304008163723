<!-- <div style="display: flex; margin-top: 10px;">
  <input type="date" class="espacamento" #inputStartDate nbInput fieldSize="small" id="inputStartDate" (change)="onChange($event)" />
  <input type="date" class="espacamento" #inputEndDate nbInput fieldSize="small" id="inputEndDate" (change)="onChange($event)" />
  <button class="espacamento" nbButton size="tiny" status="primary" [disabled]="!startDate || !endDate" (click)="loadData()">
    Pesquisar
  </button>
</div>
<div class="content">
  <app-t2grid class="gridArea" #gridHist id=" gridHist" sortable="true"> </app-t2grid>
</div> -->

<div style="display:flex;flex-direction:column;height: 100%;">
  <div>
    <!-- <input type="date" class="espacamento" #inputStartDate nbInput fieldSize="small" id="inputStartDate"
      (change)="onChange($event)" />
    <input type="date" class="espacamento" #inputEndDate nbInput fieldSize="small" id="inputEndDate"
      (change)="onChange($event)" />
    <button class="espacamento" nbButton size="tiny" status="primary" [disabled]="!startDate || !endDate"
      (click)="loadData()">
      Pesquisar
    </button> -->

    <div style="height: 40px;">
      <input nbInput placeholder="Dt inicial" [nbDatepicker]="formpicker1" [formControl]="dateStartControl"
        fieldSize="tiny" style="max-width: 150px;height: 40px;">
      <nb-datepicker #formpicker1 [max]="dateEndControl.value" [format]="format"></nb-datepicker>
      <input nbInput placeholder="Dt final" [nbDatepicker]="formpicker2" [formControl]="dateEndControl" fieldSize="tiny"
        style="max-width: 150px; margin-left: 5px;height: 40px;">
      <nb-datepicker #formpicker2 [min]="dateStartControl.value" [format]="format"></nb-datepicker>

      <button style="margin-left: 5px; height: 40px;" nbButton size="tiny" status="primary"
        [disabled]="!dateStartControl.value || !dateEndControl.value" nbSpinnerStatus="primary" nbSpinnerSize="tiny"
        [nbSpinner]="loading" (click)="loadData()">
        <nb-icon icon="refresh-outline"></nb-icon>
      </button>
    </div>
  </div>
  <div style="height: 100%;" class="espacamento">
    <app-t2grid class="gridArea" #gridHist id=" gridHist" [sortable]="true"> </app-t2grid>
  </div>
</div>
