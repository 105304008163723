import { Component, Input, OnInit, Optional, ViewChild } from '@angular/core';
import { DialogTemplateComponent } from "../dialog-template/dialog-template.component";

@Component({
  selector: 'app-dialog-log-data',
  templateUrl: './dialog-log-data.component.html',
  styleUrls: ['./dialog-log-data.component.scss']
})
export class DialogLogDataComponent implements OnInit {

  @Input() id_information: string;
  @Input() datasetName: string;

  @ViewChild('dialog', { static: true }) dialog: DialogTemplateComponent;

  constructor() { }

  ngOnInit(): void {
  }

  closeDialog() {
    this.dialog.close(null);

  }

}
