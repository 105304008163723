import { Component } from '@angular/core';
import { ICellRendererAngularComp } from "ag-grid-angular";
import { ICellRendererParams } from "ag-grid-community";

@Component({
  selector: 'app-status-renderer',
  templateUrl: './status-renderer.component.html',
  styleUrls: ['./status-renderer.component.scss']
})
export class StatusRendererComponent implements ICellRendererAngularComp {

  public params: ICellRendererParams;
  public statusFlow: Array<{ name: string, action: string, tooltip?: string, colorStyle: string }>;
  public statusDescription: string;
  public statusColorStyle: string;

  constructor() { }

  agInit(params: ICellRendererParams): void {
    this.params = params;

    this.statusFlow = typeof this.params.colDef["statusFlow"] === "function"
      ? this.params.colDef["statusFlow"]() || []
      : this.params.colDef["statusFlow"] || [];

    this.statusColorStyle = this.statusFlow?.find(status => status.name?.toLowerCase() == params.value?.toLowerCase())?.colorStyle;
    this.statusDescription = params.value;
  }

  refresh(params: ICellRendererParams): boolean {
    return true;
  }
}
