import { Component, OnInit, Input, ElementRef, ViewChild, ViewChildren, QueryList } from '@angular/core';
import { WidgetService } from "./widget.service";
import { T2Widget } from './model/t2widget';
import { T2WidgetOutput } from './model/t2widgetOutput';
import { T2WidgetDependency } from './model/t2widgetDependency';
import { T2WidgetDependencyParam } from './model/t2widgetDependencyParam';
import { WidgetChartComponent } from './widget-chart/widget-chart.component';
import { WidgetDatatableComponent } from './widget-datatable/widget-datatable.component';
import { WidgetDateRangeComponent } from './widget-date-range/widget-date-range.component';
import { WidgetSelectionComponent } from './widget-selection/widget-selection.component';
import { WidgetTextComponent } from './widget-text/widget-text.component';

@Component({
  selector: 'app-widget-panel',
  templateUrl: './widget-panel.component.html',
  styleUrls: ['./widget-panel.component.scss']
})
export class WidgetPanelComponent implements OnInit {

  @ViewChildren(WidgetChartComponent) chartWidgets: QueryList<WidgetChartComponent>;
  @ViewChildren(WidgetDatatableComponent) dataTableWidgets: QueryList<WidgetDatatableComponent>;
  @ViewChildren(WidgetDateRangeComponent) dateRangeWidgets: QueryList<WidgetDateRangeComponent>;
  @ViewChildren(WidgetChartComponent) selectionWidgets: QueryList<WidgetSelectionComponent>;
  @ViewChildren(WidgetChartComponent) textWidgets: QueryList<WidgetTextComponent>;

  // tslint:disable-next-line: variable-name
  private _panelName: string = null;
  @Input()
  get panelName() { return this._panelName; }
  set panelName(str: string) { this._panelName = str; this.getPanelWidgets(); }

  private _params = {};
  @Input()
  get params() { return this._params; }
  set params(value: any) { this._params = value; this.panelParamsChange(); }

  private widgets = new Array<T2Widget>();
  widgetLines = [];

  constructor(private widgetService: WidgetService) { }

  ngOnInit(): void { }

  private getPanelWidgets() {
    this.widgetService.getPanelWidgets(this._panelName)
      .subscribe((widgets: Array<T2Widget>) => {

        this.widgets = [];

        const widgetLines = [];

        widgets.forEach((widget: T2Widget) => {
          let line = widgetLines.filter((item: any) => item.positionY === widget.positionY).shift();
          if (!line) {
            line = { positionY: widget.positionY, widgets: [] };
            widgetLines.push(line);
          }
          if (widget.widgetDependency && (typeof widget.widgetDependency === "string")) {
            widget.widgetDependency = JSON.parse(widget.widgetDependency as unknown as string);
          }
          if (widget.inputOutputParams && (typeof widget.inputOutputParams === "string")) {
            widget.inputOutputParams = JSON.parse(widget.inputOutputParams as unknown as string);
          }

          this.widgets.push(widget);
          line.widgets.push(widget);
        });

        this.widgetLines = widgetLines;
        // this.widgetLines.forEach((line: any) => {
        //   console.log(this._panelName, line.positionY);
        //   console.table(line.widgets);
        // });
      });
  }

  private panelParamsChange() {

    this.chartWidgets?.forEach((widget: WidgetChartComponent) => widget.params = this._params);
    this.dataTableWidgets?.forEach((widget: WidgetDatatableComponent) => widget.params = this._params);
    this.dateRangeWidgets?.forEach((widget: WidgetDateRangeComponent) => widget.params = this._params);
    this.selectionWidgets?.forEach((widget: WidgetSelectionComponent) => widget.params = this._params);
    this.textWidgets?.forEach((widget: WidgetTextComponent) => widget.params = this._params);
  }

  widgetChange(output: T2WidgetOutput) {

    this.widgets.forEach((widget: T2Widget) => {
      if (widget.widgetDependency) {
        const widDep = widget.widgetDependency.find((dep: T2WidgetDependency) => dep.id_taskprodWidget === output.widget.id_techprodWidget);

        if (widDep) {
          this.updateWidget(output, widget, widDep);
        }
      }
    });
  }

  private updateWidget(output: T2WidgetOutput, widget: T2Widget, dep: T2WidgetDependency) {

    let params = {};
    dep.params?.filter((param: T2WidgetDependencyParam) => param.name)
      .forEach((param: T2WidgetDependencyParam) => {
        let data = output.data;
        const outputParts = param.outputName.split(".");
        outputParts.forEach((part: string) => {
          if (data instanceof Object && data != null && data !== undefined) {
            data = data[part];
          }
        });

        params[param.name] = data;
      });

    this.updateWidgetParams<WidgetChartComponent>(widget.id_techprodWidget_panel, params, this.chartWidgets.toArray());
    this.updateWidgetParams<WidgetDatatableComponent>(widget.id_techprodWidget_panel, params, this.dataTableWidgets.toArray());
    this.updateWidgetParams<WidgetDateRangeComponent>(widget.id_techprodWidget_panel, params, this.dateRangeWidgets.toArray());
    this.updateWidgetParams<WidgetSelectionComponent>(widget.id_techprodWidget_panel, params, this.selectionWidgets.toArray());
    this.updateWidgetParams<WidgetTextComponent>(widget.id_techprodWidget_panel, params, this.textWidgets.toArray());
  }

  // tslint:disable-next-line: variable-name
  private updateWidgetParams<T>(id_techprodWidget_panel: string, params: any, widgets: T[]): void {

    const widget = widgets?.filter((w: any) => {
      return w.widget.id_techprodWidget_panel === id_techprodWidget_panel;
    }).shift();
    if (widget) {
      (widget as any).params = params;
    }
  }

  dataTableAutoSizeColumns() {
    this.dataTableWidgets.forEach((widget: WidgetDatatableComponent) => widget.tableAutoSizeColumns());
  }
}
