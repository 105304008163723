import { Component, HostListener, Input } from '@angular/core';
import { EChartsOption, EChartsType } from "echarts";

@Component({
  selector: 'app-t2-pizza-index',
  templateUrl: './t2-pizza-index.component.html',
  styleUrls: ['./t2-pizza-index.component.scss']
})
export class T2PizzaIndexComponent {

  total: number = 0.0;
  private _values: Array<IndexValue> = [];

  @Input() title: string;
  @Input()
  get values(): Array<IndexValue> { return this._values; };
  set values(valueList: Array<IndexValue>) {
    this._values = valueList?.filter(item => item.value) || [];

    if (!this._values.length)
      this._values.push({ name: '', value: 0, color: 'yellow' });

    this.total = 0.0;
    for (const vi of valueList) {
      this.total += (vi.value || 0.0);
    }
  };

  smallWindow: boolean;
  bgColor = {
    red: 'rgba(255, 125, 125, 1)',
    yellow: '#FFC700',
    green: 'rgb(74, 224, 89)',
    blue: '#0789c2'
  };

  themeName = "light";
  echartsIntance: EChartsType;
  co: EChartsOption = {
    //title: {text: "75% Consumo", show: true, bottom: -5, textStyle: {fontSize: 16}},
    legend: {
      show: false,
    },
    series: [
      {
        top: -20,
        left: -20,
        bottom: -20,
        right: -20,
        type: 'pie',
        radius: ['30%', '57%'],
        avoidLabelOverlap: false,
        label: {
          show: true, formatter: '{c}m', position: 'inside', fontWeight: 'bold', fontSize: 10, color: 'rgba(255, 255, 255, 1)',
          backgroundColor: "#1f2a3c8f",
          borderRadius: 10,
          padding: [2, 3]
        },
        labelLine: { show: false },
        data: []
      }
    ]
  };

  onChartInit(ec: EChartsType) {
    const colors = {
      red: 'rgba(255, 0, 0, 1)',
      yellow: '#FFC700',
      green: '#24C634',
      blue: '#0789c2',
    };

    this.echartsIntance = ec;
    if (this.echartsIntance) {

      this.co.series[0].data = this.values
        .filter(v => v.value)
        .map(v => {
          return { name: v.name, value: v.value.toFixed(2), itemStyle: { color: colors[v.color] } };
        });

      this.echartsIntance.setOption(this.co);
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event): void {
    if (window.innerWidth <= 450) this.smallWindow = true;
    if (window.innerWidth > 450) this.smallWindow = false;
  }

  ngAfterContentInit(): void {
    if (window.innerWidth <= 450) this.smallWindow = true;
    if (window.innerWidth > 450) this.smallWindow = false;
  }

}

export class IndexValue {
  name: string;
  value: number;
  color: 'red' | 'yellow' | 'green' | 'blue';
}
