import { Component, EventEmitter, Inject, Input, LOCALE_ID, Output } from '@angular/core';
import { DeviceTaskPlan, ShopFloorService } from "../../shop-floor.service";
import { TaskRequisition } from "../../../separation/t2separation.service";
import { take } from "rxjs/Operators";
import { T2UserDeviceService } from "src/app/t2-user-device.service";

@Component({
  selector: 'app-appointment-register-raw-material-request',
  templateUrl: './appointment-register-raw-material-request.component.html',
  styleUrls: ['./appointment-register-raw-material-request.component.scss']
})
export class AppointmentRegisterRawMaterialRequestComponent {
  @Input()
  get taskPlan(): DeviceTaskPlan {
    return this._taskPlan
  }
  set taskPlan(value: DeviceTaskPlan) {
    this._taskPlan = value;
    this.loadInfo();
  }

  @Output() changeBtnText = new EventEmitter<string>();
  @Output() hasLoaded = new EventEmitter<void>();

  private _taskPlan: DeviceTaskPlan;
  public requestList: Array<TaskRequisition>;
  public loaded: boolean = false;

  constructor(private sfService: ShopFloorService, public userDevService: T2UserDeviceService,
    @Inject(LOCALE_ID) public locale: string) {}

  private loadInfo() {
    if (this.taskPlan) {
      this.sfService.getTaskRequests(this.taskPlan.taskId).pipe(take(1)).subscribe(reqList => {
        this.requestList = reqList;

        if (this.requestList?.length) {
          this.changeBtnText.emit(this.requestList[0].rawMaterialRequested ? "Cancelar Solicitação" : "Confirmar Solicitação");
        }
        
        this.loaded = true;
        this.hasLoaded.emit();
      })
    }
  }
}
