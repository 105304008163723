<div [ngStyle.xs]="{'width':'300px'}">
<app-dialog-template #dialog [title]="title">
  <content>
    <textarea [formControl]="reproveReasonField" nbInput fullWidth placeholder="Escreva aqui"></textarea>
  </content>
  <footer>
    <div class="flexAlignRowCenter flexGap5">
      <button (click)="confirm()" nbButton size="tiny" status="primary" [disabled]="!reproveReasonField.valid">
        Confirmar
      </button>
      <button (click)="cancel()" nbButton size="tiny" status="primary">
        Cancelar
      </button>
    </div>
  </footer>
</app-dialog-template>
</div>
