import { ItemSelection } from './../../../../core/cmp/dialog-item-selection/dialog-item-selection.component';
import { Component, OnInit, ViewChild, OnDestroy, Inject, LOCALE_ID } from "@angular/core";
import { ContextMenuComponent, } from "src/app/core/cmp/context-menu/context-menu.component";
import { T2HttpClientService } from "src/app/core/http/t2httpClient.service";
import {
  take,
  map,
  filter,
  distinctUntilChanged,
  debounceTime,
  takeUntil,
} from "rxjs/Operators";
import { FormBuilder, FormControl } from "@angular/forms";
import { Observable, Subject, Subscription, timer } from "rxjs";
import { FormTemplateComponent } from "src/app/core/cmp/form-template/form-template.component";
import { T2SecurityService } from "src/app/core/security/t2security.service";
import { ActivatedRoute, Router } from "@angular/router";
import { ActionService } from "src/app/core/action/action.service";
import { T2AccessItem, T2AccessItemDatasetActionType } from "src/app/core/security/model/t2accessItem";
import { ModalDashboardComponent } from "src/app/bsn/prd/analysis/sequence-dashboard/modal-dashboard/modal-dashboard.component";
import { NbDialogService } from "@nebular/theme";
import { DialogItemSelectionComponent, GroupSelection } from "src/app/core/cmp/dialog-item-selection/dialog-item-selection.component";
import { FormatWidth, getLocaleDateFormat } from "@angular/common";

@Component({
  selector: "app-sequence-dashboard",
  templateUrl: "./sequence-dashboard.component.html",
  styleUrls: ["./sequence-dashboard.component.scss"],
})
export class SequenceDashboardComponent implements OnInit, OnDestroy {
  @ViewChild("treePopup", { static: true }) treePopup: ContextMenuComponent;
  @ViewChild("formTemplate", { static: true }) formTemplate: FormTemplateComponent;

  public loaded = false;

  format: string;
  dateStartControl = new FormControl();
  dateEndControl = new FormControl();
  showXs = true;
  loading = false;
  formTitle = "Acompanhamento da programação";
  mainbarFormGroup = this.formBuilder.group({});
  searchField = new FormControl();
  devSelectedField = new FormControl();
  panelField = new FormControl();
  dateFilterField = new FormControl();
  private subSearch$: Subscription;
  private subDevSelected$: Subscription;
  private subPanel$: Subscription;
  private subsQueryParams$: Subscription = null;
  private modalService: ModalDashboardComponent;
  private unsubscribe = new Subject<void>();
  private lastPanelID = null;
  private devGroup = [];
  panelList = [];
  deviceList = [];
  check: boolean = true;
  allDeviceList = [];
  dateOptionList = [
    {
      field: "salesOrderDate",
      label: "Data Ped Venda",
    },
    {
      field: "purchaseOrder",
      label: "Data Ped Compra",
    },
    {
      field: "minDate",
      label: "Dt Mínima Tarefa",
    },
    {
      field: "maxDate",
      label: "Dt Máxima Tarefa",
    },
    {
      field: "planStartDate",
      label: "Dt Início Previsto Planejamento",
    },
    {
      field: "planEndDate",
      label: "Dt Fim Previsto Planejamento",
    },
  ];

  constructor(
    public router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private sec: T2SecurityService,
    private actionService: ActionService,
    private httpClient: T2HttpClientService,
    private dialogService: NbDialogService,
    @Inject(LOCALE_ID) private locale
  ) {
    let dtFormat = getLocaleDateFormat(locale, FormatWidth.Short);

    if (dtFormat.startsWith("M")) {
      this.format = "MM/dd/yyyy";
    } else {
      this.format = "dd/MM/yyyy";
    }
  }

  openModal() {
    const dlx = this.dialogService.open(ModalDashboardComponent);
  }

  getDevGroup(): Array<any> {
    if (this.devGroup.length === 0) {
      const devSel = [];

      this.deviceList.forEach((dev) => {
        let group = null;
        const groups = this.devGroup.filter((g) => g.type === dev.type);
        if (groups.length > 0) {
          group = groups[0];
        } else {
          group = { type: dev.type, devs: [] };
          this.devGroup.push(group);
        }

        devSel.push(dev.name);

        group.devs.push({ name: dev.name });
      });
    }

    return this.devGroup;
  }

  ngOnInit(): void {
    this.sec.accessLoaded()
      .pipe(take(1))
      .subscribe(() => {
        if (this.formTemplate.validateAccess(["zD20200611H133825437R000000005"], 'dashboard')) {
          this.loadPanelData();
        }
      });

    this.subsQueryParams$ = this.route.queryParams.subscribe((params) => {
      if (this.lastPanelID != (params['panel'] || "")) {
        this.lastPanelID = params['panel'] || this.lastPanelID || "";
      }
    });

    this.sec.companySiteObservable().pipe(takeUntil(this.unsubscribe)).subscribe(() => {
      if (this.panelField.value)
        this.loadDeviceList(this.panelField.value)
    });
  }

  ngOnDestroy(): void {
    if (this.subSearch$) {
      this.subSearch$.unsubscribe();
    }
    if (this.subDevSelected$) {
      this.subDevSelected$.unsubscribe();
    }
    if (this.subPanel$) {
      this.subPanel$.unsubscribe();
    }
    if (this.subsQueryParams$) {
      this.subsQueryParams$.unsubscribe();
    }

    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  cssPV(task: any, origem: string) {
    return { showPV: origem == "PV" && task.id_pedidovenda };
  }


  showTreePopUp(task, origem) {


    let actions: T2AccessItem[] = [];

    if (origem == "OP" && task.id_ordemprod) {
      this.sec.getDatasetActions("ord_ordemprod").subscribe((actionList) => {
        actionList.forEach((action) => {
          if ([T2AccessItemDatasetActionType.DSACTION_OTHER, T2AccessItemDatasetActionType.DSACTION_OTHERCHANGE, T2AccessItemDatasetActionType.DSACTION_EDIT].includes(action.datasetActionType)) {
            actions.push(action);
          }
        });
      });
    }

    if (origem == "PV" && task.id_pedidovenda) {
      this.sec.getDatasetActions("pvd_pedidovenda").subscribe((actionList) => {
        actionList.forEach((action) => {
          if ([T2AccessItemDatasetActionType.DSACTION_OTHER, T2AccessItemDatasetActionType.DSACTION_OTHERCHANGE, T2AccessItemDatasetActionType.DSACTION_EDIT
          ].includes(action.datasetActionType)) {
            actions.push(action);
          }

        });
      });
    }

    if (origem == "PC" && task.id_pedidoCompra) {
      this.sec.getDatasetActions("cpr_pedidoCompra").subscribe((actionList) => {

        actions = actionList.filter(a => {
          return [
            T2AccessItemDatasetActionType.DSACTION_OTHER,
            T2AccessItemDatasetActionType.DSACTION_OTHERCHANGE,
            T2AccessItemDatasetActionType.DSACTION_EDIT
          ].includes(a.datasetActionType);
        });


        // actionList.forEach((action) =>{
        //   if([T2AccessItemDatasetActionType.DSACTION_OTHER].includes(action.datasetActionType)){
        //     actions.push(action);
        //   }
        // });
      });

    }

    let groups: Array<GroupSelection> = [];
    let items: Array<ItemSelection> = [];
    let groupName = null;
    if (actions.length > 0) {
      actions.forEach(action => {
        groupName = action.datasetGroup || groupName;
        items.push(
          {
            id_item: action.id,
            itemName: action.datasetActionDescription,
            selected: false
          });

        if (groups.find(g => g.id_group == action.id_dataset_group)) {
          return;
        }

        groups.push(
          {
            id_group: action.id_dataset_group,
            groupName: null,
            selected: false,
            items: items
          });
      });

      const dialogRef = this.dialogService.open(
        DialogItemSelectionComponent,
        {
          context: {
            title: groupName,
            groupSelection: 'none',
            itemSelection: 'single',
            itemFromDifGroups: false,
            groups: groups,
            useConfirmButton: false,
            buttonCase: 'normal',
            buttonSize: 'large'
          },
        }
      );

      dialogRef.onClose.subscribe((resp) => {
        if (resp) {
          let i = items.find(item => item.selected);

          let id_action = null;
          if (i) {
            id_action = i.id_item;
          }

          const action: T2AccessItem = actions.find(a => a.id == id_action);

          const params = new Map<string, string>();
          params.set("datasetName", action.datasetName);

          if (action.datasetName.toLowerCase() == "pvd_pedidovenda") {
            params.set("id", task.id_pedidovenda);
            this.executeAction(action, params);
          } else if (action.datasetName.toLowerCase() == "ord_ordemprod") {
            params.set("id", task.id_ordemprod);
            this.executeAction(action, params);
          } else if (action.datasetName.toLowerCase() == "cpr_pedidocompra") {
            params.set("id", task.id_pedidoCompra);
            this.executeAction(action, params);
          }
        }
      });
    }
  }

  private executeAction(action: T2AccessItem, params: Map<string, string>): void {
    const resp = this.actionService.executeAction(action, params, "newWindow");
    if (resp instanceof Observable) {
      resp.subscribe(resp => {
        //What should I do??
      });
    }
  }

  private loadPanelData() {
    this.httpClient
      .get(`production.taskMonitoring/panels`, null)
      .pipe(take(1))
      .subscribe((resp) => {
        if (!Array.isArray(resp.panel)) {
          resp.panel = [resp.panel];
        }

        if (resp.panel.length === 1) {
          this.loadDeviceList(resp.panel[0].id);
        } else {
          this.panelList = resp.panel;
          if (
            this.lastPanelID &&
            this.panelList.find((panel) => panel.id === this.lastPanelID)
          ) {
            this.panelField.setValue(this.lastPanelID);
          }
        }
      });

    this.subSearch$ = this.searchField.valueChanges
      .pipe(
        map((value) => value.trim()),
        filter((value) => value.length > 2 || value.length === 0),
        debounceTime(750),
        distinctUntilChanged()
      )
      .subscribe((value) => this.filterTasks(value));

    this.subDevSelected$ = this.devSelectedField.valueChanges
      .pipe(debounceTime(400), distinctUntilChanged())
      .subscribe((value: Array<any>) => {
        this.filterTasks(this.searchField.value);
        // this.deviceList = this.getSelectedDevices(value);
      });

    this.subPanel$ = this.panelField.valueChanges
      .pipe(debounceTime(400), distinctUntilChanged())
      .subscribe((value: string) => {
        this.router.navigate([], {
          relativeTo: this.route,
          queryParams: { panel: value },
          queryParamsHandling: "merge",
        });
        this.loadDeviceList(value);
        this.panelChange();
      });

    this.loaded = true;
  }

  private loadDeviceList(panelID: string) {
    this.loading = true;
    this.httpClient
      .get(`production.taskMonitoring/panel/${panelID}`, null)
      .pipe(take(1))
      .subscribe(
        (resp) => {
          if (this.lastPanelID !== panelID) {
            this.devSelectedField.setValue([]);
          }

          const respData = JSON.parse(resp.message);
          let tasks: Array<any> = null;
          let devs: Array<any> = null;
          if (Array.isArray(respData)) {
            tasks = [];
            devs = respData;
          } else {
            tasks = respData.allTasks;
            devs = respData.allDevices;
          }

          devs.forEach((dev) => {
            dev.taskCount = dev.tasks.length;
            dev.allTasks = dev.tasks.map((task: any, index: number) => {
              task.index = index + 1;
              if (task.taskIndex >= 0) {
                task.parent = tasks.find(
                  (item: any) => item.taskIndex === task.taskIndex
                );
              }
              return task;
            });

            dev.tasks = dev.tasks.slice(0, 50);
          });
          this.allDeviceList = devs;
          this.devGroup = [];
          this.lastPanelID = panelID;
          this.filterTasks(this.searchField.value);

          if (
            this.dateStartControl.value &&
            this.dateEndControl.value &&
            this.dateFilterField.value
          ) {
            this.filterTasksDate();
          }

          // Recarrega painel a cada 2 min (1000ms * 120)
          timer(1000 * 120).subscribe((val) => {
            if (this.lastPanelID === panelID) {
              this.loadDeviceList(panelID);
            }
          });

          this.loading = false;
        },
        (error) => {
          this.loading = false;
        }
      );
  }

  private getSelectedDevices(devSelectedValue: Array<string>) {
    if (!devSelectedValue || !devSelectedValue.length) {
      return this.allDeviceList;
    } else {
      return this.allDeviceList.filter(
        (dev) => devSelectedValue.indexOf(dev.name) > -1
      );
    }
  }

  private filterTasks(str: string) {
    try {
      const devices = this.getSelectedDevices(this.devSelectedField.value);

      if (!str?.trim()) {
        devices.forEach((dev) => (dev.tasks = dev.allTasks));
        this.deviceList = devices;

        return;
      }

      str = str.toLowerCase();
      const devs = devices.concat([]);

      devs.forEach((dev) => {
        if (str) {
          dev.tasks = dev.allTasks.filter((task: any) => {
            const customer: string = task.customer || task.parent?.customer;
            if (customer?.toLowerCase().includes(str)) {
              return true;
            }

            const salesOrder: string = task.salesOrder || task.parent?.salesOrder;
            if ((salesOrder + "").toLowerCase().includes(str)) {
              return true;
            }

            const prodOrder: string = task.prodOrder || task.parent?.prodOrder;
            if (prodOrder?.toLowerCase().includes(str)) {
              return true;
            }

            const title: string = task.title || task.parent?.title;
            if (title?.toLowerCase().includes(str)) {
              return true;
            }

            const detail: string = task.detail || task.parent?.detail;
            if (detail?.toLowerCase().includes(str)) {
              return true;
            }

            if (task.details) {
              for (let td of task.details) {
                if (td.text?.toLowerCase().includes(str)) {
                  return true;
                }
              }
            }

            const itens = task.items?.filter(item =>
              item.title.toLocaleLowerCase().includes(str))
              || task.parent?.items?.filter(item =>
                item.title.toLocaleLowerCase().includes(str));
            if (itens?.length) return true;

            return false;
          });
        } else {
          dev.tasks = dev.allTasks;
        }
        dev.tasks = dev.tasks.slice(0, 50);
      });

      this.deviceList = devs.filter((dev) => dev.tasks.length > 0);
    } finally {
      // TODO document why this block is empty

    }
  }

  panelChange() {
    this.formTitle = this.panelList.filter((p) => p.id == this.panelField.value)[0].description;
  }

  filterTasksDate() {
    const devices = this.getSelectedDevices(this.devSelectedField.value);
    const devs = devices.concat([]);

    devs.forEach((dev) => {
      dev.tasks = dev.allTasks.filter((task: any) => {
        let fieldDtStr =
          task[this.dateFilterField.value] ||
          task.parent?.[this.dateFilterField.value];
        let fieldDt: Date = new Date(fieldDtStr);

        if (
          fieldDt.getTime() >=
          new Date(this.dateStartControl.value).getTime() &&
          fieldDt.getTime() <= new Date(this.dateEndControl.value).getTime()
        ) {
          return true;
        } else {
          return false;
        }
      });
    });

    this.deviceList = devs.filter((dev) => dev.tasks.length > 0);
  }
}
