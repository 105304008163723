<app-dialog-template #dialog title="Inclusão de Modelo de EP" [nbSpinner]="loading" nbSpinnerSize="giant"
  nbSpinnerStatus="primary">
  <content class="flexColumn" style="max-height: 70vh; width: 450px; max-width: 90vw;">
    <app-view-template #viewTemplate [FormGroup]="formGroup" [layout]="layout"></app-view-template>
  </content>
  <footer>
    <div class="flexAlignColumnStartEnd">
      <button nbButton status="basic" size="tiny" (click)="closeDialog()">Cancelar</button>
    </div>
  </footer>
</app-dialog-template>
