import { AfterViewInit, Component, ComponentRef, ElementRef, EventEmitter, Inject, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { T2AutoFormComponent } from "src/app/core/form/t2-auto-form/t2-auto-form.component";
import { CharacteristicInfo } from "../model/characteristic-info";
import { CharacteristicComponent, ICharacteristicComponentInjection } from "../characteristic-component";
import { T2MessageService } from "src/app/core/t2-message.service";
import { take, takeUntil } from "rxjs/Operators";
import { BehaviorSubject, Subject } from "rxjs";
import { EPService } from "../ep.service";
import { FlxProductSpecificationService } from "src/app/bsn/flx/epp/flx-product-specification.service";

@Component({
  selector: 'app-dimension',
  templateUrl: './dimension.component.html',
  styleUrls: ['./dimension.component.scss']
})
export class DimensionComponent extends CharacteristicComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('formDimensao') formDimensao: T2AutoFormComponent;
  @ViewChild('formPasso') formPasso: T2AutoFormComponent;
  @ViewChild('formEmbobinament') formEmbobinament: T2AutoFormComponent;
  @ViewChild('formMontagem') formMontagem: T2AutoFormComponent;

  @Input() id_specif_component: string
  @Input() blockChange: boolean;
  @Input() id_specif: string;
  @Input() id_process: string;
  @Input() characInfoList: Array<CharacteristicInfo>;

  private characList = ["epp_especif_carac_dimensao", "flx_especif_carac_prodPasso", "flx_especif_carac_embobinament", "flx_especif_carac_mont_prd", "flx_especif_carac_mont_cj"];
  private autoformList = new Array<T2AutoFormComponent>();
  private mapForm = new Map<string, string>();
  private unsubscribe = new Subject<void>();

  public lockScreen: boolean = false;
  public loaded: boolean = false;
  public dimInfoId: string;
  public passoInfoId: string;
  public embobInfoId: string;
  public montInfoId: string;
  public montDatasetname: string;

  constructor(@Inject("characCmpInjector") private characCmpInjector: BehaviorSubject<ICharacteristicComponentInjection>,
    private messageService: T2MessageService, private epService: EPService, private flxService: FlxProductSpecificationService,
    private elementRef: ElementRef) {
    super();
    this.mapForm.set("epp_especif_carac_dimensao", "formDimensao");
    this.mapForm.set("flx_especif_carac_prodPasso", "formPasso");
    this.mapForm.set("flx_especif_carac_embobinament", "formEmbobinament");
    this.mapForm.set("flx_especif_carac_mont_prd", "formMontagem");
    this.mapForm.set("flx_especif_carac_mont_cj", "formMontagem");
  }
  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  ngAfterViewInit(): void {
    this.addFormsToList();

    let promList = new Array<Promise<void>>();

    if (this.dimInfoId) {
      promList.push(new Promise<void>((resolve, reject) => {
        this.formDimensao.loadingCompleted.pipe(take(1)).subscribe(() => {
          resolve();
        })
      }));
    }

    if (this.montInfoId) {
      promList.push(new Promise<void>((resolve, reject) => {
        this.formMontagem.loadingCompleted.pipe(take(1)).subscribe(() => {
          resolve();
        })
      }));
    }

    this.formDimensao?.loadingCompleted.pipe(take(1)).subscribe(() => {

      this.formDimensao.formGroup.controls["epp_especif_carac_dimensao.dimAltura"].valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe(value => {
        this.formMontagem.viewTemplate.setCmpInputValue("EPPAssemblyComponent", "heightAssembly", value);
      });

      this.formDimensao.formGroup.controls["epp_especif_carac_dimensao.dimLargura"].valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe(value => {
        this.formMontagem.viewTemplate.setCmpInputValue("EPPAssemblyComponent", "widthAssembly", value);
      });
    })

    this.formMontagem?.loadingCompleted.pipe(take(1)).subscribe(() => {
      this.formMontagem.formGroup.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe(obj => {
        if (this.montDatasetname == "flx_especif_carac_mont_prd") {
          this.formMontagem.viewTemplate.setCmpInputValue("EPPAssemblyComponent", "rotation", obj["flx_especif_carac_mont_prd.rotacaoImagem"]);
          this.formMontagem.viewTemplate.setCmpInputValue("EPPAssemblyComponent", "trackQty", Number(obj["flx_especif_carac_mont_prd.numFaixas"]) || 0);
          this.formMontagem.viewTemplate.setCmpInputValue("EPPAssemblyComponent", "verticalQty", Number(obj["flx_especif_carac_mont_prd.qtdVertical"]) || 0);
          this.formMontagem.viewTemplate.setCmpInputValue("EPPAssemblyComponent", "bloodlettingBtwLines", Number(obj["flx_especif_carac_mont_prd.sangriaEntreLinhas"]) || 0);
          this.formMontagem.viewTemplate.setCmpInputValue("EPPAssemblyComponent", "bloodlettingBtwTracks", Number(obj["flx_especif_carac_mont_prd.sangriaEntreFaixas"]) || 0);
          this.formMontagem.viewTemplate.setCmpInputValue("EPPAssemblyComponent", "rightMargin", Number(obj["flx_especif_carac_mont_prd.margemDireita"]));
          this.formMontagem.viewTemplate.setCmpInputValue("EPPAssemblyComponent", "leftMargin", Number(obj["flx_especif_carac_mont_prd.margemEsquerda"]));
          this.formMontagem.viewTemplate.setCmpInputValue("EPPAssemblyComponent", "bottomMargin", Number(obj["flx_especif_carac_mont_prd.margemInferior"]));
          this.formMontagem.viewTemplate.setCmpInputValue("EPPAssemblyComponent", "topMargin", Number(obj["flx_especif_carac_mont_prd.margemSuperioir"]));
        } else {
          this.formMontagem.viewTemplate.setCmpInputValue("EPPConjugateAssemblyComponent", "rightMargin", Number(obj["flx_especif_carac_mont_cj.margemDireita"]));
          this.formMontagem.viewTemplate.setCmpInputValue("EPPConjugateAssemblyComponent", "leftMargin", Number(obj["flx_especif_carac_mont_cj.margemEsquerda"]));
          this.formMontagem.viewTemplate.setCmpInputValue("EPPConjugateAssemblyComponent", "bottomMargin", Number(obj["flx_especif_carac_mont_cj.margemInferior"]));
          this.formMontagem.viewTemplate.setCmpInputValue("EPPConjugateAssemblyComponent", "topMargin", Number(obj["flx_especif_carac_mont_cj.margemSuperioir"]));
        }

      })
    });

    Promise.all(promList).then(() => {
      this.updateAssemblyForm();
    });

    this.sendComponentRef();
  }

  ngOnInit(): void {
    this.characCmpInjector.pipe(takeUntil(this.unsubscribe)).subscribe(cmpInjector => {
      this.id_specif = cmpInjector.id_specif;
      this.id_process = cmpInjector.id_process;
      this.blockChange = cmpInjector.blockChange;
      this.characInfoList = cmpInjector.characInfoList;
      this.id_specif_component = cmpInjector.id_specif_component;

      this.characInfoList.filter(c => this.characList.includes(c.datasetName)).forEach(charac => {
        if (["flx_especif_carac_mont_prd", "flx_especif_carac_mont_cj"].includes(charac.datasetName) && charac.id_especif_componente_prc == this.id_process) {
          this.montDatasetname = charac.datasetName;
          this.montInfoId = charac.id_especif_carac;

          if (this.loaded) { // Não é a primeira vez que está carregando            
            this.addFormsToList();

            this.formDimensao?.setAllControlsReadOnly(this.blockChange);
            this.formEmbobinament?.setAllControlsReadOnly(this.blockChange);
            this.formPasso?.setAllControlsReadOnly(this.blockChange);
            this.formMontagem?.setAllControlsReadOnly(this.blockChange);

            this.formMontagem?.loadingCompleted.pipe(take(1)).subscribe(() => {
              this.updateAssemblyForm();

              this.formMontagem.formGroup.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe(obj => {
                if (this.montDatasetname == "flx_especif_carac_mont_prd") {
                  this.formMontagem.viewTemplate.setCmpInputValue("EPPAssemblyComponent", "rotation", obj["flx_especif_carac_mont_prd.rotacaoImagem"]);
                  this.formMontagem.viewTemplate.setCmpInputValue("EPPAssemblyComponent", "trackQty", Number(obj["flx_especif_carac_mont_prd.numFaixas"]) || 0);
                  this.formMontagem.viewTemplate.setCmpInputValue("EPPAssemblyComponent", "verticalQty", Number(obj["flx_especif_carac_mont_prd.qtdVertical"]) || 0);
                  this.formMontagem.viewTemplate.setCmpInputValue("EPPAssemblyComponent", "bloodlettingBtwLines", Number(obj["flx_especif_carac_mont_prd.sangriaEntreLinhas"]) || 0);
                  this.formMontagem.viewTemplate.setCmpInputValue("EPPAssemblyComponent", "bloodlettingBtwTracks", Number(obj["flx_especif_carac_mont_prd.sangriaEntreFaixas"]) || 0);
                  this.formMontagem.viewTemplate.setCmpInputValue("EPPAssemblyComponent", "rightMargin", Number(obj["flx_especif_carac_mont_prd.margemDireita"]));
                  this.formMontagem.viewTemplate.setCmpInputValue("EPPAssemblyComponent", "leftMargin", Number(obj["flx_especif_carac_mont_prd.margemEsquerda"]));
                  this.formMontagem.viewTemplate.setCmpInputValue("EPPAssemblyComponent", "bottomMargin", Number(obj["flx_especif_carac_mont_prd.margemInferior"]));
                  this.formMontagem.viewTemplate.setCmpInputValue("EPPAssemblyComponent", "topMargin", Number(obj["flx_especif_carac_mont_prd.margemSuperioir"]));
                } else {
                  this.formMontagem.viewTemplate.setCmpInputValue("EPPConjugateAssemblyComponent", "rightMargin", Number(obj["flx_especif_carac_mont_cj.margemDireita"]));
                  this.formMontagem.viewTemplate.setCmpInputValue("EPPConjugateAssemblyComponent", "leftMargin", Number(obj["flx_especif_carac_mont_cj.margemEsquerda"]));
                  this.formMontagem.viewTemplate.setCmpInputValue("EPPConjugateAssemblyComponent", "bottomMargin", Number(obj["flx_especif_carac_mont_cj.margemInferior"]));
                  this.formMontagem.viewTemplate.setCmpInputValue("EPPConjugateAssemblyComponent", "topMargin", Number(obj["flx_especif_carac_mont_cj.margemSuperioir"]));
                }

              })
            })

          }
        } else {
          if (charac.datasetName == "epp_especif_carac_dimensao") {
            this.dimInfoId = charac.id_especif_carac;
          } else if (charac.datasetName == "flx_especif_carac_prodPasso") {
            this.passoInfoId = charac.id_especif_carac;
          } else if (charac.datasetName == "flx_especif_carac_embobinament") {
            this.embobInfoId = charac.id_especif_carac;
          }
        }
      });
    });

    this.loaded = true;
  }

  private addFormsToList() {
    setTimeout(() => {
      if (this.autoformList.length > 0) {
        this.autoformList.splice(0, this.autoformList.length);
      }

      if (this.dimInfoId) this.autoformList.push(this.formDimensao);
      if (this.passoInfoId) this.autoformList.push(this.formPasso);
      if (this.embobInfoId) this.autoformList.push(this.formEmbobinament);
      if (this.montInfoId) this.autoformList.push(this.formMontagem);
    }, 200);    
  }

  private updateAssemblyForm() {
    if (this.montInfoId) {
      if (this.montDatasetname == "flx_especif_carac_mont_prd") {
        this.formMontagem.viewTemplate.setCmpInputValue("EPPAssemblyComponent", "itemDescription", this.epService.getPSDescription());
        this.formMontagem.viewTemplate.setCmpInputValue("EPPAssemblyComponent", "heightAssembly", Number(this.formDimensao.formGroup.controls["epp_especif_carac_dimensao.dimAltura"].value));
        this.formMontagem.viewTemplate.setCmpInputValue("EPPAssemblyComponent", "widthAssembly", Number(this.formDimensao.formGroup.controls["epp_especif_carac_dimensao.dimLargura"].value));
        this.formMontagem.viewTemplate.setCmpInputValue("EPPAssemblyComponent", "rotation", this.formMontagem.formGroup.controls["flx_especif_carac_mont_prd.rotacaoImagem"].value);
        this.formMontagem.viewTemplate.setCmpInputValue("EPPAssemblyComponent", "trackQty", Number(this.formMontagem.formGroup.controls["flx_especif_carac_mont_prd.numFaixas"].value) || 0);
        this.formMontagem.viewTemplate.setCmpInputValue("EPPAssemblyComponent", "verticalQty", Number(this.formMontagem.formGroup.controls["flx_especif_carac_mont_prd.qtdVertical"].value) || 0);
        this.formMontagem.viewTemplate.setCmpInputValue("EPPAssemblyComponent", "bloodlettingBtwLines", Number(this.formMontagem.formGroup.controls["flx_especif_carac_mont_prd.sangriaEntreLinhas"].value) || 0);
        this.formMontagem.viewTemplate.setCmpInputValue("EPPAssemblyComponent", "bloodlettingBtwTracks", Number(this.formMontagem.formGroup.controls["flx_especif_carac_mont_prd.sangriaEntreFaixas"].value) || 0);
        this.formMontagem.viewTemplate.setCmpInputValue("EPPAssemblyComponent", "rightMargin", Number(this.formMontagem.formGroup.controls["flx_especif_carac_mont_prd.margemDireita"].value));
        this.formMontagem.viewTemplate.setCmpInputValue("EPPAssemblyComponent", "leftMargin", Number(this.formMontagem.formGroup.controls["flx_especif_carac_mont_prd.margemEsquerda"].value));
        this.formMontagem.viewTemplate.setCmpInputValue("EPPAssemblyComponent", "bottomMargin", Number(this.formMontagem.formGroup.controls["flx_especif_carac_mont_prd.margemInferior"].value));
        this.formMontagem.viewTemplate.setCmpInputValue("EPPAssemblyComponent", "topMargin", Number(this.formMontagem.formGroup.controls["flx_especif_carac_mont_prd.margemSuperioir"].value));
      } else {
        this.flxService.getConjugatedAssemblyItems(this.montInfoId).pipe(take(1)).subscribe(itemList => {
          this.formMontagem.viewTemplate.setCmpInputValue("EPPConjugateAssemblyComponent", "rightMargin", Number(this.formMontagem.formGroup.controls["flx_especif_carac_mont_cj.margemDireita"]));
          this.formMontagem.viewTemplate.setCmpInputValue("EPPConjugateAssemblyComponent", "leftMargin", Number(this.formMontagem.formGroup.controls["flx_especif_carac_mont_cj.margemEsquerda"]));
          this.formMontagem.viewTemplate.setCmpInputValue("EPPConjugateAssemblyComponent", "bottomMargin", Number(this.formMontagem.formGroup.controls["flx_especif_carac_mont_cj.margemInferior"]));
          this.formMontagem.viewTemplate.setCmpInputValue("EPPConjugateAssemblyComponent", "topMargin", Number(this.formMontagem.formGroup.controls["flx_especif_carac_mont_cj.margemSuperioir"]));
          this.formMontagem.viewTemplate.setCmpInputValue("EPPConjugateAssemblyComponent", "items", itemList);
        })
      }
    }
  }

  enabledSaveButton(): boolean {
    return this.autoformList?.some(af => af.formGroup.dirty);
  }

  saveAll(exteriorCall?: boolean) {
    this.lockScreen = true;
    let response: Promise<void>;
    if (this.autoformList.some(af => af.formGroup.invalid)) {
      this.lockScreen = false;

      if (exteriorCall) {
        response = Promise.reject(new Error("Não é possível salvar a característica de Dimensão. verifique as informações"));
      } else {
        this.messageService.showToast("Não é possível salvar. verifique as informações", "ATENÇÃO", "warning");
        let characName = this.autoformList.filter(af => af.formGroup.invalid)[0].datasetName;

        document.getElementById(this.mapForm.get(characName)).scrollIntoView();
      }
    } else {
      let promiseList = new Array<Promise<string>>();
      this.autoformList.filter(af => af.formGroup.dirty).forEach(af => {
        promiseList.push(af.saveAutoform(af));
      });

      let prom = Promise.all(promiseList).finally(() => {
        this.formMontagem.loadingCompleted.pipe(take(1)).subscribe(() => {
          this.updateAssemblyForm();
        })

        this.lockScreen = false;
      });

      if (exteriorCall) {
        response = new Promise<void>((resolve, reject) => {
          prom.then(() => resolve(), error => reject(error))
        })
      } else {
        prom.finally(() => { });
      }
    }

    return response;
  }

  override saveInfo(): Promise<void> {
    return this.saveAll(true);
  }

  override sendComponentRef() {
    this.epService.addComponentRef(this);
  }
}
