import { AfterContentInit, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from "@angular/forms";
import { LayoutType, ViewTemplateElement } from "../model/view-template-element";

@Component({
  selector: 'app-view-layout',
  templateUrl: './view-layout.component.html',
  styleUrls: ['./view-layout.component.scss']
})
export class ViewLayoutComponent implements AfterContentInit {

  private _layout: ViewTemplateElement;

  @Input() lastPart: boolean = false;
  @Input()
  get layout(): ViewTemplateElement { return this._layout };
  set layout(value: ViewTemplateElement) {
    if (value.children)
      value.children = value.children.filter(c => c != null && c != undefined);

    this._layout = value;
  };
  @Input() FormGroup: FormGroup;
  @Output() cmpReference = new EventEmitter<any>();

  public selectedTab: string;

  constructor() {

  }

  ngAfterContentInit(): void {
    if (this.layout.layoutType == LayoutType.listLayout && !this.layout.cssClassLayout) {
      if (this.layout.direction == "row") {
        this.layout.cssClassLayout = "flexAlignRowStartStretch flexGap20";
      } else if (this.layout.direction == "row wrap") {
        this.layout.cssClassLayout = "flexAlignRowStartStretch flexWrap flexGap20";
      } else if (this.layout.direction == "column") {
        this.layout.cssClassLayout = "flexAlignColumnStartStretch flexGap20"
      }
    }
  }

  gridColumns(l: ViewTemplateElement): string {

    if (l.columnsFixed) {
      return `repeat(auto-fill, ${l.columnsFixed})`;
    }

    if (l.columnsMin) {
      return `repeat(auto-fill, minmax(${l.columnsMin}, 72px))`;
    }

    if (l.columnsMax) {
      return `repeat(auto-fill, minmax(72px, ${l.columnsMax}))`;
    }

    if (l.columns) {
      return l.columns;
    }

    return "repeat(auto-fill, minmax(72px, 72px))";
  }

  gridSpan(l: ViewTemplateElement): string {
    if (l.columnSpan) {
      return l.columnSpan + " span";
    }

    return "1 span";
  }

  changeTab(event) {
    this.selectedTab = event.tabTitle;
    setTimeout(() => {
      this.layout.children.forEach(tab => {
        if (tab.layoutType == LayoutType.component) {
          tab.inputs["tabChange"]?.next(event.tabTitle);
        }
      });
    });
  }

  addCmpReference(cmp) {
    this.cmpReference.emit(cmp);
  }

  adjustListHeight(layout: ViewTemplateElement, isLastPart: boolean) {
    if (layout.height) return layout.height;

    if (layout.layoutType == LayoutType.listLayout && (layout.direction || 'row') == 'row') return '100%';

    if (isLastPart) return '100%';

  }
}
