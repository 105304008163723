import { AfterViewInit, Component, EventEmitter, Inject, Input, LOCALE_ID, Output } from '@angular/core';
import { VolumeInfo } from "../../t2separation.service";
import { T2UserDeviceService } from "src/app/t2-user-device.service";

@Component({
  selector: 'app-transfer-volume',
  templateUrl: './transfer-volume.component.html',
  styleUrls: ['./transfer-volume.component.scss']
})
export class TransferVolumeComponent  {
  @Input() 
  get volumeList(): Array<VolumeInfo> {
    return this._volumeList
  }
  set volumeList(value: Array<VolumeInfo>) {
    this._volumeList = value;

    if (this._volumeList) {
      this._volumeList.forEach(vol => {
        vol.quantity = vol.quantity || 0;
        vol.requisitionReservedQty = vol.requisitionReservedQty || 0;
        vol.reservedQuantity = vol.reservedQuantity || 0;
      })
    }
  }

  @Input() enableClickSelection: boolean;
  @Input() isTransferredVolume: boolean;
  @Output() volumeSelected = new EventEmitter<VolumeInfo>();

  private _volumeList: Array<VolumeInfo>;

  constructor(public userDevService: T2UserDeviceService, @Inject(LOCALE_ID) public locale: string) { }

  selectVolume(volume: VolumeInfo) {
    if (this.enableClickSelection) {
      this.volumeSelected.emit(volume);
    }
  }
}
