<div class="flexColumn" style="height: 100%;">
  <div class="mainContent">
    <div class="treeArea">

      <div class="T2GrayArea flexColumn" style="padding: 5px 10px; gap: 5px;">
        <span>Agrupado por</span>
        <div class="flexAlignRowSpaceBetweenCenter">
          <div class="flexRow flexGap5">
            <div class="groupButton" [ngClass]="{'groupButtonSelected': groupedBy == 'Account'}"
              (mousedown)="setGroupBy('Account')">
              Conta Contábil
            </div>
            <div class="groupButton" [ngClass]="{'groupButtonSelected': groupedBy == 'Cost'}"
              (mousedown)="setGroupBy('Cost')">
              Centro de Custo
            </div>
          </div>
          <div class="flexAlignRowEndCenter flexGap5">
            <div class="insertFloatButton" (click)="addPolicyItem()">
              <nb-icon icon="plus-outline"></nb-icon>
            </div>
            <div *ngIf="selectedPolicyItem" class="deleteFloatButton" (click)="deleteItem()">
              <nb-icon icon="close-outline"></nb-icon>
            </div>
          </div>
        </div>
      </div>

      <app-t2grid #grid [treeData]="true" [rowHeight]="25" [sortable]="false" (rowClick)="gridRowClick($event)"
        [pagination]="false" [rowStyle]="{ 'border-bottom': '0px' }"
        [autoGroupColumnDef]="{ suppressHeaderMenuButton: true, flex: 1, headerName:  undefined }" style="flex: 1;" />

    </div>

    <div *ngIf="selectedPolicyItem" class="detailArea">

      <!-- Centro de custo e conta contábil -->
      <div class="title">
        <div>
          <span class="detailBoxTitle">Conta Contábil</span>
          <span>{{selectedPolicyItem.glAccountCode}} {{selectedPolicyItem.glAccount}}</span>
        </div>
        <div>
          <span class="detailBoxTitle">Centro de Custos</span>
          <span>{{selectedPolicyItem.costCenterCode}} {{selectedPolicyItem.costCenter}}</span>
        </div>
      </div>

      <!-- Regras de verba e aprovações -->
      <nb-tabset class="flexColumn" style=" height: calc(100% - 42px);">
        <nb-tab tabTitle="Regras globais" class="flexColumn" style="flex: 1;">
          <app-budget-rule #budgetRule [budgetRule]="selectedPolicyItem.generalRule" (hasChanges)="rulesChanged()"
            (lockScreen)="lockScreen($event)" />
        </nb-tab>
        <nb-tab tabTitle="Regras por período" class="flexColumn" style="flex: 1; ">
          <div class="flexAlignRowStart" style="gap: 20px; max-width: 100px;">
            <app-budget-rule #budgetRule *ngFor="let periodRule of selectedPolicyItem.periodRuleList"
              [budgetRule]="periodRule" (hasChanges)="rulesChanged()" (lockScreen)="lockScreen($event)" />
          </div>
        </nb-tab>
      </nb-tabset>
    </div>
  </div>
</div>