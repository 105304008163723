<div style="display: flex; flex-direction: column">
  <nb-form-field style="margin-left: auto; margin-bottom: 5px; width: 200px">
    <nb-icon nbSuffix icon="search" pack="eva"></nb-icon>
    <input nbInput type="text" #searchField fieldSize="small" style="width: 200px" placeholder="Busca rápida"
      (keyup)="onSearchChange($event.target['value'])" />
  </nb-form-field>

  <div style="margin-left: auto;">
    <button *ngFor="let item of freightTypeList" nbButton size="tiny" [status]="item.selected ? 'success' : 'basic'"
      style="margin: 0px 0px 5px 5px;" (click)="freightTypeClick(item)">
      {{item.name}}
    </button>
  </div>

</div>

<div class="flexRow flexWrap flexGap10" style="width: 100%">
  <div *ngFor="let packing of filteredPackingList; let i = index" class="cardPacking" (click)="onCardClick(packing)"
    [ngStyle]="{'flex': '0 1 350px' }" [ngStyle.sm]="{'flex': '100%' }" [ngStyle.xs]="{'flex': '100%' }"
    [ngClass]="{'cardPacking2' : clickable, 'cardPackingGray': sepIncompleted(packing), 'cardPackingGreen': !sepIncompleted(packing)}">
    <!-- <div style="font-size: 150%; font-weight: bold; min-width: 30px; margin-right: 5px;">{{i+1}}</div> -->

    <!-- numero do pedido e data -->
    <div style="display: flex; margin: 0px 5px 5px 5px">
      <div style="display: flex; flex-direction: column">
        <div style="font-size: 70%; margin-bottom: -7px">Pedido</div>
        <div style="font-weight: bold">{{ packing.salesOrder }}</div>
      </div>
      <div *ngIf="packing.urgent" class="flexAlignColumnCenter urgent-order">
        Urgente
      </div>

      <div *ngIf="packing.prefCustomer" class="flexAlignColumnCenter pref-order">
        Preferencial
      </div>
      <div style="margin-left: auto">
        <div style="text-align: right; font-size: 70%; margin-bottom: -7px">
          Entrega
        </div>
        <div style="font-weight: bold">
          {{ packing.deliveryDate | date: "dd/MM/yyyy" }}
        </div>
      </div>
    </div>

    <!-- nome do cliente e embalador -->
    <div style="display: flex; margin: 0px 5px 5px 5px">
      <div style="display: flex; flex-direction: column">
        <div style="font-size: 70%; margin-bottom: -7px">Cliente</div>
        <div style="font-weight: bold; font-size: 80%">
          {{ packing.customer }}
        </div>

        <div style="color: red" *ngIf="packing.packingReproved">
          <div style="font-size: 70%; margin-bottom: -7px">
            Reprovado na conferência
          </div>
          <div style="font-weight: bold; font-size: 80%">
            {{ packing.packingReproved }}
          </div>
        </div>

        <div *ngIf="packing.packerName">
          <div style="font-size: 70%; margin-bottom: -7px">Embalador</div>
          <div style="font-weight: bold; font-size: 80%">
            {{ packing.packerName }}
          </div>
        </div>

        <div *ngIf="packing.carrier">
          <div style="font-size: 70%; margin-bottom: -7px">Transportadora</div>
          <div style="font-weight: bold; font-size: 80%">
            {{ packing.carrier }}
          </div>
        </div>

        <div *ngIf="packing.salesOrderNotes" style="font-size: 70%; margin-bottom: -7px">
          Observação
        </div>
        <div class="dont-break-out" style="font-weight: bold; font-size: 80%;">
          {{ packing.salesOrderNotes }}
        </div>
      </div>
    </div>

    <!-- quantidade de volumes -->

    <div>
      <div *ngIf="packing.volumes > 0" class="flexColumn" style="margin: 0px 5px 5px 5px">
        <div style="font-size: 70%; margin-bottom: -7px">Qdt Vol</div>
        <div style="font-weight: bold; font-size: 80%">
          {{ packing.volumes | number }}
        </div>
      </div>

      <div *ngIf="packing.peso > 0" class="flexColumn" style="margin: 0px 5px 5px 5px">
        <div style="font-size: 70%; margin-bottom: -7px">Peso</div>
        <div style="font-weight: bold; font-size: 80%">
          {{ packing.peso | number }} kg
        </div>
      </div>
    </div>

    <div style="margin: 0px 5px 5px 5px">
      <!-- itens -->
      <table style="border: 0px">
        <tr>
          <th></th>
          <th style="width: 100%"></th>
          <th>Ped</th>
          <th>Sep</th>
        </tr>
        <tr *ngFor="let produto of packing.itemList; let i = index" style="color: ivory;" [style.background-color]=" produto.separatedAmount >= produto.requiredAmount ? 'rgba(2, 192, 84)' :
          produto.itemDetails?.color ? produto.itemDetails.color : 'rgba(255,0,0)'
          ">
          <td style="text-align: right; vertical-align: top; font-size: 80%; color: ivory;">{{ i + 1 }}</td>
          <td>
            <div style="font-weight: bold; color: ivory;">{{ produto.itemDescription }}</div>
            <div><b>{{produto.itemDetails?.description}}</b> {{produto.itemDetails?.detail}}</div>
            <div style="font-size: 80%; color: ivory;" *ngIf="produto.obsItem"><b>{{ produto.obsItem }}</b></div>
            <div *ngIf="produto.separador" style="color: ivory;"> <b>Separador</b> {{ produto.separador }}</div>
          </td>
          <td style="text-align: right; color: ivory;">
            <b>{{ produto.requiredAmount | number }}</b>
          </td>
          <td style="text-align: right; color: ivory;">
            <b>{{ produto.separatedAmount | number }}</b>
          </td>
        </tr>
      </table>
    </div>
  </div>
</div>
