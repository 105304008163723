<nb-card>
  <nb-card-body>
    <p><strong>Configurações locais</strong></p>
    <div class="flexRow flexGap10" [ngStyle.xs]="{'flex-direction': 'column', 'gap': '0px' }"
      style="width: 70vw; height: 60vh; font-size: 80%;">
      <div class="flexColumn">
        <div>
          <label [for]="selTheme">Tema</label>
        </div>
        <nb-select #selTheme [(selected)]="themeName" size="tiny" (selectedChange)="onThemeChange()">
          <nb-option value="corporate">Light</nb-option>
          <nb-option value="default" selected>Classic</nb-option>
          <nb-option value="dark">Dark</nb-option>
        </nb-select>
      </div>

      <div class="flexColumn flexLength1">
        <label [for]="selPrinter">Impressora de Etiqueta</label>
        <nb-select #selPrinter [(selected)]="selectedLabelPrinter" size="tiny" (selectedChange)="onLabelPrinterChange()">
          <nb-option-group *ngFor="let group of printerList" [title]="group.clientName">
            <nb-option *ngFor="let printer of group.printerList"
              [value]="group.clientName + '##' + printer">{{printer}}
            </nb-option>
          </nb-option-group>
        </nb-select>
      </div>


    </div>
  </nb-card-body>
</nb-card>