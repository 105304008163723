import { Component } from '@angular/core';
import { ICellRendererAngularComp } from "ag-grid-angular";
import { IAfterGuiAttachedParams, ICellRendererParams } from "ag-grid-community";
import { IIconRendererParams } from "./iicon-renderer-params";

@Component({
  selector: 'app-icon-renderer',
  templateUrl: './icon-renderer.component.html',
  styleUrls: ['./icon-renderer.component.scss']
})
export class IconRendererComponent implements ICellRendererAngularComp {

  public params: ICellRendererParams;
  public iconList: Array<IIconRendererParams>

  constructor() { }

  refresh(params: ICellRendererParams): boolean {
    return true;
  }

  afterGuiAttached?(params?: IAfterGuiAttachedParams): void {
  }

  agInit(params: ICellRendererParams): void {
    this.params = params;
    this.iconList = params["iconList"];
  }

  checkedHandler(checked) {
    let colId = this.params.column.getColId();
    this.params.node.setDataValue(colId, checked);
  }

  getCssClass(item: IIconRendererParams) {
    return {
      'text-top': item.text?.position == "Top",
      'text-bottom': item.text?.position == "Bottom",
      'text-left': item.text?.position == "Left" || !item.text,
      'text-right': item.text.position == "Right"
    }
  }
}
