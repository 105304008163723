import { take } from 'rxjs/Operators';
import { T2DatasetService } from './../../dataset/t2dataset.service';
import { T2HttpClientService } from './../../http/t2httpClient.service';
import { Component, Input, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { T2gridComponent } from "../t2grid/t2grid.component";
import { DialogTemplateComponent } from "../dialog-template/dialog-template.component";

@Component({
  selector: 'app-t2-log',
  templateUrl: './t2-log.component.html',
  styleUrls: ['./t2-log.component.scss']
})
export class T2LogComponent implements OnInit, AfterViewInit {
  private _id_information: string;
  @Input() get id_information(): string { return this._id_information; };
  set id_information(value: string) { this._id_information = value; this.loadLogData(); };

  private _datasetName: string;
  @Input() get datasetName(): string { return this._datasetName; };
  set datasetName(value: string) { this._datasetName = value; this.loadLogData(); };



  @ViewChild("gridLog", { static: true }) gridLog: T2gridComponent;
  @ViewChild('dialog', { static: true }) dialog: DialogTemplateComponent;

  columnGroup = { headerName: 'Data' };

  constructor(private httpClient: T2HttpClientService, private datasetService: T2DatasetService) { }

  ngOnInit(): void {
    this.loadGridHeaders();
  }

  ngAfterViewInit(): void {
    this.loadLogData();
  }


  loadGridHeaders() {
    let columnDef = [
      { headerName: "Data", showRowGroup: 'logDate', field: "logDate", rowGroup: true, type: "dateTimeColumn", hide: true },
      { headerName: "Versão", field: "recordVersion"},
      { headerName: "Usuário", field: "userName"},
      { headerName: "Campo", field: "propertyLabel"},
      { headerName: "Valor original", field: "oldValue"},
      { headerName: "Valor alterado", field: "newValue"}
    ];

    this.gridLog.setGridColumnDefs(columnDef);
  }

  loadLogData(): void {
    if (!this.id_information || !this.datasetName) {
      this.gridLog.setGridData([]);
      return;
    }

    this.datasetService.getDataset(this.datasetName).pipe(take(1)).subscribe(resp => {
      if (!resp) return;
      const id_dataset = resp.id_dataset;

      if (!id_dataset) {
        this.gridLog.setGridData([]);
      }
      else {
        this.gridLog.loading = true;
        this.loadGridData(id_dataset);
      }

    });

  }

  loadGridData(id_dataset: string) : void {

    let queryParams = new Map<string, string>();
    queryParams.set("id_record_of_dataset", this.id_information);
    queryParams.set("id_dataset", id_dataset);
    this.httpClient.get("infoLog/informationLog", queryParams).pipe().subscribe(resp => {
      if (!resp || !resp.informationLogList) return this.gridLog.loading = false;

      let gridInfo = [];
      let row;

      if (!Array.isArray(resp.informationLogList?.informationLog)){
        resp.informationLogList.informationLog = [resp.informationLogList?.informationLog];
      }

      resp.informationLogList?.informationLog.forEach(logRow => {
        if (Array.isArray(logRow.informationPropLogList.informationPropLog)) {
          logRow.informationPropLogList.informationPropLog.forEach(r => {
            row = {
              recordVersion: logRow.recordVersion,
              logDate: logRow.logDate,
              userName: logRow.userName,
              propertyLabel: r.propertyLabel,
              oldValue: r.oldValue == false && typeof r.oldValue === 'boolean' ? 'Não'
                : r.oldValue == true && typeof r.oldValue === 'boolean' ? 'Sim' : r.oldValue,
              newValue: r.newValue == false && typeof r.newValue === 'boolean' ? 'Não'
                : r.newValue == true && typeof r.newValue === 'boolean' ? 'Sim' : r.newValue
            },
              gridInfo.push(row);
          }
          );
        }
        else {
          let oldValue = logRow.informationPropLogList.informationPropLog.oldValue;
          let newValue = logRow.informationPropLogList.informationPropLog.newValue;
          row = {
            recordVersion: logRow.recordVersion,
            logDate: logRow.logDate,
            userName: logRow.userName,
            propertyLabel: logRow.informationPropLogList.informationPropLog.propertyLabel,
            oldValue: oldValue == false && typeof oldValue === 'boolean' ? 'Não'
              : oldValue == true && typeof oldValue === 'boolean' ? 'Sim' : oldValue,
            newValue: newValue == false && typeof newValue === 'boolean' ? 'Não'
              : newValue == true && typeof newValue === 'boolean' ? 'Sim' : newValue
          },
            gridInfo.push(row);
        }
      });

      this.gridLog.setGridData(gridInfo);
      this.gridLog.loading = false;
    },
      error => {
        this.gridLog.loading = false;
      });

  }

}
