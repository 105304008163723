<app-dialog-template #dialog title="Resultados da Comparação" [ngStyle]="{'height.vh': 80, 'width.vw': 80}">
  <content class="flexColumn flexLength1">
    <app-t2grid class="flexLength1" #grid [pagination]="false">
    </app-t2grid>
  </content>
  <footer>
    <div class="wFooter">
      <div class="button-row" style="display: flex;">
        <button nbButton status="danger" size="tiny" (click)="closeDialog('Cancela')"
          style="margin-left: auto">Cancelar</button>
        <button nbButton status="primary" size="tiny" (click)="closeDialog('Confirma')">Atualizar Simulação</button>
      </div>
    </div>
  </footer>
</app-dialog-template>
