<app-dialog-template #dialog title="Seleção de Planejamento" [ngStyle]="{'height.vh': 80, 'width.vw': 80}"
  [nbSpinner]="loading" nbSpinnerSize="giant" nbSpinnerStatus="primary">

  <content class="flexColumn flexGap10" style="height: 100%;">

    <div class="flexRow flexGap10">

      <div class="flexRow flexGap5">
        <span class="defaultSmallTitle">OP</span>
        <app-t2-input-text id="searchInput" #searchInput [formGroup]="formGroup" t2IdCmp="prodOrder" (keyDownEnter)="searchPlans()" />
      </div>

      <app-t2-button status="primary" size="tiny" value="Pesquisar" (clicked)="searchPlans()" />
    </div>

    <div class="flexColumn flexGap5 fillContent">
      <span *ngIf="!showNoPlanMsg && taskPlanList?.length" class="defaultSmallTitle">Planejamentos</span>
      <span *ngIf="showNoPlanMsg" class="defaultTitle noPlanMsg">
        Nenhum planejamento encontrado nesse dispositivo para esta OP
      </span>
      <div
        [ngClass]="{'planList': !userDevService.isXSmallScreen, 'planListXSmallScreen': userDevService.isXSmallScreen}">
        <div *ngFor="let taskPlan of taskPlanList" class="detailBox plan"
          [ngClass]="{'blueBox': taskPlan.status == 'Finalizada', 'grayBox': taskPlan.status == 'Não Iniciada'}"
          style="overflow-y: unset;" (click)="selectPlan(taskPlan)">
          <div class="flexColumn flexGap5">
            <div class="flexAlignRowSpaceBetweenStart">
              <span class="defaultSmallTitle">{{taskPlan.taskDescription}}</span>
              <div class="flexAlignColumnStartEnd">
                <span class="defaultSmallTitle">{{taskPlan.status}}</span>
              </div>
            </div>
            <div class="flexAlignRowSpaceBetweenStart flexGap5" style="line-height: 0.7rem;">
              <div class="flexAlignRowSpaceBetweenStart flexGap5">
                <div class="flexAlignColumnStartEnd">
                  <span class="detailBoxTitle">Dt Inicio</span>
                  <span class="detailBoxValue">{{taskPlan.startDate | date: 'short'}}</span>
                </div>
              </div>
              <div class="flexAlignRowSpaceBetweenStart flexGap5">
                <div class="flexAlignColumnStartEnd">
                  <span class="detailBoxTitle">Dt Fim</span>
                  <span class="detailBoxValue">{{taskPlan.startDate | date: 'short'}}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </content>

  <footer class="flexAlignRowEndCenter">
    <app-t2-button status="basic" size="tiny" value="Fechar" (clicked)="closeDialog()" />
  </footer>

</app-dialog-template>