import { T2SecurityService } from './../../security/t2security.service';
import { AnalysisEditComponent } from './analysis-edit/analysis-edit.component';
import { AnalysisParamsComponent } from './analysis-params/analysis-params.component';
import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { NbDialogService } from "@nebular/theme";
import { take } from "rxjs/Operators";
import { T2HttpClientService } from "../../http/t2httpClient.service";
import { T2gridComponent } from "../t2grid/t2grid.component";
import { _ } from "ag-grid-community";

@Component({
  selector: 'app-t2-analysis',
  templateUrl: './t2-analysis.component.html',
  styleUrls: ['./t2-analysis.component.scss']
})
export class T2AnalysisComponent implements OnInit {

  @Output() analysisTitle = new EventEmitter();

  private id_analysis: string;
  private analysisParams: Array<any> = null;
  loaded = false;

  analysisConfig: any[] = null;
  analysisConfigShow = true;
  analysisVisualization = {
    config: null,
    paramsCount: 0,
    paramsFilter: {}
  }
  loadingData = false;
  saving = false;
  colDefs: Array<any> = [];
  @ViewChild("gridAnalysis") gridAnalysis: T2gridComponent;

  constructor(private httpClient: T2HttpClientService, private secService: T2SecurityService, private dialogService: NbDialogService) {

  }

  ngOnInit(): void { }

  loadAnalysis(id_analysis: string) {

    this.id_analysis = id_analysis;

    this.analysisVisualization.config = null;
    this.analysisVisualization.paramsFilter = {};
    this.analysisVisualization.paramsCount = 0;

    this.gridAnalysis.setGridData([]);

    if (this.id_analysis) {

      this.httpClient.get(`core.analysis/structure/${this.id_analysis}`, null).pipe(take(1)).subscribe(resp => {
        this.analysisTitle.next(resp.description);

        if (!resp.fields) resp.fields = []
        else if (!Array.isArray(resp.fields)) resp.fields = [resp.fields];

        this.colDefs = resp.fields.map((field: any) => {
          field.headerName = (field.fieldLabel || field.fieldName);
          field.field = field.fieldName;
          switch (field.fieldType) {
            case "INTEGER":
            case "SMALLINT":
            case "FLOAT":
            case "DECIMAL":
              field.type = "numericColumn";
              field.enableValue = true;
              break;

            case "DATE":
              field.type = "dateColumn";
              field.enablePivot = true;
              field.enableValue = true;
              break;

            case "DATETIME":
            case "DATE_TIME":
            case "TIME":
              field.type = "dateTimeColumn";
              field.enablePivot = true;
              field.enableValue = true;
              break;

            case "BOOLEAN":
              field.type = "booleanColumn";
              break;

            default:
              field.enablePivot = true;
              field.enableRowGroup = true;
          }

          return field;
        });

        if (!resp.parameters) {
          this.analysisParams = null;
          this.loadData({});
        } else {
          if (!Array.isArray(resp.parameters)) resp.parameters = [resp.parameters];
          this.analysisParams = resp.parameters;
        }

        this.loaded = true;
      });

      this.loadAnalysisConfigs();
    }
  }

  private loadAnalysisConfigs(id_analysis_config?: string) {
    this.httpClient.get(`core.analysis/configList/${this.id_analysis}`, null).pipe(take(1)).subscribe(resp => {

      if (!resp.availableConfig) {
        this.analysisConfig = null;
        this.analysisVisualization.config = [];
      } else {
        if (!Array.isArray(resp.availableConfig)) resp.availableConfig = [resp.availableConfig];
        this.analysisConfig = resp.availableConfig.map(item => {
          item.creationDate = new Date(item.creationDate);
          return item;
        });

        if (id_analysis_config) {
          const config = this.analysisConfig.find(item => item.id_analysis_config == id_analysis_config);
          if (config)
            this.selectionConfig(config, false)
        }
      }
    });
  }

  processParameters(showFilter: boolean = true) {

    if (!this.analysisParams) {
      this.loadData({});
      return;
    }

    if (!showFilter) {
      this.loadData(this.analysisVisualization.paramsFilter);
      return;
    }

    this.dialogService.open(AnalysisParamsComponent, {
      closeOnEsc: true,
      context: {
        analysisParams: this.analysisParams?.map(p => {

          let paramValue;

          if (this.analysisVisualization.paramsCount) {
            paramValue = this.analysisVisualization.paramsFilter[p.paramName];
          } else {
            if (p.mandatory) {
              if (p.paramType == "selection" && p.selection?.length) paramValue = p.selection[0].value;
            }
          }

          if (p.paramType == "date" && paramValue && (typeof paramValue == "string")) {
            paramValue = new Date(paramValue)
          }

          return { ...p, value: paramValue };
        })
      }
    }).onClose.subscribe(resp => {

      if (resp) {

        if (!this.analysisVisualization.config?.id_analysis_config) this.analysisConfigShow = false;

        this.analysisVisualization.paramsFilter = resp;
        this.analysisVisualization.paramsCount = Object.keys(this.analysisVisualization.paramsFilter).length;
        this.loadData(this.analysisVisualization.paramsFilter);
      }
    });
  }

  selectionConfig(config: any, process: boolean = true): void {

    if (config && config.id_analysis_config == this.analysisVisualization.config?.id_analysis_config) {
      return;
    }

    if (!config || !config.id_analysis_config) {
      this.analysisVisualization.config = null;
      this.gridAnalysis.setGridData([]);
      this.gridAnalysis.resetColumnState();
    } else {
      this.httpClient.get(`core.analysis/config/${config.id_analysis_config}`, null).pipe(take(1)).subscribe(resp => {

        if (resp.analysisConfig) {
          const configObj = JSON.parse(resp.analysisConfig.configObject || "{}");

          this.gridAnalysis.setGridState(configObj.state)

          this.analysisVisualization.config = {
            id_analysis_config: config.id_analysis_config,
            description: resp.analysisConfig.description || config.description,
            userName: resp.analysisConfig.userName || config.insertUserName,
            showFilterOnLoad: resp.analysisConfig.callFilterOnLoad ?? true,
            publicAnalisys: resp.analysisConfig.publicAnalisys ?? true,
            diffUser: resp.analysisConfig.id_identity_security != this.secService.getUserId()
          };

          this.analysisVisualization.paramsFilter = configObj.filterParams || {};
          this.analysisVisualization.paramsCount = Object.keys(this.analysisVisualization.paramsFilter).length;

          if (process)
            this.processParameters(this.analysisVisualization.config.showFilterOnLoad);
        }
      });
    }
  }

  editConfig() {
    this.dialogService.open(AnalysisEditComponent, {
      closeOnEsc: true,
      context: {
        newConfig: !this.analysisVisualization.config?.id_analysis_config,
        diffUser: this.analysisVisualization.config?.diffUser,
        description: this.analysisVisualization.config?.description,
        publicAnalisys: this.analysisVisualization.config?.publicAnalisys ?? true,
        showFilterOnLoad: this.analysisVisualization.config?.showFilterOnLoad ?? true,
      }
    }).onClose.subscribe(resp => {
      const configObject = {
        filterParams: this.analysisVisualization.paramsFilter,
        state: this.gridAnalysis.getGridState()
      };

      const ac = {
        ["@id_analysis_config"]: this.analysisVisualization.config?.id_analysis_config,
        ["@id_analysis"]: this.id_analysis,
        ["@description"]: resp.description,
        ["@callFilterOnLoad"]: resp.showFilterOnLoad,
        ["@publicAnalisys"]: resp.publicAnalisys,
        ["@makeCopy"]: resp.makeCopy,
        configObject: JSON.stringify(configObject)
      }

      this.saving = true;
      this.httpClient.post(`core.analysis/save`, null, ac).pipe(take(1)).subscribe(resp => {

        try {
          let id_analysis_config = this.analysisVisualization.config?.id_analysis_config;
          if (!id_analysis_config || resp.id_analysis_config != id_analysis_config) {
            id_analysis_config = resp.id_analysis_config;

            this.loadAnalysisConfigs(id_analysis_config);
          } else if (this.analysisVisualization.config) {
            this.analysisVisualization.config.description = ac["@description"];
            this.analysisVisualization.config.publicAnalisys = ac["@publicAnalisys"];
            this.analysisVisualization.config.showFilterOnLoad = ac["@callFilterOnLoad"];
          }

        } finally {
          this.saving = false;
        }

      }, error => {
        this.saving = false;
      });
    });
  }

  deleteConfig(id_analysis_config: string) {
    this.saving = true;
    this.httpClient.delete(`core.analysis/deleteConfig/${id_analysis_config}`, null).pipe(take(1)).subscribe(resp => {

      try {
        this.selectionConfig(null);
        this.loadAnalysisConfigs();
      } finally {
        this.saving = false;
      }

    }, error => {
      this.saving = false;
    });
  }

  loadData(params) {

    const requestParam = this.analysisParams?.map(p => { return { paramName: p.paramName, paramValue: params[p.paramName] || undefined, paramType: p.paramType }; }) || [];

    this.loadingData = true;

    this.httpClient.post(`core.analysis/data/${this.id_analysis}`, null, requestParam).pipe(take(1)).subscribe(resp => {
      if (!resp.jsonData) resp.jsonData = "[]";
      resp.jsonData = JSON.parse(resp.jsonData);
      if (!Array.isArray(resp.jsonData)) resp.jsonData = [resp.jsonData];

      this.gridAnalysis.setGridData(resp.jsonData)
      this.loadingData = false;

    }, error => this.loadingData = false);

  }

  onGridStateChanged(gridState) {
    // console.log(gridState);
  }

}
