import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { FormBuilder } from "@angular/forms";
import { ICellEditorAngularComp } from "ag-grid-angular";
import { take } from "rxjs/Operators";
import { T2AggregationComponent } from "../../../ui/t2-aggregation/t2-aggregation.component";

@Component({
  selector: 'app-aggregation-editor',
  templateUrl: './aggregation-editor.component.html',
  styleUrls: ['./aggregation-editor.component.scss']
})
export class AggregationEditorComponent implements ICellEditorAngularComp, AfterViewInit {

  @ViewChild("aggCmp", { static: true }) aggCmp: T2AggregationComponent;

  public informationId: string;
  public datasetId: string;
  public datasetName: string;
  public id: string = "informationId";
  private params: any;

  constructor(private formBuilder: FormBuilder) {}

  ngAfterViewInit(): void {
    if (!this.aggCmp.formGroup.controls[this.id]) {
      this.aggCmp.formGroup.addControl(this.id, this.formBuilder.control(this.informationId || null));
      this.aggCmp.formGroup.controls[this.id].valueChanges.pipe(take(1)).subscribe(e => this.onValueChange());
    }
  }

  agInit(params: any): void {

    this.params = params;
    this.informationId = params.value;
    this.datasetId = params.datasetId || params.colDef["datasetId"] ;
    this.datasetName = params.datasetName || params.colDef["datasetName"];

    if (this.aggCmp.formGroup) {
      this.aggCmp.formGroup.addControl(this.id, this.formBuilder.control(this.informationId || null));
      this.aggCmp.formGroup.controls[this.id].valueChanges.pipe(take(1)).subscribe(e => this.onValueChange());
    }
  }

  getValue() {
    return this.aggCmp.formGroup.controls[this.id].value;
  }

  // Gets called once before editing starts, to give editor a chance to
  // cancel the editing before it even starts.
  // isCancelBeforeStart() {
  //   return false;
  // }

  // Gets called once when editing is finished (eg if enter is pressed).
  // If you return true, then the result of the edit will be ignored.
  isCancelAfterEnd() {
    return false;
  }

  public onValueChange() {
    this.params.api.stopEditing();
  }

}
