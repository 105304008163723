<div class="T2GrayArea flexColumn layerDetail" style="gap: 10px; padding: 0px 10px 10px 10px !important; width: 100%; ">

  <div>
    <div class="layerType">&nbsp;{{structLayer?.chemical?.chemicalType}} </div>
    <div class="layerDescription"><span>{{structIndex}}</span> {{structLayer?.description}}</div>
  </div>

  <div *ngIf="structLayer?.layerType == 'SUBSTRATE'">
    <div>
      <span style="font-size: 1rem; font-weight: bold">{{structLayer?.structCode}}</span>
      -
      <strong>{{structLayer?.grammage}}g/m<sup>2</sup> </strong>
      ({{structLayer?.minGrammage}}g/m<sup>2</sup> -
      {{structLayer?.maxGrammage}}g/m<sup>2</sup>)
    </div>
  </div>

  <div *ngIf="structLayer?.layerType == 'CHEMICAL'">

    <div>
      <span style="font-size: 1rem; font-weight: bold">{{structLayer?.structCode}}</span>
      -
      <strong>{{structLayer?.grammage}}g/m<sup>2</sup> </strong> ({{structLayer?.minGrammage}}g/m<sup>2</sup> -
      {{structLayer?.maxGrammage}}g/m<sup>2</sup>)
    </div>

    <div class="structDetail">
      <div>Aplicação</div>
      <div>{{structLayer?.chemical?.substrateDescription}}</div>
    </div>

    <div class="structDetail">
      <div>Lado aplic</div>
      <div>{{structLayer?.chemical?.side}}</div>
    </div>

    <div class="structDetail" *ngIf="structLayer?.chemical?.printing">
      <div>Impressão</div>
      <div>{{structLayer?.chemical?.printing}}</div>
    </div>

    <div class="structDetail">
      <div>Cura</div>
      <div>{{structLayer?.chemical?.waitingTime}}</div>
    </div>

  </div>

</div>
