import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-task-urgent',
  templateUrl: './task-urgent.component.html',
  styleUrls: ['./task-urgent.component.scss']
})
export class TaskUrgentComponent implements OnInit {
  @Input() urgent: boolean;

  ngOnInit(): void { }
}
