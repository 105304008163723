import { Injectable } from '@angular/core';
import { T2LocalStorageService } from './t2local-storage.service';
import { NbThemeService } from '@nebular/theme';
import { Subject, BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class T2ThemingService {

  private TP_THEME = 'TPTheme';
  private subThemeName$ = new BehaviorSubject<string>("default");

  constructor(private themeService: NbThemeService, private storage: T2LocalStorageService) { }

  startTheme() {
    this.changeTheme(this.getThemeName());
  }

  changeThemeForThisSession(themeName: string) {
    this.themeService.changeTheme(themeName);
  }

  changeTheme(themeName: string) {
    this.changeThemeForThisSession(themeName);
    this.storage.setData(this.TP_THEME, themeName);

    this.subThemeName$.next(themeName);
  }

  getThemeName(): string {
    return this.storage.getData(this.TP_THEME) || 'default';
  }

  getThemeNameObservable(): Observable<string> {
    return this.subThemeName$.asObservable();
  }
}
