<div [style.display]="!visible ? 'none' : 'block'" style="height: 32px;">
  <div style="display: flex; justify-content: flex-start; align-items: center; " class="T2CmpReadOnly"
    [ngStyle]="{'background-color': (readOnly ? '' : 'var(--input-basic-background-color)')}">

    <button *ngIf="!readOnly" nbButton size="small" ghost style="margin-left: 2px; margin-top: 1px;"
      (click)="openTreeGrid()">
      <nb-icon icon="list-outline"></nb-icon>
    </button>

    <div class="cmpInputFont"
      style="padding: 5px 5px 7px 0px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">
      &nbsp; {{ description }}
    </div>
  </div>
</div>

<ng-template #treeGridDialog>
  <app-dialog-template [ngStyle]="{'height.vh': 65, 'width.vw': 80}">
    <content style="height: 100%;">
      <app-t2-dataset-tree-grid #treeGrid [datasetName]="datasetName"
        [dataIdSelected]="formGroup.controls[t2IdCmp].value"
        (selectionChanged)="onTreeGridSelectionChanged($event)"></app-t2-dataset-tree-grid>
    </content>
    <footer fstyle="display: flex; justify-content: flex-end; align-items: center; gap: 5px">
      <button nbButton size="tiny" status="danger" (click)="dialogRef.close(false)">Cancelar</button>
      <button nbButton size="tiny" status="primary" (click)="confirmSelection()">Confirmar</button>
    </footer>
  </app-dialog-template>
</ng-template>
