<div *ngIf="device" #mainPanel class="T2GrayArea flexColumn flexGap3">

  <div class="mainPanel">
    <div class="mainPanelItem"
    (click)="$event.stopPropagation(); showActionTree('device', device.deviceId, smallWindow)">
    <div class="flexRow">
      <div *ngIf="device.warningList?.length" class="deviceWarning"
        (click)="$event.stopPropagation(); openWarning(device.warningList)">
        <nb-icon icon="bell" status="danger"></nb-icon>
      </div>
      <div class="deviceTitle">
        {{device.title}}
      </div>
    </div>
  </div>

    <div [ngClass]="{'defaultStatusBG': true, 'mainPanelItem': true,
                     'productionStatusBG': currentTaskPlan?.basicType == 'Production',
                     'setupStatusBG': currentTaskPlan?.basicType == 'Setup',
                     'stopStatusBG': currentTaskPlan?.basicType == 'Stop',
                     'blueBox': searchedTaskPlan?.status == 'Finalizada',
                     'grayBox': searchedTaskPlan?.status == 'Não Iniciada'}">

      <div style="font-size: 1.3em; font-weight: bold; color: #f5f5f5;"
        [ngClass]="{'prodOrderInfo': true, 'prodOrderInfoH': currentTaskPlan?.id_ordemProd && !smallWindow}"
        (click)="$event.stopPropagation(); showActionTree('op', currentTaskPlan?.id_ordemProd, smallWindow)">
        {{currentTaskPlan?.prodOrder || "Sem OP"}}
      </div>

      <div class="taskInfo">
        <div class="taskOperation" [nbTooltip]="currentTaskPlan?.operation">
          {{currentTaskPlan?.operation || "-"}}
        </div>
        <div [ngClass]="{'taskDescription' : true, 'taskDescriptionH' : currentTaskPlan?.id_task && !smallWindow}"
          (click)="$event.stopPropagation(); showActionTree('task', currentTaskPlan?.id_task, smallWindow)">
          {{currentTaskPlan?.description || "-"}}
        </div>
      </div>

      <div [ngClass]="{'qttMinutesExecuting' : true,
                     'qttMinutesSetup':  currentTaskPlan?.basicType == 'Setup',
                     'qttMinutesProduction':  currentTaskPlan?.basicType == 'Production',
                     'qttMinutesStop':  currentTaskPlan?.basicType == 'Stop'}"
        *ngIf="currentTaskPlan?.prodOrder && currentTaskPlan?.qttMinutesExecuting">
        <nb-icon icon="clock-outline" style="font-size: 1.4em;"></nb-icon>
        {{currentTaskPlan?.qttMinutesExecuting | minutesToHours}}
      </div>


    </div>

    <div *ngIf="currentTaskPlan?.prodOrder" class="T2GrayArea summaryPanel mainPanelItem">
      <div class="qttPrevista">
        <div class="taskDateCaptionInfo">Qtd prevista</div>
        <div class="taskDateCaptionInfoSmall">Qtd Prev</div>
        <div class="defaultSmallText">{{currentTaskPlan?.estimatedQty | number: "1.0-2"}}
          {{currentTaskPlan?.unit}}</div>
      </div>
      <div class="dateInfos" style="line-height: 0.5em;">
        <div class="taskDateInfo">
          <div class="taskDateCaptionInfo">Início</div>
          <div class="taskDateCaptionInfoSmall">Início</div>
          <div class="defaultSmallText">{{currentTaskPlan?.startDate | date: "dd/MM/yy HH:mm"}}</div>
        </div>
        <div class="taskDateInfo">
          <div class="taskDateCaptionInfo">Fim previsto</div>
          <div class="taskDateCaptionInfoSmall">Prev</div>
          <div class="defaultSmallText">{{currentTaskPlan?.estimatedEndDate | date: "dd/MM/yy HH:mm"}}</div>
        </div>
      </div>
    </div>

    <div *ngIf="currentTaskPlan?.prodOrder" class="T2GrayArea productContainer mainPanelItem">

      <div *ngFor="let prod of currentTaskPlan?.productList; let i = index" class="productLine">
        <div style="display: flex; gap: 3px; min-width: 0px;">
          <div style="font-size: 0.9em; font-weight: bold; width: 10px; text-align: center;">{{i + 1}}</div>
          <div style="flex: 1; white-space: nowrap; text-overflow: ellipsis; overflow: hidden;"
            [nbTooltip]="prod.description">
            {{prod.description}}
          </div>
        </div>
        <div>{{prod.quantity | number: "1.0-2" }} {{prod.unit}}</div>
      </div>
    </div>
  </div>

  <div *ngIf="false" class="mainPanel"
    style="display: flex; gap: 3px; background-color: rgb(237, 237, 237); color: #0e0e0e;">
    <div>Quantidades</div>
    <app-t2-evolution-index class="mainPanelItem" title="Evolução" [evolution]="currentTaskPlan?.evolution"/>
    <div>Indicadores</div>
  </div>

</div>
