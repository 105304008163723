import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, Subject, of } from "rxjs";
import { map, shareReplay, switchMap, take, takeUntil } from "rxjs/Operators";
import { T2HttpClientService } from "src/app/core/http/t2httpClient.service";
import { T2SecurityService } from "src/app/core/security/t2security.service";
import { T2FirebaseService } from "src/app/core/t2-firebase.service";
import { Warning } from "src/app/core/warning/model/warning";

@Injectable()
export class DeviceCacheService implements OnDestroy {

  private companyId: string;
  private appName: string = "companyFirebase";
  private unsubscribe = new Subject<void>();
  private deviceGrouplist: Array<{ groupId: string, description: string, deviceIdList: Array<string> }> = [];
  private deviceObservableList = new Array<{ deviceId: string, observable: Observable<DeviceCache> }>();
  selectedfilterTasksSubject = new BehaviorSubject<Array<{ description, checked }>>([]);

  constructor(private firebaseService: T2FirebaseService, private httpClient: T2HttpClientService, private secService: T2SecurityService) { }

  ngOnDestroy(): void {
    this.selectedfilterTasksSubject.complete();
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  public initialize() {
    return new Promise<void>((resolve, reject) => {
      if (this.deviceGrouplist.length) {
        resolve();
        return;
      }

      this.firebaseService.initialize(this.appName).then(() => {
        this.secService.loadCompanyId().then((compId) => {
          this.companyId = compId;

          this.firebaseService.signIn(this.appName, this.companyId).then(() => {
            this.loadDeviceGroupList().then(() => {
              resolve();
            }, error => reject(error))
          }, error => reject(error))
        }, error => reject(error))
      }, error => reject(error))
    });
  }

  public getDeviceData(deviceId: string): Observable<DeviceCache> {
    let devObservable = this.deviceObservableList.find(dev => dev.deviceId == deviceId);

    if (devObservable) {
      return devObservable.observable;
    }

    let path = this.httpClient.isHomologEnvironment ? "homologation/" : "production/";
    path += `device/${deviceId}`;

    let observable = this.firebaseService.getData(path)
      .pipe(takeUntil(this.unsubscribe),
        shareReplay(1),
        map(resp => {
          let device = new DeviceCache();
          if (resp) {
            device = resp;
            this.deviceGrouplist.find(dg => dg.groupId == device.deviceGroupId)?.deviceIdList.push(deviceId);

            device.taskList = device.taskList ?? [];

            if (!Array.isArray(device.taskList)) {
              device.taskList = [device.taskList];
            }

            if (device.multiTask) {
              device.taskList = device.taskList.filter(t => t.prodOrder);
            }

            device.taskList.forEach(task => {
              task.estimatedQty = Number(task.estimatedQty || 0).valueOf();
              task.consumption = Number(task.consumption || 0).valueOf();
              task.production = Number(task.production || 0).valueOf();
              task.startDate = task.startDate ? new Date(task.startDate) : undefined;
              task.estimatedEndDate = task.estimatedEndDate ? new Date(task.estimatedEndDate) : undefined;
              task.startDateDurationReg = task.startDateDurationReg ? new Date(task.startDateDurationReg) : undefined;

              if (task.time) {
                task.time.running = Number(task.time.running || 0).valueOf();
                task.time.setup = Number(task.time.setup || 0).valueOf();
                task.time.stopped = Number(task.time.stopped || 0).valueOf();
              }

              task.qtdList = task.qtdList ?? [];

              if (!Array.isArray(task.qtdList)) {
                task.qtdList = [task.qtdList];
              }

              task.qtdList.forEach(qtd => {
                qtd.value = Number(qtd.value || 0).valueOf();
              });

              if (task?.id_task) {
                this.loadAppointmentInfo(task)
                  .pipe(take(1))
                  .subscribe(r => {
                    task.evolution = r?.evolution;
                    task.appointment = r?.appointments;
                  });
              }


              task.productList = task.productList ?? [];

              if (!Array.isArray(task.productList)) {
                task.productList = [task.productList];
              }

              task.productList.forEach(prod => {
                prod.quantity = Number(prod.quantity || 0).valueOf();
              })
            });

            device.taskList.sort((a, b) => a.startDate.getTime() - b.startDate.getTime());

            return device;
          }
        }));

    this.deviceObservableList.push({ deviceId: deviceId, observable: observable });

    return observable;
  }

  public loadDeviceGroupList() {
    return new Promise<void>((resolve, reject) => {
      let path = this.httpClient.isHomologEnvironment ? "homologation/" : "production/";
      path += "deviceGroup";

      this.deviceGrouplist = [];

      this.firebaseService.getData(path, true).pipe(take(1)).subscribe(devGroupObj => {
        if (devGroupObj) {
          Object.keys(devGroupObj).forEach(key => {
            this.deviceGrouplist.push({ groupId: key, description: devGroupObj[key].description, deviceIdList: [] });
          });
        }
        this.deviceGrouplist?.sort((a, b) => {
          return a.description.localeCompare(b.description);
        });
        resolve();
      }, error => reject(error));
    })
  }

  public getDeviceGroupList() {
    return this.deviceGrouplist;
  }

  public getDeviceGroup(deviceGroupid: string) {
    return this.deviceGrouplist.find(dg => dg.groupId == deviceGroupid);
  }

  public unsubscribeDevice(deviceId: string) {
    let path = this.httpClient.isHomologEnvironment ? "homologation/" : "production/";
    path += `device/${deviceId}`;

    this.firebaseService.unsubscribeFromPath(path);

    let index = this.deviceObservableList.findIndex(dev => dev.deviceId == deviceId);

    if (index > -1) {
      this.deviceObservableList.splice(index, 1);
    }
  }

  public unsubscribeFromAllDevices() {
    this.deviceObservableList.forEach(obs => {
      let path = this.httpClient.isHomologEnvironment ? "homologation/" : "production/";
      path += `device/${obs.deviceId}`;

      this.firebaseService.unsubscribeFromPath(path);
    });

    this.deviceObservableList = [];
  }

  public getSelectedFilterTasks() {
    return this.selectedfilterTasksSubject.asObservable();
  }

  public setSelectedFilterTasks(selectedFilters: Array<{ description, checked }>) {
    this.selectedfilterTasksSubject.next(selectedFilters);
  }

  loadAppointmentInfo(task: DeviceCacheTask): Observable<{ evolution: EvolutionIndex, appointments: Array<Appointment> }> {
    if (!task.id_task) 
      return of({ evolution: undefined, appointments: [] });

    let evolution: EvolutionIndex = { prev: { run: 0.0, setup: 0.0 }, real: [], sumRun: 0.0, sumSetup: 0.0, sumStop: 0.0 };
    let appointments: Array<Appointment> = [];
    let real = [];
    let params = new Map<string, string>();
    params.set("id_task", task.id_task);

    return this.httpClient.get("bsn.production.prodOrder/getEstimatedTaskTime", params)
      .pipe(take(1), switchMap(resp => {
        if (!resp.estimatedTaskTime) return of({ evolution: undefined, appointments: [] });
        evolution.prev.run = Number(resp.estimatedTaskTime.estimatedRunDuration).valueOf();
        evolution.prev.setup = Number(resp.estimatedTaskTime.estimatedSetupDuration).valueOf();

        return this.httpClient.get("bsn.production.prodOrder/listaApontamentos", params)
          .pipe(take(1)
            , map(resp => {
              if (!resp.listaApontamentos) return;
              if (!Array.isArray(resp.listaApontamentos.apontamento)) {
                resp.listaApontamentos.apontamento = [resp.listaApontamentos.apontamento];
              }

              const appointmentList = resp.listaApontamentos.apontamento;
              appointmentList.forEach(a => {
                //fill appointment
                let appointment = new Appointment();
                appointment.registrationId = a.id_registration;
                appointment.taskProdQtyId = a.id_taskProd_quantity;
                appointment.description = a.operacao;
                appointment.endDate = a.fim ? new Date(a.fim) : null;
                appointment.startDate = a.inicio ? new Date(a.inicio) : null;
                appointment.insertUserName = a.operador;
                appointment.vlQuantity = +a.quantidade;
                appointment.unitSymbol = a.unitSymbol;
                appointment.taskProdType = a.taskProdType;
                appointment.taskProdId = a.id_taskprod;
                appointments.push(appointment);

                //fill evolution info
                if (!a.taskProdType || !a.hasDuration) return;
                let qttMinutes = 0;
                if (a.inicio && a.fim) qttMinutes = (new Date(a.fim).getTime() - new Date(a.inicio).getTime()) / (1000 * 60);
                if (a.inicio && !a.fim) qttMinutes = (new Date().getTime() - new Date(a.inicio).getTime()) / (1000 * 60);
                switch (a.taskProdType) {
                  case "Production":
                    evolution.sumRun += qttMinutes;
                    break;
                  case "Setup":
                    evolution.sumSetup += qttMinutes;
                    break;
                  case "Stop":
                    evolution.sumStop += qttMinutes;
                    break;
                };
                real.push({ operationType: a.taskProdType, duration: qttMinutes, operation: a.operacao });
              });
              evolution.real = real;
              return {
                evolution: evolution,
                appointments: appointments
              };
            }));
      }));
  }
}

export class Tasks {
  taskDescription: string;
  deviceName: string;
  sortKey: number;
  currentTask: boolean;
}

export class DeviceCache {
  deviceId: string;
  deviceGroupId: string;
  title: string;
  multiTask: boolean;
  companySiteId: string;
  taskList: Array<DeviceCacheTask>;
  errorOnUpdate?: boolean;
  warningList?: Array<Warning>;
}

export class DeviceCacheTask {
  id_ordemProd: string;
  id_task: string;
  id_taskPlan: string;
  description: string;
  prodOrder: string;
  prodOrderDescription: string;
  customerName: string;
  operation: string;
  basicType: 'Setup' | 'Production' | 'Stop';
  estimatedQty: number;
  consumption: number;
  production: number;
  startDate: Date;
  estimatedEndDate: Date;
  startDateDurationReg: Date;
  unit: string;
  time: { setup: number, running: number, stopped: number };
  qtdList: Array<{ title: string, type: string, unit: string, value: number }>;
  evolution: EvolutionIndex;
  appointment: Array<Appointment>;
  productList: Array<{ description: string, quantity: number, unit: string }>;
  qttMinutesExecuting?: number;
}

export class EvolutionIndex {
  prev: { run: number, setup: number };
  real: Array<{ operationType: string, duration: number }>;
  sumRun: number;
  sumSetup: number;
  sumStop: number
}

export class Appointment {
  registrationId: string;
  taskProdQtyId: string;
  index: number;
  startDate: Date;
  endDate: Date;
  description: string;
  vlQuantity: number;
  insertUserName: string;
  color?: string;
  unitSymbol: string;
  filterGroup?: string;
  id_report_etq?: string;
  sendToPrinter?: boolean;
  taskProdType?: string;
  taskProdId: string;
}

