export abstract class T2Exception {
    id: string;
    className: string;
    message: string;

    constructor(id: string, className: string, message: string) {
        this.id = id;
        this.className = className;
        this.message = message;
    }

}

export class T2CoreException extends T2Exception {}

export class T2BsnException extends T2Exception {}
