<div style="display: flex; gap: 20px; flex-direction: column;">
  <div *ngFor="let group of groupList" class="flexColumn" style="gap: 5px">

    <div class="bannerLayoutTitle">{{group.description}}</div>

    <div class="container">
      <div *ngFor="let maq of group.deviceList" class="singleTaskDevice">

        <app-task-float-actions *ngIf="!maq.taskList?.length" id="editButtons" [device]="maq" [taskPlan]="undefined"
          (deviceSelected)="selectDevice($event);" />

        <div *ngIf="!maq.multiTask" class="T2GrayArea mainPanel buttonHolder">

          <app-task-float-actions id="editButtons" [device]="maq"
            [taskPlan]="maq.taskList.length ? maq.taskList[0] : undefined" (deviceSelected)="selectDevice($event);" />

          <div class="headerPanel">
            <div class="flexColumn" style="gap: 5px; width: 100%;">
              <div class="flexRow"
                (click)="$event.stopPropagation(); showActionTree('device', maq.deviceId, smallWindow)">
                <nb-icon *ngIf="maq.errorOnUpdate" icon="alert-triangle-outline"></nb-icon>
                <div *ngIf="maq.warningList?.length" class="deviceWarning"
                  (click)="$event.stopPropagation(); openWarning(maq.warningList)">
                  <nb-icon icon="bell" status="danger"></nb-icon>
                </div>
                <div class="deviceTitle">{{maq.title}}</div>
              </div>

              <div class="productContainer">
                <div *ngFor="let prod of maq.taskList[0]?.productList; let i = index" class="productLine">
                  <div style="font-size: 0.9em; font-weight: bold; width: 10px;">{{i + 1}}</div>
                  <div style="white-space: nowrap; text-overflow: ellipsis; overflow: hidden;width: 170px;"
                    [nbTooltip]="prod.description">{{prod.description}}</div>
                  <div>{{prod.quantity | number: "1.0-2" }} {{prod.unit}}</div>
                </div>
              </div>
            </div>

            <div [ngClass]="{'defaultStatusBG': true,
                            'productionStatusBG': maq.taskList[0]?.basicType == 'Production',
                            'setupStatusBG': maq.taskList[0]?.basicType == 'Setup',
                            'stopStatusBG': maq.taskList[0]?.basicType == 'Stop'}">
              <div style="display: flex; width: 100%; justify-content: space-around; align-items: center;">
                <div style="font-size: 1.3em; font-weight: bold; color: #f5f5f5;"
                  (click)="$event.stopPropagation(); showActionTree('op', maq.taskList[0]?.id_ordemProd, smallWindow)"
                  [ngClass]="{'prodOrderInfo': true,
                                'prodOrderInfoH': maq.taskList[0]?.id_ordemProd && !smallWindow}">
                  {{maq.taskList[0]?.prodOrder || "Sem OP"}}</div>
                <div [ngClass]="{'qttMinutesExecuting' : true,
                                      'qttMinutesSetup':  maq.taskList[0]?.basicType == 'Setup',
                                    'qttMinutesProduction':  maq.taskList[0]?.basicType == 'Production',
                                  'qttMinutesStop':  maq.taskList[0]?.basicType == 'Stop'}"
                  *ngIf="maq.taskList[0]?.prodOrder && maq.taskList[0]?.qttMinutesExecuting">
                  <nb-icon icon="clock-outline" style="font-size: 1.4em;"></nb-icon>
                  {{maq.taskList[0]?.qttMinutesExecuting | minutesToHours}}
                </div>
              </div>
              <div class="flexColumn" style="align-items: center; text-align: center; line-height: 0.9rem;">
                <div class="taskOperation" style="width: 155px; font-weight: bold"
                  [nbTooltip]="maq.taskList[0]?.operation">
                  {{maq.taskList[0]?.operation || "-"}}
                </div>
                <div (click)="$event.stopPropagation(); showActionTree('task', maq.taskList[0]?.id_task, smallWindow)"
                  [ngClass]="{'taskDescription' : true,
                                  'taskDescriptionH' : maq.taskList[0]?.id_task && !smallWindow}">
                  {{maq.taskList[0]?.description || "-"}}
                </div>
              </div>
            </div>
          </div>

          <div *ngIf="maq.taskList[0]?.prodOrder" class="T2GrayArea summaryPanel">
            <div class="qttPrevista">
              <div class="taskDateCaptionInfo">Quantidade prevista</div>
              <div class="taskDateCaptionInfoSmall">Qtd Prev</div>
              <div class="defaultSmallText">{{maq.taskList[0]?.estimatedQty | number: "1.0-2"}}
                {{maq.taskList[0].unit}}</div>
            </div>
            <div class="dateInfos" style="line-height: 0.5em;">
              <div class="taskDateInfo">
                <div class="taskDateCaptionInfo">Início</div>
                <div class="taskDateCaptionInfoSmall">Início</div>
                <div class="defaultSmallText">{{maq.taskList[0]?.startDate | date: "dd/MM/yy HH:mm"}}</div>
              </div>
              <div class="taskDateInfo">
                <div class="taskDateCaptionInfo">Fim previsto</div>
                <div class="taskDateCaptionInfoSmall">Prev</div>
                <div class="defaultSmallText">{{maq.taskList[0]?.estimatedEndDate | date: "dd/MM/yy HH:mm"}}</div>
              </div>
            </div>
          </div>

          <nb-flip-card *ngIf="maq.taskList[0]?.prodOrder"
            style="min-height: 165px; max-height: 165px; height: 165px; overflow: hidden;">
            <nb-card-front>
              <nb-card>
                <nb-card-body style="padding: 0px;">
                  <div class="indexPanel">
                    <div class="quantityInfo">
                      <div>Quantidades</div>
                      <div *ngFor="let item of maq.taskList[0]?.qtdList" class="quantityItemInfo"
                        [ngClass]="{'qtdProduction': item.type == 'production', 'qtdScrap': item.type == 'scrap', 'qtdDefault': item.type != 'production' && item.type != 'scrap'}">
                        <div style="text-overflow: ellipsis; white-space: nowrap; overflow: hidden;"
                          [nbTooltip]="item.title">{{item.title}}</div>
                        <div style="text-align: end; text-wrap: nowrap">{{item.value | number: "1.0-2"}} {{item.unit}}
                        </div>
                      </div>

                    </div>

                    <div class="evolutionInfo">
                      <app-t2-evolution-index title="Evolução"
                        [evolution]="maq.taskList[0]?.evolution"></app-t2-evolution-index>

                      <div class="evolutionCharts">
                        <app-t2-progress-index [progress]="maq.taskList[0]?.consumption || 0.0" color="green"
                          title="Consumo" [ngStyle]="{'width': smallWindow ? '100%' : '80px'}" style="height: 100%;" />
                        <app-t2-progress-index [progress]="maq.taskList[0]?.production || 0.0" color="yellow"
                          title="Produção" [ngStyle]="{'width': smallWindow ? '100%' : '80px'}" style="height: 100%;" />
                        <!-- <app-t2-pizza-index [values]="[
                                        {name: 'Setup', value: maq.taskList[0]?.time?.setup || 0.0 , color: 'yellow'},
                                        {name: 'Produzindo', value: maq.taskList[0]?.time?.running || 0.0, color: 'green'},
                                        { name: 'Parada', value: maq.taskList[0]?.time?.stopped || 0.0, color: 'red'}]"
                          title="Duração" [ngStyle]="{'width': smallWindow ? '100%' : '80px'}" style="height: 100%;" /> -->
                      </div>
                    </div>

                  </div>
                </nb-card-body>
              </nb-card>
            </nb-card-front>
            <nb-card-back>
              <nb-card>
                <nb-card-body style="padding: 0px;">
                  <div class="indexPanel" style="height: 165px;">
                    Outros detalhes...
                    - Tarefas seguintes
                    - Materiais separados (%)
                    - Pedidos de venda
                    - Data de entrega
                    - Etc
                  </div>
                </nb-card-body>
              </nb-card>
            </nb-card-back>
          </nb-flip-card>


        </div>

        <div *ngIf="maq.multiTask" class="T2GrayArea mainPanel">

          <div class="headerPanel">
            <div class="deviceTitle"
              (click)="$event.stopPropagation(); showActionTree('device', maq.deviceId, smallWindow)">
              <nb-icon *ngIf="maq.errorOnUpdate" icon="alert-triangle-outline"></nb-icon>
              <div *ngIf="maq.warningList?.length" class="deviceWarning"
                (click)="$event.stopPropagation(); openWarning(maq.warningList)">
                <nb-icon icon="bell" status="danger"></nb-icon>
              </div>
              {{maq.title}}
            </div>
            <div class="deviceTaskCount">{{maq.taskList?.length == 0 ? 'Nenhuma atividade em produção' :
              (maq.taskList.length == 1 ? 'Uma atividade em produção' : maq.taskList.length + ' atividades em
              produção')}}</div>
          </div>

          <div class="flexColumn" style="gap: 5px; overflow-y: auto;">
            <div *ngFor="let task of maq.taskList; let i = index" [ngClass]="{'defaultStatusBGTransp': true,
                            'productionStatusBGTransp': task.basicType == 'Production',
                            'setupStatusBGTransp': task.basicType == 'Setup',
                            'stopStatusBGTransp': task.basicType == 'Stop'
                           }" class="buttonHolderMult">

              <app-task-float-actions id="editButtons" [device]="maq" [taskPlan]="task"
                (deviceSelected)="selectDevice($event);" />

              <div class="multiTaskCount">{{i+1}}</div>

              <div class="flexColumn" style="width: 100%; height: 100%; gap: 5px;">

                <div class="flexColumn" style="justify-content: space-between; gap: 5px;">

                  <div class="multiTaskHeaderPanel" [ngClass]="{'defaultStatusBG': true,
                            'productionStatusBG': task.basicType == 'Production',
                            'setupStatusBG': task.basicType == 'Setup',
                            'stopStatusBG': task.basicType == 'Stop'}">
                    <div
                      style="display: flex; justify-content: space-around; align-items: center; gap: 1px; padding: 3px; width: 100%;">
                      <div class="prodOrderClock">
                        {{task.prodOrder || "Sem OP"}}
                        <div style="font-size: 0.5em;" [ngClass]="{'qttMinutesExecuting' : true,
                                                              'qttMinutesSetup':  task?.basicType == 'Setup',
                                                            'qttMinutesProduction':  task?.basicType == 'Production',
                                                          'qttMinutesStop':  task?.basicType == 'Stop'}"
                          *ngIf="task?.prodOrder && task?.qttMinutesExecuting">
                          <nb-icon icon="clock-outline" style="font-size: 1.4em;"></nb-icon>
                          {{task?.qttMinutesExecuting | minutesToHours}}
                        </div>
                      </div>

                      <div class="flexColumn" style="align-items: center; text-align: center;">
                        <div style="font-size: 1.2em; font-weight: bold;color: #f5f5f5;">
                          {{task.operation || "-"}}
                        </div>
                        <div style="font-size: 0.9em; line-height: 0.9em; color: #f5f5f5;">
                          {{task.description|| "-"}}
                        </div>
                      </div>
                    </div>
                    <div style="display: flex; width: 100%; justify-content: space-between;">
                      <div class="multiTaskDateItem" style="display: flex; flex-direction:column;">
                        <div class="multiTaskOpInfo" [nbTooltip]="task.customerName">{{task.customerName}}</div>
                        <div class="multiTaskOpInfo" [nbTooltip]="task.prodOrderDescription">
                          {{task.prodOrderDescription}}
                        </div>
                      </div>
                      <div style="display: flex; flex-direction:column;">
                        <div class="multiTaskDateItem">
                          <div class="multiTaskDateTitle">Início</div>
                          <div class="multiTaskDate">{{task.startDate | date: "dd/MM/yy HH:mm"}}</div>
                        </div>
                        <div *ngIf="task.estimatedEndDate" class="multiTaskDateItem">
                          <div class="multiTaskDateTitle">Fim Previsto</div>
                          <div class="multiTaskDate">{{task.estimatedEndDate | date: "dd/MM/yy HH:mm"}}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div *ngIf="task?.evolution else noEvolutionTemplate" class="multiTaskEvolution">
                  <app-t2-evolution-index [evolution]="task?.evolution"></app-t2-evolution-index>
                </div>
                <ng-template #noEvolutionTemplate>
                  <div class="flexRow multiTaskEvolution evolutionLabel" style="gap: 5px;">
                    <div style="font-size: 0.9em; font-weight: bold;">Evolução</div>
                    <div class="defaultSmallText">Não há quantidade estimada nesse planejamento</div>
                  </div>
                </ng-template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>