import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from "ag-grid-angular";
import { ICellRendererParams } from "ag-grid-community";
import { start } from "repl";

@Component({
  selector: 'app-task-startdate-cell-renderer',
  templateUrl: './task-startdate-cell-renderer.component.html',
  styleUrls: ['./task-startdate-cell-renderer.component.scss']
})
export class TaskStartdateCellRendererComponent implements OnInit, ICellRendererAngularComp {

  startDate: Date;
  maxDate: Date;
  minDate: Date;
  date: 'afterMax' | 'beforeMin' | 'onTime' = undefined;

  constructor() { }

  ngOnInit(): void {
  }

  agInit(params: ICellRendererParams): void {
    this.startDate = new Date(params.data?.startDate);
    this.maxDate = new Date(params.data?.maxDate);
    if (this.startDate && this.maxDate && (this.startDate.getTime() > this.maxDate.getTime())) {
      this.date = 'afterMax';
    } else if (this.startDate && this.minDate && (this.startDate.getTime() < this.maxDate.getTime())) {
      this.date = 'beforeMin';
    } else if (this.startDate && this.maxDate && (this.startDate.getTime() < this.maxDate.getTime())) {
      this.date = 'onTime';
    }
  }

  refresh(params: ICellRendererParams): boolean {
    return true;
  }

}
