<nb-card>
  <nb-card-body>
    <p><strong>Ajuda e suporte</strong></p>

    <div style="height: 60vh; font-size: 80%;">

      <div class="helpContent">

        <div> Dcumentação </div>

        <div> Vídeos </div>

        <div> Chamados </div>

        <div> Contato </div>
      </div>
    </div>
  </nb-card-body>
</nb-card>
