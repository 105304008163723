import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from "@angular/forms";
import { NbDialogRef } from "@nebular/theme";
import { take } from "rxjs/Operators";
import { T2TreeGridInputComponent } from "src/app/core/cmp/ui/t2-tree-grid-input/t2-tree-grid-input.component";
import { ViewTemplateComponent } from "src/app/core/cmp/view-template/view-template.component";
import { Dataset } from "src/app/core/dataset/model/dataset";
import { T2DatasetService } from "src/app/core/dataset/t2dataset.service";
import { FlxProductSpecificationService, StructureLayer } from "../flx-product-specification.service";

@Component({
  selector: 'app-eppstructure-layer-edit',
  templateUrl: './eppstructure-layer-edit.component.html',
  styleUrls: ['./eppstructure-layer-edit.component.scss']
})
export class EPPStructureLayerEditComponent implements OnInit {

  @ViewChild("viewTemplate", { static: true }) viewTemplate: ViewTemplateComponent;
  @ViewChild("chemicalTreeGrid") chemicalTreeGrid: T2TreeGridInputComponent;
  @ViewChild("substrateTreeGrid") substrateTreeGrid: T2TreeGridInputComponent;

  @Input() title: string;

  private dsChemical: Dataset;
  public layerType: "CHEMICAL" | "SUBSTRATE" | "METALIZATION" | "EXTRUSION";
  public structureLayerList: Array<StructureLayer>;
  public structureLayerEdit: StructureLayer;
  public substrateList: Array<{ id: string, description: string }>;
  public formGroup: FormGroup;
  public chemicalTypeList: Array<{ id: string, description: string }>;
  public chemicalSideList: Array<{ id: string, description: string }>;
  public chemicalPrintList: Array<{ id: string, description: string }>;
  public loaded: boolean = false;

  constructor(public dialogRef: NbDialogRef<EPPStructureLayerEditComponent>, private formBuilder: FormBuilder, private dsService: T2DatasetService,
     private flxEppService: FlxProductSpecificationService) {
  }

  ngOnInit(): void {
    this.loaded = false;
    this.formGroup = this.formBuilder.group({});
    this.formGroup.addControl("id_especif_carac_estr_i_subst", this.formBuilder.control(this.structureLayerEdit?.chemical?.id_especif_carac_estr_i_subst)); // Substrato
    this.formGroup.addControl("tipo", this.formBuilder.control(this.structureLayerEdit?.chemical?.chemicalType));
    this.formGroup.addControl("lado", this.formBuilder.control(this.structureLayerEdit?.chemical?.side));
    this.formGroup.addControl("impressao", this.formBuilder.control(this.structureLayerEdit?.chemical?.printing));
    this.formGroup.addControl("gramatura", this.formBuilder.control(this.structureLayerEdit?.grammage || 0));
    this.formGroup.addControl("gramMinima", this.formBuilder.control(this.structureLayerEdit?.minGrammage || 0));
    this.formGroup.addControl("gramMaxima", this.formBuilder.control(this.structureLayerEdit?.maxGrammage || 0));
    this.formGroup.addControl("id_quimico_3", this.formBuilder.control(this.structureLayerEdit?.chemical?.id_quimico_3)); // Familia de Quimicos
    this.formGroup.addControl("tempoCura", this.formBuilder.control(this.structureLayerEdit?.chemical?.waitingTime || 0));
    this.formGroup.addControl("id_item_3", this.formBuilder.control(this.structureLayerEdit?.substrate?.id_item_3)); // Tipo de Substrato

    this.substrateList = this.structureLayerList.filter(s => s.layerType == "SUBSTRATE").map(s => {
      return {
        id: s.id_especif_carac_estr_item,
        description: s.description
      }
    });

    if (this.structureLayerEdit) {
      this.layerType = this.structureLayerEdit.layerType;
    }

    this.dsService.getDataset("flx_especif_carac_estr_i_quim").pipe(take(1)).subscribe(ds => {
      this.dsChemical = ds;

      this.chemicalTypeList = [];
      this.chemicalSideList = [];
      this.chemicalPrintList = [];

      this.dsChemical.propertyList.find(p => p.propertyName == "tipo").fixedComboList?.forEach(item => {
        this.chemicalTypeList.push({ id: item.description, description: item.description });
      });

      this.dsChemical.propertyList.find(p => p.propertyName == "lado").fixedComboList?.forEach(item => {
        this.chemicalSideList.push({ id: item.description, description: item.description });
      });

      this.dsChemical.propertyList.find(p => p.propertyName == "impressao").fixedComboList?.forEach(item => {
        this.chemicalPrintList.push({ id: item.description, description: item.description });
      });

      this.loaded = true;
    }, error => {
      this.loaded = true;
    });
  }

  changeLayerType(layerType: "CHEMICAL" | "SUBSTRATE" | "METALIZATION" | "EXTRUSION") {
    this.layerType = layerType;
  }
}
