import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/Operators";
import { DialogTemplateComponent } from "src/app/core/cmp/dialog-template/dialog-template.component";
import { T2SelectComponent } from "src/app/core/cmp/ui/t2-select/t2-select.component";
import { T2ViewTemplateData } from "src/app/core/cmp/view-template/model/t2-view-template-data";
import { LayoutType, ViewTemplateElement } from "src/app/core/cmp/view-template/model/view-template-element";
import { WorkScheduleService } from "../../../ppc/work-schedule/work-schedule.service";

@Component({
  selector: 'app-work-schedule-device-selection',
  templateUrl: './work-schedule-device-selection.component.html',
  styleUrls: ['./work-schedule-device-selection.component.scss']
})
export class WorkScheduleDeviceSelectionComponent implements OnInit, OnDestroy {

  @ViewChild('dialog', { static: true }) dialog: DialogTemplateComponent;

  public deviceList: Array<{ id: string, description: string }>;
  public workScheduleList = new Array<{ id: string, description: string }>();
  public workScheduleDeviceMap: Map<string, string>;
  public loaded: boolean = false;

  public formGroup: FormGroup;
  public layout: Array<ViewTemplateElement> = [
    {
      layoutType: LayoutType.listLayout,
      direction: "column",
      children: [
        {
          layoutType: LayoutType.gridLayout,
          children: [
            {
              layoutType: LayoutType.component,
              cmpName: "workScheduleAll",
              cmpType: T2SelectComponent,
              title: "Definir para todos os dispositivos",
              isBaseComponent: true,
              columnSpan: 3
            }
          ]
        },
        {
          layoutType: LayoutType.gridLayout,
          children: []
        }
      ]
    }
  ];

  public data: Array<T2ViewTemplateData> = [];

  private unsubscribe = new Subject<void>();

  constructor(private workScheduleService: WorkScheduleService, private formBuilder: FormBuilder) { }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  ngOnInit(): void {
    this.formGroup = this.formBuilder.group({});
    this.formGroup.addControl("workScheduleAll", this.formBuilder.control(undefined));

    this.formGroup.get("workScheduleAll").valueChanges
    .pipe(takeUntil(this.unsubscribe))
    .subscribe(value => {
      if (value) {
        this.deviceList.forEach(dev => {
          this.formGroup.get(dev.description).setValue(value);
        })
      }
    })

    this.workScheduleService.getWorkScheduleList().then(wsList => {
      this.workScheduleList = wsList.map(ws => {
        return {
          id: ws.id,
          description: ws.description
        }
      });

      this.data.push({ cmpName: "workScheduleAll", properties: { list: this.workScheduleList } });

      let layoutCmp = this.layout[0].children[1].children;

      this.deviceList.forEach(dev => {
        layoutCmp.push({
          layoutType: LayoutType.component,
          cmpName: dev.description,
          title: dev.description,
          cmpType: T2SelectComponent,
          isBaseComponent: true,
          columnSpan: 2,
        });

        this.formGroup.addControl(dev.description, this.formBuilder.control(this.workScheduleDeviceMap.get(dev.id), Validators.required));
        this.data.push({ cmpName: dev.description, properties: { list: this.workScheduleList } });
      });

      this.loaded = true;
    });
  }

  closeDialog(action: string) {
    if (action == "Cancela") {
      this.dialog.close(undefined);
    } else if (action == "Confirma") {
      this.deviceList.forEach(dev => {
        this.workScheduleDeviceMap.set(dev.id, this.formGroup.get(dev.description).value);
      });

      this.dialog.close(this.workScheduleDeviceMap);
    }
  }

}
