import { Component } from '@angular/core';

@Component({
  selector: 'app-shop-floor-reprint-labels',
  templateUrl: './shop-floor-reprint-labels.component.html',
  styleUrls: ['./shop-floor-reprint-labels.component.scss']
})
export class ShopFloorReprintLabelsComponent {

}
