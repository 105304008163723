import { Component, OnInit, Input } from '@angular/core';
import { NbDialogRef } from "@nebular/theme";
import { EditCylinderComponent } from "../edit-cylinder/edit-cylinder.component";

@Component({
  selector: 'app-add-cylinder',
  templateUrl: './add-cylinder.component.html',
  styleUrls: ['./add-cylinder.component.scss']
})
export class AddCylinderComponent implements OnInit {

  quimicoLista: Array<any> = [];

  selectedItem = null;

  constructor(public dialogRef: NbDialogRef<EditCylinderComponent>) { }

  ngOnInit(): void {
  }

  closeWindow(confirm: boolean) {

    const result = { confirm: confirm, color: null };

    if (confirm) {
      result.color = {
        id_ordemprod: this.selectedItem.id_ordemprod,
        id_especif_carac_quimico_item: this.selectedItem.id_especif_carac_quimico_item
      };
    }

    this.dialogRef.close(result);
  }
}
