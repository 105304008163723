<app-form-template #formTemplate formTitle="Registros de produção" [loaded]="loaded">
  <header>
    <div style="display: flex; gap: 10px">
      <app-widget-selection #deviceComponent placeholder="Selecione o dispositivo..."
        (widgetChange)="deviceListChange($event)" [multiple]="false">
      </app-widget-selection>

      <app-t2-date t2IdCmp="startDate" [formGroup]="formGroup" [visible]="true" [readOnly]="false" style="max-width: 150px">
      </app-t2-date>

      <app-t2-date t2IdCmp="endDate" [formGroup]="formGroup" [visible]="true" [readOnly]="false" style="max-width: 150px">
      </app-t2-date>

      <button nbButton size="tiny" status="primary" (click)="getListRegistrations()"
        [nbSpinner]="loadingData" nbSpinnerStatus="basic" nbSpinnerSize="tiny" [disabled]="!id_device">
        <nb-icon icon="refresh-outline"></nb-icon>
      </button>

      <button nbButton size="tiny" status="primary" [disabled]="!button" (click)="editClick()" style="width: 100px;">
        Editar
      </button>
    </div>
  </header>
  <content>
    <app-t2grid #gridReg id="gridReg" [sortable]="false" [loading]="loadingData" (rowDoubleClick)="rowDoubleClick($event)"
      (rowClick)="rowSelected($event)">
    </app-t2grid>
  </content>
</app-form-template>
