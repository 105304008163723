import { DeviceRegistrationInputType } from "./device-registration-input-type";
import { DeviceRegistrationStopReason } from "./device-registration-stop-reason";
import { RegistrationData } from "./registration-data";

export class DeviceRegistration {
  id_device: string;
  id_task: string;
  id_taskPlan: string;
  id_operationGroup_op: string;
  manByTimeIntervalMot: string;
  inputs: Array<DeviceRegistrationInputType>;
  data: Array<RegistrationData>;
  stopReason: Array<DeviceRegistrationStopReason>;
}
