import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { DialogTemplateComponent } from "../dialog-template/dialog-template.component";

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss']
})
export class DialogComponent implements OnInit {

  public title: string;
  @Input() topMessage: string;
  public message: string;
  public message2: string;
  public message3: string;
  public actions: Array<{ description: string, status?: string, closes?: boolean }>;

  @ViewChild('dialog', { static: true }) dialog: DialogTemplateComponent;

  constructor() { }

  ngOnInit(): void {
  }

  closeDialog(action: { description: string, status?: string, closes?: boolean }) {
    this.dialog.close(action);
  }

}
