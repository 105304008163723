export class CilindroCor {
  updating = false;

  id_productSpecification: string;
  id_task_plan: string;
  id_especif_carac_quimico_item: string;
  seq: number;
  gram: number;
  area: number;
  aplicGram: number;
  color: string;
  detail: string;
  solid: number;
  cylinderCode: string;
  id_item_cylinder: string;
  status: string;
  id_ordemProd_cilindro: string;
  supplier: string;
  invoice: string;
  sendToInvoice: boolean;
  seqCorIgual: number;
  id_grupoQuimico?: string;
}
