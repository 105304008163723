import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { take } from "rxjs/Operators";
import { FormTemplateComponent } from "src/app/core/cmp/form-template/form-template.component";
import { T2HttpClientService } from "src/app/core/http/t2httpClient.service";
import { T2Route } from "src/app/core/http/t2route";
import { T2SecurityService } from "src/app/core/security/t2security.service";

@Component({
  selector: 'app-stock-detail-form',
  templateUrl: './stock-detail-form.component.html',
  styleUrls: ['./stock-detail-form.component.scss']
})
export class StockDetailFormComponent implements OnInit {
  private t2Route: T2Route;
  public loaded = false;
  public itemDescr: string;

  private _itemId: string = null;
  @Input()
  get itemId(): string { return this._itemId; }
  set itemId(value: string) { this._itemId = value; }

  @Input()
  get id(): string { return this._itemId; }
  set id(value: string) { this._itemId = value; }

  @ViewChild("formTemplate", { static: true }) formTemplate: FormTemplateComponent;

  constructor(private route: ActivatedRoute, private httpClient: T2HttpClientService, private sec: T2SecurityService) { }

  ngOnInit(): void {

    this.sec.accessLoaded()
      .pipe(
        take(1)
      ).subscribe(() => {
        if (this.formTemplate.validateAccess(['zD20210422H154211923R000000043'], 'dashboard')) {
          this.t2Route = this.httpClient.getT2RouteFromSnapshot(this.route.snapshot);
          this.itemId = this.itemId || this.t2Route.queryParams.get("id");
        }
      });
  }

  onLoadedChange(value: { descr: string, loaded: boolean }): void {
    this.itemDescr = value.descr;
    this.loaded = value.loaded;
  }

}
