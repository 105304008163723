import { ValueRenderCellComponent } from './../value-render-cell/value-render-cell.component';
import { ValueEditorCellComponent } from './../value-editor-cell/value-editor-cell.component';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { take } from "rxjs/Operators";
import { DialogTemplateComponent } from "src/app/core/cmp/dialog-template/dialog-template.component";
import { T2gridComponent } from "src/app/core/cmp/t2grid/t2grid.component";
import { T2HttpClientService } from "src/app/core/http/t2httpClient.service";
import { RegistrationFixData } from "../model/RegistrationFixData";

@Component({
  selector: 'app-dialog-registration-fix',
  templateUrl: './dialog-registration-fix.component.html',
  styleUrls: ['./dialog-registration-fix.component.scss']
})
export class DialogRegistrationFixComponent implements OnInit {

  @Input() title: string;
  @Input() id_registration: string;

  @ViewChild('dialog', { static: true }) dialog: DialogTemplateComponent;
  @ViewChild("gridRegData", { static: true }) gridRegData: T2gridComponent;

  regListData: Array<RegistrationFixData> = [];
  regDataGridComponents = {
    valueEditorCell: ValueEditorCellComponent,
    valueCellRender: ValueRenderCellComponent
  };

  updating = false;

  constructor(
    private httpClient: T2HttpClientService,
  ) { }

  ngOnInit(): void {
    this.getRegistrationData();
    this.gridRegData.component = this;
    this.setGridColumnsPriceTable(null);
  }

  getRegistrationData() {

    this.httpClient.get(`production.registrationFix/registrations/${this.id_registration}/data`, null)
      .pipe(take(1))
      .subscribe(resp => {

        if (!resp.regDataList) {
          resp.regDataList = []
        } else if (!Array.isArray(resp.regDataList)) {
          resp.regDataList = [resp.regDataList];
        }

        resp.regDataList.forEach((data: any) => {
          data.ID = data.id_registration_data;

          if (data.contentType === 'datetime' && data.propValue) {
            data.propValue = data.propValue + "T00:00:00.000-03:00";
          }
          data.propValueInitial = data.propValue;
        });

        this.regListData = resp.regDataList;

        this.gridRegData.setGridData(this.regListData, null);
        // this.gridRegData.autoSizeAllColumns(false);
      });
  }

  private setGridColumnsPriceTable(customColumns: any[]): void {

    const fixedColumns = [
      { headerName: "Propriedade", field: "description" },
      { headerName: "Valor", field: "propValue", minWidth: 150, editable: true, cellRenderer: "valueCellRender", cellEditor: "valueEditorCell", onCellValueChanged: this.onPropValueChange, },
      { headerName: "Unidade", field: "un" },
      { headerName: "Referência", field: "reference" },
      { headerName: "Valor min", field: "propMinValue" },
      { headerName: "Valor max", field: "propMaxValue" },
      { headerName: "Descrição", field: "helpText" },
    ];

    this.gridRegData.setGridColumnDefs(fixedColumns);
  }

  private onPropValueChange(event) {
    // const tela: PriceTableComponent = event.context.component;
    // tela.changed = tela.getChangedRows().length > 0;
  }

  public alterRegData() {

    const body = this.regListData
      .filter((data: any) => data.propValue != data.propValueInitial)
      .map(data => {
        return {
          id_registration_data: data.id_registration_data,
          propValue: data.contentType === 'datetime' ? data.propValue.substring(0, 10) : data.propValue
        };
      });

    this.updating = true;
    this.httpClient.put('production.registrationFix/updateRegistrationsData', null, body)
      .pipe(take(1))
      .subscribe(resp => {
        this.updating = false;
      }, error => {
        this.updating = false;
      });
  }
}
