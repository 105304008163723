import { Component, Input, OnDestroy, OnInit, Optional, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { NbDialogRef } from "@nebular/theme";
import { CellValueChangedEvent } from "ag-grid-community";
import { Subject } from "rxjs";
import { take, takeUntil } from "rxjs/Operators";
import { FormTemplateComponent } from "src/app/core/cmp/form-template/form-template.component";
import { T2gridComponent } from "src/app/core/cmp/t2grid/t2grid.component";
import { T2HttpClientService } from "src/app/core/http/t2httpClient.service";
import { T2Route } from "src/app/core/http/t2route";
import { T2SecurityService } from "src/app/core/security/t2security.service";
import { T2MessageService } from "src/app/core/t2-message.service";
import { OE } from "./model/oe";
import { OEI } from "./model/oei";
import { OEIs } from "./model/oeis";
import { OEIV } from "./model/oeiv";
import { OEIVs } from "./model/oeivs";
import { StockOperation } from "./model/stock-operation";

@Component({
  selector: 'app-stock-operation',
  templateUrl: './stock-operation.component.html',
  styleUrls: ['./stock-operation.component.scss']
})
export class StockOperationComponent implements OnInit, OnDestroy {

  public loaded: boolean = false;
  public stockOperationList: Array<StockOperation>;
  public operationList: Array<{ id: string, description: string }>;
  public formGroup: FormGroup;
  public referenceDatasetId: string;
  public referenceDatasetName: string;
  public referenceAggCondPropId: string;
  private unsubscribe = new Subject<void>();
  private t2Route: T2Route;
  private itemList = new Array<any>();
  private volumeList = new Array<any>();
  public loadingDataItem: boolean = false;
  public loadingDataVolume: boolean = false;
  public lockScreen: boolean = false;

  @ViewChild('formTemplate', { static: true }) formTemplate: FormTemplateComponent;
  @ViewChild('gridItens', { static: true }) gridItens: T2gridComponent;
  @ViewChild('gridVolumes', { static: true }) gridVolumes: T2gridComponent;

  @Input() operationType: string; // "Entrada" | "Saida" | "Transferencia"
  @Input() operationId: string;
  @Input() reference: string;
  @Input() referenceType: string;
  @Input() disabledChanges: boolean = false;
  @Input() inDialog: boolean = false;

  constructor(private sec: T2SecurityService,
    private httpClient: T2HttpClientService,
    private messageService: T2MessageService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    @Optional() private dialogRef: NbDialogRef<any>) { }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  ngOnInit(): void {
    this.sec.accessLoaded()
      .pipe(take(1))
      .subscribe(() => {
        if (this.formTemplate.validateAccess(['{B67F64D5-9308-4609-A75B-FA60794DBAD8}'], 'full')) {
          this.t2Route = this.httpClient.getT2RouteFromSnapshot(this.route.snapshot);

          this.operationType = this.operationType || this.t2Route.queryParams.get("operationType");
          this.operationId = this.operationId || this.t2Route.queryParams.get("operationId");
          this.reference = this.reference || this.t2Route.queryParams.get("reference");
          this.referenceType = this.referenceType || this.t2Route.queryParams.get("referenceType");
          this.referenceDatasetId = this.referenceDatasetId || this.t2Route.queryParams.get("referenceDatasetId");
          this.referenceDatasetName = this.referenceDatasetName || this.t2Route.queryParams.get("referenceDatasetName");
          this.referenceAggCondPropId = this.referenceAggCondPropId || this.t2Route.queryParams.get("referenceAggCondPropId");
          this.disabledChanges = this.disabledChanges || this.t2Route.queryParams.get("disabledChanges")?.toLowerCase() == "true";

          this.formGroup = this.formBuilder.group({});
          this.formGroup.addControl("stockOperationType", this.formBuilder.control(this.operationId, Validators.required));
          this.formGroup.addControl("reference", this.formBuilder.control(this.reference));

          this.formGroup.controls["stockOperationType"].valueChanges
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(id => {
              this.reference = undefined;
              let ref = this.stockOperationList.find(sot => sot.id_operationType == id)?.reference;

              this.loadReference(ref);

              this.gridVolumes.setGridData([], null);
              this.gridItens.setGridData([], null);

              this.configGrid();

              this.formGroup.controls["reference"].setValue(undefined, { emitEvent: false });
            });

          this.formGroup.controls["reference"].valueChanges
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(value => {
              this.reference = value;

              this.loadItens();
            })

          this.loadReference(this.referenceType);
          this.configGrid();
          this.loadItens();

          this.loaded = true;
        }
      })
  }

  public selectOperationType(type: "Entrada" | "Saida" | "Transferencia") {
    this.operationType = type;
    this.stockOperationList = [];
    this.reference = undefined;

    this.formGroup.controls["stockOperationType"].setValue(undefined);

    let params = new Map<string, string>();
    params.set("operType", this.operationType);

    this.httpClient.get("stk.operation/getAvailableOperations", params)
      .pipe(take(1))
      .subscribe(resp => {
        if (resp.operList) {
          if (!Array.isArray(resp.operList)) {
            resp.operList = [resp.operList];
          }

          resp.operList.forEach(oper => {
            let stockOper = new StockOperation();
            stockOper.id_operationType = oper.id_tipoOperacao;
            stockOper.code = oper.codigo;
            stockOper.description = oper.descricao;
            stockOper.reference = oper.referencia;

            this.stockOperationList.push(stockOper);
          });

          this.operationList = [...this.stockOperationList.map(sot => {
            return {
              id: sot.id_operationType,
              description: sot.description
            }
          })];
        } else {
          this.messageService.showToast(`Você não possuí acesso à nenhuma operação do tipo "${this.operationType}"`, "ATENÇÃO", "warning");
        }
      })
  }

  private loadReference(referenceType: string) {
    switch (referenceType) {
      case "Requisição Departamental": {
        this.referenceDatasetId = "zD20211223H114243173R000000003";
        this.referenceDatasetName = "stk_requisicaoDepartAgrup";
        this.referenceAggCondPropId = "zD20211223H114352927R000000005";

        break;
      }
      default: {
        this.referenceDatasetId = undefined;
        this.referenceDatasetName = undefined;
        this.referenceAggCondPropId = undefined;
      }
    }

    this.referenceType = referenceType;
  }

  private configGrid() {
    switch (this.referenceType) {
      case "Requisição Departamental": {
        this.configGridDepartmentalRequisition();
        break;
      }
    }
  }

  private configGridDepartmentalRequisition() {
    this.gridItens.setGridColumnDefs([
      { headerName: "Item", field: "item" },
      { headerName: "Qtd Aprovada", field: "approvedQty", type: "numericColumn", filter: "agNumberColumnFilter" },
      { headerName: "Qtd Pendente Baixa", field: "pendingQty", type: "numericColumn", filter: "agNumberColumnFilter" },
    ]);

    this.gridVolumes.setGridColumnDefs([
      { headerName: "Código", field: "code" },
      { headerName: "Local Estoque", field: "stockAddress" },
      { headerName: "Qtd Disponível", field: "quantityAvailable", type: "numericColumn", filter: "agNumberColumnFilter" },
      { headerName: "Qtd Reservada", field: "quantityReserved", type: "numericColumn", filter: "agNumberColumnFilter" },
      {
        headerName: "Qtd Baixa", field: "quantityWriteOff", type: "numericColumn", filter: "agNumberColumnFilter", editable: true,
        onCellValueChanged: (event: CellValueChangedEvent) => {
          if (event.newValue > event.data.quantityAvailable) {
            event.data.quantityWriteOff = 0;
            this.messageService.showToast("A quantidade informada é maior do que a disponível", "ATENÇÃO", "warning");
            this.gridVolumes.refreshCells();
          }
        }
      },
    ])
  }

  private loadItens() {
    switch (this.referenceType) {
      case "Requisição Departamental": {
        this.loadDepartRequisitionItens();
        break;
      }
    }
  }

  private loadDepartRequisitionItens() {
    this.loadingDataItem = true;
    this.itemList = [];

    let params = new Map<string, string>();
    params.set("id_requisicaoDepartAgrup", this.reference);

    this.httpClient.get("stk.departmentalRequisition/requisitions", params)
      .pipe(take(1))
      .subscribe(resp => {
        if (resp.requisitionList) {
          if (!Array.isArray(resp.requisitionList)) {
            resp.requisitionList = [resp.requisitionList];
          }

          resp.requisitionList.forEach(req => {
            req["ID"] = req.id_requisition;
            req.consumedQty = new Number(req.consumedQty).valueOf();
            req.requestedQty = new Number(req.requestedQty).valueOf();
            req.userRequestedQty = new Number(req.userRequestedQty).valueOf();

            this.itemList.push({
              ID: req.id_requisition,
              id_requisition: req.id_requisition,
              id_item: req.id_item,
              item: req.item,
              approvedQty: req.requestedQty,
              pendingQty: req.requestedQty - req.consumedQty
            })
          })
        }

        this.gridItens.setGridData(this.itemList, this.itemList.length > 0 ? this.itemList[0]["ID"] : null);
        this.loadingDataItem = false;
      }, error => {
        this.loadingDataItem = false;
        this.messageService.showToastError(error);
      });
  }

  private loadVolumes() {
    switch (this.referenceType) {
      case "Requisição Departamental": {
        this.loadDepartReqVolumes();
        break;
      }
    }
  }

  private loadDepartReqVolumes() {
    this.loadingDataVolume = true;

    let id_requisition = this.gridItens.getRowsSelected()[0]["ID"];
    let volReqList = this.volumeList.filter(vol => vol.id_requisition == id_requisition);

    if (volReqList && volReqList.length) {
      this.gridVolumes.setGridData(volReqList, null);
      this.loadingDataVolume = false;
      return;
    }

    let params = new Map<string, string>();
    params.set("id_requisition", id_requisition);

    this.httpClient.get("stk.reservationRequisition/requisitionsVolumes", params)
      .pipe(take(1))
      .subscribe(resp => {
        if (resp.requisitionsVolumes) {
          if (!Array.isArray(resp.requisitionsVolumes)) {
            resp.requisitionsVolumes = [resp.requisitionsVolumes];
          }

          resp.requisitionsVolumes.forEach(vol => {
            vol.quantityAvailable = new Number(vol.quantityAvailable).valueOf();
            vol.quantityRequisition = new Number(vol.quantityRequisition).valueOf();
            vol.quantityReserved = new Number(vol.quantityReserved).valueOf();

            this.volumeList.push({
              ID: vol.id_volume,
              id_requisition: id_requisition,
              id_item: vol.id_item,
              id_volume: vol.id_volume,
              code: vol.volumeCode,
              stockAddress: vol.stockAddress,
              quantityAvailable: vol.quantityAvailable,
              quantityReserved: vol.quantityReserved,
              quantityWriteOff: vol.quantityRequisition <= vol.quantityAvailable ? vol.quantityRequisition : vol.quantityAvailable > 0 ? vol.quantityAvailable : 0
            })
          });

          this.httpClient.get("stk.departmentalRequisition/reservationVolumes", params)
            .pipe(take(1))
            .subscribe(respReserv => {
              if (respReserv.reservationList) {
                if (!Array.isArray(respReserv.reservationList)) {
                  respReserv.reservationList = [respReserv.reservationList];
                }

                respReserv.reservationList.forEach(reservVol => {
                  let vol = this.volumeList.find(v => v.id_volume == reservVol.id_volume);

                  if (vol) {
                    vol["id_romaneio_item_vol"] = reservVol.id_romaneio_item_vol;
                    vol["id_romaneio_item_vol_ref"] = reservVol.id_romaneio_item_vol_ref;
                  }
                })
              }
            })
        }

        this.gridVolumes.setGridData(this.volumeList.filter(vol => vol.id_requisition == id_requisition), null);
        this.loadingDataVolume = false;
      }, error => {
        this.loadingDataVolume = false;
      });
  }

  public gridItensSelectionChanged() {
    switch (this.referenceType) {
      case "Requisição Departamental": {
        this.loadVolumes();
        break;
      }
    }
  }

  public execStockOperation() {
    this.lockScreen = true;

    let oe = new OE();

    oe.id_tipoOperacao = this.operationId;
    oe.tipoOperacao = this.operationType;
    oe.referencia = this.reference;
    oe.itemList = new OEIs();
    oe.itemList.operacaoEstoqueItem = [];

    switch (this.referenceType) {
      case "Requisição Departamental": {
        if (this.volumeList.some(vol => vol.quantityWriteOff > 0)) {
          this.configStkOperDepReq(oe);
        } else {
          this.messageService.showToast("Não foi informada nenhuma quantidade para baixa", "ATENÇÃO", "warning");
          this.lockScreen = false;
          return;
        }

        break;
      }
    }


    this.httpClient.post("stk.operation/execStockOperation", null, oe).pipe(take(1))
      .subscribe(() => {
        this.lockScreen = false;
        this.messageService.showToast("Operação realizada", "Sucesso", "success");

        if (this.inDialog) {
          this.dialogRef.close();
        }
      }, error => {
        this.lockScreen = false;
      })
  }

  private configStkOperDepReq(oe: OE) {
    let itemMovList = this.itemList.filter(it => this.volumeList.filter(vol => vol.quantityWriteOff > 0).map(vol => vol.id_requisition).includes(it.ID));

    itemMovList.forEach(item => {

      let oei = new OEI;
      oei.id_item = item.id_item;
      oei.volumeList = new OEIVs();
      oei.volumeList.operacaoEstoqueItemVol = [];

      let volItemList = this.volumeList.filter(vol => vol.id_item == item.id_item && vol.quantityWriteOff > 0);

      volItemList.forEach(vol => {
        let oeiv = new OEIV();
        oeiv.id_requisicao = vol.id_requisition;
        oeiv.id_volume = vol.id_volume;
        oeiv.quantidade = vol.quantityWriteOff;

        if (vol.id_romaneio_item_vol) {
          oeiv.id_romaneio_item_vol = vol.id_romaneio_item_vol;
        }

        if (vol.id_romaneio_item_vol_ref) {
          oeiv.id_romaneio_item_vol_ref = vol.id_romaneio_item_vol_ref;
        }

        oei.volumeList.operacaoEstoqueItemVol.push(oeiv);
      });

      oe.itemList.operacaoEstoqueItem.push(oei);
    });
  }
}
