<app-form-template #formTemplate formTitle="Tabela de Preço" [loaded]="loaded">
  <header>
  </header>
  <content>
    <div class="flexColumn" style="gap: 5px; height: 100%; ">
      <!-- cabeçalho -->
      <div [ngStyle]="{'display': 'flex', 'gap': '5px'}"
           [ngStyle.xs]="{'flex-direction': 'column'}"
           [ngStyle.md]="{'flex-direction': 'column'}">
        <div style="min-width: 500px; display: flex; gap: 5px">
          <nb-select size="small" placeholder="Tabela de Preço" [nbSpinner]="loading"
            style="margin-left: auto; font-size: 80%; width: 400px; margin-right: 5px" [formControl]="selectionField">
            <nb-option *ngFor="let pt of priceTableList" [value]="pt.id_priceTable">
              {{ pt.description }} ({{pt.startDate | date:'dd/MM/yy'}} - {{pt.endDate | date: 'dd/MM/yy'}})
            </nb-option>
          </nb-select>
          <button nbButton size="small" nbTooltip="Duplicar" (click)="duplicateTable()" [nbSpinner]="duplicating"
            [disabled]="duplicating || loading">
            <nb-icon icon="plus-outline"></nb-icon>
          </button>
          <button nbButton size="small" nbTooltip="Editar" (click)="updatePriceTableInfo()" [nbSpinner]="duplicating"
            [disabled]="!editing || loading">
            <nb-icon icon="edit"></nb-icon>
          </button>
        </div>

        <div [ngStyle]="{'display': 'flex', 'justify-content': 'flex-end', 'align-items': 'flex-end', 'gap': '5px', 'flex': '100%' }"
             [ngStyle.md]="{'justify-content': 'flex-start', 'align-items': 'flex-start'}"
             [ngStyle.sm]="{'flex-direction': 'column', 'justify-content': 'flex-start', 'align-items': 'flex-start'}"
             [ngStyle.xs]="{'flex-direction': 'column', 'justify-content': 'flex-start', 'align-items': 'flex-start'}">
          <div>
            <input [formControl]="newValueField" nbInput fieldSize="small" placeholder="valor" type="number"
              style="width: 100px; margin-right: 5px;" />
            <input [formControl]="newPercField" nbInput fieldSize="small" placeholder="percentual" type="number"
              style="width: 100px; margin-right: 5px;" />
            <button nbButton size="small" status="primary" [disabled]="!applyValue"
              (click)="getFilterRows()">Aplicar</button>
          </div>

          <div style="min-width: 222px;">
            <button nbButton size="small" status="primary" (click)="savePriceTableChanges()" style="margin-left: 30px;"
              [disabled]="(changedItems > 4000) || saving || !changed" [nbSpinner]="saving"
              [nbTooltip]="'Não é permitido alterar mais de 4000 itens de uma unica vez'"
              [nbTooltipTrigger]="changedItems <= 4000 ? 'nopp' : 'hover'">Gravar</button>

            <button nbButton size="small" status="danger" (click)="undoPriceTableChanges()" style="margin-left: 5px;"
              [disabled]="saving || !changed">Desfazer</button>
          </div>
        </div>

      </div>


      <div style="width: 100%; height: calc(100% - 60px);">
        <div style="display: flex; gap: 5px; justify-content: flex-end; align-items: center; width: 99%;">
          {{changedItems == 0
          ? 'Nenhum item alterado'
          : changedItems == 1
          ? 'Um item alterado'
          : changedItems + ' itens alterados' +
          (changedItems > 4000 ? '. Não é permitido gravar mais de 4000 alterações de uma única vez' : '')}}
        </div>

        <app-t2grid class="gridArea" #gridTask id="gridTask" [sortable]="true" rowSelection="multiple"
          [loading]="loading || saving" [showFiltersSidebar]="true" (rowClick)="clicouNaLinha($event)">
        </app-t2grid>
      </div>
    </div>
  </content>
</app-form-template>
