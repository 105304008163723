import { AfterViewInit, Component, EventEmitter, Inject, Input, LOCALE_ID, OnDestroy, Output, ViewChild } from '@angular/core';
import { T2SeparationService, TaskRequisition, TransferOperation, VolumeInfo } from "../t2separation.service";
import { take, takeUntil } from "rxjs/Operators";
import { Subject } from "rxjs";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { LayoutType, ViewTemplateElement } from "src/app/core/cmp/view-template/model/view-template-element";
import { T2SelectComponent } from "src/app/core/cmp/ui/t2-select/t2-select.component";
import { T2InputTextComponent } from "src/app/core/cmp/ui/t2-input-text/t2-input-text.component";
import { T2InputFloatComponent } from "src/app/core/cmp/ui/t2-input-float/t2-input-float.component";
import { T2UserDeviceService } from "src/app/t2-user-device.service";
import { ViewTemplateComponent } from "src/app/core/cmp/view-template/view-template.component";
import { T2MessageService } from "src/app/core/t2-message.service";
import { NbDialogService } from "@nebular/theme";
import { ReservationRequisitionComponent } from "src/app/bsn/stk/reservation-requisition/reservation-requisition.component";
import { T2ReservationService } from "src/app/bsn/stk/reservation-requisition/t2-reservation.service";

@Component({
  selector: 'app-separation-transfer',
  templateUrl: './separation-transfer.component.html',
  styleUrls: ['./separation-transfer.component.scss']
})
export class SeparationTransferComponent implements AfterViewInit, OnDestroy {

  @ViewChild('viewTemplate') viewTemplate: ViewTemplateComponent;

  @Input()
  get taskRequisition(): TaskRequisition {
    return this._taskRequisition;
  }

  set taskRequisition(value: TaskRequisition) {
    this._taskRequisition = value;

    this.formGroup.controls["volume"].setValue(undefined);
    this.formGroup.controls["origem"].setValue(undefined);
    this.formGroup.controls["quantidade"].setValue(undefined);
    this.formGroup.controls["operacao"].setValue(this.transfOperationList?.length > 0 ? this.transfOperationList[0].operationId : undefined);

    let promList = new Array<Promise<void>>();
    if (this._taskRequisition && !this._taskRequisition.availableVolumeList?.length) {
      this.loading = true;

      promList.push(
        this.separationService.getRequestVolumes(this._taskRequisition.prodOrderId, this._taskRequisition.requestId, this._taskRequisition.itemId).pipe(take(1)).toPromise().then(resp => {
          this._taskRequisition.availableVolumeList = resp.volumeList;
          this._taskRequisition.transferredVolumeList = resp.transferredVolumeList;

          this._taskRequisition.availableVolumeList.forEach(vol => {
            vol.itemUnit = this._taskRequisition.itemUnit;
          });

          this._taskRequisition.transferredVolumeList.forEach(vol => {
            vol.itemUnit = this._taskRequisition.itemUnit;
          })
        }));
    }

    if (this._taskRequisition && !this._taskRequisition.reservedVolumeList?.length) {
      this.loading = true;

      promList.push(this.reservationService.loadVolumes(this._taskRequisition.requestId).pipe(take(1)).toPromise().then(volList => {
        this._taskRequisition.reservedVolumeList = volList.filter(v => v.quantityRequisition > 0);
      }))
    }

    if (promList.length) {
      Promise.all(promList).finally(() => {
        this._taskRequisition.reservedVolumeList.forEach(reservedVol => {
          let vol = this._taskRequisition.availableVolumeList.find(v => v.volumeId == reservedVol.id_volume);

          if (vol) {
            vol.reservedQuantity = reservedVol.quantityRequisition;
            vol.requisitionReservedQty = reservedVol.quantityReserved;
          }
        });

        if (this._taskRequisition.reservedVolumeList?.length) {
          this._taskRequisition.availableVolumeList = this._taskRequisition.availableVolumeList.filter(v => this._taskRequisition.reservedVolumeList.some(vReserved => v.volumeId == vReserved.id_volume));
        }

        this.loading = false;
      })
    }
  }

  @Output() transferCompleted = new EventEmitter<void>();
  @Output() reservationExit = new EventEmitter<void>();
  @Output() loadingInfo = new EventEmitter<boolean>();

  private _taskRequisition: TaskRequisition;
  private unsubscribe = new Subject<void>();
  private transfOperationList: Array<TransferOperation>;
  private lastVolumeCode: string;
  private selectedVolume: VolumeInfo;
  private volInfoList: Array<VolumeInfo>;
  private originList = new Array<{ id: any, stockAddressId: string, description: string }>();
  public selectedOperation: TransferOperation;
  public formGroup: FormGroup;
  public loading: boolean = false;
  public suggestVolQty: boolean = false;
  public layout: Array<ViewTemplateElement> = [
    {
      layoutType: LayoutType.gridLayout,
      children: [
        {
          layoutType: LayoutType.component,
          isBaseComponent: true,
          cmpName: "operacao",
          title: "Operação de Estoque",
          cmpType: T2SelectComponent,
          columnSpan: 4
        },
        {
          layoutType: LayoutType.component,
          isBaseComponent: true,
          cmpName: "volume",
          title: "Volume",
          cmpType: T2InputTextComponent,
          columnSpan: 2,
          inputs: {
            enableScanner: true
          },
          outputs: {
            "keyDownEnter": this.onVolumeKeyEnter.bind(this)
          }
        },
        {
          layoutType: LayoutType.component,
          isBaseComponent: true,
          cmpName: "origem",
          title: "Origem",
          cmpType: T2SelectComponent,
          columnSpan: 3
        },
        {
          layoutType: LayoutType.component,
          isBaseComponent: true,
          cmpName: "destino",
          title: "Destino",
          cmpType: T2InputTextComponent,
          columnSpan: 2
        },
        {
          layoutType: LayoutType.component,
          isBaseComponent: true,
          cmpName: "quantidade",
          title: "Qtd",
          cmpType: T2InputFloatComponent,
          columnSpan: 1
        }
      ]
    }
  ]

  constructor(private separationService: T2SeparationService, private formBuilder: FormBuilder,
    public userDevService: T2UserDeviceService, private messageService: T2MessageService,
    @Inject(LOCALE_ID) public locale: string, private dialogService: NbDialogService, private reservationService: T2ReservationService) {
    this.formGroup = this.formBuilder.group({}, { updateOn: "change" });
    this.formGroup.addControl("operacao", this.formBuilder.control(undefined, [Validators.required]));
    this.formGroup.addControl("volume", this.formBuilder.control(undefined, { updateOn: "blur", validators: [Validators.required, this.validateReservedVolume()] }));
    this.formGroup.addControl("origem", this.formBuilder.control(undefined, [Validators.required, this.validateOperationOrigin()]));
    this.formGroup.addControl("destino", this.formBuilder.control(undefined, [Validators.required]));
    this.formGroup.addControl("quantidade", this.formBuilder.control(undefined, [Validators.required, this.validateMaxVolumeQty()]));
  }

  ngAfterViewInit(): void {
    this.separationService.getTransferDirective().pipe(take(1)).subscribe(transfDirective => {
      this.suggestVolQty = transfDirective.suggestQty == "Total do Volume";
    });

    this.separationService.getTransferOperations(this.taskRequisition.taskId).pipe(take(1)).subscribe(operList => {
      this.transfOperationList = operList;

      if (this.transfOperationList.length > 0) {
        this.viewTemplate.setCmpInputValue("operacao", "list", this.transfOperationList.map(oper => {
          return {
            id: oper.operationId,
            description: oper.description
          }
        }));

        this.formGroup.controls["operacao"].valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe(id => {
          this.selectedOperation = this.transfOperationList.find(oper => oper.operationId == id);
          this.lastVolumeCode = undefined;
          this.formGroup.controls["destino"].setValue(this.selectedOperation.stockAddressCode);

          this.viewTemplate.setCmpInputValue("destino", "readOnly", this.selectedOperation.lockDestination);
          this.formGroup.controls["origem"].setValue(undefined);
          this.formGroup.controls["volume"].setValue(undefined);
          this.formGroup.controls["quantidade"].setValue(undefined);
        });

        this.formGroup.controls["operacao"].setValue(this.transfOperationList[0].operationId);

        this.formGroup.controls["volume"].valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe(value => {
          this.searchVolume();
        });

        this.formGroup.controls["origem"].valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe(value => {
          let vol = this.volInfoList?.find(v => v.uniqueId == value);

          if (vol) {
            this.selectedVolume = vol;

            if (this.suggestVolQty) {
              this.formGroup.controls["quantidade"].setValue(this.selectedVolume.availableQuantity);
            } else {
              let pendingQty = this.taskRequisition.requestedQty - this.taskRequisition.transferredQty;
              this.formGroup.controls["quantidade"].setValue(Math.min(Math.max(pendingQty, 0), this.selectedVolume.availableQuantity));
            }
          }

          this.formGroup.controls["quantidade"].updateValueAndValidity();
        });

        this.formGroup.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe(changes => {
          this.formGroup.markAllAsTouched();
        })
      }
    });
  }

  private validateMaxVolumeQty() {
    return (control: FormControl) => {
      const currentValue = control.value;

      if (currentValue > this.selectedVolume?.availableQuantity) {
        return {
          max: {
            max: this.selectedVolume?.availableQuantity,
            actual: currentValue
          }
        };
      }
      return null; // Retorna nulo se a validação passar
    };
  }

  private validateOperationOrigin() {
    return (control: FormControl) => {
      if (this.selectedOperation?.lockOrigin &&
        this.originList.find(it => it.id == control.value)?.stockAddressId != this.selectedOperation.originStockAddressId) {
        return {
          message: "O local de origem informado não é permitido para essa operação"
        }
      } else {
        return null; // Retorna nulo se a validação passar
      }
    }
  }

  private validateReservedVolume() {
    return (control: FormControl) => {
      if (!this.selectedOperation?.isDevolution && this.taskRequisition?.reservedVolumeList?.length) {
        if (!this.taskRequisition.reservedVolumeList.some(vol => vol.volumeCode == control.value)) {
          return {
            message: "Esse volume não foi reservado para essa requisição"
          }
        } else {
          return null; // Retorna nulo se a validação passar
        }
      } else {
        return null; // Retorna nulo se a validação passar
      }
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  selectVolume(volume: VolumeInfo) {
    this.lastVolumeCode = volume.code;
    this.volInfoList = [];
    this.selectedVolume = volume;
    this.formGroup.controls["volume"].setValue(volume.code, { emitEvent: false });

    this.formGroup.controls["origem"].setValue(undefined);
    this.originList = [{ id: volume.uniqueId, stockAddressId: volume.stockAddressId, description: `${volume.stockAddressCode} qtd: ${volume.availableQuantity}` }];
    this.viewTemplate.setCmpInputValue("origem", "list", this.originList.map(it => {
      return {
        id: it.id,
        description: it.description
      }
    }));

    if (!this.selectedOperation.isDevolution) {
      this.volInfoList = this.taskRequisition.availableVolumeList.filter(v => v.volumeId == this.selectedVolume.volumeId);
    } else {
      this.volInfoList = this.taskRequisition.transferredVolumeList.filter(v => v.volumeId == this.selectedVolume.volumeId);
    }

    setTimeout(() => {
      this.formGroup.controls["origem"].setValue(this.originList[0].id);
    }, 100);
  }

  transferVolume() {
    this.loading = true;
    this.loadingInfo.emit(true);
    this.separationService.getStockAddressId(this.formGroup.controls["destino"].value).pipe(take(1)).subscribe(stockAddressId => {
      let qty = this.formGroup.controls["quantidade"].value;

      this.separationService.makeTransfer(this.taskRequisition.prodOrderId, this.taskRequisition.taskId, this.selectedOperation.operationId, this.taskRequisition.itemId,
        this.selectedVolume.volumeId, qty, stockAddressId, this.taskRequisition.requestId, this.selectedOperation.isDevolution).pipe(take(1)).subscribe(message => {
          this.loading = false;
          this.loadingInfo.emit(false);
          this.messageService.showToast(message, "Operação Realizada", "success");
          this.transferCompleted.emit();
        }, error => {
          this.loading = false;
          this.loadingInfo.emit(false);
        })
    }, error => {
      this.loading = false;
      this.loadingInfo.emit(false);
    });
  }

  onVolumeKeyEnter() {

  }

  searchVolume() {
    let volumeCode: string = this.formGroup.controls["volume"].value;

    if (volumeCode == this.lastVolumeCode) {
      return;
    }

    this.volInfoList = [];
    this.viewTemplate.setCmpInputValue("origem", "list", []);
    this.formGroup.controls["origem"].setValue(undefined);

    if (volumeCode && this.selectedOperation && this.taskRequisition) {
      this.loading = true;
      this.loadingInfo.emit(true);

      if (!this.selectedOperation.isDevolution) {
        this.volInfoList = this.taskRequisition.availableVolumeList.filter(v => v.code == volumeCode);
      } else {
        this.volInfoList = this.taskRequisition.transferredVolumeList.filter(v => v.code == volumeCode);
      }

      if (this.volInfoList.length) {
        this.lastVolumeCode = volumeCode;
        this.originList = this.volInfoList.map(vol => {
          return {
            id: vol.uniqueId,
            stockAddressId: vol.stockAddressId,
            description: `${vol.stockAddressCode} qtd: ${vol.availableQuantity}`
          }
        });

        this.viewTemplate.setCmpInputValue("origem", "list", this.originList.map(it => {
          return {
            id: it.id,
            description: it.description
          }
        }));

        setTimeout(() => {
          this.selectedVolume = this.volInfoList[0];
          this.formGroup.controls["origem"].setValue(this.volInfoList[0].uniqueId);
        }, 100);
      } else {
        this.viewTemplate.setCmpInputValue("origem", "list", []);
      }

      this.loading = false;
      this.loadingInfo.emit(false);
    }
  }

  public reservation() {
    let dlg = this.dialogService.open(ReservationRequisitionComponent, {
      context: {
        itemId: this.taskRequisition.itemId,
        requisitionType: "OP",
        initialRequisitionSelectedId: this.taskRequisition.requestId
      }, closeOnBackdropClick: false
    });

    dlg.onClose.pipe(take(1)).subscribe(() => {
      this.reservationExit.emit();
    });
  }
}
