<div (click)="onClick()" class="flexColumn card" style="justify-content: space-around;">


  <div *ngIf="topText" class="fontLicenses"
    [ngStyle]="{'font-size':fontSize ? fontSize : '12px'}">
    {{topText}}
  </div>

  <div style="font-size: 160%; font-weight: bold; text-align: center; "
    [ngStyle]="{'margin-top': topText != undefined ? '0px' : '15px', 'margin-bottom': bottomText != undefined ? '0px' : '15px'}">
    {{ middleText }}</div>

  <div *ngIf="bottomText" class="fontLicenses"
    [ngStyle]="{'font-size':fontSize ? fontSize : '12px'}">
    {{bottomText}}
  </div>

</div>
