import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { take, takeUntil } from "rxjs/Operators";
import { FormTemplateComponent } from "src/app/core/cmp/form-template/form-template.component";
import { T2SecurityService } from "src/app/core/security/t2security.service";
import { TechprodDevService } from "../../techprod-dev.service";
import { NbDialogService } from "@nebular/theme";
import { T2HttpClientService } from "src/app/core/http/t2httpClient.service";
import { Subject } from "rxjs";
import { ActionService } from "src/app/core/action/action.service";
import { T2DatasetService } from "src/app/core/dataset/t2dataset.service";
import { ConditionGroup, DatasetVisualization, VisualizationField } from "src/app/core/dataset/visualization/t2-visualization.service";
import { QueryComponent } from "src/app/core/dataset/query/query.component";
import { DatasetControlsComponent } from "../dataset-controls/dataset-controls.component";

@Component({
  selector: 'app-dictionary',
  templateUrl: './dictionary.component.html',
  styleUrls: ['./dictionary.component.scss']
})
export class DictionaryComponent implements OnInit, OnDestroy {

  loaded = false;
  reloadingCache = false;
  dataset = null;
  compilingDataset = false;
  savingDefVisualization = false;

  private unsubscribe$ = new Subject<void>();
  public canEditAF: boolean = false;

  @ViewChild("formTemplate", { static: true }) formTemplate: FormTemplateComponent;

  constructor(
    private sec: T2SecurityService,
    private httpClient: T2HttpClientService,
    private devService: TechprodDevService,
    private dialogService: NbDialogService,
    private actionService: ActionService,
    private dsService: T2DatasetService) { }

  ngOnInit(): void {
    this.sec.accessLoaded()
      .pipe(
        take(1)
      ).subscribe(() => {
        if (this.formTemplate.validateAccess(['{1FE20456-DBFB-4A01-B331-C25B1EB8C72B}'], 'full')) {
          this.canEditAF = this.sec.hasAction("{C2327858-73C9-439B-A0D4-33344375311C}");
          this.loaded = true;
        }
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  realodServerCache() {
    this.reloadingCache = true;
    this.devService.realodServerCache().subscribe(resp => this.reloadingCache = false, error => this.reloadingCache = false);
  }

  onSelectDS(dataset) {
    this.dataset = dataset;
  }

  defaultVisualization() {

    this.httpClient.get(`core.presentation/datasetDefaultVisualization/${this.dataset.id_dataset}`, null)
      .pipe(take(1), takeUntil(this.unsubscribe$))
      .subscribe(resp => {

        let dv: DatasetVisualization;
        if (resp.visualization?.visualizationData) {
          dv = resp.visualization;
          const obj: { fieldList?: Array<VisualizationField>, queryCondition: ConditionGroup } = JSON.parse(resp.visualization.visualizationData);
          dv.fieldList = obj.fieldList || [];
          dv.queryCondition = obj.queryCondition || { condition: "AND" };
        } else {
          dv = new DatasetVisualization();
          dv.description = 'Visualização Padrão';
        }

        this.dialogService.open(QueryComponent, {
          autoFocus: true, closeOnEsc: false, closeOnBackdropClick: false, hasBackdrop: true,
          context: { id_dataset: this.dataset?.id_dataset, datasetVisualization: dv, fromDictionary: true }
        }).onClose.subscribe((opt: { save: boolean, datasetVisualization?: DatasetVisualization }) => { });
      });
  }

  compileDataset() {
    if (!this.dataset) return null;

    this.compilingDataset = true;
    this.devService.compileDataset(this.dataset.datasetName).subscribe(resp => {
      this.compilingDataset = false;
    }, error => {
      this.compilingDataset = false;
    });
  }

  editDataset() {
    this.sec.getAction("{C2327858-73C9-439B-A0D4-33344375311C}").pipe(take(1)).subscribe(action => {
      let params = new Map<string, string>();
      params.set("id", this.dataset.id_autoform);

      this.actionService.executeAction(action, params, "newWindow");
    })
  }

  editDatasetControls() {
    this.dialogService.open(DatasetControlsComponent, {
      context: {
        dataset: this.dataset
      }, closeOnBackdropClick: false, hasBackdrop: true
    }).onClose.pipe(take(1)).subscribe(resp => {
      
    })
  }

}
