import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from "ag-grid-angular";
import { ICellRendererParams } from "ag-grid-community";
import { RegistrationFixData } from "../model/RegistrationFixData";

@Component({
  selector: 'app-value-render-cell',
  templateUrl: './value-render-cell.component.html',
  styleUrls: ['./value-render-cell.component.scss']
})
export class ValueRenderCellComponent implements OnInit, ICellRendererAngularComp {

  registrationData: RegistrationFixData;
  dateValue: Date;

  constructor() { }

  ngOnInit(): void {
  }

  agInit(params: ICellRendererParams){
    this.registrationData = params.data;

    if (this.registrationData.contentType === 'datetime' && this.registrationData.propValue) {
      this.dateValue = new Date(this.registrationData.propValue);
    }
  }

  refresh(params: ICellRendererParams): boolean{

    if (this.registrationData.contentType === 'datetime' && this.registrationData.propValue) {
      this.dateValue = new Date(this.registrationData.propValue);
    }

    return true;
  }

}
