import { Component, Input, OnInit } from '@angular/core';
import { T2BaseComponent } from "../t2-base-component";

@Component({
  selector: 'app-t2-time',
  templateUrl: './t2-time.component.html',
  styleUrls: ['./t2-time.component.scss']
})
export class T2TimeComponent extends T2BaseComponent implements OnInit {

  @Input() withSeconds: any;

  public mask;

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.setDefaultValues();
    this.withSeconds = this.withSeconds ?? false;

    if (this.withSeconds) {
      this.mask = "Hh:m0:s0";
    } else {
      this.mask = "Hh:m0"
    }
  }

  // onChange(event: any) {
  //   let value: Date = undefined;

  //   if (event.target.value) {
  //     value = new Date(this.data);
  //     value.setHours(event.target.value.substring(0, 2));
  //     value.setMinutes(event.target.value.substring(3, 5));

  //     if (this.withSeconds) {
  //       value.setSeconds(event.target.value.substring(6, 8), 0);
  //     } else {
  //       value.setSeconds(0, 0);
  //     }
  //   }

  //   let obj: IT2CmpValueChange = {
  //     id: this.id,
  //     data: value
  //   };

  //   this.valueChange.emit(obj);
  // }
}
