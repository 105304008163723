<div [ngStyle.xs]="{ width: '300px' }">
  <app-dialog-template #dialog [title]="title">
    <content>

      <nb-select size="small" [formControl]="reproveReasonField" fullWidth>
        <nb-option *ngFor="let rs of reasonList" [value]="rs.id_reason">{{ rs.reason }}</nb-option>
      </nb-select>

      <textarea nbInput [formControl]="notes" fullWidth style="margin-top: 5px;"></textarea>
    </content>
    <footer>
      <div class="flexAlignRowCenter flexGap5">
        <button (click)="confirm()" nbButton size="tiny" status="primary" [disabled]="!reproveReasonField.valid">
          Confirmar
        </button>
        <button (click)="cancel()" nbButton size="tiny" status="primary">
          Cancelar
        </button>
      </div>
    </footer>
  </app-dialog-template>
</div>
