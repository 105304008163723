<div style="width: 100%; display: flex; gap: 5px;"
  [ngStyle]="{'align-items': editType == 'OnlyValue' ? 'center' : 'baseline'}">

  <!-- campo -->
  <div *ngIf="editType == 'All'" class="field" (click)="selectField(item)">
    <div [ngStyle]="{'color': item.property ? '#000000' : 'rgb(173, 173, 173)',
                           'font-size': item.property ? '0.8em' : '0.8em',
                           'font-weight': item.property ? 'bold' : 'normal'
                          }">
      {{item.property ? item.description : "Selecione um campo..."}}
    </div>
    <nb-icon icon="menu-2-outline"></nb-icon>
  </div>
  <!-- operador -->
  <div *ngIf="item.property && editType == 'All'" class="field"
    style="width: 52px; text-align: center; display: block; color: rgb(0, 0, 0); font-size: 0.8em; font-weight: bold;"
    (click)="item.not = !item.not">
    {{item.not ? "Não é" : "É"}}
  </div>
  <div *ngIf="item.property && editType == 'All'">
    <select class="operatorSelect" (change)="visService.selectOperator(item, $event.target.value)"
      [(ngModel)]="item.operator">
      <option *ngFor="let o of item.operators" [value]="o">{{o}}</option>
    </select>
  </div>

  <!-- Campo pré-definido -->
  <div style="display: flex; flex-direction: column; line-height: 0.75rem; align-items: center;">
    <div class="readOnlyField description" *ngIf="editType == 'OnlyValue'">
      <div>{{item.description}}</div>
    </div>
    <!-- Operador pré-definido -->
    <div style="display: flex; gap: 5px;">
      <div *ngIf="item.property && editType == 'OnlyValue' && item.not" class="operatorDescription">
        {{item.not ? "não" : undefined}}
      </div>
      <!-- Condição pré-definida -->
      <div *ngIf="item.property && editType == 'OnlyValue'" class="operatorDescription">
        <div>{{['CONTAINS', 'CONTAINS_ALL', 'CONTAINS_ONE'].includes(item.operator) ? "contém" : item.operator ==
          'EQUALS' ? 'igual' : item.operator == 'BETWEEN' ? 'entre' : item.operator == 'MAJOR' ? 'maior' : item.operator
          == 'MINOR' ? 'menor': item.operator }}</div>
      </div>
    </div>
  </div>

  <div *ngIf="item.property && item.requireValue" [ngStyle]="{'max-width': editType == 'OnlyValue' ? '100%' : '51%' }">

    <input *ngIf="item.propertyType == 'EMAIL'" type="email" class="operatorSelect" style="width: 100%;"
      [(ngModel)]="item.value" [required]="item?.fieldFilter?.required" />

    <input *ngIf="['STRING','AUTO_INC_MASKED'].includes(item.propertyType)" type="text" class="operatorSelect"
      style="width: 100%;" [(ngModel)]="item.value" [required]="item?.fieldFilter?.required" nbInput fieldSize="tiny" />

    <div *ngIf="['INT','DECIMAL','MONEY','DURATION'].includes(item.propertyType)"
      style="width: 100%; display: flex; gap: 5px; align-items: center;">
      <input type="number" class="operatorSelect operatorSelectNumber" [(ngModel)]="item.value"
        [required]="item?.fieldFilter?.required" />
      <div *ngIf="item.operator == 'BETWEEN'">e</div>
      <input *ngIf="item.operator == 'BETWEEN'" type="number" class="operatorSelect operatorSelectNumber"
        [(ngModel)]="item.value2" [required]="item?.fieldFilter?.required" />
    </div>

    <div *ngIf="['DATE_TIME','DATE','TIME'].includes(item.propertyType)"
      style="width: 100%; display: flex; gap: 5px; align-items: center;">
      <input [type]="item.propertyType == 'DATE_TIME' ? 'date' : (item.propertyType == 'DATE' ? 'date' : 'time')"
        class="operatorSelect" [(ngModel)]="item.value" [required]="item?.fieldFilter?.required" />
      <div *ngIf="item.operator == 'BETWEEN'">e</div>
      <input *ngIf="item.operator == 'BETWEEN'"
        [type]="item.propertyType == 'DATE_TIME' ? 'date' : (item.propertyType == 'DATE' ? 'date' : 'time')"
        class="operatorSelect" [(ngModel)]="item.value2" [required]="item?.fieldFilter?.required" />
    </div>

    <div *ngIf="['STATUS','FIXED_COMBO'].includes(item.propertyType)" style="display: flex; gap: 5px; flex-wrap: wrap;">
      <div *ngFor="let opt of item.valueOptions" class="operatorSelectStatus" [ngClass]="opt.colorStyle">
        <input type="checkbox" [id]="opt.id" [(ngModel)]="opt.checked" [required]="item?.fieldFilter?.required" />
        <label [for]="opt.id" style="white-space: nowrap; text-overflow: ellipsis; overflow: hidden;">
          {{opt.description}}
        </label>
      </div>

    </div>

    <div *ngIf="['BOOLEAN'].includes(item.propertyType)" style="display: flex; gap: 5px; flex-wrap: wrap">
      <div class="operatorSelectStatus">
        <input type="radio" [id]="'sim'" [name]="'boolean'" [value]="1" [(ngModel)]="item.value"
          [required]="item?.fieldFilter?.required" />
        <label [for]="'sim'" style="white-space: nowrap; text-overflow: ellipsis; overflow: hidden; color: #0d0d0d">
          Sim
        </label>

        <input type="radio" [id]="'nao'" [name]="'boolean'" [value]="0" [(ngModel)]="item.value"
          [required]="item?.fieldFilter?.required" />
        <label [for]="'nao'" style="white-space: nowrap; text-overflow: ellipsis; overflow: hidden; color: #0d0d0d">
          Não
        </label>

      </div>

    </div>

    <div *ngIf="item.propertyType == 'GUID' && item.datasetId"
      style="max-width: 350px; width: 350px; display: flex; gap: 5px; align-items: center;">
      <app-t2-aggregation [t2IdCmp]="item.property" [formGroup]="formGroup" [datasetId]=" item.datasetId"
        [datasetName]="item.datasetName" [classicTheme]="true" style="width: 100%;" />
    </div>

  </div>
</div>