import { Component, Input, OnInit } from '@angular/core';
import { WarningService } from "../warning/warning.service";
import { T2SecurityService } from "../security/t2security.service";
import { take } from "rxjs/Operators";
import { Warning } from "../warning/model/warning";
import { ColorService } from "../color.service";
import { T2AccessItem, T2AccessItemDatasetActionType, T2AccessItemType } from "../security/model/t2accessItem";
import { ActionService } from "../action/action.service";
import { DialogItemSelectionComponent, GroupSelection, ItemSelection } from "../cmp/dialog-item-selection/dialog-item-selection.component";
import { NbDialogService } from "@nebular/theme";
import { T2MessageService } from "../t2-message.service";

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit {
  @Input() compactDisplay: boolean = true;

  public warningList: Array<Warning> = [];
  public loading: boolean;
  public datasetActions = new Map<string, Array<T2AccessItem>>();
  private _warningListSolved: Array<Warning> = [];

  get warningListSolved() {
    return this._warningListSolved;
  }
  set warningListSolved(value: Array<Warning>) {
    this._warningListSolved = value;
  }

  constructor(private warningService: WarningService, private secService: T2SecurityService, public colorService: ColorService,
    private actionService: ActionService, private dialogService: NbDialogService, private messageService: T2MessageService) { }

  ngOnInit(): void {
    this.loading = true;
    this.warningService.getWarningListByUser(this.secService.getUserId()).pipe(take(1)).subscribe(wList => {
      this.warningList = wList.filter(i => i.status == "Pendente");
      this.warningListSolved = wList.filter(i => i.status != "Pendente");
      this.warningList?.sort((a, b) => {
        let resp: number;
        if (a.maxDate && b.maxDate) {
          resp = a.maxDate < b.maxDate ? -1 : 1
        } else {
          resp = a.maxDate ? -1 : 1;
        }
        return resp;
      });

      this.warningListSolved?.sort((a, b) => {
        let resp: number;
        if (a.completionDate && b.completionDate) {
          resp = a.completionDate < b.completionDate ? -1 : 1
        } else {
          resp = a.completionDate ? -1 : 1;
        }
        return resp;
      });

      wList?.forEach(warning => {
        if (!this.datasetActions.has(warning.id_dataset)) {
          this.datasetActions.set(warning.id_dataset, undefined);
        }
      });

      for (let id_dataset of this.datasetActions.keys()) {
        this.secService.getDatasetActionsByDatasetId(id_dataset).pipe(take(1)).subscribe(actionList => {
          this.datasetActions.set(id_dataset, actionList?.filter((action: T2AccessItem) => {
            return (
              [T2AccessItemDatasetActionType.DSACTION_OTHER,
              T2AccessItemDatasetActionType.DSACTION_SEARCH,
              T2AccessItemDatasetActionType.DSACTION_OTHERCLOSE,
              T2AccessItemDatasetActionType.DSACTION_VIEW,
              T2AccessItemDatasetActionType.DSACTION_EDIT
              ].includes(action.datasetActionType) &&
              this.actionService.canExecuteAction(action)
            )
          }));
        });
      }

      this.loading = false;
    }, error => {
      this.loading = false;
    })
  }

  finishWarning(warning: Warning, status: string) {
    warning.saving = true;
    warning.status = status;
    this.warningService.finishWarning(warning).pipe(take(1)).subscribe(updatedWarning => {
      warning.completionDate = updatedWarning.completionDate;
      warning.completionUser = updatedWarning.completionUser;
      warning.id_security_identity = updatedWarning.id_security_identity;
      warning.saving = false;
      this.warningListSolved = [...this.warningListSolved, warning];
      this.warningList = this.warningList.filter(i => i.status == 'Pendente');
    }, error => {
      warning.saving = false;
    })
  }
}
