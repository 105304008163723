import { AfterViewInit, Component, ViewChild, ViewContainerRef } from '@angular/core';
import { FormControl } from "@angular/forms";
import { ICellEditorAngularComp } from "ag-grid-angular";

@Component({
  selector: 'app-select-editor',
  templateUrl: './select-editor.component.html',
  styleUrls: ['./select-editor.component.scss']
})
export class SelectEditorComponent implements ICellEditorAngularComp, AfterViewInit {

  selectionField = new FormControl();
  selectionOptions: Array<{ id: string, value: string }>;
  required: boolean = false;
  placeHolder: string;
  params: any;

  @ViewChild('select', { read: ViewContainerRef }) public select: ViewContainerRef;

  constructor() { }

  ngAfterViewInit(): void {
    // focus on the select
    setTimeout(() => this.select.element.nativeElement.focus());
  }

  agInit(params: any): void {
    this.params = params;
    this.selectionOptions = params.selectionOptions;
    this.required = params.required || false;
    this.placeHolder = params.placeHolder;
    this.selectionField.patchValue(params.value);
  }

  getValue() {
    return this.selectionField.value;
  }

  // Gets called once before editing starts, to give editor a chance to
  // cancel the editing before it even starts.
  isCancelBeforeStart() {
    return false;
  }

  // Gets called once when editing is finished (eg if enter is pressed).
  // If you return true, then the result of the edit will be ignored.
  isCancelAfterEnd() {
    return false;
  }

  public onValueChange() {
    this.params.api.stopEditing();
  }

}
