<app-dialog-template #dialog [title]="title">
  <header>
    <div class="flexAlignRowCenter">
    <button nbButton size="tiny" status="primary" (click)="alterRegData()">Confirmar Alterações</button>
    </div>
  </header>
  <content>
    <div style="height: 40vh; width: 80vw;" [nbSpinner]="updating">
      <app-t2grid #gridRegData id="gridRegData" [sortable]="false" [components]="regDataGridComponents"> </app-t2grid>
    </div>
  </content>
</app-dialog-template>
