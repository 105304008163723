<div *ngIf="!readOnly" class="flexColumn txtArea" [formGroup]="formGroup">
  <textarea nbInput fieldSize="small" [id]="t2IdCmp" [hidden]="!visible" [ngStyle]="ngStyle" [placeholder]="placeHolder"
    [formControlName]="t2IdCmp" fullWidth>
  </textarea>
  <div *ngIf="formGroup.controls[t2IdCmp].value" class="copyFloatButton floatBtn">
    <nb-icon icon="copy-outline"></nb-icon>
  </div>
  <div *ngIf="formGroup.controls[t2IdCmp].value" [ngClass]="{'copyFloatButton floatBtn': true,
                'floatBtnActive': click}">
    <nb-icon icon="copy-outline" (click)="copyText()"></nb-icon>
  </div>
  <span *ngIf="additionalInfo && visible" class="defaultSmallText">{{additionalInfo}}</span>
</div>

<div *ngIf="readOnly && visible" class="flexColumn">
  <div class="T2CmpReadOnly txtArea">
    {{formGroup.controls[t2IdCmp].value}}
    <div *ngIf="formGroup.controls[t2IdCmp].value" class="copyFloatButton floatBtn">
      <nb-icon icon="copy-outline"></nb-icon>
    </div>
    <div *ngIf="formGroup.controls[t2IdCmp].value" [ngClass]="{'copyFloatButton floatBtn': true,
                'floatBtnActive': click}">
      <nb-icon icon="copy-outline" (click)="copyText()"></nb-icon>
    </div>
  </div>
</div>
