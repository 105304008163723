import { AfterViewInit, Component } from '@angular/core';
import { T2HttpClientService } from "../../../http/t2httpClient.service";
import { HttpClient } from "@angular/common/http";
import { take } from "rxjs/Operators";
import { NbDialogService } from "@nebular/theme";
import { DialogComponent } from "../../dialog/dialog.component";

@Component({
  selector: 'app-t2-oauth-autorized',
  templateUrl: './t2-oauth-autorized.component.html',
  styleUrls: ['./t2-oauth-autorized.component.scss']
})
export class T2OAuthAutorizedComponent implements AfterViewInit {


  private urlParam = {};
  status: { entity: string, email: string, id: string };

  constructor(public http: T2HttpClientService, private dialogService: NbDialogService) {

    const url = new URL(window.location.href);
    let params = url.hash || url.search;
    params = params.startsWith("#") ? params.substring(1) : params;
    params = params.startsWith("?") ? params.substring(1) : params;
    this.urlParam = params.split("&").map((p: string) => {
      const sp = p.split("=");
      switch (sp.length) {
        case 2:
          return { param: sp[0], value: sp[1] }

        case 1:
          return { param: sp[0], value: undefined }

        default:
          return { param: undefined, value: undefined }
      }
    })
      .reduce((acc, cur, i) => {

        if (cur.param == "scope") cur.value = decodeURIComponent(cur.value);

        acc[cur.param] = cur.value;
        return acc;
      }, {});

    this.status = JSON.parse(decodeURIComponent(this.urlParam["state"]));
    this.urlParam["state"] = this.status;
    this.urlParam["code"] = decodeURIComponent(this.urlParam["code"]);
  }


  ngAfterViewInit(): void {
    /*
    1 - Verifica se os parametros foram recebidos corretamente (access_token, email, id)
    2 - Envia as informações para o BACKEND (para revalidar o token recebido)
    3 - Se o BACKEND responder que deu tudo certo, exibe mensagem para o usuário e depois fecha a tela
    */

    console.log(this.urlParam);

    const params = new Map<string, string>();
    params.set("id_emailConnector", this.status?.id);

    this.http.post("core.email.oauth/validateOAuth", params, this.urlParam)
      .pipe(take(1))
      .subscribe(resp => {
        this.showMessage(`Autenticação realizada com sucesso`, null);
      }, error => {
        this.showMessage(`Não foi possível realizar a autenticação, tente novamente.`, error.message);
      });
  }

  private showMessage(title: string, error: string): void {
    this.dialogService.open(DialogComponent, {
      context: {
        topMessage: title,
        message2: error || undefined,
        actions: [{ description: error ? "Fechar" : "OK" }]
      }
    }).onClose.pipe(take(1)).subscribe(c => window.close());
  }

}
