export class ExceptionDetail {
  id: string;
  description: string;
  startDate: Date;
  endDate: Date;
  isProductive: boolean;
  id_workShift: string;
  workShift: string;
  isRecurrent: boolean;
}
