<div class="flexColumn fillContent" (click)="onComponentClick()">

  <div style="display: flex; justify-content: space-between; align-items: center; margin: 5px 0px 5px 0px;">
    <div style="font-size: 120%;">{{device?.description || ""}}</div>
    <div style="display: flex;">
      <app-t2-input-text #inputFilter t2IdCmp="busca" [placeHolder]="'Busca Rápida'"></app-t2-input-text>
    </div>
  </div>

  <div class="flexColumn flexLength1">
    <app-t2grid class="flexLength1" #gridSequence rowSelection="multiple" pagination="false" (selectionChanged)="selectionChanged()"
      [gridStateName]="device?.id ? 'aps_'+device.description : undefined" [component]="gridContextComponent"
      [loading]="loadingGrid" [components]="sequenceGridComponents" [tooltipMouseTrack]="false"
      [tooltipComponentName]="'sequenceTaskDetail'" (allFiltersCleared)="filtersCleared()">
    </app-t2grid>
  </div>

</div>
