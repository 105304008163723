import { Component, ComponentRef, Input, OnDestroy, OnInit, reflectComponentType } from '@angular/core';
import { FormGroup } from "@angular/forms";
import { T2MessageService } from "../../t2-message.service";
import { IT2CmpValueChange } from "../ui/it2-cmp-value-change";
import { T2ButtonComponent } from "../ui/t2-button/t2-button.component";
import { T2MenuButtonComponent } from "../ui/t2-menu-button/t2-menu-button.component";
import { ActionType, T2ViewTemplateAction } from "./model/t2-view-template-action";
import { T2ViewTemplateData } from "./model/t2-view-template-data";
import { Condition, ConditionalOperator, InputType, T2ViewTemplateFlow, T2ViewTemplateFlowConditionalOutput } from "./model/t2-view-template-flow";
import { LayoutType, ViewTemplateElement } from "./model/view-template-element";
import { T2ViewTemplateFunctionsService } from "./t2-view-template-functions.service";
import { ActionService } from "src/app/core/action/action.service";
import { take, takeUntil } from "rxjs/Operators";
import { T2ScriptService } from "../../script/t2-script.service";
import { ViewTemplateService } from "./view-template.service";
import { Subject } from "rxjs";

@Component({
  selector: 'app-view-template',
  templateUrl: './view-template.component.html',
  styleUrls: ['./view-template.component.scss'],
  providers: [T2ViewTemplateFunctionsService, ViewTemplateService]
})
export class ViewTemplateComponent implements OnInit, OnDestroy {

  @Input()
  get layout(): ViewTemplateElement[] {
    return this._layout
  }
  set layout(value: ViewTemplateElement[]) {
    if (!this._layout) {
      this._layout = value;
    } else {
      this._layout = value;
      this.unsubscribe.next();
      this.layout.forEach(l => this.generateDefaultCmpInputs(l, true));
    }
  }

  @Input() data: Array<T2ViewTemplateData>;
  @Input() flows: Array<T2ViewTemplateFlow>;
  @Input() actions: Array<T2ViewTemplateAction>;
  @Input() FormGroup: FormGroup;

  private _layout: ViewTemplateElement[];
  private unsubscribe = new Subject<void>();
  public cmpInputs = new Array<{ cmpName: string, name: string, data: any }>();
  public cmpReferenceList = new Array<ComponentRef<any>>();

  constructor(private funcService: T2ViewTemplateFunctionsService, private messageService: T2MessageService, private actionService: ActionService,
    private scriptService: T2ScriptService) {
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  ngOnInit(): void {
    this.layout.forEach(l => this.generateDefaultCmpInputs(l));
    this.funcService.initialize(this.FormGroup, this.cmpInputs);
  }

  private generateDefaultCmpInputs(layout: ViewTemplateElement, updatingLayout?: boolean): void {

    if (!layout) return;

    if (layout.children) {
      layout.children.forEach(child => this.generateDefaultCmpInputs(child, updatingLayout));
    }

    if (layout.layoutType == LayoutType.component) {
      let componentMirror = reflectComponentType(layout.cmpType);

      if (!layout.inputs) {
        layout.inputs = {};
      }

      if (layout.isBaseComponent) {
        this.FormGroup.controls[layout.cmpName].valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe(v => this.valueChange({ id: layout.cmpName }));

        componentMirror.inputs.forEach(input => {
          let dataInput = layout.inputs[input.templateName] || this.data?.find(d => d.cmpName == layout.cmpName)?.properties[input.templateName];
          let index = this.cmpInputs.findIndex(inp => inp.cmpName == layout.cmpName && inp.name == input.templateName);
          if (dataInput != undefined) {
            if (!updatingLayout || index < 0) {
              index = this.cmpInputs.push({
                cmpName: layout.cmpName,
                name: input.templateName,
                data: dataInput
              }) - 1;

              layout.inputs[this.cmpInputs[index].name] = this.cmpInputs[index].data;
            } else if (index >= 0) {
              layout.inputs[this.cmpInputs[index].name] = this.cmpInputs[index].data;
            }
          } else {
            if (!updatingLayout || index < 0) {
              let value: any;

              switch (input.templateName) {
                case "t2IdCmp":
                  value = layout.cmpName;
                  break;
                case "visible":
                  value = true;
                  break;
                case "readOnly":
                  value = false;
                  break;
                case "placeHolder":
                  value = "";
                  break;
                case "ngStyle":
                  value = {}
                  break;

                default:
                  value = undefined
              }

              index = this.cmpInputs.push({
                cmpName: layout.cmpName,
                name: input.templateName,
                data: value
              }) - 1;

              layout.inputs[this.cmpInputs[index].name] = this.cmpInputs[index].data;
            } else if (index >= 0) {
              layout.inputs[this.cmpInputs[index].name] = this.cmpInputs[index].data;
            }
          }
        });
      } else {
        componentMirror.inputs.forEach(input => {
          let dataInput = this.data?.filter(d => d.cmpName == layout.cmpName)[0]?.properties[input.templateName];
          if (dataInput != undefined) {
            let index = this.cmpInputs.push({
              cmpName: layout.cmpName,
              name: input.templateName,
              data: dataInput
            }) - 1;

            layout.inputs[this.cmpInputs[index].name] = this.cmpInputs[index].data;

          } else {
            let value;
            if (input.templateName == "t2IdCmp") {
              value = layout.cmpName
            } else {
              value = layout.inputs[input.templateName];
            }

            let index = this.cmpInputs.push({
              cmpName: layout.cmpName,
              name: input.templateName,
              data: value
            }) - 1;

            layout.inputs[this.cmpInputs[index].name] = this.cmpInputs[index].data;
          }
        });
      }
    }
  }

  public valueChange(event: IT2CmpValueChange) {
    this.executeActions(event.id);
  }

  public btnClick(id: any) {
    this.executeActions(id);
  }

  private executeActions(cmpName: string): void {
    if (this.flows && this.flows.length > 0) {
      let cmpFlows = this.flows.filter(f => f.triggerCmpNameList.filter(cmp => cmp == cmpName).length > 0);
      if (cmpFlows && cmpFlows.length > 0) {
        cmpFlows.forEach(flow => {
          try {
            if (flow.action?.type == ActionType.changeValue) {
              let params = {};
              for (let [key, value] of this.generateFlowInputParams(flow)) {
                params[key] = value;
              }

              let result: any

              if (Object.keys(params).length)
                result = this.funcService[flow.action.methodName](params)
              else
                result = this.funcService[flow.action.methodName]();

              if (flow.outputs && flow.outputs.length > 0) {
                let originalResult = result;
                flow.outputs.forEach(out => {
                  result = originalResult;
                  if (out.conditionList) {
                    let conditionalResult = this.getConditionalOutputValue(result, out.conditionList);

                    if (conditionalResult != null) {
                      result = conditionalResult;
                    }
                  }

                  if (out.cmpInputName) {
                    this.setCmpInputValue(out.cmpName, out.cmpInputName, result);
                  } else {
                    this.funcService.setCmpValue({ cmpName: out.cmpName, value: result, emitChangeEvent: out.emitChangeEvent })
                  }
                });
              }
            } else if (flow.action?.type == ActionType.runAction) {
              let params = this.generateFlowInputParams(flow);

              if (flow.action.adjustResponse) {
                flow.action.adjustResponse(this.actionService.executeAction(flow.action.action, params)).pipe(take(1)).subscribe(outputList => {
                  outputList.forEach(out => {

                    if (out.cmpInputName) {
                      this.setCmpInputValue(out.cmpName, out.cmpInputName, out.value);
                    } else {
                      this.funcService.setCmpValue({ cmpName: out.cmpName, value: out.value, emitChangeEvent: out.emitChangeEvent })
                    }
                  })
                })
              } else {
                this.actionService.executeAction(flow.action.action, params).pipe(take(1)).subscribe(resp => { });
              }
            } else if (flow.action?.type == ActionType.runScript) {
              let params = this.generateFlowInputParams(flow);

              if (flow.action.scriptName) {
                this.setFlowOutputCmpLoading(flow, true);
                this.scriptService.executeScript(flow.action.scriptName, params)
                  .pipe(take(1))
                  .subscribe(strResp => {
                    let resp = JSON.parse(strResp);

                    flow.outputs?.forEach(out => {
                      let value = out.scriptObjKey ? resp[out.scriptObjKey] : resp;

                      if (out.cmpInputName) {
                        this.setCmpInputValue(out.cmpName, out.cmpInputName, value);
                      } else {
                        this.funcService.setCmpValue({ cmpName: out.cmpName, value: value });
                      }
                    });

                    this.setFlowOutputCmpLoading(flow, false);
                  });
              }
            }
          } catch (error) {
            this.messageService.showToastError(`Ocorreu um erro ao executar a ação "${flow.action?.methodName}" com a mensagem: ${error}`);
            console.error(error);
          }
        });
      }
    }
  }

  private getConditionalOutputValue(originalValue: any, conditionList: Array<T2ViewTemplateFlowConditionalOutput>) {
    let conditionalResult = null;

    for (let cond of conditionList) {
      switch (cond.operator) {
        case ConditionalOperator.equals: {
          if (originalValue == cond.inputValue) {
            conditionalResult = cond.outputValue;
          }

          break;
        }
        case ConditionalOperator.in: {
          if ((cond.inputValue as Array<any>).includes(originalValue)) {
            conditionalResult = cond.outputValue
          }

          break;
        }
        case ConditionalOperator.notIn: {
          if (!(cond.inputValue as Array<any>).includes(originalValue)) {
            conditionalResult = cond.outputValue
          }

          break;
        }
      }

      if (conditionalResult != null) {
        break;
      }
    }

    return conditionalResult;
  }

  private generateFlowInputParams(flow: T2ViewTemplateFlow): Map<string, any> {
    let params = new Map<string, any>();

    flow.inputs?.forEach(inp => {
      switch (inp.type) {
        case InputType.component: {
          if (inp.cmpInputName) {
            let cmpInpValue = this.funcService.getInputValue({ cmpName: inp.cmpName, inputName: inp.cmpInputName });
            params.set(inp.paramName, cmpInpValue)
          } else {
            params.set(inp.paramName, this.funcService.getCmpValue({ cmpName: inp.cmpName }));
          }

          switch (inp.condition) {
            case Condition.denyValue: {
              let value = params.get(inp.paramName);
              params.set(inp.paramName, !value);
              break;
            }
          }

          break;
        }
        case InputType.static: {
          params.set(inp.paramName, inp.paramValue);
          break;
        }
      }
    });

    return params;
  }

  public addCmpReference(cmp: ComponentRef<any>) {
    if (cmp.instance instanceof T2ButtonComponent || cmp.instance instanceof T2MenuButtonComponent) {
      cmp.instance.onClick = () => {
        this.btnClick(cmp.instance.t2IdCmp);
        cmp.instance.clicked.emit(cmp.instance.t2IdCmp);
      }
    }

    this.cmpReferenceList.push(cmp);
  }

  /**
   * Esse método não é permitido caso esteja usando 'flows' e 'actions'
   */
  public getCmpReference(cmpName: string): ComponentRef<any> {
    if (this.flows || this.actions) {
      throw new Error("O método 'getCmpReference' não pode ser invocado em um viewtemplate com 'flows' e 'actions'");
    }

    return this.cmpReferenceList.find(cmp => cmp.instance.t2IdCmp == cmpName);
  }

  /**
   * ATENÇÃO. Caso o retorno seja um objeto, NÃO ALTERE os valores das propriedades.
   * Para alterar valores use o método "setCmpInputValue"
   */
  public getCmpInputValue(cmpName: string, inputName: string): any {
    return this.cmpReferenceList.find(cmp => cmp.instance.t2IdCmp == cmpName)?.instance[inputName];
  }

  public setCmpInputValue(cmpName: string, inputName: string, inputValue: any) {
    let cmp = this.cmpReferenceList.find(cmp => cmp.instance.t2IdCmp == cmpName);

    if (cmp) {
      let componentMirror = reflectComponentType(cmp.componentType);

      if (componentMirror.inputs.some(inp => inp.templateName == inputName)) {
        let cmpInput = this.cmpInputs.find(cmpInp => cmpInp.cmpName == cmpName && cmpInp.name == inputName);

        if (cmpInput) {
          cmpInput.data = inputValue;
        } else {
          this.cmpInputs.push({ cmpName: cmpName, name: inputName, data: inputValue });
        }

        cmp.instance[inputName] = inputValue;
      } else {
        throw new Error(`Não há nenhum input "${inputName}" para o componente "${cmpName}"`);
      }
    } else {
      throw new Error(`Não há nenhum componente com o nome "${cmpName}"`);
    }
  }

  private setFlowOutputCmpLoading(flow: T2ViewTemplateFlow, loading: boolean) {
    if (flow.outputs && flow.outputs.length > 0) {
      flow.outputs.forEach(out => {
        let cmp = this.cmpReferenceList.find(cmp => cmp.instance.t2IdCmp == out.cmpName);

        if (cmp && "loading" in cmp.instance && typeof cmp.instance["loading"] == "boolean") {
          this.setCmpInputValue(out.cmpName, "loading", loading);
        }
      })
    }
  }
}
