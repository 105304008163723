import { SupplierComponent } from './../../../flx/cylinder-scheduler/supplier/supplier.component';
import { Component, Input, OnDestroy } from '@angular/core';
import { Subject } from "rxjs";
import { take, takeUntil } from "rxjs/Operators";
import { T2ThemingService } from "src/app/core/t2theming.service";
import { ApsService } from "../aps.service";
import { TaskPlan } from "../model/task-plan";
import { ApsProdOrder } from "../model/aps-prod-order";

@Component({
  selector: 'app-cylinder-detail',
  templateUrl: './cylinder-detail.component.html',
  styleUrls: ['./cylinder-detail.component.scss']
})
export class CylinderDetailComponent implements OnDestroy {

  dark = false;

  private unsubscribe = new Subject<void>();

  private _idd: string;
  private _idtp: string;
  private _id_task: string;
  private _id_ordemprod: string;

  public prodOrdInfo: ApsProdOrder;
  public taskDescription: string;
  public deviceDescription: string;

  cylinders = [
    { code: 'CR005610', name: 'Preto ', status: 'REVISAR' },
    { code: 'CR982393', name: 'Verde ', detail: 'Panetone 357', status: 'GRAVAR', supplier: 'ROTOGRAV' },
    { code: 'CR088129', name: 'Vermelho', status: 'GRAVAR', supplier: 'ROTOGRAV' },
    { code: '7CR90238', name: 'Branco ', detail: ' Registado BR016', status: 'OK', supplier: 'ROTOGRAV' },
  ];

  @Input()
  get id_device(): string { return this._idd }
  set id_device(id: string) { this._idd = id; this.getInfo(); }

  @Input()
  get id_task_plan(): string { return this._idtp };
  set id_task_plan(id: string) { this._idtp = id; this.getInfo() }

  @Input()
  get id_task(): string { return this._id_task };
  set id_task(id: string) { this._id_task = id; this.getInfo() }

  @Input()
  get id_ordemprod(): string { return this._id_ordemprod };
  set id_ordemprod(id: string) { this._id_ordemprod = id; this.getInfo() }

  constructor(public apsService: ApsService, private _themeService: T2ThemingService) {
    _themeService.getThemeNameObservable()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(theme => {
        this.dark = theme == "dark";
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  private getInfo() {
    if (!this._idd || !this._idtp || !this._id_ordemprod) return;

    this.apsService.getProdOrderInfo()
      .pipe(take(1))
      .subscribe((prodOrdList: ApsProdOrder[]) => {
        if (prodOrdList && prodOrdList.length > 0) {
          this.prodOrdInfo = prodOrdList.find(po => po.id_ordemprod == this.id_ordemprod);
          this.taskDescription = this.prodOrdInfo.taskList.find(t => t.id_task == this.id_task)?.description;

          let poTaskPlan = this.prodOrdInfo.taskList?.find(t => t.id_task == this.id_task)?.taskPlanList?.find(tp => tp.id_task_plan == this.id_task_plan);

          if (poTaskPlan) {
            this.deviceDescription = poTaskPlan.device;
          }
        }
      });
  }

}
