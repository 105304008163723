<app-dialog-template #dialog [title]="'Parâmetros de carregamento da análise'"
  [ngStyle]="{'minWidth.vw': 40, 'maxHeight.vh': 65, 'maxWidth.vw': 88}">
  <content>
    <app-view-template *ngIf="layout" [layout]="layout" [data]="data" [FormGroup]="formGroup"></app-view-template>
  </content>
  <footer style="display: flex; gap: 5px; justify-content: flex-end; align-items: center;">
    <button nbButton status="basic" size="tiny" (click)="cancel()">Cancelar</button>
    <button nbButton status="primary" size="tiny" [disabled]="!formGroup.valid" (click)="confirm()">Carregar</button>
  </footer>
</app-dialog-template>
