<app-dialog-template #dialog [title]="periodDescription ? 'Fechamento de OP ' + periodDescription : 'Fechamento de OP'">
  <content style="max-width: 80vw; min-width: 40vw; max-height: 70vh">
    <div class="steps">

      <div *ngFor="let step of executionStepList" class="step">

        <div style="min-width: 25px; text-align: center; min-height: 25px;" [nbSpinner]="step.stage == 'running'"
          nbSpinnerStatus="primary" nbSpinnerSize="small">
          <!-- icone -->
          <nb-icon *ngIf="step.stage == 'waiting'" icon="radio-button-off-outline"></nb-icon>
          <nb-icon *ngIf="step.stage == 'done'" icon="checkmark-circle-outline" status="success"></nb-icon>
          <nb-icon *ngIf="step.stage == 'error'" icon="checkmark-circle-outline" status="danger"></nb-icon>
        </div>

        <div [ngClass]="{'stepVermelho' : step.stage == 'error' }">
          {{step.description}}
        </div>

      </div>
    </div>
    <div>
      <textarea style="max-width: 80%; min-width: 40vw; max-height: 80%; font-size: 80%;" *ngIf="error" nbInput
        [(ngModel)]="textError" readonly></textarea>
    </div>
  </content>
  <footer>
    <div *ngIf="!started || error">
      <div style="display: flex; justify-content: flex-end; gap: 5px;">
        <button nbButton status="primary" size="tiny" (click)="executeClosingProductionOrderSteps()"
          [disabled]="executing">Processar Fechamento</button>
        <button nbButton status="primary" size="tiny" (click)="closeDialog('Fechar')"
          [disabled]="executing">Cancelar</button>
      </div>
    </div>
    <div *ngIf="executing || success" style="display: flex; justify-content: flex-end;">
      <button nbButton status="primary" size="tiny" (click)="closeDialog('Fechar')"
        [disabled]="executing">Fechar</button>
    </div>
  </footer>
</app-dialog-template>
