<div class="flexColumn flexGap5">
  <div *ngIf="canInsert">
    <app-t2-button status="primary" size="tiny" value="Inserir" (clicked)="insertRecord()" />
  </div>
  <div cdkDropList [cdkDropListDisabled]="!hasSortOrder" (cdkDropListDropped)="onDrop($event)">
    <div *ngFor="let compId of compIdList; let i = index" cdkDrag [id]="'divDrag' + i">
      <div class="flexAlignRowStartCenter">
        <div *ngIf="canDelete">
          <button nbButton outline size="small" status="danger" (click)="deleteRecord(compId)">
            <nb-icon icon="trash-2-outline"></nb-icon>
          </button>
        </div>
        <app-t2-auto-form #autoForm style="width: 100%;" [datasetName]="datasetName" [informationId]="compId"
          [changeTitle]="false" [layoutFormList]="true" [loadIdFromUrl]="false" [inFormList]="true"
          [formTemplateUseFlexLayout]="false" [hideHeader]="true" [hideButtons]="true" [parentId]="parentId"
          (loadingCompleted)="detectChanges(compId)" />
      </div>
    </div>
  </div>
  
</div>