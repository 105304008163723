<app-form-template #formTemplate formTitle="Separação de MP para OP" [loaded]="loaded" [nbSpinner]="loadingTransfer"
  nbSpinnerSize="giant" nbSpinnerStatus="primary">
  <content>
    <app-manual-separation *ngIf="separationType == 'Manual' && !showTransfer"
      (startTransfer)="startTransfer($event)" [hasCoordinatorAccess]="hasCoordinatorAccess"/>
    <div *ngIf="separationType == 'Automático' && !showTransfer">
      <h5>A separação de OP no modo de escolha "Automático" não está disponível no Techprod WEB no momento</h5>
    </div>

    <div *ngIf="showTransfer">
      <app-separation-transfer [taskRequisition]="transferRequisition" (loadingInfo)="loadingInfoTransfer($event)"
        (transferCompleted)="transferCompleted()"></app-separation-transfer>
    </div>
  </content>
  <footer>
    <div *ngIf="showTransfer">
      <app-t2-button icon="arrow-back-outline" status="basic" size="tiny" value="Voltar"
        (clicked)="btnVoltarClick()"></app-t2-button>
    </div>
  </footer>
</app-form-template>