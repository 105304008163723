import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-stock-detail-dialog',
  templateUrl: './stock-detail-dialog.component.html',
  styleUrls: ['./stock-detail-dialog.component.scss']
})
export class StockDetailDialogComponent implements OnInit {

  private _itemId: string = null;
  @Input()
  get itemId(): string { return this._itemId; }
  set itemId(value: string) { this._itemId = value; }

  @Input()
  get id(): string { return this._itemId; }
  set id(value: string) { this._itemId = value; }

  public loaded = false;
  public itemDescr: string;

  constructor() { }

  ngOnInit(): void {
  }

  onLoadedChange(value: { descr: string, loaded: boolean }): void {
    this.itemDescr = value.descr;
    this.loaded = value.loaded;
  }

}
