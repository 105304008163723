<div class="container" [ngClass]="{T2GrayArea: !smallWindow}" [ngStyle]="{'padding': smallWindow ? '0px' : '10px'}">


  <div class="flexGap5" style="display: flex; justify-content: space-between; position: relative;">
    <app-shop-floor-singletask-panel #appSingleTaskPanel [taskPlan]="taskPlan" [searchedTaskPlan]="searchedTaskPlan"
      style="width: 100%;" />

    <div class="searchFloatButton searchButton" nbTooltip="Buscar OP" (click)="btnSearchClick() ">
      <nb-icon icon="search"></nb-icon>
    </div>
  </div>

  <div class="content">
    <app-shop-floor-sequencing *ngIf="selectedPanel == 'sequencing'" style="width: 100%; height: 100%;"
      [id_device]="device?.deviceId" (taskPlanSelected)="selectTaskPlanSequencing($event)" />
    <app-shop-floor-appointment *ngIf="selectedPanel == 'appointment'" style="width: 100%; height: 100%;"
      [device]="device" [taskPlan]="taskPlan" [searchedTaskPlan]="searchedTaskPlan" (lockScreen)="setLockScreen($event)"
      (taskPlanChanged)="taskPlanChanged($event)" />
    <app-shop-floor-task-history *ngIf="selectedPanel == 'document'"
      style="width: 100%; height: 100%; display: flex; flex-direction: column;" [taskPlan]="currentTaskPlan"
      [device]="device" (justifyOperationFinished)="goBackToDocument($event)" (lockScreen)="setLockScreen($event)" />
  </div>

  <div class="footer">
    <app-t2-menu-button caption="Dashboard Tela Cheia" (clicked)="closeTaskPanel()" />
    <app-t2-menu-button caption="Sequência Online" [status]="selectedPanel == 'sequencing' ? 'success' : 'basic'"
      (click)="selectPanel('sequencing')" />
    <app-t2-menu-button caption="Apontamento" [status]="selectedPanel == 'appointment' ? 'success' : 'basic'"
      (click)="selectPanel('appointment')" />
    <app-t2-menu-button [disabled]="!currentTaskPlan?.id_taskPlan" caption="Histórico & Documentos"
      [status]="selectedPanel == 'document' ? 'success' : 'basic'" (click)="selectPanel('document')" />
  </div>
</div>