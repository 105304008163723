import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { T2gridComponent } from "src/app/core/cmp/t2grid/t2grid.component";
import { NbDialogRef } from "@nebular/theme";
import { DialogTemplateComponent } from "src/app/core/cmp/dialog-template/dialog-template.component";

@Component({
  selector: 'app-engrave-invoice',
  templateUrl: './engrave-invoice.component.html',
  styleUrls: ['./engrave-invoice.component.scss']
})
export class EngraveInvoiceComponent implements OnInit {

  public suppNameFilter: string;
  public supplierList: Array<any> = [];  
  public suggestedSupplier: string;

  constructor(public dialogRef: NbDialogRef<EngraveInvoiceComponent>) {        
  }

  @ViewChild("gdForn", { static: true }) gdForn: T2gridComponent;
  @ViewChild('dialog', { static: true }) dialog: DialogTemplateComponent;  
  @ViewChild("dtEnvio") dtEnvio: ElementRef;

  ngOnInit() {
    this.load();
  }

  load(){    
    this.gdForn.setGridColumnDefs(
    [
      { checkboxSelection: true, lockPosition: true, pinned: "left", valueGetter: "node.rowIndex + 1", width: 45, suppressNavigable: true },
      { headerName: "Cód", field: "code" },
      { headerName: "Nome", field: "supplierName" },
      { headerName: "Doc", field: "document" },
    ]);    

    this.gdForn.setGridData(this.supplierList, null);     
  }

  onGDFornDoubleClicked(event) {    
    this.gdForn.setSelected(event.data.ID);
    this.gdForn.onGDSelectionChanged(event);
    this.closeWindow(true);
  }

  closeWindow(confirm: boolean) {                    
    const result = {
      confirm: confirm,
      id_conta: this.gdForn.getRowsSelected().length ? this.gdForn.getRowsSelected()[0].id_conta : null,
      dtEnvio: this.dtEnvio.nativeElement.value ? this.dtEnvio.nativeElement.value : null
    };

    this.dialogRef.close(result);
  }

  onSuppNameKeyEnter(event) {
    const filterText: string = event.target.value;

    const gridFilter = this.gdForn.getFilterInstance("supplierName");
    gridFilter.setModel(null);
    if (filterText && filterText.trim()) {
      gridFilter.setModel({ type: "contains", filter: filterText });
    }
    this.gdForn.callOnFilterChanged();
  }

  onGDFornGridReady() {
    if (this.suggestedSupplier) {
      this.gdForn.setSelected(this.supplierList.filter(s => s.supplierName == this.suggestedSupplier)[0].ID);
    }
  }

}
