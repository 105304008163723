<app-form-template #formTemplate formTitle="Dicionário TechPROD" [loaded]="loaded" beta="true">
  <!-- TOOLBAR -->
  <header>
    <button nbButton size="tiny" [status]="'primary'" (click)="realodServerCache()" [disabled]="reloadingCache"
      [nbSpinner]="reloadingCache" nbSpinnerStatus="primary">
      Realod cache
    </button>
  </header>

  <!-- FUNCTIONS -->
  <content class="flexRow flexGap10" [ngStyle.sm]="{'flex-direction': 'column'}"
    [ngStyle.xs]="{'flex-direction': 'column'}" style="height: 100%">
    <app-dict-dataset [ngStyle]="{'flex': '100%' }" [ngStyle.sm]="{'flex': '70%' }" [ngStyle.xs]="{'flex': '70%' }"
      (selectDataset)="onSelectDS($event)"></app-dict-dataset>

    <div [ngStyle]="{'flex': '500px' }" [ngStyle.sm]="{'flex': '30%' }" [ngStyle.xs]="{'flex': '30%' }"
      class="flexColumn grayBackground flexGap10">

      <div *ngIf="dataset" style="text-align: right;" class="grayBackground">
        <div style="font-size: 1.2rem; font-weight: bold">{{dataset?.datasetName}}</div>
        <div style="font-size: 0.8rem">{{dataset?.datasetDescription}}</div>

        <div style="display: flex; justify-content: flex-end; align-items: center; gap: 5px; margin-top: 10px;">
          <app-t2-button status="primary" size="tiny" [disabled]="!canEditAF || !dataset.id_autoform"
            value="Editar Autoform" (clicked)="editDataset()" />

          <app-t2-button status="basic" size="tiny" [nbSpinner]="savingDefVisualization"
            [disabled]="savingDefVisualization" value="Default Visualization" (clicked)="defaultVisualization()" />

          <app-t2-button status="basic" size="tiny" [nbSpinner]="compilingDataset"
            [disabled]="compilingDataset" value="Compile" (click)="compileDataset()" />

          <app-t2-button status="basic" size="tiny" value="Controles" (clicked)="editDatasetControls()"/>
        </div>
      </div>



      <!-- Colocar aqui as funcionalidades para o dataset selecionado -->
      <div *ngIf="dataset" style="font-style: italic; font-size: 80%">
        Aqui estarão os detalhes do dataset selecionado e as suas configurações para customização
      </div>
    </div>
  </content>
</app-form-template>