import { AfterViewInit, Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { BarcodeScannerLivestreamComponent } from "ngx-barcode-scanner";

@Component({
  selector: 'app-t2-scanner',
  templateUrl: './t2-scanner.component.html',
  styleUrls: ['./t2-scanner.component.scss']
})
export class T2ScannerComponent implements AfterViewInit {

  @ViewChild(BarcodeScannerLivestreamComponent) barcodeScanner: BarcodeScannerLivestreamComponent;
  @Output() scanSuccess = new EventEmitter<string>();

  public formats = ["code_128"]


  ngAfterViewInit(): void { 
    this.barcodeScanner.start(); 
  }

  onValueChanges(result) {
    this.scanSuccess.emit(result.codeResult.code);
  }

  onStarted(started) {
    
  }
}
