<div class="content">

  <div class="csStockList T2GrayArea">

    <div *ngFor="let cs of companySiteStock" class="csStock T2GrayArea">

      <div class="fontCS">{{cs.code}}</div>

      <div class="csStockQuantity">
        <div class="fontQtyCS">
          <div style="font-size: 50%; line-height: 70%; font-weight: bold">Local com dispon.</div>
          {{cs.inAvailAdresses | number:'1.0-2'}}
        </div>
        <div class="fontQtyCS">
          <div style="font-size: 50%; line-height: 70%; font-weight: bold">Local sem dispon.</div>
          {{cs.inUnavailAdresses | number:'1.0-2'}}
        </div>
      </div>
    </div>
  </div>

  <div style="flex: 1">

    <nb-tabset (changeTab)="tabChange($event)">

      <nb-tab tabTitle="Locais e Volumes" style="height: calc(100% - 48px);">
        <div class="T2GrayArea T2GrayBackground"
          style="height: 100%; margin: 0px -8px 0px -8px; display: flex; gap: 5px">

          <div style="overflow: auto; flex: 1">

            <div *ngFor="let sa of stockAdresses" class="T2GrayArea T2GrayBackground"
              style="display: flex; gap: 10px; margin-bottom: 5px;" [ngStyle.xs]="{'flex-direction': 'column'}">

              <div [ngStyle]="{'min-width': '20rem'}" [ngStyle.xs]="{'flex': 'auto'}">

                <div class="flexAlignRowSpaceBetweenStart">
                  <div style="font-weight: bold;">
                    <div>{{sa.code}}</div>
                    <div>{{sa.name}}</div>
                  </div>
                  <div class="fontQtyCS flexLength1">
                    <div style="font-size: 50%; line-height: 70%; font-weight: normal">Quantidade</div>
                    {{sa.stock | number:'1.0-2'}}
                  </div>
                </div>

                <div style="font-size: 80%; margin-top: 10px; line-height: 100%;">
                  Local indica <strong [ngStyle]="{'color': sa.avail ? greenName : redName}">{{sa.avail ?
                    "disponibilidade" : "indisponibilidade"}}</strong> dos volumes
                </div>
              </div>

              <div class="flexRow flexWrap flexLength1">

                <div class="T2GrayArea T2GrayBackground boxVolume" *ngFor="let vol of sa.volumes"
                  [ngStyle]="{'max-width': '315px', 'margin-right': '5px', 'min-width' : '315px'}"
                  [ngStyle.sm]="{'max-width': '232px', 'min-width' : '232px', 'margin-right': '5px'}"
                  [ngStyle.xs]="{'width': '100%','margin-right': '0px;'}">

                  <div *ngIf="printing && id_volume_printing == vol.id_volume" [nbSpinner]="printing"
                    style="display: flex; justify-content: center; align-items: center; width: 100%; height: 100%;">
                    Gerando etiqueta do volume
                  </div>

                  <div *ngIf="!printing || id_volume_printing != vol.id_volume" class="flexColumn"
                    style="justify-content: space-between; width: 100%; height: 100%; position: relative;">

                    <div style="display: flex; justify-content: space-between;">
                      <div style="font-weight: bold;">{{vol.code}}</div>
                      <div class="fontQtyCS">
                        <div style="font-size: 50%; line-height: 70%; font-weight: bold">Quantidade</div>
                        {{vol.stock | number:'1.0-2'}}
                      </div>
                    </div>

                    <div class="volOrigin">
                      <span>
                        {{vol.operation}} em {{vol.date | date : 'shortDate'}}
                      </span>

                      <span *ngIf="vol.detail" style="font-size: 0.65rem;">
                        {{vol.detail}}
                      </span>

                    </div>


                    <div id="buttonBg">
                      <div class="printFloatButton" (click)="printVolumeReport(vol.id_volume)">
                        <nb-icon icon="printer"></nb-icon>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>

            <div *ngIf="!stockAdresses.length" class="noExistingVolumes">
              Não existem volumes deste material em estoque</div>
          </div>
        </div>

      </nb-tab>

      <nb-tab tabTitle="Movimentação Futura" style="height: calc(100% - 48px);">
        <!-- <div class="T2GrayArea T2GrayBackground" style="height: 100%; margin: 0px -8px 0px -8px"> -->
        <div style="display: flex; gap: 5px;">
          <div class="T2GrayArea T2GrayBackground">
            <div class="fontQtyBox">+{{stockQtt.ocQtt | number:'1.0-2'}}</div>
            <div class="fontQtyTextBox">Em Compras</div>
          </div>
          <div class="T2GrayArea T2GrayBackground">
            <div class="fontQtyBox">-{{stockQtt.pvQtt | number:'1.0-2'}}</div>
            <div class="fontQtyTextBox">Em Ped Venda</div>
          </div>
          <div class="T2GrayArea T2GrayBackground">
            <div class="fontQtyBox">-{{stockQtt.reqQtt | number:'1.0-2'}}</div>
            <div class="fontQtyTextBox">Req consumo</div>
          </div>
          <div class="T2GrayArea T2GrayBackground">
            <div class="fontQtyBox">+{{stockQtt.opQtt | number:'1.0-2'}}</div>
            <div class="fontQtyTextBox">Em Produção</div>
          </div>
        </div>
        <div style="height: calc(100% - 64px);; margin-top: 10px;">
          <app-t2grid #gridFutureMov [loading]="loadingFutureMov" gridStateName="gridFutureMov"></app-t2grid>
        </div>
        <!-- </div> -->
      </nb-tab>

      <nb-tab tabTitle="Histórico de Movimentação" style="height: calc(100% - 48px);">
        <!-- <div class="T2GrayArea T2GrayBackground" style="height: 100%; margin: 0px -8px 0px -8px"> -->
        <app-t2grid #gridHist [component]="gridContextComponent" [loading]="loadingHist" gridStateName="gridHist"
          [components]="gridHistComponents">
        </app-t2grid>
        <!-- </div> -->
      </nb-tab>

    </nb-tabset>

  </div>

  <!-- </div> -->
</div>