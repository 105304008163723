<app-dialog-template #dialog class="w" title="Informe o motivo para gravação ou recromo">

  <content class="wContent flexColumn" style="gap: 10px">

    <div>
      <div class="inpuTitle">Motivo</div>
      <nb-select [(selected)]="selectedItem" style="width: 100%;" size="small">
        <nb-option *ngFor="let motive of motiveList" [value]="motive.id + 1">{{motive.motive}}</nb-option>
      </nb-select>
    </div>

    <div style="width: 100%; margin-top: 5px;">
      <div class="inpuTitle">Observação</div>
      <input style="width: 100%; min-width: 400px;" nbInput [(ngModel)]="motiveObs" fieldSize="small" />
    </div>

  </content>

  <footer class="flexAlignRowEndCenter flexGap3 wFooter">
    <button nbButton size="tiny" status="danger" (click)="closeWindow(false)">
      Cancela
    </button>
    <button nbButton size="tiny" status="primary" [disabled]="!selectedItem" (click)="closeWindow(true)">
      Confirma
    </button>
  </footer>

</app-dialog-template>
