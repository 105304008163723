<div style="min-height: 75vh; min-width: 88vw; height: 100%; width: 100%;">
  <app-form-template #formTemplate [formTitle]="title" [loaded]="loaded" [showHeader]="showHeader"
    [changeTitle]="!inDialog">
    <content style="display: flex; gap: 15px; width: 100%; height: 100%;">

      <!-- COMPONENTES - NECESSIDADES -->
      <div class="flexColumn" style="min-width: 400px; max-width: 500px; width: 30%; height: 100%;">

        <div style="font-weight: bold; padding: 5px;">
          Componentes da Ordem de Produção
        </div>


        <div
          style="width: 100%; height: 100%; overflow: hidden auto; border-radius: 5px; background-color: rgba(212, 212, 212, 0.277);">

          <div *ngFor="let cmp of componentNeeds" class="cmp" style="display: flex; gap: 5px" (click)="selecComponent(cmp)"
            [ngStyle]="{'background-color': cmp.id_component == selectedComponent?.id_component ? 'rgba(212, 212, 212, 0.277)' : ''}">
            <div class="cmpPart" style="width: 100%;">
              <div style="font-weight: bold;">{{cmp.description}}</div>
              <div>{{cmp.componentDetail}} - {{cmp.task}}</div>
            </div>

            <div style="width: 110px; text-align: end;">
              <div style="width: 110px; text-align: end; font-weight: bold; font-size: 60%; margin: -7px 0px -7px 0px;">
                Necessário
              </div>
              <div class="cmpPart" style="width: 110px; text-align: end;">
                {{cmp.qttExpected | number: "1.0-2": "pt-BR"}} {{cmp.mu}}
              </div>
              <div style="width: 110px; text-align: end; font-weight: bold; font-size: 60%; margin: 0px 0px -7px 0px;">
                Empenhado
              </div>
              <div class="cmpPart" style="width: 110px; text-align: end;">
                {{cmp.qttCommitted | number: "1.0-2": "pt-BR"}}
              </div>
            </div>

          </div>

          <div *ngIf="!componentNeeds || componentNeeds.length == 0"
            style="margin: 5px 5px -5px 5px; padding: 5px; font-size: 110%;">
            Nenhum componente requer empenho
          </div>

        </div>

      </div>

      <!-- EMPENHO E ESTOQUE FUTURO -->
      <div class="flexColumn flexLength1 flexGap10">

        <div class="flexColumn" style="height: 100%">

          <div style="display: flex; gap: 15px; justify-content: flex-end; align-items: center; height: 30px">
            <app-filter-params *ngIf="this.gridReq && !this.gridReq.loading"
              [filterParams]="selectedComponent?.filterField" (updateFilter)="selecComponent(selectedComponent)">
            </app-filter-params>
            <div style="font-weight: bold; padding: 5px;">Itens compatíveis</div>
          </div>

          <app-t2grid class="flexLength1" #gridReq id="gridReq" [sortable]="true" (rowClick)="gridRecSelectRow($event)"></app-t2grid>

          <div style="display: flex; justify-content: flex-end; align-items: start; margin-top: 3px; height: 30px">
            <button nbButton status="primary" size="tiny" [disabled]="this.gridReq.loading"
              (click)="generateItemRequest()">Empenhar</button>
          </div>
        </div>

        <div class="flexColumn" style="height: 60vh;  margin-top: 10px">
          <div sstyle="display: flex; justify-content: space-between align-items: flex-end; font-weight: bold; padding: 5px;">
            <div>{{selectedSuggItem?.description}}</div>
            <div style="height: 20px">Estoque futuro</div>
          </div>
          <app-t2grid class="flexLength1" #gridStock id="gridStock"></app-t2grid>
        </div>

      </div>
    </content>
  </app-form-template>
</div>
