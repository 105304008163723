<div *ngIf="!readOnly" class="flexColumn" [formGroup]="formGroup">
  <div>
    <input #dtInput nbInput fieldSize="small" [id]="t2IdCmp" [hidden]="!visible" [ngStyle]="ngStyle"
      [placeholder]="placeHolder" [nbDatepicker]="datetimepicker" [formControlName]="t2IdCmp" fullWidth>
    <nb-date-timepicker #datetimepicker [withSeconds]="withSeconds" [format]="format">
    </nb-date-timepicker>
  </div>
  <span *ngIf="additionalInfo && visible" class="defaultSmallText">{{additionalInfo}}</span>
</div>

<div *ngIf="readOnly && visible" class="T2CmpReadOnly">
  {{formGroup.controls[t2IdCmp].value | date:format}}
</div>
