import { Component, Input, OnDestroy } from '@angular/core';
import { Subject } from "rxjs";
import { take, takeUntil } from "rxjs/Operators";
import { T2ThemingService } from "src/app/core/t2theming.service";
import { ApsService } from "../aps.service";
import { ApsProdOrder } from "../model/aps-prod-order";
import { TaskPlan } from "../model/task-plan";
import { ApsProdOrderTaskPlan } from "../model/aps-prod-order-task-plan";

/** TODO
 * - Carregar todas as tarefas da OP (com todas as datas planejadas - considerar que pode haver mais de um planejamento)
 * - Carregar todas as requisições da OP (com tarefa e disponibilidade de estoque)
 * - Carregar todas as demandas da OP (com suas datas de entrega)
 */

@Component({
  selector: 'app-task-detail',
  templateUrl: './task-detail.component.html',
  styleUrls: ['./task-detail.component.scss']
})
export class TaskDetailComponent implements OnDestroy {

  dark = false;

  private unsubscribe = new Subject<void>();

  private _idd: string;
  private _idtp: string;
  private _id_task: string;
  private _id_ordemprod: string;

  @Input()
  get id_device(): string { return this._idd }
  set id_device(id: string) { this._idd = id; this.getInfo(); }

  @Input()
  get id_task_plan(): string { return this._idtp };
  set id_task_plan(id: string) { this._idtp = id; this.getInfo() }

  @Input()
  get id_task(): string { return this._id_task };
  set id_task(id: string) { this._id_task = id; this.getInfo() }

  @Input()
  get id_ordemprod(): string { return this._id_ordemprod };
  set id_ordemprod(id: string) { this._id_ordemprod = id; this.getInfo() }

  prodOrdInfo: ApsProdOrder;
  taskDescription: string;
  deviceDescription: string;
  beforeMin = false;
  afterMax = false;
  taskPlan: ApsProdOrderTaskPlan;


  constructor(public apsService: ApsService, private themeService: T2ThemingService) {
    themeService.getThemeNameObservable()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(theme => {
        this.dark = theme == "dark";
      });

  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  private getInfo() {
    if (!this._idd || !this._idtp || !this._id_task || !this._id_ordemprod) return;

    this.apsService.getProdOrderInfo()
      .pipe(take(1))
      .subscribe((prodOrdList: ApsProdOrder[]) => {

        this.prodOrdInfo = prodOrdList?.find(po => po.id_ordemprod == this.id_ordemprod);
        if (this.prodOrdInfo) {
          this.taskDescription = this.prodOrdInfo.taskList.find(t => t.id_task == this.id_task)?.description;
          this.taskPlan = this.prodOrdInfo.taskList?.find(t => t.id_task == this.id_task)?.taskPlanList?.find(tp => tp.id_task_plan == this.id_task_plan);

          if (this.taskPlan) {
            this.deviceDescription = this.taskPlan.device;
            const planDate = new Date(this.taskPlan.startDate);

            this.apsService.getDeviceData(this.id_device)
              .pipe(take(1))
              .subscribe((tpList: TaskPlan[]) => {
                let taskPlan = tpList.find(tp => tp.id_task_plan == this.id_task_plan);
                if (taskPlan) {
                  this.beforeMin = !taskPlan.minDate ? false : new Date(taskPlan.minDate).getTime() > planDate.getTime();
                  this.afterMax = !taskPlan.maxDate ? false : new Date(taskPlan.maxDate).getTime() < planDate.getTime();
                }
              });
          }
        }
      });
  }

  public getPlanDevice(id_task_plan: string): string {
    return this.prodOrdInfo?.taskList?.find(t => t.taskPlanList?.some(tp => tp.id_task_plan == id_task_plan))?.taskPlanList?.find(tp => tp.id_task_plan == id_task_plan)?.device;
  }

  public getPlanDate(id_task_plan: string): Date {
    return this.prodOrdInfo?.taskList?.find(t => t.taskPlanList?.some(tp => tp.id_task_plan == id_task_plan))?.taskPlanList?.find(tp => tp.id_task_plan == id_task_plan)?.startDate;
  }

  public planCssClass(id_task: string, id_task_plan: string) {
    let task = this.prodOrdInfo?.taskList?.find(t => t.id_task == id_task);
    let taskPlan = task?.taskPlanList?.find(tp => tp.id_task_plan == id_task_plan);

    task.minDate = task?.minDate ? new Date(task.minDate) : undefined;
    task.maxDate = task?.maxDate ? new Date(task.maxDate) : undefined;
    taskPlan.endDate = taskPlan?.endDate ? new Date(taskPlan.endDate) : undefined;

    let cssClass: {
      taskOnTime: boolean,
      taskLate: boolean,
      taskAhead: boolean
    }


    cssClass = {
      taskOnTime: task?.minDate && task?.maxDate ? taskPlan.endDate.getTime() >= task.minDate.getTime() && taskPlan.endDate.getTime() <= task.maxDate.getTime() : false,
      taskLate: task?.maxDate ? taskPlan.endDate.getTime() > task.maxDate.getTime() : false,
      taskAhead: task?.minDate ? taskPlan.endDate.getTime() < task.minDate.getTime() : false
    }

    return cssClass;
  }
}
