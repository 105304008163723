<div *ngIf="favAccessList && favAccessList.length" class="box boxShadow">
  <div style="font-size: 80%; font-weight: bold;">
    Favoritos
  </div>
  <div style="display: flex; flex-wrap: wrap; gap: 5px">
    <div *ngFor="let access of favAccessList" class="favItem"
      (click)="menuService.selectItem(access);"
      [style.backgroundColor]="access.informationType === 3 ? 'var(--T2ColorMenuLightBlue)' : 'none'"
      [style.backgroundColor]="access.informationType === 0 ? 'var(--T2ColorMenuBlue)' : 'none'"
      [style.backgroundColor]="access.informationType === 1 ? 'var(--T2ColorMenuGreen)' : 'none'"
      [style.backgroundColor]="access.informationType === 5 ? 'var(--T2ColorMenuOrange)' : 'none'"
      [style.backgroundColor]="access.informationType === 4 ? 'var(--T2ColorMenuPurple)' : 'none'"
      [style.backgroundColor]="access.informationType === 2 ? 'var(--T2ColorMenuGray)' : 'none'">
      <div class="favItemDetail">
        <div><strong>{{access.informationGroupName}}</strong></div>
        <div>{{access.informationGroupSubName}}</div>
      </div>
      <div class="favItemName">{{access.name}}</div>

    </div>
  </div>

</div>
