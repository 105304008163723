<app-dialog-template #dialog title="Fixar Data">
  <content style="display: flex; gap: 10px">
    <span>Data</span>
    <div>
      <input nbInput fieldSize="small" [nbDatepicker]="datetimepicker" [(ngModel)]="fixedDate">
      <nb-date-timepicker #datetimepicker format="dd/MM/yyyy HH:mm"></nb-date-timepicker>
    </div>
  </content>
  <footer>
    <div class="wFooter">
      <div class="button-row" style="display: flex;">
        <button nbButton status="danger" size="tiny" (click)="closeDialog('Cancela')"
          style="margin-left: auto">Cancela</button>
        <button nbButton status="primary" size="tiny" (click)="runCallback()">Confirma</button>
      </div>
    </div>
  </footer>
</app-dialog-template>
