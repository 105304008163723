import { Component, ElementRef, ViewChild, Input, AfterContentInit } from '@angular/core';
import { DataInterfaceEdges, DataInterfaceNodes, Node, Edge, Network, Options } from "vis-network";

@Component({
  selector: 'app-t2-flow',
  templateUrl: './t2-flow.component.html',
  styleUrls: ['./t2-flow.component.scss']
})
export class T2FlowComponent implements AfterContentInit {

  @Input() shape: "dot" | "ellipse" | "circle" | "database" | "box" | "text" = "box";
  private _nodes: Node[] | DataInterfaceNodes = [];
  @Input() get nodes() { return this._nodes; } set nodes(value: Node[] | DataInterfaceNodes) { this._nodes = value; }
  private _edges: Edge[] | DataInterfaceEdges = [];
  @Input() get edges() { return this._edges; } set edges(value: Edge[] | DataInterfaceEdges) { this._edges = value; }

  @ViewChild('nwRef', { static: true }) nwRef: ElementRef;
  private network: Network;
  private options: Options = {
    layout: {
      hierarchical: { enabled: true, direction: 'LR' },
    },
    nodes: {
      shadow: { enabled: true }, shape: this.shape, size: 20, borderWidth: 2,
      margin: { top: 10, right: 15, bottom: 10, left: 15 },
      physics: true
    },
    edges: { arrows: { to: { enabled: true } } },
  };

  constructor() { }

  ngAfterContentInit(): void {
    this.network = new Network(this.nwRef.nativeElement, { nodes: this._nodes, edges: this._edges }, this.options);
    this.network.on("dragEnd", (params) => { this.network.stabilize(); });
    this.network.on("resize", (params) => { this.network.stabilize(); });
    setTimeout(() => {
      this.network.stabilize();
    }, 1000);
  }

}
