<nb-layout  style="height: 100%; width: 100%">
  <nb-layout-header fixed *ngIf="userAuthenticated" class="header"
    [ngStyle]="{'background-color': isHomolog ? 'rgba(26, 152, 0, 0.3)' : ''}">
    <div class="logo_techprod" [ngClass]="{
        logo_techprod_branco: (themeName$ | async) === 'dark',
        logo_techprod_preto: (themeName$ | async) != 'dark'
      }"></div>

    <div class="flexAlignColumnCenter">
      <div *ngIf="sec.sites?.length > 1" class="companySite">
        {{companySiteName}}
      </div>

      <div *ngIf="isHomolog" style="color: red; font-weight: bolder; text-align: center;">
        Homologação
      </div>

    </div>


    <!-- switch do tema -->
    <nb-icon *ngIf="(themeName$ | async) != 'dark'" icon="moon" nbTooltip="Mudar para modo escuro"
      style="margin: 6px 10px 0px auto; cursor: pointer; " (click)="themeService.changeTheme('dark');">
    </nb-icon>
    <nb-icon *ngIf="(themeName$ | async) === 'dark'" icon="sun" nbTooltip="Mudar para modo claro"
      style="margin: 6px 10px 0px auto; cursor: pointer;" (click)="themeService.changeTheme('default');">
    </nb-icon>

    <!-- Iniciais do usuario  -->
    <div style="display: flex; gap: 5px; margin-top: 10px; font-size: 75%;">
      <app-main-menu-item title="Avisos" icon="bell" (click)="$event.stopPropagation(); changeStatusNotificationBar()"/>
      <app-main-menu-item title="Home" icon="home" (click)="goHome()"></app-main-menu-item>
      <app-main-menu-item title="Menu" icon="keypad" (click)="goMenu()"></app-main-menu-item>
      <app-main-menu-item title="Sistema" icon="options-2" [nbContextMenu]="contextMenu" nbContextMenuTrigger="click">
      </app-main-menu-item>
    </div>

    <div *ngIf="showNotificationsBar" id="notificationsBar" class="notificationBox flexColumn" (click)="$event.stopPropagation();">
      <app-notification class="flexLength1" style="height: 100%;" [compactDisplay]="true"/>
    </div>

  </nb-layout-header>

  <nb-layout-column>
    <router-outlet></router-outlet>
  </nb-layout-column>

  <nb-layout-footer>
    <div style="width: 100%; display: flex; flex-direction: row; font-size: 70%">

      <div style="display: flex; justify-content: space-between; align-items: center; width: 100%;">

        <div [ngStyle.xs]="{'display': 'none' }" [ngStyle.sm]="{'display': 'none' }">
          <span> {{sec.userName}} </span>
        </div>

        <div
          style="display: flex; flex-direction: row; justify-content: flex-end; gap: 5px; align-items: center; line-height: 99%; ">
          <div>{{version}}</div>
          <div>
            <a href="http://www.techprod.com.br" target="_blank">TechPROD</a>
            e
            <a href="http://www.tech2e.com.br" target="_blank">Tech2e</a>
          </div>
          <div>® 2019-2024 &nbsp;Todos os direitos reservados.</div>
        </div>
      </div>
    </div>
  </nb-layout-footer>
</nb-layout>
