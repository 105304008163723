import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, Validators } from "@angular/forms";
import { DialogTemplateComponent } from "src/app/core/cmp/dialog-template/dialog-template.component";

@Component({
  selector: 'app-dialog-finished-reproves-packing',
  templateUrl: './dialog-finished-reproves-packing.component.html',
  styleUrls: ['./dialog-finished-reproves-packing.component.scss']
})
export class DialogFinishedReprovesPackingComponent implements OnInit {

  @Input() title: string;
  @Input() id_packingList: string;
  //@Input() reproveReason: string;

  reproveReasonField = new FormControl('', Validators.required);

  @ViewChild('dialog', { static: true }) dialog: DialogTemplateComponent;

  constructor() { }

  ngOnInit(): void {
  }

  confirm() {
    const resp = {
      type: 'confirm',
      id_packingList: this.id_packingList,
      reproveReason: this.reproveReasonField.value
    };

    this.dialog.close(resp);
  }

  cancel() {
    const resp = {
      type: 'cancel',
      id_packingList: this.id_packingList
    };

    this.dialog.close(resp);
  }

}
