<div class="progressIndex">
  <div *ngIf="!smallWindow" echarts [options]="co" [theme]="themeName" (chartInit)="onChartInit($event)"
    style="width: 100%; height: 100%;">
  </div>


  <div *ngIf="smallWindow" class="barra-externa">
    <div [style.width]="progress+'%'" [style.background-color]="bgColor[color]"
      [ngClass]="{'barra-interna': true, 'arredondada' : progress >= 100}">
      <span class="qttyInfo">{{progress | number: "1.0-2"}}%</span>
    </div>
  </div>

  <div *ngIf="title" class="title">{{title}}</div>
</div>
