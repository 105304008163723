import { Component, Input, OnInit } from '@angular/core';
import { take } from "rxjs/Operators";
import { MediaType } from "src/app/core/http/t2RequestInfo";
import { T2HttpClientService } from "src/app/core/http/t2httpClient.service";

@Component({
  selector: 'app-t2-image-draw',
  templateUrl: './t2-image-draw.component.html',
  styleUrls: ['./t2-image-draw.component.scss']
})
export class T2ImageDrawComponent implements OnInit{

  @Input() imageType: "svg" | "png" = "svg";
  @Input() script: string;
  @Input() fileName: string = "techprod_draw.svg";
  @Input() useWidth100: boolean = true;
  @Input("params")
  get params(): object {
    return this._params;
  }
  set params(value: object) {
    this._params = value;
    this.getDrawingImage();
  }

  public urlImage: string | ArrayBuffer = null;
  private _params: object = null;

  constructor(private httpClient: T2HttpClientService) { }

  ngOnInit(): void {
    this.getDrawingImage();
  }

  getDrawingImage() {

    if (!this.script || !this.params) return;

    const params = new Map<string, string>();
    params.set("script", this.script);
    params.set("fileName", this.fileName);
    if (this.params) params.set("params", JSON.stringify(this.params));
    this.httpClient.getBlob(`core.image/draw/${this.imageType}`, params, null)
      .pipe(take(1))
      .subscribe(resp => {
        let blobType: MediaType;
        switch (this.imageType) {
          case "svg":
            blobType = MediaType.SVG
            break;

          case "png":
            blobType = MediaType.PNG
            break;
        }

        const image = new Blob([resp], { type: blobType });
        const reader = new FileReader();
        reader.onloadend = (e) => this.urlImage = e.target.result;
        reader.readAsDataURL(image);

      }, error => {
        //tratar se der erro no back
      });
  }

}
