import { Injectable } from '@angular/core';
import { Observable } from "rxjs";
import { take, map } from "rxjs/Operators";
import { environment } from "src/environments/environment";
import { T2HttpClientService } from "../http/t2httpClient.service";

@Injectable({
  providedIn: 'root'
})
export class TechprodDevService {

  constructor(private httpClient: T2HttpClientService) { }

  realodServerCache(): Observable<any> {
    return this.httpClient.post("core.datasetCache/clearCache", null, null).pipe(take(1));
  }

  getDicDataset() {
    return this.httpClient.get("core.dev/dataset", null).pipe(
      take(1),
      map(resp => {
        return resp.datasetList;
      }));
  }

  compileDataset(datasetName: string) {
    const params = new Map<string, string>();
    params.set("datasetName", datasetName);
    return this.httpClient.get("core.dev/compile", params).pipe(
      take(1),
      map(resp => {
        return resp.datasetList;
      }));
  }

  devModeLog(log: string): void {
    if (!environment.production) {
      console.log('%cTECHPROD DEVELOPMENT ENVIRONMENT', 'padding: 10px 15px; background-color: #105a8b; color: white; border-radius: 7px; font-size: 0.8rem; width');
      console.log(">>> " + log)
    }
  }



}
