<div class="flexColumn fillContent" [nbSpinner]="loading" nbSpinnerSize="giant" nbSpinnerStatus="primary">
  <div class="flexColumn" style="gap: 3px; height: 100%;">
    <div style="display: flex; justify-content: space-between; align-items: center;">
      <div>
        <app-t2-button size="tiny" status="primary" value="Salvar" [disabled]="formGroup.pristine || !canSave"
          (click)="save()"></app-t2-button>
      </div>
      <div style="display: flex; gap: 5px; justify-content: flex-end; align-items: center;">
        <button nbButton outline size="tiny" status="primary" (click)="addLamination()">
          <nb-icon icon="plus-circle-outline"></nb-icon>
        </button>
        <button nbButton outline size="tiny" status="danger" [disabled]="!selectedLamination" (click)="delete()">
          <nb-icon icon="trash-2-outline"></nb-icon>
        </button>
      </div>
    </div>
    <div style="overflow: hidden auto; border-radius: 5px;">
      <div *ngFor="let lamination of laminationList"
        class="T2GrayAreaItemNoFill T2GrayHoverArea flexRow flexWrap flexGap10" style="width: 100%;"
        [ngClass]="{'T2GraySelectedArea':  lamination.id_especif_carac_lamSeq_item == selectedLamination?.id_especif_carac_lamSeq_item}"
        (click)="selectLamination(lamination)">
        <div class="flexColumn" style="flex: 0.25">
          <span class="cmpLabel">Descrição</span>
          <app-t2-input-text [formGroup]="formGroup"
            [t2IdCmp]="lamination.id_especif_carac_lamSeq_item + '_description'"></app-t2-input-text>
        </div>
        <div class="flexColumn"  style="flex: 0.25">
          <span class="cmpLabel">Camada 1</span>
          <app-t2-select #layer1 [formGroup]="formGroup" [t2IdCmp]="lamination.id_especif_carac_lamSeq_item + '_layer1'"
            [list]="lamination.layer1List"></app-t2-select>
        </div>
        <div class="flexColumn"  style="flex: 0.25">
          <span class="cmpLabel">Camada 2</span>
          <app-t2-select #layer2 [formGroup]="formGroup" [t2IdCmp]="lamination.id_especif_carac_lamSeq_item + '_layer2'"
            [list]="lamination.layer2List"></app-t2-select>
        </div>
        <div class="flexColumn">
          <span class="cmpLabel">Requer Adesivo</span>
          <app-t2-check-box [formGroup]="formGroup"
            [t2IdCmp]="lamination.id_especif_carac_lamSeq_item + '_requiresAdhesive'"></app-t2-check-box>
        </div>
        <div class="flexColumn">
          <span class="cmpLabel">Executar antes da impressão</span>
          <app-t2-check-box [formGroup]="formGroup"
            [t2IdCmp]="lamination.id_especif_carac_lamSeq_item + '_runBeforePrinting'"></app-t2-check-box>
        </div>
      </div>
    </div>
  </div>
</div>
