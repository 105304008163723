import { Component, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Observable, Subject } from "rxjs";
import { T2HttpClientService } from "src/app/core/http/t2httpClient.service";
import { DeviceCache, DeviceCacheService, DeviceCacheTask } from "../device-cache.service";
import { map, take, takeUntil } from "rxjs/Operators";
import { NbDialogService } from "@nebular/theme";
import { ActionService } from "src/app/core/action/action.service";
import { T2AccessItem, T2AccessItemDatasetActionType } from "src/app/core/security/model/t2accessItem";
import { T2SecurityService } from "src/app/core/security/t2security.service";
import { DialogItemSelectionComponent, GroupSelection, ItemSelection } from "src/app/core/cmp/dialog-item-selection/dialog-item-selection.component";
import { ActivatedRoute } from "@angular/router";
import { PanelType } from "../shop-floor-taskpanel/shop-floor-taskpanel.component";
import { WarningService } from "src/app/core/warning/warning.service";
import { Warning } from "src/app/core/warning/model/warning";
import { WarningComponent } from "src/app/core/warning/warning/warning.component";

@Component({
  selector: 'app-shop-floor-dashboard',
  templateUrl: './shop-floor-dashboard.component.html',
  styleUrls: ['./shop-floor-dashboard.component.scss']
})
export class ShopFloorDashboardComponent implements OnInit, OnDestroy {

  private unsubscribe = new Subject<void>();
  private unsubscribeDevice = new Subject<void>();
  private selectedPanel: PanelType = "sequencing";
  public loaded = false;
  public smallWindow: boolean;
  public deviceList = [];
  public groupList = new Array<{ id: string, description: string, deviceList: Array<DeviceCache> }>();
  private deviceDatasetId = "{418FEA12-1475-450C-8227-D7296915390B}";

  private _selectedIdDevice: string;
  @Input()
  get selectedIdDevice(): string { return this._selectedIdDevice }
  set selectedIdDevice(value: string) {
    this._selectedIdDevice = value;
    this.selectDeviceByID(this._selectedIdDevice, this._selectedIdTaskPlan, 'sequencing');
  }
  private _selectedIdTaskPlan: string;
  @Input()
  get selectedIdTaskPlan(): string { return this._selectedIdTaskPlan }
  set selectedIdTaskPlan(value: string) {
    this._selectedIdTaskPlan = value;
    this.selectDeviceByID(this._selectedIdDevice, this._selectedIdTaskPlan, this.selectedPanel ?? "sequencing");
  }

  @Output() deviceSelected = new EventEmitter<{ device: DeviceCache, taskPlan: DeviceCacheTask, tab: PanelType, clearSelection?: boolean }>();
  @Output() devicesLoaded = new EventEmitter<boolean>();

  constructor(
    private sec: T2SecurityService,
    private devCacheService: DeviceCacheService,
    private httpClient: T2HttpClientService,
    private dialogService: NbDialogService,
    private actionService: ActionService,
    private route: ActivatedRoute,
    private warningService: WarningService) { }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
    this.unsubscribeDevice.next();
    this.unsubscribeDevice.complete();
  }

  ngOnInit(): void {
    this.route.queryParams.pipe(takeUntil(this.unsubscribe)).subscribe(params => {
      this.selectedPanel = params["tab"];
    })


    this.httpClient.get("production.registration/getUserAllowedDevices", null)
      .pipe(
        take(1),
        map(resp => {
          if (resp?.devices?.device) {

            if (!Array.isArray(resp.devices.device)) {
              resp.devices.device = [resp.devices.device];
            }

            return resp.devices.device
              .sort((a, b) => a.code.localeCompare(b.code));
          }

          return [];
        }))
      .subscribe(devList => {
        this.deviceList.length = 0;
        this.deviceList.push(...devList);

        this.sec.companySiteObservable().pipe(takeUntil(this.unsubscribe)).subscribe(csId => {
          // Caso tenha mudado de filial se desinscreve dos observables
          this.devCacheService.unsubscribeFromAllDevices();
          this.unsubscribeDevice.next();

          this.groupList.length = 0;
          this.selectDevice({ device: undefined, taskPlan: undefined, tab: 'sequencing' });
          let deviceCsList = this.deviceList.filter(d => d.id_companySite == csId);
          //Carregar as informacoes do GROUP_LIST
          this.devCacheService.initialize().then(() => {

            deviceCsList.forEach(dev => {
              this.devCacheService.getDeviceData(dev.id_device)
                .pipe(takeUntil(this.unsubscribeDevice))
                .subscribe(device => {
                  if (!device) return;

                  device.deviceId = dev.id_device;

                  let dg = null;
                  let dgIndex = this.groupList.findIndex(g => g.id == device.deviceGroupId);
                  if (dgIndex < 0) {
                    const d = this.devCacheService.getDeviceGroup(device.deviceGroupId);

                    dg = { id: device.deviceGroupId, description: d?.description || device.title, deviceList: [] };
                    this.groupList.push(dg);
                  } else {
                    dg = this.groupList[dgIndex];
                  }

                  let dIndex = dg.deviceList.findIndex(d => d.deviceId == device.deviceId);
                  if (dIndex < 0) {
                    dg.deviceList.push(device);
                  } else {
                    dg.deviceList[dIndex] = device;
                  }

                  this.calculateMinutes();

                  this.warningService.getWarningList(this.deviceDatasetId, dev.id_device, null, "Apontamento", true)
                    .pipe(take(1))
                    .subscribe(warningList => {
                      device.warningList = warningList;
                    });

                  if (this.selectedIdDevice && this.selectedIdDevice === dev.id_device) {
                    let taskPlan;

                    if (!device.multiTask) {
                      this.selectedIdTaskPlan = device.taskList[0]?.id_taskPlan;
                      taskPlan = device.taskList[0];
                    } else {
                      taskPlan = device.taskList.find((t) => t.id_taskPlan == this.selectedIdTaskPlan);
                    }

                    this.selectDevice({ device, taskPlan, tab: this.selectedPanel ?? "sequencing" });
                  }
                });


            });
            this.loaded = true;
            this.devicesLoaded.emit(true);
          });
        })
      });

    this.deviceTimer();
  }

  selectDeviceByID(id_device: string, id_taskPlan: string, tab: 'sequencing' | 'appointment' | 'document') {
    let device: DeviceCache;
    let taskPlan: DeviceCacheTask;
    for (const g of this.groupList) {
      device = g.deviceList.find((d) => d.deviceId == id_device);
      if (device) {
        taskPlan = device?.taskList?.find((t) => t.id_taskPlan == id_taskPlan);
        this.selectDevice({ device, taskPlan, tab });

        break;
      }
    };
  }

  selectDevice({ device, taskPlan, tab, clearSelection }: { device: DeviceCache; taskPlan: DeviceCacheTask; tab: PanelType; clearSelection?: boolean }) {
    this.deviceSelected.emit({ device, taskPlan, tab, clearSelection });
  }

  deviceTimer() {
    setInterval(() => {
      this.calculateMinutes();
    }, 60000);
  }

  calculateMinutes() {
    this.groupList?.forEach(gl => {
      gl.deviceList?.forEach(dl => {
        if (!dl.multiTask) {
          dl.taskList[0] ? dl.taskList[0].qttMinutesExecuting = (new Date().getTime() - new Date(dl.taskList[0]?.startDateDurationReg).getTime()) / 60000 : null;
        } else {
          dl.taskList.forEach(t => {
            t.qttMinutesExecuting = (new Date().getTime() - new Date(t?.startDateDurationReg).getTime()) / 60000;
          });
        }
      });
    });
  }

  showActionTree(origem: string, id, smallWindow): void {
    if (smallWindow) return;
    let actions: T2AccessItem[] = [];

    if (origem == "op" && id) {
      this.sec.getDatasetActions("ord_ordemprod").subscribe((actionList) => {
        actionList.forEach((action) => {
          if ([T2AccessItemDatasetActionType.DSACTION_OTHER,
          T2AccessItemDatasetActionType.DSACTION_SEARCH,
          T2AccessItemDatasetActionType.DSACTION_OTHERCLOSE,
          T2AccessItemDatasetActionType.DSACTION_VIEW,
          T2AccessItemDatasetActionType.DSACTION_EDIT].includes(action.datasetActionType)) {
            actions.push(action);
          }
        });
      });
    }

    if (origem == "task" && id) {
      this.sec.getDatasetActions("ord_ordemprod_tarefa").subscribe((actionList) => {
        actionList.forEach((action) => {
          if ([T2AccessItemDatasetActionType.DSACTION_OTHER,
          T2AccessItemDatasetActionType.DSACTION_SEARCH,
          T2AccessItemDatasetActionType.DSACTION_OTHERCLOSE,
          T2AccessItemDatasetActionType.DSACTION_VIEW,].includes(action.datasetActionType)) {
            actions.push(action);
          }
        });
      });
    }

    if (origem == 'device' && id) {
      this.sec.getDatasetActions("prd_dispositivo").subscribe((actionList) => {
        actionList.forEach((action) => {
          if ([T2AccessItemDatasetActionType.DSACTION_OTHER,
          T2AccessItemDatasetActionType.DSACTION_SEARCH,
          T2AccessItemDatasetActionType.DSACTION_OTHERCLOSE,
          T2AccessItemDatasetActionType.DSACTION_VIEW,].includes(action.datasetActionType)) {
            actions.push(action);
          }
        });
      });
    }

    let groups: Array<GroupSelection> = [];
    let items: Array<ItemSelection> = [];
    let groupName = null;
    if (actions.length > 0) {
      actions.forEach(action => {
        groupName = action.datasetDescription || action.datasetGroup || groupName;
        items.push(
          {
            id_item: action.id,
            itemName: action.datasetActionDescription,
            selected: false
          });

        if (groups.find(g => g.id_group == action.id_dataset_group)) {
          return;
        }

        groups.push(
          {
            id_group: action.id_dataset_group,
            groupName: null,
            selected: false,
            items: items
          });
      });

      const dialogRef = this.dialogService.open(
        DialogItemSelectionComponent,
        {
          context: {
            title: groupName,
            groupSelection: 'none',
            itemSelection: 'single',
            itemFromDifGroups: false,
            groups: groups,
            useConfirmButton: false,
            buttonSize: 'large'
          },
        }
      );

      dialogRef.onClose.subscribe((resp) => {
        if (resp) {
          let i = items.find(item => item.selected);

          let id_action = null;
          if (i) {
            id_action = i.id_item;
          }

          const action: T2AccessItem = actions.find(a => a.id == id_action);

          const params = new Map<string, string>();
          params.set("datasetName", action.datasetName);
          params.set("id", id);
          this.executeAction(action, params);
        }
      });
    }
  }

  executeAction(action, params): void {
    const resp = this.actionService.executeAction(action, params, "newWindow");
    if (resp instanceof Observable) {
      resp.subscribe(resp => {
      });
    }
  }

  openWarning(warningList: Array<Warning>) {
    this.dialogService.open(WarningComponent, {
      context: {
        warningList: warningList
      }
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event): void {
    if (window.innerWidth <= 450) this.smallWindow = true;
    if (window.innerWidth > 450) this.smallWindow = false;
  }

  ngAfterContentInit(): void {
    if (window.innerWidth <= 450) this.smallWindow = true;
    if (window.innerWidth > 450) this.smallWindow = false;
  }
}
