<div class="flexAlignRowStartCenter flexWrap flexGap5">

  <!-- PROGRAMAÇÃO -->
  <div style="display: flex; gap: 5px" class="buttonGroup">
    <app-t2-menu-button [status]="apsService.programmingVars.showComponent.sequencing ? 'success' : 'basic'"
      (clicked)="showComponent('sequencing')" [caption]=" 'Sequência'"></app-t2-menu-button>
    <app-t2-menu-button [status]=" apsService.programmingVars.showComponent.gantt ? 'success' : 'basic'"
      (clicked)="showComponent('gantt')" [caption]=" 'Gantt'"></app-t2-menu-button>
    <!-- <app-t2-menu-button [status]="apsService.programmingVars.showComponent.list ? 'success' : 'basic'"
      (click)="showComponent('list')" caption="Fila"></app-t2-menu-button> -->
  </div>

  <!-- AÇÕES APLICADAS À SIMULAÇÃO -->
  <div style="display: flex; gap: 5px" class="buttonGroup">

    <app-t2-menu-button [icon]=" 'calendar-outline'" [caption]=" 'Prazo Firme'" (clicked)="setFirmDeadline()"
      [disabled]=" isProductionEnvironment || !progEnvironment || !canSetFirmDeadline()">
    </app-t2-menu-button>

    <app-t2-menu-button [icon]=" 'sync'" [caption]=" 'Recalcular'" (click)="recalculate()"
      [disabled]=" isProductionEnvironment || !progEnvironment
    || !apsService.programmingVars.deviceSelectedId.value || !apsService.programmingVars.showComponent.sequencing || !canRecalculate()">
    </app-t2-menu-button>

    <div style="position: relative;">
      <app-t2-menu-button [icon]=" 'refresh'"
        [caption]=" isProductionEnvironment ? 'Recarregar dados reais' : 'Simulação x Real'"
        (clicked)="updateSimulation()" [nbSpinner]="outOfDateSpinner" nbSpinnerStatus="success" nbSpinnerSize="tiny"
        [disabled]=" outOfDateSpinner || !progEnvironment || !apsService.programmingVars.deviceSelectedId.value">
      </app-t2-menu-button>
      <nb-badge *ngIf="simulationOutOfDate.asObservable() | async" position="top left" status="danger" text="!"
        nbTooltip="Essa simulação está desatualizada">
      </nb-badge>
    </div>

    <app-t2-menu-button [icon]=" 'save-outline'" [caption]=" 'Aplicar programação'" (clicked)="saveSimulation()"
      [disabled]=" isProductionEnvironment || !progEnvironment || !apsService.programmingVars.deviceSelectedId.value
      || !apsService.programmingVars.showComponent.sequencing || !canApplySimulation()">
    </app-t2-menu-button>

    <app-t2-menu-button [icon]=" 'calendar-outline'" [caption]=" 'Horário de Trabalho'"
      [disabled]=" !progEnvironment || !canEditWorkSchedule()" (clicked)="editWorkSchedule()">
    </app-t2-menu-button>
  </div>

  <!-- AÇÕES APLICADAS À TAREFA -->
  <div style="display: flex; gap: 5px" class="buttonGroup">
    <!-- Só mostar se tiver algum planejamento selecionado-->
    <app-t2-menu-button [icon]=" 'clock-outline'" [caption]=" 'Reserva de tempo'" (clicked)="timeReservation()"
      [disabled]=" isProductionEnvironment || !progEnvironment || !apsService.programmingVars.showComponent.sequencing || !canDoTimeReservation()">
    </app-t2-menu-button>
    <app-t2-menu-button [icon]=" 'swap'" [caption]=" 'Troca de Máquina'" (clicked)="swapDevice()"
      [disabled]=" isProductionEnvironment || !progEnvironment || !apsService.programmingVars.showComponent.sequencing || apsService.programmingVars.planSelectedList?.value?.length < 1 || !canSwapDevice()">
    </app-t2-menu-button>
    <app-t2-menu-button [icon]=" 'flip'" [caption]=" 'Ordenar Tarefas'" (clicked)="sortSelectedTasks()"
      [disabled]=" isProductionEnvironment || !progEnvironment || !apsService.programmingVars.showComponent.sequencing || apsService.programmingVars.planSelectedList?.value?.length < 2 || !canSortSelectedTasks()">
    </app-t2-menu-button>
    <app-t2-menu-button [icon]=" 'lock'" [caption]=" 'Fixar Data'" (clicked)="setFixedDate(true)"
      [disabled]=" isProductionEnvironment || !progEnvironment || !apsService.programmingVars.showComponent.sequencing || apsService.programmingVars.planSelectedList?.value?.length != 1 || !canSetFixedDate()">
    </app-t2-menu-button>
    <app-t2-menu-button [icon]=" 'unlock-outline'" [caption]=" 'Desafixar Data'" (clicked)="setFixedDate(false)"
      [disabled]=" isProductionEnvironment || !progEnvironment || !apsService.programmingVars.showComponent.sequencing || apsService.programmingVars.planSelectedList?.value?.length < 1 || !isAllSelectedPlansFixedDate() || !canSetFixedDate()">
    </app-t2-menu-button>
  </div>

  <!-- AÇÕES LIGADAS À SEQUENCIA -->
  <div *ngIf="apsService.programmingVars.showComponent.sequencing" class="buttonGroup" style="display: flex; gap: 5px">
    <app-t2-menu-button [caption]="'Dispositivo'" [disabled]=" !progEnvironment" (clicked)="selectSequencingDevice()">
    </app-t2-menu-button>
  </div>

  <!-- AÇÕES LIGADAS AO GANTT -->
  <div *ngIf="apsService.programmingVars.showComponent.gantt" class="buttonGroup" style="display: flex; gap: 5px">
    <app-t2-menu-button [caption]=" 'Dispositivos'" [disabled]=" !progEnvironment" (clicked)="selectGanttDevices()">
    </app-t2-menu-button>
  </div>

  <!-- AÇÕES LIGADAS AO Fila -->
  <div *ngIf="apsService.programmingVars.showComponent.list" class="buttonGroup" style="display: flex; gap: 5px">
    <app-t2-menu-button [caption]=" 'Dispositivos'" [disabled]=" !progEnvironment" (clicked)="selectListDevices()">
    </app-t2-menu-button>
  </div>

</div>
