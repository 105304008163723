import { environment } from 'src/environments/environment';
import { Component, AfterViewInit, OnDestroy, ViewChildren, ElementRef, QueryList } from '@angular/core';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.scss'],
})
export class NewsComponent implements AfterViewInit, OnDestroy {

  visible = !environment.production;
  @ViewChildren("newsRef") private newsRef: QueryList<ElementRef>;

  newsList = [];

  constructor() { }

  ngAfterViewInit(): void {
    this.loadNews();
    this.newsRef.changes.subscribe(() => {
      this.newsRef.forEach((item, index) => {
        const html = this.getHTML(this.newsList[index]);
        item.nativeElement.style.cssText = html.style;
        item.nativeElement.innerHTML = html.html;
      });
    });
  }

  private loadNews() {
    this.newsList.push({
      title: "",
      text: `
        <div>
          Você sabia que já é possível consultar muitas informações do sistema aqui na versão Web do Techprod?
          <a href="dataset/cpr_pedidoCompra" target="_blank">Pedidos de Compra</a>,
          <a href="dataset/pvd_pedidovenda" target="_blank">Pedidos de Venda</a>,
          <a href="dataset/ord_ordemprod" target="_blank">Ordens de Produção</a> e muitas outras informações já estão aqui para consulta.<br/>
          Vamos lá, clique no botão <strong>Menu</strong> acima para exibir todas as funcionalidades que já estão disponíveis para você aqui no Techprod.
        </div>
      `,
      release: new Date()
    });

    this.newsList.push({
      title: "",
      backgroundImg: "http://techprod.com.br/wp-content/uploads/2020/06/pcmockup.png",
      text: `
        <div>
          Você sabia que o Techprod possui um módulo para geração dos indicadores de OEE?
          Procure nosso o setor de atendimento para saber como configurar as metas por áreas, máquinas, turnos e como extrair os diversos relatórios e analises de OEE.
        </div>
      `,
      release: new Date()
    });

    this.newsList.push({
      title: "",
      text: `
        <div>
          Você sabia que já é possível consultar muitas informações do sistema aqui na versão Web do Techprod?
          <a href="dataset/cpr_pedidoCompra" target="_blank">Pedidos de Compra</a>,
          <a href="dataset/pvd_pedidovenda" target="_blank">Pedidos de Venda</a>,
          <a href="dataset/ord_ordemprod" target="_blank">Ordens de Produção</a> e muitas outras informações já estão aqui para consulta.<br/>
          Vamos lá, clique no botão <strong>Menu</strong> acima para exibir todas as funcionalidades que já estão disponíveis para você aqui no Techprod.
        </div>
      `,
      release: new Date()
    });

    this.newsList.push({
      title: "",
      backgroundImg: "http://techprod.com.br/wp-content/uploads/2020/06/pcmockup.png",
      text: `
        <div>
          Você sabia que o Techprod possui um módulo para geração dos indicadores de OEE?
          Procure nosso o setor de atendimento para saber como configurar as metas por áreas, máquinas, turnos e como extrair os diversos relatórios e analises de OEE.
        </div>
      `,
      release: new Date()
    });
  }


  // Cleanup properly. You can add more cleanup-related stuff here.
  ngOnDestroy() {

  }


  getHTML(news: any): { html: string, style: string } {

    const html = [];
    const style = []

    html.push(news.text);

    style.push(`padding-top: 90px`);

    if (news.backgroundImg) {
      style.push(`background-image: url('${news.backgroundImg}')`);
      style.push("background-size: cover");
      style.push("background-repeat: no-repeat");
   }

    return { html: html.join(""), style: style.join(";") }
  }

}
