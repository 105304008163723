import { PackingItem } from "./../model/packingItem";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormControl } from "@angular/forms";
import { Packing } from "../model/packing";

@Component({
  selector: "app-packing-stage",
  templateUrl: "./packing-stage.component.html",
  styleUrls: ["./packing-stage.component.scss"],
})
export class PackingStageComponent implements OnInit {
  @Input() currentTab;
  @Input()
  get packingList(): Array<Packing> {
    return this.fullPackingList;
  }
  set packingList(v: Array<Packing>) {
    this.filteredPackingList = v;
    this.fullPackingList = v;

    this.freightTypeList = [];
    this.fullPackingList.forEach((packing) =>
      packing.salesOrderList
        .filter((so) => so.freightType)
        .forEach((salesOrder) => {
          if (
            !this.freightTypeList.find(
              (item) => item.name === salesOrder.freightType
            )
          ) {
            this.freightTypeList.push({
              name: salesOrder.freightType,
              selected: true,
            });
          }
        })
    );
  }

  @Output() packingClick = new EventEmitter();
  @Input() packer;
  @Input() clickable: boolean;

  private fullPackingList: Array<Packing>;
  filteredPackingList: Array<Packing>;
  freightTypeList = [];

  private searchText: string;

  constructor() {}

  ngOnInit(): void {}

  onCardClick(packing: Packing) {
    this.packingClick.emit({
      packing,
      currentTab: this.currentTab,
    });
  }

  onSearchChange(searchValue: string) {
    this.searchText = searchValue;

    searchValue = searchValue
      .toLowerCase()
      .trim()
      .normalize("NFD")
      .replace(/([\u0300-\u036f]|[^0-9a-zA-Z])/g, "");

    if (!searchValue || searchValue.length < 3) {
      this.filteredPackingList = this.fullPackingList;
    } else {
      this.filteredPackingList = this.fullPackingList.filter((pl: Packing) => {
        return (
          this.searchTextCheck(pl.salesOrder, searchValue) ||
          this.searchTextCheck(pl.carrier, searchValue) ||
          this.searchTextCheck(pl.customer, searchValue) ||
          this.searchTextCheck(pl.salesOrderNotes, searchValue) ||
          this.searchTextItemCheck(pl.itemList, searchValue)
        );
      });
    }

    // Filtra tipo de frete
    const selected = this.freightTypeList
      .filter((item) => item.selected)
      .map((item) => item.name);
    if (selected.length === 0) {
      this.filteredPackingList = [];
    } else if (selected.length !== this.freightTypeList.length) {
      this.filteredPackingList = this.filteredPackingList.filter((packing) =>
        packing.salesOrderList
          .filter((so) => so.freightType)
          .find((so) => selected.includes(so.freightType))
      );
    }
  }

  private searchTextCheck(text: string, searchText: string): boolean {
    if (!text) {
      return false;
    }

    return text
      .toLowerCase()
      .trim()
      .normalize("NFD")
      .replace(/([\u0300-\u036f]|[^0-9a-zA-Z])/g, "")
      .includes(searchText);
  }

  freightTypeClick(freightType: any) {
    freightType.selected = !freightType.selected;
    this.onSearchChange(this.searchText || "");
  }

  private searchTextItemCheck(
    itemList: Array<PackingItem>,
    searchText: string
  ): boolean {
    if (!itemList) {
      return false;
    }

    for (const item of itemList) {
      if (
        this.searchTextCheck(item.itemCode, searchText) ||
        this.searchTextCheck(item.itemDescription, searchText)
      ) {
        return true;
      }
    }

    return false;
  }

  sepIncompleted(packing: Packing): boolean {
    return packing.itemList.find(item => item.separatedAmount < item.requiredAmount) ? true : false;
  }
}
