import { T2ChartComponent } from './../../../../../core/cmp/ui/t2-chart/t2-chart.component';
import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/Operators";
import { LayoutType, ViewTemplateElement } from "src/app/core/cmp/view-template/model/view-template-element";
import { ApsService } from "../../aps.service";
import { T2ViewTemplateData } from "src/app/core/cmp/view-template/model/t2-view-template-data";
import { ViewTemplateComponent } from "src/app/core/cmp/view-template/view-template.component";
import { T2MessageService } from "src/app/core/t2-message.service";


@Component({
  selector: 'app-machine-load',
  templateUrl: './machine-load.component.html',
  styleUrls: ['./machine-load.component.scss']
})
export class MachineLoadComponent implements OnInit, OnDestroy {

  private unsubscribe = new Subject<void>();
  private previousDeviceId: string;

  formGroup: FormGroup;
  machineLoadOptions = [];

  private chartChanged = false;
  private chartLoading = false;
  private seriesBackground = { showBackground: true, backgroundStyle: { color: 'rgba(180, 180, 180, 0.2)' } }
  private seriesBar = { yAxisIndex: '1', type: 'bar', stack: 'one', label: { show: true, fontSize: 9, fontWeight: 'bold', formatter: this.labelFormatter }, data: [], ...this.seriesBackground };
  private chartOptions = {
    title: { text: '' },
    legend: { data: ['Fora do prazo', 'No prazo', 'Adiantadas', "Capacidade", "# Tarefas"], },
    tooltip: {
      valueFormatter: (params) => { return params.toFixed(2); },
      formatter: (params) => {
        var seriesName = params.seriesName;
        const htmlElement =
          `
        <div style="display: flex; flex-direction: row; justify-content: space-between; width: 200px; font-size=70%">
          <div>${params.seriesName}</div>
          <div>${seriesName == '# Tarefas' ?
            params.value.toFixed(0).replace(".", ".") :
            ((Math.round(params.value * 1000) + 1) / 1000).toFixed(1).replace(".", ".") + "h"}</div>
        </div>
        `;
        return htmlElement;
      }
    },
    toolbox: {
      feature: {
        dataView: { show: true, readOnly: true },
        restore: { show: true },
        saveAsImage: { show: true }
      }
    },
    angleAxis: null,
    radiusAxis: null,
    polar: null,
    dataZoom: [{ type: 'inside', start: 0, end: 35 }, { start: 0, end: 35 }],
    grid: { backgroundColor: 'rgba(180, 180, 180, 0.2)' },
    xAxis: { type: 'category', data: [], axisLabel: { rotate: 60, fontSize: 9, fontWeight: "bolder" } },
    yAxis: [{
      type: 'value', position: 'right', alignTicks: true,
      axisLine: { show: true }, axisLabel: { formatter: '{value} tarefas' },
    },
    {
      type: 'value', position: 'left', alignTicks: true,
      axisLine: { show: true }, axisLabel: { formatter: '{value}h' }
    },
    ],

    series: [
      { name: 'Fora do prazo', ...this.seriesBar, color: "#c12e34" },
      { name: 'No prazo', ...this.seriesBar, color: "#e6b600" },
      { name: 'Adiantadas', ...this.seriesBar, color: "#2b821d" },
      { name: 'Capacidade', yAxisIndex: '1', type: 'line', smooth: true, data: [], color: "#0098d9" },
      { name: '# Tarefas', yAxisIndex: 0, type: 'bar', stack: 'two', barWidth: 7, label: { show: true, fontSize: 9, fontWeight: 'bold', }, data: [] },
    ]
  };
  data: Array<T2ViewTemplateData> = [
    { cmpName: `machineLoad`, properties: { chartOptions: this.chartOptions, changed: this.chartChanged, loading: this.chartLoading } },
  ];

  layout: ViewTemplateElement[] = [
    {
      // layoutType: LayoutType.listLayout,
      // children: [
      //   {
      layoutType: LayoutType.component,
      // title: "Gráfico",
      cmpName: `machineLoad`,
      fill: true,
      height: '300px',
      cmpType: T2ChartComponent,
      isBaseComponent: true,
      //   },
      // ]
    }
  ];

  @ViewChild("viewTemplate", { static: true }) viewTemplate: ViewTemplateComponent;

  constructor(private formBuilder: FormBuilder,
    public apsService: ApsService,
    private messageService: T2MessageService) {
    this.formGroup = formBuilder.group({});
    this.formGroup.addControl(`machineLoad`, formBuilder.control(null, Validators.required));

  }

  labelFormatter(params) {
    if (params.value) return ((Math.round(params.value * 1000) + 1) / 1000).toFixed(1).replace(".", ".") + "h";
    return "";
  }

  ngOnInit(): void {
    this.chartChanged = true;

    this.apsService.analysisVars.deviceSelectedId
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(id => {
        if (this.viewTemplate.cmpReferenceList.length > 0) {
          this.deviceChanged();
        }
      });

    this.apsService.analysisVars.machiLoadConsolidation
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(consolidation => {
        if (this.viewTemplate.cmpReferenceList.length > 0) {
          this.deviceChanged();
        }
      });

  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  private deviceChanged() {

    const id_device = this.apsService.analysisVars.deviceSelectedId.value;

    if (this.previousDeviceId) {
      this.apsService.unsubscribeDevice(this.previousDeviceId);
    }

    if (!id_device) {
      return;
    }

    let machineLoad = [];

    this.chartLoading = true;
    this.viewTemplate.setCmpInputValue("machineLoad", "loading", this.chartLoading);

    this.apsService.loadUnproductiveInterval(id_device)
      .subscribe(tpList => {

        const sortFunction = (a, b) => { return a.startDate.getTime() - b.startDate.getTime(); };

        tpList.sort(sortFunction);

        if (tpList.length == 0) {

          this.chartOptions.title.text = this.getDeviceName(id_device);
          this.chartOptions.series[0].data = [];
          this.chartOptions.series[1].data = [];
          this.chartOptions.series[2].data = [];
          this.chartOptions.series[3].data = [];
          this.chartOptions.series[4].data = [];
          this.chartChanged = true;
          this.chartLoading = false;

          this.viewTemplate.setCmpInputValue("machineLoad", "chartOptions", this.chartOptions);
          this.viewTemplate.setCmpInputValue("machineLoad", "loading", this.chartLoading);

          return;

        }

        machineLoad = this.reduceTaskPlanList(tpList);
        let initialDate = tpList[0]?.startDate;
        if (initialDate) {
          initialDate.setHours(0, 0, 0, 0);
        }

        try {
          this.apsService.getDeviceCalendar(id_device, initialDate || new Date(new Date().toLocaleString()))
            .subscribe(servResp => {
              try {
                const deviceCalendar = servResp.deviceCalendar;
                if (!deviceCalendar) {
                  this.messageService.showToastError("Horário de trabalho não gerado para a data " + initialDate.toLocaleDateString());
                  console.error("Horário de trabalho não gerado para a data " + initialDate.toLocaleDateString());
                  return;
                }

                const availDates = this.reduceDeviceCalendar(deviceCalendar);

                const maxPlanDate = tpList.reduce((ac: Date, item: any) => {
                  return (!ac || ac < item.endDate) ? item.endDate : ac;
                }, null);

                availDates.forEach(avlTime => {
                  const avlTimeDate: string = avlTime.key;
                  const machDate = machineLoad.find(machDate => machDate.key === avlTimeDate);
                  if (machDate) machDate.availableLoad = avlTime.availableLoad
                  else if (avlTimeDate <= maxPlanDate) {
                    machineLoad.push({
                      key: avlTimeDate,
                      load: 0.0,
                      loadLate: 0.0,
                      loadOnTime: 0.0,
                      loadInAdvance: 0.0,
                      availableLoad: avlTime.availableLoad,
                      tasks: []
                    });
                  }
                });


                this.chartOptions.title.text = this.getDeviceName(id_device);
                this.chartOptions.xAxis.data = machineLoad.map(item => { return item.key; });
                this.chartOptions.series[0].data = machineLoad.map(item => { return item.loadLate; });
                this.chartOptions.series[1].data = machineLoad.map(item => { return item.loadOnTime; });
                this.chartOptions.series[2].data = machineLoad.map(item => { return item.loadInAdvance; });
                this.chartOptions.series[3].data = machineLoad.map(item => { return item.availableLoad || 0; });
                this.chartOptions.series[4].data = machineLoad.map(item => { return item.tasks.length; });
                this.chartChanged = true;

                this.viewTemplate.setCmpInputValue("machineLoad", "chartOptions", this.chartOptions);

              } finally {
                this.chartLoading = false;
                this.viewTemplate.setCmpInputValue("machineLoad", "loading", this.chartLoading);
              }
            }, error => {
              this.chartLoading = false;
              this.viewTemplate.setCmpInputValue("machineLoad", "loading", this.chartLoading);
            });
        } finally {
          this.chartLoading = false;
          this.viewTemplate.setCmpInputValue("machineLoad", "loading", this.chartLoading);
        }
      }, error => {
        this.chartLoading = false;
        this.viewTemplate.setCmpInputValue("machineLoad", "loading", this.chartLoading);
      });

    //chamar back que vai ler script para gráficos personalizados
    this.apsService.getCustomMachineLoad(id_device)
      .subscribe((machineLoadDisplayOptions: Array<{ dataTitle: string, show?: string, chartOptions: object, tableOptions: object }>) => {
        if (!Array.isArray(machineLoadDisplayOptions))
          this.machineLoadOptions = [machineLoadDisplayOptions]
        else
          this.machineLoadOptions = machineLoadDisplayOptions;

        this.machineLoadOptions.forEach(options => {
          if (options.chartOptions)
            options.show = 'chart'
          else if (options.tableOptions)
            options.show = 'table';

        });
      })
  }

  private reduceDeviceCalendar(deviceCalendar): Array<{ key: string, availableLoad: number }> {

    deviceCalendar?.sort((a, b) => {
      return a.startDate.localeCompare(b.startDate);
    });

    return deviceCalendar.reduce((acumulador, interval) => {

      const intervalDate: Date = new Date(new Date(interval.startDate).toDateString());
      const consolidation = this.apsService.analysisVars.machiLoadConsolidation.value;
      let key = this.getConsolidationStr(intervalDate, consolidation);

      //acumulador = acumulador || [];

      let avd = acumulador.find((availableTime: any) => {
        return availableTime.key === key;

      });

      if (!avd) {
        avd = { key: key, availableLoad: 0.0 };
        acumulador.push(avd);
      }

      avd.availableLoad += (new Date(interval.endDate).getTime() - new Date(interval.startDate).getTime()) / 1000 / 60 / 60;

      return acumulador;

    }, []);

  }

  private reduceTaskPlanList(tpList: Array<{ id_task_plan: string, started?: boolean, maxDate?: Date, startDate: Date, endDate: Date }>): Array<any> {

    const consolidation = this.apsService.analysisVars.machiLoadConsolidation.value;

    return tpList.reduce((ac, task) => {

      const funcAccumulate = ((day: Date, load: number, consolidation: string) => {

        load = load < 0 ? 0 : load;

        let key = this.getConsolidationStr(day, consolidation);

        if (!ac) ac = [];

        let iac = ac.find(cm => cm.key === key);
        if (!iac) {
          iac = { key: key, load: 0.0, loadLate: 0.0, loadOnTime: 0.0, loadInAdvance: 0.0, tasks: [] };
          ac.push(iac);
        }

        const maxDate = new Date(new Date(task.maxDate).toDateString());
        const endDate = new Date(task.endDate.toDateString());

        iac.load += load;
        iac.loadLate += (endDate > maxDate ? load : 0);
        iac.loadOnTime += (endDate == maxDate ? load : 0);
        iac.loadInAdvance += (endDate < maxDate ? load : 0);

        iac.tasks.push({ load, ...task });
      });

      let startDate = task.startDate;

      if (task.started) {
        startDate = new Date();
      }

      let fimDia = this.endOfDay(startDate);

      // Acumula horas qdo a atividade nao acaba no mesmo dia
      while (task.endDate > fimDia) {
        funcAccumulate(new Date(startDate.toDateString()), (fimDia.getTime() - startDate.getTime()) / 1000 / 60 / 60, consolidation);

        startDate = new Date(fimDia.getTime() + 1);
        fimDia = this.endOfDay(startDate);
      }

      // Acumula horas quando a atividade acaba no dia
      funcAccumulate(new Date(startDate.toDateString()), (task.endDate.getTime() - startDate.getTime()) / 1000 / 60 / 60, consolidation);

      return ac;
    }, []);
  }

  private getConsolidationStr(day: Date, consolidation: string) {
    switch (consolidation) {
      case 'daily':
        return day.toLocaleDateString();

      case 'weekly':
        return this.weekOfYear(day);

      case 'monthly':
        return ("0" + (day.getMonth() + 1)).substr(-2) + "/" + day.getFullYear();

      default:
        throw ("Invalid consolidation [" + consolidation + "]");
    }
  }
  private weekOfYear(day: Date) {
    let oneJan = new Date(day.getFullYear() + "-01-01T" + ("0" + new Date().getTimezoneOffset() / 60).substr(-2) + ":00");
    let numberOfDays = Math.floor((day.getTime() - oneJan.getTime()) / (24 * 60 * 60 * 1000));
    let week = Math.floor((oneJan.getDay() + numberOfDays) / 7) + 1;
    return "Sem" + ("0" + week).substr(-2) + " " + day.getFullYear();
  }

  private endOfDay(day: Date) {
    return new Date(new Date(day.toDateString()).getTime() + ((1000 * 60 * 60 * 24) - 1))
  }

  private getDeviceName(id: string): string {
    return this.apsService.getSimulationDevice(id)?.description || null;
  }

}
