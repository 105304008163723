import { Component, Input, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { PSReleaseInfo } from "../model/psrelease-info";
import { ProdOrderReleaseService } from "../prod-order-release.service";
import { take } from "rxjs/Operators";
import { FlxProductSpecificationService } from "src/app/bsn/flx/epp/flx-product-specification.service";
import { EPPConjugateAssemblyComponent } from "src/app/bsn/flx/epp/eppconjugate-assembly/eppconjugate-assembly.component";
import { T2LocalStorageService } from "src/app/core/t2local-storage.service";

@Component({
  selector: 'app-release-detail',
  templateUrl: './release-detail.component.html',
  styleUrls: ['./release-detail.component.scss']
})
export class ReleaseDetailComponent {
  @ViewChildren("conjAssembly") conjAssemblyList: QueryList<EPPConjugateAssemblyComponent>;

  @Input()
  get data() {
    return this._data;
  }
  set data(value: { prodSpecifId: string, isConjugation: boolean, psReleaseList: Array<PSReleaseInfo> }) {
    if (value) {
      this._data = value;
      this.loadPSRelease();
    } else {
      this._data = undefined;
      this.psReleaseList = undefined;
    }
  }

  private _data: { prodSpecifId: string, isConjugation: boolean, psReleaseList: Array<PSReleaseInfo> };
  public loading: boolean = false;
  public psReleaseList: Array<PSReleaseInfo>;

  constructor(private releaseService: ProdOrderReleaseService, private flxPsService: FlxProductSpecificationService,
    private storage: T2LocalStorageService) { }

  loadPSRelease() {
    this.loading = true;
    if (this.data.psReleaseList?.length) {
      this.psReleaseList = this.data.psReleaseList;

      if (this.data.isConjugation) {
        this.loadAssembly();
      }

      this.loading = false;
    } else {
      if (this.data.prodSpecifId) {
        this.releaseService.getPsReleaseList(this.data.prodSpecifId, this.data.isConjugation).pipe(take(1)).subscribe(list => {
          this.data.psReleaseList = list;
          this.psReleaseList = list;

          if (this.data.isConjugation) {
            this.loadAssembly();
          } else {
            this.loading = false;
          }

          this.loading = false;
        }, error => {
          this.loading = false;
        })
      } else {
        this.psReleaseList = [];
        this.loading = false;
      }
    }
  }

  private loadAssembly() {
    this.psReleaseList.forEach(rel => {
      let obj: any = JSON.parse(this.storage.getSessionData(`assemblyPS#${rel.specificationId}`));

      setTimeout(() => {
        let conjAssembly = this.conjAssemblyList.find(ca => ca.t2IdCmp == rel.specificationId);

        if (!obj) {
          this.flxPsService.getConjugatedAssembly(rel.specificationId).pipe(take(1)).subscribe(assembly => {
            obj = assembly;
            this.storage.setSessionData(`assemblyPS#${rel.specificationId}`, JSON.stringify(obj));

            if (conjAssembly) {
              conjAssembly.leftMargin = obj.leftMargin;
              conjAssembly.rightMargin = obj.rightMargin;
              conjAssembly.topMargin = obj.topMargin;
              conjAssembly.bottomMargin = obj.bottomMargin;
              conjAssembly.items = obj.itemList;
            }
          })
        } else {
          if (conjAssembly) {
            conjAssembly.leftMargin = obj.leftMargin;
            conjAssembly.rightMargin = obj.rightMargin;
            conjAssembly.topMargin = obj.topMargin;
            conjAssembly.bottomMargin = obj.bottomMargin;
            conjAssembly.items = obj.itemList;
          }
        }
      }, 100);

    })
  }

  openProdSpecif(specificationId: string) {
    window.open(`epp/ep?id=${specificationId}`, '_blank');
  }
}
