export enum DeviceOperationDataType {
  SINGLE_TEXT = "SINGLE_TEXT",
  LIST = "LIST",
  FLOAT = "FLOAT",
  MULTIPLE_TEXT = "MULTIPLE_TEXT",
  LOGIC = "LOGIC",
  INTEGER = "INTEGER",
  DATETIME = "DATETIME",
  DATASET = "DATASET"
}
