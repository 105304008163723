import { Component, Input, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { T2gridComponent } from "src/app/core/cmp/t2grid/t2grid.component";

@Component({
  selector: 'app-machine-load-table',
  templateUrl: './machine-load-table.component.html',
  styleUrls: ['./machine-load-table.component.scss']
})
export class MachineLoadTableComponent implements AfterViewInit {

  @Input() tableOptions: { columns: [], data: [] };

  @ViewChild('gridML', { static: true }) gridML: T2gridComponent;

  constructor() { }

  ngAfterViewInit(): void {
    this.gridML.setGridColumnDefs(this.tableOptions?.columns || []);
    this.gridML.setGridData(this.tableOptions?.data || [], null);
    this.gridML.autoSizeAllColumns(false);
  }
}
