<div *ngIf="!readOnly" class="flexColumn" [formGroup]="formGroup" >

  <div class="flexAlignRowCenter flexGap5">
    <input #inputText id="inputText" nbInput type="text" fieldSize="small" [id]="t2IdCmp" [hidden]="!visible" [ngStyle]="ngStyle"
      [placeholder]="placeHolder ? placeHolder : ''" [formControlName]="t2IdCmp" [maxlength]="maxLength" fullWidth
      (keydown.enter)="keyDownEnter.emit()">
    <div *ngIf="showIconScanner">
      <app-t2-icon style="cursor: pointer;" icon="camera-outline" (clicked)="openCamera()"></app-t2-icon>
    </div>
  </div>

  <span *ngIf="additionalInfo && visible" class="defaultSmallText">{{additionalInfo}}</span>

</div>

<div *ngIf="readOnly && visible" class="T2CmpReadOnly">
  {{formGroup.controls[t2IdCmp].value}}
</div>
