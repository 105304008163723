import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from "@angular/forms";
import { DialogTemplateComponent } from "src/app/core/cmp/dialog-template/dialog-template.component";
import { T2MessageService } from "src/app/core/t2-message.service";

@Component({
  selector: 'app-firm-deadline-dialog',
  templateUrl: './firm-deadline-dialog.component.html',
  styleUrls: ['./firm-deadline-dialog.component.scss']
})
export class FirmDeadlineDialogComponent implements OnInit {

  @ViewChild('dialog', { static: true }) dialog: DialogTemplateComponent;

  constructor(private messageService: T2MessageService, private formBuilder: FormBuilder) { }

  public firmDeadlineDays: number;
  public option: string;
  public _fg: FormGroup;

  ngOnInit(): void {
    this.option = this.firmDeadlineDays ? "2" : "1";

    this._fg = this.formBuilder.group({ firmDeadline: this.firmDeadlineDays }, { updateOn: 'blur' });
    this._fg.get("firmDeadline").valueChanges.subscribe(value => {
      this.firmDeadlineDays = value;
    });
  }

  closeDialog(action) {
    if (action == "Confirma") {
      if (this.option == "2" && !this.firmDeadlineDays) {
        this.messageService.showToast("Informe a quantidade de dias de prazo firme", "Atenção", "warning");
        return;
      }

      this.dialog.close({
        firmDeadline: this.option == "2" ? true : false,
        days: this.option == "2" ? this.firmDeadlineDays : undefined
      });
    } else {
      this.dialog.close(undefined);
    }
  }
}
