import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { T2SecurityService } from "../security/t2security.service";
import { Router } from "@angular/router";
import { NbDialogRef } from "@nebular/theme";
import { T2AccessItem, T2AccessItemType } from "../security/model/t2accessItem";
import { T2AccessGroup } from "../security/model/t2accessGroup";
import { take } from "rxjs/Operators";
import { MenuService } from "./menu.service";

@Component({
  selector: "app-menu",
  templateUrl: "./menu.component.html",
  styleUrls: ["./menu.component.scss"],
})
export class MenuComponent implements OnInit, AfterViewInit {
  @ViewChild("searchInput") searchInput: ElementRef;
  @ViewChild("searchInputSmall") searchInputSmall: ElementRef;

  public accessGroups = new Array<T2AccessGroup>();
  public accessGroupsOriginal: Array<T2AccessGroup>;
  loading = true;
  selectedGroup: T2AccessGroup = null;

  constructor(
    private menuService: MenuService,
    protected dialogRef: NbDialogRef<MenuComponent>,
    public route: Router,
    public secService: T2SecurityService
  ) { }

  ngOnInit(): void {
    this.secService
      .getAccessTree()
      .pipe(take(1))
      .subscribe((acg) => {
        this.accessGroupsOriginal = JSON.parse(JSON.stringify(acg)).filter(
          (ag: T2AccessGroup) => {
            const accessList = ag.accessList.filter((item: T2AccessItem) => this.showAction(item));

            if (accessList && accessList.length) {
              ag.accessList = accessList;
              return true;
            }

            return false;
          }
        );

        this.accessGroups = JSON.parse(JSON.stringify(this.accessGroupsOriginal));

        this.loading = false;

        if (this.accessGroups.length) {
          this.groupClick(this.accessGroups[0]);
        }
        this.selectedGroup = this.accessGroups[0];
      });
  }

  ngAfterViewInit(): void {
    this.searchInput?.nativeElement.blur();
    this.searchInput?.nativeElement.focus();
    this.searchInputSmall?.nativeElement.blur();
    this.searchInputSmall?.nativeElement.focus();
  }

  private showAction(action: T2AccessItem): boolean {
    return action.actionCommand?.trim().startsWith("tpw") ||
      (action.id_dataset && !action.id_dataset_parent && !action.eppCarac && !action.directive && action.datasetName != "prd_taskprod") ||
      action.type == T2AccessItemType.ACTION_ANALYSIS;
  }

  setFavItem(item: T2AccessItem) {
    item.favItem = !item.favItem;
    this.secService.setFavAccess(item);
  }


  selectItem(item: T2AccessItem) {
    if (item) {
      this.menuService.selectItem(item);
      this.dialogRef.close();
    }
  }

  onSearchChange(searchValue: string) {
    if (searchValue) {
      searchValue = searchValue
        .normalize("NFD")
        .replace(/([\u0300-\u036f]|[^0-9a-zA-Z])/g, "")
        .toLowerCase();
      this.accessGroups = JSON.parse(JSON.stringify(this.accessGroupsOriginal));
      this.accessGroups = this.accessGroups.filter((ag) => {
        const accessList = ag.accessList.filter((item) => {
          return item.name
            .toLowerCase()
            .normalize("NFD")
            .replace(/([\u0300-\u036f]|[^0-9a-zA-Z])/g, "")
            .includes(searchValue);
        });

        if (accessList && accessList.length) {
          ag.accessList = accessList;
          return true;
        }

        return false;
      });

      if (this.accessGroups.length) {
        this.groupClick(this.accessGroups[0]);
      }
    }
    else {
      this.accessGroups = JSON.parse(JSON.stringify(this.accessGroupsOriginal));
      this.groupClick(this.accessGroups[0]);
    }
  }

  executeCustomCall(item: T2AccessItem) { }

  groupClick(group: T2AccessGroup) {
    this.accessGroups.forEach((item) => (item.selected = false));
    group.selected = true;
    this.selectedGroup = group;
  }

}
