<div class="flexColumn fillContent" (click)="onComponentClick()">
  <app-t2-gantt class="flexLength1" #t2Gantt [groups]="groups" [items]="items" [template]="ganttTemplate"
    (doubleClick)="ganttDoubleClick($event)" (mouseOver)="ganttItemOver($event)"></app-t2-gantt>
</div>

<div *ngIf="showTooltip" class="taskDetail" style="opacity: 0.9;" [style.left]="tooltipObj.posX + 'px'"
  [style.top]="tooltipObj.posY + 'px'">
  <nb-card>
    <app-task-detail [id_device]="tooltipObj.id_device" [id_task_plan]="tooltipObj.id_task_plan"
      [id_task]="tooltipObj.id_task" [id_ordemprod]="tooltipObj.id_ordemProd">
    </app-task-detail>
  </nb-card>
</div>
