import { Component, Input, OnInit } from '@angular/core';
import { StructureLayer } from "../../flx-product-specification.service";

@Component({
  selector: 'app-layer-details',
  templateUrl: './layer-details.component.html',
  styleUrls: ['./layer-details.component.scss']
})
export class LayerDetailsComponent implements OnInit {

  @Input() structIndex: number;
  @Input() structLayer: StructureLayer = null;

  constructor() { }

  ngOnInit(): void {
  }

}
