import { Component, Input, OnDestroy } from '@angular/core';
import { DeviceOperationStopReason } from "../../model/device-operation-stop-reason";
import { FormBuilder, FormGroup } from "@angular/forms";
import { LayoutType, ViewTemplateElement } from "src/app/core/cmp/view-template/model/view-template-element";
import { T2CheckBoxComponent } from "src/app/core/cmp/ui/t2-check-box/t2-check-box.component";
import { DeviceRegistrationStopReason } from "../model/device-registration-stop-reason";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/Operators";

@Component({
  selector: 'app-appointment-register-stop-reason',
  templateUrl: './appointment-register-stop-reason.component.html',
  styleUrls: ['./appointment-register-stop-reason.component.scss']
})
export class AppointmentRegisterStopReasonComponent implements OnDestroy {
  @Input()
  get stopReasonList(): Array<DeviceOperationStopReason> {
    return this._stopReasonList;
  }
  set stopReasonList(value: Array<DeviceOperationStopReason>) {
    this._stopReasonList = value;
    this.generateForm();
  }

  private _stopReasonList: Array<DeviceOperationStopReason>;
  private unsubscribe = new Subject<void>();
  public loaded: boolean = false;
  public formGroup: FormGroup;
  public layout: Array<ViewTemplateElement>;
  public devRegStopReasonList = new Array<DeviceRegistrationStopReason>();

  constructor(private formBuilder: FormBuilder) { }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  private generateForm() {
    this.loaded = false;

    this.formGroup = this.formBuilder.group({});

    this.layout = [
      {
        layoutType: LayoutType.gridLayout,
        children: []
      }
    ];

    let cmpList = this.layout[0].children;

    this.stopReasonList?.forEach(sr => {
      cmpList.push({
        layoutType: LayoutType.component,
        cmpName: sr.id_stopreason,
        cmpType: T2CheckBoxComponent,
        title: sr.description,
        columnSpan: 2,
        isBaseComponent: true
      });

      this.formGroup.addControl(sr.id_stopreason, this.formBuilder.control(false));
      this.formGroup.controls[sr.id_stopreason].valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe(checked => {
        if (checked) {
          this.devRegStopReasonList.push({ id_stopReason: sr.id_stopreason });
        } else {
          let index = this.devRegStopReasonList.findIndex(item => item.id_stopReason == sr.id_stopreason);

          if (index > -1) {
            this.devRegStopReasonList.splice(index, 1);
          }
        }
      });
    });

    this.loaded = true;
  }
}
