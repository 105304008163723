<div *ngIf="registrationData.contentType === 'boolean'" class="flexAlignRowEndCenter">
  <nb-checkbox [formControl]="valueControl"> </nb-checkbox>
</div>

<div *ngIf="registrationData.contentType === 'datetime'" class="propValue">
  <input nbInput [nbDatepicker]="formpicker" [formControl]="valueControl" fullWidth fieldSize="tiny"
    style="max-width: 150px; margin-left: 5px" />
  <nb-datepicker #formpicker></nb-datepicker>
</div>
<div *ngIf="registrationData.contentType === 'list'">
  <app-t2-select [t2IdCmp]="'regFixEditor'" [formGroup]="formGroup"
    [list]="registrationData.listOption"
    [visible]="true"
    [readOnly]="false">
  </app-t2-select>
</div>


<!-- <div *ngIf="registrationData.contentType === 'list'" class="propValue">
  <nb-select size="tiny" fullWidth [formControl]="valueControl">
    <nb-option
      *ngFor="let opt of registrationData.listOption"
      [value]="opt.description"
    >
      {{ opt.description }}
    </nb-option>
  </nb-select>
</div> -->

<editor-cell [editorValue]="numberValue" class="propValue"
  *ngIf="['integer', 'decimal'].includes(registrationData.contentType)" (changed)="onNumberValueChange($event)">
</editor-cell>

<input #input *ngIf="['text', 'memo'].includes(registrationData.contentType)" nbInput fieldSize="small" fullWidth
  type="text" [formControl]="valueControl" class="propValue" />
