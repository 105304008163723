import { Component, Input, OnDestroy, OnInit, Optional, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { Subscription } from "rxjs";
import { take, takeWhile } from "rxjs/Operators";
import { FormTemplateComponent } from "src/app/core/cmp/form-template/form-template.component";
import { T2ViewTemplateData } from "src/app/core/cmp/view-template/model/t2-view-template-data";
import { LayoutType, ViewTemplateElement } from "src/app/core/cmp/view-template/model/view-template-element";
import { T2AccessItem, T2AccessItemDatasetActionType } from "src/app/core/security/model/t2accessItem";
import { T2SecurityService } from "src/app/core/security/t2security.service";
import { T2HttpClientService } from "src/app/core/http/t2httpClient.service";
import { T2gridComponent } from "src/app/core/cmp/t2grid/t2grid.component";
import { T2DatasetService } from "src/app/core/dataset/t2dataset.service";
import { T2MessageService } from "src/app/core/t2-message.service";
import { DepartmentalRequisition } from "../model/departmental-requisition";
import { T2InputTextComponent } from "src/app/core/cmp/ui/t2-input-text/t2-input-text.component";
import { T2AggregationComponent } from "src/app/core/cmp/ui/t2-aggregation/t2-aggregation.component";
import { T2SelectComponent } from "src/app/core/cmp/ui/t2-select/t2-select.component";
import { ViewTemplateComponent } from "src/app/core/cmp/view-template/view-template.component";
import { NbDialogRef, NbDialogService } from "@nebular/theme";
import { DialogComponent } from "src/app/core/cmp/dialog/dialog.component";

@Component({
  selector: 'app-edit-requisition',
  templateUrl: './edit-requisition.component.html',
  styleUrls: ['./edit-requisition.component.scss']
})
export class EditRequisitionComponent implements OnInit, OnDestroy {

  formGroup: FormGroup;
  data: Array<T2ViewTemplateData> = [
    { cmpName: `${EditRequisitionComponent.reqGroupDatasetName}.codigo`, properties: { readOnly: true } },
    { cmpName: `${EditRequisitionComponent.reqGroupDatasetName}.id_security_identity`, properties: { readOnly: true, datasetId: "{0FD9B8EE-8A91-4F81-BF0F-C8F5DDD8BE83}", datasetName: "afx_security_identity_user" } },
  ];
  layout: ViewTemplateElement[] = [
    {
      layoutType: LayoutType.gridLayout,
      children: [
        {
          layoutType: LayoutType.component,
          title: "Código",
          cmpName: `${EditRequisitionComponent.reqGroupDatasetName}.codigo`,
          cmpType: T2InputTextComponent,
          columnSpan: 2,
          isBaseComponent: true
        },
        {
          layoutType: LayoutType.component,
          title: "Solicitante",
          cmpName: `${EditRequisitionComponent.reqGroupDatasetName}.id_security_identity`,
          cmpType: T2AggregationComponent,
          columnSpan: 5,
          isBaseComponent: true
        },
        {
          layoutType: LayoutType.component,
          title: "Centro de custo",
          cmpName: `${EditRequisitionComponent.reqGroupDatasetName}.id_centrodecusto`,
          cmpType: T2SelectComponent,
          columnSpan: 5,
          isBaseComponent: true
        },

      ]
    }
  ];

  private pathParams$: Subscription = null;
  buttonDelete = false;
  saving = false;
  code: string | null;
  private id_security_identity: string | null;
  requisitionList: Array<DepartmentalRequisition> = [];
  requisitionDeletedList: Array<DepartmentalRequisition> = [];
  private costCenterList: Array<{ costCenterId: string, code: string, description: string }> = [];
  statusList: any;
  public btnCanRequestApprove: boolean;
  public btnCanReturnToEdit: boolean;
  public selectedRequisition: DepartmentalRequisition;

  private static reqGroupDatasetName = "stk_requisicaoDepartAgrup";
  private static reqDatasetName = "stk_requisicao_departamental";

  @ViewChild("formTemplate", { static: true }) formTemplate: FormTemplateComponent;
  @ViewChild("gridReq", { static: true }) gridReq: T2gridComponent;
  @ViewChild("viewTemplate", { static: true }) viewTemplate: ViewTemplateComponent;

  @Input() id_requisicaoDepartAgrup: string | null;
  @Input() edit: boolean = false;
  @Input() insert: boolean = false;

  constructor(private route: ActivatedRoute,
    public router: Router,
    private formBuilder: FormBuilder,
    private httpClient: T2HttpClientService,
    private messageService: T2MessageService,
    private datasetService: T2DatasetService,
    private sec: T2SecurityService,
    private dialogService: NbDialogService,
    @Optional() public dialogRef: NbDialogRef<any>) {

    this.formGroup = formBuilder.group({});
    this.formGroup.addControl(`${EditRequisitionComponent.reqGroupDatasetName}.codigo`, formBuilder.control(null, Validators.required));
    this.formGroup.addControl(`${EditRequisitionComponent.reqGroupDatasetName}.id_security_identity`, formBuilder.control(null, Validators.required));
    this.formGroup.addControl(`${EditRequisitionComponent.reqGroupDatasetName}.id_centrodecusto`, formBuilder.control(null, Validators.required));
  }

  ngOnInit(): void {
    if (typeof this.insert == "string") {
      this.insert = JSON.parse(this.insert);
    }

    if (typeof this.edit == "string") {
      this.edit = JSON.parse(this.edit);
    }

    // Carrega ações da requisições e verifica acessos
    this.sec.getDatasetActions(EditRequisitionComponent.reqDatasetName)
      .subscribe((actionList: T2AccessItem[]) => {

        if ((this.insert && actionList.find(a => a.datasetActionType == T2AccessItemDatasetActionType.DSACTION_INSERT)) ||
          (this.edit && actionList.find(a => a.datasetActionType == T2AccessItemDatasetActionType.DSACTION_EDIT))) {

          if (this.formTemplate.validateAccess(actionList.map(a => a.id), 'full')) {

            this.buttonDelete = actionList.find(ac => ac.datasetActionType === T2AccessItemDatasetActionType.DSACTION_DELETE) != undefined;

            this.datasetService.getStatusList("stk_requisicao_departamental").subscribe(statusList => {
              this.statusList = statusList;
              this.statusList?.forEach(status => {
                this.datasetService.getNextStatus("stk_requisicao_departamental", status.description).subscribe(statusFlow => {
                  status.flow = statusFlow || [];
                });
              });

              this.setGridColumns();
            });

            this.sec.authStatusObservable()
              .pipe(takeWhile(val => !val?.userId, true))
              .subscribe(resp => {
                if (resp.userId) {
                  this.id_security_identity = resp.userId;
                  this.loadInfo();
                }
              });

          }
        } else {
          // Se o usuario nao tem acesso a ação informada (INSERT ou EDIT), simplesmente avisa ao template que
          // não há acesso
          this.formTemplate.validateAccess(null, 'full');
        }
      });
  }

  ngOnDestroy(): void {
    if (this.pathParams$) {
      this.pathParams$.unsubscribe();
    }
  }

  private loadInfo() {
    // Carrega os centros de custo
    this.costCenterList = [];
    let params = new Map<string, string>();
    params.set("id_security_identity", this.id_security_identity);

    this.httpClient.get("stk.departmentalRequisition/getCostCenterList", params)
      .pipe(take(1))
      .subscribe(resp => {
        if (resp.costCenterList) {
          if (!Array.isArray(resp.costCenterList)) {
            resp.costCenterList = [resp.costCenterList]
          }

          resp.costCenterList.forEach(cc => {
            this.costCenterList.push({ costCenterId: cc.costCenterId, code: cc.code, description: cc.description });
          });

          this.viewTemplate.setCmpInputValue(`${EditRequisitionComponent.reqGroupDatasetName}.id_centrodecusto`, "list",
            this.costCenterList.map(cc => {
              return {
                id: cc.costCenterId,
                description: cc.description
              }
            })
          );
        }

        if (this.insert) {
          this.formGroup.controls[`${EditRequisitionComponent.reqGroupDatasetName}.id_centrodecusto`].setValue(this.costCenterList[0]?.costCenterId);
          this.formGroup.controls[`${EditRequisitionComponent.reqGroupDatasetName}.id_centrodecusto`].markAsDirty();

          this.setNewReqData();
        } else if (this.edit) {
          this.setExistingReqData();
        } else {
          // Invalid operation (it should not happen, what isnt INSERT or EDIT?)
        }

        this.gridReq.setGridData(this.requisitionList, null);
      });
  }

  private setGridColumns() {
    this.gridReq.setGridColumnDefs([
      { headerName: "Seq", field: "code", width: 100 },
      {
        headerName: "Status", field: "status", type: "statusColumn", width: 200,
        editable: (params) => {
          let editable: boolean = !params?.data?.newReq && !this.canRequestApprove() && !this.canReturnToEdit();

          if (editable) {
            editable = !["Finalizada", "Cancelada"].includes(params?.data?.status);
          }

          return editable;
        },
        statusFlow: () => this.statusList.map(status => { // É passado como function pois o fluxo ainda está sendo carregado
          return {
            name: status.description,
            action: status.execDescription,
            tooltip: status.helpText,
            colorStyle: status.statusColorStyle
          }
        }),
      },
      {
        headerName: "Item", field: "id_item", type: "aggregationColumn", width: 400,
        editable: this.insert || this.btnCanRequestApprove,
        datasetId: "{6527C214-BB28-4E85-9A84-FD6B05BC66A9}",
        datasetName: "stk_item_produto",
      },
      { headerName: "UN", field: "un", width: 65 },
      {
        headerName: "Dt Necessária", field: "requiredDate", type: "dateColumn", width: 130, editable: this.insert || this.btnCanRequestApprove,
      },
      {
        headerName: "Qtd Solicitada", field: "userRequestedQty", type: "numericColumn", filter: "agNumberColumnFilter", width: 130, editable: this.insert || this.btnCanRequestApprove,
      },
      { headerName: "Qtd Aprovada", field: "requestedQty", type: "numericColumn", filter: "agNumberColumnFilter", width: 130 },
      { headerName: "Qtd Entregue", field: "consumedQty", type: "numericColumn", filter: "agNumberColumnFilter", width: 130 },
      {
        headerName: "Obs", field: "notes", editable: this.insert || this.btnCanRequestApprove, width: 330,
        cellEditor: 'agLargeTextCellEditor',
        cellEditorPopup: true
      }
    ]);

  }

  private setNewReqData() {
    // ID
    this.httpClient.getNewUUID().subscribe(id => { this.id_requisicaoDepartAgrup = id; });

    // Código
    let params = new Map<string, string>();
    params.set("id_dataset_property", "zD20211223H114558223R000000006");

    this.httpClient.get("core.dataset/dataset/autoincMaskedValue", params)
      .pipe(take(1))
      .subscribe(resp => {
        if (resp.autoincMaskedValue) {
          this.formGroup.controls[`${EditRequisitionComponent.reqGroupDatasetName}.codigo`].setValue(resp.autoincMaskedValue);
          this.formGroup.controls[`${EditRequisitionComponent.reqGroupDatasetName}.codigo`].markAsDirty();

          this.code = resp.autoincMaskedValue;
        }
      });

    // Solicitante
    this.formGroup.controls[`${EditRequisitionComponent.reqGroupDatasetName}.id_security_identity`].setValue(this.id_security_identity);
    this.formGroup.controls[`${EditRequisitionComponent.reqGroupDatasetName}.id_security_identity`].markAsDirty();
  }

  private setExistingReqData() {
    let params = new Map();
    params.set("loadCompositionIDs", true);
    params.set("deleted", false);
    this.httpClient.get(`core.dynDataset/datasetEntity/${EditRequisitionComponent.reqGroupDatasetName}/${this.id_requisicaoDepartAgrup}`, params)
      .pipe(take(1))
      .subscribe(resp => {

        const reqGroup = resp[EditRequisitionComponent.reqGroupDatasetName];

        this.formGroup.controls[`${EditRequisitionComponent.reqGroupDatasetName}.codigo`].setValue(reqGroup.codigo);
        this.formGroup.controls[`${EditRequisitionComponent.reqGroupDatasetName}.codigo`].markAsDirty();
        this.code = reqGroup.codigo;

        this.formGroup.controls[`${EditRequisitionComponent.reqGroupDatasetName}.id_security_identity`].setValue(reqGroup.id_security_identity);
        this.formGroup.controls[`${EditRequisitionComponent.reqGroupDatasetName}.id_security_identity`].markAsDirty();

        this.formGroup.controls[`${EditRequisitionComponent.reqGroupDatasetName}.id_centrodecusto`].setValue(reqGroup.id_centrodecusto);
        this.formGroup.controls[`${EditRequisitionComponent.reqGroupDatasetName}.id_centrodecusto`].markAsDirty();

      });

    this.setExistingReqListData();
  }

  private setExistingReqListData() {
    const params = new Map();
    params.set("id_requisicaoDepartAgrup", this.id_requisicaoDepartAgrup);
    params.set("statusEndsInformation", true);
    this.httpClient.get("stk.departmentalRequisition/requisitions", params)
      .pipe(take(1))
      .subscribe(resp => {

        this.requisitionList = [];
        this.requisitionDeletedList = [];

        if (resp.requisitionList) {
          if (!Array.isArray(resp.requisitionList)) {
            resp.requisitionList = [resp.requisitionList];
          }

          resp.requisitionList.forEach(req => {
            req["ID"] = req.id_requisition;
            req["originalStatus"] = req.status;

            req["statusInfo"] = {
              datasetName: "stk_requisicao_departamental",
              informationId: req.id_requisition
            }
          });

          this.requisitionList = resp.requisitionList;
          this.requisitionList.sort((a: any, b: any) => a.code.localeCompare(b.code));
        }

        this.btnCanRequestApprove = this.canRequestApprove();
        this.btnCanReturnToEdit = this.canReturnToEdit();
        this.setGridColumns();
        this.gridReq.setGridData(this.requisitionList, null);
        this.viewTemplate.setCmpInputValue(`${EditRequisitionComponent.reqGroupDatasetName}.id_centrodecusto`, "readOnly", !this.btnCanRequestApprove);
      });
  }

  addNewReqItem() {
    this.gridStopEditing();

    // Se alguma linha do grid estiver incompleta, nao deixa incluir uma nova linha
    for (let req of this.requisitionList) {
      if (!this.validadeReqItem(req, false)) {
        return;
      }
    }


    this.httpClient.getNewUUID().subscribe(id => {
      let req: DepartmentalRequisition = new DepartmentalRequisition();
      req.id_group_requisition = this.id_requisicaoDepartAgrup;
      req.id_requisition = id;
      req.userRequestedQty = 0.0;
      req.requestedQty = 0.0;
      req.consumedQty = 0.0;
      req.status = "Em Elaboração";
      req["originalStatus"] = req.status;
      req.requiredDate = this.requisitionList[this.requisitionList.length - 1]?.requiredDate;
      req["ID"] = req.id_requisition;
      req["statusInfo"] = {
        datasetName: "stk_requisicao_departamental",
        informationId: req.id_requisition
      };
      req.newReq = true;

      this.requisitionList.push(req);

      this.gridReq.setGridData(this.requisitionList, null);
      this.gridReq.setFocusedColumnByID(req.id_requisition, "id_item");
    });
  }

  private validadeReqItem(req: DepartmentalRequisition, silentError: boolean): boolean {

    if (!req) return true;

    if (!req.id_item) {
      if (!silentError) {
        this.gridReq.editColumnByID(req.id_requisition, "id_item");
        this.messageService.showToastError("Antes de incluir uma nova requisição, informe o item.");
      }
      return false;
    }

    if (!req.requiredDate) {
      if (!silentError) {
        this.gridReq.editColumnByID(req.id_requisition, "requiredDate");
        this.messageService.showToastError("Antes de incluir uma nova requisição, informe a data necessária.");
      }
      return false;
    }

    if (!req.userRequestedQty) {
      if (!silentError) {
        this.gridReq.editColumnByID(req.id_requisition, "userRequestedQty");
        this.messageService.showToastError("Antes de incluir uma nova requisição, informe a quantidade solicitada.");
      }
      return false;
    }

    return true;
  }

  deleteReqItem() {
    if (this.gridReq.getFocusedRow()) {
      if (this.edit) {
        this.requisitionDeletedList.push(this.requisitionList.find(r => r.id_requisition === this.gridReq.getFocusedRow().ID));
      }

      this.requisitionList = this.requisitionList.filter(r => r.id_requisition != this.gridReq.getFocusedRow().ID);
      this.gridReq.removeFocusedRow();
    }
  }

  saveAutoform() {
    this.gridStopEditing();

    if (this.formGroup.invalid) {
      this.messageService.showToastError("Antes de salvar, preencha os campos obrigatórios da requisição");
      return;
    }

    if (this.requisitionList.length == 0) {
      this.messageService.showToastError("Antes de salvar, informe os itens da requisição");
      return;
    }

    this.requisitionList.forEach(req => {
      if (!this.validadeReqItem(req, true)) {
        this.validadeReqItem(req, false);
        return;
      }
    });

    this.saving = true;

    this.datasetService.saveFormGroupEntity(EditRequisitionComponent.reqGroupDatasetName, this.id_requisicaoDepartAgrup, this.formGroup).subscribe(async resp => {
      // Após salvar o grupo de requisição, salvar os itens

      const funcMap = (req: any) => {
        req.id_group_requisition = this.id_requisicaoDepartAgrup;

        return {
          id_requisicao: req.id_requisition,
          id_item: req.id_item,
          dtConsumoPrev: req.requiredDate,
          qtdSolicitada: req.requestedQty,
          obs: req.notes,
          dtAprovacao: null,

          id_requisicaoDepartAgrup: req.id_group_requisition,
          qtdSolicitadaUsuario: req.userRequestedQty,
          id_usuario_aprovacao: null,
          dataAprovacao: null,
          id_usuario_reprovacao: null,
          dataReprovacao: null,
          saved: false,
          newReq: req.newReq
        };

      };

      // Transforma REQ na estrutura do dataset para poder salvar
      let reqs = this.requisitionList.map(funcMap);

      // Adicionar as requisições que vão ser excluídas
      reqs = reqs.concat(...this.requisitionDeletedList.map(funcMap).map((req: any) => { req.isDeleted = true; return req; }));

      // Salva cada requisição
      for (let req of reqs) {
        if (req.newReq) {
          await this.datasetService.saveObject(EditRequisitionComponent.reqDatasetName, req.id_requisicao, req).toPromise().then(reqResp => {
            req.saved = true;
          }, error => {
            this.saving = false;
          });
        } else {
          this.datasetService.saveObject(EditRequisitionComponent.reqDatasetName, req.id_requisicao, req).subscribe(reqResp => {
            req.saved = true;
          }, error => {
            this.saving = false;
          })
        }
      }

      let idInterval = setInterval(() => {
        if (!this.saving || reqs.every(r => r.saved)) {
          clearInterval(idInterval);

          if (this.insert) {
            this.insert = false;
            this.edit = true;
          }

          this.setExistingReqListData();
          this.saving = false;
        }
      }, 100);
    }, error => {
      this.saving = false;
    });
  }

  deleteRecord() {
    this.messageService
      .showDialog({
        context: {
          message: "Deseja exluir o grupo de requisições?",
          actions: [{ description: "Sim" }, { description: "Não", status: "basic" }]
        }
      })
      .onClose.subscribe(resp => {
        if (resp == "Sim") {
          this.saving = true;
          this.datasetService.deleteDatasetRecord(EditRequisitionComponent.reqGroupDatasetName, this.id_requisicaoDepartAgrup)
            .subscribe(resp => {
              this.saving = false;
              if (!resp.error) {
                this.close();
              }

            }, error => {
              this.saving = false;
              if (error.message) {
                this.messageService.showToastError(error.message);
              }
            });
        }
      });
  }

  public canRequestApprove(): boolean {
    return this.requisitionList.every(req => req.status == "Em Elaboração");
  }

  public requestApprove() {
    this.datasetService.getNextStatusFlow(EditRequisitionComponent.reqDatasetName, "Em Elaboração")
      .pipe(take(1))
      .subscribe(statusFlowList => {
        if (statusFlowList) {
          let statusFlow = statusFlowList.find(st => st.status == "Aguardando Aprovação");

          if (statusFlow.showQuestionMsg) {
            let dlg = this.dialogService.open(DialogComponent, {
              context: {
                //title: "Mudança de Status",
                message: statusFlow.questionMsg,
                actions: [{ description: "Sim" }, { description: "Não", status: "basic" }]
              }
            });

            dlg.onClose.pipe(take(1)).subscribe(resp => {
              if (resp == "Sim") {
                this.changeReqGroupStatus("Aguardando Aprovação");
              }
            });
          } else {
            this.changeReqGroupStatus("Aguardando Aprovação")
          }
        }
      });
  }

  public canReturnToEdit() {
    return this.requisitionList.every(req => req.status == "Aguardando Aprovação");
  }

  public returnGroupToEdit() {
    this.datasetService.getNextStatusFlow(EditRequisitionComponent.reqDatasetName, "Aguardando Aprovação")
      .pipe(take(1))
      .subscribe(statusFlowList => {
        if (statusFlowList) {
          let statusFlow = statusFlowList.find(st => st.status == "Em Elaboração");

          if (statusFlow.showQuestionMsg) {
            let dlg = this.dialogService.open(DialogComponent, {
              context: {
                //title: "Mudança de Status",
                message: statusFlow.questionMsg,
                actions: [{ description: "Sim" }, { description: "Não", status: "basic" }]
              }
            });

            dlg.onClose.pipe(take(1)).subscribe(resp => {
              if (resp == "Sim") {
                this.changeReqGroupStatus("Em Elaboração");
              }
            });
          } else {
            this.changeReqGroupStatus("Em Elaboração")
          }
        }
      });
  }

  private changeReqGroupStatus(status: string) {
    let params = new Map<string, string>();
    params.set("status", status);

    this.httpClient.post("stk.departmentalRequisition/changeStatusGroupRequisition", params, this.requisitionList.map(req => req.id_requisition))
      .pipe(take(1))
      .subscribe(resp => {
        this.requisitionList.forEach(req => {
          req.status = status
        });

        this.btnCanRequestApprove = this.canRequestApprove();
        this.btnCanReturnToEdit = this.canReturnToEdit();
        this.setGridColumns(); // para atualizar a condição de editável das colunas
        this.gridReq.setGridData(this.requisitionList, null);
        this.gridReq.redrawRows();

        this.viewTemplate.setCmpInputValue(`${EditRequisitionComponent.reqGroupDatasetName}.id_centrodecusto`, "readOnly", !this.btnCanRequestApprove);
      });
  }

  gridStopEditing() {
    if (this.gridReq.t2Grid.api.getCellEditorInstances().length > 0) {
      this.gridReq.t2Grid.api.stopEditing();
    }
  }

  close() {
    this.dialogRef.close({ id_requisicaoDepartAgrup: this.id_requisicaoDepartAgrup });
  }

  onGridRowClick(params) {
    this.selectedRequisition = this.requisitionList.find(req => params?.data?.ID == req.id_requisition);
  }
}
