import { Injectable } from '@angular/core';
import { AbstractControl, FormGroup, ValidatorFn, Validators } from "@angular/forms";
import { Observable, Subject } from "rxjs";
import { take } from "rxjs/Operators";
import { LayoutType, ViewTemplateElement } from "./model/view-template-element";

@Injectable()
export class T2ViewTemplateFunctionsService {

  private formGroup: FormGroup;
  private cmpInputs: Array<{ cmpName: string, name: string, data: any }>;

  constructor() { }

  initialize(formGroup: FormGroup, cmpInputs: Array<{ cmpName: string, name: string, data: any }>) {
    this.formGroup = formGroup;
    this.cmpInputs = cmpInputs;
  }

  getCmpValue({ cmpName }: { cmpName: string }) {
    this.checkParameters({ cmpName: cmpName });
    return this.formGroup.controls[cmpName].value;
  }

  setCmpValue({ cmpName, value, emitChangeEvent }: { cmpName: string, value: any, emitChangeEvent?: boolean }) {
    this.checkParameters({ cmpName: cmpName });
    this.formGroup.controls[cmpName].setValue(value, { emitEvent: emitChangeEvent ?? true });
    this.formGroup.controls[cmpName].markAsDirty();
  }

  getInputValue({ cmpName, inputName }: { cmpName: string, inputName: string }) {
    this.checkParameters({ cmpName: cmpName, inputName: inputName });
    let cmpInp = this.cmpInputs.filter(ci => ci.cmpName == cmpName && ci.name == inputName)[0];

    return cmpInp.data;
  }

  getComponent(cmpName: string, layout: ViewTemplateElement): ViewTemplateElement {
    if (layout.layoutType == LayoutType.component && layout.cmpName == cmpName) {
      return layout;
    }

    if (layout.children) {
      for (let i = 0; i < layout.children.length; i++) {
        let cmp = this.getComponent(cmpName, layout.children[i]);

        if (cmp) {
          return cmp;
        }
      }
    }

    return undefined;
  }

  changeValue({ value }: { value: any }) {
    return value;
  }

  scriptParams(value: any) {
    return value;
  }

  setValidators({ cmpName, validators, clearValidators }: { cmpName: string, validators: ValidatorFn | ValidatorFn[], clearValidators: boolean }) {
    if (clearValidators) {
      this.formGroup.controls[cmpName].clearValidators();
    } else {
      this.formGroup.controls[cmpName].setValidators(validators);
    }

    this.formGroup.controls[cmpName].updateValueAndValidity();
  }

  private checkParameters(parameters: { [paramName: string]: any }) {
    let missingParams = new Array<string>();

    Object.keys(parameters).forEach((key) => {
      if (parameters[key] === undefined) {
        missingParams.push(key);
      }
    });

    if (missingParams.length == 1) {
      throw new Error(`O parâmetro ${missingParams[0]} não foi informado`);
    } else if (missingParams.length > 1) {
      throw new Error(`Os seguintes parâmetros não foram informados: ${missingParams.join(",")}`);
    }
  }

  clearValue() {
    return undefined;
  }

  returnScriptResponse(response: Observable<any>): Observable<Array<{ cmpName: string, value: any, cmpInputName?: string, emitChangeEvent?: boolean }>> {
    let sub = new Subject<Array<{ cmpName: string, value: any, cmpInputName?: string, emitChangeEvent?: boolean }>>();
    response.pipe(take(1)).subscribe(resp => {
      resp.result = JSON.parse(resp.result);
      if (!Array.isArray(resp.result)) {
        resp.result = [resp.result];
      }

      sub.next(resp.result);
    });

    return sub.asObservable();
  }
}
