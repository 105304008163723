import { Component, Input, OnInit } from "@angular/core";
import { T2LayoutModel } from "./layout.model";

@Component({
  selector: "app-t2-layout",
  templateUrl: "./t2-layout.component.html",
  styleUrls: ["./t2-layout.component.scss"],
})
export class T2LayoutComponent implements OnInit {
  private _layout: T2LayoutModel;

  center = "end";

  @Input()
  get layout(): T2LayoutModel {
    return this._layout;
  }
  set layout(value: T2LayoutModel) {
    this._layout = this.checkLayout(value);
    console.log(this._layout);
  }

  constructor() {}

  ngOnInit(): void {}

  private checkLayout(layout: T2LayoutModel) {

    return layout;
  }

  t2Style(parent: T2LayoutModel, layout: T2LayoutModel): object {
    const style = {
      width: layout.width,
    };

    if (layout.width) {
      style["max-width"] = layout.width;
    }

    return style;
  }

  t2StyleXS(parent: T2LayoutModel, layout: T2LayoutModel): object {
    return {};

    const style = {
      "max-width": "100%",
      width: layout.widthXS,
    };

    if (layout.items?.length && layout.width) {
      style["min-width"] = "360px";
      style["max-width"] = "360px";
    } else {
      style["min-width"] = layout.widthXS || layout.width;
      style["max-width"] = style["min-width"];
    }

    return style;
  }
}
