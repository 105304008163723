<div class="pieIndex">
  <div *ngIf="!smallWindow" echarts [options]="co" [theme]="themeName" (chartInit)="onChartInit($event)"
    style="width: 100%; height: 100%;">
  </div>

  <div *ngIf="smallWindow && values.length" class="barra-externa">
    <div *ngFor="let item of values; let isLast = last" class="barra-interna"
      [style.width]="(item.value / total) * 100+'%'"
      [ngStyle]="{'border-radius': isLast ? '0px 80px 80px 0px' : '0px',
                 'position': 'relative',
                 'text-shadow': '1px 0 '+bgColor[item.color]+', -1px 0 '+bgColor[item.color]+', 0 1px '+bgColor[item.color]+', 0 -1px '+bgColor[item.color]+';'}"
      [style.background-color]="bgColor[item.color]">
      <div *ngIf="item.value" class="qttyInfo">{{item.value | number: "1.0-2"}}m</div>
    </div>
  </div>
  <div *ngIf="title" class="title">{{title}}</div>
</div>
