import { AutoformTabsheet } from './../../form/model/autoformTabsheet';
import { LayoutType } from './../../cmp/view-template/model/view-template-element';
import { Autoform } from './../../form/model/autoform';
import { Component, OnInit, ViewChild } from '@angular/core';
import { take } from "rxjs/Operators";
import { FormTemplateComponent } from "../../cmp/form-template/form-template.component";
import { ViewTemplateElement } from "../../cmp/view-template/model/view-template-element";
import { T2HttpClientService } from "../../http/t2httpClient.service";
import { T2SecurityService } from "../../security/t2security.service";
import { AutoformGroup } from "../../form/model/autoformGroup";

@Component({
  selector: 'app-autoform',
  templateUrl: './autoform.component.html',
  styleUrls: ['./autoform.component.scss']
})
export class AutoformComponent implements OnInit {

  @ViewChild("formTemplate", { static: true }) formTemplate: FormTemplateComponent;

  loaded: boolean = false;
  layout: ViewTemplateElement[] = [];

  private datasetName = "crm_conta_cliente";
  private informationId = "";
  private autoform: Autoform = null;

  constructor(private sec: T2SecurityService, private httpClient: T2HttpClientService) { }

  ngOnInit(): void {
    this.sec.accessLoaded().pipe(take(1)).subscribe(() => {
      if (this.formTemplate.validateAccess(["zD20210723H134635550R000000005"], 'full')) {

        this.loadAutoformInfo();
        this.loaded = true;
      }
    });
  }

  private loadAutoformInfo() {
    const params = new Map<string, string>();
    params.set("id_recordOfDataset", this.informationId);

    this.httpClient.get(`core.dynAutoform/autoform/${this.datasetName}`, params)
      .pipe(take(1))
      .subscribe(resp => {

        if (resp.autoform.mainTabsheet) {
          this.validateDGArray(resp.autoform.mainTabsheet);
        }

        if (resp.autoform.tabsheetList) {
          resp.autoform.tabsheetList = resp.autoform.tabsheetList.tabsheet;
          if (!Array.isArray(resp.autoform.tabsheetList)) {
            resp.autoform.tabsheetList = [resp.autoform.tabsheetList];
          }

          resp.autoform.tabsheetList.forEach(ts => {
            this.validateDGArray(ts);
          });
        }

        this.autoform = resp.autoform;
        this.formTemplate.formTitle = this.autoform.description;

        this.layout = [{ layoutType: LayoutType.listLayout, direction: "column", children: [] }];

        this.addAutoformTabsheetToPart(this.layout[0], this.autoform.mainTabsheet);
        this.autoform.tabsheetList.forEach(t => this.addAutoformTabsheetToPart(this.layout[0], t));

        console.log(this.layout);
      });
  }

  private validateDGArray(ts: any) {

    if (ts.dataGroupList) {
      ts.dataGroupList = ts.dataGroupList.dataGroup;
      if (!Array.isArray(ts.dataGroupList)) {
        ts.dataGroupList = [ts.dataGroupList];
      }

      ts.dataGroupList.forEach((dg: any) => {
        if (dg.propertyList) {
          dg.propertyList = dg.propertyList.property;
        }

        if (!Array.isArray(dg.propertyList)) {
          dg.propertyList = [dg.propertyList];
        }

        dg.propertyList.forEach((prop: any) => {
          if (prop.aggregation) {
            prop.span = 3;
          } else if (prop.javaType === "String") {
            if (prop.contentType === "MEMO") {
              prop.span = 4;
            } else if (prop.size <= 30) {
              prop.span = 1;
            } else if (prop.size <= 60) {
              prop.span = 2;
            } else {
              prop.span = 3;
            }
          } else {
            prop.span = 1;
          }
        });
      });
    }
  }

  private addAutoformTabsheetToPart(l: ViewTemplateElement, t: AutoformTabsheet) {

    if (!t || !t.dataGroupList || !t.dataGroupList.length) return;

    let parent: ViewTemplateElement;

    if (t.dataGroupList.length > 1) {
      parent = { layoutType: LayoutType.listLayout, direction: "column", title: t.description, children: [] };
      l.children.push(parent);
    } else {
      parent = l;
    }

    t.dataGroupList.forEach(g => this.addAutoformGroupToPart(parent, g));
  }

  private addAutoformGroupToPart(l: ViewTemplateElement, g: AutoformGroup) {

    if (!g || !g.propertyList || !g.propertyList.length) return;

    const part: ViewTemplateElement = { layoutType: LayoutType.gridLayout, children: [], title: g.description };
    l.children.push(part);

    g.propertyList.forEach(p => {
      const c: ViewTemplateElement = {
        layoutType: LayoutType.component,
        title: p.label || p.name,
        columnSpan: p.span || 1,
        toolTip: p.contentType
      };
      part.children.push(c);
    });

  }

}


