/**
 * 1. Testar execução da prova de conceito
 */
import { AfterViewInit, Component, Input, OnDestroy, Optional, ViewChild } from '@angular/core';
import { NbDialogRef } from "@nebular/theme";
import { ConditionField, ConditionGroup, DatasetVisualization, SelectableField, T2VisualizationService, VisualizationField } from "../visualization/t2-visualization.service";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/Operators";
import { FormBuilder, Validators } from "@angular/forms";
import { LayoutType, ViewTemplateElement } from "../../cmp/view-template/model/view-template-element";
import { T2InputTextComponent } from "../../cmp/ui/t2-input-text/t2-input-text.component";
import { T2CheckBoxComponent } from "../../cmp/ui/t2-check-box/t2-check-box.component";
import { ViewTemplateComponent } from "../../cmp/view-template/view-template.component";

@Component({
  selector: 'app-query',
  templateUrl: './query.component.html',
  styleUrls: ['./query.component.scss']
})
export class QueryComponent implements AfterViewInit, OnDestroy {

  private unsubscribe$ = new Subject<void>();

  public savingQuery = false;
  public formGroup = this.formBuilder.group({}, { updateOn: 'blur' });
  public layout: Array<ViewTemplateElement> = [
    {
      layoutType: LayoutType.listLayout,
      direction: "column",
      children: [
        {
          layoutType: LayoutType.gridLayout,
          children: [
            {
              layoutType: LayoutType.component,
              cmpType: T2InputTextComponent,
              cmpName: 'queryDescription',
              title: "Descrição",
              columnSpan: 6,
              isBaseComponent: true,
              inputs: { visible: true }
            },
            {
              layoutType: LayoutType.component,
              cmpType: T2CheckBoxComponent,
              cmpName: 'queryPublic',
              title: "Público",
              isBaseComponent: true,
              inputs: { visible: true, readOnly: false }
            },
            {
              layoutType: LayoutType.component,
              cmpType: T2CheckBoxComponent,
              cmpName: 'queryDefault',
              title: "Padrão",
              isBaseComponent: true,
              inputs: { visible: true, readOnly: true }
            }
          ]
        }
      ]
    }
  ];
  private selectableFieldList: SelectableField[] = [];
  public tabSelected = 1;

  @Input() id_dataset: string;
  @Input() datasetVisualization: DatasetVisualization;
  private _fromDictionary: boolean = false;
  @Input()
  get fromDictionary() { return this._fromDictionary; }
  set fromDictionary(value: boolean) { this._fromDictionary = value; this.setFormGroupValues() }

  @ViewChild("viewTemplate", { static: true }) viewTemplate: ViewTemplateComponent;

  constructor(
    private formBuilder: FormBuilder,
    private visService: T2VisualizationService,
    @Optional() protected dialogRef: NbDialogRef<QueryComponent>) {

    this.formGroup.addControl("queryDescription", this.formBuilder.control(undefined, [Validators.required]));
    this.formGroup.addControl("queryPublic", this.formBuilder.control(this.fromDictionary, []));
    this.formGroup.addControl("queryDefault", this.formBuilder.control(false, []));
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngAfterViewInit(): void {

    this.setFormGroupValues();
    this.viewTemplate.setCmpInputValue("queryDescription", "readOnly", this.fromDictionary);

    if (this.id_dataset && this.datasetVisualization.queryCondition?.fieldList || this.datasetVisualization.queryCondition?.groupList) {

      // 1. Agrupa todos os campos por JOIN
      let fieldsName: string[] = [];
      const getNames = (g: ConditionGroup) => {
        g.fieldList?.forEach(i => {
          const sa = i.property.split(".");
          sa.pop();
          fieldsName.push(sa.join("."));
        });

        g.groupList?.forEach(g1 => getNames(g1));
      }
      getNames(this.datasetVisualization.queryCondition);
      // remove duplicidade e coloca em ordem alfabetica
      fieldsName = [...new Set(fieldsName.filter(i => i)).keys()].sort((s1, s2) => s1.localeCompare(s2));
      const groupedFields = [];
      this.groupFields(fieldsName, groupedFields);

      // 2. Carrega configuracao dos campos do dataset principal
      this.visService.loadFieldList({
        id_dataset: this.id_dataset,
        id_dataset_previous: null,
      })
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(resp => {
          this.selectableFieldList.push(...resp.fieldList);

          // 3. Depois que carregou os campos das tabelas principais, carrega as configurações dos campos que são JOIN
          groupedFields.forEach(gf => {
            const field = this.selectableFieldList.find(f => f.propertyName == gf.name);
            if (field) {
              this.loadGroupedField(gf, field, [field.propertyLabel || field.propertyName]);
            }
          });

          //TESTE
          for (const fn of fieldsName) {
            this.visService.loadFieldSublist(this.id_dataset, this.selectableFieldList, fn);
          }

          this.fillItems(this.datasetVisualization.queryCondition.fieldList, this.selectableFieldList);
          this.fillGroups(this.datasetVisualization.queryCondition.groupList, this.selectableFieldList);

        });
    }
  }

  private setFormGroupValues() {
    this.formGroup.controls["queryDescription"].setValue(this.datasetVisualization?.description);
    this.formGroup.controls["queryPublic"].setValue(this.datasetVisualization?.publicVisualization || this.fromDictionary);
    this.formGroup.controls["queryDefault"].setValue(this.fromDictionary);

    this.viewTemplate.getCmpReference("queryPublic") ? this.viewTemplate.setCmpInputValue("queryPublic", "readOnly", this.fromDictionary) : undefined;
    // this.viewTemplate.setCmpInputValue("queryDefault", "visible", this.fromDictionary);
    this.viewTemplate.getCmpReference("queryDefault") ? this.viewTemplate.setCmpInputValue("queryDefault", "readOnly", true) : undefined;
  }

  private groupFields(fieldsName: string[], groupedFields: any[]) {
    fieldsName.forEach(name => {
      if (name.indexOf(".") <= 0) {
        groupedFields.push({ name, child: [] });
      } else {
        const arrayName = name.split(".");
        const firstName = arrayName.shift();
        let existingName = groupedFields.find(gf => gf.name == firstName);
        if (!existingName) {
          existingName = { name: firstName, child: [] }
          groupedFields.push(existingName);
        }

        if (arrayName.length > 1) {
          this.groupFields([arrayName.join(".")], existingName.child);
        } else if (arrayName.length > 0) {
          existingName.child.push({ name: arrayName[0], child: [] })
        }
      }
    });
  }

  private loadGroupedField(groupedField, mainField: SelectableField, previous: string[]) {

    this.visService.loadFieldList({ id_dataset: mainField.id_dataset_aggregation })
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(resp => {
        mainField.description = [...previous].join(" / ");
        mainField.fieldList = resp.fieldList;
        mainField.fieldList.forEach(f => {
          f.description = [...previous, (f.propertyLabel || f.propertyName)].join(" / ");
        });

        groupedField.child?.forEach(gf => {
          const field = mainField.fieldList.find(f => f.propertyName == gf.name);
          if (field) {
            this.loadGroupedField(gf, field, [...previous, (field.propertyLabel || field.propertyName)]);
          }
        });

        if (!groupedField.child || !groupedField.child.length) {
          this.fillItems(this.datasetVisualization.queryCondition.fieldList, this.selectableFieldList);
          this.fillGroups(this.datasetVisualization.queryCondition.groupList, this.selectableFieldList);
        }
      });
  }

  private getSelectableField(fieldList: SelectableField[], fieldName: string): SelectableField {
    const fieldLevels = fieldName.split(".");
    if (fieldLevels.length > 1) {
      const field = fieldList?.filter(f => f.joinName).find(f => {
        const name = f.joinName + "." + f.propertyName;
        return fieldName.startsWith(name);
      });

      if (field) {

        if ((field.join + "." + field.propertyName) == fieldName) {
          return field;
        } else {
          // Se o campo nao é exatamente o que se quer, mas começa com o texto que se quer, é porque é uma tabela
          // que se liga com esse campo (uma agregação ou composição)

          return null;
        }

        fieldLevels.splice(0, 1);
        return this.getSelectableField(field.fieldList, fieldLevels.join("."));
      }

      return null;
    } else {
      return fieldList?.find(f => {
        const name = f.propertyName;
        return fieldName == name;
      });
    }
  }

  private fillItems(items: ConditionField[], fieldList: SelectableField[]) {

    if (!fieldList || !fieldList.length || !items || !items.length)
      return;

    items.forEach(i => {
      const field = this.getSelectableField(fieldList, i.property);

      if (field) {
        i.datasetId = field.id_dataset_aggregation || i.datasetId;
        i.datasetName = field.datasetName;
        i.propertyType = field.propertyType;
        i.inactityControl = field.inactityControl;
        i.statusControl = field.statusControl;
        i.description = field.description || field.propertyLabel || field.propertyName;
        this.visService.getValueOptions(i, field.datasetName, field.propertyName);
        i.operators = this.visService.validOperators(field.propertyType) || i.operators || [];
        this.visService.selectOperator(i, i.operator);
      } else if (i.propertyType) {
        i.operators = this.visService.validOperators(i.propertyType) || i.operators || [];
      }

      this.visService.selectOperator(i, i.operator);
    });
  }

  private fillGroups(groups: ConditionGroup[], fieldList: SelectableField[]) {

    if (!fieldList || !fieldList.length || !groups || !groups.length)
      return;

    groups.forEach(g => {
      this.fillItems(g.fieldList, fieldList);
      this.fillGroups(g.groupList, fieldList);
    });
  }

  closeDialog(save: boolean) {
    if (this.dialogRef) {

      if (save && this.formGroup.invalid) {
        this.tabSelected = 1;
        return;
      }

      if (save) {
        const dv: DatasetVisualization = {
          id_dataset_visualization: this.datasetVisualization.id_dataset_visualization,
          description: this.formGroup.controls["queryDescription"].value || undefined,
          detail: undefined,
          publicVisualization: this.formGroup.controls["queryPublic"].value || false,
          defaultVisualization: this.formGroup.controls["queryDefault"].value || this.fromDictionary,
          queryCondition: {
            condition: this.datasetVisualization.queryCondition.condition,
            fieldList: this.mapItems(this.datasetVisualization.queryCondition.fieldList),
            groupList: this.mapGroups(this.datasetVisualization.queryCondition.groupList)
          },
          fieldList: this.datasetVisualization.fieldList
        };

        this.visService.saveVisualization(this.id_dataset, dv)
          .subscribe(
            resp => {
              this.savingQuery = true;
              if (resp.id_dataset_visualization) dv.id_dataset_visualization = resp.id_dataset_visualization;

              this.dialogRef.close({ save: true, datasetVisualization: dv });
            },
            error => {
              this.savingQuery = false;
              //this.dialogRef.close({ save: false, queryCondition: dv.queryCondition });
            }, () => {
              this.savingQuery = false;
            });
      } else {
        this.dialogRef.close({ save, datasetVisualization: this.datasetVisualization });
      }
    }
  }

  private mapItems(items: Array<ConditionField>) {

    if (!items || items.length == 0)
      return undefined;

    return items.map(i => {
      const item = {
        property: i.property,
        operator: i.operator,
        value: i.value,
        value2: i.value2,
        valueList: i.valueOptions?.filter(o => o.checked).map(o => o.id) || [],
        not: i.not || false,
        description: i.description,
        datasetId: i.datasetId,
        datasetName: i.datasetName,
        propertyType: i.propertyType,
        inactityControl: i.inactityControl,
        statusControl: i.statusControl,
        fieldFilter: { quickSearch: i?.fieldFilter?.quickSearch, required: i?.fieldFilter?.required }
      };

      return item;
    });
  }

  private mapGroups(groups: Array<ConditionGroup>) {

    if (!groups || groups.length == 0)
      return undefined;

    return groups.map(g => {
      return { condition: g.condition, fieldList: this.mapItems(g.fieldList), groupList: this.mapGroups(g.groupList) };
    });

  }

}
