<div class="content">
  <div *ngIf="!queryMode" class="T2GrayArea selectedFieldList">
    <div class="fieldsAreaTitle">Campos selecionados</div>
    <div cdkDropListGroup style="overflow: hidden auto;">
      <div *ngFor="let fieldName of selectedFieldList; let i = index; let last = last; let size = count" cdkDropList
        [cdkDropListData]="i" class="selectedField" [ngClass]="{'selectedFieldLast': last}">
        <div style="display: flex; justify-content: space-between;">
          <div *ngIf="size > 1" cdkDrag [cdkDragData]="i" (cdkDragEntered)="dragEntered($event)" style="cursor: move;">
            <nb-icon icon="move-outline"></nb-icon>
            {{fieldName.description}}
          </div>

          <div *ngIf="size <= 1">
            {{fieldName.description}}
          </div>
          <nb-checkbox *ngIf="showPinned" [(checked)]="fieldName.pinned">Pinar</nb-checkbox>
        </div>
      </div>
    </div>
  </div>

  <div class="T2GrayArea flexColumn" style="width: 100%; gap: 10px; background-color: transparent;">
    <div class="fieldsAreaTitle" style="text-align: center;">Campos disponíveis</div>
    <div style="flex: auto; display: flex; gap: 5px; height: calc(100% - 30px); overflow: auto;">
      <div *ngFor="let dataset of datasetList" class="T2GrayArea datasetFieldList">
        <div *ngIf="dataset?.description" class="fieldsAreaTitle T2GrayArea"
          style="font-size: 0.9em; margin: -5px -5px 2px -5px; text-align: center; border-radius: 0px;">
          {{dataset.description}}
        </div>
        <div class="fieldDescriptionList">
          <div *ngFor="let field of dataset.fieldList" class="fieldDescription" (click)="fieldSelect(field)">

            <div *ngIf="!field.id_dataset_aggregation || queryMode"
              style="display: flex; gap: 5px; height: 28px; align-items: center; width: 100%;">
              <nb-icon style="color: var(--T2ColorBGLightBlue); font-size: 0.85rem;"
                [icon]="field.selected ? 'checkmark-circle-2-outline' : 'radio-button-off-outline'"></nb-icon>
              <div>{{field.propertyLabel || field.propertyName}}</div>
            </div>

            <div *ngIf="field.id_dataset_aggregation && !queryMode" class="fieldDescriptionAggreg">
              {{field.propertyLabel || field.propertyName}}
            </div>

            <nb-icon *ngIf="field.id_dataset_aggregation" id="iconAggreg" icon="arrow-circle-right-outline"
              [ngStyle]="{'color': field.selected ? 'var(--text-basic-color)' : 'var(--text-disabled-color)' }"></nb-icon>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>