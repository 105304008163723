import { Component, OnInit } from '@angular/core';
import { T2BaseComponent } from "../t2-base-component";

@Component({
  selector: 'app-t2-input-integer',
  templateUrl: './t2-input-integer.component.html',
  styleUrls: ['./t2-input-integer.component.scss']
})
export class T2InputIntegerComponent extends T2BaseComponent implements OnInit {

  private pattern = /^[0-9]{0,12}?$/;

  constructor() {
    super();
   }

  ngOnInit(): void {
    this.setDefaultValues();
  }

  onKeyPress(event: any) {
    const previousValue = event.srcElement.value;
    const inputChar = event.key;
    if (!this.pattern.test(previousValue + inputChar)) {
      event.preventDefault();
    }
  }
}
