import { AfterViewInit, Component, Inject, Input, LOCALE_ID, OnDestroy, OnInit } from '@angular/core';
import { TaskRequisition } from "../t2separation.service";
import { FormatWidth, getLocaleDateFormat } from "@angular/common";
import { FormBuilder, FormGroup } from "@angular/forms";
import { Observable, Subject } from "rxjs";
import { take, takeUntil } from "rxjs/Operators";
import { T2DatasetService } from "src/app/core/dataset/t2dataset.service";
import { T2AccessItem, T2AccessItemDatasetActionType } from "src/app/core/security/model/t2accessItem";
import { T2SecurityService } from "src/app/core/security/t2security.service";
import { DialogItemSelectionComponent, GroupSelection, ItemSelection } from "src/app/core/cmp/dialog-item-selection/dialog-item-selection.component";
import { NbDialogService } from "@nebular/theme";
import { ActionService } from "src/app/core/action/action.service";

@Component({
  selector: 'app-requisition',
  templateUrl: './requisition.component.html',
  styleUrls: ['./requisition.component.scss']
})
export class RequisitionComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() requisition: TaskRequisition;
  @Input() hasCoordinatorAccess: boolean;

  public dateFormat: string;
  public formGroup: FormGroup;
  public loading: boolean = false;
  private intervalId;
  private minToUpdate: number = 1;
  private unsubscribe = new Subject<void>();

  constructor(@Inject(LOCALE_ID) public locale: string,
    private formBuilder: FormBuilder,
    private datasetService: T2DatasetService,
    private sec: T2SecurityService,
    private dialogService: NbDialogService,
    private actionService: ActionService) {
    this.formGroup = this.formBuilder.group({}, { updateOn: "change" });
    this.formGroup.addControl("equipeSeparacao", this.formBuilder.control(undefined));
  }

  ngAfterViewInit(): void {
    this.formGroup.controls["equipeSeparacao"].setValue(this.requisition.separationTeamId);

    this.formGroup.controls["equipeSeparacao"].valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe(value => {
      this.loading = true;
      this.datasetService.changeDatasetPropertyValue("ord_ordemProd_tarefa_cmp_req", this.requisition.requestId, "id_equipeSeparacao", value)
        .pipe(take(1))
        .subscribe(resp => {
          this.loading = false;
        }, error => {
          this.loading = false;
        });
    })
  }

  ngOnDestroy(): void {
    clearInterval(this.intervalId);
  }

  ngOnInit(): void {
    let dtFormat = getLocaleDateFormat(this.locale, FormatWidth.Short);

    if (dtFormat.startsWith("M")) {
      this.dateFormat = "MM/dd/yy hh:mm a";
    } else {
      this.dateFormat = "dd/MM/yy HH:mm";
    }

    this.requisition.elapsedTimeInMin = this.requisition.requestedDate ? Math.floor((new Date().getTime() - this.requisition.requestedDate.getTime()) / 60000) : undefined;

    this.intervalId = setInterval(() => {
      this.requisition.elapsedTimeInMin = this.requisition.requestedDate ? Math.floor((new Date().getTime() - this.requisition.requestedDate.getTime()) / 60000) : undefined;
    }, this.minToUpdate * 60000)
  }

  showActionTree(origem: string, id: string): void {
    let actions: T2AccessItem[] = [];

    if (origem == "req" && id) {
      this.sec.getDatasetActions("stk_requisicao").subscribe((actionList) => {
        actionList.forEach((action) => {
          if ([T2AccessItemDatasetActionType.DSACTION_OTHER,
          T2AccessItemDatasetActionType.DSACTION_SEARCH,
          T2AccessItemDatasetActionType.DSACTION_OTHERCLOSE,
          T2AccessItemDatasetActionType.DSACTION_VIEW,
          T2AccessItemDatasetActionType.DSACTION_EDIT].includes(action.datasetActionType)) {
            actions.push(action);
          }
        });
      });
    }

    let groups: Array<GroupSelection> = [];
    let items: Array<ItemSelection> = [];
    let groupName = null;
    if (actions.length > 0) {
      actions.forEach(action => {
        groupName = action.datasetDescription || action.datasetGroup || groupName;
        items.push(
          {
            id_item: action.id,
            itemName: action.datasetActionDescription,
            selected: false
          });

        if (groups.find(g => g.id_group == action.id_dataset_group)) {
          return;
        }

        groups.push(
          {
            id_group: action.id_dataset_group,
            groupName: null,
            selected: false,
            items: items
          });
      });

      const dialogRef = this.dialogService.open(
        DialogItemSelectionComponent,
        {
          context: {
            title: groupName,
            groupSelection: 'none',
            itemSelection: 'single',
            itemFromDifGroups: false,
            groups: groups,
            useConfirmButton: false,
            buttonSize: 'large'
          },
        }
      );

      dialogRef.onClose.subscribe((resp) => {
        if (resp) {
          let i = items.find(item => item.selected);

          let id_action = null;
          if (i) {
            id_action = i.id_item;
          }

          const action: T2AccessItem = actions.find(a => a.id == id_action);

          const params = new Map<string, string>();
          params.set("datasetName", action.datasetName);
          params.set("id", id);
          this.executeAction(action, params);
        }
      });
    }
  }

  executeAction(action, params): void {
    const resp = this.actionService.executeAction(action, params, "newWindow");
    if (resp instanceof Observable) {
      resp.subscribe(resp => {
      });
    }
  }
}
