import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FlxProductSpecificationService, Lamination, StructureLayer } from "../flx-product-specification.service";
import { take, takeUntil } from "rxjs/Operators";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { T2MessageService } from "src/app/core/t2-message.service";
import { T2DatasetService } from "src/app/core/dataset/t2dataset.service";
import { T2HttpClientService } from "src/app/core/http/t2httpClient.service";
import { Subject } from "rxjs";

@Component({
  selector: 'app-epplamination',
  templateUrl: './epplamination.component.html',
  styleUrls: ['./epplamination.component.scss']
})
export class EPPLaminationComponent implements OnInit, OnDestroy {

  @Input() laminationList: Array<Lamination>
  @Input() id_especif_carac: string;
  @Input()
  get substrateList(): Array<StructureLayer> {
    return this._substrateList;
  }
  set substrateList(value: Array<StructureLayer>) {
    this._substrateList = value?.filter(s => s.layerType == "SUBSTRATE");
  }

  @Output() saved = new EventEmitter<Array<Lamination>>();
  @Output() reloadLamination = new EventEmitter<void>();

  public selectedLamination: Lamination;
  public loading: boolean = false;
  public formGroup: FormGroup;
  private _substrateList: Array<StructureLayer>;
  private unsubscribe = new Subject<void>();
  public canSave: boolean = true;

  constructor(private flxEppService: FlxProductSpecificationService,
    private formBuilder: FormBuilder,
    private messageService: T2MessageService,
    private datasetService: T2DatasetService,
    private httpClient: T2HttpClientService) {
    this.formGroup = this.formBuilder.group({}, { updateOn: "change" });
  }

  ngOnDestroy(): void {
    if (this.formGroup.dirty) {
      this.reloadLamination.emit();
    }

    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  ngOnInit(): void {
    this.laminationList.forEach(lamination => {
      let layer1Id = lamination.id_especif_carac_substC_item_1 || lamination.id_especif_carac_lamSeq_item_1;
      let layer2Id = lamination.id_especif_carac_substC_item_2 || lamination.id_especif_carac_lamSeq_item_2;

      let layerList = this.getLayerList(lamination);
      lamination.layer1List = layerList.filter(l => l.id != layer2Id);
      lamination.layer2List = layerList.filter(l => l.id != layer1Id);

      this.formGroup.addControl(`${lamination.id_especif_carac_lamSeq_item}_description`, this.formBuilder.control(lamination.description, Validators.required));
      this.formGroup.addControl(`${lamination.id_especif_carac_lamSeq_item}_layer1`, this.formBuilder.control(layer1Id));
      this.formGroup.addControl(`${lamination.id_especif_carac_lamSeq_item}_layer2`, this.formBuilder.control(layer2Id));
      this.formGroup.addControl(`${lamination.id_especif_carac_lamSeq_item}_requiresAdhesive`, this.formBuilder.control(lamination.requiresAdhesive));
      this.formGroup.addControl(`${lamination.id_especif_carac_lamSeq_item}_runBeforePrinting`, this.formBuilder.control(lamination.runBeforePrinting));

      this.formGroup.controls[`${lamination.id_especif_carac_lamSeq_item}_description`].valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe(value => lamination.description = value);

      this.formGroup.controls[`${lamination.id_especif_carac_lamSeq_item}_layer1`].valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe(value => {
        if (this.substrateList.some(s => s.id_especif_carac_estr_item == value)) {
          lamination.id_especif_carac_substC_item_1 = value;
          lamination.id_especif_carac_lamSeq_item_1 = undefined;
        } else {
          lamination.id_especif_carac_lamSeq_item_1 = value;
          lamination.id_especif_carac_substC_item_1 = undefined;
        }

        this.layerChanged();
      });

      this.formGroup.controls[`${lamination.id_especif_carac_lamSeq_item}_layer2`].valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe(value => {
        if (this.substrateList.some(s => s.id_especif_carac_estr_item == value)) {
          lamination.id_especif_carac_substC_item_2 = value;
          lamination.id_especif_carac_lamSeq_item_2 = undefined;
        } else {
          lamination.id_especif_carac_lamSeq_item_2 = value;
          lamination.id_especif_carac_substC_item_2 = undefined;
        }

        this.layerChanged();
      });

      this.formGroup.controls[`${lamination.id_especif_carac_lamSeq_item}_requiresAdhesive`].valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe(value => lamination.requiresAdhesive = value);
      this.formGroup.controls[`${lamination.id_especif_carac_lamSeq_item}_runBeforePrinting`].valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe(value => lamination.runBeforePrinting = value);
    });

    if (this.laminationList.length > 0) {
      this.selectLamination(this.laminationList[0]);
    }
  }

  private layerChanged() {
    this.canSave = !this.laminationList.some(l => (!l.id_especif_carac_lamSeq_item_1 && !l.id_especif_carac_substC_item_1) || (!l.id_especif_carac_lamSeq_item_2 && !l.id_especif_carac_substC_item_2));

    this.laminationList.forEach(lamination => {
      let layerList = this.getLayerList(lamination);
      lamination.layer1List = layerList.filter(l => ![lamination.id_especif_carac_lamSeq_item_2, lamination.id_especif_carac_substC_item_2].includes(l.id));
      lamination.layer2List = layerList.filter(l => ![lamination.id_especif_carac_lamSeq_item_1, lamination.id_especif_carac_substC_item_1].includes(l.id));
    })
  }

  selectLamination(lamination: Lamination) {
    this.selectedLamination = lamination;
  }

  save(exteriorCall?: boolean) {
    this.loading = true;
    let response = this.flxEppService.saveLamination(this.laminationList).pipe(take(1)).toPromise().then(resp => {
      this.laminationList.forEach(lam => {
        lam.newRecord = false;
      });

      this.formGroup.markAsPristine();
      this.saved.emit(this.laminationList);
      this.loading = false;
    }, error => {
      this.loading = false;
    });

    if (exteriorCall) {
      return response;
    } else {
      response.finally(() => {});
    }
  }

  delete() {
    this.messageService.showDialog({
      context: {
        topMessage: "ATENÇÃO",
        message: `Deseja excluir a laminação "${this.selectedLamination.description}" ?`,
        actions: [{ description: "Excluir", status: "danger" }, { description: "Cancelar", status: "basic" }]
      }
    }).onClose.subscribe(resp => {
      if (resp == "Excluir") {
        this.loading = true;
        if (!this.selectedLamination.newRecord) {
          this.datasetService.deleteDatasetRecord("flx_especif_carac_lamSeq_item", this.selectedLamination.id_especif_carac_lamSeq_item).pipe(take(1)).subscribe(resp => {
            this.deleteLamination();
            this.saved.emit(this.laminationList);
            this.loading = false;
          }, error => {
            this.loading = false;
          })
        } else {
          this.deleteLamination();
          this.loading = false;
        }
      }
    })
  }

  private deleteLamination() {
    this.removeFormControls(this.selectedLamination);

    let deletedId = this.selectedLamination.id_especif_carac_lamSeq_item;

    this.laminationList.splice(this.laminationList.findIndex(l => l.id_especif_carac_lamSeq_item == this.selectedLamination.id_especif_carac_lamSeq_item), 1);
    this.selectedLamination = undefined;
    this.canSave = !this.laminationList.some(l => (!l.id_especif_carac_lamSeq_item_1 && !l.id_especif_carac_substC_item_1) || (!l.id_especif_carac_lamSeq_item_2 && !l.id_especif_carac_substC_item_2));

    this.laminationList.forEach(lam => {
      if (lam.id_especif_carac_lamSeq_item_1 == deletedId) {
        this.formGroup.controls[`${lam.id_especif_carac_lamSeq_item}_layer1`].setValue(undefined);
      }

      if (lam.id_especif_carac_lamSeq_item_2 == deletedId) {
        this.formGroup.controls[`${lam.id_especif_carac_lamSeq_item}_layer2`].setValue(undefined);
      }

      this.formGroup.controls[`${lam.id_especif_carac_lamSeq_item}_layer1`].updateValueAndValidity();
      this.formGroup.controls[`${lam.id_especif_carac_lamSeq_item}_layer2`].updateValueAndValidity();
    });
  }

  private removeFormControls(lamination: Lamination) {
    this.formGroup.removeControl(`${lamination.id_especif_carac_lamSeq_item}_description`);
    this.formGroup.removeControl(`${lamination.id_especif_carac_lamSeq_item}_layer1`);
    this.formGroup.removeControl(`${lamination.id_especif_carac_lamSeq_item}_layer2`);
    this.formGroup.removeControl(`${lamination.id_especif_carac_lamSeq_item}_requiresAdhesive`);
    this.formGroup.removeControl(`${lamination.id_especif_carac_lamSeq_item}_runBeforePrinting`);
  }

  addLamination() {
    this.loading = true;
    this.httpClient.getNewUUID().pipe(take(1)).subscribe(newId => {
      this.laminationList.push(new Lamination());
      let lamination = this.laminationList[this.laminationList.length - 1];
      lamination.id_especif_carac_lamSeq_item = newId;
      lamination.id_especif_carac = this.id_especif_carac;
      lamination.description = `Laminação ${this.laminationList.length}`;
      lamination.requiresAdhesive = false;
      lamination.runBeforePrinting = false;
      lamination.newRecord = true;

      let layerList = this.getLayerList(lamination);
      lamination.layer1List = layerList.filter(l => ![lamination.id_especif_carac_lamSeq_item_2, lamination.id_especif_carac_substC_item_2].includes(l.id));
      lamination.layer2List = layerList.filter(l => ![lamination.id_especif_carac_lamSeq_item_1, lamination.id_especif_carac_substC_item_1].includes(l.id));

      this.formGroup.addControl(`${lamination.id_especif_carac_lamSeq_item}_description`, this.formBuilder.control(lamination.description, Validators.required));
      this.formGroup.addControl(`${lamination.id_especif_carac_lamSeq_item}_layer1`, this.formBuilder.control(undefined));
      this.formGroup.addControl(`${lamination.id_especif_carac_lamSeq_item}_layer2`, this.formBuilder.control(undefined));
      this.formGroup.addControl(`${lamination.id_especif_carac_lamSeq_item}_requiresAdhesive`, this.formBuilder.control(lamination.requiresAdhesive));
      this.formGroup.addControl(`${lamination.id_especif_carac_lamSeq_item}_runBeforePrinting`, this.formBuilder.control(lamination.runBeforePrinting));

      this.formGroup.controls[`${lamination.id_especif_carac_lamSeq_item}_description`].valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe(value => lamination.description = value);

      this.formGroup.controls[`${lamination.id_especif_carac_lamSeq_item}_layer1`].valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe(value => {
        if (this.substrateList.some(s => s.id_especif_carac_estr_item == value)) {
          lamination.id_especif_carac_substC_item_1 = value;
          lamination.id_especif_carac_lamSeq_item_1 = undefined;
        } else {
          lamination.id_especif_carac_lamSeq_item_1 = value;
          lamination.id_especif_carac_substC_item_1 = undefined;
        }

        this.layerChanged();
      });

      this.formGroup.controls[`${lamination.id_especif_carac_lamSeq_item}_layer2`].valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe(value => {
        if (this.substrateList.some(s => s.id_especif_carac_estr_item == value)) {
          lamination.id_especif_carac_substC_item_2 = value;
          lamination.id_especif_carac_lamSeq_item_2 = undefined;
        } else {
          lamination.id_especif_carac_lamSeq_item_2 = value;
          lamination.id_especif_carac_substC_item_2 = undefined;
        }

        this.layerChanged();
      });

      this.formGroup.controls[`${lamination.id_especif_carac_lamSeq_item}_requiresAdhesive`].valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe(value => lamination.requiresAdhesive = value);
      this.formGroup.controls[`${lamination.id_especif_carac_lamSeq_item}_runBeforePrinting`].valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe(value => lamination.runBeforePrinting = value);

      this.canSave = false;
      
      this.selectLamination(lamination);

      this.laminationList.filter(l => l.id_especif_carac_lamSeq_item != lamination.id_especif_carac_lamSeq_item).forEach(lam => {
        this.formGroup.controls[`${lam.id_especif_carac_lamSeq_item}_layer1`].updateValueAndValidity();
        this.formGroup.controls[`${lam.id_especif_carac_lamSeq_item}_layer2`].updateValueAndValidity();
      });

      this.loading = false;
    }, error => {
      this.loading = false;
    })
  }

  getLayerList(lamination: Lamination) {
    let lamList = this.laminationList?.filter(l => l.id_especif_carac_lamSeq_item != lamination.id_especif_carac_lamSeq_item);
    let subList = this.substrateList?.filter(s => !lamList.some(l => l.id_especif_carac_substC_item_1 == s.id_especif_carac_estr_item || l.id_especif_carac_substC_item_2 == s.id_especif_carac_estr_item));

    if (!lamList && !subList) {
      return [];
    }

    lamList = lamList?.filter(l => lamList.every(l2 => l2.id_especif_carac_lamSeq_item_1 != l.id_especif_carac_lamSeq_item && l2.id_especif_carac_lamSeq_item_2 != l.id_especif_carac_lamSeq_item));

    let layerList = new Array<{ id: string, description: string }>();

    if (subList?.length) {
      layerList.push(...subList.map(s => {
        return {
          id: s.id_especif_carac_estr_item,
          description: s.description
        }
      }));
    }

    if (lamList?.length) {
      layerList.push(...lamList.map(l => {
        return {
          id: l.id_especif_carac_lamSeq_item,
          description: l.description
        }
      }))
    }

    return layerList;
  }
}
