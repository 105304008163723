import { T2DateTimeComponent } from './../../ui/t2-date-time/t2-date-time.component';
import { T2DateComponent } from './../../ui/t2-date/t2-date.component';
import { T2AggregationComponent } from './../../ui/t2-aggregation/t2-aggregation.component';
import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { DialogTemplateComponent } from "../../dialog-template/dialog-template.component";
import { T2InputTextComponent } from "../../ui/t2-input-text/t2-input-text.component";
import { T2ViewTemplateData } from "../../view-template/model/t2-view-template-data";
import { LayoutType, ViewTemplateElement } from "../../view-template/model/view-template-element";
import { T2CheckBoxComponent } from "../../ui/t2-check-box/t2-check-box.component";
import { T2InputIntegerComponent } from "../../ui/t2-input-integer/t2-input-integer.component";
import { T2InputFloatComponent } from "../../ui/t2-input-float/t2-input-float.component";
import { T2SelectComponent } from "../../ui/t2-select/t2-select.component";

@Component({
  selector: 'app-analysis-params',
  templateUrl: './analysis-params.component.html',
  styleUrls: ['./analysis-params.component.scss']
})
export class AnalysisParamsComponent implements OnInit, AfterViewInit {

  @Input() analysisParams: Array<any> = null;
  @ViewChild('dialog', { static: true }) dialog: DialogTemplateComponent;

  formGroup: FormGroup;
  data: Array<T2ViewTemplateData> = [];
  layout: ViewTemplateElement[];

  constructor(private formBuilder: FormBuilder) {
    this.formGroup = formBuilder.group({});
  }

  ngOnInit(): void { }

  ngAfterViewInit(): void {

    const intLayout: ViewTemplateElement[] = [];
    intLayout.push({ layoutType: LayoutType.gridLayout, children: [] });

    this.analysisParams?.forEach(param => {

      const dataProp: { datasetId?: string, datasetName?: string, list?: Array<{ id: any, description: string }> } = {};

      // LAYOUT
      let l = intLayout.find(l => l.title == param.groupName);
      if (!l) {
        l = { layoutType: LayoutType.gridLayout, title: param.groupName, children: [] };
        intLayout.push(l);
      }

      const cmp: ViewTemplateElement = {
        layoutType: LayoutType.component,
        title: param.paramLabel,
        cmpName: param.paramName,
        isBaseComponent: true,
        cmpType: T2InputTextComponent,
        columnSpan: 3
      }

      if (param.paramType == 'dataset') {
        dataProp.datasetId = param.id_dataset;
        dataProp.datasetName = param.datasetName
        cmp.cmpType = T2AggregationComponent;
        cmp.columnSpan = 4;
      } else if (param.paramType == 'date') {
        cmp.cmpType = T2DateComponent;
        cmp.columnSpan = 2;
      } else if (param.paramType == 'datetime') {
        cmp.cmpType = T2DateTimeComponent;
        cmp.columnSpan = 2;
      } else if (param.paramType == 'boolean') {
        cmp.cmpType = T2CheckBoxComponent;
        cmp.columnSpan = 1;
      } else if (param.paramType == 'integer') {
        cmp.cmpType = T2InputIntegerComponent;
        cmp.columnSpan = 1;
      } else if (param.paramType == 'decimal') {
        cmp.cmpType = T2InputFloatComponent;
        cmp.columnSpan = 1;
      } else if (param.paramType == 'selection') {
        if (!Array.isArray(param.selection)) param.selection = [param.selection];
        dataProp.list = param.selection.map(s => { return { id: s.value, description: s.value }; });
        cmp.cmpType = T2SelectComponent;
        cmp.columnSpan = 3;
      }

      l.children.push(cmp);

      // FORMGROUP and DATA
      const validators = [];
      if (param.mandatory) {
        validators.push(Validators.required);
      }

      this.formGroup.addControl(param.paramName, this.formBuilder.control(param.value, validators));
      this.data.push({ cmpName: param.paramName, properties: dataProp });

    });

    this.layout = intLayout;
  }

  cancel() {
    this.dialog.close(null);
  }

  confirm() {
    if (this.formGroup.valid) {

      const result = {};
      this.analysisParams.forEach(p => result[p.paramName] = this.formGroup.controls[p.paramName].value);

      this.dialog.close(result);
    }

  }

}
