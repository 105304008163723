import { Injectable } from '@angular/core';
import { T2HttpClientService } from "../http/t2httpClient.service";
import { take, filter, map } from "rxjs/Operators";
import { Observable } from 'rxjs';
import { T2Widget } from './model/t2widget';

@Injectable({
  providedIn: 'root'
})
export class WidgetService {

  constructor(private httpClient: T2HttpClientService) { }

  getPanelWidgets(panelName: string): Observable<Array<T2Widget>> {
    const queryParams = new Map<string, string>();
    queryParams.set("panelName", panelName);
    return this.httpClient.get(`core.widgetPanel/widgets`, queryParams)
      .pipe(
        filter(resp => resp.widgets),
        take(1),
        map((resp: any) => {
          if (!Array.isArray(resp.widgets)) {
            resp.widgets = [resp.widgets];
          }

          resp.widgets.sort((a: T2Widget, b: T2Widget) => {
            let idx = a.positionY - b.positionY;
            if (!idx) { idx = a.positionX - b.positionX; }
            return idx;
          });

          return resp.widgets;
        })
      );
  }

  mergeInputParams(widget: T2Widget, widgetParams: any, updatedParams: any): any {
    const keys = Object.keys(updatedParams);
    let updated = false;
    keys.forEach((key: string) => {
      if (widget.inputOutputParams?.input.find((inputParam: any) => inputParam.name === key)) {
        if (!widgetParams) { widgetParams = {}; }
        widgetParams[key] = updatedParams[key];
        updated = true;
      }
    });

    return { params: widgetParams, updated };
  }

  getWidgetContent(widget: T2Widget, inputParams: any): Observable<any> {

    return this.httpClient.post(`core.widgetPanel/widget/${widget.id_techprodWidget}`, null, inputParams)
      .pipe(
        take(1),
        map((resp: any) => {
          return resp.message;
        })
      );
  }
}
