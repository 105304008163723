import { Component, OnInit, Input, EventEmitter, Output } from "@angular/core";
import { WidgetService } from "../widget.service";
import { FormBuilder, FormControl } from "@angular/forms";
import { distinct } from "rxjs/Operators";
import { T2Widget } from "../model/t2widget";
import { T2WidgetOutput } from "../model/t2widgetOutput";

@Component({
  selector: "app-widget-selection",
  templateUrl: "./widget-selection.component.html",
  styleUrls: ["./widget-selection.component.scss"],
})
export class WidgetSelectionComponent implements OnInit {
  private _widget: T2Widget;
  private _params: {};

  @Input()
  get widget() {
    return this._widget;
  }
  set widget(value: T2Widget) {
    this._widget = value;
    this.updateContent();
  }

  @Input()
  get params() {
    return this._params;
  }
  set params(value: any) {
    const newParams = this.widgetService.mergeInputParams(
      this._widget,
      this._params,
      value
    );
    if (newParams.updated) {
      this._params = newParams.params;
      this.updateContent();
    }
  }

  @Input() placeHolder: string;
  @Input() multiple: boolean;

  @Output() widgetChange = new EventEmitter<T2WidgetOutput>();

  widgetFormGroup = this.formBuilder.group({});
  selectionOptions = [];
  selectionField = new FormControl();

  constructor(
    private formBuilder: FormBuilder,
    private widgetService: WidgetService
  ) {}

  ngOnInit(): void {
    this.selectionField.valueChanges
      .pipe(distinct())
      .subscribe((value: any) => {
        const output = new T2WidgetOutput();
        output.widget = this._widget;
        output.data = value;
        this.widgetChange.emit(output);
      });
  }

  private updateContent() {
    if (this._widget && this._widget.id_techprodWidget) {
      this.widgetService
        .getWidgetContent(this._widget, this._params)
        .subscribe((content: string) => {
          this.selectionOptions = JSON.parse(content || "[]");
        });
    }
  }
}
