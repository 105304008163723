import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from "ag-grid-angular";
import { ICellRendererParams } from "ag-grid-community";

@Component({
  selector: 'app-task-status-cell-renderer',
  templateUrl: './task-status-cell-renderer.component.html',
  styleUrls: ['./task-status-cell-renderer.component.scss']
})
export class TaskStatusCellRendererComponent implements OnInit, ICellRendererAngularComp {

  constructor() { }
  public started: boolean;
  progress: number = undefined;
  suspended: boolean;


  ngOnInit(): void {
  }

  agInit(params: ICellRendererParams): void {
    this.started = params.data?.started || false;
    this.progress = Number(params.data?.percProgress) || undefined;
    this.suspended = params.data?.suspended;
  }

  refresh(params: ICellRendererParams): boolean {
    return true;
  }

}
