import { T2TimeComponent } from '../../../../../core/cmp/ui/t2-time/t2-time.component';
import { Component, Input, ViewChild, AfterViewInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { DialogTemplateComponent } from "src/app/core/cmp/dialog-template/dialog-template.component";
import { T2ViewTemplateData } from "src/app/core/cmp/view-template/model/t2-view-template-data";
import { LayoutType, ViewTemplateElement } from "src/app/core/cmp/view-template/model/view-template-element";
import { T2AggregationComponent } from "src/app/core/cmp/ui/t2-aggregation/t2-aggregation.component";
import { T2HttpClientService } from "src/app/core/http/t2httpClient.service";
import { take } from "rxjs/Operators";
import { Subscription } from "rxjs";
import { T2MessageService } from "src/app/core/t2-message.service";
import { ViewTemplateComponent } from "src/app/core/cmp/view-template/view-template.component";

@Component({
  selector: 'app-event-editor',
  templateUrl: './event-editor.component.html',
  styleUrls: ['./event-editor.component.scss']
})
export class EventEditorComponent implements OnDestroy, AfterViewInit {

  @Input() title: string;
  @Input() id_workShift: string = null;
  @Input() startTime: string = null;
  @Input() endTime: string = null;
  private workShifDescr: string;

  @ViewChild('dialog', { static: true }) dialog: DialogTemplateComponent;
  @ViewChild('viewTemplate', {static: true}) viewTemplate: ViewTemplateComponent;

  formGroup: FormGroup;
  data: Array<T2ViewTemplateData> = [
    { cmpName: "id_workShift", properties: { readOnly: false, datasetId: "{C3727C3C-1D11-4E8B-8E55-BFCFF4F84942}", datasetName: "afx_workShift" } },
    { cmpName: "startTime", properties: { readOnly: false } },
    { cmpName: "endTime", properties: { readOnly: false } },
  ];
  layout: ViewTemplateElement[] = [
    {
      layoutType: LayoutType.component,
      title: "Turno",
      cmpName: "id_workShift",
      cmpType: T2AggregationComponent,
      isBaseComponent: true
    },
    {
      layoutType: LayoutType.gridLayout,
      children: [
        {
          layoutType: LayoutType.component,
          title: "Início",
          cmpName: 'startTime',
          cmpType: T2TimeComponent,
          isBaseComponent: true
        },
        {
          layoutType: LayoutType.component,
          title: "Fim",
          cmpName: 'endTime',
          cmpType: T2TimeComponent,
          isBaseComponent: true
        },
      ]
    },

  ];
  private wsSubs$: Subscription;

  constructor(private formBuilder: FormBuilder, private httpClient: T2HttpClientService, private messageService: T2MessageService) {

    this.formGroup = formBuilder.group({
      id_workShift: new FormControl(null, Validators.required),
      startTime: new FormControl(null, Validators.required),
      endTime: new FormControl(null, Validators.required)
    });
  }

  ngOnDestroy(): void {
    if (this.wsSubs$) {
      this.wsSubs$.unsubscribe();
    }
  }

  ngAfterViewInit(): void {
    this.formGroup.controls["id_workShift"].setValue(this.id_workShift);
    this.formGroup.controls["startTime"].setValue(this.startTime);
    this.formGroup.controls["endTime"].setValue(this.endTime);

    this.wsSubs$ = this.formGroup.controls["id_workShift"].valueChanges.subscribe(resp => {
      this.updateWorkShifDescr();
    });

    // Carrega ao abrir tela
    this.updateWorkShifDescr();
  }

  private updateWorkShifDescr() {
    this.workShifDescr = null;
    if (this.formGroup.controls["id_workShift"].value) {
      this.workShifDescr = this.viewTemplate.getCmpInputValue("id_workShift", "description");
    }
  }

  confirm() {
    const resp = {
      type: 'confirm',
      title: this.workShifDescr,
      id_workShift: this.formGroup.controls["id_workShift"].value,
      startTime: this.formGroup.controls["startTime"].value + ":00.000",
      endTime: this.formGroup.controls["endTime"].value + ":59.000",
    };

    let startT = new Date(`1899-12-31 ${resp.startTime}`),
      endT = new Date(`1899-12-31 ${resp.endTime}`);

    if (endT < startT) {
      this.messageService.showToastError("O horário final não pode ser anterior ao início !!");
      return;
    }

    this.dialog.close(resp);
  }

}
