import { Component, Input, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { DialogTemplateComponent } from "src/app/core/cmp/dialog-template/dialog-template.component";
import { NumericEditorComponent } from "src/app/core/cmp/t2grid/cellEditor/numeric-editor.component";

@Component({
  selector: 'app-dialog-in-packing-volumes',
  templateUrl: './dialog-in-packing-volumes.component.html',
  styleUrls: ['./dialog-in-packing-volumes.component.scss']
})
export class DialogInPackingVolumesComponent implements OnInit, AfterViewInit {

  @Input() title: string;
  @Input() id_packingList: string;
  @Input() qtdVolume: number;
  @Input() peso: number;


  @ViewChild('volume', { static: true }) public volume: NumericEditorComponent;

  @ViewChild('dialog', { static: true }) dialog: DialogTemplateComponent;

  constructor() { }

  ngOnInit(): void {

  }

  ngAfterViewInit() {
    this.volume.setFocus();
  }

  onVolumeChange(novoValor: number) {
    this.qtdVolume = novoValor;
  }

  onPesoChange(novoValor:number){
    this.peso = novoValor;
  }

  confirm() {
    const resp = {
      type: 'confirm',
      id_packingList: this.id_packingList,
      qtdVolume: this.qtdVolume,
      peso: this.peso
    };

    this.dialog.close(resp);
  }

  cancel() {
    const resp = {
      type: 'cancel',
      id_packingList: this.id_packingList
    };

    this.dialog.close(resp);
  }
}
