<div>

  <div style="display: flex; justify-content: center;">
    <div class="logo_techprod" [ngClass]="{
          logo_techprod_branco: themeService.getThemeName() === 'dark',
          logo_techprod_preto: themeService.getThemeName() != 'dark'
        }">
    </div>
  </div>


  <div class="serverInfo">
    <div>
      <div class="serverInfo_title">Techprod WEB</div>
      <div class="serverInfo_detail"> <strong>TPWeb version</strong> {{version}}</div>
    </div>

    <div>
      <div class="serverInfo_title">Serviço Techprod (back-end)</div>
      <div style="display: flex; gap: 5px">
        <div class="serverInfo_detail" style="text-align: end; font-weight: bold;">
          <div>TPServer version</div>
          <div>JAVA VM</div>
          <div>JAVA Runtime version</div>
          <div>OS Arch</div>
          <div>OS Name</div>
        </div>
        <div class="serverInfo_detail" style="text-align: start;">
          <div>{{serverVersion}}</div>
          <div>{{javaVmName}}</div>
          <div>{{javaRuntimeVersion}}</div>
          <div>{{osArch}}</div>
          <div>{{osName}}</div>
        </div>
      </div>
    </div>
  </div>
</div>
