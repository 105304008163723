import { AfterContentInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { take } from "rxjs/Operators";
import { FormTemplateComponent } from "src/app/core/cmp/form-template/form-template.component";
import { T2SecurityService } from "src/app/core/security/t2security.service";
import { DeviceCache, DeviceCacheService, DeviceCacheTask } from "./device-cache.service";
import { T2UserDeviceService } from "src/app/t2-user-device.service";
import { T2FirebaseService } from "src/app/core/t2-firebase.service";
import { Subject } from "rxjs";
import { ShopFloorDashboardComponent } from "./shop-floor-dashboard/shop-floor-dashboard.component";
import { ActivatedRoute, Router } from "@angular/router";
import { environment } from "src/environments/environment";
import { T2HttpClientService } from "src/app/core/http/t2httpClient.service";
import { ShopFloorService } from "./shop-floor.service";
import { PanelType, ShopFloorTaskpanelComponent } from "./shop-floor-taskpanel/shop-floor-taskpanel.component";

@Component({
  selector: 'app-shop-floor',
  templateUrl: './shop-floor.component.html',
  styleUrls: ['./shop-floor.component.scss'],
  providers: [DeviceCacheService, ShopFloorService, T2FirebaseService]
})
export class ShopFloorComponent implements OnInit, OnDestroy, AfterContentInit {

  @ViewChild("formTemplate", { static: true }) formTemplate: FormTemplateComponent;
  @ViewChild("dashboard") dashboard: ShopFloorDashboardComponent;
  @ViewChild("appTaskPanel") appTaskPanel: ShopFloorTaskpanelComponent;

  devEnvironment = false;
  updatingCache = false;

  public loaded: boolean = false;
  public showTaskPanel: boolean = false;
  public selectedIdDevice: string;
  public selectedIdTaskPlan: string;
  public dashboardLoaded: boolean = false;

  get selectedDevice(): { device: DeviceCache, taskPlan: DeviceCacheTask, tab: 'sequencing' | 'appointment' | 'document' } {
    return this._selectedDevice;
  }
  set selectedDevice(value: { device: DeviceCache, taskPlan: DeviceCacheTask, tab: 'sequencing' | 'appointment' | 'document' }) {
    this._selectedDevice = value;
    this.selectedIdDevice = value?.device?.deviceId;
  };

  public lockScreen: boolean = false;

  private unsubscribe = new Subject();
  private _selectedDevice = { device: undefined, taskPlan: undefined, tab: undefined };

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private httpClient: T2HttpClientService,
    private sec: T2SecurityService,
    public userDevService: T2UserDeviceService,
    private deviceCacheService: DeviceCacheService) {
    this.devEnvironment = !environment.production;
  }

  ngOnInit(): void {
    this.sec
      .accessLoaded()
      .pipe(take(1))
      .subscribe(() => {
        if (this.formTemplate.validateAccess(["zD20160804H171504742R000000001", "zD20160804H171624193R000000002"], 'dataCollector')) {
          this.loaded = true;
        }
      });
  }

  ngAfterContentInit(): void {
    this.selectedIdDevice = this.route.snapshot.queryParams["id_device"];
    this.selectedIdTaskPlan = this.route.snapshot.queryParams["id_taskPlan"];
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  btnVoltarClick(device: DeviceCache) {
    this.selectedDevice.device = undefined;
    this.selectedDevice.taskPlan = undefined;

    this.router.navigate(
      [],
      {
        relativeTo: this.route,
        queryParams: { id_device: undefined, id_taskPlan: undefined, tab: undefined },
        queryParamsHandling: "merge",
      });

    this.showTaskPanel = false;
  }

  deviceSelected(deviceSelected: { device: DeviceCache, taskPlan: DeviceCacheTask, tab: PanelType, clearSelection?: boolean }) {

    if (this.userDevService.isSmallScreen) {
      this.dashboard.deviceList.map(dev => dev.id_device).forEach(id => {
        if (id != deviceSelected.device?.deviceId) {
          this.deviceCacheService.unsubscribeDevice(id);
        }
      })
    }

    this.selectedDevice.device = deviceSelected.device;
    this.selectedDevice.taskPlan = deviceSelected.taskPlan;
    this.selectedDevice.tab = deviceSelected.tab;

    if (!deviceSelected.device) {
      this.showTaskPanel = false;
    }

    this.router.navigate(
      [],
      {
        relativeTo: this.route,
        queryParams: { id_device: deviceSelected.device?.deviceId, id_taskPlan: deviceSelected.taskPlan?.id_taskPlan, tab: deviceSelected.device ? deviceSelected.tab : undefined },
        queryParamsHandling: "merge",
      });

    if (deviceSelected.device)
      this.showTaskPanel = true;

    if (deviceSelected.clearSelection && this.showTaskPanel && ["appointment", "document"].includes(this.selectedDevice.tab) && this.appTaskPanel?.searchedTaskPlan) {
      this.appTaskPanel.searchedTaskPlan = undefined;
      this.appTaskPanel.taskPlan = this.selectedDevice.taskPlan;
    }
  }

  setLockScreen(lock: boolean) {
    this.lockScreen = lock;
  }

  refreshDevice(): void {
    if (this.updatingCache) return;

    this.updatingCache = true;

    this.httpClient.get(`production.registration/clearCache`, null).subscribe(resp => {
      this.updatingCache = false;
    }, error => {
      this.updatingCache = false;
    });
  }

  taskPlanChanged(tp: DeviceCacheTask) {
    this.selectedIdTaskPlan = tp?.id_taskPlan;
    this.selectedDevice.taskPlan = tp;
  }

  devicesLoaded(loaded) {
    this.dashboardLoaded = loaded;
  }

  selectDeviceChanged(panel: PanelType) {
    this.selectedDevice.tab = panel;
  }
}
