import { DeviceRegistrationInputTypeVolume } from "./device-registration-input-type-volume";

export class DeviceRegistrationInputType {
  id_operationGroup_it: string;
  id_operationGroup_op_it: string;
  id_component: string;
  id_item: string;
  value: number;
  tare: number;
  totalCost: number;
  volumes: Array<DeviceRegistrationInputTypeVolume>;
}
