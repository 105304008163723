import { AfterViewInit, Component, Injector, Input, OnDestroy, OnInit, Optional, StaticProvider, ViewChild, ViewContainerRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { NbDialogRef, NbDialogService, NbTabsetComponent } from "@nebular/theme";
import { BehaviorSubject, Subject } from "rxjs";
import { take, takeUntil } from "rxjs/Operators";
import { FormTemplateComponent } from "src/app/core/cmp/form-template/form-template.component";
import { T2Status } from "src/app/core/cmp/ui/t2-status/model/t2-status";
import { DatasetTemplateComponent } from "src/app/core/dataset/dataset-template.component";
import { DatasetPropertyStatusItem } from "src/app/core/dataset/model/dataset-property-status-item";
import { T2DatasetService } from "src/app/core/dataset/t2dataset.service";
import { T2HttpClientService } from "src/app/core/http/t2httpClient.service";
import { T2Route } from "src/app/core/http/t2route";
import { T2SecurityService } from "src/app/core/security/t2security.service";
import { T2MessageService } from "src/app/core/t2-message.service";
import { ICharacteristicComponentInjection } from "./characteristic-component";
import { EPService, ICharacCmp } from "./ep.service";
import { EPCharacteristicComponent } from "./epcharacteristic/epcharacteristic.component";
import { CharacteristicInfo } from "./model/characteristic-info";
import { ProcessOption } from "./model/process-option";
import { ProductSpecification } from "./model/product-specification";
import { LayoutType, ViewTemplateElement } from "src/app/core/cmp/view-template/model/view-template-element";
import { T2InputTextComponent } from "src/app/core/cmp/ui/t2-input-text/t2-input-text.component";
import { DialogInputComponent } from "src/app/core/cmp/dialog-input/dialog-input.component";
import { T2CheckBoxComponent } from "src/app/core/cmp/ui/t2-check-box/t2-check-box.component";
import { T2StatusComponent } from "src/app/core/cmp/ui/t2-status/t2-status.component";
import { T2AutoFormComponent } from "src/app/core/form/t2-auto-form/t2-auto-form.component";

@Component({
  selector: 'app-ep',
  templateUrl: './ep.component.html',
  styleUrls: ['./ep.component.scss']
})
export class EPComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input() id: string;
  @Input() inDialog: boolean = false;
  @ViewChild("epCharacteristic", { static: true }) epCharacteristic: EPCharacteristicComponent;
  @ViewChild("formTemplate", { static: true }) formTemplate: FormTemplateComponent;
  @ViewChild("t2status", { static: true }) t2status: T2StatusComponent;
  @ViewChild("tabSet") tabSetCmp: NbTabsetComponent;
  @ViewChild("autoformGeneral") autoformGeneral: T2AutoFormComponent;

  public loaded = false;
  public title: string;
  private t2Route: T2Route;
  private unsubscribe = new Subject<void>();
  public characInfoList: Array<CharacteristicInfo> = [];
  private processOptionList: Array<ProcessOption>;
  public selectedCharac: CharacteristicInfo;
  public productSpecification: ProductSpecification;
  public formGroup: FormGroup;
  public formGroupProcessOption: FormGroup;
  public datasetPS: string = "epp_especif";
  public currentStatus: T2Status;
  private statusList = new Array<T2Status>();
  public statusFlow = new Array<T2Status>();
  private originalCharacList: Array<CharacteristicInfo> = [];
  private mapPsTypeAccess = new Map<string, string>();
  public selectedProcessOption: ProcessOption;
  public lockScreen: boolean = false;
  public cmpList: Array<ICharacCmp>;
  public injectorList = new Array<Injector>();
  public procOptList: Array<{ id: string, description: string }>;
  private injectorBehaviorSubjectList = new Array<BehaviorSubject<ICharacteristicComponentInjection>>();
  private uniqueCmpList: Array<ICharacCmp>;

  constructor(
    public router: Router,
    private route: ActivatedRoute,
    private httpClient: T2HttpClientService,
    private secService: T2SecurityService,
    private epService: EPService,
    private dsService: T2DatasetService,
    private formBuilder: FormBuilder,
    private messageService: T2MessageService,
    @Optional() public dialogRef: NbDialogRef<any>,
    public viewContainerRef: ViewContainerRef,
    private injector: Injector,
    private dialogService: NbDialogService,
    private datasetService: T2DatasetService) {
    this.mapPsTypeAccess.set("P", "{00421793-E264-4F80-AA09-254387A7BBEC}");
    this.mapPsTypeAccess.set("M", "{0BED0E9C-BA11-4703-85A8-C8901A2701B8}");
    this.mapPsTypeAccess.set("CONJ", "}D20151008H131710184R000000004");
    this.mapPsTypeAccess.set("EP", "{00421793-E264-4F80-AA09-254387A7BBEC}");

    this.formGroup = this.formBuilder.group({});
    this.formGroup.addControl(`${this.datasetPS}.status`, this.formBuilder.control(undefined));

    this.formGroupProcessOption = this.formBuilder.group({});
    this.formGroupProcessOption.addControl("processOption", this.formBuilder.control(undefined));
  }

  ngAfterViewInit(): void {
    this.secService.accessLoaded()
      .pipe(take(1))
      .subscribe(() => {
        this.dsService.getStatusList(this.datasetPS).pipe(take(1)).subscribe(sList => {
          sList.forEach((status: DatasetPropertyStatusItem) => {
            this.statusList.push({
              name: status.description,
              action: status.execDescription,
              tooltip: status.helpText,
              colorStyle: status.statusColorStyle,
              blockChange: status.blockChanges
            });
          });

          this.getProductSpecification().then(() => {
            if (this.formTemplate.validateAccess([this.mapPsTypeAccess.get(this.productSpecification.specificationType)], 'full')) {
              this.getCharacteristics(false).then(() => {

                this.epCharacteristic.loadCompleted.pipe(takeUntil(this.unsubscribe)).subscribe(() => {
                  this.loaded = true;
                  this.tabSetCmp.selectTab(this.tabSetCmp.tabs.get(0));

                  let interval = setInterval(() => {
                    if (this.autoformGeneral) {
                      this.autoformGeneral.setAllControlsReadOnly(this.currentStatus.blockChange);
                      clearInterval(interval);
                    }
                  }, 200);
                });

                if (this.characInfoList.length == 0) {
                  this.epCharacteristic.loadCompleted.emit();
                }
              })
            }
          }, error => {
            if (this.dialogRef?.componentRef?.componentType == EPComponent) {
              this.dialogRef.close();
            }
          })
        });
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  ngOnInit(): void {
    this.loaded = false;

    this.t2Route = this.httpClient.getT2RouteFromSnapshot(this.route.snapshot);
    this.id = this.id || this.t2Route.queryParams.get("id");
    this.route.params.pipe(takeUntil(this.unsubscribe)).subscribe(params => {
      this.id = params['id'] || this.id;
    });

    this.formGroupProcessOption.controls["processOption"].valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe(value => {
      this.selectedProcessOption = this.processOptionList.find(po => po.id_especif_componente_prc == value);
      this.reloadInjectionDependencies();
    })
  }

  getCharacteristics(reloading: boolean): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.epService.getPSCharacStructure(this.id, false)
        .pipe(take(1))
        .subscribe(resp => {
          if (this.originalCharacList.length > 0) {
            this.originalCharacList.splice(0, this.originalCharacList.length);
          }

          this.originalCharacList.push(...resp.characteristicList);

          this.characInfoList = resp.characteristicList;

          if (!reloading) {
            this.processOptionList = resp.processOptionList;

            this.procOptList = this.processOptionList.map(po => {
              return {
                id: po.id_especif_componente_prc,
                description: po.name
              }
            });

            let defaultProcess = this.processOptionList.find(po => po.default);
            let po = defaultProcess || this.processOptionList[0];
            this.selectedProcessOption = po;

            this.uniqueCmpList = this.epService.getUniqueCmpList(this.epService.getFullCharacList().map(charac => charac.datasetName));

            this.formGroupProcessOption.controls["processOption"].setValue(po?.id_especif_componente_prc, { emitEvent: false });

            this.injectorList = [];

            this.uniqueCmpList.forEach(cmp => {
              this.injectorList.push(this.getInjector(cmp));
            })

            this.cmpList = this.uniqueCmpList;
          } else {
            this.reloadInjectionDependencies();
          }

          resolve();
        }, error => reject(error));
    })

  }

  reloadInjectionDependencies() {
    for (let i = 0; i < this.uniqueCmpList.length; i++) {
      let cmp = this.uniqueCmpList[i];

      let characInfoList = this.epService.getFullCharacList().filter(charac => this.epService.getCharacNameListByComponentType(cmp.description).includes(charac.datasetName));
      let characCmpInject: ICharacteristicComponentInjection = {
        id_specif_component: this.productSpecification.id_specif_component,
        id_specif: this.productSpecification.id_specification,
        id_process: this.selectedProcessOption.id_especif_componente_prc,
        blockChange: this.currentStatus.blockChange,
        characInfoList: characInfoList
      }
      this.injectorBehaviorSubjectList[i].next(characCmpInject);
    }
  }

  getInjector(cmp: ICharacCmp) {
    let characInfoList = this.epService.getFullCharacList().filter(charac => this.epService.getCharacNameListByComponentType(cmp.description).includes(charac.datasetName));
    let characCmpInject: ICharacteristicComponentInjection = {
      id_specif_component: this.productSpecification.id_specif_component,
      id_specif: this.productSpecification.id_specification,
      id_process: this.selectedProcessOption?.id_especif_componente_prc,
      blockChange: this.currentStatus.blockChange,
      characInfoList: characInfoList
    }

    this.injectorBehaviorSubjectList.push(new BehaviorSubject<ICharacteristicComponentInjection>(characCmpInject));

    let providerList: Array<StaticProvider> = [
      { provide: "characCmpInjector", useValue: this.injectorBehaviorSubjectList[this.injectorBehaviorSubjectList.length - 1] },
    ];

    return Injector.create({ providers: providerList, parent: this.injector });
  }

  getProductSpecification() {
    return new Promise<void>((resolve, reject) => {
      this.epService.getProductSpecification(this.id)
        .pipe(take(1))
        .subscribe(psList => {

          if (psList.length > 0) {
            // TODO: Tratar para quando houver mais de 1 componente, pois virá mais de 1 item no array.
            this.productSpecification = psList[0];

            switch (this.productSpecification.specificationType) {
              case "P": {
                this.title = "Especificação de Produto";
                break;
              }
              case "M": {
                this.title = "Modelo de Especificação de Produto";
                break;
              }
              case "CONJ": {
                this.title = "Conjugação de Produto";
                break;
              }
              default: {
                this.title = "EP";
                break;
              }
            }

            this.currentStatus = this.statusList.find(status => status.name == this.productSpecification.status);
            this.formGroup.controls[`${this.datasetPS}.status`].setValue(this.currentStatus.name, { emitEvent: false });
            this.dsService.getNextStatusFlow(this.datasetPS, this.productSpecification.status).pipe(take(1)).subscribe(flowList => {
              this.statusFlow = this.statusList.filter(status => flowList.map(flow => flow.status).includes(status.name)).map(stat => {
                let flow = flowList.find(f => f.status == stat.name);
                return {
                  name: stat.name,
                  action: stat.action,
                  tooltip: stat.tooltip,
                  colorStyle: stat.colorStyle,
                  blockChange: stat.blockChange,
                  questionMsg: flow.questionMsg,
                  showQuestionMsg: flow.showQuestionMsg
                }
              })
              resolve();
            })
          } else {
            resolve();
          }
        }, error => reject(error));
    })
  }

  selectCharac(charac: CharacteristicInfo) {
    this.selectedCharac = charac;
  }

  insertCharac(charac: CharacteristicInfo) {
    let index = this.originalCharacList.indexOf(charac);
    this.epCharacteristic.insertCharac(charac, index);
  }

  deleteCharac(charac: CharacteristicInfo) {
    this.epCharacteristic.deleteCharac(charac);
  }

  changeTab(event) {
    setTimeout(() => {
      switch (event.tabTitle) {
        case "Outras Informações": {
          this.epCharacteristic.autoformList.forEach(af => {
            af.viewTemplate?.cmpReferenceList?.filter(cr => cr.componentType == DatasetTemplateComponent).forEach(cr => {
              let grid = (cr.instance as DatasetTemplateComponent).gridDataset;

              if (!grid.loadColumnGridState()) {
                grid.autoSizeAllColumns(false);
              }
            })
          })
          break;
        }
      }
    }, 100);
  }

  close() {
    if (!this.inDialog) {
      window.close();
    } else if (this.inDialog) {
      this.dialogRef.close();
    }
  }

  deleteProductSpecification() {
    this.messageService.showDialog({
      context: {
        topMessage: "ATENÇÃO",
        message: "Deseja excluir esse registro ?",
        actions: [{ description: "Excluir", status: "danger" }, { description: "Cancelar", status: "basic" }]
      }
    }).onClose.subscribe(resp => {
      if (resp == "Excluir") {
        this.lockScreen = true;
        this.dsService.deleteDatasetRecord(this.datasetPS, this.id).pipe(take(1)).subscribe(resp => {
          this.lockScreen = false;
          this.close();
        }, error => {
          this.lockScreen = false;
        })
      }
    })
  }

  saveData(params: { cmpReference: any, data?: any }): Promise<void> {
    return params.cmpReference.savePS(params.cmpReference);
  }

  savePS(epCmp: EPComponent, ignoreSpinner?: boolean) {
    if (!ignoreSpinner)
      epCmp.lockScreen = true;
    return new Promise<void>((resolve, reject) => {
      if (!epCmp.epCharacteristic.canSaveCharacteristics()) {
        if (!ignoreSpinner)
          epCmp.lockScreen = false;

        epCmp.messageService.showToast("Não é possível salvar. verifique as caracteristicas", "ATENÇÃO", "warning");
        let characName = epCmp.epCharacteristic.autoformList.filter(af => af.formGroup.invalid)[0].datasetName;

        let tab = this.tabSetCmp.tabs.find(tab => tab.tabId == "outrasInfo");
        this.tabSetCmp.selectTab(tab);

        epCmp.epCharacteristic.scrollToCharac(characName);
        reject();
      } else {
        if (epCmp.formGroup.pristine) {
          if (!ignoreSpinner)
            epCmp.lockScreen = false;
          resolve();
          return;
        }

        epCmp.dsService.saveFormGroupEntity(epCmp.datasetPS, epCmp.id, epCmp.formGroup)
          .pipe(take(1))
          .subscribe(resp => {
            epCmp.cmpList.forEach((cmp, index, array) => {
              epCmp.injectorList[index] = epCmp.getInjector(cmp);
            });

            if (!ignoreSpinner)
              epCmp.lockScreen = false;
            resolve();
          }, error => {
            if (!ignoreSpinner)
              epCmp.lockScreen = false;
            epCmp.messageService.showToastError(error);
            reject();
          })
      }
    });
  }

  saveAllCharac() {
    return new Promise<void>((resolve, reject) => {
      if (!this.epCharacteristic.canSaveCharacteristics()) {
        this.messageService.showToast("Não é possível salvar. verifique as caracteristicas", "ATENÇÃO", "warning");
        let characName = this.epCharacteristic.autoformList.filter(af => af.formGroup.invalid)[0].datasetName;

        let tab = this.tabSetCmp.tabs.find(tab => tab.tabId == "outrasInfo");
        this.tabSetCmp.selectTab(tab);

        this.epCharacteristic.scrollToCharac(characName);
        reject();
      } else {
        let promiseList = new Array<Promise<string>>();
        this.epCharacteristic.autoformList.filter(af => af.formGroup.dirty).forEach(af => {
          promiseList.push(af.saveAutoform(af));
        });

        Promise.all(promiseList).then(() => { resolve() }, error => { reject(error) });
      }
    })
  }

  editProcessOption(insert: boolean) {
    let formGroupEditProcOpt: FormGroup = this.formBuilder.group({}, { updateOn: "blur" });
    formGroupEditProcOpt.addControl("epp_especif_componente_prc.descricao", this.formBuilder.control(insert ? undefined : this.selectedProcessOption.name, Validators.required));
    formGroupEditProcOpt.addControl("epp_especif_componente_prc.padrao", this.formBuilder.control(insert ? false : this.selectedProcessOption.default));

    let layout: Array<ViewTemplateElement> = [
      {
        layoutType: LayoutType.component,
        cmpType: T2InputTextComponent,
        cmpName: "epp_especif_componente_prc.descricao",
        title: "Descrição",
        isBaseComponent: true
      },
      {
        layoutType: LayoutType.component,
        cmpType: T2CheckBoxComponent,
        cmpName: "epp_especif_componente_prc.padrao",
        title: "Padrão",
        isBaseComponent: true
      }
    ];

    let dlg = this.dialogService.open(DialogInputComponent, {
      context: {
        FormGroup: formGroupEditProcOpt,
        layout: layout,
        title: insert ? "Inclusão de Opção de Processo" : "Edição de Opção de Processo"
      }, closeOnBackdropClick: false, closeOnEsc: false, hasBackdrop: true
    });

    dlg.onClose.pipe(take(1)).subscribe(async resp => {
      if (resp == "Confirma") {
        this.lockScreen = true;

        let id: string;
        if (insert) {
          await this.httpClient.getNewUUID().pipe(take(1)).toPromise().then(newId => id = newId);
          formGroupEditProcOpt.addControl("epp_especif_componente_prc.id_especif_componente", this.formBuilder.control(this.productSpecification.id_specif_component));
          formGroupEditProcOpt.controls["epp_especif_componente_prc.id_especif_componente"].markAsDirty();
        } else {
          id = this.selectedProcessOption.id_especif_componente_prc;
        }

        this.datasetService.saveFormGroupEntity("epp_especif_componente_prc", id, formGroupEditProcOpt).pipe(take(1)).subscribe(async resp => {
          if (insert) {
            let newProcOpt = new ProcessOption();
            newProcOpt.id_especif_componente_prc = id;
            newProcOpt.name = formGroupEditProcOpt.controls["epp_especif_componente_prc.descricao"].value;
            newProcOpt.default = formGroupEditProcOpt.controls["epp_especif_componente_prc.padrao"].value;

            this.processOptionList.push(newProcOpt);
            this.procOptList.push({ id: id, description: newProcOpt.name });

            await this.getCharacteristics(true);

            this.formGroupProcessOption.controls["processOption"].setValue(id);
          } else {
            this.selectedProcessOption.name = formGroupEditProcOpt.controls["epp_especif_componente_prc.descricao"].value;
            this.selectedProcessOption.default = formGroupEditProcOpt.controls["epp_especif_componente_prc.padrao"].value;
          }

          this.lockScreen = false;
        }, error => {
          this.lockScreen = false
        });
      }
    })
  }

  deleteProcessOption() {
    this.messageService.showDialog({
      context: {
        topMessage: "ATENÇÃO",
        message: "Deseja excluir esse registro ?",
        actions: [{ description: "Excluir", status: "danger" }, { description: "Cancelar", status: "basic" }]
      }
    }).onClose.subscribe(resp => {
      if (resp == "Excluir") {
        this.lockScreen = true;
        this.datasetService.deleteDatasetRecord("epp_especif_componente_prc", this.selectedProcessOption.id_especif_componente_prc).pipe(take(1))
          .subscribe(resp => {
            let index = this.processOptionList.findIndex(p => p.id_especif_componente_prc == this.selectedProcessOption.id_especif_componente_prc);
            this.processOptionList.splice(index, 1);

            index = this.procOptList.findIndex(p => p.id == this.selectedProcessOption.id_especif_componente_prc);
            this.procOptList.splice(index, 1);

            this.selectedProcessOption = this.processOptionList.find(p => p.default) || this.processOptionList[0];
            this.formGroupProcessOption.controls["processOption"].setValue(this.selectedProcessOption.id_especif_componente_prc);

            this.lockScreen = false;
          }, error => {
            this.lockScreen = false;
          })
      }
    })

  }

  saveAllInfo() {
    this.lockScreen = true;
    let promList = new Array<Promise<any>>();
    let characCmpList = this.epService.getCharacComponentList();

    if (characCmpList) {
      characCmpList.forEach(cmp => {
        promList.push(cmp.saveInfo());
      })
    }

    promList.push(this.saveAllCharac());
    promList.push(this.autoformGeneral.saveAutoform(this.autoformGeneral));
    promList.push(this.savePS(this, true));

    Promise.all(promList).finally(() => { this.lockScreen = false });
  }

  statusChanged(status: T2Status) {
    this.currentStatus = status;

    if (this.loaded) {
      this.autoformGeneral.setAllControlsReadOnly(status.blockChange);
      this.reloadInjectionDependencies();
    }
  }

  reloadCustomCmp(charac: CharacteristicInfo) {
    let index = this.characInfoList.findIndex(c => c.datasetName == charac.datasetName);
    this.characInfoList[index] = charac;

    this.reloadInjectionDependencies();
  }
}
