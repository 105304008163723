<div>
  <h4>Bem vindo</h4>
  <p>
    Você está acessando a nova interface do Codit-AFX que agora se chama <strong>TechPROD</strong>.<br />
    Nos próximos meses teremos muitas novidades por aqui e traremos a flexibilidade e facilidade de uso do AFX para essa
    plataforma. <br />
    Funcionalidades novas também serão adicionadas, deixando a experiencia ainda mais agradável e prática.
  </p>

  <p>
    Nos conte o que achou, o que gostaria de ver por aqui e até mesmo o que deveriamos mudar. <br />
    <a href="mailto:novotechprod@tech2e.com.br">Manda um email pra gente</a>, estamos ansiosos para saber o que achou.
  </p>
</div>
