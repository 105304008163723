export class DepartmentalRequisition {
  id_group_requisition: string;
  id_requisition: string;
  group: string;
  code: string;
  status: string;
  costCenter: string;
  requesterUser: string;
  id_item: string;
  item: string;
  un: string;
  requiredDate: Date;
  userRequestedQty: number;
  requestedQty: number;
  consumedQty: number;
  reservedQty: number;
  notes: string;
  approverNotes: string;
  isDeleted?: boolean = false;
  newReq?: boolean = false;
}
