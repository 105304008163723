<div class="flexColumn" style="gap: 10px; height: 100%;">
  <div style="display: flex; gap: 5px;">
    <app-t2-button size="tiny" [status]="structShow == 'struct' ? 'primary' : 'basic'" value="Estrutura"
      (clicked)="setStructShow('struct')"></app-t2-button>
    <app-t2-button size="tiny" [status]="structShow == 'lamination' ? 'primary' : 'basic'" value="Laminação"
      (clicked)="setStructShow('lamination')"></app-t2-button>
  </div>

  <div *ngIf="structShow == 'struct'" class="flexColumn flexGap10" style="flex: 1">
    <div class="flexRow flexGap10" style="flex: 0.6">
      <!-- Listagem da estrutura -->
      <div class="fillContent">
        <div style="display: flex; justify-content: space-between; align-items: center;">
          <div style="display: flex; gap: 5px; justify-content: flex-start; align-items: center;">
            <nb-icon class="iconButton" icon="list-outline" (click)="setStructView('list');"></nb-icon>
            <nb-icon class="iconButton" icon="layers-outline" (click)="setStructView('layer');"></nb-icon>
          </div>

          <div *ngIf="structView == 'list'"
            style="display: flex; gap: 5px; justify-content: flex-end; align-items: center;">
            <button nbButton outline size="tiny" status="primary" (click)="addStructureLayer()">
              <nb-icon icon="plus-circle-outline"></nb-icon>
            </button>
            <button nbButton outline size="tiny" status="danger" (click)="deleteLayer()">
              <nb-icon icon="trash-2-outline"></nb-icon>
            </button>
          </div>

        </div>


        <div #structLayer *ngIf="structView == 'layer'" style="display: flex;">
          <div class="tariffCards">
            <div *ngFor="let sl of structLayerList; let i = index"
              [ngClass]="{'above': i == 0,
                            'cardBellow': i > 0,
                            'substrate': sl.layerType == 'SUBSTRATE',
                            'metaliz': sl.layerType == 'METALIZATION',
                            'extrusion': sl.layerType == 'EXTRUSION',
                            'adhesiv': sl.chemical?.chemicalType == 'Adesivo',
                            'color': sl.chemical?.chemicalType == 'Cor',
                            'chemical': sl.chemical?.chemicalType && (sl.chemical?.chemicalType != 'Cor') && (sl.chemical?.chemicalType != 'Adesivo'),
                            'layerSelected' : sl.id_especif_carac_estr_item == selectedLayer?.id_especif_carac_estr_item }" [ngStyle]="{'z-index' : i}"
              (click)="setSelectLayer(sl);">
              <!-- <app-layer-details [structIndex]="i + 1" [structLayer]="sl" style="height: 100%"></app-layer-details> -->
              <div class="layerType">{{sl?.layerType}} {{sl?.chemical?.chemicalType}} </div>
              <div class="layerDescription"><span>{{i + 1}}</span> {{sl?.description}}</div>
            </div>
          </div>

          <app-layer-details [structIndex]="selectedLayer.sortKey" [structLayer]="selectedLayer"
            style="width: 100%;"></app-layer-details>

        </div>

        <div #structList *ngIf="structView == 'list'" style="height: calc(100% - 30px);">
          <app-t2grid #gridStruct [loading]="structLoading" [pagination]="false"
            (rowClick)="onGridStructRowClick($event)" (rowDoubleClick)="editStructureLayer($event)"
            (filterChanged)="onGridStructFilterChanged()"></app-t2grid>
        </div>
      </div>

      <!-- Detalhe da estrutura selecionada -->
      <!-- <div class="fillContent">
          <div><strong style="font-size: 250%">{{selectedLayer?.sortKey}}</strong> {{selectedLayer?.description}}</div>
        </div> -->
    </div>

    <div style="flex: 0.4">
      <app-eppstructure-layer-substrate *ngIf="selectedLayer && selectedLayer.layerType == 'SUBSTRATE'"
        [structLayer]="selectedLayer"></app-eppstructure-layer-substrate>
      <app-eppstructure-layer-chemical *ngIf="selectedLayer && selectedLayer.layerType == 'CHEMICAL'"
        [id_especif_carac]="id_specif_carac_chemical"
        [id_especif_carac_estr_item]="selectedLayer.id_especif_carac_estr_item"
        [blockChange]="blockChange"></app-eppstructure-layer-chemical>
    </div>
  </div>

  <div *ngIf="structShow == 'lamination'" class="flexColumn fillContent">
    <app-epplamination #eppLamination style="height: 100%;" [laminationList]="laminationList"
      [substrateList]="structLayerList" [id_especif_carac]="laminationId_especif_carac"
      (saved)="laminationSaved($event)" (reloadLamination)="reloadLaminationList()"></app-epplamination>
  </div>
</div>
