<app-dialog-template #dialog [title]="'Controles'">
  <content style="width: 30vw; height: 30vh;">
    <div [nbSpinner]="saving" nbSpinnerSize="giant" nbSpinnerStatus="primary">
      <div class="flexColumn">
        <span class="cmpLabel">Permite Política Aprovação</span>
        <app-t2-check-box [formGroup]="formGroup" t2IdCmp="allowApprovalPolicy" />
      </div>
    </div>
  </content>
  <footer>
    <div class="flexAlignRowEndCenter flexGap5">
      <app-t2-button status="danger" size="tiny" value="Cancelar" [disabled]="saving" (clicked)="closeDialog(false)" />
      <app-t2-button status="primary" size="tiny" value="Salvar" [disabled]="saving" (clicked)="closeDialog(true)" />
    </div>
  </footer>
</app-dialog-template>