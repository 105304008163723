import { FormatWidth, getLocaleDateFormat } from "@angular/common";
import { Inject, LOCALE_ID, ViewChild } from "@angular/core";
import { Component, OnInit } from '@angular/core';
import { FormControl } from "@angular/forms";
import { NbDateService } from "@nebular/theme";
import { take } from "rxjs/Operators";
import { T2gridComponent } from "src/app/core/cmp/t2grid/t2grid.component";
import { T2HttpClientService } from "src/app/core/http/t2httpClient.service";
import { CilindroProgramacao } from "../../model/cilindro-programacao";

@Component({
  selector: 'app-cyl-history',
  templateUrl: './cyl-history.component.html',
  styleUrls: ['./cyl-history.component.scss']
})
export class CylHistoryComponent implements OnInit {

  @ViewChild("gridHist", { static: true }) gridHist: T2gridComponent;  

  loading = false;
  startDate: string;
  endDate: string;
  dateStartControl = new FormControl(this.dateService.getMonthStart(this.dateService.today()));
  dateEndControl = new FormControl(this.dateService.getMonthEnd(this.dateService.today()));
  format: string;

  constructor(private httpClient: T2HttpClientService, protected dateService: NbDateService<Date>, @Inject(LOCALE_ID) private locale) {
    let dtFormat = getLocaleDateFormat(locale, FormatWidth.Short);

    if (dtFormat.startsWith("M")) {
      this.format = "MM/dd/yyyy";
    } else {
      this.format = "dd/MM/yyyy";
    }
   }

  ngOnInit(): void {
    this.gridHist.t2GridOptions.masterDetail = true;
    this.gridHist.setGridColumnDefs([
      { headerName: "OP", field: "prodOrder", width: 100, cellRenderer: 'agGroupCellRenderer' },      
      { headerName: "Programação", field: "planDate", type: ["dateColumn"] },
      { headerName: "Dispositivo", field: "deviceName" },
      { headerName: "Tarefa", field: "taskDescription" },
      { headerName: "Produtos", field: "prodOrderTitle" },
      { headerName: "Cliente", field: "customer" },
      { headerName: "Pedidos", field: "salesOrder" }
    ]);

    this.gridHist.setDetailCellRendererParams({
      detailGridOptions: {
        columnDefs: [
          { headerName: "Químico", field: "color", width: 150 },
          { headerName: "Detalhe", field: "detail" },
          { headerName: "Cilindro", field: "cylinderCode", width: 100 },
          { headerName: "Local", field: "local" },
          { headerName: "Fornecedor", field: "supplier" },
          { headerName: "NF Gravação", field: "invoice" },
          { headerName: "Gramatura", field: "gram", width: 70 },
          { headerName: "Área aplic", field: "area", width: 70 },
          { headerName: "Gram Aplic", field: "aplicGram", width: 70 }          
        ]
      },

      getDetailRowData: function (params) {
        params.successCallback(params.data.colors)
      }
    });    
  }

  public loadData() {

    this.loading = true;

    if (!this.dateStartControl  || !this.dateEndControl) {
      return
    }
    
    const params = new Map<string, string>();
    params.set("startDate", this.dateService.format(this.dateStartControl.value, "yyyy-MM-dd"));
    params.set("endDate", this.dateService.format(this.dateEndControl.value, "yyyy-MM-dd"));

    this.httpClient.get("production.CylinderScheduler/programmingHistoric", params)
      .pipe(
        take(1)
      ).subscribe(resp => {
        if (!Array.isArray(resp.progList.prog)) {
          resp.progList.prog = [resp.progList.prog];
        }

        const progList: Array<CilindroProgramacao> = resp.progList.prog;
        progList.forEach(prog => {
          prog["ID"] = prog.id_task_plan;
          if (!Array.isArray(prog.colors)) {
            prog.colors = [prog.colors];
          }

          prog.colors.forEach((cor, index, array) => {
            if (cor) {
              cor["ID"] = cor.id_ordemProd_cilindro ? cor.id_ordemProd_cilindro : index;
            }
          })
        });

        this.gridHist.setGridData(progList, null);
        this.gridHist.autoSizeAllColumns(false);

        this.loading = false;
      })
  }

  onChange(event: any) {          
    if (event.target.id == "inputStartDate") {
      this.startDate = event.target.value;  
    } else if (event.target.id == "inputEndDate") {
      this.endDate = event.target.value;
    }
  }
}
