import { FormatWidth, getLocaleDateFormat } from "@angular/common";
import { Component, Inject, LOCALE_ID, OnInit } from '@angular/core';
import { T2BaseComponent } from "../t2-base-component";

@Component({
  selector: 'app-t2-date',
  templateUrl: './t2-date.component.html',
  styleUrls: ['./t2-date.component.scss']
})
export class T2DateComponent extends T2BaseComponent implements OnInit {
  public format: string;

  constructor( @Inject(LOCALE_ID) private locale) {

    super();

    let dtFormat = getLocaleDateFormat(locale, FormatWidth.Short);

    if (dtFormat.startsWith("M")) {
      this.format = "MM/dd/yyyy";
    } else {
      this.format = "dd/MM/yyyy";
    }
  }

  ngOnInit(): void {
    this.setDefaultValues();
   }

}
