import { Component } from '@angular/core';
import { ICellRendererAngularComp } from "ag-grid-angular";
import { ICellRendererParams } from "ag-grid-community";

@Component({
  selector: 'app-conjugation-action-renderer',
  templateUrl: './conjugation-action-renderer.component.html',
  styleUrls: ['./conjugation-action-renderer.component.scss']
})
export class ConjugationActionRendererComponent implements ICellRendererAngularComp {

  public specificationId: string;
  public selected: false;
  private params: any;

  agInit(params: ICellRendererParams<any, any>): void {
    this.params = params;
    this.selected = params.getValue();
    this.specificationId = params.data.specificationId;
  }

  refresh(params: ICellRendererParams<any, any>): boolean {
    return true;
  }

  openProdSpecif() {
    window.open(`epp/ep?id=${this.specificationId}`, '_blank');
  }

  setProdSpecifForConj() {
    this.params.data.setSpecificationForConjugation(this.specificationId);
  }

}
