<div class="conditionBox"
  [ngClass]="{'conditionBoxAND': condition.condition == 'AND', 'conditionBoxOR': condition.condition == 'OR'}">

  <div *ngIf="(condition.fieldList?.length + condition.groupList?.length) > 1" class="cond"
    [ngClass]="{'condAND': condition.condition == 'AND', 'condOR': condition.condition == 'OR'}"
    (click)="changeCondition(condition)">
    {{condition.condition == "AND" ? "E" : "O U"}}
  </div>

  <div class="flexColumn" style="flex: 100%; gap: 5px">

    <!-- Descrição de orientação de acordo com o tipo de condicao OR ou AND -->
    <div *ngIf="(condition.fieldList?.length + condition.groupList?.length) > 1" class="condTitle">
      <span [ngClass]="{'titleAND': condition.condition == 'AND', 'titleOR': condition.condition == 'OR'}">
        {{condition.condition == "AND" ? "Todas as condições precisam ser satisfeitas" : "Uma das condições precisa ser
        satisfeita"}}

      </span>
    </div>

    <!-- Lista de expressões CAMPO -->
    <div *ngFor="let item of condition.fieldList; let i = index" class="itemBox">
      <div style="width: 14px; text-align: center; font-weight: bold; font-size: 1em;  color: #909090">
        {{ i + 1}}
      </div>
      <app-query-condition-field [item]="item" [formGroup]="formGroup" editType="All"
        (fieldSelected)="selectField($event)"></app-query-condition-field>
      <div *ngIf="(item.propertyType != 'STATUS' && quickSearchCount < 2) || item?.fieldFilter?.quickSearch"
        style="display: flex; gap:7px; margin-top: 3px">
        <nb-checkbox (checkedChange)="changeFieldFilterQuickSearch(item)" [checked]="item?.fieldFilter?.quickSearch">
          <div class="nbCheckbox">Busca Rápida</div>
        </nb-checkbox>
        <nb-checkbox *ngIf=" item?.fieldFilter?.quickSearch" (checkedChange)="changeFieldFilterRequired(item)"
          [checked]="item?.fieldFilter?.required">
          <div class="nbCheckbox">Obrigatório</div>
        </nb-checkbox>
      </div>
      <div style="width: 20px; display: flex; align-items: center; justify-content: center" nbTooltip="Apagar campo">
        <nb-icon id="deleteField" icon="close-outline" (click)="deleteField(condition, item, i)"></nb-icon>
      </div>
    </div>

    <!-- Lista de expressões GRUPO -->
    <div *ngFor="let g of condition.groupList; let i = index">
      <div style="display: flex; align-items: center; gap: 5px;">
        <div style="width: 14px; text-align: center; font-weight: bold; font-size: 1em; color: #909090">{{
          condition.fieldList?.length + i +
          1}}</div>
        <app-query-condition [condition]="g" [id_dataset]="id_dataset" [formGroup]="formGroup" style="width: 100%;"
          (groupDeleted)="onDeleteGroup($event, i)"></app-query-condition>
      </div>
    </div>

    <!-- Botão de inclusão de expressões -->
    <div style="display: flex; align-items: center; width: 300px; margin-top: 5px;">
      <div class="addButton"
        [ngClass]="{'addButtonExp': (condition.fieldList?.length + condition.groupList?.length) > 1, 'condAND': condition.condition == 'AND', 'condOR': condition.condition == 'OR'}">

        <div id="selectCondType" style="gap: 5px; /*margin-left: 16px; */padding: 0px 15px;">
          <div class="groupAction" (click)="addField(condition)" nbTooltip="Adicionar campo">
            <nb-icon icon="menu-2-outline"></nb-icon>
          </div>

          <div class="groupAction" (click)="addGroup(condition, condition.condition == 'AND' ? 'OR' : 'AND')"
            nbTooltip="Adicionar sub-grupo">
            <nb-icon icon="credit-card-outline"></nb-icon>
          </div>

          <div *ngIf="canDelete" class="groupAction" style="background-color: var(--T2ColorBGRed);"
            nbTooltip="Apagar grupo" (click)="deleteGroup()">
            <nb-icon icon="close-outline"></nb-icon>
          </div>
        </div>

        <nb-icon id="iconAddCondType" icon="arrow-forward-outline"></nb-icon>
      </div>
    </div>
  </div>

</div>