import { BreakpointObserver, BreakpointState, Breakpoints } from "@angular/cdk/layout";
import { Injectable, OnDestroy } from '@angular/core';
import { Observable, Subject } from "rxjs";
import { takeUntil } from "rxjs/Operators";

@Injectable({
  providedIn: 'root'
})
export class T2UserDeviceService implements OnDestroy {

  private unsubscribe = new Subject<void>();
  private _isSmallScreen: boolean;
  private _isXSmallScreen: boolean;
  private _hasCamera: Boolean;
  private obsSmallScreen: Observable<BreakpointState>;

  get isSmallScreen(): boolean {
    return this._isSmallScreen;
  }

  set isSmallScreen(value: boolean) {
    // Não faz nada pois a propriedade é para ser apenas leitura
  }

  get isXSmallScreen(): boolean {
    return this._isXSmallScreen;
  }

  set isXSmallScreen(value: boolean) {
    // Não faz nada pois a propriedade é para ser apenas leitura
  }

  constructor(private breakpointObserver: BreakpointObserver) {
    this.obsSmallScreen = this.breakpointObserver.observe([Breakpoints.Small, Breakpoints.HandsetPortrait]).pipe(takeUntil(this.unsubscribe));

    this.breakpointObserver.observe([Breakpoints.XSmall]).pipe(takeUntil(this.unsubscribe)).subscribe(result => {
      this._isXSmallScreen = result.matches;
    });

    this.obsSmallScreen.subscribe(result => {
      this._isSmallScreen = result.matches;
    });
  }

  public getObservableSmallScreen(): Observable<BreakpointState> {
    return this.obsSmallScreen;
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  hasCamera() {
    if (this._hasCamera == undefined) {
      return new Promise<boolean>((resolve, reject) => {
        if (navigator.mediaDevices) {
          navigator.mediaDevices.enumerateDevices().then(devices => {
            this._hasCamera = devices.some(dev => dev.kind == "videoinput");
            resolve(this._hasCamera.valueOf());
          }, error => reject(error));
        } else {
          console.warn("O navegador não possuí suporte a API necessária para a utilização de câmera");
          this._hasCamera = false;
          resolve(this._hasCamera.valueOf());
        }
      })
    } else {
      return new Promise<boolean>((resolve, reject) => resolve(this._hasCamera.valueOf()));
    }
  }
}
