import { Injectable } from '@angular/core';
import { map, take } from "rxjs/Operators";
import { T2HttpClientService } from "src/app/core/http/t2httpClient.service";
import { ReleaseProdOrderInfo } from "./model/release-prod-order-info";
import { ReleaseProdOrderInfoCylinderInfo } from "./model/release-prod-order-info-cylinder-info";
import { ColorInfoDetail } from "./model/color-info-detail";
import { ColorInfoDetailCylinder } from "./model/color-info-detail-cylinder";
import { PSReleaseInfo } from "./model/psrelease-info";
import { PSReleaseItemInfo } from "./model/psrelease-item-info";
import { PSReleaseItemDemand } from "./model/psrelease-item-demand";
import { Observable, throwError } from "rxjs";
import { ConjugationItemInfo } from "./model/conjugation-item-info";
import { ConjugationInfo } from "./model/conjugation-info";
import { ConjugationPSCompat } from "./model/conjugation-pscompat";

@Injectable({
  providedIn: 'root'
})
export class ProdOrderReleaseService {

  constructor(private httpClient: T2HttpClientService) { }

  public getReleaseList(params?: {
    progressStatus?: Array<string>, status?: Array<string>
  }) {
    return this.httpClient.post("bsn.production.prodOrder/getReleaseList", null, params).pipe(take(1), map(resp => {
      let releaseList = new Array<ReleaseProdOrderInfo>();
      if (resp?.releaseInfoList) {
        if (!Array.isArray(resp.releaseInfoList)) {
          resp.releaseInfoList = [resp.releaseInfoList];
        }

        resp.releaseInfoList.forEach(info => {
          let releaseInfo = new ReleaseProdOrderInfo();
          releaseInfo.id = info.id;
          releaseInfo.salesOrderId = info.id_pedidovenda;
          releaseInfo.salesOrderItemId = info.id_pedidovenda_item;
          releaseInfo.type = info.tipo;
          releaseInfo.status = info.status;
          releaseInfo.progressStatus = info.situacao;
          releaseInfo.code = info.codigo;
          releaseInfo.itemId = info.itemId;
          releaseInfo.item = info.item;
          releaseInfo.prodSpecifVersion = info.versaoEP;
          releaseInfo.prodSpecifDetail = info.detalheEP;
          releaseInfo.requestedQty = new Number(info.qtdNec || 0).valueOf();
          releaseInfo.releasedQty = new Number(info.qtdLib || 0).valueOf();
          releaseInfo.deliveryDate = info.dtEntrega ? new Date(info.dtEntrega) : undefined;
          releaseInfo.ppcDate = info.dtPCP ? new Date(info.dtPCP) : undefined;
          releaseInfo.itemType = info.tipoItem;
          releaseInfo.conjCode = info.codConj;
          releaseInfo.client = info.cliente;
          releaseInfo.prodSpecifId = info.id_especificacao;
          releaseInfo.structure = info.estrutura;
          releaseInfo.conjugationId = info.id_conjugacaoItem;
          releaseInfo.cylPsConjugationId = info.id_conjugacao_cil;
          releaseInfo.assignedPsConjugationId = info.id_conjugacao;
          releaseInfo.cylInfoList = [];

          if (info.cylInfoList) {
            if (!Array.isArray(info.cylInfoList)) {
              info.cylInfoList = [info.cylInfoList];
            }

            info.cylInfoList.forEach(cyl => {
              let cylInfo = new ReleaseProdOrderInfoCylinderInfo();
              cylInfo.type = cyl.type;
              cylInfo.colorQty = new Number(cyl.colorQty || 0).valueOf();
              cylInfo.engravedQty = new Number(cyl.engravedQty || 0).valueOf();

              releaseInfo.cylInfoList.push(cylInfo);
            });
          }

          releaseList.push(releaseInfo);
        });
      }

      return releaseList;
    }))
  }

  public getColorInfoList(specificationId: string) {
    let params = new Map<string, string>();
    params.set("id_especificacao", specificationId);

    return this.httpClient.get("bsn.epp/getColorInfoDetail", params).pipe(take(1), map(resp => {
      let colorList = new Array<ColorInfoDetail>();

      if (resp && resp.colorList) {
        if (!Array.isArray(resp.colorList)) {
          resp.colorList = [resp.colorList];
        }

        resp.colorList.forEach((cd: ColorInfoDetail) => {
          if (cd.cylinderList && !Array.isArray(cd.cylinderList)) {
            cd.cylinderList = [cd.cylinderList];
          }

          cd.cylinderList = cd.cylinderList ?? [];

          cd.cylinderList.forEach((cyl: ColorInfoDetailCylinder) => {
            cyl.planDate = cyl.planDate ? new Date(cyl.planDate) : undefined;
          });

          colorList.push(cd);
        });
      }

      return colorList;
    }))
  }

  public getPsReleaseList(specificationId: string, isConjugation: boolean) {
    let params = new Map();
    params.set("id_especificacao", specificationId);
    params.set("isConjugation", isConjugation);

    return this.httpClient.get("bsn.epp/getPsReleaseList", params).pipe(take(1), map(resp => {
      let psRelList = new Array<PSReleaseInfo>();
      if (resp?.psReleaseList) {
        if (!Array.isArray(resp.psReleaseList)) {
          resp.psReleaseList = [resp.psReleaseList];
        }

        resp.psReleaseList.forEach((psRel: PSReleaseInfo) => {
          psRel.itemList = psRel.itemList ?? [];
          psRel.isConjugation = isConjugation;

          if (!Array.isArray(psRel.itemList)) {
            psRel.itemList = [psRel.itemList];
          }

          psRel.itemList.forEach((it: PSReleaseItemInfo) => {
            it.demandList = it.demandList ?? [];

            if (!Array.isArray(it.demandList)) {
              it.demandList = [it.demandList];
            }

            it.totalQty = new Number(it.totalQty || 0).valueOf();

            it.demandList.forEach((demand: PSReleaseItemDemand) => {
              demand.date = demand.date ? new Date(demand.date) : undefined;
              demand.quantity = new Number(demand.quantity || 0).valueOf();
            })
          });

          psRelList.push(psRel);
        })
      }

      return psRelList;
    }))
  }

  public getConjugationInfo(demandId: string, demandType: string): Observable<ConjugationInfo> {
    let params = new Map<string, string>();

    if (demandType == "Pedido Venda") {
      params.set("id_salesOrder_item_delivery", demandId);
    } else if (demandType == "Solicitação Avulsa") {
      params.set("id_prodOrder_request", demandId);
    } else {
      return throwError("Tipo de demanda desconhecido. Deve ser 'Pedido Venda' ou 'Solicitação Avulsa'");
    }

    return this.httpClient.get("bsn.sales.salesOrderDelivery/deliveryInfo", params).pipe(take(1), map(resp => {
      let conjInfo: ConjugationInfo;
      if (resp?.deliveryInfo) {
        conjInfo = new ConjugationInfo();
        conjInfo.conjugationItemId = resp.deliveryInfo.id_conjugacaoItem;
        conjInfo.specificationId = resp.deliveryInfo.id_multiProductSpecification;

        if (resp.deliveryInfo.combinationList?.combination) {
          if (!Array.isArray(resp.deliveryInfo.combinationList.combination)) {
            resp.deliveryInfo.combinationList.combination = [resp.deliveryInfo.combinationList.combination];
          }

          resp.deliveryInfo.combinationList.combination.forEach(cji => {
            let conjItem = new ConjugationItemInfo();
            conjItem.code = cji.salesOrderCode;
            conjItem.itemDescription = cji.itemDescription;
            conjItem.quantity = new Number(cji.deliveryQuantity || 0).valueOf();
            conjItem.deliveryDate = cji.deliveryDate ? new Date(cji.deliveryDate) : undefined;
            conjItem.type = cji.id_salesOrder_item_deliver ? "Pedido Venda" : "Solicitação Avulsa";
            conjItem.demandId = cji.id_salesOrder_item_deliver ? cji.id_salesOrder_item_deliver : cji.id_solicitacaoOP;

            conjInfo.itemList.push(conjItem);
          });
        }

        if (resp.deliveryInfo.EPCompatList?.InfoEntregaEPCompat) {
          if (!Array.isArray(resp.deliveryInfo.EPCompatList.InfoEntregaEPCompat)) {
            resp.deliveryInfo.EPCompatList.InfoEntregaEPCompat = [resp.deliveryInfo.EPCompatList.InfoEntregaEPCompat];
          }

          resp.deliveryInfo.EPCompatList.InfoEntregaEPCompat.forEach(spc => {
            let psCompat = new ConjugationPSCompat();
            psCompat.specificationId = spc.id_especificacao;
            psCompat.code = spc.codigo;
            psCompat.description = spc.descricao;

            conjInfo.psCompatList.push(psCompat);
          })
        }
      }

      return conjInfo;
    }))
  }

  public addPsToConjugation(releaseInfoToAdd: ReleaseProdOrderInfo, releaseInfoInConjugation?: ReleaseProdOrderInfo) {
    let params = new Map<string, string>();

    if (releaseInfoInConjugation) {
      if (releaseInfoInConjugation.type == "Pedido Venda") {
        params.set("id_salesOrder_item_delivery", releaseInfoInConjugation.id);
      } else {
        params.set("id_prodOrder_request", releaseInfoInConjugation.id);
      }
    } else {
      if (releaseInfoToAdd.type == "Pedido Venda") {
        params.set("id_salesOrder_item_delivery", releaseInfoToAdd.id);
      } else {
        params.set("id_prodOrder_request", releaseInfoToAdd.id);
      }
    }

    if (releaseInfoInConjugation) {
      if (releaseInfoToAdd.type == "Pedido Venda") {
        params.set("ids_new_salesOrder_item_delivery", releaseInfoToAdd.id);
      } else {
        params.set("ids_new_prodOrder_request", releaseInfoToAdd.id);
      }
    }

    return this.httpClient.get("bsn.sales.salesOrderDelivery/combineDeliveries", params).pipe(take(1));
  }

  public removePsFromConjugation(demandId: string, demandType: string) {
    let params = new Map<string, string>();

    if (demandType == "Pedido Venda") {
      params.set("id_salesOrder_item_delivery", demandId);
    } else {
      params.set("id_prodOrder_request", demandId);
    }

    return this.httpClient.get("bsn.sales.salesOrderDelivery/removeDeliveryCombination", params).pipe(take(1));
  }

  public setProdSpecifForConjugation(demandId: string, demandType: string, prodSpecifId: string) {
    let params = new Map<string, string>();

    if (demandType == "Pedido Venda") {
      params.set("id_salesOrder_item_delivery", demandId);
    } else {
      params.set("id_prodOrder_request", demandId);
    }

    if (prodSpecifId)
      params.set("id_productSpecification", prodSpecifId);

    return this.httpClient.get("bsn.sales.salesOrderDelivery/setCombinationProductSpecification", params).pipe(take(1));
  }

  public createEpForConjugation(conjugationItemId: string, mainItemId: string) {
    let params = new Map<string, string>();
    params.set("id_conjugacaoItem", conjugationItemId);
    params.set("id_mainItem", mainItemId);

    return this.httpClient.get("bsn.epp/createMultiProductSpecifFromSalesOrder", params).pipe(take(1));
  }
}
