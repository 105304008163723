import { Component, EventEmitter, Input, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { take } from "rxjs/Operators";
import { T2AutoFormComponent } from "src/app/core/form/t2-auto-form/t2-auto-form.component";
import { EPService } from "../ep.service";
import { CharacteristicInfo } from "../model/characteristic-info";
import { ProcessOption } from "../model/process-option";
import { T2DatasetService } from "src/app/core/dataset/t2dataset.service";
import { T2MessageService } from "src/app/core/t2-message.service";

@Component({
  selector: 'app-epcharacteristic',
  templateUrl: './epcharacteristic.component.html',
  styleUrls: ['./epcharacteristic.component.scss']
})
export class EPCharacteristicComponent implements OnInit {

  @ViewChildren("autoForm") autoformList: QueryList<T2AutoFormComponent>;

  @Input()
  get characteristicList(): Array<CharacteristicInfo> {
    return this._characteristicList;
  }
  set characteristicList(value: Array<CharacteristicInfo>) {
    this._characteristicList = value?.filter(c => !c.hasCustomCmp);

    setTimeout(() => {
      if (this.autoformList?.length > 0) {
        this.autoformList.forEach(af => {
          af.setAllControlsReadOnly(this.blockProductCaracChange)
        });

        let interval = setInterval(() => {
          if (!this.autoformList.some(af => !af.loaded)) {
            this.loadCompleted.emit();
            clearInterval(interval);
          }
        })
      }
    }, 300);
  }

  @Input()
  get selectedCharac(): CharacteristicInfo {
    return this._selectedCharac;
  }
  set selectedCharac(value: CharacteristicInfo) {
    this._selectedCharac = value;

    if (value) {
      document.getElementById(value.datasetName).scrollIntoView();
    }
  }
  @Input()
  get blockProductCaracChange(): boolean {
    return this._blockProductCaracChange;
  }
  set blockProductCaracChange(value: boolean) {
    this._blockProductCaracChange = value;

    if (this.autoformList && this.autoformList.length > 0) {
      this.autoformList.forEach(af => af.setAllControlsReadOnly(value));
    }
  }

  @Input() selectedProcessOption: ProcessOption;

  @Output() loadCompleted = new EventEmitter<void>();
  @Output() reloadCustomCmpCharac = new EventEmitter<CharacteristicInfo>();

  private _selectedCharac: CharacteristicInfo;
  private _blockProductCaracChange: boolean;
  private _characteristicList: Array<CharacteristicInfo>;
  private _selectedProcessOption: ProcessOption;

  constructor(private epService: EPService, private datasetService: T2DatasetService, private messageService: T2MessageService) { }

  ngOnInit(): void {

  }

  public deleteCharac(charac: CharacteristicInfo) {
    if (!charac.hasCustomCmp) {
      let af = this.autoformList.find(af => af.datasetName == charac.datasetName);

      af.deleted.pipe(take(1)).subscribe(() => {
        charac.loading = false;
      });

      af.deleteRecord();
    } else {
      this.messageService.showDialog({
        context: {
          topMessage: "ATENÇÃO",
          message: "Deseja excluir esse registro ?",
          actions: [{ description: "Excluir", status: "danger" }, { description: "Cancelar", status: "basic" }]
        }
      }).onClose.subscribe(resp => { 
        if (resp == "Excluir") {
          this.datasetService.deleteDatasetRecord(charac.datasetName, charac.id_especif_carac).pipe(take(1)).subscribe(resp => {
            this.deleted(true, charac).then(() => {
              this.reloadCustomCmpCharac.emit(charac);
            })
          }, error => {
            this.deleted(false, charac).then(() => {});
          })
        }
      });

      
    }
  }

  public insertCharac(charac: CharacteristicInfo, index: number) {
    this.characteristicList.splice(index, 0, charac);

    if (charac.hasCustomCmp) {
      this.reloadCustomCmpCharac.emit(charac);
    }
  }

  public canSaveCharacteristics(): boolean {
    return !this.autoformList.some(af => af.formGroup.invalid);
  }

  public scrollToCharac(datasetName: string) {
    document.getElementById(datasetName).scrollIntoView();
  }

  startedDelete(charac: CharacteristicInfo) {
    charac.loading = true;
  }

  deleted(deleted: boolean, charac: CharacteristicInfo) {
    return new Promise<void>((resolve, reject) => {
      if (deleted) {
        this.epService.deleteCharacteristic(charac.id_especif_carac).pipe(take(1)).subscribe(() => {
          charac.id_especif_carac = undefined;
          charac.loading = false;

          let index = this.characteristicList.findIndex(c => c.id_especif_carac == charac.id_especif_carac);

          this.characteristicList.splice(index, 1);

          resolve();
        }, error => {
          charac.loading = false;
          reject(error);
        });
      } else {
        charac.loading = false;
        resolve();
      }
    })
    
  }

  enabledSaveButton(): boolean {
    return this.autoformList?.some(af => af.formGroup.dirty);
  }

}
