import { Component, Input, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { FormControl } from "@angular/forms";
import { DialogTemplateComponent } from "src/app/core/cmp/dialog-template/dialog-template.component";

@Component({
  selector: 'app-duplicate-dialog',
  templateUrl: './duplicate-dialog.component.html',
  styleUrls: ['./duplicate-dialog.component.scss']
})
export class DuplicateDialogComponent implements OnInit {

  private _description: string;

  @Input() title: string;
  @Input() id_priceTable: string;
  @Input() baseDescription: string;
  @Input() startDate : Date;
  @Input() endDate : Date;

  descriptionField = new FormControl();
  startDateField = new FormControl();
  endDateField = new FormControl();

  @ViewChild('dialog', { static: true }) dialog: DialogTemplateComponent;

  constructor() { }

  ngOnInit(): void {
    this.descriptionField.setValue(this.baseDescription);
    this.startDateField.setValue(this.startDate);
    this.endDateField.setValue(this.endDate);
  }

  confirm() {

    const resp = {
      type: 'confirm',
      id_script_3: this.id_priceTable,
      description: this.descriptionField.value,
      startDate: this.startDateField.value ? this.startDateField.value : null,
      endDate : this.endDateField.value ? this.endDateField.value : null,
    };


    this.dialog.close(resp);
  }

  cancel() {
    const resp = { type: 'cancel' };
    this.dialog.close(resp);
  }

}
