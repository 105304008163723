import { Component, Input, OnDestroy } from '@angular/core';
import { DeviceOperationData } from "../../model/device-operation-data";
import { LayoutType, ViewTemplateElement } from "src/app/core/cmp/view-template/model/view-template-element";
import { AbstractControl, FormBuilder, FormGroup, ValidatorFn, Validators } from "@angular/forms";
import { DeviceOperationDataType } from "../../model/device-operation-data-type";
import { T2InputTextComponent } from "src/app/core/cmp/ui/t2-input-text/t2-input-text.component";
import { T2TextAreaComponent } from "src/app/core/cmp/ui/t2-text-area/t2-text-area.component";
import { T2InputIntegerComponent } from "src/app/core/cmp/ui/t2-input-integer/t2-input-integer.component";
import { T2InputFloatComponent } from "src/app/core/cmp/ui/t2-input-float/t2-input-float.component";
import { T2CheckBoxComponent } from "src/app/core/cmp/ui/t2-check-box/t2-check-box.component";
import { T2DateTimeComponent } from "src/app/core/cmp/ui/t2-date-time/t2-date-time.component";
import { T2SelectComponent } from "src/app/core/cmp/ui/t2-select/t2-select.component";
import { Subject } from "rxjs";
import { RegistrationData } from "../model/registration-data";
import { takeUntil } from "rxjs/Operators";

@Component({
  selector: 'app-appointment-register-formdata',
  templateUrl: './appointment-register-formdata.component.html',
  styleUrls: ['./appointment-register-formdata.component.scss']
})
export class AppointmentRegisterFormdataComponent implements OnDestroy {
  @Input()
  get dataList(): Array<DeviceOperationData> {
    return this._dataList;
  }
  set dataList(value: Array<DeviceOperationData>) {
    this._dataList = value;

    this.generateForm();
  }

  private unsubscribe = new Subject<void>();
  public layout: Array<ViewTemplateElement>;
  public formGroup: FormGroup;
  public loaded: boolean = false;
  public regDataList: Array<RegistrationData> = [];

  private _dataList: Array<DeviceOperationData>;

  constructor(private formBuilder: FormBuilder) {
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  private generateForm() {
    this.loaded = false;

    this.formGroup = this.formBuilder.group({});

    this.layout = [
      {
        layoutType: LayoutType.gridLayout,
        children: []
      }
    ];

    let cmpList = this.layout[0].children;

    this.dataList?.forEach(data => {
      let validators = new Array<ValidatorFn>();

      if (data.mandatory) {
        validators.push(Validators.required);
      }

      let controlId: string;
      let refValue: string = data.refValue;

      let regData = new RegistrationData();
      regData.id_item = data.id_item;

      if (data.id_registrationType_property) {
        regData.id_registrationType_property = data.id_registrationType_property;
        controlId = data.id_registrationType_property;
      } else if (data.id_paramMedicaoProducao) {
        regData.id_especif_carac = data.id_especif_carac;
        regData.id_paramMedicaoProducao = data.id_paramMedicaoProducao;
        regData.id_especif_carac_paramMPrd_it = data.id_especif_carac_paramMPrd_it;
        controlId = data.id_especif_carac_paramMPrd_it;
      } else if (data.id_inspecaoProducao) {
        regData.id_inspecaoProducao = data.id_inspecaoProducao;
        regData.id_inspecaoProducao_item = data.id_inspecaoProducao_item;
        regData.id_especif_carac_inspeProd_it = data.id_especif_carac_inspeProd_it;
        regData.id_esp_carac_inspeProd_it_ref = data.id_esp_carac_inspeProd_it_ref;
        controlId = data.id_inspecaoProducao_item;

        if (data.inspMinRefValue) {
          refValue += ` Min: ${data.inspMinRefValue}`;
        }

        if (data.inspMaxRefValue) {
          refValue += ` Max: ${data.inspMaxRefValue}`;
        }

        if (data.inspUnidade) {
          refValue += ` Und: ${data.inspUnidade}`;
        }
      }

      if (refValue) {
        refValue = `(Ref: ${refValue})`;
      }

      this.regDataList.push(regData);

      switch (data.dataType) {
        case DeviceOperationDataType.SINGLE_TEXT: {
          let colSpan: number;

          if (data.size <= 15) {
            colSpan = 1;
          } else if (data.size <= 30) {
            colSpan = 2;
          } else if (data.size <= 60) {
            colSpan = 4;
          } else {
            colSpan = 6;
          }

          cmpList.push({
            layoutType: LayoutType.component,
            cmpType: T2InputTextComponent,
            cmpName: controlId,
            title: data.propertyLabel,
            isBaseComponent: true,
            columnSpan: colSpan,
            inputs: {
              additionalInfo: refValue
            }
          });

          this.formGroup.addControl(controlId, this.formBuilder.control(data.defValue || "", validators));
          break;
        }
        case DeviceOperationDataType.MULTIPLE_TEXT: {
          cmpList.push({
            layoutType: LayoutType.component,
            cmpType: T2TextAreaComponent,
            cmpName: controlId,
            title: data.propertyLabel,
            isBaseComponent: true,
            columnSpan: 8,
            inputs: {
              additionalInfo: refValue
            }
          });

          this.formGroup.addControl(controlId, this.formBuilder.control(data.defValue || "", validators));
          break;
        }
        case DeviceOperationDataType.INTEGER: {
          cmpList.push({
            layoutType: LayoutType.component,
            cmpType: T2InputIntegerComponent,
            cmpName: controlId,
            title: data.propertyLabel,
            isBaseComponent: true,
            columnSpan: 2,
            inputs: {
              additionalInfo: refValue
            }
          });

          if (data.minValue) {
            validators.push(Validators.min(data.minValue));
          }

          if (data.maxValue) {
            validators.push(Validators.max(data.maxValue));
          }

          if (data.refValue) {
            validators.push(validatorEquals(Number(data.refValue).valueOf()));
          }

          this.formGroup.addControl(controlId, this.formBuilder.control(Number(data.defValue || 0), validators));
          break;
        }
        case DeviceOperationDataType.FLOAT: {
          cmpList.push({
            layoutType: LayoutType.component,
            cmpType: T2InputFloatComponent,
            cmpName: controlId,
            title: data.propertyLabel,
            isBaseComponent: true,
            columnSpan: 2,
            inputs: {
              additionalInfo: refValue
            }
          });

          if (data.minValue) {
            validators.push(Validators.min(data.minValue));
          }

          if (data.maxValue) {
            validators.push(Validators.max(data.maxValue));
          }

          if (data.refValue) {
            validators.push(validatorEquals(Number(data.refValue).valueOf()));
          }

          this.formGroup.addControl(controlId, this.formBuilder.control(Number(data.defValue || 0), validators));
          break;
        }
        case DeviceOperationDataType.LOGIC: {
          cmpList.push({
            layoutType: LayoutType.component,
            cmpType: T2CheckBoxComponent,
            cmpName: controlId,
            title: data.propertyLabel,
            isBaseComponent: true,
            columnSpan: 2,
            inputs: {
              additionalInfo: refValue
            }
          });

          this.formGroup.addControl(controlId, this.formBuilder.control(data.defValue));
          break;
        }
        case DeviceOperationDataType.DATETIME: {
          cmpList.push({
            layoutType: LayoutType.component,
            cmpType: T2DateTimeComponent,
            cmpName: controlId,
            title: data.propertyLabel,
            isBaseComponent: true,
            columnSpan: 2,
            inputs: {
              additionalInfo: refValue
            }
          });

          this.formGroup.addControl(controlId, this.formBuilder.control(data.defValue, validators));
          break;
        }
        case DeviceOperationDataType.LIST: {
          cmpList.push({
            layoutType: LayoutType.component,
            cmpType: T2SelectComponent,
            cmpName: controlId,
            title: data.propertyLabel,
            isBaseComponent: true,
            columnSpan: 2,
            inputs: {
              required: data.mandatory,
              grouped: false,
              list: data.itemList.map(item => {
                return {
                  id: item.description,
                  description: item.description
                }
              }),
              additionalInfo: refValue
            }
          });

          this.formGroup.addControl(controlId, this.formBuilder.control(data.defValue, validators));
          break;
        }
      }

      this.formGroup.controls[controlId].valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe(value => {
        regData.dataValue = value;
      })
    });

    // Apenas para já iniciar mostrando os campos marcados como inválidos, caso estejam
    this.formGroup.markAllAsTouched();
    this.formGroup.updateValueAndValidity();

    this.loaded = true;
  }

}

export function validatorEquals(valorEsperado: any): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const valorAtual = control.value;

    // Verifica se os valores são iguais
    if (valorAtual !== valorEsperado) {
      return { valorIgual: { value: control.value, expected: valorEsperado } };
    }

    return null; // Retorna null se a validação passar
  };
}
