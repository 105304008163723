import { Component } from '@angular/core';
import { ICellRendererAngularComp } from "ag-grid-angular";
import { ICellRendererParams } from "ag-grid-community";

@Component({
  selector: 'app-cylinder-info-renderer',
  templateUrl: './cylinder-info-renderer.component.html',
  styleUrls: ['./cylinder-info-renderer.component.scss']
})
export class CylinderInfoRendererComponent implements ICellRendererAngularComp {
  public data: Array<{colorQty: number, engravedQty: number}>;
  
  agInit(params: ICellRendererParams<any, any>): void {
    this.data = [];
    if (params.colDef.headerName == "Cil Gravado") {
      params.data.cylInfoList.filter(info => info.type == "Versao").forEach(cyl => {
        this.data.push({
          colorQty: cyl.colorQty,
          engravedQty: cyl.engravedQty
        })
      });

      if (this.data.length == 0) {
        this.data.push({colorQty: 0, engravedQty: 0});
      }
    } else if (params.colDef.headerName == "Cil Conjugado") {
      params.data.cylInfoList.filter(info => info.type == "Conjugacao").forEach(cyl => {
        this.data.push({
          colorQty: cyl.colorQty,
          engravedQty: cyl.engravedQty
        })
      });
    }
  }

  refresh(params: ICellRendererParams<any, any>): boolean {
    return true;
  }

}
