<div class="taskDetail flexColumn flexGap5">

  <div style="display: flex; justify-content: space-between; align-items: center;">
    <div style="font-weight: bold; font-size: 130%">{{prodOrdInfo?.prodOrder}} - {{taskDescription}} -
      {{deviceDescription}}
    </div>
  </div>

  <div class="cardItem" [ngClass]="{'cardItemDark': dark, 'cardItemLight': !dark}" style="font-weight: bold;">
    {{prodOrdInfo?.prodOrdDescription}}
  </div>
  <div class="cardItem" [ngClass]="{'cardItemDark': dark, 'cardItemLight': !dark}">
    {{prodOrdInfo?.client}}
  </div>

  <!-- CILINDROS -->
  <div style="margin-top: 10px;">
    <div style="font-weight: bold;">Cilindros</div>

    <div *ngFor="let item of prodOrdInfo?.cylinderList" class="cardItem"
      [ngClass]="{'cardItemDark': dark, 'cardItemLight': !dark}"
      style="display: flex; gap: 5px; justify-content: space-between; align-items: center;">

      <div>{{item.code}} {{item.color}} {{item.detail}}</div>

      <div style="display: flex; gap: 10px">

        <div style="width: 150px;" style="text-align: end;">
          {{item.supplier}}
        </div>

        <div style="width: 80px;;" class="cilBox" [ngClass]="{'cardItemDark': dark, 'cardItemLight': !dark}"
          [ngClass]="{'cilGray': !item.status, 'cilGreen': item.status == 'OK', 'cilOrange': item.status == 'REVISAR', 'cilRed': item.status == 'GRAVAR' }">
          {{item.status}}
        </div>

      </div>
    </div>
  </div>

</div>
