<app-dialog-template #dialog class="w" title="OPs Programadas com outra EP">
  <content>
    <div class="wContent">
      <app-t2grid #gdOP id="gdOP" rowSelection="single">
      </app-t2grid>
    </div>
  </content>
  <footer>
    <div class="wFooter">
      <p>Há OPs futuras programadas com esses cilindros e que usam outra EP. Escolha uma das ações para prosseguir</p>
      <div style="display: flex;">
        <button nbButton size="tiny" status="danger" (click)="closeWindow(1)" style="margin-left: auto">
          Cancelar
        </button>
        <button nbButton size="tiny" status="primary" (click)="closeWindow(2)">
          Usar os cilindros apenas nessa OP
        </button>
        <button nbButton size="tiny" status="primary" (click)="closeWindow(3)">
          Usar os cilindros nessa OP e remover nas futuras
        </button>
      </div>
    </div>
  </footer>
</app-dialog-template>