<div *ngIf="loaded" class="flexColumn flexGap5">
  <span class="defaultSmallTitle">Requisições</span>
  <div
    [ngClass]="{'reqList': !userDevService.isXSmallScreen, 'reqListXSmallScreen': userDevService.isXSmallScreen}">
    <div *ngFor="let request of requestList" class="detailBox">
      <div class="flexColumn flexGap5">
        <div class="flexAlignRowSpaceBetweenStart">
          <span class="defaultSmallTitle">{{request.requestCode}}</span>
          <span class="defaultSmallTitle">{{request.itemDescription}}</span>
        </div>
        <div class="flexAlignRowSpaceBetweenStart flexGap5" style="line-height: 0.7rem;">
          <div class="flexAlignRowSpaceBetweenStart flexGap5">
            <div class="flexAlignColumnStartEnd">
              <span class="detailBoxTitle">Empenhado</span>
              <span class="detailBoxValue">{{request.requestedQty | number: '1.1-2' : locale}} {{request.itemUnit}}</span>
            </div>
            <div class="flexAlignColumnStartEnd">
              <span class="detailBoxTitle">Transferido</span>
              <span class="detailBoxValue">{{request.transferredQty | number: '1.1-2' : locale}} {{request.itemUnit}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="!requestList.length">
      <span class="defaultSmallTitle">Não há nenhuma requisição para essa Tarefa</span>
    </div>
  </div>
</div>
