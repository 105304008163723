import { Injectable } from '@angular/core';
import { Observable } from "rxjs";
import { map } from "rxjs/Operators";
import { T2HttpClientService } from "../http/t2httpClient.service";
import { Warning } from "./model/warning";

@Injectable({
  providedIn: 'root'
})
export class WarningService {

  constructor(private httpClient: T2HttpClientService) { }

  transformServerResponse(warningFromServer): Warning {
    let warning = new Warning();
    warning.id_warning = warningFromServer.id_warning;
    warning.description = warningFromServer.description;
    warning.id_warningType = warningFromServer.id_warningType;
    warning.warningType = warningFromServer.warningType;
    warning.type = warningFromServer.type;
    warning.color = warningFromServer.color;

    if (warningFromServer.startDate) {
      warning.startDate = new Date(warningFromServer.startDate);
    }

    if (warningFromServer.maxDate) {
      warning.maxDate = new Date(warningFromServer.maxDate);
    }

    if (warningFromServer.completionDate) {
      warning.completionDate = new Date(warningFromServer.completionDate);
    }

    warning.id_security_identity = warningFromServer.id_security_identity;
    warning.completionUser = warningFromServer.completionUser;
    warning.id_dataset = warningFromServer.id_dataset;
    warning.datasetDescription = warningFromServer.datasetDescription;
    warning.id_informationRecord = warningFromServer.id_informationRecord;
    warning.warningNote = warningFromServer.warningNote;
    warning.denyManualClosure = warningFromServer.denyManualClosure;
    warning.critical = warningFromServer.critical;
    warning.id_approvalPolicy = warningFromServer.id_approvalPolicy;
    warning.id_security_identity_target = warningFromServer.id_security_identity_target;
    warning.status = warningFromServer.status;
    warning.acceptName = warningFromServer.acceptName;
    warning.declineName = warningFromServer.declineName;
    warning.policyNote = warningFromServer.policyNote;

    return warning;
  }

  public getWarningList(id_dataset?: string, id_informationRecord?: string, id_warningType?: string, warningType?: string, onlyPending?: boolean): Observable<Array<Warning>> {
    let params = new Map();

    if (id_dataset) {
      params.set("id_dataset", id_dataset);
    }

    if (id_informationRecord) {
      params.set("id_informationRecord", id_informationRecord);
    }

    if (id_warningType) {
      params.set("id_warningType", id_warningType);
    }

    if (warningType) {
      params.set("warningType", warningType);
    }

    if (onlyPending) {
      params.set("onlyPending", true);
    }

    return this.httpClient.get("core.warning/getWarningList", params).pipe(map(resp => {
      let warningList = new Array<Warning>();

      if (resp && resp.warningList) {
        if (!Array.isArray(resp.warningList)) {
          resp.warningList = [resp.warningList];
        }

        resp.warningList.forEach(w => {
          warningList.push(this.transformServerResponse(w));
        })
      }

      return warningList;
    }))
  }

  getWarningListByUser(id_user: string, onlyPending?: boolean): Observable<Array<Warning>> {
    let params = new Map();

    params.set("id_user", id_user);

    if (onlyPending) {
      params.set("onlyPending", true);
    }

    return this.httpClient.get("core.warning/getWarningList", params).pipe(map(resp => {
      let warningList = new Array<Warning>();

      if (resp && resp.warningList) {
        if (!Array.isArray(resp.warningList)) {
          resp.warningList = [resp.warningList];
        }

        resp.warningList.forEach(w => {
          warningList.push(this.transformServerResponse(w));
        })
      }

      return warningList;
    }))
  }

  insertWarning(warning: Warning): Observable<Warning> {
    return this.httpClient.post("core.warning/insertWarning", null, warning).pipe(map(resp => {
      let newWarning: Warning;
      if (resp && resp.warning) {
        newWarning = this.transformServerResponse(resp.warning);
      }

      return newWarning;
    }))
  }

  updateWarning(warning: Warning): Observable<Warning> {
    return this.httpClient.post("core.warning/updateWarning", null, warning).pipe(map(resp => {
      let updatedWarning: Warning;
      if (resp && resp.warning) {
        updatedWarning = this.transformServerResponse(resp.warning);
      }

      return updatedWarning;
    }))
  }

  finishWarning(warning: Warning): Observable<Warning> {
    return this.httpClient.post("core.warning/finishWarning", null, warning).pipe(map(resp => {
      let updatedWarning: Warning;
      if (resp && resp.warning) {
        updatedWarning = this.transformServerResponse(resp.warning);
      }

      return updatedWarning;
    }))
  }

  deleteWarning(id_warning: string): Observable<boolean> {
    let params = new Map<string, string>();
    params.set("id_warning", id_warning);

    return this.httpClient.get("core.warning/deleteWarning", params).pipe(map(resp => {
      return resp != null;
    }))
  }
}
