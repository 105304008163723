<app-form-template #formTemplate formTitle="TechProd UI Pattern" [loaded]="true">
  <content style="display: flex; gap: 10px; height: 100%;">
    <!-- Indice -->
    <div class="bar flexColumn flexGap10"
      style="flex: 200px; padding: 5px; background-color: rgba(71, 208, 148, 0.03); overflow: auto; scrollbar-width: none;">
      <div class="title">Sidebar</div>
      <div class="title">Main menu</div>
      <div class="title">Containers</div>
      <div class="title">Dialogs</div>
      <div class="title">Buttons</div>
      <div class="title">Grid / Table</div>
      <div>
        <div class="sub-title">Filter</div>
        <div class="sub-title">Editable</div>
      </div>

      <div class="title">Forms layout</div>
      <div>
        <div class="sub-title">Data group</div>
        <div class="sub-title">Autoform action bar</div>
        <div class="sub-title">Summary footer</div>
      </div>

      <div class="title">Common inputs</div>
      <div>
        <div class="sub-title">Integer</div>
        <div class="sub-title">Decimal</div>
        <div class="sub-title">Single line text</div>
        <div class="sub-title">Multi line text</div>
        <div class="sub-title">Date</div>
        <div class="sub-title">Date range</div>
        <div class="sub-title">Datetime</div>
        <div class="sub-title">Time</div>
        <div class="sub-title">Selector</div>
      </div>

      <div class="title">Special inputs</div>
      <div>
        <div class="sub-title">Aggregation</div>
        <div class="sub-title"><a routerLink="." fragment="inputStatus">Status</a></div>
        <div class="sub-title">ItemQuantity (unit conversion)</div>
        <div class="sub-title">Device selector</div>
      </div>

      <div class="title">Tooltip</div>
      <div class="title">Wait (spinner)</div>
      <div class="title">Badget</div>
      <div class="title">Popover menu</div>
    </div>

    <!-- Detail -->
    <div style="overflow: auto; scrollbar-width: none; flex: 1">

      <div class="flexColumn" style="gap: 40px">

        <div class="flexColumn" style="gap: 25px">
          <div class="sessionTitle">Special Inputs</div>

          <div class="flexColumn" style="gap: 3px; margin-left: 15px;">
            <div class="sessionSubtitle">Aggregation</div>
            <app-t2-aggregation style="width: 400px;"></app-t2-aggregation>
          </div>

          <div class="flexColumn" style="gap: 3px; margin-left: 15px;">
            <div class="sessionSubtitle" #inputStatus>Status</div>

            <app-t2-status (statusChange)="onStatusChange($event)">
            </app-t2-status>

            <app-dp-status [currentStatus]="currentStatus" [statusFlow]="statusFlow"
              (statusChange)="onStatusChange($event)">
            </app-dp-status>
          </div>

          <div style="margin-left: 15px;">
            <div class="sessionSubtitle">Item quantity</div>
          </div>

          <div style="margin-left: 15px;">
            <div class="sessionSubtitle">Device selector</div>
          </div>
        </div>
      </div>
    </div>
  </content>
</app-form-template>
