import { Component, OnInit } from '@angular/core';
import { T2BaseComponent } from "../t2-base-component";

@Component({
  selector: 'app-t2-text-area',
  templateUrl: './t2-text-area.component.html',
  styleUrls: ['./t2-text-area.component.scss']
})
export class T2TextAreaComponent extends T2BaseComponent implements OnInit {
  click: boolean = false;
  constructor() {
    super();
  }

  ngOnInit(): void {
    this.setDefaultValues();
  }

  copyText() {
    this.click = true;
    const txt = this.formGroup.controls[this.t2IdCmp].value;
    const input = document.createElement("input");
    input.value = txt;
    document.body.appendChild(input);
    input.select();
    document.execCommand("copy");
    input.remove();

    setTimeout(() => {
      this.click = false;
    }, 300);
  }
}
