import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import { LicenseManager } from 'ag-grid-enterprise';
const l = [
  'CompanyName=TECH2E EXC E EXP EM TECNOLOGIA LTDA',
  'LicensedApplication=TECH2E',
  'LicenseType=SingleApplication',
  'LicensedConcurrentDeveloperCount=1',
  'LicensedProductionInstancesCount=1',
  'AssetReference=AG-036654',
  'SupportServicesEnd=8_March_2024_[v2]_MTcwOTg1NjAwMDAwMA==7a552afeb31986591c03c3043087f3cf'
];
LicenseManager.setLicenseKey(l.join(','));

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
