import { T2AccessItemDatasetActionType } from './../../security/model/t2accessItem';
import { Component, HostBinding, HostListener, Input, OnInit, OnDestroy } from '@angular/core';
import { randomInt } from "crypto";
import { Subject } from "rxjs";
import { take, takeUntil } from "rxjs/Operators";
import { T2SecurityService } from "../../security/t2security.service";
import { T2MessageService } from "../../t2-message.service";
import { T2DsAttachmentsService } from "./t2-ds-attachments.service";

@Component({
  selector: 'app-t2-ds-attachments',
  templateUrl: './t2-ds-attachments.component.html',
  styleUrls: ['./t2-ds-attachments.component.scss']
})
export class T2DsAttachmentsComponent implements OnInit, OnDestroy {

  public actionsAccess: { upload: boolean, download: boolean, delete: boolean } = { upload: false, download: false, delete: false };
  private destroy$: Subject<boolean> = new Subject<boolean>();
  private _datasetName: string = null;
  private _parentId: string = null;

  @Input() onlyReading: boolean = false;
  @Input() t2IdCmp: string;

  @Input()
  get datasetName(): string { return this._datasetName; }
  set datasetName(value: string) { this._datasetName = value; this.loadFileList(); }

  @Input()
  get parentId(): string { return this._parentId; }
  set parentId(value: string) { this._parentId = value; this.loadFileList(); }

  @Input() autoformInInsertMode: boolean;

  currentFiles: Array<T2AttachmentFile> = [];

  @HostBinding('style.background-color')
  public background = '#ffffff01';

  // Dragover Event
  @HostListener('dragover', ['$event']) dragOver(event: any) {
    event.preventDefault();
    event.stopPropagation();
    if (!this.actionsAccess.upload) {
      this.background = '#ca0d0d1c';
    } else {
      this.background = '#068d6e1c';
    }

  }
  // Dragleave Event
  @HostListener('dragleave', ['$event']) public dragLeave(event: any) {
    event.preventDefault();
    event.stopPropagation();
    this.background = '#ffffff01';

    console.log("arquivos", event.dataTransfer?.files?.length);
  }
  // Drop Event
  @HostListener('drop', ['$event']) public drop(event: any) {
    event.preventDefault();
    event.stopPropagation();
    this.background = '#ffffff01';
    if (this.onlyReading) return;

    if (!this.actionsAccess.upload) {
      this.msgService.showToastError("Sem permissão para incluir arquivos");
      return;
    }

    if (this.autoformInInsertMode) {
      this.msgService.showToast("Salve a informação antes de incluir um anexo", "ATENÇÃO", "warning");
      return;
    }

    const files = event.dataTransfer.files;
    if (files.length > 0) {
      const f: Array<File> = Array.from(files);

      if (f.length > 5) {
        this.msgService.showToastError("Não é permitido anexar mais de 5 arquivos simultaneamente");
        return;
      }

      f.forEach(file => {
        if (file.size / 1000000 > 12) {
          this.msgService.showToastError("Não é permitido anexar arquivos maiores que 12MB");
          return;
        }

        let existingFile = this.currentFiles.find(fn => fn.fileName == file.name);
        if (existingFile) {
          this.msgService.showDialog({
            context: {
              message: `Deseja realmente substituir o anexo [${file.name}]?`,
              message2: `A operação não poderá ser revertida.`,
              actions: [{ description: "Cancelar", status: 'basic' }, { description: "Substituir", status: 'danger' }]
            }
          }).onClose.pipe(take(1)).subscribe((resp: string) => {
            if (resp == "Substituir") {
              existingFile.loading = true;
              this.attachService.delete(this.datasetName, this.parentId, existingFile.id)
                .pipe(take(1))
                .subscribe(() => {
                  this.currentFiles.splice(this.currentFiles.findIndex(fn => fn.fileName == existingFile.fileName), 1);
                  this.sendFile(file);
                });
            }
          });
        } else {
          this.sendFile(file);
        }
      }
      );
    }
  }

  constructor(private attachService: T2DsAttachmentsService, private msgService: T2MessageService, public sec: T2SecurityService) { }

  ngOnInit(): void { }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  private loadFileList() {
    if (!this.datasetName || !this.parentId) {
      this.currentFiles = [];
      return;
    }

    this.attachService.getAttachList(this._datasetName, this.parentId)
      .pipe(takeUntil(this.destroy$))
      .subscribe((resp: { id_dataset: string; datasetName: string, attachs: Array<T2AttachmentFile> }) => {
        this.currentFiles = resp.attachs;
        if (resp.datasetName) {
          this.sec.accessLoaded().pipe(take(1)).subscribe(() => {
            this.sec.getDatasetActions(resp.datasetName).subscribe(resp => {

              this.actionsAccess = { upload: false, download: false, delete: false };

              if (resp.length) {
                resp.forEach(action => {
                  this.actionsAccess.upload = this.actionsAccess.upload ||
                    (action.datasetActionType == T2AccessItemDatasetActionType.DSACTION_INSERT);

                  this.actionsAccess.delete = this.actionsAccess.delete ||
                    (action.datasetActionType == T2AccessItemDatasetActionType.DSACTION_DELETE);

                  this.actionsAccess.download = this.actionsAccess.download ||
                    (action.datasetActionType == T2AccessItemDatasetActionType.DSACTION_EDIT) ||
                    (action.datasetActionType == T2AccessItemDatasetActionType.DSACTION_SEARCH);
                });
              }
            });
          });

        }

      });
  }

  private sendFile(file: File) {
    const t2File = { id: new Date().getTime() + Math.random() + "", fileName: file.name, loading: true, mimeType: null };
    this.currentFiles.push(t2File);

    this.attachService.upload(this.datasetName, this.parentId, file)
      .subscribe(resp => {
        t2File.id = resp.id;
        t2File.fileName = resp.fileName;
        t2File.mimeType = resp.mimeType;
        t2File.loading = false;
      }, error => {
        // Se ocorreu erro para inserir o arquivo, remove item temporario da lista definitiva
        const idx = this.currentFiles.findIndex(item => item.id = t2File.id);
        this.currentFiles.splice(idx, 1);
      });
  }

  downloadFile(file: T2AttachmentFile): void {
    file.loading = true;
    this.attachService.download(this.datasetName, this.parentId, file.id)
      .pipe(take(1))
      .subscribe(resp => {

        if (!resp || !resp.size) {
          this.msgService.showToastError("Não é possível exibir o arquivo, menhuma informação foi recebida.");
        } else {
          const fileBlob = new Blob([resp], { type: file.mimeType });

          const a = document.createElement('a')
          const objectUrl = URL.createObjectURL(fileBlob)
          a.href = objectUrl
          a.download = file.fileName;
          a.click();
          URL.revokeObjectURL(objectUrl);
        }
        file.loading = false;
      }, error => {
        file.loading = false;
      });
  }

  removeFile(file: T2AttachmentFile): void {
    this.msgService.showDialog({
      context: {
        message: `Deseja realmente excluir o anexo [${file.fileName}]?`,
        message2: `A operação não poderá ser revertida.`,
        actions: [{ description: "Excluir", status: 'danger' }, { description: "Cancelar", status: 'basic' }]
      }
    }).onClose.pipe(take(1)).subscribe((resp: string) => {
      if (resp == "Excluir") {
        file.loading = true;
        this.attachService.delete(this.datasetName, this.parentId, file.id)
          .pipe(take(1))
          .subscribe(resp => {
            file.loading = false;
            this.loadFileList();
          }, error => {
            file.loading = false;
            this.loadFileList();
          });
      }

    });
  }

}

export class T2AttachmentFile {
  id: string;
  fileName: string;
  description?: string;
  mimeType?: string;
  loading?: boolean;
}
