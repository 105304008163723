import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NbDialogService } from "@nebular/theme";
import { DeviceCache, DeviceCacheService, DeviceCacheTask } from "../../device-cache.service";
import { T2AccessItem, T2AccessItemDatasetActionType } from "src/app/core/security/model/t2accessItem";
import { T2SecurityService } from "src/app/core/security/t2security.service";
import { DialogItemSelectionComponent, GroupSelection, ItemSelection } from "src/app/core/cmp/dialog-item-selection/dialog-item-selection.component";
import { Observable, Subject } from "rxjs";
import { ActionService } from "src/app/core/action/action.service";
import { DeviceTaskPlan } from "../../shop-floor.service";
import { ActivatedRoute } from "@angular/router";
import { takeUntil } from "rxjs/Operators";
import { Warning } from "src/app/core/warning/model/warning";
import { WarningComponent } from "src/app/core/warning/warning/warning.component";

@Component({
  selector: 'app-shop-floor-singletask-panel',
  templateUrl: './shop-floor-singletask-panel.component.html',
  styleUrls: ['./shop-floor-singletask-panel.component.scss']
})
export class ShopFloorSingletaskPanelComponent implements OnInit, OnDestroy {
  @Input() device: DeviceCache;
  @Input()
  get taskPlan(): DeviceCacheTask {
    return this._taskPlan;
  }
  set taskPlan(value: DeviceCacheTask) {
    this._taskPlan = value;

    if (this.taskPlan)
      this.currentTaskPlan = this._taskPlan;
  }

  @Input()
  get searchedTaskPlan(): DeviceTaskPlan {
    return this._searchedTaskPlan;
  }
  set searchedTaskPlan(value: DeviceTaskPlan) {
    this._searchedTaskPlan = value;

    if (this.searchedTaskPlan) {
      let devTP = new DeviceCacheTask();
      devTP.id_taskPlan = this.searchedTaskPlan.taskPlanId;
      devTP.id_task = this.searchedTaskPlan.taskId;
      devTP.id_ordemProd = this.searchedTaskPlan.productionOrderId;
      devTP.prodOrder = this.searchedTaskPlan.productionOrderCode;
      devTP.prodOrderDescription = this.searchedTaskPlan.productionOrderDescription;
      devTP.description = this.searchedTaskPlan.taskDescription;
      devTP.startDate = this.searchedTaskPlan.startDate;
      devTP.estimatedEndDate = this.searchedTaskPlan.estimatedEndDate;
      devTP.estimatedQty = this.searchedTaskPlan.estimatedQty;
      devTP.operation = this.searchedTaskPlan.status;
      devTP.productList = this.searchedTaskPlan.prodOrderCmpList.map(cmp => {
        return {
          description: cmp.description,
          quantity: cmp.quantity,
          unit: cmp.unitSymbol
        }
      });

      this.currentTaskPlan = devTP;
    } else {
      this.taskPlan = this.device?.taskList.find(tp => tp.id_taskPlan == this.taskPlanId);
      this.currentTaskPlan = this.taskPlan;
    }
  }

  @ViewChild("mainPanel") mainPanel: ElementRef;

  public smallWindow = false;
  public currentTaskPlan: DeviceCacheTask;
  private _searchedTaskPlan: DeviceTaskPlan;
  private _taskPlan: DeviceCacheTask;
  private unsubscribe = new Subject<void>();
  private unsubscribeDevice = new Subject<void>();
  private deviceId: string;
  private taskPlanId: string;

  constructor(
    private sec: T2SecurityService,
    private dialogService: NbDialogService,
    private actionService: ActionService,
    private route: ActivatedRoute,
    private devCacheService: DeviceCacheService) { }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
    this.unsubscribeDevice.next();
    this.unsubscribeDevice.complete();
  }

  ngOnInit(): void {
    this.route.queryParams.pipe(takeUntil(this.unsubscribe)).subscribe(params => {
      this.deviceId = params["id_device"];
      this.taskPlanId = params["id_taskPlan"];

      if (this.deviceId != this.device?.deviceId) {
        this.unsubscribeDevice.next();

        this.devCacheService.initialize().then(() => {
          this.devCacheService.getDeviceData(this.deviceId).pipe(takeUntil(this.unsubscribeDevice)).subscribe(dev => {
            this.device = dev;

            // É alterado diretamente o privado para não mudar a tela caso esteja exibindo um planejamento feito por busca
            this._taskPlan = this.device.taskList?.find(tp => tp.id_taskPlan == this.taskPlanId);
            if (!this.searchedTaskPlan) {
              this.currentTaskPlan = this.taskPlan;
            }

            this.calculateMinutes();
          });
        })
      }
    })

    this.deviceTimer();
  }

  private calculateMinutes() {
    this.device?.taskList?.forEach(t => {
      t.qttMinutesExecuting = (new Date().getTime() - new Date(t?.startDateDurationReg).getTime()) / 60000;
    });
  }

  private deviceTimer() {
    setInterval(() => {
      this.calculateMinutes();
    }, 60000);
  }

  showActionTree(origem: string, id, smallWindow): void {
    if (smallWindow) return;
    let actions: T2AccessItem[] = [];

    if (origem == "op" && id) {
      this.sec.getDatasetActions("ord_ordemprod").subscribe((actionList) => {
        actionList.forEach((action) => {
          if ([T2AccessItemDatasetActionType.DSACTION_OTHER,
          T2AccessItemDatasetActionType.DSACTION_SEARCH,
          T2AccessItemDatasetActionType.DSACTION_OTHERCLOSE,
          T2AccessItemDatasetActionType.DSACTION_VIEW,
          T2AccessItemDatasetActionType.DSACTION_EDIT].includes(action.datasetActionType)) {
            actions.push(action);
          }
        });
      });
    }

    if (origem == "task" && id) {
      this.sec.getDatasetActions("ord_ordemprod_tarefa").subscribe((actionList) => {
        actionList.forEach((action) => {
          if ([T2AccessItemDatasetActionType.DSACTION_OTHER,
          T2AccessItemDatasetActionType.DSACTION_SEARCH,
          T2AccessItemDatasetActionType.DSACTION_OTHERCLOSE,
          T2AccessItemDatasetActionType.DSACTION_VIEW,].includes(action.datasetActionType)) {
            actions.push(action);
          }
        });
      });
    }

    if (origem == 'device' && id) {
      this.sec.getDatasetActions("prd_dispositivo").subscribe((actionList) => {
        actionList.forEach((action) => {
          if ([T2AccessItemDatasetActionType.DSACTION_OTHER,
          T2AccessItemDatasetActionType.DSACTION_SEARCH,
          T2AccessItemDatasetActionType.DSACTION_OTHERCLOSE,
          T2AccessItemDatasetActionType.DSACTION_VIEW,].includes(action.datasetActionType)) {
            actions.push(action);
          }
        });
      });
    }

    let groups: Array<GroupSelection> = [];
    let items: Array<ItemSelection> = [];
    let groupName = null;
    if (actions.length > 0) {
      actions.forEach(action => {
        groupName = action.datasetDescription || action.datasetGroup || groupName;
        items.push(
          {
            id_item: action.id,
            itemName: action.datasetActionDescription,
            selected: false
          });

        if (groups.find(g => g.id_group == action.id_dataset_group)) {
          return;
        }

        groups.push(
          {
            id_group: action.id_dataset_group,
            groupName: null,
            selected: false,
            items: items
          });
      });

      const dialogRef = this.dialogService.open(
        DialogItemSelectionComponent,
        {
          context: {
            title: groupName,
            groupSelection: 'none',
            itemSelection: 'single',
            itemFromDifGroups: false,
            groups: groups,
            useConfirmButton: false,
            buttonSize: 'large'
          },
        }
      );

      dialogRef.onClose.subscribe((resp) => {
        if (resp) {
          let i = items.find(item => item.selected);

          let id_action = null;
          if (i) {
            id_action = i.id_item;
          }

          const action: T2AccessItem = actions.find(a => a.id == id_action);

          const params = new Map<string, string>();
          params.set("datasetName", action.datasetName);
          params.set("id", id);
          this.executeAction(action, params);
        }
      });
    }
  }

  private executeAction(action, params): void {
    const resp = this.actionService.executeAction(action, params, "newWindow");
    if (resp instanceof Observable) {
      resp.subscribe(resp => {
      });
    }
  }

  openWarning(warningList: Array<Warning>) {
    this.dialogService.open(WarningComponent, {
      context: {
        warningList: warningList
      }
    });
  }

}
