import { Component, OnInit } from '@angular/core';
import { T2PrinterService } from "../printer/t2-printer.service";
import { T2ThemingService } from '../t2theming.service';

@Component({
  selector: 'app-config',
  templateUrl: './config.component.html',
  styleUrls: ['./config.component.scss']
})
export class ConfigComponent implements OnInit {

  public themeName: string;
  public selectedLabelPrinter: string;
  public printerList;

  constructor(private themeService: T2ThemingService, private printerService: T2PrinterService) { 
    this.themeName = themeService.getThemeName();
    let sel = printerService.getSelectedLabelPrinter();

    if (sel) {
      this.selectedLabelPrinter = sel.clientName + "##" + sel.printerName;
    }
  }

  ngOnInit(): void {
    this.printerService.getPrinterList().subscribe(resp => {
      this.printerList = resp;
    });
  }

  onThemeChange() {
    this.themeService.changeTheme(this.themeName);
  }

  onLabelPrinterChange() {
    let data = this.selectedLabelPrinter.split("##");
    this.printerService.changeLabelPrinter(data[0], data[1]);
  }

}
