import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-t2-icon',
  templateUrl: './t2-icon.component.html',
  styleUrls: ['./t2-icon.component.scss']
})
export class T2IconComponent {
  @Input() t2IdCmp: string;
  @Input() icon: string;
  @Input() status: string;
  @Input() ngStyle: any;
  @Output() clicked = new EventEmitter<string>();

  onClick() {
    this.clicked.emit(this.t2IdCmp);
  }
}
