<div class="flexColumn flexLength1">

  <app-view-template #viewTemplate [layout]="layout" [data]="data" [FormGroup]="formGroup">
  </app-view-template>


  <div  *ngFor="let mlOption of machineLoadOptions" class="flexColumn" style="gap: 10px; padding: 5px;">

    <div *ngIf="mlOption.chartOptions && mlOption.tableOptions"
      style="display: flex; gap: 5px; justify-content: space-between; align-items: center; margin-right: 10px">
      <div style="font-size: 1.3rem; margin-right: 5px; font-weight: bold;">
        {{mlOption.tableOptions && mlOption.show == 'table' ? mlOption.dataTitle : "" }}
      </div>
      <div>
        <button nbButton size="tiny" [status]="mlOption.show == 'chart' ? 'info' : 'basic'"
          (click)="mlOption.show = 'chart'" style="margin-right: 5px;">Gráfico</button>
        <button nbButton size="tiny" [status]="mlOption.show == 'table' ? 'info' : 'basic'"
          (click)="mlOption.show = 'table'">Tabela</button>
      </div>
    </div>

    <div style="display: flex; flex-direction: column">
      <app-t2-chart *ngIf="mlOption.chartOptions && mlOption.show == 'chart'" [chartOptions]="mlOption.chartOptions"
        style="width: 100%; height: 300px;"></app-t2-chart>

      <div *ngIf="mlOption.tableOptions && mlOption.show == 'table'" style="height: 300px">
        <app-machine-load-table [tableOptions]="mlOption.tableOptions"> </app-machine-load-table>
      </div>
    </div>

  </div>




</div>
