import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-task-separation-status',
  templateUrl: './task-separation-status.component.html',
  styleUrls: ['./task-separation-status.component.scss']
})
export class TaskSeparationStatusComponent implements OnInit {

  @Input() stockAvailable: boolean;
  @Input() mpSeparate: boolean;
  @Input() percSeparation: number;
  @Input() hasRequisition: boolean;

  ngOnInit(): void { }
}
