import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-eppassembly',
  templateUrl: './eppassembly.component.html',
  styleUrls: ['./eppassembly.component.scss']
})
export class EPPAssemblyComponent {
  @Input() t2IdCmp: string;
  @Input()
  public get trackQty(): number {
    return this._trackQty;
  }
  public set trackQty(value: number) {
    this._trackQty = value;
    if (value != undefined) this.changeParams();
  }
  @Input()
  public get verticalQty(): number {
    return this._verticalQty;
  }
  public set verticalQty(value: number) {
    this._verticalQty = value;
    if (value != undefined) this.changeParams();
  }
  @Input()
  public get topMargin(): number {
    return this._topMargin;
  }
  public set topMargin(value: number) {
    this._topMargin = value;
    if (value != undefined) this.changeParams();
  }
  @Input()
  public get bottomMargin(): number {
    return this._bottomMargin;
  }
  public set bottomMargin(value: number) {
    this._bottomMargin = value;
    if (value != undefined) this.changeParams();
  }
  @Input()
  public get rightMargin(): number {
    return this._rightMargin;
  }
  public set rightMargin(value: number) {
    this._rightMargin = value;
    if (value != undefined) this.changeParams();
  }
  @Input()
  public get leftMargin(): number {
    return this._leftMargin;
  }
  public set leftMargin(value: number) {
    this._leftMargin = value;
    if (value != undefined) this.changeParams();
  }
  @Input()
  public get bloodlettingBtwTracks(): number {
    return this._bloodlettingBtwTracks;
  }
  public set bloodlettingBtwTracks(value: number) {
    this._bloodlettingBtwTracks = value;
    if (value != undefined) this.changeParams();
  }
  @Input()
  public get bloodlettingBtwLines(): number {
    return this._bloodlettingBtwLines;
  }
  public set bloodlettingBtwLines(value: number) {
    this._bloodlettingBtwLines = value;
    if (value != undefined) this.changeParams();
  }
  @Input()
  public get rotation(): string {
    return this._rotation;
  }
  public set rotation(value: string) {
    this._rotation = value;
    if (value != undefined) this.changeParams();
  }
  @Input()
  public get itemDescription(): string {
    return this._itemDescription;
  }
  public set itemDescription(value: string) {
    this._itemDescription = value;
    if (value != undefined) this.changeParams();
  }
  @Input()
  public get heightAssembly(): number {
    return this._heightAssembly;
  }
  public set heightAssembly(value: number) {
    this._heightAssembly = value;
    if (value != undefined) this.changeParams();
  }
  @Input()
  public get widthAssembly(): number {
    return this._widthAssembly;
  }
  public set widthAssembly(value: number) {
    this._widthAssembly = value;
    if (value != undefined) this.changeParams();
  }

  public assemblyObj: object;
  private timer;
  private _trackQty: number;
  private _verticalQty: number;
  private _topMargin: number;
  private _bottomMargin: number;
  private _rightMargin: number;
  private _leftMargin: number;
  private _bloodlettingBtwTracks: number;
  private _bloodlettingBtwLines: number;
  private _rotation: string;
  private _itemDescription: string;
  private _heightAssembly: number;
  private _widthAssembly: number;

  constructor() {

  }

  private changeParams() {
    if (this.timer) {
      clearTimeout(this.timer);
    }

    this.timer = setTimeout(() => {
      this.generateAssemblyObject();
    }, 1000);
  }

  public generateAssemblyObject() {
    let rotation = this.rotation ? new Number(this.rotation.replace(/[^0-9]/g, "")) : 0;
    this.assemblyObj = {
      montagem: {
        margin: {
          top: this.topMargin,
          right: this.rightMargin,
          bottom: this.bottomMargin,
          left: this.leftMargin
        },
        sequences: [
          {
            sequence: 1, name: "Montagem"
          }
        ],
        elements: [
          {
            sequence: 1,
            name: this.itemDescription,
            width: this.widthAssembly,
            height: this.heightAssembly,
            orientation: rotation,
            tracks: this.trackQty,
            repetition: this.verticalQty,
            blood: { vertical: this.bloodlettingBtwLines, horizontal: this.bloodlettingBtwTracks }
          }
        ]
      }
    }
  }
}
