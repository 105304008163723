<ag-grid-angular
  #t2grid
  id="t2grid"
  [gridOptions]="t2GridOptions"
  (gridReady)="onGDAFXReady($event)"
  (firstDataRendered)="onFirstDataRendered($event)"
  (rowClicked)="onGDRowClicked($event)"
  (rowDoubleClicked)="onGDRowDoubleClicked($event)"
  (cellKeyDown)="onGDCellKeyDown($event)"
  (cellKeyPress)="onGDCellKeyPress($event)"
  (cellClicked)="onGDCellClicked($event)"
  (selectionChanged)="onGDSelectionChanged($event)"
  (cellMouseOver)="onGDCellMouseOver($event)"
  (sortChanged)="onSortChanged($event)"
  (columnResized)="onColumnResized($event)"
  (columnVisible)="onColumnVisible($event)"
  (columnMoved)="onColumnMoved($event)"
  (columnPinned)="onColumnPinned($event)"
  (columnRowGroupChanged)="onColumnRowGroupChanged($event)"
  (columnPivotChanged)="onPivotChanged($event)"
  (columnPivotModeChanged)="onPivotChanged($event)"
  (filterChanged)="onFilterChanged($event)"
  [ngClass]="{'ag-theme-balham-dark' : sessionTheme === 'dark', 'ag-theme-balham' : sessionTheme != 'dark'} "
  [animateRows]="animateRows"
  [tooltipShowDelay]="0"
  [tooltipMouseTrack]="tooltipMouseTrack"
  [pagination]="pagination"
  [headerHeight]="headerHeight"
  [rowHeight]="rowHeight"
  [paginationPageSize]="250"
  [groupDisplayType]="'singleColumn'"
  [treeData]="treeData"
  [autoGroupColumnDef]="autoGroupColumnDef"
  [getDataPath]="getIntDataPath"
  [getContextMenuItems]="getContextMenuItems"
  [sideBar]="sideBar"
  [rowStyle]="rowStyle"
  [getRowStyle]="getRowStyle"
  [excelStyles]="excelStyles"
  [rowDragManaged]="rowDragManaged"
  [rowClassRules]="rowClassRules"
  style="width: 100%; height: 100%;"
>
</ag-grid-angular>
