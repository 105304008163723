import { Observable } from "rxjs";
import { T2AccessItem } from "src/app/core/security/model/t2accessItem";

export enum ActionType {
  changeValue = "changeValue",
  runAction = "runAction",
  runScript = "runScript"
}

export class T2ViewTemplateAction {
  type: ActionType;

  // apenas para identificar a ação
  actionName?: string;

  // apenas para o tipo "changeValue"
  methodName?: string;

  // apenas para o tipo "runAction"
  action?: T2AccessItem;
  adjustResponse?: (actionResponse: any) => Observable<Array<{cmpName: string, value: any, cmpInputName?: string, emitChangeEvent?: boolean}>>;

  // apenas para o tipo "runScript"
  scriptName?: string;
}
