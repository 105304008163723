import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from "ag-grid-angular";
import { ICellRendererParams } from "ag-grid-community";

@Component({
  selector: 'app-operation-type-cell-renderer',
  templateUrl: './operation-type-cell-renderer.component.html',
  styleUrls: ['./operation-type-cell-renderer.component.scss']
})
export class OperationTypeCellRendererComponent implements OnInit, ICellRendererAngularComp {

  constructor() { }
  operationType: string;
  oT: "E" | "S" | "T" = undefined;

  agInit(params: ICellRendererParams<any, any>): void {
    this.operationType = params.data?.operationType;
    if (this.operationType == 'Entrada') {
      this.oT = "E";
    } else if (this.operationType == 'Saída') {
      this.oT = "S";
    } else if (this.operationType == 'Transferência') {
      this.oT = "T";
    }
  }

  refresh(params: ICellRendererParams<any, any>): boolean {
    return true;
  }

  ngOnInit(): void {
  }

}
