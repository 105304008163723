import { FlexLayoutModule } from '@angular/flex-layout';
import { CylHistoryComponent } from './../flx/cylinder-scheduler/cyl-history/cyl-history.component';
import { FlexProductionModule } from './../flx/flex-production.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PriceTableComponent } from './price-table/price-table.component';
import { T2NebularModule } from "src/app/t2nebular.module";
import { T2CoreModule } from "src/app/core/t2core.module";
import { DuplicateDialogComponent } from './price-table/duplicate-dialog/duplicate-dialog.component';




@NgModule({
  declarations: [PriceTableComponent, DuplicateDialogComponent],
  imports: [
    CommonModule,

    T2NebularModule,
    T2CoreModule,
    FlexLayoutModule
  ]
})
export class SalesModule { }
