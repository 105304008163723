<app-dialog-template #dialog title="Ordenar Tarefas">
  <content class="flexColumn" style="gap: 10px; width: 350px;">
    <div style="display: flex; justify-content: flex-start; align-items: center;">
      <div style="width: 130px;">Primeiro campo</div>
      <nb-select [(ngModel)]="field1" [size]="'small'" style="min-width: 150px;" (ngModelChange)="selectChange($event)">
        <nb-option [value]="">{{"-"}}</nb-option>
        <nb-option *ngFor="let item of fieldsList" [value]="item.fieldName">{{item.fieldLabel}}</nb-option>
      </nb-select>
    </div>

    <div style="display: flex; justify-content: flex-start; align-items: center;">
      <div style="width: 130px;">Segundo campo</div>
      <nb-select [(ngModel)]="field2" [size]="'small'" style="min-width: 150px;" (ngModelChange)="selectChange($event)">
        <nb-option [value]="">{{"-"}}</nb-option>
        <nb-option *ngFor="let item of fieldsList" [value]="item.fieldName">{{item.fieldLabel}}</nb-option>
      </nb-select>
    </div>

    <div style="display: flex; justify-content: flex-start; align-items: center;">
      <div style="width: 130px;">Terceiro campo</div>
      <nb-select [(ngModel)]="field3" [size]="'small'" style="min-width: 150px;" (ngModelChange)="selectChange($event)">
        <nb-option [value]="">{{"-"}}</nb-option>
        <nb-option *ngFor="let item of fieldsList" [value]="item.fieldName">{{item.fieldLabel}}</nb-option>
      </nb-select>
    </div>

  </content>
  <footer>
    <div style="display: flex; justify-content: flex-end; align-items: center; gap: 5px">
      <button nbButton status="danger" size="tiny" (click)="closeDialog('cancel')">Cancela</button>
      <button nbButton status="primary" size="tiny" (click)="closeDialog('confirm')"
        [disabled]="!isSelectionValid">Confirma</button>
    </div>
  </footer>
</app-dialog-template>
