import { Component, OnInit, ViewChild, ViewChildren, QueryList, Inject, LOCALE_ID } from '@angular/core';
import { FormTemplateComponent } from "src/app/core/cmp/form-template/form-template.component";
import { T2SecurityService } from "src/app/core/security/t2security.service";
import { take, filter, map } from 'rxjs/Operators';
import { NbDateService } from "@nebular/theme";
import { FormControl } from "@angular/forms";
import { WidgetPanelComponent } from '../../../../core/widget/widget-panel.component';
import { T2HttpClientService } from "src/app/core/http/t2httpClient.service";
import { WidgetSelectionComponent } from '../../../../core/widget/widget-selection/widget-selection.component';
import { EChartsType } from 'echarts';
import { T2ThemingService } from "src/app/core/t2theming.service";
import { T2MessageService } from "src/app/core/t2-message.service";
import { FormatWidth, getLocaleDateFormat } from "@angular/common";


@Component({
  selector: 'app-oee',
  templateUrl: './oee.component.html',
  styleUrls: ['./oee.component.scss']
})
export class OeeComponent implements OnInit {

  @ViewChild("formTemplate", { static: true }) formTemplate: FormTemplateComponent;

  @ViewChild("deviceComponent", { static: true }) deviceComponent: WidgetSelectionComponent;

  @ViewChildren(WidgetPanelComponent) panelList: QueryList<WidgetPanelComponent>;


  public loaded = false;
  dateStartControl = new FormControl();
  dateEndControl = new FormControl();

  dateFormat = "dd/MM/yy";

  loadingOEEData = false;
  echartsIntance: EChartsType;

  panelParams = {
    dataInicio: this.dateStartControl.value,
    dataFim: this.dateEndControl.value,
    id_dispositivo: []
  };

  oeeData: any = {
    startDate: null,
    endDate: null,
    availability: {},
    performance: {},
    quality: {},
    oee: {},
    oeeChart: {},
    opened: true
  };

  themeName = "light";
  format: string;

  constructor(
    themeService: T2ThemingService,
    protected dateService: NbDateService<Date>,
    private httpClient: T2HttpClientService,
    private sec: T2SecurityService,
    private messageService: T2MessageService,
    @Inject(LOCALE_ID) private locale) {
    themeService.getThemeNameObservable().subscribe(theme => this.themeName = theme);

    let dtFormat = getLocaleDateFormat(locale, FormatWidth.Short);

    if (dtFormat.startsWith("M")) {
      this.format = "MM/dd/yyyy";
    } else {
      this.format = "dd/MM/yyyy";
    }
  }

  ngOnInit(): void {

    this.sec.accessLoaded()
      .pipe(
        take(1)
      ).subscribe(() => {
        if (this.formTemplate.validateAccess(['zD20200611H143408380R000000022'], 'dashboard')) {
          this.deviceComponent.widget = { id_techprodWidget: '2', positionX: 0, positionY: 0, id_techprodWidget_panel: null, widgetType: null };

          this.loaded = true;
        }
      });
  }

  public loadOEEData() {

    if (this.panelParams.dataInicio != this.dateStartControl.value || this.panelParams.dataFim != this.dateEndControl.value) {
      this.loadingOEEData = true;

      this.panelParams.dataInicio = this.dateStartControl.value;
      this.panelParams.dataFim = this.dateEndControl.value;

      const queryParams = new Map<string, string>();
      queryParams.set("startDate", this.panelParams.dataInicio);
      queryParams.set("endDate", this.panelParams.dataFim);
      this.httpClient.get(`production.oee/oee`, queryParams)
        .pipe(
          take(1),
          map((resp: any) => resp.message),
          filter((resp: any) => {
            try {
              const oee = JSON.parse(resp);
              return Object.keys(oee).indexOf("oee") >= 0;
            } catch (error) {
              return false;
            }
          }))
        .subscribe((oee: any) => {
          this.loadingOEEData = false;
          this.oeeData = JSON.parse(oee);
          console.log(this.oeeData);

          if (this.oeeData.children?.length == 1) {
            this.oeeData.children[0].opened = true;
          }
        });
    }

    this.loadPanels();

    this.loaded = true;
  }

  private loadPanels() {
    this.panelList?.forEach((panel: WidgetPanelComponent) => panel.params = this.panelParams);
  }

  availabilityTooltip(avail: any) {

    if (avail.availTime && avail.prodTime) {
      return `${avail.availTime.toFixed(2)}h disp, ${avail.prodTime.toFixed(2)}h real`;
    } else if (avail.availTime) {
      return ` ${avail.availTime.toFixed(2)}h disp, - h real`;
    } else if (avail.prodTime) {
      return `- h disp, ${avail.prodTime.toFixed(2)}h real`;
    }

    return ` - h disp, - h real`;
  }

  performanceTooltip(perf: any) {
    if (perf.estimatedSpeed && perf.averageSpeed) {
      return `vel plan ${perf?.estimatedSpeed.toFixed(2)}, vel média ${perf?.averageSpeed.toFixed(2)}`;
    } else if (perf.estimatedSpeed) {
      return `vel plan ${perf?.estimatedSpeed.toFixed(2)}, vel média -`;
    } else if (perf.averageSpeed) {
      return `vel plan - , vel média ${perf?.averageSpeed.toFixed(2)}`;
    }

    return `vel plan - , vel média - `;
  }

  qualityTooltip(quality: any) {
    if (quality.prod && quality.waste) {
      return `${quality.prod.toFixed(2)} bons, ${quality.waste.toFixed(2)} perda`;
    } else if (quality.prod) {
      return `${quality.prod.toFixed(2)} bons, - perda`;
    } else if (quality.waste) {
      return ` - bons, ${quality.waste.toFixed(2)} perda`;
    }

    return ` - bons, - perda`;
  }

  deviceListChange(event: any) {
    if (event.data) {
      if (!Array.isArray(event.data)) {
        event.data = [event.data];
      }

      if (event.data.length > 0) {
        let selectedGroups = this.deviceComponent.selectionOptions.filter(g => g.options
          .some(d => this.deviceComponent.selectionField.value.some(v => v.id == d.id))).map(g => g.id_group);

        if (selectedGroups.length > 1) {
          this.messageService.showToastError("Não é permitido selecionar dispositivos de grupos diferentes !!");

          this.panelParams.id_dispositivo = [];
          this.deviceComponent.selectionField.setValue([]);
        }
      }

      this.panelParams.id_dispositivo = [];

      event.data.forEach(item => this.panelParams.id_dispositivo.push(item.id));
    }
  }


  changeContent() {

  }

  onTabChange(event) {
    this.panelList.forEach((panel: WidgetPanelComponent) => panel.dataTableAutoSizeColumns());
  }

}
