import { Component, EventEmitter, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import { DeviceCache, DeviceCacheTask } from "../device-cache.service";
import { ActivatedRoute, Router } from "@angular/router";
import { DeviceTaskPlan, ShopFloorService, TaskPlanSelection } from "../shop-floor.service";
import { take, takeUntil } from "rxjs/Operators";
import { ShopFloorSingletaskPanelComponent } from "../shop-floor-dashboard/shop-floor-singletask-panel/shop-floor-singletask-panel.component";
import { SearchProdOrderComponent } from "../shop-floor-appointment/search-prod-order/search-prod-order.component";
import { NbDialogService } from "@nebular/theme";
import { T2MessageService } from "src/app/core/t2-message.service";
import { T2UserDeviceService } from "src/app/t2-user-device.service";
import { Subject } from "rxjs";

@Component({
  selector: 'app-shop-floor-taskpanel',
  templateUrl: './shop-floor-taskpanel.component.html',
  styleUrls: ['./shop-floor-taskpanel.component.scss']
})
export class ShopFloorTaskpanelComponent implements OnDestroy {
  @ViewChild("appSingleTaskPanel") appSingleTaskPanel: ShopFloorSingletaskPanelComponent;

  @Input() device: DeviceCache;

  @Input()
  get taskPlan(): DeviceCacheTask {
    return this._taskPlan;
  }
  set taskPlan(value: DeviceCacheTask) {
    this._taskPlan = value;// { ...value };
    this.currentTaskPlan = this._taskPlan?.id_taskPlan ? this._taskPlan : undefined;
  }

  @Input()
  get selectedPanel(): PanelType {
    return this._selectedPanel;
  }
  set selectedPanel(value: PanelType) {
    this._selectedPanel = value;
    this.selectedPanelChanged.emit(value);
  }
  @Output() closePanel = new EventEmitter<DeviceCache>();
  @Output() lockScreen = new EventEmitter<boolean>();
  @Output() tpChanged = new EventEmitter<DeviceCacheTask>();
  @Output() selectedPanelChanged = new EventEmitter<PanelType>();

  public smallWindow = false;
  public searchedTaskPlan: DeviceTaskPlan;
  public currentTaskPlan: DeviceCacheTask;
  private _selectedPanel: PanelType = "sequencing";
  private _taskPlan: DeviceCacheTask;
  private unsubscribe = new Subject<void>();

  constructor(private router: Router, private route: ActivatedRoute, private sfService: ShopFloorService,
    private dlgService: NbDialogService, private messageService: T2MessageService, deviceService: T2UserDeviceService) {
    deviceService.getObservableSmallScreen()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(resp => {
        this.smallWindow = resp.matches;
      });

  }
  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete()
  }

  selectPanel(panel: PanelType) {
    this.selectedPanel = panel;

    this.router.navigate(
      [],
      {
        relativeTo: this.route,
        queryParams: { tab: panel || undefined },
        queryParamsHandling: "merge",
      });
  }

  closeTaskPanel(): void {
    this.closePanel.emit(this.device);
  }

  setLockScreen(lock: boolean) {
    this.lockScreen.emit(lock);
  }

  selectTaskPlanSequencing(tp: TaskPlanSelection) {
    this.setLockScreen(true);

    if (tp.taskPlanId == this.taskPlan?.id_taskPlan) {
      if (this.searchedTaskPlan) {
        this.searchedTaskPlan = undefined;
        this.taskPlan = { ...this.taskPlan };
      }

      this.selectPanel("appointment");
      this.setLockScreen(false);
    } else {
      this.sfService.getDeviceTaskPlan(this.device.deviceId, tp.taskPlanId).pipe(take(1)).subscribe(taskPlan => {
        if (!taskPlan) {
          this.messageService.showToastError("OP Inválida ou não está liberada para produção");
          this.setLockScreen(false);
          return;
        }

        this.selectPanel("appointment");

        this.searchedTaskPlan = taskPlan;
        let devTP = new DeviceCacheTask();
        devTP.id_taskPlan = this.searchedTaskPlan.taskPlanId;
        devTP.id_task = this.searchedTaskPlan.taskId;
        devTP.id_ordemProd = this.searchedTaskPlan.productionOrderId;
        devTP.prodOrder = this.searchedTaskPlan.productionOrderCode;
        devTP.prodOrderDescription = this.searchedTaskPlan.productionOrderDescription;
        devTP.description = this.searchedTaskPlan.taskDescription;
        devTP.startDate = this.searchedTaskPlan.startDate;
        devTP.estimatedEndDate = this.searchedTaskPlan.estimatedEndDate;
        devTP.estimatedQty = this.searchedTaskPlan.estimatedQty;

        this.currentTaskPlan = devTP;


        this.setLockScreen(false);
      }, error => {
        this.setLockScreen(false);
      })
    }
  }

  selectTaskPlanSearch(tp: DeviceTaskPlan) {
    this.searchedTaskPlan = tp;

    let devTP = new DeviceCacheTask();
    devTP.id_taskPlan = this.searchedTaskPlan.taskPlanId;
    devTP.id_task = this.searchedTaskPlan.taskId;
    devTP.id_ordemProd = this.searchedTaskPlan.productionOrderId;
    devTP.prodOrder = this.searchedTaskPlan.productionOrderCode;
    devTP.prodOrderDescription = this.searchedTaskPlan.productionOrderDescription;
    devTP.description = this.searchedTaskPlan.taskDescription;
    devTP.startDate = this.searchedTaskPlan.startDate;
    devTP.estimatedEndDate = this.searchedTaskPlan.estimatedEndDate;
    devTP.estimatedQty = this.searchedTaskPlan.estimatedQty;
    devTP.productList = this.searchedTaskPlan.prodOrderCmpList.map(cmp => {
      return {
        description: cmp.description,
        quantity: cmp.quantity,
        unit: cmp.unitSymbol
      }
    });

    this.currentTaskPlan = devTP;

    this.selectPanel("appointment");
  }

  public taskPlanChanged(tp: DeviceCacheTask) {
    this.searchedTaskPlan = undefined;
    this.taskPlan = tp;

    if (!this.taskPlan?.id_taskPlan) {
      this.currentTaskPlan = undefined;
      this.appSingleTaskPanel.currentTaskPlan = undefined;
    }

    this.tpChanged.emit(tp);
  }

  btnSearchClick() {
    let dlg = this.dlgService.open(SearchProdOrderComponent, {
      context: {
        deviceId: this.device.deviceId
      }
    });

    dlg.onClose.pipe(take(1)).subscribe(tp => {
      if (tp) {
        this.selectTaskPlanSearch(tp);
      }
    })
  }

  goBackToDocument(justifyOperationFinished: boolean) {
    if (justifyOperationFinished) this.selectPanel("document");
  }
}

export type PanelType = "sequencing" | "appointment" | "document";


