import { ViewTemplateComponent } from './../view-template/view-template.component';
import { takeUntil } from 'rxjs/Operators';
import { T2TextAreaComponent } from './../ui/t2-text-area/t2-text-area.component';
import { T2SelectComponent } from './../ui/t2-select/t2-select.component';
import { take } from 'rxjs/Operators';
import { Component, Input, OnInit, ViewChild, OnDestroy, AfterViewInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NbDialogService } from "@nebular/theme";
import { T2HttpClientService } from "../../http/t2httpClient.service";
import { T2SecurityService } from "../../security/t2security.service";
import { T2MessageService } from "../../t2-message.service";
import { DialogTemplateComponent } from "../dialog-template/dialog-template.component";
import { T2ViewTemplateAction } from "../view-template/model/t2-view-template-action";
import { T2ViewTemplateData } from "../view-template/model/t2-view-template-data";
import { T2ViewTemplateFlow } from "../view-template/model/t2-view-template-flow";
import { LayoutType, ViewTemplateElement } from "../view-template/model/view-template-element";
import { T2InputTextComponent } from "../ui/t2-input-text/t2-input-text.component";
import { Subject } from "rxjs";

@Component({
  selector: 'app-dialog-event-nf',
  templateUrl: './dialog-event-nf.component.html',
  styleUrls: ['./dialog-event-nf.component.scss']
})
export class DialogEventNFComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('dialog', { static: true }) dialog: DialogTemplateComponent;
  @ViewChild('viewtemplate', {static: true}) viewTemplate : ViewTemplateComponent;

  @Input() id: string;
  @Input() id_notaFiscal: string;

  private unsubscribe = new Subject<void>();

  formGroup: FormGroup;
  warningMessage: string;
  maxCharacters: number = 1000;
  usedCharacters: number = 0;
  minCharacters: number = 15;
  invoiceStatusEmi: string;
  isEventTypeOK: boolean = false;
  isEventMessageSizeOK: boolean = false;
  saving: boolean = false;

  layout: Array<ViewTemplateElement> = [
    {
      layoutType: LayoutType.listLayout,
      direction: "column",
      children: [
        {
          layoutType: LayoutType.listLayout,
          children: [
            {
              layoutType: LayoutType.component,
              title: "Nota Fiscal",
              cmpName: "numeroNF",
              cmpType: T2InputTextComponent,
              isBaseComponent: true,
            },
            {
              layoutType: LayoutType.component,
              title: "Status",
              cmpName: "statusNF",
              cmpType: T2InputTextComponent,
              isBaseComponent: true,
            },
          ]
        },
        {
          layoutType: LayoutType.component,
          cmpName: "tipoEvento",
          title: "Tipo de Evento",
          cmpType: T2SelectComponent,
          isBaseComponent: true,
        },
        {
          layoutType: LayoutType.component,
          cmpName: "descricaoEvento",
          title: "Descrição do evento",
          cmpType: T2TextAreaComponent,
          isBaseComponent: true,
        },
      ]
    }];

  data: Array<T2ViewTemplateData> = [
    {
      cmpName: "tipoEvento",
      properties: {
        list: [
          { id: "cartaCorrecao", description: "Carta de correção" },
          { id: "inutilizacao", description: "Inutilização" },
          { id: "cancelamento", description: "Cancelamento" },
          { id: "descartDoc", description: "Descartar Documento" }
        ]
      }
    },
    { cmpName: "numeroNF", properties: { readOnly: true } },
    { cmpName: "statusNF", properties: { readOnly: true } },
  ];
  actions: Array<T2ViewTemplateAction> = [];

  flows: Array<T2ViewTemplateFlow> = [];

  constructor(private formBuilder: FormBuilder, private messageService: T2MessageService, public sec: T2SecurityService, public httpService: T2HttpClientService, private dialogService: NbDialogService) { }

  ngOnInit(): void {
    this.loadEventNFWindow();
    this.formGroup = this.formBuilder.group({});
    this.formGroup.addControl('numeroNF', this.formBuilder.control(undefined));
    this.formGroup.addControl('statusNF', this.formBuilder.control(undefined));
    this.formGroup.addControl('tipoEvento', this.formBuilder.control(this.data[0].properties['list'][0].id, Validators.required));
    this.formGroup.addControl('descricaoEvento', this.formBuilder.control(undefined));

    this.formGroup.controls['descricaoEvento'].valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe(qntCaracteres => {
      this.usedCharacters = qntCaracteres.length;
      this.setSizeValueDefault();
      this.validateEventMessageSize();
    });

    this.formGroup.controls['tipoEvento'].valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe(tipoEvento => {
      this.setSizeValueDefault();
      this.validateEventType();
    })

  }

  ngAfterViewInit(): void {
    this.setSizeValueDefault();
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
  }

  loadEventNFWindow() {
    let params = new Map<string, string>();
    params.set("id_notaFiscal", this.id || this.id_notaFiscal);
    this.httpService.get("fiscal.invoice.utils/getInvoiceInfo", params).pipe(take(1)).subscribe(invoiceInfo => {
      if (!invoiceInfo) return;
      this.formGroup.controls['numeroNF'].setValue(invoiceInfo.invoiceInfo.invoiceNumber);
      this.formGroup.controls['statusNF'].setValue(invoiceInfo.invoiceInfo.invoiceStatus);
      this.invoiceStatusEmi = invoiceInfo.invoiceInfo.invoiceStatusEmi;
      this.validateEventType();
    });
  }

  closeDialog(action: string) {
    if (action == "Confirma") {
      if (this.formGroup.invalid) {
        this.messageService.showToastError("As informações são inválidas");
        return;
      }
      this.executeInvoiceEvent(action);
    }
    else {
      this.dialog.close(undefined);
    }

  }

  private validateEventType(): void {

    this.viewTemplate.setCmpInputValue("descricaoEvento", "visible", true);

    //Validar status emitida para tipo correção e cancelamento
    if ((this.formGroup.controls['tipoEvento'].value == "cartaCorrecao" ||
      this.formGroup.controls['tipoEvento'].value == "cancelamento") &&
      (this.formGroup.controls['statusNF'].value != this.invoiceStatusEmi)) {
      this.warningMessage = "Nota fiscal deve estar emitida";
      this.isEventTypeOK = false;
      return;
    }

    //validar status em elaboração para tipo inutilização
    if (this.formGroup.controls['tipoEvento'].value == "inutilizacao" &&
      ((this.formGroup.controls['statusNF'].value != "Em Elaboração") && (this.formGroup.controls['statusNF'].value != "Rejeitada"))) {
      this.warningMessage = "Nota fiscal deve estar no status 'Em Elaboração' ou 'Rejeitada'";
      this.isEventTypeOK = false;
      return;
    }

    //validar status aguardando emissão e tipo descartar documento
    if (this.formGroup.controls['tipoEvento'].value == "descartDoc") {
      this.viewTemplate.setCmpInputValue("descricaoEvento", "visible", false);
      if (this.formGroup.controls['statusNF'].value != "Aguardando Emissão") {
        this.warningMessage = "Nota fiscal deve estar no status 'Aguardando Emissão'";
        this.isEventTypeOK = false;
        return;
      }
    }

    this.warningMessage = "";
    this.isEventTypeOK = true;
  }

  private validateEventMessageSize(): void {

    if (this.usedCharacters <= this.maxCharacters && this.usedCharacters >= this.minCharacters) {
      this.isEventMessageSizeOK = true;
      return;
    }

    if (this.usedCharacters > this.maxCharacters || this.usedCharacters < this.minCharacters) {
      this.isEventMessageSizeOK = false;
      return;
    }
  }

  private setSizeValueDefault() {
    if (this.formGroup.controls['tipoEvento'].value == "cartaCorrecao") {
      this.minCharacters = 15;
      this.maxCharacters = 1000;
    }

    if (this.formGroup.controls['tipoEvento'].value == "cancelamento" || this.formGroup.controls['tipoEvento'].value == "inutilizacao") {
      this.minCharacters = 15;
      this.maxCharacters = 255;
    }

    if (this.formGroup.controls['tipoEvento'].value == "descartDoc") {
      this.maxCharacters = 0;
      this.minCharacters = 0;
      this.usedCharacters = 0;
    }
  }

  executeInvoiceEvent(action) {
    let params = new Map<string, string>();
    params.set("id_notaFiscal", this.id || this.id_notaFiscal);
    params.set("description", this.formGroup.controls['descricaoEvento'].value);

    if (this.formGroup.controls['tipoEvento'].value == "cartaCorrecao") {
      params.set("type", "CARTA DE CORREÇÃO");
    }

    if (this.formGroup.controls['tipoEvento'].value == "descartDoc") {
      params.set("type", "DESCARTA");
    }

    if (this.formGroup.controls['tipoEvento'].value == "inutilizacao") {
      params.set("type", "INUTILIZAÇÃO");
    }

    if (this.formGroup.controls['tipoEvento'].value == "cancelamento") {
      params.set("type", "CANCELAMENTO");
    }

    this.saving = true;

    this.httpService.get("fiscal.invoice.utils/executeInvoiceEvent", params).pipe(take(1)).subscribe(resp => {
      if (!resp) return;
      this.saving = false;
      this.dialog.close(action);
    }, error => {
      this.saving = false;
    });
  }
}
