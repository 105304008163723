import { Component, OnDestroy, OnInit } from '@angular/core';
import { ITooltipAngularComp } from "ag-grid-angular";
import { ITooltipParams, IAfterGuiAttachedParams } from "ag-grid-community";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/Operators";
import { T2ThemingService } from "src/app/core/t2theming.service";

@Component({
  selector: 'app-sequence-tooltip',
  templateUrl: './sequence-tooltip.component.html',
  styleUrls: ['./sequence-tooltip.component.scss']
})
export class SequenceTooltipComponent implements ITooltipAngularComp, OnDestroy {

  dark = false;
  id_device: string;
  id_task_plan: string;
  columnName: string;
  id_ordemprod: string;
  id_task: string;
  private unsubscribe = new Subject<void>();

  constructor(private themeService: T2ThemingService) {
    themeService.getThemeNameObservable()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(theme => {
        this.dark = theme == "dark";
      });

  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  agInit(params: ITooltipParams): void {

    this.id_device = params.data.id_device;
    this.id_task_plan = params.data.id_task_plan;
    this.id_ordemprod = params.data.id_ordemProd;
    this.id_task = params.data.id_task;
    this.columnName = params.colDef['field'];
  }

}
