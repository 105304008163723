import { Injectable } from '@angular/core';
import { T2HttpClientService } from "../http/t2httpClient.service";
import { Observable, of } from "rxjs";
import { take, pluck, map } from "rxjs/Operators";
import { Dataset } from "./model/dataset";
import { DatasetProperty } from "./model/datasetProperty";
import { FormGroup } from "@angular/forms";
import { T2SimpleEntity } from "../security/model/t2SimpleEntity";
import { T2SimpleEntityProp } from "../security/model/t2SimpleEntityProp";
import { DatasetPropertyStatusItem } from "./model/dataset-property-status-item";
import { DatasetPropertyStatusFlow } from "./model/dataset-property-status-flow";
import { ColorService } from "../color.service";
import { DatasetPropertyFixedComboItem } from "./model/dataset-property-fixed-combo-item";
import { T2LocalStorageService } from "../t2local-storage.service";
import { DatasetEntityComposition } from "./model/dataset-entity-composition";

@Injectable({
  providedIn: 'root'
})
export class T2DatasetService {

  constructor(private httpClient: T2HttpClientService, private colorService: ColorService, private storageService: T2LocalStorageService) { }

  getDataset(datasetName: string): Observable<Dataset> {
    return this.httpClient.get(`core.dataset/datasetxByName/${datasetName}`, null)
      .pipe(take(1))
      .pipe(
        pluck("dataset"),
        map((dataset: any) => {
          const ds = new Dataset();
          ds.id_dataset = dataset.id_dataset;
          ds.datasetName = dataset.datasetName;
          ds.description = dataset.description;
          ds.parentName = dataset.parentName;
          ds.propertyList = new Array<DatasetProperty>();
          ds.allowApprovalPolicy = dataset.allowApprovalPolicy;

          dataset.completePropertyList.forEach((prop: any) => {
            if (prop != null) {
              const dsp = new DatasetProperty();
              ds.propertyList.push(dsp);

              dsp.id_dataset_property = prop.id_dataset_property;
              dsp.id_dataset = dataset.id_dataset;
              dsp.propertyName = prop.propertyName;
              dsp.propertyLabel = prop.propertyLabel;
              dsp.contentType = prop.contentType;

              dsp.sortkey = prop.sortKey;
              dsp.required = prop.required;
              dsp.requiredForPublication = prop.requiredForPublication;
              dsp.defaultValue = prop.defaultValue;

              dsp.code = prop.code;
              dsp.descriptive = prop.descriptive;
              dsp.primaryKey = prop.primaryKey;
              dsp.statusCtrl = prop.statusCtrl;
              dsp.deletionCtrl = prop.deletionCtrl;
              dsp.inactivityCtrl = prop.inactivityCtrl;
              dsp.publicationCtrl = prop.publicationCtrl;
              dsp.companySiteCtrl = prop.companySiteCtrl;

              if (prop.statusList && prop.statusList.length) {
                dsp.statusCtrl = true;
                dsp.statusList = new Array<DatasetPropertyStatusItem>();
                prop.statusList?.forEach(status => {
                  const dsps = new DatasetPropertyStatusItem();
                  dsp.statusList.push(dsps);

                  dsps.description = status.description;
                  dsps.endsInformation = status.endsInformation;
                  dsps.execDescription = status.execDescription;
                  dsps.helpText = status.helpText;
                  dsps.sortKey = status.sortKey;
                  dsps.statusColor = status.statusColor;
                  dsps.statusColorStyle = this.colorService.getClass(status.statusColor);
                });
              }

              if (prop.fixedComboList) {
                if (!Array.isArray(prop.fixedComboList)) {
                  prop.fixedComboList = [prop.fixedComboList];
                }

                dsp.fixedComboList = new Array<DatasetPropertyFixedComboItem>();
                prop.fixedComboList.forEach(item => {
                  let fcItem = new DatasetPropertyFixedComboItem();
                  fcItem.description = item.description;
                  fcItem.sortKey = item.sortKey;

                  dsp.fixedComboList.push(fcItem);
                })
              }
            }
          });

          return ds;
        }),
        take(1));
  }

  private saveRecord(datasetName: string, se: T2SimpleEntity): Observable<any> {
    if (se.propList.prop.length > 0) {
      let params = new Map<string, string>();
      params.set("simpleEntity", "true");

      let obj = { datasetEntityInfo: se };

      return this.httpClient.post("core.dynDataset/datasetEntity/" + datasetName, params, obj).pipe(take(1));
    } else {
      return of({ datasetEntityID: se["@datasetEntityID"] });
    }
  }

  saveSimpleEntity(datasetName: string, informationId: string, properties: Map<string, any>): Observable<any> {
    let se = new T2SimpleEntity();
    se["@datasetName"] = datasetName;
    se["@datasetEntityID"] = informationId;

    Array.from(properties.keys()).forEach(key => {
      let seProp = new T2SimpleEntityProp();
      seProp["@propName"] = key;
      seProp.propValue = properties.get(key);

      if (typeof seProp.propValue == "string" && (seProp.propValue.indexOf("&") > -1 || seProp.propValue.indexOf("%") > -1)) {
        seProp.propValue = encodeURIComponent(seProp.propValue);
      }

      se.propList.prop.push(seProp);
    });

    return this.saveRecord(datasetName, se);
  }

  saveFormGroupEntity(datasetName: string, informationId: string, formGroup: FormGroup): Observable<any> {
    let se = new T2SimpleEntity();
    se["@datasetName"] = datasetName;
    se["@datasetEntityID"] = informationId;

    Object.keys(formGroup.controls).forEach(key => {
      if (formGroup.controls[key].dirty) {
        let seProp = new T2SimpleEntityProp();
        seProp["@propName"] = key;
        seProp.propValue = formGroup.controls[key].value;

        if (typeof seProp.propValue == "string" && (seProp.propValue.indexOf("&") > -1 || seProp.propValue.indexOf("%") > -1)) {
          seProp.propValue = encodeURIComponent(seProp.propValue);
        }

        se.propList.prop.push(seProp);
      }
    });

    return this.saveRecord(datasetName, se);
  }

  saveObject(datasetName: string, informationId: string, obj: Object): Observable<any> {
    let se = new T2SimpleEntity();
    se["@datasetName"] = datasetName;
    se["@datasetEntityID"] = informationId;

    Object.keys(obj).forEach(key => {
      let seProp = new T2SimpleEntityProp();
      seProp["@propName"] = key;
      seProp.propValue = obj[key];
      se.propList.prop.push(seProp);
    });

    return this.saveRecord(datasetName, se);
  }

  deleteDatasetRecord(datasetName: string, informationId: string): Observable<any> {
    return this.httpClient.delete(`core.dynDataset/datasetEntity/${datasetName}/${informationId}`, null).pipe(take(1));
  }

  getStatusList(datasetName: string): Observable<Array<DatasetPropertyStatusItem>> {
    let sList: Array<DatasetPropertyStatusItem> = JSON.parse(this.storageService.getData(`#${datasetName}_statusList`));

    if (sList) {
      return of(sList);
    } else {
      return this.httpClient.get(`core.dataset/dataset/${datasetName}/statusList`, null)
        .pipe(
          map((resp: any) => {
            let statusList = resp.statusList;

            if (!Array.isArray(statusList)) {
              statusList = [statusList];
            }

            statusList?.forEach((status: DatasetPropertyStatusItem) => {
              status.statusColorStyle = this.colorService.getClass(status.statusColor);
            });

            this.storageService.setData(`#${datasetName}_statusList`, JSON.stringify(statusList));
            return statusList;
          }),
          take(1)
        )
    }
  }

  getFixedComboList(datasetName: string, propertyName): Observable<Array<DatasetPropertyFixedComboItem>> {
    let sList: Array<DatasetPropertyFixedComboItem> = JSON.parse(this.storageService.getData(`#${datasetName}${propertyName}_fixedComboList`));

    if (sList) {
      return of(sList);
    } else {
      return this.httpClient.get(`core.dataset/dataset/${datasetName}/${propertyName}/fixedComboList`, null)
        .pipe(
          map((resp: any) => {
            let fcList: Array<DatasetPropertyFixedComboItem> = resp.fixedComboList;

            if (!Array.isArray(fcList)) {
              fcList = [fcList];
            }

            fcList = fcList.map(fc => {
              return { sortKey: fc.sortKey, description: fc.description, helpText: fc.helpText };
            });

            this.storageService.setData(`#${datasetName}${propertyName}_fixedComboList`, JSON.stringify(fcList));
            return fcList;
          }),
          take(1)
        )
    }
  }

  changeDatasetPropertyValue(datasetName: string, informationId: string, propertyName: string, value: any) {
    return this.httpClient.post(`core.dynDataset/datasetEntity/${datasetName}/${informationId}/${propertyName}`, null, value).pipe(take(1));
  }

  getNextStatus(datasetName: string, fromStatus: string): Observable<any> {
    let params = new Map<string, string>();
    params.set("fromStatus", fromStatus);

    return this.httpClient.get(`core.dataset/dataset/${datasetName}/nextStatus`, params).pipe(
      map(resp => {
        if (resp && resp.status) {
          if (!Array.isArray(resp.status)) {
            resp.status = [resp.status];
          }
        }

        return resp?.status;
      }),
      take(1)
    );
  }

  getNextStatusFlow(datasetName: string, fromStatus: string): Observable<Array<DatasetPropertyStatusFlow>> {
    let params = new Map<string, string>();
    params.set("fromStatus", fromStatus);

    return this.httpClient.get(`core.dataset/dataset/${datasetName}/nextStatusFlow`, params).pipe(
      map(resp => {
        if (resp && resp.flowList) {
          if (!Array.isArray(resp.flowList)) {
            resp.flowList = [resp.flowList];
          }
        }

        return resp?.flowList;
      }),
      take(1)
    );
  }

  getAggPropertyDescription(datasetName: string, informationId: string, propertyName: string) {
    let params = new Map<string, string>()
    params.set("datasetName", datasetName);
    params.set("id", informationId);
    params.set("propertyName", propertyName);

    return this.httpClient.get("core.dynDataset/datasetEntityAggregationPropertyDescription", params).pipe(take(1));
  }

  getPropertyValue(datasetName: string, informationId: string, propertyName: string): Observable<string> {
    return this.httpClient.get(`core.dynDataset/datasetEntity/${datasetName}/${informationId}/${propertyName}`, null)
      .pipe(
        take(1),
        map(resp => {
          return resp?.propertyValue?.$
        })
      );
  }

  getBlockChanges(datasetName: string, informationId: string): Observable<{ blockChanges: boolean }> {
    let params = new Map<string, string>();
    params.set("datasetName", datasetName);
    params.set("id_recordOfDataset", informationId);

    return this.httpClient.get("core.dynDataset/getBlockChange", params)
      .pipe(
        take(1),
        map(resp => {
          return {
            blockChanges: resp?.blockChange
          }
        }));
  }

  updateRecordsSequence(datasetName: string, parentId: string, propertySeqName: string, sortSequenceList: Array<{ id_recordOfDataset: string, value: number }>): Observable<Array<{ id_recordOfDataset: string, value: number }>> {
    let params = new Map<string, string>();
    params.set("propertyName", propertySeqName);
    params.set("parentId", parentId);

    return this.httpClient.post(`core.dynDataset/datasetEntity/${datasetName}/updateSortSequence`, params, sortSequenceList)
      .pipe(take(1),
        map(resp => {
          let sortList = new Array<{ id_recordOfDataset: string, value: number }>();

          if (resp && resp.recordList) {
            if (!Array.isArray(resp.recordList)) {
              resp.recordList = [resp.recordList];
            }

            sortList = resp.recordList;
          }

          return sortList;
        }))
  }

  getAutoformId(datasetName: string): Observable<string> {
    return this.httpClient.get(`core.dynAutoform/getAutoformId/${datasetName}`, null).pipe(take(1), map(resp => {
      return resp.autoformId;
    }))
  }

  changeRelatedCtrl(datasetName: string, type: "policy", activeStatus: boolean): Observable<any> {
    let params = new Map<string, string>();
    params.set("type", type);
    params.set("activeStatus", activeStatus.toString());

    return this.httpClient.get(`core.dataset/dataset/${datasetName}/changeRelatedCtrl`, params).pipe(take(1));
  }

  getDatasetEntityComposition(datasetName: string, informationId: string, compositionDatasetName?: string): Observable<Array<DatasetEntityComposition>> {
    let params = new Map();

    if (compositionDatasetName) {
      params.set("compositionDatasetName", compositionDatasetName);
    }

    return this.httpClient.get(`core.dynDataset/datasetEntity/${datasetName}/${informationId}/compositionList`, params).pipe(take(1), map(resp => {
      let compList: Array<DatasetEntityComposition>;
      compList = resp?.compositionList;

      if (compList) {
        if (!Array.isArray(compList)) {
          compList = [compList];
        }

        compList.forEach((comp: DatasetEntityComposition) => {
          comp.recordCount = Number(comp.recordCount).valueOf();

          if (comp.ID && !Array.isArray(comp.ID)) {
            comp.ID = [comp.ID];
          }
        })
      } else {
        compList = [];
      }

      return compList
    }))
  }
}
