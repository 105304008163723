<div class="flexColumn flexGap5" [nbSpinner]="loading" nbSpinnerSize="giant" nbSpinnerStatus="primary">

  <div *ngFor="let color of colorList" class="T2GrayArea" style="padding: 5px;">

    <div class="flexAlignRowSpaceBetweenCenter">

      <div class="flexAlignColumnCenterStart" style="line-height: 0.9rem;">
        <div class="defaultTitle">{{color.color}}</div>
        <div class="defaultText">{{color.detail}}</div>
      </div>

      <div class="flexColumn flexGap5"  style="line-height: 0.85rem;">

        <div *ngFor="let cylinder of color.cylinderList" class="flexColumn"
          [ngClass]="{cilRed: cylinder.status == 'GRAVAR' ||  cylinder.status == 'RECROMO',
                      cilOrange: cylinder.status == 'REVISAR',
                      cilGreen: cylinder.status == 'OK' || cylinder.engraved}">

          <!-- status do cilindro -->
          <div class="flexAlignRowEndCenter flexGap5">
            <div class="defaultTinyTitle">{{cylinder.engraved ? "GRAVADO" : cylinder.status}} </div>
            <div class="defaultSmallTitle">{{cylinder.cylinderCode}}</div>
          </div>

          <!-- status da programação -->
          <div *ngIf="cylinder.planDate" class="flexAlignRowEndCenter flexGap5">
            <div class="defaultTinyTitle">OP {{cylinder.prodOrderCode}}</div>
            <div class="defaultTinyTitle">{{cylinder.planDate | date:'shortDate'}}</div>
          </div>

        </div>

        <!-- Se não há cilindro para a cor -->
        <div *ngIf="!color.cylinderList?.length" class="cilGray">
          <div class="defaultSmallTitle">Sem Cilindro</div>
        </div>

      </div>
    </div>

  </div>

</div>
