import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-summary-info',
  templateUrl: './summary-info.component.html',
  styleUrls: ['./summary-info.component.scss']
})
export class SummaryInfoComponent implements OnInit {
  @Input() topText: string;
  @Input() middleText: string;
  @Input() bottomText: string;
  @Input() width: string;
  @Input() height: string;
  @Input() fontSize: string;
  @Input() id: string;
  @Output() clicked = new EventEmitter<any>();


  constructor() { }

  ngOnInit(): void {
  }

  onClick() {
    this.clicked.emit(this.id);
  }


}
