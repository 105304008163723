<div class="fillContent" [ngClass]="{'inDialog': inDialog}">
  <app-form-template #formTemplate formTitle="Operação de Estoque" [loaded]="loaded" [changeTitle]="!inDialog"
    [nbSpinner]="lockScreen" nbSpinnerStatus="primary" nbSpinnerSize="giant" class="fillContent"
    [ngClass]="{'inDialog': inDialog}">
    <content class="flexColumn fillContent flexGap10">
      <div style="display: flex; gap: 10px">
        <button nbButton size="tiny" [status]="operationType == 'Entrada' ? 'primary' : 'basic'"
          (click)="selectOperationType('Entrada')"
          [ngStyle]="{'pointer-events': disabledChanges ? 'none' : 'auto'}">Entrada</button>
        <button nbButton size="tiny" [status]="operationType == 'Saida' ? 'primary' : 'basic'"
          (click)="selectOperationType('Saida')"
          [ngStyle]="{'pointer-events': disabledChanges ? 'none' : 'auto'}">Saída</button>
        <button nbButton size="tiny" [status]="operationType == 'Transferencia' ? 'primary' : 'basic'"
          (click)="selectOperationType('Transferencia')"
          [ngStyle]="{'pointer-events': disabledChanges ? 'none' : 'auto'}">Transferência</button>
      </div>
      <!-- REMOVER FXLAYOUT -->
      <div fxLayoutAlign="start center" gdColumns="repeat(auto-fill, minmax(75px, 1fr))" gdGap="10px">
        <div gdColumn="4 span">
          <span>Operação</span>
          <app-t2-select t2IdCmp="stockOperationType" [list]="operationList" [formGroup]="formGroup"
            [readOnly]="disabledChanges" [grouped]="false">
          </app-t2-select>
        </div>
        <div gdColumn="3 span">
          <span>Referência</span>
          <app-t2-input-text t2IdCmp="reference" *ngIf="referenceType == 'Avulso'" [formGroup]="formGroup"
            [readOnly]="disabledChanges">
          </app-t2-input-text>
          <app-t2-aggregation t2IdCmp="reference" *ngIf="referenceType != 'Avulso'" [formGroup]="formGroup"
            [readOnly]="disabledChanges" [datasetId]="referenceDatasetId" [datasetName]="referenceDatasetName"
            [aggregationDatasetDescription]="undefined" [description]="undefined">
          </app-t2-aggregation>
        </div>
      </div>
      <div class="flexColumn flexGap5" style="height: 100%;">
        <div class="flexColumn flexGap5" style="height: 100%;">
          <span>Itens</span>
          <app-t2grid #gridItens style="height: 100%;" [loading]="loadingDataItem" [rowSelection]="'single'"
            [suppressRowClickSelection]="false" (selectionChanged)="gridItensSelectionChanged()"></app-t2grid>
        </div>
        <div class="flexColumn flexGap5" style="height: 100%;">
          <span>Volumes</span>
          <app-t2grid #gridVolumes style="height: 100%;" [loading]="loadingDataVolume"></app-t2grid>
          <div style="display: flex; justify-content: flex-end; align-items: center;">
            <button nbButton size="tiny" status="primary" (click)="execStockOperation()">Realizar Operação</button>
          </div>
        </div>
      </div>
    </content>
  </app-form-template>
</div>
