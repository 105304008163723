import { AfterContentInit, Component, Input, ViewChild } from '@angular/core';
import { DeviceTaskPlan, ShopFloorService } from "../../shop-floor.service";
import { FormBuilder, FormGroup } from "@angular/forms";
import { T2FirebaseService } from "src/app/core/t2-firebase.service";
import { take } from "rxjs/Operators";
import { T2UserDeviceService } from "src/app/t2-user-device.service";
import { DialogTemplateComponent } from "src/app/core/cmp/dialog-template/dialog-template.component";
import { T2InputTextComponent } from "src/app/core/cmp/ui/t2-input-text/t2-input-text.component";

@Component({
  selector: 'app-search-prod-order',
  templateUrl: './search-prod-order.component.html',
  styleUrls: ['./search-prod-order.component.scss'],
  providers: [ShopFloorService, T2FirebaseService]
})
export class SearchProdOrderComponent implements AfterContentInit {
  @ViewChild('dialog', { static: true }) dialog: DialogTemplateComponent;
  @ViewChild("searchInput") searchInput: T2InputTextComponent;
  @Input() deviceId: string;

  public taskPlanList: Array<DeviceTaskPlan>;
  public formGroup: FormGroup;
  public showNoPlanMsg: boolean = false;
  public loading: boolean = false;

  constructor(private sfService: ShopFloorService, private formBuilder: FormBuilder, public userDevService: T2UserDeviceService) {
    this.formGroup = this.formBuilder.group({});
    this.formGroup.addControl("prodOrder", this.formBuilder.control(undefined));
  }

  ngAfterContentInit(): void {
    setTimeout(() => { this.searchInput?.focus(); }, 100);
  }

  public searchPlans() {
    this.showNoPlanMsg = false;
    this.loading = true;
    let prodOrderCode = this.formGroup.controls["prodOrder"].value;

    this.sfService.getTaskPlanList(this.deviceId, prodOrderCode).pipe(take(1)).subscribe(tpList => {
      this.loading = false;
      if (tpList?.length == 1) {
        this.selectPlan(tpList[0]);
      } else {
        this.taskPlanList = tpList;
        this.showNoPlanMsg = !this.taskPlanList.length;
      }
    }, error => {
      this.loading = false;
    })
  }

  public selectPlan(taskPlan: DeviceTaskPlan) {
    this.dialog.close(taskPlan);
  }

  public closeDialog() {
    this.dialog.close(undefined);
  }
}
