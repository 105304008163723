import { AfterViewInit, Component, EventEmitter, OnDestroy, Output, ViewChild } from '@angular/core';
import { BehaviorSubject, from, Subject } from "rxjs";
import { filter, take, takeUntil } from "rxjs/Operators";
import { WorkingTimeCalendar } from "src/app/bsn/prd/ppc/work-schedule/model/working-time-calendar";
import { WorkScheduleService } from "src/app/bsn/prd/ppc/work-schedule/work-schedule.service";
import { T2GanttComponent } from "src/app/core/cmp/t2-gantt/t2-gantt.component";
import { T2MessageService } from "src/app/core/t2-message.service";
import { DataSet } from "vis-data";
import { IdType } from "vis-timeline";
import { ApsService } from "../../../aps.service";
import { TaskPlan } from "../../../model/task-plan";


@Component({
  selector: 'app-aps-gantt-vis',
  templateUrl: './aps-gantt-vis.component.html',
  styleUrls: ['./aps-gantt-vis.component.scss']
})
export class ApsGanttVisComponent implements AfterViewInit, OnDestroy {

  @Output() focused = new EventEmitter<string>();
  @Output() lockScreen = new EventEmitter<boolean>();
  @ViewChild("t2Gantt", { static: true }) t2Gantt: T2GanttComponent;

  private unsubscribe = new Subject<void>();
  private unsubscribeDevice = new Subject<void>();
  private selectedDeviceIds = new Array<string>();
  private deviceList: Array<{
    id_group: string,
    description: string,
    devices: Array<{
      id: string,
      deviceWorkScheduleId?: string,
      description: string
    }>
  }>;
  private workingScheduleList = new Array<{ id: string, loading: boolean, sub$: BehaviorSubject<boolean>, intervals: Array<WorkingTimeCalendar> }>();
  private taskPlanList = new Array<TaskPlan>();

  public showTooltip: boolean = false;
  public tooltipObj = {
    id_device: undefined,
    id_task_plan: undefined,
    id_task: undefined,
    id_ordemProd: undefined,
    posX: 0,
    posY: 0
  }
  public groups = new DataSet<{ id: string, content: string, loading: boolean, deviceWorkScheduleId?: string }>();
  public items = new DataSet<{
    id?: string | number,
    content: string,
    start: Date,
    end: Date,
    group?: string,
    fixedDate?: boolean,
    incompatibilityNextPlan?: number,
    id_device?: string,
    id_task_plan?: string,
    taskPlan?: TaskPlan,
    type: "range" | "background",
    className?: string,
    style?: string
  }>();

  public arrowsArray: Array<{ id: any, id_item_1: any, id_item_2: any, title?: string }> = [];
  public ganttTemplate = (item, element, data): string => {
    return `
    <div>
      <div>${item.content}</div>
    </div>`;
  }

  constructor(public apsService: ApsService, private messageService: T2MessageService, private workScheduleService: WorkScheduleService) { }

  ngAfterViewInit(): void {
    this.apsService.getSimulationSelectedId()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(id => {
        if (id) {
          this.deviceList = this.apsService.getSimulationGroupDevices();
          this.groups.clear();
          this.items.clear();
        }
      });

    this.apsService.programmingVars.ganttDeviceSelectedIdList
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(ids => {
        this.selectedDeviceIds = ids;
        this.loadGroups();
        this.loadGantt();
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
    this.unsubscribeDevice.next();
    this.unsubscribeDevice.complete();

    this.workingScheduleList.forEach(item => item.sub$.complete());
  }

  public onComponentClick() {
    this.focused.emit("gantt");
  }

  private loadGroups() {
    this.groups.clear();
    this.deviceList?.forEach(devGroup => {
      devGroup.devices.filter(d => this.selectedDeviceIds?.includes(d.id)).forEach(dev => {
        this.groups.add({
          id: dev.id,
          deviceWorkScheduleId: dev.deviceWorkScheduleId,
          content: dev.description,
          loading: true
        });
      });
    });


    // Carrega e exibe o horario de trabalho dos dispositivos
    this.groups.forEach(group => {
      if (!group.deviceWorkScheduleId) {
        group.deviceWorkScheduleId = this.apsService.getDeviceWorkScheduleId(group.id);
      }

      this.updateWorkingTime(group.deviceWorkScheduleId, group.id);
    });
  }

  private loadGantt() {
    this.unsubscribeDevice.next();

    if (!this.selectedDeviceIds) return;

    this.taskPlanList = [];
    for (let id of this.selectedDeviceIds) {
      this.apsService.getDeviceData(id)
        .pipe(takeUntil(this.unsubscribeDevice))
        .subscribe(tpList => {
          if (tpList && tpList.length > 0) {
            this.apsService.loadTaskDependency(id).then(() => {
              let result = this.groups.get({ filter: (group) => group.id == id });
              let group;
              if (Array.isArray(result)) {
                group = result[0];
              } else {
                group = result;
              }

              group.loading = false;
            })

            this.taskPlanList = this.taskPlanList.filter(tp => tp.id_device != id);
            this.taskPlanList.push(...tpList);

            this.items.remove(this.items.get({
              fields: ["id"],
              filter: (item) => {
                return item.group == id && item.type == "range" && (typeof item.id == "number") && !tpList.map(p => p.apsId).includes(item.id)
              }
            }));

            const addedItems = [];
            const updatedItems = [];

            tpList.forEach(tp => {
              let result = this.items.get({ filter: (it) => it.id == tp.apsId });
              let plan;

              if (Array.isArray(result)) {
                plan = result[0];
              } else {
                plan = result;
              }

              if (!plan) {
                addedItems.push({
                  id: tp.apsId,
                  content: tp.prodOrder ? tp.prodOrder + " - " + tp.prodOrdDescription : tp.prodOrdDescription,
                  group: tp.id_device,
                  start: tp.startDate,
                  end: tp.endDate,
                  id_device: tp.id_device,
                  id_task_plan: tp.id_task_plan,
                  taskPlan: tp,
                  type: "range"
                });
              } else if (new Date(plan.start).getTime() != tp.startDate.getTime() || new Date(plan.end).getTime() != tp.endDate.getTime() ||
                plan.fixedDate != tp.fixedDate || plan.incompatibilityNextPlan != tp.incompatibilityNextPlan) {
                plan.start = new Date(tp.startDate).toISOString();
                plan.end = new Date(tp.endDate).toISOString();
                plan.fixedDate = tp.fixedDate;
                plan.incompatibilityNextPlan = tp.incompatibilityNextPlan;
                updatedItems.push(plan);
              }
            });

            if (addedItems.length) {
              this.items.add(addedItems);
            }

            if (updatedItems.length) {
              this.items.updateOnly(updatedItems);
            }
          }
        }, error => {
          console.error(error);
          this.messageService.showToastError(error);
        });
    };
  }

  private updateWorkingTime(workScheduleId: string, deviceId: string) {

    let ws = this.workingScheduleList.find(item => item.id == workScheduleId);
    if (!ws) {
      ws = {
        id: workScheduleId,
        intervals: [],
        loading: true,
        sub$: new BehaviorSubject(false)
      };
      this.workingScheduleList.push(ws);

      const minDate = new Date(new Date().setHours(0, 0, 0, 0) - (15 * 24 * 60 * 60 * 1000));
      const maxDate = new Date(new Date().setHours(23, 59, 59, 999) + (120 * 24 * 60 * 60 * 1000));

      this.workScheduleService.getWorkingTimeIntervals(workScheduleId, minDate, maxDate)
        .pipe(take(1))
        .subscribe(wtcList => {
          ws.intervals = wtcList;
          ws.loading = false;
          ws.sub$.next(true);
        });
    }

    ws.sub$.asObservable().pipe(filter(loaded => loaded), take(1)).subscribe(loaded => {

      // Limpa background atual do dispositivo
      this.items.remove(this.items.get({
        fields: ["id"],
        filter: (item) => { return item.group == deviceId && item.type == "background" }
      }));

      // Adiciona intervalos no dispositivo (todos os itens de uma vez, se adicionar um por um fica MUITO lento)
      this.items.add(ws.intervals.map(wtc => {
        return {
          type: "background",
          start: wtc.startDate,
          end: wtc.endDate,
          content: wtc.description,
          group: deviceId,
          className: wtc.color ? wtc.color : wtc.productive ? "t2ColorDarkBlue" : "t2ColorBlack",
          style: "opacity: 0.5"
        };
      }));
    });
  }

  ganttDoubleClick(event) {
    if (event.item) {
      this.arrowsArray.forEach(arrow => {
        this.t2Gantt.removeArrow(arrow.id);
      });

      this.arrowsArray = [];

      let plan = this.taskPlanList.find(tp => tp.apsId == event.item);
      this.setDependency(plan, false);

      let tpIds = new Set<IdType>();
      this.arrowsArray.forEach(arrow => {
        tpIds.add(arrow.id_item_1);
        tpIds.add(arrow.id_item_2);
        this.t2Gantt.addArrow(arrow);
      });

      this.t2Gantt.focus(Array.from(tpIds.values()));
    }
  }

  private setDependency(plan: TaskPlan, reachedLastTask: boolean) {
    if (!reachedLastTask) {
      if (!plan.successorTaskIds || plan.successorTaskIds.length == 0) {
        this.setDependency(plan, true);
      } else {
        plan.successorTaskIds.forEach(id => {
          let tpList: Array<TaskPlan> = this.taskPlanList.filter(p => p.id_task == id);

          if (tpList.length == 0) {
            this.setDependency(plan, true);
          } else {
            tpList.forEach(tp => {
              this.setDependency(tp, false);
            });
          }
        });
      }
    } else {
      if (plan.predecessorTaskIds && plan.predecessorTaskIds.length > 0) {
        plan.predecessorTaskIds.forEach(id => {
          let tpList: Array<TaskPlan> = this.taskPlanList.filter(p => p.id_task == id);
          tpList.forEach(tp => {
            this.arrowsArray.push({ id: this.arrowsArray.length, id_item_1: tp.apsId, id_item_2: plan.apsId });
            this.setDependency(tp, true);
          });
        });
      }
    }
  }

  ganttItemOver(event): void {
    if (event.item) {
      let plan = this.taskPlanList.find(tp => tp.apsId == event.item);

      if (plan) {
        if (this.tooltipObj.id_task_plan != plan.id_task_plan) {
          this.tooltipObj.id_device = plan.id_device;
          this.tooltipObj.id_task_plan = plan.id_task_plan;
          this.tooltipObj.id_task = plan.id_task;
          this.tooltipObj.id_ordemProd = plan.id_ordemProd;
          this.tooltipObj.posX = event.event.x;
          this.tooltipObj.posY = event.event.y;
        }

        this.showTooltip = true;
        return;
      }
    }

    this.showTooltip = false;
  }

}
