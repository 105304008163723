import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Subject } from "rxjs";
import { take, takeUntil } from "rxjs/Operators";
import { FormTemplateComponent } from "src/app/core/cmp/form-template/form-template.component";
import { T2SecurityService } from "src/app/core/security/t2security.service";
import { T2MessageService } from "src/app/core/t2-message.service";
import { ApsSequenceComponent } from "./visualization/sequencing/aps-sequence/aps-sequence.component";
import { ApsService } from "./aps.service";
import { T2FirestoreService } from "src/app/core/t2firestore.service";

@Component({
  selector: 'app-aps',
  templateUrl: './aps.component.html',
  styleUrls: ['./aps.component.scss'],
  providers: [ApsService, T2FirestoreService]
})
export class ApsComponent implements OnInit, OnDestroy {

  @ViewChild("formTemplate", { static: true }) formTemplate: FormTemplateComponent;
  @ViewChild("apsSequence") apsSequence: ApsSequenceComponent;
  @ViewChild('datasetDialog') datasetDialog: TemplateRef<any>;

  public loaded: boolean = false;
  private unsubscribe = new Subject<any>();
  public lockCmp: boolean = false;

  constructor(private securityService: T2SecurityService,
    private messageService: T2MessageService,
    public apsService: ApsService) {
  }
  ngOnDestroy(): void {
    this.unsubscribe.next(true);
    this.unsubscribe.complete();
    this.apsService.ngOnDestroy();
  }

  ngOnInit(): void {
    this.securityService.accessLoaded()
      .pipe(
        take(1)
      ).subscribe(() => {
        if (this.formTemplate.validateAccess(['zD20220203H171937600R000000005'], 'full')) {
          this.apsService.initialize().then(() => {
            this.apsService.loadSimulationList().then(() => {
              this.loaded = true;
            }, error => {
              console.error(error);
              this.messageService.showToastError(error);
            });
          }, error => {
            console.error(error);
            this.messageService.showToastError(error);
          });
        }
      });

    this.securityService.companySiteObservable().pipe(takeUntil(this.unsubscribe)).subscribe(id => {
      this.apsService.setSimulationSelected(undefined);
    })
  }

  public setFixedDate(status: boolean) {
    switch (this.apsService.programmingVars.focusedCmp) {
      case "sequencing": this.apsSequence.setFixedDate(status);
    }
  }

  public recalculate() {
    this.lockCmp = true;
    switch (this.apsService.programmingVars.focusedCmp) {
      case "sequencing": {
        this.apsSequence.recalculate().finally(() => {
          this.lockCmp = false;
        })

        break;
      }

      default: {
        this.messageService.showToast("Essa função não está disponível para a visualização selecionada", "ATENÇÃO", "warning");
        this.lockCmp = false;
        break;
      }
    }
  }

  public timeReservation() {
    switch (this.apsService.programmingVars.focusedCmp) {
      case "sequencing": this.apsSequence.timeReservation();
    }
  }

  public swapDevice() {
    switch (this.apsService.programmingVars.focusedCmp) {
      case "sequencing": this.apsSequence.swapDevice();
    }
  }

  public sortSelectedTasks() {
    switch (this.apsService.programmingVars.focusedCmp) {
      case "sequencing": this.apsSequence.sortSelectedTasks();
    }
  }

  public lockScreen(lock: boolean) {
    this.lockCmp = lock;
  }

}
