import { Component, Input, OnInit } from '@angular/core';
import { EChartsOption, EChartsType } from "echarts";
import { T2ThemingService } from "src/app/core/t2theming.service";

@Component({
  selector: 'app-t2-chart',
  templateUrl: './t2-chart.component.html',
  styleUrls: ['./t2-chart.component.scss']
})
export class T2ChartComponent implements OnInit {

  private _chartOptions: any = {};

  @Input() t2IdCmp: string;
  @Input() loading: boolean = false;
  @Input() changed: boolean = false;
  @Input()
  get chartOptions(): any { return this._chartOptions; };
  set chartOptions(value: any) {
    this._chartOptions = value;
    this.changed = true
  };

  private isLoading = false;

  themeName = "light";
  echartsIntance: EChartsType;
  co: EChartsOption = {
    title: { text: null },
    style: { height: '100%', width: '100%' },
    tooltip: { trigger: 'axis', axisPointer: { type: 'shadow' } },
    // dataZoom: [ { type: 'inside' }, { type: 'slider' } ],
    toolbox: {
      show: true,
      orient: 'vertical',
      left: 'right',
      top: 'top',
      feature: {
        // dataView: { show: false, readOnly: true, title: 'dados' },
        saveAsImage: { show: true, title: " " },
        restore: { show: true }
      }
    },
    // color: ['#007e54', '#015981', '#ce8807', '#e5323e'],
    legend: { top: '20%', left: 'center' },
    //xAxis: { type: 'category', data: [], },
    //yAxis: { type: 'value', },
    angleAxis: null,
    radiusAxis: null,
    polar: null,
    series: [],
    graphic: {
      elements: [
        {
          type: 'image',
          left: 'center',
          top: 50,
          style: {
            image: 'https://cdn.techprod.com.br/wave-chart2.png',
            opacity: 0.25,
            width: 180
          }
        }
      ]
    },
    animationEasing: 'elasticOut',
    animationDelayUpdate: (idx: any) => idx * 3,
  };


  constructor(themeService: T2ThemingService) {
    themeService.getThemeNameObservable().subscribe(theme => {
      this.themeName = theme == 'dark' ? 'dark' : 'vintage';
      this.co.backgroundColor = theme == 'dark' ? 'rgb(55,55,55)' : 'rgb(255,255,255)';
      console.log(theme);
    });

    setInterval(() => {
      if (this.changed) {
        this.changed = false;
        this.updateChartOptions();
      }

      if (this.loading && !this.isLoading) {
        this.echartsIntance?.showLoading();
        this.isLoading = true;
      }

      if (!this.loading && this.isLoading) {
        this.echartsIntance?.hideLoading();
        this.isLoading = false;
      }

    }, 200);
  }

  ngOnInit(): void {}

  onChartInit(ec: EChartsType) {
    this.echartsIntance = ec;
  }

  private updateChartOptions() {

    this.co = { ...this.co, ...this.chartOptions };
    if (this.echartsIntance) {
      const filled = (Array.isArray(this.co.series) && this.co.series.length) ||
                     (!Array.isArray(this.co.series) && this.co.series);
      if (filled) {
        this.co.graphic = this.chartOptions.graphic || null;
      }

      this.echartsIntance.setOption(this.co, true, true);
    }
  }

}
