<div [ngClass]="{'flexColumn': true, 'warningDetail': true, 'warningDetailFinished': warning.status != 'Pendente'}"
  [nbSpinner]="warning.saving" nbSpinnerStatus="primary">

  <div style="display: flex; flex: 1;">
    <div *ngIf="index" id="index">
      {{index}}
    </div>

    <div class="flexColumn" style="flex: 1; gap: 15px">

      <div style="display: flex; justify-content: space-between; align-items: center; gap: 10px;">
        <span style="line-height: 0.8rem; font-size: 0.8rem; font-weight: bold;">{{warning.description}}</span>

        <div class="warningType" [ngClass]="colorService.getClass(warning.color)">
          <span>{{warning.warningType}}</span>
        </div>
      </div>

      <div *ngIf="warning.policyNote || warning.warningNote" style="font-size: 0.75rem; line-height: 1.35;">
        <div *ngIf="warning.policyNote">
          <span>{{warning.policyNote}}</span>
        </div>
        <div *ngIf="warning.warningNote">
          <span>{{warning.warningNote}}</span>
        </div>
      </div>

      <div class="flexAlignRowSpaceBetweenCenter flexGap3">
        <div class="flexRow flexGap10 flexLength1">
          <div *ngIf="warning.startDate" class="flexColumn" style="line-height: 0.8rem;">
            <span class="detailBoxTitle">Prazo Inicial</span>
            <span class="detailBoxValue">{{warning.startDate | date: 'shortDate'}}</span>
          </div>

          <div *ngIf="warning.maxDate" class="flexColumn" style="line-height: 0.8rem;">
            <span class="detailBoxTitle">Prazo Final</span>
            <span class="detailBoxValue">{{warning.maxDate | date: 'shortDate'}}</span>
          </div>

          <div *ngIf="warning.completionDate" class="flexColumn" style="line-height: 0.8rem;">
            <span class="detailBoxTitle">Finalizado em</span>
            <span class="detailBoxValue">{{warning.completionDate | date: 'shortDate'}}</span>
          </div>
        </div>
        <div *ngIf="!warning.completionDate" class="flexRow flexGap5">
          <div class="actionsButtons actionAccept" (click)="finishWarning('Aceito')">
            {{warning.acceptName}}
          </div>
          <div class="actionsButtons actionDecline" (click)="finishWarning('Recusado')">
            {{warning.declineName}}
          </div>
        </div>
      </div>
    </div>

    <div>
      <nb-icon icon="more-vertical-outline" nbTooltip="Ações" (click)="getActionList()" style="--icon-font-size: 1rem;">
      </nb-icon>
    </div>
  </div>

</div>
