<app-dialog-template #dialog [title]="'Exceção'" [formGroup]="formGroup">
  <content style="width:300px" class="flexColumn" style="gap: 5px">
    <app-view-template #viewTemplate [layout]="layout" [data]="data" [flows]="flows" [actions]="actions" [FormGroup]="formGroup"></app-view-template>
  </content>
  <footer>
    <div class="flexAlignRowEndCenter flexGap5">
      <button (click)="confirm()" nbButton size="tiny" status="primary" [disabled]="!formGroup.valid">
        Confirmar
      </button>
    </div>
  </footer>

</app-dialog-template>
