<app-dialog-template #dialog class="flexColumn" [ngStyle]="{'height.vh': 50, 'width.vw': 50}">
  <content class="flexColumn flexGap5 fillContent">

    <div class="flexRow flexGap3">

      <div class="flexColumn flexGap3" style="width: 50%;">
        <span style="font-weight: bold;">Produto</span>
        <app-t2-aggregation t2IdCmp="product" [formGroup]="formGroup" [datasetName]="'stk_item_produto'" [datasetId]="'{6527C214-BB28-4E85-9A84-FD6B05BC66A9}'" />
      </div>

      <div class="flexColumn flexGap3" style="width: 50%;">
        <span style="font-weight: bold;">EP</span>
        <app-t2-select t2IdCmp="productSpecification" [formGroup]="formGroup" [list]="psList" [required]="true" />
      </div>

    </div>

    <app-t2grid class="fillContent" #gridColor [loading]="loadingGrid"></app-t2grid>
  </content>

  <footer class="wFooter">
    <div style="margin-top: auto; display: flex;">
      <button nbButton size="tiny" (click)="closeWindow(false)" style="margin-left: auto">
        Cancela
      </button>
      <button nbButton size="tiny" status="primary" [disabled]="gridColor.getRowsSelected().length === 0"
        (click)="closeWindow(true)">
        Confirma
      </button>
    </div>
  </footer>
</app-dialog-template>
