<div [ngClass]="{'waiting': lockCmp}" [nbSpinner]="lockCmp" nbSpinnerStatus="primary" nbSpinnerSize="giant"
  class="fillContent">

  <app-form-template #formTemplate formTitle="Planejamento de Produção" [loaded]="loaded"
    helpUrl="https://tech2e.atlassian.net/l/cp/60T25TUi">

    <header>
      <app-tool-bar (fixedDateClick)="setFixedDate($event)" (recalculateClick)="recalculate()"
        (timeReservationClick)="timeReservation()" (swapDeviceClick)="swapDevice()"
        (sortSelctedTasksClick)="sortSelectedTasks()" (lockScreenClick)="lockScreen($event)">
      </app-tool-bar>
    </header>

    <content *ngIf="loaded" class="flexColumn fillContent" style="gap: 10px">
      <div class="flexRow fillContent">
        <div *ngIf="apsService.showView == 'programming'" class="flexColumn fillContent">
          <app-aps-sequence #apsSequence *ngIf="apsService.programmingVars.showComponent.sequencing" class="fillContent"
            (focused)="apsService.setProgrammingFocusedComponent($event)" (lockScreen)="lockScreen($event)">
          </app-aps-sequence>
          <app-aps-gantt-vis #apsGantt *ngIf="apsService.programmingVars.showComponent.gantt" class="fillContent"
            (focused)="apsService.setProgrammingFocusedComponent($event)" (lockScreen)="lockScreen($event)">
          </app-aps-gantt-vis>
        </div>

        <div *ngIf="apsService.showView == 'analysis'" class="flexColumn fillContent">
          <app-aps-analysis class="fillContent" ></app-aps-analysis>
        </div>

        <div *ngIf="apsService.showView == 'kpi'" class="flexColumn fillContent">
          <app-aps-kpi class="fillContent"></app-aps-kpi>
        </div>

      </div>

    </content>

  </app-form-template>

</div>
