import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DeviceCache, DeviceCacheTask } from "../../device-cache.service";
import { PanelType } from "../../shop-floor-taskpanel/shop-floor-taskpanel.component";

@Component({
  selector: 'app-task-float-actions',
  templateUrl: './task-float-actions.component.html',
  styleUrls: ['./task-float-actions.component.scss']
})
export class TaskFloatActionsComponent {

  @Input() device: DeviceCache;
  @Input() taskPlan: DeviceCacheTask;
  @Output() deviceSelected = new EventEmitter<{ device: DeviceCache, taskPlan: DeviceCacheTask, tab: PanelType, clearSelection?: boolean }>();

  selectDevice(tab: PanelType, clearSelection?: boolean) {
    this.deviceSelected.emit({ device: this.device, taskPlan: this.taskPlan, tab: tab, clearSelection: clearSelection });
  }



}
