<nb-card style="height: 100%;">
  <nb-card-body style="display: flex; flex-direction: column;">

    <app-t2-google-oauth [id]="'zD20191001H145715590R000000055'" [datasetName]="'afx_emailConfiguration'"
      [emailAddress]="'rodrigo.lourenco@tech2e.com.br'" />



  </nb-card-body>
</nb-card>
