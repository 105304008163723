<app-dialog-template #dialog title="Seleção de cilindros">
  <content style="width: 75vw; max-width: 75vw; height: 55vh; max-height: 55vh;">

    <nb-tabset style="width: 100%; height: calc(100% - 20px)">

      <nb-tab tabTitle="Sugestão de cilindros">
        <div class="fillContent flexRow flexGap10">
          <!--CORES-->
          <div style="padding: 2px 5px; flex: 0.34">

            <span class="spanBold">Cores</span>

            <div class="suggestionAreas">
              <div class="colors T2GrayHoverArea" *ngFor="let color of prodOrderColors; let seq = index"
                [ngClass]="{'T2GraySelectedArea':  color.id_especif_carac_quimico_item == selectedColor?.id_especif_carac_quimico_item && color.seqCorIgual == selectedColor?.seqCorIgual}"
                (click)="selectColor(color)">

                <div
                  style="display: flex; justify-content: space-between; align-items: start; gap: 5px; min-height: 26px; margin-bottom: 5px;">
                  <!-- TITULO -->
                  <div class="colorTitle">
                    <div class="colorName"><span style="text-align: end;">{{seq + 1}}</span> {{color.color}}</div>
                    <div class="colorDetail">{{color.detail}}</div>
                  </div>

                  <!-- CILINDRO ATRIBUIDO -->
                  <div
                    [ngClass]="{'cilRed': color.status == 'GRAVAR' || color.status == 'RECROMO', 'cilOrange': color.status == 'REVISAR', 'cilGreen': color.status == 'OK'}"
                    class="colorCylinder">
                    <span>{{color.cylinderCode}}</span>
                  </div>
                </div>

                <div class="T2GrayArea colorData"
                  style="display: flex; justify-content: space-between; align-items: start; gap: 5px">
                  <div class="flex: 0.34">
                    <div><strong>Gramatura</strong></div>
                    <div>{{color.gram}}</div>
                  </div>
                  <div class="flex: 0.33">
                    <div><strong>Área Aplic</strong></div>
                    <div>{{color.area}}</div>
                  </div>
                  <div class="flex: 0.33">
                    <div><strong>Gram Aplic</strong></div>
                    <div>{{color.aplicGram}}</div>
                  </div>

                </div>

              </div>
            </div>
          </div>

          <!--CILINDROS-->
          <div style="padding: 2px 5px; flex: 0.66">

            <div class="spanBold" style="text-align: end; width: 100%;">Cilindros Sugeridos</div>

            <div class="T2GrayArea suggestionAreas" style="padding: 10px;">
              <div class="semCilindros" *ngIf="suggestionLoaded && cylinderSuggestionList.length == 0">
                <h2>Não há nenhuma sugestão de cilindro para essa cor</h2>
              </div>

              <div class="T2GrayArea" style="padding: 10px; width: 100%; margin-bottom: 5px;"
                *ngFor="let cyl of cylinderSuggestionList">

                <div style="display: flex; justify-content: space-between; align-items: start; gap: 5px">

                  <!-- CILINDRO -->
                  <div style="display: flex; gap: 5px; ">
                    <span style="width: 120px;" class="spanBold">{{cyl.code}}</span>
                    <div *ngIf="cyl.suggestionInfo?.compatible" class="cilSuggestion" style="background-color: blue;"
                      nbTooltip="Cilindro Compatível">C</div>
                    <div *ngIf="cyl.suggestionInfo?.lastUsed" class="cilSuggestion" style="background-color: purple;"
                      nbTooltip="Cilindro utilizado na última OP">U</div>
                    <div *ngIf="cyl.suggestionInfo?.colorAssigned" class="cilSuggestion"
                      style="background-color: green;" nbTooltip="Cilindro gravado com a mesma arte e cor">G</div>
                  </div>

                  <!-- ACAO DE SELECAO -->
                  <div>
                    <app-t2-button *ngIf="selectedColor.id_item_cylinder != cyl.id_item" t2IdCmp="btnAssociar"
                      [status]="'primary'" [size]="'tiny'" [value]="'Associar'" (clicked)="assignCylinder(cyl)">
                    </app-t2-button>

                    <div *ngIf="selectedColor.id_item_cylinder == cyl.id_item" class="cylSelected">
                      <span>Associado</span>
                    </div>
                  </div>
                </div>

                <div
                  style="display: flex; justify-content: space-between; align-items: start; gap: 5px; font-size: 75%; margin-top: 8px; line-height: 1.3em;">
                  <!-- Produto gravado -->
                  <div  class="flex: 0.7">
                    <div>Produto Gravado</div>
                    <div><strong>{{cyl.descricaoEP}}</strong></div>
                  </div>

                  <!--Cor gravada-->
                  <div  class="flex: 0.3">
                    <div>Cor Gravada</div>
                    <div><strong>{{cyl.corEP}}</strong></div>
                  </div>

                </div>

                <div class="T2GrayArea cylGridData"
                  style="display: flex; gap: 5px; justify-content: space-between; align-items: start;">

                  <div  class="flex: 0.34">
                    <div>
                      <span class="cylDataTitle1">Circunferência </span>
                      <span class="cylData">{{cyl.circ}}</span>
                    </div>
                    <div>
                      <span class="cylDataTitle1">Aspecto visual </span>
                      <span class="cylData">{{cyl.aspect}}</span>
                    </div>
                    <div>
                      <span class="cylDataTitle1">Balanc </span>
                      <span class="cylData">{{cyl.balancing}}</span>
                    </div>
                  </div>

                  <div  class="flex: 0.33">
                    <div>
                      <span class="cylDataTitle2">Diâmetro </span>
                      <span class="cylData">{{cyl.diameter}}</span>
                    </div>
                    <div>
                      <span class="cylDataTitle2">Espessura </span>
                      <span class="cylData">{{cyl.espess}}</span>
                    </div>
                    <div>
                      <span class="cylDataTitle2">Dur cromo </span>
                      <span class="cylData">{{cyl.thickness}}</span>
                    </div>
                  </div>

                  <div  class="flex: 0.33">
                    <div><span class="cylDataTitle3">Excent corpo </span> <span
                        class="cylData">{{cyl.excentBody}}</span>
                    </div>
                    <div><span class="cylDataTitle3">Excent pontas </span> <span
                        class="cylData">{{cyl.ExcentEdge}}</span>
                    </div>
                    <div>
                      <span class="cylDataTitle">Rugosidade </span>
                      <span class="cylData">{{cyl.roughness}}</span>
                    </div>
                  </div>

                </div>

              </div>

            </div>

          </div>
        </div>
      </nb-tab>

      <nb-tab tabTitle="Seleção manual">
        <div class="wContent">
          <app-t2grid #gdCadCilindro id="gdCadCilindro" [sortable]="true">
          </app-t2grid>
        </div>
      </nb-tab>

    </nb-tabset>

  </content>

  <footer>
    <div class="wFooter">
      <div style="display: flex;">
        <button nbButton size="tiny" status="danger" (click)="closeWindow(false)" style="margin-left: auto">
          Cancela
        </button>
        <button nbButton size="tiny" status="primary" (click)="closeWindow(true)">
          Confirma
        </button>
      </div>
    </div>
  </footer>
</app-dialog-template>
