import { FormTemplateComponent } from './../cmp/form-template/form-template.component';
import { DatasetTemplateComponent } from './dataset-template.component';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Dataset } from './model/dataset';
import { T2SecurityService } from "../security/t2security.service";
import { take, filter } from "rxjs/Operators";

@Component({
  selector: 'app-dataset',
  templateUrl: './dataset.component.html',
  styleUrls: ['./dataset.component.scss']
})
export class DatasetComponent implements OnInit {

  @ViewChild("datasetTemplate", { static: true }) datasetTemplate: DatasetTemplateComponent;
  @ViewChild("formTemplate", { static: true }) formTemplate: FormTemplateComponent;

  public dataset: Dataset;
  public loaded = false;

  constructor(private sec: T2SecurityService) { }

  ngOnInit(): void { }

  onDatasetChange(dataset: Dataset) {
    this.dataset = dataset;
    this.loaded = false;

    this.sec.getDatasetActionIDs(dataset.datasetName).pipe(
      filter(ids => ids != null),
      take(1)
    ).subscribe(ids => {
      if (this.formTemplate.validateAccess(ids, 'dashboard')) {
        this.datasetTemplate.loadDatasetData();
        this.loaded = true;
      }
    });
  }
}
