import { AfterViewInit, Component, Input } from '@angular/core';
import { take } from "rxjs/Operators";
import { T2HttpClientService } from "src/app/core/http/t2httpClient.service";

@Component({
  selector: 'app-t2-google-oauth',
  templateUrl: './t2-google-oauth.component.html',
  styleUrls: ['./t2-google-oauth.component.scss']
})
export class T2GoogleOAuthComponent implements AfterViewInit {

  private _id: string = undefined;

  @Input() t2IdCmp: string;
  @Input() visible;
  @Input() get id() { return this._id }; set id(value: string) { this._id = value; this.checkConnectionStatus() }
  @Input() datasetName: string;
  @Input() emailAddress: string;
  private googleScopes: string = "http://mail.google.com/";

  private redirectURL: string;
  public loading = true;
  public hasConfig = false;
  public connectionIsValid = false;

  constructor(public httpService: T2HttpClientService) {
    const url = new URL(window.location.href)
    this.redirectURL = `${url.protocol}//${url.hostname}${url.port ? ":" + url.port : ""}/validateOAuth`
  }
  ngAfterViewInit(): void {
    console.log(this.id);
  }

  private checkConnectionStatus() {
    if (!this.id) return;

    const params = new Map<string, string>();
    params.set("id_emailConnector", this.id);
    this.httpService.get("core.email.oauth/checkConnectionOAuth", params)
      .pipe(take(1))
      .subscribe(resp => {
        if (resp) {
          this.hasConfig = resp.hasConnection;
          this.connectionIsValid = this.hasConfig && resp.validConnection;
        }

        this.loading = false;
      });

  }


  public authorize() {
    if (this.loading) return;

    const body = {
      redirectUrl: this.redirectURL,
      scopes: this.googleScopes,
      responseTypes: "code",
      state: `${JSON.stringify({ email: this.emailAddress, entity: 'Google', id: this.id, datasetName: this.datasetName, redirectURL: this.redirectURL })}`
    };

    this.httpService.post("core.email.oauth/googleRequestUrl", null, body)
      .pipe(take(1))
      .subscribe(resp => {
        if (resp && resp.requestUrl)
          window.open(resp.requestUrl, '_blank');
      });
  }


  /**

http://localhost:4200/#state=email:undefined&access_token=ya29.a0AXooCgtFcF03l5eRu2flLdmEbwRb-MIUqAE7RxRlBoSslxoY9PsoX07B23sHpl7HasfrIrgIQlyBRsHm-OAOynvXVx2Xcgxv1juzALg-vFiH01nhRIJ7nQXSawxKcYUTM3Th1NDwUZ72gnyTzP5eSU7M8zIIwfKe0AaCgYKAdESAQ8SFQHGX2Mic8hb6koJinGndEAHvAoQbg0169&token_type=Bearer&expires_in=3599&scope=https://mail.google.com/%20https://www.googleapis.com/auth/drive.metadata.readonly

http://localhost:4200/
#state=email:undefined
&
access_token=ya29.a0AXooCgtFcF03l5eRu2flLdmEbwRb-MIUqAE7RxRlBoSslxoY9PsoX07B23sHpl7HasfrIrgIQlyBRsHm-OAOynvXVx2Xcgxv1juzALg-vFiH01nhRIJ7nQXSawxKcYUTM3Th1NDwUZ72gnyTzP5eSU7M8zIIwfKe0AaCgYKAdESAQ8SFQHGX2Mic8hb6koJinGndEAHvAoQbg0169
&
token_type=Bearer
&
expires_in=3599
&
scope=https://mail.google.com/%20https://www.googleapis.com/auth/drive.metadata.readonly


   */

}
