import { ComponentMirror, ComponentRef, Directive, EventEmitter, Injector, Input, OnChanges, OnDestroy, Output, reflectComponentType, SimpleChange, SimpleChanges, Type, ViewContainerRef } from '@angular/core';
import { FormGroup } from "@angular/forms";
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/Operators';

type UserOutputs = Record<string, (event: any) => void>;
type UserInputs = Record<string, any>;
type ComponentInputs = ReadonlyArray<{
  readonly propName: string;
  readonly templateName: string;
}>;
type ComponentOutputs = ReadonlyArray<{
  readonly propName: string;
  readonly templateName: string;
}>;
@Directive({
  selector: '[T2Cmp]',
})
export class T2CmpDirective implements OnDestroy, OnChanges {
  private subscription = new Subject();
  private componentMirror?: ComponentMirror<any>;
  private componentRef?: ComponentRef<any>;
  @Input('component') component!: Type<any>;
  @Input() outputs?: UserOutputs = {};
  @Input() inputs?: UserInputs = {};
  @Input() injector?: Injector;
  @Input() FormGroup?: FormGroup;
  @Output() componentReference = new EventEmitter<ComponentRef<any>>();

  constructor(
    private viewContainerRef: ViewContainerRef
  ) { }

  ngOnChanges(changes: SimpleChanges): void {

    assertNotNullOrUndefined(this.component);

    let componentChanges: Record<string, SimpleChange>;
    const shouldCreateNewComponent =
      changes['component']?.previousValue !== changes['component']?.currentValue
      ||
      changes['injector']?.previousValue !== changes['injector']?.currentValue;

    if (shouldCreateNewComponent) {
      this.destroyComponent();
      this.createComponent();
      componentChanges = this.makeComponentChanges(changes['inputs'], true);
    }
    componentChanges ??= this.makeComponentChanges(changes['inputs'], false);

    assertNotNullOrUndefined(this.componentMirror);
    assertNotNullOrUndefined(this.componentRef);

    this.componentRef.instance.formGroup = this.FormGroup;

    this.validateOutputs(this.componentMirror.outputs, this.outputs ?? {}, this.componentRef.instance);
    this.validateInputs(this.componentMirror.inputs, this.inputs ?? {});
    if (changes['inputs']) {
      this.bindInputs(this.componentMirror.inputs, this.inputs ?? {}, this.componentRef.instance);
    }
    if (changes['outputs']) {
      this.subscription.next(null); // to remove old subscription
      this.bindOutputs(this.componentMirror.outputs, this.outputs ?? {}, this.componentRef.instance);
    }
    if ((this.componentRef.instance as OnChanges).ngOnChanges) {
      this.componentRef.instance.ngOnChanges(componentChanges);
    }
  }


  ngOnDestroy(): void {
    this.destroyComponent();
    this.subscription.next(void 0);
    this.subscription.complete();
  }

  private makeComponentChanges(inputsChange: SimpleChange, firstChange: boolean): Record<string, SimpleChange> {
    const previuosInputs = inputsChange?.previousValue ?? {};
    const currentInputs = inputsChange?.currentValue ?? {};
    return Object.keys(currentInputs).reduce((acc, inputName) => {
      const currentInputValue = currentInputs[inputName];
      const previuosInputValue = previuosInputs[inputName];
      if (currentInputValue !== previuosInputValue) {
        acc[inputName] = new SimpleChange(firstChange ? undefined : previuosInputValue, currentInputValue, firstChange);
      }
      return acc;
    }, {} as Record<string, SimpleChange>);
  }

  private createComponent() {
    this.componentMirror = reflectComponentType(this.component);
    this.componentRef = this.viewContainerRef.createComponent(this.component, { index: 0, injector: this.injector });
    this.componentReference.emit(this.componentRef);
  }

  private bindOutputs(componentOutputs: ComponentInputs, userOutputs: UserOutputs, componentInstance: any) {
    componentOutputs.forEach((output) => {
      (componentInstance[output.propName] as EventEmitter<any>)
        .pipe(takeUntil(this.subscription))
        .subscribe((event) => {
          const handler = userOutputs[output.templateName];
          if (handler) { // in case the output has not been provided at all
            handler(event);
          }
        });
    });
  }

  private bindInputs(componentInputs: ComponentInputs, userInputs: UserInputs, componentInstance: any) {
    componentInputs.forEach((input) => {
      const inputValue = userInputs[input.templateName];
      componentInstance[input.propName] = inputValue;
    });
  }

  private validateOutputs(componentOutputs: ComponentOutputs, userOutputs: UserOutputs, componentInstance: any) {
    componentOutputs.forEach((output) => {
      if (!(componentInstance[output.propName] instanceof EventEmitter)) {
        throw new Error(`Output ${output.propName} deve ser do tipo EventEmitter`);
      }
    });

    const outputsKeys = Object.keys(userOutputs);
    outputsKeys.forEach(key => {
      const componentHaveThatOutput = componentOutputs.some(output => output.templateName === key);
      if (!componentHaveThatOutput) {
        throw new Error(`Output ${key} não é um ${this.component.name} output.`);
      }
      if (!(userOutputs[key] instanceof Function)) {
        throw new Error(`Output ${key} deve ser uma função`);
      }
    });
  }

  private validateInputs(componentInputs: ComponentInputs, userInputs: UserInputs) {
    const userInputsKeys = Object.keys(userInputs);
    userInputsKeys.forEach(userInputKey => {
      const componentHaveThatInput = componentInputs.some(componentInput => componentInput.templateName === userInputKey);
      if (!componentHaveThatInput) {
        throw new Error(`Input ${userInputKey} não é um ${this.component.name} input.`);
      }
    });
  }

  private destroyComponent() {
    this.componentRef?.destroy();
    this.viewContainerRef.clear();
  }

}

function assertNotNullOrUndefined<T>(value: T): asserts value is NonNullable<T> {
  if (value === null || value === undefined) {
    throw new Error(`não pode ser undefined ou null`);
  }
}
