<div style="display: flex; gap: 2px; align-items: center;">
  <div  *ngFor="let item of iconList" style="display: flex;">
    <div [ngClass]="getCssClass(item)">
      <div style="line-height: 1em;" [ngStyle]="{'font-size.%': item.text?.sizeInPercent || 100}">{{item.text.value ?
        item.text.value : ''}}</div>
      <nb-icon *ngIf="item.tooltip && item.icon.name" [nbTooltip]="item.tooltip" nbTooltipPlacement="top"
        [icon]="item.icon.name" style="font-size: 120%;" [ngStyle]="{'color': item.icon.color}"></nb-icon>
      <nb-icon *ngIf="!item.tooltip" [icon]="item.icon.name" style="font-size: 120%;"
        [ngStyle]="{'color': item.icon.color}"></nb-icon>
    </div>
  </div>
</div>
