import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { BudgetPolicyItemExcessRule, BudgetPolicyItemExcessRuleApprover, BudgetPolicyItemRule } from "../budget-policy.service";
import { T2DatasetService } from "src/app/core/dataset/t2dataset.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { T2HttpClientService } from "src/app/core/http/t2httpClient.service";
import { take, takeUntil } from "rxjs/Operators";
import { Subject } from "rxjs";
import { T2MessageService } from "src/app/core/t2-message.service";
import { LayoutType, ViewTemplateElement } from "src/app/core/cmp/view-template/model/view-template-element";
import { T2SelectComponent } from "src/app/core/cmp/ui/t2-select/t2-select.component";
import { T2AggregationComponent } from "src/app/core/cmp/ui/t2-aggregation/t2-aggregation.component";
import { T2CheckBoxComponent } from "src/app/core/cmp/ui/t2-check-box/t2-check-box.component";
import { ConditionalOperator, InputType, T2ViewTemplateFlow } from "src/app/core/cmp/view-template/model/t2-view-template-flow";
import { ActionType, T2ViewTemplateAction } from "src/app/core/cmp/view-template/model/t2-view-template-action";
import { NbDialogService } from "@nebular/theme";
import { DialogInputComponent } from "src/app/core/cmp/dialog-input/dialog-input.component";

@Component({
  selector: 'app-budget-rule',
  templateUrl: './budget-rule.component.html',
  styleUrls: ['./budget-rule.component.scss']
})
export class BudgetRuleComponent implements OnDestroy {

  @Input()
  get budgetRule(): BudgetPolicyItemRule {
    return this._budgetRule;
  }
  set budgetRule(value: BudgetPolicyItemRule) {
    this._budgetRule = value;
    this.setFormGroup();
  }

  @Output() hasChanges = new EventEmitter<void>();
  @Output() lockScreen = new EventEmitter<boolean>();

  private _budgetRule: BudgetPolicyItemRule;
  private unsubscribe = new Subject<void>();
  public formGroupRule: FormGroup;
  public formGroupExRule: FormGroup;
  public formGroupExRuleApprov: FormGroup;

  constructor(private dsService: T2DatasetService, private formBuilder: FormBuilder, private httpClient: T2HttpClientService,
    private messageService: T2MessageService, private dialogService: NbDialogService) {
    this.formGroupRule = this.formBuilder.group({});
    this.formGroupExRule = this.formBuilder.group({});
    this.formGroupExRuleApprov = this.formBuilder.group({});
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  private setFormGroup() {
    this.unsubscribe.next();
    this.formGroupRule.removeAllControls();
    this.formGroupExRule.removeAllControls();
    this.formGroupExRuleApprov.removeAllControls();

    if (this.budgetRule) {
      // this.formGroupRule.addControl("dataInicio", this.formBuilder.control(this.budgetRule.startDate));
      // this.formGroupRule.addControl("dataFim", this.formBuilder.control(this.budgetRule.endDate));
      this.formGroupRule.addControl("valorVerba", this.formBuilder.control(this.budgetRule.budgetValue));

      this.budgetRule.excessRuleList?.forEach(exRule => {
        this.formGroupExRule.addControl(`${exRule.budgetPolicyItemExcessRuleId}_valorExcedente`, this.formBuilder.control(exRule.excessValue));
        this.formGroupExRule.addControl(`${exRule.budgetPolicyItemExcessRuleId}_qtdMinAprovacoes`, this.formBuilder.control(exRule.minApprovalQty));

        exRule.approverList?.forEach(approver => {
          this.formGroupExRuleApprov.addControl(`${approver.budgetPolicyItemRuleExcessApproverId}_obrigatorio`, this.formBuilder.control(approver.mandatory));
        })
      });
    }

    this.formGroupRule.markAsPristine();
    this.formGroupExRule.markAsPristine();
    this.formGroupExRuleApprov.markAsPristine();

    this.formGroupRule.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe(changes => {
      this.hasChanges.emit();
    });

    this.formGroupExRule.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe(changes => {
      this.hasChanges.emit();
    });

    this.formGroupExRuleApprov.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe(changes => {
      this.hasChanges.emit();
    });
  }

  public addExcessRule() {
    let newExcessRule = new BudgetPolicyItemExcessRule();

    this.httpClient.getNewUUID().pipe(take(1)).subscribe(newId => {
      newExcessRule.budgetPolicyItemExcessRuleId = newId;
      newExcessRule.excessValue = 0;
      newExcessRule.minApprovalQty = 1;
      newExcessRule.approverList = [];
      newExcessRule.pendingInclusion = true;

      this.formGroupExRule.addControl(`${newId}_valorExcedente`, this.formBuilder.control(newExcessRule.excessValue));
      this.formGroupExRule.addControl(`${newId}_qtdMinAprovacoes`, this.formBuilder.control(newExcessRule.minApprovalQty));
      this.formGroupExRule.addControl(`${newId}_id_polOrca_it_regra`, this.formBuilder.control(this.budgetRule.budgetPolicyItemRuleId));

      this.formGroupExRule.markAllControlsAsDirty();

      this.budgetRule.excessRuleList.push(newExcessRule);
    });
  }

  public deleteExcessRule(exRule: BudgetPolicyItemExcessRule) {
    this.messageService.showDialog({
      context: {
        topMessage: "ATENÇÃO",
        message: "Deseja excluir esse registro ?",
        message2: "A regra de excedente será imediatamente excluída. Essa ação não poderá ser desfeita.",
        actions: [{ description: "Excluir", status: "danger" }, { description: "Cancelar", status: "basic" }]
      }
    }).onClose.pipe(take(1)).subscribe(resp => {
      if (resp == "Excluir") {
        this.lockScreen.emit(true);

        if (!exRule.pendingInclusion) {
          this.dsService.deleteDatasetRecord("cst_polOrca_it_reg_excedente", exRule.budgetPolicyItemExcessRuleId).pipe(take(1)).subscribe(() => {
            this.deleteExcessRuleControls(exRule);
            this.lockScreen.emit(false);
          }, error => {
            this.lockScreen.emit(false);
          });
        } else {
          this.deleteExcessRuleControls(exRule);
          this.lockScreen.emit(false);
        }
      }
    })
  }

  private deleteExcessRuleControls(exRule: BudgetPolicyItemExcessRule) {
    Object.keys(this.formGroupExRule.controls).filter(id => id.indexOf(exRule.budgetPolicyItemExcessRuleId) >= 0).forEach(key => {
      this.formGroupExRule.removeControl(key);
    });

    let index = this.budgetRule.excessRuleList.findIndex(exr => exr.budgetPolicyItemExcessRuleId == exRule.budgetPolicyItemExcessRuleId);
    this.budgetRule.excessRuleList.splice(index, 1);
  }

  public saveData() {
    return new Promise<void>((resolve, reject) => {
      let promList = new Array<Promise<any>>();
      if (this.formGroupRule.dirty) {
        promList.push(this.dsService.saveFormGroupEntity("cst_polOrca_it_regra", this.budgetRule.budgetPolicyItemRuleId, this.formGroupRule).toPromise());
      }

      if (this.formGroupExRule.dirty) {
        let mapExRule = new Map<string, Map<string, any>>();
        Object.keys(this.formGroupExRule.controls).forEach(key => {
          if (this.formGroupExRule.controls[key].dirty) {
            let exRuleId = key.substring(0, key.indexOf("_"));
            let fieldName = key.substring(key.indexOf("_") + 1);
            let exRule: Map<string, any>;

            exRule = mapExRule.get(exRuleId);

            if (!exRule) {
              exRule = new Map<string, any>();
              mapExRule.set(exRuleId, exRule);
            }

            exRule.set(fieldName, this.formGroupExRule.controls[key].value);
          }
        });

        mapExRule.forEach((value, exRuleId) => {
          promList.push(this.dsService.saveSimpleEntity("cst_polOrca_it_reg_excedente", exRuleId, value).toPromise().then(() => {
            let exRule = this.budgetRule.excessRuleList.find(exR => exR.budgetPolicyItemExcessRuleId == exRuleId);

            value.forEach((fieldValue, fieldName) => {
              if (fieldName == "valorExcedente")
                exRule.excessValue = fieldValue
              else if (fieldName == "qtdMinAprovacoes")
                exRule.minApprovalQty = fieldValue;
            })
          }));
        });
      }

      Promise.all(promList).then(() => {
        promList.length = 0;

        this.budgetRule.excessRuleList.forEach(exRule => {
          exRule.pendingInclusion = false;
        })

        if (this.formGroupExRuleApprov.dirty) {
          let mapExRuleApprov = new Map<string, Map<string, any>>();
          Object.keys(this.formGroupExRuleApprov.controls).forEach(key => {
            if (this.formGroupExRuleApprov.controls[key].dirty) {
              let exRuleApprovId = key.substring(0, key.indexOf("_"));
              let fieldName = key.substring(key.indexOf("_") + 1);
              let exRuleApprov: Map<string, any>;

              exRuleApprov = mapExRuleApprov.get(exRuleApprovId);

              if (!exRuleApprov) {
                exRuleApprov = new Map<string, any>();
                mapExRuleApprov.set(exRuleApprovId, exRuleApprov);
              }

              exRuleApprov.set(fieldName, this.formGroupExRuleApprov.controls[key].value);
            }
          });

          mapExRuleApprov.forEach((value, exRuleApprovId) => {
            promList.push(this.dsService.saveSimpleEntity("cst_polOrca_it_reg_ex_aprov", exRuleApprovId, value).toPromise());
          })
        }

        Promise.all(promList).then(() => {
          this.budgetRule.excessRuleList?.forEach(exRule => {
            exRule.approverList?.forEach(approver => {
              approver.pendingInclusion = false;
            });
          });

          resolve();
        }, error => reject(error));
      }, error => reject(error));
    })
  }

  public hasToSave(): boolean {
    return this.formGroupRule.dirty || this.formGroupExRule.dirty || this.formGroupExRuleApprov.dirty;
  }

  public addApprover(excessRule: BudgetPolicyItemExcessRule) {
    let formGroup: FormGroup = this.formBuilder.group({});
    formGroup.addControl("tipo", this.formBuilder.control(undefined, { validators: [Validators.required] }));
    formGroup.addControl("id_security_identity", this.formBuilder.control(undefined));
    formGroup.addControl("obrigatorio", this.formBuilder.control(undefined));

    this.dsService.getFixedComboList("cst_polOrca_it_reg_ex_aprov", "tipo").pipe(take(1)).subscribe(itemList => {
      let typeList = new Array<{ id: string, description: string }>();

      itemList?.forEach(item => {
        typeList.push({ id: item.description, description: item.description });
      });

      formGroup.controls["tipo"].setValue(typeList[0].id);

      let layout: Array<ViewTemplateElement> = [
        {
          layoutType: LayoutType.gridLayout,
          children: [
            {
              layoutType: LayoutType.component,
              cmpName: "tipo",
              title: "Tipo",
              cmpType: T2SelectComponent,
              columnSpan: 4,
              isBaseComponent: true,
              inputs: {
                required: true,
                list: typeList
              }
            },
            {
              layoutType: LayoutType.component,
              cmpName: "id_security_identity",
              title: "Grupo/Usuário",
              cmpType: T2AggregationComponent,
              columnSpan: 4,
              isBaseComponent: true,
              inputs: {
                readOnly: true
              }
            },
            {
              layoutType: LayoutType.component,
              cmpName: "obrigatorio",
              title: "Obrigatório",
              cmpType: T2CheckBoxComponent,
              columnSpan: 3,
              isBaseComponent: true
            }
          ]
        }
      ];

      let actions: Array<T2ViewTemplateAction> = [
        {
          type: ActionType.changeValue,
          actionName: "changeValue",
          methodName: "changeValue"
        },
        {
          type: ActionType.changeValue,
          actionName: "clearValue",
          methodName: "clearValue"
        }
      ];

      let flows: Array<T2ViewTemplateFlow> = [
        {
          action: actions[0],
          actionName: "changeValue",
          triggerCmpNameList: ["tipo"],
          inputs: [
            {
              type: InputType.component,
              cmpName: "tipo",
              paramName: "value"
            }
          ],
          outputs: [
            {
              cmpName: "id_security_identity",
              cmpInputName: "datasetName",
              conditionList: [
                {
                  inputValue: "Grupo",
                  operator: ConditionalOperator.equals,
                  outputValue: "afx_security_identity_group"
                },
                {
                  inputValue: "Usuário",
                  operator: ConditionalOperator.equals,
                  outputValue: "afx_security_identity_user"
                }
              ]
            },
            {
              cmpName: "id_security_identity",
              cmpInputName: "datasetId",
              conditionList: [
                {
                  inputValue: "Grupo",
                  operator: ConditionalOperator.equals,
                  outputValue: "{5DF41EF9-559F-4FDB-BCED-CD6BC3BBC3EB}"
                },
                {
                  inputValue: "Usuário",
                  operator: ConditionalOperator.equals,
                  outputValue: "{0FD9B8EE-8A91-4F81-BF0F-C8F5DDD8BE83}"
                }
              ]
            },
            {
              cmpName: "id_security_identity",
              cmpInputName: "readOnly",
              conditionList: [
                {
                  inputValue: ["Grupo", "Usuário"],
                  operator: ConditionalOperator.in,
                  outputValue: false,
                },
                {
                  inputValue: ["Grupo", "Usuário"],
                  operator: ConditionalOperator.notIn,
                  outputValue: true
                }
              ]
            }
          ]
        },
        {
          action: actions[1],
          actionName: "clearValue",
          triggerCmpNameList: ["tipo"],
          outputs: [
            {
              cmpName: "id_security_identity"
            },
            {
              cmpName: "id_security_identity",
              cmpInputName: "description"
            }
          ]
        }
      ];

      let dlg = this.dialogService.open(DialogInputComponent, {
        context: {
          FormGroup: formGroup,
          layout: layout,
          flows: flows,
          actions: actions,
          ngStyle: { "width": "30vw" }
        }
      });

      dlg.onClose.pipe(take(1)).subscribe(resp => {
        if (resp == "Confirma") {
          if (["Grupo", "Usuário"].includes(formGroup.controls["tipo"].value) && !formGroup.controls["id_security_identity"].value) {
            let msg = "Para os tipos Grupo e Usuário é obrigatório informar o campo Grupo/Usuário"
            this.messageService.showDialog({
              context: {
                title: "ATENÇÃO",
                message: msg,
                actions: [{description: "OK"}]
              }
            });

            throw new Error("Para os tipos Grupo e Usuário é obrigatório informar o campo Grupo/Usuário");
          }

          this.httpClient.getNewUUID().pipe(take(1)).subscribe(newId => {
            let approver = new BudgetPolicyItemExcessRuleApprover();
            approver.budgetPolicyItemRuleExcessApproverId = newId;
            approver.type = formGroup.controls["tipo"].value;
            approver.mandatory = formGroup.controls["obrigatorio"].value;
            approver.id_security_identity = formGroup.controls["id_security_identity"].value;
            approver.pendingInclusion = true;

            if (approver.id_security_identity) {
              approver.name = dlg.componentRef.instance.viewTemplate.getCmpInputValue("id_security_identity", "description");
            } else {
              approver.name = approver.type;
            }

            this.formGroupExRuleApprov.addControl(`${newId}_id_polOrca_it_reg_excedente`, this.formBuilder.control(excessRule.budgetPolicyItemExcessRuleId));
            this.formGroupExRuleApprov.addControl(`${newId}_tipo`, this.formBuilder.control(approver.type));
            this.formGroupExRuleApprov.addControl(`${newId}_obrigatorio`, this.formBuilder.control(approver.mandatory));

            if (approver.id_security_identity) {
              this.formGroupExRuleApprov.addControl(`${newId}_id_security_identity`, this.formBuilder.control(approver.id_security_identity));
            }

            if (!excessRule.approverList?.length) {
              excessRule.approverList = [];
            }

            excessRule.approverList.push(approver);

            this.formGroupExRuleApprov.controls[`${newId}_id_polOrca_it_reg_excedente`].markAsDirty();
            this.formGroupExRuleApprov.controls[`${newId}_tipo`].markAsDirty();
            this.formGroupExRuleApprov.controls[`${newId}_obrigatorio`].markAsDirty();

            if (approver.id_security_identity) {
              this.formGroupExRuleApprov.controls[`${newId}_id_security_identity`].markAsDirty();
            }

            this.formGroupExRuleApprov.markAsDirty();
          })
        }
      })
    })
  }

  public deleteApprover(exRule: BudgetPolicyItemExcessRule, approver: BudgetPolicyItemExcessRuleApprover) {
    this.messageService.showDialog({
      context: {
        topMessage: "ATENÇÃO",
        message: "Deseja excluir esse registro ?",
        message2: "O aprovador será imediatamente excluído. Essa ação não poderá ser desfeita.",
        actions: [{ description: "Excluir", status: "danger" }, { description: "Cancelar", status: "basic" }]
      }
    }).onClose.pipe(take(1)).subscribe(resp => {
      if (resp == "Excluir") {
        this.lockScreen.emit(true);

        if (!approver.pendingInclusion) {
          this.dsService.deleteDatasetRecord("cst_polOrca_it_reg_ex_aprov", approver.budgetPolicyItemRuleExcessApproverId).pipe(take(1)).subscribe(() => {
            this.deleteExcessRuleApproverControls(exRule, approver);
            this.lockScreen.emit(false);
          }, error => {
            this.lockScreen.emit(false);
          });
        } else {
          this.deleteExcessRuleApproverControls(exRule, approver);
          this.lockScreen.emit(false);
        }
      }
    })
  }

  private deleteExcessRuleApproverControls(exRule: BudgetPolicyItemExcessRule, approver: BudgetPolicyItemExcessRuleApprover) {
    Object.keys(this.formGroupExRuleApprov.controls).filter(id => id.indexOf(approver.budgetPolicyItemRuleExcessApproverId) >= 0).forEach(key => {
      this.formGroupExRuleApprov.removeControl(key);
    });

    let index = exRule.approverList.findIndex(app => app.budgetPolicyItemRuleExcessApproverId == approver.budgetPolicyItemRuleExcessApproverId);
    exRule.approverList.splice(index, 1);
  } 
}
