import { Component, Input, OnDestroy, Optional } from '@angular/core';
import { NbDialogRef } from "@nebular/theme";
import { VisualizationComponent } from "./visualization.component";
import { T2HttpClientService } from "../../http/t2httpClient.service";
import { take, takeUntil } from "rxjs/Operators";
import { Subject } from "rxjs";
import { VisualizationField } from "./t2-visualization.service";

@Component({
  selector: 'app-visualization-dialog',
  templateUrl: './visualization-dialog.component.html',
  styleUrls: ['./visualization-dialog.component.scss']
})
export class VisualizationDialogComponent implements OnDestroy {

  public savingDefVisualization = false;
  public saveAsDefault = false;
  private unsubscribe$ = new Subject<void>();
  private _showPinned: boolean = false

  @Input() queryMode = false;
  @Input() id_dataset: string;
  @Input() description: string;
  @Input() selectedFieldList: Array<VisualizationField>;
  @Input() fromDictionary = false;
  set showPinned(value) {
    if (value) this._showPinned = value;
  }
  get showPinned() {
    return this._showPinned;
  }

  constructor(private httpClient: T2HttpClientService,
    @Optional() protected dialogRef: NbDialogRef<VisualizationComponent>) { }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private saveDefaultVisualization() {
    this.savingDefVisualization = true;
    this.httpClient.post(`core.presentation/datasetDefaultVisualization/${this.id_dataset}`, null, this.selectedFieldList)
      .pipe(take(1), takeUntil(this.unsubscribe$))
      .subscribe(resp => {
        this.savingDefVisualization = false;
        this.callCloseDialog(true);
      }, error => {
        this.savingDefVisualization = false;
      });
  }

  private callCloseDialog(save: boolean) {
    this.dialogRef.close({ save, fields: (save ? this.selectedFieldList : null) });
  }

  closeDialog(save: boolean) {
    if (this.dialogRef) {
      if (save && (this.fromDictionary || this.saveAsDefault)) {
        this.saveDefaultVisualization();
      } else {
        this.callCloseDialog(save)
      }
    }
  }

}
