import { Component, VERSION, OnInit, HostListener, ViewChild, ElementRef } from '@angular/core';
import { NbDialogService, NbMenuService } from '@nebular/theme';
import { T2SecurityService } from './core/security/t2security.service';
import { Router } from '@angular/router';
import { T2ThemingService } from './core/t2theming.service';
import { T2Authentication } from './core/security/model/t2authentication';
import { T2HttpClientService } from "./core/http/t2httpClient.service";
import { environment } from '../environments/environment';
import { T2LocalStorageService } from './core/t2local-storage.service';
import * as AfxVersionJson from '../assets/afxversion.json';
import { HttpClient } from "@angular/common/http";
import { take } from "rxjs/Operators";
import { MenuComponent } from "./core/menu/menu.component";
import { ConfigComponent } from "./core/config/config.component";
import { UserInfoComponent } from "./core/user-info/user-info.component";
import { HelpComponent } from "./core/help/help.component";
import { AboutComponent } from "./core/about/about.component";
import { Observable } from "rxjs";


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  host: {
    '(document:click)': 'onClick($event)',
  }
})
export class AppComponent implements OnInit {

  @ViewChild("notificationsBar") notificationsBar: ElementRef;

  private inLogin = false;
  public userAuthenticated = false;
  public version: string;
  public companySiteName: string;
  public isHomolog: boolean;
  public themeName$: Observable<string>;
  public showNotificationsBar: boolean = false;

  contextMenu = [
    { title: "Filiais ", icon: "globe", key: "companySite", hidden: true, children: [] },
    { title: "Configurações ", icon: "settings-2", key: "settings" },
    { title: "Meus dados", icon: "person", key: "profile", hidden: environment.production },
    { title: "Ajuda", icon: "message-circle", key: "help", hidden: environment.production },
    { title: "Sobre o sistema", icon: "heart", key: "about" },
    { title: "Sair", icon: "power", key: "quit" },
  ];

  jasondata: string = "";
  constructor(
    private router: Router,
    public sec: T2SecurityService,
    private httpClient: T2HttpClientService,
    storage: T2LocalStorageService,
    public http: HttpClient,
    private dialogService: NbDialogService,
    private nbMenuService: NbMenuService,
    public themeService: T2ThemingService) {

    let intermediateJson = AfxVersionJson

    this.version = intermediateJson.afxversion + "." + intermediateJson.afxrelease;

    console.log(this.version);
    console.log("NG " + VERSION.full);

    this.techProdCustomer();

    themeService.changeThemeForThisSession('corporate');
    this.themeName$ = this.themeService.getThemeNameObservable();

    sec.checkAuthStatus();

    sec.authStatusObservable().subscribe((auth: T2Authentication) => {
      if (auth.authenticated) {
        // CARREGA TEMA DO USUARIO (SALVO NO STORAGE)
        sec.getAccessTree();
        sec.loadCompanySites();
        themeService.startTheme();
      } else {
        // A TELA DE LOGIN é SEMPRE BRANCA
        themeService.changeThemeForThisSession('corporate');
        sec.clearAccessTree();
        this.userAuthenticated = false;
      }

      if (!this.inLogin && !auth.authenticated) {
        this.inLogin = true;
        this.router.navigateByUrl('/login');
      } else if (this.inLogin && auth.authenticated) {
        this.inLogin = false;
        // this.router.navigateByUrl('/home');
      }

      this.httpClient.get("core.instance/isHomolog", null)
        .pipe(take(1))
        .subscribe(resp => {
          this.httpClient.isHomologEnvironment = resp.homolog;
          this.isHomolog = resp.homolog;

          this.userAuthenticated = auth.authenticated;

          sec.companySiteObservable().subscribe(id_companySite => {
            const site = sec.getCompanySite(id_companySite);
            this.companySiteName = site?.description;

            this.contextMenu[0].hidden = this.sec.sites?.length <= 1;
            this.contextMenu[0].children = this.sec.sites?.map(site => { return { title: site.description, key: "companySite", id_companySite: site.id_companySite }; });
          });
        });
    });
  }

  private techProdCustomer(): string {
    let url = window.location.href;
    this.httpClient.clientUrl = new URL(url).origin;

    if (url.toLowerCase().startsWith('https://')) {
      url = url.substring(8);
    } else if (url.toLowerCase().startsWith('http://')) {
      url = url.substring(7);
    }

    console.log("UR -> " + url);
    console.log("SW -> " + url.startsWith("localhost"));
    console.log("IO -> " + url.indexOf('techprod.com.br'));

    let companyID = null;
    if (url.startsWith("localhost") || url.startsWith("127.0.0.1")) {
      // Utilizado para testes internos
      companyID = environment.companyID;
    } else {
      const i = url.indexOf('techprod.com.br');
      if (i > 0) {
        companyID = url.substring(0, i - 1).toLowerCase();
      }
    }

    this.httpClient.setCompanyID(companyID);

    return companyID;
  }

  ngOnInit(): void {
    this.nbMenuService.onItemClick()
      .subscribe(menu => {

        switch (menu.item["key"]) {

          case "companySite":
            this.sec.setCompanySite(menu.item["id_companySite"]);
            break;

          case "settings":
            this.dialogService.open(ConfigComponent, { autoFocus: true, closeOnEsc: true, hasBackdrop: true });
            break;

          case "profile":
            this.dialogService.open(UserInfoComponent, { autoFocus: true, closeOnEsc: true, hasBackdrop: true });
            break;

          case "help":
            this.dialogService.open(HelpComponent, { autoFocus: true, closeOnEsc: true, hasBackdrop: true });
            break;

          case "about":
            this.dialogService.open(AboutComponent, { autoFocus: true, closeOnEsc: true, hasBackdrop: true });
            break;

          case "quit":
            this.sec.logoff();
            break;
        }
      });

  }

  goMenu() {
    const dialogRef = this.dialogService.open(MenuComponent, { autoFocus: true, closeOnEsc: true, hasBackdrop: true });
  }

  goHome() {
    this.router.navigateByUrl('/home');
  }

  changeStatusNotificationBar() {
    this.showNotificationsBar = !this.showNotificationsBar;
  }

  onClick(event) {
    if (this.showNotificationsBar && !this.notificationsBar?.nativeElement.contains(event.target)) {
      this.showNotificationsBar = false;
    }
  }
}

