import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'app-cil-status-renderer',
  templateUrl: './cil-status-renderer.component.html',
  styleUrls: ['./cil-status-renderer.component.scss']
})
export class CilStatusRendererComponent implements ICellRendererAngularComp {

  updating = false;
  status: string;
  statusND = false;
  statusOK = false;
  statusRev = false;
  statusGrav = false;
  statusRecromo = false;

  refresh(params: any): boolean {
    return true;
  }
  agInit(params: import("ag-grid-community").ICellRendererParams): void {
    if (params.data) {

      this.updating = params.data.updating;

      this.status = params.data.status;
      this.statusND = (params.data.status === "Não definido");
      this.statusOK = (params.data.status === "OK");
      this.statusRev = (params.data.status === "REVISAR");
      this.statusGrav = (params.data.status === "GRAVAR");
      this.statusRecromo = (params.data.status === "RECROMO");
    }
  }
  afterGuiAttached?(params?: import("ag-grid-community").IAfterGuiAttachedParams): void {
  }

}
