<div class="flexColumn flexGap5 divPrincipal" [nbSpinner]="loadingTransfer" nbSpinnerSize="giant"
  nbSpinnerStatus="primary">
  <div class="T2GrayArea flexAlignRowEndCenter flexGap5" style="padding: 5px;">
    <span class="defaultSmallTitle">Seleção manual de OP</span>
    <app-t2-input-text #inputSearch t2IdCmp="buscaOP" (keyDownEnter)="btnSearchClick()"></app-t2-input-text>
    <app-t2-button #buttonSearch t2IdCmp="btnBuscaOP" status="primary" size="small" icon="search-outline"
      (clicked)="btnSearchClick()"></app-t2-button>
  </div>
  <div style="flex: 1;">
    <div *ngIf="!userDevService.isSmallScreen;" class="flexColumn flexGap10" style="height: 100%;">
      <div class="divGrid">
        <app-t2grid #gridReq [pagination]="false" [rowDragManaged]="true" [gridStateName]="'gridTransfMpOP'"
          [component]="gridContextComponent" [loading]="loadingData" (rowClick)="onRowClicked($event)" />
      </div>
      <div class="divList">
        <app-separation-transfer *ngIf="selectedRequisition" [taskRequisition]="selectedRequisition"
          (loadingInfo)="loadingInfoTransfer($event)" (transferCompleted)="transferCompleted()"
          (reservationExit)="reservationExit()"></app-separation-transfer>
      </div>
    </div>
    <div *ngIf="userDevService.isSmallScreen" class="divReqListSmallScreen">
      <ng-container *ngTemplateOutlet="divReqList"></ng-container>
    </div>
  </div>

  <ng-template #divReqList>
    <div>
      <span class="defaultTitle">{{reqTitle}}</span>
      <div [nbSpinner]="loading" nbSpinnerSize="giant" nbSpinnerStatus="primary" cdkDropList
        [cdkDropListDisabled]="!hasCoordinatorAccess || !canChangeOrder" (cdkDropListDropped)="onDrop($event)"
        style="display: flex; flex-wrap: wrap; gap: 5px;">
        <div *ngFor="let requisition of pendingRequestList; let i = index" class="T2GrayAreaItemNoFill T2GrayHoverArea"
          [ngClass]="{'T2GraySelectedArea': requisition.taskId == selectedRequisition?.taskId}"
          style="margin-bottom: 5px; width: 100%;" cdkDrag [cdkDragStartDelay]="userDevService.isSmallScreen ? 300 : 0"
          [id]="'divDrag' + i" (click)="onReqClick(requisition)">
          <div>
            <app-requisition [requisition]="requisition"
              [hasCoordinatorAccess]="hasCoordinatorAccess"></app-requisition>
          </div>
        </div>
        <span *ngIf="pendingRequestList?.length == 0" class="defaultBigTitle">Não há requisições</span>
      </div>
    </div>
  </ng-template>
</div>
