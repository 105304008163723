<div class="date">
  <div class="dateTitle">
    {{timeDetail?.dayOfWeek}}
  </div>

  <div class="dateEvents flexColumn">
    <div *ngFor="let item of dateParts" class="dateEvent" [style.height]="item.perc+'%'"
      [ngClass]="item.timeRange?.color">
      <div *ngIf="!item.empty">
        <div style="line-height: 92%;">
          <span class="dateEventTitle">{{item.timeRange?.workShift}}</span>
          <span style="font-size: 85%;">
            <br>{{item.timeRange.startTime | date : 'HH:mm:ss'}} - {{item.timeRange.endTime | date : 'HH:mm:ss'}}
          </span>
        </div>

        <div id="editButtons">
          <button nbButton status="primary" size="tiny" (click)="editEvent(item)">
            <nb-icon icon="edit-outline"></nb-icon>
          </button>
          <button nbButton status="danger" size="tiny" (click)="removeEvent(item)">
            <nb-icon icon="trash-2-outline"></nb-icon>
          </button>
        </div>
      </div>

      <div *ngIf="item.empty" style="display: flex; justify-content: center; align-items: center; height: 100%;"
        (click)="editEvent(item)">
        <nb-icon id="emptyEvent" icon="plus-outline"></nb-icon>
      </div>
    </div>
  </div>
</div>
