import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { DialogTemplateComponent } from "../../dialog-template/dialog-template.component";
import { T2CheckBoxComponent } from "../../ui/t2-check-box/t2-check-box.component";
import { T2InputTextComponent } from "../../ui/t2-input-text/t2-input-text.component";
import { T2ViewTemplateData } from "../../view-template/model/t2-view-template-data";
import { LayoutType, ViewTemplateElement } from "../../view-template/model/view-template-element";

@Component({
  selector: 'app-analysis-edit',
  templateUrl: './analysis-edit.component.html',
  styleUrls: ['./analysis-edit.component.scss']
})
export class AnalysisEditComponent implements OnInit, AfterViewInit {

  @Input() newConfig = true;
  @Input() diffUser = false;
  @Input() description: string;
  @Input() showFilterOnLoad: boolean;
  @Input() publicAnalisys: boolean;
  @ViewChild('dialog', { static: true }) dialog: DialogTemplateComponent;

  formGroup: FormGroup;
  data: Array<T2ViewTemplateData> = [];
  layout: ViewTemplateElement[];

  constructor(private formBuilder: FormBuilder) {
    this.formGroup = formBuilder.group({});
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {

    const intLayout: ViewTemplateElement[] = [
      { layoutType: LayoutType.component, children: [] },
      { layoutType: LayoutType.gridLayout, children: [] }
    ];

    intLayout[0] = this.addCmp("description", "Descrição", null, this.description, true, { placeHolder: "Nome do modelo de visualização" });

    const cmp1 = this.addCmp("public", "Público", "Permite que outros usuários acessem esse modelo de visualização", this.publicAnalisys, true);
    cmp1.cmpType = T2CheckBoxComponent;
    cmp1.columnSpan = 1;
    intLayout[1].children.push(cmp1);

    const cmp2 = this.addCmp("showFilter", "Exibir filtro ao abrir", null, this.showFilterOnLoad, true);
    cmp2.cmpType = T2CheckBoxComponent;
    cmp2.columnSpan = 2;
    intLayout[1].children.push(cmp2);


    this.layout = [
      { layoutType: LayoutType.listLayout, direction: "column", children: [...intLayout] }
    ]
  }

  private addCmp(name: string, label: string, tooltip: string, value: any, required: boolean, properties?: any): ViewTemplateElement {

    const validators = [];
    if (required) {
      validators.push(Validators.required);
    }

    this.data.push({ cmpName: name, properties: properties || {} });
    this.formGroup.addControl(name, this.formBuilder.control(value, validators));
    const cmp = {
      layoutType: LayoutType.component,
      title: label,
      cmpName: name,
      toolTip: tooltip,
      isBaseComponent: true,
      cmpType: T2InputTextComponent,
      columnSpan: 3
    }

    return cmp;
  }


  confirm(makeCopy: boolean = false) {
    if (this.formGroup.valid) {
      this.dialog.close({
        makeCopy,
        description: this.formGroup.controls["description"].value,
        showFilterOnLoad: this.formGroup.controls["showFilter"].value,
        publicAnalisys: this.formGroup.controls["public"].value
      });
    }
  }

  cancel() {
    this.dialog.close(null);
  }

}
