import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { DialogTemplateComponent } from "src/app/core/cmp/dialog-template/dialog-template.component";

@Component({
  selector: 'app-sort-tasks-dialog',
  templateUrl: './sort-tasks-dialog.component.html',
  styleUrls: ['./sort-tasks-dialog.component.scss']
})
export class SortTasksDialogComponent implements OnInit {


  public field1: string = "";
  public field2: string = "";
  public field3: string = "";
  public isSelectionValid = false;

  @Input() fieldsList: Array<{ fieldName: string, fieldLabel: string }> = [];
  @ViewChild('dialog', { static: true }) dialog: DialogTemplateComponent;

  constructor() { }

  ngOnInit(): void {
  }

  closeDialog(action: string): void {

    if (action == "confirm") {

      const fields = [];
      if (this.field1) fields.push(this.field1);
      if (this.field2) fields.push(this.field2);
      if (this.field3) fields.push(this.field3);

      this.dialog.close(fields);
      return;
    };

    this.dialog.close(undefined);
  }

  selectChange(event: any): void {
    this.validateSelection();
  }

  private validateSelection(): void {

    const fields = [];
    let selection = 0;
    if (this.field1) {
      selection++;
      fields.push(this.field1);
    }

    if (this.field2) {
      selection++;
      if (!fields.includes(this.field2)) fields.push(this.field2);
    };

    if (this.field3) {
      selection++;
      if (!fields.includes(this.field3)) fields.push(this.field3);
    };

    this.isSelectionValid = selection && (selection == fields.length);
  }

}
