import { AfterViewInit, Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormTemplateComponent } from "src/app/core/cmp/form-template/form-template.component";
import { T2gridComponent } from "src/app/core/cmp/t2grid/t2grid.component";
import { TechprodDevService } from "../../techprod-dev.service";

@Component({
  selector: 'app-dict-dataset',
  templateUrl: './dict-dataset.component.html',
  styleUrls: ['./dict-dataset.component.scss']
})
export class DictDatasetComponent implements AfterViewInit {

  @ViewChild("formTemplate", { static: true }) formTemplate: FormTemplateComponent;
  @ViewChild('grid', { static: true }) grid: T2gridComponent;

  @Output() selectDataset = new EventEmitter<any>();

  private dados = [];

  constructor(private dev: TechprodDevService) { }

  ngAfterViewInit(): void {
    this.grid.loading = true;
    this.dev.getDicDataset().subscribe(resp => {
      this.dados = resp;
      this.grid.setGridData(this.dados);
      this.grid.autoSizeAllColumns(false);
      this.grid.loading = false;

    }, error => {
      this.grid.loading = false;
    });

    this.setGridColumns();
    this.grid.getDataPath = (data) => {
      this.setDatasetHier(data);


      return data.hier;
    };

  }

  private setDatasetHier(dataset: any, level: number = 0) {
    level++;

    dataset.id = dataset.id_dataset;

    if (dataset.id_dataset_ancestor) {
      const dataset_ancestor: any = this.dados.find((ds) => ds.id_dataset === dataset.id_dataset_ancestor);
      if (dataset_ancestor) {
        dataset.ancestor = dataset_ancestor.datasetName;
      }
    }

    if (dataset.hier) return dataset.hier;

    if (dataset.id_dataset_parent) {
      const dataset_parent: any = this.dados.find((ds) => ds.id_dataset === dataset.id_dataset_parent);
      if (dataset_parent) {
        if (!dataset_parent.hier) {
          this.setDatasetHier(dataset_parent, level);
        }

        dataset.hier = [...dataset_parent.hier, dataset.datasetName];
        return dataset.hier;
      }
    }

    dataset.hier = [dataset.informationGroup || ("# " + dataset.moduleName), dataset.datasetGroup, dataset.datasetName]
    return dataset.hier;
  }

  private setGridColumns() {
    this.grid.setGridColumnDefs([
      { headerName: "Description", field: "datasetDescription" },
      { headerName: "Dataset name", field: "datasetName" },
      { headerName: "Ancestor", field: "ancestor" },
      { headerName: "Group", field: "datasetGroup" },
      { headerName: "Module", field: "moduleName" },
      { headerName: "Info group", field: "informationGroup" },
      { headerName: "id_dataset", field: "id_dataset" },
    ]);
  }

  onSelectChange(params) {
    this.selectDataset.emit(params.data);
  }

}
