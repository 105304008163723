import { Component, Input, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from "@angular/forms";
import { take } from "rxjs/Operators";
import { DialogTemplateComponent } from "src/app/core/cmp/dialog-template/dialog-template.component";
import { Dataset } from "src/app/core/dataset/model/dataset";
import { T2DatasetService } from "src/app/core/dataset/t2dataset.service";

@Component({
  selector: 'app-dataset-controls',
  templateUrl: './dataset-controls.component.html',
  styleUrls: ['./dataset-controls.component.scss']
})
export class DatasetControlsComponent {
  @ViewChild('dialog', { static: true }) dialog: DialogTemplateComponent;

  @Input()
  get dataset(): Dataset {
    return this._dataset;
  }
  set dataset(value: Dataset) {
    this._dataset = value;
    this.configFormGroup();
  }

  private _dataset: Dataset;
  public formGroup: FormGroup;
  public saving: boolean;

  constructor(private formBuilder: FormBuilder, private dsService: T2DatasetService) {
    this.formGroup = this.formBuilder.group({});
    this.formGroup.addControl("allowApprovalPolicy", this.formBuilder.control(false));
  }

  private configFormGroup() {
    this.formGroup.controls["allowApprovalPolicy"].setValue(this.dataset?.allowApprovalPolicy);
  }

  public closeDialog(save: boolean) {
    if (save) {
      this.saving = true;
      this.dsService.changeRelatedCtrl(this.dataset.datasetName, "policy", this.formGroup.controls["allowApprovalPolicy"].value)
        .pipe(take(1))
        .subscribe(resp => {
          this.saving = false;
          this.dataset.allowApprovalPolicy = this.formGroup.controls["allowApprovalPolicy"].value;

          this.dialog.close(save);
        }, error => {
          this.saving = false;
        })
    } else {
      this.dialog.close(save);
    }
  }
}
