import { Component } from '@angular/core';
import { ICellRendererAngularComp } from "ag-grid-angular";
import { ICellRendererParams } from "ag-grid-community";

@Component({
  selector: 'app-task-urgent-cell-renderer',
  templateUrl: './task-urgent-cell-renderer.component.html',
  styleUrls: ['./task-urgent-cell-renderer.component.scss']
})
export class TaskUrgentCellRendererComponent implements ICellRendererAngularComp {
  urgent: boolean;

  agInit(params: ICellRendererParams<any, any>): void {
    this.urgent = params?.data?.urgent;
  }
  refresh(params: ICellRendererParams<any, any>): boolean {
    return true;
  }

}
