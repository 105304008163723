import { AfterViewInit, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ProdOrderReleaseService } from "../prod-order-release.service";
import { ConjugationInfo } from "../model/conjugation-info";
import { ReleaseProdOrderInfo } from "../model/release-prod-order-info";
import { take } from "rxjs/Operators";
import { T2gridComponent } from "src/app/core/cmp/t2grid/t2grid.component";
import { ConjugationItemInfo } from "../model/conjugation-item-info";
import { ConjugationActionRendererComponent } from "./conjugation-action-renderer/conjugation-action-renderer.component";
import { NbDialogService } from "@nebular/theme";
import { DialogComponent } from "src/app/core/cmp/dialog/dialog.component";

@Component({
  selector: 'app-conjugation',
  templateUrl: './conjugation.component.html',
  styleUrls: ['./conjugation.component.scss']
})
export class ConjugationComponent implements AfterViewInit {

  @ViewChild("gridConjCompat", { static: true }) gridConjCompat: T2gridComponent;

  @Input()
  get releaseInfo(): ReleaseProdOrderInfo {
    return this._releaseInfo;
  }
  set releaseInfo(value: ReleaseProdOrderInfo) {
    this.selectedReleaseInfo = value;

    if (this.selectedReleaseInfo) {
      if (!this.holdConjugation) {
        this._releaseInfo = value;
        this.loadConjugationInfo();
      }
    }
  }
  @Input() conjugationReleaseInfoList: Array<ReleaseProdOrderInfo>;

  @Output() assignedConjugation = new EventEmitter<{ specificationId: string, demandIdList: Array<string> }>();
  @Output() executingOperation = new EventEmitter<boolean>();
  @Output() demandIdFromConjugation = new EventEmitter<Array<string>>();

  private _releaseInfo: ReleaseProdOrderInfo;
  private selectedReleaseInfo: ReleaseProdOrderInfo;
  public holdConjugation: boolean;
  public conjugationInfo: ConjugationInfo;
  public loading: boolean = false;
  public lockScreen: boolean = false;
  public gridComponent = {
    actionRenderer: ConjugationActionRendererComponent,
  }

  constructor(private releaseService: ProdOrderReleaseService, private dlgService: NbDialogService) { }

  ngAfterViewInit(): void {
    this.gridConjCompat.setGridColumnDefs([
      { headerName: "Ações", width: 90, cellRenderer: "actionRenderer", valueGetter: (params) => params.data.specificationId == this.releaseInfo.assignedPsConjugationId, },
      { headerName: "Código", field: "code", width: 90 },
      { headerName: "Descrição", field: "description", width: 200 }
    ]);
  }

  loadConjugationInfo() {
    this.loading = true;
    this.releaseService.getConjugationInfo(this.releaseInfo?.id, this.releaseInfo?.type)
      .pipe(take(1))
      .subscribe(cjInfo => {
        this.conjugationInfo = cjInfo;
        this.demandIdFromConjugation.emit(this.conjugationInfo.itemList?.map(it => it.demandId));

        this.conjugationInfo.psCompatList.forEach(ps => {
          ps.setSpecificationForConjugation = this.setSpecifForConj.bind(this);
        })

        this.gridConjCompat.setGridData(this.conjugationInfo.psCompatList);

        this.loading = false;
      }, error => {
        this.conjugationInfo = undefined;
        this.gridConjCompat.setGridData([]);
        this.loading = false;
      })
  }

  setHoldConjugation() {
    this.holdConjugation = !this.holdConjugation;

    if (this.selectedReleaseInfo?.id != this.releaseInfo?.id) {
      this.releaseInfo = this.selectedReleaseInfo;
    }
  }

  addDemandToConjugation() {
    this.lockScreen = true;
    this.executingOperation.next(true);
    this.releaseService.addPsToConjugation(this.selectedReleaseInfo, this.conjugationInfo.itemList?.length ? this.releaseInfo : null)
      .pipe(take(1))
      .subscribe(resp => {
        this.loadConjugationInfo();
        this.lockScreen = false;
        this.executingOperation.next(false);
      }, error => {
        this.lockScreen = false;
        this.executingOperation.next(false);
      })
  }

  removeDemandFromConjugation(item: ConjugationItemInfo) {
    let dlg = this.dlgService.open(DialogComponent, {
      context: {
        message: `Deseja realmente excluir o item "${item.itemDescription}" da conjugação ?`,
        actions: [{ description: "Sim" }, { description: "Não", status: "basic" }]
      }
    });

    dlg.onClose.pipe(take(1)).subscribe(resp => {
      if (resp == "Sim") {
        this.lockScreen = true;
        this.executingOperation.next(true);
        this.releaseService.removePsFromConjugation(item.demandId, item.type).pipe(take(1)).subscribe(resp => {
          if (this.holdConjugation && this.conjugationInfo.itemList?.length > 2 && item.demandId == this.releaseInfo.id) {
            this._releaseInfo = this.conjugationReleaseInfoList.find(r => r.id != item.demandId);
          }

          this.loadConjugationInfo();
          this.lockScreen = false;
          this.executingOperation.next(false);
        }, error => {
          this.lockScreen = false;
          this.executingOperation.next(false);
        })
      }
    })

  }

  setSpecifForConj(specificationId: string) {
    this.loading = true;
    this.executingOperation.next(true);
    let specifId = this.releaseInfo.assignedPsConjugationId != specificationId ? specificationId : null;
    this.releaseService.setProdSpecifForConjugation(this.releaseInfo.id, this.releaseInfo.type, specifId)
      .pipe(take(1))
      .subscribe(resp => {
        this.releaseInfo.assignedPsConjugationId = specifId;
        this.gridConjCompat.redrawRows();
        this.assignedConjugation.emit({ specificationId: specifId, demandIdList: this.conjugationInfo.itemList.map(it => it.demandId) });
        this.loading = false;
        this.executingOperation.next(false);
      }, error => {
        this.loading = false;
        this.executingOperation.next(false);
      })
  }

  createEpForConj() {
    let msg = this.conjugationInfo.psCompatList?.length ? "Já existe EP para essa conjugação. Deseja criar uma nova ?" : "Deseja criar uma EP para essa conjugação ?";

    let dlg = this.dlgService.open(DialogComponent, {
      context: {
        message: msg,
        actions: [{ description: "Sim" }, { description: "Não", status: "basic" }]
      }
    });

    dlg.onClose.pipe(take(1)).subscribe(resp => {
      if (resp == "Sim") {
        this.lockScreen = true;
        this.executingOperation.next(true);
        this.setHoldConjugation();
        this.releaseService.createEpForConjugation(this.conjugationInfo.conjugationItemId, this.releaseInfo.itemId)
          .pipe(take(1))
          .subscribe(resp => {
            this.loadConjugationInfo();
            this.setSpecifForConj(resp.id_specification);
            this.lockScreen = false;
            this.executingOperation.next(false);
          }, error => {
            this.lockScreen = false;
            this.executingOperation.next(false);
          });
      }
    });
  }

  clearSelection() {
    this.holdConjugation = false;
    this.conjugationInfo = undefined;
    this._releaseInfo = undefined;
    this.selectedReleaseInfo = undefined;
  }
}
