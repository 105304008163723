import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { T2NebularModule } from "src/app/t2nebular.module";
import { T2CoreModule } from "src/app/core/t2core.module";
import { ApsSequenceComponent } from "./visualization/sequencing/aps-sequence/aps-sequence.component";
import { ApsComponent } from "./aps.component";
import { FixedDateDialogComponent } from './visualization/fixed-date-dialog/fixed-date-dialog.component';
import { FirmDeadlineDialogComponent } from './visualization/firm-deadline-dialog/firm-deadline-dialog.component';
import { TimeReservationComponent } from './visualization/time-reservation/time-reservation.component';
import { ChangeDeviceDialogComponent } from './visualization/change-device-dialog/change-device-dialog.component';
import { ToolBarComponent } from './tool-bar/tool-bar.component';
import { CompareSimulationComponent } from './visualization/compare-simulation/compare-simulation.component';
import { ProgrammingComponent } from './tool-bar/programming/programming.component';
import { AnalysisApsComponent } from './tool-bar/analysis-aps/analysis-aps.component';
import { KpiComponent } from './tool-bar/kpi/kpi.component';
import { MachineLoadComponent } from './analysis/machine-load/machine-load.component';
import { ApsAnalysisComponent } from './visualization/aps-analysis/aps-analysis.component';
import { ApsKPIComponent } from './visualization/aps-kpi/aps-kpi.component';
import { TaskDetailComponent } from './task-detail/task-detail.component';
import { CylinderDetailComponent } from './cylinder-detail/cylinder-detail.component';
import { TaskStatusCellRendererComponent } from '../utils/task-status-cell-renderer/task-status-cell-renderer.component';
import { SequenceTooltipComponent } from './visualization/sequencing/sequence-tooltip/sequence-tooltip.component';
import { EnvironmentSelectionComponent } from './visualization/environment-selection/environment-selection.component';
import { ApsGanttVisComponent } from './visualization/gantt/aps-gantt-vis/aps-gantt-vis.component';
import { MachineLoadTableComponent } from './analysis/machine-load/machine-load-table/machine-load-table.component';
import { WorkScheduleDeviceSelectionComponent } from './visualization/work-schedule-device-selection/work-schedule-device-selection.component';
import { SortTasksDialogComponent } from './visualization/sort-tasks-dialog/sort-tasks-dialog.component';
import { TaskStartdateCellRendererComponent } from './visualization/sequencing/task-startdate-cell-renderer/task-startdate-cell-renderer.component';

@NgModule({
  declarations: [
    ApsSequenceComponent,
    ApsComponent,
    FixedDateDialogComponent,
    FirmDeadlineDialogComponent,
    TimeReservationComponent,
    ChangeDeviceDialogComponent,
    ToolBarComponent,
    CompareSimulationComponent,
    ProgrammingComponent,
    AnalysisApsComponent,
    KpiComponent,
    MachineLoadComponent,
    ApsAnalysisComponent,
    ApsKPIComponent,
    TaskDetailComponent,
    CylinderDetailComponent,
    TaskStatusCellRendererComponent,
    SequenceTooltipComponent,
    EnvironmentSelectionComponent,
    ApsGanttVisComponent,
    MachineLoadTableComponent,
    WorkScheduleDeviceSelectionComponent,
    SortTasksDialogComponent,
    TaskStartdateCellRendererComponent,
  ],
  imports: [
    CommonModule,
    T2NebularModule,
    T2CoreModule,
  ]
})
export class ApsModule { }
