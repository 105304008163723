import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'minutesToHours'
})
export class MinutesToHoursPipe implements PipeTransform {

  transform(minutes: number): string {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = Math.max(minutes % 60, 0);

    if (hours > 0 && hours < 24) {
      return `${hours}h ${Math.floor(remainingMinutes)}m`;
    } else if (hours > 24) {
      let days = Math.floor(hours / 24);
      let h = Math.max(hours % 24, 0);
      let m = Math.floor(remainingMinutes);
      return `${days}d ${h}h ${m}m`
    } else {
      return `${Math.floor(remainingMinutes)}m`;
    }
  }

}
