import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EPComponent } from "./ep/ep.component";
import { T2NebularModule } from "src/app/t2nebular.module";
import { T2CoreModule } from "src/app/core/t2core.module";
import { EPCharacteristicComponent } from './ep/epcharacteristic/epcharacteristic.component';
import { EPCharacteristicNavigationComponent } from './ep/epcharacteristic-navigation/epcharacteristic-navigation.component';
import { FilterCharacProcessPipe } from './ep/filter-charac-process.pipe';
import { ProcessOptionMapPipe } from './ep/epcharacteristic-navigation/process-option-map.pipe';
import { ComponentService } from "src/app/core/cmp/component.service";
import { DimensionComponent } from './ep/dimension/dimension.component';
import { MeasurementParametersComponent } from './ep/measurement-parameters/measurement-parameters.component';
import { InsertEPDialogComponent } from './ep/insert-epdialog/insert-epdialog.component';
import { InsertEpModelDialogComponent } from './ep/insert-ep-model-dialog/insert-ep-model-dialog.component';

@NgModule({
  declarations: [
    EPComponent,
    EPCharacteristicComponent,
    EPCharacteristicNavigationComponent,
    FilterCharacProcessPipe,
    ProcessOptionMapPipe,
    DimensionComponent,
    MeasurementParametersComponent,
    InsertEPDialogComponent,
    InsertEpModelDialogComponent,
  ],
  imports: [
    CommonModule,
    T2NebularModule,
    T2CoreModule,
  ]
})
export class EPPModule { 
  constructor(private cmpService: ComponentService) {
    this.cmpService.registerType("EPComponent", EPComponent);
    this.cmpService.registerType("MeasurementParametersComponent", MeasurementParametersComponent);
    this.cmpService.registerType("InsertEPDialogComponent", InsertEPDialogComponent);
    this.cmpService.registerType("InsertEpModelDialogComponent", InsertEpModelDialogComponent);
  }  
}
