import { environment } from './../../../../../../environments/environment';
import { ChangeDetectorRef, Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { NbDialogService } from "@nebular/theme";
import { BehaviorSubject, Subject } from "rxjs";
import { take, takeUntil } from "rxjs/Operators";
import { DialogComponent } from "src/app/core/cmp/dialog/dialog.component";
import { T2MessageService } from "src/app/core/t2-message.service";
import { ApsService } from "../../aps.service";
import { CompareSimulationComponent } from "../../visualization/compare-simulation/compare-simulation.component";
import { FirmDeadlineDialogComponent } from "../../visualization/firm-deadline-dialog/firm-deadline-dialog.component";

@Component({
  selector: 'app-programming',
  templateUrl: './programming.component.html',
  styleUrls: ['./programming.component.scss']
})
export class ProgrammingComponent implements OnInit, OnDestroy {

  @Output() fixedDateClick = new EventEmitter<boolean>();
  @Output() recalculateClick = new EventEmitter<any>();
  @Output() timeReservationClick = new EventEmitter<any>();
  @Output() swapDeviceClick = new EventEmitter<any>();
  @Output() sortSelctedTasksClick = new EventEmitter<any>();
  @Output() lockScreen = new EventEmitter<boolean>();

  private unsubscribe = new Subject<void>();
  public simulationOutOfDate = new BehaviorSubject<boolean>(false);
  public outOfDateSpinner: boolean = false;
  public progEnvironment: string;
  public isProductionEnvironment: boolean = false;

  constructor(private messageService: T2MessageService,
    public apsService: ApsService,
    private dialogService: NbDialogService,
    private changeRef: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.checkSimulationOutOfDate();

    this.apsService.getSimulationSelected()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(env => {
        this.progEnvironment = env?.id || undefined;
        this.isProductionEnvironment = env?.real || false;
        this.changeRef.detectChanges();
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  public setFirmDeadline() {
    this.apsService.getSimulationSelectedId()
      .pipe(take(1))
      .subscribe(simuId => {
        if (!simuId) {
          this.messageService.showToast("Nenhuma simulação selecionada", "Atenção", "warning");
          return;
        }

        let dlg = this.dialogService.open(FirmDeadlineDialogComponent, {
          context: {
            firmDeadlineDays: this.apsService.getSimulationFirmDeadline()
          },
          autoFocus: true, closeOnEsc: true, hasBackdrop: true
        });

        dlg.onClose
          .pipe(take(1))
          .subscribe(resp => {
            if (resp) {
              if (resp.firmDeadline) {
                this.apsService.setFirmDeadline(resp.days).catch(error => {
                  this.messageService.showToastError(error);
                  console.error(error);
                })
              } else {
                this.apsService.removeFirmDeadline().catch(error => {
                  this.messageService.showToastError(error);
                  console.error(error);
                })
              }
            }
          });
      })
  }

  public setFixedDate(status: boolean) {
    this.fixedDateClick.emit(status);
  }

  public recalculate() {
    this.recalculateClick.emit();
  }

  public timeReservation() {
    this.timeReservationClick.emit();
  }

  public swapDevice() {
    this.swapDeviceClick.emit();
  }

  public sortSelectedTasks() {
    this.sortSelctedTasksClick.emit();
  }

  public saveSimulation() {
    let focusedCmp = this.apsService.programmingVars.focusedCmp;
    let message: string;

    let deviceIdList = new Array<string>();
    switch (focusedCmp) {
      case "sequencing": {
        let devId = this.apsService.programmingVars.deviceSelectedId.getValue();

        if (!devId) {
          this.messageService.showToast("Nenhum dispositivo selecionado !!", "ATENÇÃO", "warning");
          return;
        }

        message = "Deseja aplicar a simulação do dispositivo selecionado à programação real ?";
        deviceIdList.push(devId);
        break;
      }

      case "gantt": {
        let ganttDevIds = this.apsService.programmingVars.ganttDeviceSelectedIdList.getValue();


        if (!ganttDevIds || ganttDevIds.length == 0) {
          this.messageService.showToast("Nenhum dispositivo selecionado !!", "ATENÇÃO", "warning");
          return;
        }

        deviceIdList.push(...ganttDevIds);

        message = "Deseja aplicar a simulação do(s) dispositivo(s) selecionado(s) à programação real ?";

        break;
      }
    }

    let dlg = this.dialogService.open(DialogComponent, {
      context: {
        title: "Aplicar programação",
        topMessage: message,
        actions: [{ description: "Sim" }, { description: "Não", status: "basic" }]
      },
      autoFocus: true, closeOnEsc: true, hasBackdrop: true
    });

    dlg.onClose
      .pipe(take(1))
      .subscribe(resp => {
        if (resp == "Sim") {
          this.lockScreen.emit(true);
          this.apsService.saveSimulationInDB(deviceIdList).then(() => {
            this.lockScreen.emit(false);
          }, error => {
            this.lockScreen.emit(false);
            console.error(error);
            this.messageService.showToastError(error);
          })

        }
      });
  }

  public updateSimulation() {
    if (this.apsService.isRealSimulation()) {
      this.lockScreen.emit(true);
      this.apsService.updateRealSimulation().then(() => this.lockScreen.emit(false), error => {
        this.lockScreen.emit(false);
        this.messageService.showToastError(error);
        console.error(error);
      })
    } else {
      this.apsService.isSimulationOutOfDate()
        .pipe(take(1))
        .subscribe(isOutOfDate => {
          if (!isOutOfDate) {
            this.outOfDateSpinner = true;

            let deviceIdList: Array<string> = [];
            if (this.apsService.programmingVars.showComponent.sequencing && this.apsService.programmingVars.deviceSelectedId?.getValue()) {
              deviceIdList.push(this.apsService.programmingVars.deviceSelectedId.getValue());
            } else if (this.apsService.programmingVars.showComponent.gantt && this.apsService.programmingVars.ganttDeviceSelectedIdList?.getValue()) {
              deviceIdList.push(...this.apsService.programmingVars.ganttDeviceSelectedIdList.getValue());
            }

            if (deviceIdList.length == 0) {
              this.messageService.showToast("Nenhum dispositivo selecionado", "ATENÇÃO", "warning");
              this.outOfDateSpinner = false;
              return;
            } else {
              let promList = new Array<Promise<void>>();

              for(let devId of deviceIdList) {
                promList.push(this.apsService.compareSimulationWithDB(devId))
              }

              Promise.all(promList).then(() => {
                this.outOfDateSpinner = false;
              }, error => {
                this.messageService.showToastError(error);
                console.error(error);
              });
            }
          } else {
            let data = this.apsService.getSimulationNewData();

            let dlg = this.dialogService.open(CompareSimulationComponent, {
              context: {
                removedPlannings: data.removedPlans,
                newPlannings: data.newPlans
              }
            });

            dlg.onClose
              .pipe(take(1))
              .subscribe(resp => {
                if (resp == "Confirma") {
                  this.apsService.updateSimulation().catch(error => {
                    this.messageService.showToastError(error);
                    console.error(error);
                  })
                }
              });
          }
        });
    }
  }

  public checkSimulationOutOfDate() {
    this.apsService.isSimulationOutOfDate()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(outOfDate => {
        this.simulationOutOfDate.next(outOfDate);
        this.changeRef.detectChanges();
      })
  }

  public selectSequencingDevice() {
    const ids = this.apsService.programmingVars.deviceSelectedId.value ? [this.apsService.programmingVars.deviceSelectedId.value] : [];
    this.apsService.openDeviceSelection('none', 'single', false, ids).subscribe(selectedDevices => {
      if (selectedDevices?.items && selectedDevices?.items.length > 0) {
        this.apsService.programmingVars.deviceSelectedId.next(selectedDevices.items[0]);
      }
    });
  }

  public selectGanttDevices() {
    const ids = this.apsService.programmingVars.ganttDeviceSelectedIdList.value ? this.apsService.programmingVars.ganttDeviceSelectedIdList.value : [];
    this.apsService.openDeviceSelection('multiple', 'multiple', true, ids, 'Selecione um ou mais grupos de dispositivos').subscribe(groupsDevices => {
      if (groupsDevices?.items && groupsDevices?.items.length > 0) {
        this.apsService.programmingVars.ganttDeviceSelectedIdList.next(groupsDevices.items);
      }
    })

  }

  public selectListDevices() {

  }

  public showComponent(cmpName: string) {
    this.apsService.showProgrammingCmp(cmpName);
    this.apsService.setProgrammingFocusedComponent(cmpName);
  }

  public editWorkSchedule() {
    this.apsService.editWorkScheduleSimulationDevices(this.progEnvironment);
  }

  public isAllSelectedPlansFixedDate(): boolean {
    return this.apsService.programmingVars.planSelectedList.value.every(tp => tp.fixedDate);
  }

  public canSelectedPlansSwapDevice(): boolean {
    return this.apsService.programmingVars.planSelectedList.value.every(tp => !tp.started);
  }

  public canSetFirmDeadline() : boolean {
    return this.apsService.canSetFirmDeadline();
  }

  public canRecalculate(): boolean {
    return this.apsService.canRecalculate();
  }

  public canApplySimulation(): boolean {
    return this.apsService.canApplySimulation();
  }

  public canEditWorkSchedule(): boolean {
    return this.apsService.canEditWorkSchedule();
  }

  public canDoTimeReservation(): boolean {
    return this.apsService.canDoTimeReservation();
  }

  public canSwapDevice(): boolean {
    return this.apsService.canSwapDevice();
  }

  public canSortSelectedTasks(): boolean {
    return this.apsService.canSortSelectedTasks();
  }

  public canSetFixedDate(): boolean {
    return this.apsService.canSetFixedDate();
  }
}
