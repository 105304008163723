import { environment } from '../../../environments/environment';
import { T2CompanySite } from './model/t2CompanySite';
import { Injectable } from "@angular/core";
import { LicenseType, T2HttpClientService } from "../http/t2httpClient.service";
import { T2LocalStorageService } from "../t2local-storage.service";
import { Observable, BehaviorSubject, Subject } from "rxjs";
import { T2Authentication } from "./model/t2authentication";
import { WSResponse } from "../http/WSResponse";
import { T2AccessGroup } from "./model/t2accessGroup";
import { T2AccessItem, T2AccessItemType, T2AccessItemDatasetActionType, T2AccessInformationType } from "./model/t2accessItem";
import { take, map, skipWhile } from "rxjs/Operators";
import { TechprodDevService } from "../dev/techprod-dev.service";


export class WSResponseLogin extends WSResponse {



  // tslint:disable-next-line: variable-name
  public id_session?: string;
  public userDescription?: string;
  public id_security_identity?: string;

  constructor() {
    super();
  }
}

@Injectable({
  providedIn: "root"
})
export class T2SecurityService {

  private accessGroups = new Array<T2AccessGroup>();
  private accessList = new Array<T2AccessItem>();
  private loadingAccess: boolean;
  private subAccessLoaded$ = new BehaviorSubject<boolean>(false);
  private subAccessGroup$ = new BehaviorSubject<Array<T2AccessGroup>>([]);
  private subAccessList$ = new BehaviorSubject<Array<T2AccessItem>>([]);
  private subFavAccessList$ = new BehaviorSubject<Array<T2AccessItem>>([]);
  private subCompanySite$ = new BehaviorSubject<string>(null);
  private accGroupLoaded: boolean = false;
  private accListLoaded: boolean = false;
  private accLoaded: boolean = false;
  private companyId: string;

  public sites: Array<T2CompanySite> = [];
  public userName: string;

  constructor(
    private storage: T2LocalStorageService,
    private httpClient: T2HttpClientService,
    private devService: TechprodDevService) {
    this.loadingAccess = false;

    this.subAccessGroup$.pipe(skipWhile(list => !list.length), take(1)).subscribe(() => {
      this.accGroupLoaded = true;

      if (this.accListLoaded) {
        this.accListLoaded = true;
        this.subAccessLoaded$.next(true);
      }
    });

    this.subAccessList$.pipe(skipWhile(list => !list.length), take(1)).subscribe(() => {
      this.accListLoaded = true;

      if (this.accGroupLoaded) {
        this.accListLoaded = true;
        this.subAccessLoaded$.next(true);
      }
    })

    this.httpClient.clientID = this.storage.getData("$s2");

    if (this.httpClient.clientID == null) {
      // const min = Math.ceil(1000000);
      // const max = Math.floor(9999999);
      this.httpClient.clientID = Math.random().toString(36).substring(2, 18).toUpperCase();
      this.storage.setData("$s2", this.httpClient.clientID);
    }
  }

  public login(username: string, password: string) {
    const params = new Map<string, string>();
    params.set("userName", username);
    params.set("password", password);

    const obs$ = this.httpClient.get("core.sec.auth/logon", params);
    const subs$ = obs$.subscribe(resp => {
      if (resp) {

        subs$.unsubscribe();

        const r = this.httpClient.checkResponse(resp, WSResponseLogin);
        if (r.error) {
          this.httpClient.updateAuthStatus(new T2Authentication(false, true, r.errorMessage));
        } else {
          this.storage.setData("$s1", r.id_session);
          this.storage.setData("TPuserName", r.userDescription);
          this.userName = r.userDescription;
          this.httpClient.updateAuthStatus(new T2Authentication(true, false, null, false, r.id_security_identity));
        }
      }
    }, error => {
      console.log(error);
      this.httpClient.updateAuthStatus(new T2Authentication(false, false, error.message, true));
    });

    return this.httpClient.authStatusObservable();
  }

  public logoff() {
    this.clearAccessTree();
    this.storage.removeData("$s1");
    this.storage.removeData("TPuserName");
    this.storage.removeData("accessTree");
    this.storage.removeData("tpserver");
    this.storage.removeData("companySite");

    this.httpClient.updateAuthStatus(new T2Authentication(false, false, null));
  }

  public checkAuthStatus() {
    this.httpClient.checkAuthStatus();
  }

  public authStatusObservable(): Observable<T2Authentication> {
    return this.httpClient.authStatusObservable();
  }

  public loadCompanySites(): void {

    this.httpClient.get("core.sec.access/userCompanySites", null)
      .pipe(
        take(1),
        map(resp => {
          if (resp.companySites) {
            if (!Array.isArray(resp.companySites.site))
              resp.companySites.site = [resp.companySites.site];

            return resp.companySites.site.filter((site: T2CompanySite) => site.hasAccess);
          }

          return [];
        })
      ).subscribe((sites: Array<T2CompanySite>) => {
        this.sites = sites;

        let id = this.storage.getData("companySite");
        let defaultSite = this.sites.find(site => site.defaultSite);

        if (id && this.sites.some(site => site.id_companySite == id)) {
          this.httpClient.id_companySite = id;
        } else {
          if (defaultSite && !this.httpClient.id_companySite) {
            this.httpClient.id_companySite = defaultSite.id_companySite;
          } else if (!defaultSite && this.sites.length > 0) {
            defaultSite = this.sites[0];
            this.httpClient.id_companySite = defaultSite.id_companySite;
          }
        }

        this.subCompanySite$.next(this.httpClient.id_companySite);
      });
  }

  public loadCompanyId() {
    return new Promise<string>((resolve, reject) => {
      if (this.companyId) {
        resolve(this.companyId);
      } else {
        this.httpClient.get("core.company/getCompanyId", null).pipe(take(1)).subscribe(resp => {
          this.companyId = resp.companyId;
          resolve(this.companyId);
        }, error => {
          reject(error);
        });
      }
    });
  }

  public companySiteObservable() {
    return this.subCompanySite$.asObservable();
  }

  public getCompanySite(id_companySite: string) {
    return this.sites.find(site => site.id_companySite == id_companySite);
  }

  public setCompanySite(id_companySite: string) {
    if (this.httpClient.id_companySite != id_companySite) {
      if (this.sites.find(site => site.id_companySite == id_companySite)) {
        this.httpClient.id_companySite = id_companySite;
        this.storage.setData("companySite", id_companySite);
        this.subCompanySite$.next(id_companySite);
      }
    }

  }

  public getAccessTree(): Observable<Array<T2AccessGroup>> {
    if (!this.loadingAccess) {
      this.loadingAccess = true;
      this.loadAccessGroups();
    }

    return this.subAccessGroup$.pipe(skipWhile(list => !list.length))
  }

  public getAccesList(): Observable<Array<T2AccessItem>> {
    if (!this.loadingAccess) {
      this.loadingAccess = true;
      this.loadAccessGroups();
    }

    return this.subAccessList$.pipe(skipWhile(list => !list.length))
  }

  public accessLoaded(): Observable<boolean> {
    return this.subAccessLoaded$.pipe(skipWhile(loaded => !loaded));
  }

  public clearAccessTree() {
    this.loadingAccess = false;
    this.accessGroups = [];
    this.accessList = [];
  }

  public setFavAccess(item: T2AccessItem): Observable<T2AccessItem> {

    const subFavAccess$ = new Subject<T2AccessItem>();

    this.getAccesList().pipe(take(1)).subscribe(items => {


      // this.accessGroups.forEach(group => {
      //   const accessItem = group.accessList.find(item => item.id == id);
      //   accessItem.favItem = !accessItem.favItem;
      // });
      let accessItem: T2AccessItem = null;
      if (!item.favItem) {
        items.forEach(i => {
          if (item.id_dataset && i.id_dataset == item.id_dataset && i.favItem) {
            i.favItem = !i.favItem;
          } else if (!item.id_dataset) {
            item.id == i.id ? i.favItem = !i.favItem : undefined;
          }
        });
      } else {
        accessItem = items.find(i => {
          return (i.id_dataset == item.id_dataset && i.id == item.id) || (!item.id_dataset && i.id == item.id);
        });
      }

      if (accessItem) {
        accessItem.favItem = !accessItem.favItem;
      }

      subFavAccess$.next(item);
      subFavAccess$.complete();

      const favMenu = items.filter(item => item.favItem);
      const favMenuL = favMenu.filter((value, index, arr) => arr.findIndex(t => t.id_dataset ? t.id_dataset == value.id_dataset : t.id == value.id) === index);
      this.httpClient.put("core.sec.access.menu/favMenu", null, favMenuL.map(item => item.id_dataset || item.id)).pipe(take(1)).subscribe(resp => { });

      this.subFavAccessList$.next(favMenuL);
    });

    return subFavAccess$.asObservable();
  }

  public getFavAccessList(): Observable<Array<T2AccessItem>> {

    this.getAccesList().pipe(take(1)).subscribe(items => {
      this.subFavAccessList$.next(items.filter(item => item.favItem).filter((value, index, arr) => arr.findIndex(t => t.id_dataset ? t.id_dataset == value.id_dataset : t.id == value.id) === index));
    });

    return this.subFavAccessList$.asObservable();
  }

  private loadFavAccess() {
    this.httpClient.get("core.sec.access.menu/favMenu", null).pipe(take(1)).subscribe(resp => {

      if (resp && resp.favMenuList) {
        if (!Array.isArray(resp.favMenuList)) {
          resp.favMenuList = [resp.favMenuList];
        }

        const favList = [];

        resp.favMenuList.forEach((id: string) => {
          const favMenu = this.accessList.find(access => access.id_dataset == id || access.id == id);
          if (favMenu) {
            favMenu.favItem = true;
            favList.push(favMenu);
          }

          this.accessGroups.forEach(group => {
            const favMenu = group.accessList.find(access => access.id_dataset == id || access.id == id);
            if (favMenu) favMenu.favItem = true;

          });
        });

        this.subFavAccessList$.next(favList);
      }

    });
  }

  private showAction(action: any): boolean {
    if (action.actionCommand?.startsWith("tpw")) {
      return true;
    }

    if (action.actionType === "report" && Number(action.reportFilterProps) === 0) {
      return true;
    }

    if (action.actionType === "analysis") {
      return true;
    }

    if (action.actionType === "autoform") {
      return true;
    }

    if (action.actionType === "customcall" && (action.datasetActionType?.toLowerCase() == "delete" && action.hasAutoformScript)) {
      return true;
    }

    if (action.actionType === "customcall" && action.internalAction) {
      return true;
    }

    return false;
  }

  private loadAccessGroups() {

    if (!environment.production &&
      this.storage.getData("accessTree") &&
      this.storage.getData("tpserver") === environment.afxserver
    ) {

      // ONLY FOR DEVELOPTMENT ENVIRONMENT, STORE THE ACCESS TREE FOR LOADING OPTMIZATION
      const ac = JSON.parse(this.storage.getData("accessTree"));
      if (ac.updated) {
        ac.updated = new Date(ac.updated);

        if (new Date(ac.updated.getTime() + (1000 * 60 * 60 * 24)) > new Date()) {

          this.accessGroups = ac.accessGroups;
          this.accessList = ac.accessList;

          this.loadFavAccess();

          this.sortMenu();

          this.subAccessGroup$.next(this.accessGroups);
          this.subAccessList$.next(this.accessList);

          this.devService.devModeLog("ACCESS TREE IS IN CACHE");

          return;
        }
      }
    }

    const subs$ = this.httpClient.get("core.sec.access/getAccessTree", null).subscribe((resp: any) => {

      subs$.unsubscribe();

      if (!resp.actionList) {
        resp.actionList = [];
      } else if (!Array.isArray(resp.actionList)) {
        resp.actionList = [resp.actionList];
      }

      if (resp && resp.actionList) {

        for (let i = 0; i < resp.actionList.length; i++) {
          let action = resp.actionList[i];
          let item = new T2AccessItem();

          item.id = action.id;
          item.name = action.datasetGroupDescription || action.actionDescription;
          item.description = action.actionDescription;
          item.module = action.module;
          item.informationGroupName = action.informationGroupName;
          item.informationGroupSubName = action.informationGroupSubName;
          item.actionCommand = action.actionCommand;
          item.datasetActionDescription = action.datasetActionDescription;
          item.id_dataset = action.id_dataset;
          item.datasetName = action.datasetName;
          item.datasetActionQuestion = action.datasetActionQuestion;
          item.id_dataset_group = action.id_dataset_group;
          item.datasetGroup = action.datasetGroupDescription;
          item.datasetGroupDatasetName = action.datasetGroupDatasetName;
          item.groupIndex = +action.groupNameOrder;
          item.subGroupIndex = +action.subGroupNameOrder;
          item.mainDataset = action.mainDataset;
          item.id_report = action.id_report;
          item.reportFilterProps = +action.reportFilterProps;
          if (action.id_dataset_parent) item.id_dataset_parent = action.id_dataset_parent
          if (action.simpleDatasetEntity) item.simpleDatasetEntity = true
          if (action.eppCarac) item.eppCarac = true;
          if (action.directive) item.directive = true;
          item.datasetDescription = action.datasetDescription;

          switch (action.actionType?.toLowerCase()) {
            case "analysis":
              item.type = T2AccessItemType.ACTION_ANALYSIS;
              break;

            case "customcall":
              item.type = T2AccessItemType.ACTION_CUSTOMCALL;
              break;

            case "autoform":
              item.type = T2AccessItemType.ACTION_AUTOFORM;
              break;

            case "report":
              item.type = T2AccessItemType.ACTION_REPORT;
              break;

            case "search":
              item.type = T2AccessItemType.ACTION_SEARCH;
              break;

            case "view":
              item.type = T2AccessItemType.ACTION_VIEW;
              break;
          }

          switch (action.datasetActionType?.toLowerCase()) {
            case "copy":
              item.datasetActionType = T2AccessItemDatasetActionType.DSACTION_COPY;
              break;

            case "delete":
              item.datasetActionType = T2AccessItemDatasetActionType.DSACTION_DELETE;
              break;

            case "edit":
              item.datasetActionType = T2AccessItemDatasetActionType.DSACTION_EDIT;
              break;

            case "insert":
              item.datasetActionType = T2AccessItemDatasetActionType.DSACTION_INSERT;
              break;

            case "other":
              item.datasetActionType = T2AccessItemDatasetActionType.DSACTION_OTHER;
              break;

            case "otherchange":
              item.datasetActionType = T2AccessItemDatasetActionType.DSACTION_OTHERCHANGE;
              break;

            case "otherclose":
              item.datasetActionType = T2AccessItemDatasetActionType.DSACTION_OTHERCLOSE;
              break;

            case "search":
              item.datasetActionType = T2AccessItemDatasetActionType.DSACTION_SEARCH;
              break;

            case "view":
              item.datasetActionType = T2AccessItemDatasetActionType.DSACTION_VIEW;
              break;
          }

          switch (action.informationType?.toLowerCase()) {
            case "configuration":
              item.informationType = T2AccessInformationType.CONFIGURATION;
              break;

            case "dashboard":
              item.informationType = T2AccessInformationType.DASHBOARD;
              break;

            case "operational":
              item.informationType = T2AccessInformationType.OPERATIONAL;
              break;

            case "register":
              item.informationType = T2AccessInformationType.REGISTER;
              break;

            case "report":
              item.informationType = T2AccessInformationType.REPORT;
              break;

            case "utility":
              item.informationType = T2AccessInformationType.UTILITY;
              break;
          }

          this.accessList.push(item);

          if (!this.showAction(action)) {
            continue;
          }

          if ((item.mainDataset || !item.id_dataset) && !action.internalAction) {
            this.buildMenu(item);
          }
        }

        this.loadFavAccess();
        this.sortMenu();

        this.subAccessGroup$.next(this.accessGroups);
        this.subAccessList$.next(this.accessList);

        if (!environment.production) {
          this.storage.setData("accessTree", JSON.stringify({
            updated: new Date(),
            accessGroups: this.accessGroups,
            accessList: this.accessList
          }));
          this.storage.setData("tpserver", environment.afxserver);
        }
      }
    });
  }

  private buildMenu(item: T2AccessItem) {
    let accessGroup = this.accessGroups.find(group => {
      return group.name == (item.informationGroupName || item.module) && (group.index == item.groupIndex);
    });

    if (!accessGroup) {
      accessGroup = new T2AccessGroup();
      accessGroup.name = item.informationGroupName || item.module;
      accessGroup.index = item.groupIndex;
      this.accessGroups.push(accessGroup);
    }

    if (!item.id_dataset || !accessGroup.accessList.some(it => it.id_dataset_group == item.id_dataset_group)) {
      accessGroup.accessList.push(item);

      if (!accessGroup.accessSubMenu.find(subMenu => subMenu.index == item.subGroupIndex)) {
        accessGroup.accessSubMenu.push({ name: item.informationGroupSubName, index: item.subGroupIndex });

        accessGroup.accessSubMenu.sort((a, b) => {
          let res = a.index - b.index;
          if (res) return res;

          if (a.name && b.name) return a.name.localeCompare(b.name)
          else return 0;
        });
      }
    }
  }

  private sortMenu() {
    let itemTypeSort = [3, 0, 1, 5, 4, 2];

    this.accessGroups.sort((a, b) => {

      if (a.index == 0 && b.index == 0) return 0;
      if (b.index == 0) return -1;
      if (a.index == 0) return 1;

      const res = a.index - b.index;
      if (!res) return a.name.localeCompare(b.name)
      else return res;
    })

    this.accessGroups.forEach(group => {

      group.accessList.sort((a, b) => {
        let res = a.subGroupIndex - b.subGroupIndex;
        if (res == 0) {
          res = itemTypeSort.indexOf(a.informationType) - itemTypeSort.indexOf(b.informationType);
          if (res == 0) res = a.name.localeCompare(b.name);
        }

        return res;
      });

    });

  }

  public getUserId() {
    return this.httpClient.getUserId();
  }

  public hasAction(idAction: string) {
    const actions = this.accessList.filter((it: T2AccessItem) => {
      return it.id === idAction;
    });
    return actions.length > 0;
  }

  public getAction(actionId: string): Observable<T2AccessItem> {
    return this.getAccesList()
      .pipe(
        take(1),
        map((actions: T2AccessItem[]) => {
          const acList = actions.filter((action: T2AccessItem) => action.id === actionId);
          if (acList.length) {
            return acList[0];
          } else {
            return null;
          }
        })
      );
  }

  public getActionList(actionIdList: string[]) {
    return this.getAccesList()
      .pipe(
        take(1),
        map((actions: T2AccessItem[]) => {
          return actions.filter((action: T2AccessItem) => actionIdList.indexOf(action.id) >= 0);
        })
      );
  }

  public getDatasetActionsByDatasetId(id_dataset: string): Observable<T2AccessItem[]> {
    return this.getAccesList()
      .pipe(
        take(1),
        map((actions: T2AccessItem[]) => {
          return actions
            .filter(it => it.id_dataset === id_dataset);
        })
      );  
  }

  public getDatasetActions(datasetName: string): Observable<T2AccessItem[]> {
    return this.getAccesList()
      .pipe(
        take(1),
        map((actions: T2AccessItem[]) => {
          return actions
            .filter(it => it.datasetName && it.datasetName.toLowerCase() === datasetName.toLowerCase());
        })
      );
  }

  public getDatasetActionIDs(datasetName: string) {
    return this.getDatasetActions(datasetName).pipe(
      map((actions: T2AccessItem[]) => {
        return actions
          .map(it => it.id);
      })
    )
  }

  public getAnalysisActionID(id_analysis: string) {
    return this.getAccesList()
      .pipe(
        take(1),
        map((actions: T2AccessItem[]) => {
          const action = actions.find(it => it.id_report == id_analysis);
          if (action) return action.id;

          return null;
        })
      );
  }

  public getCompanySiteId() {
    return this.subCompanySite$.getValue();
  }
}
