<div [nbSpinner]="saving" nbSpinnerStatus="primary" nbSpinnerSize="giant">
  <app-dialog-template #dialog title="Evento para a Nota Fiscal" [ngStyle]="{'min-height': '62vh'}">
    <content class="flexColumn" style="width: 50vw;">
      <div style="display: flex; justify-content: flex-end; align-items: center; color: red; font-size: 12px; height: 20px;">
        {{warningMessage}}
      </div>
      <app-view-template #viewtemplate [layout]="layout" [FormGroup]="formGroup" [data]="data"></app-view-template>
      <div style="display: flex; justify-content: flex-end; align-items: center;">
        <div *ngIf="minCharacters && maxCharacters">
          <div *ngIf="(usedCharacters <= maxCharacters && usedCharacters >= minCharacters)"
            style="color: black;font-size: 14px;">
            {{usedCharacters}}/{{maxCharacters}}</div>
          <div *ngIf="usedCharacters > maxCharacters || usedCharacters < minCharacters "
            style="color: red; font-size: 14px;">
            {{usedCharacters}}/{{maxCharacters}}</div>
        </div>
      </div>
      <div style="color: red; font-size: 12px;">A descrição do evento será enviada para o SEFAZ sem quebras de linha
      </div>
    </content>
    <footer>
      <div style="display: flex; gap: 5px; justify-content: flex-end; align-items: center;">
        <button nbButton status="danger" size="tiny" (click)="closeDialog('Cancela')">Cancela</button>
        <button nbButton status="primary" size="tiny" (click)="closeDialog('Confirma')"
          [disabled]="formGroup.invalid || !isEventMessageSizeOK || !isEventTypeOK || saving">Confirma</button>
      </div>
    </footer>
  </app-dialog-template>
</div>
