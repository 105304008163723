import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-t2-menu-button',
  templateUrl: './t2-menu-button.component.html',
  styleUrls: ['./t2-menu-button.component.scss']
})
export class T2MenuButtonComponent implements OnInit {
  @Input() t2IdCmp: string;
  @Input() icon: string = null;
  @Input() caption: string = null;
  @Input() disabled: boolean;
  @Input() status: string;
  @Input() loading: boolean;

  @Output() clicked = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {
    if (this.disabled)
      this.disabled = this.disabled ?? false;
    if (this.loading)
      this.loading = this.loading ?? false;
  }

  onClick(event: any) {
    this.clicked.emit(event);
  }

}
