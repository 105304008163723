import { Component, EventEmitter, Inject, Input, LOCALE_ID, Output, ViewChild } from '@angular/core';
import { AgGridAngular } from "ag-grid-angular";
import { ColDef, ColGroupDef, GetDataPath, GetLocaleTextParams, GridApi, GridOptions, IsRowSelectable, RowClickedEvent } from "ag-grid-community";
import { T2ThemingService } from "../../t2theming.service";
import agGridLocale from "../../../../assets/agGridLocalePTBR.json";
import { DatePipe, formatNumber } from "@angular/common";

@Component({
  selector: 'app-t2-tree-grid',
  templateUrl: './t2-tree-grid.component.html',
  styleUrls: ['./t2-tree-grid.component.scss']
})
export class T2TreeGridComponent {

  @ViewChild("t2TreeGrid", { static: true }) t2TreeGrid: AgGridAngular;

  @Input() autoGroupColumnDef: ColDef;
  @Input() sortable = false;
  @Input() wrapText = false;
  @Input() tooltipComponentName: string = undefined;
  @Input() headerHeight = 35;
  @Input() rowHeight = 35;
  @Input() getDataPath: GetDataPath;
  @Input() rowSelection: 'single' | 'multiple' = "single";
  @Input() suppressRowClickSelection: boolean = true;
  @Input()
  get data(): Array<any> { return this.gridData; }
  set data(value: Array<any>) { this.setGridData(value, null); }

  @Input()
  get columnDef(): Array<any> { return this.getColumnDefs(); }
  set columnDef(value: Array<any>) { this.setGridColumnDefs(value); }

  @Input() isRowSelectable: IsRowSelectable;

  @Output() gridReady = new EventEmitter<any>();
  @Output() selectionChanged = new EventEmitter();
  @Output() rowClick = new EventEmitter();

  private _settingGridData: number = 0;
  private isGridReady = false;
  private t2GridApi: GridApi;
  private dataLoaded = false;
  private rowsSelected: Array<any> = [];
  private focusedRow: any;

  public sessionTheme = "default";
  public gridData = [];
  public t2GridOptions: GridOptions = {
    defaultColDef: {
      resizable: true,
      filter: true,
      sortable: this.sortable,
      wrapText: this.wrapText,
      tooltipComponent: this.tooltipComponentName,
      headerComponentParams: {
        template: `<div class="ag-cell-label-container" role="presentation">
             <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>
             <div ref="eLabel" class="ag-header-cell-label" role="presentation">
               <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>
               <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>
               <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>
               <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>
               <span ref="eText" class="ag-header-cell-text" role="columnheader"></span>
               <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>
             </div>
           </div>
          `,
      },
      cellStyle: (params) => {
        const { level } = params.node;
        const groupCell = params.value === params.node.key;
        const indent = 10;

        if (groupCell) {
          return {
            paddingLeft: (level + 1) * indent + "px",
          };
        }
      },
    },
    columnTypes: {
      booleanColumn: {
        valueGetter: (params: any) => {
          const value = params.data[params.column.colId];

          if (value === null || value === undefined) {
            return null;
          }

          if (value) {
            return "Sim";
          } else {
            return "Não";
          }
        },
        width: 115,
      },

      dateColumn: {
        valueFormatter: (params) => {
          if (!params.value) return "";

          return new DatePipe("en-GB").transform(params.value || undefined, "dd/MM/yyyy");
        },
        cellRenderer: (params) => {
          return `
          <div style="display: flex;">
            <div class="flexLength1 flexAlignColumnCenter">

              ${(params.value ? new DatePipe("en-GB").transform(params.value || undefined, "dd/MM/yyyy") : "")}
            </div>
          </div>
          `;
        },
        cellClass: "dateTimeType",
        cellEditor: "dateTimeEditor",
        width: 115,
      },
      dateTimeColumn: {
        valueFormatter: (params) => {
          if (!params.value) return "";

          return new DatePipe("en-GB").transform(params.value || undefined, "dd/MM/yyyy HH:mm");
        },
        cellRenderer: (params) => {
          return `
          <div style="display: flex;">
            <div class="flexLength1 flexAlignColumnCenter">

              ${(params.value ? new DatePipe("en-GB").transform(params.value || undefined, "dd/MM/yyyy HH:mm") : "")}
            </div>
          </div>
          `;
        },
        cellClass: "dateTimeType",
        cellEditor: "dateTimeEditor",
        width: 120,
      },

      checkColumn: {
        cellRenderer: "checkBoxRenderer",
        cellStyle: { display: "flex", "justify-content": "center" },
      },
    },
    suppressRowClickSelection: this.suppressRowClickSelection,
    rowSelection: this.rowSelection,
    rowData: [],
    getRowId: (item: any) => { return item?.data['ID']; },
    getLocaleText: (params: GetLocaleTextParams) => {
      return agGridLocale[params.key] ? agGridLocale[params.key] : params.defaultValue;
    }
  };

  constructor(private themeService: T2ThemingService,
    @Inject(LOCALE_ID) private locale: string) {
    themeService.getThemeNameObservable().subscribe((themeName: string) => {
      this.sessionTheme = themeName;
    });
  }

  onGDAFXReady(params: any) {
    this.t2GridApi = params.api;
    this.t2GridOptions.rowSelection = this.rowSelection;
    this.t2GridOptions.defaultColDef.sortable = this.sortable;
    this.t2GridOptions.defaultColDef.wrapText = this.wrapText;
    this.t2GridOptions.defaultColDef.tooltipComponent = this.tooltipComponentName;

    this.t2GridApi.setColumnDefs(this.t2GridOptions.columnDefs);
    this.t2GridApi.setRowData(this.gridData);

    this.isGridReady = true;
    this.gridReady.emit();
  }

  onGDSelectionChanged(params: any) {
    const obj = {
      rowsSelected: this.t2GridApi.getSelectedRows(),
    };

    this.rowsSelected = obj.rowsSelected;

    this.selectionChanged.emit(params);
  }

  onGDRowClicked(params: RowClickedEvent) {
    const obj = { event: "clicked", row: params.rowIndex, data: params.data };
    this.clickRow(obj);
  }

  private clickRow(obj: any) {
    this.focusedRow = obj.data;
    this.rowClick.emit(obj);
  }

  public setGridData(data: Array<any>, selectId?: string) {
    try {
      this._settingGridData++;
      this.gridData = data;
      if (this.gridData && this.gridData.length > 0) {
        this.gridData.filter(item => item["ID"] == undefined || item["ID"] == null)?.forEach((item, index, arr) => {
          item["ID"] = "index" + index;
        });
      }

      if (this.isGridReady) {
        this.t2GridApi.setRowData(this.gridData);
        if (selectId) {
          const rowNode = this.t2GridApi.getRowNode(selectId);
          if (rowNode) {
            rowNode.setSelected(true);
            this.t2GridApi.setRowNodeExpanded(rowNode, true, true);
            setTimeout(() => {
              this.t2GridApi.ensureNodeVisible(rowNode, "middle");
            }, 100);

          }
        }

        this.dataLoaded = true;
        // this.loadColumnGridState();
      }
    } finally {
      setTimeout(() => { this._settingGridData--; }, 50);
    }
  }

  private numberFormatter(params): string {

    var value = params.value;

    if (typeof value == 'string') {
      if (!value) return "";

      value = Number(value);
    }

    if (isNaN(value)) return "";

    const digitsInfo = `1.${params.colDef['precision'] || 0}-${params.colDef['precision'] || 4}`;
    return formatNumber(Number(value), this.locale, digitsInfo);
  }

  private columnEditable(column: any): boolean {
    if (typeof column.editable === "function") {
      // Retorna TRUE pois a validação específica será feita linha a linha
      return true;
    }

    return column.editable;
  }

  public setGridColumnDefs(columnDefs: Array<ColDef>, maintainColumnOrder?: boolean) {
    let grouplableColumn = false;
    this.t2GridOptions.maintainColumnOrder = maintainColumnOrder;
    columnDefs?.forEach((column) => {
      if (column.enableRowGroup) {
        // If any column is set o be grouped, enabled grid-grouping
        grouplableColumn = true;
      }

      if (column?.type === "numericColumn") {
        // Value must be converted to number
        if (!column.valueGetter) {
          column.valueGetter = (params: any) =>
            !params || !params.data ? null : Number(params.data[column.field]);
        }

        // Value must be displayed with current localization
        column.valueFormatter = this.numberFormatter.bind(this);

        // Filtro personalizado pois o filtro padrão não ordena numericamente

        column.filterParams = {
          comparator: (a: any, b: any) => {
            try {
              const valA = parseFloat(a || 0);
              const valB = parseFloat(b || 0);
              if (valA === valB) return 0;
              return valA > valB ? 1 : -1;
            } catch (error) {
              return 0;
            }
          },
        };

        // If is an editabled field, must use our default editor (if no other was defined)
        if (this.columnEditable(column)) {
          column.cellEditor = column.cellEditor || "numericEditor";
          column.singleClickEdit = true;
        }
      } else if (!column?.type) {
        column.cellClass = column.cellClass || 'stringType';
      }

      if (this.columnEditable(column)) {
        column.singleClickEdit = true;


        // if (!column.cellStyle) {
        //   column.cellStyle = this.editableCellStyle;
        // }
      }
    });

    this.t2GridOptions.rowGroupPanelShow = grouplableColumn ? "always" : "never";
    this.t2GridOptions.columnDefs = columnDefs;
    if (this.t2GridApi) {
      this.t2GridApi.setColumnDefs(columnDefs);

      this.t2GridApi.getColumnDefs
    }
  }

  getRowsSelected() {
    return this.rowsSelected;
  }

  getColumnDefs(): (ColDef | ColGroupDef)[] {
    if (this.t2GridApi) {
      return this.t2GridApi.getColumnDefs();
    }

    return [];
  }

  public setSelected(rowID) {
    this.t2GridApi.getRowNode(rowID)?.setSelected(true);

    this.rowsSelected = this.t2GridApi.getSelectedRows();
  }
}
