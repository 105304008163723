<div *ngIf="visible" class="box">
  <div style="font-weight: bold; font-size: 110%; text-align: end; margin-bottom: 10px; padding: 3px;">
    Notícias Techprod
  </div>

  <div style="overflow: auto; max-height: 75vh;">
    <div *ngFor="let news of newsList" class="flexColumn background" style="gap: 5px">
      <div *ngIf="news.title">{{news.title}}</div>
      <div #newsRef></div>
      <div *ngIf="news.release" style="font-size: 60%; font-weight: bold; text-align: end; margin-top: -20px;">
        {{news.release | date : 'short'}}</div>
    </div>

  </div>

</div>
