import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ConditionField, ConditionGroup } from "../../visualization/t2-visualization.service";
import { FormBuilder, FormGroup } from "@angular/forms";
import { takeUntil } from "rxjs/Operators";
import { Subject } from "rxjs";

@Component({
  selector: 'app-quick-search',
  templateUrl: './quick-search.component.html',
  styleUrls: ['./quick-search.component.scss']
})
export class QuickSearchComponent implements OnInit, OnDestroy {

  private _condition: ConditionGroup;
  @Input()
  get condition() { return this._condition; }
  set condition(value: ConditionGroup) {
    this._condition = value;
    this.setDrawingStyle();
  }
  @Input() outsideCalling = false;
  @Input() loading = false;
  @Output() queryCondition = new EventEmitter<ConditionGroup>();

  simpleFieldsDrawing: boolean = false;
  quickSearchFields: Array<ConditionField>;
  search: boolean = true;
  formGroup: FormGroup<{}> = this.formBuilder.group({}, { updateOn: 'blur' });
  private unsubscribe = new Subject<void>();

  constructor(private formBuilder: FormBuilder) { }

  ngOnInit(): void { this.setDrawingStyle(); }

  ngAfterContentInit(): void {
    this.condition.fieldList?.filter(f => f.datasetId).forEach(f => {
      this.formGroup.addControl(f.property, this.formBuilder.control(f.value));
    });

    this.formGroup.valueChanges
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((resp: object) => {

        Object.keys(resp).forEach(key => {
          const field = this.condition.fieldList.find(f => f.property == key);
          if (field) {
            field.value = resp[key] || null;
          }
        });
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  setDrawingStyle() {
    if (this.outsideCalling) {
      this.quickSearchFields = this.condition?.fieldList?.filter(i => i.fieldFilter?.quickSearch);
      this.simpleFieldsDrawing = (this.quickSearchFields?.length == 1 || this.quickSearchFields?.length == 2)
        && !this.condition?.groupList;
    }
  }

  conditionSelected() {
    if (!this.loading) {
      this.queryCondition.emit(this.condition);
    }

  }

  getSelectedField($event) { }
}
