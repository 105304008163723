import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from "@angular/forms";
import { T2MessageService } from "../../t2-message.service";
import { DialogTemplateComponent } from "../dialog-template/dialog-template.component";
import { T2ViewTemplateData } from "../view-template/model/t2-view-template-data";
import { ViewTemplateElement } from "../view-template/model/view-template-element";
import { T2ViewTemplateFlow } from "../view-template/model/t2-view-template-flow";
import { T2ViewTemplateAction } from "../view-template/model/t2-view-template-action";
import { ViewTemplateComponent } from "../view-template/view-template.component";

@Component({
  selector: 'app-dialog-input',
  templateUrl: './dialog-input.component.html',
  styleUrls: ['./dialog-input.component.scss']
})
export class DialogInputComponent implements OnInit {

  @ViewChild('dialog', { static: true }) dialog: DialogTemplateComponent;
  @ViewChild('viewTemplate', {static: true}) viewTemplate: ViewTemplateComponent;

  public layout: ViewTemplateElement[];
  public data: Array<T2ViewTemplateData>;
  public FormGroup: FormGroup;
  public title: string;
  public topMessage: string;
  public flows: Array<T2ViewTemplateFlow>;
  public actions: Array<T2ViewTemplateAction>;

  @Input() ngStyle: { [klass: string]: any }

  constructor(private messageService: T2MessageService) { }

  ngOnInit(): void {
  }

  closeDialog(action) {
    if (action == "Confirma") {
      if (this.FormGroup.invalid) {
        this.messageService.showToastError("As informações são inválidas");
        return;
      }
    }

    this.dialog.close(action);
  }
}
