import { DatasetProperty } from "./datasetProperty";
import { DatasetStatus } from "./datasetStatus";

export class Dataset {
  id_dataset: string;
  id_dataset_ancestor: string;
  datasetName: string;
  description: string;
  parentName: string;
  allowApprovalPolicy: boolean;

  propertyList: Array<DatasetProperty>;
  //statusList:  Array<DatasetStatus>;
}
