<app-dialog-template #dialog class="w" title="Seleção de Fornecedor da OP">
  <content>
    <div class="wContent">
      <div style="display: flex; margin-top: 10px;">
        <input nbInput [(ngModel)]="suppNameFilter" (keydown.enter)="onSuppNameKeyEnter($event)"
          placeholder="Filtro por fornecedor" />
      </div>
      <div style="font-size: 70%; font-weight: bold;">Selecione o fornecedor</div>
      <app-t2grid #gdForn id="gdForn" rowSelection="single" (rowDoubleClick)="onGDFornDoubleClicked($event)">
      </app-t2grid>
    </div>
  </content>
  <footer>
    <div class="wFooter">
      <div style="margin-top: auto; display: flex;">
        <button nbButton size="tiny" status="danger" (click)="closeWindow(false)" style="margin-left: auto">
          Cancela
        </button>
        <button nbButton size="tiny" status="primary"
          [disabled]="gdForn.getRowsSelected().length === 0" (click)="closeWindow(true)">
          Confirma
        </button>
      </div>
    </div>
  </footer>
</app-dialog-template>