import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from "@angular/forms";
import { NbDialogRef } from "@nebular/theme";
import { Subject } from "rxjs";
import { take, takeUntil } from "rxjs/Operators";
import { T2gridComponent } from "src/app/core/cmp/t2grid/t2grid.component";
import { T2AggregationComponent } from "src/app/core/cmp/ui/t2-aggregation/t2-aggregation.component";
import { PSColor } from "../../model/pscolor";
import { FlxProductSpecificationService } from "../flx-product-specification.service";
import { EPService } from "src/app/bsn/epp/ep/ep.service";
import { ConditionField } from "src/app/core/dataset/visualization/t2-visualization.service";

@Component({
  selector: 'app-eppchemical-compat-cylinder-selection',
  templateUrl: './eppchemical-compat-cylinder-selection.component.html',
  styleUrls: ['./eppchemical-compat-cylinder-selection.component.scss']
})
export class EppchemicalCompatCylinderSelectionComponent implements OnInit, OnDestroy {

  @ViewChild("gridColor", { static: true }) gridColor: T2gridComponent;
  @ViewChild("aggProdSpecif", { static: true }) aggProdSpecif: T2AggregationComponent;

  public formGroup: FormGroup;
  public loadingGrid: boolean = false;
  public psDatasetName: string;
  public psDatasetId: string;
  private unsubscribe = new Subject<void>();
  private productSpecificationId: string;
  private colorList: Array<PSColor>;
  public fixedFilter: ConditionField[] = [
    { property: "id_tipoEspecif", value: null, operator: "EQUALS" },
    { property: "status", value: "Obsoleto", operator: "EQUALS", not: true }
  ];
  private _id_especifType: string;
  @Input()
  get id_especifType() { return this._id_especifType; }
  set id_especifType(value) {
    this._id_especifType = value;
    this.fixedFilter[0].value = value;
  }
  public showProductSelection: boolean = false;
  public productId: string;
  public psList: Array<{ id: string, description: string }> = [];

  constructor(public dialogRef: NbDialogRef<EppchemicalCompatCylinderSelectionComponent>, private formBuilder: FormBuilder,
    private flxPsService: FlxProductSpecificationService, private epService: EPService) {
    this.formGroup = this.formBuilder.group({});
    this.formGroup.addControl("product", this.formBuilder.control(undefined));
    this.formGroup.addControl("productSpecification", this.formBuilder.control(undefined));

    this.formGroup.controls["product"].valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe(value => {
      this.productId = value;
      this.epService.getPsByProduct(value).pipe(take(1)).subscribe(list => {
        this.psList = [];
        list.forEach(ps => {
          this.psList.push({ id: ps.productSpecificationId, description: `${ps.code} - ${ps.description}` });
        })
      })
    });

    this.formGroup.controls["productSpecification"].valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe(value => {
      this.productSpecificationId = value;
      this.loadGrid();
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  ngOnInit(): void {
    this.gridColor.setGridColumnDefs([
      { checkboxSelection: true, lockPosition: true, pinned: "left", valueGetter: "node.rowIndex + 1", width: 45, suppressNavigable: true },
      { headerName: "Cor", field: "color" },
      { headerName: "Detalhe", field: "detail" },
    ]);

    this.showProductSelection = this.psDatasetName == "stk_item_produto_versao";
  }

  closeWindow(close: boolean) {
    let rowData = undefined;
    if (close) {
      rowData = this.gridColor.getRowsSelected()[0];
    }

    this.dialogRef.close(rowData);
  }

  loadGrid() {
    this.loadingGrid = true;

    this.flxPsService.getProductSpecificationColors(this.productSpecificationId).pipe(take(1)).subscribe(psColorList => {
      this.colorList = psColorList;
      this.gridColor.setGridData(this.colorList, null);
      this.loadingGrid = false;
    }, error => {
      this.loadingGrid = false;
    })
  }

}
