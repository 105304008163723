import { ConjugatedRendererComponent } from './conjugated-renderer/conjugated-renderer.component';
import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { take, takeUntil } from "rxjs/Operators";
import { FormTemplateComponent } from "src/app/core/cmp/form-template/form-template.component";
import { T2SelectComponent } from "src/app/core/cmp/ui/t2-select/t2-select.component";
import { LayoutType, ViewTemplateElement } from "src/app/core/cmp/view-template/model/view-template-element";
import { T2SecurityService } from "src/app/core/security/t2security.service";
import { ProdOrderReleaseService } from "./prod-order-release.service";
import { T2gridComponent } from "src/app/core/cmp/t2grid/t2grid.component";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { T2DatasetService } from "src/app/core/dataset/t2dataset.service";
import { ViewTemplateComponent } from "src/app/core/cmp/view-template/view-template.component";
import { ComplementaryField } from "src/app/bsn/utils/model/complementary-field";
import { CylinderInfoRendererComponent } from "./cylinder-info-renderer/cylinder-info-renderer.component";
import { ReleaseProdOrderInfo } from "./model/release-prod-order-info";
import { CellClickedEvent } from "ag-grid-community";
import { PSReleaseInfo } from "./model/psrelease-info";
import { ConjugationComponent } from "./conjugation/conjugation.component";
import { T2ButtonComponent } from "src/app/core/cmp/ui/t2-button/t2-button.component";
import { T2AccessItem, T2AccessItemDatasetActionType } from "src/app/core/security/model/t2accessItem";
import { ActionService } from "src/app/core/action/action.service";
import { Subject } from "rxjs";

@Component({
  selector: 'app-production-order-release',
  templateUrl: './production-order-release.component.html',
  styleUrls: ['./production-order-release.component.scss']
})
export class ProductionOrderReleaseComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild("formTemplate", { static: true }) formTemplate: FormTemplateComponent;
  @ViewChild("gridLiberacao", { static: true }) gridLiberacao: T2gridComponent;
  @ViewChild("viewTemplate", { static: true }) viewTemplate: ViewTemplateComponent;
  @ViewChild("conjugation") conjugation: ConjugationComponent;

  private releaseList: Array<ReleaseProdOrderInfo>;
  private psReleaseList = new Array<{ prodSpecifId: string, isConjugation: boolean, psReleaseList: Array<PSReleaseInfo> }>();
  private unsubscribe = new Subject<void>();
  public formGroup: FormGroup;
  public loaded: boolean = false;
  public loadingData: boolean = true;
  public lockScreen: boolean;
  public action: T2AccessItem;
  public canEdit: boolean;
  public datasetName: string;
  public id_edit_info: string;
  public selectedRelease: ReleaseProdOrderInfo;
  public selectedPsRelease: { prodSpecifId: string, isConjugation: boolean, psReleaseList: Array<PSReleaseInfo> };
  public gridComponents = {
    cylInfoRenderer: CylinderInfoRendererComponent,
    conjugatedRenderer: ConjugatedRendererComponent
  }
  public relInfoListFromConj: Array<ReleaseProdOrderInfo>;
  public layout: Array<ViewTemplateElement> = [
    {
      layoutType: LayoutType.gridLayout,
      children: [
        {
          layoutType: LayoutType.component,
          cmpType: T2SelectComponent,
          cmpName: "situacao",
          title: "Situação",
          columnSpan: 4,
          isBaseComponent: true,
          inputs: {
            multiple: true,
            list: [
              { id: "Não Atendida", description: "Não Atendida" },
              { id: "Atendida Parcialmente", description: "Atendida Parcialmente" },
              { id: "Finalizada", description: "Finalizada" }
            ]
          }
        },
        {
          layoutType: LayoutType.component,
          cmpType: T2SelectComponent,
          cmpName: "statusPV",
          title: "Status PV",
          columnSpan: 3,
          isBaseComponent: true,
          inputs: {
            multiple: true
          }
        },
        {
          layoutType: LayoutType.component,
          cmpType: T2ButtonComponent,
          cmpName: "btnBuscar",
          isBaseComponent: false,
          cmpCssClass: "allignButtonBottom",
          inputs: {
            value: "Buscar",
            status: "primary",
            size: "tiny"
          },
          outputs: {
            clicked: this.searchClick.bind(this)
          }
        }
      ]
    }
  ];

  constructor(private sec: T2SecurityService, private releaseService: ProdOrderReleaseService, private formBuilder: FormBuilder,
    private dsService: T2DatasetService, private actionService: ActionService) {
    this.formGroup = this.formBuilder.group({});
    this.formGroup.addControl("situacao", this.formBuilder.control(undefined, { validators: [Validators.required] }));
    this.formGroup.addControl("statusPV", this.formBuilder.control(undefined));
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  ngAfterViewInit(): void {
    this.loadingData = true;
    this.dsService.getStatusList("pvd_pedidovenda").pipe(take(1)).subscribe(statusList => {
      this.viewTemplate.setCmpInputValue("statusPV", "list", statusList.map(status => {
        return {
          id: status.description,
          description: status.description
        }
      }));

      setTimeout(() => {
        this.formGroup.controls["statusPV"].setValue(statusList.filter(status => !status.endsInformation).map(s => s.description));
        this.formGroup.controls["situacao"].setValue(["Não Atendida", "Atendida Parcialmente"]);

        this.configGrid();
        this.searchClick();
      }, 100);
    });
  }

  ngOnInit(): void {
    this.sec.accessLoaded().pipe(take(1)).subscribe(() => {
      if (this.formTemplate.validateAccess(["zD20170818H200611760R000000002"], 'full')) {
        this.loaded = true;
      }
    });

    this.sec.companySiteObservable().pipe(takeUntil(this.unsubscribe)).subscribe(id => {
      if (this.conjugation) {
        this.conjugation.clearSelection();
      }

      this.searchClick();
    })
  }

  private configGrid() {
    this.gridLiberacao.setGridColumnDefs([
      { headerName: "Origem", field: "type", enableRowGroup: true },
      { headerName: "Código", field: "code", enableRowGroup: true },
      { headerName: "Item", field: "item", enableRowGroup: true },
      { headerName: "Cliente", field: "client", enableRowGroup: true },
      { headerName: "Dt Entrega", field: "deliveryDate", type: "dateColumn", enableRowGroup: true },
      { headerName: "Dt PCP", field: "ppcDate", type: "dateColumn", enableRowGroup: true },
      { headerName: "Versão", field: "prodSpecifVersion", enableRowGroup: true },
      { headerName: "Qtd Solic", field: "requestedQty", type: "numericColumn" },
      { headerName: "Qtd Lib", field: "releasedQty", type: "numericColumn" },
      { headerName: "Estrutura", field: "structure", enableRowGroup: true },
      {
        headerName: "Conjugado", field: "conjugationId",
        valueGetter: (params) => {
          return params.data.conjugationId ? "Conjugado" : "Não conjugado"
        }, enableRowGroup: true, cellRenderer: "conjugatedRenderer"
      },
      { headerName: "Status", field: "status", enableRowGroup: true },
      { headerName: "Situação", field: "progressStatus", enableRowGroup: true },
      { headerName: "Tipo Item", field: "itemType" },
      { headerName: "EP Conjugada", field: "conjCode", enableRowGroup: true },
      { headerName: "Cil Gravado", field: "cylInfoList", cellRenderer: "cylInfoRenderer" },
      { headerName: "Cil Conjugado", field: "cylInfoList", cellRenderer: "cylInfoRenderer" },
    ]);
  }

  private showComplementaryFields(compFields: Array<ComplementaryField>) {
    this.configGrid();
    let columnDef = this.gridLiberacao.t2GridOptions.columnDefs;

    const fieldTypeFunc = (field) => {
      if (["INT", "FLOAT"].includes(field.type)) return "numericColumn";
      if (field.type == "DATE") return "dateColumn";
      if (field.type == "DATE_TIME") return "dateTimeColumn";
      if (field.type == "BOOLEAN") return "booleanColumn";

      return null;
    };

    compFields.forEach(field => {
      columnDef.push({
        headerName: field.name,
        field: field.name,
        getQuickFilterText: (params) => "",
        valueGetter: params => {
          let compField = params.data.complementaryFields?.find(cf => cf.name == field.name);

          if (["DATE", "DATE_TIME"].includes(compField?.type)) {
            compField.value = compField.value ? new Date(compField.value) : compField.value;
          }

          return compField?.value;
        },
        type: fieldTypeFunc(field)
      })
    })

    this.gridLiberacao.setGridColumnDefs(columnDef);
  }

  searchClick() {
    this.loadingData = true;
    this.selectedRelease = undefined;
    this.selectedPsRelease = undefined;
    let params: { progressStatus?: Array<string>, status?: Array<string> } = {};

    if (this.formGroup.controls["situacao"].value)
      params.progressStatus = this.formGroup.controls["situacao"].value;

    if (this.formGroup.controls["statusPV"].value)
      params.status = this.formGroup.controls["statusPV"].value;

    this.releaseService.getReleaseList(params).pipe(take(1)).subscribe(list => {
      this.releaseList = list;

      let releaseInfo = this.releaseList.find(rel => rel.complementaryFields?.length > 0);

      if (releaseInfo) {
        this.showComplementaryFields(releaseInfo.complementaryFields);
      }

      this.gridLiberacao.setGridData(this.releaseList);

      if (!this.gridLiberacao.loadColumnGridState()) this.gridLiberacao.autoSizeAllColumns(false);

      this.loadingData = false;
    }, error => {
      this.loadingData = false;
    });
  }

  public onCellClicked(event: CellClickedEvent) {
    if (event.data.id != this.selectedRelease?.id) {
      this.selectedRelease = this.releaseList.find(r => r.id == event.data.id);
      this.selectedPsRelease = undefined;
    }

    if (event.colDef.headerName == "Cil Conjugado" || event.colDef.headerName == "Cil Gravado") {
      let psRel = this.psReleaseList.find(rel => rel.prodSpecifId == this.selectedRelease.prodSpecifId && rel.isConjugation == (event.colDef.headerName == "Cil Conjugado"));

      if (!psRel) {
        this.psReleaseList.push({ prodSpecifId: this.selectedRelease.prodSpecifId, psReleaseList: [], isConjugation: event.colDef.headerName == "Cil Conjugado" });
        this.selectedPsRelease = this.psReleaseList[this.psReleaseList.length - 1];
      } else {
        this.selectedPsRelease = psRel;
      }
    }

    if (!this.selectedRelease) return;
    this.datasetName = this.selectedRelease.salesOrderId ? "pvd_pedidovenda" : "prd_solicitacaoOP";
    this.id_edit_info = this.selectedRelease.salesOrderId || this.selectedRelease.id;

    this.sec.getDatasetActions(this.datasetName).pipe(take(1)).subscribe(actions => {
      if (actions) {
        let ac = actions.filter(a => a.datasetActionType == T2AccessItemDatasetActionType.DSACTION_EDIT);
        this.action = ac[0];
        this.canEdit = this.action ? true : false;
      }
    });
  }

  public addDemandToConjugation() {
    this.conjugation.addDemandToConjugation();
  }

  public assignedConjugation(data: { specificationId: string, demandIdList: Array<string> }) {
    this.releaseList.filter(r => data.demandIdList.includes(r.id)).forEach(rel => {
      rel.assignedPsConjugationId = data.specificationId
    });
  }

  public waitForConjugation(lock: boolean) {
    this.lockScreen = lock;
  }

  public conjugationSelected(demnadIdList: Array<string>) {
    this.relInfoListFromConj = this.releaseList.filter(r => demnadIdList.includes(r.id));
  }

  public buttonEdit() {
    const params = new Map<string, string>();
    params.set("datasetName", this.datasetName);
    params.set("id", this.id_edit_info);

    this.actionService.executeAction(this.action, params, 'newWindow');
  }
}
