import { take, takeUntil } from 'rxjs/Operators';
import { Component, OnInit, ViewChild, AfterViewInit, Input, OnDestroy } from '@angular/core';
import { T2HttpClientService } from "src/app/core/http/t2httpClient.service";
import { T2gridComponent } from "../../cmp/t2grid/t2grid.component";
import { Subject, timer } from "rxjs";

@Component({
  selector: 'app-license',
  templateUrl: './license.component.html',
  styleUrls: ['./license.component.scss']
})
export class LicenseComponent implements OnInit, OnDestroy {

  contract: any = null;
  usedLicenses: Array<any> = null;
  loading = false;
  private _showView: string = 'license';
  private unsubscribe$ = new Subject<void>();

  @Input()
  get showView(): string { return this._showView; };
  set showView(value: string) {
    this._showView = value;
    if (this._showView == 'sessions') {
      setTimeout(() => { this.loadGrid(); }, 100);
    }
  };

  @ViewChild("gridLicense", { static: false }) gridLicense: T2gridComponent;

  constructor(private httpClient: T2HttpClientService) { }

  ngOnInit(): void {
    this.loadLicenses(false);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }


  private loadGrid() {
    const fixedColumns = [
      { headerName: "Descrição", field: "userKey" },
      { headerName: "Tipo", field: "license" },
      { headerName: "Licenças", field: "count", type: 'numericColumn' },
      { headerName: "Sessões", field: "sessions", type: 'numericColumn' },
    ];

    this.gridLicense.setGridColumnDefs(fixedColumns);
    this.gridLicense.setGridData(this.usedLicenses, null);
    this.gridLicense.autoSizeAllColumns(false);
  }

  private loadLicenses(manual: boolean) {

    this.loading = true;

    this.httpClient.get(`core.licensing/license/`, null)
      .pipe(take(1))
      .subscribe((resp: any) => {

        this.loading = false;

        if (resp.license && !Array.isArray(resp.license.licenses)) {
          resp.license.licenses = [resp.license.licenses];
        }
        resp.license.licenses = resp.license?.licenses?.map((lic: any) => {
          lic.used = 0;
          return lic;
        });
        this.contract = resp.license;

        if (resp.usedLicenses && !Array.isArray(resp.usedLicenses)) {
          resp.usedLicenses = [resp.usedLicenses];
        }
        this.usedLicenses = resp.usedLicenses;

        this.usedLicenses?.forEach(using => {
          const license = this.contract.licenses.find(license => using.license === license.name);
          if (license) {
            license.used += Number(using.count);
          }
        });

        if (!manual) {
          // Recarrega painel a cada 1 min (1000ms * 60)
          timer(1000 * 60)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((val) => {
              this.loadLicenses(false);
              if (this._showView == 'sessions') {
                this.loadGrid();
              }
            });
        }
      },
        error => {
          this.loading = false;
        });
  }
}
