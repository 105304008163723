import { Component, OnInit } from '@angular/core';
import { NbDialogRef } from "@nebular/theme";

@Component({
  selector: 'app-engrave-motive',
  templateUrl: './engrave-motive.component.html',
  styleUrls: ['./engrave-motive.component.scss']
})
export class EngraveMotiveComponent implements OnInit {

  public selectedItem;
  public motiveList: Array<any> = [];
  public motiveObs: string;

  constructor(public dialogRef: NbDialogRef<EngraveMotiveComponent>) {
  }

  ngOnInit(): void {        
  }

  closeWindow(confirm: boolean) {
    const result = {
      confirm: confirm,
      motive: this.selectedItem ? this.motiveList[parseInt(this.selectedItem) -1].motive : null,
      motiveObs: this.motiveObs
    };

    this.dialogRef.close(result);
  }
}
