<div style="height: 100%;" [nbSpinner]="lockScreen" nbSpinnerStatus="primary" nbSpinnerSize="giant">
  <app-form-template #formTemplate formTitle="Demandas para Produção" [loaded]="loaded">
    <content class="flexColumn flexGap10" style="height: 100%;">

      <!-- Filtro -->
      <div style="padding: 5px; ">
        <app-view-template #viewTemplate [FormGroup]="formGroup" [layout]="layout" />
      </div>

      <!-- Listagem -->
      <div style="display: flex; gap: 10px; flex: 1; max-height: calc(100% - 80px);">

        <!-- listagem de demandas -->
        <div class="flexColumn flexGap5" style="width: 100%">
          <div style="display: flex; justify-content: end; gap: 5px;">
            <button nbButton status="primary" size="tiny" [disabled]="!canEdit" (click)="buttonEdit()">Editar</button>
            <button nbButton status="primary" size="tiny" [disabled]="!selectedRelease"
              (click)="addDemandToConjugation()">Adic Conj <nb-icon icon="arrowhead-right"></nb-icon></button>
          </div>
          <div style="flex: 1">
            <app-t2grid #gridLiberacao gridStateName="gridLiberacaoOP" [components]="gridComponents"
              [loading]="loadingData" [sortable]="true" (cellClick)="onCellClicked($event)" (rowDoubleClick)="buttonEdit()" />
          </div>
        </div>

        <!-- Detalhes da demanda -->
        <nb-tabset style="width: 700px; height: 100%; overflow-y: auto;">
          <nb-tab tabTitle="Conjugação" style="height: calc(100% - var(--T2TabsetHeight)); padding: 0px;">
            <app-conjugation #conjugation [releaseInfo]="selectedRelease"
              [conjugationReleaseInfoList]="relInfoListFromConj" (assignedConjugation)="assignedConjugation($event)"
              (executingOperation)="waitForConjugation($event)"
              (demandIdFromConjugation)="conjugationSelected($event)" />
          </nb-tab>
          <nb-tab tabTitle="Detalhes" style="height: calc(100% - var(--T2TabsetHeight));">
            <app-release-detail [data]="selectedPsRelease" />
          </nb-tab>
        </nb-tabset>
      </div>
    </content>
  </app-form-template>
</div>
