import { AfterContentInit, Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { NbDialogService } from "@nebular/theme";
import { ConditionField, ConditionGroup, T2VisualizationService, VisualizationField } from "../../visualization/t2-visualization.service";
import { FormBuilder, FormGroup } from "@angular/forms";
import { takeUntil } from "rxjs/Operators";
import { Subject } from "rxjs";
import { VisualizationDialogComponent } from "../../visualization/visualization-dialog.component";

@Component({
  selector: 'app-query-condition',
  templateUrl: './query-condition.component.html',
  styleUrls: ['./query-condition.component.scss']
})
export class QueryConditionComponent implements AfterContentInit, OnDestroy {

  @Input() formGroup: FormGroup<{}>;
  @Input() canDelete = true;
  @Input() id_dataset: string;
  @Input() condition: ConditionGroup;
  @Output() groupDeleted = new EventEmitter<ConditionGroup>();

  private unsubscribe = new Subject<void>();
  quickSearchCount: number = 0;

  // private stringType: PropertyType[] = ["STRING", "MEMO", "AUTO_INC_MASKED", "EMAIL"];
  // private numberType: PropertyType[] = ["INT", "MONEY", "DECIMAL", "DURATION"];

  constructor(private formBuilder: FormBuilder, public visService: T2VisualizationService, private dialogService: NbDialogService) { }

  ngAfterContentInit(): void {

    this.condition.fieldList?.filter(f => f.datasetId).forEach(f => {
      this.formGroup.addControl(f.property, this.formBuilder.control(f.value));
    });

    this.quickSearchCount = this.condition.fieldList?.filter(i => i.fieldFilter?.quickSearch).length || 0;

    this.formGroup.valueChanges
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((resp: object) => {

        Object.keys(resp).forEach(key => {
          const field = this.condition.fieldList.find(f => f.property == key);
          if (field) {
            field.value = resp[key] || null;
          }
        });
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  onDeleteGroup(cond: ConditionGroup, index: number) {
    this.condition.groupList.splice(index, 1);
  }

  changeCondition(cond: ConditionGroup): void {
    cond.condition = (cond.condition == "AND" ? "OR" : "AND");

    const a = "O valor é " + this.id_dataset;
  }

  addGroup(cond: ConditionGroup, type: "AND" | "OR"): void {
    if (!cond.groupList) {
      cond.groupList = [];
    }

    cond.groupList.push({ condition: type, title: "expressão" });
  }

  addField(cond: ConditionGroup): void {
    if (!cond.fieldList) {
      cond.fieldList = [];
    }

    cond.fieldList.push({ operator: "EQUALS", propertyType: "STRING", requireValue: true, statusControl: false, inactityControl: false, fieldFilter: { quickSearch: false, required: false } });
  }

  selectField(field: ConditionField): void {

    this.dialogService.open(VisualizationDialogComponent, {
      autoFocus: true, closeOnEsc: false, closeOnBackdropClick: false, hasBackdrop: true,
      context: { queryMode: true, id_dataset: this.id_dataset, description: null, selectedFieldList: [] }
    }).onClose.subscribe((opt: { save: boolean, fields?: VisualizationField[] }) => {
      if (opt.save && opt.fields?.length) {

        if (opt.fields.length > 1) {
          console.warn("RETORNOU MAIS DE UM CAMPO POSSÍVEL", opt.fields)
        }

        field.property = opt.fields[0].name;
        field.description = opt.fields[0].description;
        field.datasetId = opt.fields[0].id_dataset_aggregation;
        field.datasetName = opt.fields[0].datasetName_aggregation;
        field.propertyType = opt.fields[0].propertyType;
        field.inactityControl = opt.fields[0].inactityControl;
        field.statusControl = opt.fields[0].statusControl;
        field.operators = this.visService.validOperators(field.propertyType) || field.operators || [];
        field.operator = field.operators[0];
        if (opt.fields[0].propertyType == 'STATUS' && field.fieldFilter.quickSearch) {
          field.fieldFilter.quickSearch = false;
          field.fieldFilter.required = false;
          this.quickSearchCount--;
        }
        this.visService.selectOperator(field, field.operator);
        this.visService.getValueOptions(field, opt.fields[0].datasetName, opt.fields[0].propertyName);

        if (opt.fields[0].id_dataset_aggregation) {
          this.formGroup.removeControl(opt.fields[0].name);
          this.formGroup.addControl(opt.fields[0].name, this.formBuilder.control(''));
        }
      }
    });
  }

  deleteField(cond: ConditionGroup, item: ConditionField, index: number) {
    cond.fieldList.splice(index, 1);
  }

  deleteGroup() {
    if (this.canDelete)
      this.groupDeleted.emit(this.condition);
  }

  visibleChanged(event) {
    console.log(event);
  }

  changeFieldFilterQuickSearch(item: ConditionField) {
    if (item.fieldFilter.quickSearch) item.fieldFilter.required = false;
    item.fieldFilter.quickSearch = !item.fieldFilter.quickSearch;
    item.fieldFilter.quickSearch ? this.quickSearchCount++ : this.quickSearchCount--;
    if (this.quickSearchCount > 2) item.fieldFilter.quickSearch = false;
  }

  changeFieldFilterRequired(item: ConditionField) {
    item.fieldFilter.required = !item.fieldFilter.required;
  }
}
