import { Component, OnInit, ViewChild } from '@angular/core';
import { DialogTemplateComponent } from "../cmp/dialog-template/dialog-template.component";
import { T2ThemingService } from "../t2theming.service";

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {
  @ViewChild('dialog', { static: true }) dialog: DialogTemplateComponent;

  public showView: string = 'license';

  constructor(public themeService: T2ThemingService) { }

  ngOnInit(): void {
  }

  showInfo(info: string) {
    this.showView = info;
  }
}
