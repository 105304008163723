import { Component, EventEmitter, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import { Appointment, DeviceCache, DeviceCacheTask } from "../device-cache.service";
import { DeviceTaskPlan, JustifiableOpertaion, ShopFloorService } from "../shop-floor.service";
import { DeviceRegistration } from "./model/device-registration";
import { take } from "rxjs/Operators";
import { AppointmentOperationsComponent } from "./appointment-operations/appointment-operations.component";
import { DeviceOperation } from "../model/device-operation";
import { AppointmentRegisterFormdataComponent } from "./appointment-register-formdata/appointment-register-formdata.component";
import { AppointmentRegisterQuantityComponent } from "./appointment-register-quantity/appointment-register-quantity.component";
import { AppointmentRegisterConsumptionComponent } from "./appointment-register-consumption/appointment-register-consumption.component";
import { AppointmentRegisterConsumptionReverseComponent } from "./appointment-register-consumption-reverse/appointment-register-consumption-reverse.component";
import { AppointmentRegisterProductionReverseComponent } from "./appointment-register-production-reverse/appointment-register-production-reverse.component";
import { AppointmentRegisterRawMaterialRequestComponent } from "./appointment-register-raw-material-request/appointment-register-raw-material-request.component";
import { AppointmentRegisterStopReasonComponent } from "./appointment-register-stop-reason/appointment-register-stop-reason.component";
import { T2MessageService } from "src/app/core/t2-message.service";
import { ActivatedRoute, Router } from "@angular/router";

@Component({
  selector: 'app-shop-floor-appointment',
  templateUrl: './shop-floor-appointment.component.html',
  styleUrls: ['./shop-floor-appointment.component.scss']
})
export class ShopFloorAppointmentComponent implements OnDestroy {

  @ViewChild('appOperations') appOperations: AppointmentOperationsComponent;
  @ViewChild('appFormData') appFormData: AppointmentRegisterFormdataComponent;
  @ViewChild('appQuantity') appQuantity: AppointmentRegisterQuantityComponent;
  @ViewChild('appConsumption') appConsumption: AppointmentRegisterConsumptionComponent;
  @ViewChild('appConsumptionReverse') appConsumptionReverse: AppointmentRegisterConsumptionReverseComponent;
  @ViewChild('appProductionReverse') appProductionReverse: AppointmentRegisterProductionReverseComponent;
  @ViewChild('appRawMaterialReq') appRawMaterialReq: AppointmentRegisterRawMaterialRequestComponent;
  @ViewChild('appStopReason') appStopReason: AppointmentRegisterStopReasonComponent;

  @Input()
  get device(): DeviceCache {
    return this._device;
  }
  set device(value: DeviceCache) {
    if (this.device && value && this.device.deviceId != value.deviceId) {
      this._device = value;
      this.clearCmpSelection();
      this.showCmp.operations = true;
    } else {
      this._device = value;
    }
  }

  @Input()
  get taskPlan(): DeviceCacheTask {
    return this._taskPlan;
  }
  set taskPlan(value: DeviceCacheTask) {
    this._taskPlan = value;

    if (this.taskPlan && !this.searchedTaskPlan)
      this.currentTaskPlan = this.taskPlan;
  }

  @Input()
  get searchedTaskPlan(): DeviceTaskPlan {
    return this._searchedTaskPlan;
  }
  set searchedTaskPlan(value: DeviceTaskPlan) {
    if (this._searchedTaskPlan && !value) {
      this.currentDeviceTaskPlan = undefined;
      this.currentTaskPlan = this.taskPlan;

      this.sfService.getDeviceTaskPlan(this.device.deviceId, this.currentTaskPlan?.id_taskPlan).pipe(take(1)).subscribe(devTaskPlan => {
        this.deviceTaskPlan = devTaskPlan;
        this.currentDeviceTaskPlan = this.deviceTaskPlan;
      });
    }

    this._searchedTaskPlan = value;

    if (this.searchedTaskPlan) {
      let devTP = new DeviceCacheTask();
      devTP.id_taskPlan = this.searchedTaskPlan.taskPlanId;
      devTP.id_task = this.searchedTaskPlan.taskId;
      devTP.id_ordemProd = this.searchedTaskPlan.productionOrderId;
      devTP.prodOrder = this.searchedTaskPlan.productionOrderCode;
      devTP.prodOrderDescription = this.searchedTaskPlan.productionOrderDescription;
      devTP.description = this.searchedTaskPlan.taskDescription;
      devTP.startDate = this.searchedTaskPlan.startDate;
      devTP.estimatedEndDate = this.searchedTaskPlan.estimatedEndDate;
      devTP.estimatedQty = this.searchedTaskPlan.estimatedQty;

      this.currentTaskPlan = devTP;
      this.currentDeviceTaskPlan = this.searchedTaskPlan;
    }
  }

  @Input()
  set justifyOperation(value: boolean) {
    if (value) this._justifyOperation = value;
  }

  get justifyOperation() {
    return this._justifyOperation;
  }

  @Input()
  set appointment(value: Appointment) {
    if (value) this._appointment = value;
  }
  get appointment() {
    return this._appointment;
  }

  @Output() lockScreen = new EventEmitter<boolean>();
  @Output() taskPlanChanged = new EventEmitter<DeviceCacheTask>();
  @Output() justifyOperationFinished = new EventEmitter<boolean>();

  public showCmp = {
    operations: true,
    consumption: false,
    consumptionReverse: false,
    formData: false,
    quantity: false,
    productionReverse: false,
    rawMaterialRequest: false,
    stopReason: false
  }
  public selectedOperation: DeviceOperation;
  public deviceTaskPlan: DeviceTaskPlan;
  public btnConfirmText: string = "Confirmar";
  public disableBtnConfirm: boolean = false;
  public currentTaskPlan: DeviceCacheTask;
  public currentDeviceTaskPlan: DeviceTaskPlan;
  private _searchedTaskPlan: DeviceTaskPlan;
  private _taskPlan: DeviceCacheTask;
  private _device: DeviceCache;
  private _justifyOperation: boolean = false;
  private _appointment: Appointment;

  constructor(private sfService: ShopFloorService, private messageService: T2MessageService, private router: Router, private route: ActivatedRoute) { }

  ngOnDestroy(): void {
    this.sfService.unsubscribeAllAutoData();
  }

  setLockScreen(lock: boolean) {
    this.lockScreen.emit(lock);
  }

  operationClicked(operation: DeviceOperation) {
    this.selectedOperation = operation;

    if (!operation.inputList?.length && !operation.dataList?.length && !operation.specialAction) {
      this.clearCmpSelection();
    } else {
      this.lockScreen.emit(true);

      if (operation.specialAction == "AUTH_LOWPROD") {
        this.sfService.AuthorizeBelowMinPerc(this.currentTaskPlan.id_task).pipe(take(1)).subscribe(resp => {
          this.lockScreen.emit(false);
        }, error => {
          this.lockScreen.emit(false);
        })
      } else if (operation.specialAction == "UNJUSTIFIED_DURATION") {
        if (operation.stopReasonList?.length) {
          this.showCmp.stopReason = true;
        }

        this.clearCmpSelection();
        this.lockScreen.emit(false);
      } else {
        this.clearCmpSelection();
        this.sfService.getDeviceTaskPlan(this.device.deviceId, this.currentTaskPlan?.id_taskPlan).pipe(take(1)).subscribe(devTaskPlan => {
          this.deviceTaskPlan = devTaskPlan;
          this.currentDeviceTaskPlan = this.deviceTaskPlan;

          if (operation.inputList?.length) {
            if (operation.inputList.some(inp => inp.functionality == "Consumption" && inp.id_operationType)) {
              this.showCmp.consumption = true;
            } else if (operation.inputList.some(inp => inp.functionality == "ConsumptionReturn" && inp.id_operationType)) {
              this.showCmp.consumptionReverse = true;
            } else if (operation.inputList.some(inp => inp.functionality == "ProductionReturn" && inp.id_operationType)) {
              this.showCmp.productionReverse = true;
            } else {
              this.showCmp.quantity = true;
            }
          }

          let paramType: string;

          switch (operation.specialAction) {
            case "PRODUCTION_PARAMS": {
              paramType = "PARAMETROS_MEDICAO";
              break;
            }
            case "PRODUCT_PARAMS": {
              paramType = "PARAMETROS_MEDICAO_PRODUTO";
              break;
            }
            case "QUALITY_PARAMS": {
              paramType = "INSPECAO_PRODUCAO";
              break;
            }
          }

          if (paramType && !operation.specialActionLoaded?.includes(paramType)) {
            if (!operation.specialActionLoaded) {
              operation.specialActionLoaded = [];
            }

            this.sfService.getFormParam(this.currentTaskPlan.id_taskPlan, this.device.deviceId, paramType).pipe(take(1)).subscribe(dataList => {
              operation.specialActionLoaded.push(paramType);

              if (operation.dataList) {
                operation.dataList.push(...dataList);
              } else {
                operation.dataList = dataList;
              }

              if (operation.dataList?.length) {
                this.showCmp.formData = true;
              }

              this.lockScreen.emit(false);
            }, error => {
              this.lockScreen.emit(false);
            });
          } else {
            if (operation.dataList?.length) {
              this.showCmp.formData = true;
            }

            if (operation.specialAction == "REQUEST_RAWMATERIAL") {
              this.showCmp.rawMaterialRequest = true;
            }

            this.lockScreen.emit(false);
          }
        }, error => {
          this.lockScreen.emit(false);
        })
      }
    }
  }

  private clearCmpSelection() {
    this.btnConfirmText = "Confirmar";
    this.disableBtnConfirm = false;
    this.showCmp.operations = false;
    this.showCmp.consumption = false;
    this.showCmp.consumptionReverse = false;
    this.showCmp.formData = false;
    this.showCmp.quantity = false;
    this.showCmp.productionReverse = false;
    this.showCmp.rawMaterialRequest = false;
    this.showCmp.stopReason = false;
  }

  public backToOperations() {
    this.clearCmpSelection();
    this.showCmp.operations = true;
  }

  public ConfirmClick() {
    if (this.appQuantity?.formGroup?.invalid) {
      this.messageService.showToast("Há campos de quantidade obrigatórios que não foram preenchidos !!", "ATENÇÃO", "warning");
      return;
    }

    if (this.appFormData?.formGroup) {
      if (this.appFormData?.formGroup?.invalid) {
        this.messageService.showToast("Há campos de dados obrigatórios que não foram preenchidos !!", "ATENÇÃO", "warning");
        return;
      }
    }

    let reg = new DeviceRegistration();
    reg.id_device = this.device.deviceId;
    reg.id_operationGroup_op = this.selectedOperation.id_operationGroup_op;
    reg.inputs = [];
    reg.data = [];

    if (this.selectedOperation.taskRequirement != "TASK_NOT_ALLOWED") {
      reg.id_task = this.currentTaskPlan?.id_task;
      reg.id_taskPlan = this.currentTaskPlan?.id_taskPlan;
    }

    this.appQuantity?.devRegInputList.forEach(regInp => {
      if (regInp.value) {
        reg.inputs.push(regInp);
      }
    });

    this.appFormData?.regDataList.forEach(regData => {
      reg.data.push(regData);
    });

    if (this.showCmp.consumption) {
      reg.inputs.push(this.appConsumption.devRegInput);
    }

    if (this.showCmp.consumptionReverse) {
      reg.inputs.push(this.appConsumptionReverse.devRegInput);
    }

    if (this.showCmp.productionReverse) {
      reg.inputs.push(this.appProductionReverse.devRegInput);
    }

    if (this.showCmp.stopReason) {
      reg.stopReason = this.appStopReason.devRegStopReasonList;
    }

    this.lockScreen.emit(true);
    if (!this.justifyOperation) this.setNewRegistration(reg);
    if (this.justifyOperation) this.setJustifiedOperation(reg);

  }

  changeBtnConfirmText(text: string) {
    this.btnConfirmText = text;
  }

  rawMaterialRequestLoaded() {
    this.disableBtnConfirm = !this.appRawMaterialReq.requestList?.length;
  }

  disableConfirm() {
    this.disableBtnConfirm = true;
  }

  private adjustmentsAfterAppointment() {
    if (this.searchedTaskPlan && this.selectedOperation.allowedToStartTask) {

      switch (this.selectedOperation.taskProdType) {
        case "PRODUCTION": {
          this.currentTaskPlan.basicType = "Production";
          break;
        }
        case "SETUP": {
          this.currentTaskPlan.basicType = "Setup";
          break;
        }
        case "STOPPED": {
          this.currentTaskPlan.basicType = "Stop";
          break;
        }
      }

      this.currentTaskPlan.operation = this.selectedOperation.description;

      this.router.navigate(
        [],
        {
          relativeTo: this.route,
          queryParams: { tab: "appointment", id_taskPlan: this.searchedTaskPlan.taskPlanId },
          queryParamsHandling: "merge",
        });

      this.searchedTaskPlan = undefined;
      this.taskPlan = this.currentTaskPlan;
      this.deviceTaskPlan = this.currentDeviceTaskPlan;
      this.currentDeviceTaskPlan = undefined;
      this.taskPlanChanged.emit(this.currentTaskPlan);
    }

    if (["FINISH", "SUSPEND"].includes(this.selectedOperation.taskStatus)) {
      this.deviceTaskPlan = undefined;
      this.taskPlan = undefined;
      this.currentTaskPlan = undefined;
      this.taskPlanChanged.emit(this.currentTaskPlan);
    }
  }

  setNewRegistration(reg: DeviceRegistration) {
    this.sfService.setRegistration(reg).pipe(take(1)).subscribe(resp => {
      if (this.selectedOperation.id_report_etq) {
        if (resp?.taskProdQtyIdList?.length) {
          resp?.taskProdQtyIdList?.forEach(taskProdQtyId => {
            this.sfService.printLabel(resp.registrationId, taskProdQtyId);
          });
        } else {
          this.sfService.printLabel(resp.registrationId, "");
        }
      }

      this.adjustmentsAfterAppointment();

      this.selectedOperation = undefined;
      this.backToOperations();
      this.sfService.unsubscribeAllAutoData();

      this.lockScreen.emit(false);
    }, error => {
      this.lockScreen.emit(false);
    });
  }

  setJustifiedOperation(reg: DeviceRegistration) {
    let params = new Map<string, string>();
    params.set("id_taskProd", this.appointment.taskProdId);
    params.set("id_registration", this.appointment.registrationId);
    params.set("id_operationGroup_op", reg.id_operationGroup_op);
    this.sfService.setJustifyOperation(reg, params)
      .pipe(take(1))
      .subscribe(resp => {
        this.adjustmentsAfterAppointment();
        this.selectedOperation = undefined;
        this.sfService.unsubscribeAllAutoData();
        this.backToDocuments(true);
        this.lockScreen.emit(false);
      }, error => {
        this.lockScreen.emit(false);
      });
  }

  backToDocuments(goBack: boolean) {
    this.justifyOperationFinished.emit(goBack);
    this.clearCmpSelection();
  }
}
