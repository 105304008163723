import { AfterViewInit, Component, Input, OnDestroy, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { FormBuilder, Validators } from "@angular/forms";
import { NbDialogService } from "@nebular/theme";
import { take, takeUntil } from "rxjs/Operators";
import { DialogInputComponent } from "src/app/core/cmp/dialog-input/dialog-input.component";
import { T2gridComponent } from "src/app/core/cmp/t2grid/t2grid.component";
import { T2AggregationComponent } from "src/app/core/cmp/ui/t2-aggregation/t2-aggregation.component";
import { LayoutType, ViewTemplateElement } from "src/app/core/cmp/view-template/model/view-template-element";
import { T2SecurityService } from "src/app/core/security/t2security.service";
import { BudgetPolicyItem, BudgetPolicyItemRule, BudgetPolicyService } from "./budget-policy.service";
import { T2AutoFormComponent } from "src/app/core/form/t2-auto-form/t2-auto-form.component";
import { T2DatasetService } from "src/app/core/dataset/t2dataset.service";
import { T2MessageService } from "src/app/core/t2-message.service";
import { BudgetRuleComponent } from "./budget-rule/budget-rule.component";
import { Subject } from "rxjs";

@Component({
  selector: 'app-budget-policy',
  templateUrl: './budget-policy.component.html',
  styleUrls: ['./budget-policy.component.scss']
})
export class BudgetPolicyComponent implements AfterViewInit, OnDestroy {
  @ViewChild('grid', { static: true }) grid: T2gridComponent;
  @ViewChildren('budgetRule') budgetRuleCmpList: QueryList<BudgetRuleComponent>;

  @Input() saveAutoformParent: () => Promise<string>;
  @Input() autoformParent: T2AutoFormComponent;

  public groupedBy: GroupBy = 'Account';
  public selectedPolicyItem: PolicyItem = undefined;
  public budgetPolicyId: string;
  public policyItemList: Array<BudgetPolicyItem>;

  private unsubscribe = new Subject<void>();
  private costCenterData = [];
  private glAccountData = [];

  constructor(private sec: T2SecurityService, private dialogService: NbDialogService, private formBuilder: FormBuilder,
    private bpService: BudgetPolicyService, private dsService: T2DatasetService, private messageService: T2MessageService
  ) { }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  ngAfterViewInit(): void {
    this.budgetPolicyId = this.autoformParent.informationId;

    this.grid.loading = true;

    this.bpService.getBudgetPolicyItemList(this.budgetPolicyId).pipe(take(1)).subscribe(polItList => {
      this.policyItemList = polItList;

      this.costCenterData = [];
      for (let item of this.policyItemList) {
        let ccInfo = item.costCenterInfo;
        this.costCenterData.push({ id_costCenter: ccInfo.code, costCenterCode: ccInfo.code, description: ccInfo.description, hier: item.costCenterHierarchyList.map(cch => cch.description) });
      }

      this.glAccountData = [];
      for (let item of this.policyItemList) {
        let accInfo = item.accountInfo;
        this.glAccountData.push({ id_glAccount: accInfo.code, glAccountCode: accInfo.code, description: accInfo.description, hier: item.accountHierarchyList.map(acch => acch.description) });
      }

      this.setTreeData();
      this.grid.loading = false;
    }, error => {
      this.grid.loading = false;
    });

    this.autoformParent.saved.pipe(takeUntil(this.unsubscribe)).subscribe(resp => {
      this.savePendingRules();
    })
  }

  private setTreeData() {
    const treeData = new Array<PolicyItem>();

    if (this.groupedBy == "Account") {
      this.policyItemList.sort((a, b) => { return a.accountInfo.code.localeCompare(b.accountInfo.code); });
    } else {
      this.policyItemList.sort((a, b) => { return a.costCenterInfo.code.localeCompare(b.costCenterInfo.code); });
    }

    this.policyItemList.forEach(dataItem => {

      const centroCusto = this.costCenterData.find(ccItem => dataItem.costCenterInfo.code == ccItem.id_costCenter);
      const contaContabil = this.glAccountData.find(ccItem => dataItem.accountInfo.code == ccItem.id_glAccount);

      let description;
      let hier;

      if (this.groupedBy == "Account") {
        description = centroCusto.costCenterCode + " " + centroCusto.description + " (" + centroCusto.hier.join(" / ") + ")";
        hier = [...contaContabil.hier, contaContabil.description, description];
      } else {
        description = contaContabil.glAccountCode + " " + contaContabil.description + " (" + contaContabil.hier.join(" / ") + ")";
        hier = [...centroCusto.hier, centroCusto.description, description];
      }

      treeData.push({
        ID: dataItem.budgetPolicyItemId,
        description: description,
        hier: hier,
        costCenter: centroCusto.description + " (" + centroCusto.hier.join(" / ") + ")",
        glAccount: contaContabil.description + " (" + contaContabil.hier.join(" / ") + ")",
        id_costCenter: dataItem.costCenterInfo.code,
        costCenterCode: dataItem.costCenterInfo.code,
        id_glAccount: dataItem.accountInfo.code,
        glAccountCode: dataItem.accountInfo.code
      });
    });

    this.grid.setGridData(treeData);
    this.grid.autoSizeAllColumns(true);
    this.grid.getRowStyle = params => {
      if (params.data?.ID) return { "font-weight": "bold" };
    };
  }

  setGroupBy(group: GroupBy) {
    this.groupedBy = group;
    this.setTreeData();
  }

  async gridRowClick(params) {
    if (this.selectedPolicyItem && this.selectedPolicyItem.ID != params?.data?.ID) {
      await this.savePendingRules();
    }

    this.selectedPolicyItem = params.data;

    let budgetPolItem = this.policyItemList.find(pi => pi.budgetPolicyItemId == this.selectedPolicyItem?.ID);

    if (budgetPolItem) {
      if (budgetPolItem.ruleList?.length) {
        this.selectedPolicyItem.generalRule = budgetPolItem.ruleList.find(r => r.budgetPolicyItemRuleId == budgetPolItem.budgetPolicyItemGlobalRuleId);
        this.selectedPolicyItem.periodRuleList = budgetPolItem.ruleList.filter(r => r.budgetPolicyItemRuleId != budgetPolItem.budgetPolicyItemGlobalRuleId);
      } else {
        this.bpService.getBudgetPolicyItemRules(this.budgetPolicyId, budgetPolItem.budgetPolicyItemId).pipe(take(1)).subscribe(polIt => {
          budgetPolItem.ruleList = polIt.ruleList;

          this.selectedPolicyItem.generalRule = budgetPolItem.ruleList.find(r => r.budgetPolicyItemRuleId == budgetPolItem.budgetPolicyItemGlobalRuleId);
          this.selectedPolicyItem.periodRuleList = budgetPolItem.ruleList.filter(r => r.budgetPolicyItemRuleId != budgetPolItem.budgetPolicyItemGlobalRuleId);
        })
      }
    }
  }

  addPolicyItem() {
    if (this.autoformParent.insert || this.autoformParent?.formGroup?.dirty) {
      this.messageService.showDialog({
        context: {
          title: "ATENÇÃO",
          message: "É necessário salvar antes de realizar uma inclusão",
          actions: [{ description: "OK" }]
        }
      });
    } else {
      this.addItem();
    }
  }

  private async addItem() {
    if (this.selectedPolicyItem) {
      await this.savePendingRules();
    }

    let formGroup = this.formBuilder.group({});
    formGroup.addControl("accountId", this.formBuilder.control(undefined, [Validators.required]));
    formGroup.addControl("costCenterId", this.formBuilder.control(undefined, [Validators.required]));

    let layout: Array<ViewTemplateElement> = [
      {
        layoutType: LayoutType.listLayout,
        direction: "column",
        children: [
          {
            layoutType: LayoutType.gridLayout,
            children: [
              {
                layoutType: LayoutType.component,
                cmpType: T2AggregationComponent,
                cmpName: "accountId",
                title: "Conta Contábil",
                columnSpan: 6,
                isBaseComponent: true,
                inputs: {
                  datasetName: "fin_contacontabil",
                  datasetId: "zD20201027H153519097R000000001"
                }
              },
              {
                layoutType: LayoutType.component,
                cmpType: T2AggregationComponent,
                cmpName: "costCenterId",
                title: "Centro de Custo",
                columnSpan: 6,
                isBaseComponent: true,
                inputs: {
                  datasetName: "cts_centrodecusto",
                  datasetId: "{269FB182-5A7F-43DE-B801-068A6273C574}"
                }
              }
            ]
          }
        ]
      }
    ];

    let dlg = this.dialogService.open(DialogInputComponent, {
      context: {
        FormGroup: formGroup,
        layout: layout,
        title: "Inclusão de Item da Política",
        ngStyle: { "width": "40vw" }
      }
    });

    dlg.onClose.pipe(take(1)).subscribe(resp => {
      if (resp == "Confirma") {
        this.autoformParent.waitingDB = true;

        let policyItem = new BudgetPolicyItem();
        policyItem.accountId = formGroup.controls["accountId"].value;
        policyItem.costCenterId = formGroup.controls["costCenterId"].value;

        if (this.policyItemList.find(it => it.accountId == policyItem.accountId && it.costCenterId == policyItem.costCenterId)) {
          let msg = "Já existe um item da política para a Conta Contábil e Centro de Custo informado.";
          this.messageService.showDialog({
            context: {
              title: "ATENÇÃO",
              message: msg,
              message2: "A operação de inclusão foi cancelada",
              actions: [{description: "OK"}]
            }
          })

          this.autoformParent.waitingDB = false;

          throw new Error(msg);
        }

        this.bpService.addBudgetPolicyItem(this.budgetPolicyId, policyItem).pipe(take(1)).subscribe(resp => {
          policyItem = resp;
          this.policyItemList.push(policyItem);

          let ccInfo = policyItem.costCenterInfo;

          if (!this.costCenterData.some(cc => cc.code == ccInfo.code)) {
            this.costCenterData.push({ id_costCenter: ccInfo.code, costCenterCode: ccInfo.code, description: ccInfo.description, hier: policyItem.costCenterHierarchyList.map(cch => cch.description) });
          }

          let accInfo = policyItem.accountInfo;

          if (!this.glAccountData.some(acc => acc.glAccountCode == accInfo.code)) {
            this.glAccountData.push({ id_glAccount: accInfo.code, glAccountCode: accInfo.code, description: accInfo.description, hier: policyItem.accountHierarchyList.map(acch => acch.description) });
          }

          this.setTreeData();
          this.autoformParent.waitingDB = false;
        }, error => {
          this.autoformParent.waitingDB = false;
        })
      }
    })
  }

  public deleteItem() {
    this.messageService.showDialog({
      context: {
        topMessage: "ATENÇÃO",
        message: "Deseja excluir esse registro ?",
        actions: [{ description: "Excluir", status: "danger" }, { description: "Cancelar", status: "basic" }]
      }
    }).onClose.pipe(take(1)).subscribe(resp => {
      if (resp == "Excluir") {
        this.autoformParent.waitingDB = true;
        this.dsService.deleteDatasetRecord("cst_polOrcamentaria_it", this.selectedPolicyItem.ID).pipe(take(1)).subscribe(() => {
          let index = this.policyItemList.findIndex(it => it.budgetPolicyItemId == this.selectedPolicyItem.ID);

          this.selectedPolicyItem = undefined;
          this.policyItemList.splice(index, 1);

          this.setTreeData();

          this.autoformParent.waitingDB = false;
        }, error => {
          this.autoformParent.waitingDB = false;
        })
      }
    })
  }

  public savePendingRules() {
    this.autoformParent.waitingDB = true;
    return new Promise<void>((resolve, reject) => {
      let promList = new Array<Promise<void>>();
      this.budgetRuleCmpList?.filter(cmp => cmp.hasToSave())?.forEach(cmp => {
        promList.push(cmp.saveData());
      });

      Promise.all(promList).then(() => {
        this.autoformParent.waitingDB = false;
        resolve();
      }, error => {
        this.autoformParent.waitingDB = false;
        reject(error);
      });
    });
  }

  public rulesChanged() {
    this.autoformParent.formGroup.markAsDirty();
  }

  public lockScreen(status: boolean) {
    this.autoformParent.waitingDB = status;
  }
}

export type GroupBy = 'Cost' | 'Account';

export class PolicyItem {
  ID: string;
  description?: string;
  id_costCenter: string;
  costCenter?: string;
  costCenterCode: string;
  id_glAccount: string;
  glAccount?: string;
  glAccountCode: string;
  hier?: Array<string>;

  generalRule?: BudgetPolicyItemRule;
  periodRuleList?: Array<BudgetPolicyItemRule>;
}
