import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";

@Component({
  template: ""
})
export abstract class T2BaseComponent implements OnChanges {

  private _formGroup: FormGroup;
  private _visible: boolean;
  private _readOnly: boolean;
  private _placeHolder: string;
  private _ngStyle: any;
  private _defaultValue: any;
  private _additionalInfo: string;

  @Input("formGroup")
  get formGroup(): FormGroup { return this._formGroup; }
  set formGroup(value: FormGroup) {
    if (value instanceof FormGroup) {
      this._formGroup = value;
    }
  }

  @Input() t2IdCmp: string;

  @Input("visible")
  get visible(): boolean { return this._visible; }
  set visible(value: boolean) {
    this._visible = value;
    this.visibleChanged.emit(value);
  }

  @Input("readOnly")
  get readOnly(): boolean {
    let value: boolean;

    if (this.formGroup && this.formGroup.readOnly) {
      value = true;
    } else {
      value = this._readOnly;
    }

    return value;
  }
  set readOnly(value: boolean) {
    this._readOnly = value;
  }

  @Input("placeHolder")
  get placeHolder(): string {
    return this._placeHolder
  }
  set placeHolder(value: string) {
    this._placeHolder = value;
  }

  @Input("ngStyle")
  get ngStyle(): any {
    return this._ngStyle;
  }
  set ngStyle(value: any) {
    this._ngStyle = value;
  }

  @Input("defaultValue")
  get defaultValue(): any {
    return this._defaultValue;
  }
  set defaultValue(value: any) {
    this._defaultValue = value;
  }

  @Input("additionalInfo")
  get additionalInfo(): string {
    return this._additionalInfo;
  }
  set additionalInfo(value: string) {
    this._additionalInfo = value;
  }

  @Output() visibleChanged = new EventEmitter<boolean>();

  /**
   *
   * @param changes
   * @description A implementação padrão altera os campos padrões. Esse método pode ser sobreposto caso necessário
   */
  ngOnChanges(changes: SimpleChanges): void {
    this.makeBaseCmpChanges(changes);
  }

  protected makeBaseCmpChanges(changes: SimpleChanges) {
    if (changes['id'] && changes['id']?.currentValue) {
      this.t2IdCmp = changes['id'].currentValue;
    }

    if (changes['ngStyle'] && changes['ngStyle']?.currentValue) {
      this.ngStyle = changes['ngStyle'].currentValue;
    }

    if (changes['visible'] && changes['visible']?.currentValue) {
      this.visible = changes['visible'].currentValue;
    }

    if (changes['readOnly'] && changes['readOnly']?.currentValue) {
      this.readOnly = changes['readOnly'].currentValue;
    }

    if (changes['placeHolder'] && changes['placeHolder']?.currentValue) {
      this.placeHolder = changes['placeHolder'].currentValue;
    }

    if (changes['defaultValue'] && changes['defaultValue']?.currentValue) {
      this.defaultValue = changes['defaultValue'].currentValue;
    }

    if (changes['formGroup'] && changes['formGroup']?.currentValue instanceof FormGroup) {
      this.formGroup = changes['formGroup'].currentValue;
    }

    if (changes["additionalInfo"]?.currentValue) {
      this.additionalInfo = changes["additionalInfo"].currentValue;
    }
  }

  protected setDefaultValues() {
    if (!this.formGroup) {
      let formBuilder = new FormBuilder();
      this.formGroup = formBuilder.group({}, { updateOn: 'blur' });
      this.formGroup.addControl(this.t2IdCmp, formBuilder.control(this.defaultValue));

      if (this.defaultValue) {
        this.formGroup.controls[this.t2IdCmp].markAsDirty();
      }
    }

    if (this.visible == undefined || this.visible == null) {
      this.visible = true;
    }

    if (this.readOnly == undefined || this.readOnly == null) {
      this.readOnly = false;
    }

    if (this.defaultValue && this.formGroup.controls[this.t2IdCmp].value == undefined) {
      this.formGroup.controls[this.t2IdCmp].setValue(this.defaultValue);
      this.formGroup.controls[this.t2IdCmp].markAsDirty();
    }
  }
}
