import { T2ViewTemplateAction } from "./t2-view-template-action";

export enum InputType {
  component = "component",
  static = "static"
}

export enum Condition {
  denyValue = "denyValue"
}

export enum ConditionalOperator {
  equals = "equals",
  in = "in",
  notIn = "notIn"
}

export class T2ViewTemplateFlowConditionalOutput {
  inputValue: any;
  operator: ConditionalOperator;
  outputValue: any;
}

export class T2ViewTemplateFlow {
  action?: T2ViewTemplateAction;
  actionName?: string;
  triggerCmpNameList: Array<string>;
  inputs?: Array<{
    type: InputType,
    cmpName?: string,
    cmpInputName?: string,
    paramName: string,
    paramValue?: any,
    condition?: Condition
  }>;
  outputs?: Array<{
    cmpName: string,
    cmpInputName?: string,
    emitChangeEvent?: boolean,

    // apenas para action do tipo "runScript"
    scriptObjKey?: string;

    // apenas para action do tipo "changeValue"
    conditionList?: Array<T2ViewTemplateFlowConditionalOutput>;
  }>;
}
