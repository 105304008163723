<app-dialog-template #dialog
  [title]="queryMode ? null : description + (fromDictionary ? ' - Padrão de visualização' : ' - Configuração de visualização')">

  <content style="width: 75vw; max-width: 80vw; height: 60vh;">
    <app-visualization [fromDictionary]="fromDictionary" [queryMode]="queryMode" [id_dataset]="id_dataset"
      [description]="description" [selectedFieldList]="selectedFieldList" [showPinned]="showPinned"
      style="height: 100%; display: flex; flex-direction: row;" />
  </content>

  <footer style="display: flex; gap: 5px; justify-content: space-between;">
    <!-- <div>
      <nb-checkbox *ngIf="!fromDictionary && !queryMode" [(checked)]="saveAsDefault">Salvar como padrão?</nb-checkbox>
    </div> -->
    <div style="display: flex; gap: 5px">
      <button nbButton size="tiny" (click)="closeDialog(false)">Cancelar</button>
      <button nbButton status="primary" size="tiny" (click)="closeDialog(true)" [nbSpinner]="savingDefVisualization"
        [disabled]="savingDefVisualization">
        {{ queryMode ? 'Selecionar' : (fromDictionary ? 'Salvar' : 'Aplicar')}}
      </button>
    </div>
  </footer>
</app-dialog-template>
