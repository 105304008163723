import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { Subject } from "rxjs";
import { delay, take, takeUntil } from "rxjs/Operators";
import { FormTemplateComponent } from "src/app/core/cmp/form-template/form-template.component";
import { T2HttpClientService } from "src/app/core/http/t2httpClient.service";
import { T2Route } from "src/app/core/http/t2route";
import { T2SecurityService } from "src/app/core/security/t2security.service";

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild("formTemplate", { static: true }) formTemplate: FormTemplateComponent;

  public loaded: boolean = false;
  public title: string;
  private t2Route: T2Route;
  private actionId: string;
  public scriptName: string;
  public params: { [key: string]: any };
  private unsubscribe = new Subject<void>();

  constructor(private httpClient: T2HttpClientService, private route: ActivatedRoute, private secService: T2SecurityService) { }
  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  ngOnInit(): void {
    this.secService.accessLoaded()
      .pipe(
        take(1),
      ).subscribe(resp => {
        this.t2Route = this.httpClient.getT2RouteFromSnapshot(this.route.snapshot);
        this.route.params.pipe(takeUntil(this.unsubscribe)).subscribe(urlParams => {
          this.actionId = urlParams["actionId"] || this.actionId;

          if (this.formTemplate.validateAccess([this.actionId], 'dashboard')) {
            this.secService.getAction(this.actionId).subscribe(resp => {

              let actionCommand = resp.actionCommand.substring(resp.actionCommand.indexOf(":") + 1);
              if (actionCommand.trim().startsWith("//")) {
                actionCommand = actionCommand.substring(actionCommand.indexOf("//") + 2);
              }
              const sep = actionCommand.indexOf("?");
              if (sep > -1) {
                this.scriptName = actionCommand.substring(0, sep);
              } else {
                this.scriptName = actionCommand;
              }

              this.loaded = true;
            }, error => {
              this.loaded = true;
            });
          }
        })
      });

  }

  ngAfterViewInit(): void {

  }

  setTitle(value: string) {
    this.title = value;
  }

}
