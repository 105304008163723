import { Component, OnInit, ViewChild } from '@angular/core';
import { NbDialogRef } from "@nebular/theme";
import { DialogTemplateComponent } from "src/app/core/cmp/dialog-template/dialog-template.component";
import { T2gridComponent } from "src/app/core/cmp/t2grid/t2grid.component";
import { EngraveInvoiceComponent } from "../engrave-invoice/engrave-invoice.component";

@Component({
  selector: 'app-supplier',
  templateUrl: './supplier.component.html',
  styleUrls: ['./supplier.component.scss']
})
export class SupplierComponent implements OnInit {

  public suppNameFilter: string;
  public supplierList: Array<any> = [];

  constructor(public dialogRef: NbDialogRef<EngraveInvoiceComponent>) {
  }

  @ViewChild("gdForn", { static: true }) gdForn: T2gridComponent;
  @ViewChild('dialog', { static: true }) dialog: DialogTemplateComponent;

  ngOnInit() {
    this.load();
  }

  load() {
    this.gdForn.setGridColumnDefs(
      [
        { checkboxSelection: true, lockPosition: true, pinned: "left", valueGetter: "node.rowIndex + 1", width: 45, suppressNavigable: true },
        { headerName: "Cód", field: "code" },
        { headerName: "Nome", field: "supplierName" },
        { headerName: "Doc", field: "document" },
      ]);

    this.gdForn.setGridData(this.supplierList, null);
  }

  onGDFornDoubleClicked(event) {
    this.gdForn.setSelected(event.data.ID);
    this.gdForn.onGDSelectionChanged(event);
    this.closeWindow(true);
  }

  closeWindow(confirm: boolean) {
    const result = {
      confirm: confirm,
      id_conta: this.gdForn.getRowsSelected().length ? this.gdForn.getRowsSelected()[0].id_conta : null
    };

    this.dialogRef.close(result);
  }

  onSuppNameKeyEnter(event) {
    const filterText: string = event.target.value;

    const gridFilter = this.gdForn.getFilterInstance("supplierName");
    gridFilter.setModel(null);
    if (filterText && filterText.trim()) {
      gridFilter.setModel({ type: "contains", filter: filterText });
    }
    this.gdForn.callOnFilterChanged();
  }

}
