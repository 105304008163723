import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Subject } from "rxjs";
import { take, takeUntil } from "rxjs/Operators";
import { ActionService } from "src/app/core/action/action.service";
import { DialogTemplateComponent } from "src/app/core/cmp/dialog-template/dialog-template.component";
import { T2AggregationComponent } from "src/app/core/cmp/ui/t2-aggregation/t2-aggregation.component";
import { T2CheckBoxComponent } from "src/app/core/cmp/ui/t2-check-box/t2-check-box.component";
import { T2InputFloatComponent } from "src/app/core/cmp/ui/t2-input-float/t2-input-float.component";
import { T2InputIntegerComponent } from "src/app/core/cmp/ui/t2-input-integer/t2-input-integer.component";
import { T2InputTextComponent } from "src/app/core/cmp/ui/t2-input-text/t2-input-text.component";
import { T2SelectComponent } from "src/app/core/cmp/ui/t2-select/t2-select.component";
import { LayoutType, ViewTemplateElement } from "src/app/core/cmp/view-template/model/view-template-element";
import { T2DatasetService } from "src/app/core/dataset/t2dataset.service";
import { T2HttpClientService } from "src/app/core/http/t2httpClient.service";
import { T2AccessItem, T2AccessItemDatasetActionType } from "src/app/core/security/model/t2accessItem";
import { T2SecurityService } from "src/app/core/security/t2security.service";
import { T2MessageService } from "src/app/core/t2-message.service";
import { T2LocalStorageService } from "src/app/core/t2local-storage.service";

@Component({
  selector: 'app-measurement-parameters',
  templateUrl: './measurement-parameters.component.html',
  styleUrls: ['./measurement-parameters.component.scss']
})
export class MeasurementParametersComponent implements OnInit, AfterViewInit {
  @Input() t2IdCmp: string;
  @Input() insert: boolean;
  @Input() id: string;
  @Input() parentId: string;

  @ViewChild('dialog', { static: true }) dialog: DialogTemplateComponent;

  private datasetName = "prd_especif_carac_paramMPrd_it";
  private childrenCmp: Array<ViewTemplateElement>;
  public FormGroup: FormGroup;
  public loaded: boolean = false;
  private unsubscribe = new Subject<void>();
  public lockScreen: boolean = false;
  private paramType: string;
  private datasetActions: Array<T2AccessItem>;
  public canSave: boolean;
  public canDelete: boolean;

  public layout: Array<ViewTemplateElement> = [
    {
      layoutType: LayoutType.listLayout,
      direction: "column",
      children: [
        {
          layoutType: LayoutType.gridLayout,
          children: [
            {
              layoutType: LayoutType.component,
              cmpType: T2AggregationComponent,
              cmpName: `${this.datasetName}.id_paramMedicaoProducao`,
              title: "Parâmetro",
              columnSpan: 6,
              isBaseComponent: true,
              inputs: {
                datasetName: "prd_paramMedicaoProducao",
                datasetId: "zD20180606H103410900R000000001"
              }
            },
            {
              layoutType: LayoutType.component,
              cmpType: T2SelectComponent,
              cmpName: `${this.datasetName}.tipoParametro`,
              title: "Tipo",
              columnSpan: 2,
              isBaseComponent: true,
              inputs: {
                required: true,
                list: [
                  {
                    id: "Produção",
                    description: "Produção"
                  },
                  {
                    id: "Produto",
                    description: "Produto"
                  }
                ]
              }
            }
          ]
        },
        {
          layoutType: LayoutType.gridLayout,
          children: [
            {
              layoutType: LayoutType.component,
              cmpType: T2AggregationComponent,
              cmpName: `${this.datasetName}.id_operacao`,
              title: "Operação",
              columnSpan: 6,
              isBaseComponent: true,
              inputs: {
                datasetName: "prd_operacao",
                datasetId: "{22EE5C02-8410-4A2F-BE22-062183D2B360}"
              }
            },
            {
              layoutType: LayoutType.component,
              cmpType: T2AggregationComponent,
              cmpName: `${this.datasetName}.id_grupoDispositivo`,
              title: "Grupo Dispositivo",
              columnSpan: 6,
              isBaseComponent: true,
              inputs: {
                datasetName: "prd_grupoDispositivo",
                datasetId: "{B6DAA6E5-643E-4965-83F3-7631C1B96DAC}"
              }
            },
            {
              layoutType: LayoutType.component,
              cmpType: T2AggregationComponent,
              cmpName: `${this.datasetName}.id_origemColetaAutom`,
              title: "Origem de Dados",
              columnSpan: 6,
              isBaseComponent: true,
              inputs: {
                datasetName: "prd_origemColetaAutom",
                datasetId: "zD20170707H144539740R000000008"
              }
            },
            {
              layoutType: LayoutType.component,
              cmpType: T2InputIntegerComponent,
              cmpName: `${this.datasetName}.sortKey`,
              title: "Sequência",
              columnSpan: 2,
              isBaseComponent: true
            }
          ]
        },
        {
          layoutType: LayoutType.gridLayout,
          children: []
        }
      ]
    }
  ];

  constructor(private httpClient: T2HttpClientService,
    private formBuilder: FormBuilder,
    private storageService: T2LocalStorageService,
    private messageService: T2MessageService,
    private datasetService: T2DatasetService,
    private securityService: T2SecurityService,
    private actionService: ActionService
  ) { }

  ngOnInit(): void {
    this.securityService.getDatasetActions(this.datasetName).subscribe(acList => {
      this.datasetActions = acList;
      this.canDelete = acList.some(ac => ac.datasetActionType == T2AccessItemDatasetActionType.DSACTION_DELETE);
    });

    this.FormGroup = this.formBuilder.group({}, { updateOn: "blur" });

    this.FormGroup.addControl(`${this.datasetName}.id_paramMedicaoProducao`, this.formBuilder.control(undefined, Validators.required));
    this.FormGroup.addControl(`${this.datasetName}.tipoParametro`, this.formBuilder.control(undefined, Validators.required));
    this.FormGroup.addControl(`${this.datasetName}.id_operacao`, this.formBuilder.control(undefined));
    this.FormGroup.addControl(`${this.datasetName}.id_grupoDispositivo`, this.formBuilder.control(undefined));
    this.FormGroup.addControl(`${this.datasetName}.id_origemColetaAutom`, this.formBuilder.control(undefined));
    this.FormGroup.addControl(`${this.datasetName}.sortKey`, this.formBuilder.control(undefined));
    this.FormGroup.addControl(`${this.datasetName}.valorParam`, this.formBuilder.control(undefined));
    this.FormGroup.addControl(`${this.datasetName}.texto`, this.formBuilder.control(undefined));
    this.FormGroup.addControl(`${this.datasetName}.valor1`, this.formBuilder.control(undefined));
    this.FormGroup.addControl(`${this.datasetName}.valor2`, this.formBuilder.control(undefined));
    this.FormGroup.addControl(`${this.datasetName}.valor3`, this.formBuilder.control(undefined));
    this.FormGroup.addControl(`${this.datasetName}.logico`, this.formBuilder.control(undefined));
  }

  ngAfterViewInit(): void {
    this.childrenCmp = this.layout[0].children[2].children;

    this.FormGroup.controls[`${this.datasetName}.id_paramMedicaoProducao`].valueChanges.pipe(takeUntil(this.unsubscribe))
      .subscribe(async value => {
        if (value) {
          let measurementParam: {
            id_paramMedicaoProducao: string,
            codigo: string,
            descricao: string,
            id_unit: string,
            tipo: string,
            listaOpcao?: Array<{ id_paramMedicaoProducao_opc: string, descricao: string, padrao: boolean }>
          };

          measurementParam = JSON.parse(this.storageService.getSessionData(`prd_paramMedicaoProducao#${value}`));

          if (!measurementParam) {
            await this.httpClient.get(`core.dynDataset/datasetEntity/prd_paramMedicaoProducao/${value}`, null).pipe(take(1))
              .toPromise().then(async resp => {
                if (resp && resp.prd_paramMedicaoProducao) {
                  resp = resp.prd_paramMedicaoProducao;

                  measurementParam = {
                    id_paramMedicaoProducao: value,
                    codigo: resp["codigo"],
                    descricao: resp["descricao"],
                    tipo: resp["tipo"],
                    id_unit: resp["id_unit"]
                  };

                  if (measurementParam.tipo == "Lista de Opções") {
                    let params = new Map();
                    params.set("deleted", false);
                    params.set("value1", `'${value}'`);

                    await this.httpClient.get("core.dynDataset/datasetEntity/prd_paramMedicaoProducao_opc/cond1/id_paramMedicaoProducao", params)
                      .pipe(take(1))
                      .toPromise().then(resp => {
                        if (resp && resp.prd_paramMedicaoProducao_opc) {
                          resp = resp.prd_paramMedicaoProducao_opc;

                          if (!Array.isArray(resp)) {
                            resp = [resp];
                          }

                          measurementParam.listaOpcao = resp.map(opcao => {
                            return {
                              id_paramMedicaoProducao_opc: opcao["id_paramMedicaoProducao_opc"],
                              descricao: opcao["descricao"],
                              padrao: opcao["padrao"]
                            }
                          })
                        }
                      });
                  }

                  this.storageService.setSessionData(`prd_paramMedicaoProducao#${value}`, JSON.stringify(measurementParam));
                }
              });
          }

          this.childrenCmp.splice(0, this.childrenCmp.length);
          this.paramType = measurementParam.tipo;

          switch (measurementParam.tipo) {
            case "Número": {
              this.childrenCmp.push(
                {
                  layoutType: LayoutType.component,
                  cmpType: T2InputFloatComponent,
                  cmpName: `${this.datasetName}.valor1`,
                  title: "Valor",
                  columnSpan: 2,
                  isBaseComponent: true,
                  inputs: {
                    t2IdCmp: `${this.datasetName}.valor1`
                  }
                }
              );

              this.FormGroup.controls[`${this.datasetName}.valorParam`].setValue(undefined);
              this.FormGroup.controls[`${this.datasetName}.texto`].setValue(undefined);
              this.FormGroup.controls[`${this.datasetName}.logico`].setValue(undefined);
              this.FormGroup.controls[`${this.datasetName}.valor2`].setValue(undefined);
              this.FormGroup.controls[`${this.datasetName}.valor3`].setValue(undefined);

              break;
            }
            case "Número com tolerância": {
              this.childrenCmp.push(
                {
                  layoutType: LayoutType.component,
                  cmpType: T2InputFloatComponent,
                  cmpName: `${this.datasetName}.valor1`,
                  title: "Nominal",
                  columnSpan: 2,
                  isBaseComponent: true
                }
              );

              this.childrenCmp.push(
                {
                  layoutType: LayoutType.component,
                  cmpType: T2InputFloatComponent,
                  cmpName: `${this.datasetName}.valor2`,
                  title: "Mínimo",
                  columnSpan: 2,
                  isBaseComponent: true
                }
              );

              this.childrenCmp.push(
                {
                  layoutType: LayoutType.component,
                  cmpType: T2InputFloatComponent,
                  cmpName: `${this.datasetName}.valor3`,
                  title: "Máximo",
                  columnSpan: 2,
                  isBaseComponent: true
                }
              );

              this.FormGroup.controls[`${this.datasetName}.valorParam`].setValue(undefined);
              this.FormGroup.controls[`${this.datasetName}.texto`].setValue(undefined);
              this.FormGroup.controls[`${this.datasetName}.logico`].setValue(undefined);

              break;
            }
            case "Intervalo": {
              this.childrenCmp.push(
                {
                  layoutType: LayoutType.component,
                  cmpType: T2InputFloatComponent,
                  cmpName: `${this.datasetName}.valor1`,
                  title: "Intervalo Início",
                  columnSpan: 2,
                  isBaseComponent: true
                }
              );

              this.childrenCmp.push(
                {
                  layoutType: LayoutType.component,
                  cmpType: T2InputFloatComponent,
                  cmpName: `${this.datasetName}.valor2`,
                  title: "Intervalo Fim",
                  columnSpan: 2,
                  isBaseComponent: true
                }
              );

              this.FormGroup.controls[`${this.datasetName}.valorParam`].setValue(undefined);
              this.FormGroup.controls[`${this.datasetName}.texto`].setValue(undefined);
              this.FormGroup.controls[`${this.datasetName}.logico`].setValue(undefined);
              this.FormGroup.controls[`${this.datasetName}.valor3`].setValue(undefined);

              break;
            }
            case "Texto": {
              this.childrenCmp.push(
                {
                  layoutType: LayoutType.component,
                  cmpType: T2InputTextComponent,
                  cmpName: `${this.datasetName}.texto`,
                  title: "Valor",
                  columnSpan: 6,
                  isBaseComponent: true
                }
              );

              this.FormGroup.controls[`${this.datasetName}.valorParam`].setValue(undefined);
              this.FormGroup.controls[`${this.datasetName}.logico`].setValue(undefined);
              this.FormGroup.controls[`${this.datasetName}.valor1`].setValue(undefined);
              this.FormGroup.controls[`${this.datasetName}.valor2`].setValue(undefined);
              this.FormGroup.controls[`${this.datasetName}.valor3`].setValue(undefined);

              break;
            }
            case "Lista de Opções": {
              this.childrenCmp.push(
                {
                  layoutType: LayoutType.component,
                  cmpType: T2SelectComponent,
                  cmpName: `${this.datasetName}.texto`,
                  title: "Valor",
                  columnSpan: 2,
                  isBaseComponent: true,
                  inputs: {
                    list: measurementParam.listaOpcao.map(opcao => {
                      return {
                        id: opcao.id_paramMedicaoProducao_opc,
                        description: opcao.descricao
                      }
                    })
                  }
                }
              );

              this.FormGroup.controls[`${this.datasetName}.valorParam`].setValue(undefined);
              this.FormGroup.controls[`${this.datasetName}.logico`].setValue(undefined);
              this.FormGroup.controls[`${this.datasetName}.valor1`].setValue(undefined);
              this.FormGroup.controls[`${this.datasetName}.valor2`].setValue(undefined);
              this.FormGroup.controls[`${this.datasetName}.valor3`].setValue(undefined);

              break;
            }
            case "Lógico": {
              this.childrenCmp.push(
                {
                  layoutType: LayoutType.component,
                  cmpType: T2CheckBoxComponent,
                  cmpName: `${this.datasetName}.logico`,
                  title: "Valor",
                  columnSpan: 2,
                  isBaseComponent: true
                }
              );

              this.FormGroup.controls[`${this.datasetName}.valorParam`].setValue(undefined);
              this.FormGroup.controls[`${this.datasetName}.texto`].setValue(undefined);
              this.FormGroup.controls[`${this.datasetName}.valor1`].setValue(undefined);
              this.FormGroup.controls[`${this.datasetName}.valor2`].setValue(undefined);
              this.FormGroup.controls[`${this.datasetName}.valor3`].setValue(undefined);

              break;
            }
          }

          this.layout = [...this.layout];
        }
      })

    if (!this.insert) {
      this.canSave = this.datasetActions.some(ac => ac.datasetActionType == T2AccessItemDatasetActionType.DSACTION_EDIT);
      this.httpClient.get(`core.dynDataset/datasetEntity/prd_especif_carac_paramMPrd_it/${this.id}`, null).pipe(take(1))
        .subscribe(resp => {
          if (resp.prd_especif_carac_paramMPrd_it) {
            resp = resp.prd_especif_carac_paramMPrd_it;

            this.FormGroup.controls[`${this.datasetName}.id_paramMedicaoProducao`].setValue(resp["id_paramMedicaoProducao"]);
            this.FormGroup.controls[`${this.datasetName}.tipoParametro`].setValue(resp["tipoParametro"]);
            this.FormGroup.controls[`${this.datasetName}.id_operacao`].setValue(resp["id_operacao"]);
            this.FormGroup.controls[`${this.datasetName}.id_grupoDispositivo`].setValue(resp["id_grupoDispositivo"]);
            this.FormGroup.controls[`${this.datasetName}.id_origemColetaAutom`].setValue(resp["id_origemColetaAutom"]);
            this.FormGroup.controls[`${this.datasetName}.sortKey`].setValue(new Number(resp["sortKey"] || 0));
            this.FormGroup.controls[`${this.datasetName}.valorParam`].setValue(resp["valorParam"]);
            this.FormGroup.controls[`${this.datasetName}.texto`].setValue(resp["texto"]);
            this.FormGroup.controls[`${this.datasetName}.valor1`].setValue(new Number(resp["valor1"] || 0));
            this.FormGroup.controls[`${this.datasetName}.valor2`].setValue(new Number(resp["valor2"] || 0));
            this.FormGroup.controls[`${this.datasetName}.valor3`].setValue(new Number(resp["valor3"] || 0));
            this.FormGroup.controls[`${this.datasetName}.logico`].setValue(new Boolean(JSON.parse(resp["logico"])).valueOf());
          }

          this.loaded = true;
        })
    } else {
      this.canSave = this.datasetActions.some(ac => ac.datasetActionType == T2AccessItemDatasetActionType.DSACTION_INSERT);
      this.loaded = true;
    }
  }

  close() {
    this.dialog.close(undefined);
  }

  save() {
    if (!this.FormGroup.valid) {
      this.messageService.showToastError("Antes de salvar, preencha os campos obrigatórios");
      return;
    }

    this.lockScreen = true;

    this.FormGroup.addControl(`${this.datasetName}.id_especif_carac`, this.formBuilder.control(this.parentId));
    this.FormGroup.controls[`${this.datasetName}.id_especif_carac`].markAsDirty();

    let valorParam = this.FormGroup.controls[`${this.datasetName}.valorParam`];

    if (this.paramType == "Número") {
      valorParam.setValue(this.FormGroup.controls[`${this.datasetName}.valor1`].value);
    } else if (this.paramType == "Número com tolerância") {
      let v1 = this.FormGroup.controls[`${this.datasetName}.valor1`].value;
      let v2 = this.FormGroup.controls[`${this.datasetName}.valor2`].value;
      let v3 = this.FormGroup.controls[`${this.datasetName}.valor3`].value;

      valorParam.setValue(`Nominal ${v1} Min ${v2} Max ${v3}`);
    } else if (this.paramType == "Intervalo") {
      let v1 = this.FormGroup.controls[`${this.datasetName}.valor1`].value;
      let v2 = this.FormGroup.controls[`${this.datasetName}.valor2`].value;

      valorParam.setValue(`Entre ${v1} e ${v2}`);
    } else if (this.paramType == "Texto" || this.paramType == "Lista de Opções") {
      valorParam.setValue(this.FormGroup.controls[`${this.datasetName}.texto`].value);
    } else if (this.paramType == "Lógico") {
      valorParam.setValue(this.FormGroup.controls[`${this.datasetName}.logico`].value == true ? "Sim" : "Não");
    } 

    valorParam.markAsDirty();

    this.datasetService.saveFormGroupEntity(this.datasetName, this.id, this.FormGroup).pipe(take(1)).subscribe(resp => {
      this.FormGroup.markAsPristine();
      this.insert = false;
      this.lockScreen = false;
    }, error => {
      this.lockScreen = false;
    })
  }

  delete() {
    this.messageService.showDialog({
      context: {
        topMessage: "ATENÇÃO",
        message: "Deseja excluir esse registro ?",
        actions: [{ description: "Excluir", status: "danger" }, { description: "Cancelar", status: "basic" }]
      }
    }).onClose.pipe(take(1)).subscribe(resp => {
      if (resp == "Excluir") {
        let deleteAction = this.datasetActions.find(ac => ac.datasetActionType == T2AccessItemDatasetActionType.DSACTION_DELETE);

        let params = new Map<string, string>();
        params.set("datasetName", this.datasetName);
        params.set("id", this.id);

        this.actionService.executeAction(deleteAction, params).pipe(take(1)).subscribe(() => {
          this.close();
        })
      }
    })
  }
}
