import { CilindroCor } from '../model/cilindro-cor';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { Component } from '@angular/core';

@Component({
  selector: 'app-cil-small-box-renderer',
  templateUrl: './cil-small-box-renderer.component.html',
  styleUrls: ['./cil-small-box-renderer.component.scss']
})
export class CilSmallBoxRendererComponent implements ICellRendererAngularComp {
  cils = 0;
  cilND = 0;
  cilOK = 0;
  cilRev = 0;
  cilGrav = 0;
  cilRecromo = 0;

  refresh(params: any): boolean {
    return true;
  }
  agInit(params: import("ag-grid-community").ICellRendererParams): void {    
    if (params.data.colors) {
      const cores: Array<CilindroCor> = params.data.colors;

      this.cils = cores.length;

      cores.forEach(cor => {
        if (cor) {
          switch (cor.status) {
            case "OK":
              this.cilOK++;
              break;
            case "REVISAR":
              this.cilRev++;
              break;
            case "GRAVAR":
              this.cilGrav++;
              break;
            case "RECROMO":
              this.cilRecromo++;
              break;
            default:
              this.cilND++;
              break;
          }
        }
      });
    }
  }
  afterGuiAttached?(params?: import("ag-grid-community").IAfterGuiAttachedParams): void {
  }
}
