import { StatusEditorComponent } from './cellEditor/status-editor/status-editor.component';
import { AggregationRendererComponent } from './cellRenderer/aggregation-renderer/aggregation-renderer.component';
import { StatusRendererComponent } from './cellRenderer/status-renderer/status-renderer.component';
import { Component, Input, Output, EventEmitter, ViewChild, Inject, LOCALE_ID, OnDestroy } from "@angular/core";
import { DatePipe, formatNumber } from "@angular/common";
import { T2gridExportToPDF } from './t2gridExportToPDF';
import { AgGridAngular } from "ag-grid-angular";
import { ColumnApi, GridOptions, GridApi, Column, IFilterComp, RedrawRowsParams, RefreshCellsParams, GetLocaleTextParams, ColDef, ColGroupDef, RowGroupingDisplayType, ColumnState, IRowNode, RowClassRules, ISetFilterParams, GetContextMenuItemsParams, CellClickedEvent, RowStyle, RowClassParams } from "ag-grid-community";
import { T2ThemingService } from "../../t2theming.service";
import { NumericEditorComponent } from "./cellEditor/numeric-editor.component";
import { SelectEditorComponent } from "./cellEditor/select-editor/select-editor.component";
import { CheckBoxRendererComponent } from "./cellRenderer/check-box-renderer/check-box-renderer.component";
import { AggregationEditorComponent } from "./cellEditor/aggregation-editor/aggregation-editor.component";
import { DateTimeEditorComponent } from "./cellEditor/date-time-editor/date-time-editor.component";
import { IconRendererComponent } from "./cellRenderer/icon-renderer/icon-renderer.component";
import { T2LocalStorageService } from "../../t2local-storage.service";
import agGridLocale from "../../../../assets/agGridLocalePTBR.json";
import { T2HttpClientService } from "../../http/t2httpClient.service";
import { IT2Loading } from "../it2-loading";

@Component({
  selector: "app-t2grid",
  templateUrl: "./t2grid.component.html",
  styleUrls: ["./t2grid.component.scss"],
})
export class T2gridComponent implements IT2Loading, OnDestroy {
  private _loading = false;
  private _components: any = null;
  // private decimalSeparator = ","; // virgula

  public sideBar: any = { toolPanels: [], hiddenByDefault: true };

  private _settingGridData: number = 0;

  @Input()
  get showFiltersSidebar(): boolean {
    return this.sideBar.toolPanels.indexOf("filters") >= 0;
  }
  set showFiltersSidebar(value: boolean) {
    if (value) {
      this.sideBar.toolPanels.push("filters");
    } else {
      this.sideBar.toolPanels = this.sideBar.toolPanels.filter((item: string) => item != "filters")
    }

    this.sideBar.hiddenByDefault = (this.sideBar.toolPanels.length === 0)
  }

  @Input()
  get showColumnsSidebar(): boolean {
    return this.sideBar.toolPanels.indexOf("columns") >= 0;
  }
  set showColumnsSidebar(value: boolean) {
    if (value) {
      this.sideBar.toolPanels.push("columns");
    } else {
      this.sideBar.toolPanels = this.sideBar.toolPanels.filter((item: string) => item != "columns")
    }

    this.sideBar.hiddenByDefault = (this.sideBar.toolPanels.length === 0)
  }

  a: RowGroupingDisplayType;

  @Input()
  get component() {
    return this.t2GridOptions ? this.t2GridOptions.context.component : null;
  }
  set component(value: any) {
    if (this.t2GridOptions) {
      this.t2GridOptions.context.component = value ?? {};
      this.t2GridOptions.context.component["t2Grid"] = this;
    }
  }
  @Input() gridStateName: string = undefined;
  @Input()
  get rowSelection(): 'single' | 'multiple' {
    return this._rowSelection;
  }
  set rowSelection(value: 'single' | 'multiple') {
    this._rowSelection = value;
    this.t2GridOptions.rowSelection = value;
  }
  @Input() tooltipMouseTrack = false;
  @Input() sortable = false;
  @Input() wrapText = false;
  @Input() tooltipComponentName: string = undefined;
  @Input() treeData = false;
  @Input() headerHeight = 30;
  @Input() rowHeight = 30;
  @Input() rowStyle: RowStyle;
  @Input() getRowStyle: ((params: RowClassParams<any>) => RowStyle | undefined) | undefined;
  @Input() autoGroupColumnDef: any = null;
  @Input() pagination = true;
  @Input() t2IdCmp: string;
  @Input()
  get suppressRowClickSelection() { return this.t2GridOptions.suppressRowClickSelection; }
  set suppressRowClickSelection(value: boolean) { this.t2GridOptions.suppressRowClickSelection = value; }

  @Input()
  get data(): Array<any> { return this.gridData; }
  set data(value: Array<any>) { this.setGridData(value, null); }

  @Input()
  get components() { return this._components; }

  @Input() gridTitle: string;
  set components(value: any) {
    this._components = value;
    if (this.t2GridOptions) {
      this.t2GridOptions.components = Object.assign(
        this.defaultComponents,
        this._components
      );
    }
  }
  @Input()
  get loading(): boolean { return this._loading; }
  set loading(value: boolean) { this.changeLoading(value); }

  @Input()
  get columnDef(): Array<any> { return this.getColumnDefs(); }
  set columnDef(value: Array<any>) { this.setGridColumnDefs(value); }
  @Input() rowDragManaged: boolean = false;
  @Input() animateRows: boolean = true;
  @Input() rowClassRules: RowClassRules;

  @Output() rowClick = new EventEmitter();
  @Output() rowDoubleClick = new EventEmitter();
  @Output() selectionChanged = new EventEmitter();
  @Output() cellKeyDown = new EventEmitter();
  @Output() cellKeyPress = new EventEmitter();
  @Output() cellMouseOver = new EventEmitter();
  @Output() cellClick = new EventEmitter<CellClickedEvent>();
  @Output() gridReady = new EventEmitter<any>();
  @Output() allFiltersCleared = new EventEmitter();
  @Output() gridStateChanged = new EventEmitter();
  @Output() filterChanged = new EventEmitter();

  public getDataPath: (data: any) => Array<string> | string;


  @ViewChild("t2grid", { static: true }) t2Grid: AgGridAngular;
  private t2GridApi: GridApi;
  private t2GridColumnApi: ColumnApi;
  private isGridReady = false;
  private rowsSelected: Array<any> = [];
  private lastSelectedNodes: Array<IRowNode> = [];
  private focusedRow: any;
  private isDestroyed: boolean = false;
  private defaultComponents = {
    numericEditor: NumericEditorComponent,
    selectEditor: SelectEditorComponent,
    aggregationEditor: AggregationEditorComponent,
    statusEditor: StatusEditorComponent,
    dateTimeEditor: DateTimeEditorComponent,

    checkBoxRenderer: CheckBoxRendererComponent,
    aggregationRenderer: AggregationRendererComponent,
    statusRenderer: StatusRendererComponent,
    iconRenderer: IconRendererComponent
  };
  private dataLoaded = false;
  columnState: any = undefined;
  private loadingColumnState = false;
  private quickFilter: string;
  private _rowSelection: 'single' | 'multiple' = "single";
  excelStyles = [
    {
      id: 'stringType',
      dataType: 'String',
    },
    {
      id: 'dateISOStyle',
      dataType: 'DateTime',
      numberFormat: {
        format: 'yyyy-mm-ddThh:mm:ss',
      },
    },
  ];

  sessionTheme = "default";

  gridData = [];

  t2GridOptions: GridOptions = {
    defaultColDef: {
      resizable: true,
      filter: true,
      sortable: this.sortable,
      wrapText: this.wrapText,
      tooltipComponent: this.tooltipComponentName,
      // autoHeight: true,
      headerComponentParams: {
        template: `<div class="ag-cell-label-container" role="presentation">
             <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>
             <div ref="eLabel" class="ag-header-cell-label" role="presentation">
               <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>
               <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>
               <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>
               <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>
               <span ref="eText" class="ag-header-cell-text" role="columnheader"></span>
               <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>
             </div>
           </div>
          `,
      },
      cellStyle: (params) => {
        const { level } = params.node;
        const groupCell = params.value === params.node.key;
        const indent = 10;

        if (groupCell) {
          return {
            paddingLeft: (level + 1) * indent + "px",
          };
        }
      },
    },
    columnDefs: [],
    defaultExcelExportParams: {
      processCellCallback: (params) => {

        if (params.value == undefined) return undefined;

        let isDate = false;

        if (params.column.getColDef().type) {
          if (Array.isArray(params.column.getColDef().type)) {
            isDate = params.column.getColDef().type.includes("dateColumn") || params.column.getColDef().type.includes("dateTimeColumn");
          } else {
            isDate = params.column.getColDef().type === "dateColumn" || params.column.getColDef().type === "dateTimeColumn";
          }
        }

        if (isDate || Object.prototype.toString.call(params.value) === "[object Date]") {
          try {
            const dia = ("0" + (params.value.getDate())).slice(-2) + "/" +
              ("0" + (params.value.getMonth() + 1)).slice(-2) + "/" +
              params.value.getFullYear();
            const hora = ("0" + params.value.getHours()).slice(-2) + ":" + ("0" + params.value.getMinutes()).slice(-2) + ":" + ("0" + params.value.getSeconds()).slice(-2);
            return `${dia} ${hora}`;
            //return new Date(params.value).toISOString();
          } catch (error) {
            //se não conseguiu converter retorna o próprio valor
          }
        }

        return params.value;
      }

    },
    // treeData: this.treeData,
    autoGroupColumnDef: this.autoGroupColumnDef,
    rowGroupPanelShow: "never",
    columnTypes: {
      booleanColumn: {
        valueGetter: (params: any) => {
          const value = params.data[params.column.colId];

          if (value === null || value === undefined) {
            return null;
          }

          if (value) {
            return "Sim";
          } else {
            return "Não";
          }
        },
        width: 115,
      },

      dateColumn: {
        valueFormatter: (params) => {
          if (!params.value) return "";

          return new DatePipe("en-GB").transform(params.value || undefined, "dd/MM/yyyy");
        },
        cellRenderer: (params) => {
          return `
          <div style="display: flex;">
            <div class="flexLength1 flexAlignColumnCenter">

              ${(params.value ? new DatePipe("en-GB").transform(params.value || undefined, "dd/MM/yyyy") : "")}
            </div>
          </div>
          `;
        },
        // cellClass: "dateISOStyle",
        cellEditor: "dateTimeEditor",
        width: 115,
        filter: 'agSetColumnFilter',
        filterParams: {
          treeList: true,
          keyCreator: params => params.value == null ? null : `${params.value.getFullYear()}-${params.value.getMonth()}-${params.value.getDate()}`
        } as ISetFilterParams<any, Date>,
      },
      dateTimeColumn: {
        valueFormatter: (params) => {
          if (!params.value) return "";

          return new DatePipe("en-GB").transform(params.value || undefined, "dd/MM/yyyy HH:mm");
        },
        cellRenderer: (params) => {
          return `
          <div style="display: flex;">
            <div class="flexLength1 flexAlignColumnCenter">

              ${(params.value ? new DatePipe("en-GB").transform(params.value || undefined, "dd/MM/yyyy HH:mm") : "")}
            </div>
          </div>
          `;
        },
        // cellClass: "dateISOStyle",
        cellEditor: "dateTimeEditor",
        width: 120,
        filter: 'agSetColumnFilter',
        filterParams: {
          treeList: true,
          keyCreator: params => params.value == null ? null : `${params.value.getFullYear()}-${params.value.getMonth()}-${params.value.getDate()}`
        } as ISetFilterParams<any, Date>
      },

      checkColumn: {
        cellRenderer: "checkBoxRenderer",
        cellStyle: { display: "flex", "justify-content": "center" },
      },

      statusColumn: {
        cellRenderer: "statusRenderer",
        cellEditor: "statusEditor"
      },

      aggregationColumn: {
        cellRenderer: "aggregationRenderer",
        cellEditor: "aggregationEditor"
      },
    },
    maintainColumnOrder: false,
    components: this.defaultComponents,
    detailCellRendererParams: {},
    rowSelection: this.rowSelection,
    pagination: this.pagination,
    groupDefaultExpanded: -1,
    suppressRowClickSelection: true,
    rowData: [],
    context: { component: this.component, aggregations: {} },
    masterDetail: false,
    suppressAggFuncInHeader: true,
    getRowId: (item: any) => { return item?.data['ID']; },
    overlayLoadingTemplate: '<span class="ag-overlay-loading-center">Carregando informações...</span>',
    getLocaleText: (params: GetLocaleTextParams) => {
      return agGridLocale[params.key] ? agGridLocale[params.key] : params.defaultValue;
    }
  };

  public getIntDataPath = (data: any) => {

    if (this.getDataPath) {
      return this.getDataPath(data);
    }

    return data.hier;
  };

  constructor(
    public httpClient: T2HttpClientService,
    private storage: T2LocalStorageService,
    private themeService: T2ThemingService,
    @Inject(LOCALE_ID) private locale: string
  ) {
    themeService.getThemeNameObservable().subscribe((themeName: string) => {
      this.sessionTheme = themeName;
      // this.t2Grid?.api.redrawRows();
    });

    // const decimalSep: string = getLocaleNumberSymbol(locale, NumberSymbol.Decimal);
    // this.decimalSeparator = decimalSep;
  }

  ngOnDestroy(): void {
    this.isDestroyed = true;
  }

  private changeLoading(value: boolean) {
    this._loading = value;

    if (!this.t2GridApi) {
      return;
    } else if (this._loading) {
      this.t2GridApi.showLoadingOverlay();
    } else {
      this.t2GridApi.hideOverlay();
    }
  }

  onGDAFXReady(params: any) {
    this.t2GridApi = params.api;
    this.t2GridColumnApi = params.columnApi;

    this.changeLoading(this._loading);

    // if (!this.t2Grid.defaultExcelExportParams) {
    //   this.t2Grid.defaultExcelExportParams = {};
    // }
    // this.t2Grid.defaultExcelExportParams.fileName = "techprod.xlsx";
    // this.t2Grid.defaultExcelExportParams.sheetName = "Techprod";

    this.t2GridOptions.rowSelection = this.rowSelection;
    this.t2GridOptions.pagination = this.pagination;
    this.t2GridOptions.defaultColDef.sortable = this.sortable;
    this.t2GridOptions.defaultColDef.wrapText = this.wrapText;
    this.t2GridOptions.defaultColDef.tooltipComponent = this.tooltipComponentName;

    if (!this.t2GridOptions.context) {
      this.t2GridOptions.context = { component: { t2Grid: this } };
    } else {
      if (this.t2GridOptions.context.component) {
        this.t2GridOptions.context.component["t2Grid"] = this;
      } else {
        this.t2GridOptions.context["component"] = { t2Grid: this }
      }
    }

    this.t2GridApi.setColumnDefs(this.t2GridOptions.columnDefs);
    this.t2GridApi.setRowData(this.gridData);

    this.isGridReady = true;
    this.gridReady.emit();
  }

  onFirstDataRendered(_event: any): void {
    if (!this.loadColumnGridState()) {

      const checkWidth = setInterval(() => {
        if (this.isDestroyed) {
          clearInterval(checkWidth);
        } else {
          let tamanhos = [...new Set(this.t2GridApi.getColumnDefs().map((def: any) => def.width))];
          if (tamanhos && tamanhos.length == 1 && tamanhos[0] <= 24) {
            this.autoSizeAllColumns(false);
            tamanhos = [...new Set(this.t2GridApi.getColumnDefs().map((def: any) => def.width))];
          } else {
            clearInterval(checkWidth);
          }
        }
      }, 100)
    }
  }

  onGDRowClicked(params: any) {
    const obj = { event: "clicked", row: params.rowIndex, data: params.data };
    this.clickRow(obj);
  }

  private clickRow(obj: any) {
    this.focusedRow = obj.data;
    this.rowClick.emit(obj);
  }

  onGDRowDoubleClicked(params) {
    const obj = { row: params.rowIndex, data: params.data };
    this.rowDoubleClick.emit(obj);
  }

  onGDCellKeyPress(params: any) {
    const obj = {
      keyName: params.event.code,
      key: params.event.key,
      keyCode: params.event.keyCode,
      altKey: params.event.altKey,
      ctrlKey: params.event.ctrlKey,
      field: params.colDef.field,
      data: params.data,
      rowIndex: params.rowIndex,
      node: params.node,
    };

    this.cellKeyPress.emit(obj);
  }

  onGDCellKeyDown(params: any) {
    const obj = {
      keyName: params.event.code,
      key: params.event.key,
      keyCode: params.event.keyCode,
      altKey: params.event.altKey,
      ctrlKey: params.event.ctrlKey,
      field: params.colDef.field,
      data: params.data,
      rowIndex: params.rowIndex,
      node: params.node,
    };

    const event = params.event;
    if (!event.ctrlKey) {
      let row = -1;
      if (event.key === "ArrowUp") {
        row = params.rowIndex - 1;
      } else if (event.key === "ArrowDown") {
        row = params.rowIndex + 1;
      }

      if (row >= 0 && row < this.gridData.length) {
        const dispRow = this.t2GridApi.getDisplayedRowAtIndex(row);
        this.clickRow({ event: "keyDown", row, data: dispRow?.data });
      }
    }

    this.cellKeyDown.emit(obj);
  }

  onGDSelectionChanged(params: any) {
    this.lastSelectedNodes = [];
    let currentSelectedRows = this.t2GridApi.getSelectedRows();

    this.rowsSelected.filter(row => currentSelectedRows.find(r => r["ID"] == row["ID"]) == undefined).forEach(row => {
      this.lastSelectedNodes.push(this.t2GridApi.getRowNode(row["ID"]));
    })

    const obj = {
      rowsSelected: this.t2GridApi.getSelectedRows(),
    };

    this.rowsSelected = obj.rowsSelected;

    this.selectionChanged.emit(params);
  }

  onGDCellMouseOver(event: any) {
    this.cellMouseOver.emit({
      data: event.data,
      rowIndex: event.rowIndex,
      node: event.node,
      context: event.context,
      clientX: event.event.clientX,
      clientY: event.event.clientY,
      pageX: event.event.pageX,
      pageY: event.event.pageY
    });
  }

  private editableCellStyle(params) {
    return {
      "background-color": "rgba(255, 255, 0, 0.40)",
      "height": "100%",
      "max-height": "calc(100% - 4px)",
      "max-width": params.column.actualWidth - 4 + "px",
      "margin": "2px",
      "border-radius": "4px"
    };
  }

  public setGridColumnDefs(columnDefs: Array<any>, maintainColumnOrder?: boolean) {
    let grouplableColumn = false;
    this.t2GridOptions.maintainColumnOrder = maintainColumnOrder;
    columnDefs?.forEach((column) => {
      if (column.enableRowGroup) {
        // If any column is set o be grouped, enabled grid-grouping
        grouplableColumn = true;
      }

      if (column?.type === "numericColumn") {
        // Value must be converted to number
        if (!column.valueGetter) {
          column.valueGetter = (params: any) =>
            !params || !params.data ? null : Number(params.data[column.field]);
        }

        // Value must be displayed with current localization
        column.valueFormatter = this.numberFormatter.bind(this);

        // Filtro personalizado pois o filtro padrão não ordena numericamente

        column.filterParams = {
          comparator: (a: any, b: any) => {
            try {
              const valA = parseFloat(a || 0);
              const valB = parseFloat(b || 0);
              if (valA === valB) return 0;
              return valA > valB ? 1 : -1;
            } catch (error) {
              return 0;
            }
          },
        };

        // If is an editabled field, must use our default editor (if no other was defined)
        if (this.columnEditable(column)) {
          column.cellEditor = column.cellEditor || "numericEditor";
          column.singleClickEdit = true;
        }
      } else if (column?.type === "dateColumn" || column?.type === "dateTimeColumn") {
        if (!column.valueGetter) {
          column.valueGetter = (params: any) => {
            if (!params?.data) return null;

            const value = params.data[column.field];
            if (value && (typeof value) == 'string') return new Date(value);

            return value;
          }
        }

      } else if (!column?.type) {
        column.cellClass = column.cellClass || 'stringType';
      }

      if (this.columnEditable(column)) {
        column.singleClickEdit = true;


        if (!column.cellStyle) {
          column.cellStyle = this.editableCellStyle;
        }
      }
    });

    this.t2GridOptions.rowGroupPanelShow = grouplableColumn ? "always" : "never";
    this.t2GridOptions.columnDefs = columnDefs;
    if (this.t2GridApi) {
      this.t2GridApi.setColumnDefs(columnDefs);

      this.t2GridApi.getColumnDefs
    }
  }

  getColumnDefs(): (ColDef | ColGroupDef)[] {
    if (this.t2GridApi) {
      return this.t2GridApi.getColumnDefs();
    }

    return [];
  }


  private columnEditable(column: any): boolean {
    if (typeof column.editable === "function") {
      // Retorna TRUE pois a validação específica será feita linha a linha
      return true;
    }

    return column.editable;
  }

  private numberFormatter(params): string {

    var value = params.value;

    if (typeof value == 'string') {
      if (!value) return "";

      value = Number(value);
    }

    if (isNaN(value)) return "";

    const digitsInfo = `1.${params.colDef['precision'] || 0}-${params.colDef['precision'] || 4}`;
    return formatNumber(Number(value), this.locale, digitsInfo);
  }

  public setGridData(data: Array<any>, selectId?: string) {
    try {
      this._settingGridData++;
      this.gridData = data;
      if (this.gridData && this.gridData.length > 0) {
        this.gridData.filter(item => item["ID"] == undefined || item["ID"] == null)?.forEach((item, index, arr) => {
          item["ID"] = "index" + index;
        });
      }

      if (this.isGridReady) {
        this.t2GridApi.setRowData(this.gridData);
        if (selectId) {
          const rowNode = this.t2GridApi.getRowNode(selectId);
          if (rowNode) {
            rowNode.setSelected(true);
            this.t2GridApi.ensureNodeVisible(rowNode, "middle");
          }
        }

        this.dataLoaded = true;
        this.loadColumnGridState();
      }
    } finally {
      setTimeout(() => { this._settingGridData--; }, 50);
    }
  }

  public sizeToFit() {
    try {
      this._settingGridData++;
      this.t2GridApi?.sizeColumnsToFit();
    } finally {
      setTimeout(() => { this._settingGridData--; }, 50);
    }

  }

  public autoSizeAllColumns(skipHeader: boolean) {

    if (!this.t2GridColumnApi) return;

    try {
      this._settingGridData++;

      const allColumnIds = [];
      this.t2GridColumnApi.getColumns()?.forEach((column: any) => {
        allColumnIds.push(column.colId);
      });

      if (allColumnIds.length) {
        this.t2GridColumnApi.autoSizeColumns(allColumnIds, skipHeader);
      }

    } finally {
      setTimeout(() => { this._settingGridData--; }, 50);
    }

  }

  public resetColumnsWidth() {
    const colDefs = this.t2GridApi?.getColumnDefs() || [];
    colDefs.forEach((col: any) => {
      if (col.width) {
        this.t2GridColumnApi.setColumnWidth(col.headerName, col.width);
      }
    });
  }

  public unselectAll() {
    this.t2GridApi.deselectAll();
  }

  public getRowsSelected() {
    return this.rowsSelected;
  }

  public getFocusedRow() {
    return this.focusedRow;
  }

  public getSelectedNodes() {
    return this.t2GridApi.getSelectedNodes();
  }

  public getFocusedNode() {
    return this.t2GridApi.getRowNode(this.focusedRow?.ID);
  }

  public getFilterInstance(
    key: string | Column,
    callback?: (filter: IFilterComp) => void
  ) {
    return this.t2GridApi.getFilterInstance(key, callback);
  }

  public callOnFilterChanged() {
    this.t2GridApi.onFilterChanged();
  }

  onFilterChanged(event) {
    this.filterChanged.emit(event);
  }

  public redrawRows(params?: RedrawRowsParams) {
    setTimeout(() => {
      if (this.t2GridApi)
        this.t2GridApi.redrawRows(params);
    }, 50);
  }

  public setSelected(rowID, newValue: boolean = true) {
    this.t2GridApi.getRowNode(rowID)?.setSelected(newValue);

    this.rowsSelected = this.t2GridApi.getSelectedRows();
  }

  public deselectAll() {
    this.t2GridApi.deselectAll();
  }

  public setFocusedColumn(columName: string): void {
    const node = this.getFocusedNode();
    if (node) {
      this.setRowFocus(node);
      this.t2GridApi.setFocusedCell(node.rowIndex, columName);
    }
  }

  public setFocusedColumnByID(id: string, columName: string): void {
    const node = this.getRowNode(id);
    if (node) {
      this.setRowFocus(node);
      this.t2GridApi.setFocusedCell(node.rowIndex, columName);
    }
  }

  public editColumnByID(id: string, columName: string): void {
    const node = this.getRowNode(id);
    if (node) {
      this.setRowFocus(node);
      this.t2GridApi.startEditingCell({ rowIndex: node.rowIndex, colKey: columName });
    }
  }

  public getRowNode(rowID) {
    return this.t2GridApi.getRowNode(rowID);
  }

  public removeFocusedRow() {
    if (this.getFocusedRow()) {

      const nodes = this.getAllRowNodes();
      var prev: IRowNode, current: IRowNode, next: IRowNode;
      for (var i = 0; i < nodes.length; i++) {

        if (nodes[i].data.ID === this.getFocusedRow().ID) {
          current = nodes[i];
        }

        if (!current) {
          prev = nodes[i];
        }

        if (current && nodes[i].data.ID != current.data.ID) {
          next = nodes[i];
        }

        if (current && next) {
          break;
        }
      }

      current.setSelected(true);
      this.t2GridApi.applyTransaction({ remove: [current.data] });

      this.setRowFocus(next || prev);
    }
  }

  public getFirstRowNode(): IRowNode {
    const rows = this.getAllFilterRowNodes();
    if (rows.length) return rows[0];

    return null;
  }

  public getAllRowNodes(loadGroups: boolean = false): IRowNode[] {
    const rows: IRowNode[] = [];
    this.t2GridApi.forEachNode((node) => {
      if (!node.group || loadGroups) {
        rows.push(node);
      }
    });

    return rows;
  }

  public getAllFilterRowNodes(loadGroups: boolean = false): IRowNode[] {
    const rows: IRowNode[] = [];
    this.t2GridApi.forEachNodeAfterFilter((node) => {
      if (!node.group || loadGroups) {
        rows.push(node);
      }
    });

    return rows;
  }

  public clearRowFocus() {
    this.focusedRow = null;
  }

  public setRowFocus(rowNode: IRowNode) {

    const row = rowNode?.rowIndex || -1;
    const data = rowNode?.data || null;

    this.focusedRow = data;

    const obj = { row: row, data: data };
    this.rowClick.emit(obj);
  }

  public refreshCells(params?: RefreshCellsParams) {
    this.t2GridApi.refreshCells();
  }

  public setDetailCellRendererParams(detailCellRendererParams) {
    this.t2GridOptions.detailCellRendererParams = detailCellRendererParams;
  }

  public ensureNodeVisible(comparator: any, position?: 'top' | 'bottom' | 'middle' | null) {
    this.t2GridApi.ensureNodeVisible(comparator, position);
  }

  public ensureIndexVisible(index: any, position?: 'top' | 'bottom' | 'middle' | null) {
    this.t2GridApi.ensureIndexVisible(index, position);
  }

  getContextMenuItems(params: GetContextMenuItemsParams) {
    // EVENTO DE CLICK COM O BOTAO DIREITO

    if (!params.context.component?.t2Grid) {
      throw new Error("Não foi passada a referência do T2GridComponent no componente de contexto");
    }

    let t2Grid = params.context.component.t2Grid;

    if (params.node) {
      // clicar com o botao direito nao foca na linha, entao força o foco na linha
      const obj = {
        row: params.node.rowIndex,
        data: params.node.data,
        // eventParams: params
      };
      t2Grid.clickRow(obj);
    }

    const menuItems = [];

    if (
      params.context.component?.contextMenu &&
      Array.isArray(params.context.component.contextMenu) &&
      params.context.component.contextMenu.length
    ) {
      let mainMenu = [];
      let subMenu = [];

      params.context.component.contextMenu.sort((a, b) => {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
      });

      params.context.component.contextMenu.forEach((item) => {
        if (item.requiresId) {
          const rows = new Array(...t2Grid.getRowsSelected());
          if (!rows.length && t2Grid.getFocusedRow()) {
            rows.push(t2Grid.getFocusedRow());
          }
          const row = rows.find((row) => {
            return row.ID;
          });
          item.disabled = !row;
        }
        if (item.outsideSubMenu) {
          if (item.name == "separator") {
            mainMenu.push("separator");
          } else {
            mainMenu.push(item);
          }
        } else {
          subMenu.push(item);
        }

      });

      if (subMenu.length) menuItems.push({ name: "Ações", subMenu: subMenu });

      menuItems.sort((a, b) => {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
      });
      menuItems.push("separator");
      menuItems.push(...mainMenu);
      menuItems.push("separator");

    }

    if (t2Grid.hasFilters(t2Grid.t2GridApi) || t2Grid.quickFilter) {
      menuItems.push({
        name: "Limpar todos os filtros",
        requiresId: false,
        action: () => {
          t2Grid.t2GridApi.setFilterModel(null);
          t2Grid.setQuickFilter(null);
          t2Grid.t2GridApi.onFilterChanged();

          setTimeout(() => {
            if (t2Grid.rowsSelected.length > 0) {
              t2Grid.ensureIndexVisible(Math.min.apply(Math, t2Grid.getSelectedNodes().map(n => n.rowIndex)), "middle");
              t2Grid.allFiltersCleared.emit();
            }
          }, 100);
        },
      });
      menuItems.push("separator");
    }

    if (t2Grid.columnState) {
      menuItems.push({
        name: "Restaurar configuração das colunas",
        requiresId: false,
        action: () => {
          t2Grid.removeColumnGridState();
        },
      });
      menuItems.push("separator");
    }

    menuItems.push("copy");
    menuItems.push("copyWithHeaders");
    menuItems.push("separator");
    menuItems.push("excelExport");
    menuItems.push("csvExport");
    menuItems.push({
      name: "PDF Export",
      requiresId: true,
      action: () => { t2Grid.exportToPDF(t2Grid); },
      //icon: '<span unselectable="on" role="presentation"><nb-icon icon="star"></nb-icon></span>'
    });

    return menuItems;
  }

  public hasFilters(gridApi: GridApi): boolean {
    const filters = gridApi.getFilterModel();
    return Object.keys(filters).length > 0;
  }

  public exportToPDF(t2grid: T2gridComponent) {
    new T2gridExportToPDF().generatePDF(t2grid.t2GridApi, t2grid.t2GridColumnApi, this.gridTitle, this.httpClient.clientUrl);
  }

  public getLastSelectedNodes() {
    return this.lastSelectedNodes;
  }

  public onSortChanged(event: any) {
    this.saveColumnGridState();
  }
  public onColumnResized(event: any) {
    if (!this._settingGridData) {
      this.saveColumnGridState();
    }

  }

  public onColumnVisible(event: any) {
    this.saveColumnGridState();
  }

  public onColumnMoved(event: any) {
    this.saveColumnGridState();
  }

  public onColumnPinned(event: any) {
    this.saveColumnGridState();
  }

  public onColumnRowGroupChanged(event: any) {
    this.saveColumnGridState();
  }

  onPivotChanged(event: any) {
    this.saveColumnGridState();
  }

  public getGridState() {

    if (!this.isGridReady) return null;

    const colState = this.t2GridColumnApi.getColumnState().map((item: ColumnState) => {

      return {
        ...item,
        field: this.t2GridColumnApi.getColumn(item.colId)?.getColDef().field
      };
    });

    return {
      pivotMode: this.t2GridColumnApi.isPivotMode(),
      colState
    };
  }

  public resetColumnState() {
    this.t2GridColumnApi.resetColumnState();
  }

  public setGridState(gridState: { pivotMode: true | false, colState: ColumnState[] }) {

    try {
      this.loadingColumnState = true;

      this.t2GridColumnApi.setPivotMode(gridState.pivotMode);

      const currentColState = this.t2GridColumnApi.getColumnState();
      const newColState = [];

      var changed = false;

      //First, validate col in saved state
      gridState.colState.forEach(colState => {
        const colIndex = currentColState.findIndex(item => item.colId == colState.colId);
        if (colIndex >= 0) {
          newColState.push({ ...currentColState[colIndex], ...colState });
          currentColState.splice(colIndex, 1);
          changed = true;
        } else if (colState.colId == "ag-Grid-AutoColumn") {
          // If it this de auto-group column, should add it
          newColState.push(colState);
        }
      });

      // If any column remains from de current state (does not exist in saved state), include them in the newColState
      if (currentColState.length) {
        newColState.push(...currentColState);
      }

      if (changed) {
        this.t2GridColumnApi.applyColumnState({ state: newColState });
        for (var i = 0; i < newColState.length; i++) {
          this.t2GridColumnApi.moveColumn(newColState[i].colId, i);
        }
      }

      return true;

    } finally {
      this.loadingColumnState = false;
    }
  }

  private saveColumnGridState(): void {

    if (this._settingGridData) return;

    const gridState = this.getGridState();
    const colState = gridState.colState;

    if (this.dataLoaded && this.gridStateName) {

      /*if (colState.find(item => !item.field)) {
        console.error(`TECHPROD, Cannot save gridstate when column.field is not defined [${this.gridStateName}]`);
        return;
      }*/

      this.storage.setData("t2g_" + this.gridStateName, JSON.stringify(colState));
      this.columnState = colState;
    }

    this.gridStateChanged.emit(gridState);
  }

  private removeColumnGridState() {
    this.t2GridColumnApi.resetColumnState();
    this.autoSizeAllColumns(false);

    setTimeout(() => {
      this.storage.removeData("t2g_" + this.gridStateName);
      this.columnState = undefined;
    }, 200);

    this.gridStateChanged.emit(this.getGridState());
  }

  public loadColumnGridState(): boolean {

    if (this.loadingColumnState) return true;

    if (!this.dataLoaded || !this.gridStateName) return false;

    this.columnState = JSON.parse(this.storage.getData("t2g_" + this.gridStateName));
    if (!this.columnState) return false;

    this.setGridState({ pivotMode: false, colState: this.columnState });
    return true;
  }

  public setColumnVisible(columnKey: string, visible: boolean) {
    this.t2GridColumnApi.setColumnVisible(columnKey, visible);
  }

  public setQuickFilter(searchValue: string) {
    this.quickFilter = searchValue;
    this.t2GridApi.setQuickFilter(searchValue);

    if (!searchValue && this.rowsSelected.length > 0) {
      this.ensureIndexVisible(Math.min.apply(Math, this.getSelectedNodes().map(n => n.rowIndex)), "middle");
    }
  }

  public updateData(data: any[]) {
    let resp = this.t2GridApi.applyTransaction({ update: data });
    this.gridData = this.getAllRowNodes().map(rn => rn.data);

    this.redrawRows({ rowNodes: resp.update });

    return resp;
  }

  public removeData(data: Array<{ ID: any }>) {
    let resp = this.t2GridApi.applyTransaction({ remove: data });
    this.gridData = this.getAllRowNodes().map(rn => rn.data);

    return resp;
  }

  public addData(data: any[]) {
    let resp = this.t2GridApi.applyTransaction({ add: data });
    this.gridData = this.getAllRowNodes().map(rn => rn.data);

    return resp;
  }

  public sortBy(colId: string, sortOrder: "asc" | "desc") {
    this.t2GridColumnApi.applyColumnState({
      state: [{ colId: colId, sort: sortOrder }],
      defaultState: { sort: null }
    });
  }

  public isDataLoaded() {
    return this.dataLoaded;
  }

  public onGDCellClicked(event: CellClickedEvent) {
    this.cellClick.emit(event);
  }
}
