<div class="flexColumn flexGap10" style="height: calc(100% - 10px);">

  <div style="display: flex; align-items: flex-start;">
    <div style="display: flex; gap: 5px" [nbSpinner]="saving">
      <button nbButton status="basic" nbTooltip="Abrir lista de relatórios" size="small" [disabled]="!analysisConfig || loadingData"
        (click)="analysisConfigShow = !analysisConfigShow">
        <nb-icon icon="folder-outline"></nb-icon>
      </button>
      <button nbButton status="basic" nbTooltip="Salvar modelo de relatório" size="small" [disabled]="!analysisVisualization.paramsCount || loadingData"
        (click)="editConfig()">
        <nb-icon icon="save-outline"></nb-icon>
      </button>
      <button nbButton status="danger" nbTooltip="Apagar modelo de relatório" size="small"
        [disabled]="!analysisVisualization.config?.id_analysis_config || analysisVisualization.config?.diffUser || loadingData"
        (click)="deleteConfig(analysisVisualization.config.id_analysis_config)">
        <nb-icon icon="trash-2-outline"></nb-icon>
      </button>
      <button nbButton status="success" nbTooltip="Gerar relatório" size="small" (click)="processParameters()" [disabled]="!loaded || loadingData">
        <nb-icon icon="arrow-right-outline"></nb-icon>
      </button>

    </div>

    <div *ngIf="analysisVisualization.config?.description">
      <div style="font-size: 125%;  font-weight: bold;">{{ analysisVisualization.config?.description }}</div>
    </div>
  </div>

  <div  class="flexRow flexGap10" style="flex: calc(100% - 34px);">

    <div *ngIf="analysisConfigShow && analysisConfig && analysisConfig?.length"
      class="t2GlassBG flexColumn" [nbSpinner]="saving" style="gap: 3px; overflow: auto;">
      <div class="analysisConfig" style="text-align: center; font-weight: bold;" (click)="selectionConfig(null)"
        *ngIf="analysisVisualization?.config?.id_analysis_config">
        Limpar seleção
      </div>
      <div class="analysisConfig" *ngFor="let cfg of analysisConfig"
        [ngClass]="{'analysisConfig_active': cfg.id_analysis_config == analysisVisualization.config?.id_analysis_config}"
        (click)="selectionConfig(cfg)">
        <div style="font-size: 100%;  font-weight: bold;">{{ cfg.description }}</div>
        <div style="font-size: 65%; margin-top: -3px;">{{ cfg.userName }} - {{ cfg.creationDate | date : 'short' }}
        </div>
      </div>
    </div>

    <app-t2grid style="width: 100%;" #gridAnalysis id="gridAnalysis" sortable="true" [headerHeight]="25"
      [rowHeight]="25" [columnDef]="colDefs" [showColumnsSidebar]="true" [loading]="loadingData"
      (gridStateChanged)="onGridStateChanged($event)">
    </app-t2grid>
  </div>


</div>
