import { Component } from '@angular/core';
import { ICellRendererAngularComp } from "ag-grid-angular";
import { ICellRendererParams } from "ag-grid-community";

@Component({
  selector: 'app-task-flow-cell-renderer',
  templateUrl: './task-flow-cell-renderer.component.html',
  styleUrls: ['./task-flow-cell-renderer.component.scss']
})
export class TaskFlowCellRendererComponent implements ICellRendererAngularComp {
  taskList: Array<Task>;

  agInit(params: ICellRendererParams<any, any>): void {
    if (params?.value) {
      this.taskList = params?.value;
    }
  }
  refresh(params: ICellRendererParams): boolean {
    return true;
  }
}
