import { ExceptionDetail } from "./exception-detail";
import { TimeDetail } from "./TimeDetail";


export class WorkSchedule {
  id?: string;
  description?: string;
  timeDetailList: Array<TimeDetail> = [];
  exceptionList: Array<ExceptionDetail> = [];
}
