<app-form-template #formTemplate formTitle="Desbloqueio de Volumes" [loaded]="loaded">
  <content>

    <div class="flexColumn" style="height: 100%;">
      <div class="flexAlignRowEndCenter">
        <button nbButton size="tiny" status="primary" (click)="desbloquearClick()"
          [disabled]="!gridVolume.getRowsSelected().length">Desbloquear</button>
      </div>

      <div class="flexLength1">
        <app-t2grid #gridVolume id="gridVolume" rowSelection="multiple" [loading]="(loadingData != 0) || !loaded">
        </app-t2grid>
      </div>
    </div>
  </content>
  <footer>
  </footer>
</app-form-template>
