import { Component, OnInit, Input, ViewChild, EventEmitter, Output, OnDestroy, ElementRef } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { trigger, state, style, transition, animate } from "@angular/animations";
import { Observable, Subject } from "rxjs";
import { take, takeUntil, tap } from "rxjs/Operators";
import { T2Route } from "../http/t2route";
import { T2gridComponent } from "../cmp/t2grid/t2grid.component";
import { Dataset } from "./model/dataset";
import { T2HttpClientService } from "../http/t2httpClient.service";
import { T2DatasetService } from "./t2dataset.service";
import { T2SecurityService } from "../security/t2security.service";
import { T2AccessItem, T2AccessItemDatasetActionType } from "../security/model/t2accessItem";
import { ActionService } from "../action/action.service";
import { T2MessageService } from "../t2-message.service";
import { T2CoreException } from "../exception/exception";
import { T2LocalStorageService } from "../t2local-storage.service";
import { TechprodDevService } from "../dev/techprod-dev.service";
import { FormGroup } from "@angular/forms";
import { RowDragEndEvent, RowDragMoveEvent } from "ag-grid-community";
import { NbDialogService } from "@nebular/theme";
import { QueryComponent } from "./query/query.component";
import { ConditionField, ConditionGroup, DatasetVisualization, DatasetVisualizationInfo, T2VisualizationService, VisualizationField } from "./visualization/t2-visualization.service";
import { VisualizationDialogComponent } from "./visualization/visualization-dialog.component";

@Component({
  selector: "app-dataset-template",
  templateUrl: "./dataset-template.component.html",
  styleUrls: ["./dataset-template.component.scss"],
  host: {
    '(document:click)': 'onClick($event)',
  },
  animations: [
    trigger("filterState", [
      state("closed", style({ width: "0px", height: "100%", visibility: "hidden", padding: "0px" })),
      state("opened", style({ width: "300px", height: "100%", visibility: "visible" })),
      transition("closed <=> opened", animate("200ms")),
    ])
  ]
})
export class DatasetTemplateComponent implements OnInit, OnDestroy {
  private t2Route: T2Route;
  private lastLoadingData = { datasetName: null, searchText: null, selectedId: null };
  private gridData = [];
  private gridColumns = [];
  private extTypes: ExtType[] = [];
  private loadingComponente = true;
  private _parentId: string = null;
  private _selectedId: string = null;
  private _loadingData = false;
  private actionList: Array<T2AccessItem>;
  private sql: string;
  private unsubscribe$ = new Subject<void>();
  private sortKeyColumnAlias: string;
  public hasStatusCol = false;
  public hasStatusColTooltip = "";
  public showStatusEndsInfo = false;
  public hasInativeCol = false;
  public showInactive = false;
  public gridContextComponent = { contextMenu: [] };
  public gridStateName: string = "";
  public rowDatasetName: string = null;
  private movingRow;
  private _fixedFilter: ConditionField[] = [];
  private tabLoaded: boolean = false;
  public visualizationInfoList: Array<DatasetVisualizationInfo> = [];
  public showingInfoList = false;
  visualization: DatasetVisualization = { fieldList: [], queryCondition: { condition: "AND" } };
  visualizationSelected: boolean;
  showQuickSearch: boolean = false;

  @Input('loadingData')
  get loadingData() { return this._loadingData; }
  set loadingData(value: boolean) { this._loadingData = value; this.loadingDataChange.emit(value); }
  @Input() datasetName: string = null;
  @Input() selectionType: string = null;
  @Input() searchText: string = "";
  @Input('fixedFilter')
  get fixedFilter(): ConditionField[] {
    return this._fixedFilter
  }
  set fixedFilter(value: ConditionField[]) {
    this._fixedFilter = value;

    if (this.dataset?.id_dataset) {
      this.loadDatasetInfo(true);
    }
  }
  @Input('selectedId')
  get selectedId() { return this._selectedId; }
  set selectedId(selectedId: string) {
    if (this._selectedId != selectedId) {
      this._selectedId = selectedId;
      this.selectedIdChange.emit(selectedId);
    }
  }

  private _sortable = true;

  @Input('parentId')
  get parentId(): string { return this._parentId; }
  set parentId(id: string) { this._parentId = id; };
  @Input() showHeader: boolean = true;
  @Input() plainContent: boolean = false;
  @Input() checkUrl: boolean = true;
  @Input() isAggregation: boolean = false;
  @Input() isChildrenTabsheet: boolean = false;
  // @Input() aggregationCondPropId: string;
  // @Input() aggregationCondPropValue: string;
  @Input() tabChange: Subject<any>;
  @Input() tabTitle: string;
  @Input() get sortable() { return this._sortable; } set sortable(value: boolean) { this._sortable = (value != false); }
  @Input() FormGroup: FormGroup;
  @Input() saveAutoformParent: () => Promise<string>;
  @Input() sortKeyFieldName: string;
  @Input() insertParams: Map<string, any>;
  @Output() loadingDataChange = new EventEmitter<boolean>();
  @Output() datasetChange = new EventEmitter<Dataset>();
  @Output() selectedIdChange = new EventEmitter<string>();
  @Output() extTypeListChange = new EventEmitter<{ values: Array<ExtType>, selected: Array<ExtType> }>();

  @ViewChild("gridDataset", { static: true }) gridDataset: T2gridComponent;
  @ViewChild("visualizationButton") visualizationButton: ElementRef;

  dataset = new Dataset();

  buttonInsert = false;
  buttonEdit = false;
  buttonDelete = false;
  otherActions = [];
  filterStatus = "closed";

  constructor(
    private _eref: ElementRef,
    public router: Router,
    private route: ActivatedRoute,
    private dialogService: NbDialogService,
    private storage: T2LocalStorageService,
    private httpClient: T2HttpClientService,
    private datasetService: T2DatasetService,
    private sec: T2SecurityService,
    private visService: T2VisualizationService,
    private actionService: ActionService,
    private messageService: T2MessageService,
    private devService: TechprodDevService) {
  }

  ngOnInit(): void {
    if (!this.isAggregation && !this.isChildrenTabsheet && this.checkUrl) {
      this.t2Route = this.httpClient.getT2RouteFromSnapshot(this.route.snapshot);
      this.datasetName = this.datasetName || this.t2Route.pathParams.get("datasetName");
      this.searchText = this.searchText || this.t2Route.queryParams.get("searchText") || "";
      this.selectedId = this.selectedId || this.t2Route.queryParams.get("selectedId") || "";

      this.route.params
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(params => {
          if (this.datasetName != params['datasetName']) {
            this.datasetName = params['datasetName'] || this.datasetName;
            this.loadingComponente = false;
            this.loadDatasetInfo();
          }
        });

      this.route.queryParams
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(params => {
          if ((this.searchText != (params['searchText'] || "")) || (this.selectedId != (params['selectedId'] || ""))) {
            this.searchText = params['searchText'] || this.searchText || "";
            this.selectedId = params['selectedId'] || this.selectedId || "";

            this.loadingComponente = false;
            this.loadDatasetInfo();
          }
        });
    }

    this.loadingComponente = false;
    this.loadDatasetInfo();

    this.tabChange?.subscribe(tabTitle => {
      if (tabTitle == this.tabTitle) {
        this.createGridStateName();
        this.gridDataset.gridStateName = this.gridStateName;

        if (!this.tabLoaded) {
          this.loadDatasetData();
          this.tabLoaded = true;
        }

        if (!this.gridDataset.loadColumnGridState()) {
          this.gridDataset.autoSizeAllColumns(false);
        }
      }
    });

    this.updateInactiveStatusEndsInfo();
  }

  ngOnDestroy(): void {
    this.tabChange?.unsubscribe();

    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  public getT2Grid(): T2gridComponent {
    return this.gridDataset;
  }

  private createGridStateName() {
    if (!this.datasetName) return;

    if (this.isAggregation) {
      this.gridStateName = this.datasetName + "-dsTemplate-agg";
      return;
    }

    if (this.isChildrenTabsheet) {
      this.gridStateName = this.datasetName + "-dsTemplate-tabSheet";
      return;
    }

    this.gridStateName = this.datasetName + "-dsTemplate";
    return;
  }

  public setDatasetInfo(datasetName: string, parentId: string, searchText: string, fixedFilter?: ConditionField[], forceLoad?: boolean) {
    this.datasetName = datasetName;
    this.parentId = parentId;
    this.searchText = searchText;
    if (fixedFilter) this.fixedFilter = fixedFilter;

    this.loadDatasetInfo(forceLoad);
  }

  private loadDatasetInfo(forceLoad?: boolean) {
    if (this.loadingComponente || this.loadingData || (this.datasetName === this.lastLoadingData.datasetName && !forceLoad)) {
      return;
    }

    if (!this.datasetName) {
      return;
    }

    this.datasetService.getDataset(this.datasetName)
      .subscribe((ds: Dataset) => {
        this.lastLoadingData.datasetName = this.datasetName;
        this.dataset = ds;
        this.setShowFilter().then(() => {
          this.datasetChange.emit(this.dataset);

          if (this.isAggregation || this.isChildrenTabsheet) {
            this.loadDatasetData();
          }

          if (!this.FormGroup && this.parentId && this.dataset.parentName) {
            this.FormGroup = new FormGroup({});
            this.datasetService.getBlockChanges(this.dataset.parentName, this.parentId)
              .pipe(take(1))
              .subscribe(resp => {
                this.FormGroup.readOnly = resp.blockChanges;
              })
          } else if (forceLoad) {
            this.datasetService.getBlockChanges(this.dataset.parentName, this.parentId)
              .pipe(take(1))
              .subscribe(resp => {
                this.FormGroup.readOnly = resp.blockChanges;
              })
          }

        });
      });
  }

  private async setShowFilter() {

    const statusProp = this.dataset.propertyList.find(prop => prop.statusCtrl);
    if (statusProp) {
      const statusEndsInformation = statusProp.statusList.filter(status => status.endsInformation).map(status => status.description);
      if (statusEndsInformation?.length) {
        this.hasStatusCol = true;
        this.hasStatusColTooltip = `Exibir também os registros com status encerrados (${statusEndsInformation.join(", ")})`;
      } else {
        this.hasStatusCol = false;
      }
    } else {
      this.hasStatusCol = false;
    }

    this.hasInativeCol = this.dataset.propertyList.find(prop => prop.inactivityCtrl) ? true : false;

    await this.getVisualizationList().toPromise()
      .then(async (resp: Array<DatasetVisualizationInfo>) => {
        this.visualizationInfoList = resp || [];
        await this.selectDefaultVisualization().then(() => { });
      })
      .catch(error => {
        this.visualizationInfoList = [];
      });
  }

  private getVisualizationList() {
    return this.visService.getVisualizationList(this.dataset.id_dataset)
      .pipe(tap(resp => {
        this.visualizationInfoList = resp || [];
        this.visualizationInfoList.sort((a, b) => {
          if (a.defaultVisualization) { return -1; }
          else if (b.defaultVisualization) { return 1; }
          else { return a.description.localeCompare(b.description) };
        });
        return resp;
      }));
  }

  async eraseLastSelectedVisualization() {
    this.storage.removeData('lastSelectedVisualization-' + this.datasetName);
    this.visualizationSelected = false;
    this.updateInactiveStatusEndsInfo();
    await this.selectDefaultVisualization().then(() => {
      this.executeSearch();
      this.gridDataset.autoSizeAllColumns(true);
    });
  }

  private updateInactiveStatusEndsInfo() {
    if (this.showInactive) this.showInactive = !this.showInactive;
    if (this.showStatusEndsInfo) this.showStatusEndsInfo = !this.showStatusEndsInfo;
  }

  private async selectDefaultVisualization() {
    const lastSelectedVisualization = this.storage?.getData('lastSelectedVisualization-' + this.datasetName);
    this.visualizationSelected = lastSelectedVisualization ? true : false;
    let visInfo = this.visualizationInfoList.find(vi => vi.id_dataset_visualization == lastSelectedVisualization);
    visInfo = visInfo ? visInfo : this.visualizationInfoList.find(vi => vi.defaultVisualization);

    if (visInfo) {
      await this.visService.getDatasetVisualization(visInfo.id_dataset_visualization)
        .toPromise()
        .then(v => {
          this.visualization = v;
          this.showQuickSearch = true;
        })
        .catch(error => {
          this.visualization = { fieldList: [], queryCondition: { condition: 'AND' } };
        });
    } else {
      this.visualization = { fieldList: [], queryCondition: { condition: 'AND' } };
    }

  }

  public loadDatasetData(loadOptions?: { force?: boolean, extTypeSelected?: Array<String>, quickSearch?: boolean }) {

    if (!this.dataset?.id_dataset) {
      return;
    }

    const force = loadOptions?.force || false;
    if (this.visualization.description && this.hasInativeCol) this.showInactive = true;
    if (this.visualization.description && this.hasStatusCol) this.showStatusEndsInfo = true;

    if (!force && this.searchText === this.lastLoadingData.searchText && this.selectedId === this.lastLoadingData.selectedId) {
      return;
    }

    if (!this.dataset?.id_dataset) {
      return;
    }

    this.buttonEdit = false;
    this.buttonInsert = false;
    this.buttonDelete = false;

    const aggregDatasetConfig: any = JSON.parse(this.storage.getData(`AGGC_${this.datasetName}`));
    if (!this.fixedFilter?.length) {
      //se é busca rápida, remove dados do cache e realiza nova busca senão não é feita nenhuma busca
      if (loadOptions?.quickSearch) this.storage.removeSessionData(`AGG_${this.datasetName}`);

      // Se é agregração e possui a consulta recente armazenada no cache/storage, utiliza os dados do cache
      const aggregDatasetData: any = JSON.parse(this.storage.getSessionData(`AGG_${this.datasetName}`));
      if (this.datasetName && this.isAggregation && !force) {
        if (aggregDatasetData?.data) {
          aggregDatasetData.updated = new Date(aggregDatasetData.updated);
          if (new Date(aggregDatasetData.updated.getTime() + (1000 * 60 * 30)) > new Date() &&
            this._parentId == aggregDatasetData.parentId) {
            this.showGridData(aggregDatasetData.metadata, aggregDatasetData.data);

            let extTypeField = aggregDatasetData.metadata.filter(field => field.extType?.length);
            if (extTypeField.length) {
              extTypeField = extTypeField[0];
            } else {
              extTypeField = undefined;
            }


            this.extTypeListChange.emit({ values: extTypeField?.extType || aggregDatasetConfig?.extType, selected: loadOptions?.extTypeSelected || aggregDatasetConfig?.extTypeSelected || [] });

            this.loadingData = false;

            this.devService.devModeLog(`LOADED DATASET DATA FROM CACHE ${((JSON.stringify(aggregDatasetData).length / 1024 / 1024).toFixed(2))}MB (${this.datasetName})`)

            return;
          }
        }
      }
    }

    this.loadDatasetActions();

    const queryParams = new Map<string, string>();
    if (this.parentId) {
      queryParams.set("id_parent", this.parentId);
    }

    if (this.searchText != undefined && this.searchText != null)
      queryParams.set("text", this.searchText);

    // if (this.aggregationCondPropId && this.aggregationCondPropValue) {
    //   queryParams.set("id_dataset_property", this.aggregationCondPropId);
    //   queryParams.set("text", this.aggregationCondPropValue);
    // }
    const sd: { fieldList: Array<any>, queryCondition: ConditionGroup } = {
      fieldList: [...(this.visualization?.fieldList || [])].map(f => { return { name: f.name, description: f.description, id_dataset_aggregation: f.id_dataset_aggregation, pinned: f.pinned }; }),
      queryCondition: this.fixedFilter?.length
        ? { condition: "AND", fieldList: [...this.fixedFilter], groupList: this.visualization?.queryCondition ? [this.visualization?.queryCondition] : [] }
        : this.visualization.queryCondition
    };

    const requiredFields = sd.queryCondition?.fieldList?.filter((i: ConditionField) => i.fieldFilter?.required);
    const quickSearchFields = sd.queryCondition?.fieldList?.filter((i: ConditionField) => i.fieldFilter?.quickSearch);
    let canSearch = true;
    let requiredF: ConditionField;
    if (quickSearchFields && quickSearchFields.length) canSearch = force || loadOptions?.quickSearch;
    if (requiredFields && requiredFields.length) {
      requiredF = requiredFields.find((i: ConditionField) => !i.value?.trim());
      canSearch = requiredF == undefined;
    }

    if (!canSearch) {
      if (loadOptions?.quickSearch) {
        this.messageService.showDialog(
          {
            context: {
              title: "Carregar informações",
              message: "Para realizar a pesquisa preencha o campo (" + requiredF.description + ") no filtro de busca rápida.",
              actions: [{ description: "OK" }]
            }
          }
        );
      }
      return;
    };

    this.showQuickSearch = true;

    queryParams.set("statusEndsInformation", String(this.showStatusEndsInfo));
    queryParams.set("inativeInformation", String(this.showInactive));
    this.extTypes = (!this.extTypes || !this.extTypes.length ? aggregDatasetConfig?.extType : this.extTypes);
    let etl: any[] = loadOptions?.extTypeSelected || aggregDatasetConfig?.extTypeSelected;
    if (!etl || !etl.length || etl.length >= this.extTypes.length) {
      // If none or all types are selected, it isn't necessary send any filter, cause all data will be delivered from server
      etl = null;
    } else {
      etl = this.extTypes.filter(type => {
        return etl.find(item => type.datasetName == item) ? true : false;
      });
    }
    queryParams.set("extensionType", JSON.stringify(etl || []));
    this.loadingData = true;
    this.httpClient.post(`core.presentation/conditionSearch/${this.dataset.id_dataset}`, queryParams, sd)
      .pipe(take(1), takeUntil(this.unsubscribe$))
      .subscribe(resp => {
        let metadata: Array<{ extensionType: boolean, extType: Array<ExtType> }> = resp.metadata;
        if (metadata && !Array.isArray(metadata)) {
          metadata = [metadata]
        }

        const data = JSON.parse(resp.data);
        sd.fieldList.forEach(i => {
          let field = metadata.find(m => m["propertyName"] == i.name);
          if (!field) return;
          field["pinned"] = i.pinned;
        });
        this.showGridData(metadata, data);
        this.loadingData = false;

        const extTypeField = metadata.find(f => f.extensionType);
        if (extTypeField) {
          if (extTypeField.extType && !Array.isArray(extTypeField.extType)) {
            extTypeField.extType = [extTypeField.extType];
          }
          this.extTypeListChange.emit({ values: extTypeField.extType, selected: loadOptions?.extTypeSelected || aggregDatasetConfig?.extTypeSelected || [] });
        } else {
          this.extTypeListChange.emit({ values: null, selected: null });
        }

        this.sql = resp.sql;
        // this.devService.devModeLog(`LOADED DATASET DATA FROM BACKEND ${((data.length / 1024 / 1024).toFixed(2))}MB`);

        if (this.isAggregation) {

          // AGGREGATION DATA (TO BE USED WITHOUT CALL BACKEND)
          const dataStorage = JSON.stringify({
            updated: new Date(),
            parentId: this._parentId,
            metadata,
            data: data,
          });

          if ((dataStorage.length / 1024 / 1024) < 5.0 && !this.searchText) {
            // Limite do storage é de 5MB
            try {
              this.storage.setSessionData(`AGG_${this.datasetName}`, dataStorage);
            } catch {
              this.storage.removeSessionData(`AGG_${this.datasetName}`);
            }
          } else {
            this.storage.removeSessionData(`AGG_${this.datasetName}`);
          }

          // AGGREGATION CONFIG (FOR AGGREGATION EXHIBITION)
          if (etl && etl.length) {
            this.storage.setData(`AGGC_${this.datasetName}`, JSON.stringify({
              extType: extTypeField?.extType,
              extTypeSelected: etl.map(item => item.datasetName),
            }));
          } else {
            this.storage.removeData(`AGGC_${this.datasetName}`);
          }

        }

      }, error => {
        this.loadingData = false;
      });
  }

  private loadDatasetActions() {

    this.actionList = [];
    this.buttonEdit = false;
    this.buttonInsert = false;
    this.buttonDelete = false;

    if (this.rowDatasetName || this.datasetName) {
      this.sec.getDatasetActions(this.rowDatasetName || this.datasetName)
        .subscribe(actionList => {
          this.actionList = actionList;

          this.buttonEdit = (this.getAction(T2AccessItemDatasetActionType.DSACTION_EDIT).length > 0);
          this.buttonInsert = (this.getAction(T2AccessItemDatasetActionType.DSACTION_INSERT).length > 0);
          this.buttonDelete = (this.getAction(T2AccessItemDatasetActionType.DSACTION_DELETE).length > 0);

          this.gridContextComponent.contextMenu = [];

          this.actionList.forEach((action: T2AccessItem) => {
            if (
              [T2AccessItemDatasetActionType.DSACTION_OTHER,
              T2AccessItemDatasetActionType.DSACTION_OTHERCHANGE,
              T2AccessItemDatasetActionType.DSACTION_OTHERCLOSE,
              T2AccessItemDatasetActionType.DSACTION_COPY,
              T2AccessItemDatasetActionType.DSACTION_VIEW
              ].includes(action.datasetActionType) &&
              this.actionService.canExecuteAction(action)
            ) {

              this.gridContextComponent.contextMenu.push({
                name: action.datasetActionDescription,
                requiresId: true,
                action: () => { this.executeDatasetAction(action) },
                // icon: '<span unselectable="on" role="presentation"><nb-icon icon="star"></nb-icon></span>'
              });
            }
          });


          // this.gridContextComponent.contextMenu.push({ name: "separator", outsideSubMenu: true});

          // this.gridContextComponent.contextMenu.push({
          //   name: "Campos",
          //   requiresId: false,
          //   outsideSubMenu: true,
          //   action: () => {
          //     this.selectVisualization();
          //   },
          //   icon: '<span unselectable="on" role="presentation"><nb-icon icon="star"></nb-icon></span>'
          // });

          this.gridContextComponent.contextMenu.push({
            name: "SQL",
            requiresId: false,
            outsideSubMenu: true,
            action: () => {
              this.messageService.showDialog({
                context: {
                  title: `SQL Expression`,
                  message: this.sql,
                  actions: []
                }
              });
            },
            icon: '<span unselectable="on" role="presentation"><nb-icon icon="star"></nb-icon></span>'
          });

        });
    }
  }

  private showGridData(metadata: Array<{}>, data: Array<{}>) {

    const IDFields = metadata.filter((item: any) => item.alias === "ID");

    this.gridColumns = [];
    this.gridDataset.setGridColumnDefs([]);
    this.gridDataset.setGridData([], null);
    this.sortable = (this.sortKeyFieldName ? false : true);

    let enabledDrag: boolean = this.sortKeyFieldName && !this.FormGroup.readOnly;

    if (enabledDrag) {
      this.sortable = false;
      this.gridDataset.t2GridOptions.animateRows = true;
      this.gridDataset.t2GridOptions.onRowDragMove = this.onRowDragMove.bind(this);
      this.gridDataset.t2GridOptions.onRowDragEnd = this.onRowDragEnd.bind(this);
    }

    metadata.forEach((item: any) => {
      if (item.alias !== "ID") {
        const col = { colId: item.alias, headerName: item.propertyLabel, field: item.alias, statusFlow: [], type: null, extType: [], precision: 0 };

        switch (item.propertyType) {
          case "DECIMAL":
            col.type = "numericColumn";
            col.precision = Number(item.propertySize) || 0;
            break;

          case "BOOLEAN":
            col.type = "booleanColumn";
            break;

          case "DATE":
            col.type = "dateColumn";
            break;

          case "DATE_TIME":
            col.type = "dateTimeColumn";
            break;

          default:
            break;
        }

        if (item.statusControl) {
          col.type = "statusColumn";

          const statusProp = this.dataset.propertyList.find(prop => prop.statusCtrl);
          if (statusProp) {
            col.statusFlow = statusProp.statusList.map(status => {
              return {
                name: status.description,
                action: status.execDescription,
                tooltip: status.helpText,
                colorStyle: status.statusColorStyle
              }
            });
          }
        }

        if (item.extType?.length) {
          col.type = "dsExtentionTypeColumn";
          col.extType = item.extType;
          this.extTypes = item.extType;
        }

        if (enabledDrag && item.propertyName == this.sortKeyFieldName) {
          col["rowDrag"] = true;
          col["pinned"] = "left";
          col["width"] = 70;
          col.type = "numericColumn";

          this.sortKeyColumnAlias = item.alias;
        }

        if (item["pinned"]) {
          col["pinned"] = "left";
        }

        this.gridColumns.push(col);
      }
    });

    this.gridData = data;

    this.gridData.forEach(item => {
      item["statusInfo"] = {
        datasetName: this.datasetName,
        informationId: item["ID"]
      }
    })

    let colList = metadata.filter(m => ["DATE", "DATETIME", "DATE_TIME"].includes(m["propertyType"]));

    if (colList.length > 0) {
      this.gridData.forEach(item => {
        colList.forEach(col => {
          if (item[col["alias"]]) {
            item[col["alias"]] = new Date(item[col["alias"]]);
          }
        })
      })
    }

    if (enabledDrag) {
      this.gridData.sort((a, b) => a[this.sortKeyColumnAlias] - b[this.sortKeyColumnAlias]);
    }

    this.gridDataset.setGridColumnDefs(this.gridColumns);
    this.gridDataset.setGridData(this.gridData, this.selectedId);

    this.createGridStateName();
  }

  private onRowDragMove(event: RowDragMoveEvent) {
    this.movingRow = undefined;
    var movingNode = event.node;
    var overNode = event.overNode;
    var rowNeedsToMove = movingNode !== overNode;
    if (rowNeedsToMove) {
      // the list of rows we have is data, not row nodes, so extract the data
      var movingData = movingNode.data;
      this.movingRow = movingData;
      var overData = overNode!.data;
      var fromIndex = this.gridData.indexOf(movingData);
      var toIndex = this.gridData.indexOf(overData);
      var newStore = this.gridData.slice();
      moveInArray(newStore, fromIndex, toIndex);
      this.gridData = newStore;
      this.gridDataset.t2Grid.api.setRowData(newStore);
      this.gridDataset.t2Grid.api.clearFocusedCell();
    }
    function moveInArray(arr: any[], fromIndex: number, toIndex: number) {
      var element = arr[fromIndex];
      arr.splice(fromIndex, 1);
      arr.splice(toIndex, 0, element);
    }
  }

  private onRowDragEnd(e: RowDragEndEvent) {
    if (e.overNode) {
      this.loadingData = true;
      let seq = 1;
      this.gridData.forEach(item => {
        item[this.sortKeyColumnAlias] = seq;
        seq++;
      });

      let recChangeList = this.gridData.map(item => {
        return {
          id_recordOfDataset: item["ID"],
          value: item[this.sortKeyColumnAlias]
        }
      });


      this.datasetService.updateRecordsSequence(this.datasetName, this.parentId, this.sortKeyFieldName, recChangeList)
        .pipe(take(1))
        .subscribe(sortedList => {
          sortedList.forEach(item => {
            let data = this.gridData.find(d => d["ID"] == item.id_recordOfDataset);
            data[this.sortKeyColumnAlias] = item.value;
          });

          this.loadingData = false;
          this.gridDataset.refreshCells();
        })
    }
  }

  private executeDatasetAction(action: T2AccessItem) {
    this.loadingData = true;
    const params = new Map<string, string>();
    const rows = this.gridDataset.getRowsSelected();
    if (!rows.length && this.gridDataset.getFocusedRow()) {
      rows.push(this.gridDataset.getFocusedRow());
    }

    let promList = new Array<Promise<any>>()

    rows.forEach((row) => {
      params.set("id", row.ID);
      const obs$: Observable<any> = this.actionService.executeAction(action, params);
      if (obs$) {
        promList.push(obs$.pipe(take(1)).toPromise());
      }
    });

    Promise.all(promList).then(() => {
      if (action.datasetActionType == T2AccessItemDatasetActionType.DSACTION_OTHERCHANGE) {
        this.updateRecords(rows.map(row => row.ID)).then(() => {
          this.messageService.showToast("A ação foi executada", action.datasetActionDescription, "info", false);
        })
      } else {
        this.loadingData = false;
        this.messageService.showToast("A ação foi executada", action.datasetActionDescription, "info", false);
      }
    }, error => {
      this.loadingData = false;
    })
  }

  private updateRecords(idList: Array<string>, insert?: boolean) {
    return new Promise<void>((resolve, reject) => {
      let pkProperty = this.dataset.propertyList?.find(prop => prop.primaryKey);

      if (pkProperty) {
        const sd: { fieldList: Array<any>, queryCondition: ConditionGroup } = {
          fieldList: [...(this.visualization?.fieldList || [])].map(f => { return { name: f.name, description: f.description, id_dataset_aggregation: f.id_dataset_aggregation }; }),
          queryCondition: {
            condition: 'OR',
            fieldList: idList.map(id => {
              return {
                datasetId: this.dataset.id_dataset,
                datasetName: this.dataset.datasetName,
                property: pkProperty.propertyName,
                value: id,
                operator: "EQUALS",
                propertyType: "GUID"
              }
            })
          }
        };

        let queryParams = new Map<string, string>();
        queryParams.set("extensionType", JSON.stringify([]));
        queryParams.set("statusEndsInformation", String(this.showStatusEndsInfo));
        queryParams.set("inativeInformation", String(this.showInactive));

        if (this.parentId) {
          queryParams.set("id_parent", this.parentId);
        }

        if (this.searchText != undefined && this.searchText != null) {
          queryParams.set("text", this.searchText);
        }

        this.httpClient.post(`core.presentation/conditionSearch/${this.dataset.id_dataset}`, queryParams, sd).pipe(take(1)).subscribe(resp => {
          const data: Array<any> = JSON.parse(resp.data);

          if (data.length < idList.length) { // Se é menor é pq o registro foi excluído
            this.gridDataset.removeData(idList.map(itemId => { return { ID: itemId } }));
          } else if (!insert) {
            this.gridDataset.updateData(data);
          } else {
            this.gridDataset.addData(data);
          }

          this.gridData = this.gridDataset.data;

          this.loadingData = false;
          resolve();
        }, error => {
          this.loadingData = false;
          reject(error);
        })
      } else {
        resolve();
        this.loadingData = false;
      }
    });
  }

  private getAction(actionType: T2AccessItemDatasetActionType): T2AccessItem[] {
    return this.actionList.filter(ac => ac.datasetActionType === actionType);
  }

  private openRecord(id: string, ac: T2AccessItemDatasetActionType): void {

    const actions = this.getAction(ac);

    if (actions.length > 0) {
      const action = actions[0];
      const params = new Map<string, string>();

      params.set("datasetName", this.rowDatasetName || this.datasetName);
      params.set("id", id);

      if (this.parentId) {
        params.set("parentId", this.parentId)
      }

      let respAction = this.actionService.executeAction(action, params, !this.parentId ? 'newWindow' : 'dialog');
      if (respAction) {
        respAction.pipe(take(1)).subscribe(resp => {
          this.loadingData = true;

          this.updateRecords([id], ac === T2AccessItemDatasetActionType.DSACTION_INSERT);
        })
      }
    }

  }

  buttonEditClick() {

    if (this.selectedId) {
      this.openRecord(this.selectedId, T2AccessItemDatasetActionType.DSACTION_EDIT);

      /* CODIGO DUPLICADO
      const actions = this.getAction(T2AccessItemDatasetActionType.DSACTION_EDIT);
      if (actions.length > 0) {
        const action = actions[0];
        const params = new Map<string, string>();

        params.set("datasetName", this.rowDatasetName || this.datasetName);
        params.set("id", this.selectedId);

        if (this.parentId) {
          params.set("parentId", this.parentId)
        }

        let respAction = this.actionService.executeAction(action, params, !this.parentId ? 'newWindow' : 'dialog');

        if (respAction) {
          respAction.pipe(take(1)).subscribe(resp => {
            const rows = this.gridDataset.getRowsSelected();
            if (!rows.length && this.gridDataset.getFocusedRow()) {
              rows.push(this.gridDataset.getFocusedRow());
            }

            this.loadingData = true;
            this.updateRecords(rows.map(row => row.ID));
          })
        }
      }
      */
    }
  }

  buttonInsertClick() {
    if (this.isChildrenTabsheet) {
      this.saveAutoformParent().then(informationId => {
        this.tabLoaded = false;
        this.parentId = informationId;
        this.insertRecord();
      });
    } else {
      this.insertRecord();
    }
  }

  private insertRecord() {

    this.httpClient.getNewUUID().pipe(take(1)).subscribe(newId => {
      this.openRecord(newId, T2AccessItemDatasetActionType.DSACTION_INSERT);
    });

    /* CODIGO DUPLICADO
    const actions = this.getAction(T2AccessItemDatasetActionType.DSACTION_INSERT);
    if (actions.length > 0) {
      const action = actions[0];
      const params = new Map<string, any>();

      params.set("datasetName", this.datasetName);

      if (this.insertParams) {
        params.set("insertParams", this.insertParams);
      }

      this.httpClient.getNewUUID().pipe(take(1)).subscribe(newId => {
        params.set("id", newId);

        if (this.parentId) {
          params.set("parentId", this.parentId);
        }

        let respAction = this.actionService.executeAction(action, params, !this.parentId ? 'newWindow' : 'dialog');
        if (respAction) {
          respAction.pipe(take(1)).subscribe(resp => {
            this.loadingData = true;

            if (resp?.newId) {
              newId = resp.newId;
            }

            this.updateRecords([newId], true);
          })
        }
      });
    }
    */
  }

  buttonDeleteClick() {
    this.messageService.showDialog({
      context: {
        topMessage: "ATENÇÃO",
        message: "Deseja excluir esse registro ?",
        actions: [{ description: "Excluir", status: "danger" }, { description: "Cancelar", status: "basic" }]
      }
    }).onClose.subscribe(resp => {
      if (resp == "Excluir") {
        const actions = this.getAction(T2AccessItemDatasetActionType.DSACTION_DELETE);
        if (actions.length > 0) {
          const action = actions[0];
          const params = new Map<string, string>();

          params.set("datasetName", this.rowDatasetName || this.datasetName);
          params.set("id", this.selectedId);

          this.actionService.executeAction(action, params).subscribe(resp => {
            if (!resp.error) {
              this.gridDataset.t2Grid.api.applyTransaction({ remove: [{ ID: this.selectedId }] });
            }
          }, (error: T2CoreException) => {
            if (error.message) {
              this.messageService.showToastError(error.message);
            }
          });;
        }
      }
    })
  }

  onGridRowClick(params) {
    this.selectedId = null;
    if (params && params.data && params.data.ID) {
      this.selectedId = params.data.ID;
    }

    if (!this.isAggregation && !this.isChildrenTabsheet) {
      if (!this.parentId) {
        if (this.t2Route) {
          this.t2Route.queryParams.set("selectedId", this.selectedId);
        }

        this.router.navigate(
          [],
          {
            relativeTo: this.route,
            queryParams: { selectedId: this.selectedId },
            queryParamsHandling: "merge",
          });
      }
    }

    /**
     * When the data list has more then one dataset type, we must know the specif
     * dataset of the selected row
     */
    this.rowDatasetName = null;
    if (this.extTypes?.length) {
      const column = this.gridColumns.find(column => column.type == "dsExtentionTypeColumn");
      if (column) {
        const extDescription = params.data[column.colId];
        const extType = this.extTypes.find(ext => ext.description == extDescription);

        this.rowDatasetName = extType.datasetName || null;

        if (this.rowDatasetName) this.loadDatasetActions();
      }
    }

    this.gridDataset.unselectAll();
  }

  onGridRowDoubleClick(params: any) {
    if (this.isAggregation) return;

    this.buttonEditClick();
  }

  onClick(event) {
    if (this.showingInfoList && !this.visualizationButton.nativeElement.contains(event.target))
      this.showingInfoList = false;
  }

  visualizationInfoClick() {
    this.showingInfoList = !this.showingInfoList;
  }

  selectFilter(id_datasetVisualization: string, action: 'run' | 'edit' | 'copy' | 'erase' | 'add') {
    this.showingInfoList = false;

    switch (action) {
      case 'run':
        this.storage.setData('lastSelectedVisualization-' + this.datasetName, id_datasetVisualization);
        this.visualizationSelected = true;
        this.visService.getDatasetVisualization(id_datasetVisualization)
          .subscribe(resp => {
            this.visualization = resp;
            this.gridDataset.autoSizeAllColumns(true);
            this.showQuickSearch = true;
            if (this.visualization.queryCondition?.fieldList?.filter((i: ConditionField) => i.fieldFilter?.quickSearch).length) {
              this.executeQuickSearch(false);
              return;
            }
            this.executeSearch();

          });
        break;

      case 'add':
        this.openDialogDatasetVisualization(new DatasetVisualization());
        break;

      case 'edit':
        this.visService.getDatasetVisualization(id_datasetVisualization)
          .subscribe(resp => {
            this.openDialogDatasetVisualization(resp);
          });
        break;

      case 'copy':
        this.visService.getDatasetVisualization(id_datasetVisualization)
          .subscribe(resp => {
            resp.id_dataset_visualization = undefined;
            resp.description = "(COPY) " + resp.description;
            resp.publicVisualization = false;
            this.openDialogDatasetVisualization(resp);
          });
        break;

      case 'erase':
        this.messageService.showDialog({
          context: {
            topMessage: "ATENÇÃO",
            message: "Deseja excluir a visualização?",
            actions: [{ description: "Sim", status: "danger" }, { description: "Não", status: "primary" }]
          }
        }).onClose.subscribe(resp => {
          if (resp == "Sim") {
            this.visService.deleteVisualization(id_datasetVisualization)
              .subscribe(resp => {
                this.getVisualizationList().subscribe();

                if (this.visualization?.id_dataset_visualization == id_datasetVisualization) {
                  this.selectDefaultVisualization().then(() => {
                    this.executeSearch();
                    this.gridDataset.autoSizeAllColumns(true);
                  });
                }
              });
          }
        });

        break;
    }
  }

  private openDialogDatasetVisualization(dv: DatasetVisualization) {

    this.dialogService.open(QueryComponent, {
      autoFocus: true, closeOnEsc: false, closeOnBackdropClick: false, hasBackdrop: true,
      context: { id_dataset: this.dataset?.id_dataset, datasetVisualization: dv }
    }).onClose.subscribe((opt: { save: boolean, datasetVisualization?: DatasetVisualization }) => {
      if (opt.save && opt.datasetVisualization) {
        const vi = this.visualizationInfoList.find(vi => vi.id_dataset_visualization == opt.datasetVisualization.id_dataset_visualization);
        this.storage.setData('lastSelectedVisualization-' + this.datasetName, opt.datasetVisualization.id_dataset_visualization);
        this.visualizationSelected = true;

        if (vi) {
          vi.description = opt.datasetVisualization.description;
        }

        this.getVisualizationList().subscribe();
        this.visualization = opt.datasetVisualization;
        this.showQuickSearch = true;
        this.gridDataset.autoSizeAllColumns(true);
        if (this.visualization.queryCondition?.fieldList?.filter((i: ConditionField) => i.fieldFilter?.quickSearch).length) {
          this.executeQuickSearch(false);
          return;
        }
        this.executeSearch();
      }
    });
  }

  selectVisualization() {

    if (this.visualization?.fieldList.length == 0) {
      this.httpClient.get(`core.presentation/datasetDefaultVisualization/${this.dataset.id_dataset}`, null)
        .pipe(take(1), takeUntil(this.unsubscribe$))
        .subscribe(resp => {
          if (resp.visualization?.visualizationData) {
            const obj: { fieldList?: Array<VisualizationField>, queryCondition: ConditionGroup } = JSON.parse(resp.visualization.visualizationData);
            this.visualization?.fieldList.push(...obj.fieldList);
          }

          this.openVisualizationFields();
        });
    } else {
      this.openVisualizationFields();
    }
  }

  private openVisualizationFields() {
    this.dialogService.open(VisualizationDialogComponent, {
      autoFocus: true, closeOnEsc: false, closeOnBackdropClick: false, hasBackdrop: true,
      context: { id_dataset: this.dataset.id_dataset, description: this.dataset.description, selectedFieldList: this.visualization?.fieldList }
    }).onClose.subscribe((opt: { save: boolean, fields?: VisualizationField[] }) => {
      if (opt.save) {
        this.visualization.fieldList = opt.fields.map(f => {
          return {
            propertyName: f.propertyName,
            datasetName: f.datasetName,
            id_dataset: f.id_dataset,
            id_dataset_property: f.id_dataset_property,
            name: f.name,
            description: f.description,
            id_dataset_aggregation: f.id_dataset_aggregation,
            relationType: f.relationType
          };
        });
        this.executeSearch();
        this.gridDataset.autoSizeAllColumns(true);
      }
    });
  }

  onSeachTextChange(event) {
    this.searchText = event.srcElement.value || "";
  }

  onsearchTextKeyEnter(event) {
    if (event.keyCode === 13 && !event.ctrlKey && !event.altKey && !event.shiftKey) {
      this.executeSearch();
    }
  }

  executeSearch() {
    if (!this.isAggregation && !this.isChildrenTabsheet) {
      if (this.t2Route) {
        this.t2Route.queryParams.set("searchText", this.searchText);
      }

      this.router.navigate(
        [],
        {
          relativeTo: this.route,
          queryParams: { searchText: this.searchText },
          queryParamsHandling: "merge",
        });
    }

    this.loadDatasetData({ force: true });
  }

  showFilter() {
    this.filterStatus = this.filterStatus === "opened" ? "closed" : "opened";

    // // Ajusta largura do menu
    // if (this.menuDimention.widthMenu === 50) {
    //   this.menuDimention.widthMenu = 300;
    // } else {
    //   this.menuDimention.widthMenu = 50;
    // }

    // this.menuDimention.contentLeft = this.menuDimention.widthMenu + 20;
  }

  showQuickSearchFields() {
    this.showQuickSearch = !this.showQuickSearch;
  }

  quickSearchQueryCondition(queryCondition: ConditionGroup) {
    if (this.visualization?.queryCondition != queryCondition) this.visualization.queryCondition = queryCondition;
    this.executeQuickSearch(true);
  }

  executeQuickSearch(quickSearch?: boolean) {
    if (!this.isAggregation && !this.isChildrenTabsheet) {
      if (this.t2Route) {
        this.t2Route.queryParams.set("searchText", this.searchText);
      }

      this.router.navigate(
        [],
        {
          relativeTo: this.route,
          queryParams: { searchText: this.searchText },
          queryParamsHandling: "merge",
        });
    }

    this.loadDatasetData({ quickSearch: quickSearch });
  }
}

export class ExtType {
  description: string;
  datasetName: string;
}
