import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ConditionField, T2VisualizationService } from "../../visualization/t2-visualization.service";
import { FormGroup } from "@angular/forms";

@Component({
  selector: 'app-query-condition-field',
  templateUrl: './query-condition-field.component.html',
  styleUrls: ['./query-condition-field.component.scss']
})
export class QueryConditionFieldComponent {
  private _item: ConditionField;
  @Input()
  get item(): ConditionField {
    return this._item;
  }
  set item(value: ConditionField) {
    if (!value.valueOptions && ['STATUS', 'FIXED_COMBO'].includes(value.propertyType)) {
      this.visService.getValueOptions(value, value.datasetName, value.property);
    }
    this._item = value;
    this._item.requireValue = !["EMPTY"].includes(value.operator);
  };

  @Input() formGroup: FormGroup<{}>;
  @Input() editType: 'All' | 'OnlyValue';
  @Output() fieldSelected = new EventEmitter<ConditionField>();

  constructor(public visService: T2VisualizationService) { }

  selectField(item) {
    this.fieldSelected.emit(item);
  }

}
