<div *ngIf="registrationData.contentType === 'boolean'" style="text-align: center;">
  <div *ngIf="registrationData.propValue === 'True'">Sim</div>

  <div *ngIf="registrationData.propValue === 'False'">Não</div>
</div>

<div *ngIf="registrationData.contentType === 'datetime'">
  {{ dateValue | date: "dd/MM/yyyy" }}
</div>

<div
  *ngIf="['integer', 'decimal'].includes(registrationData.contentType)"
  style="text-align: right; margin-right: 7px"
>
  <!-- alinhar a direita -->
  {{ registrationData.propValue }}
</div>

<div *ngIf="['text', 'memo', 'list'].includes(registrationData.contentType)">
  {{ registrationData.propValue }}
</div>
