import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { NbDialogService } from "@nebular/theme";
import { take } from "rxjs/Operators";
import { DialogInputComponent } from "src/app/core/cmp/dialog-input/dialog-input.component";
import { FormTemplateComponent } from "src/app/core/cmp/form-template/form-template.component";
import { T2gridComponent } from "src/app/core/cmp/t2grid/t2grid.component";
import { T2InputFloatComponent } from "src/app/core/cmp/ui/t2-input-float/t2-input-float.component";
import { T2TextAreaComponent } from "src/app/core/cmp/ui/t2-text-area/t2-text-area.component";
import { T2ViewTemplateData } from "src/app/core/cmp/view-template/model/t2-view-template-data";
import { LayoutType, ViewTemplateElement } from "src/app/core/cmp/view-template/model/view-template-element";
import { DatasetPropertyStatusItem } from "src/app/core/dataset/model/dataset-property-status-item";
import { T2DatasetService } from "src/app/core/dataset/t2dataset.service";
import { T2CoreException } from "src/app/core/exception/exception";
import { T2HttpClientService } from "src/app/core/http/t2httpClient.service";
import { T2AccessItem, T2AccessItemDatasetActionType } from "src/app/core/security/model/t2accessItem";
import { T2SecurityService } from "src/app/core/security/t2security.service";
import { T2MessageService } from "src/app/core/t2-message.service";
import { StockOperationComponent } from "../stock-operation/stock-operation.component";
import { DepartmentalRequisition } from "./model/departmental-requisition";
import { ReservationRequisitionComponent } from "../reservation-requisition/reservation-requisition.component";
import { ActionService } from "src/app/core/action/action.service";
import { Observable } from "rxjs";
import { T2ReservationService } from "../reservation-requisition/t2-reservation.service";

@Component({
  selector: 'app-departmental-requisition',
  templateUrl: './departmental-requisition.component.html',
  styleUrls: ['./departmental-requisition.component.scss']
})
export class DepartmentalRequisitionComponent implements OnInit {

  @ViewChild("formTemplate", { static: true }) formTemplate: FormTemplateComponent;
  @ViewChild("gridReq") gridReq: T2gridComponent;

  public loadingData: boolean = false;
  public loaded: boolean = false;
  public selectedId: string = null;
  public buttonInsert: boolean = false;
  public buttonEdit: boolean = false;
  public buttonDelete: boolean = false;
  public buttonApprove: boolean = false;
  public buttonReprove: boolean = false;
  public buttonWriteOff: boolean = false;
  public buttonReservation: boolean = false;
  private hasAccessToApprove: boolean = false;
  private hasAccessToReprove: boolean = false;
  private hasAccessToWriteOff: boolean = false;
  public showVolumes: boolean = false;
  public saving = false;
  public requisitionList: Array<DepartmentalRequisition> = [];
  public statusList: Array<DatasetPropertyStatusItem> = [];
  private id_requisicaoDepartAgrup: string = null;
  private selectedItemId: string;
  public lockButtons: boolean = false;
  private datasetName: string = "stk_requisicao_departamental";
  private approveAction = "zD20211227H115725321R000000331";
  private reproveAction = "zD20211227H115955318R000000413";
  private writeOffAction = "zD20231114H171938815R000000041";
  private dsActionList: Array<T2AccessItem>;
  public showStatusEndsInfo: boolean = false;
  public gridContextComponent = { contextMenu: [] };

  constructor(
    public router: Router,
    private route: ActivatedRoute,
    private sec: T2SecurityService,
    private httpClient: T2HttpClientService,
    private messageService: T2MessageService,
    private datasetService: T2DatasetService,
    private formBuilder: FormBuilder,
    private dialogService: NbDialogService,
    private actionService: ActionService,
    private reservationService: T2ReservationService
  ) { }

  ngOnInit(): void {
    this.loaded = false;

    this.sec.accessLoaded()
      .pipe(
        take(1)
      )
      .subscribe(() => {
        if (this.formTemplate.validateAccess(["zD20211227H170929433R000000005"], 'full')) {
          this.loadingData = true;
          this.loaded = true;

          this.loadStatusList().then(() => {
            this.configGrid();
            this.loadRequisitions();
          }, error => {
            this.messageService.showToastError(error);
            console.error(error);
          });

        }
      });

    this.sec.getDatasetActions(this.datasetName)
      .pipe(
        take(1)
      ).subscribe(actionList => {
        this.dsActionList = actionList;
        this.buttonEdit = (actionList.filter(ac => ac.datasetActionType === T2AccessItemDatasetActionType.DSACTION_EDIT).length > 0);
        this.buttonInsert = (actionList.filter(ac => ac.datasetActionType === T2AccessItemDatasetActionType.DSACTION_INSERT).length > 0);
        this.buttonDelete = (actionList.filter(ac => ac.datasetActionType === T2AccessItemDatasetActionType.DSACTION_DELETE).length > 0);
      });

    this.sec.getActionList([this.approveAction, this.reproveAction, this.writeOffAction]).subscribe(acList => {
      this.hasAccessToApprove = acList.some(ac => ac.id == this.approveAction);
      this.hasAccessToReprove = acList.some(ac => ac.id == this.reproveAction);
      this.hasAccessToWriteOff = acList.some(ac => ac.id == this.writeOffAction);
    });
  }

  public loadRequisitions() {
    return new Promise<void>((resolve, reject) => {
      this.loadingData = true;

      let params = new Map();
      params.set("statusEndsInformation", this.showStatusEndsInfo);


      this.httpClient.get("stk.departmentalRequisition/requisitions", params)
        .pipe(take(1))
        .subscribe(resp => {
          if (resp.requisitionList) {
            if (!Array.isArray(resp.requisitionList)) {
              resp.requisitionList = [resp.requisitionList];
            }

            this.requisitionList = resp.requisitionList;

            this.requisitionList.forEach(req => {
              req["ID"] = req.id_requisition;
              req.consumedQty = Number(req.consumedQty).valueOf();
              req.requestedQty = Number(req.requestedQty).valueOf();
              req.userRequestedQty = Number(req.userRequestedQty).valueOf();
              req.reservedQty = Number(req.reservedQty).valueOf();
            })
          }

          this.gridReq.setGridData(this.requisitionList, null);
          this.loadingData = false;
          resolve();
        }, error => {
          this.loadingData = false;
          this.messageService.showToastError(error);
          reject(error);
        });
    });
  }

  private configGrid() {
    this.gridReq.setGridColumnDefs([
      { checkboxSelection: true, lockPosition: true, pinned: "left", valueGetter: "node.rowIndex + 1", width: 45, suppressNavigable: true },
      { headerName: "Grupo", field: "group", width: 90, rowGroup: !this.hasAccessToApprove, hide: !this.hasAccessToApprove },
      { headerName: "Código", field: "code", width: 100 },
      {
        headerName: "Status", field: "status", type: "statusColumn", statusFlow: this.statusList.map(status => {
          return {
            name: status.description,
            action: status.execDescription,
            tooltip: status.helpText,
            colorStyle: status.statusColorStyle
          }
        }), width: 160
      },
      { headerName: "Centro de Custo", field: "costCenter" },
      { headerName: "Solicitante", field: "requesterUser" },
      { headerName: "Item", field: "item" },
      { headerName: "UN", field: "un" },
      { headerName: "Dt Necessário", field: "requiredDate", type: "dateColumn", width: 130 },
      { headerName: "Qtd Solic Usuário", field: "userRequestedQty", type: "numericColumn", filter: "agNumberColumnFilter", width: 150 },
      { headerName: "Qtd Aprovada", field: "requestedQty", type: "numericColumn", filter: "agNumberColumnFilter", width: 130 },
      { headerName: "Qtd Entregue", field: "consumedQty", type: "numericColumn", filter: "agNumberColumnFilter", width: 130 },
      { headerName: "Qtd Reservada", field: "reservedQty", type: "numericColumn", filter: "agNumberColumnFilter", width: 130 },
      { headerName: "Obs", field: "notes" },
      { headerName: "Obs Aprovador", field: "approverNotes" },
    ]);

    this.sec.getDatasetActions("stk_requisicao").subscribe((actionList) => {
      actionList.forEach((action) => {
        if ([T2AccessItemDatasetActionType.DSACTION_OTHER,
        T2AccessItemDatasetActionType.DSACTION_SEARCH,
        T2AccessItemDatasetActionType.DSACTION_OTHERCLOSE,
        T2AccessItemDatasetActionType.DSACTION_VIEW,
        T2AccessItemDatasetActionType.DSACTION_EDIT].includes(action.datasetActionType)) {
          this.gridContextComponent.contextMenu.push({
            name: action.datasetActionDescription,
            requiresId: true,
            action: () => { this.executeDatasetAction(action) },
          });
        }
      });
    });
  }

  private executeDatasetAction(action: T2AccessItem) {
    this.loadingData = true;
    const params = new Map<string, string>();
    const rows = this.gridReq.getRowsSelected();
    if (!rows.length && this.gridReq.getFocusedRow()) {
      rows.push(this.gridReq.getFocusedRow());
    }

    let promList = new Array<Promise<any>>()

    rows.forEach((row) => {
      params.set("id", row.ID);
      const obs$: Observable<any> = this.actionService.executeAction(action, params);
      if (obs$) {
        promList.push(obs$.pipe(take(1)).toPromise());
      }
    });

    Promise.all(promList).then(() => {
      this.loadingData = false;
      this.messageService.showToast("A ação foi executada", action.datasetActionDescription, "info", false);
    })

  }

  private loadStatusList() {
    return new Promise<void>((resolve, reject) => {
      this.datasetService.getStatusList(this.datasetName).subscribe(statusList => {
        this.statusList = statusList;
        resolve();
      }, error => reject(error));
    })
  }

  onGridRowClick(params) {
    this.selectedId = null;
    this.id_requisicaoDepartAgrup = null;
    this.selectedItemId = null;

    if (params?.data?.ID) {
      this.selectedId = params.data.ID;
      this.id_requisicaoDepartAgrup = params.data.id_group_requisition;
      this.selectedItemId = params.data.id_item;
    }

    this.buttonReservation = this.hasAccessToWriteOff && this.requisitionList.some(req => req.id_item == this.selectedItemId && ["Aprovada", "Baixa Parcial"].includes(req.status));
  }

  editReqGroup() {
    if (this.id_requisicaoDepartAgrup) {
      let params = new Map();
      params.set("id_requisicaoDepartAgrup", this.id_requisicaoDepartAgrup);

      let resp = this.actionService.executeAction(this.dsActionList.find(ac => ac.datasetActionType == T2AccessItemDatasetActionType.DSACTION_EDIT), params);
      this.reloadRequisition(resp, false);
    }
  }

  insertReqGroup() {
    let resp = this.actionService.executeAction(this.dsActionList.find(ac => ac.datasetActionType == T2AccessItemDatasetActionType.DSACTION_INSERT), null);
    this.reloadRequisition(resp, true);
  }

  private reloadRequisition(obs$: Observable<any>, insert: boolean) {
    if (obs$) {
      this.loadingData = true;

      obs$.pipe(take(1)).subscribe(resp => {
        let idReqAgrup = resp.id_requisicaoDepartAgrup;

        let params = new Map();
        params.set("id_requisicaoDepartAgrup", idReqAgrup);
        params.set("statusEndsInformation", this.showStatusEndsInfo);

        this.httpClient.get("stk.departmentalRequisition/requisitions", params)
          .pipe(take(1))
          .subscribe(resp => {
            if (resp.requisitionList) {
              if (!Array.isArray(resp.requisitionList)) {
                resp.requisitionList = [resp.requisitionList];
              }

              let reqList: Array<DepartmentalRequisition> = resp.requisitionList;

              reqList.forEach(req => {
                req["ID"] = req.id_requisition;
                req.consumedQty = Number(req.consumedQty).valueOf();
                req.requestedQty = Number(req.requestedQty).valueOf();
                req.userRequestedQty = Number(req.userRequestedQty).valueOf()
              });

              if (insert) {
                this.gridReq.addData(reqList);
              } else {
                this.gridReq.updateData(reqList);

                let currentList = this.requisitionList.filter(r => r.id_group_requisition == idReqAgrup);
                if (reqList.length != currentList.length) {
                  let removeList = currentList.filter(r => !reqList.map(dr => dr.id_requisition).includes(r.id_requisition));
                  this.gridReq.removeData(removeList.map(r => {
                    return {
                      ID: r.id_requisition
                    }
                  }));
                }
              }

              this.loadingData = false;
            } else {
              let idList = this.requisitionList.filter(r => r.id_group_requisition == idReqAgrup).map(r => r.id_requisition);
              this.gridReq.removeData(idList.map(id => {
                return {
                  ID: id
                }
              }));

              this.loadingData = false;
            }
          }, error => {
            this.loadingData = false;
          })
      });
    }

  }

  deleteRecord() {
    this.messageService.showDialog({
      context: {
        message: `Deseja excluir o grupo de requisições ${this.requisitionList.find(req => req.id_group_requisition == this.id_requisicaoDepartAgrup).group} ?`,
        actions: [{ description: "Sim" }, { description: "Não", status: "basic" }]
      }
    }).onClose.subscribe(resp => {
      if (resp == "Sim") {
        this.saving = true;
        this.datasetService.deleteDatasetRecord("stk_requisicaoDepartAgrup", this.id_requisicaoDepartAgrup).subscribe(resp => {
          this.saving = false;
          if (!resp.error) {
            this.gridReq.t2Grid.api.applyTransaction({
              remove: this.requisitionList.filter(req => req.id_group_requisition == this.id_requisicaoDepartAgrup).map(req => {
                return {
                  ID: req.id_requisition
                }
              })
            });

            this.selectedId = null;
            this.id_requisicaoDepartAgrup = null;
          }
        }, (error: T2CoreException) => {
          this.saving = false;
          if (error.message) {
            this.messageService.showToastError(error.message);
          }
        })
      }
    });
  }

  public async changeStatusRequisitions(status) {
    let reqList: Array<DepartmentalRequisition> = this.gridReq.getRowsSelected();

    if (!reqList || reqList.length == 0) {
      this.messageService.showToastError("Nenhuma requisição selecionada !");
      return;
    }

    let layout: Array<ViewTemplateElement> = [];
    let formGroup: FormGroup = this.formBuilder.group({});
    let data: Array<T2ViewTemplateData> = [];

    if (status == "Aprovada") {
      for (let req of reqList) {
        layout.push(
          {
            layoutType: LayoutType.gridLayout,
            title: `${req.code} - ${req.item}`,
            children: [
              {
                layoutType: LayoutType.component,
                cmpType: T2InputFloatComponent,
                cmpName: `${req.id_requisition}_qtdSolicitada`,
                title: "Qtd Solicitada",
                columnSpan: 2,
                isBaseComponent: true,
              },
              {
                layoutType: LayoutType.component,
                cmpType: T2InputFloatComponent,
                cmpName: `${req.id_requisition}_qtdAprovada`,
                title: "Aprovar(Qtd)",
                columnSpan: 2,
                isBaseComponent: true
              },
              {
                layoutType: LayoutType.component,
                cmpType: T2TextAreaComponent,
                cmpName: `${req.id_requisition}_obsAprovador`,
                title: "Observação",
                columnSpan: 3,
                isBaseComponent: true
              }
            ]
          }
        );

        data.push({ cmpName: `${req.id_requisition}_qtdSolicitada`, properties: { readOnly: true } });

        formGroup.addControl(`${req.id_requisition}_qtdSolicitada`, this.formBuilder.control(req.userRequestedQty));
        formGroup.addControl(`${req.id_requisition}_qtdAprovada`, this.formBuilder.control(req.userRequestedQty));
        formGroup.addControl(`${req.id_requisition}_obsAprovador`, this.formBuilder.control(req.approverNotes));
      }
    } else {
      for (let req of reqList) {
        layout.push(
          {
            layoutType: LayoutType.gridLayout,
            title: `${req.code} - ${req.item}`,
            children: [
              {
                layoutType: LayoutType.component,
                cmpType: T2TextAreaComponent,
                cmpName: `${req.id_requisition}_obsAprovador`,
                title: "Observação",
                columnSpan: 3,
                isBaseComponent: true
              }
            ]
          }
        );

        formGroup.addControl(`${req.id_requisition}_obsAprovador`, this.formBuilder.control(req.approverNotes));
      }
    }

    let dlg = this.dialogService.open(DialogInputComponent, {
      context: {
        title: status == "Aprovada" ? "Aprovar Requisições" : "Reprovar Requisições",
        FormGroup: formGroup,
        layout: layout,
        data: data,
        ngStyle: {
          "width.vw": "60",
          "max-height.vh": "60"
        }
      }
    });

    dlg.onClose.pipe(take(1)).subscribe(resp => {
      if (resp == "Confirma") {
        this.saving = true;
        this.lockButtons = true;
        let properties = new Map<string, any>();
        let promList = new Array<Promise<void>>();

        if (status == "Aprovada") {
          for (let req of reqList) {
            promList.push(new Promise<void>((resolve, reject) => {
              let qty: number = formGroup.controls[`${req.id_requisition}_qtdAprovada`].value,
                approverNotes: string = formGroup.controls[`${req.id_requisition}_obsAprovador`].value;

              if (qty > 0 || approverNotes) {
                if (qty > 0) {
                  properties.set("stk_requisicao_departamental.status", "Aprovada");
                  properties.set("stk_requisicao.qtdSolicitada", qty);
                }

                properties.set("stk_requisicao_departamental.obsAprovador", approverNotes);

                this.datasetService.saveSimpleEntity(this.datasetName, req.id_requisition, properties)
                  .pipe(take(1))
                  .subscribe(() => {
                    if (qty > 0) {
                      req.status = "Aprovada";
                    }

                    req.requestedQty = qty;
                    req.approverNotes = approverNotes;

                    this.gridReq.redrawRows({ rowNodes: [this.gridReq.getRowNode(req.id_requisition)] });
                    this.gridSelectionChanged(null);
                    resolve();
                  }, error => reject(error));
              }
            }));
          }
        } else {
          for (let req of reqList) {
            promList.push(new Promise<void>((resolve, reject) => {
              let approverNotes: string = formGroup.controls[`${req.id_requisition}_obsAprovador`].value;
              properties.set("stk_requisicao_departamental.status", "Reprovada");

              if (approverNotes) {
                properties.set("stk_requisicao_departamental.obsAprovador", approverNotes);
              }

              this.datasetService.saveSimpleEntity(this.datasetName, req.id_requisition, properties)
                .pipe(take(1))
                .subscribe(() => {
                  req.status = "Reprovada";
                  req.approverNotes = approverNotes;

                  this.gridReq.redrawRows({ rowNodes: [this.gridReq.getRowNode(req.id_requisition)] });
                  this.gridSelectionChanged(null);
                  resolve();
                }, error => reject(error));
            }));
          }
        }

        Promise.all(promList).then(() => {
          this.saving = false;
          this.lockButtons = false;
        }, error => {
          this.saving = false;
          this.lockButtons = false;

          this.messageService.showToastError(error);
          console.error(error);
        })
      } else {
        this.lockButtons = false;
        this.saving = false;
      }
    })
  }

  public reservation() {
    let dlg = this.dialogService.open(ReservationRequisitionComponent, {
      context: {
        itemId: this.selectedItemId,
        requisitionType: "DEPARTAMENTAL",
        initialRequisitionSelectedId: this.selectedId
      }, closeOnBackdropClick: false
    });

    dlg.onClose.pipe(take(1)).subscribe(() => {
      this.loadingData = true;

      this.reservationService.loadVolumes(this.selectedId).pipe(take(1)).subscribe(volList => {
        let reservedQty: number = volList?.map(vol => vol.quantityRequisition).reduce((partialSum, value) => partialSum + value, 0);

        let req = this.requisitionList.find(req => req.id_requisition == this.selectedId);
        if (req) {
          req.reservedQty = reservedQty;
          this.gridReq.updateData([req]);
        }

        this.loadingData = false;
      }, error => {
        this.loadingData = false;
      })
    });
  }

  public gridSelectionChanged(params) {
    let rowsSelected = this.gridReq.getRowsSelected();
    this.buttonApprove = this.hasAccessToApprove && rowsSelected.length && rowsSelected.every(req => ["Aguardando Aprovação", "Reprovada"].includes(req.status));
    this.buttonReprove = this.hasAccessToReprove && rowsSelected.length && rowsSelected.every(req => ["Aguardando Aprovação", "Aprovada"].includes(req.status));
    this.buttonWriteOff = this.hasAccessToWriteOff && rowsSelected.length && rowsSelected.some(req => ["Aprovada", "Baixa Parcial"].includes(req.status));
  }

  public writeOffStock() {
    let rowsSelected: Array<DepartmentalRequisition> = this.gridReq.getRowsSelected();

    if (rowsSelected.some(req => req.id_group_requisition != rowsSelected[0].id_group_requisition)) {
      this.messageService.showToast("Para realizar a baixa apenas um grupo de requisição pode estar selecionado", "ATENÇÃO", "warning");
      return;
    }

    this.httpClient.get("stk.departmentalRequisition/writeOffOperation", null)
      .pipe(take(1))
      .subscribe(resp => {
        if (resp.operation) {
          let dlg = this.dialogService.open(StockOperationComponent, {
            context: {
              disabledChanges: true,
              operationType: "Saida",
              operationId: resp.operation.operationId,
              operationList: [{ id: resp.operation.operationId, description: resp.operation.description }],
              referenceType: "Requisição Departamental",
              reference: rowsSelected[0].id_group_requisition,
              inDialog: true
            }
          });

          dlg.onClose.pipe(take(1)).subscribe(() => {
            this.loadRequisitions().finally(() => {
              this.gridReq.redrawRows();
            });
          })
        } else {
          this.messageService.showToast("Configure a operação de baixa na unidade da companhia", "ATENÇÃO", "warning");
        }
      });
  }
}
