<div class="flexColumn fillContent">
  <div *ngIf="visible && (component.title || component.toolTip)"
    class="divTitle">
    <span [ngClass]="requiredCss">{{component.title}}</span>

    <nb-icon *ngIf="component.toolTip" icon="question-mark-circle-outline"
      [nbTooltipTrigger]="component.toolTip ? 'hover' : 'noop'" [nbTooltip]="component.toolTip"></nb-icon>
  </div>

  <div class="divTemplate" [class]="component.cmpCssClass">
    <ng-template T2Cmp #cmp [component]="component.cmpType" [inputs]="component.inputs" [outputs]="component.outputs"
      [FormGroup]="FormGroup" (componentReference)="sendCmpRef($event)">
    </ng-template>
  </div>
</div>
