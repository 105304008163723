import { takeUntil } from 'rxjs/Operators';
import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { Subject } from "rxjs";
import { T2HttpClientService } from "../http/t2httpClient.service";
import { T2Route } from "../http/t2route";

@Component({
  selector: 'app-log',
  templateUrl: './log.component.html',
  styleUrls: ['./log.component.scss']
})
export class LogComponent implements OnInit, OnDestroy {
  private t2Route: T2Route;
  private unsubscribe = new Subject<any>();

  @Input() id_information: string;
  @Input() datasetName: string;


  constructor(private route: ActivatedRoute, private httpClient: T2HttpClientService) { }

  ngOnInit(): void {
    this.t2Route = this.httpClient.getT2RouteFromSnapshot(this.route.snapshot);
    this.id_information = this.id_information || this.t2Route.queryParams.get("id_information") || "";
    this.datasetName = this.datasetName || this.t2Route.queryParams.get("datasetName") || "";

    this.route.queryParams.pipe(takeUntil(this.unsubscribe)).subscribe(params => {
      if ((this.id_information != (params['id_information'] || "")) || (this.datasetName != (params['datasetName'] || ""))) {
        this.id_information = params['id_information'] || this.id_information || "";
        this.datasetName = params['datasetName'] || this.datasetName || "";
      }
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe.next(true);
    this.unsubscribe.complete();
  }

}
