import { Pipe, PipeTransform } from '@angular/core';
import { Warning } from "./model/warning";

@Pipe({
  name: 'filterWarning'
})
export class FilterWarningPipe implements PipeTransform {

  transform(warningList: Array<Warning>, description: string): Warning[] {
    if (!warningList || !description) {
      return warningList;
    }

    return warningList.filter(warning => warning.datasetDescription === description);
  }

}
