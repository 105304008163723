import { Component, OnInit, ViewChild } from '@angular/core';
import { RowClassParams, RowStyle } from "ag-grid-community";
import { DialogTemplateComponent } from "src/app/core/cmp/dialog-template/dialog-template.component";
import { T2gridComponent } from "src/app/core/cmp/t2grid/t2grid.component";
import { TaskPlan } from "../../model/task-plan";

@Component({
  selector: 'app-compare-simulation',
  templateUrl: './compare-simulation.component.html',
  styleUrls: ['./compare-simulation.component.scss']
})
export class CompareSimulationComponent implements OnInit {

  @ViewChild('grid', { static: true }) grid: T2gridComponent;
  @ViewChild('dialog', { static: true }) dialog: DialogTemplateComponent;

  public removedPlannings: Array<TaskPlan>;
  public newPlannings: Array<TaskPlan>;

  constructor() { }

  ngOnInit(): void {
    this.grid.t2GridOptions.getRowStyle = (params: RowClassParams) => {
      if (this.removedPlannings.map(tp => tp.id_task_plan).includes(params.data.id_task_plan)) {
        return { background: 'red', color: 'white' }
      } else {
        return { background: 'green', color: 'white' }
      }
    }

    this.grid.setGridColumnDefs([
      { headerName: "Início", field: "startDate", type: ["dateTimeColumn"], },
      { headerName: "Fim", field: "endDate", type: ["dateTimeColumn"] },
      { headerName: "OP", field: "prodOrder" },
      { headerName: "Tarefa", field: "task" },
      { headerName: "Dispositivo", field: "device"},
      { headerName: "Descrição", field: "prodOrdDescription" },
      { headerName: "Cliente", field: "client" }
    ]);

    let allPlanings = new Array<TaskPlan>();
    allPlanings.push(...this.removedPlannings);
    allPlanings.push(...this.newPlannings);

    allPlanings.forEach(tp => {
      tp["ID"] = tp.apsId;
    })

    this.grid.setGridData(allPlanings, null);
  }

  closeDialog(action) {
    this.dialog.close(action);
  }
}
