import { ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { take } from "rxjs/Operators";
import { DialogTemplateComponent } from "../../cmp/dialog-template/dialog-template.component";
import { ColorService } from "../../color.service";
import { T2MessageService } from "../../t2-message.service";
import { Warning } from "../model/warning";
import { WarningService } from "../warning.service";

@Component({
  selector: 'app-warning',
  templateUrl: './warning.component.html',
  styleUrls: ['./warning.component.scss']
})
export class WarningComponent implements OnInit {

  @ViewChild('dialog', { static: true }) dialog: DialogTemplateComponent;

  @Input('warningList')
  get warningList(): Array<Warning> {
    return this._warningList;
  }
  set warningList(value: Array<Warning>) {
    this._warningList = value;
    this.datasetGroup = Array.from(new Set(this.warningList.map(w => w.datasetDescription)));
  }

  private _warningList: Array<Warning>;
  public datasetGroup: Array<string>;
  public todayDate: Date;

  constructor(private warningService: WarningService, private messageService: T2MessageService, public colorService: ColorService) {
    this.todayDate = new Date();
    this.todayDate.setHours(0, 0, 0, 0);
  }

  ngOnInit(): void {

  }

  finishWarning(warning: Warning) {
    this.messageService.showDialog({
      context: {
        topMessage: "ATENÇÃO",
        message: "Deseja marcar esse aviso como concluído ?",
        actions: [{ description: "Sim" }, { description: "Não", status: "basic" }]
      }
    }).onClose
      .pipe(take(1))
      .subscribe(resp => {
        if (resp == "Sim") {
          warning.saving = true;
          this.warningService.finishWarning(warning).pipe(take(1)).subscribe(updatedWarning => {
            warning.completionDate = updatedWarning.completionDate;
            warning.completionUser = updatedWarning.completionUser;
            warning.id_security_identity = updatedWarning.id_security_identity;
            warning.saving = false;
          }, error => {
            warning.saving = false;
          })
        }
      })
  }

  closeDialog() {
    this.dialog.close(null);
  }

}
