import { Component, OnInit, ViewChild } from '@angular/core';
import { take } from "rxjs/Operators";
import { FormTemplateComponent } from "src/app/core/cmp/form-template/form-template.component";
import { T2gridComponent } from "src/app/core/cmp/t2grid/t2grid.component";
import { T2HttpClientService } from "src/app/core/http/t2httpClient.service";
import { T2SecurityService } from "src/app/core/security/t2security.service";
import { VolumeBlockedInfo } from "./volumeBlockedInfo";

@Component({
  selector: 'app-volume-unlock',
  templateUrl: './volume-unlock.component.html',
  styleUrls: ['./volume-unlock.component.scss']
})
export class VolumeUnlockComponent implements OnInit {
  @ViewChild("formTemplate", { static: true }) formTemplate: FormTemplateComponent;
  @ViewChild("gridVolume", { static: true }) gridVolume: T2gridComponent;

  public loaded = false;
  public loadingData: number = 0;

  constructor(private sec: T2SecurityService,
    private httpClient: T2HttpClientService) { }

  ngOnInit(): void {
    this.sec.accessLoaded()
      .pipe(
        take(1)
      ).subscribe(() => {
        if (this.formTemplate.validateAccess(['zD20210125H153407903R000000005'], 'full')) {
          this.setGridColumns();

          this.loaded = true;

          this.loadVolumeData();
        }
      });
  }

  private setGridColumns() {
    this.gridVolume.setGridColumnDefs([
      { checkboxSelection: true, lockPosition: true, pinned: "left", valueGetter: "node.rowIndex + 1", width: 45, suppressNavigable: true },
      { headerName: "NF", field: "nf" },
      { headerName: "Volume", field: "codVolume", width: 100 },
      { headerName: "Origem Bloqueio", field: "origemBloqueio"},
      { headerName: "Qtd entrada", field: "qtdOriginal", type: "numericColumn" },
      { headerName: "Cod Estoque", field: "codEstoque" },
      { headerName: "Endereço", field: "localEstoque" },
      { headerName: "Tp Endereço", field: "tipoEndereco" },
      { headerName: "Dt entrada", field: "dtInclusaoVol", type: ["dateTimeColumn"] },
      { headerName: "Item", field: "item" },
      { headerName: "Lote", field: "lote" },
      { headerName: "NF", field: "nf", enableRowGroup: true },
      { headerName: "Fornecedor", field: "fornecedor" }
    ]);
  }

  private loadVolumeData() {

    this.loadingData++;

    this.httpClient.get("stk.volume/getBlockedVolume", null)
      .pipe(take(1))
      .subscribe(resp => {
        if (!resp.volumeList) {
          this.gridVolume.setGridData(null, null);
          this.gridVolume.autoSizeAllColumns(false);
          return;
        }

        if (!Array.isArray(resp.volumeList.volume)) {
          resp.volumeList.volume = [resp.volumeList.volume];
        }

        const volumeList: Array<VolumeBlockedInfo> = resp.volumeList.volume.map((item: any) => { return { ID: item.id_volume, ...item }; });
        this.gridVolume.setGridData(volumeList, null);
        this.gridVolume.autoSizeAllColumns(false);

        this.loadingData--;
      }, _error => {
        this.loadingData--;
      });

  }

  public desbloquearClick() {
    let volumeIds = [];
    this.gridVolume.getRowsSelected().forEach((volume: VolumeBlockedInfo) => {
      volumeIds.push(volume.id_volume);
    });

    const dados = {
      volumeIds: volumeIds
    }

    this.loadingData++;
    this.httpClient.post("stk.volume/unlockVolumes", null, dados)
      .pipe(take(1))
      .subscribe(_resp => {
        this.loadVolumeData();
        this.loadingData--;
      }, _error => {
        this.loadingData--;
      });
  }

}
