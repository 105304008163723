import { Component, Input } from '@angular/core';
import { ConjugateAssemblyItem } from "../flx-product-specification.service";

@Component({
  selector: 'app-eppconjugate-assembly',
  templateUrl: './eppconjugate-assembly.component.html',
  styleUrls: ['./eppconjugate-assembly.component.scss']
})
export class EPPConjugateAssemblyComponent {
  @Input() t2IdCmp: string;
  @Input()
  public get topMargin(): number {
    return this._topMargin;
  }
  public set topMargin(value: number) {
    this._topMargin = value;
    if (value != undefined) this.changeParams();
  }
  @Input()
  public get bottomMargin(): number {
    return this._bottomMargin;
  }
  public set bottomMargin(value: number) {
    this._bottomMargin = value;
    if (value != undefined) this.changeParams();
  }
  @Input()
  public get rightMargin(): number {
    return this._rightMargin;
  }
  public set rightMargin(value: number) {
    this._rightMargin = value;
    if (value != undefined) this.changeParams();
  }
  @Input()
  public get leftMargin(): number {
    return this._leftMargin;
  }
  public set leftMargin(value: number) {
    this._leftMargin = value;
    if (value != undefined) this.changeParams();
  }
  @Input()
  public get items(): Array<ConjugateAssemblyItem> {
    return this._items;
  }
  public set items(value: Array<ConjugateAssemblyItem>) {
    this._items = value;
    if (value != undefined) this.changeParams();
  }
  @Input() useWidth100: boolean = false;

  private _topMargin: number;
  private _bottomMargin: number;
  private _rightMargin: number;
  private _leftMargin: number;
  private _items: Array<ConjugateAssemblyItem>;

  private timer;
  public assemblyObj: object;

  constructor() {

  }

  private changeParams() {
    if (this.timer) {
      clearTimeout(this.timer);
    }

    this.timer = setTimeout(() => {
      this.generateAssemblyObject();
    }, 1000);
  }

  public generateAssemblyObject() {
    this.assemblyObj = {
      montagem: {
        margin: {
          top: this.topMargin,
          right: this.rightMargin,
          bottom: this.bottomMargin,
          left: this.leftMargin
        },
        sequences: [...new Set(this.items.map(it => it.printSequence))].map(seq => {
          return {
            sequence: seq,
            name: `Impressão ${seq}`
          }
        }),
        elements: this.items.map(item => {
          let rotation = item.rotation ? new Number(item.rotation.replace(/[^0-9]/g, "")) : 0;
          return {
            sequence: item.printSequence,
            name: item.description,
            width: item.width,
            height: item.height,
            orientation: rotation,
            tracks: item.trackQty,
            repetition: item.verticalQty,
            blood: { vertical: item.bloodlettingBtwLines, horizontal: item.bloodlettingBtwTracks }
          }
        })
      }
    }
  }
}


