import { Component, EventEmitter, Inject, Input, LOCALE_ID, OnDestroy, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from "@angular/forms";
import { Subject } from "rxjs";
import { DeviceOperationInput } from "../../model/device-operation-input";
import { ReturnableVolume, DeviceTaskPlan, ShopFloorService } from "../../shop-floor.service";
import { DeviceRegistrationInputType } from "../model/device-registration-input-type";
import { DeviceRegistrationInputTypeVolume } from "../model/device-registration-input-type-volume";
import { take, takeUntil } from "rxjs/Operators";
import { T2MessageService } from "src/app/core/t2-message.service";
import { T2UserDeviceService } from "src/app/t2-user-device.service";
import { T2InputFloatComponent } from "src/app/core/cmp/ui/t2-input-float/t2-input-float.component";
import { T2SelectComponent } from "src/app/core/cmp/ui/t2-select/t2-select.component";
import { DecimalPipe } from "@angular/common";

@Component({
  selector: 'app-appointment-register-consumption-reverse',
  templateUrl: './appointment-register-consumption-reverse.component.html',
  styleUrls: ['./appointment-register-consumption-reverse.component.scss']
})
export class AppointmentRegisterConsumptionReverseComponent implements OnDestroy {

  @ViewChild('localSelect') localSelect: T2SelectComponent;
  @ViewChild('qtyInput') qtyInput: T2InputFloatComponent;

  @Input()
  get taskPlan(): DeviceTaskPlan {
    return this._taskPlan
  }
  set taskPlan(value: DeviceTaskPlan) {
    this._taskPlan = value;
    this.loadInfo();
  }

  @Input()
  get inputList(): Array<DeviceOperationInput> {
    return this._inputList;
  }
  set inputList(value: Array<DeviceOperationInput>) {
    this._inputList = value;
    this.loadInfo();
  }

  @Output() lockScreen = new EventEmitter<boolean>();

  private _inputList: Array<DeviceOperationInput>;
  private _taskPlan: DeviceTaskPlan;
  private unsubscribe = new Subject<void>();
  private unsubscribeAutoInput = new Subject<void>();
  private consumptionInput: DeviceOperationInput;
  private inputVol = new DeviceRegistrationInputTypeVolume();
  public loaded: boolean = false;
  public formGroup: FormGroup;
  public volumeList: Array<ReturnableVolume>;
  public devRegInput = new DeviceRegistrationInputType();
  public itemDescription: string;

  constructor(public userDevService: T2UserDeviceService, private formBuilder: FormBuilder, private sfService: ShopFloorService,
    @Inject(LOCALE_ID) public locale: string, private messageService: T2MessageService) {
    this.formGroup = this.formBuilder.group({});
    this.formGroup.addControl("volume", this.formBuilder.control(undefined));
    this.formGroup.addControl("local", this.formBuilder.control(undefined));
    this.formGroup.addControl("quantidade", this.formBuilder.control(0));

    this.devRegInput.volumes = [];
    this.devRegInput.volumes.push(this.inputVol);

    this.formGroup.controls["local"].valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe(volId => {
      if (!volId) {
        return;
      }

      let selectedVol = this.volumeList.find(vol => vol.volumeId == volId);
      let cmpCons = this.taskPlan.cmpConsList.find(cmp => cmp.itemId == selectedVol.itemId);

      this.itemDescription = cmpCons.descriptionCmp;

      this.devRegInput.id_component = cmpCons.prodOrderCmpId;
      this.devRegInput.id_operationGroup_it = this.consumptionInput.id_operationGroup_it;
      this.devRegInput.id_operationGroup_op_it = this.consumptionInput.id_operationGroup_op_it;
      this.devRegInput.id_item = cmpCons.itemId;

      this.inputVol.id_volume = volId;
      this.inputVol.id_stockAddress_destination = this.consumptionInput.id_operation_stockAddress_destination;
      this.inputVol.id_item_produto_lote = selectedVol.batchItemId;
      this.inputVol.volumeCode = selectedVol.code;
    });

    this.formGroup.controls["quantidade"].valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe(qty => {
      this.devRegInput.value = qty;
      this.inputVol.quantity = qty;
    })
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
    this.unsubscribeAutoInput.next();
    this.unsubscribeAutoInput.complete();
  }

  private loadInfo() {
    if (this.taskPlan && this.inputList) {
      this.consumptionInput = this.inputList.find(inp => inp.functionality == "ConsumptionReturn");
      this.unsubscribeAutoInput.next();

      if (this.consumptionInput.autoInputId) {
        this.sfService.getAutoDataObservable(this.consumptionInput.autoInputId).pipe(takeUntil(this.unsubscribeAutoInput)).subscribe(value => {
          this.formGroup.controls["quantidade"].setValue(Number(Number(value).toFixed(this.consumptionInput.decimals)));
        })
      }

      this.sfService.getReturnableVolumes(this.taskPlan.taskPlanId).pipe(take(1)).subscribe(volList => {
        this.volumeList = volList;
        this.loaded = true;

        setTimeout(() => {
          this.qtyInput.decimalQty = this.consumptionInput.decimals;
        }, 100);
      }, error => {
        this.loaded = true;
      })
    }
  }

  public searchVolume() {
    this.formGroup.controls["local"].setValue(undefined);
    this.localSelect.list = [];
    let volumeCode = this.formGroup.controls["volume"].value;

    if (!volumeCode) {
      this.messageService.showToast("Informe um código de volume para realizar a busca", "Aviso", "warning");
      return;
    }

    this.lockScreen.emit(true);
    let volList = this.volumeList.filter(vol => vol.code == volumeCode);

    if (!volList?.length) {
      this.messageService.showToast("Esse volume não foi consumido anteriormente !!", "Aviso", "warning");
    } else {
      let localList = new Array<{ id: string, description: string }>();

      volList.forEach(vol => {
        localList.push({
          id: vol.volumeId,
          description: `${vol.stockCode} - ${new DecimalPipe(this.locale).transform(Math.abs(vol.quantity), "1.1-2", this.locale)}`
        });
      });

      this.localSelect.list = localList;

      // É necessário o delay para que carregue a lista no componente T2Select
      setTimeout(() => {
        this.formGroup.controls["local"].setValue(localList[0].id);
      }, 100);
    }

    this.lockScreen.emit(false);
  }

  selectVolume(volume: ReturnableVolume) {
    this.formGroup.controls["volume"].setValue(volume.code);
    this.searchVolume();
  }
}
