<div class="flexColumn flexGap5" style="overflow: hidden; max-height: 100%;">
  <div class="T2GrayArea flexAlignRowCenter" style="padding: 5px; overflow: hidden;">
    <span id="spanOP" class="defaultTitle">{{taskRequisition?.prodOrderCode + ' - ' +
      taskRequisition?.prodOrderDescription}}</span>
  </div>
  <div class="T2GrayArea flexColumn flexGap10" style="padding: 5px; overflow-y: auto; max-height: 100%;">
    <div class="flexAlignRowSpaceBetweenCenter flexGap5">
      <div class="flexRow flexGap10">
        <span class="defaultSmallTitle">{{taskRequisition?.deviceDescription}}</span>
        <span class="defaultText">{{taskRequisition?.taskDescription}}</span>
      </div>
      <div *ngIf="!userDevService.isSmallScreen">
        <app-t2-button status="primary" size="tiny" value="Reserva" (clicked)="reservation()"></app-t2-button>
      </div>
    </div>
    <div class="flexAlignColumnStart flexGap5">
      <div class="flexAlignColumnCenterStart">
        <span class="defaultTitle">{{taskRequisition?.itemDescription}}</span>

        <div class="flexRow flexGap10">
          <div class="flexColumn">
            <span class="reqQtyTitle">Empenhado</span>
            <span class="reqQtyText">{{taskRequisition?.requestedQty | number: '1.1-2' : locale}} {{taskRequisition?.itemUnit}}</span>
          </div>
          <div class="flexColumn">
            <span class="reqQtyTitle">Transferido</span>
            <span class="reqQtyText">{{taskRequisition?.transferredQty | number: '1.1-2' : locale}} {{taskRequisition?.itemUnit}}</span>
          </div>
        </div>
      </div>
      <!-- Campos para transferencia -->
      <div class="flexAlignColumnStartEnd" style="width: 100%;">
        <div style="width: 100%;">
          <app-view-template #viewTemplate [layout]="layout" [FormGroup]="formGroup"></app-view-template>
        </div>
        <div>
          <app-t2-button status="primary" size="tiny" value="Transferir" (clicked)="transferVolume()" [disabled]="formGroup?.invalid"></app-t2-button>
        </div>
      </div>
      <!--Volumes-->
      <div class="T2GrayArea flexColumn" style="padding: 5px; width: 100%;">
        <div>
          <span class="defaultText">Volumes Transferidos</span>
          <app-transfer-volume [volumeList]="taskRequisition?.transferredVolumeList"
            [enableClickSelection]="selectedOperation?.isDevolution" [isTransferredVolume]="true"
            (volumeSelected)="selectVolume($event)"></app-transfer-volume>
        </div>
        <div>
          <span class="defaultText">Volumes no estoque</span>
          <app-transfer-volume [volumeList]="taskRequisition?.availableVolumeList"
            [enableClickSelection]="!selectedOperation?.isDevolution"
            (volumeSelected)="selectVolume($event)"></app-transfer-volume>
        </div>
      </div>
      <!--Observação-->
      <div *ngIf="taskRequisition?.taskNote || taskRequisition?.salesOrderNote">
        <span class="defaultNote">Obs: {{taskRequisition?.taskNote || taskRequisition?.salesOrderNote}}</span>
      </div>
    </div>
  </div>
</div>
