import { Injectable, OnDestroy } from '@angular/core';
import { DeviceRegistration } from "./shop-floor-appointment/model/device-registration";
import { T2HttpClientService } from "src/app/core/http/t2httpClient.service";
import { filter, map, switchMap, take, takeUntil } from "rxjs/Operators";
import { BehaviorSubject, Observable, Subject, of } from "rxjs";
import { DeviceOperation } from "./model/device-operation";
import { DeviceOperationDataType } from "./model/device-operation-data-type";
import { DeviceOperationInput } from "./model/device-operation-input";
import { DeviceOperationData } from "./model/device-operation-data";
import { T2FirebaseService } from "src/app/core/t2-firebase.service";
import { T2SecurityService } from "src/app/core/security/t2security.service";
import { TaskRequisition } from "../separation/t2separation.service";
import { DeviceOperationStopReason } from "./model/device-operation-stop-reason";
import { T2MessageService } from "src/app/core/t2-message.service";
import { T2PrinterService } from "src/app/core/printer/t2-printer.service";
import { ChangeDeviceDialogComponent } from "../../aps/visualization/change-device-dialog/change-device-dialog.component";
import { NbDialogService } from "@nebular/theme";

@Injectable()
export class ShopFloorService implements OnDestroy {

  private deviceOperationList = new Map<string, Array<DeviceOperation>>();
  private appName: string = "companyFirebase";
  private companyId: string;
  private unsubscribe = new Subject<void>();
  private autoDataObservableList = new Array<{ autoDataId: string, observable: Observable<string> }>();

  constructor(private httpClient: T2HttpClientService, private firebaseService: T2FirebaseService, private secService: T2SecurityService,
    private messageService: T2MessageService, private dialogService: NbDialogService, private printerService: T2PrinterService) { }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
    this.unsubscribeAllAutoData();
  }

  setRegistration(registration: DeviceRegistration) {
    return this.httpClient.post(`production.registration/device/${registration.id_device}/register`, null, registration).pipe(take(1), map(resp => {
      if (resp?.id_taskProd_quantity) {
        if (!Array.isArray(resp.id_taskProd_quantity)) {
          resp.id_taskProd_quantity = [resp.id_taskProd_quantity]
        }
      }

      return {
        registrationId: resp?.id_registration,
        taskProdQtyIdList: resp?.id_taskProd_quantity
      }
    }));
  }

  public getSequencing(deviceId: string): Observable<Array<TaskSequenceInfo>> {
    let params = new Map<string, string>();
    params.set("id_dispositivo", deviceId);

    return this.httpClient.get("bsn.production.planning/sequenciamento", params)
      .pipe(take(1), map(resp => {
        if (!resp.devicePlanningList) return [];

        if (!Array.isArray(resp.devicePlanningList.devicePlanning)) {
          resp.devicePlanningList.devicePlanning = [resp.devicePlanningList.devicePlanning];
        }

        const devicePlanningList = resp.devicePlanningList.devicePlanning;

        let taskSequenceList: Array<TaskSequenceInfo> = [];
        devicePlanningList?.forEach((dp, index) => {
          let taskSequence = new TaskSequenceInfo();
          taskSequence.taskPlanId = dp.id_task_plan;
          taskSequence.taskId = dp.id_task;
          taskSequence.position = index + 1;
          taskSequence.prodOrder = dp.op;
          taskSequence.shippingDate = dp.dataEntrega ? new Date(dp.dataEntrega) : null;
          taskSequence.salesOrder = dp.pedVenda;
          taskSequence.planDate = new Date(dp.dataInicio);
          taskSequence.taskDescription = dp.descrTarefa;
          taskSequence.urgent = dp.urgente;
          taskSequence.stockAvailable = dp.temEstoque;
          taskSequence.mpSeparate = dp.mpSeparada;
          taskSequence.percSeparation = dp.percTransf;
          taskSequence.taskStatus = dp.statusTarefa;
          taskSequence.reworkProdOrder = dp.opRetrabalho;
          taskSequence.shippingType = dp.tipoFrete;
          taskSequence.customer = dp.cliente;
          taskSequence.translateX = 0;
          taskSequence.startX = 0;
          taskSequence.currentX = 0;
          taskSequence.hasRequisition = dp.possuiReq;

          taskSequence.tasksList = [];
          if (dp.taskList && !Array.isArray(dp.taskList)) {
            dp.taskList = [dp.taskList];
          }

          dp.taskList?.forEach(t => {
            let task = new Tasks();
            task.currentTask = t.currentTask;
            task.deviceName = t.deviceName;
            task.sortKey = t.sortKey;
            task.taskDescription = t.taskDescription;
            taskSequence.tasksList.push(task);
          });

          taskSequenceList.push(taskSequence);
        });
        return taskSequenceList;
      }));
  }

  public getOperationGroup(deviceId: string): Observable<Array<DeviceOperation>> {

    const subj$ = new BehaviorSubject<Array<DeviceOperation>>([]);

    if (this.deviceOperationList.get(deviceId)) {
      subj$.next(this.deviceOperationList.get(deviceId));
    } else {
      this.httpClient.get(`production.registration/device/${deviceId}/operations`, null)
        .pipe(
          take(1),
          map(resp => {
            if (!resp?.operations?.operation) return [];

            if (!Array.isArray(resp.operations.operation))
              return [resp.operations.operation];

            return resp.operations.operation;
          }),
        ).subscribe((resp: Array<DeviceOperation>) => {

          resp.forEach((op) => {
            // Trata sortkey
            op.sortKey = +op.sortKey;

            // Trata apontamento de quantidades
            const il: any = op.inputList;
            if (il?.input) {
              op.inputList = !Array.isArray(il?.input) ? [il?.input] : il?.input;
            } else {
              op.inputList = undefined;
            }

            // Trata os motivos de parada
            if (op.stopReasonList?.["stopReason"]?.length) {
              let srList = new Array<DeviceOperationStopReason>();
              op.stopReasonList?.["stopReason"].forEach(item => {
                let sr = new DeviceOperationStopReason();
                sr.id_stopreason = item.id_stopreason;
                sr.code = item.code;
                sr.description = item.description;

                srList.push(sr);
              });

              op.stopReasonList = srList;
            }

            // Trata campos/dados do apontamento
            const dl: any = op.dataList;
            if (dl?.data) {
              op.dataList = !Array.isArray(dl?.data) ? [dl?.data] : dl?.data;
            } else {
              op.dataList = undefined;
            }

            op.dataList?.forEach(data => {
              data.dataType = DeviceOperationDataType[data.dataType];

              if ([DeviceOperationDataType.FLOAT, DeviceOperationDataType.INTEGER].includes(data.dataType)) {
                if (data.minValue) {
                  data.minValue = Number(data.minValue).valueOf();
                }

                if (data.maxValue) {
                  data.maxValue = Number(data.maxValue).valueOf();
                }
              }

              if (data.dataType == DeviceOperationDataType.LIST) {
                let itemList = data["itemList"]?.["itemList"];
                if (itemList) {
                  if (!Array.isArray(itemList)) {
                    itemList = [itemList];
                  }

                  data.itemList = itemList;
                }
              }
            })

            // Trata se gera impressao
            op.sendToPrinter = (op.id_report_etq ? op.sendToPrinter : false) || false;

            // Trata encerreamento do planejamento
            const ts: string = op.taskStatus;
            switch (ts) {
              case "Finaliza":
                op.taskStatus = 'FINISH';
                break;

              case "Suspende":
                op.taskStatus = 'SUSPEND';
                break;

              default:
                op.taskStatus = 'NONE';
                break;
            }

            // Trata specialAction
            const sa: string = op.specialAction;
            switch (sa) {
              case "Solicitar matéria-prima":
                op.specialAction = "REQUEST_RAWMATERIAL";
                break;

              case "Informar parâmetros definidos na EP":
                op.specialAction = "PRODUCTION_PARAMS";
                break;

              case "Informar parâmetros de produto definidos na EP":
                op.specialAction = "PRODUCT_PARAMS";
                break;

              case "Inspeção de Produção":
                op.specialAction = "QUALITY_PARAMS";
                break;

              case "Autorizar finalização abaixo do percentual mínimo":
                op.specialAction = "AUTH_LOWPROD";
                break;

              case "Parada sem justificativa":
                op.specialAction = "UNJUSTIFIED_DURATION";
                break;
            }

            if (op.hasDuration && (op.taskProdType == 'SETUP' || op.taskProdType == 'PRODUCTION')) {
              op.filterGroup = 'RUN';
              op.icon = 'log-in';
            } else if (op.hasDuration && op.taskProdType == 'STOPPED') {
              op.filterGroup = 'STOP';
              op.icon = 'pause-circle-outline'
            } else if (!op.hasDuration && op.taskStatus == 'FINISH') {
              op.filterGroup = 'RUN';
              op.icon = 'log-out';
            } else if (!op.hasDuration && op.taskStatus == 'SUSPEND') {
              op.filterGroup = 'RUN';
              op.icon = 'stop-circle';
            } else if (op.inputList) {
              let inputList = new Array<DeviceOperationInput>();
              op.inputList.forEach((inp) => {
                let input = new DeviceOperationInput();

                const func: string = inp.functionality;
                switch (func) {
                  case 'Produção e Liberação':
                    inp.functionality = 'Production';
                    break;

                  case 'Perda':
                    inp.functionality = 'Scrap';
                    break;

                  case 'Consumo':
                    inp.functionality = 'Consumption';
                    break;

                  case 'Devolução':
                    inp.functionality = 'ConsumptionReturn';
                    break;

                  case 'Estorno de Produção':
                    inp.functionality = 'ProductionReturn';
                    break;
                }

                input.functionality = inp.functionality;
                input.id_operationGroup_it = inp.id_operationGroup_it;
                input.id_operationGroup_op_it = inp.id_operationGroup_op_it;
                input.id_operationType = inp.id_operationType;
                input.inputLabel = inp.inputLabel;
                input.inputCode = inp.inputCode;
                input.id_unit = inp.id_unit;
                input.unitSymbol = inp.unitSymbol;
                input.unitDescription = inp.unitDescription;
                input.id_operation_stockAddress_origin = inp.id_operation_stockAddress_origin;
                input.id_operation_stockAddress_destination = inp.id_operation_stockAddress_destination;
                input.operationDevol = inp.operationDevol;
                input.operationType = inp.operationType;
                input.showCurrentQty = inp.showCurrentQty;
                input.showEstimatedQty = inp.showEstimatedQty;
                input.sourceType = inp.sourceType;
                input.decimals = Number(inp["decimals"]).valueOf();
                input.autoInput = inp["autoOrigemColetaAutom"];
                input.autoInputId = inp["id_origemColetaAutom"];
                input.fillConsumptionQty = inp["preencheQtdConsumo"];
                input.allowVolumeTyping = inp["digitarVolume"];
                input.showRequisitionVolumes = inp["exibeVolumesRequisicao"];
                input.suggestConsumptionQty = inp["sugereQtdConsumo"];
                input.fillConsumptionReturnQty = inp["sugerirQtdVolumeEstorno"];
                input.stockOperationOriginValidation = inp["validacaoOrigem"] == "Apenas do local de origem configurado" ? 'Allow only the specified' : 'Allow any Origin';
                input.stockOperationDestinationValidation = inp["validacaoDestino"] == "Apenas para o destino configurado" ? 'Allow only the specified' : 'Allow any Destination';

                inputList.push(input);
              });

              op.inputList = inputList;

              const qttType = op.inputList?.map((i) => i.functionality);
              if (qttType.includes("Production") || qttType.includes("ConsumptionReturn")) {
                op.icon = 'plus-circle-outline';
              } else {
                op.icon = 'minus-circle-outline';
              }

              op.filterGroup = 'QUANTITY';
            } else if (op.dataList || ["QUALITY_PARAMS"].includes(op.specialAction)) {
              op.icon = 'credit-card-outline';
              op.filterGroup = 'INFODATA';
            } else if (op.id_report_etq) {
              op.icon = op.sendToPrinter ? 'printer-outline' : 'file-text-outline';
              op.filterGroup = 'DOC';
            } else if (["REQUEST_RAWMATERIAL", "AUTH_LOWPROD"].includes(op.specialAction)) {
              op.icon = "flash-outline";
              op.filterGroup = 'ACTION';
            } else if (op.id_operationGroup_it_consum || op.id_action) {
              op.icon = "flash-outline";
              op.filterGroup = 'ACTION';
            } else {
              op.filterGroup = 'UNKNOWN';
              op.icon = "question-mark-outline";
            }

            if (op.dataList?.length) {
              op.dataList.sort((a, b) => {
                return (a.sortKey - b.sortKey) || a.propertyLabel.localeCompare(b.propertyLabel);
              })
            }
          });

          resp.sort((a, b) => {
            return (a.sortKey - b.sortKey) || a.description.localeCompare(b.description);
          });

          this.deviceOperationList.set(deviceId, resp);
          subj$.next(resp);
        });
    }

    return subj$.asObservable().pipe(filter(resp => { return resp.length > 0 }), take(1));
  }

  private transformDevTaskPlan(devTaskPlan: DeviceTaskPlan, respTaskPlan) {
    devTaskPlan.deviceId = respTaskPlan.id_device;
    devTaskPlan.productionOrderId = respTaskPlan.id_productionOrder;
    devTaskPlan.productionOrderCode = respTaskPlan.productionOrderCode;
    devTaskPlan.productionOrderDescription = respTaskPlan.productionOrderDescription;
    devTaskPlan.taskId = respTaskPlan.id_task;
    devTaskPlan.taskDescription = respTaskPlan.taskDescription;
    devTaskPlan.taskPlanId = respTaskPlan.id_taskPlan;
    devTaskPlan.taskProdId = respTaskPlan.id_taskProd;
    devTaskPlan.registrationId = respTaskPlan.id_registration;
    devTaskPlan.startDate = respTaskPlan.taskPlanStart ? new Date(respTaskPlan.taskPlanStart) : undefined;
    devTaskPlan.endDate = respTaskPlan.taskPlanEnd ? new Date(respTaskPlan.taskPlanEnd) : undefined;
    devTaskPlan.estimatedStartDate = respTaskPlan.taskPlanEstimatedStart ? new Date(respTaskPlan.taskPlanEstimatedStart) : undefined;
    devTaskPlan.estimatedEndDate = respTaskPlan.taskPlanEstimatedEnd ? new Date(respTaskPlan.taskPlanEstimatedEnd) : undefined;
    devTaskPlan.estimatedQty = Number(respTaskPlan.taskPlanEstimatedQuantity || 0).valueOf();
    devTaskPlan.productivityUnit = respTaskPlan.productivityUnit;
    devTaskPlan.status = respTaskPlan.taskPlanStatus;

    if (respTaskPlan.cmpsCons?.cmpCons && !Array.isArray(respTaskPlan.cmpsCons?.cmpCons)) {
      respTaskPlan.cmpsCons.cmpCons = [respTaskPlan.cmpsCons.cmpCons];
    }

    respTaskPlan.cmpsCons?.cmpCons.forEach(cmp => {
      let cmpCons = new DeviceTaskPlanCmpCons();
      cmpCons.id_ordemprod_tarefa_cmp_cons = cmp.idOrdemprodTarefaCmpCons;
      this.transformDevTaskPlanCmp(cmpCons, cmp);

      devTaskPlan.cmpConsList.push(cmpCons);
    });

    if (respTaskPlan.cmpsProd?.cmpProd && !Array.isArray(respTaskPlan.cmpsProd?.cmpProd)) {
      respTaskPlan.cmpsProd.cmpProd = [respTaskPlan.cmpsProd.cmpProd];
    }

    respTaskPlan.cmpsProd?.cmpProd.forEach(cmp => {
      let cmpProd = new DeviceTaskPlanCmpProd();
      cmpProd.id_ordemprod_tarefa_cmp_prod = cmp.idOrdemprodTarefaCmpProd;
      this.transformDevTaskPlanCmp(cmpProd, cmp);

      devTaskPlan.cmpProdList.push(cmpProd);
    });

    if (respTaskPlan.prodOrderCmpList?.prodOrderCmp && !Array.isArray(respTaskPlan.prodOrderCmpList?.prodOrderCmp)) {
      respTaskPlan.prodOrderCmpList.prodOrderCmp = [respTaskPlan.prodOrderCmpList.prodOrderCmp];
    }

    respTaskPlan.prodOrderCmpList?.prodOrderCmp.forEach(cmp => {
      let prodOrderCmp = new ProdOrderCmp();
      prodOrderCmp.description = cmp.description;
      prodOrderCmp.quantity = Number(cmp.quantity || 0).valueOf();
      prodOrderCmp.unitSymbol = cmp.unitSymbol;

      devTaskPlan.prodOrderCmpList.push(prodOrderCmp);
    })
  }

  public getDeviceTaskPlan(deviceId: string, taskPlanId: string): Observable<DeviceTaskPlan> {
    if (deviceId && taskPlanId) {
      return this.httpClient.get(`production.registration/device/${deviceId}/taskPlan/${taskPlanId}`, null).pipe(take(1), map(resp => {
        let devTaskPlan: DeviceTaskPlan;

        if (resp.taskPlans?.taskPlan) {
          let tp = resp.taskPlans.taskPlan;
          devTaskPlan = new DeviceTaskPlan();
          this.transformDevTaskPlan(devTaskPlan, tp);
        }

        return devTaskPlan;
      }))
    } else {
      return of(undefined);
    }
  }

  private transformDevTaskPlanCmp(devTaskPlanCmp: DeviceTaskPlanCmp, cmp) {
    devTaskPlanCmp.prodOrderCmpId = cmp.idProdOrderCmp;
    devTaskPlanCmp.descriptionCmp = cmp.cmpDescr;
    devTaskPlanCmp.unitId = cmp.id_unit;
    devTaskPlanCmp.unitSymbol = cmp.unitSymbol;
    devTaskPlanCmp.itemId = cmp.idItem;
    devTaskPlanCmp.requestItemId = cmp.id_itemRequest;
    devTaskPlanCmp.requestItemCode = cmp.codeItemRequest;
    devTaskPlanCmp.minWeight = cmp.minWeight;
    devTaskPlanCmp.maxWeight = cmp.maxWeight;
    devTaskPlanCmp.requestedQty = Number(cmp.requestedQtt || 0).valueOf();
    devTaskPlanCmp.consumedQty = Number(cmp.consumedQtt || 0).valueOf();
    devTaskPlanCmp.taskQtyPrev = Number(cmp.taskQtyPrev || 0).valueOf();
    devTaskPlanCmp.taskPlanQtyPrev = Number(cmp.taskPlanQtyPrev || 0).valueOf();
    devTaskPlanCmp.taskPlanQtyActual = Number(cmp.taskPlanQtyActual || 0).valueOf();
    devTaskPlanCmp.tare = Number(cmp.tare || 0).valueOf();

    if (cmp.units?.unit && !Array.isArray(cmp.units?.unit)) {
      cmp.units.unit = [cmp.units.unit];
    }

    cmp.units?.unit.forEach(un => {
      let unit = new DeviceTaskPlanCmpUnit();
      unit.unitId = un.id_unit;
      unit.unitSymbol = un.un_symbol;
      unit.isMain = un.is_main;
      unit.factor = Number(un.factor || 0).valueOf();

      devTaskPlanCmp.unitList.push(unit);
    });
  }

  public getFormParam(taskPlanId: string, deviceId: string, paramType: string): Observable<Array<DeviceOperationData>> {
    let params = new Map<string, string>();
    params.set("id_task_plan", taskPlanId);
    params.set("id_dispositivo", deviceId);
    params.set("tipoParametro", paramType);

    return this.httpClient.get("production.registration/formParam", params).pipe(take(1), map(resp => {
      let dataList: Array<DeviceOperationData> = [];

      let list = resp.operations?.operation?.dataList?.data;
      if (list && !Array.isArray(list)) {
        list = [list];
      }

      list?.forEach(data => {
        data.dataType = DeviceOperationDataType[data.dataType];

        if ([DeviceOperationDataType.FLOAT, DeviceOperationDataType.INTEGER].includes(data.dataType)) {
          if (data.minValue) {
            data.minValue = Number(data.minValue).valueOf();
          }

          if (data.maxValue) {
            data.maxValue = Number(data.maxValue).valueOf();
          }
        }

        if (data.dataType == DeviceOperationDataType.LIST) {
          let itemList = data["itemList"]?.["itemList"];
          if (itemList) {
            if (!Array.isArray(itemList)) {
              itemList = [itemList];
            }

            data.itemList = itemList;
          }
        }
      });

      dataList = list;

      return dataList;
    }));
  }

  public getAvailableVolumesForTask(prodOrderId: string, taskId: string): Observable<Array<VolumeTransferred>> {
    let params = new Map<string, string>();
    params.set("id_ordemProd", prodOrderId);
    params.set("id_task", taskId);

    return this.httpClient.get("bsn.production.prodOrder/getAvailableVolumesForTask", params).pipe(take(1), map(resp => {
      let volumeList: Array<VolumeTransferred> = [];
      if (resp?.volumeList) {
        if (!Array.isArray(resp.volumeList)) {
          resp.volumeList = [resp.volumeList];
        }

        resp.volumeList.forEach(vol => {
          let volume = new VolumeTransferred();
          volume.volumeId = vol.id_volume;
          volume.code = vol.codigo;
          volume.itemId = vol.id_item;
          volume.item = vol.item;
          volume.itemUnit = vol.unidade;
          volume.stockId = vol.id_localestoque_3;
          volume.stockCode = vol.local;
          volume.stockDescr = vol.localDescricao;
          volume.quantity = Number(vol.quantidade || 0).valueOf();
          volume.transferDate = vol.dtTransferencia ? new Date(vol.dtTransferencia) : undefined;
          volume.transferUser = vol.usuarioTransferencia;
          volume.consumedQty = Number(vol.qtdConsumida || 0).valueOf();

          volumeList.push(volume);
        });
      }

      return volumeList;
    }))
  }

  public getVolInfo(volumeCode: string) {
    let params = new Map<string, string>();
    params.set("codigo", volumeCode);

    return this.httpClient.get("stk.volume/volInfo", params).pipe(take(1), map(resp => {
      let volumeList = new Array<VolumeInfo>();
      let volList = resp?.volumeList?.volume;

      if (volList) {
        if (!Array.isArray(volList)) {
          volList = [volList];
        }

        volList.forEach(vol => {
          let volume = new VolumeInfo();
          volume.volumeId = vol.id_volume;
          volume.code = vol.codigo;
          volume.stockId = vol.id_local;
          volume.stockCode = vol.localizacao;
          volume.stockDescr = vol.localizacaoCompleta;
          volume.itemId = vol.itemList.item.id_item;
          volume.item = vol.itemList.item.descricao;
          volume.itemUnit = vol.itemList.item.un;
          volume.quantity = vol.itemList.item.qtdEstoque;

          volumeList.push(volume);
        });
      }

      return volumeList;
    }))
  }

  public getReturnableVolumes(taskPlanId: string): Observable<Array<ReturnableVolume>> {
    let params = new Map<string, string>();
    params.set("id_task_plan", taskPlanId);

    return this.httpClient.get("bsn.production.prodOrder/getReturnableVolumes", params).pipe(take(1), map(resp => {
      let volList = new Array<ReturnableVolume>();

      if (resp?.volumeList) {
        if (!Array.isArray(resp.volumeList)) {
          resp.volumeList = [resp.volumeList];
        }

        resp.volumeList.forEach(cons => {
          let vol = new ReturnableVolume();
          vol.volumeId = cons.id_volume;
          vol.code = cons.codigo;
          vol.itemId = cons.id_item;
          vol.item = cons.item;
          vol.itemUnit = cons.unidade;
          vol.quantity = cons.quantidade;
          vol.batchItemId = cons.id_item_produto_lote;
          vol.stockCode = cons.local
          vol.stockDescr = cons.localDescricao;
          vol.stockId = cons.id_localestoque_3;

          volList.push(vol);
        })
      }

      return volList;
    }))
  }

  public getProducedVolumes(taskPlanId: string): Observable<Array<VolumeProduced>> {
    return this.httpClient.get(`production.registration/taskPlan/${taskPlanId}/producedVolumesHistory`, null).pipe(take(1), map(resp => {
      let volList = new Array<VolumeProduced>() ;
      if (resp?.prodVol) {
        if (!Array.isArray(resp.prodVol)) {
          resp.prodVol = [resp.prodVol];
        }

        resp.prodVol.forEach(vol => {
          let volume = new VolumeProduced();
          volume.volumeId = vol.id_volume;
          volume.code = vol.volume;
          volume.itemId = vol.id_item;
          volume.item = vol.item;
          volume.itemUnit = vol.unidade;
          volume.stockId = vol.id_localEstoque;
          volume.stockCode = vol.local;
          volume.stockDescr = vol.localEstoque;
          volume.quantity = vol.saldo;

          volList.push(volume);
        });
      }

      return volList;
    }))
  }

  private initializeFirebase() {
    return new Promise<void>((resolve, reject) => {
      this.firebaseService.initialize(this.appName).then(() => {
        this.secService.loadCompanyId().then(compId => {
          this.companyId = compId;
          this.firebaseService.signIn(this.appName, this.companyId).then(() => {
            resolve();
          }, error => reject(error))
        }, error => reject(error));
      }, error => reject(error));
    })


  }

  public getAutoDataObservable(autoDataId: string): Observable<string> {
    let autoObservable = this.autoDataObservableList.find(ad => ad.autoDataId == autoDataId);

    if (autoObservable) {
      return autoObservable.observable;
    }

    if (!this.firebaseService.isInitialized()) {
      let sub = new Subject<string>();
      this.initializeFirebase().then(() => {
        this.getAutoData(autoDataId).pipe(takeUntil(this.unsubscribe)).subscribe(value => {
          sub.next(value);
        });
      });

      let obs = sub.asObservable();
      this.autoDataObservableList.push({ autoDataId: autoDataId, observable: obs })

      return obs;
    } else {
      let obs = this.getAutoData(autoDataId);
      this.autoDataObservableList.push({ autoDataId: autoDataId, observable: obs });

      return obs;
    }
  }

  public unsubscribeAutoData(autoDataId: string) {
    let path = this.httpClient.isHomologEnvironment ? "homologation/" : "production/";
    path += `automData/${autoDataId}`;

    this.firebaseService.unsubscribeFromPath(path);

    let index = this.autoDataObservableList.findIndex(ad => ad.autoDataId == autoDataId);

    if (index > -1) {
      this.autoDataObservableList.splice(index, 1);
    }
  }

  private getAutoData(autoDataId: string): Observable<string> {
    let path = this.httpClient.isHomologEnvironment ? "homologation/" : "production/";
    path += `automData/${autoDataId}`;

    return this.firebaseService.getData(path).pipe(takeUntil(this.unsubscribe), map(resp => {
      return resp?.value
    }))
  }

  public unsubscribeAllAutoData() {
    if (this.autoDataObservableList.length) {
      do {
        this.unsubscribeAutoData(this.autoDataObservableList[0].autoDataId);
      } while (this.autoDataObservableList.length);
    }
  }

  public getTaskRequests(taskId: string): Observable<Array<TaskRequisition>> {
    let params = new Map<string, string>();
    params.set("id_task", taskId);

    return this.httpClient.get("bsn.production.prodOrder/getTaskRequests", params).pipe(take(1), map(resp => {
      let requisitionList = new Array<TaskRequisition>();
      if (resp?.requestList) {
        if (!Array.isArray(resp.requestList)) {
          resp.requestList = [resp.requestList];
        }

        resp.requestList.forEach(pr => {
          let req = new TaskRequisition();
          req.sequence = Number(pr.seqSeparacao || 0).valueOf();
          req.requestId = pr.id_itemRequest;
          req.requestCode = pr.code;
          req.taskId = pr.id_task;
          req.prodOrderId = pr.prodOrderId;
          req.prodOrderCode = pr.prodOrderCode;
          req.prodOrderDescription = pr.prodOrderDescription;
          req.deviceDescription = pr.deviceDescr;
          req.itemId = pr.id_item;
          req.taskDescription = pr.taskDescr;
          req.itemDescription = pr.itemDescr;
          req.itemUnit = pr.itemUnit;
          req.requestedQty = Number(pr.requestedQty).valueOf();
          req.reservedQty = Number(pr.reservedQty || 0).valueOf();
          req.transferredQty = Number(pr.transfQty || 0).valueOf();
          req.consumedQty = Number(pr.consumedQty || 0).valueOf();
          req.requestedDate = pr.dateReq ? new Date(pr.dateReq) : undefined;
          req.requestedUser = pr.userReq;
          req.separationTeamId = pr.separationTeamId;
          req.separationTeam = pr.separationTeam;
          req.prodOrderSeparationTeam = pr.prodOrderSeparationTeam;
          req.planningDate = new Date(pr.planningDate);
          req.taskNote = pr.taskNote;
          req.salesOrderNote = pr.salesOrderNote;
          req.rawMaterialRequested = pr.solicitadaMP;

          requisitionList.push(req);
        });
      }

      return requisitionList;
    }))
  }

  public getFilterOperationColor(filterGroup: string, selected: boolean): string {
    if (!selected) return 'rgba(128, 128, 128, 1)';

    switch (filterGroup) {
      case 'QUANTITY':
        return 'rgba(27, 90, 165, 1)';
      case 'RUN':
        return 'rgba(12, 153, 0, 1)';
      case 'STOP':
        return 'rgba(255, 0, 0, 1)';
      case 'UNKNOWN':
        return '#252525';
      case 'INFODATA':
        return 'rgba(56, 128, 158, 1)';
      case 'ACTION':
        return 'rgba(188, 183, 55, 1)';
      case 'DOC':
        return 'rgba(66, 15, 210, 1)';
      default:
        return '';
    }
  }

  public getOperationColor(op: DeviceOperation): string {
    if (op.hasDuration && op.taskProdType == 'SETUP') {
      return 'var(--T2ColorBGOrange)';
    } else if (op.hasDuration && op.taskProdType == 'PRODUCTION') {
      return 'rgba(12, 153, 0, 1)';
    } else if (op.hasDuration && op.taskProdType == 'STOPPED') {
      return 'rgba(255, 0, 0, 1)';
    } else if (!op.hasDuration && op.taskStatus == 'FINISH') {
      return 'rgba(255, 0, 0, 1)';
    } else if (!op.hasDuration && op.taskStatus == 'SUSPEND') {
      return 'rgba(128, 128, 128, 1)';
    } else if (!op.hasDuration && op.inputList?.length) {
      return 'rgba(27, 90, 165, 1)';
    } else if (!op.hasDuration && !op.inputList?.length && op.taskStatus != 'FINISH' && op.taskStatus != 'SUSPEND' && (op.dataList?.length || ['PRODUCTION_PARAMS', 'PRODUCT_PARAMS', 'QUALITY_PARAMS'].includes(op.specialAction))) {
      return 'rgba(56, 128, 158, 1)';
    } else if (op.filterGroup == 'DOC') {
      return 'rgba(66, 15, 210, 1)';
    } else if (op.filterGroup == 'ACTION') {
      return 'rgba(188, 183, 55, 1)';
    } else if (op.icon == 'question-mark-outline') {
      return '#252525';
    }
  }

  public AuthorizeBelowMinPerc(taskId: string) {
    let params = new Map<string, string>();
    params.set("id_task", taskId);

    return this.httpClient.get("bsn.production.prodOrder/autorizarLiberacao", params).pipe(take(1))
  }

  public getTaskPlanList(deviceId: string, prodOrderCode?: string, taskPlanId?: string, taskId?: string): Observable<Array<DeviceTaskPlan>> {
    let params = new Map<string, string>();
    params.set("id_device", deviceId);

    if (prodOrderCode)
      params.set("prodOrderCode", prodOrderCode);

    if (taskPlanId)
      params.set("id_task_plan", taskPlanId)

    if (taskId)
      params.set("id_task", taskId);

    return this.httpClient.get("production.registration/shopFloor/taskPlanList", params).pipe(take(1), map(resp => {
      let taskPlanList = new Array<DeviceTaskPlan>();

      if (resp?.taskPlans?.taskPlan) {
        if (!Array.isArray(resp.taskPlans.taskPlan)) {
          resp.taskPlans.taskPlan = [resp.taskPlans.taskPlan];
        }

        resp.taskPlans.taskPlan.forEach(tp => {
          let taskPlan = new DeviceTaskPlan();
          this.transformDevTaskPlan(taskPlan, tp);

          taskPlanList.push(taskPlan);
        })
      }

      return taskPlanList;
    }))
  }

  public printLabel(registrationId: string, taskProdQtyId: string) {
    let params = new Map();
    params.set("id_registration", registrationId);
    params.set("id_taskProd_quantity", taskProdQtyId);

    this.printerService.getPrinterList().subscribe((printerArray) => {
      let selectedPrinter = this.printerService.getSelectedLabelPrinter();
      let printerOnline = printerArray.some((obj) => {
        return obj.clientName == selectedPrinter.clientName && obj.printerList.some(p => p == selectedPrinter.printerName)
      });

      if (printerOnline) {
        params.set("printerClient", selectedPrinter.clientName);
        params.set("printerName", selectedPrinter.printerName);
      } else {
        this.messageService.showToastError(
          "A impressora configurada não está online. Verifique se a mesma está ligada"
        );
        return;
      }

      params.set("description", "Impressao Etq");
      params.set("sendToPrinter", true);

      this.httpClient.get("production.registration/operationLabel", params).pipe(take(1)).subscribe(resp => { });
    });

  }

  public swapTaskPlanDevice(taskPlanId: string, currentDeviceId: string): Observable<any> {
    return this.httpClient.get(`production.registration/device/${currentDeviceId}/compatibleDevs/list`, null).pipe(take(1), switchMap(resp => {
      let devList: Array<{ id: string, description: string }> = [];

      if (resp?.compatibleDevList?.compatibleDev) {
        if (!Array.isArray(resp.compatibleDevList.compatibleDev)) {
          resp.compatibleDevList.compatibleDev = [resp.compatibleDevList.compatibleDev];
        }

        devList.push(...resp.compatibleDevList.compatibleDev.map(dev => {
          return {
            id: dev.id_device,
            description: dev.description
          }
        }));
      }

      if (devList.length == 0) {
        this.messageService.showToast("Não há nenhum dispositivo compatível", "Atenção", "warning");
        throw new Error("Não há nenhum dispositivo compatível");
      }

      let dlg = this.dialogService.open(ChangeDeviceDialogComponent, {
        context: {
          itemList: resp.compatibleDevList.compatibleDev.map(dev => {
            return {
              id: dev.id_device,
              description: dev.description
            }
          }),
          closeOnConfirm: true
        }
      });

      return dlg.onClose.pipe(take(1), switchMap(newDevId => {
        if (!newDevId) {
          throw new Error("Nenhum dispositivo foi selecionado");
        }

        let params = new Map<string, string>();
        params.set("id_device_new", newDevId);
        params.set("id_task_plan", taskPlanId);

        return this.httpClient.get("production.registration/device/changeTaskPlanDevice", params).pipe(take(1));
      }))
    }))
  }

  setJustifyOperation(registration: DeviceRegistration, params: Map<string, string>) {
    return this.httpClient.post(`production.registration/justifyOperation`, params, registration).pipe(take(1), map(resp => {
    }, error => {
    }));
  }

  getTaskItemQty(id_task: string, id_item: string): Observable<ProdOrderTaskItemQuantity> {
    let params = new Map<string, string>();
    params.set("id_task", id_task);
    params.set("id_item", id_item);

    return this.httpClient.get("bsn.production.prodOrder/getTaskItemQty", params).pipe(take(1), map(resp => {
      let itemQty: ProdOrderTaskItemQuantity;
      if (resp?.itemQty) {
        itemQty = resp.itemQty;

        itemQty.expectedQty = Number(itemQty.expectedQty || 0).valueOf();
        itemQty.requestedQty = Number(itemQty.requestedQty || 0).valueOf();
        itemQty.consumedQty = Number(itemQty.consumedQty || 0).valueOf();
        itemQty.producedQty = Number(itemQty.producedQty || 0).valueOf();
      }

      return itemQty;
    }))
  }
}


export class TaskSequenceInfo {
  taskPlanId: string;
  taskId: string;
  position: number;
  customer: string;
  prodOrder: string;
  taskDescription: string;
  planDate: Date;
  taskStatus: string;
  prodOrderDescription: string;
  stockAvailable: boolean;
  mpSeparate: boolean;
  percSeparation: number;
  salesOrder?: string;
  shippingDate?: Date;
  urgent: boolean;
  reworkProdOrder?: string;
  shippingType?: string;
  tasksList?: Array<Tasks>;
  gridId: number;
  translateX: number = 0;
  startX: number = 0;
  currentX: number = 0;
  showLeftText: boolean = false;
  showRightText: boolean = false;
  hasRequisition: boolean;
}

export class Tasks {
  taskDescription: string;
  deviceName: string;
  sortKey: number;
  currentTask: boolean;
  gridId: string;
}

export class DeviceTaskPlan {
  deviceId: string;
  productionOrderId: string;
  productionOrderCode: string;
  productionOrderDescription: string;
  taskId: string;
  taskDescription: string;
  taskPlanId: string;
  taskProdId: string;
  registrationId: string;
  estimatedQty: number;
  productivityUnit: string;
  startDate: Date;
  endDate: Date;
  estimatedStartDate: Date;
  estimatedEndDate: Date;
  status: string;
  cmpConsList: Array<DeviceTaskPlanCmpCons> = [];
  cmpProdList: Array<DeviceTaskPlanCmpProd> = [];
  prodOrderCmpList: Array<ProdOrderCmp> = [];
}

export class DeviceTaskPlanCmp {
  prodOrderCmpId: string;
  itemId: string;
  requestItemId: string;
  requestItemCode: string;
  descriptionCmp: string;
  unitId: string;
  unitSymbol: string;
  requestedQty: number;
  consumedQty: number;
  taskQtyPrev: number;
  taskPlanQtyPrev: number;
  taskPlanQtyActual: number;
  minWeight: number;
  maxWeight: number;
  tare: number;
  unitList: Array<DeviceTaskPlanCmpUnit> = [];
}

export class DeviceTaskPlanCmpCons extends DeviceTaskPlanCmp {
  id_ordemprod_tarefa_cmp_cons: string;
}

export class DeviceTaskPlanCmpProd extends DeviceTaskPlanCmp {
  id_ordemprod_tarefa_cmp_prod: string;
}

export class ProdOrderCmp {
  description: string;
  quantity: number;
  unitSymbol: string;
}

export class DeviceTaskPlanCmpUnit {
  unitId: string;
  unitSymbol: string;
  factor: number;
  isMain: boolean;
}

export class VolumeInfo {
  volumeId: string;
  code: string;
  itemId: string;
  item: string;
  itemUnit: string;
  stockId: string;
  stockCode: string;
  stockDescr: string;
  quantity: number;
}

export class VolumeTransferred extends VolumeInfo {
  transferDate: Date;
  transferUser: string;
  consumedQty: number;
}

export class ReturnableVolume extends VolumeInfo {
  batchItemId: string;
}

export class VolumeProduced extends VolumeInfo { }

export class TaskPlanSelection {
  taskPlanId?: string;
  taskId?: string;
  prodOrderCode?: string;
}

export class JustifiableOpertaion {
  id_operationGroup_op: string;
  code: string;
  description: string;
}

export class ProdOrderTaskItemQuantity {
  id_item: string;
  expectedQty: number = 0;
  requestedQty: number = 0;
  consumedQty: number = 0;
  producedQty: number = 0;
}