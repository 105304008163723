import { Component, Input, AfterViewInit, OnDestroy } from '@angular/core';
import { CdkDragEnter, moveItemInArray } from '@angular/cdk/drag-drop';
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/Operators";
import { PropertyType, SelectableDataset, SelectableField, T2VisualizationService, VisualizationField } from "./t2-visualization.service";

@Component({
  selector: 'app-visualization',
  templateUrl: './visualization.component.html',
  styleUrls: ['./visualization.component.scss']
})
export class VisualizationComponent implements AfterViewInit, OnDestroy {

  private _showPinned: boolean = false
  @Input() fromDictionary = false;
  @Input() queryMode = false;
  @Input() id_dataset: string;
  @Input() description: string;
  @Input() selectedFieldList: Array<VisualizationField> = [];
  @Input()
  set showPinned(value) {
    if (value) this._showPinned = value;
  }
  get showPinned() {
    return this._showPinned;
  }

  public datasetList: Array<SelectableDataset> = [];

  private unsubscribe$ = new Subject<void>();

  constructor(private visService: T2VisualizationService) { }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngAfterViewInit(): void {
    this.datasetList = [];
    this.loadFieldList(this.id_dataset, this.description, null);
  }

  private loadFieldList(id: string, description: string, field: SelectableField) {

    const validTypes: PropertyType[] = [];
    if (this.queryMode) {
      validTypes.push("STRING");
      validTypes.push("AUTO_INC_MASKED");
      validTypes.push("EMAIL");

      validTypes.push("MEMO");

      validTypes.push("INT");
      validTypes.push("MONEY");
      validTypes.push("DECIMAL");
      validTypes.push("DURATION");

      validTypes.push("DATE_TIME");
      validTypes.push("TIME");
      validTypes.push("DATE");

      validTypes.push("BOOLEAN");

      validTypes.push("BINARY");
      validTypes.push("UNKNOWN");

      validTypes.push("STATUS");
      validTypes.push("FIXED_COMBO");

      validTypes.push("GUID");
    }

    this.visService.loadFieldList({
      id_dataset: id,
      id_dataset_previous: this.id_dataset,
      description,
      datasetList: this.datasetList,
      field,
      validTypes,
      loadChildrenFields: this.queryMode
    })
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(resp => {
        resp.fieldList.forEach(f => {
          // Tratamento para quando a seleção vier através do componente de agregação
          let selField = this.selectedFieldList?.find(sf => sf.id_dataset_property == f.id_dataset_property && !sf.propertyName);

          if (selField) {
            selField.description = f.description;
            selField.datasetName_aggregation = f.datasetName_aggregation;
            selField.id_dataset_aggregation = f.id_dataset_aggregation;
            selField.inactityControl = f.inactityControl;
            selField.propertyName = f.propertyName;
            selField.propertyType = f.propertyType;
            selField.relationType = f.relationType;
            selField.statusControl = f.statusControl;
            selField.name = f.joinName ? f.joinName + "." : "" + f.propertyName;
          }

          const sf = this.selectedFieldList?.find(sf => {
            const n = field?.id_dataset_aggregation ? ((field.join ? field.joinName ? field.join + "." + field.joinName + "." : field.join + "." : f.join ? f.join + "." : "") + field.propertyName + "." + f.propertyName) : ((f.joinName ? f.joinName + "." : "") + f.propertyName);
            return sf.name == n;
          });

          if (sf)
            f.selected = true;
        });

      });
  }

  private uncheckFields(fieldList: SelectableField[]) {
    fieldList?.forEach(f => {
      f.selected = false;
      if (f.fieldList?.length) {
        this.uncheckFields(f.fieldList);
      }
    });
  }

  fieldSelect(field: SelectableField) {
    if (this.queryMode && this.datasetList?.length) {
      this.uncheckFields(this.datasetList[0].fieldList);
      this.selectedFieldList.length = 0;
    }

    if (field.id_dataset_aggregation) {
      this.datasetList.length = field.level;

      // Marcar o campo agregação como selecionado fará com
      // que, visualmente, o campo fique destacado quando marcado
      this.unmarkSelectedAggreg(this.datasetList[this.datasetList.length - 1].fieldList);
      field.selected = true;

      if (field.fieldList && field.fieldList.length > 0) {
        this.unmarkSelectedAggreg(field.fieldList);
        this.datasetList.push({
          id_dataset: field.id_dataset_aggregation,
          join: (field.join ? field.join + "." : "") + field.propertyName,
          description: field.propertyLabel || field.propertyName,
          fieldList: field.fieldList
        });
      }
      else
        this.loadFieldList(field.id_dataset_aggregation, field.propertyLabel || field.propertyName, field);
    }

    if (!field.id_dataset_aggregation || this.queryMode) {

      const name = this.visService.joinName(field);
      const index = this.selectedFieldList.findIndex(f => f.name == name);
      if (index >= 0) {
        field.selected = false;
        this.selectedFieldList.splice(index, 1);
      } else {
        field.selected = true;
        this.selectedFieldList.push({
          id_dataset: field.id_dataset,
          id_dataset_property: field.id_dataset_property,
          propertyName: field.propertyName,
          datasetName: field.datasetName,
          id_dataset_aggregation: field.id_dataset_aggregation,
          datasetName_aggregation: field.datasetName_aggregation,
          name,
          description: field.description,
          propertyType: field.propertyType,
          inactityControl: field.inactityControl,
          statusControl: field.statusControl,
          pinned: field.pinned
        });
      }
    }
  }

  private unmarkSelectedAggreg(fieldList: SelectableField[]) {
    fieldList.forEach(f => {
      if (f.id_dataset_aggregation)
        f.selected = false;
    });
  }

  dragEntered(event: CdkDragEnter<SelectableField>) {
    const drag = event.item;
    const dropList = event.container;
    const dragIndex: number = +drag.data;
    const dropIndex: number = +dropList.data;

    moveItemInArray(this.selectedFieldList, dragIndex, dropIndex);
  }
}

