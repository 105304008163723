export class Warning {
  id_warning: string;
  description: string;
  id_warningType: string;
  warningType: string;
  type: string;
  color: string;
  startDate?: Date;
  maxDate: Date;
  completionDate: Date;
  id_security_identity: string;
  completionUser: string;
  id_dataset: string;
  datasetDescription;
  id_informationRecord: string;
  warningNote: string;
  policyNote: string;
  denyManualClosure: boolean;
  critical: boolean;
  id_approvalPolicy?: string;
  id_security_identity_target?: string;
  status?: string;
  acceptName?: string;
  declineName?: string;
  saving?: boolean;
}
