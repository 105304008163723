import "./form-group";
import { MainMenuItemComponent } from './core/main-menu/menu-item/main-menu-item.component';
import { SalesModule } from './bsn/sls/sales.module';

import { BrowserModule } from '@angular/platform-browser';
import { NgModule, NO_ERRORS_SCHEMA, LOCALE_ID } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import ptBr from '@angular/common/locales/pt';

import { AgGridModule } from 'ag-grid-angular';
import 'ag-grid-enterprise';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import {
  NbThemeModule, NbLayoutModule, NbDatepickerModule, NbSidebarModule, NbWindowModule, NbDialogModule,
  NbMenuModule, NbTreeGridModule, NbToastrModule, NbTimepickerModule
} from '@nebular/theme';
import { NbEvaIconsModule } from '@nebular/eva-icons';
import { T2NebularModule } from './t2nebular.module';

import { NgxEchartsModule } from 'ngx-echarts';
import * as echarts from 'echarts';

import { T2CoreModule } from './core/t2core.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { ProductionModule } from './bsn/prd/production.module';
import { FlexProductionModule } from './bsn/flx/flex-production.module';
import { StockModule } from "./bsn/stk/stock.module";
import { FlexLayoutModule } from "@angular/flex-layout";
import { IConfig, NgxMaskModule } from "ngx-mask";
import { ApsModule } from './bsn/prd/aps/aps.module';
import { DashboardComponent } from './core/dashboard/dashboard.component';
import { EPPModule } from "./bsn/epp/epp.module";
import { CostModule } from "./bsn/cst/cost.module";

registerLocaleData(ptBr);

export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;

@NgModule({
  declarations: [AppComponent, MainMenuItemComponent, DashboardComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NbThemeModule.forRoot({ name: "default" }),
    NbSidebarModule.forRoot(),
    NbDatepickerModule.forRoot(),
    NbTimepickerModule.forRoot(),
    NbWindowModule.forRoot({ closeOnBackdropClick: false }),
    NbDialogModule.forRoot({}),
    NbMenuModule.forRoot(),
    NbToastrModule.forRoot({ duration: 5000 }),
    NbLayoutModule,
    NbEvaIconsModule,
    NbTreeGridModule,

    NgxEchartsModule.forRoot({ echarts }),

    AgGridModule,

    T2CoreModule,
    ProductionModule,
    FlexProductionModule,
    SalesModule,
    T2NebularModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    StockModule,
    ApsModule,
    NgxMaskModule.forRoot(),
    EPPModule,
    CostModule
  ],
  exports: [],
  providers: [{ provide: LOCALE_ID, useValue: navigator.language }],
  bootstrap: [AppComponent],
  schemas: [NO_ERRORS_SCHEMA], // add this line
})
export class AppModule { }
