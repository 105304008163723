<div style="min-height: 90vh; min-width: 90vw; height: 100%; width: 100%;" [ngClass]="{'inDialog': inDialog}"
  [nbSpinner]="lockScreen" nbSpinnerSize="giant" nbSpinnerStatus="primary">
  <app-form-template #formTemplate [formTitle]="title" [loaded]="loaded" [useFlexLayout]="true" [changeTitle]="false">
    <header>
      <div style="display: flex; justify-content: space-between;">
        <div style="display: flex; justify-content: space-between;">
          <div style="display: flex; gap: 20px">
            <div style="display: flex; gap: 5px; padding: 5px;">
              <app-t2-button t2IdCmp="btnExcluir" status="primary" size="tiny" value="Salvar"
                (clicked)="saveAllInfo()"></app-t2-button>
              <app-t2-button t2IdCmp="btnExcluir" status="danger" size="tiny" value="Excluir"
                (clicked)="deleteProductSpecification()"></app-t2-button>
              <app-t2-button t2IdCmp="btnFechar" status="basic" size="tiny" value="Fechar"
                (clicked)="close()"></app-t2-button>
              <app-t2-button t2IdCmp="btnImprimir" status="basic" size="tiny" value="Imprimir"
                disabled="true"></app-t2-button>
            </div>

            <div style="padding-top: 2px;">
              <app-t2-status #t2Status [t2IdCmp]="datasetPS + '.status'" [formGroup]="formGroup"
                [datasetName]="datasetPS" [informationId]="id" [saveData]="saveData"
                [paramsSaveData]="{cmpReference: this}" (statusChanged)="statusChanged($event)"></app-t2-status>
            </div>

            <div class="processOption" style="min-width: 150px;">
              <app-t2-select t2IdCmp="processOption" [formGroup]="formGroupProcessOption" [list]="procOptList"
                [grouped]="false" [required]="true">
              </app-t2-select>

              <div id="infoActionList">
                <div class="infoAction" (click)="editProcessOption(true)">
                  <nb-icon icon="plus-outline"></nb-icon>
                </div>
                <div class="infoAction" (click)="editProcessOption(false)">
                  <nb-icon icon="edit-outline"></nb-icon>
                </div>
                <div *ngIf="procOptList?.length > 1" class="infoAction" (click)="deleteProcessOption()">
                  <nb-icon icon="trash-2-outline"></nb-icon>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="flexColumn" style="justify-content: center; align-items: end; font-weight: bold;">
          <span>{{productSpecification?.code}}</span>
          <span>{{productSpecification?.description}}</span>
        </div>
      </div>
    </header>

    <content class="flexColumn flexGap5" style="height: 100%;">

      <nb-tabset #tabSet style="height: 100%;" (changeTab)="changeTab($event)">
        <nb-tab tabTitle="Geral"
          style="max-height: calc(100% - 60px) !important; height: calc(100% - 60px) !important;">
          <div *ngIf="loaded" style="height: 100%;">
            <app-t2-auto-form #autoformGeneral [datasetName]="productSpecification.datasetName"
              [informationId]="productSpecification.id_specification" [changeTitle]="false" [hideButtons]="true"
              [formTemplateUseFlexLayout]="false" [hideHeader]="true"></app-t2-auto-form>
          </div>
        </nb-tab>

        <nb-tab *ngFor="let characCmp of cmpList; let i = index" [tabTitle]="characCmp.description"
          style="max-height: calc(100% - 60px) !important; height: calc(100% - 60px) !important;">
          <ng-container #characCmp *ngComponentOutlet="characCmp.cmpType; injector: injectorList[i]">
          </ng-container>
        </nb-tab>

        <nb-tab tabId="outrasInfo" tabTitle="Outras Informações"
          style="max-height: calc(100% - 60px) !important; height: calc(100% - 60px) !important;">
          <div style="display: flex; gap: 10px; height: 100%;">

            <div class="T2GrayArea" style="padding: 8px; width: 270px; ">
              <app-epcharacteristic-navigation [characteristicList]="characInfoList" [id_specification]="id"
                [blockProductCaracChange]="currentStatus?.blockChange" [selectedProcessOption]="selectedProcessOption"
                (selectCharac)="selectCharac($event)" (insertCharac)="insertCharac($event)"
                (deleteCharac)="deleteCharac($event)">
              </app-epcharacteristic-navigation>
            </div>

            <div class="flexColumn" style="gap: 10px; width: calc(100% - 270px);">
              <div style="display: flex; padding-left: 10px;">
                <!-- <app-t2-button id="btnSalvar" status="primary" size="tiny" value="Salvar Todas"
                  [disabled]="!epCharacteristic?.enabledSaveButton()" (clicked)="saveAllCharac()">
                </app-t2-button> -->
              </div>

              <div style="overflow: auto; ">
                <app-epcharacteristic style="width: calc(100% - 270px);" #epCharacteristic
                  [characteristicList]="characInfoList" [blockProductCaracChange]="currentStatus?.blockChange"
                  [selectedCharac]="selectedCharac" [selectedProcessOption]="selectedProcessOption"
                  (reloadCustomCmpCharac)="reloadCustomCmp($event)">
                </app-epcharacteristic>
              </div>
            </div>
          </div>
        </nb-tab>

        <nb-tab tabTitle="Anexos"
          style="max-height: calc(100% - 60px) !important; height: calc(100% - 60px) !important;">
          <app-t2-ds-attachments style="width: 100%; height: 100%;" datasetName="epp_especif"
            [parentId]="id"></app-t2-ds-attachments>
        </nb-tab>
      </nb-tabset>
    </content>

  </app-form-template>
</div>