<app-form-template #formTemplate formTitle="Programação de Cilindros" [loaded]="loaded" [nbSpinner]="lockScreen"
  nbSpinnerSize="giant" nbSpinnerStatus="info" [ngClass]="{'disableClick': lockScreen}">
  <content>
    <nb-tabset style="height: 100%" (changeTab)="onChangeTab($event)">

      <nb-tab tabTitle="Programação" class="flexColumn" style="height: calc(100% - 57px);">

        <!-- 3 Divs em colunas -->

        <div class="flexColumn flexGap5" style="gap: 10px; height: calc(100% - 55px);">

          <!-- Div 1 - Cabeçalho -->

          <div class="flexAlignRowSpaceBetweenCenter">


            <div class="flexColumn">

              <!-- Titulo -->

              <h6 style="margin: 5px 0px 5px 0px;">Programação de Impressão</h6>

              <!-- Legendas -->
              <div class="iconBar" style="display: flex; margin-top: auto; font-size: 9px">
                <div class="cilSmallBox cilGray"></div>
                <div>Sem cilindro</div>

                <div class="cilSmallBox cilOrange"></div>
                <div>Revisar</div>

                <div class="cilSmallBox cilRed"></div>
                <div>Gravar</div>

                <div class="cilSmallBox cilGreen"></div>
                <div>OK</div>
              </div>

            </div>

            <!-- Botões -->

            <div>

              <div class="flexAlignRowEndCenter flexGap5">
                <button style="margin-left: auto" nbButton size="tiny" status="primary"
                  [disabled]="!gridTask.getRowsSelected().length" (click)="setSupplier()">
                  Definir Fornecedor ({{gridTask.getRowsSelected().length}})
                </button>
                <button nbButton size="tiny" status="primary" [disabled]="!gridTask.getRowsSelected().length"
                  (click)="printRevision()">
                  <nb-icon icon="printer-outline"></nb-icon>
                  Revisar ({{ cilRevisar.length }})
                </button>
                <button nbButton size="tiny" status="primary" [disabled]="cilGravar.length === 0 || genEngrave"
                  (click)="printEngrave()">Gravar ({{ cilGravar.length }})
                </button>
                <button nbButton size="tiny" status="primary" [disabled]="!gridTask.getRowsSelected().length"
                  (click)="printProdOrder()">
                  <nb-icon icon="printer-outline"></nb-icon>
                  OP
                </button>
              </div>
              <div style="margin: 0px 0px 0px auto; font-size: 9px; text-align: end;">
                {{ getSelectedProdOrder() }}
              </div>
            </div>

          </div>

          <!-- Div 2 - Primeiro grid -->
          <div style="flex: 0.6">


            <app-t2grid #gridTask id="gridTask" [component]="gridContextComponent" rowSelection="multiple"
              (rowClick)="onGDTaskClick($event)" (selectionChanged)="onGDTaskChanged($event)"
              [components]="taskGridComponents" [loading]="loadingData" [sortable]="true"
              [gridStateName]="'progCilindros'"></app-t2grid>
          </div>

          <!-- Div 3 - Segundo grid -->
          <div style="flex: 0.4">

            <div class="flexAlignRowSpaceBetweenCenter">
              <div class="flexColumn">

                <!-- Titulo -->
                <h6 style="margin: 5px 0px 5px 0px;">Sequencia de Cores</h6>

                <div style="font-size: 70%">
                  <b>{{ gridTask.getFocusedRow()?.prodOrder }}</b> {{ gridTask.getFocusedRow()?.prodOrderTitle }}
                </div>
              </div>


              <!-- Botoes -->
              <div class="flexAlignRowEndCenter flexGap5">
                <button style="margin-left: auto" nbButton size="tiny" status="primary"
                  [disabled]="cylinderList.length === 0 || !gridTask.getFocusedRow()" (click)="openCylEditDialog()">
                  Selecionar cilindros
                </button>
                <button nbButton size="tiny" status="primary"
                  [disabled]="cylinderList.length === 0 || !gridTask.getFocusedRow()" (click)="openSwapDialog()">
                  Troca entre Cores
                </button>
                <button nbButton [ngClass]="{cilOrange: showRevisar}" size="tiny" [disabled]="!showRevisar"
                  (click)="editarCilindroStatus('REVISAR')">Revisar</button>
                <button nbButton [ngClass]="{cilRed: showGravar}" size="tiny" [disabled]="!showGravar"
                  (click)="editarCilindroStatus('GRAVAR')">Gravar</button>
                <button nbButton [ngClass]="{cilRed: showRecromo}" size="tiny" [disabled]="!showRecromo"
                  (click)="editarCilindroStatus('RECROMO')">Recromo</button>
                <button nbButton [ngClass]="{cilGreen: showOK}" size="tiny" [disabled]="!showOK"
                  (click)="editarCilindroStatus('OK')">Cilindro OK</button>
                <button nbButton (click)="addRegisterCylinder()" size="tiny" status="primary"
                  [disabled]="!gridTask.getFocusedRow()">Adicionar Cilindro</button>
              </div>

            </div>

            <!-- Segundo grid -->
            <div style="height: 100%;">
              <app-t2grid #gridColor id="gridColor" rowSelection="multiple"
                (rowDoubleClick)="onGDColorDoubleClick($event)" (selectionChanged)="onGDColorChanged($event)"
                (rowClick)="onGDColorClick($event)" [components]="colorGridComponents"
                [loading]="loadingData"></app-t2grid>
            </div>
          </div>

        </div>

      </nb-tab>

      <nb-tab tabTitle="Gravações solicitadas" style="height: calc(100% - 49px);">
        <div style="height: 100%;">
          <app-cyl-existing-engraving style="height: 100%;"></app-cyl-existing-engraving>
        </div>
      </nb-tab>

      <nb-tab tabTitle="Histórico" style="height: calc(100% - 49px);">
        <div style="height: 100%;">
          <app-cyl-history style="height: 100%;"></app-cyl-history>
        </div>
      </nb-tab>

    </nb-tabset>
  </content>
</app-form-template>
