import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from "@angular/forms";
import { DialogTemplateComponent } from "src/app/core/cmp/dialog-template/dialog-template.component";

@Component({
  selector: 'app-fixed-date-dialog',
  templateUrl: './fixed-date-dialog.component.html',
  styleUrls: ['./fixed-date-dialog.component.scss']
})
export class FixedDateDialogComponent implements OnInit {

  @ViewChild('dialog', { static: true }) dialog: DialogTemplateComponent;
  @Output() callBack = new EventEmitter<Date>();

  constructor() { }

  public fixedDate: Date;

  ngOnInit(): void {
  }

  closeDialog(action) {
    this.dialog.close(undefined);
  }

  runCallback() {
    this.callBack.emit(this.fixedDate);
  }
}
