import { T2CompanySite } from './../../../core/security/model/t2CompanySite';
import { T2MessageService } from 'src/app/core/t2-message.service';
import { T2gridComponent } from 'src/app/core/cmp/t2grid/t2grid.component';
import { T2ThemingService } from './../../../core/t2theming.service';
import { Component, Input, OnInit, Output, ViewChild, EventEmitter } from '@angular/core';
import { take } from "rxjs/Operators";
import { T2HttpClientService } from "src/app/core/http/t2httpClient.service";
import { T2SecurityService } from "src/app/core/security/t2security.service";
import { T2PrinterService } from "src/app/core/printer/t2-printer.service";
import { OperationTypeCellRendererComponent } from "./operation-type-cell-renderer/operation-type-cell-renderer.component";

@Component({
  selector: 'app-stock-detail',
  templateUrl: './stock-detail.component.html',
  styleUrls: ['./stock-detail.component.scss']
})
export class StockDetailComponent implements OnInit {

  public themeName: string;
  public greenName: string = 'forestgreen';
  public redName: string = 'orangered';
  public itemDescription: string;
  public itemCode: string;
  public companySiteStock: Array<{ id_companySite: string, code: string, name: string, inAvailAdresses: number, inUnavailAdresses: number }> = [];
  public stockAdresses: Array<{ name: string, code: string; stock: number; avail: boolean, volumes?: Array<{ id_volume: string, code: string, stock: number; operation: string; date: Date; detail?: string }> }> = [];
  public loadingHist: boolean = true;
  public loadingFutureMov: boolean = true;
  public loadingItemInfo: boolean = false;
  public stockQtt = { ocQtt: 0.0, pvQtt: 0.0, reqQtt: 0.0, opQtt: 0.0, balQtt: 0.0 };
  public gridContextComponent = { contextMenu: [] };
  public waitingMessage: string;
  public printing: boolean = false;
  public id_volume_printing: string;

  @ViewChild("gridHist", { static: true }) gridHist: T2gridComponent;
  @ViewChild("gridFutureMov", { static: true }) gridFutureMov: T2gridComponent;


  private _itemId: string = null;
  @Input()
  get itemId(): string { return this._itemId; }
  set itemId(value: string) { this._itemId = value; this.loadItemData(); }

  @Input()
  get id(): string { return this._itemId; }
  set id(value: string) { this._itemId = value; this.loadItemData(); }

  @Input() t2IdCmp: string;

  private loaded: boolean = false;
  @Output() loadedChange = new EventEmitter<{ descr?: string, loaded: boolean }>();

  gridHistComponents = {
    operationTypeCellRenderer: OperationTypeCellRendererComponent,
  };

  constructor(private httpClient: T2HttpClientService, private sec: T2SecurityService,
    theme: T2ThemingService, private printService: T2PrinterService, private messageToast: T2MessageService) {
    theme.getThemeNameObservable().subscribe(name => {
      this.themeName = name;
      if (this.themeName == 'dark') {
        this.greenName = 'lightgreen';
        this.redName = '#ff6363';
      } else {
        this.greenName = 'forestgreen';
        this.redName = 'orangered';
      }
    });
  }

  ngOnInit(): void { }

  private loadItemData() {
    this.loadItemInfo();
    this.loadHistMov();
    this.loaded = true;
    this.loadedChange.emit({ descr: this.itemCode + ' ' + this.itemDescription, loaded: this.loaded });
  }

  tabChange(event) {
    if (event.tabTitle == "Movimentação Futura") {
      setTimeout(() => {
        this.gridFutureMov.autoSizeAllColumns(false);
      }, 2);
    } else if (event.tabTitle == "Histórico de Movimentação") {
      setTimeout(() => {
        this.gridHist.autoSizeAllColumns(false);
      }, 0);
    }
  }

  public loadItemInfo(): void {

    if (!this.itemId) {
      this.itemDescription = null;
      this.itemCode = null;
      this.companySiteStock = [];
      this.stockAdresses = [];
      this.stockQtt = { ...this.stockQtt, ocQtt: 0, pvQtt: 0, reqQtt: 0, opQtt: 0 };

      return;
    }

    this.loadingItemInfo = true;

    const params = new Map<string, string>();
    params.set("id_item", this.itemId);
    this.httpClient.get(`stk.itemx/itemInfo`, params)
      .subscribe((resp: any) => {
        if (resp.description && resp.code) {
          this.itemDescription = resp.description;
          this.itemCode = resp.code;
          this.loadedChange.emit({ descr: this.itemCode + ' ' + this.itemDescription, loaded: this.loaded });
        }
      }, error => {
        throw "Nome do item não encontrado";
      });

    this.httpClient.get(`stock.stockMov/balanceDetail`, params)
      .pipe(take(1))
      .subscribe((resp: any) => {

        if (resp.balance.companySiteListOnly) {
          this.stockAdresses = [];
          this.companySiteStock = this.getCompanySiteList(resp.balance.companySiteList);
          this.loadFutureMovGrid();
          this.loadingItemInfo = false;
          return;
        }

        if (!Array.isArray(resp.balance)) {
          resp.balance = [resp.balance];
        }

        this.companySiteStock = this.getCompanySiteList(resp.balance[0].companySiteList);

        resp.balance.forEach((vd: VolumeDetail) => {
          if (typeof vd.volumeAmount != 'number') {
            vd.volumeAmount = Number(vd.volumeAmount);
          }

          let sa = this.stockAdresses.find(item => item.code == vd.locationCode);
          if (!sa) {
            sa = { name: vd.location, code: vd.locationCode, stock: 0.0, avail: vd.availability, volumes: [] };
            this.stockAdresses.push(sa);
          }

          sa.stock += vd.volumeAmount;

          sa.volumes.push({ id_volume: vd.id_volume, code: vd.volumeCode, stock: vd.volumeAmount, operation: vd.stockOperation, date: vd.stockOperationDate, detail: vd.stockOperationDetail });

          let companySiteObj = this.companySiteStock.find(cs => cs.id_companySite == vd.id_companySite);
          if (!companySiteObj) {
            companySiteObj = { id_companySite: vd.id_companySite, code: vd.companySiteCode, name: vd.companySiteDescription, inAvailAdresses: 0.0, inUnavailAdresses: 0.0 };
            this.companySiteStock.push(companySiteObj);
          }

          companySiteObj.inAvailAdresses += vd.availability ? vd.volumeAmount : 0.0;
          companySiteObj.inUnavailAdresses += vd.availability ? 0.0 : vd.volumeAmount;

          this.stockQtt.balQtt += vd.availability ? vd.volumeAmount : 0.0;
        });

        this.stockAdresses.sort((a, b) => {
          const compareValue = (a.avail ? 0 : 1) - (b.avail ? 0 : 1);
          if (compareValue) return compareValue;

          return a.code.localeCompare(b.code);
        });

        this.loadingItemInfo = false;
        this.loadFutureMovGrid();
      }, error => {
        this.loadingItemInfo = false;
      });


  }

  private loadHistMov() {

    const fixedColumns = [
      { headerName: "Data mov", field: "dtMov", type: ["dateTimeColumn"] },
      { headerName: "Mês mov", field: "mes", enableRowGroup: true, columnGroupShow: 'always' },
      { headerName: "Volume", field: "volume", enableRowGroup: true, columnGroupShow: 'always' },
      { headerName: "Qtd", field: "qty", type: 'numericColumn' },
      { headerName: "Local", field: "local" },
      { headerName: "Usuário", field: "insertUserName" },
      { headerName: "Operação", field: "operation" },
      { headerName: "Tipo Operação", field: "operationType", cellRenderer: "operationTypeCellRenderer" },
      { headerName: "Requisição", field: "requisition" },
      { headerName: "OP", field: "op", enableRowGroup: true, columnGroupShow: 'always' },
      { headerName: "PV", field: "pedVenda", enableRowGroup: true, columnGroupShow: 'always' },
      { headerName: "Dispositivo", field: "dispositivo" },
      { headerName: "OC", field: "codigoPC" },
      { headerName: "Nota fiscal", field: "invoice", enableRowGroup: true, columnGroupShow: 'always' },
      { headerName: "Fornecedor", field: "nomeForn", columnGroupShow: 'always' },
      { headerName: "Lote", field: "batch", enableRowGroup: true, columnGroupShow: 'always' },
      { headerName: "Validade Lote", field: "batchValidityDate", type: ["dateColumn"] },
      { headerName: "Valor médio", field: "averageUnVl", type: 'numericColumn' },
      { headerName: "Valor unitário", field: "movUnVl", type: 'numericColumn' },
      { headerName: "Valor original", field: "origUnVl", type: 'numericColumn' },
      { headerName: "Valor Frete", field: "vlTotalFrete", type: 'numericColumn' },
      { headerName: "% PIS", field: "percPIS", type: 'numericColumn' },
      { headerName: "% COFINS", field: "percCOFINS", type: 'numericColumn' },
      { headerName: "% IPI", field: "percIPI", type: 'numericColumn' },
      { headerName: "% ICMS", field: "percICMS", type: 'numericColumn' },
    ];

    this.gridHist.setGridColumnDefs(fixedColumns);
    this.loadHistMovData();
    this.loadActionGrid();
  }

  private loadHistMovData(): void {

    if (!this.itemId) {
      this.gridHist.setGridData([], null);

      return null;
    }

    this.loadingHist = true;

    const params = new Map<string, string>();
    params.set("id_item", this.itemId);
    this.httpClient.get(`stock.stockMov/stkMovement`, params)
      .pipe(take(1))
      .subscribe((resp: any) => {

        let movtoList: Array<any> = resp.stkMovList;

        if (movtoList && !Array.isArray(movtoList)) {
          movtoList = [movtoList];
        }

        movtoList?.forEach((movto: any) => {

          if (movto) {
            movto.dtMov = new Date(movto.dtMov);

            if (movto.dtMov.getMonth() < 9) {
              movto.mes = movto.dtMov.getFullYear() + "-0" + (movto.dtMov.getMonth() + 1);
            } else {
              movto.mes = movto.dtMov.getFullYear() + "-" + (movto.dtMov.getMonth() + 1);
            }
            if (movto.batchValidityDate) {
              if (new Date(movto.batchValidityDate).getFullYear() > 1900) {
                movto.batchValidityDate = new Date(movto.batchValidityDate)
              }
              else {
                movto.batchValidityDate = null
              }
            }
          }
        });

        this.gridHist.setGridData(movtoList, null);
        this.gridHist.autoSizeAllColumns(false);

        this.loadingHist = false;
      }
        , error => {
          this.loadingHist = false;
        }
      )
  }

  private loadActionGrid() {
    this.gridContextComponent.contextMenu.push({
      name: 'Imprimir etiqueta de volume',
      requiresId: true,
      action: () => { this.getRowSelectedIdVolume() },
    });
  }

  private getRowSelectedIdVolume() {
    let rowData = this.gridHist.getFocusedRow();
    this.printVolumeReport(rowData?.id_volume);
  }

  private loadFutureMovGrid() {
    const fixedColumns = [
      { headerName: "Dt Prevista", field: "movDate", type: ["dateColumn"] },
      { headerName: "Mês mov", field: "mes", enableRowGroup: true, columnGroupShow: 'always' },
      { headerName: "Saldo futuro", field: "futureBalance", type: "numericColumn" },
      { headerName: "Qtd Entrada", field: "inputQty", type: "numericColumn" },
      { headerName: "Qtd Saída", field: "outputQty", type: "numericColumn" },
      { headerName: "Origem", field: "origin", enableRowGroup: true, columnGroupShow: 'always' },
      { headerName: "Código", field: "originCode" },
      { headerName: "Filial", field: "companySite" },
      { headerName: "Status", field: "originStatus" },
      { headerName: "Tarefa", field: "originTask" },
      { headerName: "Conta", field: "account" },
    ];

    this.gridFutureMov.setGridColumnDefs(fixedColumns);
    this.loadFutureMovGridData();

  }

  private loadFutureMovGridData() {
    this.loadingFutureMov = true;
    this.stockQtt = { ...this.stockQtt, ocQtt: 0, pvQtt: 0, reqQtt: 0, opQtt: 0 };

    const params = new Map<string, string>();
    params.set("id_item", this.itemId);
    this.httpClient.get(`stock.stockMov/futureMovement`, params)
      .pipe(take(1))
      .subscribe(async (resp: any) => {

        let futureList: Array<any> = resp.futureMovement;

        if (futureList && !Array.isArray(futureList)) {
          futureList = [futureList];
        }

        let futureBalance = this.stockQtt.balQtt;
        futureList?.forEach((movto: any) => {
          if (movto) {
            movto.movDate = new Date(movto.movDate);
            futureBalance += movto.inputQty - movto.outputQty;
            movto.futureBalance = futureBalance;

            if (movto.movDate.getMonth() < 9) {
              movto.mes = movto.movDate.getFullYear() + "-0" + (movto.movDate.getMonth() + 1);
            } else {
              movto.mes = movto.movDate.getFullYear() + "-" + (movto.movDate.getMonth() + 1);
            }

            if (movto.origin == 'PV') {
              this.stockQtt.pvQtt += new Number(movto.outputQty).valueOf();
            } else if (movto.origin == 'PC') {
              this.stockQtt.ocQtt += new Number(movto.inputQty).valueOf();
            } else if (movto.origin == 'OP') {
              this.stockQtt.reqQtt += new Number(movto.outputQty).valueOf();
            }
          }
        });

        this.gridFutureMov.setGridData(futureList, null);
        this.gridFutureMov.autoSizeAllColumns(false);
        this.loadingFutureMov = false;

      }, error => {
        this.loadingFutureMov = false;
      });
  }

  public printVolumeReport(id_volume) {
    this.id_volume_printing = id_volume;
    const params = new Map<string, string>();
    params.set("id_volume", String(id_volume));

    this.printing = true;
    this.httpClient.get("stk.volume/volumeReport", params)
      .pipe(take(1))
      .subscribe(report => {
        if (!report.canPrintReport) return this.messageToast.showToast("O Volume não tem quantidade para impressão de etiqueta", "", "warning", true, "alert-triangle");
        if (!report.id_reportVolume) return;
        let paramReport = new Map<string, string>();
        if (report.typeOfReport == 'PROD') {
          paramReport.set("id_taskProd_quantity", report.id_taskprod_quantity);
        } else {
          paramReport.set("id_volume", id_volume);
        }
        this.printService.openReport(report.id_reportVolume, paramReport).subscribe(() => this.printing = false,
          error => {
            this.printing = false;
          });
      }, error => {
        this.printing = false;
      });
  }

  public getCompanySiteList(companySiteList: Array<any>): Array<any> {

    let csList: Array<{ id_companySite: string, code: string, name: string, inAvailAdresses: number, inUnavailAdresses: number }> = [];

    if (companySiteList && !Array.isArray(companySiteList)) {
      companySiteList = [companySiteList];
    }

    if (companySiteList) {
      companySiteList.forEach(cs => {
        csList.push({ id_companySite: cs.id_companySite, code: cs.code, name: cs.description, inAvailAdresses: 0.0, inUnavailAdresses: 0.0 });
      });
    }

    return csList;
  }
}

export class VolumeDetail {
  location: string;
  locationCode: string;
  availability: boolean;
  id_companySite: string;
  companySiteCode?: string;
  companySiteDescription: string;
  companySiteList?: string;
  id_volume: string;
  volumeAmount: number;
  volumeCode: string;
  stockOperation?: string;
  stockOperationDate?: Date;
  stockOperationDetail?: string;
}
