<div class="flexColumn" style="gap: 8px; min-width: 250px; margin-right: 5px;">

  <div *ngFor="let cg of characGroupsPrd; let i = index">
    <div class="group">{{cg.groupName}}</div>
    <div class="flexColumn" style="gap: 3px">
      <div *ngFor="let c of cg.characs; let j = index" class="characteristic flexRow"
        [ngClass]="{'characteristicDisabled': c.id_especif_carac == undefined, 'characteristicEnabled': c.id_especif_carac != undefined}"
        (click)="selectCharacteristic(c)">

        <div class="characteristicButtonArea" style="display: flex; justify-content: center; align-items: center;"
          [nbSpinner]="c.loading" nbSpinnerSize="tiny">
          <div id="editButtons" *ngIf="!c.loading && !blockProductCaracChange">

            <div *ngIf="!c.id_especif_carac" class="insertFloatButton"
              (click)="$event.stopPropagation(); insertCharacteristic(c)">
              <nb-icon icon="plus-outline"></nb-icon>
            </div>

            <div *ngIf="c.id_especif_carac && !c.mandatory" class="deleteFloatButton"
              (click)="$event.stopPropagation(); deleteCharacteristic(c)">
              <nb-icon icon="close-outline"></nb-icon>
            </div>
          </div>
        </div>

        <div>{{c.caracDescription}}</div>
      </div>
    </div>
  </div>

  <div *ngFor="let cg of characGroupsPrc; let i = index">
    <div class="group">{{cg.groupName}}</div>
    <div class="flexColumn" style="gap: 3px">
      <div
        *ngFor="let c of cg.characs | filterCharacProcess:selectedProcessOption?.id_especif_componente_prc; let j = index"
        class="characteristic flexRow"
        [ngClass]="{'characteristicDisabled': c.id_especif_carac == undefined, 'characteristicEnabled': c.id_especif_carac != undefined}"
        (click)="selectCharacteristic(c)">

        <div class="characteristicButtonArea" style="display: flex; justify-content: center; align-items: center;"
          [nbSpinner]="c.loading" nbSpinnerSize="tiny">
          <div id="editButtons" *ngIf="!c.loading && !blockProductCaracChange">

            <div *ngIf="!c.id_especif_carac" class="insertFloatButton"
              (click)="$event.stopPropagation(); insertCharacteristic(c)">
              <nb-icon icon="plus-outline"></nb-icon>
            </div>

            <div *ngIf="c.id_especif_carac && !c.mandatory" class="deleteFloatButton"
              (click)="$event.stopPropagation(); deleteCharacteristic(c)">
              <nb-icon icon="close-outline"></nb-icon>
            </div>
          </div>
        </div>

        <div>{{c.caracDescription}}</div>
      </div>
    </div>
  </div>
</div>
