<div class="taskDetail flexColumn flexGap10" style="min-width: 680px;">

  <div style="display: flex; justify-content: space-between; align-items: center;">
    <div style="font-weight: bold; font-size: 130%">{{prodOrdInfo?.prodOrder}} - {{taskDescription}} -
      {{deviceDescription}}
    </div>
    <div *ngIf="taskPlan && afterMax" class="taskStatus" style="background-color: red;">
      Fora do prazo
    </div>
    <div *ngIf="taskPlan && beforeMin" class="taskStatus" style="background-color: blue;">
      Adiantada
    </div>
    <div *ngIf="taskPlan && !beforeMin && !afterMax" class="taskStatus" style="background-color: green;">
      Dentro do prazo
    </div>

  </div>

  <div style="display: flex; gap: 15px">

    <!-- Titulo da OP -->
    <div class="cardItem" [ngClass]="{'cardItemDark': dark, 'cardItemLight': !dark}" [style.flex]="'75%'">
      <div style="font-weight: bold;">{{prodOrdInfo?.prodOrdDescription}}</div>
      <div>{{prodOrdInfo?.client}}</div>
    </div>

    <!-- Demandas da OP -->
    <div class="cardItem" [ngClass]="{'cardItemDark': dark, 'cardItemLight': !dark}"  [style.flex]="'25%'">
      <div style="font-weight: bold;">Demandas</div>
      <div *ngFor="let item of prodOrdInfo?.demandList; let i = index" style="line-height: 0.8rem;">
        <span style="width: 200px;">
          {{item.description}}
        </span>
        -
        <span>
          {{item.date | date: "dd/MM/yy HH'h'"}}
        </span>
      </div>
    </div>
  </div>

  <div style="display: flex; gap: 15px">
    <div class="cardItem" [ngClass]="{'cardItemDark': dark, 'cardItemLight': !dark}" [style.flex]="'50%'">
      <!-- TAREFAS -->
      <div style="font-weight: bold;">Tarefas</div>
      <div *ngFor="let task of prodOrdInfo?.taskList; let i = index" style="display: flex; gap: 5px; padding: 3px;">
        <div [style.flex]="'50%'">
          <span style="font-weight: bold; font-size: 110%;">{{i + 1}}</span>
          <span style="width: 100%; text-overflow: ellipsis; white-space: nowrap;">
            {{task.description}}
          </span>
        </div>
        <div [style.flex]="'50%'" style="line-height: 0.8rem;">
          <div *ngFor="let plan of task.taskPlanList" class="plan"
            [ngClass]="planCssClass(plan.id_task, plan.id_task_plan)">
            <span style="font-weight: bold;"> {{plan.device}} </span>
            <div>
              <nb-icon *ngIf="plan?.fixedDate" style="font-size: 95%;" icon="lock"></nb-icon>
              <span style="text-overflow: ellipsis; white-space: nowrap;">
                {{plan.startDate | date: "dd/MM HH:mm"}}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="cardItem" [ngClass]="{'cardItemDark': dark, 'cardItemLight': !dark}" [style.flex]="'50%'">
      <!-- REQUISICOES -->
      <div style="font-weight: bold;">Requisições</div>
      <div *ngFor="let item of prodOrdInfo?.requisitionList ; let i = index" style="line-height: 0.95rem;">
        <div>
          <span style="font-weight: bold; font-size: 110%; white-space: nowrap;">{{i + 1}}</span>
          <span style="text-overflow: ellipsis; white-space: nowrap;">
            {{item.itemDescription}}
          </span>
        </div>
        <div style="display: flex; font-size: 80%;">
          <div style="width: 80px; text-align: end;" class="itemStock">
            {{item.requestedQty | number: '1.2-2'}}</div>

          <div style="width: 100%; white-space: nowrap;" class="itemStock">
            {{getPlanDevice(item.id_task_plan)}} - {{getPlanDate(item.id_task_plan) | date: "dd/MM HH:mm"}}
          </div>

          <!-- <div style="width: 200px;" class="itemStock"
            [ngClass]="{'itemInStock': item.stockStatus == 'inStock',
                        'itemNotAvailable': item.stockStatus == 'notAvailable',
                        'itemInPurchase': item.stockStatus == 'inPurchase' }">
            {{(item.stockStatus == 'inStock' ? 'Em estoque'
                                             : (item.stockStatus == 'inPurchase' ? 'Em compras'
                                             : 'Sem Estoque')) }}
          </div> -->
        </div>
      </div>
    </div>

  </div>
</div>
