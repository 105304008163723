<div class="flexColumn" style="height: 100%; justify-content: space-between;">
  <div *ngFor="let l of layout; let i = index"
    [ngStyle]="{'padding': '5px',
                'height':  (l.height ? l.height : (i == layout.length-1 ? '100%' : 'auto')),
                'overflow-y': i == layout.length-1 ? 'auto' : 'inherited' }">

    <app-view-layout *ngIf="l.layoutType === 'list' || l.layoutType === 'grid'  || l.layoutType === 'tab'" [layout]="l"
      [FormGroup]="FormGroup" [lastPart]="i == layout.length-1"
      [ngStyle]="{height: (i == layout.length-1) ? '100%' : ''}" (cmpReference)="addCmpReference($event)">
    </app-view-layout>

    <app-view-component *ngIf="l.layoutType === 'component'" [component]="l" [FormGroup]="FormGroup" (cmpReference)="addCmpReference($event)">
    </app-view-component>
  </div>
</div>
