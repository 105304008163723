import { DatePipe } from "@angular/common";
import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NbDialogService } from "@nebular/theme";
import { Observable, Subject } from "rxjs";
import { map, take } from "rxjs/Operators";
import { DialogInputComponent } from "src/app/core/cmp/dialog-input/dialog-input.component";
import { T2DateComponent } from "src/app/core/cmp/ui/t2-date/t2-date.component";
import { LayoutType, ViewTemplateElement } from "src/app/core/cmp/view-template/model/view-template-element";
import { ColorService } from "src/app/core/color.service";
import { T2DatasetService } from "src/app/core/dataset/t2dataset.service";
import { T2HttpClientService } from "src/app/core/http/t2httpClient.service";
import { ExceptionDetail } from "./model/exception-detail";
import { TimeDetail } from "./model/TimeDetail";
import { TimeRange } from "./model/TimeRange";
import { WorkingTimeCalendar } from "./model/working-time-calendar";
import { WorkSchedule } from "./model/WorkSchedule";

@Injectable({
  providedIn: 'root'
})
export class WorkScheduleService {

  constructor(private httpClient: T2HttpClientService, private datasetService: T2DatasetService,
     private dialogService: NbDialogService, private formBuilder: FormBuilder, private colorService: ColorService) { }

  public getWorkScheduleList(): Promise<Array<WorkSchedule>> {
    return new Promise<Array<WorkSchedule>>((resolve, reject) => {
      this.httpClient.get("workingTime/getHorarioTrabalhoList", null)
        .pipe(take(1))
        .subscribe(resp => {
          let wsList = new Array<WorkSchedule>();

          if (resp.horarioTrabalhoList) {
            if (!Array.isArray(resp.horarioTrabalhoList)) {
              resp.horarioTrabalhoList = [resp.horarioTrabalhoList];
            }

            resp.horarioTrabalhoList.forEach(ht => {
              let ws = new WorkSchedule();
              ws.id = ht.id_horarioTrabalho;
              ws.description = ht.descricao;

              wsList.push(ws);
            });
          }

          resolve(wsList);
        }, error => reject(error));
    });
  }

  public getWorkSchedule(id: string): Promise<WorkSchedule> {
    return new Promise<WorkSchedule>((resolve, reject) => {
      let params = new Map<string, string>();
      params.set("id_horarioTrabalho", id);

      this.httpClient.get("workingTime/getHorarioTrabalho", params)
        .pipe(take(1))
        .subscribe(resp => {
          if (resp.horarioTrabalho) {
            let ht = resp.horarioTrabalho;
            let ws = new WorkSchedule();
            ws.id = ht.id_horarioTrabalho;
            ws.description = ht.descricao;
            ws.timeDetailList = new Array<TimeDetail>();
            ws.exceptionList = new Array<ExceptionDetail>();

            this.insertAllDaysOfWeek(ws);

            if (ht.detalheHorario) {
              if (!Array.isArray(ht.detalheHorario)) {
                ht.detalheHorario = [ht.detalheHorario];
              }

              ht.detalheHorario.forEach(det => {
                let td: TimeDetail;
                td = ws.timeDetailList.find(timeDet => timeDet.dayOfWeek == det.dia);

                if (!td) {
                  td = new TimeDetail();
                  td.dayOfWeek = det.dia;
                  td.timeRangeList = new Array<TimeRange>();

                  ws.timeDetailList.push(td);
                }

                let tr = new TimeRange();
                tr.id = det.id_horarioTrabalho_horario;
                tr.workShift = det.turno;
                tr.startTime = new Date(det.horarioInicio);
                tr.startTime.setFullYear(1899, 11, 31);
                tr.endTime = new Date(det.horarioFim);
                tr.endTime.setFullYear(1899, 11, 31);
                tr.id_workShift = det.id_workShift;
                tr.color = det.cor ? this.colorService.getClass(det.cor) : "t2ColorDarkBlue";

                td.timeRangeList.push(tr);
              });
            }

            if (ht.excecaoHorario) {
              if (!Array.isArray(ht.excecaoHorario)) {
                ht.excecaoHorario = [ht.excecaoHorario];
              }

              ht.excecaoHorario.forEach(ex => {
                let exception = new ExceptionDetail();
                exception.id = ex.id_horarioTrabalho_excecao;
                exception.description = ex.descricao;
                exception.startDate = new Date(ex.dataInicio);
                exception.endDate = new Date(ex.dataFim);
                exception.isProductive = ex.produtivo;
                exception.id_workShift = ex.id_workShift;
                exception.workShift = ex.turno;
                exception.isRecurrent = ex.recorrente;

                ws.exceptionList.push(exception);
              });
            }

            resolve(ws);
          } else {
            resolve(null);
          }
        }, error => reject(error));
    });
  }

  private insertAllDaysOfWeek(ws: WorkSchedule) {
    let daysOfWeek = ["Domingo", "Segunda-feira", "Terça-feira", "Quarta-feira", "Quinta-feira", "Sexta-feira", "Sábado"];

    daysOfWeek.forEach(day => {
      if (!ws.timeDetailList.find(td => td.dayOfWeek == day)) {
        ws.timeDetailList.push({ dayOfWeek: day, timeRangeList: [] });
      }
    })
  }

  public duplicateWorkSchedule(id: string, description: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      let properties = new Map();
      properties.set("id_workingTime", id);
      properties.set("description", description);

      this.httpClient.get("workingTime/duplicate", properties)
        .pipe(take(1))
        .subscribe(resp => {
          resolve()
        }, error => reject(error));
    });
  }

  public saveWorkScheduleDescription(ws: WorkSchedule): Promise<WorkSchedule> {
    return new Promise<WorkSchedule>((resolve, reject) => {
      let properties = new Map();
      properties.set("descricao", ws.description);

      this.datasetService.saveSimpleEntity("prd_horarioTrabalho", ws.id, properties)
        .pipe(take(1))
        .subscribe(resp => {
          ws.id = resp.datasetEntityID;
          resolve(ws);
        }, error => reject(error));
    });
  }

  public deleteWorkSchedule(id: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.datasetService.deleteDatasetRecord("prd_horarioTrabalho", id)
        .pipe(take(1))
        .subscribe(resp => resolve(), error => reject(error));
    });
  }

  public saveWorkScheduleTimeDetail(workScheduleId: string, dayOfWeek: string, tr: TimeRange, forceLoadColor?: boolean): Promise<TimeRange> {
    return new Promise<TimeRange>((resolve, reject) => {
      let properties = new Map<string, any>();
      properties.set("id_horarioTrabalho", workScheduleId);
      properties.set("dia", dayOfWeek);
      properties.set("id_workShift", tr.id_workShift);
      properties.set("horarioInicio", new DatePipe("pt-BR").transform(tr.startTime, "yyyy-MM-dd'T'HH:mm:ss.SSS"));
      properties.set("horarioFim", new DatePipe("pt-BR").transform(tr.endTime, "yyyy-MM-dd'T'HH:mm:ss.SSS"));

      this.datasetService.saveSimpleEntity("prd_horarioTrabalho_horario", tr.id, properties)
        .pipe(take(1))
        .subscribe(resp => {
          if (!tr.id || forceLoadColor) {
            tr.id = resp.datasetEntityID;
            this.httpClient.get(`core.dynDataset/datasetEntity/afx_workShift/${tr.id_workShift}/color`, null)
              .pipe(take(1))
              .subscribe(resp => {
                if (resp.propertyValue) {
                  tr.color = this.colorService.getClass(resp.propertyValue.$);
                } else {
                  tr.color = "t2ColorDarkBlue";
                }

                resolve(tr);
              }, error => reject(error));
          } else {
            resolve(tr);
          }
        }, error => reject(error));
    });
  }

  public deleteWorkScheduleTimeDetail(id: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.datasetService.deleteDatasetRecord("prd_horarioTrabalho_horario", id)
        .pipe(take(1))
        .subscribe(resp => resolve(), error => reject(error));
    })
  }

  public saveException(workScheduleId: string, exception: ExceptionDetail): Promise<ExceptionDetail> {
    return new Promise((resolve, reject) => {
      let properties = new Map<string, any>();
      properties.set("id_horarioTrabalho", workScheduleId);
      properties.set("descricao", exception.description);
      properties.set("dataInicio", new DatePipe("pt-BR").transform(exception.startDate, "yyyy-MM-dd'T'HH:mm:ss.SSS"));
      properties.set("dataFim", new DatePipe("pt-BR").transform(exception.endDate, "yyyy-MM-dd'T'HH:mm:ss.SSS"));
      properties.set("produtivo", exception.isProductive);
      properties.set("recorrente", exception.isRecurrent);
      properties.set("id_workShift", exception.id_workShift);

      this.datasetService.saveSimpleEntity("prd_horarioTrabalho_excecao", exception.id, properties)
        .pipe(take(1))
        .subscribe(resp => {
          exception.id = resp.datasetEntityID;

          resolve(exception);
        }, error => reject(error));
    });
  }

  public deleteWorkScheduleException(id: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.datasetService.deleteDatasetRecord("prd_horarioTrabalho_excecao", id)
        .pipe(take(1))
        .subscribe(resp => resolve(), error => reject(error));
    })
  }

  public recreateCalendar(id_horarioTrabalho: string): Observable<any> {

    const subRWT$ = new Subject<any>();

    let layout: Array<ViewTemplateElement> = [
      {
        layoutType: LayoutType.listLayout,
        direction: "row",
        children: [
          {
            layoutType: LayoutType.component,
            cmpType: T2DateComponent,
            cmpName: "dataInicio",
            title: "Data Início",
            isBaseComponent: true
          },
          {
            layoutType: LayoutType.component,
            cmpType: T2DateComponent,
            cmpName: "dataFim",
            title: "Data Fim",
            isBaseComponent: true
          }
        ]
      }
    ];

    let formGroup: FormGroup = this.formBuilder.group({});
    let dtInicio = new Date();
    dtInicio.setDate(1);
    dtInicio.setHours(0, 0, 0, 0);

    let dtFim = new Date(dtInicio);
    dtFim.setMonth(dtFim.getMonth() + 11);
    dtFim = new Date(dtFim.getFullYear(), dtFim.getMonth() + 1, 0, 23, 59, 59, 0);

    formGroup.addControl("dataInicio", this.formBuilder.control(dtInicio, Validators.required));
    formGroup.addControl("dataFim", this.formBuilder.control(dtFim, Validators.required));

    this.dialogService.open(DialogInputComponent, {
      context: {
        FormGroup: formGroup,
        layout: layout,
        title: "Recriar Calendário"
      }
    }).onClose.pipe(take(1)).subscribe(resp => {
      if (resp == "Confirma") {
        let params = new Map<string, string>();
        params.set("id_horarioTrabalho", id_horarioTrabalho);
        params.set("dataInicio", (formGroup.get("dataInicio").value as Date).toISOString());
        params.set("dataFim", (formGroup.get("dataFim").value as Date).toISOString());

        this.httpClient.get("workingTime/recreate", params)
          .pipe(take(1))
          .subscribe(resp1 => {
            subRWT$.next(resp1);
            subRWT$.complete();
          }, error => {
            subRWT$.error(error);
            subRWT$.complete();
          });
      } else {
        subRWT$.next({});
        subRWT$.complete();
      }
    });

    return subRWT$.asObservable();
  }

  public getWorkingTimeIntervals(id_workingTime: string, startDate: Date, endDate: Date): Observable<Array<WorkingTimeCalendar>> {
    const params = new Map<string, string>();
    params.set("id_workingTime", id_workingTime);
    params.set("startDate", startDate.toISOString());
    params.set("endDate", endDate.toISOString());

    return this.httpClient.get("workingTime/intervalList", params)
      .pipe(
        take(1),
        map(resp => {
          let wtcList: Array<WorkingTimeCalendar>;
          if (resp.intervalList) {
            wtcList = [];

            if (!Array.isArray(resp.intervalList)) {
              resp.intervalList = [resp.intervalList];
            }

            resp.intervalList.forEach(item => {
              let wtc = new WorkingTimeCalendar();
              wtc.startDate = new Date(item.startDate);
              wtc.endDate = new Date(item.endDate);
              wtc.description = item.description;
              wtc.productive = item.productive;
              wtc.color = this.colorService.getClass(item.color);

              wtcList.push(wtc);
            });
          }

          return wtcList;
        })
      )
  }
}
