import { Component, ElementRef, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { ShopFloorService, TaskPlanSelection, TaskSequenceInfo } from "../../shop-floor.service";
import { T2FirebaseService } from "src/app/core/t2-firebase.service";
import { take } from "rxjs/Operators";

@Component({
  selector: 'app-sequencing-cards',
  templateUrl: './sequencing-cards.component.html',
  styleUrls: ['./sequencing-cards.component.scss'],
  providers: [ShopFloorService, T2FirebaseService]
})
export class SequencingCardsComponent {
  @Input()
  set taskSequenceList(value: Array<TaskSequenceInfo>) {
    this._taskSequenceList = value;
  }
  get taskSequenceList() {
    return this._taskSequenceList;
  }

  @Input() loading = false;
  @Input() deviceId: string;
  @Output() taskPlanSelected = new EventEmitter<TaskPlanSelection>();
  @Output() swapDevice = new EventEmitter<TaskSequenceInfo>();

  private _taskSequenceList: Array<TaskSequenceInfo>;
  private maxDrag: number = 130;

  constructor(private elementRef: ElementRef, private sfService: ShopFloorService) { }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event): void {
    const sliderWidth = this.elementRef.nativeElement.querySelector('.slider').getBoundingClientRect().width;
    this.maxDrag = sliderWidth * 0.5;
  }

  public selectTaskPlan(taskSeq: TaskSequenceInfo) {
    this.taskPlanSelected.emit({ taskPlanId: taskSeq.taskPlanId, taskId: taskSeq.taskId, prodOrderCode: taskSeq.prodOrder });
  }

  onTouchStart(event: TouchEvent, index: number): void {
    this.taskSequenceList[index].startX = event.touches[0].clientX;
    this.taskSequenceList[index].currentX = this.taskSequenceList[index].translateX;
  }

  onTouchMove(event: TouchEvent, index: number): void {
    const moveX = event.touches[0].clientX - this.taskSequenceList[index].startX;
    const newTranslateX = this.taskSequenceList[index].currentX + moveX;

    // Limitando o arrasto para a esquerda
    if (newTranslateX < -this.maxDrag) {
      this.taskSequenceList[index].translateX = -this.maxDrag;
    }
    // Limitando o arrasto para a direita
    else if (newTranslateX > this.maxDrag) {
      this.taskSequenceList[index].translateX = this.maxDrag;
    }
    // Permitindo o arrasto dentro do limite máximo
    else {
      this.taskSequenceList[index].translateX = newTranslateX;
    }
  }

  onTouchEnd(event: TouchEvent, index: number): void {
    if (Math.abs(this.taskSequenceList[index].translateX) == this.maxDrag) {
      if (this.taskSequenceList[index].translateX > 0) {
        this.selectTaskPlan(this.taskSequenceList[index])
      } else {
        this.swapTaskPlanDevice(this.taskSequenceList[index]);
      }
    }

    this.taskSequenceList[index].translateX = 0;
  }

  swapTaskPlanDevice(ts: TaskSequenceInfo) {
    this.swapDevice.emit(ts);
    // this.loading = true;

    // this.sfService.swapTaskPlanDevice(ts.taskPlanId, this.deviceId).pipe(take(1)).subscribe(() => {
    //   let index = this.taskSequenceList.findIndex(t => t.taskPlanId == ts.taskPlanId);

    //   if (index > -1) {
    //     this.taskSequenceList.splice(index, 1);
    //   }

    //   this.loading = false;
    // }, error => {
    //   this.loading = false;
    // })
  }
}
