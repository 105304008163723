import { Component, EventEmitter, HostListener, Input, OnDestroy, Output } from '@angular/core';
import { DeviceCacheService } from "../device-cache.service";
import { take, takeUntil } from "rxjs/Operators";
import { T2FirebaseService } from "src/app/core/t2-firebase.service";
import { Subject } from "rxjs";
import { ShopFloorService, TaskPlanSelection, TaskSequenceInfo } from "../shop-floor.service";

@Component({
  selector: 'app-shop-floor-sequencing',
  templateUrl: './shop-floor-sequencing.component.html',
  styleUrls: ['./shop-floor-sequencing.component.scss'],
  providers: [DeviceCacheService, T2FirebaseService]
})

export class ShopFloorSequencingComponent implements OnDestroy {

  private _id_device: string;
  @Input()
  set id_device(value: string) {
    if (this._id_device != value) {
      this._id_device = value
      this.getSequencing();
    }
  }
  get id_device() {
    return this._id_device;
  }

  @Output() taskPlanSelected = new EventEmitter<TaskPlanSelection>();

  public taskSequenceList = new Array<TaskSequenceInfo>;
  public filteredTaskSequenceList = new Array<TaskSequenceInfo>;
  public loading = false;
  private unsubscribe = new Subject<void>();
  private unsubFilter = new Subject<void>();
  smallWindow: boolean;

  constructor(private devCacheService: DeviceCacheService, private sfService: ShopFloorService) { }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
    this.unsubFilter.next();
    this.unsubFilter.complete();
  }

  getSequencing() {
    this.loading = true;
    this.sfService.getSequencing(this.id_device)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(resp => {
        this.loading = false;

        if (!resp) return;
        this.taskSequenceList = resp;
        this.filteredTaskSequenceList = this.taskSequenceList;
        this.taskSequenceList.forEach(ts => {
          let taskFilterDescription = null;
          ts.tasksList.forEach(t => {
            taskFilterDescription = taskFilterDescription ? taskFilterDescription + "-> " + t.taskDescription : t.taskDescription;
          });
          ts.gridId = taskFilterDescription;
          ts.tasksList.forEach(t => t.gridId = taskFilterDescription);
          ts["ID"] = ts.taskPlanId;
        });

        this.devCacheService.getSelectedFilterTasks().pipe(takeUntil(this.unsubFilter)).subscribe(ft => {
          if (!ft.length) return;
          this.filterTasks(ft);
        });
      },
        error => {
          this.loading = false;
        });
  }

  filterTasks(filteredList: Array<{ description, checked }>) {
    this.filteredTaskSequenceList = [];
    filteredList.forEach(f => {
      this.filteredTaskSequenceList.push(...this.taskSequenceList.filter(ts => {
        switch (f.description) {
          case "Iniciada":
            return ts.taskStatus == 'Iniciada';
          case "Suspensa":
            return ts.taskStatus == 'Suspensa';
          case "Separada":
            return ts.mpSeparate && ts.taskStatus == 'Não Iniciada';
          case "Não separada":
            return !ts.mpSeparate && ts.stockAvailable && ts.taskStatus == 'Não Iniciada';
          case "Sem estoque":
            return !ts.stockAvailable && ts.taskStatus == 'Não Iniciada';
        }
      }));
    });

    this.filteredTaskSequenceList.sort((a, b) => {
      if (a.position > b.position) {
        return 1;
      } else {
        return -1;
      }
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event): void {
    if (window.innerWidth <= 450) this.smallWindow = true;
    if (window.innerWidth > 450) this.smallWindow = false;
  }

  ngAfterContentInit(): void {
    if (window.innerWidth <= 450) this.smallWindow = true;
    if (window.innerWidth > 450) this.smallWindow = false;
  }

  selectTaskPlan(params: TaskPlanSelection) {
    this.taskPlanSelected.emit(params);
  }

  swapTaskPlanDevice(ts: TaskSequenceInfo) {
    this.sfService.swapTaskPlanDevice(ts.taskPlanId, this.id_device).pipe(take(1)).subscribe(() => {
      let index = this.taskSequenceList.findIndex(t => t.taskPlanId == ts.taskPlanId);

      if (index > -1) {
        this.taskSequenceList.splice(index, 1);
      }

      this.loading = false;
    }, error => {
      this.loading = false;
    })
  }
}
