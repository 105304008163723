<ng-template #simpleDrawing>
  <div class="simpleDrawing">
    <div *ngFor="let f of quickSearchFields; let i = index" style="display: flex; gap: 10px; align-items: center;">
      <app-query-condition-field *ngIf="f.fieldFilter?.quickSearch" [editType]="'OnlyValue'" [item]="f"
        [formGroup]="formGroup" (fieldSelected)="getSelectedField($event)" />

      <div *ngIf="i < quickSearchFields.length -1" [ngClass]="{'conditionLayout': true,
                                                                  'conditionAnd': condition.condition == 'AND',
                                                                  'conditionOr': condition.condition == 'OR'}">
        <div style="font-weight: bold; font-size: 0.8rem;">{{condition.condition == 'AND' ? 'e': 'ou'}}</div>
      </div>

    </div>

    <div style="min-width: 1.6em; min-height: 1.6em;" *ngIf="search"
      [ngClass]="{'searchButton': !loading, 'editFloatButton': !loading}" [nbSpinner]="loading" nbSpinnerSize="small">
      <nb-icon *ngIf="!loading" icon="sync-outline" nbTooltip="Pesquisar" (click)="conditionSelected()" />
    </div>
  </div>
</ng-template>

<div *ngIf="!simpleFieldsDrawing && quickSearchFields?.length; else simpleDrawing" class="conditionBox"
  [ngClass]="{'conditionBoxAND': condition.condition == 'AND', 'conditionBoxOR': condition.condition == 'OR'}">
  <div *ngFor="let fl of condition?.fieldList">
    <app-query-condition-field *ngIf="fl.fieldFilter?.quickSearch" [editType]="'OnlyValue'" [item]="fl"
      [formGroup]="formGroup" />
  </div>

  <div *ngFor="let g of quickSearchFields">
    <div style="display: flex; align-items: center; gap: 5px;">
      <app-quick-search [condition]="g" style="width: 100%;"></app-quick-search>
    </div>
  </div>
</div>
