import { ComponentService } from './../../core/cmp/component.service';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SequenceDashboardComponent } from './analysis/sequence-dashboard/sequence-dashboard.component';
import { T2CoreModule } from '../../core/t2core.module';
import { T2NebularModule } from "src/app/t2nebular.module";
import { OeeComponent } from './analysis/oee/oee.component';
import { NgxEchartsModule } from "ngx-echarts";
import { RegistrationFixComponent } from './mes/registration-fix/registration-fix.component';
import { DialogRegistrationFixComponent } from './mes/registration-fix/dialog-registration-fix/dialog-registration-fix.component';
import { ValueEditorCellComponent } from './mes/registration-fix/value-editor-cell/value-editor-cell.component';
import { ValueRenderCellComponent } from './mes/registration-fix/value-render-cell/value-render-cell.component';
import { ModalDashboardComponent } from './analysis/sequence-dashboard/modal-dashboard/modal-dashboard.component';
import { WorkScheduleComponent } from './ppc/work-schedule/work-schedule.component';
import { CalendarDayComponent } from './ppc/work-schedule/calendar-day/calendar-day.component';
import { EventEditorComponent } from './ppc/work-schedule/event-editor/event-editor.component';
import { ExceptionComponent } from './ppc/work-schedule/exception/exception.component';
import { ManageItemRequestComponent } from './ppc/manage-item-request/manage-item-request.component';
import { FilterParamsComponent } from './ppc/manage-item-request/filter-params/filter-params.component';
import { ClosingProductionOrderComponent } from './analysis/closing-production-order/closing-production-order.component';
import { ManualSeparationComponent } from './mes/separation/manual-separation/manual-separation.component';
import { SeparationComponent } from './mes/separation/separation.component';
import { RequisitionComponent } from './mes/separation/requisition/requisition.component';
import { SeparationTransferComponent } from './mes/separation/separation-transfer/separation-transfer.component';
import { TransferVolumeComponent } from './mes/separation/separation-transfer/transfer-volume/transfer-volume.component';
import { ProductionOrderReleaseComponent } from './ppc/production-order-release/production-order-release.component';
import { CylinderInfoRendererComponent } from './ppc/production-order-release/cylinder-info-renderer/cylinder-info-renderer.component';
import { ColorDetailComponent } from './ppc/production-order-release/color-detail/color-detail.component';
import { ReleaseDetailComponent } from './ppc/production-order-release/release-detail/release-detail.component';
import { FlexProductionModule } from "../flx/flex-production.module";
import { ConjugationComponent } from './ppc/production-order-release/conjugation/conjugation.component';
import { ConjugationActionRendererComponent } from './ppc/production-order-release/conjugation/conjugation-action-renderer/conjugation-action-renderer.component';
import { ShopFloorComponent } from "./mes/shop-floor/shop-floor.component";
import { ShopFloorDashboardComponent } from './mes/shop-floor/shop-floor-dashboard/shop-floor-dashboard.component';
import { SequencingCardsComponent } from "./mes/shop-floor/shop-floor-sequencing/sequencing-cards/sequencing-cards.component";
import { SequencingGridComponent } from "./mes/shop-floor/shop-floor-sequencing/sequencing-grid/sequencing-grid.component";
import { TaskSeparationCellRendererComponent } from "./mes/shop-floor/shop-floor-sequencing/sequencing-grid/task-separation-cell-renderer/task-separation-cell-renderer.component";
import { TaskSeparationStatusComponent } from './mes/shop-floor/shop-floor-sequencing/task-separation-status/task-separation-status.component';
import { TaskUrgentCellRendererComponent } from './utils/task-urgent-cell-renderer/task-urgent-cell-renderer.component';
import { TaskUrgentComponent } from './mes/shop-floor/shop-floor-sequencing/task-urgent/task-urgent.component';
import { ShopFloorSequencingFilterComponent } from "./mes/shop-floor/shop-floor-sequencing/shop-floor-sequencing-filter/shop-floor-sequencing-filter.component";
import { ShopFloorTaskpanelComponent } from './mes/shop-floor/shop-floor-taskpanel/shop-floor-taskpanel.component';
import { ShopFloorAppointmentComponent } from './mes/shop-floor/shop-floor-appointment/shop-floor-appointment.component';
import { ShopFloorTaskHistoryComponent } from './mes/shop-floor/shop-floor-task-history/shop-floor-task-history.component';
import { ShopFloorReprintLabelsComponent } from './mes/shop-floor/shop-floor-reprint-labels/shop-floor-reprint-labels.component';
import { AppointmentOperationsComponent } from './mes/shop-floor/shop-floor-appointment/appointment-operations/appointment-operations.component';
import { AppointmentRegisterQuantityComponent } from './mes/shop-floor/shop-floor-appointment/appointment-register-quantity/appointment-register-quantity.component';
import { AppointmentRegisterConsumptionComponent } from './mes/shop-floor/shop-floor-appointment/appointment-register-consumption/appointment-register-consumption.component';
import { AppointmentRegisterConsumptionReverseComponent } from './mes/shop-floor/shop-floor-appointment/appointment-register-consumption-reverse/appointment-register-consumption-reverse.component';
import { AppointmentRegisterFormdataComponent } from './mes/shop-floor/shop-floor-appointment/appointment-register-formdata/appointment-register-formdata.component';
import { ShopFloorSequencingComponent } from './mes/shop-floor/shop-floor-sequencing/shop-floor-sequencing.component';
import { ShopFloorSingletaskPanelComponent } from './mes/shop-floor/shop-floor-dashboard/shop-floor-singletask-panel/shop-floor-singletask-panel.component';
import { ShopFloorTaskFlowComponent } from './mes/shop-floor/shop-floor-task-flow/shop-floor-task-flow.component';
import { TaskFlowCellRendererComponent } from './mes/shop-floor/shop-floor-sequencing/sequencing-grid/task-flow-cell-renderer/task-flow-cell-renderer.component';
import { AppointmentRegisterProductionReverseComponent } from './mes/shop-floor/shop-floor-appointment/appointment-register-production-reverse/appointment-register-production-reverse.component';
import { AppointmentRegisterRawMaterialRequestComponent } from './mes/shop-floor/shop-floor-appointment/appointment-register-raw-material-request/appointment-register-raw-material-request.component';
import { AppointmentRegisterStopReasonComponent } from './mes/shop-floor/shop-floor-appointment/appointment-register-stop-reason/appointment-register-stop-reason.component';
import { SearchProdOrderComponent } from './mes/shop-floor/shop-floor-appointment/search-prod-order/search-prod-order.component';
import { TaskFloatActionsComponent } from './mes/shop-floor/shop-floor-dashboard/task-float-actions/task-float-actions.component';
import { ConjugatedRendererComponent } from './ppc/production-order-release/conjugated-renderer/conjugated-renderer.component';


@NgModule({
  declarations: [
    SequenceDashboardComponent,
    OeeComponent,
    RegistrationFixComponent,
    DialogRegistrationFixComponent,
    ValueEditorCellComponent,
    ValueRenderCellComponent,
    ModalDashboardComponent,
    WorkScheduleComponent,
    CalendarDayComponent,
    EventEditorComponent,
    ExceptionComponent,
    ManageItemRequestComponent,
    FilterParamsComponent,
    ClosingProductionOrderComponent,
    ManualSeparationComponent,
    SeparationComponent,
    RequisitionComponent,
    SeparationTransferComponent,
    TransferVolumeComponent,
    ProductionOrderReleaseComponent,
    CylinderInfoRendererComponent,
    ColorDetailComponent,
    ReleaseDetailComponent,
    ConjugationComponent,
    ConjugationActionRendererComponent,
    ShopFloorComponent,
    SequencingCardsComponent,
    SequencingGridComponent,
    ShopFloorDashboardComponent,
    TaskSeparationCellRendererComponent,
    TaskSeparationStatusComponent,
    TaskUrgentCellRendererComponent,
    TaskUrgentComponent,
    ShopFloorSequencingFilterComponent,
    ShopFloorTaskpanelComponent,
    ShopFloorAppointmentComponent,
    ShopFloorTaskHistoryComponent,
    ShopFloorSequencingComponent,
    ShopFloorReprintLabelsComponent,
    AppointmentOperationsComponent,
    AppointmentRegisterQuantityComponent,
    AppointmentRegisterConsumptionComponent,
    AppointmentRegisterConsumptionReverseComponent,
    AppointmentRegisterFormdataComponent,
    ShopFloorSequencingFilterComponent,
    ShopFloorSingletaskPanelComponent,
    ShopFloorTaskFlowComponent,
    TaskFlowCellRendererComponent,
    AppointmentRegisterProductionReverseComponent,
    AppointmentRegisterRawMaterialRequestComponent,
    AppointmentRegisterStopReasonComponent,
    SearchProdOrderComponent,
    TaskFloatActionsComponent,
    ConjugatedRendererComponent,
  ],
  imports: [
    CommonModule,

    NgxEchartsModule.forChild(),

    T2NebularModule,
    T2CoreModule,
    FlexProductionModule
  ],

})
export class ProductionModule {

  constructor(private cmpService: ComponentService) {
    cmpService.registerType("ManageItemRequest", ManageItemRequestComponent);
    cmpService.registerType("ProductionOrderReleaseComponent", ProductionOrderReleaseComponent);
  }
}
