<div>
  <button nbButton filled [id]="t2IdCmp" [status]="status || 'basic'" size="tiny"
    [disabled]="disabled? disabled : false" (click)="onClick($event)" class="flexColumn"
    style="gap: 2px; align-items: center;" [ngStyle]="{'justify-content': icon ? 'space-between' : 'center' }"
    [nbSpinner]="loading">
    <div *ngIf="icon" style="font-size: 75%;">
      <nb-icon [icon]="icon? icon : ''"></nb-icon>
    </div>
    <div *ngIf="caption" style="text-transform: none; font-weight: bold; font-size: 80%">
      {{caption? caption : ''}}
    </div>
  </button>
</div>
