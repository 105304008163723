import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from "@angular/forms";
import { take } from "rxjs/Operators";
import { T2ScriptService } from "src/app/core/script/t2-script.service";
import { T2MessageService } from "src/app/core/t2-message.service";
import { T2ViewTemplateAction } from "../model/t2-view-template-action";
import { T2ViewTemplateData } from "../model/t2-view-template-data";
import { T2ViewTemplateFlow } from "../model/t2-view-template-flow";
import { ViewTemplateElement } from "../model/view-template-element";
import { ViewTemplateService } from "../view-template.service";

@Component({
  selector: 'app-view-template-builder',
  templateUrl: './view-template-builder.component.html',
  styleUrls: ['./view-template-builder.component.scss']
})
export class ViewTemplateBuilderComponent implements OnInit, AfterViewInit {

  @Input() id: string;
  @Input() 
  get scriptName(): string {
    return this._scriptName;
  }
  set scriptName(value: string) {
    this._scriptName = value;
    this.loaded = false;
    this.callScript();
  }

  @Input() 
  get params(): {[key: string]: any} {
    return this._params
  }
  set params(value: { [key: string]: any }) {
    this._params = value;

    if (value) {
      this.loaded = false;
      this.callScript();
    }
  }
  @Output() title = new EventEmitter<string>();

  private _params: { [key: string]: any };
  private _scriptName: string;

  public layout:Array<ViewTemplateElement>;
  public data: Array<T2ViewTemplateData>;
  public flows: Array<T2ViewTemplateFlow>;
  public actions: Array<T2ViewTemplateAction>;
  public FormGroup: FormGroup;
  public loaded: boolean = false;
  private timer;

  constructor(private scriptService: T2ScriptService, private viewTemplateService: ViewTemplateService, 
    private messageService: T2MessageService) { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.callScript();
  }

  private callScript() {
    if (this.timer) {
      clearTimeout(this.timer);
    }

    this.timer = setTimeout(() => {
      this.runScript();
    }, 500);
  }

  private runScript() {
    if (this.scriptName) {
      this.scriptService.executeScript(this.scriptName, this.params)
        .pipe(take(1))
        .subscribe(resp => {
          let vtInputs = this.viewTemplateService.generateViewTemplateInputs(JSON.parse(resp));

          this.layout = vtInputs.layout;
          this.data = vtInputs.data;
          this.flows = vtInputs.flows;
          this.actions = vtInputs.actions;
          this.FormGroup = vtInputs.formGroup;

          this.title.emit(vtInputs.title);

          this.loaded = true;
        }, error => {
          this.loaded = false;
          this.messageService.showToastError(error);
          console.error(error);
        });
    }
  }
}
