import { T2ScriptService } from 'src/app/core/script/t2-script.service';
import { Injectable, Type } from '@angular/core';
import { T2AccessItem, T2AccessItemType, T2AccessItemDatasetActionType } from "../security/model/t2accessItem";
import { Router } from "@angular/router";
import { T2MessageService } from "../t2-message.service";
import { T2PrinterService } from "../printer/t2-printer.service";
import { T2HttpClientService } from "../http/t2httpClient.service";
import { NbDialogRef, NbDialogService, NbWindowService } from "@nebular/theme";
import { ComponentService } from "../cmp/component.service";
import { T2AutoFormComponent } from "../form/t2-auto-form/t2-auto-form.component";
import { Observable, of, throwError } from "rxjs";
import { ActionDialogComponent } from "./action-dialog/action-dialog.component";
import { catchError, concatMap, map } from "rxjs/Operators";

@Injectable({
  providedIn: 'root'
})
export class ActionService {

  constructor(
    private router: Router,
    private messageService: T2MessageService,
    private printer: T2PrinterService,
    private httpClient: T2HttpClientService,
    private dialogService: NbDialogService,
    private windowService: NbWindowService,
    private cmpService: ComponentService,
    private scriptService: T2ScriptService) { }

  canExecuteAction(action: T2AccessItem): boolean {

    if (action.type == T2AccessItemType.ACTION_AUTOFORM &&
      [T2AccessItemDatasetActionType.DSACTION_EDIT,
      T2AccessItemDatasetActionType.DSACTION_INSERT].includes(action.datasetActionType)) return true;

    if (action.actionCommand && (action.actionCommand.startsWith("delete:") ||
      action.actionCommand.startsWith("get:") ||
      action.actionCommand.startsWith("post:") ||
      action.actionCommand.startsWith("put:"))) return true;

    if (
      (action.actionCommand?.toLowerCase().startsWith("script")) ||
      (action.actionCommand && action.actionCommand.startsWith("tpw:")) ||
      (action.actionCommand && action.actionCommand.startsWith("tpwd:")) ||
      (action.id_report && action.reportFilterProps === 0)
    ) return true;

    return false;
  }

  executeAction(action: T2AccessItem, params: Map<string, any>, open: 'default' | 'dialog' | 'newWindow' = 'default'): Observable<any> {
    let dlg = this.dialogService.open(ActionDialogComponent, {
      context: {
        actionDescription: action.description
      }, closeOnBackdropClick: false, closeOnEsc: false
    });
    params = params || new Map();

    if (action.type == T2AccessItemType.ACTION_AUTOFORM) {
      if (open == 'dialog') {
        dlg.close();
        return this.openDialogAutoform(action, params).onClose;
      } else {
        this.openURLAutoform(action, params, open == 'newWindow');
        dlg.close();
      }
    } else if (action.actionCommand && (action.actionCommand.startsWith("delete:") ||
      action.actionCommand.startsWith("get:") ||
      action.actionCommand.startsWith("post:") ||
      action.actionCommand.startsWith("put:"))) {
      let url = this.fillActionCommand(action.actionCommand, params),
        httpMethod = action.actionCommand.substring(0, action.actionCommand.indexOf(":"));

      switch (httpMethod) {
        case "get": return this.httpClient.get(url, null).pipe(map(resp => {
          dlg.componentRef.instance.actionResponseMsg = "Ação executada com sucesso";
          dlg.componentRef.instance.showCloseButton = true;
          return resp;
        }), catchError(error => {
          dlg.componentRef.instance.isError = true;
          dlg.componentRef.instance.showCloseButton = true;
          dlg.componentRef.instance.actionResponseMsg = error;
          return throwError(error);
        }));
        case "delete": return this.httpClient.delete(url, null).pipe(map(resp => {
          dlg.componentRef.instance.actionResponseMsg = "Ação executada com sucesso";
          dlg.componentRef.instance.showCloseButton = true;
          return resp;
        }), catchError(error => {
          dlg.componentRef.instance.isError = true;
          dlg.componentRef.instance.showCloseButton = true;
          dlg.componentRef.instance.actionResponseMsg = error;
          return throwError(error);
        }));;
        default: this.notImplementedCall(action, params);
      }
    } else if (action.actionCommand?.toLowerCase().startsWith("script")) {

      let scriptName: string = null;
      let scriptParams: Object = null;
      let script = this.fillActionCommand(action.actionCommand, params);

      const sepPos = script.indexOf("?");
      if (sepPos < 0) {
        scriptName = script;
      } else {
        scriptName = script.substring(0, sepPos);

        // Os parametros devem ser enviados como o JSON
        scriptParams = {};
        script.substring(sepPos + 1).split("&").forEach(param => {
          const io = param.indexOf("=");
          scriptParams[param.substring(0, io).trim()] = param.substring(io + 1).trim();
        });
      }

      return this.scriptService.executeScript(scriptName, scriptParams).pipe(concatMap(resp => {
        dlg.componentRef.instance.actionResponseMsg = resp || "Ação executada com sucesso";
        dlg.componentRef.instance.showCloseButton = true;

        return of(resp);
      }),
        catchError((error, obs) => {
          dlg.componentRef.instance.isError = true;
          dlg.componentRef.instance.showCloseButton = true;
          dlg.componentRef.instance.actionResponseMsg = error;
          return obs;
        }))

    } else if (action.actionCommand && action.actionCommand.startsWith("tpw:")) {
      let url = this.fillActionCommand(action.actionCommand, params);

      let paramsAux = new Map<string, any>();
      if (url.indexOf("?") > -1) {
        let paramsStr = url.substring(url.indexOf("?") + 1);
        let paramsArr = paramsStr.split("&");
        paramsArr.forEach(p => {
          let pair = p.split("=");

          paramsAux.set(pair[0], pair[1]);
        })
      }

      if (paramsAux.size > 0) {
        let urlArr = [];
        urlArr.push(url.substring(0, url.indexOf("?")));

        let objParams = {};

        paramsAux.forEach((value, key) => {
          objParams[key] = value;
        });

        urlArr.push(objParams);

        url = this.router.serializeUrl(this.router.createUrlTree(urlArr));
      } else {
        url = this.router.serializeUrl(this.router.createUrlTree([url]));
      }

      if (open == "newWindow") {
        window.open(url, '_blank');
        dlg.close();
      } else if (open == "dialog") {
        dlg.close();
        let index = url.indexOf("?");
        let targetPath: string;
        if (index < 0)
          index = url.indexOf(";");

        if (index > 0)
          targetPath = url.substring(1, index)
        else
          targetPath = url.substring(1);

        let cmpType = this.getComponentFromPath(targetPath);

        // Open the dialog
        if (!cmpType) {
          this.notImplementedCall(action, params);
        } else {
          const context = { showHeader: true, inDialog: true };
          let closeOnBackdropClick = false;
          params.forEach((value, key) => {
            context[key] = value;
            if (key == 'closeOnBDClick') closeOnBackdropClick = value == 'true';
          });

          return this.dialogService.open(cmpType, { context, closeOnBackdropClick: closeOnBackdropClick, closeOnEsc: false }).onClose;
        }
      } else {
        this.router.navigateByUrl(url);
        dlg.close();
      }

      
    } else if (action.actionCommand && action.actionCommand.startsWith("tpwd:")) {
      dlg.close();
      return this.openComponentDialog(action, params).onClose;
    } else if (action.actionCommand && action.actionCommand.startsWith("tpwdh:")) {
      const url = this.fillActionCommand(action.actionCommand, params);
      const sep = this.separatePath(url);
      this.router.navigateByUrl(`dashboard/${action.id}?${sep.param}`);
      dlg.close();
    } else if (action.id_report && action.reportFilterProps === 0) {
      const reportParams = new Map<string, string>();
      reportParams.set("ID", params.get("id"));
      this.printer.openReport(action.id_report, reportParams).subscribe(() => dlg.close(), error => {
        dlg.componentRef.instance.isError = true;
        dlg.componentRef.instance.showCloseButton = true;
        dlg.componentRef.instance.actionResponseMsg = error;
      });
    } else {
      this.notImplementedCall(action, params);
      dlg.close();
    }
  }

  private getComponentFromPath(targetPath: string): Type<any> {
    let route = this.router.config.find(route => {
      const routePath = route.path;
      const routeSegments = routePath.split('/');
      const targetSegments = targetPath.split('/');

      if (routeSegments.length !== targetSegments.length) {
        return false;
      }

      for (let i = 0; i < routeSegments.length; i++) {
        const routeSegment = routeSegments[i];
        const targetSegment = targetSegments[i];

        if (routeSegment.startsWith(':')) {
          continue;
        }

        if (routeSegment !== targetSegment) {
          return false;
        }
      }

      return true;
    });

    return route?.component;
  }

  private separatePath(actionCommand: string) {
    let sep = actionCommand.indexOf("?");

    if (sep < 0) {
      sep = actionCommand.indexOf("%");
    }

    let path: string = null, param: string = null;
    if (sep > -1) {
      path = actionCommand.substring(0, sep);
      param = actionCommand.substring(sep + 1);
    } else {
      path = actionCommand;
    }

    return { path, param };
  }

  private fillActionCommand(actionCommand: string, actionParams: Map<string, string>): string {
    actionCommand = actionCommand.substring(actionCommand.indexOf(":") + 1);
    if (actionCommand.trim().startsWith("//")) {
      actionCommand = actionCommand.substring(actionCommand.indexOf("//") + 2);
    }

    const sep = this.separatePath(actionCommand);
    let path = sep.path, param = sep.param;

    // Resolve path
    let pos = 0;
    const paths = [];
    let varName: string = "";
    let inVar = false;
    do {
      if (path[pos] === "{") {
        inVar = true;
      } else if (path[pos] === "}") {

        if (actionParams.has(varName)) {
          paths.push(actionParams.get(varName));
        } else {
          paths.push(`{${varName}}`);
        }
        inVar = false;
        varName = "";
      } else if (inVar) {
        varName += path[pos];
      } else {
        paths.push(path[pos]);
      }

      pos++;
    } while (pos <= path.length)


    // Resolve param
    const params = [];
    param?.split("&").forEach((item: string) => {
      const paramSep = item.indexOf("=");
      if (paramSep > -1) {
        const paramName = item.substring(0, paramSep);
        if (actionParams.has(paramName)) {
          params.push(paramName + "=" + actionParams.get(paramName));
        } else {
          params.push(item);
        }
      } else {
        params.push(item);
      }
    });

    return paths.join("") + "?" + params.join("&");
  }

  private openURLAutoform(action: T2AccessItem, params: Map<string, string>, newWindow: boolean = false) {
    let datasetName = params.get("datasetName");
    let selectedId = params.get("id");
    let url: string;

    if (action.datasetActionType == T2AccessItemDatasetActionType.DSACTION_EDIT) {
      url = `/dataset/${datasetName}/edit/${selectedId}`;
    } else if (action.datasetActionType == T2AccessItemDatasetActionType.DSACTION_INSERT) {
      let parentId = params.get("parentId");
      url = `/dataset/${datasetName}/edit/${selectedId}`;
      if (parentId) url = url + `?parentId=${parentId}`;
    } else {
      this.notImplementedCall(action, params);
    }

    if (newWindow) {
      url = this.router.serializeUrl(this.router.createUrlTree([url]));
      window.open(url, '_blank');
    } else {
      this.router.navigateByUrl(url, {});
    }

  }

  private openDialogAutoform(action: T2AccessItem, params: Map<string, any>): NbDialogRef<T2AutoFormComponent> {
    let datasetName = params.get("datasetName");
    let informationId = params.get("id");

    const context = { datasetName, informationId, inDialog: true, changeTitle: false };
    params.forEach((value, key) => context[key] = value)
    return this.dialogService.open(T2AutoFormComponent, { closeOnBackdropClick: false, closeOnEsc: false, context });
  }

  private openComponentDialog(action: T2AccessItem, params: Map<string, string>) {

    // Get the component name and its params
    let actionCommand: string = action.actionCommand.substring(action.actionCommand.indexOf(":") + 1);
    if (actionCommand.trim().startsWith("//")) {
      actionCommand = actionCommand.substring(actionCommand.indexOf("//") + 2);
    }

    const sep = actionCommand.indexOf("?");
    let cmpName: string = null, param: string = null;
    if (sep > -1) {
      cmpName = actionCommand.substring(0, sep);
      param = actionCommand.substring(sep + 1);
    } else {
      cmpName = actionCommand;
    }

    if (param) {
      param.split("&").forEach(p => {
        const p1 = p.split("=");
        const key = p1[0];
        if (p1.length > 0 && p1[1]) {
          params.set(key, p1[1]);
        }
      });
    }

    const cmpType = this.cmpService.getType(cmpName);

    // Open the dialog
    if (!cmpType) {
      this.notImplementedCall(action, params);
    } else {
      const context = { showHeader: true, inDialog: true };
      let closeOnBackdropClick = false;
      params.forEach((value, key) => {
        context[key] = value;
        if (key == 'closeOnBDClick') closeOnBackdropClick = value == 'true';
      });

      return this.dialogService.open(cmpType, { context, closeOnBackdropClick: closeOnBackdropClick, closeOnEsc: false });
    }
  }

  notImplementedCall(action: T2AccessItem, params: Map<string, string>) {

    console.log({ action, params });

    this.messageService.showDialog({
      context: {
        title: "Estamos correndo para entregar esse funcionalidade para você",
        message: "Muita coisa está mudando e essa é uma delas.",
        message2: "Logo essa funcionalidade estará habilitada aqui no Techprod WEB",
        message3: action.type,
        actions: [{ description: "OK" }]
      }
    });
  }
}
