<div style="display: flex; gap: 5px;">
  <div *ngFor="let status of statusList">
    <div [ngClass]="{'checkbox': true,
                    'checkboxGreen': status.description == 'Iniciada',
                    'checkboxYellow': status.description == 'Não separada',
                    'checkboxGray': status.description == 'Suspensa',
                    'checkboxRed': status.description == 'Sem estoque',
                    'checkboxBlue': status.description == 'Separada'}">
      <nb-checkbox [(checked)]="status.checked" (checkedChange)="filterTasks($event)"></nb-checkbox>
      <div class="description">{{status.description}}</div>
    </div>
  </div>
</div>
