import { ColDef, ColumnApi, GridApi, ValueFormatterParams } from "ag-grid-community";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";

export class T2gridExportToPDF {

  printParams = {
    PDF_HEADER_COLOR: "#f8f8f8",
    PDF_INNER_BORDER_COLOR: "#dde2eb",
    PDF_OUTER_BORDER_COLOR: "#babfc7",
    PDF_PAGE_ORITENTATION: "landscape",
    PDF_WITH_FOOTER_PAGE_COUNT: true,
    PDF_HEADER_HEIGHT: 25,
    PDF_ROW_HEIGHT: 10,
    PDF_ODD_BKG_COLOR: "#fcfcfc",
    PDF_EVEN_BKG_COLOR: "#ffffff",
    PDF_WITH_CELL_FORMATTING: true,
    PDF_WITH_COLUMNS_AS_LINKS: true,
    PDF_SELECTED_ROWS_ONLY: false
  };

  constructor() {
    pdfMake.vfs = pdfFonts.pdfMake.vfs;
  }

  public generatePDF(t2GridApi: GridApi, t2GridColumnApi: ColumnApi, documentTitle: string, clientUrl: string) {
    const docDefinition = this.getDocDefinition(
      {
        ...this.printParams,
        PDF_LOGO: `https://cdn.techprod.com.br/techprod-preto_header.png`,
        PDF_WITH_HEADER_IMAGE: clientUrl != undefined,
       },
      t2GridApi,
      t2GridColumnApi
    );
    pdfMake.createPdf(docDefinition).download();
  }

  private getDocDefinition(printParams, agGridApi, agGridColumnApi) {

    const columnGroupsToExport = this.getColumnGroupsToExport(agGridColumnApi);
    const columnsToExport = this.getColumnsToExport(agGridColumnApi);
    const widths = this.getExportedColumnsWidths(columnsToExport);
    const rowsToExport = this.getRowsToExport(columnsToExport, agGridApi, agGridColumnApi, printParams);

    const body = columnGroupsToExport
      ? [columnGroupsToExport, columnsToExport, ...rowsToExport]
      : [columnsToExport, ...rowsToExport];

    const headerRows = columnGroupsToExport ? 2 : 1;

    const header = printParams.PDF_WITH_HEADER_IMAGE
      ? {
        image: "ag-grid-logo",
        width: 105,
        heights: 70,
        alignment: "left",
        margin: [10, 10, 0, 5],
      }
      : null;

    const footer = printParams.PDF_WITH_FOOTER_PAGE_COUNT
      ? function (currentPage, pageCount) {
        return {
          text: currentPage.toString() + " of " + pageCount,
          margin: [20],
        };
      }
      : null;

    const pageMargins = [
      10,
      printParams.PDF_WITH_HEADER_IMAGE ? 50 : 20,
      10,
      printParams.PDF_WITH_FOOTER_PAGE_COUNT ? 40 : 10,
    ];

    const heights = (rowIndex) => rowIndex < headerRows ? printParams.PDF_HEADER_HEIGHT : printParams.PDF_ROW_HEIGHT;

    const fillColor = (rowIndex, node, columnIndex) => {
      if (rowIndex < node.table.headerRows) {
        return printParams.PDF_HEADER_COLOR;
      }
      return rowIndex % 2 === 0 ? printParams.PDF_ODD_BKG_COLOR : printParams.PDF_EVEN_BKG_COLOR;
    };

    const hLineWidth = (i, node) => i === 0 || i === node.table.body.length ? 1 : 1;

    const vLineWidth = (i, node) => i === 0 || i === node.table.widths.length ? 1 : 0;

    const hLineColor = (i, node) =>
      i === 0 || i === node.table.body.length
        ? printParams.PDF_OUTER_BORDER_COLOR
        : printParams.PDF_INNER_BORDER_COLOR;

    const vLineColor = (i, node) =>
      i === 0 || i === node.table.widths.length
        ? printParams.PDF_OUTER_BORDER_COLOR
        : printParams.PDF_INNER_BORDER_COLOR;

    return {
      pageOrientation: printParams.PDF_PAGE_ORITENTATION,
      header,
      footer,
      content: [
        {
          style: "myTable",
          table: {
            headerRows,
            widths,
            body,
            heights,
          },
          layout: {
            fillColor,
            hLineWidth,
            vLineWidth,
            hLineColor,
            vLineColor,
          },
        },
      ],
      images: { "ag-grid-logo": printParams.PDF_LOGO, },
      styles: {
        myTable: {
          margin: [0, 0, 0, 0],
        },
        tableHeader: {
          bold: true,
          margin: [0, printParams.PDF_HEADER_HEIGHT / 3, 0, 0],
        },
        tableCell: {
          // margin: [0, 15, 0, 0]
          fontSize: 8
        },
      },
      pageMargins,
    };
  }

  private getColumnGroupsToExport(agGridColumnApi) {
    let displayedColumnGroups = agGridColumnApi.getAllDisplayedColumnGroups();

    let isColumnGrouping = displayedColumnGroups.some((col) => col.hasOwnProperty("children"));

    if (!isColumnGrouping) {
      return null;
    }

    let columnGroupsToExport = [];

    displayedColumnGroups.forEach((colGroup) => {
      let isColSpanning = colGroup.children.length > 1;
      let numberOfEmptyHeaderCellsToAdd = 0;

      if (isColSpanning) {
        let headerCell = this.createHeaderCell(colGroup);
        columnGroupsToExport.push(headerCell);
        // subtract 1 as the column group counts as a header
        numberOfEmptyHeaderCellsToAdd--;
      }

      // add an empty header cell now for every column being spanned
      colGroup.displayedChildren.forEach((childCol) => {
        let pdfExportOptions = this.getPdfExportOptions(childCol.getColId(), agGridColumnApi);
        if (!pdfExportOptions || !pdfExportOptions.skipColumn) {
          numberOfEmptyHeaderCellsToAdd++;
        }
      });

      for (let i = 0; i < numberOfEmptyHeaderCellsToAdd; i++) {
        columnGroupsToExport.push({});
      }
    });

    return columnGroupsToExport;
  }

  private getColumnsToExport(agGridColumnApi) {
    let columnsToExport = [];

    agGridColumnApi.getAllDisplayedColumns().forEach((col) => {
      let pdfExportOptions = this.getPdfExportOptions(col.getColId(), agGridColumnApi);
      if (pdfExportOptions && pdfExportOptions.skipColumn) {
        return;
      }
      let headerCell = this.createHeaderCell(col);
      columnsToExport.push(headerCell);
    });

    return columnsToExport;
  }

  private getRowsToExport(columnsToExport, agGridApi: GridApi, agGridColumnApi: ColumnApi, printParams) {
    let rowsToExport = [];

    agGridApi.forEachNodeAfterFilterAndSort((node) => {
      if (printParams.PDF_SELECTED_ROWS_ONLY && !node.isSelected()) {
        return;
      }
      let rowToExport = columnsToExport.map(({ colId }) => {

        let cellValue = agGridApi.getValue(colId, node);

        const colDef: ColDef = agGridApi.getColumnDefs().find((colDef: ColDef) => colDef.field == colId);
        if (colDef?.valueFormatter && typeof colDef?.valueFormatter == 'function') {

          const params: ValueFormatterParams = {
            value: cellValue,
            node: node,
            data: node.data,
            column: undefined,
            colDef: colDef,
            api: agGridApi,
            columnApi: agGridColumnApi,
            context: undefined
          };
          cellValue = colDef.valueFormatter(params);
        }

        return this.createTableCell(cellValue, colId, agGridColumnApi, printParams);
      });
      rowsToExport.push(rowToExport);
    });

    return rowsToExport;
  }

  private getExportedColumnsWidths(columnsToExport: Array<any>) {

    const width = columnsToExport.reduce((totalWidth, column) => totalWidth += column.width, 0);

    return columnsToExport.map((column: any) => ((column.width / width) * 100) + "%");
  }

  private createHeaderCell(col) {
    let headerCell = { text: null, colSpan: null, colId: null, width: 30 };

    let isColGroup = col.hasOwnProperty("children");

    if (isColGroup) {
      headerCell.text = col.originalColumnGroup.colGroupDef.headerName;
      headerCell.colSpan = col.children.length;
      headerCell.colId = col.groupId;
    } else {
      let headerName = col.colDef.headerName;

      if (col.sort) {
        headerName += ` (${col.sort})`;
      }
      if (col.filterActive) {
        headerName += ` [FILTERING]`;
      }

      headerCell.text = headerName;
      headerCell.width = col.actualWidth;
      headerCell.colId = col.getColId();
    }

    headerCell["style"] = "tableHeader";

    return headerCell;
  }

  private createTableCell(cellValue, colId, agGridColumnApi, printParams) {

    const tableCell = {
      text: cellValue,
//      text: cellValue !== undefined && !isNaN(cellValue) ? cellValue : "",
      // noWrap: PDF_PAGE_ORITENTATION === "landscape",
      style: "tableCell",
    };

    const pdfExportOptions = this.getPdfExportOptions(colId, agGridColumnApi);

    if (pdfExportOptions) {
      const { styles, createURL } = pdfExportOptions;

      if (printParams.PDF_WITH_CELL_FORMATTING && styles) {
        Object.entries(styles).forEach(
          ([key, value]) => (tableCell[key] = value)
        );
      }

      if (printParams.PDF_WITH_COLUMNS_AS_LINKS && createURL) {
        tableCell["link"] = createURL(cellValue);
        tableCell["color"] = "blue";
        tableCell["decoration"] = "underline";
      }
    }

    return tableCell;
  }

  private getPdfExportOptions(colId, agGridColumnApi) {
    let col = agGridColumnApi.getColumn(colId);
    return col.colDef.pdfExportOptions;
  }

}
