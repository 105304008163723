<app-dialog-template #dialog [ngStyle]="{'height.vh': 80, 'width.vw': 60}" [title]="'Avisos'">
  <content>

    <div class="flexColumn" style="height: 100%;">

      <div class="T2GrayArea spacing flexColumn flexGap5">
        <div *ngFor="let description of datasetGroup">
          <div class="flexColumn flexGap10">

            <span class="defaultTitle">{{description}}</span>

            <div *ngFor="let warning of warningList | filterWarning: description" class="T2GrayArea spacing flexRow">

              <div style="display: flex; justify-content: center; align-items: center; flex: 0.2;">

                <nb-icon *ngIf="warning.completionUser" class="warningBlue" style="font-size: 300%;"
                  icon="checkmark-square-outline"></nb-icon>

                <nb-icon *ngIf="!warning.completionUser && !warning.denyManualClosure" class="buttonIcon"
                  [ngClass]="{'warningRed': warning.maxDate?.getTime() < todayDate.getTime(), 'warningOrange': warning.maxDate?.getTime() == todayDate.getTime(), 'warningGreen': warning.maxDate?.getTime() > todayDate.getTime() || !warning.maxDate}"
                  icon="square-outline" (click)="finishWarning(warning)" [nbSpinner]="warning.saving"
                  nbSpinnerSize="medium" nbSpinnerStatus="primary"
                  [ngStyle]="{'pointer-events': warning.saving ? 'none' : 'auto'}"></nb-icon>
              </div>

              <div class="flexColumn flexGap10" style="flex: 1">
                <div style="display: flex; justify-content: space-between; align-items: center; padding: 5px;">
                  <span class="defaultSmallTitle">{{warning.description}}</span>
                  <div class="warningType" [ngClass]="colorService.getClass(warning.color)">
                    <span>{{warning.warningType}}</span>
                  </div>
                </div>
                <div *ngIf="warning.note" class="defaultNote">
                  <span>{{warning.note}}</span>
                </div>
                <div class="detailBox"
                  style="display: flex; gap: 5px; justify-content: space-between; align-items: flex-start;">
                  <div class="flexRow flexGap5" style="flex: 33%">
                    <div class="flexColumn" *ngIf="warning.startDate">
                      <span class="detailBoxTitle">Prazo Inicial</span>
                      <span class="detailBoxValue">{{warning.startDate | date: 'short'}}</span>
                    </div>
                    <div class="flexColumn">
                      <span class="detailBoxTitle">Prazo Final</span>
                      <span class="detailBoxValue">{{warning.maxDate | date: 'short'}}</span>
                    </div>
                  </div>
                  <div style="flex: 34%">
                    <div class="flexColumn" *ngIf="warning.completionDate">
                      <span class="detailBoxTitle">Finalizado em</span>
                      <span class="detailBoxValue">{{warning.completionDate | date: 'short'}}</span>
                    </div>
                  </div>
                  <div style="flex: 33%">
                    <div class="flexColumn" *ngIf="warning.completionUser">
                      <span class="detailBoxTitle">Finalizado por</span>
                      <span class="detailBoxValue">{{warning.completionUser}}</span>
                    </div>
                  </div>
                </div>
              </div>

            </div>

          </div>
        </div>
      </div>

    </div>

  </content>

  <footer style="display: flex; justify-content: flex-end; align-items: center;">
    <app-t2-button t2IdCmp="btnFechar" [status]="'basic'" [size]="'tiny'" [value]="'Fechar'"(clicked)="closeDialog()"></app-t2-button>
  </footer>

</app-dialog-template>
