<div #statusCmp style="width: 200px;">
  <div style="display: flex; justify-content: space-between; align-items: center;" class="status" [ngClass]="{'statusClick': showingOptions}"
    [ngStyle]="{'background-color': currentStatus.color}">
    <div style="padding: 5px 7px;">{{currentStatus.name}}</div>
    <div class="statusChange flexAlignColumnCenter" [ngClass]="{'statusChangeClick': showingOptions}"
      (click)="statusClick()">
      <nb-icon icon="more-horizontal"></nb-icon>
    </div>
  </div>

  <div id="statusOptions" [ngClass]="{'statusOptionsClick': showingOptions}">
    <div class="flexAlignColumnCenter statusOption" *ngFor="let status of statusFlow"
         [nbTooltip]="status.tooltip" [nbTooltipTrigger]="!status.tooltip ? 'noop' : 'hover'"
         [ngStyle]="{'background-color': status.color}"
      (click)="selectStatus(status)">
      {{status.action || status.name}}</div>
  </div>
</div>
