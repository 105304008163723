<div class="searchFloatButton" nbTooltip="Sequenciamento" style="z-index: 999;"
  (click)="$event.stopPropagation(); selectDevice('sequencing'); ">
  <nb-icon icon="list-outline"></nb-icon>
</div>

<div class="editFloatButton" nbTooltip="Apontamento" style="z-index: 999;"
  (click)="$event.stopPropagation(); selectDevice('appointment', true); ">
  <nb-icon icon="edit-outline"></nb-icon>
</div>

<div *ngIf="taskPlan?.id_taskPlan" class="printFloatButton" nbTooltip="Documentos" style="z-index: 999;"
  (click)="$event.stopPropagation(); selectDevice('document', true); ">
  <nb-icon icon="file-text-outline"></nb-icon>
</div>
