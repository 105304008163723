<div *ngIf="panel == 'document'" class="history">

  <!-- APPOINTMENTS -->
  <div class="T2GrayArea contentList">

    <div class="titles" style="display: flex; justify-content: space-between; align-items: center;">
      <div>Apontamentos</div>
      <div style="display: flex; gap: 5px">
        <div *ngIf="false" class="apontFilter" style="background-color: #212f3d;"
          nbTooltip="Exibir apenas apontamentos não justificados">
          <nb-checkbox status="basic" style="height: 16px;" [(checked)]="unjustifiedAppont"
            (checkedChange)="checkUnjustifiedAppont($event);" />
          <div>Não justificado</div>
        </div>

        <div class="apontFilter apontFilterQtt" style="background-color: rgba(27, 90, 165, 1);"
          nbTooltip="Exibir apenas apontamentos de quantidades">
          <nb-checkbox status="primary" style="height: 16px;" [(checked)]="quantityAppont"
            (checkedChange)="checkQuantityAppont($event);" />
          <div>Etiqueta</div>
        </div>
      </div>
    </div>

    <div *ngIf="!taskPlan.appointment.length" style="font-size: 0.7rem; font-weight: bold;">
      Não há registros de apontamento para essa tarefa
    </div>

    <div *ngIf="filteredAppointmentList?.length" class="appointment" style="max-height: 450px;">
      <div *ngFor="let appointment of filteredAppointmentList; let i = index"
        [ngStyle]="{'background': appointment.color}" class="defaultAppointment">

        <div class="appointmentIndex">{{appointment.index + 1}}</div>

        <div class="defaultAppointmentBG">

          <div class="flexColumn" style="line-height: 0.8rem; min-width: 0px;">
            <div class="appointmentOperation" [nbTooltip]="appointment.description  || 'Operação não justificada'">
              {{appointment.description || 'Operação não justificada'}}
            </div>
            <div class="appointmentUserInfo">{{appointment.insertUserName}}</div>
          </div>

          <div *ngIf="appointment.vlQuantity > 0" class="appointmentInfo">
            {{appointment.vlQuantity}}{{appointment.unitSymbol}}
          </div>

          <div class="flexColumn" style="line-height: 0.8rem; align-items: flex-end;">
            <div class="appointmentInfo">{{appointment.startDate | date: "dd/MM/yy HH:mm"}}</div>
            <div *ngIf="appointment.filterGroup != 'QUANTITY'" class="appointmentInfo">
              {{appointment.endDate | date: "dd/MM/yy HH:mm"}}
            </div>
          </div>

          <div #apptActions id="apptActions"
            *ngIf="(appointment.id_report_etq && appointment.sendToPrinter )|| appointment.description == 'Operação não justificada'">

            <!-- JUSTIFY OPERATION -->
            <div *ngIf="appointment.description == 'Operação não justificada'" class="editFloatButton"
              nbTooltip="Justificar operação" style="z-index: 999;" (click)="justifyOperation(appointment)">
              <nb-icon icon="edit-outline"></nb-icon>
            </div>

            <!-- PRINT QUANTITY LABEL -->
            <div *ngIf="appointment.id_report_etq && appointment.sendToPrinter" class="printFloatButton"
              nbTooltip="Re-imprimir" style="z-index: 999;" (click)="reprintLabel(appointment)">
              <nb-icon icon="printer-outline"></nb-icon>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>

  <!-- ITEMS REQUESTS -->
  <div class="T2GrayArea contentList">

    <div class="titles">Requisições</div>

    <div *ngIf="!requisitionMaterialList?.length" style="font-size: 0.7rem; font-weight: bold;">
      Não há requisições para essa tarefa
    </div>

    <div *ngIf="requisitionMaterialList?.length" style="display: flex; flex-wrap: wrap; gap: 5px;">
      <div *ngFor="let req of requisitionMaterialList" class="T2GrayArea"
        style="display: flex; gap: 5px; justify-content: space-between; align-items: center; min-width: 296px; padding: 3px 8px;">
        <div class="reqMaterial" [nbTooltip]="req.cmpDescr">{{req.cmpDescr}}</div>
        <div style="display: flex; gap: 10px;">
          <div class="requisitionQtts">
            <div class="reqFontSize" style="font-weight: bold;">Req</div>
            <div class="reqFontSize">{{req.requestedQtt | number: '1.0-2'}} {{req.unit}}</div>
          </div>
          <div class="requisitionQtts">
            <div class="reqFontSize" style="font-weight: bold;">Sep</div>
            <div class="reqFontSize">{{req.separatedQtt | number: '1.0-2'}} {{req.unit}}</div>
          </div>
          <div class="requisitionQtts">
            <div class="reqFontSize" style="font-weight: bold;">Cons</div>
            <div class="reqFontSize">{{req.consumedQtt | number: '1.0-2'}} {{req.unit}}</div>
          </div>
        </div>
      </div>
    </div>

  </div>

  <!-- ATTACHMENTS AND DOCUMENTS -->
  <div class="T2GrayArea contentList">

    <div class="titles">Anexos e Documentos</div>
    <div class="attachsDocs">
      <div *ngIf="taskPlan?.id_ordemProd && (prodOrder?.reportOperations.length || prodOrder?.actions.length)"
        class="T2GrayArea titles titlesAlign">
        <div>Ordem de Produção</div>
        <div style="display: flex; justify-content: space-between; align-items: flex-start; gap: 5px; padding: 5px;">
          <div class="docsBG">
            <div [ngClass]="{'docInfoBG': !opReport.loading, 'loading': opReport.loading}"
              *ngFor="let opReport of prodOrder?.reportOperations" [nbTooltip]="opReport.description"
              [nbSpinner]="opReport.printing" nbSpinnerSize="tiny"
              (click)="!opReport.printing ? openReport(opReport): null">
              {{opReport.description}}
            </div>
            <div class="docInfoBG" (click)="executeAction(action, taskPlan.id_ordemProd)"
              *ngFor="let action of prodOrder?.actions" [nbTooltip]="action.datasetActionDescription">
              {{action.datasetActionDescription}}</div>
          </div>
          <div *ngIf="prodOrder?.hasAttachment" class="attachBG">
            <app-t2-ds-attachments onlyReading="true" datasetName="ord_ordemProd" [parentId]="taskPlan.id_ordemProd" />
          </div>
        </div>
      </div>

      <div *ngFor="let ep of epList">
        <div *ngIf="ep.actions.length" class="T2GrayArea titles titlesAlign flexColumn" style="flex: 1">
          <div>Especificação de Produto {{ep.code}}</div>
          <div style="display: flex; justify-content: space-between; align-items: flex-start; gap: 5px; padding: 5px;">
            <div class="docsBG">
              <div class="docInfoBG" *ngFor="let action of ep.actions" [nbTooltip]="action.datasetActionDescription"
                (click)="executeAction(action, ep.id_especificacao)">
                {{action.datasetActionDescription}}</div>
            </div>
            <!-- <div *ngIf="ep.hasAttachment" class="attachBG">
            <app-t2-ds-attachments onlyReading="true" datasetName="pvd_pedidovenda" [parentId]="ep.id_especificacao" />
          </div> -->
          </div>
        </div>
      </div>

      <div *ngFor="let so of salesOrderList">
        <div *ngIf="so.actions.length" class="T2GrayArea titles titlesAlign flexColumn" style="flex: 1">
          <div>Pedido de Venda {{so.code}}</div>
          <div style="display: flex; justify-content: space-between; align-items: flex-start; gap: 5px; padding: 5px;">
            <div class="docsBG">
              <div class="docInfoBG" *ngFor="let action of so.actions" [nbTooltip]="action.datasetActionDescription"
                (click)="executeAction(action, so.id_pedidovenda)">
                {{action.datasetActionDescription}}</div>
            </div>
            <div *ngIf="so.hasAttachment" class="attachBG">
              <app-t2-ds-attachments onlyReading="true" datasetName="pvd_pedidovenda" [parentId]="so.id_pedidovenda" />
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>

<app-shop-floor-appointment *ngIf="panel == 'justify operation'" [device]="device" [taskPlan]="taskPlan"
  [justifyOperation]="true" [appointment]="appointmentToJustify"
  (justifyOperationFinished)="goBackToDocument($event)"
  (lockScreen)="setLockScreen($event)"></app-shop-floor-appointment>