import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  NbFormFieldModule,
  NbSelectModule, NbButtonModule, NbCardModule, NbInputModule, NbDatepickerModule, NbUserModule,
  NbIconModule, NbListModule, NbCheckboxModule, NbTooltipModule, NbAccordionModule, NbTabsetModule, NbToggleModule,
  NbContextMenuModule,
  NbMenuModule,
  NbBadgeModule,
  NbCalendarRangeModule,
  NbSpinnerModule,
  NbTreeGridModule,
  NbTimepickerModule,
  NbRadioModule,
  NbPopoverModule,
} from '@nebular/theme';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NbDateFnsDateModule } from "@nebular/date-fns";



@NgModule({
  declarations: [],
  imports: [
    CommonModule,

    NbFormFieldModule,
    NbCardModule,
    NbInputModule,
    NbSelectModule,
    NbButtonModule,
    NbCalendarRangeModule,
    NbDateFnsDateModule,
    NbUserModule,
    NbIconModule,
    NbListModule,
    NbCheckboxModule,
    NbTooltipModule,
    NbAccordionModule,
    NbTabsetModule,
    NbMenuModule,
    NbContextMenuModule,
    NbToggleModule,
    NbBadgeModule,
    NbSpinnerModule,
    NbRadioModule,
    NbPopoverModule,
    FormsModule,
    ReactiveFormsModule
  ],
  exports: [
    NbFormFieldModule,
    NbCardModule,
    NbInputModule,
    NbSelectModule,
    NbButtonModule,
    NbDatepickerModule,
    NbTimepickerModule,
    NbCalendarRangeModule,
    NbDateFnsDateModule,
    NbUserModule,
    NbIconModule,
    NbListModule,
    NbCheckboxModule,
    NbTooltipModule,
    NbAccordionModule,
    NbTabsetModule,
    NbToggleModule,
    NbMenuModule,
    NbContextMenuModule,
    NbBadgeModule,
    NbSpinnerModule,
    NbTreeGridModule,
    NbRadioModule,
    NbPopoverModule
  ]
})
export class T2NebularModule { }
