<div style="display: flex; gap: 5px; justify-content: flex-start; align-items: center;">

  <!-- TIPOS DE ANALISES -->
  <div style="display: flex; gap: 5px" class="buttonGroup">
    <app-t2-menu-button [caption]="'Carga máquina'"
      [status]="apsService.analysisVars.analysisView == 'machineLoad' ? 'info' : 'basic'"
      (click)="analysisClick('machineLoad')">
    </app-t2-menu-button>
    <!-- <app-t2-menu-button caption="Problemas"
      [status]="apsService.analysisVars.analysisView == 'inconsistency' ? 'info' : 'basic'"
      (click)="analysisClick('inconsistency')">
    </app-t2-menu-button> -->
  </div>

  <!-- AÇÕES LIGADAS CARGA MÁQUINA -->
  <div *ngIf="apsService.analysisVars.analysisView == 'machineLoad'" class="buttonGroup"
    style="display: flex; gap: 5px; ">
    <app-t2-menu-button [caption]="'Dispositivo'" [disabled]="!progEnvironment" (click)="selectDeviceClick()">
    </app-t2-menu-button>
    <app-t2-menu-button [caption]="'Diário'" [disabled]="!progEnvironment"
      (click)="selectMachineLoadConsolidation('daily');"
      [status]="apsService.analysisVars.machiLoadConsolidation.value == 'daily' ? 'info' : 'basic'">
    </app-t2-menu-button>
    <app-t2-menu-button [caption]="'Semanal'" [disabled]="!progEnvironment"
      (click)="selectMachineLoadConsolidation('weekly');"
      [status]="apsService.analysisVars.machiLoadConsolidation.value == 'weekly' ? 'info' : 'basic'">
    </app-t2-menu-button>
    <app-t2-menu-button [caption]="'Mensal'" [disabled]="!progEnvironment"
      (click)="selectMachineLoadConsolidation('monthly');"
      [status]="apsService.analysisVars.machiLoadConsolidation.value == 'monthly' ? 'info' : 'basic'">
    </app-t2-menu-button>


  </div>

</div>
