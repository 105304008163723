import { Component, OnInit, Input, ViewChild } from "@angular/core";
import { DialogTemplateComponent } from "src/app/core/cmp/dialog-template/dialog-template.component";
import { T2AccessItem } from "src/app/core/security/model/t2accessItem";

@Component({
  selector: "app-modal-dashboard",
  templateUrl: "./modal-dashboard.component.html",
  styleUrls: ["./modal-dashboard.component.scss"],
})
export class ModalDashboardComponent implements OnInit {

  @Input() title: string;
  @Input() task: any;
  @Input() actions: T2AccessItem[];

  @ViewChild("dialog", { static: true }) dialog: DialogTemplateComponent;

  constructor() {}

  ngOnInit(): void {}

  actionClick(action: T2AccessItem) {
    this.dialog.close({
      action,
      task: this.task,
    });
  }
}

