import { Component, Input, OnInit } from '@angular/core';
import { Tasks } from "../shop-floor.service";

@Component({
  selector: 'app-shop-floor-task-flow',
  templateUrl: './shop-floor-task-flow.component.html',
  styleUrls: ['./shop-floor-task-flow.component.scss']
})
export class ShopFloorTaskFlowComponent implements OnInit {

  @Input() taskFlowList = new Array<Tasks>;

  ngOnInit(): void {

  }

}
