import { AfterContentInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { NbDialogService } from "@nebular/theme";
import { take } from "rxjs/Operators";
import { T2gridComponent } from "src/app/core/cmp/t2grid/t2grid.component";
import { T2MessageService } from "src/app/core/t2-message.service";
import { CylinderFamilyItem } from "../../model/cylinder-family-item";
import { PSColor } from "../../model/pscolor";
import { EppchemicalCompatCylinderSelectionComponent } from "../eppchemical-compat-cylinder-selection/eppchemical-compat-cylinder-selection.component";
import { FlxProductSpecificationService } from "../flx-product-specification.service";

@Component({
  selector: 'app-eppchemical-compat-cylinder',
  templateUrl: './eppchemical-compat-cylinder.component.html',
  styleUrls: ['./eppchemical-compat-cylinder.component.scss']
})
export class EppchemicalCompatCylinderComponent implements OnInit, AfterContentInit {

  @Input() id: string;
  @Input() id_familiaCilindro: string;

  @ViewChild("gridCylinderFamily", { static: true }) gridCylinderFamily: T2gridComponent;

  public loadingGrid: boolean = false;
  private psType: string;
  private id_specifType: string;
  private cylFamilyItemList: Array<CylinderFamilyItem>;
  private mapDataset = new Map<string, { dsName: string, dsId: string }>();

  constructor(private flxPsService: FlxProductSpecificationService, private nbDialogService: NbDialogService, private messageService: T2MessageService) {
    this.mapDataset.set("C", { dsName: "ctv_cotacao_item_carac_versao", dsId: "{810CE805-737E-4704-976C-BCA9AF3631E4}" });
    this.mapDataset.set("CONJ", { dsName: "stk_conjugado", dsId: "}D20151008H130433256R000000001" });
    this.mapDataset.set("P", { dsName: "stk_item_produto_versao", dsId: "{46CEEDC6-3B5B-4486-828F-54A4EA91FD39}" });
    this.mapDataset.set("M", { dsName: "epp_modelo", dsId: "{FF74C2B0-D703-45D7-B465-5FAEA6F1B6E8}" });
    this.mapDataset.set("EP", { dsName: "epp_especif", dsId: "{D752425C-D833-4B40-868B-D62634ED1CA7}" });
  }

  ngAfterContentInit(): void {
    if (this.id) {
      this.flxPsService.getPsTypeByChemicalCarac(this.id)
        .pipe(take(1))
        .subscribe(resp => {
          this.psType = resp.psType;
          this.id_specifType = resp.id_specifType;
        });
    }
    
    this.loadGrid();
  }

  ngOnInit(): void {
    this.gridCylinderFamily.setGridColumnDefs([
      { headerName: "Cor", field: "color" },
      { headerName: "Detalhe", field: "detail" },
      { headerName: "EP", field: "code" },
    ]);
  }

  loadGrid() {
    this.loadingGrid = true;

    if (this.id_familiaCilindro) {
      this.flxPsService.getCylinderFamilyItens(this.id_familiaCilindro).pipe(take(1)).subscribe(cfiList => {
        this.cylFamilyItemList = cfiList?.filter(c => c.id_especif_carac_quimico_item != this.id);

        this.gridCylinderFamily.setGridData(this.cylFamilyItemList, null);
        this.loadingGrid = false;
      }, error => {
        this.loadingGrid = false;
      })
    } else {
      this.loadingGrid = false;
    }
  }

  addCylinderToFamily() {
    let ds = ["P", "CONJ"].includes(this.psType) ? this.mapDataset.get(this.psType) : this.mapDataset.get("EP");
    let dlg = this.nbDialogService.open(EppchemicalCompatCylinderSelectionComponent, {
      context: {
        psDatasetName: ds.dsName,
        psDatasetId: ds.dsId,
        id_especifType: this.id_specifType
      }
    });
    dlg.onClose
      .pipe(take(1))
      .subscribe((psColor: PSColor) => {
        if (psColor) {
          this.flxPsService.addColorToCylinderFamily(this.id, psColor.id_especif_carac_quimico_item)
            .pipe(take(1))
            .subscribe(resp => {
              if (resp.id_familiaCilindro) {
                this.id_familiaCilindro = resp.id_familiaCilindro;
                this.loadGrid();
              }
            })
        }
      })
  }

  deleteCylinderFromFamily() {
    this.messageService.showDialog({
      context: {
        topMessage: "ATENÇÃO",
        message: "Deseja remover esse registro da família de cilindros ?",
        actions: [{ description: "Remover", status: "danger" }, { description: "Cancelar", status: "basic" }]
      }
    }).onClose
      .pipe(take(1))
      .subscribe(resp => {
        if (resp == "Remover") {
          this.loadingGrid = true;
          let rowData = this.gridCylinderFamily.getFocusedRow();
          this.flxPsService.deleteColorFromCylinderFamily(rowData.id_especif_carac_quimico_item, this.id_familiaCilindro).pipe(take(1)).subscribe(resp => {
            if (this.cylFamilyItemList.length == 1) {
              this.id_familiaCilindro = undefined;
              this.cylFamilyItemList = [];
              this.gridCylinderFamily.setGridData([], null);
            }

            this.gridCylinderFamily.removeFocusedRow();
            this.loadingGrid = false;
          }, error => {
            this.loadingGrid = false;
          })
        }
      });
  }
}