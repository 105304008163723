<div [style.display]="!visible ? 'none' : 'block'" style="height: 27px;">

  <div class="aggregContent"
    [ngClass]="{'aggregContentEnabled': !readOnly, 'T2CmpReadOnlyColor' : readOnly, 'aggregationBoxClassicTheme': classicTheme}">

    <div style="display: flex; gap: 5px;" class="cmpInputFont">

      <div *ngIf="aggregationInfo.deleted" class="aggregDeletedIcon" nbTooltip="A informação está excluída">
        <nb-icon icon="slash-outline"></nb-icon>
      </div>

      <div *ngIf="aggregationInfo.inactive && !aggregationInfo.deleted" class="aggregInactiveIcon"
        nbTooltip="A informação está inativada">
        <nb-icon icon="pause-circle-outline"></nb-icon>
      </div>

      <div
        [ngClass]="{'aggregContentDeleted': aggregationInfo.deleted, 'aggregContentInactive': aggregationInfo.inactive && !aggregationInfo.deleted}">
        &nbsp; {{aggregationInfo?.code}} &nbsp; {{description || formGroup?.controls[t2IdCmp]?.value?.value}}
      </div>

    </div>

    <div id="infoActionList" *ngIf="!readOnly || canView || canEdit">

      <div [ngClass]="{'infoAction':!readOnly, 'infoActionDisabled': readOnly}" *ngIf="!readOnly"
        (click)="openDataset()">
        <nb-icon icon="search-outline" nbTooltip="Pesquisar"></nb-icon>
      </div>

      <div *ngIf="canView && !this.aggregationInfo.deleted" class="infoAction"
        (click)="executeAction(this.aggregationInfo.actionEdit)">
        <nb-icon icon="eye-outline" nbTooltip="Visualizar"></nb-icon>
      </div>

      <div *ngIf="canEdit && !this.aggregationInfo.deleted" class="infoAction"
        (click)="executeAction(this.aggregationInfo.actionEdit)">
        <nb-icon icon="edit-outline" nbTooltip="Editar"></nb-icon>
      </div>

      <div *ngIf="!readOnly && (description || formGroup?.controls[t2IdCmp]?.value?.value)" class="infoAction"
        (click)="clearValue()">
        <nb-icon icon="minus-circle-outline" nbTooltip="Limpar o conteúdo do campo"></nb-icon>
      </div>


      <div
        *ngIf="(!readOnly && aggregationInfo.otherActions.length) || (readOnly && aggregationInfo.otherReadonlyActions.length)"
        class="infoAction" (click)="showOtherActionsList();">
        <nb-icon icon="menu-outline" nbTooltip="Outras ações"></nb-icon>
      </div>


    </div>

  </div>
</div>



<ng-template #datasetDialog>
  <app-dialog-template [ngStyle]="{'height.vh': 65, 'width.vw': 80}">

    <content>

      <div class="aggregationHeader">
        <div>
          {{aggregationDatasetDescription}}
        </div>

        <div class="aggregationSearchArea">

          <input type="text" nbInput fullWidth fieldSize="tiny" placeholder="Busca rápida" style="width: 180px;"
            [(ngModel)]="searchText" [disabled]="loadingData">

          <nb-select *ngIf="aggregationInfo?.extTypeList && aggregationInfo?.extTypeList.length"
            [(ngModel)]="aggregationInfo.extTypeSelected" size="tiny" multiple style="width: 220px;"
            [disabled]="loadingData">
            <nb-option *ngFor="let item of aggregationInfo?.extTypeList"
              [value]="item.datasetName">{{item.description}}</nb-option>
          </nb-select>

          <button nbButton size="tiny" status="success" (click)="refreshData()"
            [disabled]="loadingData">Atualizar</button>

        </div>
      </div>

      <app-dataset-template #datasetTemplate [datasetName]="datasetName" [isAggregation]="true"
        [searchText]="searchText" [fixedFilter]="fixedFilter" [(selectedId)]="informationId"
        [(loadingData)]="loadingData" [parentId]="datasetTemplateParentId" (extTypeListChange)="onExtTypeList($event)">
      </app-dataset-template>
    </content>

    <footer>
      <button nbButton size="tiny" (click)="closeDialog(false)">Cancelar</button>
      <button nbButton size="tiny" status="primary" [disabled]="!informationId"
        (click)="closeDialog(true)">Selecionar</button>
    </footer>

  </app-dialog-template>
</ng-template>

<ng-template #propertySelectionDialog>
  <app-dialog-template [ngStyle]="{'height.vh': 65, 'width.vw': 80}">
    <content>
      <app-visualization [queryMode]="true" [id_dataset]="aggregationInfo?.condPropertyValue"
        [description]="aggregationDatasetDescription" [selectedFieldList]="selectedFieldList"
        style="height: 100%; display: flex; flex-direction: row;" />
    </content>
    <footer>
      <button nbButton size="tiny" (click)="dialogRef.close(false)">Cancelar</button>
      <button nbButton size="tiny" status="primary" (click)="closeDialog(true)">Selecionar</button>
    </footer>
  </app-dialog-template>
</ng-template>