import { Pipe, PipeTransform } from '@angular/core';
import { ProcessOption } from "../model/process-option";

@Pipe({
  name: 'processOptionMap'
})
export class ProcessOptionMapPipe implements PipeTransform {

  transform(items: Array<ProcessOption>, ...args: unknown[]): unknown {
    return items?.map(po => {
      return {
        id: po.id_especif_componente_prc,
        description: po.name
      }
    })
  }

}
