import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, Validators } from "@angular/forms";
import { take } from "rxjs/Operators";
import { DialogTemplateComponent } from "src/app/core/cmp/dialog-template/dialog-template.component";
import { T2HttpClientService } from "src/app/core/http/t2httpClient.service";

@Component({
  selector: 'app-dialog-finished-reproves-separation',
  templateUrl: './dialog-finished-reproves-separation.component.html',
  styleUrls: ['./dialog-finished-reproves-separation.component.scss']
})
export class DialogFinishedReprovesSeparationComponent implements OnInit {

  @Input() title: string;
  @Input() id_packingList: string;
  @Input() reasonList;

  reproveReasonField = new FormControl('', Validators.required);
  notes = new FormControl('', Validators.required);

  @ViewChild('dialog', { static: true }) dialog: DialogTemplateComponent;

  constructor(
    private httpClient: T2HttpClientService
  ) {
  }

  ngOnInit(): void {
  }

  confirm() {
    const resp = {
      type: 'confirm',
      id_packingList: this.id_packingList,
      notes: this.notes.value,
      reproveReason: this.reproveReasonField.value
    };

    this.dialog.close(resp);
  }

  cancel() {
    const resp = {
      type: 'cancel',
      id_packingList: this.id_packingList,
      notes: this.notes.value,
      reproveReason: this.reproveReasonField.value
    };

    this.dialog.close(resp);
  }
}
