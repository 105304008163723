import { Component, OnInit } from '@angular/core';
import { T2BaseComponent } from "../t2-base-component";

@Component({
  selector: 'app-t2-check-box',
  templateUrl: './t2-check-box.component.html',
  styleUrls: ['./t2-check-box.component.scss']
})
export class T2CheckBoxComponent extends T2BaseComponent implements OnInit {

  constructor() {
    super();
  }

  ngOnInit(): void {
    if (typeof this.formGroup.controls[this.t2IdCmp].value == 'string') this.formGroup.controls[this.t2IdCmp].setValue(Number(this.formGroup.controls[this.t2IdCmp].value));
    this.setDefaultValues();
  }

}
