<app-dialog-template>
  <content>

    <div class="panel">

      <div class="panelMenu">
        <div (click)="showInfo('license')" [ngClass]="{ tabSelected: showView === 'license' }" class="tabSelection">
          Licenciamento</div>
        <div (click)="showInfo('sessions')" [ngClass]="{ tabSelected: showView === 'sessions' }" class="tabSelection">
          Sessões</div>
        <div (click)="showInfo('aboutTP')" [ngClass]="{ tabSelected: showView === 'aboutTP' }" class="tabSelection">
          Sobre</div>
      </div>

      <app-license *ngIf="showView == 'sessions' || showView == 'license'" [showView]="showView"
        style="width: 100%; flex-grow: 1; font-size: 80%; display: flex;"></app-license>

      <app-server-info *ngIf="showView == 'aboutTP'" style="font-size: 80%; height: 100%; width: 100%;">
      </app-server-info>
    </div>

  </content>

  <footer class="companyInfo">
    <div class="tech2e_logo" [ngClass]="{
                tech2e_logo_branco: themeService.getThemeName() === 'dark',
                tech2e_logo_preto: themeService.getThemeName() != 'dark'
              }"></div>
    <div class="copyrightText">
      <div style="font-weight: bold;">Tech2e Exc e Exp em Tecnologia LTDA</div>
      <div>Copyright 2023 All rights reserved - Todos os direitos reservados</div>
    </div>

    <div style="display: flex; justify-content: space-around; font-size: 0.9em; width: 100%;">
      <a href="https://www.techprod.com.br" target="_blank">www.techprod.com.br</a>
      <a href="https://www.tech2e.com.br" target="_blank">www.tech2e.com.br</a>
    </div>

  </footer>
</app-dialog-template>
