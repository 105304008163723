<div style="display: flex; justify-content: flex-start; align-items: center; gap: 10px">

  <!-- SIMULAÇÃO -->
  <div>
    <app-t2-menu-button [caption]="selectedSimuDescription" (click)="selectSimulation()">
    </app-t2-menu-button>
  </div>


  <!-- VISUALIZAÇÃO -->
  <div style="display: flex; gap: 5px" class="buttonGroup">
    <app-t2-menu-button [status]="apsService.showView == 'programming' ? 'success' : 'basic'"
      (click)="viewClick('programming')" [caption]="'Programação'"></app-t2-menu-button>
    <app-t2-menu-button [status]="apsService.showView == 'analysis' ? 'info' : 'basic'"
      (click)="viewClick('analysis')" [caption]="'Análises'"></app-t2-menu-button>
    <!-- <app-t2-menu-button [status]="apsService.showView == 'kpi' ? 'warning' : 'basic'" (click)="viewClick('kpi')"
      caption="KPIs"></app-t2-menu-button> -->

  </div>

  <div *ngIf="apsService.showView == 'programming'">
    <app-programming (fixedDateClick)="setFixedDate($event)" (recalculateClick)="recalculate()"
      (timeReservationClick)="timeReservation()" (swapDeviceClick)="swapDevice()" (lockScreen)="lockScreen($event)"
      (sortSelctedTasksClick)="sortSelctedTasks()">
    </app-programming>
  </div>

  <div *ngIf="apsService.showView == 'analysis'">
    <app-analysis-aps></app-analysis-aps>
  </div>

  <div *ngIf="apsService.showView == 'kpi'">
    <app-kpi></app-kpi>
  </div>
</div>
