import { Component, OnInit } from '@angular/core';
import { T2HttpClientService } from "src/app/core/http/t2httpClient.service";
import * as AfxVersionJson from 'src/assets/afxversion.json';
import { T2ThemingService } from "src/app/core/t2theming.service";

@Component({
  selector: 'app-server-info',
  templateUrl: './server-info.component.html',
  styleUrls: ['./server-info.component.scss']
})
export class ServerInfoComponent implements OnInit {

  serverDescription: string;
  serverVersion: string;
  javaVmName: string;
  javaRuntimeVersion: string;
  osArch: string;
  osName: string;
  version: string;


  constructor(private httpClient: T2HttpClientService, public themeService: T2ThemingService) {
    let intermediateJson = AfxVersionJson

    this.version = intermediateJson.afxversion + "." + intermediateJson.afxrelease;
  }


  getServerInfos() {
    this.serverDescription = this.httpClient?.serverDescription;
    this.serverVersion = this.httpClient?.serverVersion;
    this.javaVmName = this.httpClient?.javaVmName;
    this.javaRuntimeVersion = this.httpClient?.javaRuntimeVersion;
    this.osArch = this.httpClient?.osArch;
    this.osName = this.httpClient?.osName;
  }

  ngOnInit(): void {
    this.getServerInfos();
  }

}
