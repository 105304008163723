import { Component, OnInit } from "@angular/core";
import { T2LayoutModel } from "../../cmp/t2-layout/layout.model";

@Component({
  selector: "app-responsive-layout",
  templateUrl: "./responsive-layout.component.html",
  styleUrls: ["./responsive-layout.component.scss"],
})
export class ResponsiveLayoutComponent implements OnInit {
  panelConfig2: T2LayoutModel = null;

  constructor() {}

  ngOnInit(): void {
    this.panelConfig2 = {
      direction: "column",
      items: [
        {
          title: "Campos",
          items: [
            {
              title: "Tipo 1 ",
              width: "100px",
              items: [
                { detail: "Inicial", box: true },
                { detail: "Final", box: true },
              ],
            },
            {
              title: "Tipo 2 ",
              width: "100px",
              items: [
                { detail: "Inicial", box: true },
                { detail: "Final", box: true },
              ],
            },
            {
              title: "Tipo 3 ",
              width: "100px",
              items: [
                { detail: "Inicial", box: true },
                { detail: "Final", box: true },
              ],
            },
            {
              title: "Tipo 4 ",
              items: [
                { detail: "Inicial", box: true },
                { detail: "Final", box: true },
              ],
            },
          ],
        },
        { title: "Bobinas", card: true },
        { title: "Colunas", card: true },
      ],
    };

    // this.panelConfig2 = {
    //   direction: 'column',
    //   items: [
    //     {
    //       detail: "Linha 1, informações principais e cabeçalho de dados",
    //       items: [
    //         {
    //           direction: 'column',
    //           width: '420px',
    //           // widthXS: '100%',
    //           items: [
    //             {
    //               directionXS: 'row',
    //               // widthXS: '100%',
    //               items: [
    //                 { card: true, component: 'indicador', width: '160px' },
    //                 { card: true, component: 'indicador', width: '160px' }
    //               ]
    //             },
    //             {
    //               card: true,
    //               directionXS: 'row',
    //               // widthXS: '100%',
    //               items: [
    //                 { component: 'indicador', width: '117px' },
    //                 { component: 'indicador', width: '117px' },
    //                 { component: 'indicador', width: '117px' }
    //               ]
    //             }
    //           ]
    //         },
    //         { box: true, component: 'deviceList' }
    //       ]
    //     },
    //     {
    //       title: 'Linha 2',
    //       items: [
    //         {
    //           direction: 'column',
    //           width: '420px',
    //           // widthXS: '100%',
    //           items: [
    //             {
    //               directionXS: 'row',
    //               // widthXS: '100%',
    //               items: [
    //                 { card: true, component: 'indicador', width: '160px' },
    //                 { card: true, component: 'indicador', width: '160px' }
    //               ]
    //             },
    //             {
    //               card: true,
    //               directionXS: 'row',
    //               // widthXS: '100%',
    //               items: [
    //                 { component: 'indicador', width: '117px' },
    //                 { component: 'indicador', width: '117px' },
    //                 { component: 'indicador', width: '117px' }
    //               ]
    //             }
    //           ]
    //         },
    //         { box: true, component: 'deviceList' }
    //       ]
    //     },
    //     {
    //       title: 'Linha 3',
    //       items: [
    //         {
    //           direction: 'column',
    //           width: '420px',
    //           // widthXS: '100%',
    //           items: [
    //             {
    //               directionXS: 'row',
    //               // widthXS: '100%',
    //               items: [
    //                 { card: true, component: 'indicador', width: '160px' },
    //                 { card: true, component: 'indicador', width: '160px' }
    //               ]
    //             },
    //             {
    //               card: true,
    //               directionXS: 'row',
    //               // widthXS: '100%',
    //               items: [
    //                 { component: 'indicador', width: '117px' },
    //                 { component: 'indicador', width: '117px' },
    //                 { component: 'indicador', width: '117px' }
    //               ]
    //             }
    //           ]
    //         },
    //         { box: true, component: 'deviceList' }
    //       ]
    //     },
    //   ]
    // }
    // this.panelConfig2 = {
    //   direction: 'column',
    //   items: [
    //     {
    //       title: 'Linha 1',
    //       items: [
    //         {
    //           title: 'coluna 1.1 ',
    //           width: '40%',
    //           widthXS: '100%',
    //           direction: 'column',
    //           items: [
    //             {
    //               title: 'Linha 1.1.1',
    //               items: [
    //                 { title: 'OPs abertas', component: 'simpleText', card: true },
    //                 { title: 'OPs iniciadas', component: 'simpleText', card: true },
    //               ]
    //             },
    //             {
    //               title: 'Linha 1.1.2', card: true,
    //               items: [
    //                 { title: 'Linha 1.2.1', component: 'simpleText' },
    //                 { title: 'Linha 1.2.2', component: 'simpleText' },
    //                 { title: 'Linha 1.2.3', component: 'simpleText' },
    //               ]
    //             },
    //           ]
    //         },
    //         {
    //           title: 'coluna 1.2 ', box: true, component: 'deviceStatusList',
    //         },
    //       ]
    //     },
    //     { title: 'Linha 2', card: true, component: 'chart' },
    //     { title: 'Linha 3', card: true, component: 'chart' },
    //     {
    //       title: 'Linha 4',
    //       items: [
    //         {
    //           title: 'coluna 4.1 ',
    //           width: '20%',
    //           direction: 'column',
    //           items: [
    //             { title: 'PV sem OPs', component: 'simpleText', card: true },
    //             { title: 'PV atrasados ', component: 'simpleText', card: true },
    //           ]
    //         },
    //         { title: 'coluna 4.2 ', card: true, component: 'gridList'},
    //       ]
    //     },
    //   ]
    // }
  }
}
