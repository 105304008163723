<div class="fillContent" [ngClass]="{'waiting': waitingDB, 'inDialog': inDialog}" [nbSpinner]="waitingDB"
  nbSpinnerStatus="primary" nbSpinnerSize="giant" [id]="t2IdCmp">

  <app-form-template #formTemplate [formTitle]="autoform?.description" [loaded]="loaded" [beta]="true"
    [changeTitle]="changeTitle" [useFlexLayout]="formTemplateUseFlexLayout" class="fillContent"
    [ngClass]="{'inDialog': inDialog}" [helpUrl]="autoform?.documentationUrl" [showHeader]="!hideHeader">

    <content class="flexColumn" style="height: calc(100% - 26px);">

      <div *ngIf="!hideHeader" style="display: flex; justify-content: space-between; align-items: center;">
        <div style="display: flex; gap: 5px;">
          <button *ngIf="buttonSave && !hideButtons" nbButton size="tiny" status="primary"
            [disabled]="formGroup.readOnly || formGroup.pristine" (click)="saveAutoform(this, true)">Salvar</button>
          <button *ngIf="inDialog && !hideButtons" nbButton size="tiny" status="basic"
            (click)="exitForm()">Fechar</button>
          <button *ngIf="buttonDelete && !hideButtons" nbButton size="tiny" status="danger"
            [disabled]="formGroup.readOnly" (click)="deleteRecord()">Excluir</button>

          <app-t2-status *ngIf="fieldStatus && !hideButtons" style="min-width: 140px;" [t2IdCmp]="t2IdCmpStatus"
            [datasetName]="statusDatasetName" [informationId]="informationId" [saveData]="saveData"
            [paramsSaveData]="{cmpReference: this}" [placeHolder]="'Status'" [formGroup]="formGroup"
            [isNewRecord]="insert" (statusChanged)="onStatusChanged($event)">
          </app-t2-status>
        </div>
        <div>
          <button *ngIf="actionExists" nbButton size="tiny" nbTooltip="Ações" (click)="getActionsList()"
            style="margin-left: 5px">
            <nb-icon icon="menu-outline"></nb-icon>
          </button>
          <button nbButton size="tiny" nbTooltip="Histórico de alterações" (click)="loadLogInformation()"
            style="margin-left: 5px">
            <nb-icon icon="calendar-outline"></nb-icon>
          </button>
        </div>
      </div>

      <app-view-template #viewTemplate *ngIf="loaded" [FormGroup]="formGroup" [layout]="layout" [data]="data"
        [actions]="actions" [flows]="flows" style="height: 100%;">
      </app-view-template>

    </content>

  </app-form-template>
</div>