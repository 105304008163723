<div class="content">
  <div class="logo_techprod"></div>
  <div class="loginCard slide-top">
    <div style="text-align: center; width: 100%; padding: 30px; font-size: 110%; font-weight: bold">
      Informe suas credencias
    </div>
    <div style="padding: 0px 30px 0px 30px;">

      <form [formGroup]="loginForm" (ngSubmit)="doLogin()">

        <div class="cardElement">
          <label for="inputEmail3">Nome</label>
          <div>
            <input type="email" nbInput fullWidth id="inputEmail3" formControlName="username" required>
          </div>
        </div>

        <div class="cardElement">
          <label for="inputSenha">Senha</label>
          <div>
            <input type="password" nbInput fullWidth id="inputSenha" formControlName="password" required>
          </div>
        </div>

        <div class="loginError">
          {{loginError}}
        </div>

        <button nbButton (click)="doLogin()" [disabled]="!loginForm.valid">Acessar</button>

        <!-- <div class="cardElement" style="margin-top: 20px;">
          <nb-checkbox [checked]="onlyDashboard" (checkedChange)="onlyDashboardChange($event)">
            Acessar apenas os <strong>Paineis</strong> e <strong>Indicadores</strong>
          </nb-checkbox>
        </div> -->


      </form>

    </div>
  </div>

</div>
