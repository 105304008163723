<app-dialog-template #dialog [title]="title" >
  <header *ngIf="topMessage">
    <p style="font-weight: bold;">{{ topMessage }}</p>
  </header>
  <content style="max-width: 90vw;">
    <p *ngIf="topMessage && !title" style="font-weight: bold;">{{ topMessage }}</p>

    <p *ngIf="message">{{ message }}</p>
    <p *ngIf="message2">{{ message2 }}</p>
    <p *ngIf="message3"> {{ message3 }} </p>
  </content>
  <footer style="max-width: 90vw;">
    <div class="button-row">
      <button *ngFor="let action of actions" nbButton [status]="action.status ?? 'primary'" size="tiny" (click)="closeDialog(action.description)">
        {{ action.description }}
      </button>
    </div>
  </footer>
</app-dialog-template>
