import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { take } from "rxjs/Operators";
import { FormTemplateComponent } from "src/app/core/cmp/form-template/form-template.component";
import { T2SecurityService } from "src/app/core/security/t2security.service";
import { WorkSchedule } from "./model/WorkSchedule";
import { T2gridComponent } from "src/app/core/cmp/t2grid/t2grid.component";
import { WorkScheduleService } from "./work-schedule.service";
import { NbDialogService } from "@nebular/theme";
import { DialogInputComponent } from "src/app/core/cmp/dialog-input/dialog-input.component";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { LayoutType } from "src/app/core/cmp/view-template/model/view-template-element";
import { T2InputTextComponent } from "src/app/core/cmp/ui/t2-input-text/t2-input-text.component";
import { T2MessageService } from "src/app/core/t2-message.service";
import { ExceptionDetail } from "./model/exception-detail";
import { ExceptionComponent } from "./exception/exception.component";
import { DatePipe } from "@angular/common";
import { DialogComponent } from "src/app/core/cmp/dialog/dialog.component";

@Component({
  selector: 'app-work-schedule',
  templateUrl: './work-schedule.component.html',
  styleUrls: ['./work-schedule.component.scss']
})
export class WorkScheduleComponent implements OnInit {

  @ViewChild("formTemplate", { static: true }) formTemplate: FormTemplateComponent;
  @ViewChild("gridExc", { static: false }) gridExc: T2gridComponent;

  public loaded = false;
  public lockCmp: boolean = false;
  public hourList = new Array(12);
  public selectedWS: WorkSchedule = null;
  public workScheduleList: Array<WorkSchedule>;

  constructor(private securityService: T2SecurityService, private workScheduleService: WorkScheduleService,
    private dialogService: NbDialogService, private messageService: T2MessageService, private changeRef: ChangeDetectorRef) { }

  ngOnInit(): void {

    this.securityService.accessLoaded()
      .pipe(
        take(1)
      ).subscribe(() => {
        if (this.formTemplate.validateAccess(['zD20220630H123319537R000000032'], 'full')) {
          this.loaded = true;

          this.loadWorkScheduleList().then(() => {
            if (this.workScheduleList.length) this.selectWorkSchedule(this.workScheduleList[0].id);
          });
        }
      });
  }

  private loadWorkScheduleList() {
    return new Promise<void>((resolve, reject) => {
      this.workScheduleService.getWorkScheduleList().then(wsList => {
        this.workScheduleList = wsList;
        resolve();
      }, error => reject(error));
    })

  }

  private configGrid() {
    this.gridExc.t2GridOptions.rowSelection = "single";
    this.gridExc.t2GridOptions.suppressRowClickSelection = false;
    this.gridExc.setGridColumnDefs([
      {
        headerName: "Início", field: "startDate", type: "dateTimeColumn", width: 130,
        cellRenderer: (params) => {
          let format: string;

          if (params.data.isRecurrent) {
            format = "dd/MM HH:mm";
          } else {
            format = "dd/MM/yyyy HH:mm";
          }

          return `
          <div style="display: flex;">
            <div class="flexLength1 flexAlignColumnCenter">
              ${(params.value ? new DatePipe("en-GB").transform(params.value || undefined, format) : "")}
            </div>
          </div>
          `;
        }
      },
      {
        headerName: "Fim", field: "endDate", type: "dateTimeColumn", width: 130,
        cellRenderer: (params) => {
          let format: string;

          if (params.data.isRecurrent) {
            format = "dd/MM HH:mm";
          } else {
            format = "dd/MM/yyyy HH:mm";
          }

          return `
          <div style="display: flex;">
            <div class="flexLength1 flexAlignColumnCenter">
              ${(params.value ? new DatePipe("en-GB").transform(params.value || undefined, format) : "")}
            </div>
          </div>
          `;
        }
      },
      { headerName: "Descrição", field: "description", width: 330 },
      {
        headerName: "Produtivo", field: "isProductive", type: "checkColumn", width: 110,
        cellStyle: params => {
          return { 'pointer-events': 'none' }
        }
      },
      {
        headerName: "Recorrente", field: "isRecurrent", type: "checkColumn", width: 110,
        cellStyle: params => {
          return { 'pointer-events': 'none' }
        }
      },
      { headerName: "Turno", field: "workShift", width: 150 },
    ]);
  }

  selectWorkSchedule(id: string) {
    if (this.selectedWS?.id != id) {
      this.workScheduleService.getWorkSchedule(id).then(ws => {
        this.selectedWS = ws;
        this.changeRef.detectChanges();

        this.selectedWS.exceptionList?.forEach(ex => {
          ex["ID"] = ex.id;
        })

        this.configGrid();
        this.gridExc.setGridData(this.selectedWS.exceptionList, null);
      }, error => {
        this.messageService.showToastError(error);
        console.error(error);
      });
    }
  }

  public addWorkSchedule() {
    let formGroup = new FormGroup({ description: new FormControl(undefined, { validators: Validators.required }) }, { updateOn: "blur" });
    let dlg = this.dialogService.open(DialogInputComponent, {
      context: {
        FormGroup: formGroup,
        topMessage: "Novo Horário de Trabalho",
        layout: [{
          layoutType: LayoutType.listLayout,
          children: [{
            layoutType: LayoutType.component,
            isBaseComponent: true,
            cmpType: T2InputTextComponent,
            cmpName: "description",
            title: "Descrição"
          }]
        }]
      }
    });

    dlg.onClose.pipe(take(1))
      .subscribe(resp => {
        if (resp == "Confirma") {
          let ws = new WorkSchedule();
          ws.description = formGroup.get("description").value;

          this.workScheduleService.saveWorkScheduleDescription(ws).then(workSchedule => {
            this.workScheduleList.push(workSchedule);
            this.selectWorkSchedule(workSchedule.id);
          }, error => {
            this.messageService.showToastError(error);
            console.error(error);
          });
        }
      });
  }

  public editWorkSchedule(ws: WorkSchedule) {
    let formGroup = new FormGroup({ description: new FormControl(ws.description, { validators: Validators.required }) }, { updateOn: "blur" });
    let dlg = this.dialogService.open(DialogInputComponent, {
      context: {
        FormGroup: formGroup,
        topMessage: "Renomear o horário de trabalho",
        layout: [{
          layoutType: LayoutType.listLayout,
          children: [{
            layoutType: LayoutType.component,
            isBaseComponent: true,
            cmpType: T2InputTextComponent,
            cmpName: "description",
            title: "Nova descrição"
          }]
        }]
      }
    });

    dlg.onClose.pipe(take(1))
      .subscribe(resp => {
        if (resp == "Confirma") {
          ws.description = formGroup.get("description").value;

          this.workScheduleService.saveWorkScheduleDescription(ws).then(workSchedule => {
            this.selectWorkSchedule(workSchedule.id);
          }, error => {
            this.messageService.showToastError(error);
            console.error(error);
          });
        }
      });
  }

  deleteWorkSchedule(ws: WorkSchedule) {
    let dlg = this.dialogService.open(DialogComponent, {
      context: {
        message2: ws.description,
        message: "Deseja realmente excluir esse horário de trabalho ?",
        actions: [{ description: "Sim" }, { description: "Não", status: "basic" }]
      }
    });

    dlg.onClose
      .pipe(take(1))
      .subscribe(resp => {
        if (resp == "Sim") {
          let id = ws.id;

          this.workScheduleService.deleteWorkSchedule(id).then(() => {
            this.workScheduleList = this.workScheduleList.filter(ws => ws.id != id);

            if (this.selectedWS?.id == id) {
              this.selectedWS = undefined;
              this.gridExc.setGridData([], null);
            }
          }, error => {
            this.messageService.showToastError(error);
            console.error(error);
          })
        }
      })
  }

  editException(event): void {
    let exception: ExceptionDetail;

    if (!event) {
      exception = new ExceptionDetail();
    } else {
      exception = event.data;
    }

    let dlg = this.dialogService.open(ExceptionComponent, {
      context: {
        description: exception.description,
        startDate: exception.startDate,
        endDate: exception.endDate,
        id_workShift: exception.id_workShift,
        productive: exception.isProductive,
        recurrent: exception.isRecurrent
      }
    });

    dlg.onClose.pipe(take(1)).subscribe(resp => {
      if (resp) {
        exception.description = resp.description;
        exception.startDate = resp.startDate;
        exception.endDate = resp.endDate;
        exception.id_workShift = resp.id_workShift;
        exception.workShift = resp.workShift;
        exception.isProductive = resp.productive;
        exception.isRecurrent = resp.recurrent;

        this.workScheduleService.saveException(this.selectedWS.id, exception).then(ex => {
          exception = ex;

          if (!event) {
            exception["ID"] = exception.id;
            this.selectedWS.exceptionList.push(exception);
            this.gridExc.setGridData(this.selectedWS.exceptionList, null);
          } else {
            let rowNodes = this.gridExc.getSelectedNodes();
            this.gridExc.redrawRows({ rowNodes: rowNodes });
          }

        }, error => {
          this.messageService.showToastError(error);
          console.error(error);
        });
      }
    });
  }

  deleteException(): void {
    let rowSelected = this.gridExc.getRowsSelected()[0];

    this.workScheduleService.deleteWorkScheduleException(rowSelected.id).then(() => {
      this.selectedWS.exceptionList = this.selectedWS.exceptionList.filter(ex => ex.id != rowSelected.id);
      this.gridExc.setGridData(this.selectedWS.exceptionList, null);
    }, error => {
      this.messageService.showToastError(error);
      console.error(error);
    });
  }

  tabChange(event) {
    if (event.tabTitle == "Exceções") {
      setTimeout(() => {
        this.configGrid();
      }, 100);
    }
  }

  recreateCalendar() {
    this.lockCmp = true;
    this.workScheduleService.recreateCalendar(this.selectedWS.id).subscribe(resp => {
      this.lockCmp = false;
    }, error => {
      this.lockCmp = false;
      this.messageService.showToastError(error);
      console.error(error);
    });
  }

  public duplicateWorkSchedule(ws: WorkSchedule) {
    let formGroup = new FormGroup({ description: new FormControl("Cópia de " + ws.description, { validators: Validators.required }) }, { updateOn: "blur" });
    let dlg = this.dialogService.open(DialogInputComponent, {
      context: {
        FormGroup: formGroup,
        topMessage: "Duplicar o horário de trabalho",
        layout: [{
          layoutType: LayoutType.listLayout,
          children: [{
            layoutType: LayoutType.component,
            isBaseComponent: true,
            cmpType: T2InputTextComponent,
            cmpName: "description",
            title: "Descrição do novo horário de trabalho"
          }]
        }]
      }
    });

    dlg.onClose.pipe(take(1))
      .subscribe(resp => {
        if (resp == "Confirma") {
          this.lockCmp = true;
          this.workScheduleService.duplicateWorkSchedule(ws.id, formGroup.get("description").value).then(() => {
            this.loadWorkScheduleList().then(() => {
              this.selectWorkSchedule(this.workScheduleList.find(ws => ws.description == formGroup.get("description").value).id);
              this.lockCmp = false;
            })
          }, error => {
            this.messageService.showToastError(error);
            console.error(error);
            this.lockCmp = false;
          })
        }
      });
  }
}
