export class DeviceOperationInput {
  id_operationGroup_it: string;
  id_operationGroup_op_it: string;
  id_operationType: string;
  functionality: 'Production' | 'Scrap' | 'Consumption' | 'ConsumptionReturn' | 'ProductionReturn';
  inputLabel: string;
  inputCode: string;
  id_unit: string;
  unitSymbol: string;
  unitDescription: string;
  id_operation_stockAddress_origin: string;
  id_operation_stockAddress_destination: string;
  operationDevol: boolean;
  operationType: string;
  showCurrentQty: boolean;
  showEstimatedQty: boolean;
  sourceType: string;
  autoInput: boolean;
  autoInputId: string;
  fillConsumptionQty: boolean;
  allowVolumeTyping: boolean;
  showRequisitionVolumes: boolean;
  suggestConsumptionQty: string;
  fillConsumptionReturnQty: boolean;
  decimals: number;
  stockOperationOriginValidation: 'Allow any Origin' | 'Allow only the specified';
  stockOperationDestinationValidation: 'Allow any Destination' | 'Allow only the specified';
}
