import { Component, OnInit, ViewChild } from '@angular/core';
import { T2SeparationService, TaskRequisition } from "./t2separation.service";
import { take } from "rxjs/Operators";
import { FormTemplateComponent } from "src/app/core/cmp/form-template/form-template.component";
import { T2SecurityService } from "src/app/core/security/t2security.service";

@Component({
  selector: 'app-separation',
  templateUrl: './separation.component.html',
  styleUrls: ['./separation.component.scss']
})
export class SeparationComponent implements OnInit {

  @ViewChild('formTemplate', { static: true }) formTemplate: FormTemplateComponent;

  public separationType: string;
  public loaded: boolean;
  public showTransfer: boolean = false;
  public transferRequisition: TaskRequisition;
  public loadingTransfer: boolean;
  private actionCoordinatorId = "zD20230719H145209791R000000042";
  public hasCoordinatorAccess: boolean;

  constructor(private separationService: T2SeparationService, private sec: T2SecurityService) {

  }

  ngOnInit(): void {
    this.sec.accessLoaded()
      .pipe(
        take(1)
      ).subscribe(() => {
        if (this.formTemplate.validateAccess(['zD20161201H194737503R000000001', this.actionCoordinatorId], 'dataCollector')) {
          this.hasCoordinatorAccess = this.sec.hasAction(this.actionCoordinatorId);
          this.separationService.getProductionOrderSeparationType().then(sepType => {
            this.separationType = sepType;

            this.loaded = true;
          })
        }
      });
  }

  startTransfer(taskRequisition: TaskRequisition) {
    this.transferRequisition = taskRequisition;
    this.showTransfer = true;
  }

  btnVoltarClick() {
    this.showTransfer = false;
  }

  loadingInfoTransfer(loading: boolean) {
    this.loadingTransfer = loading;
  }

  transferCompleted() {
    this.showTransfer = false;
  }

}
