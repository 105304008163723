import { Injectable } from '@angular/core';
import { AbstractControlOptions, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { T2SecurityService } from "../../security/t2security.service";
import { ComponentService } from "../component.service";
import { ActionType, T2ViewTemplateAction } from "./model/t2-view-template-action";
import { T2ViewTemplateData } from "./model/t2-view-template-data";
import { T2ViewTemplateFlow } from "./model/t2-view-template-flow";
import { LayoutType, ViewTemplateElement } from "./model/view-template-element";
import { T2ViewTemplateFunctionsService } from "./t2-view-template-functions.service";

@Injectable()

export class ViewTemplateService {

  constructor(private formBuilder: FormBuilder, private cmpService: ComponentService, private securityService: T2SecurityService,
    private funcService: T2ViewTemplateFunctionsService) { }

  public generateViewTemplateInputs(jsonObj: {
    layout: any,
    data?: any,
    flows?: any,
    actions?: any,
    title?: string
  }, formGroupOptions?: AbstractControlOptions): {
    layout: Array<ViewTemplateElement>,
    data: Array<T2ViewTemplateData>,
    flows: Array<T2ViewTemplateFlow>,
    actions: Array<T2ViewTemplateAction>,
    formGroup: FormGroup,
    title?: string
  } {
    let layout: Array<ViewTemplateElement>,
      data: Array<T2ViewTemplateData>,
      flows: Array<T2ViewTemplateFlow>,
      actions: Array<T2ViewTemplateAction>,
      formGroup = this.formBuilder.group({}, formGroupOptions),
      title: string;

    layout = (jsonObj.layout as Array<ViewTemplateElement>);

    data = jsonObj.data ? (jsonObj.data as Array<T2ViewTemplateData>) : [];
    flows = jsonObj.flows ? (jsonObj.flows as Array<T2ViewTemplateFlow>) : [];
    actions = jsonObj.actions ? (jsonObj.actions as Array<T2ViewTemplateAction>) : [];

    flows.filter(f => !f.action).forEach(flow => {
      if (flow.actionName) {
        flow.action = actions.find(f => f.actionName.toLowerCase() == flow.actionName.toLowerCase());
      }
    })

    layout.forEach(l => {
      this.addCmpToFormGroup(l, formGroup, data, flows, actions);
    });

    title = jsonObj.title;

    return {
      layout: layout,
      data: data,
      flows: flows,
      actions: actions,
      formGroup: formGroup,
      title: title
    }
  }

  private addCmpToFormGroup(layout: ViewTemplateElement, formGroup: FormGroup, data: Array<T2ViewTemplateData>, flows: Array<T2ViewTemplateFlow>, actions: Array<T2ViewTemplateAction>) {
    if (layout.children) {
      layout.children.forEach(l => this.addCmpToFormGroup(l, formGroup, data, flows, actions));
    }

    if (layout.layoutType == LayoutType.component) {
      if (!layout.cmpType) {
        throw new Error("É obrigatório informar o \"cmpType\" do componente " + layout.cmpName);
      }

      layout.cmpType = this.cmpService.getType(layout.cmpType.toString());

      if (!layout.cmpType) {
        throw new Error(`O tipo do componente ${layout.cmpName} não foi encontrado`);
      }

      if (layout.required) {
        formGroup.addControl(layout.cmpName, this.formBuilder.control(data.find(d => d.cmpName == layout.cmpName)?.properties["value"], Validators.required));
      } else {
        formGroup.addControl(layout.cmpName, this.formBuilder.control(data.find(d => d.cmpName == layout.cmpName)?.properties["value"]));
      }

      const templateData = {
        cmpName: layout.cmpName,
        properties: {}
      };

      if (layout.inputs) {
        Object.keys(layout.inputs).forEach(key => {
          templateData.properties[key] = layout.inputs[key];
        });

        layout.inputs = undefined;
      }

      data.push(templateData);

      if (layout.actionId) {
        this.securityService.getAction(layout.actionId).subscribe(ac => {
          let index: number;
          index = actions.push({ type: ActionType.runAction, action: ac, adjustResponse: this.funcService.returnScriptResponse }) - 1;

          flows.push({ triggerCmpNameList: [layout.cmpName], action: actions[index], inputs: layout.flowsInput });
        });
      }
    }
  }
}
