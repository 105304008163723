<div class="flexColumn flexGap10" style="height: 100%; padding: 5px 5px 0px 5px; " [ngClass]="{'T2GrayArea': holdConjugation }"
  [nbSpinner]="lockScreen" nbSpinnerStatus="primary" nbSpinnerSize="giant">

  <!-- demandas agrupados/conjugados -->
  <div class="flexColumn" style="height: 55%; gap: 5px ">

    <!-- titulo -->
    <div style="display: flex; gap: 5px; align-items: center; justify-content: space-between;">
      <div class="defaultSmallTitle">Itens Conjugados</div>
      <div *ngIf="conjugationInfo?.itemList?.length">
        <nb-icon *ngIf="conjugationInfo?.itemList?.length" style="cursor: pointer;"
          [icon]="holdConjugation ? 'checkmark-square-outline' : 'square-outline'"
          [nbTooltip]="holdConjugation ? 'Desafixar conjugação' : 'Fixar conjugação'"
          (click)="setHoldConjugation()"></nb-icon>
        <!--
        <app-t2-button status="primary" size="tiny" icon="unlock-outline"
          [disabled]="conjugationInfo?.itemList?.length < 1" (clicked)="setHoldConjugation()" /> -->
      </div>
    </div>

    <!-- Itens agrupados/conjugados -->
    <div class="flexColumn" style="height: 100%; gap: 5px; overflow-y: auto;">
      <div *ngFor="let item of conjugationInfo?.itemList; let index = index" class="T2GrayArea itemConjLine">

        <div class="itemConj">

          <!-- seq -->
          <div class="itemConjInfo" style="width: 1rem;">
            <span class="defaultSmallText" style="text-align: center; font-weight: bold;">
              {{index + 1}}
            </span>
          </div>

          <!-- Origem -->
          <div class="itemConjInfo" style="width: 7rem;">
            <!-- <span class="defaultTinyTitle">{{item.type}}</span> -->
            <span class="defaultSmallText" style="text-align: center;">
              {{item.type == 'Pedido Venda' ? 'PV ' : 'Solic '}}
              {{item.code}}
            </span>
          </div>

          <!-- Item -->
          <div class="itemConjInfo" style="width: 100%;">
            <!-- <span class="defaultTinyTitle">Item</span> -->
            <span class="defaultSmallText">{{item.itemDescription}}</span>
          </div>

          <!-- Quantidade -->
          <div class="itemConjInfo" style="width: 5rem; text-align: end;">
            <!-- <span class="defaultTinyTitle">Qtd</span> -->
            <span class="defaultSmallText">{{item.quantity}} {{'KG'}} </span>
            <!-- TODO: CARREGAR UNIDADE DO MATERIAL -->
          </div>

          <!-- Entrega -->
          <div class="itemConjInfo" style="width: 3rem; text-align: end;">
            <!-- <span class="defaultTinyTitle">Entrega</span> -->
            <span class="defaultSmallText">{{item.deliveryDate | date: 'shortDate'}}</span>
          </div>
        </div>

        <div id="editButtons">
          <div class="deleteFloatButton" style="height: 1.27rem !important; width: 20px !important;"
            nbTooltip="Remover demanda da conjugação"
            (click)="$event.stopPropagation(); removeDemandFromConjugation(item);">
            <nb-icon icon="close-circle-outline"></nb-icon>
          </div>
        </div>

      </div>

    </div>

  </div>

  <!-- EPs conjugadas existentes -->
  <div class="flexColumn" style="height: 45%; gap: 5px ">

    <!-- titulo -->
    <div style="display: flex; gap: 5px; align-items: center; justify-content: space-between;">
      <div class="defaultSmallTitle">EPs Compatíveis</div>
      <div>
        <app-t2-button status="primary" size="tiny" value="Criar nova EP"
          [disabled]="conjugationInfo?.itemList?.length < 2" (clicked)="createEpForConj()" />
      </div>
    </div>

    <!-- Itens agrupados/conjugados -->
    <app-t2grid #gridConjCompat [pagination]="false" [loading]="loading" [components]="gridComponent"
      style="height: 100%;" />
  </div>

</div>
