import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class T2LocalStorageService {

  constructor() {}

  public setData(name: string, data: any) {
    localStorage.setItem(name, data);
  }

  public getData(name: string) {
    const value = localStorage.getItem(name);
    return value;
  }

  public removeData(name: string) {
    return localStorage.removeItem(name);
  }

  public setSessionData(name: string, data: any) {
    sessionStorage.setItem(name, data);
  }

  public getSessionData(name: string) {
    const value = sessionStorage.getItem(name);
    return value;
  }

  public removeSessionData(name: string) {
    return sessionStorage.removeItem(name);
  }

}
