<div *ngIf="loaded" class="flexColumn flexGap5">
  <span class="defaultTitle">{{itemDescription}}</span>
  <div *ngIf="!userDevService.isSmallScreen" class="flexRow flexWrap flexGap5">
    <div class="flexRow flexGap5">
      <div class="flexColumn divCmp">
        <span class="cmpLabel">Volume</span>
        <app-t2-input-text t2IdCmp="volume" [formGroup]="formGroup" />
      </div>
    </div>
    <div class="flexAlignRowEndCenter">
      <div class="flexColumn divCmp">
        <span class="cmpLabel">Quantidade</span>
        <app-t2-input-float #qtyInput t2IdCmp="quantidade" [formGroup]="formGroup" />
      </div>
    </div>
  </div>

  <div *ngIf="userDevService.isSmallScreen" class="flexColumn flexGap5">
    <div class="flexRow flexGap5">
      <div class="flexColumn divCmp">
        <span class="cmpLabel">Volume</span>
        <app-t2-input-text t2IdCmp="volume" [formGroup]="formGroup" />
      </div>
    </div>
    <div class="flexColumn divCmp">
      <span class="cmpLabel">Quantidade</span>
      <app-t2-input-float #qtyInput t2IdCmp="quantidade" [formGroup]="formGroup" />
    </div>
  </div>

  <div class="flexColumn">
    <span class="defaultSmallTitle">Volumes Produzidos</span>
    <div
      [ngClass]="{'volumeList': !userDevService.isXSmallScreen, 'volumeListXSmallScreen': userDevService.isXSmallScreen}">
      <div *ngFor="let volume of volumeList" class="detailBox volume" (click)="selectVolume(volume)">
        <div class="flexColumn flexGap5">
          <div class="flexAlignRowSpaceBetweenStart">
            <span class="defaultSmallTitle">{{volume.code}}</span>
            <div class="flexAlignColumnStartEnd">
              <span class="defaultSmallTitle">{{volume.stockCode}}</span>
              <span class="detailBoxValue">{{volume.stockDescr}}</span>
            </div>
          </div>
          <div class="flexAlignRowSpaceBetweenStart flexGap5" style="line-height: 0.7rem;">
            <div class="flexAlignRowSpaceBetweenStart flexGap5">
              <div class="flexAlignColumnStartEnd">
                <span class="detailBoxTitle">Saldo</span>
                <span class="detailBoxValue">{{volume.quantity | number: '1.1-2' : locale}} {{volume.itemUnit}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
