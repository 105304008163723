import { Component, OnInit, ViewChild } from "@angular/core";
import { FormTemplateComponent } from "../../cmp/form-template/form-template.component";
import { T2SecurityService } from "src/app/core/security/t2security.service";
import { take } from "rxjs/Operators";
import { T2gridComponent } from "src/app/core/cmp/t2grid/t2grid.component";
import { ColDef } from "ag-grid-community";
import { T2HttpClientService } from "../../http/t2httpClient.service";
import { DatasetActionAccessRendererComponent } from "./dataset-action-access-renderer/dataset-action-access-renderer.component";

@Component({
  selector: "app-group-access-control",
  templateUrl: "./group-access-control.component.html",
  styleUrls: ["./group-access-control.component.scss"]
})
export class GroupAccessControlComponent implements OnInit {

  loaded = false;
  private roleGroups = [];
  private functionalityGroups = [];
  selectedFunctionality: any = null;
  accessGridAutoGroupColumnDef = { headerName: "Informações", cellRendererParams: { suppressCount: true }, };
  accessGridComponents = {
    datasetActionAccess: DatasetActionAccessRendererComponent,
  };

  @ViewChild("formTemplate", { static: true }) formTemplate: FormTemplateComponent;
  @ViewChild("groupGrid", { static: true }) groupGrid: T2gridComponent;
  @ViewChild("accessGrid", { static: true }) accessGrid: T2gridComponent;

  constructor(private httpClient: T2HttpClientService, private sec: T2SecurityService) { }

  ngOnInit(): void {
    this.sec.accessLoaded()
      .pipe(
        take(1)
      ).subscribe(() => {
        if (this.formTemplate.validateAccess(["zD20210421H184813125R000000012"], 'full')) {
          this.loadGroupsData();
        }
      });
  }


  private loadGroupsData(): void {

    this.httpClient.get(`core.sec.access/accessGroups`, null)
      .pipe(take(1))
      .subscribe((resp: any) => {

        if (resp.functionalityGroups) {
          if (!Array.isArray(resp.functionalityGroups)) {
            resp.functionalityGroups = [resp.functionalityGroups];
          }

          this.functionalityGroups = resp.functionalityGroups;
        }

        if (resp.roleGroups) {
          if (!Array.isArray(resp.roleGroups)) {
            resp.roleGroups = [resp.roleGroups];
          }

          this.roleGroups = resp.roleGroups;
        }

        this.setGroupGridData();

        this.loaded = true;
      });
  }

  private setGroupGridData() {
    // Group grid columns
    const groupColumns: Array<ColDef> = [
      {
        headerName: "",
        field: "functionality",
        pinned: "left",
        lockPinned: true,
        // maxWidth: 200,
        cellStyle: { "font-weight": "bold" }
      }
    ];
    this.roleGroups.forEach((group, index) => {
      if (!group.memberOf) {
        group.memberOf = [];
      } else if (!Array.isArray(group.memberOf)) {
        group.memberOf = [group.memberOf];
      }
      group.field = "field" + index;
      groupColumns.push({
        headerName: group.description, field: group.field, cellStyle: { "text-align": "center" }
      });
    });
    this.groupGrid.setGridColumnDefs(groupColumns);

    // Group grid rows
    const groupData = [];
    this.functionalityGroups.forEach(group => {
      if (!group.memberOf) {
        group.memberOf = [];
      } else if (!Array.isArray(group.memberOf)) {
        group.memberOf = [group.memberOf];
      }

      const data = { ID: group.id_security_identity, functionality: group.description };
      this.roleGroups.forEach(item => {

        const memberOf: Array<any> = item.memberOf;
        data[item.field] = (memberOf.find((m: string) => m === group.id_security_identity)) ? "Sim" : null;
      });

      groupData.push(data);
    });
    this.groupGrid.setGridData(groupData, null);
  }

  gridCellKeyPress(event) {
    const data = event.data;
    data[event.field] = data[event.field] === "Sim" ? null : "Sim";

    const role = this.roleGroups.find(item => item.field === event.field);
    if (role) {
      const obj = {
        id_secutity_identity_functionality: data.ID,
        id_security_identity_member: role.id_security_identity
      };

      const action: string = data[event.field] === "Sim" ? "add" : "remove";
      this.httpClient.post(`core.sec.access/groupFunctionalityMember/${action}`, null, obj)
        .subscribe(resp => {

          // Se gravou a alteração com sucesso, altera a informacao visualmente no GRID
          event.node.setData(data);
        });
    }
  }

  gridRowClick(event) {
    this.selectedFunctionality = this.functionalityGroups.find(item => item.id_security_identity === event?.data.ID);

    // Group grid columns
    const accessColumns: Array<ColDef> = [
      // {
      //   headerName: "Informação",
      //   field: "action",
      //   // pinned: "left",
      //   // lockPinned: true,
      //   cellStyle: { "font-weight": "bold" }
      // },
      { headerName: "Visualizar", field: "viewAction", width: 100, cellRenderer: "datasetActionAccess" },
      { headerName: "Inserir", field: "insertAction", width: 100, cellRenderer: "datasetActionAccess" },
      { headerName: "Editar", field: "editAction", width: 100, cellRenderer: "datasetActionAccess" },
      { headerName: "Excluir", field: "deleteAction", width: 100, cellRenderer: "datasetActionAccess" },
    ];
    this.accessGrid.setGridColumnDefs(accessColumns);

    const accessData = [
      {
        hier: ["Clientes"],
        action: "Clientes", viewAction: "allow", insertAction: "allow", editAction: "allow", deleteAction: "deny"
      },
      {
        hier: ["Clientes", "Contato dos clientes"],
        action: "Contato dos clientes", viewAction: "allow", insertAction: "allow", editAction: "allow", deleteAction: "unset"
      },
      {
        hier: ["Clientes", "Contato dos clientes", "Emails"],
        action: "Emails",  insertAction: "allow", editAction: "allow", deleteAction: "unset"
      },
      { hier: ["Pedido de Venda"],
      action: "Pedido de Venda", viewAction: "allow", insertAction: "allow", editAction: "unset", deleteAction: "unset" },
      {
        hier: ["Pedido de Venda", "Item do pedido de venda"],
        action: "Item do pedido de venda",
        viewAction: "allow",
        insertAction: "allow",
        editAction: "allow",
        deleteAction: "deny"
      },
      {
        hier: ["Pedido de Venda", "Entregas"],
        action: "Entregas",
        viewAction: "allow",
        insertAction: "allow",
        editAction: "deny",
        deleteAction: "deny"
      },
      {
        hier: ["Pedido de Venda", "Itens X Entregas"],
        action: "Itens X Entregas", viewAction: "allow", insertAction: "allow", editAction: "allow", deleteAction: "deny"
      },
      {
        hier: ["Pedido de Venda", "Terceirizações"],
        action: "Terceirizações", viewAction: "allow", insertAction: "allow", editAction: "allow", deleteAction: "deny"
      },
      {
        hier: ["Pedido de Venda", "Terceirizações", "Itens das terceirizações"],
        action: "Itens das terceirizações", viewAction: "unset", insertAction: "unset", editAction: "unset", deleteAction: "deny"
      },
    ];

    this.accessGrid.setGridData(accessData, null);
  }

}
