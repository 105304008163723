import { AfterViewInit, Component, OnDestroy, ViewChild } from '@angular/core';
import { FormBuilder } from "@angular/forms";
import { ICellEditorAngularComp } from "ag-grid-angular";
import { ICellEditorParams } from "ag-grid-community";
import { skip, take, takeUntil } from "rxjs/Operators";
import { T2StatusComponent } from "../../../ui/t2-status/t2-status.component";
import { T2Status } from "../../../ui/t2-status/model/t2-status";
import { NbDialogService } from "@nebular/theme";
import { DialogComponent } from "../../../dialog/dialog.component";
import { Subject } from "rxjs";

@Component({
  selector: 'app-status-editor',
  templateUrl: './status-editor.component.html',
  styleUrls: ['./status-editor.component.scss']
})
export class StatusEditorComponent implements AfterViewInit, ICellEditorAngularComp, OnDestroy {

  @ViewChild("statusCmp", { static: true }) statusCmp: T2StatusComponent;

  public params: ICellEditorParams;
  public datasetName: string;
  public informationId: string;
  public currentStatus: T2Status;
  private unsubscribe = new Subject<void>();

  constructor(private dlgService: NbDialogService) { }
  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  ngAfterViewInit(): void {
    // focus on the select
    //setTimeout(() => this.select.element.nativeElement.focus());
  }

  agInit(params: ICellEditorParams): void {
    this.params = params;
    this.datasetName = params.data["statusInfo"]["datasetName"];
    this.informationId = params.data["statusInfo"]["informationId"];

    let dlg = this.dlgService.open(T2StatusComponent, {
      context: {
        datasetName: this.datasetName,
        informationId: this.informationId,
        visible: true,
        showOptionsForDialog: true
      }
    });

    dlg.componentRef.instance.statusChanged.pipe(take(1)).subscribe(params => {
      this.currentStatus = params.status;
    });
    
    dlg.onClose.pipe(take(1)).subscribe(status => {
      if (status) {
        this.params.value = status.name;
      }

      this.params.api.stopEditing();
    });
  }

  getValue() {
    return this.params.value;
  }

}
