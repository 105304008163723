<app-form-template #formTemplate formTitle="Liberação de IPs para acesso remoto" [loaded]="formLoaded">
  <header>
    <button nbButton status="primary" size="tiny" (click)="salvarIp()" [nbSpinner]="saving"
      [disabled]="!formValid || saving" *ngIf="!noAWSServiceAvailable">Salvar</button>
  </header>

  <content>
    <div *ngIf="!noAWSServiceAvailable">
      <div style="margin-top: 10px;"></div>

      <div [ngStyle.xs]="{'display': 'none'}" style="display: flex; gap: 5px; padding: 0px 15px;">
        <div class="ipSeq">#</div>
        <div class="ipDescr">Descrição</div>
        <div class="ipNumber">IP</div>
      </div>

      <div  *ngFor="let ip of ipList; let pos = index" class="T2GrayArea flexColumn"
       style="gap: 3px; padding: 7px 15px; margin-bottom: 5px;">
        <div style="display: flex; gap: 5px; flex-wrap: wrap;">
          <div class="ipSeq">{{pos + 1}}</div>
          <div class="ipDescr"><input nbInput fullWidth fieldSize="small" [(ngModel)]="ip.description" required
              (ngModelChange)="validateForm()"></div>
          <div class="ipNumber"><input nbInput fullWidth fieldSize="small" [(ngModel)]="ip.ip" required
              (ngModelChange)="validateForm()"></div>
          <button nbButton size="tiny" status="danger" (click)="excluirIp(pos)">Excluir</button>
        </div>
        <div>
          <div [ngStyle]="{'margin': '-3px 0px 10px 322px'}" [ngStyle.xs]="{'margin': '0px'}" class="ipError"
            *ngIf="ip.errorList?.length">{{ip.errorList[0]}}</div>
        </div>
      </div>

      <div style="display: flex; gap: 5px; margin-top: 10px; padding: 7px 15px; margin-bottom: 5px;">
        <div class="ipSeq"></div>
        <div class="ipDescr"></div>
        <div class="ipNumber"></div>
        <button nbButton status="basic" size="tiny" (click)="adicionarIp()" [disabled]="maxIP <= ipList.length">Adicionar</button>
      </div>
    </div>
    <div *ngIf="noAWSServiceAvailable" class="awsServiceAvailabilityMsg">Não existem serviços na nuvem da TECH2E para a sua empresa.</div>

  </content>

</app-form-template>
