import { Injectable } from '@angular/core';
import { Observable } from "rxjs";
import { map, skipWhile, take } from "rxjs/Operators";
import { RequisitionVolume } from "src/app/bsn/stk/reservation-requisition/model/requisition-volume";
import { T2HttpClientService } from "src/app/core/http/t2httpClient.service";
import { T2SecurityService } from "src/app/core/security/t2security.service";
import { v4 as uuidv4 } from 'uuid';

@Injectable({
  providedIn: 'root'
})
export class T2SeparationService {

  constructor(private httpClient: T2HttpClientService, private secService: T2SecurityService) { }

  public getProductionOrderSeparationType(): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      this.secService.companySiteObservable().pipe(skipWhile(compId => !compId), take(1)).subscribe(compId => {
        let params = new Map<string, string>();
        params.set("id_companySite", this.httpClient.id_companySite)

        this.httpClient.get("core.companySite/getCompanySite", params).pipe(take(1)).subscribe(resp => {
          let tipo: string;

          if (resp.companySite) {
            tipo = resp.companySite.tipoPickingOP;
          }

          resolve(tipo);
        }, error => reject(error));

      }, error => reject(error));
    })
  }

  private retrievePendingRequisitions(params?: Map<string, string>): Observable<Array<TaskRequisition>> {
    if (!params) {
      params = new Map();
    }

    params.set("loadVolumes", "false");
    return this.httpClient.get("bsn.production.prodOrder/pendingRequests", params).pipe(take(1), map(resp => {
      let requisitionList = new Array<TaskRequisition>();
      if (resp && resp.pendingRequests) {
        if (!Array.isArray(resp.pendingRequests)) {
          resp.pendingRequests = [resp.pendingRequests];
        }

        resp.pendingRequests.forEach(pr => {
          let req = new TaskRequisition();
          req.sequence = new Number(pr.seqSeparacao || 0).valueOf();
          req.requestId = pr.id_itemRequest;
          req.requestCode = pr.code;
          req.taskId = pr.id_task;
          req.prodOrderId = pr.prodOrderId;
          req.prodOrderCode = pr.prodOrderCode;
          req.prodOrderDescription = pr.prodOrderDescription;
          req.deviceDescription = pr.deviceDescr;
          req.itemId = pr.id_item;
          req.taskDescription = pr.taskDescr;
          req.itemDescription = pr.itemDescr;
          req.itemUnit = pr.itemUnit;
          req.requestedQty = new Number(pr.requestedQty).valueOf();
          req.reservedQty = new Number(pr.reservedQty || 0).valueOf();
          req.transferredQty = new Number(pr.transfQty || 0).valueOf();
          req.consumedQty = new Number(pr.consumedQty || 0).valueOf();
          req.requestedDate = pr.dateReq ? new Date(pr.dateReq) : undefined;
          req.requestedUser = pr.userReq;
          req.separationTeamId = pr.separationTeamId;
          req.separationTeam = pr.separationTeam;
          req.prodOrderSeparationTeam = pr.prodOrderSeparationTeam;
          req.planningDate = new Date(pr.planningDate);
          req.taskNote = pr.taskNote;
          req.salesOrderNote = pr.salesOrderNote;

          requisitionList.push(req);
        });

        requisitionList.sort((a, b) => a.sequence - b.sequence);

        requisitionList.forEach((pr, index, arr) => {
          pr.sequence = index + 1;
        });
      }

      return requisitionList;
    }));
  }

  public getPendingRequests(hasCoordinatorAccess: boolean): Observable<Array<TaskRequisition>> {
    let params = new Map();
    params.set("apenasEquipe", !hasCoordinatorAccess);

    return this.retrievePendingRequisitions(params);
  }

  public getRequestVolumes(prodOrderId: string, requestId: string, itemId: string): Observable<{ volumeList: Array<VolumeInfo>, transferredVolumeList: Array<VolumeInfo> }> {
    let params = new Map<string, string>();
    params.set("id_ordemProd", prodOrderId);
    params.set("id_requisicao", requestId);
    params.set("id_item", itemId);

    return this.httpClient.get("bsn.production.prodOrder/getRequestVolumes", params).pipe(take(1), map(resp => {
      let volumeList = new Array<VolumeInfo>();
      let transferredVolumeList = new Array<VolumeInfo>();

      if (resp && (resp.volumeList || resp.transferredVolumeList)) {
        if (resp.volumeList && !Array.isArray(resp.volumeList)) {
          resp.volumeList = [resp.volumeList];
        }

        if (resp.transferredVolumeList && !Array.isArray(resp.transferredVolumeList)) {
          resp.transferredVolumeList = [resp.transferredVolumeList];
        }

        resp.volumeList?.forEach(irv => {
          let vol = new VolumeInfo();
          vol.volumeId = irv.id_volume;
          vol.code = irv.volumeCode;
          vol.stockAddressId = irv.volumeAddressId;
          vol.stockAddressCode = irv.volumeAddress;
          vol.stockAddressDescription = irv.volumeFullAddress;
          vol.quantity = new Number(irv.quantidade || 0).valueOf();
          vol.creationDate = new Date(irv.dtCriacao);
          vol.expirationDate = irv.dtValidade ? new Date(irv.dtValidade) : undefined;

          volumeList.push(vol);
        });

        resp.transferredVolumeList?.forEach(vt => {
          let vol = new VolumeInfo();
          vol.volumeId = vt.id_volume;
          vol.code = vt.codigo;
          vol.stockAddressId = vt.id_localestoque_3;
          vol.stockAddressCode = vt.local;
          vol.stockAddressDescription = vt.localDescricao;
          vol.quantity = new Number(vt.quantidade || 0).valueOf();
          vol.creationDate = new Date(vt.dtCriacao);
          vol.expirationDate = vt.dtValidade ? new Date(vt.dtValidade) : undefined;

          transferredVolumeList.push(vol);
        })
      }

      volumeList.sort((a, b) => {
        let resp: number = 0;

        resp = a.expirationDate?.getTime() - b.expirationDate?.getTime();

        if (resp == 0) {
          resp = a.creationDate?.getTime() - b.creationDate?.getTime();
        }

        return resp;
      });

      transferredVolumeList.sort((a, b) => {
        let resp: number = 0;

        resp = a.expirationDate?.getTime() - b.expirationDate?.getTime();

        if (resp == 0) {
          resp = a.creationDate?.getTime() - b.creationDate?.getTime();
        }

        return resp;
      });

      return { volumeList: volumeList, transferredVolumeList: transferredVolumeList };
    }));
  }

  public getProductionOrderPendingRequisitions(prodOrderCode: string): Observable<Array<TaskRequisition>> {
    let params = new Map<string, string>();
    params.set("codigoOP", prodOrderCode);

    return this.retrievePendingRequisitions(params);
  }

  public getTransferOperations(id_task?: string): Observable<Array<TransferOperation>> {
    let params = new Map<string, string>();
    params.set("operType", "Transferencia");

    if (id_task) {
      params.set("id_task", id_task);
    }

    return this.httpClient.get("stk.operation/getAvailableOperations", params).pipe(take(1), map(resp => {
      let transfOperationList = new Array<TransferOperation>();

      if (resp?.operList) {
        if (!Array.isArray(resp.operList)) {
          resp.operList = [resp.operList];
        }

        resp.operList.forEach(oper => {
          if (["Requisição de Ordem de Produção", "Ordem de Produção (atividades produtivas)"].includes(oper.referencia)) {
            let transfOper = new TransferOperation();
            transfOper.operationId = oper.id_tipoOperacao;
            transfOper.code = oper.codigo;
            transfOper.description = oper.descricao;
            transfOper.originStockAddressId = oper.id_localestoque_origem;
            transfOper.destinationStockAddressId = oper.id_localestoque_hierClass;
            transfOper.isDevolution = oper.devolucao;
            transfOper.stockAddressCode = oper.localCodigo;
            transfOper.stockAddressDescription = oper.localDescr;
            transfOper.lockOrigin = (oper.id_localestoque_origem && oper.validacaoOrigem == "Apenas do local de origem configurado");
            transfOper.lockDestination = (oper.id_localestoque_hierClass && oper.validacaoDestino == "Apenas para o destino configurado");
            transfOper.stockAddressCodeOrigin = oper.localOrigemCodigo;
            transfOper.stockAddressDescriptionOrigin = oper.localOrigemDescricao;

            transfOperationList.push(transfOper);
          }
        })
      }

      return transfOperationList;
    }))
  }

  public getStockAddressId(stockAddressCode: string): Observable<string> {
    let params = new Map<string, string>();
    params.set("code", stockAddressCode);

    return this.httpClient.get("stk.stockAddress/stockAddressInfo", params).pipe(take(1), map(resp => {
      let id: string;

      if (resp && resp.addresses?.address) {
        id = resp.addresses.address?.id_stockAddress;
      }

      return id;
    }))
  }

  public getVolInfo(volumeCode: string, stockOperationId: string, productionOrderId: string): Observable<Array<VolumeInfo>> {
    let params = new Map<string, string>();
    params.set("codigo", volumeCode);
    params.set("id_tipoOperacao", stockOperationId);
    params.set("id_ordemProd", productionOrderId);

    return this.httpClient.get("stk.volume/volInfo", params).pipe(take(1), map(resp => {
      let volInfoList = new Array<VolumeInfo>();

      if (resp && resp.volumeList) {
        let respVol = resp.volumeList.volume;

        if (respVol) {
          if (!Array.isArray(respVol)) respVol = [respVol];
          respVol.forEach(vol => {
            let volInfo = new VolumeInfo();
            volInfo.volumeId = vol.id_volume;
            volInfo.code = vol.codigo;
            volInfo.stockAddressId = vol.id_local;
            volInfo.stockAddressCode = vol.localizacao;
            volInfo.stockAddressType = vol.tipoEndereco;

            let respVolQty = vol.itemList?.item;

            if (respVolQty) {
              volInfo.quantity = new Number(respVolQty.qtdEstoque || 0).valueOf();
              volInfo.reservedQuantity = new Number(respVolQty.qtdReservada || 0).valueOf();
              volInfo.itemId = respVolQty.id_item;
            }

            volInfoList.push(volInfo);
          });
        }
      }

      return volInfoList;
    }));
  }

  public makeTransfer(prodOrderId: string, taskId: string, operationId: string, itemId: string, volumeId: string,
    quantity: number, stkAddressDestinationId: string, requestId: string, isDevolution: boolean): Observable<string> {
    let params = new Map();
    params.set("id_ordemProd", prodOrderId);
    params.set("id_task", taskId);
    params.set("id_tipoOperacao", operationId);
    params.set("id_item", itemId);
    params.set("id_volume", volumeId);
    params.set("quantidade", quantity);
    params.set("id_local_destino", stkAddressDestinationId);
    params.set("id_requisicao", requestId);
    params.set("devolucao", isDevolution);

    return this.httpClient.get("bsn.production.prodOrder/transfer", params).pipe(take(1), map(resp => {
      return resp.message
    }));
  }

  public saveRequestListOrder(requestList: Array<TaskRequisition>) {
    return this.httpClient.post("production.orderRequest/saveRequestListOrder", null, requestList.map(req => {
      return {
        requestId: req.requestId,
        separationSequence: req.sequence
      }
    })).pipe(take(1));
  }

  public getTransferDirective() {
    return this.httpClient.get("stock.stockMov/getTransferDirective", null).pipe(take(1), map(resp => {
      let transferDirective: {
        suggestQty: string
      }

      if (resp) {
        transferDirective = {
          suggestQty: resp.sugerirQuantidade
        }
      }

      return transferDirective;
    }));
  }
}

export class TaskRequisition {
  sequence: number;
  requestId: string;
  requestCode: string;
  taskId: string;
  prodOrderId: string;
  prodOrderCode: string;
  prodOrderDescription: string;
  deviceDescription: string;
  taskDescription: string;
  itemId: string;
  itemDescription: string;
  itemUnit: string;
  requestedQty: number;
  reservedQty: number;
  transferredQty: number;
  consumedQty: number;
  requestedDate: Date;
  requestedUser: string;
  separationTeamId: string;
  separationTeam: string;
  prodOrderSeparationTeam: string;
  planningDate: Date;
  elapsedTimeInMin?: number;
  taskNote: string;
  salesOrderNote: string;
  rawMaterialRequested: boolean;
  availableVolumeList = new Array<VolumeInfo>();
  transferredVolumeList = new Array<VolumeInfo>();
  reservedVolumeList = new Array<RequisitionVolume>();
}

export class TransferOperation {
  operationId: string;
  code: string;
  description: string;
  isDevolution: boolean;
  originStockAddressId: string;
  destinationStockAddressId: string;
  stockAddressCode: string;
  stockAddressDescription: string;
  lockOrigin: boolean;
  lockDestination: boolean;
  stockAddressCodeOrigin: string;
  stockAddressDescriptionOrigin: string;
}

export class VolumeInfo {
  volumeId: string;
  code: string;
  itemId: string;
  stockAddressId: string;
  stockAddressCode: string;
  stockAddressDescription: string;
  stockAddressType: string;
  quantity: number;
  reservedQuantity: number;
  creationDate: Date;
  expirationDate: Date;
  uniqueId = uuidv4(); // Apenas para identificação
  requisitionReservedQty?: number;
  itemUnit?: string;

  get availableQuantity(): number {
    return this.quantity - (this.requisitionReservedQty || 0);
  }

}
