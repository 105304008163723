<div *ngIf="!readOnly" class="flexColumn" [formGroup]="formGroup">
  <div>
    <input nbInput fieldSize="small" [id]="t2IdCmp" [hidden]="!visible" [ngStyle]="ngStyle"
      [placeholder]="placeHolder" [nbDatepicker]="datepicker" [formControlName]="t2IdCmp" fullWidth>
    <nb-datepicker #datepicker [format]="format"></nb-datepicker>
  </div>
  <span *ngIf="additionalInfo && visible" class="defaultSmallText">{{additionalInfo}}</span>
</div>

<div *ngIf="readOnly && visible" class="T2CmpReadOnly">
  {{formGroup.controls[t2IdCmp].value | date:format}}
</div>
