<app-dialog-template #dialog title="Nova Especificação de Produto" [nbSpinner]="loading" nbSpinnerSize="giant" nbSpinnerStatus="primary">
  <content class="flexColumn" style="max-height: 70vh;">
    <app-view-template #viewTemplate [FormGroup]="formGroup" [layout]="layout" [actions]="actions" [flows]="flows"></app-view-template>
  </content>
  <footer>
    <div class="flexAlignColumnStartEnd">
      <button nbButton status="basic" size="tiny" (click)="closeDialog()">Cancelar</button>
    </div>
  </footer>
</app-dialog-template>
