<div #panelContent class="panelContent">
  <div *ngFor="let line of widgetLines; let li = index" style="display: flex;">
    <div *ngFor="let widget of line.widgets; let index = index" style="width: 100%; padding: 2px;"
      [ngClass]="{'marginLeftAuto': true }">
      <app-widget-text *ngIf="widget.widgetType === 'Fixed Text'" [widget]="widget">
      </app-widget-text>
      <app-widget-selection *ngIf="widget.widgetType === 'Selection'" [widget]="widget"
        [placeHolder]="widget.widgetText" (widgetChange)="widgetChange($event)">
      </app-widget-selection>
      <app-widget-chart *ngIf="widget.widgetType === 'Chart'" [widget]="widget">
      </app-widget-chart>
      <app-widget-datatable *ngIf="widget.widgetType === 'Data table'" [widget]="widget">
      </app-widget-datatable>
    </div>
  </div>
