import { Component, EventEmitter, Inject, Input, LOCALE_ID, OnDestroy, Output, ViewChild } from '@angular/core';
import { DeviceTaskPlan, ProdOrderTaskItemQuantity, ShopFloorService, VolumeInfo, VolumeTransferred } from "../../shop-floor.service";
import { DeviceOperationInput } from "../../model/device-operation-input";
import { T2UserDeviceService } from "src/app/t2-user-device.service";
import { FormBuilder, FormGroup } from "@angular/forms";
import { take, takeUntil } from "rxjs/Operators";
import { T2MessageService } from "src/app/core/t2-message.service";
import { T2SelectComponent } from "src/app/core/cmp/ui/t2-select/t2-select.component";
import { DecimalPipe } from "@angular/common";
import { DeviceRegistrationInputType } from "../model/device-registration-input-type";
import { Subject } from "rxjs";
import { DeviceRegistrationInputTypeVolume } from "../model/device-registration-input-type-volume";
import { T2InputFloatComponent } from "src/app/core/cmp/ui/t2-input-float/t2-input-float.component";

@Component({
  selector: 'app-appointment-register-consumption',
  templateUrl: './appointment-register-consumption.component.html',
  styleUrls: ['./appointment-register-consumption.component.scss']
})
export class AppointmentRegisterConsumptionComponent implements OnDestroy {

  @ViewChild('localSelect') localSelect: T2SelectComponent;
  @ViewChild('qtyInput') qtyInput: T2InputFloatComponent;

  @Input()
  get taskPlan(): DeviceTaskPlan {
    return this._taskPlan
  }
  set taskPlan(value: DeviceTaskPlan) {
    this._taskPlan = value;
    this.loadInfo();
  }

  @Input()
  get inputList(): Array<DeviceOperationInput> {
    return this._inputList;
  }
  set inputList(value: Array<DeviceOperationInput>) {
    this._inputList = value;
    this.loadInfo();
  }

  @Output() lockScreen = new EventEmitter<boolean>();

  private _inputList: Array<DeviceOperationInput>;
  private _taskPlan: DeviceTaskPlan;
  private unsubscribe = new Subject<void>();
  private unsubscribeAutoInput = new Subject<void>();
  private volInfoList = new Array<VolumeInfo>();
  private consumptionInput: DeviceOperationInput;
  private inputVol = new DeviceRegistrationInputTypeVolume();
  private itemQtyList = new Array<ProdOrderTaskItemQuantity>();
  public loaded: boolean = false;
  public formGroup: FormGroup;
  public volumeList: Array<VolumeTransferred>;
  public devRegInput = new DeviceRegistrationInputType();
  public itemDescription: string;

  constructor(public userDevService: T2UserDeviceService, private formBuilder: FormBuilder, private sfService: ShopFloorService,
    @Inject(LOCALE_ID) public locale: string, private messageService: T2MessageService) {
    this.formGroup = this.formBuilder.group({});
    this.formGroup.addControl("volume", this.formBuilder.control(undefined));
    this.formGroup.addControl("local", this.formBuilder.control(undefined));
    this.formGroup.addControl("quantidade", this.formBuilder.control(0));

    this.devRegInput.volumes = [];
    this.devRegInput.volumes.push(this.inputVol);

    this.formGroup.controls["local"].valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe(volId => {
      if (!volId) {
        return;
      }

      let selectedVol = this.volInfoList.find(vol => vol.volumeId == volId);
      let cmpCons = this.taskPlan.cmpConsList.find(cmp => cmp.itemId == selectedVol.itemId);

      this.itemDescription = cmpCons.descriptionCmp;

      this.devRegInput.id_component = cmpCons.prodOrderCmpId;
      this.devRegInput.id_operationGroup_it = this.consumptionInput.id_operationGroup_it;
      this.devRegInput.id_operationGroup_op_it = this.consumptionInput.id_operationGroup_op_it;
      this.devRegInput.id_item = cmpCons.itemId;

      this.inputVol.id_volume = volId;
      this.inputVol.id_stockAddress_destination = this.consumptionInput.id_operation_stockAddress_destination;

      this.qtyInput.additionalInfo = `Nec ${cmpCons.requestedQty} ${cmpCons.unitSymbol}`;

      if (this.consumptionInput && this.consumptionInput.suggestConsumptionQty != "Não sugerir quantidade") {
        if (["Qtd proporcional à produzida", "Qtd prevista"].includes(this.consumptionInput.suggestConsumptionQty)) {
          let itemQty: ProdOrderTaskItemQuantity = this.itemQtyList.find(it => it.id_item == cmpCons.itemId);

          if (!itemQty) {
            this.sfService.getTaskItemQty(this.taskPlan.taskId, cmpCons.itemId).pipe(take(1)).subscribe(resp => {
              itemQty = resp;
              let qty: number = 0;

              if (itemQty) {
                qty = this.getSuggestQty(selectedVol, itemQty);
              } else {
                itemQty = new ProdOrderTaskItemQuantity();
                itemQty.id_item = cmpCons.itemId;
              }

              this.itemQtyList.push(itemQty)

              this.formGroup.controls["quantidade"].setValue(qty);
            });
          } else {
            let qty = this.getSuggestQty(selectedVol, itemQty);
            this.formGroup.controls["quantidade"].setValue(qty);
          }
        } else {
          let qty = this.getSuggestQty(selectedVol, undefined);
          this.formGroup.controls["quantidade"].setValue(qty);
        }
      }
    });

    this.formGroup.controls["quantidade"].valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe(qty => {
      this.devRegInput.value = qty;
      this.inputVol.quantity = qty;
    })
  }

  private getSuggestQty(volume: VolumeInfo, itemQty: ProdOrderTaskItemQuantity): number {
    let qty: number = 0;

    switch (this.consumptionInput?.suggestConsumptionQty) {
      case "Qtd proporcional à produzida": {
        if (itemQty.expectedQty) {
          qty = Math.max(((itemQty.producedQty / itemQty.expectedQty) * itemQty.requestedQty) - itemQty.consumedQty, 0);
          qty = qty > (itemQty.requestedQty - itemQty.consumedQty) ? Math.max((itemQty.requestedQty - itemQty.consumedQty), 0) : qty;
          qty = Math.min(qty, volume.quantity);
        }
        
        break;
      }
      case "Qtd prevista": {
        qty = Math.max((itemQty.requestedQty - itemQty.consumedQty), 0);
        qty = Math.min(qty, volume.quantity);
        break;
      }
      case "Qtd do volume": {
        qty = volume.quantity;
        break;
      }
    }

    return qty;
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
    this.unsubscribeAutoInput.next();
    this.unsubscribeAutoInput.complete();
  }

  private loadInfo() {
    if (this.taskPlan && this.inputList) {
      this.consumptionInput = this.inputList.find(inp => inp.functionality == "Consumption");
      this.unsubscribeAutoInput.next();

      if (this.consumptionInput.autoInputId) {
        this.sfService.getAutoDataObservable(this.consumptionInput.autoInputId).pipe(takeUntil(this.unsubscribeAutoInput)).subscribe(value => {
          this.formGroup.controls["quantidade"].setValue(Number(Number(value).toFixed(this.consumptionInput.decimals)));
        })
      }

      this.sfService.getAvailableVolumesForTask(this.taskPlan.productionOrderId, this.taskPlan.taskId).pipe(take(1)).subscribe(volList => {
        this.volumeList = volList;
        this.loaded = true;

        setTimeout(() => {
          this.qtyInput.decimalQty = this.consumptionInput.decimals;
        }, 100);
      }, error => {
        this.loaded = true;
      })
    }
  }

  public searchVolume() {
    this.formGroup.controls["local"].setValue(undefined);
    this.localSelect.list = [];
    this.volInfoList = [];
    let volumeCode = this.formGroup.controls["volume"].value;

    if (!volumeCode) {
      this.messageService.showToast("Informe um código de volume para realizar a busca", "Aviso", "warning");
      return;
    }

    this.lockScreen.emit(true);
    this.sfService.getVolInfo(volumeCode).pipe(take(1)).subscribe(volList => {
      if (volList.length == 0) {
        this.messageService.showToast("Volume não encontrado, infome um código válido", "Aviso", "warning");
      } else {
        this.volInfoList = volList;
        let localList = new Array<{ id: string, description: string }>();
        volList.forEach(vol => {
          if (this.consumptionInput.stockOperationOriginValidation == 'Allow any Origin' || (this.consumptionInput.id_operation_stockAddress_origin && vol.stockId == this.consumptionInput.id_operation_stockAddress_origin)) {
            localList.push({
              id: vol.volumeId,
              description: `${vol.stockCode} - ${new DecimalPipe(this.locale).transform(vol.quantity, "1.1-2", this.locale)} ${vol.itemUnit}`
            });
          }
        });

        this.localSelect.list = localList;

        if (localList.length) {
          // É necessário o delay para que carregue a lista no componente T2Select
          setTimeout(() => {
            this.formGroup.controls["local"].setValue(localList[0].id);
          }, 100);
        } else {
          this.messageService.showToast("Volume não está disponível para essa OP ou não possui produto para esse planejamento", "Aviso", "warning");
        }
      }

      this.lockScreen.emit(false);
    }, error => {
      this.lockScreen.emit(false);
    })
  }

  selectVolume(volume: VolumeTransferred) {
    this.formGroup.controls["volume"].setValue(volume.code);
    this.searchVolume();
  }
}
