import { Component, OnInit, Input } from '@angular/core';
import { FilterField } from './model/filterField';

@Component({
  selector: 'app-filter-number',
  templateUrl: './filter-number.component.html',
  styleUrls: ['./filterTheme.scss']
})
export class FilterNumberComponent implements OnInit {

  private static PATTERN_DECIMAL = /^[0-9]{0,12}([,][0-9]{0,4})?$/;
  private static PATTERN_MONEY = /^[0-9]{0,12}([,][0-9]{0,2})?$/;
  private static PATTERN_INTEGER = /^[0-9]{0,12}?$/;

  private _filterField: FilterField;

  public fieldPattern: RegExp;


  @Input()
  get filterField() {
    return this._filterField;
  }
  set filterField(filterField: FilterField) {
    this._filterField = filterField;

    if (this._filterField) {
      switch (this._filterField.fieldType) {
        case "decimal":
          this.fieldPattern = FilterNumberComponent.PATTERN_DECIMAL;
          break;
        case "money":
          this.fieldPattern = FilterNumberComponent.PATTERN_MONEY;
          break;
        case "integer":
          this.fieldPattern = FilterNumberComponent.PATTERN_INTEGER;
          break;

        default:
          this.fieldPattern = /[*]/;
      }
    }
  }

  constructor() { }

  ngOnInit(): void {
  }

  onKeyPress(event: any) {
    // console.log(event.srcElement.value, event.target.value, event.key, event);

    const previousValue = event.srcElement.value;
    const inputChar = event.key;
    if (!this.fieldPattern.test(previousValue + inputChar)) {
      event.preventDefault();
    }
  }

}
