import { Component, OnInit, ViewChild } from '@angular/core';
import { CilindroCor } from "../../model/cilindro-cor";
import { Cilindro } from "../../model/cilindro";
import { T2gridComponent } from "src/app/core/cmp/t2grid/t2grid.component";
import { NbDialogRef, NbDialogService } from "@nebular/theme";
import { T2MessageService } from "src/app/core/t2-message.service";
import { AgPromise, CellValueChangedEvent } from "ag-grid-community";
import { DialogComponent } from "src/app/core/cmp/dialog/dialog.component";
import { take } from "rxjs/Operators";
import { T2HttpClientService } from "src/app/core/http/t2httpClient.service";
import { CylinderSuggestion } from "../../model/cylinder-suggestion";

@Component({
  selector: 'app-edit-cylinder',
  templateUrl: './edit-cylinder.component.html',
  styleUrls: ['./edit-cylinder.component.scss']
})
export class EditCylinderComponent implements OnInit {

  public prodOrderColors: Array<CilindroCor>;
  public cylinderList: Array<Cilindro>;
  public selectedColor: CilindroCor;
  public cylinderSuggestionList: Array<Cilindro>;
  public prodOrderId: string;
  public suggestionLoaded: boolean = false;

  @ViewChild("gdCadCilindro", { static: true }) gdCadCilindro: T2gridComponent;

  constructor(public dialogRef: NbDialogRef<EditCylinderComponent>, private messageService: T2MessageService,
    private dialogService: NbDialogService, private httpClient: T2HttpClientService) {

  }

  ngOnInit() {
    this.load();
    this.loadSuggestionList().then(() => {
      this.suggestionLoaded = true;
      this.selectColor(this.prodOrderColors[0]);
    });
  }

  private load() {
    this.gdCadCilindro.t2GridOptions.context.component = { prodOrderColors: this.prodOrderColors }
    this.gdCadCilindro.t2GridOptions.onCellValueChanged = this.gdCadCilindroCellValueChanged.bind(this);

    this.gdCadCilindro.setGridColumnDefs([
      { headerName: "Cilindro", field: "code"},
      {
        headerName: "Cor Associada", editable: true, field: "corOP",
        cellRenderer: function (params) {
          let result = null;

          if (params.data["corOP"]) {
            let corOP = params.context.component.prodOrderColors.filter(c => c["ID"] == params.data["corOP"])[0];

            if (corOP) {
              result = corOP.detail ? corOP.color + " - " + corOP.detail : corOP.color;
            }
          }

          return result;
        },
        cellEditorSelector: cesParams => {
          return {
            component: "selectEditor",
            params: {
              selectionOptions: cesParams.context.component.prodOrderColors.map(c => {
                return {
                  id: c["ID"],
                  value: c.detail ? c.color + " - " + c.detail : c.color,
                  context: { id_ordemProd_cilindro: c.id_ordemProd_cilindro }
                }
              })
            }
          }
        }
      },
      { headerName: "Local", field: "local" },
      { headerName: "Tipo", field: "type" },
      { headerName: "Circ", field: "circ", type: "numericColumn", filter: "agNumberColumnFilter", }, // Float
      { headerName: "Aspect Visual", field: "aspect" },
      { headerName: "Balanc", field: "balancing" },
      { headerName: "Diâmetro", field: "diameter", type: "numericColumn", filter: "agNumberColumnFilter", }, // Float
      { headerName: "Espessura", field: "espess", type: "numericColumn", filter: "agNumberColumnFilter", }, // Float
      { headerName: "Dur Cromo", field: "thickness", type: "numericColumn", filter: "agNumberColumnFilter", }, // Float
      { headerName: "Excent Corpo", field: "excentBody" },
      { headerName: "Excent Pontas", field: "ExcentEdge" },
      { headerName: "Rugosidade", field: "roughness" },
      { headerName: "Produto Gravado (EP)", field: "descricaoEP" },
      { headerName: "Cor Gravada", field: "corEP" },
      { headerName: "Último Pedido", field: "pedidos" },
      { headerName: "Largura Montagem OP", field: "larguraMontagem", type: "numericColumn", filter: "agNumberColumnFilter" }
    ]);

    this.prodOrderColors.filter(c => c.id_item_cylinder).forEach(opCor => {
      let cylinder = this.cylinderList.filter(cyl => cyl.id_item == opCor.id_item_cylinder)[0];
      cylinder["corOP"] = opCor["ID"];
    })

    this.gdCadCilindro.setGridData(this.cylinderList, null);
  }

  private loadSuggestionList(): Promise<void> {
    this.cylinderList.forEach(cyl => {
      cyl.suggestionInfo = undefined;
    });

    let id_productSpecification = this.prodOrderColors.find(c => c.id_productSpecification).id_productSpecification;
    let idList = Array.from(new Set(this.prodOrderColors.map(c => c.id_especif_carac_quimico_item))).join(",");

    return new Promise<void>((resolve, reject) => {
      let params = new Map<string, string>();
      params.set("id_especificacao", id_productSpecification);
      params.set("id_ordemprod", this.prodOrderId);

      this.httpClient.post("production.CylinderScheduler/getCylinderSuggestion", params, idList).pipe(take(1)).subscribe(resp => {
        if (resp.suggestionList) {
          if (!Array.isArray(resp.suggestionList)) {
            resp.suggestionList = [resp.suggestionList];
          }

          resp.suggestionList.forEach((cylSugg: CylinderSuggestion) => {
            let cyl = this.cylinderList.find(c => c.id_item == cylSugg.id_item);

            if (!cyl.suggestionInfo) {
              cyl.suggestionInfo = new CylinderSuggestion();
              cyl.suggestionInfo.id_item = cylSugg.id_item;
            }

            cyl.suggestionInfo.id_especif_carac_quimico_item = cylSugg.id_especif_carac_quimico_item;
            cyl.suggestionInfo.id_grupoQuimico = cylSugg.id_grupoQuimico;
            cyl.suggestionInfo.colorAssigned = cylSugg.colorAssigned;
            cyl.suggestionInfo.lastUsed = cylSugg.lastUsed;
            cyl.suggestionInfo.compatible = cylSugg.compatible;
          });
        }

        resolve();
      }, error => reject(error));
    })
  }

  closeWindow(confirm: boolean) {
    const result = { confirm: confirm, prodOrderColors: this.prodOrderColors };
    this.dialogRef.close(result);
  }

  colorHasCylinder(ID: string) {
    let hasCylinder = this.prodOrderColors.filter(c => c["ID"] == ID)[0].id_item_cylinder

    if (hasCylinder) {
      this.messageService.showDialog({
        context: {
          title: "AVISO",
          message: "Essa cor já está associada para outro cilindro !!",
          actions: [{ description: "OK" }]
        }
      });
    }
    return hasCylinder;
  }

  private canChangeCylinderInInvoice(cylinderCode: string, color: string) {
    return new AgPromise<boolean>((resolve, reject) => {
      const dlg = this.dialogService.open(DialogComponent, {
        context: {
          title: `O cilindro ${cylinderCode} está em uma nota fiscal para gravação para a cor ${color}.`,
          topMessage: "Deseja realmente trocar o cilindro da cor?",
          message: `Se continuar, o novo cilindro ficará com status REVISAR para essa cor, o cilindro
                    anterior deverá ser retirado da nota fiscal manualmente.`,
          actions: [{ description: "Sim" }, { description: "Não", status: "basic" }]
        }
      });

      dlg.onClose
        .pipe(
          take(1)
        )
        .subscribe(result => {
          if (result === "Sim") {
            resolve(true);
          } else {
            resolve(false);
          }
        }, error => {
          reject("error");
        });
    });
  }

  private changeCylinderColor(event: CellValueChangedEvent) {
    // Se está vazio é pq o cilindro foi removido
    if (event.newValue == null) {
      let oldColor = this.prodOrderColors.find(c => c.id_item_cylinder == event.data.id_item);
      oldColor.id_item_cylinder = undefined;
      oldColor.cylinderCode = undefined;
      event.data.corOP = null;

      this.gdCadCilindro.redrawRows({ rowNodes: [event.node] });

      return;
    }

    if (event.oldValue != event.newValue) {
      let cylColor: CilindroCor;
      if (!this.colorHasCylinder(event.newValue)) {
        event.data.corOP = event.newValue;

        if (event.oldValue) {
          let oldColor = this.prodOrderColors.find(c => c.id_item_cylinder == event.data.id_item);
          oldColor.id_item_cylinder = undefined;
          oldColor.cylinderCode = undefined;
        }

        cylColor = this.prodOrderColors.find(c => c["ID"] == event.newValue);
        this.changeCylinderInList(cylColor, this.cylinderList.find(c => c.id_item == event.data.id_item));
      } else {
        event.data.corOP = event.oldValue;
      }

      this.gdCadCilindro.redrawRows({ rowNodes: [event.node] });
    }
  }

  async gdCadCilindroCellValueChanged(event: CellValueChangedEvent) {
    if (event.colDef.field == "corOP") {
      // Verifica se o cilindro estava em nota fiscal
      if (event.oldValue) {
        let regOrig = this.prodOrderColors.filter(c => c["ID"] == event.oldValue)[0];

        if (regOrig.invoice) {
          await this.canChangeCylinderInInvoice(regOrig.cylinderCode, regOrig.color).then(result => {
            if (result) {
              this.changeCylinderColor(event);

              return;
            } else {
              event.data.corOP = event.oldValue;
              this.gdCadCilindro.redrawRows({ rowNodes: [event.node] });

              return;
            }
          })
        } else {
          this.changeCylinderColor(event);
        }
      } else {
        this.changeCylinderColor(event);
      }
    }
  }

  selectColor(color: CilindroCor) {
    this.selectedColor = color;
    this.cylinderSuggestionList = this.cylinderList.filter(c => c.suggestionInfo?.id_grupoQuimico == color.id_grupoQuimico).sort((o1, o2) => {
      let resp: number = o1.id_item == color.id_item_cylinder ? -1 : o2.id_item == color.id_item_cylinder ? 1 : 0;

      if (resp == 0) {
        o1.suggestionInfo.colorAssigned == o2.suggestionInfo.colorAssigned ? 0 : o1.suggestionInfo.colorAssigned ? -1 : 1;

        if (resp == 0) {
          resp = o1.suggestionInfo.lastUsed == o2.suggestionInfo.lastUsed ? 0 : o1.suggestionInfo.lastUsed ? -1 : 1;

          if (resp == 0) {
            resp = o1.suggestionInfo.compatible == o2.suggestionInfo.compatible ? 0 : o1.suggestionInfo.compatible ? -1 : 1;
          }
        }
      }

      return resp;
    })
  }

  assignCylinder(cylinder: Cilindro) {
    if (this.prodOrderColors.some(c => c.id_item_cylinder == cylinder.id_item)) {
      this.messageService.showDialog({
        context: {
          title: "AVISO",
          message: "Esse cilindro já está associado a outra cor !!",
          actions: [{ description: "OK" }]
        }
      });

      return;
    } else {
      let color = this.prodOrderColors.find(c => c.id_especif_carac_quimico_item == this.selectedColor.id_especif_carac_quimico_item);

      if (color.invoice) {
        this.canChangeCylinderInInvoice(color.cylinderCode, color.color).then(canChange => {
          if (canChange) {
            this.changeCylinderInList(color, cylinder);
          }
        })
      }
      this.changeCylinderInList(color, cylinder);
    }
  }

  private changeCylinderInList(color: CilindroCor, cylinder: Cilindro) {
    if (color.id_item_cylinder) {
      let assignedCylinder = this.cylinderList.find(c => c.id_item == color.id_item_cylinder);

      assignedCylinder["corOP"] = undefined;
    }

    color.id_item_cylinder = cylinder.id_item;
    color.cylinderCode = cylinder.code;
    cylinder["corOP"] = color["ID"];
  }
}
