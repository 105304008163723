import { TaskPlan } from "./task-plan";

export class DocumentPlan {
  apsId: number;
  startDate: Date;
  endDate: Date;
  fixedDate: boolean;
  incompatibilityNextPlan: number;

  public parseToTaskPlan(): TaskPlan {
    let plan = new TaskPlan();
    plan.apsId = this.apsId;
    plan.startDate = this.startDate;
    plan.endDate = this.endDate;
    plan.fixedDate = this.fixedDate;
    plan.incompatibilityNextPlan = this.incompatibilityNextPlan;

    return plan;
  }
}
