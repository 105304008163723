import { ComplementaryField } from "src/app/bsn/utils/model/complementary-field";
import { ReleaseProdOrderInfoCylinderInfo } from "./release-prod-order-info-cylinder-info";

export class ReleaseProdOrderInfo {
  id: string;
  salesOrderId: string;
  salesOrderItemId: string;
  type: string;
  status: string;
  progressStatus: string;
  code: string;
  itemId: string;
  item: string;
  prodSpecifVersion: string;
  prodSpecifDetail: string;
  requestedQty: number;
  releasedQty: number;
  deliveryDate: Date;
  ppcDate: Date;
  itemType: string;
  conjCode: string;
  client: string;
  prodSpecifId: string;
  structure: string;
  cylPsConjugationId: string;
  assignedPsConjugationId;
  conjugationId: string;
  cylInfoList: Array<ReleaseProdOrderInfoCylinderInfo>;
  complementaryFields: Array<ComplementaryField>;
}
