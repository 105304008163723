<div *ngIf="!showOptionsForDialog" #statusCmp [formGroup]="formGroup"
  style="height: calc(100% - 2px); max-height: '32px; letter-spacing: 0.015rem;"
  [ngStyle]="{'min-width': minWidth ? '200px' : 'auto', 'max-width': minWidth ? '200px' : 'auto'}">

  <div *ngIf="!displayOnly">
    <div class="status flexAlignRowSpaceBetweenCenter" [ngClass]=" currentStatus?.colorStyle || 't2ColorDefault'">
      <div class="flexLength1" style="font-size: 12px"
        [ngStyle]="{'text-align': (readOnly && !formGroup.readOnly) || statusFlow?.length == 0 ? 'center' : 'start', 'padding': minWidth ? '4px 7px' : '0px 7px'}">
        {{ currentStatus?.name}}
      </div>
      <div *ngIf="(!readOnly || formGroup.readOnly) && statusFlow?.length > 0" class="statusChange flexAlignRowCenter"
        [ngClass]="{'statusChangeClick': showingOptions}" (click)="statusClick()">
        <nb-icon icon="more-horizontal"></nb-icon>
      </div>
    </div>

    <div *ngIf="(!readOnly || formGroup.readOnly) && statusFlow?.length > 0" id="statusOptions"
      [ngClass]="{'statusOptionsClick': showingOptions }">
      <div class="statusOption flexAlignColumnCenter" *ngFor="let status of statusFlow" [nbTooltip]="status.tooltip"
        [nbTooltipTrigger]="!status.tooltip ? 'noop' : 'hover'" (click)="selectStatus(status)"
        [ngClass]=" status.colorStyle || 't2ColorDefault'">
        {{status.action || status.name}}</div>
    </div>
  </div>

  <div *ngIf="displayOnly && statusDescription" class="status" [ngClass]="statusColorStyle || 't2ColorDefault'"
    style="box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;">

    <div class="flexColumn" style="align-items: center; justify-content: center; height: 100%;"
      [ngStyle]="{'text-align': 'center', 'padding': minWidth ? '4px 7px' : '0px 7px'}">
      {{ statusDescription }}
    </div>
  </div>
</div>


<app-dialog-template #dialog *ngIf="showOptionsForDialog" [title]="'Status: ' + currentStatus?.name">
  <content>
    <div class="statusOption flexAlignColumnCenter" *ngFor="let status of statusFlow" [nbTooltip]="status.tooltip"
      [nbTooltipTrigger]="!status.tooltip ? 'noop' : 'hover'" (click)="selectStatus(status)"
      [ngClass]=" status.colorStyle || 't2ColorDefault'">
      {{status.action || status.name}}</div>
  </content>
</app-dialog-template>


<div *ngIf="saving" class="overlayLayer" [nbSpinner]="saving" nbSpinnerStatus="primary" nbSpinnerSize="giant"></div>
