<div [ngClass]="{'waiting': lockCmp}" [nbSpinner]="lockCmp" nbSpinnerStatus="primary" nbSpinnerSize="giant"
  class="fillContent">
  <app-form-template #formTemplate formTitle="Horários de Trabalho" [loaded]="loaded"
    [helpUrl]="'https://tech2e.atlassian.net/l/cp/Mof1grAd'">

    <content class="flexRow flexGap10 fillContent">

      <div class="T2GrayArea flexColumn" style="min-width: 240px; max-width: 240px; height: 100%; padding: 5px">

        <div class="workingTime T2GrayHoverArea" *ngFor="let ws of workScheduleList" (click)="selectWorkSchedule(ws.id)"
          [ngStyle]="{'font-weight': ws.id == selectedWS?.id ? 'bold' : ''}"
          [ngClass]="{'T2GraySelectedArea':  ws.id == selectedWS?.id}">
          {{ ws.description }}

          <div id="editButtons">
            <div class="editFloatButton" (click)="$event.stopPropagation(); editWorkSchedule(ws); ">
              <nb-icon icon="edit-outline"></nb-icon>
            </div>
            <div class="deleteFloatButton" (click)="$event.stopPropagation(); deleteWorkSchedule(ws); ">
              <nb-icon icon="trash-2-outline"></nb-icon>
            </div>
            <div class="copyFloatButton" (click)="$event.stopPropagation(); duplicateWorkSchedule(ws); "
              nbTooltip="Criar cópia">
              <nb-icon icon="copy-outline"></nb-icon>
            </div>
          </div>
        </div>

        <div class="flexAlignRowCenter addWorkSchedule"
          style="margin-top: auto; border: 1px solid rgba(104, 103, 103, 0.222); border-radius: 4px; display: flex; justify-content: center;"
          (click)="addWorkSchedule()">
          <nb-icon icon="plus-outline"></nb-icon>
          <span style="font-size: 0.8rem;">Incluir Horário de Trabalho</span>
        </div>
      </div>

      <div class="flexColumn flexLength1" *ngIf="selectedWS != null">
        <div style="display: flex; justify-content: flex-end; align-items: center;">
          <button nbButton status="primary" size="tiny" (click)="recreateCalendar()">Recriar Calendário</button>
        </div>

        <nb-tabset style="width: 100%; height: calc(100% - 74px)" (changeTab)="tabChange($event)">
          <nb-tab tabTitle="Turnos" style="height: 100%;">
            <div style="display: flex; justify-content: flex-start; align-items: stretch; padding: 5px; height: 100%;">
              <div class="flexColumn"
                style=" place-content: end space-between; height: calc(100% - 40px); margin-top: 40px; border-right: 1px solid rgba(212, 212, 212, 0.277);">
                <div class="hour" *ngFor="let in of hourList; let hour= index">
                  {{hour * 2}}:00
                </div>
                <div class="hour">
                </div>
              </div>
              <app-calendar-day class="flexColumn" style="place-content: start stretch;"
                *ngFor="let item of selectedWS?.timeDetailList" [timeDetail]="item" [workScheduleId]="selectedWS?.id">
              </app-calendar-day>
            </div>
          </nb-tab>

          <nb-tab tabTitle="Exceções" style="height: 100%;">
            <div style="display: flex; flex-direction: column; gap: 3px; height: 100%; margin-top: 5px;">

              <!-- Ações das requisiões -->
              <div style="display: flex; gap: 6px; justify-content: flex-end; align-items: center;">
                <button #btnAddExc nbButton outline size="small" status="primary" (click)="editException(null)">
                  <nb-icon icon="plus-circle-outline"></nb-icon>
                </button>
                <button #btnDelExc nbButton outline size="small" status="danger"
                  [disabled]="!selectedWS?.exceptionList.length || !gridExc?.getRowsSelected().length"
                  (click)="deleteException()">
                  <nb-icon icon="trash-2-outline"></nb-icon>
                </button>
              </div>

              <!-- Grid -->
              <app-t2grid class="flexLength1" #gridExc id="gridExc" [sortable]="true" (rowDoubleClick)="editException($event)" />

            </div>
          </nb-tab>
        </nb-tabset>
      </div>
    </content>
  </app-form-template>
</div>
