/**
 * Sidebar
 * Main menu
 * Containers
 * Dialogs
 * Buttons
 * Grid / Table
 * - Filter
 * - Editable
 * Forms layout
 * - Data group
 * - Autoform action bar
 * - Summary footer
 * Inputs
 * - Integer
 * - Decimal
 * - Single line text
 * - Multiline text
 * - Date
 * - Daterange
 * - Datetime
 * - Time
 * - Selector
 * - Aggregation
 * - Status
 * - ItemQuantity (unit conversion)
 * Tooltip
 * Wait (spinner)
 * Badget
 * Popover menu
 */
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormTemplateComponent } from "../cmp/form-template/form-template.component";

@Component({
  selector: 'app-design-pattern',
  templateUrl: './design-pattern.component.html',
  styleUrls: ['./design-pattern.component.scss']
})
export class DesignPatternComponent implements OnInit {

  @ViewChild("formTemplate", { static: true }) formTemplate: FormTemplateComponent;

  currentStatus = { name: "Em Elaboração", action: null, color: "darkorange" };
  statusFlow = [
    { name: "Aprovado", action: "Aprovar a informação", tooltip: "Mudar o status da informação para aprovado", color: "green" },
    { name: "Reprovado", action: "Reprovar", tooltip: "Mudar o status da informação para reprovado", color: "red" },
    { name: "Cancelado", action: "Cancelar", color: "gray" },
    { name: "Finalizado", action: "Finalizar", color: "blue" }
  ];

  constructor() { }

  ngOnInit(): void {
    this.formTemplate.validateAccess([], 'full');
  }

  onStatusChange(status: any) {

    // Carrega novo fluxo
    this.statusFlow = this.statusFlow.filter(s => s.name != status.name);
    this.statusFlow.push(this.currentStatus);

    // Seta o status atual
    this.currentStatus = status;
  }


}
