import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { WidgetService } from "../widget.service";
import { T2Widget } from "../model/t2widget";
import { T2gridComponent } from "../../cmp/t2grid/t2grid.component";
import { timer } from 'rxjs';
import { take } from "rxjs/Operators";

@Component({
  selector: 'app-widget-datatable',
  templateUrl: './widget-datatable.component.html',
  styleUrls: ['./widget-datatable.component.scss']
})
export class WidgetDatatableComponent implements OnInit {

  loading = false;

  private _widget: T2Widget;
  private _params: {};

  @ViewChild("gridTable", { static: true }) gridTable: T2gridComponent;
  private gridData = [];
  private gridColumns = [];

  @Input()
  get widget() { return this._widget; }
  set widget(value: T2Widget) {
    this._widget = value;
    this.updateContent();
  }

  @Input()
  get params() { return this._params; }
  set params(value: any) {
    const newParams = this.widgetService.mergeInputParams(this._widget, this._params, value);
    if (newParams.updated) {
      this._params = newParams.params;
      this.updateContent();
    }
  }

  @Output() widgetChange = new EventEmitter();

  constructor(private widgetService: WidgetService) { }

  ngOnInit(): void { }

  private updateContent() {
    this.loading = true;

    if (this._widget && this._widget.id_techprodWidget) {
      this.widgetService.getWidgetContent(this._widget, this._params)
        .subscribe((content: string) => {
          this.loading = false;

          let gridInfo = { columns: [], data: [] };
          try {
            gridInfo = JSON.parse(content);
            this.gridColumns = gridInfo.columns;
            this.gridData = gridInfo.data;
          } catch (error) {
            this.loading = false;
          }

          // if (this.gridData.length) { console.table(this.gridData); }

          this.gridTable.setGridColumnDefs(this.gridColumns);
          this.gridTable.setGridData(this.gridData, null);
          this.tableAutoSizeColumns();

        });
    }
  }

  tableAutoSizeColumns() {
    timer(100).pipe(take(1)).subscribe(val => {
      this.gridTable.autoSizeAllColumns(false);
    });
  }

}
