<div [nbSpinner]="saving" nbSpinnerStatus="primary">
  <app-dialog-template #dialog title="Seleção de Ambiente">
    <header>
      <div style="display: flex; gap: 5px;">
        <button nbButton class="button-icon" size="tiny" (click)="addSimulation()" [disabled]="!canAddEnvironment()">
          <nb-icon icon="plus-outline"></nb-icon>
        </button>

        <button nbButton class="button-icon" size="tiny" (click)="renameSimulation()"
          [disabled]="!simulationSelected || realEnv() || !canEditEnvironment()">
          <nb-icon icon="edit-outline"></nb-icon>
        </button>

        <button nbButton class="button-icon" size="tiny" (click)="deleteSimulation()"
          [disabled]="!simulationSelected || realEnv() || !canDeleteEnvironment()">
          <nb-icon icon="trash"></nb-icon>
        </button>
      </div>
    </header>
    <content class="flexColumn" style="max-width: 60vw; gap: 5px;">
      <app-view-template #viewTemplate [layout]="_layout" [data]="_data" [FormGroup]="_FormGroup"></app-view-template>
    </content>
    <footer style="max-width: 60vw; display: flex; justify-content: flex-end;">
      <div class="flexAlignRowEndCenter flexGap5">
        <button nbButton size="tiny" (click)="closeDialog('Cancela')">Fechar</button>
        <button nbButton status="primary" size="tiny" (click)="closeDialog('Confirma')" [disabled]="!simulationSelected">Selecionar</button>
      </div>
    </footer>
  </app-dialog-template>
</div>
