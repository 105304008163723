import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { take } from "rxjs/Operators";
import { DialogTemplateComponent } from "src/app/core/cmp/dialog-template/dialog-template.component";
import { T2HttpClientService } from "src/app/core/http/t2httpClient.service";

@Component({
  selector: 'app-closing-production-order',
  templateUrl: './closing-production-order.component.html',
  styleUrls: ['./closing-production-order.component.scss']
})
export class ClosingProductionOrderComponent implements OnInit {
  @ViewChild('dialog', { static: true }) dialog: DialogTemplateComponent;

  @Input() id: string;

  executionStepList: Array<ExecutionStep>;
  executing: boolean = false;
  started: boolean = false;
  error: boolean = false;
  success: boolean = false;
  textError = null;
  periodDescription: string;

  constructor(private httpClient: T2HttpClientService,) { }

  ngOnInit(): void {
    this.getClosingProductionOrderPeriodInfo();
    this.getClosingProductionOrderSteps();
  }

  getClosingProductionOrderSteps() {
    this.httpClient.get("production.closingProductionOrder/closingProductionOrderSteps", null)
      .pipe(take(1))
      .subscribe(resp => {
        if (!resp.executionSteps) return;

        if (!Array.isArray(resp.executionSteps)) {
          resp.executionSteps = [resp.executionSteps];
        }
        this.executionStepList = resp.executionSteps;
        this.executionStepList.forEach(item => item.stage = "waiting");
      }, error => {
        this.executing = false;
        this.textError = error.message;
      });

  }

  executeClosingProductionOrderSteps() {
    this.httpClient.get("production.closingProductionOrder/closingProductionOrderSteps", null)
      .pipe(take(1))
      .subscribe(async resp => {
        if (!resp.executionSteps) return;

        if (!Array.isArray(resp.executionSteps)) {
          resp.executionSteps = [resp.executionSteps];
        }
        this.executionStepList = resp.executionSteps;
        this.executionStepList.forEach(item => item.stage = "waiting");

        this.started = true;
        this.executing = true;
        for (let step of this.executionStepList) {
          let params = new Map<string, string>();
          step.scriptStep = encodeURIComponent(step.scriptStep);
          params.set("scriptStep", step.scriptStep);
          params.set("id_ordemprodFechamento", this.id);
          step.stage = "running";

          await this.httpClient.get("production.closingProductionOrder/executeClosingProductionOrderStep", params)
            .pipe(take(1))
            .toPromise()
            .then(resp => {
              step.stage = "done";
              this.success = true;
            }, error => {
              step.stage = "error";
              this.error = true;
              this.success = false;
              this.textError = error.message;
              this.executing = false;
            });

          if (this.error) break;
        }
        this.executing = false;
      }, error => {
        this.executing = false;
        this.textError = error.message;
      });
  }

  getClosingProductionOrderPeriodInfo() {
    let params = new Map<string, string>();
    params.set("id_ordemprodFechamento", this.id);
    this.httpClient.get("production.closingProductionOrder/closingProductionOrderPeriodInfo", params)
      .pipe(take(1))
      .subscribe(resp => {
        if (!resp.periodDescription) return;
        this.periodDescription = resp.periodDescription;
      }, error => { });
  }

  closeDialog(action: string) {
    this.dialog.close(undefined);
  }
}

class ExecutionStep {
  id_directive_fechamOP_etapa: string;
  scriptStep: string;
  description: string;
  sortKey: number;
  stage: "done" | "running" | "waiting" | "error";
}
