<app-form-template #formTemplate formTitle="OEE" [loaded]="loaded">
  <header>
    <div style="display: flex; flex-direction: row;">
      <app-widget-selection #deviceComponent style="margin-right: 5px;" placeholder="Selecione o dispositivo..."
        (widgetChange)="deviceListChange($event)" [multiple]="true">
      </app-widget-selection>
      <input nbInput placeholder="Dt inicial" [nbDatepicker]="formpicker1" [formControl]="dateStartControl"
        fieldSize="tiny" style="max-width: 150px">
      <nb-datepicker #formpicker1 [max]="dateEndControl.value" [format]="format"></nb-datepicker>
      <input nbInput placeholder="Dt final" [nbDatepicker]="formpicker2" [formControl]="dateEndControl" fieldSize="tiny"
        style="max-width: 150px; margin-left: 5px;">
      <nb-datepicker #formpicker2 [min]="dateStartControl.value" [format]="format"></nb-datepicker>

      <button style="margin-left: 5px" nbButton size="tiny" status="primary"
        [disabled]="!dateStartControl.value || !dateEndControl.value || !loaded || loadingOEEData"
        (click)="loadOEEData()" [nbSpinner]="loadingOEEData" nbSpinnerStatus="primary" nbSpinnerSize="tiny">
        <nb-icon icon="refresh-outline"></nb-icon>
      </button>
    </div>
  </header>
  <content>
    <nb-tabset (changeTab)="onTabChange($event)" style="height: 100%; display: flex; flex-direction: column;">
      <nb-tab tabTitle="Geral">
        <div style="display: flex; flex-direction: column">
          <div>
            <div class="mainPanel">

              <div class="oee" style="width: 200px; text-align: center;">
              </div>

              <div class="oee">
                <div>
                  <div class="oeeCircle flexColumn" style="background-color: #f26641;">
                    <strong>{{oeeData.availability.indice | number:'1.1-2'}} %</strong>
                    <strong>{{oeeData.availability.meta | number:'1.1-2'}} %</strong>
                  </div>
                </div>
                <div>
                  Disponibilidade
                </div>
              </div>

              <div class="oee">
                <div>
                  <div class="oeeCircle" style="background-color: #0080c6;">
                    {{oeeData.performance.indice | number:'1.1-2'}} % | {{oeeData.performance.meta | number:'1.1-2'}} %
                  </div>
                </div>
                <div>
                  Performance
                </div>
              </div>

              <div class="oee">
                <div>
                  <div class="oeeCircle" style="background-color: #8b8bc4;"> {{oeeData.quality.indice | number:'1.1-2'}}
                    % | {{oeeData.quality.meta | number:'1.1-2'}} %
                  </div>
                </div>
                <div>
                  Qualidade
                </div>
              </div>

              <div class="oee">
                <div>
                  <div class="oeeCircle" style="background-color: #00a651;"> {{oeeData.oee.indice | number:'1.1-2' }} % | {{oeeData.oee.meta | number:'1.1-2' }} %
                  </div>
                </div>
                <div>
                  OEE
                </div>
              </div>
            </div>

            <div *ngFor="let child2 of oeeData.children">
              <div class="mainPanel" style="cursor: pointer;" (click)="child2.opened=(!child2.opened || false)">
                <div class="oee1 oee2" style="width: 200px; text-align: left;" (click)="changeContent()"> &nbsp;
                  <strong>{{child2.description}}</strong>
                </div>
                <div class="oee1 oee2" style="border-color: #e69138; background-color: #f6b26bff; color: black;">
                  <strong [nbTooltip]="availabilityTooltip(child2.availability)"
                    nbTooltipStatus="primary">{{child2.availability?.indice | number:'1.1-2'}}% |
                    {{child2.availability?.meta | number:'1.1-2'}}%</strong>
                </div>
                <div class="oee1 oee2" style="border-color: #0080c6; background-color: #6d9eebff;color: black;">
                  <strong [nbTooltip]="performanceTooltip(child2.performance)"
                    nbTooltipStatus="primary">{{child2.performance?.indice | number:'1.1-2'}}% |
                    {{child2.performance?.meta | number:'1.1-2'}}%</strong>
                </div>
                <div class="oee1 oee2" style="border-color: #8b8bc4; background-color: #8e7cc3ff;color: black;">
                  <strong [nbTooltip]="qualityTooltip(child2.quality)"
                    nbTooltipStatus="primary">{{child2.quality?.indice | number:'1.1-2'}}% | {{child2.quality?.meta | number:'1.1-2'}}%</strong>
                </div>
                <div class="oee1 oee2" style="border-color: #6aa84f; background-color: #93c47dff;color: black;">
                  <strong>{{child2.oee.indice | number:'1.1-2'}}% | {{child2.oee.meta | number:'1.1-2'}}%</strong>
                </div>
              </div>

              <div *ngIf="child2.opened || false">
                <div *ngFor="let child3 of child2.children">
                  <div class="mainPanel">
                    <div class="oee1 oee3" style="width: 200px; text-align: left;"> &nbsp;&nbsp;&nbsp;
                      {{child3.description}}
                    </div>
                    <div class="oee1 oee3" style="border-color: #f6b26bff; background-color: #f9cb9cff;color: black;">
                      <span [nbTooltip]="availabilityTooltip(child3.availability)"
                        nbTooltipStatus="primary">{{child3.availability?.indice| number:'1.1-2'}}% |
                        {{child3.availability?.meta | number:'1.1-2'}}%</span>
                    </div>
                    <div class="oee1 oee3" style="border-color: #6d9eebff; background-color: #a4c2f4ff;color: black;">
                      <span [nbTooltip]="performanceTooltip(child3.performance)"
                        nbTooltipStatus="primary">{{child3.performance?.indice| number:'1.1-2'}}% |
                        {{child3.performance?.meta | number:'1.1-2'}}%</span>
                    </div>
                    <div class="oee1 oee3" style="border-color: #8e7cc3ff; background-color: #b4a7d6ff;color: black;">
                      <span [nbTooltip]="qualityTooltip(child3.quality)"
                        nbTooltipStatus="primary">{{child3.quality?.indice| number:'1.1-2'}}% | {{child3.quality?.meta| number:'1.1-2'}}%</span>
                    </div>
                    <div class="oee1 oee3" style="border-color: #93c47dff; background-color: #b6d7a8ff;color: black;">
                      {{child3.oee.indice| number:'1.1-2'}}% | {{child3.oee.meta| number:'1.1-2'}}%</div>
                  </div>

                  <div *ngFor="let child4 of child3.children">
                    <div class="mainPanel">
                      <div class="oee1 oee4" style="width: 200px; text-align: left;"> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        {{child4.description}} </div>
                      <div class="oee1 oee4" style="border-color: #f9cb9cff; background-color: #fce5cdff;color: black;">
                        <span [nbTooltip]="availabilityTooltip(child4.availability)"
                          nbTooltipStatus="primary">{{child4.availability?.indice| number:'1.1-2'}}% |
                          {{child4.availability?.meta | number:'1.1-2'}}%</span>
                      </div>
                      <div class="oee1 oee4" style="border-color: #a4c2f4ff; background-color: #c9daf8ff;color: black;">
                        <span [nbTooltip]="performanceTooltip(child4.performance)"
                          nbTooltipStatus="primary">{{child4.performance?.indice| number:'1.1-2'}}% |
                          {{child4.performance?.meta | number:'1.1-2'}}%</span>
                      </div>
                      <div class="oee1 oee4" style="border-color: #b4a7d6ff; background-color: #d9d2e9ff;color: black;">
                        <span [nbTooltip]="qualityTooltip(child4.quality)"
                          nbTooltipStatus="primary">{{child4.quality?.indice|number:'1.1-2'}}% | {{child4.quality?.meta|number:'1.1-2'}}%</span>
                      </div>
                      <div class="oee1 oee4" style="border-color: #b6d7a8ff; background-color: #d9ead3ff;color: black;">
                        {{child4.oee.indice| number:'1.1-2'}}% | {{child4.oee.meta| number:'1.1-2'}}%
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nb-tab>
      <nb-tab tabTitle="OEE">
        <div class="flexColumn" style="height: calc(100vh - 333px); max-height: 100%;">
          <app-widget-panel #panelOEE panelName="OEE-GRAPHIC" [params]="panelParams"></app-widget-panel>
        </div>
      </nb-tab>
      <nb-tab tabTitle="Disponibilidade">
        <div class="flexColumn" style="height: calc(100vh - 333px); max-height: 100%;">
          <app-widget-panel #panelAvailability panelName="OEE-AVAILABILITY" [params]="panelParams"></app-widget-panel>
        </div>
      </nb-tab>
      <nb-tab tabTitle="Performance">
        <div class="flexColumn" style="height: calc(100vh - 333px); max-height: 100%;">
          <app-widget-panel #panelPerformance panelName="OEE-PERFORMANCE" [params]="panelParams"></app-widget-panel>
        </div>
      </nb-tab>
      <nb-tab tabTitle="Qualidade">
        <div class="flexColumn" style="height: calc(100vh - 333px); max-height: 100%;">
          <app-widget-panel #panelQuality panelName="OEE-QUALITY" [params]="panelParams"></app-widget-panel>
        </div>
      </nb-tab>
    </nb-tabset>
  </content>
</app-form-template>