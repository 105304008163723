import { ComponentRef } from "@angular/core";
import { CharacteristicInfo } from "./model/characteristic-info";

export abstract class CharacteristicComponent {
  abstract id_specif: string; // deve ser declarada com o decorator @Input
  abstract id_specif_component: string; // deve ser declarada com o decorator @Input
  abstract id_process: string; // deve ser declarada com o decorator @Input
  abstract blockChange: boolean; // deve ser declarada com o decorator @Input
  abstract characInfoList: Array<CharacteristicInfo>; // deve ser declarada com o decorator @Input
  abstract saveInfo(): Promise<void>;
  abstract sendComponentRef();

  // no constructor do componente que estender essa classe DEVE ser colocado o seguinte:
  // @Inject("characCmpInjector") private characCmpInjector: BehaviorSubject<ICharacteristicComponentInjection>
  // O nome deve ser exatamente como na linha acima
  constructor() { }
}

export interface ICharacteristicComponentInjection {
  id_specif: string;
  id_specif_component: string;
  id_process: string;
  blockChange: boolean;
  characInfoList: Array<CharacteristicInfo>;
}
