import { DatePipe } from '@angular/common';
import { EventEditorComponent } from '../event-editor/event-editor.component';
import { TimeRange } from '../model/TimeRange';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TimeDetail } from "../model/TimeDetail";
import { UIDatePart } from "../model/UIDatePart";
import { NbDialogService } from "@nebular/theme";
import { take } from "rxjs/Operators";
import { WorkScheduleService } from "../work-schedule.service";
import { T2MessageService } from "src/app/core/t2-message.service";

@Component({
  selector: 'app-calendar-day',
  templateUrl: './calendar-day.component.html',
  styleUrls: ['./calendar-day.component.scss']
})
export class CalendarDayComponent implements OnInit {

  @Input() timeDetail: TimeDetail;
  @Input() workScheduleId: string;
  @Output() eventChange = new EventEmitter<{ prev: TimeRange, new: TimeRange }>();

  dateParts: Array<UIDatePart> = [];

  constructor(private dialogService: NbDialogService, private workScheduleService: WorkScheduleService,
    private messageService: T2MessageService) { }

  ngOnInit(): void {
    this.generateDateParts();
  }

  editEvent(item: UIDatePart) {

    this.dialogService.open(EventEditorComponent, {
      context: {
        title: this.timeDetail.dayOfWeek,
        id_workShift: item.timeRange?.id_workShift,
        startTime: new DatePipe("pt-BR").transform(item.timeRange?.startTime, 'HH:mm'),
        endTime: new DatePipe("pt-BR").transform(item.timeRange?.endTime, 'HH:mm'),
      },
    }).onClose.pipe(take(1)).subscribe(resp => {
      if (resp) {
        let newEvent = new TimeRange();
        newEvent.workShift = resp.title ?? item.timeRange?.workShift;
        newEvent.id_workShift = resp.id_workShift;
        newEvent.startTime = new Date(`1899-12-31 ${resp.startTime}`);
        newEvent.endTime = new Date(`1899-12-31 ${resp.endTime}`);

        let prev = item.timeRange,
          forceLoadColor = prev.id_workShift != newEvent.id_workShift;

        newEvent.id = prev.id;
        newEvent.color = prev.color;

        this.workScheduleService.saveWorkScheduleTimeDetail(this.workScheduleId, this.timeDetail.dayOfWeek, newEvent, forceLoadColor).then(tr => {
          if (!prev.id_workShift) {
            newEvent = tr;
            this.timeDetail.timeRangeList.push(newEvent);
          } else {
            if (forceLoadColor) {
              newEvent.color = tr.color;
            }

            Object.assign(item.timeRange, newEvent);
          }

          this.generateDateParts();
          this.eventChange.emit({ prev: prev.id_workShift ? prev : null, new: newEvent });
        }, error => {
          this.messageService.showToastError(error);
          console.error(error);
        });
      }

    });
  }

  removeEvent(item: UIDatePart) {
    const index = this.timeDetail.timeRangeList.findIndex((event: TimeRange) => {
      return event.id_workShift == item.timeRange.id_workShift &&
        event.startTime == item.timeRange.startTime
    });

    if (index >= 0) {
      this.workScheduleService.deleteWorkScheduleTimeDetail(item.timeRange.id).then(() => {
        this.timeDetail.timeRangeList.splice(index, 1);
        this.generateDateParts();
      }, error => {
        this.messageService.showToastError(error);
        console.error(error);
      });
    }
  }

  generateDateParts() {
    // Antes de separar as partes (intervalo com tempo definido e sem tempo definido), orderna por data inicial
    this.timeDetail.timeRangeList.sort((ev1, ev2) => {
      return (ev1.startTime.getTime() - ev2.startTime.getTime()) ||
        (ev1.endTime.getTime() - ev2.endTime.getTime());
    });

    // Ajusta datas de inicio e fim dos eventos, evitando que eles se sobreponham
    let lastEnd: Date = null;
    this.timeDetail.timeRangeList.forEach(event => {

      // Inicio do primeiro evento começa antes das 00:00
      if (lastEnd == null && event.startTime.getTime() <= new Date("1899-12-30 23:59:59.999").getTime()) {
        event.startTime.setTime(new Date("1899-12-31 00:00:00.000").getTime());
      }

      // Final do evento anterior é maior que inicio do evento atual
      if (lastEnd != null && event.startTime.getTime() <= lastEnd.getTime()) {
        event.startTime.setTime(lastEnd.getTime() + 1000);
      }

      // Final do evento é maior que final do dia
      if (event.endTime.getTime() > new Date("1899-12-31 23:59:59.000").getTime()) {
        event.endTime.setTime(new Date("1899-12-31 23:59:59.000").getTime());
      }

      // Se o inicio e fim sao iguais, marca para excluir o evento
      if (event.startTime.getTime() == event.endTime.getTime()) {
        event.deleted = true;
      } else {
        lastEnd = event.endTime;
      }
    });

    this.dateParts.length = 0;
    let lastDate = new Date("1899-12-30 23:59:59.000");

    this.timeDetail.timeRangeList.forEach(event => {
      if (!event.deleted) {
        if ((event.startTime.getTime() - 1000) > lastDate.getTime()) {
          lastDate = new Date(lastDate.getTime() + 1000);
          this.addEmpty(event, lastDate);
        }

        const minutes = (event.endTime.getTime() - event.startTime.getTime()) / 1000 / 60;
        this.dateParts.push({
          perc: (minutes / 1440) * 100, timeRange: event
        });
        lastDate = event.endTime;
      }
    });

    if ((lastDate.getTime()) < new Date("1899-12-31 23:59:59.999").getTime()) {
      const fulfillment = new Date();
      fulfillment.setTime(lastDate.getTime() + 1000)
      this.addEmpty({ startTime: new Date("1900-01-01 00:00:00.000"), endTime: null }, fulfillment);
    }

    const fp = this.dateParts.filter(dp => dp.perc > 0);
    this.dateParts.length = 0;
    this.dateParts.push(...fp);
  }

  addEmpty(event: TimeRange, lastDate: Date) {
    const emptyEvent = new TimeRange();
    emptyEvent.startTime = lastDate;
    emptyEvent.endTime = new Date();
    emptyEvent.endTime.setTime(event.startTime.getTime() - 1000);

    const minutes = (emptyEvent.endTime.getTime() - emptyEvent.startTime.getTime()) / 1000 / 60;

    this.dateParts.push({ perc: (minutes / 1440) * 100, timeRange: emptyEvent, empty: true });
  }

}
