import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NbDialogService } from "@nebular/theme";
import { Subject } from "rxjs";
import { pairwise, startWith, take, takeUntil } from "rxjs/Operators";
import { DialogComponent } from "src/app/core/cmp/dialog/dialog.component";
import { T2HttpClientService } from "src/app/core/http/t2httpClient.service";
import { T2MessageService } from "src/app/core/t2-message.service";
import { RequisitionStock } from "./model/requisition-stock";
import { RequisitionVolume } from "./model/requisition-volume";
import { ReservationRequisition } from "./model/reservation-requisition";
import { DialogTemplateComponent } from "src/app/core/cmp/dialog-template/dialog-template.component";
import { T2ReservationService } from "./t2-reservation.service";

@Component({
  selector: 'app-reservation-requisition',
  templateUrl: './reservation-requisition.component.html',
  styleUrls: ['./reservation-requisition.component.scss']
})
export class ReservationRequisitionComponent implements OnInit, OnDestroy {

  @ViewChild('dialog', { static: true }) dialog: DialogTemplateComponent;

  @Input() itemId: string;
  @Input() requisitionType: string;
  @Input() initialRequisitionSelectedId: string;

  public itemDescription: string;
  public volumeList: Array<RequisitionVolume>;
  public requisitionList = new Array<ReservationRequisition>();
  public formGroupVol: FormGroup;
  private unsubscribe = new Subject<void>();
  private unsubscribeVol = new Subject<void>();
  public selectedReq: ReservationRequisition;
  public lockScreen: boolean = false;
  public formGroup: FormGroup;
  public loadingVolumes: boolean = false;
  public loadingRequisitions: boolean = true;

  constructor(private httpClient: T2HttpClientService,
    private dialogService: NbDialogService,
    private messageService: T2MessageService,
    private formBuilder: FormBuilder,
    private reservationService: T2ReservationService) {
  }

  ngOnInit(): void {
    this.lockScreen = true;
    this.formGroup = this.formBuilder.group({});

    this.loadRequisitions();
  }

  private loadRequisitions() {
    this.loadingRequisitions = true;
    this.reservationService.loadRequisitions(this.itemId, this.requisitionType).pipe(take(1)).subscribe(async reqList => {
      if (reqList) {
        this.requisitionList = reqList;

        this.itemDescription = this.requisitionList[0].itemDescription;

        for (let req of this.requisitionList) {
          this.formGroup.addControl(`${req.requisitionId}_requiredDate`, this.formBuilder.control(req.requiredDate));
          this.formGroup.addControl(`${req.requisitionId}_pendingQty`, this.formBuilder.control(req.requestedQty - req.consumedQty - req.transferredQty));
          this.formGroup.addControl(`${req.requisitionId}_reservedQty`, this.formBuilder.control(0));

          
          await this.loadRequisitionInfo(req).then(() => { });
        }

        if (this.initialRequisitionSelectedId) {
          this.selectRequisition(this.requisitionList.find(req => req.requisitionId == this.initialRequisitionSelectedId));          
        } else {
          this.selectRequisition(this.requisitionList[0]);
        }
      }

      this.lockScreen = false;
      this.loadingRequisitions = false;

      setTimeout(() => {
        if (this.initialRequisitionSelectedId) {
          document.getElementById(this.initialRequisitionSelectedId).scrollIntoView();
        }
      }, 200);
      
    }, error => {
      this.lockScreen = false;
    })
  }

  private async loadRequisitionInfo(req: ReservationRequisition) {
    if (this.formGroup) {
      this.formGroup.controls[`${req.requisitionId}_requiredDate`].setValue(req.requiredDate);
      this.formGroup.controls[`${req.requisitionId}_pendingQty`].setValue(req.requestedQty - req.consumedQty - req.transferredQty);
    }

    await this.loadVolumes(req).then();
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
    this.unsubscribeVol.next();
    this.unsubscribeVol.complete();
  }

  private loadVolumes(req: ReservationRequisition) {
    this.loadingVolumes = true;
    this.volumeList = [];

    return new Promise<void>((resolve, reject) => {
      this.reservationService.loadVolumes(req.requisitionId)
        .pipe(take(1))
        .subscribe(volList => {
          this.unsubscribeVol.next();
          this.formGroupVol = this.formBuilder.group({}, { updateOn: "blur" });

          if (volList) {
            this.volumeList = volList;

            let qtyReqReserved: number = 0;

            this.volumeList.forEach(vol => {
              qtyReqReserved += vol.quantityRequisition;

              this.formGroup.controls[`${req.requisitionId}_reservedQty`].setValue(this.formGroup.controls[`${req.requisitionId}_reservedQty`].value + vol.quantityReserved);

              this.formGroupVol.addControl(`${vol.id_volume}_quantityStock`, this.formBuilder.control(vol.quantityStock));
              this.formGroupVol.addControl(`${vol.id_volume}_quantityReserved`, this.formBuilder.control(vol.quantityReserved));
              this.formGroupVol.addControl(`${vol.id_volume}_quantityAvailable`, this.formBuilder.control(vol.quantityAvailable));
              this.formGroupVol.addControl(vol.id_volume, this.formBuilder.control(Math.max(vol.quantityRequisition, 0), Validators.max(vol.quantityAvailable)));
              this.formGroupVol.controls[vol.id_volume].valueChanges
                .pipe(takeUntil(this.unsubscribeVol),
                  startWith(vol.quantityRequisition),
                  pairwise())
                .subscribe(([prevValue, nextValue]: [number, number]) => {
                  if (nextValue > vol.quantityAvailable) {
                    this.lockScreen = false;
                  } else {
                    let depReqStk = new RequisitionStock();
                    depReqStk.id_item = req.itemId;
                    depReqStk.id_requisition = req.requisitionId;
                    depReqStk.id_volume = vol.id_volume;
                    depReqStk.requisitionCode = req.code;
                    depReqStk.itemDescription = req.itemDescription;
                    depReqStk.quantity = nextValue;

                    this.lockScreen = true;
                    let params = new Map<string, string>();
                    params.set("tipoRequisicao", this.requisitionType);
                    this.httpClient.post("stk.reservationRequisition/manageStockReservation", params, [depReqStk])
                      .pipe(take(1))
                      .subscribe(resp => {
                        vol.quantityRequisition = nextValue;
                        this.formGroup.controls[`${req.requisitionId}_reservedQty`].setValue((this.formGroup.controls[`${req.requisitionId}_reservedQty`].value - prevValue) + nextValue);
                        this.lockScreen = false;
                      }, error => {
                        this.lockScreen = false;
                      });
                  }
                })
            });

            this.formGroup.controls[`${req.requisitionId}_reservedQty`].setValue(Math.max(qtyReqReserved, 0));
          }

          this.lockScreen = false;
          this.loadingVolumes = false;
          resolve();
        }, error => {
          this.loadingVolumes = false;
          reject(error);
        });
    })

  }

  public selectRequisition(req: ReservationRequisition) {
    this.selectedReq = req;

    this.loadRequisitionInfo(this.selectedReq).then(() => { });
  }

  public autoReservation() {
    let dlg = this.dialogService.open(DialogComponent, {
      context: {
        message: "Deseja distribuir automaticamente a quantidade entre os volumes ?",
        message2: "As quantidades informadas manualmente serão desfeitas",
        actions: [{ description: "Sim" }, { description: "Não", status: "basic" }]
      }
    });

    dlg.onClose.pipe(take(1)).subscribe(resp => {
      if (resp == "Sim") {
        this.lockScreen = true;

        this.volumeList.forEach(vol => {
          vol.quantityAvailable += vol.quantityReserved;
          vol.quantityRequisition = 0;
          vol.quantityReserved = 0;
        });

        this.httpClient.post("stk.reservationRequisition/clearReservation", null, this.requisitionList.map(r => r.requisitionId))
          .pipe(take(1))
          .subscribe(() => {
            let depReqStkList = new Array<RequisitionStock>();

            this.requisitionList.forEach(req => {
              let qty = req.requestedQty - req.consumedQty;

              for (let vol of this.volumeList) {
                if (vol.quantityAvailable > 0) {
                  if (vol.quantityAvailable >= qty) {
                    vol.quantityRequisition = qty;
                    vol.quantityAvailable -= qty;

                    qty = 0;
                  } else {
                    vol.quantityRequisition = vol.quantityAvailable;
                    vol.quantityAvailable = 0;

                    qty -= vol.quantityRequisition;
                  }

                  let depReqStk = new RequisitionStock();
                  depReqStk.id_item = req.itemId;
                  depReqStk.id_requisition = req.requisitionId
                  depReqStk.id_volume = vol.id_volume;
                  depReqStk.requisitionCode = req.code;
                  depReqStk.itemDescription = req.itemDescription;
                  depReqStk.quantity = vol.quantityRequisition;

                  depReqStkList.push(depReqStk);

                  if (qty <= 0) {
                    break;
                  }
                }
              }
            });

            let params = new Map<string, string>();
            params.set("tipoRequisicao", this.requisitionType);
            this.httpClient.post("stk.reservationRequisition/manageStockReservation", params, depReqStkList)
              .pipe(take(1))
              .subscribe(() => {
                // Apenas para carregar a informação da qtd reservada
                let selReqId = this.selectedReq.requisitionId;
                this.requisitionList.forEach(req => {
                  this.selectRequisition(req);
                });

                setTimeout(() => {
                  this.selectRequisition(this.requisitionList.find(r => r.requisitionId == selReqId));
                }, 200);

              }, error => {
                this.lockScreen = false;
              });
          }, error => {
            this.lockScreen = false;
          });
      }
    })
  }

  public closeDialog() {
    this.dialog.close(null);
  }
}
