import { Component } from '@angular/core';
import { ICellRendererAngularComp } from "ag-grid-angular";
import { ICellRendererParams } from "ag-grid-community";
import { take } from "rxjs/Operators";
import { T2HttpClientService } from "src/app/core/http/t2httpClient.service";

@Component({
  selector: 'app-aggregation-renderer',
  templateUrl: './aggregation-renderer.component.html',
  styleUrls: ['./aggregation-renderer.component.scss']
})
export class AggregationRendererComponent implements ICellRendererAngularComp {

  params: ICellRendererParams;

  constructor(private httpClient: T2HttpClientService) { }

  refresh(params: ICellRendererParams): boolean {

    let mustRefresh = false;

    this.updateDescription(params).then(refresh => { mustRefresh = refresh});

    return mustRefresh;
  }

  agInit(params: ICellRendererParams): void {
    this.params = params;

    this.updateDescription(params);
  }

  private updateDescription(params: ICellRendererParams): Promise<boolean> {

    const datasetId = params.colDef?.["datasetId"];

    let value = params.value;
    if (typeof params.value === "object") {
      value = value?.value;
    }

    if (datasetId && value && !params.context.aggregations[value]) {
      return new Promise((resolve, reject) => {
        this.httpClient.get(`core.presentation/infoDescription/${datasetId}/${params.value}`, null)
          .pipe(take(1))
          .subscribe(resp => {
            params.context.aggregations[params.value] = resp.description || "<descrição não informada>";
            resolve(true);
          });
      });
    }

    return new Promise((resolve, reject) => { resolve(false); });
  }

}
