import { Component } from "@angular/core";
import { ICellRendererAngularComp } from "ag-grid-angular";
import { IAfterGuiAttachedParams, ICellRendererParams } from "ag-grid-community";

@Component({
  selector: "app-cil-ep-renderer",
  templateUrl: "./cil-ep-renderer.component.html",
  styleUrls: ["./cil-ep-renderer.component.scss"]
})
export class CilEpRendererComponent implements ICellRendererAngularComp {

  updating = false;
  text: string;

  agInit(params: ICellRendererParams): void {
    if (params.data) {
      this.updating = params.data.updating;
      this.text = params.value;
    }
  }

  afterGuiAttached?(params?: IAfterGuiAttachedParams): void {
  }

  refresh(params: any): boolean {
    return false;
  }

}
