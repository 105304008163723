import { SortTasksDialogComponent } from './../../sort-tasks-dialog/sort-tasks-dialog.component';
import { AfterViewInit, Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { NbDialogService } from "@nebular/theme";
import { CellClickedEvent, IRowNode, RowDragCallbackParams, RowDragEvent } from "ag-grid-community";
import { Observable, Subject } from "rxjs";
import { take, takeUntil } from "rxjs/Operators";
import { ActionService } from "src/app/core/action/action.service";
import { IIconRendererParams } from "src/app/core/cmp/t2grid/cellRenderer/icon-renderer/iicon-renderer-params";
import { T2gridComponent } from "src/app/core/cmp/t2grid/t2grid.component";
import { T2HttpClientService } from "src/app/core/http/t2httpClient.service";
import { T2AccessItem, T2AccessItemDatasetActionType } from "src/app/core/security/model/t2accessItem";
import { T2SecurityService } from "src/app/core/security/t2security.service";
import { T2MessageService } from "src/app/core/t2-message.service";
import { ApsService } from "../../../aps.service";
import { ChangeDeviceDialogComponent } from "../../change-device-dialog/change-device-dialog.component";
import { FixedDateDialogComponent } from "../../fixed-date-dialog/fixed-date-dialog.component";
import { TaskPlan } from "../../../model/task-plan";
import { TimeReservationComponent } from "../../time-reservation/time-reservation.component";
import { ComplementaryField } from "../../../../../utils/model/complementary-field";
import { TaskStatusCellRendererComponent } from "../../../../utils/task-status-cell-renderer/task-status-cell-renderer.component";
import { CilSmallBoxRendererComponent } from "src/app/bsn/flx/cil-small-box-renderer/cil-small-box-renderer.component";
import { T2InputTextComponent } from "src/app/core/cmp/ui/t2-input-text/t2-input-text.component";
import { FormControl } from "@angular/forms";
import { SequenceTooltipComponent } from "../sequence-tooltip/sequence-tooltip.component";
import { CilindroCor } from "src/app/bsn/flx/model/cilindro-cor";
import { TaskStartdateCellRendererComponent } from "../task-startdate-cell-renderer/task-startdate-cell-renderer.component";
import { T2LocalStorageService } from "src/app/core/t2local-storage.service";
import { WarningRendererComponent } from "src/app/core/warning/warning-renderer/warning-renderer.component";
import { WarningComponent } from "src/app/core/warning/warning/warning.component";
import { TaskUrgentCellRendererComponent } from "src/app/bsn/prd/utils/task-urgent-cell-renderer/task-urgent-cell-renderer.component";


@Component({
  selector: 'app-aps-sequence',
  templateUrl: './aps-sequence.component.html',
  styleUrls: ['./aps-sequence.component.scss']
})
export class ApsSequenceComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild('gridSequence', { static: true }) gridSequence: T2gridComponent;
  @ViewChild('inputFilter', { static: true }) inputFilter: T2InputTextComponent;
  @Output() focused = new EventEmitter<string>();
  @Output() lockScreen = new EventEmitter<boolean>();

  private taskPlanList: Array<TaskPlan>;
  private unsubscribe = new Subject<void>();
  private previousDeviceId: string;
  private actionList: Array<T2AccessItem>;
  private previousOverNode: IRowNode;
  private insertedApsId: number;
  public calculationMethod: string = "Carga Maquina";
  public device: { id: string, description: string };
  public gridContextComponent = { contextMenu: [] };
  public loadingGrid: boolean = false;

  sequenceGridComponents = {
    taskStatusCellRender: TaskStatusCellRendererComponent,
    taskStartdateCellRenderer: TaskStartdateCellRendererComponent,
    sequenceTaskDetail: SequenceTooltipComponent,
    cilRenderer: CilSmallBoxRendererComponent,
    warningRenderer: WarningRendererComponent,
    taskUrgentCellRenderer: TaskUrgentCellRendererComponent,
  };

  constructor(public apsService: ApsService, private messageService: T2MessageService, private dialogService: NbDialogService,
    private httpClient: T2HttpClientService, private sec: T2SecurityService,
    private actionService: ActionService, private storage: T2LocalStorageService) { }

  ngOnInit(): void {
    this.configGrid();
    this.loadActionGrid();

    this.apsService.programmingVars.deviceSelectedId
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(id => {
        if (id) {
          this.device = this.apsService.getSimulationDevice(id);
          setTimeout(() => {
            this.newDeviceSelected(id);
          }, 100);
        }
      });
  }

  ngOnDestroy(): void {
    this.apsService.programmingVars.planSelectedList.next(undefined);
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  ngAfterViewInit(): void {
    this.inputFilter.formGroup.setControl("busca", new FormControl(undefined, { updateOn: "change" }));
    this.inputFilter.formGroup.get("busca").valueChanges
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(value => {
        this.gridSequence.setQuickFilter(value);
      });
  }

  private configGrid() {
    this.gridSequence.t2GridOptions.rowDragMultiRow = true;
    this.gridSequence.t2GridOptions.onRowDragEnd = this.onRowDragEnd.bind(this);
    this.gridSequence.t2GridOptions.onRowDragMove = this.onRowDragMove.bind(this);
    this.gridSequence.t2GridOptions.onCellClicked = this.onGridTaskCellClicked.bind(this);
    this.gridSequence.t2GridOptions.statusBar = {
      statusPanels: [
        {
          statusPanel: "agSelectedRowCountComponent",
          align: "left"
        }
      ]
    };

    this.gridSequence.setGridColumnDefs([
      {
        headerName: "",
        field: 'selection',
        checkboxSelection: true,
        lockPosition: true,
        pinned: "left",
        valueGetter: "node.rowIndex + 1",
        width: 90,
        getQuickFilterText: (params) => "",
        rowDrag: (params: RowDragCallbackParams) => {
          return !this.apsService.isRealSimulation() && this.apsService.canSortSelectedTasks() && params.node.isSelected() && !this.inFirmDeadlineRange(params.data.startDate) &&
            !params.api.getSelectedRows().some(tp => tp.fixedDate || tp.started || this.inFirmDeadlineRange(tp.startDate));
        },
        rowDragText: (params, dragItemCount) => {
          return dragItemCount > 1 ? dragItemCount + " Itens" : params.rowNode.data.prodOrder ? "OP " + params.rowNode.data.prodOrder : params.rowNode.data.prodOrdDescription;
        },
        cellRenderer: "iconRenderer",
        cellRendererParams: params => {
          let iconList: Array<IIconRendererParams> = [
            {
              icon: { name: params.data.fixedDate ? "lock" : "" },
              text: { value: params.value, position: "Left" },
              tooltip: "Data fixada"
            }
          ];

          if (params.data.incompatibilityNextPlan > 0) {
            let percentColor = 100 - ((params.data.incompatibilityNextPlan / 10) * 100)
            iconList.push({
              icon: {
                name: "alert-triangle",
                color: `rgb(255,${percentColor},${percentColor})`
              },
              text: {
                value: ((params.data.incompatibilityNextPlan / 10) * 100) + "%",
                position: "Bottom",
                sizeInPercent: 70
              },
              tooltip: "Incompatibilidade com o planejamento seguinte"
            });
          }

          if (params.data.warningList?.some(w => w.critical)) {
            
            iconList.push({
              icon: {
                name: "bell",
                color: "#EB003F"
              },
              text: {
                value: "",
                position: "Left"
              },
              tooltip: `Há um aviso crítico`
            })
          }

          return { iconList: iconList };
        }
      },
      { headerName: "Início", field: "startDate", type: ["dateTimeColumn"], tooltipField: 'startDate', getQuickFilterText: (params) => "", cellRenderer: "taskStartdateCellRenderer" },
      { headerName: "Fim", field: "endDate", type: ["dateTimeColumn"], getQuickFilterText: (params) => "", },
      { headerName: "Status", field: "started", type: ["booleanColumn"], cellRenderer: "taskStatusCellRender" },
      { headerName: "Acerto (min)", type: "numericColumn", field: "estimatedSetupDuration", width: 50, getQuickFilterText: (params) => "", },
      { headerName: "Produção (min)", type: "numericColumn", field: "estimatedRunDuration", getQuickFilterText: (params) => "", },
      {
        headerName: "Avisos", field: "warningList", getQuickFilterText: (params) => "",
        cellRenderer: "warningRenderer", width: 130,
        valueGetter: (params) => {
          let text: string = null, finished: number = 0, late: number = 0, today: number = 0, onTime: number = 0, todayDate: Date = new Date();
          todayDate.setHours(0, 0, 0, 0);

          if (params.data.warningList) {
            params.data.warningList.forEach(warning => {
              if (warning.completionDate) {
                finished++;
              } else if (warning.maxDate?.getTime() < todayDate.getTime()) {
                late++;
              } else if (warning.maxDate?.getTime() == todayDate.getTime()) {
                today++;
              } else {
                onTime++;
              }
            });

            text = `A${late} | H${today} | P${onTime} | F${finished}`;
          }

          return text;
        }
      },
      { headerName: "OP", field: "prodOrder", tooltipField: 'prodOrder', },
      { headerName: "Tarefa", field: "task", getQuickFilterText: (params) => "", },
      { headerName: "Descrição", field: "prodOrdDescription" },
      { headerName: "Cliente", field: "client" },
      { headerName: "Dt Mín", field: "minDate", type: ["dateTimeColumn"], getQuickFilterText: (params) => "", },
      { headerName: "Dt Max", field: "maxDate", type: ["dateTimeColumn"], getQuickFilterText: (params) => "", },
      { headerName: "OP Dt Mín", field: "prodOrdMinDate", type: ["dateColumn"], getQuickFilterText: (params) => "", },
      { headerName: "OP Dt Máx", field: "prodOrdMaxDate", type: ["dateColumn"], getQuickFilterText: (params) => "", },
      { headerName: "Urgente", field: "urgent", type: ["booleanColumn"], cellRenderer: "taskUrgentCellRenderer", width: 120 },
      {
        headerName: "Cilindros", field: "cilindro", tooltipField: 'cilindro', getQuickFilterText: (params) => "",
        cellRenderer: "cilRenderer", width: 115,
        valueGetter: (params) => {
          let cylColors = new Array<CilindroCor>();

          if (params.data.cylinderStatus) {
            params.data.cylinderStatus.forEach((value, key) => {
              for (let i = 0; i < Number(value); i++) {
                let color = new CilindroCor();
                color.status = key;
                cylColors.push(color);
              }
            });

            params.data["colors"] = cylColors;
          }
          return null;
        }
      }
    ]);

    if (!this.gridSequence.loadColumnGridState()) this.gridSequence.autoSizeAllColumns(false);
  }

  private inFirmDeadlineRange(planStartDate: Date): boolean {
    let firmDeadlineDate: Date = new Date(this.taskPlanList.find(tp => !tp.started).startDate);
    let firmDeadline: number = this.apsService.getSimulationFirmDeadline();

    if (firmDeadline) {
      firmDeadlineDate.setHours(0, 0, 0, 0);
      firmDeadlineDate.setDate(firmDeadlineDate.getDate() + firmDeadline);

      return planStartDate < firmDeadlineDate;
    } else {
      return false;
    }
  }


  loadActionGrid() {
    this.sec.getDatasetActions("ord_ordemprod")
      .subscribe(actionList => {
        this.actionList = actionList;
        this.gridContextComponent.contextMenu = [];

        this.actionList.forEach((action: T2AccessItem) => {

          // Os IDs fixos abaixo, é o
          const specificMenu = [
            'zD20160623H100414520R000000002', //ID da tela de empenho
            '{1E34E2E6-D354-42DF-BE1C-69B9607FDDAE}', //ID da edição da OP
          ]

          if ((T2AccessItemDatasetActionType.DSACTION_OTHER === action.datasetActionType
            && action.id_report && !action.reportFilterProps) ||
            specificMenu.includes(action.id)) {
            this.gridContextComponent.contextMenu.push({
              name: T2AccessItemDatasetActionType.DSACTION_EDIT === action.datasetActionType ? action.datasetGroup + " - " + action.datasetActionDescription : action.datasetActionDescription,
              requiresId: true,
              action: () => { this.executeDatasetAction(action, "OP") },
            });
          }
        });
      });

    this.sec.getDatasetActions("epp_especif")
      .subscribe(actionListEP => {
        this.actionList = actionListEP;
        this.actionList.forEach((action: T2AccessItem) => {
          if (T2AccessItemDatasetActionType.DSACTION_OTHER === action.datasetActionType && action.id_report
            && !action.reportFilterProps) {
            this.gridContextComponent.contextMenu.push({
              name: action.datasetActionDescription,
              requestId: true,
              action: () => { this.executeDatasetAction(action, "EP") },
            });
          }
        });
      });
  }

  private executeDatasetAction(action: T2AccessItem, tipoDataSet: string) {
    const params = new Map<string, string>();
    params.set("datasetName", action.datasetName);

    const rows = new Array(...this.gridSequence.getRowsSelected());
    if (!rows.length && this.gridSequence.getFocusedRow()) {
      rows.push(this.gridSequence.getFocusedRow());
    }

    rows.forEach((row) => {

      if (tipoDataSet === "OP") {
        params.set("id", row.id_ordemProd);
      } else if (tipoDataSet === "EP") {
        params.set("id", row.id_especificacao);
      }

      const obs$: Observable<any> = this.actionService.executeAction(action, params, 'newWindow');
      if (obs$) {
        obs$.pipe(take(1)).subscribe(resp => this.messageService.showToast("Ação realizada com sucesso", action.datasetActionDescription, "success"));
      }
    });
  }

  private async onRowDragEnd(e: RowDragEvent) {
    if (!e.overNode) {
      return;
    }

    e.nodes = this.gridSequence.getSelectedNodes();

    if (e.nodes.some(rowNode => rowNode.rowIndex == e.overIndex)) {
      return;
    }

    if (e.overNode.data.started) {
      this.messageService.showToast("Não é possível mover para antes de um planejamento já iniciado", "Atenção", "warning");
      return;
    }

    this.lockScreen.emit(true);

    e.nodes.sort((a, b) => a.rowIndex < b.rowIndex ? -1 : 1);

    let elementsToMove = new Array<TaskPlan>();

    e.nodes.forEach(rowNode => {
      let fromIndex = this.taskPlanList.findIndex(tp => tp.apsId == rowNode.data["apsId"]),
        element = this.taskPlanList[fromIndex];

      elementsToMove.push(element);

      this.taskPlanList.splice(fromIndex, 1);
    });

    let toIndex = this.taskPlanList.findIndex(tp => tp.apsId == e.overNode.data["apsId"]);

    this.taskPlanList.splice(toIndex, 0, ...elementsToMove);

    await this.recalculate().finally(() => this.lockScreen.emit(false));
  }

  private onRowDragMove(e: RowDragEvent) {
    if (e.overNode) {
      if (this.previousOverNode != e.overNode) {

        if (this.previousOverNode) {
          // this.previousOverNode.setHighlighted(null);
        }

        // e.overNode.setHighlighted(RowHighlightPosition.Above);
        this.previousOverNode = e.overNode;
      }
    }
  }

  private onGridTaskCellClicked(event: CellClickedEvent) {
    if (event.colDef.field == "warningList" && event.data.warningList && event.data.warningList.length > 0) {
      let dialog = this.dialogService.open(WarningComponent, {
        context: {
          warningList: event.data.warningList
        }
      });

      dialog.onClose.pipe(take(1)).subscribe(() => {
        this.gridSequence.redrawRows({ rowNodes: [this.gridSequence.getFocusedNode()]});
      })
    }
  }

  selectionChanged() {
    let redrawNodes = this.gridSequence.getSelectedNodes();

    this.apsService.programmingVars.planSelectedList.next(redrawNodes?.map(node => node.data));

    this.gridSequence.getLastSelectedNodes().forEach(rn => {
      redrawNodes.push(rn);
    });

    this.gridSequence.redrawRows({ rowNodes: redrawNodes });
  }

  private setGridData() {
    this.taskPlanList?.forEach(taskPlan => {
      taskPlan.estimatedSetupDuration = Number(taskPlan.estimatedSetupDuration || 0.0);
      taskPlan.estimatedRunDuration = Number(taskPlan.estimatedRunDuration || 0.0);
      taskPlan.minDate = taskPlan.minDate ? new Date(taskPlan.minDate) : taskPlan.minDate;
      taskPlan.maxDate = taskPlan.maxDate ? new Date(taskPlan.maxDate) : taskPlan.maxDate;
      taskPlan.prodOrdMinDate = taskPlan.prodOrdMinDate ? new Date(taskPlan.prodOrdMinDate) : taskPlan.prodOrdMinDate;
      taskPlan.prodOrdMaxDate = taskPlan.prodOrdMaxDate ? new Date(taskPlan.prodOrdMaxDate) : taskPlan.prodOrdMaxDate;
      taskPlan['cilindro'] = taskPlan['cilindro'] || "cilindro";
    });
    this.gridSequence.setGridData(this.taskPlanList, null);

  }

  private showComplementaryFields(compFields: Array<ComplementaryField>) {
    this.configGrid();
    let columnDef = this.gridSequence.t2GridOptions.columnDefs;

    const fieldTypeFunc = (field) => {
      if (["INT", "FLOAT"].includes(field.type)) return "numericColumn";
      if (field.type == "DATE") return "dateColumn";
      if (field.type == "DATE_TIME") return "dateTimeColumn";
      if (field.type == "BOOLEAN") return "booleanColumn";

      return null;
    };

    compFields.forEach(field => {
      columnDef.push({
        headerName: field.name,
        field: field.name,
        getQuickFilterText: (params) => "",
        valueGetter: params => {
          let compField = params.data.complementaryFields?.find(cf => cf.name == field.name);

          if (["DATE", "DATE_TIME"].includes(compField?.type)) {
            compField.value = compField.value ? new Date(compField.value) : compField.value;
          }

          return compField?.value;
        },
        type: fieldTypeFunc(field)
      })
    })

    this.gridSequence.setGridColumnDefs(columnDef);
  }

  private newDeviceSelected(id: string) {

    if (!id) {
      this.taskPlanList = undefined;
      this.setGridData();
      return;
    }

    this.loadingGrid = true;

    if (this.previousDeviceId) {
      this.apsService.unsubscribeDevice(this.previousDeviceId);
    }

    this.apsService.getDeviceData(id)
      .subscribe(tpList => {
        if (tpList && tpList.length > 0) {
          let selectedNodes = this.gridSequence.getSelectedNodes();
          let redraw: boolean = this.taskPlanList ? this.taskPlanList.some(tp => !tpList.map(p => p.apsId).includes(tp.apsId)) || tpList.some(tp => !this.taskPlanList.map(p => p.apsId).includes(tp.apsId)) : false;

          this.taskPlanList = tpList;

          let tpCompField = tpList.find(tp => tp.complementaryFields?.length > 0);

          if (tpCompField) {
            this.showComplementaryFields(tpCompField.complementaryFields);
          }

          this.setGridData();
          if (!this.gridSequence.loadColumnGridState()) this.gridSequence.autoSizeAllColumns(false);

          if (this.insertedApsId) {
            let rowNode = this.gridSequence.getRowNode(this.insertedApsId);
            rowNode?.setSelected(true);
            this.insertedApsId = null;
          } else {
            selectedNodes?.forEach(rowNode => {
              this.gridSequence.setSelected(rowNode.id);
            });
          }

          if (this.gridSequence.getSelectedNodes()?.length > 0) {
            this.gridSequence.ensureIndexVisible(this.gridSequence.getSelectedNodes()[0].rowIndex, "middle");
          }

          if (redraw) {
            this.gridSequence.redrawRows();
          }

          this.loadingGrid = false;
        } else {
          this.taskPlanList = undefined;
          this.setGridData();
        }

        let columnStateGrid = JSON.parse(this.storage.getData("t2g_" + this.gridSequence.gridStateName));
        this.storage.setData("t2g_" + this.gridSequence.gridStateName, JSON.stringify(columnStateGrid));
        this.gridSequence.loadColumnGridState();

      }, error => {
        this.messageService.showToastError(error);
        console.error(error);
      });

    this.previousDeviceId = id;
  }

  public setFixedDate(status: boolean) {
    let nodesSelected = this.gridSequence.getSelectedNodes();

    if (!nodesSelected || nodesSelected.length == 0) {
      this.messageService.showToast("Nenhum registro selecionado", "Atenção", "warning");
      return;
    } else {
      if (status) {
        if (nodesSelected.length > 1) {
          this.messageService.showToast("A data fixa só pode ser colocada em um registro por vez", "Atenção", "warning");
          return;
        }

        this.gridSequence.ensureIndexVisible(nodesSelected[0].rowIndex, "middle");

        let dlg = this.dialogService.open(FixedDateDialogComponent, {
          context: {
            fixedDate: new Date(nodesSelected[0].data.startDate)
          },
          autoFocus: true, closeOnEsc: true, hasBackdrop: true
        });

        dlg.componentRef.instance.callBack.pipe(take(1)).subscribe(fixedDate => {
          let plan = this.taskPlanList.filter(tp => tp.apsId == nodesSelected[0].data.apsId)[0];
          plan.startDate = fixedDate;
          plan.fixedDate = true;

          this.apsService.programmingVars.deviceSelectedId
            .pipe(take(1))
            .subscribe(deviceId => {
              this.apsService.setFixedDate(this.taskPlanList, deviceId).then(() => {
                dlg.close();
                this.gridSequence.redrawRows({ rowNodes: nodesSelected });
              }, error => {
                dlg.close();
                this.messageService.showToastError(error);
                console.error(error);
              })
            });
        });
      } else {
        let planList = this.taskPlanList.filter(tp => nodesSelected.map(n => n.data.apsId).includes(tp.apsId));

        if (planList.some(plan => !plan.fixedDate)) {
          this.messageService.showToast("Selecione apenas registros que estão com a data fixada", "Atenção", "warning");
          return;
        }

        this.gridSequence.ensureIndexVisible(nodesSelected[0].rowIndex, "middle");

        planList.forEach(plan => {
          plan.fixedDate = false;
        });

        this.apsService.programmingVars.deviceSelectedId
          .pipe(take(1))
          .subscribe(deviceId => {
            this.apsService.setFixedDate(this.taskPlanList, deviceId).then(() => {
              this.gridSequence.redrawRows({ rowNodes: nodesSelected });
            });
          });
      }
    }
  }

  public onComponentClick() {
    this.focused.emit("sequencing");
  }

  public async recalculate() {
    let selectedNodes = this.gridSequence.getSelectedNodes();
    await this.apsService.recalculate(this.taskPlanList, this.calculationMethod, null, this.apsService.getSimulationFirmDeadline(), this.apsService.programmingVars.deviceSelectedId.getValue()).then(tpList => {
      this.taskPlanList = tpList;

      this.setGridData();
      selectedNodes.forEach(node => {
        this.gridSequence.setSelected(node.data["ID"]);
      });

      if (!this.gridSequence.loadColumnGridState()) this.gridSequence.autoSizeAllColumns(false);

      if (selectedNodes.length > 0) {
        this.gridSequence.ensureIndexVisible(this.gridSequence.getRowNode(selectedNodes[0].id).rowIndex, "middle");
      }

      this.gridSequence.redrawRows();
    }, error => {
      this.messageService.showToastError(error);
      console.error(error);
    });
  }

  public timeReservation() {
    let selectedRows = this.gridSequence.getRowsSelected();

    if (selectedRows.length > 1) {
      this.messageService.showToast("Para editar a reserva de tempo, selecione apenas 1 registro", "Atenção", "warning");
      return;
    }

    if (selectedRows[0]?.prodOrder) {
      this.messageService.showToast("O registro selecionado não é uma reserva de tempo", "Atenção", "warning");
      return;
    }

    let insert = selectedRows.length == 0;

    if (!insert) {
      this.gridSequence.ensureIndexVisible(this.gridSequence.getSelectedNodes()[0].rowIndex, "middle");
    }

    let dlg = this.dialogService.open(TimeReservationComponent, {
      context: {
        insert: insert,
        informationId: selectedRows[0]?.id_task
      },
      autoFocus: true, closeOnEsc: false, hasBackdrop: true, closeOnBackdropClick: false
    });

    dlg.componentRef.instance.callBack.pipe(take(1)).subscribe(id_task => {
      this.apsService.updateTimeReservationPlanningsInSimulation(selectedRows[0]?.id_device, selectedRows[0]?.apsId, id_task).then(apsId => {
        if (!insert) {
          let params = new Map<string, string>();
          params.set("id_task", selectedRows[0]?.id_task);
          this.httpClient.post("production.aps/updateTimeReservation", params, null)
            .pipe(take(1))
            .subscribe(resp => {
              this.apsService.programmingVars.deviceSelectedId
                .pipe(take(1))
                .subscribe(deviceId => {
                  if (resp.planningList && !Array.isArray(resp.planningList)) {
                    resp.planningList = [resp.planningList];
                  }

                  resp.planningList?.forEach((tp: TaskPlan) => {
                    let plan = this.taskPlanList.find(p => p.apsId == tp.apsId);

                    plan.estimatedRunDuration = tp.estimatedRunDuration;
                    plan.task = tp.task;
                    plan.prodOrdDescription = tp.prodOrdDescription;
                  });

                  let selectedNode = this.gridSequence.getRowNode(selectedRows[0].apsId);

                  if (selectedNode) {
                    selectedNode.setSelected(true);
                    this.gridSequence.ensureIndexVisible(selectedNode.rowIndex, "middle");
                  }

                  this.gridSequence.refreshCells();

                  dlg.close();
                });
            }, error => {
              dlg.close();
              this.messageService.showToastError(error);
              console.error(error);
            });
        } else {
          let rowNode = this.gridSequence.getRowNode(apsId);

          if (rowNode) {
            rowNode.setSelected(true);
            this.gridSequence.ensureIndexVisible(rowNode.rowIndex);
          } else {
            this.insertedApsId = apsId;
          }

          dlg.close();
        }
      }, error => {
        dlg.close();
        this.messageService.showToastError(error);
        console.error(error);
      });
    });
  }

  public swapDevice() {
    let selectedNodes = this.gridSequence.getSelectedNodes();

    if (selectedNodes.length == 0) {
      this.messageService.showToast("Nenhum registro selecionado", "Atenção", "warning");
      return;
    }

    if (selectedNodes.some(rn => !rn.data.prodOrder)) {
      this.messageService.showToast("Existem registros selecionados que não são tarefas de OP", "Atenção", "warning");
      return;
    }

    this.gridSequence.ensureIndexVisible(selectedNodes[0].rowIndex, "middle");

    this.apsService.programmingVars.deviceSelectedId
      .pipe(take(1))
      .subscribe(deviceId => {
        let params = new Map<string, string>();
        params.set("id_device", deviceId);

        this.httpClient.get("production.aps/compatibleDevices", params)
          .pipe(take(1))
          .subscribe(resp => {
            if (!Array.isArray(resp.compatibleDevList?.compatibleDev)) {
              resp.compatibleDevList.compatibleDev = [resp.compatibleDevList.compatibleDev];
            }

            if (resp.compatibleDevList?.compatibleDev?.length == 0) {
              this.messageService.showToast("Não existem dispositivos compatíveis para os quais os planejamentos possam ser trocados", "ATENÇÃO", "warning");
              return;
            }

            let dlg = this.dialogService.open(ChangeDeviceDialogComponent, {
              context: {
                itemList: resp.compatibleDevList.compatibleDev.map(dev => {
                  return {
                    id: dev.id_device,
                    description: dev.description
                  }
                })
              }
            });

            dlg.componentRef.instance.callBack.pipe(take(1)).subscribe(newDeviceId => {
              this.lockScreen.emit(true);
              this.apsService.swapDevice(selectedNodes.map(rn => rn.data), deviceId, newDeviceId).then(() => {
                dlg.close();
                this.lockScreen.emit(false);
              }, error => {
                dlg.close();
                this.messageService.showToastError(error);
                console.error(error);
                this.lockScreen.emit(false);
              })
            });
          });
      });
  }

  public sortSelectedTasks() {

    let selectedNodes = this.gridSequence.getSelectedNodes();

    if (selectedNodes.length <= 1) {
      this.messageService.showToast("Selecione 2 ou mais tarefas", "Atenção", "warning");
      return;
    }

    //Não considerar os campos: INICIO, FIM, STATUS, DUR_ACERTO, DUR_PRODUCAO, TAREFA
    this.dialogService.open(SortTasksDialogComponent, {
      context: {
        fieldsList: this.gridSequence.getColumnDefs().map((f: any) => { return { fieldName: f.field, fieldLabel: f.headerName }; })
          .filter(f => !["startDate", "endDate", "started", "estimatedSetupDuration", "estimatedRunDuration", "task"].includes(f.fieldName))
      }
    }).onClose.pipe(take(1)).subscribe(async (fields: Array<string>) => {
      if (fields && fields.length > 0) {
        this.lockScreen.emit(true);

        selectedNodes.sort((a, b) => {
          let sort: number = 0;
          for (let i = 0; i < fields.length; i++) {
            let field = fields[i];

            if (field in a.data || field in b.data) {
              sort = a.data[field] < b.data[field] ? -1 : a.data[field] > b.data[field] ? 1 : 0;
            } else if (a.data.complementaryFields && a.data.complementaryFields.some(cf => cf.name == field)) {
              const aValue = a.data.complementaryFields.find(cf => cf.name == field).value,
                bValue = b.data.complementaryFields.find(cf => cf.name == field).value;

              sort = aValue < bValue ? -1 : aValue > bValue ? 1 : 0;
            }

            if (sort != 0) {
              break;
            }
          }

          return sort;
        });

        let elementsToMove = new Array<TaskPlan>();

        selectedNodes.forEach(rowNode => {
          let fromIndex = this.taskPlanList.findIndex(tp => tp.apsId == rowNode.data["apsId"]),
            element = this.taskPlanList[fromIndex];

          elementsToMove.push(element);

          this.taskPlanList.splice(fromIndex, 1);
        });

        let toIndex = Math.min(...selectedNodes.map(rn => rn.rowIndex));

        this.taskPlanList.splice(toIndex, 0, ...elementsToMove);

        await this.recalculate().finally(() => this.lockScreen.emit(false));
      }
    });
  }

  public filtersCleared() {
    this.inputFilter.formGroup.get("busca").setValue(undefined);
  }
}
