import { Injectable, Type } from '@angular/core';
import { DatasetTemplateComponent } from "../dataset/dataset-template.component";
import { T2AggregationComponent } from "./ui/t2-aggregation/t2-aggregation.component";
import { T2CheckBoxComponent } from "./ui/t2-check-box/t2-check-box.component";
import { T2DateTimeComponent } from "./ui/t2-date-time/t2-date-time.component";
import { T2DateComponent } from "./ui/t2-date/t2-date.component";
import { T2InputFloatComponent } from "./ui/t2-input-float/t2-input-float.component";
import { T2InputIntegerComponent } from "./ui/t2-input-integer/t2-input-integer.component";
import { T2InputTextComponent } from "./ui/t2-input-text/t2-input-text.component";
import { T2SelectComponent } from "./ui/t2-select/t2-select.component";
import { T2StatusComponent } from "./ui/t2-status/t2-status.component";
import { T2TextAreaComponent } from "./ui/t2-text-area/t2-text-area.component";
import { T2TimeComponent } from "./ui/t2-time/t2-time.component";

@Injectable({
  providedIn: 'root'
})
export class ComponentService {

  private componentMap: Map<string, Type<any>> = new Map<string, Type<any>>();

  constructor() {
    this.registerType("INT", T2InputIntegerComponent);
    this.registerType("MONEY", T2InputFloatComponent);
    this.registerType("DECIMAL", T2InputFloatComponent);
    this.registerType("STRING", T2InputTextComponent);
    this.registerType("MEMO", T2TextAreaComponent);
    this.registerType("DATE", T2DateComponent);
    this.registerType("DATE_TIME", T2DateTimeComponent);
    this.registerType("TIME", T2TimeComponent);
    this.registerType("BOOLEAN", T2CheckBoxComponent);
    this.registerType("GUID", T2AggregationComponent);
    this.registerType("FIXED_COMBO", T2SelectComponent);
    this.registerType("AUTO_INC_MASKED", T2InputTextComponent);
    this.registerType("STATUS", T2StatusComponent);

    this.registerType("Integer", T2InputIntegerComponent);
    this.registerType("Money", T2InputFloatComponent);
    this.registerType("Decimal", T2InputFloatComponent);
    this.registerType("Text", T2InputTextComponent);
    this.registerType("Memo", T2TextAreaComponent);
    this.registerType("Date", T2DateComponent);
    this.registerType("DateTime", T2DateTimeComponent);
    this.registerType("Time", T2TimeComponent);
    this.registerType("Boolean", T2CheckBoxComponent);
    this.registerType("Guid", T2AggregationComponent);
    this.registerType("FixedCombo", T2SelectComponent);
    this.registerType("AutoincMasked", T2InputTextComponent);
    this.registerType("Status", T2StatusComponent);

    this.registerType("DatasetTemplateComponent", DatasetTemplateComponent);
  }

  public registerType(name: string, type: Type<any>): void {
    this.componentMap.set(name, type);
  }

  public getType(name: string): Type<any> {
    return this.componentMap.get(name);
  }
}
