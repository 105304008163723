import { Component, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { DeviceCache, DeviceCacheTask } from "../../device-cache.service";
import { DeviceTaskPlan, JustifiableOpertaion, ShopFloorService } from "../../shop-floor.service";
import { DeviceOperation } from "../../model/device-operation";


@Component({
  selector: 'app-appointment-operations',
  templateUrl: './appointment-operations.component.html',
  styleUrls: ['./appointment-operations.component.scss']
})
export class AppointmentOperationsComponent {
  @Input()
  get device(): DeviceCache { return this._device };
  set device(value: DeviceCache) {
    this._device = value;
    this.loadOperations();
  };

  @Input()
  get taskPlan(): DeviceCacheTask {
    return this._taskPlan;
  }
  set taskPlan(value: DeviceCacheTask) {
    this._taskPlan = value;
    this.loadOperations();
  }

  @Input()
  get searchedTaskPlan(): DeviceTaskPlan {
    return this._searchedTaskPlan;
  }
  set searchedTaskPlan(value: DeviceTaskPlan) {
    this._searchedTaskPlan = value;
    this.loadOperations();
  }

  @Input()
  set justifyOperation(value: boolean) {
    if (value) this._justifyOperation = value;
  }

  get justifyOperation() {
    return this._justifyOperation;
  }

  @Output() operationSelected = new EventEmitter<DeviceOperation>();
  @Output() goBackToDocument = new EventEmitter<boolean>();

  private _device: DeviceCache;
  private _taskPlan: DeviceCacheTask;
  private _searchedTaskPlan: DeviceTaskPlan;
  private timer;
  private _justifyOperation: boolean = false;

  public smallWindow: boolean;
  loading = false;
  operationList: Array<DeviceOperation> = [];
  operationFilteredList: Array<DeviceOperation> = [];

  filterList: Array<{ description: string; filterGroup: string, count: number, selected: boolean, color?: string, icon?: string }> = [];
  utilList: Array<DeviceOperation> = [];

  constructor(private sfService: ShopFloorService) { }

  private loadOperations() {
    if (this.timer) {
      clearTimeout(this.timer);
    }

    this.timer = setTimeout(() => {
      this.loadDeviceOperations();
    }, 200);
  }

  public loadDeviceOperations() {
    return new Promise<void>((resolve, reject) => {
      this.operationList = [];
      this.operationFilteredList = [];
      this.filterList = [
        { description: "Produção", filterGroup: "RUN", count: 0, selected: true, icon: 'log-in' },
        { description: "Parada", filterGroup: "STOP", count: 0, selected: true, icon: 'pause-circle-outline' },
        { description: "Quantidade", filterGroup: "QUANTITY", count: 0, selected: true, icon: 'plus-circle-outline' },
        { description: "Informação", filterGroup: "INFODATA", count: 0, selected: true, icon: 'credit-card-outline' },
        { description: "Documento", filterGroup: "DOC", count: 0, selected: true, icon: 'file-text-outline' },
        { description: "Ação", filterGroup: "ACTION", count: 0, selected: true, icon: 'flash-outline' },
        { description: "?", filterGroup: "UNKNOWN", count: 0, selected: true },
      ];
      this.utilList = [];

      this.filterList.forEach(f => {
        f.color = this.sfService.getFilterOperationColor(f.filterGroup, f.selected);
      });

      if (this.device?.deviceId) {
        this.loading = true;
        this.sfService.getOperationGroup(this.device?.deviceId)
          .subscribe(operList => {
            if (operList?.length) {
              if (this.searchedTaskPlan?.status == "Finalizada") {
                operList = operList.filter(oper => !oper.hasDuration && oper.taskStatus != "FINISH" && oper.taskStatus != "SUSPEND" && !["AUTH_LOWPROD", "UNJUSTIFIED_DURATION"].includes(oper.specialAction));
              }
            }

            this.operationList = operList.filter(oper => oper.opType == "OPERATION") || [];

            if (this.taskPlan?.id_taskPlan) {
              this.operationList = this.operationList.filter(oper => oper.taskRequirement != "TASK_NOT_ALLOWED");
            } else {
              this.operationList = this.operationList.filter(oper => oper.taskRequirement != "TASK_REQUIRED");
            }

            this.operationFilteredList = [...this.operationList];

            this.utilList = operList.filter(oper => oper.opType != "OPERATION") || [];

            this.operationFilteredList.forEach((op) => {
              let filter = this.filterList.find((f) => f.filterGroup == op.filterGroup);
              if (filter) filter.count++;
              op.color = this.sfService.getOperationColor(op);
            });

            this.filterList = this.filterList.filter((f) => f.count > 0);

            if (this.justifyOperation)
              this.loadJustifiableOperations(operList);

            this.loading = false;

            resolve();
          }, error => {
            this.loading = false;
            reject(error);
          });
      } else {
        resolve();
      }
    })
  }

  clickFilter(filter: { description: string; filterGroup: string, count: number, selected: boolean }) {

    const filtered = this.filterList.filter((f) => f.selected).length <= 1;
    const filteredItem = this.filterList.find((f) => f.selected);

    this.filterList.forEach((f) => {
      if (filtered && filteredItem.filterGroup == filter.filterGroup) {
        f.selected = true;
      } else {
        f.selected = (f.filterGroup == filter.filterGroup);
      }

      f.color = this.sfService.getFilterOperationColor(f.filterGroup, f.selected);
    });

    if (this.filterList.filter((f) => f.selected).length <= 1) {
      this.operationFilteredList = this.operationList.filter(f => f.filterGroup == filter.filterGroup);
    } else {
      this.operationFilteredList = [...this.operationList]
    }

  }

  operationClicked(oper: DeviceOperation) {
    this.operationSelected.emit(oper);
  }

  loadJustifiableOperations(operList: Array<DeviceOperation>) {
    const taskProdType = this.taskPlan?.id_ordemProd ? 'STOPPED' : '';
    const taskAllowed = this.taskPlan?.id_taskPlan ? "TASK_NOT_ALLOWED" : "TASK_REQUIRED";
    const justOper = operList.filter(op => op.taskProdType == taskProdType && op.taskRequirement != taskAllowed && op.hasDuration);
    this.operationFilteredList = justOper;
  }

  backToDocuments() {
    this.goBackToDocument.emit(true);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event): void {
    if (window.innerWidth <= 450) this.smallWindow = true;
    if (window.innerWidth > 450) this.smallWindow = false;
  }

  ngAfterContentInit(): void {
    if (window.innerWidth <= 450) this.smallWindow = true;
    if (window.innerWidth > 450) this.smallWindow = false;
  }

}
