import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from "ag-grid-angular";
import { ICellRendererParams } from "ag-grid-community";
import { Warning } from "../model/warning";

@Component({
  selector: 'app-warning-renderer',
  templateUrl: './warning-renderer.component.html',
  styleUrls: ['./warning-renderer.component.scss']
})
export class WarningRendererComponent implements ICellRendererAngularComp {

  onTime: number = 0;
  today: number = 0;
  late: number = 0;
  finished: number = 0;

  constructor() { }

  refresh(params: ICellRendererParams): boolean {
    return true;
  }

  agInit(params: ICellRendererParams): void {
    if (params.data.warningList) {
      let warningList: Array<Warning> = params.data.warningList;
      let todayDate = new Date();
      todayDate.setHours(0, 0, 0, 0);

      warningList.forEach(warning => {
        if (warning.completionDate) {
          this.finished++;
        } else if (warning.maxDate?.getTime() < todayDate.getTime()) {
          this.late++;
        } else if (warning.maxDate?.getTime() == todayDate.getTime()) {
          this.today++;
        } else {
          this.onTime++;
        }
      })
    }
  }



}
