<div style="min-height: 70vh; min-width: 70vw; height: 100%; width: 100%;" [nbSpinner]="lockScreen || !loaded"
  nbSpinnerSize="giant" nbSpinnerStatus="primary">
  <app-dialog-template #dialog title="Parâmetro de Medição">
    <content class="flexColumn" style="gap: 5px">
      <div style="display: flex; gap: 5px; ">
        <app-t2-button size="tiny" status="primary" [disabled]="!canSave || FormGroup.pristine" value="Salvar" (clicked)="save()"></app-t2-button>
        <app-t2-button size="tiny" status="basic" value="Fechar" (clicked)="close()"></app-t2-button>
        <app-t2-button *ngIf="!insert" size="tiny" status="danger" [disabled]="!canDelete" value="Excluir" (clicked)="delete()"></app-t2-button>
      </div>
      <app-view-template [layout]="layout" [FormGroup]="FormGroup"></app-view-template>
    </content>
  </app-dialog-template>

</div>
