import { DpStatusComponent } from './design-pattern/dp-status/dp-status.component';
import { T2DsAttachmentsComponent } from './form/t2-ds-attachments/t2-ds-attachments.component';
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { DragDropModule } from '@angular/cdk/drag-drop';
import { LoginComponent } from "./login/login.component";
import { HomeComponent } from "./home/home.component";
import { ConfigComponent } from "./config/config.component";
import { T2NebularModule } from "../t2nebular.module";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MenuComponent } from "./menu/menu.component";
import { NbDialogModule } from "@nebular/theme";
import { UserInfoComponent } from "./user-info/user-info.component";
import { DatasetComponent } from "./dataset/dataset.component";
import { T2gridComponent } from "./cmp/t2grid/t2grid.component";
import { AgGridModule } from "ag-grid-angular";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { FilterStatusComponent } from "./dataset/filterFields/filter-status.component";
import { FilterTextComponent } from "./dataset/filterFields/filter-text.component";
import { FilterNumberComponent } from "./dataset/filterFields/filter-number.component";
import { FormTemplateComponent } from "./cmp/form-template/form-template.component";
import { AboutComponent } from "./about/about.component";
import { DatasetTemplateComponent } from "./dataset/dataset-template.component";
import { DatasetBasicComponent } from "./dataset/dataset-basic.component";
import { HelpComponent } from "./help/help.component";
import { ContextMenuComponent } from "./cmp/context-menu/context-menu.component";
import { DialogTemplateComponent } from "./cmp/dialog-template/dialog-template.component";
import { DateTimeFormatPipe } from "./date-time-format.pipe";
import { DialogComponent } from "./cmp/dialog/dialog.component";
import { WidgetPanelComponent } from "./widget/widget-panel.component";
import { WidgetDateRangeComponent } from "./widget/widget-date-range/widget-date-range.component";
import { WidgetTextComponent } from "./widget/widget-text/widget-text.component";
import { WidgetChartComponent } from "./widget/widget-chart/widget-chart.component";
import { WidgetDatatableComponent } from "./widget/widget-datatable/widget-datatable.component";
import { WidgetSelectionComponent } from "./widget/widget-selection/widget-selection.component";
import { NgxEchartsModule } from "ngx-echarts";
import { ResponsiveLayoutComponent } from "./tests/responsive-layout/responsive-layout.component";
import { Tela1Component } from './tests/tela1/tela1.component';
import { T2LayoutComponent } from "./cmp/t2-layout/t2-layout.component";
import { FlexLayoutModule } from "@angular/flex-layout";
import { NumericEditorComponent } from "./cmp/t2grid/cellEditor/numeric-editor.component";
import { GroupAccessControlComponent } from "./security/access-control/group-access-control.component";
import { DatasetActionAccessRendererComponent } from "./security/access-control/dataset-action-access-renderer/dataset-action-access-renderer.component";
import { SelectEditorComponent } from "./cmp/t2grid/cellEditor/select-editor/select-editor.component";
import { CheckBoxRendererComponent } from "./cmp/t2grid/cellRenderer/check-box-renderer/check-box-renderer.component";
import { ViewTemplateComponent } from './cmp/view-template/view-template.component';
import { ViewLayoutComponent } from './cmp/view-template/view-layout/view-layout.component';
import { ViewComponentComponent } from './cmp/view-template/view-component/view-component.component';
import { AutoformComponent } from './tests/autoform/autoform.component';
import { NgxMaskModule } from "ngx-mask";
import { T2AutoFormComponent } from './form/t2-auto-form/t2-auto-form.component';
import { StatusRendererComponent } from './cmp/t2grid/cellRenderer/status-renderer/status-renderer.component';
import { AggregationEditorComponent } from './cmp/t2grid/cellEditor/aggregation-editor/aggregation-editor.component';
import { DateTimeEditorComponent } from './cmp/t2grid/cellEditor/date-time-editor/date-time-editor.component';
import { LicenseComponent } from './about/license/license.component';
import { SummaryInfoComponent } from './cmp/summary-info/summary-info.component';
import { AggregationRendererComponent } from './cmp/t2grid/cellRenderer/aggregation-renderer/aggregation-renderer.component';
import { StatusEditorComponent } from './cmp/t2grid/cellEditor/status-editor/status-editor.component';
import { DialogInputComponent } from './cmp/dialog-input/dialog-input.component';
import { IconRendererComponent } from './cmp/t2grid/cellRenderer/icon-renderer/icon-renderer.component';
import { DesignPatternComponent } from './design-pattern/design-pattern.component';
import { T2CmpDirective } from "./cmp/ui/t2-cmp.directive";
import { T2InputTextComponent } from "./cmp/ui/t2-input-text/t2-input-text.component";
import { T2ButtonComponent } from "./cmp/ui/t2-button/t2-button.component";
import { T2TextAreaComponent } from "./cmp/ui/t2-text-area/t2-text-area.component";
import { T2InputIntegerComponent } from "./cmp/ui/t2-input-integer/t2-input-integer.component";
import { T2InputFloatComponent } from "./cmp/ui/t2-input-float/t2-input-float.component";
import { T2DateComponent } from "./cmp/ui/t2-date/t2-date.component";
import { T2TimeComponent } from "./cmp/ui/t2-time/t2-time.component";
import { T2DateTimeComponent } from "./cmp/ui/t2-date-time/t2-date-time.component";
import { T2CheckBoxComponent } from "./cmp/ui/t2-check-box/t2-check-box.component";
import { T2AggregationComponent } from "./cmp/ui/t2-aggregation/t2-aggregation.component";
import { T2SelectComponent } from "./cmp/ui/t2-select/t2-select.component";
import { T2StatusComponent } from "./cmp/ui/t2-status/t2-status.component";
import { T2MenuButtonComponent } from './cmp/ui/t2-menu-button/t2-menu-button.component';
import { T2ChartComponent } from './cmp/ui/t2-chart/t2-chart.component';
import { GreetingsComponent } from './home/greetings/greetings.component';
import { FavoritesComponent } from './home/favorites/favorites.component';
import { NewsComponent } from './home/news/news.component';
import { T2GanttComponent } from './cmp/t2-gantt/t2-gantt.component';
import { DictionaryComponent } from './dev/dictionary/dictionary/dictionary.component';
import { ServerInfoComponent } from './about/serverInfo/server-info/server-info.component';
import { T2ActionComponent } from './cmp/ui/t2-action/t2-action.component';
import { DialogItemSelectionComponent } from './cmp/dialog-item-selection/dialog-item-selection.component';
import { T2AnalysisComponent } from './cmp/t2-analysis/t2-analysis.component';
import { AnalysisComponent } from './analysis/analysis.component';
import { AnalysisParamsComponent } from './cmp/t2-analysis/analysis-params/analysis-params.component';
import { ComponentService } from "./cmp/component.service";
import { ViewTemplateBuilderComponent } from './cmp/view-template/view-template-builder/view-template-builder.component';
import { ViewTemplateService } from "./cmp/view-template/view-template.service";
import { T2ViewTemplateFunctionsService } from "./cmp/view-template/t2-view-template-functions.service";
import { T2LogComponent } from './cmp/t2-log/t2-log.component';
import { DialogLogDataComponent } from './cmp/dialog-log-data/dialog-log-data.component';
import { LogComponent } from './log/log.component';
import { AnalysisEditComponent } from './cmp/t2-analysis/analysis-edit/analysis-edit.component';
import { DialogDuplicateNFComponent } from './cmp/dialog-duplicate-nf/dialog-duplicate-nf.component';
import { DialogEventNFComponent } from './cmp/dialog-event-nf/dialog-event-nf.component';
import { T2FlowComponent } from './cmp/t2-flow/t2-flow.component';
import { DictDatasetComponent } from "./dev/dictionary/dict-dataset/dict-dataset.component";
import { ActionDialogComponent } from './action/action-dialog/action-dialog.component';
import { AwsIpListComponent } from './utils/aws-ip-list/aws-ip-list/aws-ip-list.component';
import { WarningComponent } from './warning/warning/warning.component';
import { WarningRendererComponent } from './warning/warning-renderer/warning-renderer.component';
import { ClosingProductionOrderComponent } from "../bsn/prd/analysis/closing-production-order/closing-production-order.component";
import { T2TreeGridComponent } from './cmp/t2-tree-grid/t2-tree-grid.component';
import { T2TreeGridInputComponent } from './cmp/ui/t2-tree-grid-input/t2-tree-grid-input.component';
import { T2DatasetTreeGridComponent } from './cmp/t2-dataset-tree-grid/t2-dataset-tree-grid.component';
import { T2ImageDrawComponent } from './cmp/ui/t2-image-draw/t2-image-draw.component';
import { VisualizationComponent } from './dataset/visualization/visualization.component';
import { QueryComponent } from './dataset/query/query.component';
import { QueryConditionComponent } from './dataset/query/query-condition/query-condition.component';
import { T2IconComponent } from './cmp/ui/t2-icon/t2-icon.component';
import { T2ScannerComponent } from './cmp/t2-scanner/t2-scanner.component';
import { BrowserModule } from "@angular/platform-browser";
import { BarcodeScannerLivestreamModule, BarcodeScannerLivestreamOverlayModule } from "ngx-barcode-scanner";
import { VisualizationDialogComponent } from "./dataset/visualization/visualization-dialog.component";
import { T2EvolutionIndexComponent } from './cmp/index/t2-evolution-index/t2-evolution-index.component';
import { T2PizzaIndexComponent } from './cmp/index/t2-pizza-index/t2-pizza-index.component';
import { T2ProgressIndexComponent } from './cmp/index/t2-progress-index/t2-progress-index.component';

import { PowerBIEmbedModule } from 'powerbi-client-angular';
import { T2PowerbiComponent } from './cmp/ui/t2-powerbi/t2-powerbi.component';
import { T2InactivityComponent } from './cmp/ui/t2-inactivity/t2-inactivity.component';
import { QueryConditionFieldComponent } from './dataset/query/query-condition-field/query-condition-field.component';
import { QuickSearchComponent } from './dataset/query/quick-search/quick-search.component';
import { FilterWarningPipe } from "./warning/filter-warning.pipe";
import { MinutesToHoursPipe } from "./pipes/minutes-to-hours.pipe";
import { NotificationComponent } from './notification/notification.component';
import { DatasetControlsComponent } from './dev/dictionary/dataset-controls/dataset-controls.component';
import { NotificationDetailComponent } from './notification/notification-detail/notification-detail.component';
import { T2FormListComponent } from './form/t2-form-list/t2-form-list.component';
import { T2GoogleOAuthComponent } from './cmp/oauth/t2-google-oauth/t2-google-oauth.component';
import { T2OAuthAutorizedComponent } from './cmp/oauth/t2-oauth-autorized/t2-oauth-autorized.component';

@NgModule({
  declarations: [
    LoginComponent,
    HomeComponent,
    ConfigComponent,
    MenuComponent,
    UserInfoComponent,
    DatasetComponent,
    T2gridComponent,
    T2LayoutComponent,
    DatasetComponent,
    FilterStatusComponent,
    FilterTextComponent,
    FilterNumberComponent,
    FormTemplateComponent,
    AboutComponent,
    DatasetTemplateComponent,
    DatasetBasicComponent,
    HelpComponent,
    ContextMenuComponent,
    DialogTemplateComponent,
    DateTimeFormatPipe,
    DialogComponent,
    WidgetPanelComponent,
    WidgetDateRangeComponent,
    WidgetTextComponent,
    WidgetChartComponent,
    WidgetDatatableComponent,
    WidgetSelectionComponent,
    ResponsiveLayoutComponent,
    Tela1Component,
    NumericEditorComponent,
    GroupAccessControlComponent,
    DatasetActionAccessRendererComponent,
    SelectEditorComponent,
    CheckBoxRendererComponent,
    ViewTemplateComponent,
    ViewLayoutComponent,
    ViewComponentComponent,
    AutoformComponent,
    T2CmpDirective,
    T2InputTextComponent,
    T2ButtonComponent,
    T2TextAreaComponent,
    T2InputIntegerComponent,
    T2InputFloatComponent,
    T2DateComponent,
    T2TimeComponent,
    T2DateTimeComponent,
    T2CheckBoxComponent,
    T2AutoFormComponent,
    T2AggregationComponent,
    T2SelectComponent,
    T2StatusComponent,
    StatusRendererComponent,
    AggregationEditorComponent,
    DateTimeEditorComponent,
    LicenseComponent,
    SummaryInfoComponent,
    AggregationRendererComponent,
    StatusEditorComponent,
    DialogInputComponent,
    IconRendererComponent,
    DesignPatternComponent,
    T2MenuButtonComponent,
    T2ChartComponent,
    GreetingsComponent,
    FavoritesComponent,
    NewsComponent,
    T2GanttComponent,
    DictionaryComponent,
    ServerInfoComponent,
    T2ActionComponent,
    DialogItemSelectionComponent,
    T2AnalysisComponent,
    AnalysisComponent,
    AnalysisParamsComponent,
    ViewTemplateBuilderComponent,
    T2LogComponent,
    DialogLogDataComponent,
    LogComponent,
    AnalysisEditComponent,
    DictDatasetComponent,
    DialogDuplicateNFComponent,
    DialogEventNFComponent,
    T2FlowComponent,
    T2DsAttachmentsComponent,
    ActionDialogComponent,
    DpStatusComponent,
    AwsIpListComponent,
    WarningComponent,
    WarningRendererComponent,
    T2TreeGridComponent,
    T2TreeGridInputComponent,
    T2DatasetTreeGridComponent,
    T2ImageDrawComponent,
    VisualizationComponent,
    QueryComponent,
    QueryConditionComponent,
    T2IconComponent,
    T2ScannerComponent,
    VisualizationDialogComponent,
    T2EvolutionIndexComponent,
    T2PizzaIndexComponent,
    T2ProgressIndexComponent,
    T2PowerbiComponent,
    T2InactivityComponent,
    QueryConditionFieldComponent,
    QuickSearchComponent,
    FilterWarningPipe,
    NotificationComponent,
    DatasetControlsComponent,
    MinutesToHoursPipe,
    NotificationDetailComponent,
    T2FormListComponent,
    T2GoogleOAuthComponent,
    T2OAuthAutorizedComponent
  ],
  imports: [
    CommonModule,

    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    DragDropModule,

    AgGridModule,
    NbDialogModule.forChild({}),

    T2NebularModule,
    FlexLayoutModule,

    NgxEchartsModule.forChild(),
    NgxMaskModule.forChild(),
    BrowserModule,
    BarcodeScannerLivestreamModule,

    PowerBIEmbedModule
  ],
  exports: [
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    DragDropModule,

    DatasetTemplateComponent,
    FormTemplateComponent,
    ContextMenuComponent,
    T2gridComponent,
    NumericEditorComponent,
    T2LayoutComponent,
    DialogTemplateComponent,
    DialogComponent,
    WidgetSelectionComponent,
    WidgetPanelComponent,
    T2InputTextComponent,
    T2ButtonComponent,
    T2TextAreaComponent,
    T2InputIntegerComponent,
    T2InputFloatComponent,
    T2DateComponent,
    T2TimeComponent,
    T2DateTimeComponent,
    T2CheckBoxComponent,
    T2AutoFormComponent,
    T2AggregationComponent,
    T2SelectComponent,
    T2StatusComponent,
    ViewTemplateComponent,
    T2ChartComponent,
    T2MenuButtonComponent,
    SummaryInfoComponent,
    T2GanttComponent,
    AnalysisComponent,
    T2DsAttachmentsComponent,
    ViewTemplateBuilderComponent,
    T2TreeGridComponent,
    T2TreeGridInputComponent,
    T2ImageDrawComponent,
    T2IconComponent,
    T2EvolutionIndexComponent,
    T2PizzaIndexComponent,
    T2ProgressIndexComponent,
    MinutesToHoursPipe,
    NotificationComponent,
    T2OAuthAutorizedComponent
  ],
  providers: [ViewTemplateService, T2ViewTemplateFunctionsService]
})
export class T2CoreModule {
  constructor(private cmpService: ComponentService) {
    cmpService.registerType("T2gridComponent", T2gridComponent);
    cmpService.registerType("T2ActionComponent", T2ActionComponent);
    cmpService.registerType("T2AggregationComponent", T2AggregationComponent);
    cmpService.registerType("T2ButtonComponent", T2ButtonComponent);
    cmpService.registerType("T2ChartComponent", T2ChartComponent);
    cmpService.registerType("T2CheckBoxComponent", T2CheckBoxComponent);
    cmpService.registerType("T2DateComponent", T2DateComponent);
    cmpService.registerType("T2DateTimeComponent", T2DateTimeComponent);
    cmpService.registerType("T2InputFloatComponent", T2InputFloatComponent);
    cmpService.registerType("T2InputIntegerComponent", T2InputIntegerComponent);
    cmpService.registerType("T2InputTextComponent", T2InputTextComponent);
    cmpService.registerType("T2MenuButtonComponent", T2MenuButtonComponent);
    cmpService.registerType("T2SelectComponent", T2SelectComponent);
    cmpService.registerType("T2StatusComponent", T2StatusComponent);
    cmpService.registerType("T2TextAreaComponent", T2TextAreaComponent);
    cmpService.registerType("T2TimeComponent", T2TimeComponent);
    cmpService.registerType("ViewTemplateBuilderComponent", ViewTemplateBuilderComponent);
    cmpService.registerType("DialogDuplicateNFComponent", DialogDuplicateNFComponent);
    cmpService.registerType("DialogEventNFComponent", DialogEventNFComponent);
    cmpService.registerType("ClosingProductionOrderComponent", ClosingProductionOrderComponent);
    cmpService.registerType("T2TreeGridComponent", T2TreeGridComponent);
    cmpService.registerType("T2TreeGridInputComponent", T2TreeGridInputComponent);
    cmpService.registerType("T2ImageDrawComponent", T2ImageDrawComponent);
    cmpService.registerType("T2IconComponent", T2IconComponent);
    cmpService.registerType("T2PowerbiComponent", T2PowerbiComponent);
    cmpService.registerType("T2GoogleOAuthComponent", T2GoogleOAuthComponent);
  }
}
