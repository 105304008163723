import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { T2BaseComponent } from "../t2-base-component";
import { T2UserDeviceService } from "src/app/t2-user-device.service";
import { NbDialogService } from "@nebular/theme";
import { T2ScannerComponent } from "../../t2-scanner/t2-scanner.component";
import { take } from "rxjs/Operators";

@Component({
  selector: 'app-t2-input-text',
  templateUrl: './t2-input-text.component.html',
  styleUrls: ['./t2-input-text.component.scss']
})
export class T2InputTextComponent extends T2BaseComponent implements OnInit {

  @Input() maxLength: number;
  @Input() enableScanner: boolean = false;
  @Output() keyDownEnter = new EventEmitter<void>();

  @ViewChild("inputText") inputText: ElementRef;

  public showIconScanner: boolean = false;

  constructor(private userDevService: T2UserDeviceService, private dialogService: NbDialogService) {
    super();
  }

  ngOnInit(): void {
    this.setDefaultValues();

    if (this.enableScanner) {
      this.userDevService.hasCamera().then(hasCamera => {
        this.showIconScanner = hasCamera;
      })
    }
  }

  openCamera() {
    let dlg = this.dialogService.open(T2ScannerComponent);

    dlg.componentRef.instance.scanSuccess.pipe(take(1)).subscribe(result => {
      this.formGroup.controls[this.t2IdCmp].setValue(result);
      dlg.close();
    })
  }

  focus(): void {
    this.inputText?.nativeElement.focus()
  }
}
