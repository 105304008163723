export class RegistrationData {
  id_registrationType_property: string;
  dataID: string;
  dataValue: string;
  id_paramMedicaoProducao: string;
  justification: string;
  id_especif_carac_inspeProd_it: string;
  id_inspecaoProducao: string;
  id_inspecaoProducao_item: string;
  id_esp_carac_inspeProd_it_ref: string;
  id_especif_carac: string;
  id_especif_carac_paramMPrd_it: string;
  id_item: string;
}
