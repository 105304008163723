import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { DialogTemplateComponent } from "../dialog-template/dialog-template.component";

@Component({
  selector: 'app-dialog-item-selection',
  templateUrl: './dialog-item-selection.component.html',
  styleUrls: ['./dialog-item-selection.component.scss']
})
export class DialogItemSelectionComponent implements OnInit {

  public title: string;
  public groups: Array<GroupSelection> = [];
  public groupSelection: string = 'multiple'; // none, single, multiple
  public itemSelection: string = 'multiple'; // none, single, multiple
  public itemFromDifGroups: boolean = true;
  public groupSelected: { groups: Array<string>, items: Array<string>, context?: object, itemsContext: Array<ItemSelection>};
  public useConfirmButton?: boolean = true;
  public context: object;

  @Input() buttonSize:'default' | 'large' = 'default';
  @Input() buttonCase: 'upper' | 'normal' = 'upper';
  @ViewChild('dialog', { static: true }) dialog: DialogTemplateComponent;

  constructor() { }

  ngOnInit(): void { }

  closeDialog(action) {
    if (action == "Confirma") {
      const groups: Array<string> = [];
      const items: Array<string> = [];
      const itemsContext: Array<ItemSelection> = [];

      this.groups.forEach(g => {
        if (g.selected) groups.push(g.id_group);

        g.items.forEach(i => {
          if (i.selected || g.selected) { items.push(i.id_item); itemsContext.push(i)};
        });
      });
      this.groupSelected = { groups: groups, items: items, itemsContext, context: this.context };
    }

    this.dialog.close(this.groupSelected);
  }

  groupClick(group: GroupSelection): void {

    if (this.groupSelection == 'none') return;

    group.selected = !group.selected;

    if (this.groupSelection == 'single') {
      this.groups.forEach(g => {
        if (group.id_group != g.id_group) {
          g.selected = false;
        }
      });
    }

    if (!group.selected) {
      group.items.forEach(item => item.selected = false);
    } else {
      group.items.forEach(item => item.selected = true);
    }
  }

  itemClick(item: ItemSelection, group: GroupSelection): void {

    if (this.itemSelection == 'none') return;

    if(!this.useConfirmButton) {
      item.selected = true;
      group.selected = false;
      this.closeDialog('Confirma');
      return;
    }

    item.selected = !item.selected;

    if (this.itemSelection == 'single') {
      this.groups.forEach(g => {
        g.items.forEach(i => {
          if (i.id_item != item.id_item) i.selected = false;
        });
      });
    }

    if (this.itemSelection == 'multiple' && !this.itemFromDifGroups) {
      this.groups.forEach(g => {
        g.items.forEach(i => {
          if (g.id_group != group.id_group) { i.selected = false; g.selected = false }
        });
      });
    }

    if (!item.selected) {
      group.selected = false;
    } else if (this.groupSelection != "none") {
      if (!group.items.find(i => !i.selected)) {
        group.selected = true;
      }
    }
  }
}

export class GroupSelection {
  id_group: string;
  groupName: string;
  selected?: boolean = false;
  items: Array<ItemSelection>
}

export class ItemSelection {
  id_item: string;
  itemName: string;
  selected?: boolean = false;

  context?: object;
}
