export class ProductSpecification {
  psCode: string;
  id_specification: string;
  id_specif_component;
  id_structure: string;
  description: string;
  descriptionItem: string;
  status: string;
  specificationType: string;
  code: string;
  datasetName?: string;
  datasetId?: string;
}
