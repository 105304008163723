import { Component, OnInit } from '@angular/core';
import { ApsService } from "../../aps.service";

@Component({
  selector: 'app-aps-analysis',
  templateUrl: './aps-analysis.component.html',
  styleUrls: ['./aps-analysis.component.scss']
})
export class ApsAnalysisComponent implements OnInit {

  constructor(public apsService: ApsService) { }

  ngOnInit(): void {

  }

}
