import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NbMenuItem, NbMenuService } from "@nebular/theme";
import { Subject } from "rxjs";
import { take, takeUntil } from "rxjs/Operators";
import { ActionService } from "src/app/core/action/action.service";
import { T2AccessItem, T2AccessItemDatasetActionType } from "src/app/core/security/model/t2accessItem";
import { T2MessageService } from "src/app/core/t2-message.service";

@Component({
  selector: 'app-t2-action',
  templateUrl: './t2-action.component.html',
  styleUrls: ['./t2-action.component.scss']
})
export class T2ActionComponent implements OnInit, OnDestroy {

  @Input() informationId: string;
  @Input("actionList")
  get actionList(): Array<T2AccessItem> {
    return this._actionList;
  }
  set actionList(value: Array<T2AccessItem>) {
    this._actionList = value;

    if (this._actionList && this._actionList) {
      this.items = [];
      this._actionList.forEach(ac => {
        if (
          [T2AccessItemDatasetActionType.DSACTION_OTHER,
          T2AccessItemDatasetActionType.DSACTION_OTHERCHANGE,
          T2AccessItemDatasetActionType.DSACTION_OTHERCLOSE
          ].includes(ac.datasetActionType)
        ) {
          this.items.push({
            title: ac.datasetActionDescription,
            data: ac.id
          });
        }
      })
    }
  }

  private _actionList: Array<T2AccessItem>;
  private unsubscribe = new Subject<void>();
  public items = new Array<NbMenuItem>();

  constructor(private nbMenuService: NbMenuService, private actionService: ActionService, private messageService: T2MessageService) {  
  }

  ngOnInit(): void {
    this.nbMenuService.onItemClick()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(item => {
        let action = this.actionList.find(ac => ac.id == item.item.data);
        let params = new Map<string, string>();

        params.set("id", this.informationId);
        let obs = this.actionService.executeAction(action, params);

        if (obs) {
          obs.pipe(take(1)).subscribe(resp => {
            this.messageService.showToast("Ação executada com sucesso", item.item.title, "success");
          }, error => {
            this.messageService.showToastError(error);
            console.error(error);
          })
        }
      })
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
