import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { WidgetService } from "../widget.service";
import { T2Widget } from "../model/t2widget";

@Component({
  selector: 'app-widget-text',
  templateUrl: './widget-text.component.html',
  styleUrls: ['./widget-text.component.scss']
})
export class WidgetTextComponent implements OnInit {

  widgetText = "";

  private _widget: T2Widget;
  private _params: {};

  @Input()
  get widget() { return this._widget; }
  set widget(value: T2Widget) {
    this._widget = value;
    this.updateContent();
  }

  @Input()
  get params() { return this._params; }
  set params(value: any) {
    const newParams = this.widgetService.mergeInputParams(this._widget, this._params, value);
    if (newParams.updated) {
      this._params = newParams.params;
      this.updateContent();
    }
  }

  @Output() widgetChange = new EventEmitter();

  constructor(private widgetService: WidgetService) { }

  ngOnInit(): void { }

  private updateContent() {
    if (this._widget && this._widget.id_techprodWidget) {
      this.widgetService.getWidgetContent(this._widget, this._params)
        .subscribe((content: string) => this.widgetText = content);
    }
  }

}
