import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewContainerRef } from '@angular/core';
import { NbDialogService } from "@nebular/theme";
import { Subject } from "rxjs";
import { take, takeUntil } from "rxjs/Operators";
import { T2MessageService } from "src/app/core/t2-message.service";
import { ApsService } from "../aps.service";
import { EnvironmentSelectionComponent } from "../visualization/environment-selection/environment-selection.component";

@Component({
  selector: 'app-tool-bar',
  templateUrl: './tool-bar.component.html',
  styleUrls: ['./tool-bar.component.scss']
})
export class ToolBarComponent implements OnInit, OnDestroy {

  @Output() fixedDateClick = new EventEmitter<boolean>();
  @Output() recalculateClick = new EventEmitter<any>();
  @Output() timeReservationClick = new EventEmitter<any>();
  @Output() swapDeviceClick = new EventEmitter<any>();
  @Output() sortSelctedTasksClick = new EventEmitter<any>();
  @Output() lockScreenClick = new EventEmitter<boolean>();

  public selectedSimuDescription: string;
  private selectedSimuId: string;
  private unsubscribe = new Subject<void>();

  constructor(private messageService: T2MessageService,
    public apsService: ApsService, private dialogService: NbDialogService, private viewContainerRef: ViewContainerRef) { }

  ngOnInit(): void {
    this.apsService.getSimulationSelected()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(s => {
        this.selectedSimuDescription = s?.description ?? "Ambiente";
        this.selectedSimuId = s?.id;
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  public viewClick(view: string) {
    this.apsService.showView = view;
  }

  public setFixedDate(status: boolean) {
    this.fixedDateClick.emit(status);
  }

  public recalculate() {
    this.recalculateClick.emit();
  }

  public timeReservation() {
    this.timeReservationClick.emit();
  }

  public swapDevice() {
    this.swapDeviceClick.emit();
  }

  public sortSelctedTasks() {
    this.sortSelctedTasksClick.emit();
  }

  public lockScreen(event) {
    this.lockScreenClick.emit(event);
  }

  public selectSimulation() {
    this.apsService.getSimulationList()
    .pipe(take(1))
    .subscribe(simuList => {
      let dlg = this.dialogService.open(EnvironmentSelectionComponent, {
        context: {
          simulationSelected: this.selectedSimuId,
          simulationList: simuList.map(s => {
            return {
              id: s.id,
              description: s.description,
              realEnv: s.real
            }
          })
        },
        autoFocus: true, closeOnEsc: true, hasBackdrop: true,
        viewContainerRef: this.viewContainerRef
      });

      dlg.onClose
        .pipe(take(1))
        .subscribe(id => {
          if (id && id != this.selectedSimuId) {
            this.apsService.setSimulationSelected(id);
          }
        });
    });
  }
}
