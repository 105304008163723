import { Injectable } from '@angular/core';
import { T2HttpClientService } from "../../http/t2httpClient.service";
import { take, map } from "rxjs/Operators";
import { Observable, Subject } from "rxjs";
import { T2DatasetService } from "../t2dataset.service";
import { DatasetPropertyFixedComboItem } from "../model/dataset-property-fixed-combo-item";

@Injectable({
  providedIn: 'root'
})
export class T2VisualizationService {

  private FIELD_SEPARATOR: string = " / ";

  constructor(private httpClient: T2HttpClientService, public dsService: T2DatasetService) { }

  loadFieldSublist(id_dataset: string, selectableFieldList: SelectableField[], fieldFullName: string) {

    const fieldParts = fieldFullName.split(".");
    let selFields = selectableFieldList.map(f => f);

    let parts = null;
    for (const index in fieldParts) {
      const fp = fieldParts[index];

      parts = (parts ? parts + "." : "") + fp;
      selFields = selFields.filter(sf => {
        const name = (sf.joinName ? sf.joinName + "." : "") + sf.propertyName;
        return name.startsWith(parts);
      });

      if (selFields.length == 1) {
        this.loadFieldList({ id_dataset: selFields[0].id_dataset_aggregation, id_dataset_previous: id_dataset }).subscribe(resp => {
          selFields[0].fieldList = resp.fieldList;

          if ((+index + 1) < fieldParts.length) {
            this.loadFieldSublist(selFields[0].id_dataset_aggregation, selFields[0].fieldList, fieldParts.slice(+index + 1 - fieldParts.length).join("."));
          }
        });
        break;
      }
    }
  }

  loadFieldList({ id_dataset, id_dataset_previous, description, datasetList, field, validTypes, loadChildrenFields }:
    {
      id_dataset: string;
      id_dataset_previous?: string;
      description?: string;
      datasetList?: Array<SelectableDataset>;
      field?: SelectableField;
      validTypes?: PropertyType[];
      loadChildrenFields?: boolean;
    }): Observable<{ fieldList: SelectableField[] }> {

    const params = new Map<string, string>();
    if (loadChildrenFields) params.set("loadChildrenFields", String(loadChildrenFields));


    const respSubj$ = new Subject<{ fieldList: SelectableField[] }>();

    this.httpClient.get(`core.presentation/datasetFullProperties/${id_dataset}`, params)
      .pipe(take(1))
      .subscribe(resp => {

        if (resp && resp.fieldList) {
          if (!Array.isArray(resp.fieldList))
            resp.fieldList = [resp.fieldList];

          if (validTypes && validTypes.length)
            resp.fieldList = resp.fieldList.filter(f => validTypes.includes(f.propertyType));

          let joinName = "";

          if (field) {
            joinName = this.joinName(field);
            field.fieldList = resp.fieldList;
            field.fieldList.forEach(f => f.join = joinName);
          }

          if (datasetList) {
            const fieldDescription = datasetList.filter(d => d.id_dataset != id_dataset_previous).map(d => d.description);
            if (id_dataset != id_dataset_previous) fieldDescription.push(description);
            resp.fieldList.forEach(f => {
              let descr = fieldDescription.join(this.FIELD_SEPARATOR);
              descr = (descr ? descr + this.FIELD_SEPARATOR : "") + (f.propertyLabel || f.propertyName)
              f.description = descr;

              f.level = datasetList.length + 1;
            });
            datasetList.push({ id_dataset, description, join: joinName, fieldList: resp.fieldList });
          }
        }

        respSubj$.next({ fieldList: resp.fieldList });
        respSubj$.complete();
      });

    return respSubj$.asObservable();
  }

  joinName(field: SelectableField): string {
    let name = [];
    if (field.join) name.push(field.join);
    if (field.joinName) name.push(field.joinName);
    name.push(field.propertyName);

    return name.join(".");
  }

  validOperators(propertyType: PropertyType): OperatorType[] {
    if (['STRING', 'AUTO_INC_MASKED', 'EMAIL'].includes(propertyType)) {
      return ["EQUALS", "CONTAINS", "CONTAINS_ALL", "CONTAINS_ONE", "STARTS_WITH", "ENDS_WITH", "EMPTY"];
    } else if (['INT', 'MONEY', 'DECIMAL', 'DURATION'].includes(propertyType)) {
      return ["EQUALS", "BETWEEN", "MINOR", "EQUALS_MINOR", "MAJOR", "EQUALS_MAJOR", "EMPTY"];
    } else if (['DATE', 'DATE_TIME', 'TIME'].includes(propertyType)) {
      return ["EQUALS", "BETWEEN", "MINOR", "EQUALS_MINOR", "MAJOR", "EQUALS_MAJOR", "EMPTY"];
    } else if (['STATUS'].includes(propertyType)) {
      return ["IN"];
    } else if (['FIXED_COMBO'].includes(propertyType)) {
      return ["IN", 'EMPTY'];
    } else if (['BOOLEAN'].includes(propertyType)) {
      return ["EQUALS", "EMPTY"];
    } else if (['MEMO'].includes(propertyType)) {
      return ["EMPTY"];
    } else if (['GUID'].includes(propertyType)) {
      return ["EQUALS", "EMPTY"];
    }

    console.log(propertyType);

    return null;
  }

  selectOperator(field: ConditionField, operator: OperatorType) {
    field.operator = operator;
    field.requireValue = !["EMPTY"].includes(field.operator);
  }

  getValueOptions(field: ConditionField, datasetName: string, propertyName: string) {
    field.valueOptions = [];

    if (field.propertyType == "STATUS") {
      this.dsService.getStatusList(datasetName).subscribe(statusList => {
        if (statusList) {
          if (!Array.isArray(statusList))
            statusList = [statusList];

          field.valueOptions = statusList.map(status => {
            return { id: status.description, description: status.description, checked: false, colorStyle: status.statusColorStyle || "t2ColorGray" };
          });
        }
      });

    } else if (field.propertyType == "FIXED_COMBO") {
      this.dsService.getFixedComboList(datasetName, propertyName).subscribe(fcList => {
        if (fcList) {
          if (!Array.isArray(fcList))
            fcList = [fcList];

          field.valueOptions = fcList.map((fc: DatasetPropertyFixedComboItem) => {
            return { id: fc.description, description: fc.description, checked: false, colorStyle: "t2ColorGray" };
          });
        }
      });
    }

    field.valueList?.forEach(vi => {
      const valueOption = field.valueOptions.find(vp => vp.id == vi);
      if (valueOption)
        valueOption.checked = true;
    });
  }

  getVisualizationList(id_dataset: string): Observable<any> {

    return this.httpClient.get(`core.presentation/datasetVisualization/${id_dataset}/list`, null)
      .pipe(
        take(1),
        map(resp => {
          resp.visualizationList = resp.visualizationList || [];

          if (!Array.isArray(resp.visualizationList))
            resp.visualizationList = [resp.visualizationList];

          return resp.visualizationList;
        }));
  }

  getDatasetVisualization(id_dataset_visualization: string): Observable<DatasetVisualization> {
    return this.httpClient.get(`core.presentation/datasetVisualization/${id_dataset_visualization}`, null)
      .pipe(
        take(1),
        map(resp => {
          const vd = JSON.parse(resp.visualization.visualizationData);
          const dv = resp.visualization
          dv.fieldList = vd.fieldList || [];
          dv.queryCondition = vd.queryCondition || vd.condition || { condition: 'AND' };

          return dv;
        }));

  }

  saveVisualization(id_dataset: string, dv: DatasetVisualization): Observable<any> {

    const obj = {
      id_dataset_visualization: dv.id_dataset_visualization,
      id_dataset,
      description: dv.description,
      detail: dv.detail,
      publicVisualization: dv.publicVisualization,
      defaultVisualization: dv.defaultVisualization,
      visualizationData: JSON.stringify({ fieldList: dv.fieldList, queryCondition: dv.queryCondition })
    };

    return this.httpClient.post(`core.presentation/datasetVisualization/${id_dataset}`, null, obj)
      .pipe(take(1));
  }

  deleteVisualization(id_dataset_visualization: string): Observable<any> {
    return this.httpClient.delete(`core.presentation/datasetVisualization/${id_dataset_visualization}`, null)
      .pipe(take(1));
  }
}


export type PropertyType =
  "INT" | "MONEY" | "DECIMAL" | "DURATION" |
  "STRING" | "MEMO" | "AUTO_INC_MASKED" | "EMAIL" |
  "STATUS" | "FIXED_COMBO" |
  "GUID" |
  "DATE" | "DATE_TIME" | "TIME" |
  "BOOLEAN" |
  "BINARY" | "UNKNOWN";

export class VisualizationField {
  datasetName: string;
  propertyName: string;
  id_dataset: string;
  id_dataset_property: string;

  name: string;
  description: string;
  id_dataset_aggregation?: string;
  datasetName_aggregation?: string;
  relationType?: string;

  propertyType?: PropertyType;
  inactityControl?: boolean;
  statusControl?: boolean;
  pinned?: boolean = false;
}

export class SelectableDataset {
  id_dataset?: string;
  description: string;
  join: string;
  fieldList: Array<SelectableField>;
}

export class SelectableField {
  datasetName: string;
  id_dataset: string;
  id_dataset_property: string;
  datasetName_aggregation?: string;
  id_dataset_aggregation?: string;
  relationType?: string;
  propertyName: string;
  propertyLabel?: string;
  propertyType?: PropertyType;
  description?: string;
  selected?: boolean;
  join?: string;
  joinName?: string;
  level: number;
  fieldList?: Array<SelectableField> = [];

  inactityControl?: boolean;
  statusControl?: boolean;
  pinned?: boolean = false;
}

export type OperatorType =
  "EQUALS" |
  "EMPTY" |
  "STARTS_WITH" |
  "ENDS_WITH" |
  "CONTAINS" |
  "CONTAINS_ALL" |
  "CONTAINS_ONE" |
  "IN" |
  "BETWEEN" |
  "MINOR" |
  "MAJOR" |
  "EQUALS_MINOR" |
  "EQUALS_MAJOR"
  ;

export class ConditionGroup {

  title?: string;
  detail?: string;
  condition: 'AND' | 'OR' = 'AND';
  fieldList?: Array<ConditionField> = [];
  groupList?: Array<ConditionGroup> = [];

}

export class ConditionField {

  property?: string;
  operator: OperatorType = "EQUALS";
  not?: boolean
  value?: any;
  value2?: any;
  valueList?: Array<any>;
  valueOptions?: Array<{ id: string, description: string, checked: boolean, colorStyle?: string }>;

  description?: string;
  operators?: OperatorType[];
  requireValue?: boolean = false;

  fieldFilter?: { quickSearch: boolean, required: boolean };

  datasetId?: string;
  datasetName?: string;
  propertyType?: PropertyType;
  inactityControl?: boolean;
  statusControl?: boolean;
  datasetNameJoin?: string;
  propertyPathJoin?: string;
  idRecordJoin?: string;
}

export class DatasetVisualization {
  id_dataset_visualization?: string;
  description?: string;
  detail?: string;
  publicVisualization?: boolean;
  defaultVisualization?: boolean = false;
  queryCondition: ConditionGroup = { condition: "AND" }
  fieldList: Array<VisualizationField> = [];
}

export class DatasetVisualizationInfo {
  id_dataset_visualization: string;
  description: string;
  detail?: string;
  ownerName?: string;
  insertDate?: Date;
  canEdit: boolean = false;
  defaultVisualization: boolean = false
}
