<div *ngIf="showView == 'license'" style="width: 100%;">

  <div [ngStyle]="{'font-size': '1.5rem', 'text-align': 'end'}"
    [ngStyle.xs]="{'font-size': '1.2rem', 'text-align': 'center'}"
    style="font-weight: bold; margin-bottom: 20px !important;">Licenças
    Contratadas</div>

  <div class="flexColumn" style="gap: 8px">
    <div *ngFor="let license of contract?.licenses" class="licenses">
      <div>{{license.name == 'techprod'? 'TP Full' :
        license.name == 'dataCollector' ? 'TP Collector' :
        license.name == 'dashboard' ? 'Dashboards and reports':
        license.name == 'scheduler' ? 'TP Scheduler' :
        license.name == 'service' ? 'Integrations (API consumptions)' : ''}}</div>
      <div style="display: flex; gap: 10px; text-align: end; line-height: 90%;">
        <div>
          <div class="countTitle">Em uso</div>
          {{license.used}}
        </div>
        <div style="width: 40px">
          <div class="countTitle">Contrat</div>
          {{license.userCount}}
        </div>
      </div>
    </div>
  </div>

</div>

<div *ngIf="showView == 'sessions'" style="display: flex; gap: 20px; flex-direction: column; width: 100%;">

  <div style="height: 100%;" [ngStyle]="{'font-size': '1.5rem', 'text-align': 'end'}"
    [ngStyle.xs]="{'font-size': '1.2rem', 'text-align': 'center'}" style="font-weight: bold; ">Sessões abertas
  </div>

  <div style="width: 100%; height: 100%;">
    <app-t2grid #gridLicense [loading]="loading"> </app-t2grid>
  </div>
</div>
