<div class="flexRow" [nbSpinner]="loading" nbSpinnerSize="giant" nbSpinnerStatus="primary">
  <div class="defaultBigTitle sequenceNumber">{{requisition.sequence}}</div>
  <div class="flexColumn" style="flex: 1 1 100%; max-width: calc(100% - 30px)">
    <div class="flexRow flexGap5" style="padding: 5px;">
      <div class="flexColumn" style="flex: 1 1 100%; max-width: calc(100% - 60px);">
        <div class="flexAlignColumnStartCenter flexGap5">
          <div class="requisitionCode" (click)="$event.stopPropagation(); showActionTree('req', requisition.requestId)">
            {{requisition.requestCode}}</div>
          <span class="defaultText ellipsisOverflow">
            <span class="defaultSmallTitle">{{requisition.deviceDescription}}</span>
            {{requisition.taskDescription}}
          </span>
        </div>
        <span class="defaultSmallTitle ellipsisOverflow">{{requisition.prodOrderCode}} -
          {{requisition.prodOrderDescription}}</span>
      </div>
      <div class="elapsedTime" [ngClass]="{'onTime': requisition.elapsedTimeInMin < 25,
                       'warning': requisition.elapsedTimeInMin >= 25 && requisition.elapsedTimeInMin < 30,
                       'late': requisition.elapsedTimeInMin >= 30,
                       'emptyDate': !requisition.elapsedTimeInMin}" style="min-width: 55px;">
        <span class="defaultSmallTitle">{{requisition.elapsedTimeInMin != undefined ? (requisition.elapsedTimeInMin |
          minutesToHours) : '-'}}</span>
        <!-- <span class="defaultSmallTitle">{{requisition.elapsedTimeInMin}}</span>       -->
      </div>
    </div>
    <div class="flexColumn flexGap5" style="padding: 5px;">
      <span class="defaultText">{{requisition.itemDescription}}</span>
      <div class="flexRow flexGap10" style="line-height: 1rem;">
        <div class="detailBox flexColumn fxFlex50">
          <div class="flexAlignRowSpaceBetweenCenter flexGap5">
            <span class="detailBoxTitle">Solicitado</span>
            <span class="detailValue">{{requisition.requestedQty | number: '1.1-2' : locale}}
              {{requisition.itemUnit}}</span>
          </div>
          <div class="flexAlignRowSpaceBetweenCenter flexGap5">
            <span class="detailBoxTitle">Reservado</span>
            <span class="detailValue">{{requisition.reservedQty | number: '1.1-2' : locale}}
              {{requisition.itemUnit}}</span>
          </div>
          <div class="flexAlignRowSpaceBetweenCenter flexGap5">
            <span class="detailBoxTitle">Transferido</span>
            <span class="detailValue">{{requisition.transferredQty | number: '1.1-2' : locale}}
              {{requisition.itemUnit}}</span>
          </div>
          <div class="flexAlignRowSpaceBetweenCenter flexGap5">
            <span class="detailBoxTitle">Consumido</span>
            <span class="detailValue">{{requisition.consumedQty | number: '1.1-2' : locale}}
              {{requisition.itemUnit}}</span>
          </div>
        </div>
        <div class="detailBox flexColumn fxFlex50">
          <div class="flexRow flexGap5">
            <span class="detailBoxTitle">Solicitação</span>
            <span *ngIf="requisition.requestedDate" class="detailValue">{{requisition.requestedDate | date:
              dateFormat}}</span>
          </div>
          <div class="flexRow flexGap5">
            <span class="detailBoxTitle">Solicitante</span>
            <span class="detailValue ellipsisOverflow">{{requisition.requestedUser}}</span>
          </div>
          <div *ngIf="hasCoordinatorAccess" class="flexRow flexGap5">
            <span class="detailBoxTitle">Equipe OP</span>
            <span class="detailValue ellipsisOverflow">{{requisition.prodOrderSeparationTeam}}</span>
          </div>
          <div class="flexRow flexGap5">
            <span class="detailBoxTitle">Planejado</span>
            <span class="detailValue">{{requisition.planningDate | date: dateFormat}}</span>
          </div>
        </div>
      </div>
      <div *ngIf="hasCoordinatorAccess" class="flexColumn">
        <span class="cmpLabel">Equipe Separação</span>
        <app-t2-aggregation t2IdCmp="equipeSeparacao" datasetName="prd_equipeSeparacao"
          datasetId="zD20201123H184442467R000000001" [formGroup]="formGroup"></app-t2-aggregation>
      </div>
    </div>
  </div>

</div>
