import { T2SecurityService } from './../../../core/security/t2security.service';
import { DialogItemSelectionComponent, GroupSelection, ItemSelection } from './../../../core/cmp/dialog-item-selection/dialog-item-selection.component';
import { Injectable, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NbDialogService } from "@nebular/theme";
import { deleteField, where } from "firebase/firestore";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { finalize, map, take, takeUntil } from "rxjs/Operators";
import { DialogInputComponent } from "src/app/core/cmp/dialog-input/dialog-input.component";
import { DialogComponent } from "src/app/core/cmp/dialog/dialog.component";
import { T2InputTextComponent } from "src/app/core/cmp/ui/t2-input-text/t2-input-text.component";
import { T2ViewTemplateData } from "src/app/core/cmp/view-template/model/t2-view-template-data";
import { LayoutType, ViewTemplateElement } from "src/app/core/cmp/view-template/model/view-template-element";
import { T2HttpClientService } from "src/app/core/http/t2httpClient.service";
import { T2FirestoreService } from "src/app/core/t2firestore.service";
import { environment } from 'src/environments/environment';
import { ApsProdOrder } from "./model/aps-prod-order";
import { DocumentPlan } from "./model/document-plan";
import { TaskPlan } from "./model/task-plan";
import { WorkScheduleDeviceSelectionComponent } from "./visualization/work-schedule-device-selection/work-schedule-device-selection.component";
import { T2MessageService } from "src/app/core/t2-message.service";
import { WorkScheduleService } from "../ppc/work-schedule/work-schedule.service";
import { WarningService } from "src/app/core/warning/warning.service";
import { Warning } from "src/app/core/warning/model/warning";

@Injectable()
export class ApsService implements OnDestroy {

  public realId = "realEnvironment";

  private simulationList = new BehaviorSubject<Array<{
    id: string,
    description: string,
    firmDeadline?: number,
    workScheduleDeviceMap?: Map<string, string>,
    real: boolean,
    deviceGroupList: Array<{
      id_group: string,
      description: string,
      devices: Array<{
        id: string,
        description: string,
        id_companySite: string
      }>
    }>,
    lastSelected?: Date
  }>>([]);
  private deviceList = new Map<string, { sub: BehaviorSubject<TaskPlan[]>, unsub: Subject<any>, dependencyLoaded?: boolean }>();
  private companyId: string;
  private simulationSelected = new BehaviorSubject<{ id: string, description: string, firmDeadline?: number, real: boolean }>(undefined);
  private unsubscribe = new Subject<void>();
  public showView: string = "programming";
  private outOfDate = new BehaviorSubject<{ removedPlans: Array<TaskPlan>, newPlans: Array<TaskPlan> }>(undefined);
  private prodOrderList = new BehaviorSubject<Array<ApsProdOrder>>(undefined);

  public programmingVars = {
    deviceSelectedId: new BehaviorSubject<string>(undefined),
    ganttDeviceSelectedIdList: new BehaviorSubject<Array<string>>(undefined),
    focusedCmp: "sequencing",
    showComponent: {
      sequencing: true,
      gantt: false,
      list: false
    },
    planSelectedList: new BehaviorSubject<Array<TaskPlan>>(undefined)
  }

  public analysisVars = {
    analysisView: "machineLoad",
    machiLoadConsolidation: new BehaviorSubject<string>("daily"),
    deviceSelectedId: new BehaviorSubject<string>(undefined),
    groupSelectedId: new BehaviorSubject<string>(undefined)
  }

  constructor(private firestoreService: T2FirestoreService, private dialogService: NbDialogService,
    private httpClient: T2HttpClientService, private formBuilder: FormBuilder,
    private workScheduleService: WorkScheduleService,
    private messageService: T2MessageService,
    private sec: T2SecurityService, private warningService: WarningService) { }

  ngOnDestroy(): void {
    this.programmingVars.deviceSelectedId.complete();
    this.programmingVars.ganttDeviceSelectedIdList.complete();
    this.simulationSelected.complete();
    this.simulationList.complete();
    this.unsubscribe.next();
    this.unsubscribe.complete();
    this.unsubscribeAndClearAllDevices();
    this.firestoreService.unsubscribeFromStoppedListeners();
  }

  public initialize() {
    return new Promise((resolve, reject) => {
      this.firestoreService.initialize({ aps: true });

      this.sec.loadCompanyId().then((compId) => {
        this.companyId = this.httpClient.isHomologEnvironment ? compId + "H" : compId;
        this.signIn().then(() => {
          // Verifica se já existe o documento da Empresa, se não existir cria
          this.firestoreService.getDocument("Company", this.companyId).then(docSnap => {
            if (!docSnap.exists()) {
              this.firestoreService.addDocument("Company", {
                name: environment.companyID
              }, this.companyId).catch(error => {
                reject(error);
                return;
              })
            }

            resolve("success");
          }, error => {
            reject(error);
          });
        }, error => {
          reject(error);
        })
      }, error => {
        reject(error);
      });
    });
  }

  public loadSimulationList() {
    return new Promise((resolve, reject) => {
      this.firestoreService.getDocuments(`Company/${this.companyId}/Simulation`, where("deleted", "==", false)).then(async resp => {
        let simuList = new Array();
        for (let simulation of resp.docs) {
          let wsObj = simulation.get("workScheduleDeviceMap");
          simuList.push({
            id: simulation.id,
            description: simulation.get("description"),
            firmDeadline: simulation.get("firmDeadline"),
            workScheduleDeviceMap: wsObj ? new Map(Object.keys(wsObj).map(key => [key, wsObj[key]])) : undefined,
            real: simulation.id == this.realId,
            deviceGroupList: []
          });

          let deviceIdList: Array<string> = simulation.get("deviceIdList");
          let insertDeviceList = new Array<{ deviceId: string, workScheduleId?: string, description?: string }>();
          let simu = simuList.find(s => s.id == simulation.id);

          await this.getAllDevices().then(devList => {
            devList.filter(dev => !deviceIdList.includes(dev.deviceId)).forEach(dev => {
              deviceIdList.push(dev.deviceId);

              if (dev.workScheduleId) {
                if (!simu.workScheduleDeviceMap) {
                  simu.workScheduleDeviceMap = new Map();
                }

                simu.workScheduleDeviceMap.set(dev.deviceId, dev.workScheduleId);
                insertDeviceList.push({ deviceId: dev.deviceId, workScheduleId: dev.workScheduleId });
              } else {
                insertDeviceList.push({ deviceId: dev.deviceId });
              }
            })
          })

          await this.httpClient.post("production.aps/getDevicesByGroup", null, deviceIdList).pipe(take(1)).toPromise().then(respGroup => {
            if (!Array.isArray(respGroup.deviceGroupList)) {
              respGroup.deviceGroupList = [respGroup.deviceGroupList];
            }

            respGroup.deviceGroupList.forEach(dg => {
              if (!Array.isArray(dg.deviceList)) {
                dg.deviceList = [dg.deviceList];
              }

              simu.deviceGroupList.push({
                id_group: dg.id_group,
                description: dg.description,
                devices: dg.deviceList.map(d => {
                  let insertDev = insertDeviceList.find(dev => dev.deviceId == d.id_device);

                  if (insertDev) {
                    insertDev.description = d.description;
                  }

                  return {
                    id: d.id_device,
                    description: d.description,
                    id_companySite: d.id_companySite
                  }
                })
              });
            });
          });

          if (insertDeviceList.length > 0) {
            let promList = new Array<Promise<any>>();

            insertDeviceList.forEach(dev => {
              promList.push(
                this.firestoreService.addDocument(`Company/${this.companyId}/Simulation/${simulation.id}/Device`, {
                  description: dev.description,
                  planList: []
                }, dev.deviceId)
              );
            });

            let workScheduleObj = {};

            if (simu.workScheduleDeviceMap) {
              (simu.workScheduleDeviceMap as Map<string, string>).forEach((v, k) => {
                workScheduleObj[k] = v;
              })
            }

            let data = {
              description: simu.description,
              deleted: false,
              deviceIdList: deviceIdList,
              workScheduleDeviceMap: workScheduleObj
            };

            promList.push(this.firestoreService.updateDocument(`Company/${this.companyId}/Simulation`, simu.id, data));

            await Promise.all(promList).then();
          }
        }

        let realEnvironment = simuList.find(s => s.id == this.realId);

        if (!realEnvironment) {
          simuList.unshift({
            id: this.realId,
            description: "Ambiente Real",
            real: true,
            deviceGroupList: []
          });
        } else {
          simuList = simuList.filter(s => s.id != this.realId);
          simuList.unshift(realEnvironment);
        }

        this.simulationList.next(simuList);
        resolve("success");
      }, error => {
        reject(error);
      });
    })
  }

  public getSimulationList() {
    return this.simulationList.asObservable();
  }

  public addSimulation() {
    return new Promise((resolve, reject) => {
      let layout: ViewTemplateElement[] = [
        {
          layoutType: LayoutType.listLayout,
          direction: "column",
          children: [
            {
              layoutType: LayoutType.component,
              cmpType: T2InputTextComponent,
              cmpName: "descricao",
              isBaseComponent: true,
              title: "Descrição"
            }
          ]
        }
      ];

      let data: Array<T2ViewTemplateData> = [
        { cmpName: "descricao", properties: {} }
      ];

      let formGroup: FormGroup = this.formBuilder.group({}, { updateOn: 'blur' });
      formGroup.addControl("descricao", this.formBuilder.control(undefined, Validators.required));

      let dlg = this.dialogService.open(DialogInputComponent, {
        context: {
          title: "Novo Ambiente",
          layout: layout,
          data: data,
          FormGroup: formGroup
        },
        autoFocus: true, closeOnEsc: true, hasBackdrop: true
      });

      dlg.onClose
        .pipe(take(1))
        .subscribe(resp => {
          if (resp == "Confirma") {
            this.createSimulation(formGroup.get("descricao").value, false).then(() => resolve("success"), error => reject(error));
          } else {
            resolve("cancel");
          }
        })
    })
  }

  private createSimulation(description: string, isRealEnvironment: boolean) {
    return new Promise<void>((resolve, reject) => {
      this.httpClient.get("production.aps/basicPlanningList", null)
        .pipe(take(1))
        .subscribe(resp => {
          if (!Array.isArray(resp.planningList)) {
            resp.planningList = [resp.planningList];
          }

          let devices = new Array<{ id_device: string, description: string, plans: Array<string> }>();

          resp.planningList.forEach(planning => {
            let device = devices.find(d => d.id_device == planning.id_device);

            if (!device) {
              device = {
                id_device: planning.id_device,
                description: planning.deviceDescription,
                plans: []
              }

              devices.push(device);
            }

            device.plans.push(this.generatePlanningStringData(planning));
          });

          let workSchedulePromList = new Array<Promise<{ id_dispositivo: string, id_horarioTrabalho: string }>>();
          for (let device of devices) {
            let params = new Map<string, string>();
            params.set("id_dispositivo", device.id_device);
            workSchedulePromList.push(this.httpClient.get("workingTime/getIdHorarioTrabalhoDispositivo", params).pipe(take(1), map(resp => {
              return {
                id_dispositivo: device.id_device,
                id_horarioTrabalho: resp.id_horarioTrabalho
              }
            })).toPromise());
          }

          Promise.all(workSchedulePromList).then(wsList => {
            let data = {
              description: description,
              deleted: false,
              deviceIdList: devices.map(d => d.id_device),
              workScheduleDeviceMap: {}
            };

            wsList.forEach(item => {
              if (item.id_horarioTrabalho) {
                data.workScheduleDeviceMap[item.id_dispositivo] = item.id_horarioTrabalho;
              }
            });

            this.firestoreService.addDocument(`Company/${this.companyId}/Simulation`, data, isRealEnvironment ? this.realId : undefined).then(async docRef => {
              if (isRealEnvironment) {
                docRef = { id: this.realId }
              }

              devices.sort((a, b) => {
                return a.description < b.description ? -1 : 1;
              });

              let promList = new Array<Promise<any>>();
              for (let device of devices) {
                promList.push(this.firestoreService.addDocument(`Company/${this.companyId}/Simulation/${docRef.id}/Device`, {
                  description: device.description,
                  planList: device.plans
                }, device.id_device));
              }

              Promise.all(promList).then(() => {
                this.loadSimulationList().then(() => {
                  this.simulationSelected.next(this.simulationList.getValue().find(s => s.id == docRef.id));
                  resolve();
                })
              }, error => reject(error));
            }, error => {
              reject(error);
            });
          }, error => reject(error));
        });
    });
  }

  public updateRealSimulation() {
    return new Promise<void>((resolve, reject) => {
      let simulation = this.simulationSelected.getValue();

      if (!simulation.real) {
        reject("A simulação selecionada não é de ambiente real");
        return;
      }

      const params = new Map();
      params.set("id_prodEnvironment", this.realId);
      this.httpClient.get("production.aps/basicPlanningList", params)
        .pipe(take(1))
        .subscribe(resp => {
          if (!Array.isArray(resp.planningList)) {
            resp.planningList = [resp.planningList];
          }

          let devices = new Array<{ id_device: string, description: string, plans: Array<string> }>();

          resp.planningList.forEach(planning => {
            let device = devices.find(d => d.id_device == planning.id_device);

            if (!device) {
              device = {
                id_device: planning.id_device,
                description: planning.deviceDescription,
                plans: []
              }

              devices.push(device);
            }

            device.plans.push(this.generatePlanningStringData(planning));
          });

          let workSchedulePromList = new Array<Promise<{ id_dispositivo: string, id_horarioTrabalho: string }>>();
          for (let device of devices) {
            let params = new Map<string, string>();
            params.set("id_dispositivo", device.id_device);
            workSchedulePromList.push(this.httpClient.get("workingTime/getIdHorarioTrabalhoDispositivo", params).pipe(take(1), map(resp => {
              return {
                id_dispositivo: device.id_device,
                id_horarioTrabalho: resp.id_horarioTrabalho
              }
            })).toPromise());
          }

          Promise.all(workSchedulePromList).then(wsList => {
            let data = {
              description: simulation.description,
              deleted: false,
              deviceIdList: devices.map(d => d.id_device),
              workScheduleDeviceMap: {}
            };

            wsList.forEach(item => {
              if (item.id_horarioTrabalho) {
                data.workScheduleDeviceMap[item.id_dispositivo] = item.id_horarioTrabalho;
              }
            });

            this.firestoreService.updateDocument(`Company/${this.companyId}/Simulation`, simulation.id, data, true).then(() => {
              devices.sort((a, b) => {
                return a.description < b.description ? -1 : 1;
              });

              let promList = new Array<Promise<any>>();
              for (let device of devices) {
                promList.push(this.firestoreService.addDocument(`Company/${this.companyId}/Simulation/${simulation.id}/Device`, {
                  description: device.description,
                  planList: device.plans
                }, device.id_device));
              }

              Promise.all(promList).then(() => {
                resolve();
              })
            }, error => reject(error));
          }, error => reject(error));
        }, error => reject(error))
    });
  }

  public updateTimeReservationPlanningsInSimulation(id_device: string, apsId: number, id_task: string) {
    return new Promise<number>((resolve, reject) => {
      this.getSimulationSelectedId()
        .pipe(take(1))
        .subscribe(simuId => {
          let params = new Map();
          if (id_task) {
            params.set("id_task", id_task);
          }

          this.httpClient.get("production.aps/basicPlanningList", params)
            .pipe(take(1))
            .subscribe(resp => {
              if (!Array.isArray(resp.planningList)) {
                resp.planningList = resp?.planningList ? [resp.planningList] : [];
              }

              let planningList: Array<TaskPlan> = resp.planningList;
              let devicePlanList = new Array<DocumentPlan>();

              if (!id_device && id_task) {
                id_device = planningList[0].id_device;
              }

              this.firestoreService.getDocument(`Company/${this.companyId}/Simulation/${simuId}/Device`, id_device).then(docSnap => {

                let device = { id: docSnap.id, planList: [] };

                docSnap.get("planList").forEach(p => {
                  let docPlan = this.retrieveDocumentPlanFromStringData(p);
                  devicePlanList.push(docPlan);
                });

                if (apsId) {
                  let plan = planningList.find(tp => tp.apsId == apsId);

                  if (!plan) {
                    devicePlanList = devicePlanList.filter(dp => dp.apsId != apsId);
                  }
                } else if (id_task) {
                  planningList.forEach(tp => {
                    device.planList.push(this.generatePlanningStringData(tp));
                  });

                  apsId = planningList[0].apsId;
                }

                devicePlanList.forEach(dp => {
                  device.planList.push(this.generatePlanningStringData(dp.parseToTaskPlan()));
                });

                this.firestoreService.updateDocument(`Company/${this.companyId}/Simulation/${simuId}/Device`, device.id, {
                  planList: device.planList
                }).then(() => {
                  resolve(apsId);
                }, error => reject(error))
              }, error => reject(error));
            }, error => reject(error));
        }, error => reject(error));
    });
  }

  public deleteSimulation(simulationId: string) {
    return new Promise((resolve, reject) => {
      if (!simulationId) {
        reject("Nenhuma simulação selecionada");
        return;
      }

      if (this.isRealSimulation(simulationId)) {
        reject("Não é possível excluir o ambiente real");
        return;
      }

      let dlg = this.dialogService.open(DialogComponent, {
        context: {
          message: `Simulação: ${this.simulationList.getValue().find(s => s.id == simulationId).description}`,
          topMessage: "Deseja realmente excluir essa simulação ?",
          actions: [{ description: "Sim", status: "danger" }, { description: "Não", status: "basic" }]
        },
        autoFocus: true, closeOnEsc: true, hasBackdrop: true
      });

      dlg.onClose
        .pipe(take(1))
        .subscribe(resp => {
          if (resp == "Sim") {
            this.firestoreService.updateDocument(`Company/${this.companyId}/Simulation`, simulationId, {
              deleted: true
            }).then(() => {
              this.simulationSelected.next(undefined);
              this.programmingVars.deviceSelectedId.next(undefined);
              this.programmingVars.ganttDeviceSelectedIdList.next(undefined);
              this.unsubscribeAndClearAllDevices();
              this.deviceList.forEach(dev => {
                dev.sub.complete();
              });

              this.deviceList.clear();

              this.loadSimulationList().then(() => resolve("success"));
            }, error => {
              reject(error);
            })
          } else {
            resolve("cancel");
          }
        })
    })
  }

  private signIn() {
    return new Promise<void>((resolve, reject) => {
      let params = new Map<string, string>();
      params.set("app", "APS");
      params.set("uid", this.companyId);

      this.httpClient.get("core.firestore/authToken", params).pipe(take(1)).subscribe(respToken => {
        this.firestoreService.signInWithToken(respToken.token).then(() => {
          resolve();
        }, error => {
          reject(error);
        });
      }, error => {
        reject(error);
      });
    });
  }

  private loadSimulationDeviceData(id_device: string) {
    return new Promise<void>((resolve, reject) => {
      this.deviceList.get(id_device).dependencyLoaded = false;

      this.firestoreService.getDocumentListener(`Company/${this.companyId}/Simulation/${this.simulationSelected.getValue().id}/Device`, id_device)
        .pipe(takeUntil(this.deviceList.get(id_device).unsub))
        .subscribe(docSnap => {
          if (docSnap) {
            let planList: Array<TaskPlan>,
              apsIdList = new Array<number>();

            planList = this.deviceList.get(id_device).sub.getValue();
            let docPlanList = new Array<{ apsId: number, startDate: Date, endDate: Date }>();
            docSnap.get("planList").forEach(p => {
              let docPlan = this.retrieveDocumentPlanFromStringData(p);

              docPlanList.push(docPlan);

              let plan = planList.find(tp => tp.apsId == docPlan.apsId);

              if (!plan) {
                plan = new TaskPlan();
                plan["ID"] = docPlan.apsId;
                plan.apsId = docPlan.apsId;

                apsIdList.push(docPlan.apsId);
                planList.push(plan);
              }

              plan.startDate = docPlan.startDate;
              plan.endDate = docPlan.endDate;
              plan.fixedDate = docPlan.fixedDate;
              plan.incompatibilityNextPlan = docPlan.incompatibilityNextPlan;
            });

            planList = planList.filter(p => docPlanList.some(tp => tp.apsId == p.apsId));

            if (apsIdList.length > 0) {
              let params = new Map();
              params.set("id_environment", this.simulationSelected.getValue().id);
              this.httpClient.post("production.aps/getProdOrderInfo", params, apsIdList)
                .pipe(take(1))
                .subscribe(resp => {
                  if (!Array.isArray(resp.prodOrderList)) {
                    resp.prodOrderList = resp?.prodOrderList ? [resp.prodOrderList] : [];
                  }

                  let prodOrderList: Array<ApsProdOrder> = this.prodOrderList.getValue() ?? [];
                  if (prodOrderList.length > 0) {
                    prodOrderList = this.prodOrderList.value.filter(po => !resp.prodOrderList.map(rpo => rpo.id_ordemProd).includes(po.id_ordemprod));
                  }

                  prodOrderList.push(...resp.prodOrderList);

                  prodOrderList.forEach(po => {
                    if (po.demandList && !Array.isArray(po.demandList)) {
                      po.demandList = [po.demandList];
                    }

                    if (po.requisitionList && !Array.isArray(po.requisitionList)) {
                      po.requisitionList = [po.requisitionList];
                    }

                    if (po.taskList && !Array.isArray(po.taskList)) {
                      po.taskList = [po.taskList];
                    }

                    po.taskList.forEach(t => {
                      if (t.taskPlanList && !Array.isArray(t.taskPlanList)) {
                        t.taskPlanList = [t.taskPlanList];
                      }
                    });

                    if (po.cylinderList && !Array.isArray(po.cylinderList)) {
                      po.cylinderList = [po.cylinderList];
                    }
                  })

                  this.prodOrderList.next(prodOrderList);
                });

              this.httpClient.post("production.aps/getPlanningInfo", null, apsIdList)
                .pipe(take(1))
                .subscribe(resp => {
                  if (!Array.isArray(resp.planningList)) {
                    resp.planningList = [resp.planningList];
                  }

                  resp.planningList.forEach(taskPlan => {
                    let plan = planList.find(tp => tp.apsId == taskPlan?.apsId);

                    if (plan) {
                      plan.id_task_plan = taskPlan.id_task_plan;
                      plan.started = taskPlan.started;

                      if (plan.started) {
                        plan.startDate = new Date(taskPlan.startDate);
                        plan.endDate = new Date(taskPlan.endDate);
                      }

                      plan.minDate = taskPlan.minDate;
                      plan.maxDate = taskPlan.maxDate;
                      plan.task = taskPlan.task;
                      plan.id_device = id_device;
                      plan.device = taskPlan.device;
                      plan.prodOrder = taskPlan.prodOrder;
                      plan.prodOrdDescription = taskPlan.prodOrdDescription;
                      plan.prodOrdMinDate = taskPlan.prodOrdMinDate;
                      plan.prodOrdMaxDate = taskPlan.prodOrdMaxDate;
                      plan.client = taskPlan.client;
                      plan.estimatedRunDuration = Number(taskPlan.estimatedRunDuration).valueOf();
                      plan.estimatedSetupDuration = Number(taskPlan.estimatedSetupDuration).valueOf();
                      plan.percProgress = taskPlan.percProgress;
                      plan.id_task = taskPlan.id_task;
                      plan.id_ordemProd = taskPlan.id_ordemProd;
                      plan.id_especificacao = taskPlan.id_especificacao;
                      plan.urgent = taskPlan.urgent;
                      if (taskPlan.cylinderStatus?.entry) {
                        if (!Array.isArray(taskPlan.cylinderStatus.entry)) {
                          taskPlan.cylinderStatus.entry = [taskPlan.cylinderStatus.entry];
                        }

                        plan.cylinderStatus = new Map();
                        taskPlan.cylinderStatus.entry.forEach(entry => {
                          plan.cylinderStatus.set(entry.key, entry.value);
                        })

                      }

                      if (taskPlan.warningList) {
                        if (!Array.isArray(taskPlan.warningList)) {
                          taskPlan.warningList = [taskPlan.warningList];
                        }

                        plan.warningList = new Array<Warning>();

                        taskPlan.warningList.forEach(w => {
                          plan.warningList.push(this.warningService.transformServerResponse(w));
                        });
                      }

                      if (taskPlan.complementaryFieldList) {
                        plan.complementaryFields = new Array();

                        if (!Array.isArray(taskPlan.complementaryFieldList)) {
                          taskPlan.complementaryFieldList = [taskPlan.complementaryFieldList];
                        }

                        taskPlan.complementaryFieldList.forEach(compField => {
                          if (compField.name != "id_task_plan") {
                            let value;
                            if (compField.value != undefined && compField.value != null) {
                              switch (compField.type) {
                                case "STRING": {
                                  value = new String(compField.value.$);
                                  break;
                                }
                                case "INT":
                                case "FLOAT": {
                                  value = new Number(compField.value.$).valueOf();
                                  break;
                                }
                                case "BOOLEAN": {
                                  value = new Boolean(compField.value.$).valueOf();
                                  break;
                                }
                                case "DATE":
                                case "DATE_TIME": {
                                  value = new Date(compField.value.$);
                                  break;
                                }
                                default: {
                                  value = compField.value.$;
                                  break;
                                }
                              }
                            }

                            plan.complementaryFields.push({ name: compField.name, value: value, type: compField.type });
                          }
                        });
                      }

                      let potp = this.prodOrderList.getValue()?.find(po => po.id_ordemprod == plan.id_ordemProd)?.taskList?.find(t => t.id_task == plan.id_task)?.taskPlanList?.find(tp => tp.id_task_plan == plan.id_task_plan);

                      if (potp) {
                        potp.fixedDate = plan.fixedDate;
                      }
                    }
                  });

                  this.prodOrderList.next(this.prodOrderList.getValue());

                  this.sortTaskPlanList(planList);

                  this.deviceList.get(id_device).sub.next(planList);

                  resolve();
                }, error => reject(error));
            } else {
              this.sortTaskPlanList(planList);
              this.deviceList.get(id_device).sub.next(planList);
              resolve();
            }

          } else {
            resolve();
          }
        }, error => reject(error));
    });
  }

  private sortTaskPlanList(taskPlanList: Array<TaskPlan>) {
    taskPlanList?.sort((a, b) => {
      let resp: number = 0;
      if (a.started || b.started) {
        if (a.started) {
          if (b.started) {
            resp = a.startDate.getTime() - b.startDate.getTime();
          } else {
            resp = -1;
          }
        } else {
          resp = 1;
        }
      } else {
        resp = a.startDate.getTime() - b.startDate.getTime();
      }

      return resp;
    })
  }

  public loadUnproductiveInterval(id_device: string): Observable<Array<{ id_task_plan: string, started?: boolean, maxDate?: Date, startDate: Date, endDate: Date }>> {
    const params = new Map();
    params.set("id_prodEnvironment", this.simulationSelected.getValue().id);
    params.set("id_device", id_device);
    return this.httpClient.get("production.aps/getProductiveInterval", params)
      .pipe(
        take(1),
        map(resp => {

          if (!resp.prodInterval) return [];

          const prodInterval: Array<{ id_task_plan: string, started?: boolean, maxDate?: Date, startDate: Date, endDate: Date }> = Array.isArray(resp.prodInterval)
            ? resp.prodInterval
            : [resp.prodInterval];

          return prodInterval.map(item => {
            return {
              id_task_plan: item.id_task_plan,
              started: item.started,
              maxDate: item.maxDate ? new Date(item.maxDate) : undefined,
              startDate: new Date(item.startDate),
              endDate: new Date(item.endDate)
            };
          });
        }))
  }

  public getCustomMachineLoad(id_device: string) {
    const params = new Map();
    params.set("id_prodEnvironment", this.simulationSelected.getValue().id);
    params.set("id_device", id_device);
    params.set("consolidation", this.analysisVars.machiLoadConsolidation.value);
    return this.httpClient.get("production.aps/getCustomMachineLoad", params)
      .pipe(
        take(1),
        map(resp => {
          if (resp.chartOptions)
            return JSON.parse(resp.chartOptions);

          return [];
        })
      );
  }

  public getDeviceData(id_device: string) {
    if (!this.deviceList.has(id_device)) {
      this.deviceList.set(id_device, { sub: new BehaviorSubject([]), unsub: new Subject() });
      this.loadSimulationDeviceData(id_device)
    }

    return this.deviceList.get(id_device).sub.asObservable();
  }

  public getDeviceWorkScheduleId(id_device: string): string {
    let simu = this.simulationList.getValue().find(s => s.id == this.simulationSelected.getValue().id);
    return simu.workScheduleDeviceMap.get(id_device);
  }

  public unsubscribeDevice(id_device: string) {
    this.deviceList.get(id_device)?.unsub.next(true);
    this.deviceList.get(id_device)?.unsub.complete();

    this.firestoreService.unsubscribeFromStoppedListeners();
  }

  public getSimulationGroupDevices() {
    return this.simulationList.getValue()?.find(s => s.id == this.simulationSelected.getValue()?.id)?.deviceGroupList;
  }

  public getSimulationDevices(): Array<{ id_group: string, groupDescription: string, id: string, description: string }> {
    const devs = [];
    const devGroupList = this.getSimulationGroupDevices();
    devGroupList?.forEach(group => {
      devs.push(...group.devices.map(d => {
        return {
          id_group: group.id_group,
          groupDescription: group.description,
          id: d.id,
          description: d.description
        };
      }));
    });

    return devs;
  }

  public getSimulationDevice(id: string): { id: string, description: string } {
    const devList = this.getSimulationDevices();
    return devList.find(dev => dev.id == id);
  }

  public getSimulationSelectedId() {
    return this.simulationSelected.pipe(map(s => s?.id));
  }

  public setSimulationSelected(id: string) {
    this.outOfDate.next(undefined);
    this.programmingVars.deviceSelectedId.next(undefined);
    this.programmingVars.ganttDeviceSelectedIdList.next(undefined);
    this.unsubscribeAndClearAllDevices();
    this.unsubscribe.next();
    this.firestoreService.unsubscribeFromStoppedListeners();

    let prom: Promise<void>;

    if (id == this.realId && this.simulationList.getValue().find(s => s.id == id).deviceGroupList.length == 0) {
      prom = new Promise((resolve, reject) => {
        this.createSimulation("Ambiente Real", true).then(() => resolve(), error => reject(error));
      });
    } else {
      prom = new Promise((resolve, reject) => resolve());
    }

    prom.then(() => {
      let simulation = this.simulationList.getValue().find(s => s.id == id);
      this.simulationSelected.next(simulation);

      if (simulation) {
        if (id == this.realId && (!simulation.lastSelected || ((new Date().getTime() - simulation.lastSelected.getTime()) / 1000 / 60) > 5)) {
          this.updateRealSimulation();
        }

        simulation.lastSelected = new Date();

        this.firestoreService.getDocumentListener(`Company/${this.companyId}/Simulation`, id)
          .pipe(takeUntil(this.unsubscribe))
          .subscribe(simulation => {
            if (simulation && !simulation.metadata.hasPendingWrites) {
              this.simulationSelected.next({
                id: simulation.id,
                description: simulation.get("description"),
                firmDeadline: simulation.get("firmDeadline"),
                real: simulation.id == this.realId
              });
            }
          });
      }
    });
  }

  private getAllDevices(): Promise<Array<{ deviceId: string, workScheduleId: string, id_companySite: string }>> {
    return this.httpClient.get("production.aps/getAllDevices", null).pipe(take(1), map(resp => {
      let deviceList = new Array<{ deviceId: string, workScheduleId: string, id_companySite: string }>();

      if (resp.deviceList) {
        if (!Array.isArray(resp.deviceList)) {
          resp.deviceList = [resp.deviceList];
        }

        resp.deviceList.forEach(dev => {
          deviceList.push({ deviceId: dev.id_device, workScheduleId: dev.id_workSchedule, id_companySite: dev.id_companySite });
        })
      }

      return deviceList;
    })).toPromise();

  }

  public recalculate(planningList: Array<TaskPlan>, calculationMethod: string, initialDate: Date, firmDeadlineQtyDays: number, id_device: string) {
    return new Promise<Array<TaskPlan>>((resolve, reject) => {

      let params = new Map<string, string>();
      let simuSelected = this.simulationList.getValue().find(s => s.id == this.simulationSelected.getValue().id);

      if (!planningList || planningList.length == 0) {
        reject("Nenhum planejamento informado");
        return;
      }

      if (!simuSelected.workScheduleDeviceMap || !simuSelected.workScheduleDeviceMap.get(id_device)) {
        reject("Informe o horário de trabalho para este ambiente");
        return;
      }

      params.set("id_prodEnvironment", simuSelected.id);
      params.set("calculationMethod", calculationMethod);
      params.set("id_workSchedule", simuSelected.workScheduleDeviceMap.get(id_device));

      if (initialDate) {
        params.set("initialDate", initialDate.toISOString());
      }

      if (firmDeadlineQtyDays) {
        params.set("firmDeadlineQtyDays", firmDeadlineQtyDays.toFixed());
      }

      // Enviar apenas as informacoes necessárias para realizar o recalculo
      // (e assim otimizar o tamanho da requisicao com a API do Techprod)
      const plList: Array<{}> = planningList.map(p => {
        return {
          apsId: p.apsId,
          id_device: p.id_device,
          id_task_plan: p.id_task_plan,
          started: p.started,
          fixedDate: p.fixedDate,
          setup: p.estimatedSetupDuration,
          run: p.estimatedRunDuration,
          startDate: p.startDate,
          endDate: p.endDate
        };
      });

      this.httpClient.post("production.aps/recalculate", params, plList).subscribe(resp => {
        if (!Array.isArray(resp.planningList)) {
          resp.planningList = [resp.planningList];
        }

        resp.planningList.forEach(tp => {
          let plan = planningList.find(p => p.apsId == tp.apsId);
          plan.startDate = tp.startDate;
          plan.endDate = tp.endDate;
          plan.incompatibilityNextPlan = tp.incompatibilityNextPlan;
        });

        let plans = [];

        planningList.forEach(p => {
          plans.push(this.generatePlanningStringData(p));
        });

        this.firestoreService.updateDocument(`Company/${this.companyId}/Simulation/${this.simulationSelected.getValue().id}/Device`, id_device, {
          planList: plans
        }).then(() => resolve(planningList), error => reject(error));
      }, error => {
        reject(error.message);
      });
    });
  }

  private generatePlanningStringData(planning: TaskPlan) {
    return planning.apsId + " " + new Date(planning.startDate).getTime() + " " + new Date(planning.endDate).getTime() + " "
      + (new Boolean(planning.fixedDate).valueOf() ? "1" : "0") + " " + planning.incompatibilityNextPlan;
  }

  private retrieveDocumentPlanFromStringData(data: string): DocumentPlan {
    let splitedString = data.split(" ");
    let docPlan = new DocumentPlan();
    docPlan.apsId = new Number(splitedString[0]).valueOf();
    docPlan.startDate = new Date(new Number(splitedString[1]).valueOf());
    docPlan.endDate = new Date(new Number(splitedString[2]).valueOf());
    docPlan.fixedDate = splitedString[3] == "1" ? true : false;
    docPlan.incompatibilityNextPlan = new Number(splitedString[4]).valueOf();

    return docPlan;
  }

  public setFixedDate(planningList: Array<TaskPlan>, id_device: string) {
    return new Promise<void>((resolve, reject) => {
      let plans = new Array<string>();

      planningList.forEach(plan => {
        plans.push(this.generatePlanningStringData(plan));
      });

      this.firestoreService.updateDocument(`Company/${this.companyId}/Simulation/${this.simulationSelected.getValue().id}/Device`, id_device, {
        planList: plans
      }).then(() => {
        planningList.forEach(plan => {
          let potp = this.prodOrderList.getValue()?.find(po => po.id_ordemprod == plan.id_ordemProd)?.taskList?.find(t => t.id_task == plan.id_task)?.taskPlanList?.find(tp => tp.id_task_plan == plan.id_task_plan);

          if (potp) {
            potp.fixedDate = plan.fixedDate;
          }
        });

        this.prodOrderList.next(this.prodOrderList.getValue());

        resolve();
      }, error => reject(error));
    });
  }

  public unsubscribeAndClearAllDevices() {
    this.deviceList.forEach(dev => {
      dev.sub.next(undefined);
      dev.unsub.next();
      dev.unsub.complete();
    });

    this.deviceList.clear();
    this.firestoreService.unsubscribeFromStoppedListeners();
  }

  public getSimulationFirmDeadline() {
    return this.simulationSelected.getValue().firmDeadline;
  }

  public getSimulationSelected() {
    return this.simulationSelected.asObservable();
  }

  public isRealSimulation(simulationId?: string) {
    if (!simulationId) {
      simulationId = this.simulationSelected.getValue().id;
    }

    return this.simulationList.getValue().find(s => s.id == simulationId).real;
  }

  public setFirmDeadline(qtyDays: number) {
    return new Promise<void>((resolve, reject) => {
      this.firestoreService.updateDocument(`Company/${this.companyId}/Simulation`, this.simulationSelected.getValue().id, {
        firmDeadline: qtyDays
      }).then(() => {
        this.simulationSelected.getValue().firmDeadline = qtyDays;
        this.simulationList.getValue().find(s => s.id == this.simulationSelected.getValue().id).firmDeadline = qtyDays;
        resolve();
      }, error => reject(error));
    })
  }

  public removeFirmDeadline() {
    return new Promise<void>((resolve, reject) => {
      this.firestoreService.updateDocument(`Company/${this.companyId}/Simulation`, this.simulationSelected.getValue().id, {
        firmDeadline: deleteField()
      }).then(() => {
        this.simulationSelected.getValue().firmDeadline = undefined;
        this.simulationList.getValue().find(s => s.id == this.simulationSelected.getValue().id).firmDeadline = undefined;
        resolve();
      }, error => reject(error));
    })
  }

  public swapDevice(taskPlanList: Array<TaskPlan>, currentDeviceId: string, newDeviceId: string) {
    return new Promise<void>((resolve, reject) => {
      this.firestoreService.getDocument(`Company/${this.companyId}/Simulation/${this.simulationSelected.getValue().id}/Device`, currentDeviceId)
        .then(docSnap => {
          let planningList = [];
          docSnap.get("planList").forEach(tp => {
            let docPlan = this.retrieveDocumentPlanFromStringData(tp);
            let plan = docPlan.parseToTaskPlan();

            if (!taskPlanList.some(p => p.apsId == plan.apsId)) {
              planningList.push(this.generatePlanningStringData(plan));
            }
          });

          this.firestoreService.updateDocument(`Company/${this.companyId}/Simulation/${this.simulationSelected.getValue().id}/Device`, currentDeviceId, {
            planList: planningList
          }).then(() => {
            this.firestoreService.getDocument(`Company/${this.companyId}/Simulation/${this.simulationSelected.getValue().id}/Device`, newDeviceId)
              .then(docSnap => {
                let planList: Array<string> = docSnap.get("planList");

                taskPlanList.forEach(p => {
                  planList.push(this.generatePlanningStringData(p));
                });

                this.firestoreService.updateDocument(`Company/${this.companyId}/Simulation/${this.simulationSelected.getValue().id}/Device`, newDeviceId, {
                  planList: planList
                }).then(() => {
                  this.loadSimulationDeviceData(currentDeviceId).then(() => {
                    if (this.deviceList.has(newDeviceId)) {
                      this.deviceList.delete(newDeviceId);
                    }

                    resolve();
                  });
                }, error => reject(error));
              }, error => reject(error));
          }, error => reject(error));
        }, error => reject(error));
    })
  }

  public showProgrammingCmp(name: string) {
    switch (name) {
      case "sequencing": {
        this.programmingVars.showComponent.sequencing = true;
        this.programmingVars.showComponent.gantt = false;
        this.programmingVars.showComponent.list = false;
        break;
      }

      case "gantt": {
        this.programmingVars.showComponent.sequencing = false;
        this.programmingVars.showComponent.gantt = true;
        this.programmingVars.showComponent.list = false;
        break;
      }

      case "list": {
        this.programmingVars.showComponent.sequencing = false;
        this.programmingVars.showComponent.gantt = false;
        this.programmingVars.showComponent.list = true;
        break;
      }
    }

    this.outOfDate.next(this.outOfDate.getValue());
  }

  public setProgrammingFocusedComponent(name: string) {
    this.programmingVars.focusedCmp = name;
  }

  public loadAllDevicesData() {
    return new Promise<void>((resolve, reject) => {
      this.simulationSelected
        .pipe(take(1), map(s => s.id))
        .subscribe(simuId => {
          if (simuId) {
            let promList = new Array<Promise<void>>();
            this.simulationList.getValue().find(s => s.id == simuId).deviceGroupList.forEach(devGroup => {
              devGroup.devices.forEach(device => {
                if (!this.deviceList.has(device.id) || this.deviceList.get(device.id).sub.isStopped) {
                  promList.push(this.loadSimulationDeviceData(device.id));
                }
              });
            });

            if (promList.length == 0) {
              resolve();
              return;
            } else {
              Promise.all(promList).then(() => resolve(), error => reject(error));
            }
          } else {
            reject("Nenhuma simulação selecionada");
            return;
          }
        });
    });
  }

  private getSimulationData() {
    return new Promise<Array<TaskPlan>>((resolve, reject) => {
      this.simulationSelected
        .pipe(take(1), map(s => s.id))
        .subscribe(simuId => {
          if (simuId) {
            this.firestoreService.getAllDocumentsFromCollection(`Company/${this.companyId}/Simulation/${simuId}/Device`).then(querySnap => {
              let apsIdList = new Array<number>();
              querySnap.forEach(docSnap => {
                docSnap.get("planList").forEach(p => {
                  let docPlan = this.retrieveDocumentPlanFromStringData(p);

                  apsIdList.push(docPlan.apsId);
                });
              });

              this.httpClient.post("production.aps/getPlanningInfo", null, apsIdList)
                .pipe(take(1))
                .subscribe(resp => {
                  if (!Array.isArray(resp.planningList)) {
                    resp.planningList = [resp.planningList];
                  }

                  let planningList: Array<TaskPlan> = resp.planningList;

                  resolve(planningList);
                  return;
                });
            });
          } else {
            reject("Nenhuma simulação selecionada");
            return;
          }
        }, error => reject(error));
    });
  }

  public saveSimulationInDB(deviceIdList: Array<string>) {
    return new Promise<void>((resolve, reject) => {
      this.simulationSelected
        .pipe(take(1))
        .subscribe(simu => {
          if (simu) {
            let planningList = new Array<TaskPlan>();

            deviceIdList.forEach(devId => {
              planningList.push(...this.deviceList.get(devId).sub.getValue());
            });

            this.httpClient.post("production.aps/applySimulation", null, planningList)
              .pipe(take(1))
              .subscribe(resp => {
                let wdm = this.simulationList.getValue().find(s => s.id == this.simulationSelected.getValue().id).workScheduleDeviceMap;

                deviceIdList.forEach(devId => {
                  let obj = new Array<{ id_dispositivo: string, id_horarioTrabalho: string }>();
                  if (wdm.has(devId)) {
                    obj.push({ id_dispositivo: devId, id_horarioTrabalho: wdm.get(devId) });
                  }

                  this.httpClient.post("workingTime/gravaHorarioTrabalhoDispositivo", null, obj)
                    .pipe(take(1))
                    .subscribe(() => resolve(), error => reject(error));
                });
              }, error => reject(error))
          } else {
            resolve();
          }
        });
    });
  }

  public compareSimulationWithDB(deviceId: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      let simuId = this.simulationSelected.getValue().id;
      if (!simuId) {
        reject("Nenhuma simulação selecionada");
        return;
      }

      let devicePlanList = this.deviceList.get(deviceId).sub.getValue();
      let params = new Map<string, string>();

      params.set("id_device", deviceId);

      this.httpClient.get("production.aps/basicPlanningList", params)
        .pipe(take(1))
        .subscribe(resp => {
          if (!Array.isArray(resp.planningList)) {
            resp.planningList = [resp.planningList];
          }

          let response = {
            removedPlans: new Array<TaskPlan>(),
            newPlans: new Array<TaskPlan>()
          }

          let dbPlanningList: Array<TaskPlan> = resp.planningList;

          response.removedPlans = devicePlanList.filter(tp => !dbPlanningList.map(p => new Number(p.apsId).valueOf()).includes(tp.apsId));
          response.newPlans = dbPlanningList.filter(tp => !devicePlanList.map(p => p.apsId).includes(new Number(tp.apsId).valueOf()));

          if (response.newPlans.length > 0) {
            this.httpClient.post("production.aps/getPlanningInfo", null, response.newPlans.map(p => new Number(p.apsId).valueOf()))
              .pipe(take(1))
              .subscribe(respPI => {
                if (!Array.isArray(respPI.planningList)) {
                  respPI.planningList = [respPI.planningList];
                }

                response.newPlans = respPI.planningList;

                if (simuId == this.simulationSelected.getValue().id) {
                  this.outOfDate.next(response);
                }

                resolve();
              }, error => reject(error));
          } else {
            if (response.removedPlans.length > 0 && simuId == this.simulationSelected.getValue().id) {
              this.outOfDate.next(response);
            }

            resolve();
          }
        }, error => reject(error));
    });
  }

  public isSimulationOutOfDate() {
    return this.outOfDate.pipe(map(obj => {
      if (this.programmingVars.showComponent.sequencing) {
        return obj?.removedPlans?.filter(p => p.id_device == this.programmingVars.deviceSelectedId.getValue()).length > 0 ||
          obj?.newPlans?.filter(p => p.id_device == this.programmingVars.deviceSelectedId.getValue()).length > 0;
      } else if (this.programmingVars.showComponent.gantt) {
        return obj?.removedPlans?.filter(p => this.programmingVars.ganttDeviceSelectedIdList.getValue().includes(p.id_device)).length > 0 ||
          obj?.newPlans?.filter(p => this.programmingVars.ganttDeviceSelectedIdList.getValue().includes(p.id_device)).length > 0;
      }

    }))
  }

  public getSimulationNewData() {
    return this.outOfDate.getValue();
  }

  public updateSimulation() {
    return new Promise<void>((resolve, reject) => {
      this.getSimulationSelectedId()
        .pipe(take(1))
        .subscribe(simuId => {
          if (!simuId) {
            reject("Nenhuma simulação selecionada");
            return;
          }

          this.isSimulationOutOfDate()
            .pipe(take(1))
            .subscribe(isOutofDate => {
              if (!isOutofDate) {
                reject("Essa simulação não precisa ser atualizada");
                return;
              }

              let devices: Array<{ id: string, planList: Array<{ plan: TaskPlan, remove: boolean }> }> = [];
              let updateList = this.outOfDate.getValue();

              updateList.removedPlans?.forEach(plan => {
                let device = devices.find(dev => dev.id == plan.id_device);

                if (!device) {
                  device = { id: plan.id_device, planList: [] }
                  devices.push(device);
                };

                device.planList.push({ plan: plan, remove: true });
              });

              updateList.newPlans?.forEach(plan => {
                let device = devices.find(dev => dev.id == plan.id_device);

                if (!device) {
                  device = { id: plan.id_device, planList: [] }
                  devices.push(device);
                };

                device.planList.push({ plan: plan, remove: false });
              });

              let promList = new Array<Promise<void>>();

              devices.forEach(device => {
                this.firestoreService.getDocument(`Company/${this.companyId}/Simulation/${simuId}/Device`, device.id).then(docSnap => {
                  let planList = new Array<TaskPlan>();
                  docSnap.get("planList").forEach(tp => {
                    let docPlan = this.retrieveDocumentPlanFromStringData(tp);
                    let plan = docPlan.parseToTaskPlan();

                    planList.push(plan);
                  });

                  planList = planList.filter(tp => !device.planList.some(p => p.remove && p.plan.apsId == tp.apsId));

                  device.planList.filter(tp => !tp.remove).forEach(p => {
                    planList.push(p.plan);
                  });

                  promList.push(this.firestoreService.updateDocument(`Company/${this.companyId}/Simulation/${simuId}/Device`, device.id, {
                    planList: planList.map(p => {
                      return this.generatePlanningStringData(p)
                    })
                  }));
                }, error => reject(error));
              });

              Promise.all(promList).then(() => {
                this.outOfDate.next(undefined);
                resolve();
              }, error => reject(error));
              return;
            }, error => reject(error));
        }, error => reject(error));
    })
  }

  getDeviceCalendar(id_device: string, initialDate: Date) {
    let simulation = this.simulationList.getValue().find(s => s.id == this.simulationSelected.getValue().id);

    if (!simulation.workScheduleDeviceMap || !simulation.workScheduleDeviceMap.get(id_device)) {
      this.messageService.showToast("Informe o horário de trabalho para este ambiente", "ATENÇÃO", "warning");
      throw new Error("Informe o horário de trabalho para este ambiente");
    }

    let id_horarioTrabalho = simulation.workScheduleDeviceMap.get(id_device);

    const params = new Map<string, string>();
    params.set("id_horarioTrabalho", id_horarioTrabalho);
    params.set("initialDate", initialDate.toISOString());
    return this.httpClient.get(`production.aps/getDeviceCalendar`, params)
      .pipe(take(1));
  }

  renameSimulation(id: string) {
    return new Promise((resolve, reject) => {
      let layout: ViewTemplateElement[] = [
        {
          layoutType: LayoutType.listLayout,
          direction: "column",
          children: [
            {
              layoutType: LayoutType.component,
              cmpType: T2InputTextComponent,
              cmpName: "descricao",
              isBaseComponent: true,
              title: "Descrição"
            }
          ]
        }
      ];

      let data: Array<T2ViewTemplateData> = [
        { cmpName: "descricao", properties: {} }
      ];

      let simulation = this.simulationList.getValue().find(s => s.id == id);
      let formGroup: FormGroup = this.formBuilder.group({}, { updateOn: 'blur' });
      formGroup.addControl("descricao", this.formBuilder.control(simulation.description, Validators.required));

      let dlg = this.dialogService.open(DialogInputComponent, {
        context: {
          title: "Renomear Ambiente",
          layout: layout,
          data: data,
          FormGroup: formGroup
        },
        autoFocus: true, closeOnEsc: true, hasBackdrop: true
      });

      dlg.onClose
        .pipe(take(1))
        .subscribe(resp => {
          if (resp == "Confirma") {
            this.firestoreService.updateDocument(`Company/${this.companyId}/Simulation`, simulation.id, {
              description: formGroup.get("descricao").value
            }).then(() => {
              simulation.description = formGroup.get("descricao").value;
              this.simulationList.next(this.simulationList.value);

              if (simulation.id == this.simulationSelected.getValue().id) {
                this.simulationSelected.next(simulation);
              }
              resolve("success");
            }, error => reject(error));
          } else {
            resolve("cancel");
          }
        })
    })
  }

  public loadTaskDependency(id_device: string) {
    return new Promise<void>((resolve, reject) => {
      let device = this.deviceList.get(id_device);

      if (device.dependencyLoaded) {
        resolve();
        return;
      } else {
        let planningList = device.sub.getValue();

        this.httpClient.post("production.aps/loadTaskDependency", null, planningList.map(tp => tp.apsId))
          .pipe(take(1))
          .subscribe(resp => {
            if (resp.taskDependencyList) {
              if (!Array.isArray(resp.taskDependencyList)) {
                resp.taskDependencyList = [resp.taskDependencyList]
              }

              resp.taskDependencyList.forEach(td => {
                let plan = planningList.find(p => p.apsId == td.apsId);

                if (td.predecessorTaskIds && !Array.isArray(td.predecessorTaskIds)) {
                  td.predecessorTaskIds = [td.predecessorTaskIds];
                }

                plan.predecessorTaskIds = td.predecessorTaskIds;

                if (td.successorTaskIds && !Array.isArray(td.successorTaskIds)) {
                  td.successorTaskIds = [td.successorTaskIds];
                }

                plan.successorTaskIds = td.successorTaskIds;
              });
            }

            device.dependencyLoaded = true;
            device.sub.next(planningList);
            resolve();
          }, error => reject(error));
      }
    })
  }

  public getProdOrderInfo(): Observable<Array<ApsProdOrder>> {
    return this.prodOrderList.asObservable();
  }

  public openDeviceSelection(groupSelection: string, itemSelection: string, itemFromDifGroups: boolean, devicesSelectedId?: Array<string>, dialogTitle?: string): Observable<{ groups: Array<string>, items: Array<string> }> {
    let groups: Array<GroupSelection> = [];
    let devices: Array<ItemSelection> = [];
    let groupSelected: boolean = false;

    this.simulationList.getValue().find(s => s.id == this.simulationSelected.getValue().id).deviceGroupList.forEach(groupDevice => {
      groupDevice.devices.filter(dev => dev.id_companySite == this.sec.getCompanySiteId()).forEach(device => {
        devices.push(
          {
            itemName: device.description,
            id_item: device.id,
            selected: devicesSelectedId?.includes(device.id)
          }
        );
      });

      if (!devices.find(d => !d.selected) && groupSelection != "none") groupSelected = true;
      groups.push(
        {
          groupName: groupDevice.description,
          id_group: groupDevice.id_group,
          selected: groupSelected,
          items: devices
        }
      );
      devices = [];
      groupSelected = false;
    });

    groups = groups.filter(g => g.items?.length);

    let dis = this.dialogService.open(DialogItemSelectionComponent, {
      context: {
        title: dialogTitle || "Selecione um dispositivo",
        groups: groups,
        groupSelection: groupSelection,
        itemSelection: itemSelection,
        itemFromDifGroups: itemFromDifGroups,

      },
      autoFocus: true, closeOnEsc: true, hasBackdrop: true
    });

    return dis.onClose
      .pipe(take(1),
        finalize(() => {
          this.outOfDate.next(this.outOfDate.getValue());
        }));
  }

  public editWorkScheduleSimulationDevices(simulationId: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      let simulation = this.simulationList.getValue().find(s => s.id == simulationId);

      if (simulation) {
        let devList = new Array<{ id: string, description: string }>();

        simulation.deviceGroupList.forEach(dg => {
          dg.devices.filter(dev => dev.id_companySite == this.sec.getCompanySiteId()).forEach(dev => {
            devList.push({ id: dev.id, description: dev.description });
          });
        });

        this.workScheduleService.getWorkScheduleList().then(wsList => {
          if (!simulation.workScheduleDeviceMap) {
            simulation.workScheduleDeviceMap = new Map<string, string>();
          }

          let dlg = this.dialogService.open(WorkScheduleDeviceSelectionComponent, {
            context: {
              deviceList: devList,
              workScheduleList: wsList.map(ws => {
                return {
                  id: ws.id,
                  description: ws.description
                }
              }),
              workScheduleDeviceMap: simulation.workScheduleDeviceMap
            }
          });

          dlg.onClose
            .pipe(take(1))
            .subscribe(resp => {
              if (resp) {
                simulation.workScheduleDeviceMap = resp;
                let data = {};

                simulation.workScheduleDeviceMap.forEach((value, key) => {
                  data[`workScheduleDeviceMap.${key}`] = value;
                })

                this.firestoreService.updateDocument(`Company/${this.companyId}/Simulation`, simulationId, data).then(() => {
                  if (simulationId == this.realId) {
                    let obj = new Array<{ id_dispositivo: string, id_horarioTrabalho: string }>();
                    simulation.workScheduleDeviceMap.forEach((value, key) => {
                      obj.push({ id_dispositivo: key, id_horarioTrabalho: value });
                    });

                    this.httpClient.post("workingTime/gravaHorarioTrabalhoDispositivo", null, obj)
                      .pipe(take(1))
                      .subscribe(resp => resolve(), error => reject(error));
                  } else {
                    resolve();
                  }
                }, error => reject(error));
              } else {
                resolve();
              }
            });
        });
      } else {
        reject("Não foi encontrada nenhuma simulação para o ID informado");
      }
    });
  }

  public canEditEnvironment(): boolean {
    const editAccess = this.sec.hasAction('zD20230126H095450213R000000005');
    return editAccess;
  }

  public canDeleteEnvironment(): boolean {
    const editAccess = this.sec.hasAction('zD20230126H095858270R000000025');
    return editAccess;
  }

  public canAddEnvironment(): boolean {
    const editAccess = this.sec.hasAction('zD20230126H095817337R000000015');
    return editAccess;
  }

  public canSetFirmDeadline(): boolean {
    const editAccess = this.sec.hasAction('zD20230126H100817673R000000035');
    return editAccess;
  }

  public canRecalculate(): boolean {
    const editAccess = this.sec.hasAction('zD20230126H100927860R000000045');
    return editAccess;
  }

  public canApplySimulation(): boolean {
    const editAccess = this.sec.hasAction('zD20230126H100958357R000000055');
    return editAccess;
  }

  public canEditWorkSchedule(): boolean {
    const editAccess = this.sec.hasAction('zD20230126H101028110R000000065');
    return editAccess;
  }

  public canDoTimeReservation(): boolean {
    const editAccess = this.sec.hasAction('zD20230126H101108470R000000075');
    return editAccess;
  }

  public canSwapDevice(): boolean {
    const editAccess = this.sec.hasAction('zD20230126H101136023R000000085');
    return editAccess;
  }

  public canSortSelectedTasks(): boolean {
    const editAccess = this.sec.hasAction('zD20230126H101213700R000000095');
    return editAccess;
  }

  public canSetFixedDate(): boolean {
    const editAccess = this.sec.hasAction('zD20230126H101250437R000000105');
    return editAccess;
  }

}
