<app-dialog-template #dialog class="w" title="Informe a cor na qual será adicionado mais um cilindro">

  <content class="wContent flexColumn">
    <div class="inpuTitle">Para que cor será adicionado um cilindro?</div>
    <nb-select size="small" [(selected)]="selectedItem" style="width: 100%;" size="small">
      <nb-option *ngFor="let quimico of quimicoLista" [value]="quimico">{{quimico.descricao}}</nb-option>
    </nb-select>
  </content>

  <footer class="wFooter flexAlignRowEndCenter flexGap3" class="wFooter">
    <button nbButton size="tiny" status="danger" (click)="closeWindow(false)" style="margin-left: auto">
      Cancela
    </button>
    <button nbButton size="tiny" status="primary" (click)="closeWindow(true)" [disabled]="!selectedItem">
      Confirma
    </button>
  </footer>

</app-dialog-template>
