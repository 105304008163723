<div *ngIf="budgetRule" class="detailBox flexColumn flexGap10" style="height: 100%;">

  <span class="defaultSmallTitle">{{budgetRule.description}}</span>
  <!-- Período da verba -->
  <div *ngIf="budgetRule.startDate" class="flexAlignRowStartCenter flexGap5 flexWrap">
    <div class="flexColumn">
      <span class="cmpLabel">Dt Início</span>
      <span class="cmpInputFont">{{budgetRule.startDate | date:'shortDate'}}</span>
      <!-- <app-t2-date t2IdCmp="dataInicio" [formGroup]="formGroupRule" readOnly="true" /> -->
    </div>
    <div class="flexColumn">
      <span class="cmpLabel">Dt Fim</span>
      <span class="cmpInputFont">{{budgetRule.endDate | date:'shortDate'}}</span>
      <!-- <app-t2-date t2IdCmp="dataFim" [formGroup]="formGroupRule" readOnly="true" /> -->
    </div>
  </div>

  <!-- Valor da verba -->
  <div class="flexAlignRowStartCenter flexGap3 flexWrap">
    <span class="defaultSmallTitle">Valor da verba</span>
    <app-t2-input-float t2IdCmp="valorVerba" [formGroup]="formGroupRule" class="input" />
  </div>

  <!-- Regras de excesso -->
  <div>
    <div class="flexAlignRowStartCenter flexGap5">
      <span class="defaultSmallTitle">Regras para excedente de verba</span>
      <div class="insertButton">
        <nb-icon (click)="addExcessRule()" icon="plus-outline"></nb-icon>
      </div>
    </div>

    <div class="flexRow flexGap5 flexWrap">
      <div *ngFor="let excessRule of budgetRule.excessRuleList" class="detailBox flexRow"
        style="width: 360px; position: relative;">
        <div>
          <div class="flexAlignRowStartCenter flexGap3 flexWrap">
            <span class="defaultSmallTitle">Se a verba for excedida em até</span>
            <app-t2-input-float [t2IdCmp]="excessRule.budgetPolicyItemExcessRuleId + '_valorExcedente'"
              [formGroup]="formGroupExRule" class="input" />

            <span class="defaultSmallTitle">serão requeridas no mínimo</span>
            <app-t2-input-float [t2IdCmp]="excessRule.budgetPolicyItemExcessRuleId + '_qtdMinAprovacoes'"
              [formGroup]="formGroupExRule" class="input" />
            <span class="defaultSmallTitle">aprovações</span>
          </div>

          <div class="detailBox" style="display: flex; flex-direction: column; gap: 5px;">
            <div class="detailBoxTitle flexAlignRowSpaceBetweenStart flexGap5">
              <span>Aprovador</span>

              <div class="flexRow flexGap5">
                <span>Obrigatório</span>
                <div class="flexAlignRowEndCenter flexGap5">
                  <nb-icon class="insertButton" (click)="addApprover(excessRule)" icon="plus-outline"></nb-icon>
                </div>
              </div>
            </div>

            <div *ngFor="let approver of excessRule.approverList " class="flexRow">
              <div class="flexAlignRowSpaceBetweenCenter flexLength1">
                <div>
                  <span class="defaultText">{{approver.name}}</span>
                </div>
                <div style="display: flex; gap: 20px; align-items: center;">
                  <app-t2-check-box [t2IdCmp]="approver.budgetPolicyItemRuleExcessApproverId + '_obrigatorio'"
                    [formGroup]="formGroupExRuleApprov" />
                  <nb-icon class="deleteButton" (click)="deleteApprover(excessRule, approver)"
                    icon="close-outline"></nb-icon>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style="position: absolute; right: 3px;">
          <nb-icon class="deleteButton" *ngIf="budgetRule.excessRuleList.length > 1"
            (click)="deleteExcessRule(excessRule)" icon="close-outline"></nb-icon>
        </div>
      </div>
    </div>
  </div>
</div>