import { Pipe, PipeTransform } from '@angular/core';
import { CharacteristicInfo } from "./model/characteristic-info";

@Pipe({
  name: 'filterCharacProcess'
})
export class FilterCharacProcessPipe implements PipeTransform {

  transform(items: Array<CharacteristicInfo>, id_especif_componente_prc: string): Array<CharacteristicInfo> {
    if (!items || !id_especif_componente_prc) {
      return items;
    }

    return items.filter(charac => charac.id_especif_componente_prc == id_especif_componente_prc || !charac.id_especif_componente_prc);
  }

}
