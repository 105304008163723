import { AfterContentInit, AfterViewInit, Component, ComponentRef, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { AbstractControl, FormGroup } from "@angular/forms";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/Operators";
import { T2CheckBoxComponent } from "../../ui/t2-check-box/t2-check-box.component";
import { ViewTemplateElement } from "../model/view-template-element";

@Component({
  selector: 'app-view-component',
  templateUrl: './view-component.component.html',
  styleUrls: ['./view-component.component.scss']
})
export class ViewComponentComponent implements OnInit, AfterViewInit, OnDestroy, AfterContentInit {
  private cmpRef: ComponentRef<any>;
  private unsubscribe = new Subject<void>();
  public visible: boolean = true;
  public requiredCss;

  @ViewChild('cmp') cmp: TemplateRef<any>;

  @Input() component: ViewTemplateElement;
  @Input() FormGroup: FormGroup;
  @Output() cmpReference = new EventEmitter<any>();

  constructor() { 
  }

  ngAfterContentInit(): void {
    let validator = this.FormGroup?.controls[this.component.cmpName]?.validator ? this.FormGroup?.controls[this.component.cmpName]?.validator({} as AbstractControl) : null

    this.requiredCss = { 'required-field': validator && validator['required'] && this.component.cmpType != T2CheckBoxComponent };
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
  
  ngAfterViewInit(): void {
    if (this.cmpRef.instance["visible"] != undefined) {
      this.visible = this.cmpRef.instance.visible;

      if (this.cmpRef.instance["visibleChanged"] != undefined) {
        this.cmpRef.instance.visibleChanged.asObservable()
          .pipe(takeUntil(this.unsubscribe))
          .subscribe(value => {
            this.visible = value;
          });
      }
    }
  }

  ngOnInit(): void {
  }

  sendCmpRef(cmpRef: ComponentRef<any>) {
    this.cmpReference.emit(cmpRef);
    this.cmpRef = cmpRef;
  }
}
