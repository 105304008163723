<div [formGroup]="formGroup">
  <div *ngIf="!readOnly">
    <input nbInput fieldSize="small" [id]="t2IdCmp" [hidden]="!visible" [ngStyle]="ngStyle" [placeholder]="placeHolder"
      [formControlName]="t2IdCmp" [mask]="mask" [leadZeroDateTime]="true" [dropSpecialCharacters]="false" fullWidth>
  </div>
</div>

<div *ngIf="readOnly && visible" class="T2CmpReadOnly">
  {{formGroup.controls[t2IdCmp].value | mask: mask}}
</div>
