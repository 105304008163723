import { RegistrationFix } from "./model/registrationFix";
import { DialogRegistrationFixComponent } from "./dialog-registration-fix/dialog-registration-fix.component";
import { Component, OnInit, OnDestroy, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NbDateService, NbDialogService } from "@nebular/theme";
import { take } from "rxjs/Operators";
import { FormTemplateComponent } from "src/app/core/cmp/form-template/form-template.component";
import { T2gridComponent } from "src/app/core/cmp/t2grid/t2grid.component";
import { T2HttpClientService } from "src/app/core/http/t2httpClient.service";
import { T2SecurityService } from "src/app/core/security/t2security.service";
import { WidgetSelectionComponent } from "src/app/core/widget/widget-selection/widget-selection.component";
import { Subscription } from "rxjs";
import { ActivatedRoute, Router } from "@angular/router";

@Component({
  selector: "app-registration-fix",
  templateUrl: "./registration-fix.component.html",
  styleUrls: ["./registration-fix.component.scss"],
})
export class RegistrationFixComponent implements OnInit, OnDestroy {

  private pathParams$: Subscription = null;
  public loaded = false;
  public loadingData = false;
  public button = false;
  public formGroup: FormGroup;
  regList: Array<RegistrationFix> = [];

  idRegistration = null;
  id_device: string;
  // dateStartControl = new FormControl("", Validators.required);
  // dateEndControl = new FormControl("", Validators.required);

  @ViewChild("formTemplate", { static: true }) formTemplate: FormTemplateComponent;
  @ViewChild("gridReg", { static: true }) gridReg: T2gridComponent;
  @ViewChild("deviceComponent", { static: true }) deviceComponent: WidgetSelectionComponent;

  constructor(
    private formBuilder: FormBuilder,
    public router: Router,
    private route: ActivatedRoute,
    private sec: T2SecurityService,
    private httpClient: T2HttpClientService,
    private dialogService: NbDialogService,
    protected dateService: NbDateService<Date>,
  ) {
    this.formGroup = this.formBuilder.group({}, { updateOn: 'blur' });
    this.formGroup.addControl('startDate', this.formBuilder.control(this.dateService.today(), Validators.required));
    this.formGroup.addControl('endDate', this.formBuilder.control(this.dateService.today(), Validators.required));
  }

  ngOnInit(): void {
    this.sec
      .accessLoaded()
      .pipe(take(1))
      .subscribe(() => {
        if (
          this.formTemplate.validateAccess(["zD20180510H130110860R000000014"], 'full')
        ) {
          this.deviceComponent.widget = {
            id_techprodWidget: "2",
            positionX: 0,
            positionY: 0,
            id_techprodWidget_panel: null,
            widgetType: null,
          };

          this.loaded = true;

          this.pathParams$ = this.route.params.subscribe(params => {
            const t2Route = this.httpClient.getT2RouteFromSnapshot(this.route.snapshot);
            try {
              if (t2Route.queryParams.has("startDate")) {
                const startDate = this.dateService.parse(t2Route.queryParams.get("startDate"), "yyyy-MM-dd");
                this.formGroup.controls['startDate'].setValue(startDate);
              }

            } catch (error) {
              // Nothing to do
            }

            try {
              if (t2Route.queryParams.has("endDate")) {
                const endDate = this.dateService.parse(t2Route.queryParams.get("endDate"), "yyyy-MM-dd");
                this.formGroup.controls["endDate"].setValue(endDate);
              }

            } catch (error) {
              // Nothing to do
            }

            if (t2Route.queryParams.get("id_device")) {
              this.id_device = t2Route.queryParams.get("id_device");
            }
          });

          this.gridReg.component = this;

          this.setGridColumns(null);
        }
      });
  }

  ngOnDestroy(): void {
    if (this.pathParams$) {
      this.pathParams$.unsubscribe();
    }
  }

  private setGridColumns(customColumns: any[]): void {
    const fixedColumns = [
      { headerName: "Início", field: "startDate", type: ["dateTimeColumn"] },
      { headerName: "Fim", field: "endDate", type: ["dateTimeColumn"] },
      { headerName: "Operação", field: "operation" },
      { headerName: "Operador", field: "operator" },
      { headerName: "OP", field: "opCode" },
      { headerName: "Cliente", field: "customer" },
      { headerName: "Produto", field: "opDescription" },
      { headerName: "Inserido por", field: "insertUser" },
      { headerName: "Alterado por", field: "updateUser" },
      { headerName: "Tipo de registro", field: "registrationType" }
    ];

    this.gridReg.setGridColumnDefs(fixedColumns);
  }

  public getListRegistrations() {

    if (this.loadingData) return;

    this.regList = [];

    this.gridReg.setGridData(null, null);
    this.button = false;
    this.loadingData = true;


    this.router.navigate(
      [],
      {
        relativeTo: this.route,
        queryParams: {
          startDate: this.dateService.format(this.formGroup.controls['startDate'].value, 'yyyy-MM-dd'),
          endDate: this.dateService.format(this.formGroup.controls['endDate'].value, 'yyyy-MM-dd'),
          id_device: this.id_device,
        },
        queryParamsHandling: "merge",
      });

    const date = this.formGroup.controls['startDate'].value;
    const endDate = new Date(this.formGroup.controls['startDate'].value.getTime() + 1000 * 60 * 60 * 24).toDateString();
    const queryParams = new Map<string, string>();
    queryParams.set("date", date);
    queryParams.set("endDate", endDate);

    this.httpClient
      .get(
        `production.registrationFix/${this.id_device}/registrationsByDate`,
        queryParams
      )
      .pipe(take(1))
      .subscribe(
        (resp) => {
          this.loadingData = false;

          if (resp.reg) {
            resp.reg.forEach((item) => (item.ID = item.id_registration));
            this.regList = resp.reg;
            this.gridReg.setGridData(this.regList, null);
            this.gridReg.autoSizeAllColumns(false);
          }
        },
        (error) => {
          this.loadingData = false;
        }
      );
  }

  deviceListChange(event: any) {
    this.id_device = event.data.id;
  }

  rowDoubleClick(event: any) {
    if (event.data != null) {
      const dialogRegistrationFix = this.dialogService.open(
        DialogRegistrationFixComponent,
        {
          context: {
            title: "Detalhe do Registro",
            id_registration: event.data.id_registration,
          },
        }
      );

      dialogRegistrationFix.onClose.subscribe((resp) => { });
    }
  }

  rowSelected(event: any) {
    this.gridReg.setSelected(event.data.ID);
    this.button = true;
    this.idRegistration = event.data.id_registration;
  }

  editClick() {
    const dialogRegistrationFix = this.dialogService.open(
      DialogRegistrationFixComponent,
      {
        context: {
          title: "Detalhe do Registro",
          id_registration: this.idRegistration,
        },
      }
    );

    dialogRegistrationFix.onClose.subscribe((resp) => { });
  }
}
