import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { T2HttpClientService } from "../../http/t2httpClient.service";
import { catchError, map, take } from "rxjs/Operators";
import { MediaType } from "../../http/t2RequestInfo";

@Injectable({
  providedIn: 'root'
})
export class T2DsAttachmentsService {

  constructor(private http: T2HttpClientService) { }

  getAttachList(datasetName: string, parentID: string): Observable<any> {
    return this.http.get(`core.dataset.attach/${datasetName}/${parentID}/list`, null)
      .pipe(
        take(1),
        map(resp => {
          let attachs = resp.attachs || [];
          if (!Array.isArray(attachs)) attachs = [attachs];

          return { id_dataset: resp.id_dataset, datasetName: resp.datasetName, attachs };
        }));
  }

  download(datasetName: string, parentId: string, id: string): Observable<any> {

    return this.http.getBlob(`core.dataset.attach/${datasetName}/${parentId}/${id}`, null, null,  MediaType.OCTET)
      .pipe(
        catchError(error => {
          throw new Error(error);
        })
      );


  }

  upload(datasetName: string, parentID: string, file: File): Observable<any> {

    const formData = new FormData();
    formData.append("file", file, file.name);
    return this.http.post(`core.dataset.attach/${datasetName}/${parentID}`, null, formData)
      .pipe(
        take(1),
        map(resp => {
          return { ...resp.attachs };
        })
      );
  }

  delete(datasetName: string, parentId: string, id: string): Observable<{ datasetName: string, parentId: string, id: string, deleted: boolean }> {
    return this.http.delete(`core.dataset.attach/${datasetName}/${parentId}/${id}`, null)
      .pipe(
        take(1),
        map(resp => {
          return { datasetName, parentId, id, deleted: true };
        }));
  }
}
