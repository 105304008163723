import { CilSmallBoxRendererComponent } from './../cil-small-box-renderer/cil-small-box-renderer.component';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormTemplateComponent } from "src/app/core/cmp/form-template/form-template.component";
import { T2SecurityService } from "src/app/core/security/t2security.service";
import { take } from "rxjs/Operators";
import { T2gridComponent } from "src/app/core/cmp/t2grid/t2grid.component";
import { T2HttpClientService } from "src/app/core/http/t2httpClient.service";
import { CilindroCor } from "../model/cilindro-cor";
import { Cilindro } from "../model/cilindro";
import { AgPromise, CellClickedEvent, CellValueChangedEvent, IRowNode, RowNode } from "ag-grid-community";
import { EditCylinderComponent } from "./edit-cylinder/edit-cylinder.component";
import { NbDialogService } from "@nebular/theme";
import { CilindroProgramacao } from "../model/cilindro-programacao";
import { T2MessageService } from "src/app/core/t2-message.service";
import { EngraveInvoiceComponent } from "./engrave-invoice/engrave-invoice.component";
import { EngraveMotiveComponent } from "./engrave-motive/engrave-motive.component";
import { ScheduledProdOrderComponent } from "./scheduled-prod-order/scheduled-prod-order.component";
import { AddCylinderComponent } from "./add-cylinder/add-cylinder.component";
import { CilStatusRendererComponent } from "../cil-status-renderer/cil-status-renderer.component";
import { CilEpRendererComponent } from "../cil-ep-renderer/cil-ep-renderer.component";
import { SupplierComponent } from "./supplier/supplier.component";
import { T2AccessItem, T2AccessItemDatasetActionType } from "src/app/core/security/model/t2accessItem";
import { Observable } from "rxjs";
import { ActionService } from "src/app/core/action/action.service";
import { WarningRendererComponent } from "src/app/core/warning/warning-renderer/warning-renderer.component";
import { WarningService } from "src/app/core/warning/warning.service";
import { WarningComponent } from "src/app/core/warning/warning/warning.component";
import { Warning } from "src/app/core/warning/model/warning";
import { ComplementaryField } from "../../utils/model/complementary-field";
import { CylinderSwapComponent } from "./cylinder-swap/cylinder-swap.component";

@Component({
  selector: 'app-cylinder-scheduler',
  templateUrl: './cylinder-scheduler.component.html',
  styleUrls: ['./cylinder-scheduler.component.scss']
})
export class CylinderSchedulerComponent implements OnInit {

  @ViewChild("formTemplate", { static: true }) formTemplate: FormTemplateComponent;
  @ViewChild("gridTask", { static: true }) gridTask: T2gridComponent;
  @ViewChild("gridColor", { static: true }) gridColor: T2gridComponent;

  private actionList: Array<T2AccessItem>;
  public gridContextComponent = { contextMenu: [] };

  public loaded = false;
  public loadingData = false;
  public cylinderList: Cilindro[] = [];
  public supplierList: any[] = [];
  public motiveList: any[] = [];
  public cilGravar: Array<CilindroCor> = [];
  public cilRevisar: Array<CilindroCor> = [];
  public genEngrave = false;
  public lockScreen = false;
  private taskSelected: CilindroProgramacao;

  showRevisar = false;
  showGravar = false;
  showRecromo = false;
  showOK = false;

  taskGridComponents = {
    cilRenderer: CilSmallBoxRendererComponent,
    warningRenderer: WarningRendererComponent
  };

  colorGridComponents = {
    cilStatusRenderer: CilStatusRendererComponent,
    CilEpRenderer: CilEpRendererComponent,
  };

  constructor(
    private sec: T2SecurityService,
    private httpClient: T2HttpClientService,
    private dialogService: NbDialogService,
    private messageService: T2MessageService,
    private actionService: ActionService,
    private warningService: WarningService
  ) { }

  ngOnInit(): void {
    this.sec.accessLoaded()
      .pipe(
        take(1)
      ).subscribe(() => {
        if (this.formTemplate.validateAccess(['zD20190703H173101503R000000005'], 'full')) {
          this.loadCylinderList.bind(this)().then(() => {
            this.loadCylinderData();
            this.loadSupplierList();
            this.loadActionProgrammingGrid();
          });
          this.loadMotiveList();
        }
      });
  }

  private loadCylinderData() {
    this.gridTask.t2GridOptions.onCellClicked = this.onGridTaskCellClicked.bind(this);
    this.gridColor.t2GridOptions.onCellValueChanged = this.gdCoresCellValueChanged.bind(this);

    return new AgPromise((resolve, reject) => {
      this.gridTask.t2GridOptions.columnTypes["cilindro"] = { cellRenderer: "cilRenderer", width: 115 };
      this.gridTask.setGridColumnDefs([
        { checkboxSelection: true, lockPosition: true, pinned: "left", valueGetter: "node.rowIndex + 1", width: 45, suppressNavigable: true },
        { headerName: "OP", field: "prodOrder", width: 100, colKey: "prodOrder" },
        { headerName: "Situação cilindros", field: "cilindro", type: ["cilindro"] },
        {
          headerName: "Avisos", field: "warningList",
          cellRenderer: "warningRenderer", width: 130,
          valueGetter: (params) => {
            let text: string = null, finished: number = 0, late: number = 0, today: number = 0, onTime: number = 0, todayDate: Date = new Date();
            todayDate.setHours(0, 0, 0, 0);

            if (params.data.warningList) {
              params.data.warningList.forEach(warning => {
                if (warning.completionDate) {
                  finished++;
                } else if (warning.maxDate?.getTime() < todayDate.getTime()) {
                  late++;
                } else if (warning.maxDate?.getTime() == todayDate.getTime()) {
                  today++;
                } else {
                  onTime++;
                }
              });

              text = `A${late} | H${today} | P${onTime} | F${finished}`;
            }

            return text;
          }
        },
        { headerName: "Programação", field: "planDate", type: ["dateTimeColumn"] },
        { headerName: "Fornecedor", field: "prodOrderSupplier" },
        { headerName: "Dispositivo", field: "deviceName" },
        { headerName: "Tarefa", field: "taskDescription" },
        { headerName: "Produtos", field: "prodOrderTitle" },
        { headerName: "Cliente", field: "customer" },
        { headerName: "Pedidos", field: "salesOrder" },
      ]);

      this.gridColor.t2GridOptions.columnTypes["cilStatusColumn"] = { cellRenderer: "cilStatusRenderer" };
      this.gridColor.t2GridOptions.columnTypes["epColumn"] = { cellRenderer: "CilEpRenderer" };

      this.gridColor.setGridColumnDefs([
        { headerCheckboxSelection: true, checkboxSelection: true, lockPosition: true, pinned: "left", valueGetter: "node.rowIndex + 1", width: 45, suppressNavigable: true },
        { headerName: "Sequencia", field: "seq", sortable: true, hide: true, suppressColumnsToolPanel: true },
        { headerName: "Enviar na NF", field: "sendToInvoice", type: ["checkColumn"] },
        { headerName: "Químico", field: "color", width: 150 },
        { headerName: "Detalhe", field: "detail" },
        { headerName: "Cilindro", field: "cylinderCode", width: 100 },
        { headerName: "Local", field: "local" },
        { headerName: "Status", field: "status", width: 200, type: ["cilStatusColumn"] },
        { headerName: "Fornecedor", field: "supplier" },
        { headerName: "NF Gravação", field: "invoice" },
        { headerName: "Gramatura", field: "gram", width: 70 },
        { headerName: "Área aplic", field: "area", width: 70 },
        { headerName: "Gram Aplic", field: "aplicGram", width: 70 },
        {
          headerName: "Produto Gravado (EP)", valueGetter: function (params) {
            let result = null;

            if (params.data["id_item_cylinder"]) {
              let cyl = params.context.component.cylinderList.filter(cyl => {
                return cyl.id_item == params.data["id_item_cylinder"]
              });

              if (cyl.length) {
                result = cyl[0].descricaoEP ? cyl[0].descricaoEP + " (" + cyl[0].versaoEP + ")" : null;
              }
            }

            return result;
          }, type: ["epColumn"]
        },
        {
          headerName: "Cor Gravada", valueGetter: function (params) {
            let result = null;
            if (params.data["id_item_cylinder"]) {
              let cyl = params.context.component.cylinderList.filter(cyl => {
                return cyl.id_item == params.data["id_item_cylinder"]
              });

              if (cyl.length) {
                result = cyl[0].corEP ? cyl[0].corEP : null;
              }
            }

            return result;
          }
        }
      ]);

      this.httpClient.get(`production.CylinderScheduler/printingProgramming`, null)
        .pipe(take(1))
        .subscribe(resp => {
          if (!Array.isArray(resp.progList.prog)) {
            resp.progList.prog = [resp.progList.prog];
          }


          const progList: Array<CilindroProgramacao> = resp.progList.prog;
          progList.forEach(prog => {
            prog["ID"] = prog.id_task_plan;
            if (!Array.isArray(prog.colors)) {
              prog.colors = [prog.colors];
            }

            prog.colors.forEach((cor, index, array) => {
              if (cor) {
                cor["ID"] = cor.id_ordemProd_cilindro ? cor.id_ordemProd_cilindro : index + 1;

                if ((cor.status == "GRAVAR" || cor.status == "RECROMO") && !cor.invoice) {
                  cor.sendToInvoice = true;
                }
              }
            });

            if (prog["complementaryFieldList"]) {
              prog.complementaryFields = new Array();

              if (!Array.isArray(prog["complementaryFieldList"])) {
                prog["complementaryFieldList"] = [prog["complementaryFieldList"]];
              }

              prog["complementaryFieldList"].forEach(compField => {
                if (compField.name != "id_task_plan") {
                  let value;
                  if (compField.value != undefined && compField.value != null) {
                    switch (compField.type) {
                      case "STRING": {
                        value = new String(compField.value.$);
                        break;
                      }
                      case "INT":
                      case "FLOAT": {
                        value = new Number(compField.value.$).valueOf();
                        break;
                      }
                      case "BOOLEAN": {
                        value = new Boolean(compField.value.$).valueOf();
                        break;
                      }
                      case "DATE":
                      case "DATE_TIME": {
                        value = new Date(compField.value.$);
                        break;
                      }
                      default: {
                        value = compField.value.$;
                        break;
                      }
                    }
                  }

                  prog.complementaryFields.push({ name: compField.name, value: value, type: compField.type });
                }
              });
            }

            if (prog.warningList) {
              let wList = new Array<Warning>();
              if (!Array.isArray(prog.warningList)) {
                prog.warningList = [prog.warningList];
              }

              prog.warningList.forEach(w => {
                wList.push(this.warningService.transformServerResponse(w));
              });

              prog.warningList = wList;
            }
          });

          let tpCompField = progList.find(tp => tp.complementaryFields?.length > 0);

          if (tpCompField) {
            this.showComplementaryFields(tpCompField.complementaryFields);
          }

          this.gridTask.setGridData(progList, null);

          if (!this.gridTask.loadColumnGridState()) this.gridTask.autoSizeAllColumns(false);

          if (progList.length) {
            this.gridTask.onGDRowClicked(this.gridTask.getRowNode(progList[0]["ID"]));
          }

          this.loaded = true;
          resolve("success");
        }, error => {
          reject("error");
        });

      this.loaded = true;
    });
  }

  private showComplementaryFields(compFields: Array<ComplementaryField>) {
    let columnDef = this.gridTask.t2GridOptions.columnDefs;

    const fieldTypeFunc = (field) => {
      if (["INT", "FLOAT"].includes(field.type)) return "numericColumn";
      if (field.type == "DATE") return "dateColumn";
      if (field.type == "DATE_TIME") return "dateTimeColumn";
      if (field.type == "BOOLEAN") return "booleanColumn";

      return null;
    };

    compFields.forEach(field => {
      columnDef.push({
        headerName: field.name,
        field: field.name,
        getQuickFilterText: (params) => "",
        valueGetter: params => {
          return params.data.complementaryFields?.find(cf => cf.name == field.name)?.value;
        },
        type: fieldTypeFunc(field)
      })
    })

    this.gridTask.setGridColumnDefs(columnDef);
  }

  private onGridTaskCellClicked(event: CellClickedEvent) {
    if (event.colDef.field == "warningList" && event.data.warningList && event.data.warningList.length > 0) {
      let dialog = this.dialogService.open(WarningComponent, {
        context: {
          warningList: event.data.warningList
        }
      });

      dialog.onClose.pipe(take(1)).subscribe(() => {
        this.gridTask.redrawRows({ rowNodes: [this.gridTask.getFocusedNode()] });
      })
    }
  }

  loadActionProgrammingGrid() {
    this.sec.getDatasetActions("ord_ordemprod")
      .subscribe(actionList => {
        this.actionList = actionList;
        this.gridContextComponent.contextMenu = [];

        this.actionList.forEach((action: T2AccessItem) => {

          if (action.id == '{1E34E2E6-D354-42DF-BE1C-69B9607FDDAE}') {
            this.gridContextComponent.contextMenu.push({
              name: T2AccessItemDatasetActionType.DSACTION_EDIT === action.datasetActionType ? action.datasetGroup + " - " + action.datasetActionDescription : action.datasetActionDescription,
              requiresId: true,
              action: () => { this.executeDatasetAction(action, "OP") },
            });
          }
        });
      });
  }

  private executeDatasetAction(action: T2AccessItem, tipoDataSet: string) {
    const params = new Map<string, string>();
    params.set("datasetName", action.datasetName);

    const rows = new Array(...this.gridTask.getRowsSelected());
    if (!rows.length && this.gridTask.getFocusedRow()) {
      rows.push(this.gridTask.getFocusedRow());
    }

    rows.forEach((row) => {

      if (tipoDataSet === "OP") {
        params.set("id", row.id_ordemProd);
      }

      const obs$: Observable<any> = this.actionService.executeAction(action, params, 'newWindow');
      if (obs$) {
        obs$.pipe(take(1)).subscribe(resp => this.messageService.showToast("Ação realizada com sucesso", action.datasetActionDescription, "success"));
      }
    });
  }

  public getSelectedProdOrder(): string {
    const prods = this.gridTask.getRowsSelected()?.map(row => row.prodOrder).join(", ");

    if (prods) {
      return "Seleção " + prods;
    }

    return null;
  }

  public onGDTaskClick(event) {
    setTimeout(() => {
      this.setGDColorData(this.gridTask.getFocusedRow());
      this.taskSelected = this.gridTask.getFocusedRow();
    }, 5);
  }

  private setGDColorData(task: any): void {

    if (task?.colors) {
      this.gridColor.setGridData(task?.colors, null);
    } else {
      this.gridColor.setGridData([], null);
    }

    this.gridColor.autoSizeAllColumns(false);
    this.gridColor.clearRowFocus();
  }

  public loadCylinderList() {
    return new AgPromise((resolve, reject) => {
      this.httpClient.get("production.CylinderScheduler/cylinderList", null)
        .pipe(take(1))
        .subscribe(resp => {
          if (resp.cylList) {
            // Se vir UM ITEM, nao virá como array
            if (!Array.isArray(resp.cylList)) {
              resp.cylList = [resp.cylList];
            }

            this.cylinderList = resp.cylList;

            this.cylinderList.forEach((cyl, index, array) => {
              cyl["ID"] = index;
            });

            this.gridColor.t2GridOptions.context.component = { cylinderList: this.cylinderList }
          }

          resolve("success");
        }, error => {
          reject("error");
        });
    });
  }

  private loadMotiveList() {
    this.httpClient.get("production.CylinderScheduler/motiveList", null)
      .pipe(take(1))
      .subscribe(resp => {
        // Se vir UM ITEM, nao virá como array
        if (!Array.isArray(resp.motiveList)) {
          resp.motiveList = [resp.motiveList];
        }

        this.motiveList = resp.motiveList;

        this.motiveList.forEach((motive, index, array) => {
          motive["id"] = index;
        })
      })
  }


  public onGDColorDoubleClick(event) {
    if (event.data != null) {
      this.openCylEditDialog();
    }
  }

  editarCilindroStatus(novoStatus: string): void {

    let colorRows: RowNode[] = this.gridColor.getRowsSelected();
    if ((!colorRows || !colorRows.length)) {
      return;
    }

    const progRow = this.gridTask.getFocusedNode()
    const colorRow: any = colorRows[0];
    const colorInvoice = colorRows.filter((item: any) => item.invoice != null && item.invoice != undefined);

    let dados = {
      id_item: null,
      id_ordemprod: progRow.data.id_ordemProd,
      id_especificacao: progRow.data.id_productSpecification,
      id_especif_carac_quimico_item: null,
      cor: null,
      statusAnterior: null,
      novoStatus: novoStatus
    }

    if (["GRAVAR", "RECROMO"].includes(colorRow.status) && novoStatus == "REVISAR" && colorInvoice.length > 0) {

      const colorNames = colorInvoice.map((item: any) => `${item.cylinderCode} (${item.color})`).join(", ");

      this.messageService.showDialog({
        context: {
          title: "Cilindros já estão em uma nota fiscal para gravação",
          topMessage: "Deseja alterar a situação dos cilindros?",
          message: "Se continuar, os cilindros ficarão com status REVISAR, os cilindros anteriores deverão ser retirados da nota fiscal manualmente.",
          message3: `Cilindros: ${colorNames}`,
          actions: [{ description: "Sim" }, { description: "Não", status: "basic" }]
        }
      }).onClose
        .pipe(take(1))
        .subscribe(resp => {
          if (resp != "Sim")
            return;

          colorRows.forEach((item: any) => {
            const itemRow: any = this.gridColor.getRowNode(item.ID);

            dados = { ...dados };
            dados.id_item = itemRow.data.id_item_cylinder;
            dados.id_especif_carac_quimico_item = itemRow.data.id_especif_carac_quimico_item;
            dados.cor = itemRow.data.color;
            dados.statusAnterior = itemRow.data.status;

            const cor = new CilindroCor();
            cor.id_ordemProd_cilindro = itemRow.data.id_ordemProd_cilindro;
            cor.id_item_cylinder = itemRow.data.id_item_cylinder;
            cor.status = itemRow.data.status;
            cor.id_especif_carac_quimico_item = itemRow.data.id_especif_carac_quimico_item;
            cor.cylinderCode = itemRow.data.code;
            cor.status = "REVISAR";

            itemRow.data.updating = true;
            itemRow.data.sendToInvoice = false;
            this.gridColor.redrawRows({ rowNodes: [itemRow] });
            this.updateCilindroCode(progRow, [cor]).then(result => {
              if (result) {
                this.updateCilindroStatus(novoStatus, progRow, [itemRow]);
                this.insertCylinderHistoric([dados]).subscribe(() => {});
                itemRow.data.updating = false;
              }
            });
          }, error => {
          });
        });
    } else if (["GRAVAR", "RECROMO"].includes(colorRow.status) && novoStatus === "OK") {
      // OK Alteracao em massa

      const msgTitle = colorRows.length > 2 ? "Cilindros OK" : "Cilindro OK";
      const msgBody = colorRows.length > 2 ? `Deseja alterar a arte gravada nos cilindros selecionados para "${progRow.data.prodOrderTitle}"?`
        : `Deseja alterar a arte gravada do cilindro ${colorRow.cylinderCode} para "${progRow.data.prodOrderTitle}" ?`;

      this.messageService.showDialog({
        context: {
          title: msgTitle,
          message: msgBody,
          actions: [{ description: "Sim" }, { description: "Não", status: "basic" }]
        }
      }).onClose
        .pipe(take(1))
        .subscribe(resp => {
          if (resp != "Sim")
            return null;


          let dadosList = [];
          let itemRowList = [];
          colorRows.forEach((item: any) => {
            const itemRow = this.gridColor.getRowNode(item.ID);

            dados = { ...dados };
            dados.id_item = itemRow.data.id_item_cylinder;
            dados.id_especif_carac_quimico_item = itemRow.data.id_especif_carac_quimico_item;
            dados.cor = itemRow.data.color;
            dados.statusAnterior = itemRow.data.status;
            dadosList.push(dados);

            itemRow.data.updating = true;
            itemRow.data.sendToInvoice = true;
            this.gridColor.redrawRows({ rowNodes: [itemRow] });
            itemRowList.push(itemRow);
          });

          this.httpClient.post("production.CylinderScheduler/confirmCylEngrave", null, itemRowList.map(it => it.data.id_ordemProd_cilindro))
            .pipe(take(1))
            .subscribe(resp => {
              this.loadCylinderList().then(() => {
                itemRowList.forEach(itemRow => {
                  itemRow.data.status = novoStatus;
                  itemRow.data.updating = false;
                })

                this.gridColor.redrawRows({ rowNodes: itemRowList });
              });
            }, error => {
              itemRowList.forEach(itemRow => {
                itemRow.data.updating = false;
              });

              this.gridColor.redrawRows({ rowNodes: itemRowList });
            });

          this.updateCilindroStatus(novoStatus, progRow, itemRowList);
          this.insertCylinderHistoric(dadosList).subscribe(() => { });;
        });

    } else if (["REVISAR"].includes(colorRow.status) && ["GRAVAR", "RECROMO"].includes(novoStatus)) {
      // OK Alteracao em massa

      const dlg = this.dialogService.open(EngraveMotiveComponent, {
        context: { motiveList: this.motiveList },
        autoFocus: true, closeOnEsc: true, hasBackdrop: true
      }).onClose
        .pipe(take(1))
        .subscribe(resp => {
          if (!resp?.confirm) {
            this.messageService.showToastError("É obrigatório informar o motivo !!");
            return;
          }

          dados["motivo"] = resp.motive;
          dados["obsMotivo"] = resp.motiveObs;

          let itemRowList = [];
          let dadosList = [];

          colorRows.forEach((item: any) => {
            const itemRow = this.gridColor.getRowNode(item.ID);

            dados = { ...dados };
            dados.id_item = itemRow.data.id_item_cylinder;
            dados.id_especif_carac_quimico_item = itemRow.data.id_especif_carac_quimico_item;
            dados.cor = itemRow.data.color;
            dados.statusAnterior = itemRow.data.status;

            itemRow.data.sendToInvoice = true;

            dadosList.push(dados);
            itemRowList.push(itemRow);
          });

          this.updateCilindroStatus(novoStatus, progRow, itemRowList);
          this.insertCylinderHistoric(dadosList).subscribe(() => { });;
        });
    } else {
      // OK Alteracao em massa

      let itemRowList = [];
      let dadosList = [];

      if (["OK"].includes(colorRow.status) && ["GRAVAR", "RECROMO"].includes(novoStatus)) {
        this.dialogService.open(EngraveMotiveComponent, {
          context: { motiveList: this.motiveList },
          autoFocus: true, closeOnEsc: true, hasBackdrop: true
        }).onClose
          .pipe(take(1))
          .subscribe(resp => {
            if (!resp?.confirm) {
              this.messageService.showToastError("É obrigatório informar o motivo !!");
              return;
            }

            dados["motivo"] = resp.motive;
            dados["obsMotivo"] = resp.motiveObs;

            colorRows.forEach((item: any) => {
              const itemRow = this.gridColor.getRowNode(item.ID);
              dados = { ...dados };
              dados.id_item = itemRow.data.id_item_cylinder;
              dados.id_especif_carac_quimico_item = itemRow.data.id_especif_carac_quimico_item;
              dados.cor = itemRow.data.color;
              dados.statusAnterior = itemRow.data.status;

              this.mustSendToInvoice(itemRow, novoStatus, itemRow.data.status);

              dadosList.push(dados);
              itemRowList.push(itemRow);
            });

            this.updateCilindroStatus(novoStatus, progRow, itemRowList);
            this.insertCylinderHistoric(dadosList).subscribe(() => { });;
          });
      } else {
        colorRows.forEach((item: any) => {
          const itemRow = this.gridColor.getRowNode(item.ID);
          dados = { ...dados };
          dados.id_item = itemRow.data.id_item_cylinder;
          dados.id_especif_carac_quimico_item = itemRow.data.id_especif_carac_quimico_item;
          dados.cor = itemRow.data.color;
          dados.statusAnterior = itemRow.data.status;

          this.mustSendToInvoice(itemRow, novoStatus, itemRow.data.status);

          dadosList.push(dados);
          itemRowList.push(itemRow);
        });

        this.updateCilindroStatus(novoStatus, progRow, itemRowList);
        this.insertCylinderHistoric(dadosList).subscribe(() => { });;
      }
    }
  }

  private mustSendToInvoice(colorRow: any, newStatus: string, currentStatus: string): void {

    if (!["GRAVAR", "RECROMO"].includes(newStatus) && ["GRAVAR", "RECROMO"].includes(currentStatus)) {
      colorRow.sendToInvoice = false;
    } else if (["GRAVAR", "RECROMO"].includes(newStatus)) {
      colorRow.sendToInvoice = true;
    }
  }

  public openCylEditDialog() {
    if (!this.cylinderList.length) {
      return;
    }

    const rowNodeProg = this.gridTask.getFocusedNode();
    this.cylEditDialog(rowNodeProg);
  }

  private canSaveCylinderChange(id_ordemprod, id_cilindroList, id_especificacao, planDate) {
    return new AgPromise((resolve, reject) => {
      if (!id_cilindroList) {
        resolve(true);
        return;
      }

      let scheduledOPList: Array<CilindroProgramacao>;
      let params = new Map<string, string>();
      params.set("id_ordemprod", id_ordemprod);
      params.set("id_especificacao", id_especificacao);
      params.set("planDate", planDate);


      this.httpClient.post("production.CylinderScheduler/scheduledProdOrdWithDiffSpecif", params, id_cilindroList)
        .pipe(
          take(1)
        )
        .subscribe(resp => {
          if (!resp.progList || !resp.progList.prog) {
            resolve(true);
          } else {
            if (!Array.isArray(resp.progList.prog)) {
              resp.progList.prog = [resp.progList.prog];
            }

            const progList: Array<CilindroProgramacao> = resp.progList.prog;
            progList.forEach(prog => {
              prog["ID"] = prog.id_ordemProd;
            });

            if (progList.length > 0) {
              scheduledOPList = progList;

              const formScheduledOP = this.dialogService.open(ScheduledProdOrderComponent, {
                context: {
                  scheduledOPList: scheduledOPList
                },
                autoFocus: true, closeOnEsc: true, hasBackdrop: true
              });

              formScheduledOP.onClose
                .pipe(
                  take(1)
                ).subscribe(resultFormOP => {
                  // acao: 1 = Cancelar | 2 = Usar o cilindro apenas nessa OP | 3 = Usar o cilindro em todas as OPs
                  if (resultFormOP.acao && resultFormOP.acao > 1) {
                    if (resultFormOP.acao == 3) {
                      this.httpClient.post("production.CylinderScheduler/deleteScheduledProdOrdWithDiffSpecif", params, id_cilindroList)
                        .subscribe(resp => resolve(true), error => {
                          this.loadingData = false;
                          reject("error");
                        });
                    } else {
                      resolve(true);
                    }
                  } else {
                    this.loadingData = false;
                    resolve(false);
                  }
                });
            } else {
              resolve(true);
            }
          }
        }, error => {
          this.loadingData = false;
          reject("error");
        });
    });
  }

  private cylEditDialog(rowNodeProg: IRowNode) {

    const formEdit = this.dialogService.open(EditCylinderComponent, {
      context: {
        prodOrderColors: JSON.parse(JSON.stringify(rowNodeProg.data.colors)), // Dessa forma o array está sendo passado por valor e não por referência
        cylinderList: JSON.parse(JSON.stringify(this.cylinderList)),
        prodOrderId: rowNodeProg.data.id_ordemProd
      },
      autoFocus: true, closeOnEsc: true, hasBackdrop: true
    });
    formEdit.onClose
      .pipe(
        take(1)
      )
      .subscribe(async result => {
        if (result?.confirm) {
          this.loadingData = true;
          let id_cylinderList = result.prodOrderColors.filter(c => c.id_item_cylinder).map(c => c.id_item_cylinder).join(",");

          await this.canSaveCylinderChange(rowNodeProg.data.id_ordemProd, id_cylinderList,
            rowNodeProg.data.id_productSpecification, rowNodeProg.data.planDate)
            .then(canSave => {
              if (canSave) {
                this.persistCylinderChanges(rowNodeProg, result.prodOrderColors);
              }
            });
        };
      });
  }

  private persistCylinderChanges(rowNodeProg: IRowNode, cylinderList: Array<CilindroCor>) {
    let corList = new Array<CilindroCor>();
    let historicList = [];

    cylinderList.forEach(c => {
      // Copia dados para um novo objeto para preservar as informacoes originais (para restaurar em caso de erro)
      let corOriginal = rowNodeProg.data.colors.filter(cl => cl.id_ordemProd_cilindro == c.id_ordemProd_cilindro && cl.id_especif_carac_quimico_item == c.id_especif_carac_quimico_item)[0];
      let cor = new CilindroCor();
      cor.id_ordemProd_cilindro = corOriginal.id_ordemProd_cilindro;
      cor.id_item_cylinder = corOriginal.id_item_cylinder;
      cor.status = corOriginal.status;
      cor.id_especif_carac_quimico_item = corOriginal.id_especif_carac_quimico_item;

      if (!c.id_item_cylinder) {
        cor.id_item_cylinder = undefined;
        cor.cylinderCode = undefined;
        cor.status = undefined;
        cor.supplier = undefined;
        cor.invoice = undefined;
      } else if (corOriginal.id_item_cylinder !== c.id_item_cylinder) {
        cor.id_item_cylinder = c.id_item_cylinder;
        cor.cylinderCode = c.cylinderCode;
        cor.status = "REVISAR";
        cor.supplier = undefined;
        cor.invoice = undefined;

        let params = new Map<string, string>();
        params.set("id_ordemprod", rowNodeProg.data.id_ordemProd);
        params.set("id_cilindro", c.id_item_cylinder);
        params.set("id_especificacao", rowNodeProg.data.id_productSpecification);
        params.set("id_especif_carac_quimico_item", corOriginal.id_especif_carac_quimico_item);
        params.set("planDate", rowNodeProg.data.planDate);
        params.set("seqCorIgual", c.seqCorIgual.toString());
      }

      corList.push(cor);

      if (corOriginal.id_item_cylinder || cor.id_item_cylinder) {
        let dados = {
          id_item: corOriginal.id_item_cylinder ? corOriginal.id_item_cylinder : cor.id_item_cylinder,
          id_ordemprod: rowNodeProg.data.id_ordemProd,
          id_especificacao: rowNodeProg.data.id_productSpecification,
          id_especif_carac_quimico_item: corOriginal.id_especif_carac_quimico_item,
          cor: corOriginal.color
        }

        if (corOriginal.status) {
          dados["statusAnterior"] = corOriginal.status
        }

        if (cor.status) {
          dados["novoStatus"] = cor.status
        }

        historicList.push(dados);
      }
    });

    this.updateCilindroCode(rowNodeProg, corList).then(result => {
      if (result) {
        this.gridColor.redrawRows();

        this.insertCylinderHistoric(historicList).subscribe(() => { });;
        this.onGDTaskChanged(null);
      }

      this.loadingData = false;
    });
  }

  onGDTaskChanged(params) {
    this.cilGravar = [];
    this.cilRevisar = [];

    this.gridTask.getRowsSelected().forEach((row: CilindroProgramacao) => {
      if (row.colors) {
        row.colors.forEach((cor: CilindroCor) => {
          if (cor && (cor.status === "GRAVAR" || cor.status === "RECROMO") && (!cor.invoice || cor.sendToInvoice)) {
            this.cilGravar.push(cor);
          } else if (cor && cor.status === "REVISAR") {
            this.cilRevisar.push(cor);
          }
        });
      }
    });
  }

  onGDColorClick(event) {
    this.updateColorsButtons();
  }

  onGDColorChanged(params) {
    this.updateColorsButtons();
  }

  private updateColorsButtons(): void {
    setTimeout(() => {
      let rows = this.gridColor.getRowsSelected();

      const statuses = [...new Set(rows.map(item => item.status))];

      if (statuses.length == 1) {
        this.showOK = ![undefined, "OK"].includes(statuses[0]);
        this.showGravar = ![undefined, "GRAVAR", "RECROMO"].includes(statuses[0]);
        this.showRecromo = this.showGravar;
        this.showRevisar = ![undefined, "REVISAR"].includes(statuses[0]);
      } else {
        this.showOK = false;
        this.showGravar = false;
        this.showRecromo = false;
        this.showRevisar = false;
      }
    }, 5);

  }

  public updateCilindroCor(prog: CilindroProgramacao, corList: Array<CilindroCor>) {
    let ordProdCilindroList = [];

    corList.forEach(cor => {
      let ordProdCilindro = {
        id_ordemProd_cilindro: cor.id_ordemProd_cilindro,
        id_ordemProd: prog.id_ordemProd,
        id_item: cor.id_item_cylinder,
        situacao: cor.status,
        id_especif_carac_quimico_item: cor.id_especif_carac_quimico_item
      };

      ordProdCilindroList.push(ordProdCilindro);
    })

    return this.httpClient.post("production.CylinderScheduler/setColorCylinder", null, ordProdCilindroList);
  }

  private updateCilindroCode(rowNodeProg: IRowNode, corList: Array<CilindroCor>) {
    return new AgPromise((resolve, reject) => {
      this.updateCilindroCor(rowNodeProg.data, corList)
        .pipe(
          take(1)
        )
        .subscribe(resp => {
          if (!Array.isArray(resp.ordProdCilindroList)) {
            resp.ordProdCilindroList = [resp.ordProdCilindroList];
          }
          let ordProdCilindroList = resp.ordProdCilindroList;
          let itemsToRemove: Array<{ ID: any }> = [], itemsToAdd = [];

          ordProdCilindroList.forEach(r => {
            let rowNodeColor = this.gridColor.getAllRowNodes().filter(rn => rn.data["ID"] == r.id_ordemProd_cilindro || rn.data.id_especif_carac_quimico_item == r.id_especif_carac_quimico_item)[0];
            rowNodeColor.data.id_ordemProd_cilindro = r.id_ordemProd_cilindro;
            rowNodeColor.data.id_item_cylinder = r.id_item;
            rowNodeColor.data.cylinderCode = this.cylinderList.filter(c => c.id_item == r.id_item)[0]?.code;
            rowNodeColor.data.status = r.situacao;
            rowNodeColor.data.supplier = undefined;
            rowNodeColor.data.invoice = undefined;
            rowNodeColor.data.updating = false;

            if (rowNodeColor.id != r.id_ordemProd_cilindro) {
              itemsToRemove.push({ ID: rowNodeColor.data["ID"] });
              let newData = JSON.parse(JSON.stringify(rowNodeColor.data));
              if (r.id_ordemProd_cilindro) {
                newData["ID"] = r.id_ordemProd_cilindro;
              } else {
                newData["ID"] = rowNodeColor.rowIndex + 1;
              }

              itemsToAdd.push(newData);
            }
          });

          if (itemsToRemove.length > 0) {
            this.gridColor.removeData(itemsToRemove);
            this.gridColor.addData(itemsToAdd);
          } else {
            let data = this.gridColor.getAllRowNodes().map(d => d.data);
            this.gridColor.setGridData(data, null);
          }

          this.gridColor.sortBy("seq", "asc");

          this.gridTask.redrawRows({ rowNodes: [rowNodeProg] });
          resolve(true);
        }, error => {
          this.loadingData = false;
          this.gridColor.redrawRows();
          this.messageService.showToastError("Não foi possível realizar a alteração\n" + error);
          reject("error");
        });
    });
  }

  private loadSupplierList() {
    this.httpClient.get("production.CylinderScheduler/supplierList", null)
      .pipe(take(1))
      .subscribe(resp => {
        // Se vir UM ITEM, nao virá como array
        if (!Array.isArray(resp.supplierList)) {
          resp.supplierList = [resp.supplierList];
        }

        this.supplierList = resp.supplierList;

        this.supplierList.forEach((sup, index, array) => {
          sup["ID"] = index;
        }, error => {
          this.messageService.showToastError("Ocorreu um erro ao carregar a lista de fornecedores\n" + error);
        });
      });
  }

  public printRevision() {
    const selectedRows = this.gridTask.getRowsSelected();
    const ids: string[] = selectedRows.map(row => row.id_ordemProd);

    this.httpClient.openPdfReport(
      this.httpClient.postBlob("production.CylinderScheduler/revisionReportStream", null, ids.join(","))
    ).subscribe(resp => { });
  }

  public printEngrave() {

    if (this.cilRevisar.length) {
      this.messageService.showDialog(
        {
          context: {
            title: "Nas OPs selecionadas existem cilindros a revisar, deseja gerar a pré-nota sem esses cilindros?",
            actions: [{ description: "Sim" }, { description: "Não", status: "basic" }]
          }
        }).onClose
        .pipe(
          take(1)
        )
        .subscribe(resp => {
          if (resp === "Sim") {
            this.generateEngraveInvoice();
          }
        }, error => {
          this.messageService.showToastError(error);
        });
    } else {
      this.generateEngraveInvoice();
    }
  }

  private generateEngraveInvoice() {
    const formEdit = this.dialogService.open(EngraveInvoiceComponent, {
      context: {
        supplierList: this.supplierList,
        suggestedSupplier: this.gridTask.getRowNode(this.cilGravar[0].id_task_plan).data.prodOrderSupplier
      }
    });
    formEdit.onClose
      .pipe(take(1))
      .subscribe(resp => {
        if (resp.confirm && resp.id_conta && resp.dtEnvio) {
          this.genEngrave = true;

          let ids = this.cilGravar.map(cor => cor.id_ordemProd_cilindro);

          this.gridTask.getRowsSelected().forEach(row => {
            row.colors.forEach(cor => {
              if (cor.sendToInvoice && !ids.includes(cor.id_ordemProd_cilindro)) {
                ids.push(cor.id_ordemProd_cilindro);
              }
            });
          });

          this.createCylInvoice(resp.id_conta, resp.dtEnvio, ids)
            .pipe(take(1))
            .subscribe(resp2 => {
              this.genEngrave = false;

              let prog: CilindroProgramacao = null;

              // Se vir UM ITEM, nao virá como array
              if (!Array.isArray(resp2.progColorList)) {
                resp2.progColorList = [resp2.progColorList];
              }

              let colorListResp: Array<CilindroCor> = resp2.progColorList;

              colorListResp.forEach(colorResp => {

                if (!prog || prog.id_task_plan !== colorResp.id_task_plan) {
                  const progRow = this.gridTask.getRowNode(colorResp.id_task_plan);
                  if (progRow) {
                    prog = progRow.data;
                  }
                }

                if (prog) {
                  prog.colors
                    .filter(item => item.id_ordemProd_cilindro === colorResp.id_ordemProd_cilindro)
                    .forEach(item => {
                      item.invoice = colorResp.invoice;
                      item.supplier = colorResp.supplier;
                    });
                }
              });

              this.gridColor.refreshCells();
              this.onGDTaskChanged(null);

            }, error2 => {
              this.genEngrave = false;
              this.messageService.showToastError("Operação não realizada.\n" + error2.message);
            });
        }
      }, error => {
        this.messageService.showToastError("Operação não realizada.\n" + error);
      });
  }

  public createCylInvoice(id_conta: string, dtEnvio: Date, id_ordemProd_cilindro: Array<String>) {
    const dados = {
      id_conta: id_conta,
      dtEnvio: dtEnvio,
      ids: id_ordemProd_cilindro
    };

    return this.httpClient.post("production.CylinderScheduler/createCylInvoice", null, dados);
  }

  private updateCilindroStatus(novoStatus: string, progRow: IRowNode, colorRowList: Array<IRowNode>) {
    colorRowList.forEach(colorRow => {
      colorRow.data.updating = true;
    })

    this.gridColor.redrawRows({ rowNodes: colorRowList });

    this.updateStatusCilindro(colorRowList.map(cr => cr.data), novoStatus)
      .pipe(take(1))
      .subscribe(resp => {
        colorRowList.forEach(colorRow => {
          colorRow.data.updating = false;
          colorRow.data.status = novoStatus;

          let progColorData = progRow.data.colors.find(c => c.id_item_cylinder == colorRow.data.id_item_cylinder);
          progColorData.status = novoStatus;
        });

        this.gridColor.redrawRows({ rowNodes: colorRowList });
        this.gridTask.redrawRows({ rowNodes: [progRow] });

        this.onGDTaskChanged(null);
        this.updateColorsButtons();
      }, error => {
        colorRowList.forEach(colorRow => {
          colorRow.data.updating = false;
        });

        this.gridColor.redrawRows({ rowNodes: colorRowList });
      });
  }

  public updateStatusCilindro(corList: Array<CilindroCor>, novoStatus: string) {
    const url = "production.CylinderScheduler/changeStatus";

    let params = new Map<string, string>();
    params.set("situacao", novoStatus);

    return this.httpClient.post(url, params, corList.map(cor => cor.id_ordemProd_cilindro));
  }

  public insertCylinderHistoric(dados: Array<any>) {
    const url = "production.CylinderScheduler/insertCylinderHistoric";
    return this.httpClient.post(url, null, dados).pipe(take(1));
  }

  public printProdOrder() {
    const selectedRows = this.gridTask.getRowsSelected();
    selectedRows.forEach(row => {
      this.httpClient.openPdfReport(
        this.httpClient.postBlob(
          "production.CylinderScheduler/prodOrderReportStream",
          null,
          row.id_ordemProd
        )
      ).subscribe(resp => { });
    });
  }

  public onChangeTab(event) {
    // Necessário para que as colunas do grid não fiquem "fechadas"
    setTimeout(() => {
      this.gridTask.autoSizeAllColumns(false);
      this.gridColor.autoSizeAllColumns(false);
    }, 5);
  }

  public addRegisterCylinder() {
    const rowNodeProg = this.gridTask.getFocusedNode();

    let quimicoLista: Array<any> = [...new Map(rowNodeProg.data.colors.map(item => [item.id_especif_carac_quimico_item, item])).values()];

    const form = this.dialogService.open(AddCylinderComponent, {
      context: {
        quimicoLista: quimicoLista.map(c => {
          return {
            id_ordemprod: c.id_ordemProd,
            id_especif_carac_quimico_item: c.id_especif_carac_quimico_item,
            descricao: c.color + (c.detail ? " - " + c.detail : "")
          }
        })
      }
    });

    form.onClose
      .pipe(take(1))
      .subscribe(resp => {
        this.lockScreen = true;
        if (resp?.confirm) {
          let rowNodeProg = this.gridTask.getFocusedNode();
          let seqCorIgual = Math.max.apply(Math, rowNodeProg.data.colors
            .filter((c: CilindroCor) => c.id_especif_carac_quimico_item == resp.color.id_especif_carac_quimico_item)
            .map((c: CilindroCor) => c.seqCorIgual));

          let params = new Map<string, string>();
          params.set("id_ordemprod", resp.color.id_ordemprod);
          params.set("id_especif_carac_quimico_item", resp.color.id_especif_carac_quimico_item);
          params.set("seqCorIgual", seqCorIgual + 1);

          this.httpClient.get("production.CylinderScheduler/addRegisterCylinder", params)
            .pipe(take(1))
            .subscribe(resp => {
              this.loadCylinderData.bind(this)().then(() => {
                let updatedRowNode = this.gridTask.getRowNode(rowNodeProg.data.ID);
                this.gridTask.ensureNodeVisible(updatedRowNode, "middle");
                this.gridTask.setRowFocus(updatedRowNode);
                this.lockScreen = false;
              });
            })
        } else {
          this.lockScreen = false;
        }
      });
  }

  public setSupplier() {

    const formEdit = this.dialogService.open(SupplierComponent, {
      context: {
        supplierList: this.supplierList
      }
    });
    formEdit.onClose
      .pipe(
        take(1)
      )
      .subscribe(resp => {
        if (resp.confirm && resp.id_conta) {
          const ids = this.gridTask.getRowsSelected()?.map(row => row.id_ordemProd).join(",");

          if (ids) {
            let params = new Map<string, string>();
            params.set("id_ordemprod", ids);
            params.set("id_fornecedor", resp.id_conta);

            this.httpClient.get("production.CylinderScheduler/setSupplier", params)
              .pipe(take(1))
              .subscribe(resp => {
                this.gridTask.unselectAll();
                this.loadCylinderData();
              });

          }
        }
      }, error => {
        this.messageService.showToastError("Operação não realizada.\n" + error);
      });
  }

  markSendToInvoice(id_ordemProd_cilindro: string, sendToInvoice: Boolean) {
    let params = new Map<string, string>();
    params.set("id_ordemProd_cilindro", id_ordemProd_cilindro);
    params.set("sendToInvoice", sendToInvoice.toString());

    this.httpClient.get("production.CylinderScheduler/markSendToInvoice", params).pipe(take(1));
  }

  async gdCoresCellValueChanged(event: CellValueChangedEvent) {
    if (event.colDef.field == "sendToInvoice") {
      if (event.data.sendToInvoice && !event.data.id_item_cylinder) {
        event.data.sendToInvoice = false;
        this.gridColor.redrawRows();
        this.messageService.showToastError("Não há nenhum cilindro selecionado para esse registro");
        return;
      }

      if (!event.data.id_ordemProd_cilindro) {
        event.data.sendToInvoice = false;
        return;
      }

      // Esses status devem ficar como marcado
      if ((event.data.status === "GRAVAR" || event.data.status === "RECROMO") && !event.data.invoice && !event.data.sendToInvoice) {
        this.messageService.showToastError("Registros com os status GRAVAR e RECROMO sempre são enviados na NF");
        event.data.sendToInvoice = true;
        this.gridColor.redrawRows();
        return;
      }

      this.markSendToInvoice(event.data.id_ordemProd_cilindro, event.data.sendToInvoice);
    }
  }

  openSwapDialog() {
    let colorList: Array<CilindroCor> = this.gridColor.data?.filter(color => color.status == "OK");
    let remainingColor: Array<CilindroCor> = this.gridColor.data?.filter(color => color.status != "OK");

    if (colorList?.length < 2) {
      this.messageService.showToast("Não há cilindros suficientes no status 'OK' para essa ação", "ATENÇÃO", "warning");
      return;
    }

    this.dialogService.open(CylinderSwapComponent, {
      context: {
        colorList: JSON.parse(JSON.stringify(colorList)) // para não passar referência
      }
    }).onClose.pipe(take(1)).subscribe(result => {
      if (result.confirm) {
        this.lockScreen = true;
        colorList = result.colorList;

        this.httpClient.post("production.CylinderScheduler/swapColorCylinder", null, colorList.map(c => {
          return {
            id_ordemProd_cilindro: c.id_ordemProd_cilindro,
            id_ordemprod: this.taskSelected.id_ordemProd,
            id_item: c.id_item_cylinder,
            situacao: c.status,
            id_especif_carac_quimico_item: c.id_especif_carac_quimico_item
          }
        })).pipe(take(1)).subscribe(() => {
          let historicList = colorList.map(color => {
            return {
              id_item: color.id_item_cylinder,
              id_ordemprod: this.taskSelected.id_ordemProd,
              id_especificacao: color.id_productSpecification,
              id_especif_carac_quimico_item: color.id_especif_carac_quimico_item,
              cor: color.color,
              statusAnterior: color.status,
              novoStatus: color.status,
              motivo: "Função de troca de cilindro"
            }
          });

          colorList.push(...remainingColor);

          this.taskSelected.colors = colorList;
          this.gridColor.setGridData(colorList);

          this.insertCylinderHistoric(historicList).subscribe(() => { 
            this.lockScreen = false;
          }, error => {
            this.lockScreen = false;
          });
        }, error => {
          this.lockScreen = false;
        })
      }
    })
  }
}
