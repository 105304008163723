<app-dialog-template #dialog title="Trocar Dispositivo">
  <content style="display: flex; gap: 10px">
    <span>Dispositivo</span>
    <div>
      <nb-select [(ngModel)]="selectedItem" style="min-width: 150px;" [size]="'small'">
        <nb-option *ngFor="let item of itemList" [value]="item.id">{{item.description}}</nb-option>
      </nb-select>
    </div>
  </content>
  <footer>
    <div class="wFooter">
      <div class="button-row" style="display: flex;">
        <button nbButton status="danger" size="tiny" (click)="closeDialog('Cancela')"
          style="margin-left: auto">Cancela</button>
        <button nbButton status="primary" size="tiny" (click)="runCallback()">Confirma</button>
      </div>
    </div>
  </footer>
</app-dialog-template>
