<app-dialog-template #dialog [title]="title + ' (ações)'" [showFooter]="useConfirmButton">

  <content class="flexColumn">
    <div style="min-width: 20vw; max-width: 70vw; max-height: 250px;">

      <div *ngFor="let group of groups" style="margin-bottom: 8px; border-radius: 5px;"
        [ngClass]="{'groupSelected': group.selected, 'groupSelectable': groupSelection != 'none'}">

        <div class="group" (click)="groupClick(group)">
          {{group.groupName}}
        </div>

        <div class="flexRow flexWrap flexGap5 fillContent">
          <div *ngFor="let item of group.items">
            <div class="item flexAlignRowCenter flexGap5"
              [ngStyle]="{'width': (buttonSize == 'large' ? '150px' : '105px'), 'text-transform': (buttonCase == 'normal' ? 'none' : 'uppercase')}"
              [ngClass]="{'itemSelected': item.selected && !group.selected, 'itemSelectable': itemSelection != 'none'}"
              (click)="itemClick(item, group)">
              {{item.itemName}}
            </div>
          </div>
        </div>

      </div>
    </div>



  </content>
  <footer *ngIf="useConfirmButton">
    <div style="display: flex; gap: 5px; justify-content: flex-end; align-items: center;">
      <button nbButton status="basic" size="tiny" (click)="closeDialog('Cancela')">Cancela</button>
      <button nbButton status="primary" size="tiny" (click)="closeDialog('Confirma')">Confirma</button>
    </div>
  </footer>
</app-dialog-template>
