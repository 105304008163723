<div [ngStyle]="{height: adjustListHeight(layout, lastPart)}"
  [ngClass]="{'boxLayout': layout.background == 'box', 'bannerLayout': layout.background == 'banner'}"
  [class]="layout.cssClass" style="display: flex; flex-direction: column;">

  <!-- VIEW-LAYOUT -->
  <div *ngIf="layout.title"
    [ngClass]="{'layoutTitle': layout.background != 'banner', 'bannerLayoutTitle': layout.background == 'banner'}">
    {{layout.title}}</div>

  <div *ngIf="layout.layoutType === 'list'" [class]="layout.cssClassLayout" [style.gap]="layout.gap"
    [ngStyle]="{height: adjustListHeight(layout, lastPart)}">
    <div *ngFor="let l of layout.children; let i = index"
      [ngStyle]="{width: l.width || '100%', height: adjustListHeight(l, i == layout.children.length-1)}">
      <app-view-layout *ngIf="l.layoutType === 'list' || l.layoutType === 'grid' || l.layoutType === 'tab'" [layout]="l"
        [FormGroup]="FormGroup" [lastPart]="i == layout.children.length-1" (cmpReference)="addCmpReference($event)">
      </app-view-layout>
      <app-view-component *ngIf="l.layoutType === 'component'" [component]="l" [FormGroup]="FormGroup"
        (cmpReference)="addCmpReference($event)">
      </app-view-component>
    </div>
  </div>

  <!-- REMOVER FXLAYOUT -->
  <div *ngIf="layout.layoutType === 'grid'" [gdGap]="layout?.gap || '5px'" fxLayoutAlign="start stretch"
    [gdColumns]="gridColumns(layout)">
    <div *ngFor="let l of layout.children" [gdColumn]="l.columnSpan ? l.columnSpan+' span' : '1 span'">
      <app-view-layout *ngIf="l.layoutType === 'list' || l.layoutType === 'grid' || l.layoutType === 'tab'" [layout]="l"
        [FormGroup]="FormGroup" (cmpReference)="addCmpReference($event)">
      </app-view-layout>
      <div [ngStyle]="l.gridStyle" style="height: 100%; width: 100%;">
        <app-view-component *ngIf="l.layoutType === 'component'" [component]="l" [FormGroup]="FormGroup"
          (cmpReference)="addCmpReference($event)">
        </app-view-component>
      </div>
    </div>
  </div>

  <nb-tabset *ngIf="layout.layoutType === 'tab'" (changeTab)="changeTab($event)">
    <nb-tab *ngFor="let l of layout.children" [tabTitle]="l.tabTitle || l.title"
      [ngClass]="{tabGrid: l.layoutType == 'component' && selectedTab == (l.tabTitle || l.title)}">
      <app-view-layout *ngIf="l.layoutType === 'list' || l.layoutType === 'grid' || l.layoutType === 'tab'" [layout]="l"
        [FormGroup]="FormGroup" (cmpReference)="addCmpReference($event)"></app-view-layout>
      <app-view-component style="width: 100%" *ngIf="l.layoutType === 'component'" [component]="l"
        [FormGroup]="FormGroup" (cmpReference)="addCmpReference($event)">
      </app-view-component>
    </nb-tab>
  </nb-tabset>


</div>
