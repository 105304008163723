<div class="mainContent">

  <!-- parte superior -->
  <div style="display: flex; gap: 25px; align-items: baseline;">
    <div class="actionsContent">

      <!-- acoes -->
      <div style="width: 100%;">
        <button nbButton *ngIf="!isAggregation && buttonInsert" size="tiny" status="primary"
          [disabled]="FormGroup?.readOnly" (click)="buttonInsertClick()">Inserir</button>
        <button nbButton *ngIf="!isAggregation && buttonEdit" size="tiny" status="primary" [disabled]="!selectedId"
          (click)="buttonEditClick()">{{FormGroup?.readOnly ? 'Visualizar' : 'Editar'}}</button>
        <button nbButton *ngIf="!isAggregation && buttonDelete" size="tiny" status="danger"
          [disabled]="!selectedId || FormGroup?.readOnly" (click)="buttonDeleteClick()">Excluir</button>

        <!-- visualizacao-->
        <div #visualizationButton id="visualizationButton" (click)="visualizationInfoClick()">
          <div class="filterAction" [ngClass]="{'filterActionClosed': !showingInfoList }">
            <nb-icon icon="layout-outline"></nb-icon>

            <div *ngIf="visualization?.description" class="filterDescription">
              <div *ngIf="visualization.description" class="filterTitle"> {{visualization.description || 'Ola'}} </div>
            </div>

            <div *ngIf="!visualization?.description" class="filterDescription">
              <div class="filterTitle">Selecione uma visão...</div>
              <!-- <div class="filterDetail"> {{showingInfoList}}</div> -->
            </div>

          </div>

          <div id="visualizationOptions" [ngClass]="{'visualizationOptionsClick': showingInfoList }">
            <!-- <div class="statusOption" *ngFor="let status of statusFlow" class="flexColumn" fxLayoutAlign="center center"
            [nbTooltip]="status.tooltip" [nbTooltipTrigger]="!status.tooltip ? 'noop' : 'hover'"
            (click)="selectStatus(status)" [ngClass]="status.colorStyle || 't2ColorDefault'">
            {{status.action || status.name}}</div>
        </div> -->
            <div
              style="display: flex; flex-direction: column; max-height: 55vh; overflow-y: auto; overflow-x: hidden; gap: 2px">
              <div *ngFor="let item of visualizationInfoList" class="visualizationOption filterDescription">
                <div>
                  <div class="filterTitle">{{item.description}}</div>
                  <div class="filterDetail">{{item.detail}}</div>
                </div>

                <div id="visualizationOptionButtons">
                  <div class="searchFloatButton"
                    (click)="$event.stopPropagation(); selectFilter(item.id_dataset_visualization, 'run'); ">
                    <nb-icon icon="arrow-right-outline" nbTooltip="Selecionar"></nb-icon>
                  </div>
                  <div *ngIf="item.canEdit" class="editFloatButton"
                    (click)="$event.stopPropagation(); selectFilter(item.id_dataset_visualization, 'edit'); ">
                    <nb-icon icon="edit-outline" nbTooltip="Editar"></nb-icon>
                  </div>
                  <div *ngIf="item.canEdit" class="deleteFloatButton"
                    (click)="$event.stopPropagation(); selectFilter(item.id_dataset_visualization, 'erase'); ">
                    <nb-icon icon="trash-2-outline" nbTooltip="Apagar"></nb-icon>
                  </div>
                  <div class="copyFloatButton"
                    (click)="$event.stopPropagation(); selectFilter(item.id_dataset_visualization, 'copy'); "
                    nbTooltip="Copiar">
                    <nb-icon icon="copy-outline"></nb-icon>
                  </div>
                </div>
              </div>
            </div>

            <div class="visualizationOption filterDescription" style="margin-top: 6px; cursor: pointer"
              (click)="selectFilter(undefined, 'add')">
              <div class="filterTitle">Criar uma nova visão...</div>
              <div class="filterDetail">Configure uma nova visualização de colunas e filtros</div>
            </div>
          </div>
        </div>
        <button nbButton size="tiny" [disabled]="!visualizationSelected" (click)="eraseLastSelectedVisualization()">
          <nb-icon icon="backspace-outline" nbTooltip="Retornar a visualização padrão"></nb-icon>
        </button>

        <!-- busca rápida -->
        <!-- <nb-icon *ngIf="!showQuickSearch" class="quickSearch" icon="search-outline" (click)="showQuickSearchFields()" /> -->
        <div *ngIf="showQuickSearch" class="quickSearchOptionsSelected"
          style="position: relative; height: 100%; width: 100%;">
          <app-quick-search [condition]="visualization?.queryCondition" [outsideCalling]="true" [loading]="loadingData"
            (queryCondition)="quickSearchQueryCondition($event)" />
        </div>
      </div>



      <!-- pesquisa -->
      <div *ngIf="!visualization?.description">
        <div *ngIf="hasInativeCol || hasStatusCol" [nbSpinner]="loadingData" style="min-width: 26px; min-height: 26px;">
        </div>
        <nb-checkbox *ngIf="hasStatusCol" [nbTooltip]="hasStatusColTooltip" [(checked)]="showStatusEndsInfo"
          (checkedChange)="executeSearch()" [disabled]="loadingData" style="margin-right: 3px; padding: 3px;">
          Encerrados
        </nb-checkbox>
        <nb-checkbox *ngIf="hasInativeCol" nbTooltip="Exibir também os registros inativos" [(checked)]="showInactive"
          (checkedChange)="executeSearch()" [disabled]="loadingData" style="margin-right: 3px;">
          Inativos
        </nb-checkbox>


        <nb-form-field *ngIf="!isAggregation && false">
          <input fieldSize="small" type="text" placeholder="Busca rápida" nbInput [value]="searchText"
            (input)="onSeachTextChange($event)" (keydown.enter)="onsearchTextKeyEnter($event)" fullWidth />

          <button nbSuffix nbButton ghost (click)="executeSearch()" size="small">
            <nb-icon class="iconSearch" icon="search" nbTooltip="Pesquisar" nbTooltipStatus="basic">
            </nb-icon>
          </button>
        </nb-form-field>
      </div>
    </div>

  </div>
  <!-- grid -->
  <div style="height: 100%; width: 100%;" (click)="onClick($event)">
    <app-t2grid #gridDataset id="gridDataset" [rowSelection]="selectionType" [loading]="loadingData"
      [sortable]="sortable" [component]="gridContextComponent" [gridStateName]="gridStateName"
      (rowClick)="onGridRowClick($event)" (rowDoubleClick)="onGridRowDoubleClick($event)">
    </app-t2grid>
  </div>
</div>
