<div style="gap: 5px" class="flexColumn fillContent">

  <div class="flexAlignRowEndCenter flexGap5">
    <button nbButton outline size="small" status="primary" [disabled]="" (click)="addCylinderToFamily()">
      <nb-icon icon="plus-circle-outline"></nb-icon>
    </button>
    <button nbButton outline size="small" status="danger" [disabled]="!gridCylinderFamily.getFocusedRow()"
      (click)="deleteCylinderFromFamily()">
      <nb-icon icon="trash-2-outline"></nb-icon>
    </button>
  </div>

  <app-t2grid class="fillContent" #gridCylinderFamily [loading]="loadingGrid"></app-t2grid>
</div>
