<app-dialog-template #dialog [title]="title">
  <content>

    <div class="flexColumn" style="gap: 10px">

      <div class="flexColumn" style="gap: 5px">
        <div> Descrição da nova tabela </div>
        <input [formControl]="descriptionField" nbInput fieldSize="small" placeholder="Descrição da tabela"
          style="width: 400px;" />
      </div>

      <div style="display: flex; gap: 10px">

        <div class="flexColumn flexLength1 flexGap5">
          <div> Início </div>
          <input [formControl]="startDateField" nbInput fieldSize="small" [nbDatepicker]="datepickerStart"
            style="max-width: 153px; width: 100%;">
          <nb-datepicker #datepickerStart format="dd/MM/yyyy"></nb-datepicker>
        </div>

        <div class="flexColumn flexLength1 flexGap5">
          <div> Fim </div>
          <input [formControl]="endDateField" nbInput fieldSize="small" [nbDatepicker]="datepickerEnd"
            style="max-width: 153px; width: 100%;">
          <nb-datepicker #datepickerEnd format="dd/MM/yyyy"></nb-datepicker>
        </div>
      </div>

    </div>


  </content>

  <footer>
    <div style=" display: flex;">
      <button style="margin-left: auto;" nbButton size="tiny" status="primary" (click)="confirm()">Confirmar</button>
      <button style="margin-left: 3px;" nbButton size="tiny" status="basic" (click)="cancel()">Cancelar</button>
    </div>
  </footer>
</app-dialog-template>
