<div [ngStyle.xs]="{ width: '300px' }">
  <app-dialog-template #dialog [title]="title">
    <content>
      <div class="flexRow flexWrap" style="max-width: 70vw;">
        <div *ngFor="let action of actions" class="flexAlignRowCenter actionStyle flexColumn"
          style="padding: 0px; margin: 10px;" (click)="actionClick(action)">
          {{action.datasetActionDescription}}
        </div>
      </div>
    </content>
  </app-dialog-template>
</div>
