import { Injectable } from '@angular/core';
import { Observable } from "rxjs";
import { map, take } from "rxjs/Operators";
import { T2HttpClientService } from "src/app/core/http/t2httpClient.service";

@Injectable({
  providedIn: 'root'
})
export class BudgetPolicyService {

  constructor(private httpClient: T2HttpClientService) { }

  public addBudgetPolicyItem(budgetPolicyId: string, item: BudgetPolicyItem): Observable<BudgetPolicyItem> {
    let params = new Map<string, string>();
    params.set("budgetPolicyId", budgetPolicyId);

    return this.httpClient.post("budgetPolicy/addBudgetPolicyItem", params, item).pipe(take(1), map(resp => {
      let policyItem: BudgetPolicyItem = resp?.policyItem;

      if (policyItem) {
        this.transformServerResponse(policyItem);
      }

      return policyItem;
    }));
  }

  public getBudgetPolicyItemList(budgetPolicyId: string): Observable<Array<BudgetPolicyItem>> {
    let params = new Map<string, string>();
    params.set("budgetPolicyId", budgetPolicyId);

    return this.httpClient.get("budgetPolicy/getBudgetPolicyItemList", params).pipe(take(1), map(resp => {
      let itemList = new Array<BudgetPolicyItem>();

      if (resp?.policyItemList) {
        if (!Array.isArray(resp.policyItemList)) {
          resp.policyItemList = [resp.policyItemList];
        }

        itemList = resp.policyItemList;

        itemList.forEach(item => {
          this.transformServerResponse(item);
        })
      }

      return itemList;
    }));
  }

  public getBudgetPolicyItemRules(budgetPolicyId: string, budgetPolicyItemId: string): Observable<BudgetPolicyItem> {
    let params = new Map<string, string>();
    params.set("budgetPolicyId", budgetPolicyId);
    params.set("budgetPolicyItemId", budgetPolicyItemId);

    return this.httpClient.get("budgetPolicy/getBudgetPolicyItemRules", params).pipe(take(1), map(resp => {
      let item: BudgetPolicyItem;

      if (resp?.policyItem) {
        item = resp.policyItem;

        this.transformServerResponse(item);
      }

      return item;
    }));
  }

  private transformServerResponse(item: BudgetPolicyItem) {
    if (item.ruleList && !Array.isArray(item.ruleList)) {
      item.ruleList = [item.ruleList];
    }

    if (!item.costCenterHierarchyList) {
      item.costCenterHierarchyList = [];
    }

    if (item.costCenterHierarchyList && !Array.isArray(item.costCenterHierarchyList)) {
      item.costCenterHierarchyList = [item.costCenterHierarchyList];
    }

    if (!item.accountHierarchyList) {
      item.accountHierarchyList = [];
    }

    if (item.accountHierarchyList && !Array.isArray(item.accountHierarchyList)) {
      item.accountHierarchyList = [item.accountHierarchyList];
    }

    item.ruleList?.forEach(rule => {
      rule.startDate = rule.startDate ? new Date(rule.startDate) : undefined;
      rule.endDate = rule.endDate ? new Date(rule.endDate) : undefined;
      rule.budgetValue = Number(rule.budgetValue).valueOf();

      if (rule.excessRuleList && !Array.isArray(rule.excessRuleList)) {
        rule.excessRuleList = [rule.excessRuleList];
      }

      rule.excessRuleList?.forEach(exRule => {
        exRule.excessValue = Number(exRule.excessValue).valueOf();
        exRule.minApprovalQty = Number(exRule.minApprovalQty).valueOf();

        if (exRule.approverList && !Array.isArray(exRule.approverList)) {
          exRule.approverList = [exRule.approverList];
        }
      })
    })
  }
}

export class BudgetPolicy {
  budgetPolicyId: string;
  code: string;
  description: string;
  startDate: Date;
  endDate: Date;
  defaultPolicy: boolean;
  frequency: BudgetPolicyFrequency;
  itemList: Array<BudgetPolicyItem>;
}

export enum BudgetPolicyFrequency {
  NONE = "Nenhuma",
  MONTHLY = "Mensal",
  BIMONTHLY = "Bimestral",
  QUARTERLY = "Trimestral",
  SEMIANNUAL = "Semestral"
}

export class BudgetPolicyItem {
  budgetPolicyItemId: string;
  accountId: string;
  accountHierarchyId: string;
  accountInfo: HierarchyInfo;
  costCenterId: string;
  costCenterHierarchyId: string;
  costCenterInfo: HierarchyInfo;
  budgetPolicyItemGlobalRuleId;
  ruleList: Array<BudgetPolicyItemRule>;
  costCenterHierarchyList: Array<HierarchyInfo>;
  accountHierarchyList: Array<HierarchyInfo>;
}

export class BudgetPolicyItemRule {
  budgetPolicyItemRuleId: string;
  description: string;
  startDate: Date;
  endDate: Date;
  budgetValue: number;
  excessRuleList: Array<BudgetPolicyItemExcessRule>;
}

export class BudgetPolicyItemExcessRule {
  budgetPolicyItemExcessRuleId: string;
  excessValue: number;
  minApprovalQty: number;
  approverList: Array<BudgetPolicyItemExcessRuleApprover>;
  pendingInclusion?: boolean;
}

export class BudgetPolicyItemExcessRuleApprover {
  budgetPolicyItemRuleExcessApproverId: string;
  type: BudgetPolicyApproverType;
  mandatory: boolean;
  id_security_identity: string;
  name?: string;
  pendingInclusion?: boolean;
}

export enum BudgetPolicyApproverType {
  COST_CENTER_MANAGER = "Gestor do Centro de Custo",
  ACCOUNT_MANAGER = "Gestor da Conta Contábil",
  GROUP_OR_USER = "Grupo/Usuário"
}

export class HierarchyInfo {
  code: string;
  description: string;
}
