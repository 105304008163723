export class T2Authentication {

    public authenticated = false;
    public error = false;
    public invalidCredentials = false;
    public communicationError = false;
    public errorMessage?: string;
    public userId?: string;

    constructor(authenticated: boolean, error: boolean, errorMessage: string, communicationError = false, userId?: string) {
        this.authenticated = authenticated;
        this.error = error;
        this.communicationError = communicationError;
        this.errorMessage = errorMessage;
        this.userId = userId;
        this.invalidCredentials = false;
    }

    toString(): string {
      return `${this.authenticated}|${this.userId}|${this.error}`;
    }
}
