import { DatePipe, FormatWidth, getLocaleDateFormat } from "@angular/common";
import { Component, ElementRef, Inject, Input, LOCALE_ID, OnInit, ViewChild } from '@angular/core';
import { NbDateTimePickerComponent } from "@nebular/theme";
import { NbCalendarWithTimeComponent } from "@nebular/theme/components/datepicker/calendar-with-time.component";
import { T2BaseComponent } from "../t2-base-component";

@Component({
  selector: 'app-t2-date-time',
  templateUrl: './t2-date-time.component.html',
  styleUrls: ['./t2-date-time.component.scss']
})
export class T2DateTimeComponent extends T2BaseComponent implements OnInit {
  @ViewChild('dtInput', {static: false}) dtInput: ElementRef;

  @Input() withSeconds: boolean;
  @Input("format")
  get format(): string { return this._format; }
  set format(value: string) {
    this._format = value;

    if (this.formGroup?.controls[this.t2IdCmp]?.value && this.dtInput) {
      this.dtInput.nativeElement.value = (new DatePipe(this.locale).transform(this.formGroup.controls[this.t2IdCmp].value, this._format));
    }
  }

  private _format: any;

  constructor(@Inject(LOCALE_ID) private locale: string) {
    super();
  }

  ngOnInit(): void {
    this.setDefaultValues();

    if (!this.withSeconds || this.withSeconds == undefined) {
      this.withSeconds = true;
    }

    if (!this.format || !this.format) {
      let dtFormat = getLocaleDateFormat(this.locale, FormatWidth.Short);

      if (dtFormat.startsWith("M")) {
        if (this.withSeconds)
          this.format = "MM/dd/yyyy hh:mm:ss a"
        else
          this.format = "MM/dd/yyyy hh:mm a";
      } else {
        if (this.withSeconds)
          this.format = "dd/MM/yyyy HH:mm:ss"
        else
          this.format = "dd/MM/yyyy HH:mm";
      }
    }
  }

}
