import { RegistrationFixData } from '../model/RegistrationFixData';
import { Component, Input, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { AgEditorComponent } from "ag-grid-angular";
import { ICellEditorParams } from "ag-grid-community";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";

@Component({
  selector: 'app-value-editor-cell',
  templateUrl: './value-editor-cell.component.html',
  styleUrls: ['./value-editor-cell.component.scss']
})
export class ValueEditorCellComponent implements OnInit, AgEditorComponent {

  registrationData: RegistrationFixData;
  formGroup: FormGroup;
  valueControl = new FormControl(undefined, []);
  @Input() numberValue;
  @ViewChild('input', { read: ViewContainerRef }) public input: ViewContainerRef;


  constructor(private formBuilder: FormBuilder) {

    this.formGroup = this.formBuilder.group({}, { updateOn: 'blur' });
    this.formGroup.addControl('regFixEditor', formBuilder.control(undefined, []));
  }

  ngOnInit(): void {
    this.numberValue = this.registrationData.propValue;
  }

  agInit(params: ICellEditorParams): void {
    this.registrationData = params.data;

    if (this.registrationData.contentType === 'list' && this.registrationData.propValue) {
      this.formGroup.controls['regFixEditor'].setValue(this.registrationData.propValue);
    } else if (this.registrationData.contentType === 'datetime' && this.registrationData.propValue) {
      this.valueControl.patchValue(new Date(this.registrationData.propValue));
    } else {
      this.valueControl.patchValue(this.registrationData.propValue);
    }
  }

  getValue() {
    if (this.registrationData.contentType === 'list') {
      return this.formGroup.controls['regFixEditor'].value;
    } else if (this.registrationData.contentType === 'datetime' && this.registrationData.propValue) {
      return this.valueControl.value.toISOString();
    } else if (this.registrationData.contentType === 'boolean') {
      const b = this.valueControl.value;
      if (!b) {
        return "False";
      }
      return b.substr(0, 1).toUpperCase() + b.substr(1).toLowerCase();
    }

    return this.valueControl.value;
  }

  onNumberValueChange(valor) {
    this.valueControl.patchValue(valor);
  }

}
