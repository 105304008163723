<app-dialog-template #dialog [ngStyle]="{'minWidth.vw': 30, 'maxHeight.vh': 65, 'maxWidth.vw': 75}">
  <content>
    <app-view-template *ngIf="layout" [layout]="layout" [data]="data" [FormGroup]="formGroup"></app-view-template>
  </content>
  <footer style="display: flex; gap: 5px; justify-content: flex-end; align-items: center;">
    <button nbButton status="basic" size="tiny" (click)="cancel()">Cancelar</button>
    <button *ngIf="!newConfig" nbButton [status]="diffUser ? 'primary' : 'basic'" size="tiny"
      [disabled]="!formGroup.valid" (click)="confirm(true)">Salvar como</button>
    <button nbButton [status]="'primary'" size="tiny" [disabled]="diffUser || !formGroup.valid"
      (click)="confirm()">Salvar</button>
  </footer>
</app-dialog-template>
