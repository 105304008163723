<div *ngIf="layout" style="height: 100%;"
   [style.border]="layout?.mouseOver || layout?.checked ? '2px solid red' : (layout?.box ? '1px solid rgb(221, 220, 220)' : null)"
   [style.border-radius]="layout?.box ? '3px' : null"
   [nbTooltipTrigger]="layout.toolTip ? 'hover' : 'noop'"
   [nbTooltip]="layout.toolTip"
   >
  <!-- REMOVER FXLAYOUT -->
  <nb-card *ngIf="layout.card" style="height: 100%;">
    <nb-card-header *ngIf="layout.title && !layout.hideTitle">{{layout.title}}</nb-card-header>
    <nb-card-body fxFlexFill
                  fxLayoutAlign="space-around stretch"
                  [fxLayout]="layout.direction || 'row'"
                  [fxLayoutAlign]="layout.align || 'left'"
                  [fxLayoutGap]="layout.gap || '10px'"
                  [fxLayout.xs]="layout.directionXS || 'column'"
                  [fxLayoutAlign.xs]="layout.alignXS || 'left'"
                  [fxLayoutGap.xs]="layout.gapXS || '5px'"
    >
      <app-t2-layout *ngFor="let item of layout.items"
         [layout]="item"
         [fxFlex]="'1 1 ' + item.width || 'auto'"
         [fxFlex.xs]="'1 1 auto'"
         [ngStyle]="t2Style(layout, item)"
         [ngStyle.xs]="t2StyleXS(layout, item)"
         >
      </app-t2-layout>

    <div *ngIf="(!layout.items || layout.items.length === 0) && layout.component === 'indicador'"
      style="text-align: center;">
      <div style="text-align: center;">
        <h4 style="margin: 10px !important;">234</h4>
        <div>OPs Abertdas</div>
      </div>
    </div>
    <div *ngIf="(!layout.items || layout.items.length === 0) && layout.component != 'indicador'"
      style="font-size: 50%; font-weight: bold;"> {{layout.component || "Component"}}</div>

    </nb-card-body>
  </nb-card>


  <div *ngIf="!layout.card && layout.title && !layout.hideTitle"
  [style.font-size]="layout.items?.length ? '100%' : '70%'"
  [style.font-weight]="layout.items?.length ? 'none' : 'bold'"
  >
    {{layout.title}}
  </div>
  <div *ngIf="!layout.card"
       fxFlexFill
       fxLayoutAlign="space-around stretch"
       [fxLayout]="layout.direction || 'row'"
       [fxLayoutAlign]="layout.align || 'start'"
       [fxLayoutGap]="layout.gap || '10px'"
       [fxLayout.xs]="layout.directionXS || 'column'"
       [fxLayoutAlign.xs]="layout.alignXS || 'left'"
       [fxLayoutGap.xs]="layout.gapXS || '5px'">


    <app-t2-layout *ngFor="let item of layout.items"
         [layout]="item"
         [fxFlex]="'1 1 ' + item.width || 'auto'"
         [fxFlex.xs]="'1 1 auto'"
         [ngStyle]="t2Style(layout, item)"
         [ngStyle.xs]="t2StyleXS(layout, item)"

      >
    </app-t2-layout>

    <div *ngIf="(!layout.items || layout.items.length === 0) && layout.component === 'indicador'" style="text-align: center;">
      <div style="text-align: center;">
        <h4 style="margin: 10px !important;">234</h4>
        <div>OPs Abertdas</div>
      </div>
    </div>
    <div *ngIf="(!layout.items || layout.items.length === 0) && layout.component != 'indicador'" style="font-size: 50%; font-weight: bold;"> {{layout.component || "Component"}}</div>

  </div>

</div>
