<div class="flexColumn flexGap5" [nbSpinner]="loading" nbSpinnerSize="giant" nbSpinnerStatus="primary">

  <div *ngFor="let rel of psReleaseList" class="T2GrayArea flexColumn flexGap5" style="padding: 5px;">

    <!-- TIPO DE EP -->
    <div class="flexAlignRowSpaceBetweenStart flexGap5">
      <span class="defaultBigTitle" style="padding: 3px 5px;">{{rel.isConjugation ? 'Conjugação' : 'Versão'}}
        {{rel.specifCode}}</span>
      <nb-icon class="edit" icon="edit-outline" nbTooltip="Abrir EP" (click)="openProdSpecif(rel.specificationId)" />
    </div>


    <!-- PRODUTOS DA EP -->
    <div *ngFor="let item of rel.itemList" class="flexColumn " style="padding: 3px 5px; border-radius: 5px;"
      [ngClass]="{greenBox: item.totalQty > 0, redBox: !item.totalQty}">

      <div class="flexAlignRowSpaceBetweenCenter">
        <span class="defaultSmallTitle">{{item.itemDescription}}</span>
        <span class="defaultTinyTitle"
          [ngClass]="{defaultSmallTitle: item.totalQty > 0, defaultTinyTitle: !item.totalQty}">
          {{item.totalQty ? item.totalQty : 'SEM DEMANDA'}}
        </span>
      </div>

      <div style="line-height: 0.9rem;">
        <div *ngFor="let demand of item.demandList" class="flexAlignRowSpaceBetweenCenter">
          <span class="defaultText">{{demand.code}}</span>
          <span class="defaultText">{{demand.date | date:'shortDate'}}</span>
          <span class="defaultText">{{demand.quantity}}</span>
        </div>
      </div>
    </div>

    <app-color-detail [prodSpecifId]="rel.specificationId"></app-color-detail>

    <app-eppconjugate-assembly *ngIf="rel.isConjugation" [t2IdCmp]="rel.specificationId" [useWidth100]="true"
      #conjAssembly></app-eppconjugate-assembly>

  </div>
</div>
