import { Injectable, OnDestroy } from '@angular/core';
import { FirebaseApp, initializeApp } from "firebase/app";
import { Database, Unsubscribe, getDatabase, onValue, ref } from "firebase/database";
import { T2HttpClientService } from "./http/t2httpClient.service";
import { take } from "rxjs/Operators";
import { getAuth, signInWithCustomToken } from "firebase/auth";
import { Subject } from "rxjs";

@Injectable()
export class T2FirebaseService implements OnDestroy {
  private firebaseApp: FirebaseApp;
  private database: Database;
  private unsubscribeList: Array<{ path: string, unsubscribe: Unsubscribe }> = [];

  constructor(private httpClient: T2HttpClientService) { }

  ngOnDestroy(): void {
    this.unsubscribeList.forEach(it => {
      it.unsubscribe();
    })
  }

  public initialize(appName: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (this.firebaseApp) {
        resolve();
      } else {
        this.httpClient.get("core.firebase.rt/firebaseAppConfig", null).pipe(take(1)).subscribe(resp => {
          let config = JSON.parse(resp.config);

          this.firebaseApp = initializeApp(config, appName);
          this.database = getDatabase(this.firebaseApp);

          resolve();
        }, error => {
          reject(error);
        });
      }
    });
  }

  private checkInitialized() {
    if (!this.firebaseApp) {
      throw Error("O serviço T2FirebaseService não foi inicializado");
    }
  }

  public isInitialized() {
    return !!this.firebaseApp;
  }

  public getData(path: string, onlyOnce?: boolean) {
    this.checkInitialized();
    let sub = new Subject<any>();

    let dbRef = ref(this.database, path);

    let unsub = onValue(dbRef, (snapshot) => {
      sub.next(snapshot.val());
    }, { onlyOnce: onlyOnce });

    if (!onlyOnce) {
      this.unsubscribeList.push({ path: path, unsubscribe: unsub });
    }

    return sub.asObservable();
  }

  public unsubscribeFromPath(path: string) {
    let index = this.unsubscribeList.findIndex(it => it.path == path);

    if (index > -1) {
      this.unsubscribeList[index].unsubscribe();
      this.unsubscribeList.splice(index, 1);
    }
  }

  public signIn(appName: string, companyId: string) {
    return new Promise<void>((resolve, reject) => {
      let params = new Map<string, string>();
      params.set("app", appName);
      params.set("uid", companyId);

      this.httpClient.get("core.firebase.rt/authToken", params).pipe(take(1)).subscribe(respToken => {
        this.signInWithToken(respToken.token).then(() => {
          resolve();
        }, error => {
          reject(error);
        });
      }, error => {
        reject(error);
      });
    });
  }

  public signInWithToken(token: string) {
    this.checkInitialized();

    let auth = getAuth(this.firebaseApp);

    return signInWithCustomToken(auth, token);
  }
}
