import { Component } from '@angular/core';
import { ICellRendererAngularComp } from "ag-grid-angular";
import { ICellRendererParams } from "ag-grid-community";

@Component({
  selector: 'app-task-separation-cell-renderer',
  templateUrl: './task-separation-cell-renderer.component.html',
  styleUrls: ['./task-separation-cell-renderer.component.scss']
})
export class TaskSeparationCellRendererComponent implements ICellRendererAngularComp {
  stockAvailable: boolean;
  mpSeparate: boolean;
  percSeparation: number;
  hasRequisition: boolean;

  agInit(params: ICellRendererParams<any, any>): void {
    if (params?.data) {
      this.stockAvailable = params.data.stockAvailable;
      this.mpSeparate = params.data.mpSeparate;
      this.percSeparation = params.data.percSeparation;
      this.hasRequisition = params.data.hasRequisition;
    }
  }
  refresh(params: ICellRendererParams): boolean {
    return true;
  }

}
