<div *ngIf="loaded" class="flexColumn fillContent flexGap10" [nbSpinner]="lockScreen" nbSpinnerSize="giant"
  nbSpinnerStatus="info">
  <div class="fillContent" style="overflow: auto;">
    <div *ngIf="dimInfoId">
      <app-t2-auto-form #formDimensao [t2IdCmp]="'formDimensao'" [datasetName]="'epp_especif_carac_dimensao'"
        [inDialog]="false" [changeTitle]="false" [formTemplateUseFlexLayout]="false" [inFormList]="false"
        [hideButtons]="true" [informationId]="dimInfoId" [loadIdFromUrl]="false"></app-t2-auto-form>
    </div>
    <div *ngIf="passoInfoId">
      <app-t2-auto-form #formPasso [t2IdCmp]="'formPasso'" [datasetName]="'flx_especif_carac_prodPasso'"
        [inDialog]="false" [changeTitle]="false" [formTemplateUseFlexLayout]="false" [inFormList]="false"
        [hideButtons]="true" [informationId]="passoInfoId" [loadIdFromUrl]="false"></app-t2-auto-form>
    </div>
    <div *ngIf="embobInfoId">
      <app-t2-auto-form #formEmbobinament [t2IdCmp]="'formEmbobinament'"
        [datasetName]="'flx_especif_carac_embobinament'" [inDialog]="false" [changeTitle]="false"
        [formTemplateUseFlexLayout]="false" [inFormList]="false" [hideButtons]="true"
        [informationId]="embobInfoId" [loadIdFromUrl]="false"></app-t2-auto-form>
    </div>
    <div *ngIf="montInfoId">
      <app-t2-auto-form #formMontagem [t2IdCmp]="'formMontagem'" [datasetName]="montDatasetname" [inDialog]="false"
        [changeTitle]="false" [formTemplateUseFlexLayout]="false" [inFormList]="false" [hideButtons]="true"
        [informationId]="montInfoId" [loadIdFromUrl]="false"></app-t2-auto-form>
    </div>
  </div>
</div>
