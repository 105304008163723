import { Component, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-dp-status',
  templateUrl: './dp-status.component.html',
  styleUrls: ['./dp-status.component.scss'],
  host: {
    '(document:click)': 'onClick($event)',
  }
})
export class DpStatusComponent implements OnInit {

  @Input() currentStatus: { name: string, action: string, tooltip?: string, color: string };
  @Input() statusFlow: Array<{ name: string, action: string, tooltip?: string, color: string }>;
  @Output() statusChange = new EventEmitter<{ name: string, action: string, tooltip?: string, color: string }>();

  showingOptions = false;

  constructor(private _eref: ElementRef) { }

  onClick(event) {
    if (!this._eref.nativeElement.contains(event.target))
      this.showingOptions = false;
  }

  ngOnInit(): void {
  }

  statusClick() {
    this.showingOptions = !this.showingOptions;
  }

  selectStatus(status: { name: string, action: string, tooltip?: string, color: string }) {

    this.showingOptions = false;
    this.statusChange.emit(status);
  }

}
