<div class="flexColumn" style="gap: 10px; height: 100%;">

  <div class="operationList" [nbSpinner]="loading" nbSpinnerSize="giant">
    <div *ngIf="operationFilteredList?.length == 0" class="defaultSmallTitle">Não há nenhuma operação configurada para o
      modo selecionado</div>
    <div *ngFor="let oper of operationFilteredList" class="operation" (click)="operationClicked(oper)">
      <div class="operationIcon" [ngStyle]="{'background': oper.color}">
        <nb-icon [icon]="oper.icon"></nb-icon>
      </div>
      <div>
        <div style="font-weight: bold; line-height: 0.95rem;">{{ oper.description }} </div>
      </div>

    </div>
  </div>

  <div *ngIf="!justifyOperation" class="operationFilterList">
    <div *ngFor="let f of filterList" [ngClass]="{'operationFilter': true}" [ngStyle]="{'background': f.color}"
      (click)="clickFilter(f)">
      <nb-icon *ngIf="smallWindow" [icon]="f.icon"></nb-icon>
      <div *ngIf="!smallWindow" style="font-weight: bold;"> {{ f.description }}</div>
      <div *ngIf="!smallWindow"> ({{ f.count || ' - ' }})</div>
      <div *ngIf="smallWindow"> {{ f.count || ' - ' }}</div>
    </div>
  </div>
  <div *ngIf="justifyOperation">
    <app-t2-button status="basic" size="tiny" value="Voltar" (clicked)="backToDocuments()" />
  </div>
</div>