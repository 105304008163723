import { FormatWidth, getLocaleDateFormat } from "@angular/common";
import { Component, Inject, LOCALE_ID, OnInit, ViewChild } from '@angular/core';
import { FormControl } from "@angular/forms";
import { NbDateService } from "@nebular/theme";
import { take } from "rxjs/Operators";
import { T2gridComponent } from "src/app/core/cmp/t2grid/t2grid.component";
import { T2HttpClientService } from "src/app/core/http/t2httpClient.service";

@Component({
  selector: 'app-cyl-existing-engraving',
  templateUrl: './cyl-existing-engraving.component.html',
  styleUrls: ['./cyl-existing-engraving.component.scss']
})
export class CylExistingEngravingComponent implements OnInit {

  @ViewChild("gridEngraving", { static: true }) gridEngraving: T2gridComponent;

  loading = false;
  startDate: string;
  endDate: string;
  dateStartControl = new FormControl(this.dateService.getMonthStart(this.dateService.today()));
  dateEndControl = new FormControl(this.dateService.getMonthEnd(this.dateService.today()));
  format: string;

  constructor(private httpClient: T2HttpClientService, protected dateService: NbDateService<Date>, @Inject(LOCALE_ID) private locale) { 
    let dtFormat = getLocaleDateFormat(locale, FormatWidth.Short);

    if (dtFormat.startsWith("M")) {
      this.format = "MM/dd/yyyy";
    } else {
      this.format = "dd/MM/yyyy";
    }
  }

  ngOnInit(): void {
    this.gridEngraving.t2GridOptions.masterDetail = true;
    this.gridEngraving.setGridColumnDefs([
      { checkboxSelection: true, lockPosition: true, pinned: "left", valueGetter: "node.rowIndex + 1", width: 45, suppressNavigable: true },
      { headerName: "# NF", field: "invoice", cellRenderer: 'agGroupCellRenderer' },
      { headerName: "Fornecedor", field: "supplier" },
      { headerName: "NF Status", field: "invoiceStatus" },
      { headerName: "NF Emissão", field: "invoiceDate" }
    ]);

    this.gridEngraving.setDetailCellRendererParams({
      detailGridOptions: {
        columnDefs: [
          { headerName: "OP", field: "prodOrder" },
          { headerName: "Cod Cilindro", field: "cylCode" },
          { headerName: "Cilindro", field: "cylDescription" },
          { headerName: "Cor", field: "cylColor" },
          { headerName: "Detalhe", field: "cylDetail" }
        ]
      },

      getDetailRowData: function (params) {
        params.successCallback(params.data.items)
      }
    });
  }

  public loadData() {

    this.loading = true;

    const params = new Map<string, string>();
    params.set("startDate", this.dateService.format(this.dateStartControl.value, "yyyy-MM-dd"));
    params.set("endDate", this.dateService.format(this.dateEndControl.value, "yyyy-MM-dd"));

    this.httpClient.get("production.CylinderScheduler/cylInvoiceItems", params)
      .pipe(take(1))
      .subscribe(resp => {
        // Se vir UM ITEM, nao virá como array
        if (!Array.isArray(resp.cylInvoiceItem)) {
          resp.cylInvoiceItem = [resp.cylInvoiceItem];
        }

        let dataList = [];

        resp.cylInvoiceItem.forEach(invIt => {
          let dataF = dataList.filter(d => d.id_notafiscal == invIt.id_notafiscal);

          if (dataF.length > 0) {
            dataF[0].items.push({
              ID: invIt.id_ordemprod,
              id_ordemprod: invIt.id_ordemprod,
              prodOrder: invIt.prodOrder,
              cylCode: invIt.cylCode,
              cylDescription: invIt.cylDescription,
              cylColor: invIt.cylColor,
              cylDetail: invIt.cylDetail
            });
          } else {
            dataList.push({
              ID: invIt.id_notafiscal,
              id_notafiscal: invIt.id_notafiscal,
              invoice: invIt.invoice,
              invoiceStatus: invIt.invoiceStatus,
              invoiceDate: invIt.invoiceDate,
              supplier: invIt.supplier,
              items: [
                {
                  id_ordemprod: invIt.id_ordemprod,
                  prodOrder: invIt.prodOrder,
                  cylCode: invIt.cylCode,
                  cylDescription: invIt.cylDescription,
                  cylColor: invIt.cylColor,
                  cylDetail: invIt.cylDetail
                }
              ]
            });
          }
        });

        this.gridEngraving.setGridData(dataList, null);
        this.gridEngraving.autoSizeAllColumns(false);

        this.loading = false;
      });

  }

  printEngraving() {
    let ids: string[] = [];

    this.gridEngraving.getRowsSelected().forEach(row => {
      ids.push(row.id_notafiscal);
    });

    this.httpClient.openPdfReport(
      this.httpClient.postBlob("production.CylinderScheduler/engraveReportStream", null, ids.join(","))
    ).subscribe(resp => {});
  }

  onChange(event: any) {
    if (event.target.id == "inputStartDate") {
      this.startDate = event.target.value;
    } else if (event.target.id == "inputEndDate") {
      this.endDate = event.target.value;
    }
  }

}
