import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from "@angular/forms";
import { take } from "rxjs/Operators";
import { EPService } from "../ep.service";
import { CharacteristicInfo } from "../model/characteristic-info";
import { ProcessOption } from "../model/process-option";

@Component({
  selector: 'app-epcharacteristic-navigation',
  templateUrl: './epcharacteristic-navigation.component.html',
  styleUrls: ['./epcharacteristic-navigation.component.scss']
})
export class EPCharacteristicNavigationComponent implements OnInit {

  private _characteristicList: Array<CharacteristicInfo>;

  @Input()
  get characteristicList(): Array<CharacteristicInfo> { return this._characteristicList };
  set characteristicList(value: Array<CharacteristicInfo>) { this._characteristicList = value; this.groupChars(); };
  
  @Input() selectedProcessOption: ProcessOption; 
  @Input() id_specification: string;
  @Input() blockProductCaracChange: boolean;
  @Output() insertCharac = new EventEmitter<CharacteristicInfo>();
  @Output() deleteCharac = new EventEmitter<CharacteristicInfo>();
  @Output() selectCharac = new EventEmitter<CharacteristicInfo>();
  @Output() selectProcessOption = new EventEmitter<ProcessOption>();

  public characGroupsPrd: Array<{ groupSortKey: number, groupName: string, characs: Array<CharacteristicInfo> }> = [];
  public characGroupsPrc: Array<{ groupSortKey: number, groupName: string, characs: Array<CharacteristicInfo> }> = [];
  public formGroupProcessOption: FormGroup;

  constructor(private epService: EPService) { 

  }

  ngOnInit(): void { }

  private groupChars() {
    this.characGroupsPrd = [];
    this.characGroupsPrc = [];

    this.characteristicList.filter(charac => !charac.id_especif_componente_prc).forEach(charac => {
      let group = this.characGroupsPrd.find(item => charac.groupSortKey == item.groupSortKey && charac.groupName == item.groupName);
      if (!group) {
        group = { groupName: charac.groupName, groupSortKey: charac.groupSortKey, characs: [] };
        this.characGroupsPrd.push(group);
      }

      group.characs.push(charac);
    });

    this.characteristicList.filter(charac => charac.id_especif_componente_prc).forEach(charac => {
      let group = this.characGroupsPrc.find(item => charac.groupSortKey == item.groupSortKey && charac.groupName == item.groupName);
      if (!group) {
        group = { groupName: charac.groupName, groupSortKey: charac.groupSortKey, characs: [] };
        this.characGroupsPrc.push(group);
      }

      group.characs.push(charac);
    });
  }

  insertCharacteristic(charac: CharacteristicInfo) {
    charac.loading = true;
    
    this.epService.addCharacteristic(charac)
      .pipe(take(1))
      .subscribe(resp => {
        charac = resp;
        charac.loading = false;

        this.insertCharac.emit(charac);
      })
  }

  deleteCharacteristic(charac: CharacteristicInfo) {
    this.deleteCharac.emit(charac);
    charac.loading = true;
  }

  selectCharacteristic(charac: CharacteristicInfo): void {
    if (!charac.id_especif_carac || charac.hasCustomCmp) return;

    this.selectCharac.emit(charac);
  }

}
