import { map, take } from 'rxjs/Operators';
import { Injectable } from '@angular/core';
import { T2HttpClientService } from "../http/t2httpClient.service";
import { Observable } from "rxjs";
import { T2CoreException } from "../exception/exception";

@Injectable({
  providedIn: 'root'
})
export class T2ScriptService {

  constructor(private http: T2HttpClientService) { }

  public executeScript(scriptName: string, params: Map<string, string> | object): Observable<string> {

    if (!scriptName)
      throw Error("SCRIPT SERVICE, nome do script nao informado");

    let obj = {};
    if (params instanceof Map) {
      params?.forEach((value, key) => {
        obj[key] = value;
      });
    } else {
      obj = params;
    }

    const queryParams = new Map<string, string>();
    queryParams.set("className", scriptName);
    return this.http.post("core.scripting/execute", queryParams, obj || {})
      .pipe(take(1), map(resp => resp?.result));
  }
}
