<app-form-template #formTemplate formTitle="Requisição Departamental" [loaded]="loaded" helpUrl="https://tech2e.atlassian.net/l/cp/Jvf2xKze">
  <content class="flexColumn" style="height: 100%;">
    <div class="flexColumn" style="height: 100%;" *ngIf="!showVolumes" [nbSpinner]="saving">
      <div class="flexRow flexGap10">
        <div class="flexRow flexGap20">
          <div class="flexRow flexGap5">
            <button nbButton size="tiny" status="primary" [disabled]="lockButtons || !buttonInsert"
              (click)="insertReqGroup()">Inserir</button>
            <button nbButton size="tiny" status="primary" [disabled]="lockButtons || !selectedId || !buttonEdit"
              (click)="editReqGroup()">Editar</button>
            <button nbButton size="tiny" status="danger" [disabled]="lockButtons || !selectedId || !buttonDelete"
              (click)="deleteRecord()">Excluir</button>
          </div>
          <div class="flexRow flexGap5">
            <button nbButton size="tiny" status="success" [disabled]="lockButtons || !buttonApprove"
              (click)="changeStatusRequisitions('Aprovada')">Aprovar</button>
            <button nbButton size="tiny" status="danger" [disabled]="lockButtons || !buttonReprove"
              (click)="changeStatusRequisitions('Reprovada')">Reprovar</button>
          </div>
        </div>
        <div class="flexAlignRowEndCenter flexGap20 flexLength1">
          <nb-checkbox nbTooltip="Exibir também os registros com status encerrados" [(checked)]="showStatusEndsInfo"
            (checkedChange)="loadRequisitions()" [disabled]="loadingData">
            Encerradas
          </nb-checkbox>
          <div class="flexRow flexGap5">
            <button nbButton size="tiny" status="primary" [disabled]="lockButtons || !selectedId || !buttonReservation"
              (click)="reservation()">Reserva</button>
            <button nbButton size="tiny" status="primary" [disabled]="lockButtons || !buttonWriteOff"
              (click)="writeOffStock()">Baixa</button>
          </div>
        </div>
      </div>
      <div class="flexLength1" style="margin-top: 3px;">
        <app-t2grid #gridReq id="gridReq" rowSelection="multiple" [loading]="loadingData"
          [component]="gridContextComponent" (rowClick)="onGridRowClick($event)"
          (selectionChanged)="gridSelectionChanged($event)">
        </app-t2grid>
      </div>
    </div>
  </content>
</app-form-template>
