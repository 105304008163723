/**
 * Lista filha de quimicos
 * - Abrir em dialogo
 * - Criar controle de ordenação manual
 * - Criar componente para selecionar cores com compatibilidade de cilindros
 */

import { Component, Input, OnInit, AfterViewInit, ViewChild, Inject, ComponentRef, OnDestroy, Output, EventEmitter } from '@angular/core';
import { FormGroup } from "@angular/forms";
import { NbDialogService } from "@nebular/theme";
import { RowDragCallbackParams, RowDragEndEvent, RowDragMoveEvent } from "ag-grid-community";
import { catchError, take, takeUntil } from "rxjs/Operators";
import { CharacteristicComponent, ICharacteristicComponentInjection } from "src/app/bsn/epp/ep/characteristic-component";
import { CharacteristicInfo } from "src/app/bsn/epp/ep/model/characteristic-info";
import { T2gridComponent } from "src/app/core/cmp/t2grid/t2grid.component";
import { T2MessageService } from "src/app/core/t2-message.service";
import { EPPStructureLayerEditComponent } from "../eppstructure-layer-edit/eppstructure-layer-edit.component";
import { ChemicalLayer, FlxProductSpecificationService, Lamination, StructureLayer, SubstrateLayer } from "../flx-product-specification.service";
import { EPService } from "src/app/bsn/epp/ep/ep.service";
import { BehaviorSubject, Subject } from "rxjs";
import { EPPLaminationComponent } from "../epplamination/epplamination.component";
import { ActionService } from "src/app/core/action/action.service";
import { T2AutoFormComponent } from "src/app/core/form/t2-auto-form/t2-auto-form.component";

@Component({
  selector: 'app-eppstructure-layer',
  templateUrl: './eppstructure-layer.component.html',
  styleUrls: ['./eppstructure-layer.component.scss']
})
export class EPPStructureLayerComponent extends CharacteristicComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild("gridStruct", { static: false }) gridStruct: T2gridComponent;
  @ViewChild("eppLamination") eppLamination: EPPLaminationComponent;

  @Input()
  get id_specif_component(): string { return this._id_specif_component; }
  set id_specif_component(value: string) { this._id_specif_component = value; this.loadStructLayers(); }
  @Input() blockChange: boolean;
  @Input() id_specif: string;
  @Input() id_process: string;
  @Input() characInfoList: Array<CharacteristicInfo>;

  private _id_specif_component: string;
  id_specif_carac_chemical: string;
  id_specif_carac_lamination: string;
  structLayerList: StructureLayer[];
  laminationList: Lamination[];
  structView: "layer" | "list" = "list"
  structLoading: boolean = false;
  selectedLayer: StructureLayer = null;
  structShow: "struct" | "lamination" = "struct";
  laminationId_especif_carac: string;
  private unsubscribe = new Subject<void>();

  constructor(public flxEppService: FlxProductSpecificationService,
    @Inject("characCmpInjector") private characCmpInjector: BehaviorSubject<ICharacteristicComponentInjection>,
    private dialogService: NbDialogService, private messageService: T2MessageService,
    private epService: EPService, private actionService: ActionService) {
    super();
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  ngOnInit(): void {
    this.characCmpInjector.pipe(takeUntil(this.unsubscribe)).subscribe(cmpInjector => {
      this.id_specif = cmpInjector.id_specif;
      this.id_process = cmpInjector.id_process;
      this.blockChange = cmpInjector.blockChange;
      this.characInfoList = cmpInjector.characInfoList;
      this.id_specif_component = cmpInjector.id_specif_component;
    })
  }

  ngAfterViewInit(): void {
    this.sendComponentRef();
  }

  private loadStructLayers(): void {

    if (!this.id_specif_component) return;

    this.structLoading = true;
    let promList = new Array<Promise<void>>();

    promList.push(this.flxEppService.getStructureLayers(this.id_specif_component)
      .pipe(take(1),
        catchError(error => {
          this.structLoading = false;
          throw new Error(error);
        }))
      .toPromise().then(struct => {
        this.structLayerList = struct.layerList;
        this.id_specif_carac_chemical = struct.id_specif_carac_chemical;
        this.id_specif_carac_lamination = struct.id_specif_carac_lamination;

        if (this.structLayerList && this.structLayerList.length) {
          this.structLayerList = this.structLayerList.map(item => { return { ID: item.id_especif_carac_estr_item, chemicalType: item.chemical?.chemicalType, ...item }; })
          this.selectedLayer = this.structLayerList[0];
        }

        this.setStructGridData();
      })
    );

    promList.push(this.flxEppService.getLamination(this.id_specif_component)
      .pipe(take(1),
        catchError(error => {
          this.structLoading = false;
          throw new Error(error);
        }))
      .toPromise().then(lamList => {
        this.laminationList = lamList;
      })
    );

    this.laminationId_especif_carac = this.epService.getFullCharacList().find(c => c.datasetName == "flx_especif_carac_lamSeq")?.id_especif_carac;

    Promise.all(promList).then(() => {
      this.structLoading = false;
    });
  }

  private setStructGridColumns(): void {
    const columns = [
      {
        headerName: "Seq", type: 'numericColumn', field: "sortKey",
        rowDrag: (params: RowDragCallbackParams) => {
          return !this.blockChange;
        }
      },
      { headerName: "Estr", field: "structCode" },
      { headerName: "Tipo", field: "chemicalType" },
      { headerName: "Descrição", field: "description" },
      { headerName: "Gram", field: "grammage", type: 'numericColumn' },
      { headerName: "Gram min", field: "minGrammage", type: 'numericColumn' },
      { headerName: "Gram max", field: "maxGrammage", type: 'numericColumn' },
    ];

    this.gridStruct.t2GridOptions.onRowDragEnd = this.onGridStructRowDragEnd.bind(this);
    this.gridStruct.t2GridOptions.onRowDragMove = this.onGridStructRowDragMove.bind(this);
    this.gridStruct.setGridColumnDefs(columns);
  }

  private setStructGridData(): void {
    this.setStructGridColumns();
    this.gridStruct.setGridData(this.structLayerList, null);

    setTimeout(() => {
      if (!this.gridStruct.loadColumnGridState()) {
        this.gridStruct.autoSizeAllColumns(false);
      }
    }, 100);
  }

  setSelectLayer(sl: StructureLayer): void {
    this.selectedLayer = sl;
  }

  setStructView(view: "layer" | "list"): void {
    this.structView = view;

    if (view == "list") setTimeout(() => { this.setStructGridData(); }, 50);
  }

  setStructShow(show: "struct" | "lamination"): void {
    this.structShow = show;

    if (show == "struct")
      this.setStructView(this.structView);
  }

  onGridStructRowClick(event): void {
    const struct = this.structLayerList.find(item => item.id_especif_carac_estr_item == event.data.ID);
    if (struct) {
      this.selectedLayer = struct;
    }
  };

  onGridStructRowDragMove(e: RowDragMoveEvent) {
    let movingNode = e.node;
    let overNode = e.overNode;
    let rowNeedsToMove = movingNode !== overNode;
    if (rowNeedsToMove) {
      let movingData = movingNode.data;
      let overData = overNode!.data;
      let fromIndex = this.structLayerList.indexOf(movingData);
      let toIndex = this.structLayerList.indexOf(overData);
      let newLayerList = this.structLayerList.slice();

      moveInArray(newLayerList, fromIndex, toIndex);

      this.structLayerList = newLayerList;
      this.gridStruct.setGridData(newLayerList);

      function moveInArray(arr: any[], fromIndex: number, toIndex: number) {
        let element = arr[fromIndex];
        arr.splice(fromIndex, 1);
        arr.splice(toIndex, 0, element);
      }
    }
  }

  onGridStructRowDragEnd(e: RowDragEndEvent) {
    this.structLoading = true;

    this.flxEppService.updateStructureLayerSortOrder(this.structLayerList).pipe(take(1)).subscribe(layerList => {
      this.structLayerList = layerList;
      this.structLoading = false;

      this.gridStruct.redrawRows();
    }, error => {
      this.structLoading = false;
    })
  }

  onGridStructFilterChanged() {
    let filterActive = this.gridStruct.t2GridOptions.api.isAnyFilterPresent();

    this.gridStruct.t2GridOptions.api.setSuppressRowDrag(filterActive);
  }

  addStructureLayer() {
    let dlg = this.dialogService.open(EPPStructureLayerEditComponent, {
      context: {
        title: "Inclusão de Camada",
        structureLayerList: this.structLayerList
      }
    });

    dlg.onClose.pipe(take(1)).subscribe(save => {
      if (save) {
        this.structLoading = true;
        let newLayer = new StructureLayer();
        this.fillLayerAlterations(newLayer, dlg.componentRef);

        this.flxEppService.updateStructureLayer(this.id_specif_component, newLayer).pipe(take(1)).subscribe(resp => {
          this.loadStructLayers();
        }, error => {
          this.structLoading = false;
        })
      }
    })
  }

  editStructureLayer(params) {
    
    let datasetName: string;

    if (this.selectedLayer.layerType == "CHEMICAL") {
      datasetName = "flx_especif_carac_estr_i_quim";
    } else if (this.selectedLayer.layerType == "SUBSTRATE") {
      datasetName = "flx_especif_carac_estr_i_subst"
    } else if (this.selectedLayer.layerType == "METALIZATION") {
      datasetName = "flx_especif_carac_estr_i_metal";
    } else if (this.selectedLayer.layerType == "EXTRUSION") {
      datasetName = "flx_especif_carac_estr_i_extru";
    }

    let dlg = this.dialogService.open(T2AutoFormComponent, {
      context: {
        datasetName: datasetName,
        informationId: this.selectedLayer.id_especif_carac_estr_item,
        inDialog: true,
        changeTitle: false
      }, closeOnBackdropClick: false, closeOnEsc: false
    });

    let af = dlg.componentRef.instance;

    af.loadingCompleted.pipe(take(1)).subscribe(() => {
      af.buttonDelete = false;
    });

    af.saved.pipe(take(1)).subscribe(() => {
      this.fillLayerAlterationsFromAutoForm(this.selectedLayer, af);

      this.gridStruct.updateData([this.selectedLayer]);
      dlg.close();
    })
  }

  private fillLayerAlterationsFromAutoForm(layer: StructureLayer, af: T2AutoFormComponent) {
    let formGroup = af.formGroup;
    let description: string = formGroup.controls["flx_especif_carac_estr_item.descricao"].value;

    switch (layer.layerType) {
      case "CHEMICAL": {
        if (!layer.chemical) layer.chemical = new ChemicalLayer();
        layer.chemical.id_quimico_3 = formGroup.controls["flx_especif_carac_estr_i_quim.id_quimico_3"].value;
        layer.chemical.chemicalType = formGroup.controls["flx_especif_carac_estr_i_quim.tipo"].value;
        layer.chemical.side = formGroup.controls["flx_especif_carac_estr_i_quim.lado"].value;

        if (formGroup.controls["flx_especif_carac_estr_i_quim.tipo"].value == "Cor") {
          layer.chemical.printing = formGroup.controls["flx_especif_carac_estr_i_quim.impressao"].value;
        }

        if (formGroup.controls["flx_especif_carac_estr_i_quim.tipo"].value == "Adesivo") {
          layer.chemical.waitingTime = formGroup.controls["flx_especif_carac_estr_i_quim.tempoCura"].value;
        }

        layer.chemical.id_especif_carac_estr_i_subst = formGroup.controls["flx_especif_carac_estr_i_quim.id_especif_carac_estr_i_subst"].value;

        break;
      }
      case "SUBSTRATE": {
        if (!layer.substrate) layer.substrate = new SubstrateLayer();
        layer.substrate.id_item_3 = formGroup.controls["flx_especif_carac_estr_i_subst.id_item_3"].value;

        break;
      }
      case "EXTRUSION": {
        break;
      }
      case "METALIZATION": {
        break;
      }
    };


    layer.grammage = formGroup.controls["flx_especif_carac_estr_item.gramatura"].value;
    layer.minGrammage = formGroup.controls["flx_especif_carac_estr_item.gramMinima"].value;
    layer.maxGrammage = formGroup.controls["flx_especif_carac_estr_item.gramMaxima"].value;
    layer.description = description;
  }

  private fillLayerAlterations(layer: StructureLayer, cmpRef: ComponentRef<EPPStructureLayerEditComponent>) {
    let formGroup = cmpRef.instance.formGroup;
    let description: string = layer.id_especif_carac_estr_item ? this.structLayerList.indexOf(layer) + 1 + " " : this.structLayerList.length + 1 + " ";

    switch (cmpRef.instance.layerType) {
      case "CHEMICAL": {
        layer.layerType = "CHEMICAL";

        if (!layer.chemical) layer.chemical = new ChemicalLayer();
        layer.chemical.id_quimico_3 = formGroup.controls["id_quimico_3"].value;
        layer.chemical.chemicalType = formGroup.controls["tipo"].value;
        layer.chemical.side = formGroup.controls["lado"].value;

        if (layer.chemical.chemicalType == "Cor") {
          layer.chemical.printing = formGroup.controls["impressao"].value;
        }

        if (layer.chemical.chemicalType == "Adesivo") {
          layer.chemical.waitingTime = formGroup.controls["tempoCura"].value;
        }

        layer.chemical.id_especif_carac_estr_i_subst = formGroup.controls["id_especif_carac_estr_i_subst"].value;

        description += cmpRef.instance.chemicalTreeGrid.description;
        break;
      }
      case "SUBSTRATE": {
        layer.layerType = "SUBSTRATE";
        if (!layer.substrate) layer.substrate = new SubstrateLayer();
        layer.substrate.id_item_3 = formGroup.controls["id_item_3"].value;

        description += cmpRef.instance.substrateTreeGrid.description;
        break;
      }
      case "EXTRUSION": {
        layer.layerType = "EXTRUSION";
        description += "Extrusão";
        break;
      }
      case "METALIZATION": {
        layer.layerType = "METALIZATION";
        description += "Metalização";
        break;
      }
    };

    layer.id_especif_carac = this.characInfoList.find(c => c.datasetName == "flx_especif_carac_estr").id_especif_carac;

    if (!layer.chemical || layer.chemical.chemicalType != "Cor") {
      layer.grammage = formGroup.controls["gramatura"].value;
    }

    layer.minGrammage = formGroup.controls["gramMinima"].value;
    layer.maxGrammage = formGroup.controls["gramMaxima"].value;
    layer.description = description;

    if (!layer.id_especif_carac_estr_item) {
      layer.sortKey = this.structLayerList.length + 1;
    }
  }

  public deleteLayer() {
    this.messageService.showDialog({
      context: {
        topMessage: "ATENÇÃO",
        message: "Deseja excluir esse registro ?",
        actions: [{ description: "Excluir", status: "danger" }, { description: "Cancelar", status: "basic" }]
      }
    }).onClose.subscribe(resp => {
      if (resp == "Excluir") {
        this.structLoading = true;
        this.flxEppService.deleteStructureLayer(this.selectedLayer.id_especif_carac_estr_item).pipe(take(1)).subscribe(resp => {
          this.gridStruct.removeData([{ ID: this.selectedLayer.id_especif_carac_estr_item }]);
          this.structLayerList = this.structLayerList.filter(s => s.id_especif_carac_estr_item != this.selectedLayer.id_especif_carac_estr_item);

          this.selectedLayer = undefined;
          this.structLoading = false;
        }, error => {
          this.structLoading = false;
        })
      }
    })
  }

  public laminationSaved(list: Array<Lamination>) {
    this.laminationList = list;
  }

  public reloadLaminationList() {
    this.flxEppService.getLamination(this.id_specif_component).pipe(take(1)).subscribe(lamList => {
      this.laminationList = lamList;
    })
  }

  override saveInfo(): Promise<void> {
    if (this.structShow == "lamination")
      return this.eppLamination.save(true)
    else {
      return new Promise<void>((resolve, reject) => {
        resolve();
      })
    }
  }

  override sendComponentRef() {
    this.epService.addComponentRef(this);
  }
}
