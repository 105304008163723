<app-form-template #formTemplate [formTitle]="formTitle" [loaded]="loaded"
  [helpUrl]="'https://tech2e.atlassian.net/l/cp/1w3N5Nhf'">
  <header>

    <div [formGroup]="mainbarFormGroup" class="flexRow" [ngStyle.xs]="{'flex-direction': 'column' }">

      <div *ngIf="panelField.value" class="flexRow" [ngStyle.xs]="{'flex-direction': 'column' }">

        <!-- Filtro de data -->
        <div class="flexColumn" [ngStyle.xs]="{'width': '100%', 'display': showXs ? 'none' : 'flex'}">
          <div style="display: flex; gap: 5px;">
            <div>
              <input nbInput placeholder="Dt inicial" [nbDatepicker]="formpicker1" [formControl]="dateStartControl"
                fieldSize="small" [ngStyle]="{width:'155px'}" [ngStyle.xs]="{'width':'100%'}">
              <nb-datepicker #formpicker1 [max]="dateEndControl.value" [format]="format"></nb-datepicker>
            </div>

            <div>
              <input nbInput placeholder="Dt final" [nbDatepicker]="formpicker2" [formControl]="dateEndControl"
                fieldSize="small" [ngStyle]="{width:'155px'}" [ngStyle.xs]="{'width':'100%'}">
              <nb-datepicker #formpicker2 [min]="dateStartControl.value" [format]="format"></nb-datepicker>
            </div>
          </div>

          <div style="display: flex; margin-top:5px" [ngStyle.xs]="{width:'100%'}">
            <nb-select [formControl]="dateFilterField" placeholder="Pesquisar por..."
              style="margin-right: 5px; width: 100%" [ngStyle.xs]="{width:'100%'}" size="small">
              <nb-option *ngFor="let dateOption of dateOptionList" [value]="dateOption.field">{{dateOption.label}}
              </nb-option>
            </nb-select>

            <button nbButton size="small" status="primary" style="max-width: 32px;"
              [disabled]="!dateStartControl.value || !dateEndControl.value || !dateFilterField.value || !loaded || loading"
              [nbSpinner]="loading" nbSpinnerStatus="primary" nbSpinnerSize="tiny" (click)="filterTasksDate()">
              <nb-icon icon="refresh-outline"></nb-icon>
            </button>
          </div>
        </div>

        <!-- Busca Rápída -->
        <div class="flexColumn" [ngStyle]="{'margin-left': '20px', 'margin-right': '20px', 'width': '353px'}"
          [ngStyle.xs]="{'margin-top': '10px', 'width': '100%', 'display': showXs ? 'none' : 'flex' }">

          <input [formControl]="searchField" fieldSize="small" type="text" placeholder="Busca rápida" nbInput />

          <nb-select style="margin-top: 5px" [formControl]="devSelectedField" multiple size="small"
            placeholder="Seleção...">
            <nb-option-group *ngFor="let group of getDevGroup()" [title]="group.type">
              <nb-option *ngFor="let dev of group.devs" [value]="dev.name">{{dev.name}}</nb-option>
            </nb-option-group>
          </nb-select>


        </div>

      </div>

      <div class="flexRow" [ngStyle]="{'margin-left':'auto'}" [ngStyle.xs]="{'margin-left':'0px'}">
        <nb-select *ngIf="panelList.length > 1" [nbSpinner]="loading" [formControl]="panelField" size="small"
          placeholder="Painel..." [ngStyle]="{'min-width':'350px', 'font-size':'80%'}"
          [ngStyle.xs]="{'width':'calc(100% - 37px)', 'margin-top':'10px', 'margin-bottom':'10px'}">
          <nb-option *ngFor="let panel of panelList" [value]="panel.id">{{panel.description}}</nb-option>
        </nb-select>

        <div [ngStyle]="{'display': 'none' }"
          [ngStyle.xs]="{'display': 'flex', 'margin-left':'5px', 'margin-top':'10px'}">
          <button size="small" status="primary" nbButton (click)="showXs = !showXs">
            <nb-icon [icon]="showXs ? 'arrow-downward-outline' : 'arrow-upward-outline'"></nb-icon>
          </button>
        </div>
      </div>

    </div>

  </header>
  <content>
    <div style="display: flex; gap: 5px" class="deviceList">

      <!-- DISPOSITIVOS -->
      <div *ngFor="let dev of deviceList" class="device">
        <div class="devTitle" style="position: relative;">
          <div *ngIf="dev.tooltip" [nbTooltip]="dev.tooltip" style="font-size: 1.2em;"> {{dev.name}}</div>
          <div *ngIf="!dev.tooltip" style="font-size: 1.2em;"> {{dev.name}}</div>
          <div *ngIf="dev.detail"> {{dev.detail}}</div>

          <nb-badge *ngIf="dev.tasks.length" [text]="dev.taskCount > 50 ? '+50' : dev.tasks.length + ''" status="danger"
            [nbTooltip]="dev.taskCount" style="margin: 2px;"></nb-badge>

        </div>



        <div style="height: calc(100% - 63px); scrollbar-width: none;"
          [style.overflow]="dev.scrollFixed ? 'visible' : 'auto'">
          <!-- TAREFAS -->
          <div *ngFor="let task of dev.tasks" [ngClass]="{
          'task': true,
          'task-ontime': task.term === 'ontime',
          'task-late': task.term === 'late',
          'task-prev-late': task.term === 'prev-late',
          'task-urgent': task.urgent === true
        }">
            <div style="display: flex;">
              <div style="font-size: 150%; font-weight: bold; min-width: 30px; margin-right: 5px; ">{{task.index}}
              </div>

              <div *ngIf="(task.prodOrder || task.parent?.prodOrder) != null"
                [ngClass]="{ 'showActionArea': task.id_ordemprod }"
                [ngStyle]="{'margin-bottom': task.salesOrderDate ? '10px' : '0px'}"
                (click)="showTreePopUp( task, 'OP');" style="padding: 0px 5px 0px 5px;">
                <div class="flexAlignRowCenter" style="font-size: 70%; margin-bottom: -7px;">Ord produção</div>
                <div class="order">{{task.prodOrder || task.parent?.prodOrder}}</div>
              </div>
              <div *ngIf="(task.purchaseOrder || task.parent?.purchaseOrder) != null"
                [ngClass]="{'showActionArea': task.id_pedidoCompra}"
                style="margin-left: auto; text-align: end;  padding: 0px 5px 0px 5px;"
                (click)="showTreePopUp( task, 'PC'); $event.preventDefault();">
                <div style="font-size: 70%; margin-bottom: -7px;">Ped Compra</div>
                <div class="order">{{task.purchaseOrder || task.parent?.purchaseOrder}}</div>
                <div style="font-size: 80%; margin-top: -7px;">
                  {{task.purchaseOrderDate || task.parent?.purchaseOrderDate | date:'shortDate'}}</div>
              </div>
              <div *ngIf="(task.outsourcing || task.parent?.outsourcing) != null">
                <div style="font-size: 70%; margin-bottom: -7px;">Terceirização</div>
                <div class="order">{{task.outsourcing || task.parent?.outsourcing}}</div>
              </div>
              <div *ngIf="(task.salesOrder || task.parent?.salesOrderDate) != null"
                [ngClass]="{ 'showActionArea': task.id_pedidovenda }"
                style="margin-left: auto; text-align: end;  padding: 0px 5px 0px 5px;" class=""
                (click)="showTreePopUp( task, 'PV'); $event.preventDefault();">
                <div style="font-size: 70%; margin-bottom: -7px;">{{task?.outsourcing || task?.parent?.outsourcing ?
                  "Remessa" : 'Ped Venda'}}</div>
                <div class="order">{{task.salesOrder || task.parent?.salesOrder}}</div>
                <div style="font-size: 80%; margin-top: -7px;">
                  {{task.salesOrderDate || task.parent?.salesOrderDate | date:'short'}}</div>
              </div>
            </div>
            <div *ngIf="(task.title || task.parent?.title ) != null" class="orderTitle">
              {{task.title || task.parent?.title}}
            </div>
            <div *ngIf="(task.customer || task.parent?.customer) != null" class="orderTitle">
              {{task.customer || task.parent?.customer}}
            </div>
            <div *ngIf="(task.maxDate || task.parent?.maxDate) != null">
              <div style="font-size: 70%; margin-bottom: -7px;">Data máxima</div>
              <div>{{task.maxDate || task.parent?.maxDate | date:'short'}}</div>
            </div>
            <div *ngIf="(task.supplier || task.parent?.supplier) != null" class="orderTitle">
              <div style="font-size: 70%; margin-bottom: -7px;">Fornecedor</div>
              {{task.supplier || task.parent?.supplier}}
            </div>
            <div style="display: flex;">
              <div *ngIf="(task.planStartDate || task.parent?.planStartDate)  != null">
                <div style="font-size: 70%; margin-bottom: -7px;">Início previsto</div>
                <div>{{task.planStartDate || task.parent?.planStartDate | date:'short'}}</div>
              </div>
              <div *ngIf="(task.planEndDate || task.parent?.planEndDate) != null"
                style="margin-left: auto; text-align: end;">
                <div style="font-size: 70%; margin-bottom: -7px;">Fim previsto</div>
                <div>{{task.planEndDate || task.parent?.planEndDate | date:'short'}}</div>
              </div>
            </div>
            <div *ngFor="let item of task.items; let i = index" class="taskItem">
              <div *ngIf="item.title"><strong>{{i + 1}}</strong> {{item.title}}</div>
              <div *ngIf="item.detail" style="font-size: 80%;">{{item.detail}}</div>
            </div>
            <div *ngFor="let item of task.details">
              <strong>{{item.label}}</strong> {{item.text}}
            </div>
            <div>
              {{task.detail || task.parent?.detail}}
            </div>

          </div>
        </div>
      </div>
    </div>
  </content>

  <!-- <footer> Rodapé </footer> -->
</app-form-template>
