import { take } from 'rxjs/Operators';
import { Component, Input, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { T2SecurityService } from "src/app/core/security/t2security.service";
import { FormTemplateComponent } from "src/app/core/cmp/form-template/form-template.component";
import { T2gridComponent } from "src/app/core/cmp/t2grid/t2grid.component";
import { T2HttpClientService } from "src/app/core/http/t2httpClient.service";
import { DecimalPipe } from "@angular/common";
import { IRowNode, RowNode } from "ag-grid-community";

@Component({
  selector: 'app-manage-item-request',
  templateUrl: './manage-item-request.component.html',
  styleUrls: ['./manage-item-request.component.scss']
})
export class ManageItemRequestComponent implements OnInit, AfterViewInit {

  @Input() get id() { return this.id_productionOrder; } set id(value) { this.id_productionOrder = value; }
  @Input() id_productionOrder: string = 'zD20220609H145927332R000013556';
  @Input() title: string = "Requisições para Ordem de Produção";
  @Input() showHeader: boolean = true;
  @Input() inDialog: boolean;

  loaded = true;
  @ViewChild("formTemplate", { static: true }) formTemplate: FormTemplateComponent;
  @ViewChild("gridReq", { static: true }) gridReq: T2gridComponent;
  @ViewChild("gridStock", { static: true }) gridStock: T2gridComponent;

  componentNeeds: Array<CommittedComponent>;

  selectedComponent: CommittedComponent;
  selectedSuggItem: SuggestedItem = null;

  constructor(private securityService: T2SecurityService,
    private httpClient: T2HttpClientService,) { }

  ngOnInit(): void {

  }

  ngAfterViewInit(): void {
    this.securityService.accessLoaded()
      .pipe(
        take(1)
      ).subscribe(() => {
        if (this.formTemplate.validateAccess(['zD20220203H171937600R000000005'], 'full')) {
          this.loaded = true;

          this.setGridColumns();
          this.loadData();
        }
      });
  }


  private setGridColumns(): void {
    this.gridReq.setGridColumnDefs([
      { headerName: "item", field: "description" },
      { headerName: "Gram", field: "gramature", type: "numericColumn" },
      { headerName: "Larg", field: "width", type: "numericColumn" },
      { headerName: "Qtd Empenhar", field: "qttRequest", type: "numericColumn", editable: true },
      { headerName: "Qtd Nec", field: "qttExpected", type: "numericColumn" },
      {
        headerName: "Disponível", field: "qttAvailable", type: "numericColumn",
        cellRenderer: (params) => {

          const bgColor = params.value > 0 ? "#000080bf" : (params.value < 0 ? "rgba(255, 0, 0, 0.65)" : "");
          const color = params.value != 0 ? "white" : "";

          return `
            <div class="flexColumn" style="background-color: ${bgColor}; color: ${color}; font-weight: bold; height: calc(100% - 2px); margin-top: 1px; padding: 0px 6px; border-radius: 20px;">
              ${new DecimalPipe("pt-BR").transform(params.value, "1.0-4")}
            </div>
          `;
        },
      },
      { headerName: "Estoque", field: "qttStock", type: "numericColumn" },
      { headerName: "Requisições", field: "qttInRequest", type: "numericColumn" },
      { headerName: "Solic compra", field: "qttPurchaseRequest", type: "numericColumn" },
      { headerName: "Ped compra", field: "qttBought", type: "numericColumn" },
      // { headerName: "Válido", field: "valid", type: "checkColumn"},
    ]);

    this.gridStock.setGridColumnDefs([
      { headerName: "Dt Prevista", field: "movDate", type: ["dateColumn"] },
      { headerName: "Qtd Entrada", field: "inputQty", type: "numericColumn" },
      { headerName: "Qtd Saída", field: "outputQty", type: "numericColumn" },
      {
        headerName: "Saldo futuro", field: "futureBalance", type: "numericColumn",
        cellRenderer: (params) => {

          const bgColor = params.value > 0 ? "#000080bf" : (params.value < 0 ? "rgba(255, 0, 0, 0.65)" : "");
          const color = params.value != 0 ? "white" : "";

          return `
            <div class="flexColumn" style="background-color: ${bgColor}; color: ${color}; font-weight: bold; height: calc(100% - 2px); margin-top: 1px; padding: 0px 6px; border-radius: 20px;">
              ${new DecimalPipe("pt-BR").transform(params.value, "1.0-4")}
            </div>
          `;
        },
      },
      { headerName: "Origem", field: "origin" },
      { headerName: "Código", field: "originCode" },
      { headerName: "Status", field: "originStatus" },
      { headerName: "Tarefa", field: "originTask" },
      { headerName: "Conta", field: "account" },
    ]);
  }

  private loadData(): void {

    if (!this.id_productionOrder) return;

    const params = new Map<string, string>();
    params.set("id_ordemProd", this.id_productionOrder);
    this.httpClient.get("bsn.production.commitment/getComponentList", params).pipe(take(1)).subscribe(resp => {
      if (resp.committedComponent && !Array.isArray(resp.committedComponent)) {
        resp.committedComponent = [resp.committedComponent];
      }

      resp.committedComponent?.forEach(cmp => {
        cmp.qttCommitted = +cmp.qttCommitted;
        cmp.qttExpected = +cmp.qttExpected;
      });

      this.componentNeeds = resp.committedComponent;
    });

  }

  selecComponent(component: CommittedComponent) {
    this.selectedComponent = component;

    this.gridStock.setGridData([], null);
    this.gridReq.setGridData([], null);
    this.gridReq.loading = true;

    const params = new Map<string, string>();
    params.set("id_ordemProd_tarefa_cmp_cons", this.selectedComponent.id_productionOrder_task_cmp_cons);
    this.setSuggParams(params);
    this.httpClient.get("bsn.production.commitment/getComponentSugestions", params).pipe(take(1)).subscribe(resp => {

      if (resp.suggestedItem && !Array.isArray(resp.suggestedItem)) {
        resp.suggestedItem = [resp.suggestedItem];
      }

      const sugItems: Array<SuggestedItem> = resp.suggestedItem || [];
      sugItems?.forEach((item: SuggestedItem) => {
        item.qttAvailable = +item.qttAvailable;
        item.qttBought = +item.qttBought;
        item.qttExpected = +item.qttExpected;
        item.qttInRequest = +item.qttInRequest;
        item.qttPurchaseRequest = +item.qttPurchaseRequest;
        item.qttRequest = +item.qttRequest || 0.0;
        item.qttStock = +item.qttStock || 0.0;
      });

      this.gridReq.setGridData(sugItems, null);
      this.gridReq.loading = false;

      const row: IRowNode = this.gridReq.getFirstRowNode();
      this.gridRecSelectRow({ row: row?.rowIndex, data: row?.data });
    });


  }

  generateItemRequest(): void {
    this.gridReq.t2Grid.api.stopEditing();

    if (!this.selectedSuggItem) return;

    const params = new Map<string, any>();
    params.set("id_productionOrder", this.id_productionOrder);
    params.set("id_task", this.selectedComponent.id_task);
    params.set("id_productionOrder_task_cmp_cons", this.selectedComponent.id_productionOrder_task_cmp_cons);
    if (this.selectedComponent.plannedDate) params.set("plannedDate", this.selectedComponent.plannedDate);

    const focusedRow = this.gridReq.getFocusedRow();
    const rows = this.gridReq.getAllRowNodes();
    rows.forEach(row => {
      const item = row.data;
      if (row.data.qttRequest) {
        params.set("id_item", row.data.id_item);
        params.set("quantity", row.data.qttRequest);
        this.httpClient.get("production.itemRequest/generateItemRequest", params).pipe(take(1)).subscribe(resp => {
          row.data.qttRequest = 0;
          this.loadData();
        });
      }
    });

    setTimeout(() => {
      this.selecComponent(this.selectedComponent);
    }, 1000);


  }

  private setSuggParams(params) {
    const obj = {};
    this.selectedComponent?.filterField?.forEach(field => {
      if (field.name && field.value != null && field.value != undefined) {
        obj[field.name] = field.value;
      }
    });

    if (Object.keys(obj).length) params.set("suggParams", JSON.stringify(obj));
  }

  gridRecSelectRow(row: any): void {
    if (row?.data) {
      this.selectedSuggItem = row.data;
    } else {
      this.selectedSuggItem = null;
    }

    this.updateFutureStock(this.selectedSuggItem?.id_item);
  }

  private updateFutureStock(id_item: string): void {

    if (id_item) {
      const params = new Map<string, string>();
      params.set("id_item", id_item);
      this.httpClient.get(`stock.stockMov/futureMovement`, params)
        .pipe(take(1))
        .subscribe((resp: any) => {
          resp.futureMovement = resp.futureMovement || [];
          if (!Array.isArray(resp.futureMovement)) {
            resp.futureMovement = [resp.futureMovement]
          }

          let qttBalance = this.selectedSuggItem.qttStock;
          resp.futureMovement.forEach(mov => {
            qttBalance = (qttBalance + (+mov.inputQty) - (+mov.outputQty));
            mov.futureBalance = qttBalance;
          });

          this.gridStock.setGridData(resp.futureMovement, null);
          this.gridStock.loading = false;
        }, error => {
          this.gridStock.setGridData([], null);
          this.gridStock.loading = false;
        });
    } else {
      this.gridStock.setGridData([], null);
    }
  }

}

export class CommittedComponent {
  id_component: string;
  id_productionOrder_task_cmp_cons: string;
  id_task: string;
  id_unit: string;

  description: string;
  componentDetail?: string;
  hasPlan?: boolean;

  qttCommitted: number;
  qttExpected: number;
  plannedDate?: Date;
  task?: string;

  filterField?: Array<any>;
}

export class SuggestedItem {
  id_item: string;

  description: string;
  qttAvailable?: number;
  qttBought?: number;
  qttExpected?: number;
  qttInRequest?: number;
  qttPurchaseRequest?: number;
  qttStock?: number;
  qttRequest?: number = 0.0;

  gramature?: number;
  width?: number;
  valid: boolean;
}
