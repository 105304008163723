import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { T2gridComponent } from "../../cmp/t2grid/t2grid.component";
import { RowStyle, GridApi } from "ag-grid-community";
import { ActivatedRoute, Router } from "@angular/router";

@Component({
  selector: 'app-tela1',
  templateUrl: './tela1.component.html',
  styleUrls: ['./tela1.component.scss'],
})
export class Tela1Component {


}
