<div style="min-width: 95vw; min-height: 80vh; height: 100%; width: 100%;">
  <app-form-template #formTemplate formTitle="Requisição Departamental"
    [loaded]="code != null && id_requisicaoDepartAgrup != null && id_requisicaoDepartAgrup != undefined"
    [changeTitle]="false">
    <content class="flexColumn flexGap10" style="height: 100%;" [nbSpinner]="saving">

      <!-- Barra de ações -->
      <div class="flexRow flexGap5">
        <button nbButton size="tiny" status="primary" (click)="saveAutoform()">Salvar</button>
        <button nbButton size="tiny" status="basic" (click)="close()">Fechar</button>
        <button nbButton size="tiny" status="danger" [disabled]="!buttonDelete || insert"
          (click)="deleteRecord()">Excluir</button>

        <button *ngIf="btnCanRequestApprove" nbButton size="tiny" status="basic" (click)="requestApprove()">Solicitar
          Aprovação</button>
        <button *ngIf="btnCanReturnToEdit" nbButton size="tiny" status="basic" (click)="returnGroupToEdit()">Retornar
          para
          Edição</button>
      </div>

      <!-- Campos do grupo -->
      <app-view-template #viewTemplate [layout]="layout" [data]="data" [FormGroup]="formGroup"></app-view-template>

      <!-- Lista de requisições -->
      <div class="flexColumn flexGap5" style="height: 100%; margin-top: 5px;">
        <!-- Ações das requisiões -->
        <div class="flexAlignRowEndCenter flexGap6">
          <button #btnAddReq nbButton outline size="small" status="primary"
            [disabled]="!insert && !btnCanRequestApprove" (click)="addNewReqItem()">
            <nb-icon icon="plus-circle-outline"></nb-icon>
          </button>
          <button #btnDelReq nbButton outline size="small" status="danger"
            [disabled]="(!insert && !btnCanRequestApprove) || !requisitionList.length" (click)="deleteReqItem()">
            <nb-icon icon="trash-2-outline"></nb-icon>
          </button>
        </div>

        <!-- Grid -->
        <app-t2grid class="flexLength1" #gridReq id="gridReq" [sortable]="true"
          (rowClick)="onGridRowClick($event)"></app-t2grid>
      </div>

    </content>
  </app-form-template>
</div>
