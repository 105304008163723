<ag-grid-angular
  #t2TreeGrid
  id="t2TreeGrid"
  [treeData]="true"
  [animateRows]="true"
  [ngClass]="{'ag-theme-balham-dark' : sessionTheme === 'dark', 'ag-theme-balham' : sessionTheme != 'dark'} "
  style="width: 100%; height: 100%;"
  [autoGroupColumnDef]="autoGroupColumnDef"
  [suppressRowClickSelection]="suppressRowClickSelection"
  [gridOptions]="t2GridOptions"
  [headerHeight]="headerHeight"
  [rowHeight]="rowHeight"
  [getDataPath]="getDataPath"
  [isRowSelectable]="isRowSelectable"
  (gridReady)="onGDAFXReady($event)"
  (selectionChanged)="onGDSelectionChanged($event)"
  (rowClicked)="onGDRowClicked($event)"
>
</ag-grid-angular>