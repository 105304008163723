import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Warning } from "../../warning/model/warning";
import { ColorService } from "../../color.service";
import { DialogItemSelectionComponent, GroupSelection, ItemSelection } from "../../cmp/dialog-item-selection/dialog-item-selection.component";
import { T2AccessItem } from "../../security/model/t2accessItem";
import { take } from "rxjs/Operators";
import { ActionService } from "../../action/action.service";
import { NbDialogService } from "@nebular/theme";
import { T2MessageService } from "../../t2-message.service";

@Component({
  selector: 'app-notification-detail',
  templateUrl: './notification-detail.component.html',
  styleUrls: ['./notification-detail.component.scss']
})
export class NotificationDetailComponent {

  @Input() index: number;
  @Input() warning: Warning;
  @Input() public datasetActions = new Map<string, Array<T2AccessItem>>();

  @Output() finish = new EventEmitter<'Aceito' | 'Recusado'>();

  constructor(private actionService: ActionService, private dialogService: NbDialogService, private messageService: T2MessageService, public colorService: ColorService) { }

  finishWarning(status: 'Aceito' | 'Recusado') {
    this.finish.emit(status);

  }

  getActionList() {

    console.log(this.warning);

    let groups: Array<GroupSelection> = [];
    let items: Array<ItemSelection> = [];
    let groupName = null;
    let dsActions = this.datasetActions.get(this.warning.id_dataset);

    dsActions?.forEach(action => {
      groupName = action.datasetDescription || action.datasetGroup || groupName;
      items.push(
        {
          id_item: action.id,
          itemName: action.datasetActionDescription,
          selected: false
        });

      if (groups.find(g => g.id_group == action.id_dataset_group)) {
        return;
      }

      groups.push(
        {
          id_group: action.id_dataset_group,
          groupName: null,
          selected: false,
          items: items
        });
    });

    const dialogActions = this.dialogService.open(
      DialogItemSelectionComponent,
      {
        context: {
          title: groupName,
          groupSelection: 'none',
          itemSelection: 'single',
          itemFromDifGroups: false,
          groups: groups,
          useConfirmButton: false,
          buttonCase: 'upper',
          buttonSize: 'large'
        },
      }
    );

    dialogActions.onClose.pipe(take(1)).subscribe(resp => {
      if (resp) {
        let i = items.find(item => item.selected);

        let id_action = null;
        if (i) {
          id_action = i.id_item;
        }

        const action: T2AccessItem = dsActions.find(a => a.id == id_action);
        let params = new Map<string, string>();
        params.set("id", this.warning.id_informationRecord);
        params.set("datasetName", action.datasetName);

        this.executeAction(action, params);
      }
    })
  }

  private executeAction(action: T2AccessItem, params: Map<string, string>) {
    let obs = this.actionService.executeAction(action, params);
    if (obs) {
      obs.pipe(take(1)).subscribe(resp => {
        this.messageService.showToast("Ação executada com sucesso", action.description, "success");
      }, error => {
        this.messageService.showToastError(error);
        console.error(error);
      });
    }
  }
}
