import { Injectable } from '@angular/core';
import { Router } from "@angular/router";
import { ActionService } from "../action/action.service";
import { T2AccessItem, T2AccessItemType } from "../security/model/t2accessItem";

@Injectable({
  providedIn: 'root'
})
export class MenuService {

  constructor(private route: Router, private actionService: ActionService) { }

  selectItem(item: T2AccessItem) {
    if (item) {
      if (item.id_dataset) {
        this.route.navigateByUrl("/dataset/" + (item.datasetGroupDatasetName || item.datasetName), {});
      } else if (item.actionCommand?.trim().startsWith("tpw")) {
        this.actionService.executeAction(item, null);
        // const url = item.actionCommand.trim().substring(4).trim();
        // this.route.navigateByUrl(`/${url}`, {});
      } else if (item.type == T2AccessItemType.ACTION_ANALYSIS) {
        this.route.navigateByUrl("/analysis/" + item.id_report, {});
      } else {
        alert("Não Implementado");
        return;
      }
    }
  }
}
