<div class="flexColumn" style="height: 100%; gap: 8px">
  <!--
  <div class="notificationTitle">
    <span class="notificationTitleFont">Avisos </span>
  </div>
  <div class="lineSeparator">
    <div class="T2GrayArea" style="width: 100%; height: 3px;"></div>
  </div>
  -->
  <div *ngIf="!warningList.length && !warningListSolved.length" style="display: flex; flex-direction: column; align-items: center; justify-content: center; gap: 20px; height: 100%;">
    <div class="notificationTitleFont"> Você não possui avisos </div>
    <div>
      <nb-icon icon="smiling-face-outline" style="width: 45px; height: 45px;" />
    </div>
  </div>
  <div *ngIf="warningList.length || warningListSolved.length" class="notification flexColumn flexGap5" style="overflow-y: auto;" [nbSpinner]="loading" nbSpinnerSize="giant"
    nbSpinnerStatus="primary">
    <div *ngIf="warningList.length" class="groupSelectionTitle">Avisos aguardando uma ação</div>
    <div *ngFor="let warning of warningList; let i = index">
      <app-notification-detail [index]="i+1" [warning]="warning" [datasetActions]="datasetActions"
        (finish)="finishWarning(warning, $event)" />
      <!--
      <div class="flexColumn warningDetail" [nbSpinner]="warning.saving" nbSpinnerStatus="primary">
        {{i}}
        <div class="flexColumn flexGap5" style="flex: 1">
          <div style="display: flex; justify-content: space-between; align-items: center; gap: 10px;">
            <span style="line-height: 0.8rem; font-size: 0.8rem; font-weight: bold;">{{warning.description}}</span>
            <div style="display: flex; gap: 5px;">
              <div class="warningType" [ngClass]="colorService.getClass(warning.color)">
                <span>{{warning.warningType}}</span>
              </div>
              <div>
                <nb-icon icon="more-vertical-outline" nbTooltip="Ações" (click)="getActionList(warning)"
                  style="--icon-font-size: 1rem;"></nb-icon>
              </div>
            </div>
          </div>
          <div>
            <div *ngIf="warning.policyNote" class="defaultNote">
              <span>{{warning.policyNote}}</span>
            </div>
            <div *ngIf="warning.warningNote" class="defaultNote">
              <span>{{warning.warningNote}}</span>
            </div>
          </div>
          <div class="flexAlignRowSpaceBetweenCenter flexGap3">
            <div class="flexRow flexGap10 flexLength1">
              <div *ngIf="warning.startDate" class="flexColumn" style="line-height: 0.8rem;">
                <span class="detailBoxTitle">Prazo Inicial</span>
                <span class="detailBoxValue">{{warning.startDate | date: 'shortDate'}}</span>
              </div>

              <div *ngIf="warning.maxDate" class="flexColumn" style="line-height: 0.8rem;">
                <span class="detailBoxTitle">Prazo Final</span>
                <span class="detailBoxValue">{{warning.maxDate | date: 'shortDate'}}</span>
              </div>

              <div *ngIf="warning.completionDate" class="flexColumn" style="line-height: 0.8rem;">
                <span class="detailBoxTitle">Finalizado em</span>
                <span class="detailBoxValue">{{warning.completionDate | date: 'shortDate'}}</span>
              </div>
            </div>
            <div *ngIf="!warning.completionDate" class="flexRow flexGap5">
              <div class="actionsButtons actionAccept" (click)="finishWarning(warning, 'Aceito')">{{warning.acceptName}}
              </div>
              <div class="actionsButtons actionDecline" (click)="finishWarning(warning, 'Recusado')">
                {{warning.declineName}}</div>
            </div>
          </div>
        </div>
      </div>

    -->
      <div *ngIf="i < warningList.length -1" class="lineSeparator">
        <div class="T2GrayArea" style="width: 30%; height: 3px;"></div>
      </div>
    </div>
    <div *ngIf="warningListSolved.length" class="groupSelectionTitle" style="margin-top: 10px;">Avisos encerrados</div>
    <div *ngFor="let warning of warningListSolved; let i = index">
      <app-notification-detail [index]="i+1" [warning]="warning" [datasetActions]="datasetActions"
        (finish)="finishWarning(warning, $event)" />
      <!--
      <div class="flexColumn warningDetail warningDetailFinished" [nbSpinner]="warning.saving"
        nbSpinnerStatus="primary">

        <div class="flexColumn flexGap5" style="flex: 1">
          <div style="display: flex; justify-content: space-between; align-items: center; gap: 10px;">
            <span style="line-height: 0.8rem; font-size: 0.8rem; font-weight: bold;">{{warning.description}}</span>
            <div style="display: flex; gap: 5px">
              <div class="warningType" [ngClass]="colorService.getClass(warning.color)">
                <span>{{warning.warningType}}</span>
              </div>
              <div>
                <nb-icon icon="more-vertical-outline" nbTooltip="Ações" (click)="getActionList(warning)"
                  style="--icon-font-size: 1rem;"></nb-icon>
              </div>
            </div>
          </div>
          <div>
            <div *ngIf="warning.policyNote" class="defaultNote">
              <span>{{warning.policyNote}}</span>
            </div>
            <div *ngIf="warning.warningNote" class="defaultNote">
              <span>{{warning.warningNote}}</span>
            </div>
          </div>
          <div class="flexAlignRowSpaceBetweenCenter flexGap3">
            <div class="flexRow flexGap10 flexLength1">
              <div *ngIf="warning.startDate" class="flexColumn" style="line-height: 0.8rem;">
                <span class="detailBoxTitle">Prazo Inicial</span>
                <span class="detailBoxValue">{{warning.startDate | date: 'shortDate'}}</span>
              </div>

              <div *ngIf="warning.maxDate" class="flexColumn" style="line-height: 0.8rem;">
                <span class="detailBoxTitle">Prazo Final</span>
                <span class="detailBoxValue">{{warning.maxDate | date: 'shortDate'}}</span>
              </div>

              <div *ngIf="warning.completionDate" class="flexColumn" style="line-height: 0.8rem;">
                <span class="detailBoxTitle">Finalizado em</span>
                <span class="detailBoxValue">{{warning.completionDate | date: 'shortDate'}}</span>
              </div>
            </div>
            <div *ngIf="!warning.completionDate" class="flexRow flexGap5">
              <div class="actionsButtons actionAccept" (click)="finishWarning(warning, 'Aceito')">
                {{warning.acceptName}}
              </div>
              <div class="actionsButtons actionDecline" (click)="finishWarning(warning, 'Recusado')">
                {{warning.declineName}}</div>
            </div>
          </div>
        </div>
      </div>
-->
      <div *ngIf="i < warningListSolved.length -1" class="lineSeparator">
        <div class="T2GrayArea" style="width: 30%; height: 3px;"></div>
      </div>
    </div>
  </div>
</div>
