import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CylinderSchedulerComponent } from './cylinder-scheduler/cylinder-scheduler.component';
import { T2NebularModule } from "src/app/t2nebular.module";
import { T2CoreModule } from "src/app/core/t2core.module";
import { CilSmallBoxRendererComponent } from './cil-small-box-renderer/cil-small-box-renderer.component';
import { CilStatusRendererComponent } from './cil-status-renderer/cil-status-renderer.component';
import { EditCylinderComponent } from './cylinder-scheduler/edit-cylinder/edit-cylinder.component';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { EngraveInvoiceComponent } from './cylinder-scheduler/engrave-invoice/engrave-invoice.component';
import { CilEpRendererComponent } from './cil-ep-renderer/cil-ep-renderer.component';
import { EngraveMotiveComponent } from './cylinder-scheduler/engrave-motive/engrave-motive.component';
import { CylExistingEngravingComponent } from './cylinder-scheduler/cyl-existing-engraving/cyl-existing-engraving.component';
import { CylHistoryComponent } from './cylinder-scheduler/cyl-history/cyl-history.component';
import { ScheduledProdOrderComponent } from './cylinder-scheduler/scheduled-prod-order/scheduled-prod-order.component';
import { AddCylinderComponent } from './cylinder-scheduler/add-cylinder/add-cylinder.component';
import { SupplierComponent } from './cylinder-scheduler/supplier/supplier.component';
import { EppchemicalCompatCylinderComponent } from './epp/eppchemical-compat-cylinder/eppchemical-compat-cylinder.component';
import { ComponentService } from "src/app/core/cmp/component.service";
import { EppchemicalCompatCylinderSelectionComponent } from './epp/eppchemical-compat-cylinder-selection/eppchemical-compat-cylinder-selection.component';
import { EppstructureLayerChemicalComponent } from "./epp/eppstructure-layer-chemical/eppstructure-layer-chemical.component";
import { EppstructureLayerSubstrateComponent } from "./epp/eppstructure-layer-substrate/eppstructure-layer-substrate.component";
import { LayerDetailsComponent } from "./epp/eppstructure-layer/layer-details/layer-details.component";
import { EPPStructureLayerComponent } from "./epp/eppstructure-layer/eppstructure-layer.component";
import { EPPStructureLayerEditComponent } from './epp/eppstructure-layer-edit/eppstructure-layer-edit.component';
import { EPPAssemblyComponent } from './epp/eppassembly/eppassembly.component';
import { EPPConjugateAssemblyComponent } from './epp/eppconjugate-assembly/eppconjugate-assembly.component';
import { EPPLaminationComponent } from './epp/epplamination/epplamination.component';
import { CylinderSwapComponent } from './cylinder-scheduler/cylinder-swap/cylinder-swap.component';

@NgModule({
  declarations: [
    CylinderSchedulerComponent,
    CilSmallBoxRendererComponent,
    CilStatusRendererComponent,
    EditCylinderComponent,
    EngraveInvoiceComponent,
    CilEpRendererComponent,
    EngraveMotiveComponent,
    CylExistingEngravingComponent,
    CylHistoryComponent,
    ScheduledProdOrderComponent,
    AddCylinderComponent,
    SupplierComponent,
    EppchemicalCompatCylinderComponent,
    EppchemicalCompatCylinderSelectionComponent,
    EppstructureLayerChemicalComponent,
    EppstructureLayerSubstrateComponent,
    LayerDetailsComponent,
    EPPStructureLayerComponent,
    EPPStructureLayerEditComponent,
    EPPAssemblyComponent,
    EPPConjugateAssemblyComponent,
    EPPLaminationComponent,
    CylinderSwapComponent
  ],
  imports: [
    CommonModule,
    T2NebularModule,
    T2CoreModule,
    FormsModule,
    ReactiveFormsModule
  ],
  exports: [
    EPPConjugateAssemblyComponent
  ]
})
export class FlexProductionModule {
  constructor(private cmpService: ComponentService) {
    cmpService.registerType("EppchemicalCompatCylinderComponent", EppchemicalCompatCylinderComponent);
    cmpService.registerType("EPPAssemblyComponent", EPPAssemblyComponent);
    cmpService.registerType("EPPConjugateAssemblyComponent", EPPConjugateAssemblyComponent);
  }
}
