<div class="fileupload" [ngStyle]="{'background-color': background}">

  <div *ngFor="let cf of currentFiles" [ngClass]="{'file': !onlyReading, 'onlyReading': onlyReading}"
    [nbSpinner]="cf.loading" >

    <div class="fileName" [nbTooltip]="onlyReading ? cf.fileName : undefined" (click)="onlyReading ? downloadFile(cf) : undefined">
      {{cf.fileName}}
    </div>

    <div style="font-size: 75%; font-weight: normal; margin-top: -7px;">
      {{cf.description}}
      <span *ngIf="!cf.description" style="color: rgba(255, 255, 255, 0);">.</span>
    </div>

    <div *ngIf="actionsAccess.download && !onlyReading" id="editButtons">
      <div class="downloadFloatButton" (click)="downloadFile(cf)">
        <nb-icon icon="download-outline"></nb-icon>
      </div>

      <div *ngIf="actionsAccess.delete && !onlyReading" class="deleteFloatButton" (click)="removeFile(cf)">
        <nb-icon icon="close-outline"></nb-icon>
      </div>
    </div>

  </div>

  <div *ngIf="!onlyReading" class="dropFileText">
    <nb-icon icon="cloud-upload-outline"></nb-icon>
    <div>Arraste e solte os arquivos aqui</div>
  </div>

</div>
