import { Title } from "@angular/platform-browser";
import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { FormGroup } from "@angular/forms";
import { T2SecurityService } from "../../security/t2security.service";
import { take } from "rxjs/Operators";
import { T2LocalStorageService } from '../../t2local-storage.service';
import { LicenseType, T2HttpClientService } from "../../http/t2httpClient.service";

@Component({
  selector: 'app-form-template',
  templateUrl: './form-template.component.html',
  styleUrls: ['./form-template.component.scss']
})
export class FormTemplateComponent implements OnInit, OnDestroy {

  private licenseType: LicenseType = 'dashboard';

  @Input() beta = false;
  @Input() formTitle: string;
  @Input() changeTitle: boolean = true;
  @Input() showHeader = true;
  @Input()
  get loaded(): boolean {
    return this._loaded;
  }
  set loaded(value: boolean) {
    this._loaded = value;
    this.setStages();
  }

  @Input() useFlexLayout: boolean = true;
  @Input() get formGroup() {
    return this._formGroup
  }
  set formGroup(formGroup: FormGroup) {
    this._formGroup = formGroup;
  }
  @Input() helpUrl: string;

  private _formGroup = new FormGroup({});
  private _loaded: boolean = true;
  private accessLoaded = false;
  private accessValidated = false;
  private accessGranted = false;
  public stage: string;

  constructor(
    private titleService: Title,
    private sec: T2SecurityService,
    private httpClient: T2HttpClientService,
    private storage: T2LocalStorageService) {
  }


  ngOnInit(): void {
    this.setStages();

    this.sec.accessLoaded()
      .pipe(
        take(1)
      ).subscribe(() => {
        this.accessLoaded = true;
        this.setStages();
      });
  }

  ngOnDestroy(): void {
    if (this.accessGranted) {
      this.httpClient.decLicense(this.licenseType);
    }
  }


  public validateAccess(actionList: Array<string>, licenseType: LicenseType): boolean {
    this.licenseType = licenseType;
    this.accessValidated = false;
    this.accessGranted = actionList && !actionList.length;
    this.setStages();

    if (actionList) {
      for (const id_action of actionList) {
        if (this.sec.hasAction(id_action)) {
          this.accessGranted = true;
          break;
        }
      }
    }

    this.accessValidated = true;
    this.setStages();

    if (this.accessGranted) {
      this.httpClient.incLicense(this.licenseType);
    }

    return this.accessGranted;
  }

  public openHowToUse() {
    if (this.helpUrl) window.open(this.helpUrl, '_blank');
  }

  private setStages(): void {

    if (!this.accessLoaded) {
      this.stage = "loadingAccess";
      return;
    }

    if (this.accessLoaded && !this.accessValidated) {
      this.stage = "validatingAccess";
      return;
    }

    if (this.accessValidated && !this.accessGranted) {
      this.stage = "accessDenied";
      return;
    }

    if (this.accessGranted && !this.loaded) {
      this.stage = "loadingData";
      return;
    }

    if (this.accessGranted && this.loaded) {
      this.stage = "dataLoaded";
      return;
    }

  }
}
