<div
  [ngClass]="{'volumeList': !userDevService.isXSmallScreen, 'volumeListXSmallScreen': userDevService.isXSmallScreen}">
  <div *ngFor="let volume of volumeList" class="detailBox"
    [ngStyle]="{'cursor': enableClickSelection ? 'pointer' : 'default'}" (click)="selectVolume(volume)">
    <div class="flexColumn flexGap5">
      <div class="flexAlignRowSpaceBetweenStart">
        <span class="defaultSmallTitle">{{volume.code}}</span>
        <div class="flexAlignColumnStartEnd">
          <span class="defaultSmallTitle">{{volume.stockAddressCode}}</span>
          <span class="detailBoxValue">{{volume.stockAddressDescription}}</span>
        </div>
      </div>
      <div class="flexAlignRowSpaceBetweenStart flexGap5" style="line-height: 0.7rem;">
        <div class="flexAlignRowSpaceBetweenStart flexGap5">
          <div class="flexAlignColumnStartEnd">
            <span class="detailBoxTitle">Estoque</span>
            <span class="detailBoxValue">{{volume.quantity | number: '1.1-2' : locale}} {{volume.itemUnit}}</span>
          </div>
          <div class="flexAlignColumnStartEnd">
            <span class="detailBoxTitle">Reserva</span>
            <span class="detailBoxValue">{{volume.reservedQuantity | number: '1.1-2' : locale}} {{volume.itemUnit}}</span>
          </div>
          <div class="flexAlignColumnStartEnd">
            <span class="detailBoxTitle">Outras</span>
            <span class="detailBoxValue">{{volume.requisitionReservedQty | number: '1.1-2' : locale}} {{volume.itemUnit}}</span>
          </div>
        </div>
        <div class="flexAlignRowSpaceBetweenStart flexGap5">
          <div class="flexAlignColumnStartEnd">
            <span class="detailBoxTitle">Dt entrada</span>
            <span class="detailBoxValue">{{volume.creationDate | date:'shortDate'}}</span>
          </div>
          <div class="flexAlignColumnStartEnd">
            <span class="detailBoxTitle">Dt validade</span>
            <span class="detailBoxValue">{{volume.expirationDate | date:'shortDate'}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
