import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/Operators";
import { ApsService } from "../../aps.service";

@Component({
  selector: 'app-analysis-aps',
  templateUrl: './analysis-aps.component.html',
  styleUrls: ['./analysis-aps.component.scss']
})
export class AnalysisApsComponent implements OnInit, OnDestroy {

  private unsubscribe = new Subject<void>();
  public progEnvironment: string;
  public isProductionEnvironment: boolean = false;

  constructor(public apsService: ApsService) { }

  ngOnInit(): void {
    this.apsService.getSimulationSelected()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(env => {
        this.progEnvironment = env?.id || undefined;
        this.isProductionEnvironment = env?.real || false;
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  public analysisClick(view: string) {
    this.apsService.analysisVars.analysisView = view;
  }

  selectDeviceClick() {
    const ids = this.apsService.analysisVars.deviceSelectedId.value ? [this.apsService.analysisVars.deviceSelectedId.value] : [];
    this.apsService.openDeviceSelection('none','single', false, ids).subscribe(ids => {
      if (ids.items && ids.items.length > 0) {
        this.apsService.analysisVars.deviceSelectedId.next(ids.items[0]);
      }
    });
  }

  selectMachineLoadConsolidation(consolidation) {
    this.apsService.analysisVars.machiLoadConsolidation.next(consolidation);
  }

}
