<app-form-template #formTemplate formTitle="Mapa de acesso dos grupos" [loaded]="loaded">

  <header class="flexRow">
  </header>

  <content class="flexColumn fillContent " style="gap: 15px;">

    <!-- Groups -->
    <div class="flexColumn" style="flex: 1; overflow: auto;">
      <div class="flexColumn"> Funções (papéis) e suas funcionalidades</div>
      <div style="width: 100%; height: 100%">
        <app-t2grid #groupGrid id="groupGrid" [wrapText]="true" [sortable]="false" [rowSelection]="'none'"
          (cellKeyPress)="gridCellKeyPress($event)" (rowClick)="gridRowClick($event)">
        </app-t2grid>

        <!--<nb-toggle style="transform: scale(0.7); border: 0px;"></nb-toggle> -->
      </div>
    </div>

    <!-- Accesses -->
    <div class="flexColumn" style=" flex: 1; overflow: auto;">
      <div>Acessos da funcionalidade</div>
      <div><strong>{{selectedFunctionality?.description || ""}}</strong></div>
      <div style="height: 100%; width: 100%;">
        <app-t2grid #accessGrid id="accessGrid" [wrapText]="true" [sortable]="false" rowSelection="none"
          [treeData]="true" [autoGroupColumnDef]="accessGridAutoGroupColumnDef" [components]="accessGridComponents">
        </app-t2grid> -
      </div>
    </div>

  </content>

  <footer>
  </footer>
</app-form-template>
