import { FormatWidth, getLocaleDateFormat } from "@angular/common";
import { AfterViewInit, Component, Inject, LOCALE_ID, OnDestroy, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/Operators";
import { DialogTemplateComponent } from "src/app/core/cmp/dialog-template/dialog-template.component";
import { T2AggregationComponent } from "src/app/core/cmp/ui/t2-aggregation/t2-aggregation.component";
import { T2CheckBoxComponent } from "src/app/core/cmp/ui/t2-check-box/t2-check-box.component";
import { T2DateTimeComponent } from "src/app/core/cmp/ui/t2-date-time/t2-date-time.component";
import { T2InputTextComponent } from "src/app/core/cmp/ui/t2-input-text/t2-input-text.component";
import { ActionType, T2ViewTemplateAction } from "src/app/core/cmp/view-template/model/t2-view-template-action";
import { T2ViewTemplateData } from "src/app/core/cmp/view-template/model/t2-view-template-data";
import { Condition, InputType, T2ViewTemplateFlow } from "src/app/core/cmp/view-template/model/t2-view-template-flow";
import { LayoutType, ViewTemplateElement } from "src/app/core/cmp/view-template/model/view-template-element";
import { ViewTemplateComponent } from "src/app/core/cmp/view-template/view-template.component";

@Component({
  selector: 'app-exception',
  templateUrl: './exception.component.html',
  styleUrls: ['./exception.component.scss']
})
export class ExceptionComponent implements AfterViewInit, OnDestroy {

  @ViewChild('dialog', { static: true }) dialog: DialogTemplateComponent;
  @ViewChild('viewTemplate', { static: true }) viewTemplate: ViewTemplateComponent;

  formGroup: FormGroup;
  data: Array<T2ViewTemplateData> = [
    { cmpName: "workShift", properties: { readOnly: false, visible: false, datasetId: "{C3727C3C-1D11-4E8B-8E55-BFCFF4F84942}", datasetName: "afx_workShift" } },
  ];
  actions: Array<T2ViewTemplateAction> = [
    {
      type: ActionType.changeValue,
      methodName: "changeValue"
    },
    {
      type: ActionType.changeValue,
      methodName: "setValidators"
    }
  ];
  flows: Array<T2ViewTemplateFlow> = [
    {
      triggerCmpNameList: ["produtivo"],
      action: this.actions[0],
      inputs: [
        {
          type: InputType.component,
          cmpName: "produtivo",
          paramName: "value"
        }
      ],
      outputs: [
        {
          cmpName: "workShift",
          cmpInputName: "visible"
        }
      ]
    },
    {
      triggerCmpNameList: ["produtivo"],
      action: this.actions[1],
      inputs: [
        {
          type: InputType.static,
          cmpName: "produtivo",
          paramName: "cmpName",
          paramValue: "workShift"
        },
        {
          type: InputType.static,
          cmpName: "produtivo",
          paramName: "validators",
          paramValue: [Validators.required]
        },
        {
          type: InputType.component,
          cmpName: "produtivo",
          paramName: "clearValidators",
          condition: Condition.denyValue
        }
      ]
    }
  ];
  layout: Array<ViewTemplateElement> = [
    {
      layoutType: LayoutType.gridLayout,
      columnSpan: 3,
      children: [
        {
          layoutType: LayoutType.component,
          isBaseComponent: true,
          title: "Descrição",
          cmpName: "descricao",
          cmpType: T2InputTextComponent,
          columnSpan: 3
        },
        {
          layoutType: LayoutType.component,
          isBaseComponent: true,
          title: "Data Início",
          cmpName: "dataInicio",
          cmpType: T2DateTimeComponent,
          columnSpan: 2,
        },
        {
          layoutType: LayoutType.component,
          isBaseComponent: true,
          title: "Data Fim",
          cmpName: "dataFim",
          cmpType: T2DateTimeComponent,
          columnSpan: 2
        },
        {
          layoutType: LayoutType.component,
          isBaseComponent: true,
          title: "Produtivo",
          cmpName: "produtivo",
          cmpType: T2CheckBoxComponent,
          columnSpan: 2
        },
        {
          layoutType: LayoutType.component,
          isBaseComponent: true,
          title: "Recorrente",
          cmpName: "recorrente",
          cmpType: T2CheckBoxComponent,
          columnSpan: 1
        },
        {
          layoutType: LayoutType.component,
          isBaseComponent: true,
          title: "Turno",
          cmpName: "workShift",
          cmpType: T2AggregationComponent,
          columnSpan: 3
        },
      ]
    },
  ];

  public id_workShift: string = null;
  public startDate: Date;
  public endDate: Date;
  public description: string;
  public productive: boolean;
  public recurrent: boolean;

  private workShift: string;
  private unsubscribe = new Subject<void>();
  private format: string;
  private dtFormat: string;

  constructor(private formBuilder: FormBuilder, @Inject(LOCALE_ID) private locale: string) {
    this.formGroup = this.formBuilder.group({
      descricao: new FormControl(null, Validators.required),
      dataInicio: new FormControl(null, Validators.required),
      dataFim: new FormControl(null, Validators.required),
      produtivo: new FormControl(null),
      recorrente: new FormControl(null),
      workShift: new FormControl(null)
    });

    this.dtFormat = getLocaleDateFormat(this.locale, FormatWidth.Short);

    if (this.dtFormat.startsWith("M")) {
      this.format = "MM/dd/yyyy hh:mm a";
    } else {
      this.format = "dd/MM/yyyy HH:mm";
    }

    this.data.push({ cmpName: "dataInicio", properties: { format: this.format, withSeconds: false } });
    this.data.push({ cmpName: "dataFim", properties: { format: this.format, withSeconds: false } });
  }

  ngAfterViewInit(): void {
    this.formGroup.controls["workShift"].setValue(this.id_workShift);
    this.formGroup.controls["dataInicio"].setValue(this.startDate);
    this.formGroup.controls["dataFim"].setValue(this.endDate);
    this.formGroup.controls["descricao"].setValue(this.description);
    this.formGroup.controls["produtivo"].setValue(this.productive);
    this.formGroup.controls["recorrente"].setValue(this.recurrent);

    if (this.recurrent) {
      this.adjustDateFormat(false);
    }

    this.data.find(d => d.cmpName == "workShift").properties["visible"] = this.productive;

    this.formGroup.controls["workShift"].valueChanges.pipe(takeUntil(this.unsubscribe))
      .subscribe(resp => {
        this.workShift = this.viewTemplate.getCmpInputValue("workShift", "description");
      });

    this.formGroup.controls["recorrente"].valueChanges.pipe(takeUntil(this.unsubscribe))
      .subscribe(checked => {
        this.adjustDateFormat(!checked);
      });

    this.formGroup.controls["dataFim"].addValidators(this.validateEndDate.bind(this));
    this.formGroup.controls["dataFim"].updateValueAndValidity();

    this.formGroup.controls["dataInicio"].valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe(() => {
      this.formGroup.controls["dataFim"].updateValueAndValidity();
    })
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  public confirm(): void {
    if (!this.formGroup.controls["produtivo"].value) {
      this.formGroup.controls["workShift"].setValue(null);
      this.workShift = null;
    }

    const resp = {
      id_workShift: this.formGroup.controls["workShift"].value,
      workShift: this.workShift,
      startDate: this.formGroup.controls["dataInicio"].value,
      endDate: this.formGroup.controls["dataFim"].value,
      description: this.formGroup.controls["descricao"].value,
      productive: this.formGroup.controls["produtivo"].value,
      recurrent: this.formGroup.controls["recorrente"].value,
    }

    this.dialog.close(resp);
  }

  private adjustDateFormat(showYear: boolean) {
    if (this.dtFormat.startsWith("M")) {
      if (showYear)
        this.format = "MM/dd/yyyy hh:mm a"
      else
        this.format = "MM/dd hh:mm a";
    } else {
      if (showYear)
        this.format = "dd/MM/yyyy HH:mm"
      else
        this.format = "dd/MM HH:mm";
    }

    this.viewTemplate.setCmpInputValue("dataInicio", "format", this.format);
    this.viewTemplate.setCmpInputValue("dataFim", "format", this.format);
  }

  validateEndDate(control: FormControl) {
    let sDate = this.formGroup.controls["dataInicio"].value;
    let eDate = control.value;

    if (sDate && eDate && eDate < sDate) {
      return { endDateBeforeStartDate: true };
    }

    return null; // Passou a validação
  }

}
