<div class="flexColumn flexGap5" style="height: 100%;">
  <div *ngIf="showCmp.operations" class="divOperation flexColumn" style="height: 100%;">
    <app-appointment-operations #appOperations style="height: 100%;" [device]="device" [taskPlan]="currentTaskPlan"
      [searchedTaskPlan]="searchedTaskPlan" [justifyOperation]="justifyOperation"
      (operationSelected)="operationClicked($event)" (goBackToDocument)="backToDocuments($event)" />
  </div>

  <div style="overflow-y: auto;">
    <div *ngIf="!showCmp.operations" class="flexAlignRowCenter operation">
      <span>{{selectedOperation?.description}}</span>
    </div>

    <div
      *ngIf="(showCmp.quantity || showCmp.formData) && !showCmp.consumption && !showCmp.consumptionReverse && !showCmp.productionReverse"
      class="flexColumn flexGap5 divInfo" style="height: 100%;">
      <div *ngIf="showCmp.quantity">
        <app-appointment-register-quantity #appQuantity [inputList]="selectedOperation.inputList"
          [taskPlan]="currentDeviceTaskPlan" (disableConfirm)="disableConfirm()" />
      </div>

      <div *ngIf="showCmp.formData">
        <app-appointment-register-formdata #appFormData [dataList]="selectedOperation.dataList" />
      </div>
    </div>

    <div *ngIf="showCmp.consumption || showCmp.consumptionReverse || showCmp.productionReverse"
      class="flexColumn flexGap5 divInfo">
      <div *ngIf="showCmp.consumption" class="divInfo">
        <app-appointment-register-consumption #appConsumption [inputList]="selectedOperation.inputList"
          [taskPlan]="currentDeviceTaskPlan" (lockScreen)="setLockScreen($event)" />
      </div>

      <div *ngIf="showCmp.consumptionReverse" class="divInfo">
        <app-appointment-register-consumption-reverse #appConsumptionReverse [inputList]="selectedOperation.inputList"
          [taskPlan]="currentDeviceTaskPlan" />
      </div>

      <div *ngIf="showCmp.productionReverse" class="divInfo">
        <app-appointment-register-production-reverse #appProductionReverse [inputList]="selectedOperation.inputList"
          [taskPlan]="currentDeviceTaskPlan" />
      </div>

      <div *ngIf="showCmp.formData">
        <app-appointment-register-formdata #appFormData [dataList]="selectedOperation.dataList" />
      </div>
    </div>

    <div *ngIf="showCmp.rawMaterialRequest">
      <app-appointment-register-raw-material-request #appRawMaterialReq [taskPlan]="currentDeviceTaskPlan"
        (hasLoaded)="rawMaterialRequestLoaded()" (changeBtnText)="changeBtnConfirmText($event)" />
    </div>

    <div *ngIf="showCmp.stopReason">
      <app-appointment-register-stop-reason #appStopReason [stopReasonList]="selectedOperation.stopReasonList" />
    </div>
  </div>

  <div *ngIf="!showCmp.operations" class="flexAlignRowSpaceBetweenStart">
    <app-t2-button status="basic" size="tiny" value="Voltar" (clicked)="backToOperations()" />
    <app-t2-button status="primary" size="tiny" [value]="btnConfirmText" [disabled]="disableBtnConfirm"
      (clicked)="ConfirmClick()" />
  </div>
</div>