import { FavoritesComponent } from './favorites/favorites.component';
import { NewsComponent } from './news/news.component';
import { Component, OnInit } from '@angular/core';
import { FormGroup } from "@angular/forms";
import { T2ViewTemplateData } from "../cmp/view-template/model/t2-view-template-data";
import { LayoutType, ViewTemplateElement } from "../cmp/view-template/model/view-template-element";
import { GreetingsComponent } from "./greetings/greetings.component";
import { Title } from "@angular/platform-browser";

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  public _layout: Array<ViewTemplateElement> = [

    {
      layoutType: LayoutType.component,
      isBaseComponent: false,
      cmpType: FavoritesComponent,
      cmpName: 'favorites'
    },
    {
      layoutType: LayoutType.listLayout,
      direction: "row",
      fill: true,
      children: [
        {
          layoutType: LayoutType.component,
          isBaseComponent: false,
          cmpType: GreetingsComponent,
          cmpName: 'greetings',
          width: '100%',
        },
        {
          layoutType: LayoutType.component,
          isBaseComponent: false,
          cmpType: NewsComponent,
          cmpName: 'news',
          width: '400px',
          maxWidth: '50%'
        }
      ]
    },
  ]
  public _data: Array<T2ViewTemplateData> = [];
  public _FormGroup?= new FormGroup({}, { updateOn: "blur" });
  constructor(private titleService: Title) { }

  ngOnInit(): void {
    this.titleService.setTitle("TechPROD - Tech2e")
  }

}
