import { TaskFlowCellRendererComponent } from './task-flow-cell-renderer/task-flow-cell-renderer.component';
import { TaskUrgentCellRendererComponent } from '../../../../utils/task-urgent-cell-renderer/task-urgent-cell-renderer.component';
import { T2gridComponent } from "src/app/core/cmp/t2grid/t2grid.component";
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { TaskSeparationCellRendererComponent } from "./task-separation-cell-renderer/task-separation-cell-renderer.component";
import { ShopFloorService, TaskPlanSelection, TaskSequenceInfo } from "../../shop-floor.service";
import { T2FirebaseService } from "src/app/core/t2-firebase.service";
import { take } from "rxjs/Operators";
import { TaskStatusCellRendererComponent } from "src/app/bsn/prd/utils/task-status-cell-renderer/task-status-cell-renderer.component";

@Component({
  selector: 'app-sequencing-grid',
  templateUrl: './sequencing-grid.component.html',
  styleUrls: ['./sequencing-grid.component.scss'],
  providers: [ShopFloorService, T2FirebaseService]
})
export class SequencingGridComponent implements OnInit {

  @ViewChild('gridSequence', { static: true }) gridSequence: T2gridComponent;

  private _taskSequenceList: Array<TaskSequenceInfo>;
  @Input()
  set taskSequenceList(value: Array<TaskSequenceInfo>) {
    this._taskSequenceList = value;
    this.setGridConfig();
  }
  get taskSequenceList() {
    return this._taskSequenceList;
  }

  @Input() deviceId: string;
  @Input() loading = false;
  @Output() taskPlanSelected = new EventEmitter<TaskPlanSelection>();

  sequencingGridComponents = {
    taskSeparationCellRenderer: TaskSeparationCellRendererComponent,
    taskUrgentCellRenderer: TaskUrgentCellRendererComponent,
    taskFlowCellRenderer: TaskFlowCellRendererComponent,
    taskStatusCellRender: TaskStatusCellRendererComponent,
  };

  gridContextMenu = {
    contextMenu: []
  }

  constructor(private sfService: ShopFloorService) { }

  ngOnInit(): void { }

  private setGridConfig() {

    this.gridSequence.setGridColumnDefs([
      { headerName: "", field: "position", type: 'numericColumn' },
      { headerName: "OP", field: "prodOrder" },
      { headerName: "Tarefa", field: "taskDescription" },
      {
        headerName: "Status Tarefa", field: "taskStatus",
        valueGetter: (params) => {
          params.data.started = false;
          params.data.suspended = false;
          if (params?.data?.taskStatus && params?.data?.taskStatus == 'Iniciada') params.data.started = true;
          if (params?.data?.taskStatus && params?.data?.taskStatus == 'Suspensa') params.data.suspended = true;
          return params?.data?.taskStatus;
        }, type: ["booleanColumn"], cellRenderer: "taskStatusCellRender", width: 140
      },
      { headerName: "Dt Planejada", field: "planDate", type: ["dateTimeColumn"] },
      { headerName: "Descrição", field: "prodOrderDescription" },
      {
        headerName: "Status estoque", field: "stockAvailable",
        valueGetter: (params) => {
          if (!params?.data?.hasRequisition) {
            return "Sem requisições";
          } else if (params?.data?.mpSeparate) {
            return "Separada";
          } else if (!params?.data?.stockAvailable) {
            return "Sem estoque";
          } else if (!params?.data?.mpSeparate && params?.data?.stockAvailable) {
            return "Não separada";
          }
        },
        type: ["booleanColumn"], cellRenderer: "taskSeparationCellRenderer", width: 140
      },
      {
        headerName: "Pedido", field: "salesOrder"
      },
      { headerName: "Dt Entrega", field: "shippingDate", type: ["dateTimeColumn"] },
      { headerName: "Urgente", field: "urgent", type: ["booleanColumn"], cellRenderer: "taskUrgentCellRenderer", width: 120 },
      {
        headerName: "Tarefas", field: "tasksList",
        filterParams: {
          keyCreator: params => params.value.gridId,
          valueFormatter: (params) => {
            return params.value.gridId;
          }
        },
        cellRenderer: "taskFlowCellRenderer", width: 500
      },
      { headerName: "Op Retrabalho", field: "reworkProdOrder" },
      { headerName: "Tipo frete", field: "shippingType" },
    ]);

    this.gridContextMenu.contextMenu = [];
    this.gridContextMenu.contextMenu.push({
      name: "Trocar Dispositivo",
      requiresId: true,
      action: () => {
        this.swapTaskPlanDevice();
      }
    })

    this.gridSequence.setGridData(this.taskSequenceList);
    this.gridSequence?.redrawRows();
    this.gridSequence.autoSizeAllColumns(false);
  }

  public selectTaskPlan(params) {
    let taskSeq = params.data;
    this.taskPlanSelected.emit({ taskPlanId: taskSeq.taskPlanId, taskId: taskSeq.taskId, prodOrderCode: taskSeq.prodOrder });
  }

  swapTaskPlanDevice() {
    this.loading = true;
    let ts: TaskSequenceInfo = this.gridSequence.getFocusedRow();

    this.sfService.swapTaskPlanDevice(ts.taskPlanId, this.deviceId).pipe(take(1)).subscribe(() => {
      this.gridSequence.removeData([{ ID: ts["ID"] }]);
      this.loading = false;
    }, error => {
      this.loading = false;
    })
  }
}
