import { Component, OnInit, Input, Optional } from '@angular/core';
import { NbDialogRef } from "@nebular/theme";

@Component({
  selector: 'app-dialog-template',
  template: `
    <nb-card [ngStyle]="ngStyle">
      <nb-card-header *ngIf="title || beta" style="display: flex; flex-direction: row; justify-content: space-between;">
        <h6>{{title}}</h6>
        <div style="display: flex; gap: 2px">
          <button *ngIf="helpUrl" nbButton shape = "round" ghost size = "medium" (click) = "openHowToUse()" style = "padding: 0px 1px; color: #3366ff;" nbTooltip = "Guia de uso" >
            <nb-icon icon = "question-mark-circle-outline" ></nb-icon>
          </button >
          <span class="beta" *ngIf="beta" nbTooltip="Essa funcionalidade está sem sua fase final de testes e ajustes" >Beta</span>
        </div>
        <ng-content select="header"></ng-content>
      </nb-card-header>
      <nb-card-body class="flexColumn" style="gap: 10px">
        <strong *ngIf="description">{{description}}</strong>
        <ng-content select="content" style="flex: 1;"></ng-content>
      </nb-card-body>
      <nb-card-footer *ngIf="showFooter">
        <ng-content select="footer"></ng-content>
      </nb-card-footer>
    </nb-card>
  `,
  styleUrls: ['./dialog-template.component.scss']
})
export class DialogTemplateComponent implements OnInit {

  @Input() beta = false;
  @Input() title: string;
  @Input() description: string;
  @Input() ngStyle: { [klass: string]: any }
  @Input() showFooter: boolean = true;
  @Input() helpUrl: string = null;

  constructor(@Optional() public dialogRef: NbDialogRef<any>) { }

  ngOnInit(): void {
  }

  close(data: any) {
    this.dialogRef?.close(data);
  }

  openHowToUse() {
    if (this.helpUrl) window.open(this.helpUrl, '_blank');
  }

}
