import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-dataset-basic',
  templateUrl: './dataset-basic.component.html',
  styleUrls: ['./dataset-basic.component.scss']
})
export class DatasetBasicComponent implements OnInit {

  @Input() datasetName: string = null;
  @Input() selectionType: string = null;
  @Input() searchText: string;
  @Input() selectedId: string;
  @Input() parentId: string;
  @Input() checkUrl = false;

  constructor() { }

  ngOnInit(): void {
  }

}
