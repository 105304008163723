import { Component, OnInit, ViewContainerRef, ViewChild, TemplateRef, Input, Output, EventEmitter } from '@angular/core';
import { NbMenuItem, NbMenuService, NbMenuBag } from "@nebular/theme";
import { OverlayRef, Overlay } from "@angular/cdk/overlay";
import { TemplatePortal } from "@angular/cdk/portal";
import { fromEvent, Subscription } from "rxjs";
import { take, filter } from "rxjs/Operators";

export class ContextMenuItem {
  id: string;
  title: string;
  context: {};
  toolTip?: string;

  constructor(id: string, title: string, context?) {
    this.id = id;
    this.title = title;
    this.context = context;
  }
}

@Component({
  selector: "app-context-menu",
  templateUrl: "./context-menu.component.html",
  styleUrls: ["./context-menu.component.scss"],
})
export class ContextMenuComponent implements OnInit {
  private _menuItems: ContextMenuItem[];
  private _isShowing = false;

  @Input() get menuItems() {
    return this._menuItems;
  }
  set menuItems(items: ContextMenuItem[]) {
    this.setMenuItems(items);
  }
  @Output() itemClick = new EventEmitter();

  @ViewChild("treePopup") treePopup: TemplateRef<any>;

  popUpItems: NbMenuItem[] = [
    { title: "Opção 1" },
    { title: "Opção 2" },
    { title: "Opção 3" },
  ];

  overlayRef: OverlayRef | null;
  private sub: Subscription;

  constructor(
    private menuService: NbMenuService,
    public overlay: Overlay,
    public viewContainerRef: ViewContainerRef
  ) {}

  ngOnInit(): void {
    this.menuService
      .onItemClick()
      .pipe(filter(({ tag }) => tag === "popUpMenu"))
      .subscribe((menuBag: NbMenuBag) => {
        this.itemClick.emit(menuBag.item.data);
      });
  }

  setMenuItems(items: ContextMenuItem[]) {
    this._menuItems = items;

    this.popUpItems = items.map((item) => {
      const nbItem = new NbMenuItem();
      nbItem.title = item.title;
      nbItem.data = item;

      return nbItem;
    });
  }

  showContextMenu(x, y) {
    this.close();

    const positionStrategy = this.overlay
      .position()
      .flexibleConnectedTo({ x, y })
      .withPositions([
        {
          originX: "end",
          originY: "bottom",
          overlayX: "end",
          overlayY: "top",
        },
      ]);

    this.overlayRef = this.overlay.create({
      positionStrategy,
      scrollStrategy: this.overlay.scrollStrategies.close(),
    });

    this.overlayRef.attach(
      new TemplatePortal(this.treePopup, this.viewContainerRef, {
        // $implicit: user
      })
    );

    this.sub = fromEvent<MouseEvent>(document, "click")
      .pipe(
        filter((event) => {
          const clickTarget = event.target as HTMLElement;
          return (
            !!this.overlayRef &&
            !this.overlayRef.overlayElement.contains(clickTarget)
          );
        }),
        take(1)
      )
      .subscribe(() => {
          this.close();
      });

    this._isShowing = true;
  }

  close() {
    this._isShowing = false;

    this.sub && this.sub.unsubscribe();
    if (this.overlayRef) {
      this.overlayRef.dispose();
      this.overlayRef = null;
    }
  }

  isShowing(): boolean {
    return this._isShowing;
  }
}
