<app-form-template #formTemplate formTitle="Chão de Fábrica" [loaded]="loaded" [beta]="true"
  [helpUrl]="'https://tech2e.atlassian.net/l/cp/HUYXvHFk'">

  <header>
    <div *ngIf="devEnvironment" class="printFloatButton" nbTooltip="Dev mode only" [nbSpinner]="updatingCache"
      style="display: flex; flex-direction: row; justify-content: center; gap: 3px; width: 220px !important;"
      [ngClass]="{'updatingCache': updatingCache}" (click)="$event.stopPropagation(); refreshDevice(); ">
      <nb-icon *ngIf="!updatingCache" icon="refresh-outline"></nb-icon>
      <span *ngIf="!updatingCache">Update device's cache</span>
      <span *ngIf="updatingCache">Updating device's cache</span>
    </div>
  </header>

  <content>
    <div *ngIf="!userDevService.isSmallScreen" style="display: flex; gap: 20px; height: 100%;"
      [nbSpinner]="lockScreen || !dashboardLoaded" nbSpinnerSize="giant" nbSpinnerStatus="primary">

      <div [ngClass]="{'showDivided': showTaskPanel, 'divList': showTaskPanel} ">
        <app-shop-floor-dashboard #dashboard [selectedIdDevice]="selectedIdDevice"
          [selectedIdTaskPlan]="selectedIdTaskPlan" (deviceSelected)="deviceSelected($event)"
          (devicesLoaded)="devicesLoaded($event)" />
      </div>

      <div *ngIf="showTaskPanel" class="divList" style="width: 100%;">
        <app-shop-floor-taskpanel #appTaskPanel [device]="selectedDevice.device" [taskPlan]="selectedDevice.taskPlan"
          [selectedPanel]="selectedDevice.tab" (closePanel)="btnVoltarClick($event)"
          (lockScreen)="setLockScreen($event)" (tpChanged)="taskPlanChanged($event)"
          (selectedPanelChanged)="selectDeviceChanged($event)" />
      </div>
    </div>

    <div *ngIf="userDevService.isSmallScreen" style="height: 100%;" [nbSpinner]="lockScreen || !dashboardLoaded"
      nbSpinnerSize="giant" nbSpinnerStatus="primary">
      <div *ngIf="!showTaskPanel">
        <app-shop-floor-dashboard #dashboard [selectedIdDevice]="selectedIdDevice"
          [selectedIdTaskPlan]="selectedIdTaskPlan" (deviceSelected)="deviceSelected($event)"
          (devicesLoaded)="devicesLoaded($event)" />
      </div>

      <div *ngIf="showTaskPanel" style="height: 100%;">
        <app-shop-floor-taskpanel #appTaskPanel [device]="selectedDevice.device" [taskPlan]="selectedDevice.taskPlan"
          [selectedPanel]="selectedDevice.tab" (closePanel)="btnVoltarClick($event)"
          (lockScreen)="setLockScreen($event)" (tpChanged)="taskPlanChanged($event)"
          (selectedPanelChanged)="selectDeviceChanged($event)" />
      </div>
    </div>

  </content>

</app-form-template>