import { Injectable } from '@angular/core';
import { Observable } from "rxjs";
import { map, take } from "rxjs/Operators";
import { T2HttpClientService } from "src/app/core/http/t2httpClient.service";
import { CylinderFamilyItem } from "../model/cylinder-family-item";
import { PSColor } from "../model/pscolor";

@Injectable({
  providedIn: 'root'
})
export class FlxProductSpecificationService {

  constructor(private httpClient: T2HttpClientService) { }


  getStructureLayers(id_specif_component: string): Observable<{ id_specif_carac_chemical?: string, id_specif_carac_lamination?: string, layerList: Array<StructureLayer> }> {

    let params = new Map<string, string>();
    params.set("id_specif_component", id_specif_component);

    return this.httpClient.get("bsn.epp.flexx/structureLayers", params)
      .pipe(
        take(1),
        map(resp => {
          const struct = {
            id_specif_carac_chemical: null,
            id_specif_carac_lamination: null,
            layerList: []
          }

          if (resp) {
            if (resp.structList && !Array.isArray(resp.structList)) resp.structList = [resp.structList];

            struct.id_specif_carac_chemical = resp.id_specif_carac_chemical;
            struct.id_specif_carac_lamination = resp.id_specif_carac_lamination;
            struct.layerList = resp.structList ?? [];
          }

          return struct;
        }));
  }

  getCylinderFamilyItens(id_familiaCilindro: string): Observable<Array<CylinderFamilyItem>> {
    let params = new Map<string, string>();
    params.set("id_familiaCilindro", id_familiaCilindro);

    return this.httpClient.get("bsn.epp.flexx/cylinderFamily", params)
      .pipe(take(1),
        map(resp => {
          let cylFamilyItList = new Array<CylinderFamilyItem>();

          if (resp && resp.cylinderFamilyItemList) {
            if (!Array.isArray(resp.cylinderFamilyItemList)) {
              resp.cylinderFamilyItemList = [resp.cylinderFamilyItemList];
            }

            cylFamilyItList = resp.cylinderFamilyItemList;
          }

          return cylFamilyItList;
        }))
  }

  getProductSpecificationColors(productSpecificationId: string): Observable<Array<PSColor>> {
    let params = new Map<string, string>();
    params.set("productSpecificationId", productSpecificationId);

    return this.httpClient.get("bsn.epp.flexx/getProductSpecificationColors", params).pipe(take(1), map(resp => {
      let colorList = new Array<PSColor>();

      if (resp && resp.colorList) {
        if (!Array.isArray(resp.colorList)) {
          resp.colorList = [resp.colorList];
        }

        colorList = resp.colorList;
      }

      return colorList;
    }));
  }

  addColorToCylinderFamily(id_especif_carac_quimico_item_from: string, id_especif_carac_quimico_item_to): Observable<{ id_familiaCilindro: string }> {
    let params = new Map<string, string>();
    params.set("id_especif_carac_quimico_item_from", id_especif_carac_quimico_item_from);
    params.set("id_especif_carac_quimico_item_to", id_especif_carac_quimico_item_to);

    return this.httpClient.get("bsn.epp.flexx/addColorToCylinderFamily", params).pipe(take(1), map(resp => {
      return {
        id_familiaCilindro: resp.id_familiaCilindro
      }
    }))
  }

  deleteColorFromCylinderFamily(id_especif_carac_quimico_item: string, id_familiaCilindro: string): Observable<any> {
    let params = new Map<string, string>();
    params.set("id_especif_carac_quimico_item", id_especif_carac_quimico_item);
    params.set("id_familiaCilindro", id_familiaCilindro);

    return this.httpClient.get("bsn.epp.flexx/deleteColorFromCylinderFamily", params).pipe(take(1));
  }

  getPsTypeByChemicalCarac(id_especif_carac_quimico_item: string): Observable<{ id_specifType: string, psType: string }> {
    let params = new Map<string, string>();
    params.set("id_especif_carac_quimico_item", id_especif_carac_quimico_item);

    return this.httpClient.get("bsn.epp.flexx/getPsTypeByChemicalCarac", params)
      .pipe(
        take(1),
        map(resp => {
          return { id_specifType: resp?.type?.id_specifType, psType: resp?.type?.psType }
        }))
  }

  updateStructureLayerSortOrder(structureLayerList: Array<StructureLayer>) {
    return this.httpClient.post("bsn.epp.flexx/updateStructureLayerSortOrder", null, structureLayerList)
      .pipe(take(1),
        map(resp => {
          if (resp) {
            if (!Array.isArray(resp.structList)) resp.structList = [resp.structList];

            resp.structList.forEach(layer => {
              structureLayerList.find(s => s.id_especif_carac_estr_item == layer.id_especif_carac_estr_item).sortKey = layer.sortKey;
            })

          }

          return structureLayerList;
        }))
  }

  updateStructureLayer(id_specif_component: string, structureLayer: StructureLayer) {
    let params = new Map<string, string>();
    params.set("id_specif_component", id_specif_component);

    return this.httpClient.post("bsn.epp.flexx/updateStructureLayer", params, structureLayer)
      .pipe(take(1),
        map(resp => {
          if (resp) {
            if (!Array.isArray(resp.structList)) resp.structList = [resp.structList];
            structureLayer.id_especif_carac_estr_item = resp.structList[0].id_especif_carac_estr_item;
          }

          return structureLayer;
        }))

  }

  deleteStructureLayer(id_especif_carac_estr_item: string) {
    let params = new Map<string, string>();
    params.set("id_especif_carac_estr_item", id_especif_carac_estr_item);

    return this.httpClient.get("bsn.epp.flexx/deleteStructureLayer", params)
      .pipe(take(1));
  }

  getChemicalClassification(): Observable<Array<ChemicalClassification>> {
    return this.httpClient.get("bsn.epp.flexx/getChemicalClassification", null)
      .pipe(
        take(1),
        map(resp => {
          let chemicalClassList = new Array<ChemicalClassification>();
          if (resp && resp.chemicalList) {
            if (!Array.isArray(resp.chemicalList)) {
              resp.chemicalList = [resp.chemicalList];
            }

            chemicalClassList = resp.chemicalList;
          }

          return chemicalClassList;
        }));
  }

  getSubstrateClassification(): Observable<Array<SubstrateClassification>> {
    return this.httpClient.get("bsn.epp.flexx/getSubstrateClassification", null)
      .pipe(
        take(1),
        map(resp => {
          let substrateClassList = new Array<SubstrateClassification>();
          if (resp && resp.substrateList) {
            if (!Array.isArray(resp.substrateList)) {
              resp.substrateList = [resp.substrateList];
            }

            substrateClassList = resp.substrateList;
          }

          return substrateClassList;
        }));
  }

  getConjugatedAssemblyItems(id_especif_carac: string): Observable<Array<ConjugateAssemblyItem>> {
    let params = new Map<string, string>();
    params.set("id_especif_carac", id_especif_carac);

    return this.httpClient.get("bsn.epp.flexx/getConjugatedAssemblyItems", params).pipe(
      take(1),
      map(resp => {
        if (resp.itemList && !Array.isArray(resp.itemList)) {
          resp.itemList = [resp.itemList];
        }

        resp.itemList?.forEach((item: ConjugateAssemblyItem) => {
          item.trackQty = new Number(item.trackQty).valueOf();
          item.verticalQty = new Number(item.verticalQty).valueOf();
          item.bloodlettingBtwTracks = new Number(item.bloodlettingBtwTracks).valueOf() || 0;
          item.bloodlettingBtwLines = new Number(item.bloodlettingBtwLines).valueOf() || 0;
          item.printSequence = new Number(item.printSequence).valueOf();
          item.width = new Number(item.width).valueOf() || 0;
          item.height = new Number(item.height).valueOf() || 0;
        })

        return resp.itemList;
      })
    )
  }

  getConjugatedAssembly(id_especificacao: string): Observable<ConjugateAssembly> {
    let params = new Map<string, string>();
    params.set("id_especificacao", id_especificacao);

    return this.httpClient.get("bsn.epp.flexx/getConjugatedAssembly", params).pipe(
      take(1),
      map(resp => {
        let assembly = new ConjugateAssembly();
        if (resp && resp.assembly) {
          if (resp.assembly.itemList && !Array.isArray(resp.assembly.itemList)) {
            resp.assembly.itemList = [resp.assembly.itemList];
          }

          resp.assembly.leftMargin = new Number(resp.assembly.leftMargin || 0).valueOf();
          resp.assembly.rightMargin = new Number(resp.assembly.rightMargin || 0).valueOf();
          resp.assembly.topMargin = new Number(resp.assembly.topMargin || 0).valueOf();
          resp.assembly.bottomMargin = new Number(resp.assembly.bottomMargin || 0).valueOf();

          resp.assembly.itemList?.forEach((item: ConjugateAssemblyItem) => {
            item.trackQty = new Number(item.trackQty).valueOf();
            item.verticalQty = new Number(item.verticalQty).valueOf();
            item.bloodlettingBtwTracks = new Number(item.bloodlettingBtwTracks).valueOf() || 0;
            item.bloodlettingBtwLines = new Number(item.bloodlettingBtwLines).valueOf() || 0;
            item.printSequence = new Number(item.printSequence).valueOf();
            item.width = new Number(item.width).valueOf() || 0;
            item.height = new Number(item.height).valueOf() || 0;
          })

          assembly = resp.assembly;
        } 

        return assembly;
      })
    )
  }

  getLamination(id_specif_component: string): Observable<Array<Lamination>> {
    let params = new Map<string, string>();
    params.set("id_especif_componente", id_specif_component);

    return this.httpClient.get("bsn.epp.flexx/getLaminationList", params).pipe(take(1), map(resp => {
      let laminationList = new Array<Lamination>();

      if (resp && resp.laminationList) {
        if (!Array.isArray(resp.laminationList)) {
          resp.laminationList = [resp.laminationList]
        }

        laminationList = resp.laminationList;
      }

      return laminationList;
    }))
  }

  saveLamination(laminationList: Array<Lamination>): Observable<Array<Lamination>> {
    return this.httpClient.post("bsn.epp.flexx/saveLamination", null, laminationList).pipe(take(1));
  }
}

export class StructureLayer {

  id_especif_carac: string;
  id_especif_carac_estr_item: string;
  id_estrutura_codigo?: string;
  sortKey: number;
  structCode?: string;
  layerType: "CHEMICAL" | "SUBSTRATE" | "METALIZATION" | "EXTRUSION";
  description: string;
  grammage?: number;
  minGrammage?: number;
  maxGrammage?: number;
  substrate?: SubstrateLayer;
  chemical?: ChemicalLayer;
}

export class SubstrateLayer {
  id_item_3: string;
  id_item_3_inicial?: string;
  classif?: string;
};

export class ChemicalLayer {
  id_quimico_3: string;
  id_especif_carac_estr_i_subst?: string;
  chemicalDescription?: string;
  substrateDescription?: string;
  side?: string;
  chemicalType?: string;
  printing?: string;
  varnishSide?: string;
  waitingTime?: number;
};

export class ChemicalClassification {
  id_quimico_3: string;
  id_quimico_3_parent: string;
  description: string;
  code: string;
  hierarchy: Array<string>;
}

export class SubstrateClassification {
  id_item_3: string;
  id_item_3_parent: string;
  description: string;
  code: string;
  hierarchy: Array<string>;
}

export class ConjugateAssembly {
  leftMargin: number;
  rightMargin: number;
  topMargin: number;
  bottomMargin: number;
  itemList: Array<ConjugateAssemblyItem>;
}

export class ConjugateAssemblyItem {
  id_especif_carac_mont_cj_item: string;
  description: string;
  trackQty: number;
  verticalQty: number;
  bloodlettingBtwTracks: number;
  bloodlettingBtwLines: number;
  rotation: string;
  printSequence: number;
  width: number;
  height: number;
}

export class Lamination {
  id_especif_carac_lamSeq_item: string;
  id_especif_carac: string;
  description: string;
  id_especif_carac_substC_item_1: string;
  id_especif_carac_substC_item_2: string;
  id_especif_carac_lamSeq_item_1: string;
  id_especif_carac_lamSeq_item_2: string;
  requiresAdhesive: boolean;
  runBeforePrinting: boolean;
  layer1: string;
  layer2: string;
  newRecord?: boolean;
  layer1List?: Array<{id: string, description: string}>;
  layer2List?: Array<{ id: string, description: string }>;
}