import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { T2DatasetService } from "../../dataset/t2dataset.service";
import { take, takeUntil } from "rxjs/Operators";
import { T2AutoFormComponent } from "../t2-auto-form/t2-auto-form.component";
import { Observable, Subject } from "rxjs";
import { T2SecurityService } from "../../security/t2security.service";
import { T2AccessItem, T2AccessItemDatasetActionType } from "../../security/model/t2accessItem";
import { T2HttpClientService } from "../../http/t2httpClient.service";
import { T2MessageService } from "../../t2-message.service";
import { ActionService } from "../../action/action.service";
import { T2CoreException } from "../../exception/exception";
import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";

@Component({
  selector: 'app-t2-form-list',
  templateUrl: './t2-form-list.component.html',
  styleUrls: ['./t2-form-list.component.scss']
})
export class T2FormListComponent implements OnInit, OnDestroy {
  @Input() datasetName: string = null;
  @Input() parentDatasetName: string = null;
  @Input() parentId: string = null;
  @Input() parentSaved: Observable<string>;
  @Output() hasChanges = new EventEmitter<void>();
  @Output() saveCompleted = new EventEmitter<void>();

  @ViewChildren("autoForm") autoformList: QueryList<T2AutoFormComponent>;

  private unsubscribe = new Subject<void>();
  private actionList: Array<T2AccessItem>;
  private unsavedIdList: Array<string> = [];
  public compIdList: Array<string>;
  public canInsert: boolean = false;
  public canDelete: boolean = false;
  public hasSortOrder: boolean = false;

  constructor(private datasetService: T2DatasetService, private secService: T2SecurityService, private httpClient: T2HttpClientService,
    private messageService: T2MessageService, private actionService: ActionService
  ) { }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  ngOnInit(): void {
    this.loadFormList();

    this.parentSaved?.pipe(takeUntil(this.unsubscribe)).subscribe(id => {
      this.adjustSortOrder();
      let promList = new Array<Promise<string>>();
      this.autoformList?.filter(af => af.formGroup.dirty).forEach(af => {
        promList.push(af.saveAutoform(af, true));
      });

      Promise.all(promList).finally(() => {
        this.saveCompleted.emit();
      })
    });

    this.secService.getDatasetActions(this.datasetName).pipe(take(1)).subscribe(acList => {
      this.actionList = acList;
      this.canInsert = acList.some(ac => ac.datasetActionType == T2AccessItemDatasetActionType.DSACTION_INSERT);
      this.canDelete = acList.some(ac => ac.datasetActionType == T2AccessItemDatasetActionType.DSACTION_DELETE);
    })
  }

  public loadFormList() {
    this.datasetService.getDatasetEntityComposition(this.parentDatasetName, this.parentId, this.datasetName).pipe(take(1)).subscribe(resp => {
      this.compIdList = resp[0]?.ID;

      setTimeout(() => {
        this.autoformList?.get(0)?.loadingCompleted.pipe(take(1)).subscribe(() => {
          if (this.autoformList?.get(0)?.formGroup?.controls[`${this.datasetName}.sortKey`]) {
            this.hasSortOrder = true;
          }
        });
      }, 200);
    })
  }

  public detectChanges(id: string) {
    let af = this.autoformList.find(af => af.informationId == id);
    af.formGroup.valueChanges.pipe(take(1)).subscribe(() => {
      this.hasChanges.emit();
    });
  }

  public insertRecord() {
    this.httpClient.getNewUUID().pipe(take(1)).subscribe(newId => {
      this.unsavedIdList.push(newId);
      this.compIdList.push(newId);
    })
  }

  public deleteRecord(id: string) {
    this.messageService.showDialog({
      context: {
        topMessage: "ATENÇÃO",
        message: "Deseja excluir esse registro ?",
        actions: [{ description: "Excluir", status: "danger" }, { description: "Cancelar", status: "basic" }]
      }
    }).onClose.subscribe(resp => {
      if (resp == "Excluir") {
        let index = this.unsavedIdList.findIndex(compId => compId == id);

        if (index >= 0) {
          this.compIdList.splice(this.compIdList.findIndex(compId => compId == id), 1);
          this.unsavedIdList.splice(index, 1);
        } else {
          const action = this.actionList.find(ac => ac.datasetActionType == T2AccessItemDatasetActionType.DSACTION_DELETE);

          if (action) {
            const params = new Map<string, string>();

            params.set("datasetName", this.datasetName);
            params.set("id", id);

            this.actionService.executeAction(action, params).subscribe(resp => {
              if (!resp.error) {
                this.compIdList.splice(this.compIdList.findIndex(compId => compId == id), 1);
              }
            }, (error: T2CoreException) => {
              if (error.message) {
                this.messageService.showToastError(error.message);
              }
            });
          }
        }
      }
    })
  }

  onDrop(event: CdkDragDrop<Array<any>>) {
    moveItemInArray(this.compIdList, event.previousIndex, event.currentIndex); 
    this.adjustSortOrder(); 
  }

  adjustSortOrder() {
    let index: number = 0;
    this.compIdList.forEach(compId => {
      index++;
      let af = this.autoformList.find(af => af.informationId == compId);
      af.formGroup.controls[`${this.datasetName}.sortKey`].setValue(index);
      af.formGroup.controls[`${this.datasetName}.sortKey`].markAsDirty();
    });
  }

}
