import { Component, OnInit, Input } from "@angular/core";
import { T2BaseComponent } from "../t2-base-component";
import { AbstractControl } from "@angular/forms";

@Component({
  selector: "app-t2-select",
  templateUrl: "./t2-select.component.html",
  styleUrls: ["./t2-select.component.scss"],
})
export class T2SelectComponent extends T2BaseComponent implements OnInit {
  @Input() list: Array<{ id: any, description: string }> | Array<{ description: string, items: Array<{ id: any, description: string }> }>;
  @Input() grouped: boolean;
  @Input() multiple: boolean = false;
  @Input() required: boolean;

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.setDefaultValues();
    let validator = this.formGroup?.controls[this.t2IdCmp]?.validator ? this.formGroup?.controls[this.t2IdCmp]?.validator({} as AbstractControl) : null

    if (!this.required || this.required == undefined) {
      this.required = validator && validator['required'];
    }
  }

  getSelectedValues(): any {
    return this.formGroup?.controls[this.t2IdCmp].value;
  }

  getDescription(): string {
    if (!this.multiple) {
      if (!this.grouped) {
        return (this.list as Array<any>).find(item => this.formGroup.controls[this.t2IdCmp].value == item.id)?.description;
      } else {
        for (let group of (this.list as Array<any>)) {
          let description = (group.items as Array<any>).find(item => this.formGroup.controls[this.t2IdCmp].value == item.id)?.description;

          if (description) {
            return description;
          }
        }
      }
    }

    return "";
  }
}
