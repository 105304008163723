import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ColDef, GetDataPath, IRowNode, IsRowSelectable } from "ag-grid-community";
import { take } from "rxjs/Operators";
import { T2HttpClientService } from "../../http/t2httpClient.service";
import { T2TreeGridComponent } from "../t2-tree-grid/t2-tree-grid.component";

@Component({
  selector: 'app-t2-dataset-tree-grid',
  templateUrl: './t2-dataset-tree-grid.component.html',
  styleUrls: ['./t2-dataset-tree-grid.component.scss']
})
export class T2DatasetTreeGridComponent {

  @ViewChild("t2TreeGrid", { static: true }) t2TreeGrid: T2TreeGridComponent;
  @Input()
  get datasetName(): string {
    return this._datasetName;
  }
  set datasetName(value: string) {
    this._datasetName = value;
    this.getTreeData();
  }
  @Input() dataIdSelected: string;
  @Input() disableGroupSelection: boolean = true;

  @Output() selectionChanged = new EventEmitter<TreeInfo>();

  private _datasetName: string;
  public autoGroupColumnDef: ColDef = {
    headerName: "Código", width: 200
  };
  public getDataPath: GetDataPath = (data) => {
    return data.hierarchy;
  }
  public data: Array<TreeInfo>

  public isRowSelectable: IsRowSelectable = (node) => {
    return node.data.leafNode;
  }

  constructor(private httpClient: T2HttpClientService) {

  }

  private getTreeData() {
    this.httpClient.get(`core.tree/dataList/${this.datasetName}`, null).pipe(take(1))
      .subscribe(resp => {
        if (resp.dataTreeList) {
          if (!Array.isArray(resp.dataTreeList)) {
            resp.dataTreeList = [resp.dataTreeList];
          }

          this.data = resp.dataTreeList;
          this.adjustDataForTreeGrid([], undefined);
          this.t2TreeGrid.setGridColumnDefs([
            {
              headerName: "Descrição", field: "description"
            }
          ]);

          this.t2TreeGrid.setGridData(this.data, this.dataIdSelected);


        }
      })
  }

  private adjustDataForTreeGrid(parentHierarchy: Array<string>, idParent: string) {
    this.data.filter(item => item.idParent == idParent).forEach(item => {
      item["ID"] = item.id;
      item.hierarchy = [...parentHierarchy];
      item.hierarchy.push(item.code);

      if (this.data.some(child => child.idParent == item.id)) {
        item.leafNode = false;
        this.adjustDataForTreeGrid(item.hierarchy, item.id);
      } else {
        item.leafNode = true;
      }
    })
  }

  onSelectionChanged(params) {
    let rowsSelected = this.t2TreeGrid.getRowsSelected();

    this.selectionChanged.emit(rowsSelected[0]);
  }


}

export class TreeInfo {
  id: string;
  idParent: string;
  code: string;
  description: string;
  hierarchy?: Array<string>;
  leafNode?: boolean;
}
