import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

import { T2LocalStorageService } from '../t2local-storage.service';
import { T2Authentication } from '../security/model/t2authentication';
import { WSResponse } from './WSResponse';
import { T2CoreException } from '../exception/exception';
import { ActivatedRouteSnapshot } from '@angular/router';
import { T2Route } from './t2route';
import { T2MessageService } from '../t2-message.service';
import { concatMap, map, take } from "rxjs/Operators";
import { MediaType, RestMethod, T2RequestInfo } from "./t2RequestInfo";

@Injectable({
  providedIn: 'root'
})
export class T2HttpClientService {

  constructor(
    private httpClient: HttpClient,
    private storage: T2LocalStorageService,
    private messageService: T2MessageService) {
    this.subAuth$ = new BehaviorSubject<T2Authentication>(new T2Authentication(true, false, null));

    this.licCount.set('full', 0);
    this.licCount.set('dataCollector', 0);
    this.licCount.set('dashboard', 0);

  }

  private licCount: Map<LicenseType, number> = new Map();

  private serverUrl = environment.afxserver || null;
  private companyID = environment.companyID || null;
  public clientUrl = undefined;

  private SESSION_ID = '$s1';
  private subAuth$: BehaviorSubject<T2Authentication>;

  public clientID: string;
  public sessionID: string;
  public id_companySite: string;

  public isHomologEnvironment: boolean;
  public serverDescription: string;
  public serverVersion: string;
  public javaVmName: string;
  public javaRuntimeVersion: string;
  public osArch: string;
  public osName: string;

  private removeAT(obj: any): {} {

    if (obj === null) { return null; }

    const newObj = {};

    Object.keys(obj).map(key => {
      let value = obj[key];
      if (value != null) {
        if (Array.isArray(value)) {
          // console.log('sub-array: ' + key);
          value = this.removeATArray(value);
        } else if (typeof value === 'object') {
          // console.log('sub-objeto: ' + key);
          value = this.removeAT(value);
        }
      }

      const newKey = (key.startsWith('@') ? key.substring(1) : key);
      if (value === 'true') {
        newObj[newKey] = true;
      } else if (value === 'false') {
        newObj[newKey] = false;
      } else {
        newObj[newKey] = value;
      }
    });

    return newObj;
  }

  private removeATArray(data) {
    return data.map(item => {
      if (Array.isArray(item)) {
        return this.removeATArray(item);
      } else if (typeof item === 'object') {
        return this.removeAT(item);
      } else {
        return item;
      }
    });
  }

  public checkResponse<T extends WSResponse>(resp, ct: new () => T): T {
    if (resp == null) {
      throw new Error('No response has returned');
    }

    const obj = new ct();
    Object.assign(obj, this.removeAT(resp));
    return obj;
  }

  public incLicense(lic: LicenseType) { const c = this.licCount.get(lic); this.licCount.set(lic, c + 1); }
  public decLicense(lic: LicenseType) { const c = this.licCount.get(lic); this.licCount.set(lic, Math.max(c - 1, 0)); }
  public getCurrentLicense(): string {

    let lic = "TechprodDash";

    if (this.licCount.get('full'))
      lic = 'TechprodWeb';

    if (this.licCount.get('dataCollector'))
      lic = 'TechprodData';

    return lic;
  }


  public setCompanyID(companyID: string) {
    this.companyID = companyID;

    if (this.companyID) {
      console.log('TP Customer', companyID);
    }
  }

  public idSession() {
    this.sessionID = this.storage.getData(this.SESSION_ID);
    return this.sessionID;
  }

  public checkAuthStatus(): Observable<T2Authentication> {

    const subjCS$ = new Subject<T2Authentication>();

    if (!this.idSession()) {
      const t = new T2Authentication(false, false, null);
      this.updateAuthStatus(t);

      subjCS$.next(t);
      subjCS$.complete();
    } else {
      this.intRequest({ method: RestMethod.GET, path: 'core.sec.auth/sessionIsAlive' }, 0).pipe(take(1)).subscribe(resp => {

        this.serverDescription = resp.serverDescription;
        this.serverVersion = resp.serverVersion;
        this.javaVmName = resp.javaVmName;
        this.javaRuntimeVersion = resp.javaRuntimeVersion;
        this.osArch = resp.osArch;
        this.osName = resp.osName;

        let t: T2Authentication;
        const r = this.checkResponse(resp, WSResponse);
        if (r.error) {
          t = new T2Authentication(false, true, r.errorMessage);
        } else {
          t = new T2Authentication(true, false, null, false, r["id_security_identity"])
        }

        this.updateAuthStatus(t);
        subjCS$.next(t);
        subjCS$.complete();


      }, error => {
        const t = new T2Authentication(false, true, error.message, true);
        this.updateAuthStatus(t);
        subjCS$.next(t);
        subjCS$.complete();
      });
    }

    return subjCS$.asObservable();
  }

  public updateAuthStatus(auth: T2Authentication) {
    if (auth.toString() != this.subAuth$.value.toString()) {
      this.subAuth$.value?.userId;
      this.subAuth$.next(auth);
    }
  }

  public authStatusObservable(): Observable<T2Authentication> {
    const obs = this.subAuth$.asObservable();
    return obs;
  }

  public getUserId(): string {
    return this.subAuth$.value?.userId;
  }

  public getNewUUID(): Observable<string> {

    const subUUID$ = new Subject<any>();
    this.get("util/getUUID", null)
      .pipe(take(1))
      .subscribe(resp => {
        subUUID$.next(resp.generatedID);
      });

    return subUUID$.asObservable();
  }

  public get(path: string, queryParams: Map<string, string>): Observable<any> {
    if (queryParams === null) {
      queryParams = new Map<string, string>();
    }

    if (path != "core.sec.auth/logon") {
      return this.checkAuthStatus().pipe(
        take(1),
        concatMap(shift => {
          return this.intRequest({ method: RestMethod.GET, path, queryParams }, 5);
        }));
    } else {
      return this.intRequest({ method: RestMethod.GET, path, queryParams }, 5);
    }
  }

  public post(path: string, queryParams: Map<string, string>, body: any): Observable<any> {
    if (queryParams === null) {
      queryParams = new Map<string, string>();
    }

    return this.checkAuthStatus().pipe(
      take(1),
      concatMap(shift => {
        return this.intRequest({ method: RestMethod.POST, path, queryParams, body }, 2);
      }));
  }

  public getBlob(path: string, queryParams: Map<string, string>, body: string, accept?: string): Observable<any> {
    if (queryParams === null) {
      queryParams = new Map<string, string>();
    }

    return this.checkAuthStatus().pipe(
      take(1),
      concatMap(shift => {
        return this.intRequest({ method: RestMethod.GET, path, queryParams, body, responseType: 'blob', accept }, 2);
      }));
  }

  public postBlob(path: string, queryParams: Map<string, string>, body: string, accept?: string): Observable<any> {
    if (queryParams === null) {
      queryParams = new Map<string, string>();
    }

    return this.checkAuthStatus().pipe(
      take(1),
      concatMap(shift => {
        return this.intRequest({ method: RestMethod.POST, path, queryParams, body, responseType: 'blob', accept }, 2);
      }));
  }

  public put(path: string, queryParams: Map<string, string>, body: any): Observable<any> {
    if (queryParams === null) {
      queryParams = new Map<string, string>();
    }

    return this.checkAuthStatus().pipe(
      take(1),
      concatMap(shift => {
        return this.intRequest({ method: RestMethod.PUT, path, queryParams, body }, 2);
      }));
  }

  public delete(path: string, queryParams: Map<string, string>): Observable<any> {
    if (queryParams === null) {
      queryParams = new Map<string, string>();
    }

    return this.checkAuthStatus().pipe(
      take(1),
      concatMap(shift => {
        return this.intRequest({ method: RestMethod.DELETE, path, queryParams, body: null }, 2);
      }));
  }

  private intRequest(
    reqInfo: T2RequestInfo,
    retries: number): Observable<any> {

    reqInfo.queryParams?.forEach((value, key) => {
      if (value && (typeof value == 'string') && value.indexOf("&") >= 0) {
        console.warn(`TECHPROD request may be broken when param value has special character.`, { path: reqInfo.path, key, value })
      }
    });

    let url = null;
    let options = null;
    let method = reqInfo.method || null;
    let body = reqInfo.body || null;

    if (this.serverUrl) {
      url = this.serverUrl + reqInfo.path;
      options = this.getRequestOptions(reqInfo);

    } else if (this.companyID) {
      const queryParams = [];

      reqInfo.path = reqInfo.path.trim();
      if (reqInfo.path.endsWith("?")) { reqInfo.path = reqInfo.path.substring(0, reqInfo.path.length - 1); }
      if (reqInfo.path.indexOf("?") > 0) {
        reqInfo.path
          .substring(reqInfo.path.indexOf("?") + 1)
          .split("&")
          .forEach((param: string) => queryParams.push(param));

        console.warn("TECHPROD request path's could not have any paramateres, use queryParams instead.", "Path:", reqInfo.path)
        reqInfo.path = reqInfo.path.substring(0, reqInfo.path.indexOf("?"));
      }

      if (this.sessionID) {
        queryParams.push('id_session=' + this.sessionID);
      }

      if (this.id_companySite) {
        queryParams.push('id_companySite=' + this.id_companySite);
      }

      const obj = {};
      if (reqInfo.queryParams) {
        reqInfo.queryParams.forEach((value: string, key: string) => {
          queryParams.push(key + '=' + value);

          obj[key] = value;
        });
      }

      queryParams.push('remoteAddress=' + this.clientID);
      queryParams.push(`clientVersion=${this.getCurrentLicense()}`);
      queryParams.push(`guestVersion=${this.getCurrentLicense()}`);

      url = 'https://api.techprod.com.br/operationV2';

      options = {
        headers: {
          'Accept': reqInfo.accept || (reqInfo.responseType === "blob" ? MediaType.OCTET : MediaType.JSON),
          // 'Content-Type': MediaType.JSON,
          'Access-Control-Allow-Origin': '*',
        },
        responseType: (reqInfo.responseType ? reqInfo.responseType : 'json')
      };

      options.params = new HttpParams({ fromObject: obj });

      if (reqInfo.body instanceof FormData) {
        body = reqInfo.body;
        const bodyRedirect: any = {
          accept: reqInfo.accept || (reqInfo.responseType === "blob" ? MediaType.OCTET : MediaType.JSON),
          method: method.toString(),
          companyID: this.companyID,
          sessionID: this.sessionID,
          companySiteID: this.id_companySite,
          clientID: this.clientID,
          userAgent: window.navigator.userAgent,
          path: reqInfo.path,
          queryParams: (queryParams.length ? queryParams.join('&') : null),
        };

        body.append("body", JSON.stringify(bodyRedirect, null, 2));

      } else {

        body = {
          accept: reqInfo.accept || (reqInfo.responseType === "blob" ? MediaType.PDF : MediaType.JSON),
          method: method.toString(),
          companyID: this.companyID,
          sessionID: this.sessionID,
          companySiteID: this.id_companySite,
          clientID: this.clientID,
          userAgent: window.navigator.userAgent,
          path: reqInfo.path,
          queryParams: (queryParams.length ? queryParams.join('&') : null),
          body: reqInfo.body
        };
      }

      method = RestMethod.POST;

    } else {
      throw new Error('Unabled to connect to TECHPRODServer, you should define AFXSERVER or COMPANYID');
    }

    const sub$ = new Subject<any>();
    switch (method) {
      case RestMethod.GET:
        this.subscribeRequest(
          this.httpClient.get(url, options),
          sub$,
          reqInfo,
          retries
        );
        break;

      case RestMethod.POST:
        if (reqInfo.responseType === "blob") {
          this.subscribeRequest(
            this.httpClient.post<Blob>(url, body, options),
            sub$,
            reqInfo,
            retries
          );
        } else {
          this.subscribeRequest(
            this.httpClient.post(url, body, options),
            sub$,
            reqInfo,
            retries
          );
        }
        break;

      case RestMethod.PUT:
        this.subscribeRequest(
          this.httpClient.put(url, body, options),
          sub$,
          reqInfo,
          retries
        );
        break;

      case RestMethod.DELETE:
        this.subscribeRequest(
          this.httpClient.delete(url, options),
          sub$,
          reqInfo,
          retries
        );
        break;
    }

    return sub$.asObservable().pipe(
      map(resp => { return resp; })
    );
  }

  private getRequestOptions(reqInfo: T2RequestInfo) {
    const obj = { id_session: null, id_companySite: null };
    if (reqInfo.queryParams) {
      reqInfo.queryParams.forEach((value: string, key: string) => {
        if (value != undefined && value != null) {
          obj[key] = value;
        }
      });
    }

    obj.id_session = this.sessionID;
    obj.id_companySite = this.id_companySite;
    obj["remoteAddress"] = this.clientID;
    obj["clientVersion"] = this.getCurrentLicense();
    obj["guestVersion"] = this.getCurrentLicense();

    const options: any = {};
    options.headers = {
      'Access-Control-Allow-Origin': '*',
      ci: this.clientID,
      'content-encoding': "UTF-8"
    };

    if (this.sessionID) {
      options.headers.is = this.sessionID;
    }

    if (this.id_companySite) {
      options.headers.cs = this.id_companySite;
    }

    options.params = new HttpParams({ fromObject: obj });
    options.responseType = (reqInfo.responseType ? reqInfo.responseType : 'json');

    if (reqInfo.body instanceof FormData) {
      const file: File = ((reqInfo.body as FormData).get("file") as File);
      if (file) {
        options.headers['enctype'] = "multipart/form-data; boundary=" + file.name;
      } else {
        options.headers['Content-Type'] = "multipart/form-data";
      }

    } else
      if (options.responseType === 'json') {
        options.headers['Content-Type'] = MediaType.JSON;
      }

    return options;
  }

  private subscribeRequest(
    obs$: Observable<any>,
    sub$: Subject<any>,
    reqInfo: T2RequestInfo,
    retries: number
  ): void {

    const subscription$ = obs$.subscribe(resp => {
      subscription$.unsubscribe();

      if (reqInfo.responseType === 'blob') {
        sub$.next(resp);
        return;
      }

      const r = this.checkResponse(resp, WSResponse);
      if (!r.error) {
        // AFXServer nao respondeu um erro
        sub$.next(r);
      } else if (retries &&
        [
          'br.com.codit.afx.core.security.session.SessionDoesNotExistException',
          "br.com.codit.afx.core.security.auth.OverthrowSessionException"
        ].includes(r.exceptionName)) {
        console.warn("AUTH RETRY", retries, r.exceptionName);

        // AFXServer respondeu um erro, porém é para tentar novamente
        setTimeout(() => {
          this.checkAuthStatus().pipe(
            take(1),
            concatMap(shift => {
              const obs2$ = this.intRequest(reqInfo, --retries).subscribe(resp2 => {
                obs2$.unsubscribe();
                sub$.next(resp2);
              }, error2 => {
                sub$.error(error2);
              });

              return null;
            })).subscribe();


        }, 300);
      } else {
        // AFXServer respondeu um erro, porém não é para tentar novamente, devolve erro
        console.warn(r, reqInfo);

        if (r.exceptionName == "br.com.codit.afx.core.security.auth.AFXUserAuthInvalidUsernamePassword") {
          const t = new T2Authentication(false, true, r.errorMessage);
          t.invalidCredentials = true;
          this.updateAuthStatus(t);
        } else if (r.exceptionName != "br.com.codit.afx.core.security.session.SessionDoesNotExistException" || this.subAuth$?.value?.userId) {
          this.messageService.showToastError(r.errorMessage);
          sub$.error(new T2CoreException(null, null, r.errorMessage));

        }
      }
    }, (error: any) => {
      console.log('ERROR', { body: reqInfo.body, method: reqInfo.method, path: reqInfo.path, error });
      this.messageService.showToastError(error.message);
      sub$.error(error);
    });
  }

  public getT2RouteFromSnapshot(routeSnapshot: ActivatedRouteSnapshot): T2Route {

    const route = new T2Route();
    route.routeSnapshot = routeSnapshot;

    if (routeSnapshot.routeConfig) {
      route.path = routeSnapshot.routeConfig.path;
      route.originalPath = routeSnapshot.routeConfig.path;
      Object.keys(routeSnapshot.params).forEach(key => {
        route.pathParams.set(key, routeSnapshot.params[key]);
        route.path = route.path.replace(':' + key, routeSnapshot.params[key]);
      });

      Object.keys(routeSnapshot.queryParams).forEach(key => {
        route.queryParams.set(key, routeSnapshot.queryParams[key]);
      });
    }

    return route;
  }

  public openPdfReport(observ: Observable<any>): Observable<void> {
    return observ
      .pipe(take(1),
        map(resp => {
          if (!resp || !resp.size) {
            this.messageService.showToastError(`Não é possível exibir o relatório.\n
                                              Nenhuma informação foi recebida para ser exibida.\n
                                              Verifique se o relatório está configurado corretamente.`);
          } else {
            const pdf = new Blob([resp], { type: MediaType.PDF });
            const fileURL = URL.createObjectURL(pdf);
            window.open(fileURL, "_blank");
          }
        }));
  }

  public openPdfReportByUrl(url: string, reportDescription?: string) {
    let params = new Map<string, string>();
    params.set("url", url);

    if (reportDescription) {
      params.set("descricao", reportDescription);
    }

    return this.openPdfReport(
      this.getBlob("core.report/reportStream/url", params, null, MediaType.PDF).pipe(take(1))
    ).pipe(take(1))
  }
}

export type LicenseType = 'dashboard' | 'dataCollector' | 'full';
